import React from 'react';
import { withStyles} from '@material-ui/core';
import Hierarchy from '../Hierarchy/Hierarchy';
import List from '../List/List';

const styles = theme => ({
  root: {
    overflow:'hidden',
    marginLeft:80,
    flexGrow:1,
    flexShrink:1
  }
})

const ResultList = props => {

  const {
    classes,
    history,
    state,
    dispatch,
  } = props;

  return (
    <div className={classes.root}>
      <Hierarchy state={state} dispatch={dispatch} history={history}/>
      <List state={state} dispatch={dispatch} history={history}/>
    </div>
  )
}

export default withStyles(styles)(ResultList);