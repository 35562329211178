import React from 'react';
import { withStyles} from '@material-ui/core';
import Details from '../Details/Details'
import Dashboard from '../../UI/Dashboard/DirectIndirectDashboard/DirectIndirectDashboard';
import NoteAdder from '../../UI/NoteAdder/NoteAdder'
import Issues from '../../UI/ProfileIssueSection/Issues';
import NewLineageContainer from '../../UI/Lineage/NewLineageContainer'

const styles = theme => ({
})

function Body(props) {

  const {
    history,
    state,
    dispatch,
    sessionData,
    fetchList,
    tabOptions
  } = props;

  let bodyContent;

  switch(tabOptions[state.tabState]){
    case 'DETAILS':
      bodyContent=(
        <Details
          history={history}
          state={state}
          dispatch={dispatch}
          sessionData={sessionData}
          fetchList={fetchList}
        />
      )
      break;
    case 'KNOWLEDGE': 
      bodyContent = (
        <NoteAdder
          history={history}
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.noteModalOpen}
          setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
        />
      )
      break;
    case 'USAGE': // usage
      bodyContent = (
        <Dashboard
          history={history}
          state={state}
          dispatch={dispatch}
          label={'file'}
          drillThrough={true}
        />
      )
      break;
    case 'LINEAGE':
      bodyContent=(
        <NewLineageContainer
          history={history}
          state={state}
          dispatch={dispatch}
          isLinkable={true}
        />
      )
      break;
    case 'ISSUES':
      bodyContent=(
        <Issues
          history={history}
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    default:

  }

  return <div>{bodyContent}</div>;
}

export default withStyles(styles)(Body);