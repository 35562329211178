import React, { useEffect, useReducer } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/PlatformManagement/Body/Body';
import useGetCerebrum from '../../hooks/useGetCerebrum';
import 'url-search-params-polyfill';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import { setPlatformSettings } from '../../permissionChecker';
import { setHelpWdigetVisibility, removeUrlQueryArg } from '../../utilities';
import { addHistory } from '../../HistoryManager';
import { generateScheduleText } from '../../components/OnBoardSource/utils';
import ProfileButton from '../../components/UI/Buttons/ProfileButton';
import { settingsHidden, settingsPrefixHidden } from '../../components/PlatformManagement/Settings/utils';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: 8,
    height: '3rem',
    right: '-12rem'
  },
  underlineOnHover: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  title: {
    height: '44px',
    //width: '164px',
    color:theme.palette.header.main,
    fontSize: '33.38px',
    letterSpacing: '0.25px',
    lineHeight: '44px'
  },
  titleBelowLine: {
    height: '24px',
    width: '824px',
    color: theme.palette.primaryText.light,
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    //marginLeft:'18px',
    //marginTop: '21px'
  },
});

const initialState = {
  tabState: 0,
  view:'home',
  // batch
  sources:[],
  // metrics
  monitorTabState:0,
  metricsData: [],
  metricsError: null,
  metricsLoading: null,
  //platform jobs
  jobsData:null,
  jobsError:null,
  jobsLoading:null,
  // settings
  settingsData: null,
  settingsError: null,
  settingsLoading: null,
  params:{
    per_page:10,
    sort:'START_DESC',
  },
  collapsedSettingGroup:[]
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_sources':{
      return {
        ...state,
        sources:action.sources,
        sourcesLoading:action.sourcesLoading,
        sourcesError:action.sourcesError
      }
    }
    case 'set_monitor_sources_data':{
      return {
        ...state,
        monitorSourcesData:action.monitorSourcesData,
        monitorSourcesLoading:action.monitorSourcesLoading,
        monitorSourcesError:action.monitorSourcesError
      }
    }
    case 'set_monitor_tab_state':{
      return {
        ...state,
        monitorTabState:action.monitorTabState
      }
    }
    case 'set_history_data':
      return {
        ...state,
        historyData: action.historyData,
        historyError: action.historyError,
        historyLoading: action.historyLoading
      } 
    case 'set_metrics_data':
      return {
        ...state,
        metricsData: action.metricsData,
        metricsError: action.metricsError,
        metricsLoading: action.metricsLoading,
        metricsPage:action.metricsPage
      }  
    case 'set_settings_data':
      return {
        ...state,
        settingsData: action.settingsData,
        settingsError: action.settingsError,
        settingsLoading: action.settingsLoading
      }
    case 'set_jobs_data':
      return {
        ...state,
        jobsData: action.jobsData,
        jobsError: action.jobsError,
        jobsLoading: action.jobsLoading
      }
    case 'set_disable_schedule_modal_open':
      return {
        ...state,
        disableScheduleModalOpen:action.disableScheduleModalOpen
      }
    case "set_selected_date":
      return {
        ...state,
        selectedDate: action.selectedDate
      }
    case "set_sources_data":
      return {
        ...state,
        sourcesData: action.sourcesData,
        sourcesError: action.sourcesError,
        sourcesLoading: action.sourcesLoading
      }
    case "set_params":
      return {
        ...state,
        params: action.params
      }
    case 'set_view':
      return {
        ...state,
        view:action.view
      }
    case 'set_selected_job':
      return {
        ...state,
        selectedJob:action.selectedJob
      }
    case 'set_collapsed_setting_group':{
      return {
        ...state,
        collapsedSettingGroup:action.collapsedSettingGroup
      }
    }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

const tabOptions = ['MONITOR', 'BATCH MANAGER','SETTINGS'];


const PlatformManagement = props => {

  const {
    history,
    classes,
    sessionData
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName');

  useEffect(()=>{
    addHistory({
      url:window.location.pathname, 
      iconLabel:'settingFilled', 
      title: 'Platform Management', 
      subTitle:'Manage and monitor the platform',
      type:'application',
    })
  },[])

  useEffect(()=>{
    if(tabName){
      let presetTabState = Math.max(0,tabOptions.indexOf(tabName.toUpperCase()));
      dispatch({type:'set_tab_state',tabState:presetTabState})
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
    }
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    setHelpWdigetVisibility(true)
    return ()=>{setHelpWdigetVisibility(false)}
  // eslint-disable-next-line
  },[])


  // database data
  const {
    data:dbData,
    loading:dbLoading,
    error:dbError,
    fetchList:dbFetch
  } = useGetCerebrum({
    url:'/api/sources',
    params:{
      configured_flag:true,
      internal_flag:false,
      types:'DATABASE,TOOL,IDENTITY_STORE',
      sort:'ALPHABETICAL',
      per_page:100
    },
    preventAuto:true
  })

  useEffect(()=>{
    dispatch({type:'set_sources',sources:dbData?dbData.items:undefined,sourcesLoading:dbLoading,sourcesError:dbError})
  },[dbData,dbLoading,dbError])

  const {
    data: settingsData,
    loading: settingsLoading,
    error: settingsError,
    fetchList: settingsFecthlist
  } = useGetCerebrum({
    url: `/api/settings`,
    params:{
      per_page:100
    },
    preventAuto: true
  });

  const {
    data: jobsData,
    loading: jobsLoading,
    error: jobsError
  } = useGetCerebrum({
    url: `/api/platformbatches`,
    params:{
      per_page:100,
      sort:"ALPHABETICAL"
      // internal_flag:false
    }
  });


  // trigger api call when tab state is changed, if data is already fetched, do nothing
  useEffect(() => {
    switch (state.tabState) {
      case 0: //  monitor
        if(!dbData)dbFetch();
        break;
      case 2: // settings
        if(settingsError) return
        if (!settingsData && !settingsError) settingsFecthlist()
        break;
      default:
        break;
    }
     // eslint-disable-next-line
  }, [
    state.tabState,settingsFecthlist, settingsData
  ])

  useEffect(() => {
    if(settingsData){
      setPlatformSettings(settingsData.items)
    }
    dispatch({
      type: 'set_settings_data',
      settingsData: settingsData ? settingsData.items.filter(el=>settingsPrefixHidden.every(p=>!el.key.includes(p)) && !settingsHidden.includes(el.id)) : undefined,
      settingsError: settingsError,
      settingsLoading: settingsLoading
    })
  }, [settingsData, settingsError, settingsLoading])

  useEffect(() => {
    dispatch({
      type: 'set_jobs_data',
      jobsData: jobsData ? jobsData.items : undefined,
      jobsError: jobsError,
      jobsLoading: jobsLoading
    })
  }, [jobsData, jobsError, jobsLoading])

  useEffect(() => {
    if (props.history.location.state)
    {
      dispatch({ type: 'set_tab_state', tabState: props.history.location.state.tabState })
    }
  }, [props])

  let title, description, bannerdisplayText, bannerCause, buttons;

  if(state.view==='home'){
    title = 'Platform Management'
    description = 'Manage and monitor the platform'
  }
  if(state.view==='scheduler'){
    title = `Schedule ${state.selectedJob.name} job`
    description = 'Use this form to schedule how frequently a job will run'
    if(generateScheduleText(state.selectedJob)){
      bannerdisplayText = `Scheduled: ${generateScheduleText(state.selectedJob)}`;
      bannerCause = 'source_scheduled';
    }
    buttons = [
      <ProfileButton
        text="REMOVE SCHEDULE"
        onClick={()=>{dispatch({type:'set_disable_schedule_modal_open',disableScheduleModalOpen:true})}}
        testID='remove-schedule-button'
      />,
      <ProfileButton
        text="CLOSE"
        onClick={()=>{dispatch({type:'set_view',view:'home'})}}
        testID='close-schedule-button'
      />
    ]
  }
  
  return (
    <div>
      <ProfileLayout
        maxWidth={1440}
        header={
          <ProfileHeader
            title={title}
            label="platform_management_admin"
            iconLabel="setting"
            subtitle=' '
            description={description}
            bannerCause={bannerCause}
            bannerdisplayText={bannerdisplayText}
            buttons={buttons}
          />
        }
        tabBar={
          state.view==='home' &&
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            disableUnderline={true}
            minWidth={200}
            maxWidth={200}
          />
        }
        body={
          <div className={classes.bodyProfile}>
            <Body
              history={history}
              tabState={state.tabState}
              state={state}
              dispatch={dispatch}
              sessionData={sessionData}
            />
          </div>
        }
      />
    </div>)
}

export default withStyles(styles)(PlatformManagement);
