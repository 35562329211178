import React, { useReducer, useEffect, useState, useRef } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import {  LinearProgress, Typography, Modal } from '@material-ui/core';
import Body from '../../components/CollectionProfile/Body/Body';
import {  handleShareClick, getDispFields, getUserRoles, setInitialState, collectionIds, removeUrlQueryArg } from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import axiosCerebrum from '../../axios-cerebrum'
import AddInstanceModal from '../../components/CollectionProfile/AddInstanceModal/AddInstanceModal'
import fileDownloader from 'js-file-download';
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import axiosSolr from '../../axios-solr'
import CreateCollection from '../CreateCollection/CreateCollection';
import { getIconLabel } from '../../components/UI/SearchResults/utils';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import TabBar from '../../components/UI/TabBar/TabBar';
import { checkCollectionEditable, checkIsFollowed } from '../../permissionChecker';
import useAlert from '../../hooks/useAlert';
import 'url-search-params-polyfill';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  underlineOnHover: {
    color:theme.palette.primaryText.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
});


const initialState = {
  tabState: 0,
  // basic table data
  basicData: null,
  instanceSearch: '',
  instanceSort: 'ALPHABETICAL',
  instanceData:undefined,
  instanceLoading:undefined,
  instanceError:undefined,
  addInstanceModalOpen:false,
  instanceProperties:{},
  instanceView:'tree',
  instanceDataPageMap:{},
  lineageData:{
    tabState:0,
    upstreamView:'basic_map',
    downstreamView:'basic_map',
    upstreamObjects:['COLLECTION_INSTANCE'],
    downstreamObjects:['COLLECTION_INSTANCE'],
  }
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataLoading:action.basicDataLoading,
        basicDataError:action.basicDataError,
      }
    case 'set_instance_search':
      return {
        ...state,
        instanceSearch:action.instanceSearch
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData:action.insightsData
      }
    case 'set_instance_sort':
      return {
        ...state,
        instanceSort:action.instanceSort
      }
    case 'set_instance_data':
      return {
        ...state,
        instanceData:action.instanceData,
        instanceLoading:action.instanceLoading,
        instanceError:action.instanceError
      }
    case 'set_instance_list_data':
      return {
        ...state,
        instanceListData:action.instanceListData,
        instanceListLoading:action.instanceListLoading,
        instanceListError:action.instanceListError
      }
    case 'set_add_instance_modal_open':
      return {
        ...state,
        addInstanceModalOpen:action.addInstanceModalOpen
      }
    case 'set_instance_properties':
      return {
        ...state,
        instanceProperties:action.instanceProperties
      }
    case 'set_lineage_data':
      return {
        ...state,
        lineageData:action.lineageData
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_instance_view':{
      return {
        ...state,
        instanceView:action.instanceView
      }
    }
    case 'set_instance_data_page_map':{
      return {
        ...state,
        instanceDataPageMap:action.instanceDataPageMap
      }
    }
    case 'set_instance_editing':{
      return {
        ...state,
        instanceEditing:action.instanceEditing
      }
    }
    case 'set_domain_map_data':{
      return {
        ...state,
        domainMapData:action.domainMapData,
        domainMapLoading:action.domainMapLoading,
        domainMapError:action.domainMapError
      }
    }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const CollectionProfile = props => {

  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
  } = props;


  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));

  const [editModalOpen, setEditModalOpen] = useState(false)
  const roles = getUserRoles(sessionData.user_role)

  const tabOptions = ['DETAILS','MAP'];

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    const urlSearch = new URLSearchParams(window.location.search);
    const tabName = urlSearch.get('tabName');
    if(tabName && state.basicData){
      if(!tabOptions.includes(tabName.toUpperCase()))return;
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
      let presetTabState = tabOptions.indexOf(tabName.toUpperCase());
      dispatch({type:'set_tab_state',tabState:presetTabState})
    }
    return ()=>{
      isCancelledRef.current = true
    } 
  // eslint-disable-next-line
  },[])


  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])

  const fetchList = () => {
    dispatch({type:"set_basic_data",basicDataLoading:true})
    axiosCerebrum
      .get(
        `/api/collections/${match.params.id.toLowerCase()}`
      )
      .then(response=>{
        if(response.data.id===collectionIds.domain){
          dispatch({
            type:'set_tab_state',
            tabState:1
          })
        }
        axiosSolr
          .get(
            `/solr/search/select`,{params:{q:"*",fq:`id:${response.data.id}`,fl:'source_txt'}}
          )
          .then(solrRes=>{
            addHistory({url:window.location.pathname, title: getDispFields(response.data,'dispTitle'), subTitle:getDispFields(response.data,'dispSubtitle'),object:response.data,type:'profile'})
            dispatch({
              type:"set_basic_data",
              basicData:{
                ...response.data,
                ...(solrRes.data.response.docs[0]||{})
              }
            })
          })
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:"set_basic_data",basicDataError:true})
      })
  }

  const followDataFetch = () => {
    checkIsFollowed({objectId:match.params.id.toLowerCase()})
      .then(followed=>{
        dispatch({type:'set_following',following:followed})
      })
  }

  useEffect(()=>{
    if(!state.basicData){
      fetchList()
    }
    if(state.followData===undefined){
      followDataFetch()
    }
  // eslint-disable-next-line
  },[])


  if (state.basicDataLoading ) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData){
    return <div></div>
  }


  let buttons = [];

  const resetInstancePropertyValue = () => {
    if(!state.basicData)return;
    let properties = {}
    state.basicData.properties.forEach(el=>{
      if(el.data_type==='DROPDOWN_MULTI'){
        properties[el.id]=[];
      }
      else if(el.data_type==='DROPDOWN_SINGLE'){
        properties[el.id]='placeholder'
      }
      else{
        properties[el.id]='';
      }
      
    })
    dispatch({type:'set_instance_properties',instanceProperties:properties})
  }

  const downloadDetail = () => {
    let payload = {
      type:'EXTRACTS',
        domain:document.location.protocol + "//" + document.location.hostname,
        lookup_code:'0310',
        filters:{collection_id:match.params.id.toLowerCase()}
    }
    axiosCerebrum.post(
      '/api/extracts',payload
    ).then(extractResponse=>{
      sendAlert({message:"Downloading collection details",type:'info'})
      let extractId = extractResponse.data.id;
      let downloaded = false;
      let previousReturned = true;
      let recursionTimes = 0;

      const onError = (interval) => {
        clearInterval(interval);
        sendAlert({message:"Error occurred downloading the file",type:'error'})
      }

      let downloadExtractInterval = setInterval(()=>{
        if(!previousReturned)return;
        if(recursionTimes>200){
          onError(downloadExtractInterval)
          return;
        }
        recursionTimes += 1;
        previousReturned=false;
        axiosCerebrum.get(`/api/extracts/${extractId}`).then(response=>{
          previousReturned = true;
          if(response.data.status==='COMPLETE'){
            if(downloaded)return;
            downloaded = true;
            axiosCerebrum.get(`/api/extracts/${extractId}/download`)
              .then(downloadRes=>{
                clearInterval(downloadExtractInterval);
                fileDownloader(downloadRes.data.replace(/\|/g,','), `K_${state.basicData.name}_details.csv`);
              })
              .catch(error=>{
                console.log(error)
                onError(downloadExtractInterval);
              }) 
          }
          if(response.data.status==='FAILED'){
            onError(downloadExtractInterval);
          }
        }).catch(error=>{
          console.log(error);
          onError(downloadExtractInterval);
          previousReturned = true;
        })
      },2000)
    })
    .catch(error=>{
      console.log(error);
      sendAlert({message:"Error occurred downloading the file",type:'error'})
    })
  }


  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  buttons.push(
    <ProfileButton
      onClick={() => downloadDetail()}
      iconLabel='download'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Download details'}
    />
  )

  let editable = checkCollectionEditable({sessionData, collectionData:state.basicData});

  let parentEditable = false
  if(sessionData.user_role && sessionData.user_role.length>0){
    let userRoles = sessionData.user_role.map(el=>el.toUpperCase());
    let parent_roles = state.basicData.roles;
    userRoles.forEach(el=>{
      if(parent_roles[el] && parent_roles[el].includes('EDIT')){
        parentEditable = true;
      }
    })
  }


  let title =  state.basicData.name
  let instanceName = 'INSTANCE'
  if(title==='Channel')instanceName = 'CHANNEL'
  if(state.basicData.category==='GLOSSARY')instanceName = 'TERM'


  if(['00','10','40'].find(el=>roles.includes(el)) && parentEditable){
    buttons.push(
      <ProfileButton
        onClick={() => setEditModalOpen(true)}
        iconLabel='edit'
        text='EDIT'
      />
    )
  }

  if(editable){
    buttons.push(
      <ProfileButton
        onClick={() => {resetInstancePropertyValue();dispatch({type:'set_add_instance_modal_open',addInstanceModalOpen:true})}}
        iconLabel='add'
        text={`ADD ${instanceName}`}
      />
    )
  }

  buttons.push(
    <FollowButton
      following={state.following}
      object={state.basicData}
      state={state}
      dispatch={dispatch}
    />
  )

  const allowTreeList = 
    ['DATA_MANAGEMENT','DATA_GOVERNANCE','GLOSSARY'].includes(state.basicData.category) || 
    [collectionIds.domain, collectionIds.verifiedUseCase, collectionIds.classification, collectionIds.category].includes(state.basicData.id)


  let iconLabel = getIconLabel({label:'collection',item:state.basicData})

  return (
    <div>
      <ProfileLayout
        header={(
          <ProfileHeader
            title={title}
            subtitle={' '}
            label={'collection'}
            iconLabel={iconLabel}
            buttons={buttons}
            shouldLoadBreadCrumb
            state={state}
            data={state.basicData}
            // description={state.basicData.short_name}
            history={history}
          />
        )}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        hideSideBar={['MAP','IMPORT'].includes(tabOptions[state.tabState])}
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
            fetchList={fetchList}
            instanceName={instanceName}
            editable={editable}
            allowTreeList={allowTreeList}
            tabOptions={tabOptions}
            sessionData={sessionData}
          />
        }
        sideBar={
          <ProfileSideBar
            history={history}
            state={state}
            dispatch={dispatch}
            data={state.basicData}
            basicData={state.basicData}
            mapping={
              ['source','collectionType','createdBy','createdOn','lastUpdated']
            }
            fetchList={fetchList}
            sessionData={sessionData}
            cerebrumLabel={'collections'}
          />
        }
        state={state}
        dispatch={dispatch}
      />
      {
        state.basicData.category!=='AUTOMATED' && 
        <AddInstanceModal
          state={state}
          dispatch={dispatch}
          resetInstancePropertyValue={resetInstancePropertyValue}
          instanceName={instanceName}
          allowTreeList={allowTreeList}
        />
      }
      {
        <Modal open={editModalOpen} disableBackdropClick={true} disableEscapeKeyDown={true} hideBackdrop={false}>
          <div id='edit-collecion-modal-container' style={{backgroundColor:theme.palette.background.main,outline:'none',position:'absolute',left:40,too:0,bottom:0,right:0,height:'100vh',width:'100vw',overflow:'auto'}}>
            <CreateCollection
              history={history}
              presetStates={{
                editMode:true,
                // linkBy:Object.keys(state.selectedCollection.instance_roles).filter(el=>state.selectedCollection.instance_roles[el].includes('LINK')),
                editableBy:Object.keys(state.basicData.roles).filter(el=>state.basicData.roles[el].includes('EDIT')),
                description:state.basicData.description,
                collectionId:state.basicData.id,
                collectionName:state.basicData.name,
                collectionShortName:state.basicData.short_name,
                collectionType:state.basicData.category,
                properties:state.basicData.properties.map((el,index)=>({...el,position:index+1})),
                idSeq:state.basicData.id_seq,
                enableDQ:state.basicData.enable_dq_dashboarding,
              }}
              onActiveStepChange={()=>{
                let el = document.getElementById('edit-collecion-modal-container');
                el && el.scrollTo(0,0)
              }}
              onSave={()=>{
                fetchList();
                setEditModalOpen(false)
              }}
              onCancel={()=>{setEditModalOpen(false)}}
            />
          </div>
        </Modal>
      }
      
    </div>
  )

}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
    permissions: state.auth.permissions
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(CollectionProfile)));
