import React, { useState, useEffect, useRef } from 'react';
import { withTheme, withStyles, Modal, Paper, Typography, CircularProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../axios-cerebrum'
import { getLabelPlural, mapObjectName, toTitleCase, mapSearchObjectName, sendMessage } from '../../../utilities';
import ModalAlert from '../ModalAlert/ModalAlert';
import '@toast-ui/editor/dist/toastui-editor-viewer';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';
import timezoneDetect from 'jstimezonedetect';
import UpdateInputCerebrum from '../UpdateInput/UpdateInputCerebrum';
import SourceDescription from '../SourceDescription/SourceDescription';
import { removeMarkDown } from '../InteractiveInput/Templates';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  header: {
    fontSize: 20,
    color:theme.palette.header.main,
    marginBottom:24
  },
  inputBlock:{
    marginBottom:40,
    display:'flex',
    alignItems:'flex-start'
  },
  title:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    width:120,
    marginRight:40,
    flexGrow:0,
    flexShrink:0
  },
  inputBase:{
    ...theme.components.inputBase,
    flexGrow:1,
    width:'100%',
    '& textarea':{
      paddingTop:12,
      paddingBottom:12,
      paddingLeft:16
    }
  },
  helperText:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    marginLeft:16
  },
})

function ResultItemEditor(props) {

  const {
    theme,
    classes,
    modalOpen,
    setModalOpen,
    setDescription,
    item,
    dispTitle
  } = props;


  const [alertOpen, setAlertOpen] = useState();
  const [alertMessage, setAlertMessage] = useState('');
  // const MDRef = useRef()

  // const [descriptionValue, setDescriptionValue] = useState('')

  const [cerebrumItem, setCerebrumItem] = useState()
  const [itemLoading, setItemLoading] = useState(false)
  const [itemError, setItemError] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

 
  
  useEffect(()=>{
    if(modalOpen){
      setCerebrumItem()
      setItemLoading(true)
      setItemError(false)
      axiosCerebrum
        .get(`/api/${getLabelPlural(mapSearchObjectName(item.object_type_txt,item.code_type_txt))}/${item.id}`)
        .then(response=>{
          setItemLoading(false)
          // setDescriptionValue(response.data.description)
          setCerebrumItem(response.data)
        })
        .catch(error=>{
          console.log(error)
          setItemLoading(false)
          setItemError(true)
        })
    }
  // eslint-disable-next-line
  },[modalOpen])

  return (
    <Modal 
      open={modalOpen} 
      onClose={()=>setModalOpen(false)}
      disableBackdropClick={true}
    > 
      <div style={{margin:'15vh auto 0 auto',width:800}}>
        <Paper style={{width:752,maxHeight:'80vh',overflow:'auto',padding:24,paddingBottom:12,background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}} data-test-id="inline-description-editor">
          <div style={{display:'flex',alignItems:'flex-start'}}>
            <Typography className={classes.header}>Edit description for {dispTitle} {toTitleCase(mapObjectName(item.object_type_txt).replace(/_/g,' '))}</Typography>
          </div>
          {
            itemLoading && 
            <div style={{textAlign:'center',marginTop:32}}><CircularProgress color='secondary'/></div>
          }
          {
            itemError && 
            <Typography 
              style={{color:theme.palette.primaryText.main}}
            >
              Error occurred loading editor
            </Typography>
          }
          {
            cerebrumItem && 
            <div>
              <UpdateInputCerebrum
                url={`/api/${getLabelPlural(cerebrumItem.object.name)}/${cerebrumItem.id}?tz=${timezoneDetect.determine().name()}`}
                label={cerebrumItem.object.name.toLowerCase()}
                initialValue={cerebrumItem.description}
                property="description"
                placeholder="Add a description"
                title={<Typography color='primary' style={{fontSize:12,letterSpacing:2}}>DESCRIPTION</Typography>}
                hideSubtitle={true}
                multiline
                data={cerebrumItem}
                onSaveEdit={(data)=>{
                  setDescription(removeMarkDown(data.description))
                  setModalOpen(false)
                  sendAlert({ message: `Successfully updated ${dispTitle} ${toTitleCase(mapObjectName(item.object_type_txt).replace(/_/g,' '))} description`, type: 'info' })
                }}
                fetchList={(data)=>{}}
                enableMarkDown={true}
                enableWidget={true}
                state={{}}
                defaultEditing={true}
                alwaysShowFooter={true}
                buttonAlignment={'flex-end'}
                onCancelEdit={()=>{
                  setModalOpen(false)
                }}
                onModalClose={()=>{
                  setModalOpen(false)
                }}
                onError={msg=>{
                  setAlertMessage(msg)
                  setAlertOpen(true)
                  sendMessage({[`open-editor-${cerebrumItem.id}`]:true})
                }}
                customAddOnComponent={
                  <div style={{marginTop:16}}>
                    <SourceDescription
                      titleStyle={{fontSize:12,letterSpacing:2,marginBottom:8}}
                      state={{basicData:cerebrumItem}}
                      overrideOnApplyFunc={value=>{
                        sendMessage({[`set_text_description_${cerebrumItem.id}`]:value})
                      }}
                      noMargin={true}
                    />
                  </div>
                }
              />
            </div>
          }
          {/* <div style={{display:'flex',justifyContent:'flex-end',marginRight:-12,marginBottom:-12}}>
            <Button color='primary' disabled={!cerebrumItem} onClick={()=>onSave()} style={{marginRight:8}}>SAVE</Button>
            <Button color='secondary' onClick={()=>setModalOpen(false)}>CANCEL</Button>
          </div> */}
        </Paper>
        <ModalAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          type='error'
        />
      </div>
    </Modal>
  )

}

ResultItemEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  setDescription: PropTypes.func.isRequired,
  initialDescription: PropTypes.string.isRequired,
  dispTitle: PropTypes.string.isRequired,
}

export default withTheme()(withStyles(styles)(ResultItemEditor));