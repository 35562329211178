import React,{useEffect} from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Select, MenuItem, Button} from '@material-ui/core';
import axiosCerebrum from '../../../../axios-cerebrum'
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import fileDownloader from 'js-file-download';
import timezoneDetect from 'jstimezonedetect';
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  subtitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  selectorHeader:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.overline.main,
    marginBottom:8
  },
  selector:{
    ...theme.components.selector,
    width:160,
    height:48
  },
  timeSelector:{
    ...theme.components.inputBase,
    '& div':{
      backgroundColor:'transparent',
    },
    '& label':{
      color:theme.palette.primaryText.light
    },
    '& input':{
      color:theme.palette.primaryText.main,
      padding:'15px 16px 15px'
    }
  },
  suggestionChip:{
    ...theme.components.suggestionChip
  },
  suggestionHeader:{
    fontSize:12,
    letterSpacing:2,
    marginTop:16,
    marginBottom:8,
    color:theme.palette.primaryText.light
  }
})

function ExtractDownloader(props) {

  const {
    classes,
    state,
    dispatch,
  } = props;

  const isCancelledRef = React.useRef(false);

  const {
    sendAlert
  } = useAlert({
    id:`add-knowledge`,
    isCancelledRef
  })

  useEffect(()=>{
    if(!state.surveyTemplates && !state.surveyTemplatesLoading){
      dispatch({
        type:'set_survey_templates',
        surveyTemplatesLoading:true
      })
      axiosCerebrum
        .get(`/api/surveytemplates`,{params:{per_page:20}})
        .then(response=>{
          dispatch({
            type:'set_survey_templates',
            surveyTemplates:response.data.items
          })
        })
        .catch(error=>{
          console.log(error)
          dispatch({
            type:'set_survey_templates',
            surveyTemplatesError:true
          })
        })
    }
    return () => {
      isCancelledRef.current = true;
    }
  // eslint-disable-next-line
  },[])


  const onSurveyObjectTypeChange = value => {
    dispatch({
      type:'set_extract_filters',
      extractFilters:{...state.extractFilters,objectType:value}
    })
  }

  const onSurveyTemplateChange = (value) => {
    dispatch({
      type:'set_extract_filters',
      extractFilters:{...state.extractFilters,surveyTemplate:value,objectType:'none'}
    })
    if(value!=='none'){
      axiosCerebrum
        .get(`/api/surveys/attributes`,{params:{
          attribute:'object_type',
          survey_template_id:value
        }})
        .then(response=>{
          dispatch({
            type:'set_extract_valid_object_types',
            extractValidObjectTypes:response.data.items
          })
        })
        .catch(error=>{
          console.log(error)
        })
    }else{
      dispatch({
        type:'set_extract_valid_object_types'
      })
    }

  }


  const onStartDateChange = value => {
    dispatch({
      type:'set_extract_filters',
      extractFilters:{...state.extractFilters,start_at:value}
    })
  }

  const onSurveyCategoryChange = value => {
    dispatch({
      type:'set_extract_filters',
      extractFilters:{...state.extractFilters,surveyCategory:value,objectType:'none',surveyTemplate:'none'}
    })
  }

  const onEndDateChange = value => {
    dispatch({
      type:'set_extract_filters',
      extractFilters:{...state.extractFilters,end_at:value}
    })
  }

  const submitExtract = (payload, fileName, setIsDownloading) => {
    setIsDownloading(true)
    axiosCerebrum.post(
      '/api/extracts',payload
    ).then(extractResponse=>{
      sendAlert({type:'info',message:'Result is being generated. This may take a few minutes.'})
      let extractId = extractResponse.data.id;
      let downloaded = false;
      let previousReturned = true;
      let recursionTimes = 0;
  
      const onError = (interval) => {
        clearInterval(interval);
        sendAlert({type:'error',message:'Error occurred downloading the file'})
        setIsDownloading(false)
      }
  
      let downloadExtractInterval = setInterval(()=>{
        if(!previousReturned)return;
        if(recursionTimes>200){
          onError(downloadExtractInterval)
          return;
        }
        recursionTimes += 1;
        previousReturned=false;
        axiosCerebrum.get(`/api/extracts/${extractId}`).then(response=>{
          previousReturned = true;
          if(response.data.status==='COMPLETE'){
            if(downloaded)return;
            downloaded = true;
            axiosCerebrum.get(`/api/extracts/${extractId}/download`)
              .then(downloadRes=>{
                clearInterval(downloadExtractInterval);
                fileDownloader(downloadRes.data.replace(/\|/g,','), `${fileName}.csv`);
                setIsDownloading(false)
              })
              .catch(error=>{
                console.log(error)
                onError(downloadExtractInterval);
              }) 
          }
          if(response.data.status==='FAILED'){
            onError(downloadExtractInterval);
          }
        }).catch(error=>{
          console.log(error);
          onError(downloadExtractInterval);
          previousReturned = true;
        })
      },2000)
    })
    .catch(error=>{
      console.log(error);
      sendAlert({type:'error',message:'Error occurred downloading the file'})
    })
  }

  const onDownloadExtract = () => {
    let filters, fileName;
    filters =  {
      start_at:moment(state.extractFilters.start_at).format('YYYY-MM-DD'),
      end_at:moment(state.extractFilters.end_at).format('YYYY-MM-DD'),
      survey_template_id:state.extractFilters.surveyTemplate,
      object_type_id:state.extractFilters.objectType,
      tz:timezoneDetect.determine().name()
    }
    fileName=`K_survey_results_from_${moment(state.extractFilters.start_at).format('DDMMYYYY')}_to_${moment(state.extractFilters.end_at).format('DDMMYYYY')}`
    let payload = {
      type:'EXTRACTS',
      domain:document.location.protocol + "//" + document.location.hostname,
      lookup_code:'0220',
      filters
    }
  
    submitExtract(payload,fileName, value=>dispatch({type:'set_extract_downloading',extractDownloading:value}))
  }

  if(state.surveyTemplatesLoading)return <CircularProgress style={{marginTop:24}} color='secondary'/>
  if(state.surveyTemplatesError || !state.surveyTemplates)return <div></div>

  let categories = [];
  state.surveyTemplates.forEach(el=>{
    if(!categories.includes(el.category)){
      categories.push(el.category)
    }
  })

  const setStartDateByDiff = days => {
    dispatch({
      type:'set_extract_filters',
      extractFilters:{...state.extractFilters,start_at:moment().add(-days,'days'),end_at:moment()}
    })
  }

  return (
    <div style={{marginTop:40}}>
      <Typography className={classes.title}>SURVEY RESPONSES</Typography>
      <Typography className={classes.subtitle}>Download and review responses from users. Select a survey type, survey, object type and a date range for responses</Typography>
      <div style={{display:'flex',alignItems:'flex-end'}}>
        <div style={{marginRight:24}}>
          <Typography className={classes.selectorHeader}>SELECT A SURVEY</Typography>
          <div style={{display:'flex'}}>
            <Select
              className={classes.selector}
              style={{marginRight:24}}
              value={state.extractFilters.surveyCategory || 'none'}
              onChange={event=>onSurveyCategoryChange(event.target.value)}
            >
              <MenuItem value={'none'}>Select Category</MenuItem>
              {
                categories.map(el=>(
                  <MenuItem key={el} value={el}>{el}</MenuItem>
                ))
              }
            </Select>
            <Select
              className={classes.selector}
              style={{marginRight:24,width:240}}
              value={state.extractFilters.surveyTemplate || 'none'}
              onChange={event=>onSurveyTemplateChange(event.target.value)}
            >
              <MenuItem value={'none'}>Select Survey</MenuItem>
              {
                state.extractFilters.surveyCategory && state.surveyTemplates.filter(el=>el.category===state.extractFilters.surveyCategory).map(el=>(
                  <MenuItem key={el.name} value={el.id}>{el.name}</MenuItem>
                ))
              }
            </Select>
            <Select
              className={classes.selector}
              value={state.extractFilters.objectType || 'none'}
              onChange={event=>onSurveyObjectTypeChange(event.target.value)}
            >
              <MenuItem value={'none'}>
                {
                  state.extractValidObjectTypes && state.extractValidObjectTypes.length===0 ?
                  'No objects found for this survey':
                  'Select Object'
                }
              </MenuItem>
              {
                state.extractValidObjectTypes && state.extractValidObjectTypes.map(el=>(
                  <MenuItem key={el.id} value={el.id}>{el.value}</MenuItem>
                ))
              }
            </Select>
          </div>
          
        </div>
        <div>
          <Typography className={classes.selectorHeader}>SELECT USAGE PERIOD</Typography>
          <div style={{display:'flex'}}>
            <div style={{width:160,marginRight:24}}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  autoOk
                  className={classes.timeSelector}
                  style={{margin:0}}
                  format='ll'
                  maxDate={state.extractFilters.end_at}
                  value={state.extractFilters.start_at||null}
                  emptyLabel="From"
                  onChange={value=>onStartDateChange(value)}
                  variant="filled"
                />
              </MuiPickersUtilsProvider>
            </div>
            <div style={{width:160}}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  autoOk
                  className={classes.timeSelector}
                  style={{margin:0}}
                  format='ll'
                  minDate={state.extractFilters.start_at}
                  value={state.extractFilters.end_at||null}
                  emptyLabel="To"
                  onChange={value=>onEndDateChange(value)}
                  variant="filled"
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>
        {
          state.extractDownloading && 
          <CircularProgress style={{width:24,height:24,marginBottom:12,marginLeft:40}} color='secondary'/>
        }
        {
          !state.extractDownloading &&
          <Button 
            onClick={()=>onDownloadExtract()} 
            color='primary' 
            variant="contained" 
            style={{marginBottom:8,marginLeft:24}} 
            disabled={!state.extractFilters.objectType || state.extractFilters.objectType==='none' || !state.extractFilters.end_at || !state.extractFilters.start_at}
          >
            DOWNLOAD
          </Button>
        }
        
      </div>
      <div style={{marginLeft:640}}>
        <Typography className={classes.suggestionHeader}>SUGGESTIONS</Typography>
        <div style={{display:'flex'}}>
          <div className={classes.suggestionChip} onClick={()=>setStartDateByDiff(7)}>Last 7 days</div>
          <div className={classes.suggestionChip} onClick={()=>setStartDateByDiff(30)}>Last 30 days</div>
          <div className={classes.suggestionChip} onClick={()=>setStartDateByDiff(90)}>Last 90 days</div>
        </div>
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(ExtractDownloader));