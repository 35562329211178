import React, { useState,  useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles } from '@material-ui/core';
import {withRouter} from 'react-router'
import '@toast-ui/editor/dist/toastui-editor-viewer';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';
import { Editor as MDEditor } from '@toast-ui/react-editor';
import { clearAllPatterns, getAllRules } from './Templates';
import TextMenu from '../ContextMenu/TextMenu';
import { checkClassName } from '../Editor/utils';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  container:{
    '& .toastui-editor-defaultUI':{
      padding:1
    }
  },
  highlightBorder:{
    '& .toastui-editor-defaultUI':{
      borderColor:theme.palette.error.main,
    }
  }
})

function InteractiveInputBody(props) {

  const {
    classes,
    theme,
    initialValue,
    onChange,
    triggerChar,
    generatePopper,
    resetFlag,
    placeholder,
    disableWidget,
    isHighlight,
    height,
    object,
    enableCustomContextMenu
  } = props;


  const MDRef = useRef()
  const containerRef = useRef()
  const [popperAnchor, setPopperAnchor] = useState()
  const [popperEl, setPopperEl] = useState()
  const [offset, setOffset] = useState({x:0,y:0})
  const [contextMenuOpen, setContextMenuOpen] = useState(false)

  const {
    sendAlert
  } = useAlert({})

  useEffect(()=>{
    if(containerRef.current){
      let rect = containerRef.current.getBoundingClientRect()
      setOffset({
        x: rect.x + 8 ,
        y: rect.y + 12,
      })
    }
  // eslint-disable-next-line
  },[containerRef, containerRef.current, popperAnchor])

  useEffect(()=>{
    if(!popperAnchor){
      window.onkeydown = undefined;
    }
  },[popperAnchor])
  
  return (
    <div 
      ref={containerRef}
      className={classes.container + (isHighlight?' '+classes.highlightBorder:'')} 
      data-test-classname={`md-input-eidtor`}
      onMouseUp={(event)=>{
        if(!enableCustomContextMenu)return;
        event.preventDefault();
        if(!MDRef.current || !MDRef.current.getSelectedText() || contextMenuOpen)return;
        setContextMenuOpen({x:event.clientX - 20, y:event.clientY+20})
      }}
      onMouseDown={(event)=>{
        if(checkClassName(event.target,'text-context-menu'))return;
        setTimeout(()=>{
          setContextMenuOpen(false);
        })
      }}
    >
      <div style={{position:'absolute', zIndex:888,background:theme.palette.background.main,visibility:popperAnchor?'visible':'hidden',marginLeft:offset.x,marginTop:offset.y, top:popperAnchor&&popperAnchor.y, left: popperAnchor && popperAnchor.x}} >
        {popperEl}
      </div>
      <MDEditor
        onLoad={instance => {MDRef.current = instance}}
        key={resetFlag}
        autofocus={false}
        placeholder={placeholder || (disableWidget?'':"Tip: Type / to add a link to a term or collection instance")}
        onKeyup={(type, event )=>{
          if(disableWidget)return;
          if(event.key===triggerChar){
            let el = document.createElement('div')
            el.style.zIndex = 300
            el.style.height = 0;
            el.style.width = 0;
            MDRef.current.addWidget(el, 'top')
            setPopperAnchor({x:el.style.left,y:el.style.top})
            setPopperEl(generatePopper({editorRef: MDRef.current, setPopperAnchor,}))
          }
          // else{
            // setPopperAnchor()
            // setPopperEl()
          // }
          if(['Backspace',' '].includes(event.key)){
            setPopperAnchor()
            setPopperEl()
          }
        }}
        height={height || '172px'}
        hideModeSwitch
        onChange={()=> onChange(MDRef.current.getMarkdown())}
        initialValue={initialValue}
        initialEditType="wysiwyg"
        useCommandShortcut={true}
        previewStyle="vertical"
        theme={localStorage.hasOwnProperty('dark')?"dark":"white"}
        usageStatistics={false}
        widgetRules={disableWidget?[]:getAllRules()}
        toolbarItems={
          [
            ['heading', 'bold', 'italic', 'strike'],
            ['hr', 'quote'],
            ['ul', 'ol', 'indent', 'outdent'],
            ['table','link'],
            ['code', 'codeblock']
          ]
        }
      />
      {
        contextMenuOpen && object && 
        <TextMenu
          object={object}
          open={contextMenuOpen}
          setOpen={setContextMenuOpen}
          anchor={contextMenuOpen}
          onPaste={
            window.isSecureContext?
            async ()=>{
              // get text from clipboard
              let text = await navigator.clipboard.readText();
              if(!text){
                sendAlert({message:"Your clipboard is empty",type:'info'})
                return;
              }
              MDRef.current.replaceSelection(text);
            }:undefined
          }
          text={MDRef.current?clearAllPatterns(MDRef.current.getSelectedText().trim()):''}
        />
      }
    </div>
  )
}


InteractiveInputBody.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  initialValue:PropTypes.string.isRequired,
  onChange: PropTypes.string.isRequired,
  triggerChar: PropTypes.string.isRequired,
  generatePopper: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disableWidget: PropTypes.bool,
  isHighlight: PropTypes.bool,
  height: PropTypes.string,
  object: PropTypes.object,
  enableCustomContextMenu: PropTypes.bool,
}

export default withTheme()(withStyles(styles)(withRouter(InteractiveInputBody)));