import React, { useEffect, useState, useRef } from 'react';
import { withTheme, withStyles, Drawer, Typography, CircularProgress, IconButton, Divider, Radio, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, } from '@material-ui/core';
import {  getDispFields, getIconComponent, onClickResultItem } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum'
import axiosSolr from '../../../axios-solr'
import CodeDisplay from '../CodeDisplay/CodeDisplay';
import sqlFormatter from "sql-formatter";
import KTooltip from '../KTooltip/KTooltip';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDown';
import ProfileSideBar from '../ProfileSideBar/ProfileSideBar';
import { useStore } from 'react-redux';
import { graphPalette } from '../Lineage/utils';
import useAlert from '../../../hooks/useAlert';
import DrawerDragTrigger from '../DrawerDragTigger/DrawerDragTrigger';

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.primary.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  openProfileButton:{
    flexShrink:0,
    marginRight:24,
    background:'#00D46A',
    color:'#000',
    width:150,
    height:36,
    marginLeft:16,
    '&:hover':{
      background:'#00D46A',
      color:'#000',
    }
  },
  listItem:{
    width:'100%',
    display:'flex',
    alignItems:'center',
    height:47,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  subtitle:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  columnBoxContainer:{
    width:'100%',
    borderRadius:4,
    paddingBottom:6,
    border:`1px solid ${theme.palette.primary.main}`,
  },
  tableBox:{
    display:'flex',
    overflow:'hidden',
    alignItems:'center',
    borderRadius:4,
  },
  columnBox:{
    display:'flex',
    overflow:'hidden',
    alignItems:'center',
    paddingTop:6,
    borderRadius:4,
    paddingBottom:6,
    cursor:'pointer',
    paddingLeft:8
    // '&:hover':{
    //   filter: 'brightness(150%)'
    // }
  },
  columnBoxTitle:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginRight:8
  },
  schemaBoxTitle:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  columnBoxSubtitle:{
    fontSize:12,
    color:theme.palette.background.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  customScroll:{
    '&::-webkit-scrollbar':{
      width: '0px'
    },
  },
  tooltip:{
    fontSize:13.75,
    maxWidth:'90vw'
  },
  viewButton:{
    color:'#F60257',
    borderColor:'#F60257',
    height:32
  },
  schemaBoxContainer:{
    borderRadius:6,
    background:theme.palette.kChartGroupBackground.main,
    border:`1px solid ${theme.palette.primary.main}`,
    width:220,
    minWidth:0,
    padding:'12px 16px',
    flexShrink:1,
    flexGrow:1,
    boxSizing:'border-box'
  },
  drawerPaper:{
    borderLeft:`1px solid ${theme.palette.listItemDivider.main}`
  },

   /////////////////////////
  // expansion panel styles
  expansionTitle:{
    fontSize:16,
    paddingLeft:16,
    color:theme.palette.header.main
  },
  expansionPanel:{
    marginBottom:24,
    paddingBottom:0,
    borderRadius:`6px !important`,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    '&::before': {
      background: 'none'
    },
  },
  expansionSummary:{
    padding:0,
    width:'100%',
    // minHeight: 64,
  },
  expanded:{
    margin:0
  },
  summaryRoot:{
    // background:theme.palette.background.main,
    borderBottom: '0',
    // height: 64,
    padding: 0,
    borderRadius:6,
    overflow:'hidden',
    margin: 0,
    '&$expanded': {
      // height:64,
      padding: 0,
    },
  },
  expansionPanelDetails:{
    flexDirection:'column',
    background:theme.palette.background.main,
    paddingLeft:16,
    paddingRight:16,
    paddingTop:0,
    paddingBottom:0,
    borderRadius:12
  },
})


function LineageDetailDrawer(props) {
  const {
    classes,
    history,
    theme,
    drawerOpen,
    setDrawerOpen,
    selectedItemGroup,
  } = props;

  const [selectedJoinType, setSelectedJoinType] = useState();
  const [selectedCode,setSelectedCode] = useState();
  const [joinsList, setJoinsList] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [joinTypeExpanded, setJoinTypeExpanded] = useState(false)
  const [joinDetailsExpanded, setJoinDetailsExpanded] = useState(true)
  const [codeDetailsExpanded, setCodeDetailsExpanded] = useState(true)

  let initialWidth = 600;
  try{
    if(localStorage.getItem('joinsDrawerWidth')){
      initialWidth = Number(localStorage.getItem('joinsDrawerWidth')).valueOf() || 600;
    }
  }catch{}

  const [paperWidth, setPaperWidth] = useState(initialWidth)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const store = useStore();
  let sessionData = store.getState().auth.session_user;

  const targetItem = selectedItemGroup.target;
  const selectedItem = selectedItemGroup.selected;
  
  useEffect(()=>{
    if(!drawerOpen){
      setSelectedCode();
      setSelectedJoinType();
      setJoinsList();
    }
    // selected item will have codeComponents field which is a list of code component object. 
    if(drawerOpen && selectedItem && targetItem ){
      setTimeout(() => {
        const loadJoins = async () => {
          if(loading)return;
          setLoading(true);
          setSelectedCode();
          setSelectedJoinType();
          setJoinsList();
          setError(false);
          try{
            let validCodes = selectedItem.codeComponents.filter(el=>el.linkedTableIds.includes(selectedItem.id)&&el.linkedTableIds.includes(targetItem.id))
            let joinsListTmp = {meta:[], joinsTypes:[]};
            for(let i=0; i<validCodes.length; i++){
              let codeComponent = validCodes[i];
  
              let columnGroups = [];
              let columnList = [];
              await axiosCerebrum
                .get(`/api/codecomponents/${codeComponent.id}/related`,{params:{relationship:'OUTPUT',object_name:'COLUMN',per_page:10}})
                .then(async response=>{
                  columnList = response.data.items;
                })
              await axiosCerebrum
                .get(`/api/codecomponents/${codeComponent.id}/related`,{params:{relationship:'INPUT_OF',object_name:'COLUMN',per_page:10}})
                .then(async response=>{
                  columnList = [...columnList, ...response.data.items]
                })
              if(columnList.length>0){
  
                let targetObj;
                await axiosSolr
                  .get(
                    `/solr/search/select`,
                    {params:{
                      q:"*",
                      fq:`id:${targetItem.id}`,
                      fl:'labels:object_type_txt,id,name_txt,table:table_txt,location_txt,schema_txt,source_txt,source_type_txt,database_txt,trust_srt'
                    }}
                  )
                  .then(response=>{
                    targetObj = response.data.response.docs[0];
                  })
                await axiosSolr
                  .get(
                    `/solr/search/select`,
                    {params:{
                      q:"*",
                      fq:`id:(${columnList.map(el=>el.id).join(' OR ')})`,
                      fl:'labels:object_type_txt,id,name:name_txt,table:table_txt,trust_srt'
                    }}
                  )
                  .then(response=>{
                    columnList = response.data.response.docs;
                  })
                columnGroups = [
                  {
                    table:targetObj,
                    columns:columnList.filter(el=>el.table && el.table.toUpperCase()===targetObj.name_txt.toUpperCase()).sort((a,b)=>a.name<b.name?-1:1)
                  },
                  {
                    table:selectedItem.obj,
                    columns:columnList.filter(el=>el.table && el.table.toUpperCase()===selectedItem.obj.name_txt.toUpperCase()).sort((a,b)=>a.name<b.name?-1:1)
                  }
                ];
                
              }
              // get joins code
              if(codeComponent.type==='JOIN'){
                if(!joinsListTmp.joinsTypes.find(el=>el.id===codeComponent.sub_type)){
                  joinsListTmp.joinsTypes.push({id:codeComponent.sub_type, name:codeComponent.sub_type, code:[]});
                }
                await axiosCerebrum
                  .get(`/api/codecomponents/${codeComponent.id}/related`,{params:{relationship:'REFERENCED_BY',per_page:10,sort:'USAGE_DESC',object_name:'QUERY'}})
                  .then(async response=>{
                    if(response.data.items.length>0){
                      await axiosCerebrum
                        .get(`/api/queries/${response.data.items[0].id}`)
                        .then(async query=>{
                          let solrDetail = {}
                          await axiosSolr
                            .get(`/solr/search/select`,{params:{
                              q:"*",
                              fq:`id:${query.data.id}`,
                              rows:1,
                            }})
                            .then(solrRes=>{
                              solrDetail = solrRes.data.response.docs[0] || {};
                            }) 
                          if(query.data && !joinsListTmp.joinsTypes.find(el=>el.id===codeComponent.sub_type).code.find(el=>el.id===query.data.id)){
                            joinsListTmp.joinsTypes.find(el=>el.id===codeComponent.sub_type).code.push({...query.data,...solrDetail,columnGroups})
                          }
                        })
                    }
                  })
              }
              // get meta
              if(codeComponent.type==='KEY' && codeComponent.sub_type==='FOREIGN' && !joinsListTmp.meta.find(m=>m.id===codeComponent.id)){
                joinsListTmp.meta.push({columnGroups,id:codeComponent.id,name:'Foreign Key'})
              }
            }
            joinsListTmp.joinsTypes = joinsListTmp.joinsTypes.sort((a,b)=>b.code.length-a.code.length)
            setJoinsList(joinsListTmp);
            if(joinsListTmp.meta.length>0 || joinsListTmp.joinsTypes.length>0){
              setSelectedJoinType([...joinsListTmp.meta,...joinsListTmp.joinsTypes][0].id)
            }
            if(joinsListTmp.meta.length===0 && joinsListTmp.joinsTypes.length>0)setSelectedCode(joinsListTmp.joinsTypes[0].code[0].id)
            setLoading(false);
          }catch(error){
            console.log(error);
            setLoading(false);
            setError(true)
          }
        }
        loadJoins();
      }); 
    }
  // eslint-disable-next-line
  },[selectedItem,drawerOpen])

  const onSelectJoinsType = (type) => {
    if(type===selectedJoinType)return;
    setJoinTypeExpanded(false)
    setSelectedJoinType(type);
    if(joinsList.joinsTypes.find(el=>el.id===type))setSelectedCode(joinsList.joinsTypes.find(el=>el.id===type).code[0].id)
    else{setSelectedCode()}
  }

  useEffect(() => {
    const handleEsc = (event) => {
      // check if key is 'ESC`
      if (event.key==='Escape') {
        setDrawerOpen(false)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    // eslint-disable-next-line
  }, [])
  

  const onCopyCode = async () => {
    try{
      if(navigator.clipboard){
        await navigator.clipboard.writeText(sqlFormatter.format(selectedCodeData.code,{language:'pl/sql'}));
      }
    }catch{
      sendAlert({
        message: "Error occurred copying the code, please try again",
        type: 'error'
      })
      return;
    }
    sendAlert({
      message: "Copied code to clipboard",
      type: 'info'
    })
  }

  const onCloseDrawer = ()  => {
    setDrawerOpen(false);
  }

  let selectedCodeData;
  if(joinsList && selectedJoinType && selectedCode){
    selectedCodeData = joinsList.joinsTypes.find(el=>el.id===selectedJoinType).code.find(el=>el.id===selectedCode);
  }
  if(joinsList && selectedJoinType && joinsList.meta.find(el=>el.id===selectedJoinType)){
    selectedCodeData = joinsList.meta.find(el=>el.id===selectedJoinType);
  }

  
  const getPorcessedCode = code => {
    let formattedCode = sqlFormatter.format(code,{language:'pl/sql'});
    let lines = formattedCode.split('\n');
    return {value:lines.length>100?lines.slice(0,100).join('\n')+"\n...":lines.join('\n'),isOverflow:lines.length>100}
  }
  
  return (
    <Drawer anchor="right" open={drawerOpen} onClose={()=>onCloseDrawer()} classes={{paper:classes.drawerPaper}} variant="persistent">
      {
        selectedItem && targetItem && 
        <>
          <DrawerDragTrigger
            onChangeWidthOffset={diff=>{
            setPaperWidth(paperWidth+diff)
            localStorage.setItem('joinsDrawerWidth',paperWidth+diff)}}
          />
          <div style={{height:'100vh',width:paperWidth,minWidth:500,maxWidth:'90vw',display:'flex',flexDirection:'column',background:theme.palette.background.main,padding:24,boxSizing:'border-box'}}>

            <div style={{display:'flex',marginBottom:16,flexGrow:0,alignItems:'flex-start',overflow:"hidden"}}>
              <div style={{overflow:"hidden",flexGrow:1}}>
                <Typography className={classes.title}>Table {targetItem.name} to Table {selectedItem.label} join</Typography>
              </div>
              <IconButton 
                onClick={()=>onCloseDrawer()} 
                style={{marginLeft:8,width:36,height:36}}
              >
                {
                  getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})
                }
              </IconButton>
            </div>

            <div className={classes.customScroll} >
              {
                loading && <CircularProgress color='secondary'/>
              }
              {
                error && <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading code</Typography>
              }
              {
                joinsList && joinsList.joinsTypes.length===0 && joinsList.meta.length===0 &&
                <Typography style={{color:theme.palette.primaryText.main}}>No joins detail found</Typography>
              }
              {
                joinsList && joinsList.joinsTypes.length>0 &&
                <div style={{marginBottom:40}}>
                  <ExpansionPanel onChange={(event,expanded)=>setJoinTypeExpanded(expanded)} expanded={joinTypeExpanded} elevation={0} className={classes.expansionPanel } >
                    <ExpansionPanelSummary onClick={undefined} classes={{expanded: classes.expanded,root:classes.summaryRoot}}  expandIcon={<ExpandMoreIcon style={{color:theme.palette.primaryText.main}} />}  className={classes.expansionSummary}>
                      <Typography className={classes.expansionTitle}>
                        Join Type{joinTypeExpanded?"":`: ${[...(joinsList?.meta||[]),...(joinsList?.joinsTypes||[])].find(el=>el.id===selectedJoinType)?.name}`}
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                      <div style={{marginBottom:16}}>
                        {
                          joinsList && joinsList.meta.length>0 && joinsList.meta.map(el=>(
                            <div onClick={()=>onSelectJoinsType(el.id)} className={classes.listItem} style={{background:el.id===selectedJoinType?theme.palette.hovered.main:undefined}}>
                              <Radio style={{color:theme.palette.primaryText.light}} checked={el.id===selectedJoinType}/>
                              <Typography style={{color:theme.palette.primaryText.main,fontSize:13.75}}>
                                {el.name}
                              </Typography>
                            </div>
                          ))
                        }
                        {
                          joinsList && joinsList.joinsTypes.length>0 && joinsList.joinsTypes.map(el=>(
                            <div onClick={()=>onSelectJoinsType(el.id)} className={classes.listItem} style={{background:el.id===selectedJoinType?theme.palette.hovered.main:undefined}}>
                              <Radio style={{color:theme.palette.primaryText.light}} checked={el.id===selectedJoinType}/>
                              <Typography style={{color:theme.palette.primaryText.main,fontSize:13.75}}>
                                {el.name} ({el.code.length}) 
                              </Typography>
                            </div>
                          ))
                        }
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              }
              

              {
                // details section
                joinsList && 
                <div style={{marginBottom:40}}>
                  <ExpansionPanel onChange={(event,expanded)=>setJoinDetailsExpanded(expanded)} expanded={joinDetailsExpanded} elevation={0} className={classes.expansionPanel } >
                    <ExpansionPanelSummary onClick={undefined} classes={{expanded: classes.expanded,root:classes.summaryRoot}}  expandIcon={<ExpandMoreIcon style={{color:theme.palette.primaryText.main}} />}  className={classes.expansionSummary}>
                      <Typography className={classes.expansionTitle}>
                        Join details
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                      {
                        selectedCodeData &&
                        <div style={{display:'flex',marginTop:12,justifyContent:'center',alignItems:'center',marginBottom:16}}>
                          {
                            selectedCodeData.columnGroups[0] &&
                            <div className={classes.schemaBoxContainer}>
                              <div style={{display:'flex',alignItems:'center',overflow:'hidden',marginBottom:8,paddingRight:8}}>
                                <div style={{width:20,height:20,marginLeft:8,marginRight:8}}>{getIconComponent({label:'schema',size:20,colour:theme.palette.primary.main})}</div>
                                <div style={{overflow:'hidden',flexGrow:1}}>
                                  <KTooltip title={selectedCodeData.columnGroups[0].table.schema_txt} classes={{tooltip:classes.tooltip}}>
                                    <Typography className={classes.schemaBoxTitle} style={{color:theme.palette.primaryText.main}}>{selectedCodeData.columnGroups[0].table.schema_txt}</Typography>
                                  </KTooltip>
                                  <KTooltip title={`${selectedCodeData.columnGroups[0].table.database_txt} (${selectedCodeData.columnGroups[0].table.source_txt})`} classes={{tooltip:classes.tooltip}}>
                                    <Typography className={classes.schemaBoxTitle} style={{color:theme.palette.primaryText.light}}>
                                      {selectedCodeData.columnGroups[0].table.database_txt} ({selectedCodeData.columnGroups[0].table.source_txt})
                                    </Typography>
                                  </KTooltip>
                                </div>
                                {
                                  // getIconComponent({label:selectedCodeData.columnGroups[0].table.source_type_txt,size:20,colour:theme.palette.primaryText.light,defaultLabel:'database'})
                                }
                              </div>

                              <div className={classes.columnBoxContainer}>
                                <div 
                                  className={classes.tableBox} 
                                >
                                  <div style={{width:20,height:20,marginLeft:8,marginRight:8}}>{getIconComponent({label:'table',size:20,colour:graphPalette.TABLE})}</div>
                                  <div style={{overflow:'hidden',flexGrow:1,borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,padding:'6px 0'}}>
                                    <KTooltip title={getDispFields(selectedCodeData.columnGroups[0].table,'dispTitle')} classes={{tooltip:classes.tooltip}}>
                                      <Typography className={classes.columnBoxTitle} >{getDispFields(selectedCodeData.columnGroups[0].table,'dispTitle')}</Typography>
                                    </KTooltip>
                                  </div>
                                  {/* <KTooltip title={getTrustScoreBand({trustScore:selectedCodeData.columnGroups[0].table.trust_srt})} >
                                    <div style={{height:16,width:16,marginRight:8}}>
                                      {getTrustScoreIcon({trustScore:selectedCodeData.columnGroups[0].table.trust_srt,size:16})}
                                    </div>  
                                  </KTooltip> */}
                                </div>
                                {
                                  selectedCodeData.columnGroups[0].columns.length===0 &&
                                  <Typography className={classes.columnBoxSubtitle} style={{padding:'6px 0px 6px 32px'}}>No columns found</Typography>
                                }
                                {
                                  selectedCodeData.columnGroups[0].columns.map((c,index)=>(
                                    <div 
                                      className={classes.columnBox} 
                                      onClick={()=>onClickResultItem({label:'column',id:c.id,history,newWindow:true})}
                                    >
                                      <div style={{width:20,height:20,marginLeft:8,marginRight:8}}>{getIconComponent({label:'column',size:20,colour:graphPalette.COLUMN})}</div>
                                      <div style={{overflow:'hidden',flexGrow:1}}>
                                        <KTooltip title={c.name} classes={{tooltip:classes.tooltip}}>
                                          <Typography className={classes.columnBoxTitle}>{c.name}</Typography>
                                        </KTooltip>
                                      </div>
                                      {/* <KTooltip title={getTrustScoreBand({trustScore: c.trust_srt})} >
                                        <div style={{height:16,width:16,marginRight:8}}>
                                          {getTrustScoreIcon({trustScore:c.trust_srt,size:16})}
                                        </div>
                                      </KTooltip> */}
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          }
                          
                          { 
                            selectedCodeData.columnGroups.length===2 &&
                            <Divider style={{width:50,flexShrink:1,minWidth:20,marginTop:74,background:theme.palette.primaryText.light}}/>
                          }

                          {
                            selectedCodeData.columnGroups[1] &&
                            <div className={classes.schemaBoxContainer}>
                              <div style={{display:'flex',alignItems:'center',overflow:'hidden',marginBottom:8,paddingRight:8}}>
                                <div style={{width:20,height:20,marginLeft:8,marginRight:8}}>{getIconComponent({label:'schema',size:20,colour:theme.palette.primary.main})}</div>
                                <div style={{overflow:'hidden',flexGrow:1}}>
                                  <KTooltip title={selectedCodeData.columnGroups[1].table.schema_txt} classes={{tooltip:classes.tooltip}}>
                                    <Typography className={classes.schemaBoxTitle} style={{color:theme.palette.primaryText.main}}>{selectedCodeData.columnGroups[1].table.schema_txt}</Typography>
                                  </KTooltip>
                                  <KTooltip title={`${selectedCodeData.columnGroups[1].table.database_txt} (${selectedCodeData.columnGroups[1].table.source_txt})`} classes={{tooltip:classes.tooltip}}>
                                    <Typography className={classes.schemaBoxTitle} style={{color:theme.palette.primaryText.light}}>
                                      {selectedCodeData.columnGroups[1].table.database_txt} ({selectedCodeData.columnGroups[1].table.source_txt})
                                    </Typography>
                                  </KTooltip>
                                </div>
                                {
                                  // getIconComponent({label:selectedCodeData.columnGroups[0].table.source_type_txt,size:20,colour:theme.palette.primaryText.light,defaultLabel:'database'})
                                }
                              </div>
                              <div className={classes.columnBoxContainer}>
                                <div 
                                  className={classes.tableBox} 
                                >
                                  <div style={{width:20,height:20,marginLeft:8,marginRight:8}}>{getIconComponent({label:'table',size:20,colour:graphPalette.TABLE})}</div>
                                  <div style={{overflow:'hidden',flexGrow:1,borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,padding:'6px 0'}}>
                                    <KTooltip title={getDispFields(selectedCodeData.columnGroups[1].table,'dispTitle')} classes={{tooltip:classes.tooltip}}>
                                      <Typography className={classes.columnBoxTitle} >{getDispFields(selectedCodeData.columnGroups[1].table,'dispTitle')}</Typography>
                                    </KTooltip>
                                  </div>
                                  {/* <KTooltip title={getTrustScoreBand({trustScore:selectedCodeData.columnGroups[1].table.trust_srt})} >
                                    <div style={{height:16,width:16,marginRight:8}}>
                                      {getTrustScoreIcon({trustScore:selectedCodeData.columnGroups[0].table.trust_srt,size:16})}
                                    </div>  
                                  </KTooltip> */}
                                </div>
                                {
                                  selectedCodeData.columnGroups[1].columns.map((c,index)=>(
                                    <div 
                                      className={classes.columnBox}  
                                      onClick={()=>onClickResultItem({label:'column',id:c.id,history,newWindow:true})}
                                    >
                                      <div style={{width:20,height:20,marginLeft:8,marginRight:8}}>{getIconComponent({label:'column',size:20,colour:graphPalette.COLUMN})}</div>
                                      <div style={{overflow:'hidden',flexGrow:1}}>
                                        <KTooltip title={c.name} classes={{tooltip:classes.tooltip}}>
                                          <Typography className={classes.columnBoxTitle}>{c.name}</Typography>
                                        </KTooltip>
                                      </div>
                                      {/* <KTooltip title={getTrustScoreBand({trustScore: c.trust_srt})} >
                                        <div style={{height:16,width:16,marginRight:8}}>
                                          {getTrustScoreIcon({trustScore:c.trust_srt,size:16})}
                                        </div>
                                      </KTooltip> */}
                                    </div>
                                  ))
                                }
                              </div>
                            </div>
                          }
                        </div>
                      }
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              }

              {
                // code section
              }
              <div>
                {
                  joinsList && (joinsList.joinsTypes.length>0 || joinsList.meta.length>0 ) &&
                  <div style={{marginBottom:40}}>
                    <ExpansionPanel onChange={(event,expanded)=>setCodeDetailsExpanded(expanded)} expanded={codeDetailsExpanded} elevation={0} className={classes.expansionPanel } >
                      <ExpansionPanelSummary onClick={undefined} classes={{expanded: classes.expanded,root:classes.summaryRoot}}  expandIcon={<ExpandMoreIcon style={{color:theme.palette.primaryText.main}} />}  className={classes.expansionSummary}>
                        <Typography className={classes.expansionTitle}>
                          Join example
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                        <div style={{marginBottom:16}}>
                          {
                            joinsList && (!joinsList.joinsTypes.find(el=>el.id===selectedJoinType) || joinsList.joinsTypes.find(el=>el.id===selectedJoinType).code.length===0) && 
                            <Typography style={{color:theme.palette.primaryText.light}}>No code to be displayed</Typography>
                          }
                          {
                            joinsList && joinsList.joinsTypes.length>0 && selectedJoinType && joinsList.joinsTypes.find(el=>el.id===selectedJoinType) && joinsList.joinsTypes.find(el=>el.id===selectedJoinType).code.map((el)=>(
                              <div onClick={()=>setSelectedCode(el.id)} className={classes.listItem} style={{background:el.id===selectedCode?theme.palette.hovered.main:undefined,}}>
                                <div style={{marginLeft:16,marginRight:16,width:24,height:24}}>
                                  {getIconComponent({label:'query',size:24,colour:theme.palette.primaryText.light})}
                                </div>
                                <Typography style={{color:theme.palette.primaryText.main,fontSize:13.75}}>
                                  {el.keyword} / Query-{el.display_id}
                                </Typography>
                              </div>
                            ))
                          }
                          {
                            joinsList && selectedCode && joinsList.joinsTypes.find(el=>el.id===selectedJoinType) && selectedCodeData && selectedCode && 
                            <>
                              <div style={{marginTop:24}}>
                                <div style={{display:'flex',alignItems:'center',overflow:'hidden',marginBottom:16}}>
                                  <Typography style={{marginRight:16,fontSize:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}} color='primary'>{selectedCodeData.name_txt}</Typography>
                                  <IconButton style={{width:24,height:24}} onClick={()=>{onClickResultItem({label:'query',id:selectedCode,newWindow:true,history})}}>
                                    {getIconComponent({label:'open',size:24,colour:theme.palette.primaryText.light})}
                                  </IconButton>
                                </div>
                                <ProfileSideBar
                                  tabOptions={[]}
                                  history={history}
                                  state={{basicData:selectedCodeData}}
                                  dispatch={()=>{}}
                                  mapping={
                                    ['sourceType','lastRun','runs']
                                  }
                                  fetchList={()=>{}}
                                  sessionData={sessionData}
                                  cerebrumLabel={'queries'}
                                  data={selectedCodeData}
                                  ignoreGrouping
                                />
                              </div>
                              <div style={{marginBottom:16}}>
                                <div style={{display:'flex',alignItems:'center',marginBottom:8}}>
                                  <Typography style={{color:theme.palette.primaryText.main,fontSize:13.75,marginRight:16,textTransform:'uppercase'}}>Code snippet (First 100 lines shown)</Typography>
                                  <IconButton style={{width:24,height:24}} onClick={onCopyCode}>
                                    {getIconComponent({label:'copy',size:24,colour:theme.palette.primaryText.light})}
                                  </IconButton>
                                </div>
                                {
                                  selectedCodeData &&
                                  <div style={{marginBottom:16}}>
                                    <CodeDisplay code={getPorcessedCode(selectedCodeData.code).value}/>
                                    {
                                      getPorcessedCode(selectedCodeData.code).isOverflow && 
                                      <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>More than 100 lines of code. Open the profile to see all lines of code</Typography>
                                    }
                                  </div>
                                }
                              </div>
                            </>
                            
                          }
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                }
              </div>
            </div>
          </div>
        </>
      }
      
    </Drawer>
  )
}


export default withTheme()(withStyles(styles)(LineageDetailDrawer));
