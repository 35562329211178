import axiosCerebrum from "../../../axios-cerebrum"

export const loadOnboardedSources = ({page = 1, type, subType, search, state, dispatch}) => {
  let dataField, loadingField, errorField, typeName;
  if(type==='active'){
    typeName = 'set_active_sources'
    dataField = 'activeSources'
    loadingField = 'activeSourcesLoading'
    errorField = 'activeSourcesError'
  }
  else if(type==='manual'){ 
    typeName = 'set_manual_sources'
    dataField = 'manualSources'
    loadingField = 'manualSourcesLoading'
    errorField = 'manualSourcesError'
  }
  else{
    typeName = 'set_inactive_sources'
    dataField = 'inactiveSources'
    loadingField = 'inactiveSourcesLoading'
    errorField = 'inactiveSourcesError'
  }

  dispatch({
    type:typeName,
    [loadingField]:true,
    [dataField]:page===1?undefined:state[dataField],
  })

  let job_mode;
  if(subType==='DIRECT CONNECT')job_mode = 'EXTRACT_AND_LOAD';
  if(subType==='COLLECTOR')job_mode = 'LOAD_FROM_FILE';
  if(type==='manual')job_mode = 'LOAD_FROM_EXCEL';

  axiosCerebrum
    .get(
      `/api/sources`,
      {params:{
        per_page:20,
        page,
        active_flag:['manual','active'].includes(type)?true:false,
        types:'DATABASE,TOOL,IDENTITY_STORE',
        sort:'ALPHABETICAL',
        job_mode
      }}
    )
    .then(response=>{
      response.data.items = response.data.items.map(item=>{
        item.source_id = item.id;
        item.id = item.host_ids[0];
        return item;
      })
      let data = page===1?response.data.items:state[dataField].items.concat(response.data.items)
      dispatch({
        type:typeName,
        [dataField]:{...response.data,items:data}
      })
    })
    .catch(error=>{
      console.log(error)
      dispatch({
        type:typeName,
        [errorField]:true
      })
    })
}