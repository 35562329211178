import React, { useState } from 'react';
import { withStyles, withTheme, Typography, MobileStepper, Button} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { getCollectionColour, getIconComponent, onClickResultItem } from '../../../utilities';
import KTooltip from '../../UI/KTooltip/KTooltip';
import UserChip from '../../UI/Chips/UserChip';

const styles = theme => ({
  container:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:4,
    display:'flex',
    flexDirection:'column',
    height:356,
    flex:'0 0 32%',
    marginLeft:16,
    minWidth:460,
    marginBottom:24,
    overflow:'hidden'
  },
  headerSection:{
    flexGrow:0,
    flexShrink:0,
    height:85,
  },
  contentSection:{
    flexGrow:1,
    flexShrink:1,
    padding:'8px 24px',
    marginTop:8,
    marginBottom:8,
    overflow:'auto',
    ...theme.components.customScroll
  },
  footerSection:{
    flexGrow:0,
    flexShrink:0,
    height:48,
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
  },
  mobileStepper:{
    width:'100%',
    background:theme.palette.background.main,
  },
  icon:{
    marginTop:24,
    marginLeft:22,
    marginRight:24,
    width:30,
    height:30,
    flexGrow:0,
    flexShrink:0,
  },
  header:{
    fontSize:20,
    marginLeft:24,
    marginRight:24,
    marginTop:8,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline'
    }
  },
  contentHeader:{
    fontSize:13,
  },
  description:{
    color:theme.palette.primaryText.light,
    fontSize:13.75,
    wordBreak:'break-word',
  },
  detailSection:{
    display:'flex',
    alignItems:'center',
    marginBottom:12,
    overflow:'hidden'
  },
  overflowText:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  tag:{
    height:24,
    borderRadius:12,
    padding:'0px 8px',
    margin:'2px 6px 2px 0',
    fontSize:13.75,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    display:'flex',
    alignItems:'center',
  }
})

function ProductCard(props) {

  const {
    // history,
    // state,
    // dispatch,
    theme,
    product,
    classes,
  } = props;
  
  const [activeStep, setActiveStep] = useState(0)

  const maxSteps = 2;

  let bodyContent;

  if(activeStep===0){
    bodyContent = (
      <div>
        <div style={{maxHeight:66,marginBottom:16,overflow:'hidden'}}>
          <Typography className={classes.description}>
            {product.description || 'No description provided'}
          </Typography>
        </div>
        <div style={{display:'flex',flexWrap:"wrap"}}>
          {
            product.domain_kc_txts?.map(el=>{
              return (
                <KTooltip title={el} key={el}>
                  <div 
                    className={classes.tag}
                    style={{
                      background:getCollectionColour('domain','manual',el).colour,
                      color:getCollectionColour('domain','manual',el).fontColour
                    }}
                  >
                    <span className={classes.overflowText}>{el}</span>
                  </div>
                </KTooltip>
              )
            })
          }
          {
            product.facets?.domain?.buckets?.map((el,i)=>{
              return (
                <KTooltip title={el.val} key={el.val}>
                  <div 
                    className={classes.tag}
                    style={{
                      background:getCollectionColour('domain','manual',el.val).colour,
                      color:getCollectionColour('domain','manual',el.val).fontColour
                    }}
                  >
                    <span className={classes.overflowText}>{el.val}</span>
                  </div>
                </KTooltip>
              )
            })
          }
          {
            product.facets?.object_count?.buckets?.map((el,i)=>{
              return (
                <KTooltip title={el.val} key={el.val}>
                  <UserChip
                    user={{name:el.count + ' ' + el.val.replace(/_/g,' ') + '(S)'}}
                    iconLabel={el.val}
                  />
                </KTooltip>
              )
            })
          }
        </div>
      </div>
    )
  }
  if(activeStep===1){
    bodyContent = (
      <div>
         {
          product.facets?.verified?.buckets?.length>0 && 
          <div className={classes.detailSection}>
            <Typography className={classes.contentHeader} style={{flex:'0 0 120px'}}>Verified Use Cases:</Typography>
            <div style={{display:'flex',overflow:'hidden'}}>
              {
                product.verified_kc_txts?.map(el=>{
                  return (
                    <KTooltip title={el} key={el}>
                      <div 
                        className={classes.tag}
                        style={{
                          background:getCollectionColour('verified','manual',el).colour,
                          color:getCollectionColour('verified','manual',el).fontColour
                        }}
                      >
                        <span className={classes.overflowText}>{el}</span>
                      </div>
                    </KTooltip>
                  )
                })
              }
              {
                product.facets?.verified?.buckets.map((el,i)=>{
                  return (
                    <KTooltip title={el.val} key={el.val}>
                      <div 
                        className={classes.tag}
                        style={{
                          background:getCollectionColour('verified','manual',el.val).colour,
                          color:getCollectionColour('verified','manual',el.val).fontColour
                        }}
                      >
                        <span className={classes.overflowText}>{el.val}</span>
                      </div>
                    </KTooltip>
                  )
                })
              }
            </div>
          </div>
        }
        {
          product.facets?.classification?.buckets?.length>0 && 
          <div className={classes.detailSection}>
            <Typography className={classes.contentHeader} style={{flex:'0 0 120px'}}>Classifications:</Typography>
            <div style={{display:'flex',overflow:'hidden'}}>
              {
                product.classification_kc_txts?.map(el=>{
                  return (
                    <KTooltip title={el} key={el}>
                      <div 
                        className={classes.tag}
                        style={{
                          background:getCollectionColour('classification','manual',el).colour,
                          color:getCollectionColour('classification','manual',el).fontColour
                        }}
                      >
                        <span className={classes.overflowText}>{el}</span>
                      </div>
                    </KTooltip>
                  )
                })
              }
              {
                product.facets?.classification?.buckets.map((el,i)=>{
                  return (
                    <KTooltip title={el.val} key={el.val}>
                      <div 
                        className={classes.tag}
                        style={{
                          background:getCollectionColour('classification','manual',el.val).colour,
                          color:getCollectionColour('classification','manual',el.val).fontColour
                        }}
                      >
                        <span className={classes.overflowText}>{el.val}</span>
                      </div>
                    </KTooltip>
                  )
                })
              }
            </div>
          </div>
        }
        <div className={classes.detailSection}>
          <Typography className={classes.contentHeader} style={{flex:'0 0 120px'}}>Created by:</Typography>
          {
            product.created_by_txts?
            <UserChip
              user={{name:product.created_by_txts[0]}}
            />
            :
            <Typography className={classes.description}>Unknown</Typography>
          }
        </div>
        <div className={classes.detailSection}>
          <Typography className={classes.contentHeader} style={{flex:'0 0 120px'}}>Owned by:</Typography>
          <div style={{display:'flex',overflow:'hidden'}}>
            {
              product.owners_txts?.length>0?
                product.owners_txts.map((el,i)=>{
                  return (
                    <KTooltip title={el} key={i}>
                      <UserChip
                        user={{name:el}}
                      />
                    </KTooltip>
                  )
                })
                :
                <div
                  className={classes.tag}
                  style={{
                    background:theme.palette.chip.main,
                    color:theme.palette.primaryText.main,
                    border:`1px solid ${theme.palette.chipBorder.main}`
                  }}
                >
                  No owner assigned
                </div>
            }
          </div>
        </div>

        <div className={classes.detailSection}>
          <Typography className={classes.contentHeader} style={{flex:'0 0 120px'}}>Stewarded by:</Typography>
          <div style={{display:'flex',overflow:'hidden'}}>
            {
              product.sterwards_txts?.length>0?
                product.sterwards_txts.map((el,i)=>{
                  return (
                    <KTooltip title={el} key={i}>
                      <UserChip
                        user={{name:el}}
                      />
                    </KTooltip>
                  )
                })
                :
                <div
                  className={classes.tag}
                  style={{
                    background:theme.palette.chip.main,
                    color:theme.palette.primaryText.main,
                    border:`1px solid ${theme.palette.chipBorder.main}`
                  }}
                >
                  No stewards assigned
                </div>
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerSection}>
        <div className={classes.icon}>
          {getIconComponent({label:'data_product_collection',size:30,colour:theme.palette.primary.main})}
        </div>
        <KTooltip title={product.name} placement={'bottom-start'}>
          <Typography className={classes.header} onClick={()=>onClickResultItem({item:product, id:product.id,label:'collection_instance',newWindow:true})}>
            {product.name}
          </Typography>
        </KTooltip>
      </div>
      <div className={classes.contentSection}>
        {bodyContent}
      </div>
      <div className={classes.footerSection}>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={
            <Button color='primary' size="small" onClick={()=>setActiveStep(activeStep+1)} disabled={activeStep === maxSteps - 1}>
              Next
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button color='primary' size="small" onClick={()=>setActiveStep(activeStep-1)} disabled={activeStep === 0}>
              <KeyboardArrowLeft /> 
              Back
            </Button>
          }
        />
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(ProductCard));