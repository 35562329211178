import React, { useRef, useState, useEffect } from 'react';
import { withTheme, withStyles, Typography, Button} from '@material-ui/core';
import moment from 'moment';
import { toTitleCase } from '../../../utilities';
import { generateDefaultPayload } from '../utils';
import axiosCerebrum from '../../../axios-cerebrum';
import cronstrue from 'cronstrue';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
})

const FinaliseSchedule = props => {

  const {
    classes,
    state,
    dispatch,
    frequency,
    loadDay,
    loadTime, 
    loadSchedule,
    customCron,
    forceOnSave
  } = props;

  const [saving, setSaving] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  let formattedCron = customCron;

  let localStr = '';
  let UTCStr = '';  

  let timeText, timeTextUTC, dayText, dayTextUTC, dayIndexUTC, cronText;
  if(frequency!=='custom'){
    timeText =  moment(loadTime).format('HH:mm')
    timeTextUTC = moment(loadTime).add(-moment().utcOffset(),'minute').format('HH:mm')
    dayText = moment().day(loadDay).format('dddd')
    dayTextUTC = moment().day(loadDay).hour(loadTime.format('HH')).minute(loadTime.format('mm')).utc().format('dddd')
    dayIndexUTC = moment().day(loadDay).hour(loadTime.format('HH')).minute(loadTime.format('mm')).utc().weekday() + ''
  }else{
    try{
      let length = customCron.split(' ').length;
      if(length!==5){
        let diff = 5 - length;
        for(let i=0;i<diff;i++){
          formattedCron = formattedCron + ' *'
        }
      }
      cronText = cronstrue.toString(formattedCron, { use24HourTimeFormat: true }) + " (UTC)"
    }catch(error){
      console.log(error)
    }
  }
  
  if(frequency==='daily'){
    localStr = `Every day at ${timeText}`;
    UTCStr = `Every day at ${timeTextUTC}`;
  }
  if(frequency==='weekly'){
    localStr = `Every ${dayText} at ${timeText}`;
    UTCStr = `Every ${moment().day(loadDay).hour(loadTime.format('HH')).minute(loadTime.format('mm')).utc().format('dddd')} at ${timeTextUTC}`;
  }
  if(frequency==='monthly'){
    localStr = `${toTitleCase(loadSchedule)} ${dayText} of each month at ${timeText}`;
    UTCStr = `${toTitleCase(loadSchedule)} ${dayTextUTC} of each month at ${timeTextUTC}`;
  }

  const onSave = () => {
    let cron = {};
    if(frequency!=='custom'){
      cron.hour = timeTextUTC.split(':')[0];
      cron.minute = timeTextUTC.split(':')[1];
      if(frequency==='weekly'){
        cron.day_of_week = dayIndexUTC;
      }
      if(frequency==='monthly'){
        cron.day_of_week = dayIndexUTC;
        if(loadSchedule==='first'){
          cron.day_of_month = '1-7';
        }else{
          cron.day_of_month = 'L-7';
        }
      }
    }else{
      let splitted = formattedCron.trim().split(' ');
      cron.minute = splitted[0];
      cron.hour = splitted[1];
      cron.day_of_month = splitted[2];
      cron.month_of_year = splitted[3];
      cron.day_of_week = splitted[4];
    }

    if(forceOnSave){
      forceOnSave({cron, load_frequency:frequency})
      return;
    }

    let payload = generateDefaultPayload(state.selectedSource)
    payload.cron = cron;
    payload.args.load_frequency = frequency;

    let successfullMsg = 'Schedule successfully updated';
    let errorMsg = 'Error occurred updating schedule, please try again'

    setSaving(true);
    axiosCerebrum
      .put('/api/sources/'+state.selectedSource.source_id,payload)
      .then(response=>{
        dispatch && dispatch({type:'set_tab_state',tabState:0});
        dispatch && dispatch({type:'set_sources_updated',sourcesUpdated:true})
        setSaving(false);
        sendAlert({message:successfullMsg,type:'info'})
      }).catch((error)=>{
        console.log(error);
        sendAlert({message:errorMsg,type:'error'})
        setSaving(false)
      })

  }


  return (
    <div className={classes.root}>
      {
        frequency!=='custom'?
        <>
          <Typography>
            Schedule (Local time): {localStr}
          </Typography>
          <Typography style={{marginTop:8}}>
            Schedule (UTC): {UTCStr}
          </Typography>
        </>
        :
        <>
          <Typography>
            {cronText || 'Invalid cron expression'}
          </Typography>
        </>
      }
     
      <div style={{display:'flex',marginTop:16}}>
        <Button color='primary' variant='contained' disabled={saving} onClick={onSave}>SAVE</Button>
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(FinaliseSchedule));