import React, {useEffect, useRef} from 'react';
import { withTheme, withStyles, Typography, Button, CircularProgress} from '@material-ui/core';
import theme from '../../../theme';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr';
import moment from 'moment';
import { getIconComponent, isInViewport, onClickResultItem } from '../../../utilities';
import KTooltip from '../../UI/KTooltip/KTooltip';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';
import CustomMenu from '../../UI/ContextMenu/ContextMenu'

const styles = theme => ({

  columnText:{
    fontSize:12,
    paddingRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    color:theme.palette.primary.main,
    minWidth:50,
    letterSpacing:1.5
  },
  tableText:{
    fontSize:12,
    paddingRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    minWidth:50,
  },
  listItem:{
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    height:48,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:'pointer',
    '&:hover':{
      backgroundColor:theme.palette.hovered.main
    }
  }
})

function Issues(props) {

  const {
    history,
    classes,
    state,
    dispatch,
    sessionData
  } = props;

  const scrollRef = useRef()


  const flexConfig = [
    '0 0 200px',
    '1 1 200px',
    '0 1 180px',
    '0 1 180px',
    '0 1 180px',
    '0 1 180px',
  ]

  const loadIssues = ({page=1}) => {
    dispatch({
      type:'set_issue_data',
      issueLoading:true,
      issuedata:page===1?undefined:state.issueData
    })
    axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related`,{
          params:{
            object_name:'ISSUE',
            relationship:'ASSIGNEE_OF',
            sort:'END_DESC',
            page,
            per_page:20
          }
        }
      )
      .then(response=>{
        if(response.data.total===0){
          dispatch({type:'set_issue_data',issueData:response.data})
          return;
        }
        axiosSolr
          .get(
            `/solr/search/select`,{
              params:{
                q:"*",
                fq:`id:(${response.data.items.map(item=>item.id).join(' OR ')})`,
                rows:20,
              }
            }
          )
          .then(solrResponse=>{
            let issueData = {
              ...response.data,
              items:response.data.items.map((item,index)=>({
                ...item,
                ...(solrResponse.data.response.docs.find(doc=>doc.id===item.id) || {})
              }))
            };
            if(page>1){
              issueData.items = state.issueData.items.concat(issueData.items)
            }
            dispatch({type:'set_issue_data',issueData})
          })
          .catch(error=>{
            dispatch({type:'set_issue_data',issueError:error})
          })
      })
      .catch(error=>{
        dispatch({type:'set_issue_data',issueError:error})
      })
  }

  useEffect(()=>{
    if(!state.issueData){
      loadIssues({})
    }
  // eslint-disable-next-line
  },[])

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && !state.issueLoading && state.issueData?.pages>state.issueData?.page
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadIssues({page:state.issueData.page+1})
    }
  // eslint-disable-next-line
  },[state.issueData,state.issueLoading])

  window.onscroll = () => {
    if(shouldLoadMore()){
      loadIssues({page:state.issueData.page+1})
    }
  }

  return (
    <div>
      <div style={{display:'flex',paddingBottom:24,alignItems:'flex-start',position:'sticky',top:221,background:theme.palette.background.main}}>
        <div style={{flexGrow:1}}>
          <Typography style={{fontSize:20}}>
            ISSUES 
          </Typography>
          <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>
            Issues assigned to you
          </Typography>
        </div>
        {
          state.issueData?.total>0 &&
          <Button 
            onClick={()=>{
              let propFilter = `active_srt:YES AND assigned_to_msrt:${sessionData.name}`
              let propColumn = 'source_type_srt,source_srt,created_by_msrt,last_updated_srt'
              history.push(`/basic_search?query=*&object=ISSUE&presetFilter=${propFilter}&presetColumn=${propColumn}`)
            }}
            color='primary'
            variant='outlined'
          >
            VIEW IN SEARCH
          </Button>
        }
      </div>
      {
        state.issueData?.total===0 &&
        <Typography style={{fontSize:12}}>No issues found</Typography>
      }
      {
        state.issueData?.total>0 &&
        <>
          <div style={{display:"flex",marginBottom:8}}>
            <div style={{width:24,height:24,flexGrow:0,flexShrink:0,marginLeft:16,marginRight:16}}>
              
            </div>
            <Typography className={classes.columnText} style={{flex:flexConfig[0]}}>
              ID
            </Typography>
            <Typography className={classes.columnText} style={{flex:flexConfig[1]}}>
              ISSUE
            </Typography>
            <Typography className={classes.columnText} style={{flex:flexConfig[2]}}>
              TYPE
            </Typography>
            <Typography className={classes.columnText} style={{flex:flexConfig[3]}}>
              SEVERITY
            </Typography>
            <Typography className={classes.columnText} style={{flex:flexConfig[4]}}>
              STATUS
            </Typography>
            <Typography className={classes.columnText} style={{flex:flexConfig[5]}}>
              LAST UPDATED
            </Typography>
          </div>
          {
            state.issueData?.items.map(el=>(
              <>
                <ContextMenuTrigger id={el.id}>
                  <div className={classes.listItem} onClick={()=>onClickResultItem({item:el,id:el.id,label:el.type,history})} >
                    <div style={{width:24,height:24,flexGrow:0,flexShrink:0,marginLeft:16,marginRight:16}}>
                      {getIconComponent({label:'issue',size:24,colour:props.theme.palette.primary.main})}
                    </div>
                    <Typography className={classes.tableText} style={{flex:flexConfig[0]}}>
                      {(el.name_txt||el.name).split(':')[0]}
                    </Typography>
                    <KTooltip title={(el.name_txt||el.name).split(':')[1] || el.description} placement={'bottom-start'}>
                      <Typography className={classes.tableText} style={{flex:flexConfig[1]}}>
                        {(el.name_txt||el.name).split(':')[1] || el.description}
                      </Typography>
                    </KTooltip>
                    <Typography className={classes.tableText} style={{flex:flexConfig[2]}}>
                      {el.issue_type_txt?.replace(/_/g,' ')}
                    </Typography> 
                    <Typography className={classes.tableText} style={{flex:flexConfig[3]}}>
                      {el.issue_severity?.replace(/_/g,' ')}
                    </Typography> 
                    <Typography className={classes.tableText} style={{flex:flexConfig[4]}}>
                      {el.issue_status_txt?.replace(/_/g,' ')}
                    </Typography> 
                    <Typography className={classes.tableText} style={{flex:flexConfig[5]}}>
                      {moment(el.last_updated_srt || el.updated_at).format('MM/DD/YYYY')}
                    </Typography> 
                  </div>
                </ContextMenuTrigger>
                <ContextMenu id={el.id}>
                  <CustomMenu
                    item={el}
                    actions={[
                      'open_new_tab'
                    ]}
                  />
                </ContextMenu>
              </>
            ))
          }
        </>
      }
      <div ref={scrollRef} style={{display:'flex',justifyContent:'center',marginBottom:24,marginTop:8}}>
        {
          state.issueLoading && 
          <CircularProgress color='secondary'/>
        }
      </div>
      {
        state.issueError && 
        <Typography>Error occurred loading issues</Typography>
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(Issues));