import React from 'react';
import { withStyles} from '@material-ui/core';
import BySource from '../BySource/BySource';


const styles = theme => ({
  root: {
    // maxWidth:1160
  }
})

const Body = props => {

  const {
    classes,
    history,
    state,
    dispatch,
    sessionData,
  } = props;

  // const isDemo = localStorage.hasOwnProperty('demo');
  
  let bodyContent = <BySource state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

export default withStyles(styles)(Body);