export const getChildType = parentType => {
  if (parentType === 'TABLE') return 'COLUMN'
  if (parentType === 'DATASET_TABLE') return 'DATASET_FIELD'
  if (parentType === 'SCHEMA') return 'TABLE'
  if (parentType === 'DATASET') return 'DATASET_TABLE'
  return null
}

export const getParentType = childType => {
  if (childType === 'COLUMN') return 'TABLE'
  if (childType === 'DATASET_FIELD') return 'DATASET_TABLE'
  if (childType === 'TABLE') return 'SCHEMA'
  if (childType === 'DATASET_TABLE') return 'DATASET'
  return null
}
