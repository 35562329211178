import React, {useEffect, useRef } from 'react';
import { withTheme, withStyles, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosSolr from '../../../../axios-solr';
import { isInViewport, mapSearchObjectName } from '../../../../utilities';
import SummaryList from './DetailList';
import theme from '../../../../theme';


const styles = theme => ({
  header:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  tile:{
    maxWidth:150,
    overflow:'hidden',
    flexGrow:1,
    flexShrink:1,
    flex:'1 1 120px',
    textAlign:'center',
    boxSizing:'border-box',
    height:123,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:4,
    '&:hover':{
      cursor:'pointer',
      background:theme.palette.hovered.main
    }
  },
  disabled:{
    pointerEvents:'none',
    '&:hover':{
      cursor:'default',
      background:theme.palette.background.main
    }
  },
  selected:{
    background:theme.palette.primary.dark,
    '&:hover':{
      background:theme.palette.primary.dark
    },
    '& p':{
      color:theme.palette.background.main
    }
  },
  tileName:{
    fontSize:12,
    letterSpacing:1.5,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginTop:8
  },
  tileValue:{
    fontSize:20,
    color:theme.palette.primaryText.main,
    marginTop:16,
  },
})


function Details(props) {

  const {
    classes,
    dqInstances,
    object,
    history,
    summaryByDimension,
    setSummaryByDimension,
    summaryList,
    setSummaryList,
    expandedItem,
    setExpandedItem,
    selectedTile,
    // setSelectedTile
  } = props;

  const [headerTopOffset, setHeaderTopOffset] = React.useState(0);
  let label =  object.object?.name || mapSearchObjectName(object.object_type_srt,object.code_type_srt)
  // let isColumn = ['COLUMN','DATASET_FIELD','QUERY'].includes(label)
  let scrollRef = useRef()

  const loadSummaryByDimension = () => {
    let fq = `object_id_srt:${object.id}`
    if(label==='TABLE')fq += ` OR table_id_srt:${object.id}`
    if(label==='DATASET_FIELD')fq += ` OR dataset_table_id_srt:${object.id}`
    if(label==='DATASET')fq = `${fq} OR dataset_id_srt:${object.id}`

    setSummaryByDimension({loading:true})
    axiosSolr
      .get(
        `/solr/data_quality_test_result/select`,{params:{
          q:'*',
          fq:fq,
          rows:0,
          'json.facet':{
            'overall_avg':"avg(last_data_quality_score_srt)",
            'dimensions':{
              type:'terms',
              field:'data_quality_dimension_srt',
              limit:100,
              "facet":{
                "avg":"avg(last_data_quality_score_srt)",
                "dimension_id":{
                  type:'terms',
                  field:'data_quality_dimension_id_srt',
                  limit:1,
                }
              }
            },
          }
        }}
      )
      .then(response=>{
        if(response.data.response.numFound===0){
          setSummaryByDimension({data:[]})
          return;
        }
        let data = [];
        data.push({name:'OVERALL',value:response.data.facets.overall_avg,id:'all',count:response.data.response.numFound});

        let emptyDqs = dqInstances.filter(d=>!response.data.facets.dimensions?.buckets?.find(el=>el.val.toLowerCase()===d.name_txt.toLowerCase()));
        [...(response.data.facets.dimensions?.buckets||[]),...emptyDqs.map(el=>({val:el.name_srt || el.name_txt}))].sort((a,b)=>a.val.localeCompare(b.val)).forEach(bucket=>{
          data.push({name:bucket.val.toUpperCase(),value:bucket.avg,id:bucket.dimension_id?.buckets[0]?.val,count:bucket.count})
        })
        setSummaryByDimension({data})
      })
      .catch(error=>{
        console.log(error)
        setSummaryByDimension({error:true})
      })
  }

  const loadListData = ({offset=0, dimension=selectedTile}) => {
    let fq = `object_id_srt:${object.id}`
    if(label==='TABLE')fq += ` OR table_id_srt:${object.id}`
    if(label==='DATASET_TABLE')fq += ` OR dataset_table_id_srt:${object.id}`
    if(label==='DATASET')fq = `(${fq} OR dataset_id_srt:${object.id}) AND object_type_srt:(DATASET OR DATASET_TABLE)`
    let dimensionID
    if(dimension!=='all' && dimension!=='other')dimensionID = dimension;
    if(dimension==='other'){
      fq += ` AND -data_quality_dimension_srt:[* TO *]`
    }
    if(dimensionID)fq = `(${fq}) AND data_quality_dimension_id_srt:${dimensionID}`
    // fq = `(${fq}) AND data_quality_dimension_srt:*`
    setSummaryList({loading:true, data:offset===0?undefined:summaryList?.data, offset})
    axiosSolr
      .get(
        `/solr/data_quality_test_result/select`,{params:{
          q:"*",
          fq,
          rows:0,
          'json.facet':{
            'object_type':{
              type:'terms',
              field:'object_type_srt',
              limit:5,
              'facet':{
                'object_id':{
                  type:'terms',
                  field:'object_id_srt',
                  limit:100,
                  offset,
                  'facet':{
                    'object_name':{
                      type:'terms',
                      field:'object_name_srt',
                      limit:1,
                    },
                    'table_type':{
                      type:'terms',
                      field:'table_type_srt',
                      limit:1,
                    },
                    'dimensions':{
                      type:'terms',
                      field:'data_quality_dimension_srt',
                      limit:100,
                      "facet":{
                        "avg":"avg(last_data_quality_score_srt)",
                      }
                    },
                    'overall_avg':"avg(last_data_quality_score_srt)",
                  }
                }
              }
            }
          }
        }}
      )
      .then(response=>{
        let objectTypeFacet = response.data.facets.object_type?.buckets;
        if(!objectTypeFacet){
          setSummaryList({data:[]})
          return;
        }

        let objectTypeSort = ['DATASET','TABLE','DATASET_TABLE','COLUMN','DATASET_FIELD']

        let notAllLoaded = false;
        let newData = [];
        objectTypeFacet.sort((a,b)=>objectTypeSort.indexOf(a.val.toUpperCase())-objectTypeSort.indexOf(b.val.toUpperCase())).forEach(type=>{
          let typeName = type.val;
          let idBucket = type.object_id?.buckets;
          if(idBucket.length===100)notAllLoaded=true;
          idBucket.forEach(id=>{
            let obj = {
              object_type_srt:typeName,
              object_id_srt:id.val,
              object_name_srt:id.object_name?.buckets[0].val,
              table_type_srt:id.table_type?.buckets[0]?.val?.toUpperCase(),
              dimensions:id.dimensions?.buckets.map(d=>({
                avg:d.avg,
                val:d.val,
                count:d.count
              }))
            }
            if(id.overall_avg){
              obj.dimensions.push({
                avg:id.overall_avg,
                val:'overall'
              })
            }
            newData.push(obj)
          })
        })

        let data = [...(offset===0?[]:summaryList?.data||[]),...newData]
        setSummaryList({
          data,
          notAllLoaded,
          offset
        })
      })
      .catch(error=>{
        console.log(error)
        setSummaryList({error:true})
      })
  }

  useEffect(()=>{
    if(!summaryByDimension)loadSummaryByDimension()
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(summaryByDimension?.data && !summaryList){
      loadListData({offset:0})
    }
  // eslint-disable-next-line
  },[summaryByDimension])

  const shouldLoadMoreList = () => {
    return isInViewport(scrollRef) && summaryList && summaryList.notAllLoaded && !summaryList.loading
  }

  useEffect(()=>{
    if(shouldLoadMoreList())loadListData({offset:summaryList.offset+10})
  // eslint-disable-next-line
  },[summaryList])
  
  window.onscroll = () => {
    setHeaderTopOffset(Math.max(0, window.scrollY-230))
    if(shouldLoadMoreList())loadListData({offset:summaryList.offset+10})
  }

  let subTitle;
  if(label==='TABLE')subTitle = 'Latest DQ Score by DQ Dimension at the table level (including its columns)'
  if(label==='COLUMN')subTitle = 'Latest DQ Score by DQ Dimension at the column level'
  if(label==='DATASET_TABLE')subTitle = 'Latest DQ Score by DQ Dimension at the dataset table level (including its fields)'
  if(label==='DATASET_FIELD')subTitle = 'Latest DQ Score by DQ Dimension at the field level'
  if(label==='QUERY')subTitle = 'Latest DQ Score by DQ Dimension at the query level'
  if(label==='DATASET')subTitle = 'Latest DQ Score by DQ Dimension at the dataset level (including its dataset tables)'

  // let scoreSubtitle = 'Latest DQ Score by DQ Dimension at the item level. DQ score is for tests linked directly to the item and does not represent a rolled-up view. Click to see more details'
  // if(isColumn)scoreSubtitle = 'Click on a DQ Test to see more details'

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        DQ SCORE DETAILS
      </Typography>
      <Typography className={classes.subTitle}>
        {subTitle}. DQ score is for tests linked directly to the item and does not represent a rolled-up view. Click to see more detail.
      </Typography>
      {
        summaryByDimension?.loading &&
        <CircularProgress color='secondary'/>
      }
      {
        summaryByDimension?.error &&
        <Typography>
          Error loading detail data
        </Typography>
      }
      {
        summaryByDimension?.data?.length===0 &&
        <Typography>
          No DQ detail found for this asset
        </Typography>
      }
      {
        summaryByDimension?.data?.length>0 && 
        <>
          <div  style={{transform:`translate(0px,${headerTopOffset}px)`,background:theme.palette.background.main,paddingBottom:8}}>
            {
              summaryList?.error &&
              <Typography>
                Error loading detail data
              </Typography>
            }
            {
              summaryList?.data?.length===0 &&
              <Typography>
                No DQ tests have been run on this asset
              </Typography>
            }
          </div>
          {
            summaryList?.data?.length>0 &&
            <SummaryList
              dimensions={summaryByDimension?.data}
              selectedTile={selectedTile}
              list={summaryList.data}
              setExpandedItem={setExpandedItem}
              expandedItem={expandedItem}
              history={history}
              object={object}
              headerTopOffset={headerTopOffset}
            />
          }
          <div ref={scrollRef}>
            {
              summaryList?.loading &&
              <CircularProgress color='secondary'/>
            }
          </div>
        </>
      }
    </div>
  )
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  summaryByDimension: PropTypes.object,
  setSummaryByDimension: PropTypes.func,
  summaryList: PropTypes.array,
  setSummaryList: PropTypes.func,
  expandedItem: PropTypes.string,
  setExpandedItem: PropTypes.func,
}

export default withTheme()(withStyles(styles)(Details));