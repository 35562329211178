import React, {  useReducer, useEffect } from 'react';
import { withStyles, Typography, Button, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import UserOnboardBody from '../../components/UserOnboard/UserOnboardBody/UserOnboardBody';
import { getUserRoles } from '../../utilities';
import useGetCerebrum from '../../hooks/useGetCerebrum'
import axiosCerebrum from '../../axios-cerebrum'
import axiosSolr from '../../axios-solr'

const initialState = {
  activeStep: 0,
  team: [],
  jobTypes: [],
  receiveEmail:'true',
  alias: [],
  isDarkMode: false,
  alert: false,
  completeLaterModalOpen:false
  // sourceData:[]
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_team':
      return {
        ...state,
        team: action.team,
      }
    case 'set_job_types':
      return {
        ...state,
        jobTypes: action.jobTypes
      }
    case 'set_receive_email':
      return {
        ...state,
        receiveEmail: action.receiveEmail
      }
    case 'set_is_dark_mode':
      return {
        ...state,
        isDarkMode: action.isDarkMode
      }
    case 'set_source_data':
      return {
        ...state,
        sourceData: action.sourceData,
      }
    case 'set_alias':
      return {
        ...state,
        alias: action.alias
      }
    case 'set_active_step':
      return {
        ...state,
        activeStep: action.activeStep
      }
    case 'set_alert':
      return {
        ...state,
        alert: action.alert
      }
    case 'set_complete_later_modal_open':
      return {
        ...state,
        completeLaterModalOpen:action.completeLaterModalOpen
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const styles = theme => ({
  root: {
  },
  subtitle: {
    height: '24px',
    width: '585px',
    color: theme.palette.primaryText.light,
    
    fontSize: '13.75px',
    fontWeight: '500',
    letterSpacing: '0.1px',
    lineHeight: '24px'
  },
  buttons:{
    zIndex:999,
    position:'fixed',
    borderTop:`1px solid ${theme.palette.border.main}`,
    backgroundColor:theme.palette.background.main,
    height:80,
    bottom:0,
    right:0,
    left:40,
    //width:'100vw',
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end',
    boxShadow:'0px -1px 5px 0px #DDD',
    paddingRight:'10%'
  },
  modalTitle:{
    marginTop:16,
    marginLeft:24,
    fontSize:20,
    color:theme.palette.primaryText.main
  },
  modalText:{
    fontSize:13.75,
    marginTop:16,
    marginLeft:24,
    marginRight:24,
    marginBottom:40,
    color:theme.palette.primaryText.main,
    whiteSpace:'pre-wrap'
  },
  modalBody:{
    margin:'20vh auto',
    outline:'none',
    width:400,
    border:`1px solid ${theme.palette.border.main}`,
    background:theme.palette.background.main,
    maxHeight:'50vh',
  }
})

function UserOnboard(props) {

  const {
    classes,
    history,
    sessionData,
  } = props;


  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    data:sourceList,
  } = useGetCerebrum({
    url:'/api/sources',
    params:{
      per_page:100,
      active_flag:true,
      internal_flag:false,
      types:'DATABASE,TOOL',
      sort:'ALPHABETICAL',
    }
  })

  useEffect(()=>{
    if(sourceList){
      dispatch({
        type:'set_source_data',
        sourceData:sourceList.items
      })
    }
  },[sourceList])
  
  const roles = getUserRoles(sessionData.user_role)

  const getUserTeam = () => {
    axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related`,{
          params:{
            relationship:'MEMBER_OF',
            object_name:'GROUP',
            per_page:50
          }
        }
      )
      .then(response=>{
        if(response.data.items.length>0){
          axiosSolr
            .get(
              `/solr/search/select`,{
                params:{
                  q:'*',
                  fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`
                }
              }
            )
            .then(solrRes=>{
              dispatch({
                type:"set_team",
                team:solrRes.data.response.docs
              })
            })
            .catch(error=>{
              console.log(error)
            })
        }
      })
      .catch(error=>{
        console.log(error)
      })
  }

  useEffect(()=>{
    getUserTeam();
    // eslint-disable-next-line
  },[])

  const onNext = () => {
    switch (state.activeStep) {
      case 0: 
      case 1:
      case 2:
        dispatch({ type: 'set_active_step', activeStep: state.activeStep + 1 });
        break;
      case 3: // show the alert box
        dispatch({ type: 'set_alert', alert: true })
        break;
      default:
    }


  }

  const onBack = () => {
    dispatch({ type: 'set_active_step', activeStep: state.activeStep - 1 });
  }

  const onCompleteLater = () => {
    dispatch({type:'set_complete_later_modal_open',completeLaterModalOpen:true})
  }


  let buttonName = state.activeStep === 3 ? 'FINISH' : 'NEXT';

  return (
    <div className={classes.root}>
      <ProfileLayout
        noPadding
        header={(
          <div style={{ marginBottom: '3rem' }}>
            <Typography color='primary' style={{ fontSize: '2.08rem' }}>Welcome</Typography>
            <Typography className={classes.subtitle}>Let's set you up in K</Typography> 
          </div>
        )}
        body={
          <UserOnboardBody
            activeStep={state.activeStep}
            dispatch={dispatch}
            history={history}
            state={state}
            sessionData={sessionData}
          />
        }
      >
      </ProfileLayout>

      <div className={classes.buttons}>
        {
          state.activeStep !== 0 && 
          <Button color="primary" style={{ marginRight: 24, width: 120, height:48 }} onClick={onBack} >
            BACK
          </Button>
        }
        <Button variant="contained" color="primary" style={{  marginRight: 24, width: 120, height:48 }} onClick={onNext} >
          {buttonName}
        </Button>
        <Button color="primary" style={{ height:48 }} onClick={onCompleteLater} >
          COMPLETE LATER
        </Button>
      </div>

      <Modal 
        onClose={()=>dispatch({type:'set_complete_later_modal_open',completeLaterModalOpen:false})} 
        open={state.completeLaterModalOpen} 
        disableBackdropClick
      >
        <div className={classes.modalBody}>
          <Typography className = {classes.modalTitle}>{`Are you sure?`}</Typography>
          <Typography className = {classes.modalText} >{`Adding in your details improves the experience in K. \nYou can update your details in your settings page.`}</Typography>
          <div align="end" style={{marginBottom:12,marginRight:12}}>
            <Button color='primary' variant='contained' style={{marginRight:12}} onClick={()=>window.open(['10','40'].some(el=>roles.includes(el))?'grid':'/home','_self')}>
              COMPLETE LATER
            </Button>
            <Button color="secondary" onClick={()=>dispatch({type:'set_complete_later_modal_open',completeLaterModalOpen:false})} >
              CANCEL
            </Button>
          </div>
        </div>
      </Modal>

    </div>
  )
}

UserOnboard.propTypes = {
  classes: PropTypes.object.isRequired,
  sessionData: PropTypes.object,
}

export default withStyles(styles)(UserOnboard);