import React  from 'react';
import PropTypes from 'prop-types';
import { withStyles} from '@material-ui/core';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';

const styles = theme => ({

})

const Details = props => {

  const {
    state,
    fetchList,
  } = props;  
  

  return (
    <div>
      <UpdateInputCerebrum
        url={`/api/hosts/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        label="host"
        initialValue={state.basicData.description}
        property="description"
        placeholder="Add a description"
        multiline
        data={state.basicData}
        fetchList={fetchList}
        collection="data"
        enableMarkDown={true}
        enableWidget={true}
        state={state}
      />
	  </div>
  )
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  userName: PropTypes.string,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default withStyles(styles)(Details);