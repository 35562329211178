import React, { } from 'react';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DateTimePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { getIconComponent } from '../../../utilities';
import moment from 'moment';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  textField: {
    ...theme.components.inputBase,
    width: '100%',
    '& input': {
      padding: '12px 8px',
      color: theme.palette.primaryText.main,
      cursor: 'pointer',
    },
    '& div': {
      marginTop: '0px !important',
      background: "none",
      '&:hover': {
        background: 'none'
      }
    },
  },
  suggestionChip: {
    ...theme.components.suggestionChip,
    marginRight: 8,
    minWidth: 60
  },
})

function KDateTimePicker(props) {

  const {
    classes,
    theme,
    initialValue,
    value,
    defaultValue,
    onChange,
    showSuggestions,
    suggestionText,
    isSuggestionExtend
  } = props;

  const {
    sendAlert
  } = useAlert({});

  return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DateTimePicker
          className={classes.textField}
          value={value}
          ampm={false}
          format='YYYY-MM-DDTHH:mm'
          defaultValue={defaultValue}
          onChange={value => {
            // if value is less than current time, show alert
            if (value.isBefore(moment().add(1, 'hour'))) {
              sendAlert({ message: 'Please select a future date and time (at least 1 hour from now)', type: 'error' });
              return;
            }
            onChange(value);
          }}
          type="datetime-local"
          variant="filled"
          InputProps={{
            startAdornment: getIconComponent({ label: 'time', size: 24, colour: theme.palette.primaryText.light }),
            disableUnderline: true
          }}
          minDate={moment().add(1, 'hour')}
        />
      </MuiPickersUtilsProvider>
      {
        showSuggestions &&
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 16, marginTop: 8 }}>
          <Typography style={{ fontSize: 12, color: theme.palette.primaryText.light, marginRight: 8 }}>
            {suggestionText || 'Suggestions:'}
          </Typography>
          {
            [8, 24, 48].map((hrs, index) => (
              <div key={index} className={classes.suggestionChip} onClick={() => onChange(moment(isSuggestionExtend ? initialValue : undefined).add(hrs, 'hour'))}>
                {isSuggestionExtend ? '+' : ''}{hrs} hrs
              </div>
            ))
          }
        </div>
      }
    </div>
  )
}

KDateTimePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
  showSuggestions: PropTypes.bool,
  suggestionText: PropTypes.string,
  isSuggestionExtend: PropTypes.bool,
}

export default withTheme()(withStyles(styles)(KDateTimePicker));