import React from 'react';
import { withTheme,  Typography,Button} from '@material-ui/core';


const SourceSelector = props => {

  const {
    classes,
    filterType,
    onSetFilterType
  } = props;
  
  return (
    <div>
      <Typography className={classes.configTitle}>Select a filter type</Typography>
      <div style={{display:'flex',paddingRight:24}}>
        <Button data-test-id="scanner-filter-type-database" color='primary' style={{marginRight:24}} onClick={()=>onSetFilterType('database')} variant={filterType==='database'?'contained':'outlined'}>SELECT TABLES BY DATABASE</Button>
        <Button data-test-id="scanner-filter-type-schema" color='primary' onClick={()=>onSetFilterType('schema')} variant={filterType==='schema'?'contained':'outlined'}>SELECT TABLES BY SCHEMA</Button>
      </div>
    </div>
  )
}

export default withTheme()(SourceSelector);