import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { Typography , withTheme, IconButton} from '@material-ui/core';
import { getIconComponent } from '../../../utilities';


const SortableOption = (props) => {

  const {
    classes,
    theme,
    el,
    onDeleteAllowedValue,
  } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({id: props.id});
  
  const style = {
    transform: CSS.Transform.toString({x:transform?.x,y:transform?.y,scaleX:1,scaleY:1}),
    transition,
  };
  
  return (
    <div ref={setNodeRef} {...attributes} {...listeners} style={{...style}} className={classes.dropdownValueChip}>
      <div style={{width:24,height:24,margin:'0 16px 0 8px', flexShrink: 0}}>
        {getIconComponent({label:'drag',size:24,colour:theme.palette.primaryText.light})}
      </div>
      <Typography className={classes.dropdownValueText}>{el.value}</Typography>
      <IconButton style={{flexShrink: 0}} className={classes.dropdownIconButton} onMouseUp={()=>onDeleteAllowedValue(el)}>
        {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
      </IconButton>
      </div>
  );
}

export default withTheme()(SortableOption);