import React, { useState , useEffect, useRef} from 'react';
import { withTheme,withStyles, Typography, CircularProgress, Button, IconButton} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import moment from 'moment'
import { getIconComponent, isInViewport } from '../../../utilities'
import fileDownloader from 'js-file-download';
import { getFileName, getExtractName, getExtractDetails, getIdsToLoad } from '../Utils/Utils'
import axiosSolr from '../../../axios-solr'
import KTooltip from '../../UI/KTooltip/KTooltip';
import CancelModal from '../../PlatformManagement/Monitor/CancelModal';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {

  },
  tooltip:{
    fontSize:13.75,
    whiteSpace:'pre-wrap'
  },
  columnRow:{
    height:48,
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    display:'flex',
    alignItems:'center',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    // cursor:'pointer',
    // '&:hover':{
    //   background:theme.palette.hovered.main
    // }
  },
  titleRow:{
    display:'flex',
    // marginBottom:18
  },
  tableTitle:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primary.main
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  statusChip:{
    minWidth:100,
    width:'max-content',
    textAlign:'center',
    padding:'8px 12px',
    borderRadius:3,
    fontSize:13.75
  }
})

const History = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
  } = props;

  const [historyData, setHistoryData] = useState({loading:false,error:false});
  const [extractsDownloading, setExtractsDownloading] = useState([]);
  const [objectDetailMap, setObjectDetailMap] = useState({});
  const scrollRef = useRef();
  const autoRefreshTimeoutRef = useRef();
  const [autoRefreshCount, setAutoRefreshCount] = useState(0);
  const [retrying, setRetrying] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const loadHistory = ({page=1,autoRefresh=false}) => {
    if(!autoRefresh){
      setHistoryData({loading:true,data:page===1?undefined:historyData.data})
    }
    if(page===1){
      setAutoRefreshCount()
      clearInterval(autoRefreshTimeoutRef.current)
    }
    axiosCerebrum
      .get(
        `/api/me/extracts`,
        {params:{
          per_page:20,
          sort:'START_DESC',
          page:page,
          status:'COMPLETE,FAILED,RUNNING,SUBMITTED,KILLED',
          type:'EXTRACTS'
        }}
      )
      .then(async response=>{
        let idsNeedDetail = [];
        response.data.items.forEach(el=>{
          idsNeedDetail.push(...getIdsToLoad({filters:el.args?.filters||{},currentMap:objectDetailMap}))
        })

        if(idsNeedDetail.length>0){
          const detailResponse = await axiosSolr
            .get(
              `/solr/search/select`,
              {params:{
                q:"*",
                fq:`id:(${idsNeedDetail.join(' OR ')})`,
                fl:'name_txt,id,object_type_txt',
                rows:idsNeedDetail.length
              }}
            )
          const detailObj = {};
          detailResponse.data.response.docs.forEach(el=>{
            detailObj[el.id] = el;
          })
          setObjectDetailMap({...objectDetailMap,...detailObj});
        }
        let newData;
        if(page===1){
          newData = response.data
        }else{
          newData = {...response.data,items:[...historyData.data.items,...response.data.items]}
        }
        setHistoryData({loading:false,data:newData});
        if(page===1){
          clearInterval(autoRefreshTimeoutRef.current)
          setAutoRefreshCount(30)
          let count = 30;
          autoRefreshTimeoutRef.current = setInterval(()=>{
            count -= 1;
            setAutoRefreshCount(count)
            if(count===0){
              setAutoRefreshCount()
              loadHistory({autoRefresh:true})
              clearInterval(autoRefreshTimeoutRef.current)
            }
          },1000)
        }
      })
      .catch(error=>{
        console.log(error);
        setHistoryData({loading:false,error:true})
      })
  }

  useEffect(()=>{
    loadHistory({})
    return ()=>{
      clearInterval(autoRefreshTimeoutRef.current)
    }
     // eslint-disable-next-line
  },[])

  const onRerunExtract = extract => {
    sendAlert({message:'Retrying extract generation.',type:'info'})
    setRetrying(true)
    axiosCerebrum
      .post(
        '/api/extracts',{...extract.args,ignore_cache:true}
      )
      .then(response=>{
        window.scrollTo(0,0)
        loadHistory({})
        setRetrying(false)
      })
      .catch(error=>{
        console.log(error)
        setRetrying(false)
        sendAlert({message:'Error occurred submitting the extract, please try again',type:'error'})
      })
  }

  const onClickHistory = extract => {
    if(extractsDownloading.includes(extract.id))return;
    if(extract.status!=='COMPLETE')return;
    let extractId = extract.id;
    let downloaded = false;
    let previousReturned = true;
    let recursionTimes = 0;

    const filters = extract.args.filters;
    const getNameParams = {
      extract,
      dataSource: filters.sourceId || filters.source_id, 
      user: filters.userId?objectDetailMap[filters.userId]:filters.user_id?objectDetailMap[filters.user_id]:undefined, 
      type:filters.type,
      userNum: filters.numUsers, 
      trend: filters.trend, 
      zone: filters.zone, 
      team: filters.groupId?objectDetailMap[filters.groupId]:undefined, 
      period: filters.period, 
      startDate: filters.startDate || filters.start_at, 
      endDate: filters.endDate || filters.end_at, 
      sourceList: state.sources,
      object: filters.object_id?objectDetailMap[filters.object_id]:undefined, 
      objects: filters.object_ids && Array.isArray(filters.object_ids)?filters.object_ids.map(el=>objectDetailMap[el]):undefined,
      objectType: filters.object_type,
      objectNames: filters.object_types,
      instance: filters.collection_instance_id?objectDetailMap[filters.collection_instance_id]:undefined, 
    }

    setExtractsDownloading([...extractsDownloading,extract.id])
    sendAlert({message:'Download started',type:'info'})

    const onError = (interval) => {
      setExtractsDownloading(extractsDownloading.filter(el=>el!==extract.id))
      clearInterval(interval);
      dispatch({type:'set_running_code',runningCode:''})
      sendAlert({message:'Error occurred downloading the file',type:'error'})
    }

    let downloadExtractInterval = setInterval(()=>{
      if(!previousReturned)return;
      if(recursionTimes>200){
        onError(downloadExtractInterval)
        return;
      }
      recursionTimes += 1;
      previousReturned=false;
      axiosCerebrum.get(`/api/extracts/${extractId}`).then(response=>{
        previousReturned = true;
        if(response.data.status==='COMPLETE'){
          if(downloaded)return;
          downloaded = true;
          axiosCerebrum.get(`/api/extracts/${extractId}/download`)
            .then(downloadRes=>{
              setExtractsDownloading(extractsDownloading.filter(el=>el!==extract.id))
              clearInterval(downloadExtractInterval);
              fileDownloader(downloadRes.data.replace(/\|/g,','), `${getFileName(extract.args.lookup_code,getNameParams)}.csv`);
              dispatch({type:'set_running_code',runningCode:''})
            })
            .catch(error=>{
              console.log(error)
              onError(downloadExtractInterval);
            }) 
        }
        if(response.data.status==='FAILED'){
          onError(downloadExtractInterval);
        }
      }).catch(error=>{
        console.log(error);
        onError(downloadExtractInterval);
        previousReturned = true;
      })
    },2000)
  }

  const getIcon = extract => {
    if(extract.status==='RUNNING'){
      return <div className={classes.statusChip} style={{background:'#FC642D',color:'white'}}>Extract running</div>
    }
    if(extract.status==='COMPLETE'){
      return <div className={classes.statusChip} style={{background:'#48D46A',color:'black'}}>Extract ready</div>
    }
    if(extract.status==='SUBMITTED'){
      return <div className={classes.statusChip} style={{background:'#FFAB00',color:'black'}}>Extract submitted</div>
    }
    if(extract.status==='FAILED'){
      return <div className={classes.statusChip} style={{background:'#B00020',color:'white'}}>Extract failed</div>
    }
    if(extract.status==='KILLED'){
      return <div className={classes.statusChip} style={{background:'#B00020',color:'white'}}>Extract cancelled</div>
    }
  }

  const getStatusTooltip = status => {
    if(status==='RUNNING')return 'Extract being generated';
    if(status==='COMPLETE')return 'Extract ready. Click to download';
    if(status==='FAILED')return 'Extract failed. Click retry button to rerun'
  }

  window.onscroll = () => {
    if(isInViewport(scrollRef) && historyData.data && historyData.data.page<historyData.data.pages && !historyData.loading){
      loadHistory({page:historyData.data.page+1})
      clearInterval( autoRefreshTimeoutRef.current)
      setAutoRefreshCount()
    } 
  }

  useEffect(()=>{
    if(isInViewport(scrollRef) && historyData.data && historyData.data.page<historyData.data.pages && !historyData.loading){
      loadHistory({page:historyData.data.page+1})
    } 
  // eslint-disable-next-line
  },[historyData.data,historyData.loading])

  let expireDays = 7;
  if(localStorage.hasOwnProperty('platformSettings')){
    try{
      let settings = JSON.parse(localStorage.getItem('platformSettings')).items;
      expireDays = settings.find(el=>el.id===4007).value;
    }catch{

    }
  }


  return (
    <div className={classes.root}>
      <div style={{zIndex:9,background:theme.palette.background.main,paddingBottom:16,position:'sticky',top:200}}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-start',marginBottom:24,marginTop:16}}>
          <div>
            <Typography style={{color:theme.palette.header.main,fontSize:20}}>{historyData.data?historyData.data.total:0} RESULT(S)</Typography>
            <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:8}}>Extracts status will update every 30 seconds. Click an available extract to download it. Extracts are stored for {expireDays} days. Rerun the extract for the latest information. </Typography>
          </div>
          <Button 
            color='primary' 
            style={{height:42,flexShrink:0, background:autoRefreshCount?theme.palette.success.main:undefined,color:autoRefreshCount?'#000':undefined}} 
            variant='contained' 
            onClick={()=>loadHistory({})}
          >
            REFRESH {autoRefreshCount && `(${autoRefreshCount})`}
          </Button>
        </div>
        <div className={classes.titleRow}>
          <div  className={classes.tableTitle} style={{width:160,flexShrink:0}}>
            STATUS
          </div>
          <div className={classes.tableTitle} style={{width:284,flexShrink:0}}>
            EXTRACT
          </div>
          <div className={classes.tableTitle} style={{flexGrow:1,flexShrink:0,marginLeft:16,marginRight:16}}>
            DETAILS
          </div>
          <div className={classes.tableTitle}  style={{width:260,flexShrink:0,marginRight:16}}>
            CREATED ON
          </div>
          <div className={classes.tableTitle} style={{width:120,flexShrink:0}}>
            ACTIONS
          </div>
        </div>
      </div>
      {
        historyData.error && 
        <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading history</Typography>
      }
      {
        historyData.data && historyData.data.total===0 && 
        <Typography style={{color:theme.palette.primaryText.main}}>No history found</Typography>
      }
      {
        historyData.data && historyData.data.items.map(el=>(
          <div>
            <div className={classes.columnRow}>
              <KTooltip classes={{tooltip:classes.tooltip}} title={getStatusTooltip(el.status)}>
                <div style={{width:150,flexShrink:0,marginRight:10}}>
                  {getIcon(el)}
                </div>
              </KTooltip>
              <KTooltip classes={{tooltip:classes.tooltip}} title={getExtractName(el.args?.lookup_code,{...el.args?.filters})}>
                <div style={{width:284,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',}}>
                  {getExtractName(el.args?.lookup_code,{...el.args?.filters})}
                </div>
              </KTooltip>
              <KTooltip classes={{tooltip:classes.tooltip}} title={getExtractDetails(el.args?.filters,false,el,state.sources,objectDetailMap).split(', ').join('\n')}>
                <div style={{flexGrow:1,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',paddingRight:16,marginLeft:16}}>
                  {getExtractDetails(el.args?.filters,true,el, state.sources, objectDetailMap)}
                </div>
              </KTooltip>
              <div style={{width:260,marginRight:16,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                {moment(el.user_created_at).format('llll')}
              </div>
              <div style={{width:120,flexShrink:0,display:'flex'}}>
                {
                  el.status==='COMPLETE'?
                  <KTooltip title={"Download the extract"}>
                    <IconButton style={{marginRight:8,padding:6}} disabled={retrying} onClick={()=>onClickHistory(el)} >{getIconComponent({label:'download',size:24,colour:theme.palette.primaryText.light})}</IconButton>
                  </KTooltip>
                  :
                  <div style={{height:36,width:36,marginRight:8}}></div>
                }
                {
                  el.status!=='RUNNING' ?
                  <KTooltip title={"Rerun the extract"}>
                    <IconButton style={{padding:6,marginRight:8}} disabled={retrying} onClick={()=>onRerunExtract(el)} >{getIconComponent({label:'refresh',size:24,colour:theme.palette.primaryText.light})}</IconButton>
                  </KTooltip>
                  :
                  <div style={{height:36,width:36,marginRight:8}}></div>
                }
                {
                  ['RUNNING','SUBMITTED'].includes(el.status) && 
                  <KTooltip title="Cancel job">
                    <IconButton 
                      onClick={(event) => {
                        event.stopPropagation();
                        setCancelModalOpen({item:el,type:'single'});
                      }}
                      style={{padding:6}}
                    >
                      {getIconComponent({label:'clear_circle',size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  </KTooltip>
                }
              </div>
            </div>
          </div>
        ))
      }
      <div ref={scrollRef} style={{marginBottom:40,display:'flex',justifyContent:'center'}}>
        {
          historyData.loading && 
          <CircularProgress color='secondary'/>
        }
      </div>

      <CancelModal
        modalOpen={cancelModalOpen}
        setModalOpen={setCancelModalOpen}
        loadMoreHistory={loadHistory}
      />
    </div>  
  )
}

export default withTheme()(withStyles(styles)(History));