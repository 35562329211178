import React, { } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, IconButton } from '@material-ui/core';
// import { getIconComponent } from '../../../utilities';
import SearchSelector from '../../UI/SearchSelector/SearchSelector';
import { collectionIds, getIconComponent } from '../../../utilities';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root:{
    maxWidth:800
  },
  title:{
    fontSize:20,
    color:theme.palette.primaryText.main,
  },
  subtitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:24
  },
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:1.5,
    marginTop:36,
    marginBottom:16
  }
})

function AddProperties(props) {

  const {
    theme,
    classes,
    properties,
    setProperties,
  } = props;

  const [searchValue, setSearchValue] = React.useState('');

  const {
    sendAlert
  } = useAlert({})

  let manualProperties = properties.filter(el=>!el.id.includes('default_prop_'))

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Add properties to template</Typography>
      <Typography className={classes.subtitle}>
        Add properties to the bulk update template. Default properties cannot be removed. Max 15 properties can be included.  
      </Typography>
      <SearchSelector 
        url='/solr/search/select'
        testID={'add-property-search'}
        params={{
          q: `${searchValue}*`,
          fq:`object_type_srt:COLLECTION AND ` + 
              `(collection_type_srt:(GLOSSARY OR DATA_MANAGEMENT OR DATA_GOVERNANCE OR COLLABORATION) OR id:${collectionIds.category})`+ 
              `${manualProperties.length>0?`AND -id:(${manualProperties.map(p=>p.id).join(' ')})`:''}`,
          fl:'*',
          sort:'name_srt asc',
          rows:10
        }}
        autoSuggestion
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        placeholder='Search for a property' 
        onResultClick={el=>{
          if(properties.length>=15){
            sendAlert({message:"You can only select max 15 properties to edit",type:'error'})
            return;
          }
          setProperties([...properties,el])
        }}
      />
      {
        properties.filter(p=>!p.id.includes('default_prop_')).length>0 && 
        <>
          <Typography className={classes.sectionHeader}>CUSTOM PROPERTIES ADDED</Typography>
          {
            properties.filter(p=>!p.id.includes('default_prop_')).map(p=>(
              <div style={{display:'flex',alignItems:'center',paddingLeft:16,height:48,borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}}>
                {getIconComponent({label:'info',size:24,colour:theme.palette.primaryText.light})}
                <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main,marginLeft:16,flexGrow:1,marginRight:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{p.name_txt}</Typography>
                <IconButton onClick={()=>{setProperties(properties.filter(d=>d.id!==p.id))}}>
                  {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              </div>
            ))
          }
        </>
      }

      <Typography className={classes.sectionHeader} >DEFAULT PROPERTIES ADDED</Typography>
      {
        properties.filter(p=>p.id.includes('default_prop_')).map(p=>(
          <div style={{display:'flex',alignItems:'center',paddingLeft:16,height:48,borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}}>
            {getIconComponent({label:'info',size:24,colour:theme.palette.primaryText.light})}
            <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main,marginLeft:16,flexGrow:1,marginRight:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{p.name_txt}</Typography>
          </div>
        ))
      }
    </div>
  )
}

AddProperties.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(AddProperties));