import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Avatar, IconButton } from '@material-ui/core';
import TeamSelector from '../../UI/SearchSelector/TeamSelector';
import { collectionIds, getCustomerName, getIconComponent, getInitials } from '../../../utilities'
import { lighten } from '@material-ui/core/styles/colorManipulator';
import SearchSelector from '../../UI/SearchSelector/SearchSelector';

const styles = theme => ({
  block: {
    marginBottom: '5rem',
    // width: '40rem'
  },
  subTitle: {
    color: theme.palette.primaryText.main,
    fontSize: 20,
    marginBottom:2
  },
  subLine: {
    width:640,
    color: theme.palette.primaryText.light,
    whiteSpace:'pre-wrap',
    fontSize: 12,
    marginBottom:16
  },
  welcomeTitle:{
    fontSize:20,
    color:theme.palette.primaryText.main,
    marginBottom:6
  },
  welcomeText:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    whiteSpace:'pre-wrap',
    marginBottom:40,
    lineHeight:2.25
    // width:640,
  },
  selector:{
    ...theme.components.selector,
    width:'100%',
    height:50,
    '& div div':{
      padding:'6px 16px',
      fontSize:16
    }
  },
  avatar: {
    fontSize: 12,
    height: 24,
    width: 24,
    backgroundColor: theme.palette.avatar.main,
    marginRight:12
  },
  itemChip:{
    width:'max-content',
    maxWidth:'100%',
    overflow:'hidden',
    display:'flex',
    alignItems:'center',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    borderRadius:16,
    padding:'4px 6px',
    marginRight:8,
    marginBottom:8,
    '& span':{
      padding:'0 8px',
      fontSize:13.75,
      // marginLeft:4
    }
  },
  targetItemChip:{
    background:theme.palette.primary.dark,
    color:theme.palette.background.main
  },
  clearButton:{
    marginLeft:16,
    width:16,
    height:16,
    '&:hover':{
      background:`${lighten(theme.palette.primary.dark,0.4)} !important`
    }
  }
})

const AddDetails = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
  } = props;

  let customerName = getCustomerName();

  const [searchValue, setSearchValue] = useState('')
  const [jobTypeSearchValue, setJobTypeSearchValue] = useState('')

  return (
    <div>
      <div className={classes.block}>
        <Typography className={classes.welcomeTitle}>Welcome to K</Typography>
        <Typography className={classes.welcomeText}>
            K is your guide to discover, understand, trust and govern data and analytical assets across {customerName!==''?'the ' + customerName:"your organisation's"} data ecosystem.
            {'\n'}
            To get started, join a Team, add your Job Type and link your accounts across the data sources that you use. 
            {'\n'}
            You can update your details at any time by going to Profile Settings. It is available by clicking on your Avatar in the bottom left corner.
        </Typography>

        <Typography className={classes.subTitle}>Team</Typography>
        <Typography className={classes.subLine}>
          Join a Team in K. {'\n'}Contact your Administrator if you cannot find your team. 
        </Typography>
        <div style={{width:450}}>
          <TeamSelector
            clearTeam={(el)=>dispatch({type:'set_team',team:state.team.filter(t=>t.id!==el.id)})}
            autoSuggestion={true}
            selectedGroup={[]}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            removeFLModify={true}
            hideTeamChip={true}
            setGroup={el=>{!state.team.find(t=>t.id===el.id) && dispatch({ type: 'set_team', team: [...state.team,el] })}}
            multiple
          /> 
          <div style={{display:'flex',marginTop:-8,flexWrap:"wrap",width:560}}>
            {state.team?.map(el=>(
              <div  className={classes.itemChip + ' ' + classes.targetItemChip}>
                <Avatar align='center' className={classes.avatar}>{getInitials(el.name_txt)}</Avatar>
                <Typography style={{color:theme.palette.background.main}}>{el.name_txt}</Typography>
                <IconButton 
                  className={classes.clearButton} 
                  onClick={()=>{
                    dispatch({type:'set_team',team:state.team.filter(t=>t.id!==el.id)})
                  }}
                >
                  {getIconComponent({label:'clear', size:16,colour:theme.palette.background.main})}
                </IconButton>
              </div>
            ))}
          </div>
        </div>

        <div style={{width:450}}>
          <Typography className={classes.subTitle} style={{marginTop:46}}>Job Type</Typography>
          <Typography className={classes.subLine}>
            Select the job type that matches the work you do. You can select more than 1 job type. 
          </Typography>
          <SearchSelector
            url={'/solr/search/select'}
            params={{
              q:`${jobTypeSearchValue}*`,
              fq:`object_type_srt:COLLECTION_INSTANCE AND parent_id_srt:${collectionIds.jobType} ${state.jobTypes.length>0?`AND -id:(${state.jobTypes.map(el=>el.id).join(' OR ')})`:''}`,
              fl:'*',
              sort:'name_srt asc',
              rows:10
            }}
            hideTeamChip={true}
            searchValue={jobTypeSearchValue}
            setSearchValue={setJobTypeSearchValue}
            onResultClick={el=>{
              dispatch({type:'set_job_types',jobTypes:[...state.jobTypes,el]})
              setJobTypeSearchValue('')
            }}
            placeholder={'Search for a Job Type'}
            autoSuggestion={true}
          /> 
          <div style={{display:'flex',marginTop:12,flexWrap:"wrap",width:560}}>
            {state.jobTypes?.map(el=>(
              <div  className={classes.itemChip + ' ' + classes.targetItemChip}>
                <Typography  style={{color:theme.palette.background.main,marginLeft:6}}>{el.name_txt}</Typography>
                <IconButton 
                  className={classes.clearButton} 
                  onClick={()=>{
                    dispatch({type:'set_job_types',jobTypes:state.jobTypes.filter(t=>t.id!==el.id)})
                  }}
                >
                  {getIconComponent({label:'clear', size:16,colour:theme.palette.background.main})}
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

AddDetails.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
}

export default withTheme()(withStyles(styles)(AddDetails));