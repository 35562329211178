export const getContentContainerStyle = (maxWidth) => {
  // let isScrollY = document.body.scrollHeight>window.innerHeight
  let navBarWidth = 40;
  if(window.innerWidth>1920){
    navBarWidth = 175
  }
  if(localStorage.hasOwnProperty('navBarWidth')){
    try{
      navBarWidth = Number(localStorage.getItem('navBarWidth')).valueOf()
    }catch{}
  }
  let isScrollY = true;
  if(maxWidth && window.innerWidth-80>maxWidth){
    return {
      navBarWidth,
      width:window.innerWidth-navBarWidth-(isScrollY?16:0),
      paddingLeft:Math.max(80,(window.innerWidth-40-maxWidth)/2),
      paddingRight:Math.max(80,(window.innerWidth-40-maxWidth)/2),
    }
  }
  if(window.innerWidth<=2560){
    return {
      navBarWidth,
      width:window.innerWidth-navBarWidth-(isScrollY?16:0),
      paddingLeft:window.innerWidth<=1350?40:80,
      paddingRight:window.innerWidth<=1350?40:80,
    }
  }else{
    return {
      navBarWidth,
      width:window.innerWidth-navBarWidth-(isScrollY?16:0),
      paddingLeft:Math.max(80,(window.innerWidth-40-2560)/2),
      paddingRight:Math.max(80,(window.innerWidth-40-2560)/2),
    }
  }
}