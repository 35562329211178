import React, { useEffect, useRef, useState }  from 'react';
import { withStyles, withTheme, Typography, CircularProgress, Select, MenuItem, Button} from '@material-ui/core';
import TimelineVertical from '../Timeline/TimelineVertical/TimelineVertical';
import PropTypes from 'prop-types';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import axiosCerebrum from '../../../axios-cerebrum';
import ChangeCard from './ChangeCard';
import { toTitleCase } from '../../../utilities';
import DropdownIcon from '@material-ui/icons/ArrowDropDownCircle'
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:1.5,
    marginRight:4
  },
  timeSelector:{
    ...theme.components.inputBase,
    borderRadius:16,
    border:`1px solid ${theme.palette.primary.main}`,
    background:'transparent',
    '& input':{
      padding:'6px 12px',
      cursor:'pointer',
      color:theme.palette.primary.main,
    },
    '& div':{
      color:theme.palette.primary.main,
    }
  },
  hideScroll:{
    ...theme.components.hideScroll
  }
})

const ChangeManager = props => {

  const {
    classes,
    theme,
    history,
    state,
    dispatch
  } = props;

  const timePeriod = 30;

  const [maxCardHeight, setMaxCardHeight] = useState(700)
  const [lookBehindDate, setLookBehindDate] = useState(timePeriod)
  const [changeLoadDate, setChangeLoadDate] = useState(state.changeDate)
  const scrollPositionRef = React.useRef(0)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  let descObj = state.changes?.descList||{}
  let descList = [];
  Object.keys(descObj).forEach(k=>descList.push(...(descObj[k]?.items||[])))
  let ascList = (state.changes?.ascList?.items || []).filter(c=>!descList.find(d=>d.id===c.id));

  const changeList = ascList.concat(descList)

  const loadChange = async ({startDate, endDate, changeType=state.changeType||'all', page=1, isLookBehind, isReset}) => {
    let forceStartDate;
    if(page===1 && (isReset || endDate===formatEndByDate(state.changeDate||moment()))){
      dispatch({type:'set_changes',changesLoading:true})
    }
    if(endDate && !startDate){
      forceStartDate = formatStartByDate(moment(endDate).add(-timePeriod,'day'))
    }
    await axiosCerebrum
      .get(
        `/api/generalisedmetrics`,{
          params:{
            target_node_id:state.basicData.id,
            end_timestamp:endDate,
            start_timestamp:startDate || forceStartDate,
            page,
            per_page:10,
            metric_types:changeType==='all'?'DETECTED_CHANGE,K_METADATA_CHANGE,K_LINKAGE_CHANGE':changeType,
            sort:startDate?'START_ASC':'START_DESC'
          }
        }
      )
      .then(response=>{
        if(endDate){
          if(page===1){
            dispatch({
              type:'set_changes',
              changes:{
                descList:{
                  ...(isReset?{}:state.changes?.descList||{}),
                  [endDate]:{...response.data}
                }
              }
            })
            if(response.data.total===0 && changeList.length!==0){
              sendAlert({message:`No changes found from ${moment(forceStartDate).format('ll')} to ${moment(endDate).format('ll')}`,type:'info'})
            }
            if(isLookBehind)setLookBehindDate(lookBehindDate+timePeriod)
          }else{
            dispatch({
              type:'set_changes',
              changes:{
                ...state.changes,
                descList:{
                  ...(state.changes?.descList||{}),
                  [endDate]:{
                    ...response.data,
                    items:[...(state.changes.descList?.[endDate]?.items||[]),...response.data.items]
                  }
                }
              }
            })
          }
        }
        else{
          if(response.data.total===0){
            sendAlert({message:'No more changes found',type:'info'})
          }
          response.data.items.reverse();
          if(page===1){
            dispatch({
              type:'set_changes',
              changes:{
                ...state.changes,
                ascList:{
                  ...response.data,
                }
                
              }
            })
          }else{
            dispatch({
              type:'set_changes',
              changes:{
                ...state.changes,
                ascList:{
                  ...response.data,
                  items:[...response.data.items,...state.changes.ascList.items]
                }
              }
            })
          }
        }
        
      })
      .catch(error=>{
        console.log(error)
        if(page===1 && !startDate){
          dispatch({type:'set_changes',changesError:true})
        }else{
          sendAlert({message:'Error occurred loading changes, please try again',type:'error'})
        }
      })
  }

  const formatStartByDate = date => {
    return moment(date).add(1,'day').format('YYYY-MM-DDT00:00:00.000000Z')
  }

  const formatEndByDate = (date) => {
    return moment(date).format('YYYY-MM-DDT23:59:59.999999Z')
  }

  const onLoadMoreNewerChanges = async () => {
    await loadChange({startDate:formatStartByDate(state.changeDate),page:(state.changes.ascList?.page || 0) + 1})
  }

  const onLoadMoreOlderChanges = async () => {
    let endDate = formatEndByDate(changeLoadDate);
    if(state.changes.descList[endDate] && state.changes.descList[endDate].page<state.changes.descList[endDate].pages ){
      await loadChange({endDate,page:state.changes.descList[endDate].page+1})
    }else{
      await onLoadNextPeriod()
    }
  }

  const onDateChange = value => {
    loadChange({endDate:formatEndByDate(value),isReset:true})
    setLookBehindDate(timePeriod)
    dispatch({type:'set_change_date',changeDate:value})
    setChangeLoadDate(value)
    dispatch({type:'set_selected_change'})
  }

  const onTypeChange = value => {
    setChangeLoadDate(state.changeDate)
    setLookBehindDate(timePeriod)
    loadChange({endDate:formatEndByDate(state.changeDate),page:1,changeType:value,isReset:true})
    dispatch({type:'set_change_type',changeType:value})
    dispatch({type:'set_selected_change'})
  }

  useEffect(()=>{
    if(!state.changes)loadChange({endDate:formatEndByDate(moment())})
  // eslint-disable-next-line
  },[])

  const setCardTopPosition = () => {
    let tab = document.getElementById('profile-tab-bar');
    if(!tab)return;
    setMaxCardHeight(window.innerHeight - tab.getBoundingClientRect().bottom - 156 )
  }

  useEffect(()=>{
    setCardTopPosition()
    // window.removeEventListener('scroll',globalListenerRef.changeCardScroll)
    // globalListenerRef.changeCardScroll = () => {
    //   setCardTopPosition()
    // }
    // window.addEventListener('scroll',globalListenerRef.changeCardScroll)

    // return ()=>{
    //   window.removeEventListener('scroll',globalListenerRef.changeCardScroll)
    // }
  },[])

  const onLoadNextPeriod = async () => {
    await loadChange({
      endDate:formatEndByDate(moment(changeLoadDate).add(-timePeriod,'day')),
      page:1,
      isLookBehind:true
    })
    setChangeLoadDate(moment(changeLoadDate).add(-timePeriod,'day'))
  }

  const isToday = moment(state.changeDate).format('ll') === moment().format('ll')

  const hasDateSelected = state.changeDate &&  moment(state.changeDate).format('ll')!==moment().format('ll')

  return (
    <div>
      <Typography style={{fontSize:20,color:theme.palette.header.main,paddingBottom:8,top:168,position:'sticky',background:theme.palette.background.main}}>
        CHANGES
        <Button
          color='primary'
          style={{marginLeft:4,marginBottom:2}}
          onClick={()=>{
            loadChange({endDate:formatEndByDate(moment()), changeType:'all', isReset:true})
            dispatch({
              type:'set_change_date',
            })
            dispatch({
              type:'set_change_type',
              changeType:'all'
            })
            setLookBehindDate(timePeriod)
            setChangeLoadDate()
          }}
        >
          RESET
        </Button>
      </Typography>
      <div style={{display:'flex',alignItems:'flex-start'}}> 
        <div style={{flex:'0 0 440px',marginRight:80}}>
          <div style={{marginBottom:32,display:'flex',alignItems:'center'}}>
            <Typography className={classes.sectionHeader}>GO TO DATE:</Typography>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk
                className={classes.timeSelector}
                format='ll'
                maxDate={moment()}
                DialogProps={{
                  PaperProps:{
                    "data-test-id":"change-date-picker"
                  }
                }}
                style={{margin:0,width:hasDateSelected?144:100}}
                InputProps={{
                  disableUnderline:true,
                  inputProps:{
                    'data-test-id':"change-date-selector",
                  },
                  endAdornment:<DropdownIcon style={{color:theme.palette.primaryText.light+'95',width:24,height:24,marginRight:4,cursor:'pointer'}}/>
                }}
                value={state.changeDate||null}
                emptyLabel="Latest"
                labelFunc={value=>{
                  if(!value)return 'Latest'
                  let v = moment(value).format('ll');
                  if(v === moment().format('ll'))return 'Latest'
                  return v
                }}
                onChange={onDateChange}
              />
            </MuiPickersUtilsProvider>
            <Typography className={classes.sectionHeader} style={{marginLeft:22}}>SHOW:</Typography>
            <Select
              className={classes.timeSelector}
              style={{width:130}}
              value={state.changeType||'all'}
              onChange={event=>onTypeChange(event.target.value)}
              disableUnderline
              renderValue={value=>{
                return <span style={{color:theme.palette.primary.main,paddingLeft:12}}>{value==='all'?'All changes':toTitleCase(value.replace(/_/g,' '))}</span>
              }}
              inputProps={{
                'data-test-id':"change-type-selector"
              }}
              IconComponent={'none'}
              endAdornment={<div style={{width:0,overflow:'visible',pointerEvents:'none',position:'relative',left:-27,top:2}}> <DropdownIcon style={{color:theme.palette.primaryText.light+'95',width:24,height:24,marginRight:4,cursor:'pointer'}}/></div>}
            > 
              <MenuItem value='all'>
                All changes
              </MenuItem>
              {
                ['DETECTED_CHANGE','K_METADATA_CHANGE','K_LINKAGE_CHANGE'].map(el=>(
                  <MenuItem value={el}>
                    {toTitleCase(el.replace(/_/g,' '))}
                  </MenuItem>
                ))
              }

            </Select>
          </div>
          {
            state.changesLoading && 
            <div style={{textAlign:'center'}}>
              <CircularProgress color='secondary'/>
            </div>
          }
          {
            state.changesError && 
            <Typography style={{fontSize:13.75}}>Error occurred loading changes</Typography>
          }
          {
            changeList.length===0 && !state.changesLoading && 
            <>
              <Typography style={{fontSize:13.75}}>No changes in the last {lookBehindDate} days.</Typography>
              <Typography 
                style={{fontSize:13.75,marginTop:24,color:theme.palette.hyperLink.main,textDecoration:'underline',cursor:'pointer'}}
                onClick={()=>{
                  onLoadNextPeriod()
                }}
              >
                Check for changes in the next {timePeriod} day period
              </Typography>
            </>
          }
          <div 
            onScroll={event=>{
              if(scrollPositionRef.current - event.target.scrollTop>260){
                event.target.scrollTo(0,scrollPositionRef.current)
              }else{
                scrollPositionRef.current = event.target.scrollTop
              }
            }} 
            style={{maxHeight:maxCardHeight-30,overflow:'auto'}} 
            className={classes.hideScroll}
          >
            {
              changeList.length>0 && 
              <TimelineVertical
                items={
                  changeList.map(c=>(
                    {
                      name: toTitleCase(c.metric_type.name.replace(/_/g,' ')), 
                      time: moment(c.timestamp).format('ll')+'\n'+moment(c.timestamp).format('HH:mm:ss'), 
                      iconLabel: 'change' ,
                      selected: state.selectedChange && state.selectedChange.id===c.id,
                      onClick: () => dispatch({type:'set_selected_change',selectedChange:c})
                    }
                  ))
                }
                bottomAction={
                  // state.changes.descList.page<state.changes.descList.pages && 
                  {
                  onClick: onLoadMoreOlderChanges,
                  iconLabel:'more_horiz'
                }}
                topAction={!isToday && (!state.changes.ascList || state.changes.ascList.page<state.changes.ascList.pages) && {
                  onClick: onLoadMoreNewerChanges,
                  iconLabel:'more_horiz'
                }}
                bottomText={moment(changeLoadDate).add(-30,'days').format('ll')}
              />
            }
          </div>
        </div>
        <div style={{flex:'1 1',overflow:'hidden'}}>
          <Typography className={classes.sectionHeader} style={{marginBottom:24}}>CHANGE DETAILS</Typography>
          {
            !state.selectedChange && 
            <Typography style={{fontSize:13.75}}>Select a change to see more details</Typography>
          }
          {
            state.selectedChange && 
            <ChangeCard
              key={state.selectedChange.id}
              onClose={()=>dispatch({type:'set_selected_change'})}
              changeItem={state.selectedChange}
              object={state.basicData}
              history={history}
              maxHeight={maxCardHeight}
              onUpdateNote={newChange=>{
                dispatch({type:'set_selected_change',selectedChange:newChange})
                let ascList = state.changes.ascList;
                if(ascList){
                  ascList = {
                    ...ascList,
                    items:ascList.items.map(el=>{
                      if(newChange.id!==el.id)return el;
                      return newChange
                    })
                  }
                }
                let descList = state.changes.descList;
                let newDescList = {}
                if(descList){
                  Object.keys(descList).forEach(k=>{
                    newDescList[k] = {
                      ...descList[k],
                      items:descList[k].items?.map(el=>{
                        if(newChange.id!==el.id)return el;
                        return newChange
                      })
                    }
                  })
                }
                dispatch({
                  type:'set_changes',
                  changes:{ascList, descList:newDescList}
                })
              }}
            />
          }
        </div>
      </div>
      
	  </div>
  )
}

ChangeManager.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  state:PropTypes.object.isRequired,
  dispatch:PropTypes.func.isRequired,
}


export default withTheme()(withStyles(styles)(ChangeManager));