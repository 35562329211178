import React from 'react';
import PropTypes from 'prop-types';
import { withStyles} from '@material-ui/core';
import Dashboard from '../../UI/Dashboard/DirectIndirectDashboard/DirectIndirectDashboard';
import Details from '../Details/Details';
import RelatedSearch from '../../UI/RelatedSearch/RelatedSearch';
// import Reference from '../Reference/Reference'
import NoteAdder from '../../UI/NoteAdder/NoteAdder'
import Issues from '../../UI/ProfileIssueSection/Issues';
import NewLineageContainer  from '../../UI/Lineage/NewLineageContainer';
import ChangeManager from '../../UI/ChangeManager/ChangeManager';
import DQInsights from '../../UI/DQInsights/DQInsights';

const styles = theme => ({
  root: {

  }
})

const Body = props => {

  const {
    classes,
    tabState,
    history,
    dispatch,
    state,
    sessionData,
    fetchList,
    setLinkModalOpen,
    tabOptions,
    isLineageLinkable
  } = props;

  let bodyContent;
  if(state.basicData){
    switch (tabOptions[tabState]) {
      case 'DETAILS': // DETAILS
        bodyContent = (
          <Details
            userName={sessionData.user_name}
            history={history}
            sessionData={sessionData}
            profileState={state}
            profileDispatch={dispatch}
            fetchList={fetchList}
            setLinkModalOpen={setLinkModalOpen}
          >
          </Details>
        );
        break;
      case 'KNOWLEDGE':
        bodyContent = (
          <NoteAdder
            history={history}
            object={state.basicData}
            state={state}
            dispatch={dispatch}
            modalOpen={state.noteModalOpen}
            setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
          />
        )
        break;
      case 'USAGE': // USAGE
        bodyContent = (
          <Dashboard
            label='column'
            dispatch={dispatch}
            history={history}
            state={state}
            drillThrough={true}
          />
        )
        break;
      case 'LINEAGE': // LINEAGE
        bodyContent = (
          <NewLineageContainer
            history={history}
            state={state}
            dispatch={dispatch}
            isLinkable={isLineageLinkable}
          />
        )
        break;
      case 'ISSUES':
        bodyContent = (
          <Issues
            history={history}
            state={state}
            dispatch={dispatch}
          />
        )
        break;
      case 'RELATED': // related content
        bodyContent = (
          <RelatedSearch
            history={history}
            nodeKey={state.basicData.signature}
            target={state.basicData}
            targetLabel='column'
            solrField='columns_str'
            newSolrField="columns_full_name_msrt"
            relatedContentData={state.relatedContentData}
            setRelatedContentData={data=>dispatch({type:'set_related_data',relatedContentData:data})}
          />
        )
        break;
      case 'QUALITY':
        bodyContent = (
          <DQInsights
            state={state}
            history={history}
            dispatch={dispatch}
            object={state.basicData}
          />
        )
        break;
      case 'CHANGES':
        bodyContent = (
          <ChangeManager state={state} dispatch={dispatch} history={history}/>
        )
        break;
      default:
    }
  }
  
  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
  tabUrlPrefix: PropTypes.string,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.object,
  sessionData: PropTypes.object
}

export default withStyles(styles)(Body);