import { getIconLabel } from './components/UI/SearchResults/utils'
// type: profile, app
export const addHistory = ({url, title, subTitle, object, type, iconLabel}) => {
  let historyObj = {url, title, subTitle, object, type, iconLabel};
  if(!iconLabel && object && object.object){
    historyObj.iconLabel = getIconLabel({label:object.object.name,item:object}) ;
  }
  let previousHistory = [];
  if(localStorage.hasOwnProperty('browseHistory')){
    try{
      previousHistory = JSON.parse(localStorage.getItem('browseHistory'))
    }catch{}
  }
  localStorage.setItem(
    'browseHistory',
    JSON.stringify(
      [
        historyObj,
        ...previousHistory.filter(el=>type==='profile'?el.url.split('?')[0].toLowerCase()!==url.split('?')[0].toLowerCase():el.url!==url)
      ].slice(0,50)
    )
  ) 
}

export const loadHistory = () => {
  let history;
  try{
    history = JSON.parse(localStorage.getItem('browseHistory'))
  }catch{
    history = []
  }
  return history || [];
}

export const findHistoryByObjectType = ({type,ignoreCurrentUrl=true}) => {
  let history;
  try{
    history = JSON.parse(localStorage.getItem('browseHistory'))
    history = history.filter(el=>el.type==='profile' && (!ignoreCurrentUrl || window.location.pathname!==el.url) && el.object && el.object.object && el.object.object.name.toUpperCase()===type.toUpperCase())
  }catch(error){
    console.log(error)
    history = []
  }
  return history || []
}
