import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import InfoBox from '../InfoBox/InfoBox';
import List from '../List/List';
import TabBar from '../../UI/TabBar/TabBar';
import HistoryList from '../History/HistoryList';


const styles = theme => ({
  
})

function Body(props) {

  const {
    classes,
    state,
    sessionData,
    dispatch,
    variant,
    history
  } = props;


  let tabOptions = ['ALERTS'];
  if(variant==='admin'){
    tabOptions = ['ALERTS','HISTORY'];
  }
  return (
    <div className={classes.root}>
      <InfoBox dispatch={dispatch} state={state} history={history} sessionData={sessionData} variant={variant}/>
      <div style={{marginLeft:-56}}>
        <TabBar
          tabOptions={tabOptions}
          tabState={state.tabState}
          setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
          minWidth={200}
          maxWidth={200}
          disableUnderline={true}
        />
      </div>
      {
        state.tabState===0 && 
        <List dispatch={dispatch} state={state} history={history} sessionData={sessionData} variant={variant}/>
      }
      {
        state.tabState===1 && 
        <HistoryList dispatch={dispatch} state={state} history={history} sessionData={sessionData} variant={variant}/>
      }
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default withStyles(styles)(Body);