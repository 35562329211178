import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { getIconComponent, getInitials, getLabelPlural, headerAutoCollectionList } from '../../../utilities';
import { Typography } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import { globalListenerRef } from '../../../GlobalListenerRef';
import Tags from './Tags';
import BreadCrumb from './BreadCrumb';
import TitleSection from './TitleSection'
import ProfileBanner from './ProfileBanner';

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexGrow: 1,
    minHeight: 120,
    background: theme.palette.background.main
  },
  subtextReadOnly: {
    textTransform: 'capitalize',
    fontSize: `13.75px !important`,
    '& span': {
      fontSize: `13.75px !important`,
    },
    marginTop: 20,
    whiteSpace: 'pre-wrap',
    color: theme.palette.primaryText.main,
  },
  progress: {
    padding: '12px'
  },
  button: {
    margin: theme.spacing.unit,
    alignSelf: 'flex-start',
    paddingRight: 24,
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing.unit,
  },
  profileIcon: {
    marginTop: 48,
    marginLeft: -58,
  },
  mainContent: {
    flexGrow: 1,
    marginLeft: 18,
    width: '100%'
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'start',
    flexGrow: 0,
    flexShrink: 0,
    marginTop: 0
  },
  description: {
    whiteSpace: 'pre-wrap',
    fontSize: '0.85rem',
    marginTop: '1rem',
    color: '#888'
  },
  '@media (max-width: 1350px)': {
    profileIcon: {
      display: 'none'
    },
    mainContent: {
      marginLeft: 0
    }
  },
  headerAvatar: {
    background: theme.palette.avatar.main,
    color: '#fff',
    width: 80,
    height: 80,
    borderRadius: 40,
    marginRight: 30,
    marginTop: 32,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 32,
  },
});



const ProfileHeader = props => {

  const {
    theme,
    classes,
    label,
    iconLabel,
    title,
    copiedTitle,
    subtitle,
    description,
    buttons,
    data,
    tags,
    // creatorData,
    sourceType,
    history,
    isShell,
    // banner
    tabOptions,
    bannerdisplayText,
    bannerCause,
    bannerData,
    bannerAction,
    state,
    dispatch,
    tagsFetch,
    shouldLoadLinkedInstance,
    shouldLoadBreadCrumb,
    // linkedInstances,
    // linkedInstanceFetch,
    //////
    avatarIcon,
    ///
    customTags,
    ////
    onClickAddTag,
    // setEditing,
    minHeight = 140
  } = props;

  const [hoveredTag, setHoveredTag] = useState()

  let linkedInstances = state && state.collectionInstancesData ? state.collectionInstancesData.items : []

  const linkedInstanceFetch = () => {
    const loadInstances = async () => {
      let autoRes = []
      await axiosCerebrum
        .get(
          '/api/stats',
          {
            params: {
              object_ids: data.id
            }
          }
        )
        .then(response => {
          let item = response.data.items[0];
          headerAutoCollectionList.forEach(el => {
            if (item[el] === null || !item[el]) return;
            autoRes.push({
              name: item[el],
              parent_name: el,
              isAuto: true
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
      let manualRes = await axiosCerebrum
        .get(
          `/api/${getLabelPlural(data.object ? data.object.name : label)}/${data.id.toLowerCase()}/related/collections`,
          {
            params: {
              category: 'DATA_MANAGEMENT,DATA_GOVERNANCE,PLATFORM',
              relationship: 'MEMBER_OF,MEMBER_OF_AUTO',
              per_page: 300
            }
          }
        )
        .catch(error => {
          console.log(error)
        })
      let finalRes = {
        total: (autoRes.length) + (manualRes ? manualRes.data.total : 0),
        items: [...autoRes, ...(manualRes ? manualRes.data.items : [])]
      }
      dispatch({
        type: 'set_collection_instances',
        collectionInstancesData: finalRes
      })
    }
    loadInstances()
  }

  useEffect(() => {
    window.removeEventListener('message', globalListenerRef.headerInstanceReloadListener)
    if (shouldLoadLinkedInstance && !state.collectionInstancesData) {
      linkedInstanceFetch()
      globalListenerRef.headerInstanceReloadListener = (msg) => {
        if (msg.data.reload_header_instance) {
          linkedInstanceFetch();
        }
      }
      window.addEventListener('message', globalListenerRef.headerInstanceReloadListener)
    }

    return () => {
      window.removeEventListener('message', globalListenerRef.headerInstanceReloadListener)
    }

    // eslint-disable-next-line
  }, [])
  
  return (
    <div>
      <div className={classes.root} style={{ minHeight: minHeight }}>
        {
          (iconLabel || label) && !avatarIcon && (!sourceType || !getIconComponent({ label: sourceType })) &&
          <div className={classes.profileIcon} >
            {getIconComponent({ label: iconLabel || label, size: 40, colour: isShell ? theme.palette.primaryText.light : theme.palette.header.main })}
          </div>
        }
        {
          sourceType && !isShell && getIconComponent({ label: sourceType }) &&
          <div className={classes.profileIcon}>
            {
              getIconComponent({ label: sourceType, size: 40, colour: theme.palette.primary.main })
            }
          </div>
        }
        {
          sourceType && isShell &&
          <div className={classes.profileIcon} >
            {getIconComponent({ label: label, size: 40, colour: isShell ? theme.palette.primaryText.light : theme.palette.header.main })}
          </div>
        }
        {
          avatarIcon && <div className={classes.headerAvatar}>{getInitials(data.name)}</div>
        }

        <div className={classes.mainContent} id='profile-header-main-content' style={{ marginLeft: (iconLabel || label) ? undefined : 0 }}>
          <div style={{ display: 'flex', height: 56, justifyContent: 'space-between', alignItems: 'center', marginTop: -12 }}>
            {
              shouldLoadBreadCrumb ?
                <div style={{ marginTop: 28 }}>
                  <BreadCrumb data={data} />
                </div>
                :
                <Typography className={classes.subtextReadOnly} variant='caption'>{subtitle}</Typography>
            }
            <div className={classes.buttonWrapper}>
              {buttons}
            </div>
          </div>

          <TitleSection
            data={data}
            title={title}
            copiedTitle={copiedTitle}
            description={description}
            label={label}
            state={state}
            dispatch={dispatch}
            isShell={isShell}
          />

          <Tags
            isShell={isShell}
            data={data}
            label={label}
            linkedInstances={linkedInstances}
            linkedInstanceFetch={linkedInstanceFetch}
            tags={tags}
            tagsFetch={tagsFetch}
            hoveredTag={hoveredTag}
            setHoveredTag={setHoveredTag}
            onClickAddTag={onClickAddTag}
            addTagButtonSize={'small'}
            history={history}
            customTags={customTags}
          />
        </div>
      </div>

      <ProfileBanner
        classes={classes}
        data={data}
        label={label}
        history={history}
        tabOptions={tabOptions}
        bannerdisplayText={bannerdisplayText}
        bannerCause={bannerCause}
        bannerData={bannerData}
        bannerAction={bannerAction}
        state={state}
        dispatch={dispatch}
      />
    </div>
  )
}


ProfileHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  copiedTitle: PropTypes.string,
  subtitle: PropTypes.string,
  buttons: PropTypes.array,
  titleMaxLength: PropTypes.number,
  iconLabel: PropTypes.string,
  subHeader: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  // creatorData,
  sourceType: PropTypes.string,
  history: PropTypes.object,
  isShell: PropTypes.string,
  // banner
  tabOptions: PropTypes.array,
  bannerdisplayText: PropTypes.string,
  bannerCause: PropTypes.string,
  bannerData: PropTypes.object,
  bannerAction: PropTypes.func,
  tagsFetch: PropTypes.func,
  shouldLoadLinkedInstance: PropTypes.bool,
  shouldLoadBreadCrumb: PropTypes.bool,
  // linkedInstances: PropTypes.array,
  // linkedInstanceFetch: PropTypes.func,
  //////
  avatarIcon: PropTypes.bool,
  ///
  customTags: PropTypes.array,
  ////
  onClickAddTag: PropTypes.func,
  setEditing: PropTypes.func,
  minHeight: PropTypes.number
}



export default withTheme()(withStyles(styles)(ProfileHeader));