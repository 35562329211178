import React, { useReducer, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/MyEcosystem/Body/Body';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import TabBar from '../../components/UI/TabBar/TabBar';
import 'url-search-params-polyfill';
import { setInitialState, removeUrlQueryArg } from '../../utilities'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import { addHistory } from '../../HistoryManager';
import { assignedObjects, contributedObjects, createdObjects, runObjects, usedObjects } from '../../components/MyEcosystem/utils';

const styles = theme => ({
});

const initialState = {
  viewType:'TIMELINE',
  tabState:0,
  listListSort:'LINKAGE_END_DESC',
  warningListSort:'LINKAGE_END_DESC',
  contributedListObjects:contributedObjects,
  contributedListSort:'LINKAGE_END_DESC',
  usedListObjects:usedObjects,
  usedListSort:'LAST_USED_DESC',
  runListObjects:runObjects,
  runListSort:'LAST_USED_DESC',
  createdListObjects:createdObjects,
  createdListSort:'LINKAGE_END_DESC',
  assignedListObjects:assignedObjects,
  assignedListSort:'LINKAGE_END_DESC',
  followedListSort:'END_DESC',
  usedTabState:0,
  assignedTabState:0,
  contributedTabState:0,
  runTabState:0,
  createdTabState:0,
  followedTabState:0,
};

function reducer(state, action) {
  switch (action.type) {
    case  "set_tab_state":
      return {
        ...state,
        tabState:action.tabState
      }
    case 'set_view_type':
      return {
        ...state,
        viewType:action.viewType
      }
    case 'set_list_timeline':
      return {
        ...state,
        listTimeLineData:action.listTimeLineData,
        listTimeLineLoading:action.listTimeLineLoading,
        listTimeLineError:action.listTimeLineError
      }
    case 'set_list_list':
      return {
        ...state,
        listListData:action.listListData,
        listListLoading:action.listListLoading,
        listListError:action.listListError
      }
    case 'set_list_list_sort':
      return {
        ...state,
        listListSort:action.listListSort
      }
    // create the same as list for warnings, but change list to warning
    case 'set_warning_timeline':
      return {
        ...state,
        warningTimeLineData:action.warningTimeLineData,
        warningTimeLineLoading:action.warningTimeLineLoading,
        warningTimeLineError:action.warningTimeLineError
      }
    case 'set_warning_list':
      return {
        ...state,
        warningListData:action.warningListData,
        warningListLoading:action.warningListLoading,
        warningListError:action.warningListError
      }
    case 'set_warning_list_sort':
      return {
        ...state,
        warningListSort:action.warningListSort
      }
    case 'set_used_tab_state':
      return {
        ...state,
        usedTabState:action.usedTabState
      }
    case 'set_used_timeline':
      return {
        ...state,
        usedTimeLineData:action.usedTimeLineData,
        usedTimeLineLoading:action.usedTimeLineLoading,
        usedTimeLineError:action.usedTimeLineError
      }
    case 'set_used_list':
      return {
        ...state,
        usedListData:action.usedListData,
        usedListLoading:action.usedListLoading,
        usedListError:action.usedListError
      }
    case 'set_used_list_objects':
      return {
        ...state,
        usedListObjects:action.usedListObjects
      }
    case 'set_used_list_sort':
      return {
        ...state,
        usedListSort:action.usedListSort
      }
    case "set_contributed_tab_state":
      return {
        ...state,
        contributedTabState:action.contributedTabState
      }
    case 'set_contributed_timeline':
      return {
        ...state,
        contributedTimeLineData:action.contributedTimeLineData,
        contributedTimeLineLoading:action.contributedTimeLineLoading,
        contributedTimeLineError:action.contributedTimeLineError
      }
    case 'set_contributed_list':
      return {
        ...state,
        contributedListData:action.contributedListData,
        contributedListLoading:action.contributedListLoading,
        contributedListError:action.contributedListError
      }
    case 'set_contributed_list_objects':
      return {
        ...state,
        contributedListObjects:action.contributedListObjects,
      }
    case 'set_contributed_list_sort':
      return {
        ...state,
        contributedListSort:action.contributedListSort
      }
    case 'set_created_tab_state':
      return {
        ...state,
        createdTabState:action.createdTabState
      }
    case 'set_created_timeline':
      return {
        ...state,
        createdTimeLineData:action.createdTimeLineData,
        createdTimeLineLoading:action.createdTimeLineLoading,
        createdTimeLineError:action.createdTimeLineError
      }
    case 'set_created_list':
      return {
        ...state,
        createdListData:action.createdListData,
        createdListLoading:action.createdListLoading,
        createdListError:action.createdListError
      }
    case 'set_created_list_objects':
      return {
        ...state,
        createdListObjects:action.createdListObjects
      }
    case 'set_created_list_sort':
      return {
        ...state,
        createdListSort:action.createdListSort
      }
    case 'set_run_tab_state':
      return {
        ...state,
        runTabState:action.runTabState
      }
    case 'set_run_timeline':
      return {
        ...state,
        runTimeLineData:action.runTimeLineData,
        runTimeLineLoading:action.runTimeLineLoading,
        runTimeLineError:action.runTimeLineError
      }
    case 'set_run_list':
      return {
        ...state,
        runListData:action.runListData,
        runListLoading:action.runListLoading,
        runListError:action.runListError
      }
    case 'set_run_list_objects':
      return {
        ...state,
        runListObjects:action.runListObjects
      }
    case 'set_run_list_sort':
      return {
        ...state,
        runListSort:action.runListSort
      }
    case 'set_assigned_tab_state':
      return {
        ...state,
        assignedTabState:action.assignedTabState
      }
    case 'set_assigned_timeline':
      return {
        ...state,
        assignedTimeLineData:action.assignedTimeLineData,
        assignedTimeLineLoading:action.assignedTimeLineLoading,
        assignedTimeLineError:action.assignedTimeLineError
      }
    case 'set_assigned_list':
      return {
        ...state,
        assignedListData:action.assignedListData,
        assignedListLoading:action.assignedListLoading,
        assignedListError:action.assignedListError
      }
    case 'set_assigned_list_objects':
      return {
        ...state,
        assignedListObjects:action.assignedListObjects
      }
    case 'set_assigned_list_sort':
      return {
        ...state,
        assignedListSort:action.assignedListSort
      }
    case 'set_followed_timeline':
      return {
        ...state,
        followedTimeLineData:action.followedTimeLineData,
        followedTimeLineLoading:action.followedTimeLineLoading,
        followedTimeLineError:action.followedTimeLineError
      }
    case 'set_followed_list':
      return {
        ...state,
        followedListData:action.followedListData,
        followedListLoading:action.followedListLoading,
        followedListError:action.followedListError
      }
    case 'set_followed_tab_state':
      return {
        ...state,
        followedTabState:action.followedTabState
      }
    case 'set_followed_list_sort':
      return {
        ...state,
        followedListSort:action.followedListSort
      }
    default:
      console.log(action)
      throw new Error("Reducer action not supported.", action);
  }
}

const MyEcosystem = props => {
  const {
    history,
    sessionData,
    pageCache,
    storePageCache
  } = props;

  const [state, dispatch] = useReducer(reducer,setInitialState(pageCache,initialState));
  
  useEffect(()=>{
    if(!state)return;
    storePageCache({
      cacheID:window.location.href,
      ...state,
    })
  // eslint-disable-next-line
  },[state])

  useEffect(()=>{
    addHistory({
      url:window.location.pathname, 
      iconLabel:'my_ecosystem', 
      title: 'My Data', 
      subTitle:`My Data`,
      type:'application',
    })
  },[])

  let buttons = [];

  buttons.push(
    <ProfileButton
      text={`SWITCH TO ${state.viewType==='TIMELINE'?'LIST':'TIMELINE'} VIEW`}
      onClick={()=>{dispatch({type:'set_view_type',viewType:state.viewType==='TIMELINE'?'LIST':'TIMELINE'})}}
    />
  )

  const tabOptions = ['LIST','NOTICES','USED','CREATED','CONTRIBUTED','RUN','FOLLOWING']

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])
  
  useEffect(()=>{
    const urlSearch = new URLSearchParams(window.location.search);
    const tabName = urlSearch.get('tabName')
    if(tabName && tabOptions.includes(tabName)){
      dispatch({type:'set_tab_state',tabState:tabOptions.indexOf(tabName)})
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
    }
  // eslint-disable-next-line
  }, [])

  return (
    <ProfileLayout
      header={
        <div>
          <ProfileHeader
            title="My Activity"
            subtitle=" "
            label='my_activity'
            buttons={buttons}
            minHeight={100}
          />
        </div>}
      tabBar={
        <TabBar
          tabOptions={tabOptions}
          tabState={state.tabState}
          setTabState={value => {dispatch({type:'set_tab_state',tabState:value})}}
          minWidth={200}
          maxWidth={200}
          disableUnderline={true}
        />
      }
      body={
        <Body
          history={history}
          state={state}
          dispatch={dispatch}
          sessionData={sessionData}
          tabOptions={tabOptions}
        />
      }
    />
  )
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyEcosystem));