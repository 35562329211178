import React from 'react';
import { withStyles,} from '@material-ui/core';
import AdditionalPropertyAdder from '../../UI/AdditionalPropertyAdder/AdditionalPropertyAdder'

const styles = theme => ({
  root: {
    marginBottom:40
  },
})

const Configuration = props => {

  const {
    classes,
    // history,
    dispatch,
    state,
  } = props;


  return (
    <div className={classes.root}>
      <AdditionalPropertyAdder
        object={state.basicData}
        state={state}
        dispatch={dispatch}
        disableEditing
        title={'CONFIGURATION'}
        subTitle={'Configurations used for this Test'}
      />
    </div>
  )
}


export default withStyles(styles)(Configuration);