import React, {} from 'react';
import { withTheme,  Typography, withStyles } from '@material-ui/core';
import { getDispFields, getIconComponent, onClickResultItem } from '../../../utilities'
import KTooltip from '../../UI/KTooltip/KTooltip';
import { getIconLabel } from '../../UI/SearchResults/utils';

const styles = theme => ({
  root:{
    display:'flex',
    alignItems:'center',
    height:48,
    paddingLeft:16,
    paddingRight:16,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:'pointer',
    '&:hover':{
      backgroundColor:theme.palette.hovered.main
    }
  },
  listText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  }
})

const ListItem = props => {

  const {
    classes,
    theme,
    history,
    item,
    label
  } = props;

  return (
    <div className={classes.root} onClick={()=>onClickResultItem({label:label,id:item.id,item, history,})}>
      <div style={{flexShrink:0,width:24,height:24}}>
        {getIconComponent({label:getIconLabel({label, item}),size:24,colour:theme.palette.primaryText.light})}
      </div>
      <KTooltip title={getDispFields(item,'dispTitle')}>
        <Typography data-test-classname="list-title" className={classes.listText} style={{flex:'1 1 260px',minWidth:100,marginRight:16,marginLeft:16}}>
          {getDispFields(item,'dispTitle')}
        </Typography>
      </KTooltip>
      {
        label==='collection_instance' && item.collection_type_txt!=='LIST' && 
        <KTooltip title={item.collection_txt}>
          <Typography data-test-classname="list-collection" className={classes.listText} style={{flex:'1 1 260px',marginRight:16,marginLeft:16}}>
            {item.collection_txt}
          </Typography>
        </KTooltip>
      }
      {
        label==='report' && 
        <KTooltip title={item.location_txt?.split('/').slice(-1)}>
          <Typography data-test-classname="list-location" className={classes.listText} style={{flex:'1 1 200px',marginRight:32}}>
            {item.location_txt?.split('/').slice(-1)}
          </Typography>
        </KTooltip>
      }
      <KTooltip title={item.description}>
        <Typography data-test-classname="list-description" className={classes.listText} style={{flex:'1 1 320px',marginRight:32}}>
          {item.description || 'No description'}
        </Typography>
      </KTooltip>
      {
        label==='issue' && 
        <Typography data-test-classname="list-issue-seveirty" className={classes.listText} style={{flex:'0 0 200px',marginRight:32}}>
          {item.issue_severity}
        </Typography>
      }
      {
        label==='issue' && 
        <Typography data-test-classname="list-issue-type" className={classes.listText} style={{flex:'0 0 200px',marginRight:32}}>
          {item.issue_type_txt}
        </Typography>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(ListItem));