import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, LinearProgress } from '@material-ui/core';
import {  collectionIds, getDispFields, onClickResultItem  } from '../../../utilities';
import PropTypes from 'prop-types';
import ReactFlowWrapper from '../../UI/ReactFlowWrapper/ReactFlowWrapper';
import { globalListenerRef } from '../../../GlobalListenerRef';
import { SolrLongListLoader } from '../../../LongListLoader';
import ELK from 'elkjs'

const styles = theme => ({

  chartWrapper:{
    width:'100%',
    marginTop:12,
    // '& div':{
    //   zIndex:16
    // }
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main
  },
  button:{
    width:20,
    height:20,
    borderRadius:16,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  emotRoot: {
    marginTop:80,
    display: 'flex',
    flexDirection: 'column',
    alignItems:'center',
    justifyContent:'center'
  },
  emot:{
    fontSize:40,
    color:theme.palette.primaryText.main
  },
  text:{
      fontSize:16,
      color:theme.palette.primaryText.light,
      marginTop:24
  },
})

function DomainMap(props) {

  const {
    classes,
    state,
    dispatch,
    history,
    theme,
  } = props;

  const [graphHeight, setGraphHeight] = useState(Math.max(window.innerHeight,window.outerHeight)-300)
  const [graphWidth, setGraphWidth] = useState()
  const [isFitView, setIsFitView] = useState(false)
  const [centre, setCentre] = useState({x:0,y:0})

  const chartWrapperRef = useRef()
  const nodeWidth = 240;
  const childHeight = 32;
  const levelOneHeaderHeight = 42;
  const childPadding = 16;
  const maxLevel = 3;

  useEffect(()=>{
    window.removeEventListener('resize',globalListenerRef.instanceMapResizeListener)
    globalListenerRef.instanceMapResizeListener = () => {
      if(!chartWrapperRef.current)return;
      setGraphWidth(document.getElementById('profile-content').getBoundingClientRect().width)
      setGraphHeight(Math.max(window.innerHeight,window.outerHeight) - chartWrapperRef.current.getBoundingClientRect().top)
    }
    window.addEventListener('resize',globalListenerRef.instanceMapResizeListener)
    return ()=>window.removeEventListener('resize',globalListenerRef.instanceMapResizeListener)
  // eslint-disable-next-line
  },[])


  useEffect(()=>{
    if(chartWrapperRef.current){
      setGraphWidth(document.getElementById('profile-content').getBoundingClientRect().width)
      setGraphHeight(Math.max(window.innerHeight,window.outerHeight) - chartWrapperRef.current.getBoundingClientRect().top)
    }
  // eslint-disable-next-line
  },[ chartWrapperRef.current])

  const loadNextLevelNodes = ({parentIds, onFinish}) => {
    SolrLongListLoader({
      url:"/solr/search/select",
      params:{
        q:"*",
        fq:`object_type_srt:COLLECTION_INSTANCE AND hierarchy_parent_id_srt:(${parentIds.join(' OR ')})`,
      },
      rows:100,
      onStartLoad:()=>{
        dispatch({type:'set_domain_map_data',domainMapLoading:true})
      },
      onFinishLoad:({data})=>{
        onFinish(data)
      },
      onError:(error)=>{
        console.log(error)
        dispatch({type:'set_domain_map_data',domainMapError:true})
      }
    })
  }

  const getMapCentre = nodes => {
    let parentNodes = nodes.filter(n=>n.level===1);
    if(!parentNodes)return {x:0,y:0}
    let minXValues = parentNodes.map(n=>n.position.x);
    let maxXValues = parentNodes.map(n=>n.position.x + n.data.width);
    let minYValues = parentNodes.map(n=>n.position.y);
    let maxYValues = parentNodes.map(n=>n.position.y + n.data.height);
    let centreX = (Math.max(...maxXValues) - Math.min(...minXValues))/2 + Math.min(...minXValues);
    let centreY = (Math.max(...maxYValues) - Math.min(...minYValues))/2 + Math.min(...minYValues) + 120;
    if(
      Math.max(...maxXValues) - Math.min(...minXValues) > graphWidth ||
      (Math.max(...maxYValues) - Math.min(...minYValues)) > graphHeight
    ){
      setIsFitView(true)
    }

    setCentre({x:centreX,y:centreY})
  }

  const onFinishLoadNextLevel = ({nodes, newData, level}) => {
    if(nodes.length===0 && newData.length===0){
      dispatch({type:'set_domain_map_data',domainMapData:{nodes}})
      return;
    }
    let processedData = [];
    let backgroundColourMap = {
      1: '#DDE1F2',
      2: '#7986cb',
      3: '#fff',
    }
    let fontColourMap = {
      1: '#000',
      2: '#fff',
      3: '#000',
    }
    let borderColourMap = {
      1: theme.palette.primaryText.light,
      2:'#001861',
      3:'#001861'
    }
    let fontSizeMap = {
      1: 16,
      2: 12,
      3: 12,
    }

    newData.forEach(d=>{
      let sameParentNodeCount = level===1?0:processedData.filter(el=>el.parentNode===d.hierarchy_parent_id_txt).length;
      let newNode = {
        id:d.id,
        type:'KCollectionMapNode',
        extent:level===1?undefined:'parent',
        parentNode:level===1?undefined:d.hierarchy_parent_id_txt, 
        level,
        position:{
          x:level===1?0:childPadding, 
          y: level===1?0:((level===2?levelOneHeaderHeight:childHeight) + (sameParentNodeCount * (childHeight + childPadding)))
        },
        data:{
          width:nodeWidth-childPadding*2*(level-1),
          height:level===1?levelOneHeaderHeight:childHeight,
          backgroundColour: backgroundColourMap[level],
          headerHeight: level===1?levelOneHeaderHeight-2:30,
          titleColour: fontColourMap[level],
          titleFontSize: fontSizeMap[level],
          borderColour: borderColourMap[level],
          obj:d,
          label:getDispFields(d,'dispTitle'),
          onClick:()=>{
            onClickResultItem({item:d,id:d.id,label:'collection_instance',history,newWindow:true})
          }
        }
      }
      processedData.push(newNode)
      if(level>1){
        const recursivelyModifyParent = currentNode => {
          let parent = nodes.find(el=>el.id===currentNode.parentNode)
          if(!parent)return;
          parent.data.height += childHeight + childPadding;
          let parentLowerSiblings = nodes.filter(el=>el.parentNode===parent.parentNode && el.position.y>parent.position.y)
          parentLowerSiblings.forEach(el=>{
            el.position.y += childHeight + childPadding;
          })
          if(parent.parentNode){
            recursivelyModifyParent(parent)
          }
        }
        recursivelyModifyParent(newNode)
      }
    })

    processedData.forEach(d=>{
      nodes.push(d)
    })

    if(level<maxLevel && newData.length>0){
      loadNextLevelNodes({
        parentIds:newData.map(d=>d.id),
        onFinish: data => {
          onFinishLoadNextLevel({nodes, newData:data, level:level+1})
        }
      })
    }else{
      let elk = new ELK();  
      elk 
        .layout({
          id:'rectpacking',
          layoutOptions: { 
            'elk.algorithm':'rectpacking',
          },
          children:nodes.filter(n=>n.level===1).map(el=>({...el,width:el.data.width*1.2,height:el.data.height*1.2})),
          edges:[]
        })
        .then(g=>{
          nodes.forEach(n=>{
            n.data.isCentreAlignTitle = !nodes.find(el=>el.parentNode===n.id);
            let detail = g.children.find(c=>c.id===n.id);
            if(detail){
              n.position.x = detail.x;
              n.position.y = detail.y + childHeight;
            }
          })

          // let rootWidth = Math.max(...nodes.filter(n=>n.level===1).map(n=>n.position.x)) + nodeWidth - Math.min(...nodes.filter(n=>n.level===1).map(n=>n.position.x)) + childPadding*2;
          // let rootHeight = childHeight + Math.max(...nodes.filter(n=>n.level===1).map(n=>n.position.y+n.data.height)) - Math.min(...nodes.filter(n=>n.level===1).map(n=>n.position.y)) + childPadding*3;

          // nodes.find(n=>n.level===0).data.width = rootWidth;
          // nodes.find(n=>n.level===0).data.height = rootHeight;

          getMapCentre(nodes)

          dispatch({type:'set_domain_map_data',domainMapData:{nodes}})
        })
        .catch(error=>{
          console.log(error)
          dispatch({type:'set_domain_map_data',domainMapData:{nodes}})
        });
    }
  }

  useEffect(()=>{
    if(state.domainMapData){
      getMapCentre(state.domainMapData.nodes)
    }
    if(state.domainMapLoading)return;

    let nodes = [
      // {
      //   id:state.basicData.id,
      //   type:'KCollectionMapNode',
      //   position:{
      //     x:childPadding, y: 0
      //   },
      //   level:0,
      //   data:{
      //     width:nodeWidth,
      //     height:childHeight,
      //     obj:state.basicData,
      //     label:getDispFields(state.basicData,'dispTitle')
      //   }
      // }
    ]
    loadNextLevelNodes({
      parentIds:[state.basicData.id],
      onFinish: data => {
        onFinishLoadNextLevel({nodes, newData:data, level:1})
      }
    })
// eslint-disable-next-line
  },[])
  
  return (
    <div className={classes.root} >
      <Typography className={classes.title}>{state.basicData.id===collectionIds.domain?'DOMAIN':'COLLECTION'} MAP</Typography>

      <div ref={chartWrapperRef} className={classes.chartWrapper} style={{height:graphHeight,width:graphWidth}}>
        {
          state.domainMapData && state.domainMapData.nodes.length>0 && 
          <ReactFlowWrapper
            initialNodes={state.domainMapData.nodes}
            initialLinks={[]}
            hideMiniMap={true}
            presetCenter={centre}
            // onFocusOffset={{x:360,y:100}}
            removeAutoCenterOnClick
            fitView={isFitView}
            draggable={false}
          />
        }
        {
          state.domainMapLoading && 
          <div style={{textAlign:'center',marginTop:168}}>
            <LinearProgress color='secondary' style={{width:200,margin:'auto',marginBottom:24}}/>
            <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>Generating map</Typography>
          </div>
        }
        {
          state.domainMapError && 
          <div style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',justifyContent:'center'}}>
            <div className={classes.emotRoot}>
              <Typography className={classes.emot}>¯\_(ツ)_/¯</Typography>
              <Typography className={classes.text}>Unfortunately I am not sure what went wrong.</Typography>
              <Typography className={classes.text}>Let your administrator know so they can fix the problem.</Typography>
            </div>
          </div>
        }
        {
          state.domainMapData && state.domainMapData.nodes.length===0 && 
          <Typography>No map data to be displayed</Typography>
        }
      </div>
    </div>
  )
}

DomainMap.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
  cerberumLabel: PropTypes.string.isRequired,
}

export default withTheme()(withStyles(styles)(DomainMap));