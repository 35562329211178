import React, { useEffect } from 'react';
import { withTheme, withStyles, Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import {getIconComponent, toTitleCase, getInitials, onClickResultItem } from '../../../../utilities'; 
import ListTile from './TilesComponents/ListTile';
import IconDataTile from './TilesComponents/IconDataTile';
import axiosSolr from '../../../../axios-solr';

const styles = theme => ({
  dataBox: {
    marginBottom: '2rem'
  },
  avatar: {
    backgroundColor: theme.palette.avatar.main,
    width:24,
    height:24,
    fontSize:12
  },
})

function TopUserTile(props) {

  const {
    theme,
    history,
    classes,
    object,
    onAddTileData,
    tileData,
    alwaysOpenNewTab
  } = props;

  const loadTopUserTile = async () => {
    let ids = object.top_users_ids;
    if(ids?.length>0){
      axiosSolr
        .get(
          `/solr/search/select`,{params:{
            q:"*",
            fq:`id:(${ids.join(' OR ')})`,
            rows:ids.length,
          }}
        )
        .then(response=>{
          onAddTileData(
            {
              "disp_body":response.data.response.docs.map(el=>({
                labels:'user',
                name:el.name_txt,
                id:el.id
              })),
              "disp_data":{"datasets":[],"labels":[]},
              "disp_footer":"",
              "disp_title":"TOP USERS",
              "disp_type":"list",
              "id":"topUsers",
            }
          )
        })
    }else{
      onAddTileData(
        {
          "disp_body":"No users found",
          "disp_title":"TOP USERS",
          "disp_type":"list",
          "id":"topUsers",
        }
      )
    }
  }

  useEffect(()=>{
    if(!tileData)loadTopUserTile()
  // eslint-disable-next-line
  },[])
  
  if(!tileData)return <></>

  if( typeof(tileData.disp_body)==='string'){
    return (
      <IconDataTile
        title={tileData.disp_title}
        text={tileData.disp_body}
        left={getIconComponent({label: 'help', size: 24, colour: theme.palette.primaryText.light})}
        action={tileData.box_action}
      />
    )
  }

  if(tileData.disp_body.length===1){
    return (
      <IconDataTile
        title={tileData.disp_title}
        text={toTitleCase(tileData.disp_body[0].name)}
        left={<Avatar className={classes.avatar}>{getInitials(tileData.disp_body[0].name)}</Avatar>}
        action={tileData.box_action}
        onClick={
          tileData.disp_body[0].name==='KADA'?
          null:
          ()=>onClickResultItem({history:history,label: tileData.disp_body[0].labels,id: tileData.disp_body[0].id,newWindow:alwaysOpenNewTab})
        }
      />
    )
  }

  return (
    <ListTile
      title={tileData.disp_title}
      action={tileData.box_action}
      list={
        tileData.disp_body.map(item => ({
          name: item.name,
          onClick: () => {
            alwaysOpenNewTab?
            window.open(`/profile/${item.labels}/${encodeURIComponent(item.id)}`,'_blank'):
            history.push(`/profile/${item.labels}/${encodeURIComponent(item.id)}`);
          }
        }))
      }
    />
  )

}

TopUserTile.propTypes = {
  classes: PropTypes.object.isRequired,
  boxData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(TopUserTile));