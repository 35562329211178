import React, { useState } from 'react';
import { withTheme, withStyles, Typography, Button, InputBase, Modal, Paper, Select, FormControl, FormHelperText, MenuItem, FilledInput, Checkbox, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getDispFields, getIconComponent, toTitleCase } from '../../../utilities';
import { useEffect } from 'react';
import axiosSolr from '../../../axios-solr'
import KTooltip from '../../UI/KTooltip/KTooltip';
import {DndContext} from '@dnd-kit/core';
import {SortableContext, verticalListSortingStrategy, arrayMove} from '@dnd-kit/sortable';
import SortableProperty from './SortableProperty';
import SortableOption from './SortableOption';
import useAlert from '../../../hooks/useAlert';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import { getIconLabel } from '../../UI/SearchResults/utils';


const styles = theme => ({
  modal:{
    width:800,
    padding:24,
    paddingBottom:8,
    maxHeight:'80vh',
    margin:'5vh auto 0',
    overflow:'auto',
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  row:{
    display:'flex',
    alignItems:'flex-start',
    width:'100%',
    marginBottom:30,
    minHeight:50,
    overflow:'visible'
  },
  fieldName:{
    color:theme.palette.primaryText.main,
    marginRight:30,
    marginTop:12,
    width:140,
    fontSize:16,
    flexShrink:0
  },
  textField:{
    ...theme.components.inputBase,
    flexGrow:1,
    '& input':{
      padding:12
    },
    '& textarea':{
      padding:0,
    },
    '& div':{
      padding:6
    },
    '& p':{
      margin:0,
      background:theme.palette.background.main,
      padding:'8px 12px 0',
      color:theme.palette.primaryText.light
    }
  },
  customPropContainer:{
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  select:{
    ...theme.components.selector,
    '& div':{
      padding:0
    },
    '& div div':{
      padding:12,
      fontSize:16
    },
  },
  formControl:{
    flexGrow:1
  },
  checkbox:{
    padding:0,
    color:theme.palette.primaryText.light
  },
  button:{
    minWidth:96,
    height:36,
    marginLeft:8
  },
  divButton:{
    width:36,
    height:36,
    borderRadius:18,
    cursor:'pointer',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    marginLeft:28,
    '&:hover':{
      backgroundColor:theme.palette.buttonHovered.main
    }
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  dropdownValueChip:{
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    fontSize:13.75,
    display:'flex',
    alignItems:'center',
    width:564,
    height:48,
    overflow:'hidden'
  },
  dropdownValueText:{
    flexGrow:1,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  dropdownIconButton:{
    padding:3,
    marginLeft:8
  },

  focusBorder:{
    border:`1px solid ${theme.palette.error.main} !important`,
  },
  errorText:{
    color:theme.palette.error.main
  }
})

function AddProperties(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    instanceName
  } = props;

  const [addModalOpen, setAddModalOpen] = useState(false);
  // const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [propertyName,setPropertyName] = useState('');
  const [propertyType,setPropertyType] = useState('none');
  const [defaultValue,setDefaultValue] = useState('');
  const [filter,setFilter] = useState('none');
  const [required, setRequired] = useState(false);
  const [modalType, setModalType] = useState();
  const [selectedProperty, setSelectedProperty] = useState({});
  const [allowedValues, setAllowedValues] = useState([]);
  const [allowedValueInput, setAllowedValueInput] = useState('');

  const [collectionType, setCollectionType] = useState('none');
  const [availableCollections, setAvailableCollections] = useState([]);
  
  const {
    sendAlert
  } = useAlert({
    id:`create-collection-add-properties`,
  })


  const onCancel = ()=>{
    setAddModalOpen(false);
    setPropertyName('');
    setPropertyType('none');
    setDefaultValue('');
    setFilter('none')
    setSelectedProperty({});
    setAllowedValues([]);
    setAllowedValueInput('')
    setCollectionType('none')
  }

  useEffect(()=>{
    if(!state.editMode && state.collectionType==='GLOSSARY'){
      dispatch({
        type:'set_properties',
        properties:state.properties.map(el=>({
          ...el,
          description:el.description.replace(/instance/g,'term')
        }))
      })
    }
  // eslint-disable-next-line
  },[])
  
  const loadCollections = type => {
    const load = ({previousData=[]}) => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:COLLECTION AND collection_type_srt:${type==='none'?'(DATA_GOVERNANCE OR DATA_MANAGEMENT)':type}`,
              rows:100,
              start:previousData.length,
              sort:'name_srt asc'
            }
          }
        )
        .then(response=>{
          let data = [...previousData, ...response.data.response.docs];
          if(data.length>=response.data.response.numFound){
            setAvailableCollections(data)
          }else{
            load({previousData:data})
          }
        })
    }

    load({})
  }

  useEffect(()=>{
    if(['COLLECTION_LOOKUP_FILTERED','COLLECTION_LOOKUP_FILTERED_MULTI'].includes(propertyType)){
      loadCollections(collectionType)
    }
  },[collectionType, propertyType])

  const requiredFilled = () => {
    if(propertyName.trim()==='')return false;
    if(propertyType==='none')return false;
    if(['DROPDOWN_MULTI','DROPDOWN_SINGLE'].includes(propertyType) && allowedValues.length===0)return false;
    if(['USER_LOOKUP_FILTERED','USER_LOOKUP_FILTERED_MULTI','COLLECTION_LOOKUP_FILTERED','COLLECTION_LOOKUP_FILTERED_MULTI'].includes(propertyType) && filter==='none')return false;
    return true;
  }

  const onAdd = () => {
    if(state.properties.find(el=>el.name.toLowerCase()===propertyName.toLowerCase())){
      sendAlert({type:'info',message:'Property name already exists'})
      return;
    }
    let newProperty = {
      "id":`${state.idSeq+1}`,
      "data_type": propertyType,
      "name":propertyName,
      "description": defaultValue,
      "position":state.properties.length+1,
      "required":required,
      "filter":filter,
      'allowed_values':allowedValues.sort((a,b)=>a.position-b.position).map(el=>el.value)
    }
    dispatch({type:'set_id_seq',idSeq:state.idSeq+1})
    dispatch({
      type:'set_properties', 
      properties:[...state.properties,newProperty]
    })
    onCancel();
  }

  const onSave = () => {
    dispatch({
      type:'set_properties', 
      properties:[...state.properties.filter(el=>el.name.toLowerCase()!==selectedProperty.name.toLowerCase()),
        {
          ...selectedProperty,
          "description": defaultValue,
          "name":propertyName,
          "required":required,
          'allowed_values':allowedValues.sort((a,b)=>a.position-b.position).map(el=>el.value),
        }
      ]
    })
    onCancel();
  }

  const onDelete = property => {
    let newArr = state.properties.filter(el=>el.name!==property.name);
    newArr.forEach((el,index)=>{
      if(el.position>property.position)newArr[index].position=newArr[index].position-1;
    })
    dispatch({
      type:'set_properties', 
      properties:newArr
    })
    onCancel()
  }

  const onEditProperty = (property) => {
    setModalType(`edit`);
    setAddModalOpen(true);
    setSelectedProperty(property);
    setFilter(property.filter)
    setPropertyName(property.name);
    setAllowedValues(property.allowed_values.map((el,index)=>({value:el,position:index})));
    setPropertyType(property.data_type);
    setDefaultValue(property.description);
    setRequired(property.required)
  }

  const onTypeSelected = type => {
    setPropertyType(type)
    if(!['DROPDOWN_MULTI','DROPDOWN_SINGLE'].includes(type)){
      setAllowedValues([])
    }
  }

  const onAddAllowedValue = () => {
    if(allowedValueInput.trim()==='')return;
    if(allowedValues.includes(allowedValueInput))return;
    setAllowedValues([...allowedValues, {value:allowedValueInput,position:allowedValues.length}]);
    setAllowedValueInput('')
  }

  const onDeleteAllowedValue = el => {
    setAllowedValues(
      allowedValues.filter(v=>v.value!==el.value).sort((a,b)=>a.position-b.position).map((el,index)=>({value:el.value,position:index}))
    );
  }

  const onDataRoleSelected = id => {
    setFilter(id);
  }

  const onCopyID = async () => {
    try{
      if(navigator.clipboard){
        await navigator.clipboard.writeText(state.collectionId || state.createdCollection.id);
      }
    }catch{
      sendAlert({type:'error',message:'Error occurred copying the id'})
      return;
    }
    sendAlert({type:'info',message:'Copied id to clipboard'})
  }

  return (
    <div className={classes.root}>
      <Typography  style={{color:theme.palette.header.main,fontSize:20,marginBottom:2}}>COLLECTION ID</Typography>
      <Typography style={{whiteSpace:'pre-wrap',fontSize:12,marginBottom:16,color:theme.palette.primaryText.main}}>
        {`Use this ID if you are updating the collection via the API`}
      </Typography>
      <div style={{display:'flex',overflow:'hidden',alignItems:'center',height:48,paddingLeft:16,borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,width:800,marginBottom:40}}>
        <KTooltip title={"Used for identifying the collection when using the K API"}>
          {getIconComponent({label:'info',size:24,colour:theme.palette.primaryText.light})}
        </KTooltip>
        <Typography style={{color:theme.palette.primaryText.main,marginLeft:16,fontSize:16,flexGrow:1,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
          {state.collectionId || state.createdCollection.id}
        </Typography>
        <IconButton onClick={onCopyID} >
          {getIconComponent({label:'copy',size:24,colour:theme.palette.primaryText.light})}
        </IconButton>
      </div>
      <div >
        <Typography  style={{color:theme.palette.header.main,fontSize:20}}>{`DEFAULT PROPERTIES`}</Typography>
      </div> 
      <Typography style={{whiteSpace:'pre-wrap',fontSize:12,marginBottom:16,color:theme.palette.primaryText.main}}>
        {`The following properties are included in each instance`}
      </Typography>
      <div style={{marginBottom:42,width:800}}>
        <div style={{display:'flex',paddingLeft:16,alignItems:'center',height:48,borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}}>
          <KTooltip title={"Name of the instance. Mandatory field"}>
            {getIconComponent({label:'info',size:24,colour:theme.palette.primaryText.light})}
          </KTooltip>
          <Typography style={{fontSize:16,marginLeft:16}}>Name</Typography>
        </div>
        <div style={{display:'flex',paddingLeft:16,alignItems:'center',marginBottom:12,height:48,borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}}>
          <KTooltip title={"Description of the instance. Mandatory field"}>
            {getIconComponent({label:'info',size:24,colour:theme.palette.primaryText.light})}
          </KTooltip>
          <Typography style={{fontSize:16,marginLeft:16}}>Description</Typography>
        </div>
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
        <Typography  style={{color:theme.palette.header.main,fontSize:20}}>{`CUSTOM PROPERTIES`}</Typography>
        <Button color='secondary' style={{marginLeft:16,fontSize:13.75,width:64}} onClick={()=>{setAddModalOpen(true);setModalType('add')}}>ADD</Button>
      </div> 
      <Typography style={{whiteSpace:'pre-wrap',fontSize:12,marginBottom:16,color:theme.palette.primaryText.main}}>
        {`Click “Add” to add a new property to all ${instanceName}(s).\n`+
          `Drag the properties up and down to set the order they appear on their profile page.`}
      </Typography>
      {
        state.properties.length===0 && 
        <Typography>No custom properties added</Typography>
      }
      <DndContext onDragEnd={(event)=>{
        const {active, over} = event;
        if (active.id !== over.id) {
          let oldArr = state.properties.map(el=>el.name)
          let oldIndex = oldArr.indexOf(active.id)
          let newIndex = oldArr.indexOf(over.id)
          let newArr = arrayMove(oldArr, oldIndex, newIndex);
          let newProperties = state.properties.map(el=>({
            ...el,
            position:newArr.indexOf(el.name)
          }))
          dispatch({type:'set_properties',properties:newProperties})
        }             
      }}>
        <SortableContext items={state.properties.sort((a,b)=>a.position-b.position).map(el=>el.name)} strategy={verticalListSortingStrategy}>
          {
            state.properties.map(el=>(
              <SortableProperty
                key={el.name}
                id={el.name}
                property={el}
                onEditProperty={onEditProperty}
                onDelete={onDelete}
                setSelectedProperty={setSelectedProperty}
                classes={classes}
              />
            ))
          }
        </SortableContext>
      </DndContext>

      <Modal
        open={addModalOpen}
        onClose={() =>setAddModalOpen(false)}
        disableBackdropClick={true}
      >
        <div style={{width:`100%`,height:`100vh`,outline:'none'}}>
          <Paper className={classes.modal}>
            <Typography  style={{color:theme.palette.header.main,fontSize:20,marginBottom:24}}>
              {modalType==='add'?`Add a new Property`:`Edit '${toTitleCase(selectedProperty.name)}' property`}
            </Typography>
            {
              <div className={classes.row}>
                <Typography className={classes.fieldName}>Name</Typography>
                <FormControl className={classes.formControl}>
                  <InputBase
                    data-test-id="add-property-name"
                    value={propertyName}
                    className={classes.textField+(propertyName && propertyName.trim()!==''?'':" "+classes.focusBorder)}
                    variant={'filled'}
                    inputProps={{ "data-test-id": 'edit-property-edit-name'}} 
                    onChange={event=>{if(event.target.value.length<=30){setPropertyName(event.target.value)}}}
                    placeholder={`Give this property a name.`}
                  />
                  <FormHelperText style={{marginLeft:12,color:propertyName && propertyName.trim()!==''?theme.palette.primaryText.light:theme.palette.error.main}}>Required. Max 30 characters</FormHelperText>
                </FormControl>
              </div>
            }
            { 
              modalType==='add' && 
              <div className={classes.row}>
                <Typography className={classes.fieldName}>Type</Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    data-test-id="add-property-type"
                    value={propertyType}
                    className={classes.select+(propertyType && propertyType.trim()!=='none'?'':" "+classes.focusBorder)}
                    onChange={event=>onTypeSelected(event.target.value)}
                    variant='filled'
                    input={<FilledInput />}
                    style={{color:propertyType==='none'?'#999':undefined}}
                    disableUnderline
                  >
                    <MenuItem value={'none'}>Select a property type</MenuItem>
                    {
                      [...state.propertyData].sort((a,b)=>a.name.localeCompare(b.name)).map(el=>(
                        <MenuItem value={el.name}>{el.name}</MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText style={{marginLeft:12,color:propertyType && propertyType.trim()!=='none'?theme.palette.primaryText.light:theme.palette.error.main}}>Required</FormHelperText>
                </FormControl>
              </div>
            }
            {
              selectedProperty && ['DROPDOWN_MULTI','DROPDOWN_SINGLE'].includes(propertyType) &&
              <div className={classes.row}>
                <Typography className={classes.fieldName}>Drop down values</Typography>
                <div style={{flexGrow:1}}>
                  <div style={{width:'100%',display:'flex',flexWrap:'wrap'}}>
                    {
                      allowedValues.length === 0 && 
                      <Typography style={{fontSize:13.75,color:theme.palette.error.main,marginBottom:16,paddingTop:13.75}}>
                        No values added
                      </Typography>
                    }
                    <DndContext onDragEnd={(event)=>{
                      const {active, over} = event;
                      if (active.id !== over.id) {
                        let oldArr = allowedValues.map(el=>el.value)
                        let oldIndex = oldArr.indexOf(active.id)
                        let newIndex = oldArr.indexOf(over.id)
                        let newArr = arrayMove(oldArr, oldIndex, newIndex);
                        let newProperties = allowedValues.map(el=>({
                          ...el,
                          position:newArr.indexOf(el.value)
                        }))
                        setAllowedValues(newProperties)
                      }             
                    }}>
                      <SortableContext items={allowedValues.sort((a,b)=>a.position-b.position).map(el=>el.value)} strategy={verticalListSortingStrategy}>
                        {
                          allowedValues.map(el=>(
                            <SortableOption
                              key={el.value}
                              id={el.value}
                              el={el}
                              onDeleteAllowedValue={onDeleteAllowedValue}
                              classes={classes}
                            />
                          ))
                        }
                      </SortableContext>
                    </DndContext>
                  </div>
                  <div style={{width:'100%',display:'flex',alignItems:'center'}}>
                    <InputBase
                      data-test-id="add-property-dropdown-values"
                      value={allowedValueInput}
                      className={classes.textField}
                      variant={'filled'}
                      onKeyDown={event => {if(event.keyCode===13)onAddAllowedValue()}}
                      onChange={event=>{if(event.target.value.length<=70)setAllowedValueInput(event.target.value)}}
                      placeholder={`Enter drop down list value and hit enter / press + to add`}
                    />
                    <IconButton disabled={allowedValueInput.trim()===''} onClick={onAddAllowedValue} style={{marginLeft:16}}>
                      {getIconComponent({label:'add',size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  </div>
                </div>
              </div>
            }
            {
              selectedProperty && (!selectedProperty.id||!selectedProperty.id.includes('-')) && ['USER_LOOKUP_FILTERED','USER_LOOKUP_FILTERED_MULTI'].includes(propertyType) &&
              <div className={classes.row}>
                <Typography className={classes.fieldName}>Data Role</Typography>
                <div style={{flexGrow:1}}>
                  <FormControl className={classes.formControl} style={{width:'100%'}}>
                    <Select
                      data-test-id="add-property-data-role"
                      value={filter}
                      className={classes.select+(filter && filter!=='none'?'':" "+classes.focusBorder)}
                      onChange={event=>{
                        onDataRoleSelected(event.target.value)
                      }}
                      variant='filled'
                      input={<FilledInput />}
                      style={{color:propertyType==='none'?'#999':undefined}}
                      disableUnderline
                    >
                      <MenuItem value={'none'}>Filter a data role</MenuItem>
                      {
                        state.dataRoles.map(el=>(
                          <MenuItem value={el.id}>{el.name}</MenuItem>
                        ))
                      }
                    </Select>
                    <FormHelperText style={{marginLeft:12,color:filter && filter!=='none'?theme.palette.primaryText.light:theme.palette.error.main}}>Required</FormHelperText>
                  </FormControl>
                </div>
              </div>
            }
            {
              selectedProperty && (!selectedProperty.id||!selectedProperty.id.includes('-')) && ['COLLECTION_LOOKUP_FILTERED','COLLECTION_LOOKUP_FILTERED_MULTI'].includes(propertyType) &&
              <div>
                <div className={classes.row}>
                  <Typography className={classes.fieldName}>Collection Type</Typography>
                  <div style={{flexGrow:1}}>
                    <FormControl className={classes.formControl} style={{width:'100%'}}>
                      <Select
                        data-test-id="add-property-collection-type"
                        value={collectionType}
                        disabled={modalType==='edit'}
                        className={classes.select}
                        onChange={event=>{
                          if(collectionType===event.target.value)return;
                          setFilter('none')
                          setCollectionType(event.target.value)
                        }}
                        variant='filled'
                        input={<FilledInput />}
                        style={{color:propertyType==='none'?'#999':undefined}}
                        disableUnderline
                      >
                        <MenuItem value={'none'}>Filter a Collection Type</MenuItem>
                        {
                          ['DATA_MANAGEMENT','DATA_GOVERNANCE'].map(el=>(
                            <MenuItem value={el}>{el.replace(/_/g,' ')}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={classes.row}>
                  <Typography className={classes.fieldName}>Collection</Typography>
                  <div style={{flexGrow:1}}>
                    <FormControl className={classes.formControl} style={{width:'100%'}}>
                      <Select
                        data-test-id="add-property-collection"
                        value={filter}
                        disabled={modalType==='edit'}
                        className={classes.select+(filter && filter!=='none'?'':" "+classes.focusBorder)}
                        // onChange={event=>setFilter(event.target.value)}
                        variant='filled'
                        input={<FilledInput />}
                        style={{color:propertyType==='none'?'#999':undefined}}
                        renderValue={value=>{
                          return value==='none'?'Select a Collection':(availableCollections.find(c=>c.id===value)?.name_txt||'')
                        }}
                        disableUnderline
                      >
                        <div>
                        {
                          availableCollections.filter(c=>!state.properties.find(p=>p.filter===c.id)).map(el=>(
                            <SimpleResultItem
                              key={el.id}
                              title={getDispFields(el,'dispTitle')}
                              label={el.object_type_txt}
                              iconLabel={getIconLabel({label:'collection', item:el})}
                              item={el}
                              subTitle={getDispFields(el,'dispSubtitle')}
                              rightText={el.trust_srt}
                              showUnderline
                              keepPropagation
                              onClick={()=>setFilter(el.id)}
                            />
                          ))
                        }
                        </div>
                      </Select>
                      <FormHelperText style={{marginLeft:12,color:filter && filter!=='none'?theme.palette.primaryText.light:theme.palette.error.main}}>Required</FormHelperText>
                    </FormControl>
                  </div>
                </div>
              </div>
            }
            <div className={classes.row}>
              <Typography className={classes.fieldName}>Description</Typography>
              <FormControl className={classes.formControl}>
                <InputBase
                  data-test-id="add-property-description"
                  value={defaultValue}
                  className={classes.textField}
                  variant={'filled'}
                  onChange={event=>{if(event.target.value.length<=200)setDefaultValue(event.target.value)}}
                  placeholder={`Set description for this property`}
                />
                <FormHelperText style={{marginLeft:12,color:theme.palette.primaryText.light}}>Optional. Max 200 characters</FormHelperText>
              </FormControl>
            </div>
            { 
              selectedProperty &&
              <div className={classes.row}>
                <Typography className={classes.fieldName} style={{marginTop:0}}>Required</Typography>
                <Checkbox
                  data-test-id="add-property-required"
                  color='primary'
                  className={classes.checkbox}
                  checked={required}
                  onChange={(event,checked)=>setRequired(checked)}
                />
              </div>
            }
            <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
              {modalType==='add' && <Button data-test-id="add-property-add-button" classes={{disabled:classes.disabledButton}} color='primary' className={classes.button} disabled={!requiredFilled()} onClick={onAdd}>ADD</Button>}
              {modalType==='edit' && <Button data-test-id="add-property-save-button" color='primary' classes={{disabled:classes.disabledButton}}  className={classes.button} disabled={!requiredFilled()}  onClick={onSave}>SAVE</Button>}
              <Button data-test-id="add-property-cancel-button" className={classes.button} color='secondary' onClick={onCancel}>CANCEL</Button>
            </div>
          </Paper>
        </div>
      </Modal>
    </div>
  )
}

AddProperties.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(AddProperties));

