import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, ClickAwayListener, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
// import axiosCerebrum from '../../../../axios-cerebrum'
import axiosCerebrum from '../../../../axios-cerebrum';
import { collectionIds, getFontColourByBackground, getIconComponent,  getLabelPlural} from '../../../../utilities';
import { tileStyles } from './utils/styles';
import { checkIsClickOnSuggestion, measureText } from './utils/utils';
import useAlert from '../../../../hooks/useAlert';
import HierarchySearchSelector from '../../SearchSelector/HierarchySearchSelector';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
  iconButton:{
    ...tileStyles.deleteButton
  },
})

function CategoryManager(props) {

  const {
    classes,
    theme,
    history,
    object,
    onAddTileData,
    tileData,
    isEditable,
    alwaysOpenNewTab
  } = props;

  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [deleting, setDeleting] = useState(false)
  const [adding, setAdding] = useState(false)
  const popperRef = useRef()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  
  const loadCategory = () => {
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related/collections`,
        {params:{
          category:'PLATFORM',
          parent_name:'Category',
          relationship:'MEMBER_OF',
          per_page:200
        }}
      )
      .then(response=>{
        let category = response.data.items;
        
        let promises = [];
        category.forEach(el=>{
          promises.push(
            axiosCerebrum
              .get(
                `/api/collectioninstances/${el.id}`,
              )
          )
        })

        Promise.all(promises)
          .then(details=>{
            onAddTileData(
              {
                "disp_body":category.map(el=>{
                  let background = details.find(r=>r.data.id===el.id)?.data?.properties?.[1];
                  return {
                    labels:'collection_instance',
                    name:el.name,
                    id:el.id,
                    background:background||theme.palette.primary.dark,
                    onClick:()=>{
                      alwaysOpenNewTab?
                      window.open(`/profile/collection_instance/${el.id}`, '_blank'):
                      history.push(`/profile/collection_instance/${el.id}`)
                    }
                  }
                  
                }),
                "disp_title": "Category",
                "id": 'category',
                "modalData":category
              }
            )
          })
          .catch(error=>{
            console.log(error)
            setLoading(false)
          })
      })
      .catch(error=>{
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(()=>{
    if(!tileData){
      loadCategory()
    }
  // eslint-disable-next-line
  },[])

  const onAdd = async item => {
    if(adding)return;
    setSearchValue('')
    setAdding(true)

    let isError = false;

    let deletedPromises = [];
    // delete existing categories
    tileData.disp_body.forEach(el=>{
      deletedPromises.push(axiosCerebrum.delete(`/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=MEMBER_OF&object_id=${el.id}`))
    })

    await Promise.all(deletedPromises).catch(error=>{
      isError = true;
      console.log(error)
    })

    if(isError){
      loadCategory();
      sendAlert({ message: 'Error occurred adding category, please try again', type: 'error' })
      setAdding(false)
      return;
    }

    axiosCerebrum
      .put(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=MEMBER_OF&object_id=${item.id}`
      )
      .then(async response=>{
        let background;
        await axiosCerebrum
          .get(`/api/collectioninstances/${item.id}`)
          .then(detail=>{
            background = detail.data.properties[1]||theme.palette.primary.dark;
          })
          .catch(error=>{
            background = theme.palette.primary.dark;
          })

        onAddTileData(
          {
            "disp_body":[{
              name:item.name_txt || item.name,
              labels:'collection_instance',
              id:item.id,
              background,
              onClick:()=>{
                alwaysOpenNewTab?
                window.open(`/profile/collection_instance/${item.id}`, '_blank'):
                history.push(`/profile/collection_instance/${item.id}`)
              }
            }],
            "disp_title":"CATEGORY",
            "id":"category",
          }
        )
        setAdding(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred adding category, please try again', type: 'error' })
        setAdding(false)
      })
  }

  const onDelete = (item) => {
    setDeleting(true)
    let showLoadingTimeout = setTimeout(()=>{
      setLoading(true)
    },800)
    axiosCerebrum
      .delete(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=MEMBER_OF&object_id=${item.id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":tileData.disp_body.filter(el=>el.id!==item.id),
            "disp_title":"CATEGORY",
            "id":"category",
          }
        )
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred deleting category, please try again', type: 'error' })
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
  }

  if(!tileData)return <></>

  
  return (
    <div data-test-id="category-data-tile" className={classes.root}>
      <div className={classes.editableHeaderContainer}>
        <Typography className={classes.title}>CATEGORY</Typography>
      </div>
      <ClickAwayListener 
        onClickAway={(event)=>{
          if(checkIsClickOnSuggestion(event.target))return;
          setSearchValue('')
          setEditing(false)
        }}
      >
        <div ref={popperRef} className={classes.chipContainer + ' ' + (editing?classes.editingBox:isEditable?classes.clickableBox:'')} onClick={()=>isEditable && setEditing(true)}>
          {
            loading && <CircularProgress color='secondary' size={16}/>
          }
          {
            !loading && tileData && tileData.disp_body.length===0 && 
            <div data-test-classname="category-data-tile-chip" className={classes.tag} style={{background:theme.palette.chip.main,color:theme.palette.primaryText.main,border:`1px solid ${theme.palette.border.main}`,cursor:'default'}}>
              <span className={classes.tagText} style={{color:theme.palette.primaryText.main}}>None</span>
            </div>
          }
          {
            !loading && tileData && tileData.disp_body?.map(el=>(
              <div data-test-classname="category-data-tile-chip" style={{background:el.background,border:'none',width:editing?undefined:measureText(el.name)+24}} className={classes.tag} onClick={el.onClick}>
                <span className={classes.tagText} style={{color:getFontColourByBackground(el.background)}}>{el.name}</span>
                {
                  isEditable &&
                  <IconButton className={classes.iconButton + ' chip-delete-button'}  style={{display:editing?'block':undefined}} onClick={event=>{event.stopPropagation();!deleting && onDelete(el)}}>
                    {getIconComponent({label:'clear',size:13.75,colour:getFontColourByBackground(el.background)})}
                  </IconButton>
                }
              </div>
            ))
          }
          {
            editing && 
            <div style={{marginTop:8,marginBottom:6,width:'100%'}}>
              <HierarchySearchSelector
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                rootId={collectionIds.category}
                fq={
                  `object_type_srt:COLLECTION_INSTANCE AND collection_srt:CATEGORY AND collection_type_srt:PLATFORM` +
                  (tileData.disp_body.length>0?` AND -id:(${tileData.disp_body.map(b=>b.id).join(' OR ')})`:'')
                }
                rootLabel={'collection'}
                height={32}
                onResultClick={item=>{
                  if(tileData.disp_body.map(b=>b.id).includes(item.id)){
                    if(deleting)return;
                    onDelete(item)
                  }else{
                    onAdd(item)
                  }
                }}
                clearable
                autoFocus
                testID="category-manager-input"
                placeholder={`Search for a category`}
                isItemSelected={item=>{
                  return tileData.disp_body.map(b=>b.id).includes(item.id)
                }}
              />
            </div>
          }
        </div>
      </ClickAwayListener>
    </div>
  )
}

CategoryManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(CategoryManager));