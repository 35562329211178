import { palette } from "../../../../theme";

export const setNodeFocus = ({focus, subFocus, nodes, links, type, isExclude}) => {
  const shouldNodeBeFocused = node => {
    if(focus.length===0)return [true];
    if(node.type==='KButtonNode')return [false];
    if(node.type!=='KLineageNode')return [true];
    // if(subFocus.length===0)return false;
    let borderColour;
    let funcName = type==='and'?'every':'some';
    let shouldFocus = focus[funcName](f=>{
      let result = false;
      if(f==='trust'){
        if(type==='and' && subFocus.trust.length>1)return false;
        result = subFocus.trust.some(s=>node.data?.obj?.trust===s || (!node.data?.obj?.trust && s==='NONE'));
      }
      if(f==='classification'){
        if(type==='and' && subFocus.classification.length>1)return false;
        result = subFocus.classification.some(s=>node.data?.obj?.classification_kc_txts?.includes(s) || (!node.data?.obj?.classification_kc_txts && s==='NONE'));
      }
      if(f==='domain'){
        if(type==='and' && subFocus.domain.length>1 && subFocus.domain.find(s=>s==='NONE'))return false;
        result = subFocus.domain[funcName](s=>node.data?.obj?.domain_kc_txts?.includes(s) || (!node.data?.obj?.domain_kc_txts && s==='NONE'));
      }
      if(f==='category'){
        if(type==='and' && subFocus.category.length>1)return false;
        result = subFocus.category.some(s=>node.data?.obj?.category_kc_txts?.includes(s) || (!node.data?.obj?.category_kc_txts && s==='NONE'));
      }
      if(f==='verified'){
        if(type==='and' && subFocus.verified.length>1 && subFocus.verified.find(s=>s==='NONE'))return false;
        result = subFocus.verified[funcName](s=>node.data?.obj?.verified_kc_txts?.includes(s) || (!node.data?.obj?.verified_kc_txts && s==='NONE'));
        borderColour = subFocus.verified[funcName](s=>node.data?.obj?.verified_kc_txts?.includes(s))?palette.success.main:undefined;
      }
      if(f==='not_verified'){
        if(type==='and' && subFocus.not_verified.length>1 && subFocus.not_verified.find(s=>s==='NONE'))return false;
        result = subFocus.not_verified[funcName](s=>node.data?.obj?.not_verified_kc_txts?.includes(s) || (!node.data?.obj?.not_verified_kc_txts && s==='NONE'));
        borderColour = subFocus.not_verified[funcName](s=>node.data?.obj?.not_verified_kc_txts?.includes(s))?palette.error.main:undefined;
      }
      if(f==='dq_score'){
        if(type==='and' && subFocus.dq_score.length>1)return false;
        result = (
          (subFocus.dq_score.includes('NONE') && isNaN(node.data.obj?.dq_score_overall_srt)) ||
          subFocus.dq_score.filter(el=>el!=='NONE').some(s=>node.data.obj?.dq_score_overall_srt>=Number(s.split(' TO ')[0]).valueOf() && node.data.obj?.dq_score_overall_srt<=Number(s.split(' TO ')[1]).valueOf() )
        )
      }
      if(f==='dq_failed'){
        result = node.data?.obj?.data_quality_failed_txt==='YES';
      }
      if(f==='owned_txt'){
        result = subFocus.owned_txt[funcName](s=>node.data?.obj?.owned_txt?.includes(s));
      }
      if(f==='steward_count'){
        if(type==='and' && subFocus.steward_count.length>1)return false;
        result =  (
          (subFocus.steward_count.includes('NONE') && node.data.obj?.steward_count===0) ||
          (subFocus.steward_count.includes('ONE') && node.data.obj?.steward_count===1) ||
          (subFocus.steward_count.includes('MANY') && node.data.obj?.steward_count>1)
        )
      }
      if(f==='documented'){
        result =  subFocus.documented[funcName](s=>node.data?.obj?.documented_txt?.includes(s));
      }
      if(f==='issue_count'){
        if(type==='and' && subFocus.issue_count.length>1)return false;
        result =  (
          (subFocus.issue_count.includes('NONE') && node.data.obj?.number_of_issues_srt===0) ||
          (subFocus.issue_count.includes('ONE') && node.data.obj?.number_of_issues_srt===1) ||
          (subFocus.issue_count.includes('MANY') && node.data.obj?.number_of_issues_srt>1)
        )
      }
      if(f==='usage_txt'){
        if(type==='and' && subFocus.usage_txt.length>1)return false;
        result =  subFocus.usage_txt.some(s=>node.data?.obj?.usage_txt===s || (!node.data?.obj?.usage_txt && s==='NONE'));
      }
      if(f==='load_frequency_txt'){
        if(type==='and' && subFocus.load_frequency_txt.length>1)return false;
        result =  subFocus.load_frequency_txt.some(s=>node.data?.obj?.load_frequency_txt===s || (!node.data?.obj?.load_frequency_txt && s==='NONE'));
      }
      // instances focus
      if(f.includes('_kc_txts')){
        if(type==='and'){
          return subFocus[f].every(s=>node.data?.obj?.[f]?.includes(s) || (!node.data?.obj?.[f] && s==='NONE'));
        }else{
          return subFocus[f].some(s=>node.data?.obj?.[f]?.includes(s) || (!node.data?.obj?.[f] && s==='NONE'));
        }
      }
      return result
    })

    return [
      isExclude?!shouldFocus:shouldFocus,
      {
        borderColour
      }
    ]
  }

  nodes.forEach(n=>{
    let results = shouldNodeBeFocused(n);
    n.data.faded = !results[0];
    if(results[1]?.borderColour){
      n.data.overwriteBorderColour = results[1].borderColour;
      n.data.borderWidth = 4;
    }else{
      n.data.overwriteBorderColour = undefined;
      n.data.borderWidth = 2;
    }
  })

  const isAllChildrenFaded = node => {
    let children = nodes.filter(n=>n.parentNode===node.id)
    if(children.length===0)return true;
    let faded = children.every(c=>c.data.faded && isAllChildrenFaded(c))
    return faded;
  }

  nodes.filter(n=>n.type==='KGroup').forEach(n=>{
    n.data.faded = isAllChildrenFaded(n)
  })

  links.forEach(l=>{
    l.data.faded = nodes.find(n=>n.id===l.source)?.data?.faded || nodes.find(n=>n.id===l.target)?.data?.faded
  })

}