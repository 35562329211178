import React, { useEffect, useRef } from 'react';
import { withTheme, CircularProgress, withStyles, Typography, Select, MenuItem } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr'
import {  isInViewport, mapObjectName } from '../../../utilities'
import {contributedObjects, getObjectCard} from '../utils'
import theme from '../../../theme';
 
const styles = theme => ({
  root: {

  },
  title:{
    fontSize:20,
    flexGrow:1,
    color:theme.palette.header.main
  },
  errorText:{
    color:theme.palette.primaryText.main,
    marginTop:24,
  },
  timelineText:{
    color:theme.palette.primary.main,
    letterSpacing:2,
    marginBottom:8,
    fontSize:12
  },
  checkbox:{
    paddingLeft:0
  },
  selector:{
    ...theme.components.selector
  },
  selectorHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:2,
    paddingLeft:16,
    marginTop:4,
    marginBottom:4
  }
})

const ContributedList = props => {

  const {
    classes,
    history,
    objectType,
    state,
    dispatch,
    sessionData,
    headerTop
  } = props;

  const scrollRef = useRef()

  let data = state.contributedListData[objectType]
  let setData = d => dispatch({type:'set_contributed_list',contributedListData:{...state.contributedListData,[objectType]:d}})

  const loadListData = ({sort=state.contributedListSort,page=1}) => {
    setData({
      ...(page===1?{}:data),
      loading:true
    })
    axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related`,
        {params:{
          relationship:'CREATOR_OF',
          object_name:objectType,
          per_page:10,
          page:page,
          sort:sort
        }}
      )
      .then(response=>{
        if(response.data.items.length===0){
          setData({
            ...response.data
          })
          return;
        }
        axiosSolr.get(
          `/solr/search/select`,{
            params:{
              q:'*',
              fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')}) AND -collection_srt:LIST`,
              fl:'*',
              start:0,
              rows:response.data.items.length,
            }
          }
        )
        .then(solrRes=>{
          setData(
            {
              ...response.data,
              items:[
                ...(page===1?[]:data.items),
                ...response.data.items.filter(el=>solrRes.data.response.docs.find(s=>s.id===el.id)).map(el=>({
                  ...el,
                  ...(solrRes.data.response.docs.find(s=>s.id===el.id)||{})
                }))
              ]
            }
          )
        })
        .catch(error=>{
          console.log(error)
          setData({
            error:true
          })
        })
      })
      .catch(error=>{
        console.log(error)
        setData({
          error:true
        })
      })
  }

  useEffect(()=>{
    if(!data.items && !data.loading){
      loadListData({})
    }
    // eslint-disable-next-line
  },[])

  const onChangeSort = (event) => {
    loadListData({sort:event.target.value})
    dispatch({type:'set_contributed_list_sort',contributedListSort:event.target.value})
  }

  const shouldPaginate = () => {
    return data.items && data.page<data.pages && !data.loading && isInViewport(scrollRef)
  }

  window.onscroll = () => {
    if(shouldPaginate()){
      loadListData({page:data.page+1})
    }
  }

  useEffect(()=>{
    if(shouldPaginate()){
      loadListData({page:data.page+1})
    }
  // eslint-disable-next-line
  },[data])

  return (
    <div className={classes.root}>
      <div style={{display:'flex',alignItems:'flex-start',paddingBottom:20,background:theme.palette.background.main,position:'sticky',top:headerTop,zIndex:9}}>
        <Typography className={classes.title} data-test-classname="ecosystem-title">{data && !isNaN(data.total) ?data.total+' ':''}{mapObjectName(objectType).replace(/_/g,' ')}(S) CONTRIBUTED</Typography>
        {
           (state.contributedListObjects.length!==contributedObjects.length || state.contributedListData?.total!==0 ) && 
           <>
            <Select
              className={classes.selector}
              style={{marginLeft:16,width:150}}
              value={state.contributedListSort}
              onChange={event=>onChangeSort(event)}
              data-test-classname="ecosystem-sort-selector"
              MenuProps={{
                getContentAnchorEl:null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  horizontal: "left"
                },
              }}
            >
              {
                [
                  {dispName:'Recently updated',value:'LINKAGE_END_DESC'},
                  {dispName:'Trust',value:'TRUST_SCORE_DESC'},
                  {dispName:'A-Z',value:'ALPHABETICAL'},
                  {dispName:'Z-A',value:'REVERSE_ALPHABETICAL'}
                ].map(el=>(
                  <MenuItem value={el.value}>
                    {el.dispName}
                  </MenuItem>
                ))
              }
            </Select>
          </>
        }
      </div>
      {
         data?.items?.map(el=>(
            getObjectCard(el,history)
         ))
      }
      {
        data && (data.total===0 || data.items?.length===0) && 
        <Typography className={classes.errorText}>No items found</Typography>
      }
      <div ref={scrollRef} style={{textAlign:'center',marginBottom:24}}>
        {
          data?.loading && 
          <CircularProgress style={{margin:'24px auto'}} color='secondary'/>
        }
      </div>
      
      {
        data?.error && 
        <Typography className={classes.errorText}>Error occurred loading contributed items</Typography>
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(ContributedList));