import React, {} from 'react';
import { withTheme, Typography,Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';


const ZoneSelector = props => {

  const {
    classes,
    theme,
    zone,
    setZone,
  } = props;


  return (
    <div className={classes.modalBlock}>
      <Typography  className={classes.modalBlockTitle}>Zone</Typography>
      <div style={{flexGrow:1}}>
        <FormControl style={{width:'100%'}}>
          <Select
            className={classes.selector}
            disableUnderline
            value={zone}
            onChange={event=>setZone(event.target.value)}
            MenuProps={{
              classes:{
                paper:classes.selectPaper
              }
            }}
            renderValue={el=>{
              return (
                <Typography style={{fontSize:16,color:theme.palette.primaryText.main}}>{el}</Typography>
              )
            }}
          > 
            <MenuItem   className={classes.menuItem} value={'All'}>
              All
            </MenuItem>
            {
              ['Operational','Consumption','Adhoc','Unknown'].map(el=>(
                <MenuItem   className={classes.menuItem} value={el}>
                  {el}
                </MenuItem>
              ))
            }

          </Select>
          <FormHelperText className={classes.helperText}>
            {'Required'}  
          </FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}

ZoneSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  zone: PropTypes.string.isRequired,
  setZone: PropTypes.func.isRequired,
}

export default withTheme()(ZoneSelector);