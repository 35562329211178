import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, CircularProgress, Button, Select, MenuItem, InputBase, IconButton } from '@material-ui/core';
import { getInitials, toTitleCase, onClickResultItem, getIconComponent, mapObjectName, handleShareClick } from '../../../utilities'
import moment from 'moment';
import axiosCerebrum from '../../../axios-cerebrum'
import VerticalTabBar from '../VerticalTabBar/VerticalTabBar';
import GenericLinkModal from '../GenericLinkModal/GenericLinkModal';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  block: {
    marginBottom: '5rem',
    width: '40rem'
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  title:{
    fontSize: 20,
    color:theme.palette.header.main,
    flexShrink:0
  },
  subtitle: {
    color:theme.palette.primaryText.light,
    marginBottom:8
  },
  addButton: {
    cursor: 'pointer',
    marginLeft: 16,
    display: 'flex',
    marginBottom: 2,
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.hovered.main
    }
  },
  selector: {
		...theme.components.selector,
		width: 200,
	},
  bodyText: {
    flexGrow: 1,
    color:theme.palette.primaryText.main,
    marginTop: '1rem',
    width: '60%',
    minWidth: 500,
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  userChip:{
    width:130,
    padding:'2px 8px 2px 2px',
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    borderRadius:16,
    background:theme.palette.primary.dark,
    cursor:'pointer',
    marginRight:8,
    marginBottom:8
  },
  avatar:{
    width:20,
    height:20,
    borderRadius:12,
    flexShrink:0,
    background:theme.palette.avatar.main,
    color:'#fff',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontSize:10,
    marginRight:8
  },
  chip:{
    width:'max-content',
    marginRight:8,
    marginBottom:8,
    flexShrink:1,
    display:'flex',
    alignItems:'center',
    padding:'1px 8px',
    background:theme.palette.chip.main,
    borderRadius:16,
    border:`1px solid ${theme.palette.border.main}`,
  },
  chipText:{
    color:theme.palette.primaryText.main,
    fontSize:13.75
  },
  detailHeader:{
    color:theme.palette.primary.main,
    fontSize:12,
    letterSpacing:2,
    marginBottom:8
  },
  chipName:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:"nowrap",
    color:theme.palette.background.main,
    fontSize:13.75
  },
  detailText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    whiteSpace:"pre-wrap",
    marginLeft:8,
    minWidth:80,
    maxWidth:180,
    marginRight:40,
    wordBreak:'break-word'
  },
  detailBodyText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    whiteSpace:"pre-wrap",
  },
  clickableText:{
    cursor:'pointer',
    // '&:hover':{
    //   textDecoration:'underline'
    // }
  },
  searchFilter:{
    ...theme.components.inputBase,
    marginRight:16,
		width:200,
		height:40,
  },
  emptyText:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
  },
  bannerButton:{
    padding:'2px 4px',
    marginBottom:-2
  },
  bannerText:{
    fontSize:16,
    color:theme.palette.primaryText.main
  },
  card:{
    padding:16,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:3,
    marginBottom:24,
    paddingBottom:8
  },
})

const Issues = props => {

  const {
    history,
    classes,
    theme,
    dispatch,
    state
  } = props;

  const [isEdit, setIsEdit] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const [linkModalOpen, setLinkModalOpen] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const enrichData = async () => {
    let newData = {
      ...state.issueData
    }
    setDataLoading(true)
    for(let i=0; i<newData.items.length; i++){
      if(newData.creatorLoaded)continue;
      let item = newData.items[i]
      await axiosCerebrum
          .get(`/api/issues/${item.id}/related`,{params:{relationship:'CREATED_BY',per_page:1}})
          // eslint-disable-next-line
          .then(response=>{
            newData = {
              ...newData,
              items:newData.items.map(i=>{
                if(i.id!==item.id)return i;
                return {
                  ...i,
                  creatorLoaded:true,
                  creator:response.data.items[0]
                }
              })
            }
          })
          // eslint-disable-next-line
          .catch(error=>{
            newData = {
              ...newData,
              items:newData.items.map(i=>{
                if(i.id!==item.id)return i;
                return {
                  ...i,
                  creatorLoaded:true,
                }
              })
            }
            console.log(error)
          })
      }

      dispatch({
        type:'set_issue_data',
        issueData:newData
      })
      
      setDataLoading(false)
    }
   
  useEffect(()=>{
    if(state.issueData && state.issueData.items.some(i=>!i.creatorLoaded) && !dataLoading){
      enrichData()
    }
  // eslint-disable-next-line
  },[state.issueData])

  if (state.issueLoading ) return (<div style={{ textAlign: 'center', marginTop: '20%' }}>
    <CircularProgress color="secondary" />
    <Typography className={classes.normalText}>Loading</Typography>
  </div>);

  if (state.issueError)
    return <Typography  className={classes.normalText}>Fail to load issues</Typography>;

  if(!state.issueData)return <div></div>

  let initialState = 'OPEN';
  if(state.issueData.items.find(el=>el.issue_status_txt==='CLOSED'))initialState = 'CLOSED'
  if(state.issueData.items.find(el=>el.issue_status_txt==='IN_PROGRESS'))initialState = 'IN_PROGRESS'
  if(state.issueData.items.find(el=>el.issue_status_txt==='OPEN'))initialState = 'OPEN'

  const tabState = state.issueData.tabState || initialState;

  const setTabState = value => {
    dispatch({
      type:'set_issue_data',
      issueData:{...state.issueData,tabState:value,search:''}
    })
  }

  const onSortChange = value => {
    dispatch({
      type:'set_issue_data',
      issueData:{...state.issueData,sort:value}
    })
  }

  const onSearchChange = value => {
    dispatch({
      type:'set_issue_data',
      issueData:{...state.issueData,search:value}
    })
  }
  
  const onRemoveIssue = (el) => {
    axiosCerebrum
      .delete(
        `/api/issues/${el.id}/related?relationship=IMPACTS&object_id=${state.basicData.id}`
      )
      .then(response=>{
        dispatch({
          type:'set_issue_data',
          issueData:{
            ...state.issueData,
            items:state.issueData.items.filter(i=>i.id!==el.id)
          }
        })
        sendAlert({
          message: `Link to ${el.name_txt} removed`,
          type: 'info'
        })
      })
      .catch(error=>{
        console.log(error);
        sendAlert({
          message: `Error occurred unlinking ${el.name_txt}`,
          type: 'error'
        })
      })
  }


  const processList = list => {
    if(tabState==='OPEN'){
      list = list.filter(l=>l.issue_status_txt==='OPEN');
    }
    else if(tabState==='IN_PROGRESS'){
      list = list.filter(l=>l.issue_status_txt==='IN_PROGRESS');
    }
    else{
      list = list.filter(l=>l.issue_status_txt==='CLOSED')
    }
    list = list.filter(l=> !state.issueData.search || (l.name_txt||l.name_srt||'').toLowerCase().includes(state.issueData.search.toLowerCase()))
    if(!state.issueData.sort || state.issueData.sort==='Severity'){
      let orderMap = {LOW:3, MEDIUM:2, HIGH:1}
      list.sort((a,b)=>orderMap[a.issue_severity] - orderMap[b.issue_severity])
    }
    if(state.issueData.sort==='Most recent')list.sort((a,b)=>-moment(a.last_updated_srt).diff(b.last_updated_srt,'minute'))
    if(state.issueData.sort==='A-Z')list.sort((a,b)=>a.name_txt.split(':').slice(1).join(':').trim()<b.name_txt.split(':').slice(1).join(':').trim()?-1:1 )
    if(state.issueData.sort==='Z-A')list.sort((a,b)=>a.name_txt.split(':').slice(1).join(':').trim()<b.name_txt.split(':').slice(1).join(':').trim()?1:-1 )
    return list;
  }

  if(state.issueData.items.length===0){
    return (
      <div style={{textAlign:'center',marginTop:80}}>
        <Typography className={classes.emptyText} style={{marginBottom:24}}>There are 0 known issue(s) with this {toTitleCase(mapObjectName(state.basicData.object.name).replace(/_/g,' '))}.</Typography>
        <Typography className={classes.emptyText}>If this is incorrect, add an issue to help warn your colleagues</Typography>
        <div style={{display:'flex',justifyContent:'center',marginTop:40}}>
          <Button 
            data-test-id="issue-section-add-issue-button" 
            color='primary' 
            variant="contained" 
            onClick={() => dispatch({ type: 'set_dqModal_open', dqModalOpen: true })}
          >
            ADD NEW ISSUE
          </Button>
          <Button 
            data-test-id="issue-section-link-issue-button" 
            color='primary' 
            variant="contained" 
            style={{marginLeft:24}}
            onClick={() => setLinkModalOpen(true)}
          >
            LINK TO EXISTING ISSUE
          </Button>
        </div>
        <GenericLinkModal
          object={state.basicData}
          history={history}
          modalOpen={linkModalOpen}
          setModalOpen={setLinkModalOpen}
          linkableObjects={['ISSUE']}
          relations={['IMPACTED_BY']}
          profileDispatch={dispatch}
          allowAddNewIssue={true}
          onLinkUpdated={()=>{
            window.postMessage({reload_issue:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
          }}
        />
      </div>
    )
  }

  const tabOptions = ['OPEN','IN_PROGRESS','CLOSED'].filter(el=>state.issueData.items.find(i=>i.issue_status_txt===el))

  return (
    <div>
      {
        state.issueData.items.length!==0 && 
        <div style={{display:'flex',height:48,justifyContent:'center',alignItems:'center',marginBottom:16,borderRadius:3,border:`1px solid ${theme.palette.listItemDivider.main}`}}>
          <Button  onClick={() => dispatch({ type: 'set_dqModal_open', dqModalOpen: true })} data-test-id="issue-section-add-issue-button" variant="outlined" className={classes.bannerButton} color='primary'>ADD AN ISSUE</Button>
          <span className={classes.bannerText} style={{marginLeft:8,marginRight:8}}>or</span>
          <Button  onClick={() => setLinkModalOpen(true)} data-test-id="issue-section-add-issue-button" variant="outlined" className={classes.bannerButton} color='primary'>LINK TO EXISTING ISSUE</Button>
          <span className={classes.bannerText} style={{marginLeft:8}}>to help warn your colleagues</span>
        </div> 
      }
      <div style={{display:'flex',}}>
        <VerticalTabBar
          tabOptions={tabOptions.map(o=>`${o.replace(/_/g,' ')} (${state.issueData.items.filter(i=>i.issue_status_txt===o).length})`)}
          tabState={tabOptions.indexOf(tabState)}
          setTabState={value => setTabState(tabOptions[value])}
        />
        <div style={{flexGrow:1,marginLeft:80, overflow:'hidden'}}>
          <div style={{display:'flex',alignItems:'flex-start',justifyContent:'space-between',flexWrap:'wrap'}}>
            <div style={{display:'flex',alignItems:'flex-start',marginBottom:12,}}>
              <Typography data-test-id="issue-section-title" className={classes.title} color='primary'> { `${processList(state.issueData.items).length} ${tabState.replace(/_/g,' ')} ISSUE(S)`}</Typography>
              <Button data-test-id="edit-issue-button" color='primary' style={{marginLeft:4,marginTop:-2}} onClick={()=>{setIsEdit(!isEdit)}}>{isEdit?"CLOSE":'EDIT'}</Button>
            </div>
            <div data-test-id="issue-section-filters" style={{display:'flex',alignItems:'flex-start',flexWrap:'wrap',marginBottom:16,flexShrink:0}}>
              <InputBase
                value={state.issueData.search}
                onChange={event => onSearchChange(event.target.value)}
                variant={'filled'}
                placeholder={'Search'}
                className={classes.searchFilter}
                inputProps={{
                  'data-test-id':"issue-section-search"
                }}
                endAdornment={
                  <IconButton 
                    disabled={state.issueData.search===''} 
                    onClick={()=>onSearchChange('')}
                    style={{width:32,height:32,marginRight:6}}
                  >
                    {getIconComponent({label:state.issueData.search===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                  </IconButton>
                }
              />
              {
                state.issueData.items.length !== 0 && 
                <Select
                  className={classes.selector}
                  value={state.issueData.sort || 'Severity'}
                  MenuProps={{
                    classes:{
                      paper:classes.selectPaper
                    }
                  }}
                  data-test-id="issue-section-sort"
                  onChange={event => onSortChange(event.target.value) }
                >
                  {
                  ['Severity','Most recent','A-Z','Z-A'].map(el => (
                      <MenuItem  value={el}>
                        {el}
                      </MenuItem>
                    ))
                  }
                </Select>
              }
            </div>
          </div>

          {
            processList(state.issueData.items).length > 0 &&
            <div>
              {
                processList(state.issueData.items).map(item => {
                  return (
                    <div className={classes.card}>
                      <Typography data-test-classname={`issue-section-header`} style={{fontSize:20,marginBottom:16,color:theme.palette.primaryText.main,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}>
                        {item.name_txt}
                      </Typography>
                      <div style={{display:'flex',flexWrap:'wrap',alignItems:'center',marginBottom:20}}>
                        {
                          item.creator && 
                          <div className={classes.userChip}  onClick={()=>onClickResultItem({label:'user',id:item.creator.id,history})}>
                            <div className={classes.avatar}>{getInitials(item.creator.name)}</div>
                            <Typography className={classes.chipName}>{item.creator.name}</Typography>
                          </div>
                        }
                        <div className={classes.chip}>
                          <Typography className={classes.chipText}>
                            Last updated {moment(item.last_updated_srt).fromNow()}
                          </Typography>
                        </div>
                        <div className={classes.chip}>
                          <Typography className={classes.chipText}>
                            {item.issue_status_txt.replace(/_/g,' ').toLowerCase()}
                          </Typography>
                        </div>
                        <div className={classes.chip}>
                          <Typography className={classes.chipText}>
                          {item.issue_severity.replace(/_/g,' ').toLowerCase()}
                          </Typography>
                        </div>
                        {
                          item.source_type_txt && item.source_type_txt!=='' &&  item.source_type_txt!=='K PLATFORM' &&
                          <div className={classes.chip}>
                            <Typography className={classes.chipText}>
                            {item.source_type_txt}
                            </Typography>
                          </div>
                        }
                      </div>
                      
                      <Typography data-test-classname="issue-chip-description" className={classes.detailBodyText} style={{paddingRight:22,wordBreak:'break-word'}}>
                        {item.description && item.description.trim()!==''?item.description:'No description provided'}
                      </Typography>

                      <div style={{display:'flex',marginTop:24}}>
                        <Button color='primary' data-test-classname="issue-chip-open-button" style={{marginRight:8}} onClick={()=>onClickResultItem({label:'issue',item:item,id:item.id,history:history})}>
                          OPEN
                        </Button>
                        <Button color='primary' data-test-classname="issue-chip-share-button" onClick={()=>handleShareClick( window.location.hostname +':'+ window.location.port + onClickResultItem({label:'issue',item:item,id:item.id,history:history,urlOnly:true}))}>
                          SHARE
                        </Button>
                        {
                          isEdit && 
                          <Button data-test-classname="issue-chip-remove-button" onClick={()=>onRemoveIssue(item)} style={{color:theme.palette.error.main,marginLeft:8}}>
                            REMOVE LINK TO ISSUE
                          </Button>
                        }
                      </div>
                    </div>
                  )
                })
              }
              {state.issueData.items.length === 0 && <Typography style={{ color: theme.palette.primaryText.light, marginTop: '2rem' }}>No issue raised for this {toTitleCase(state.basicData.object.name)}</Typography>}
            </div>
          }
        </div>
      </div>
      <GenericLinkModal
        object={state.basicData}
        history={history}
        modalOpen={linkModalOpen}
        setModalOpen={setLinkModalOpen}
        linkableObjects={['ISSUE']}
        relations={['IMPACTED_BY']}
        profileDispatch={dispatch}
        allowAddNewIssue={true}
        onLinkUpdated={()=>{
          window.postMessage({reload_issue:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
        }}
      />
    </div>
  )
}

Issues.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(Issues));