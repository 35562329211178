import React, { useEffect, useReducer } from 'react';
import { withStyles } from '@material-ui/core';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/UserManagement/Body/Body';
import 'url-search-params-polyfill';
import { addHistory } from '../../HistoryManager';

const styles = theme => ({
});

const initialState = {
  searchValue:'',
  searchField:'username'
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_user_data':
      return {
        ...state,
        userLoading:action.userLoading,
        userError:action.userError,
        userData:action.userData
      }
    case 'set_search_value':
      return {
        ...state,
        searchValue:action.searchValue
      }
    case 'set_search_field':
      return {
        ...state,
        searchField:action.searchField
      }
    case 'set_type_filter':
      return {
        ...state,
        typeFilter:action.typeFilter
      }
    case 'set_usage_type_filter':
      return {
        ...state,
        usageTypeFilter:action.usageTypeFilter
      }
    case 'set_available_teams':
      return {
        ...state,
        availableTeams:action.availableTeams,
        availableTeamsLoading:action.availableTeamsLoading,
        availableTeamsError:action.availableTeamsError
      }
    case 'set_team_filter':
      return {
        ...state,
        teamFilter:action.teamFilter
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const UserManagement = props => {

  const {
    history,
    sessionData
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(()=>{
    addHistory({url:window.location.pathname, iconLabel:'user', title: 'Users', subTitle:'User Management',type:'application'})
  },[])

  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              label='user_management'
              iconLabel="user"
              title='Users'
              subtitle=' '
            />
          </div>
        )}
        body={
          <Body
            state={state}
            history={history}
            dispatch={dispatch}
            sessionData={sessionData}
          />
        }
      />
    </div>
  )
}

export default withStyles(styles)(UserManagement);
