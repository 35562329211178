import React from 'react';
import { withStyles, withTheme} from '@material-ui/core';
import NewLineageContainer from '../../UI/Lineage/NewLineageContainer';

const styles = theme => ({
  root:{
    marginTop:-12
  }
})

const LineageMap = props => {

  const {
    classes,
    state,
    dispatch
  } = props;

  if(!state.targetObject)return <div></div>

  return (
    <div className={classes.root}>
      <NewLineageContainer
        state={state}
        dispatch={dispatch}
        target={state.targetObject}
        isApp={true}
      />
    </div>
  )
}

export default withTheme()(withStyles(styles)(LineageMap));