import React, { useEffect } from 'react';
import { withTheme, withStyles, Typography,} from '@material-ui/core';
import SelectLoadFrequency from './SelectLoadFrequency';
import SelectLoadTime from './SelectLoadTime';
import SelectLoadDay from './SelectLoadDay';
import CustomCron from './CustomCron';
import PropTypes from 'prop-types';
import moment from 'moment';

const styles = theme => ({
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    marginBottom:8,
    letterSpacing:1
  },
  block:{
    marginBottom:24
  }
})

const Scheduler = props => {

  const {
    classes,
    frequency='none',
    setFrequency,
    loadDay=-1,
    setLoadDay,
    loadTime,
    setLoadTime,
    customCron='',
    setCustomCron,
    customInputTitle
  } = props;
  
  useEffect(()=>{
    if(frequency==='none'){
      setLoadDay(-1)
      setLoadTime(moment())
      // setLoadSchedule('none')
      setCustomCron('')
    }
  // eslint-disable-next-line
  },[frequency])


  return (
    <>
      <div className={classes.block}>
        <Typography className={classes.sectionHeader}>
          FREQUENCY
        </Typography>
        <SelectLoadFrequency frequency={frequency} setFrequency={setFrequency}/>
      </div>
      {
        frequency==='custom' && 
        <div className={classes.block}>
          <Typography className={classes.sectionHeader}>
            CUSTOM CRON SCHEDULE
          </Typography>
          <CustomCron customCron={customCron} setCustomCron={setCustomCron} hideButton/>
        </div>
      }
      {
        ['weekly','monthly'].includes(frequency) &&
        <div className={classes.block}>
          <Typography className={classes.sectionHeader}>
            {customInputTitle?.['load_day']||'LOAD DAY'}{frequency==='monthly'?' (FIRST DAY OF THE MONTH)':''}
          </Typography>
          <SelectLoadDay frequency={frequency} loadDay={loadDay} setLoadDay={setLoadDay} customName={customInputTitle?.['load_day']||'LOAD DAY'}/>
        </div>
      }
      {
        frequency!=='none' && frequency!=='custom' &&
        <div className={classes.block}>
          <Typography className={classes.sectionHeader}>
            {customInputTitle?.['load_time']||'LOAD TIME'}
          </Typography>
          <SelectLoadTime loadTime={loadTime} setLoadTime={setLoadTime} customName={customInputTitle?.['load_time']||'LOAD TIME'}/>
        </div>
      }
    </>
  )
}

Scheduler.propTypes = {
  frequency: PropTypes.string,
  setFrequency: PropTypes.func,
  loadDay: PropTypes.number,
  setLoadDay: PropTypes.func,
  loadTime: PropTypes.object,
  setLoadTime: PropTypes.func,
  customCron: PropTypes.string,
  setCustomCron: PropTypes.func,
  customInputTitle: PropTypes.object
}

export default withTheme()(withStyles(styles)(Scheduler));