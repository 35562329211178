import React, { useEffect, useReducer } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/BulkLinking/Body/Body';
import ProfileHeader3 from '../../components/UI/ProfileHeader/ProfileHeader3';
import { LinearProgress, Typography } from '@material-ui/core';
import { removeUrlQueryArg } from '../../utilities';
import axiosSolr from '../../axios-solr';
import { CerebrumLongListLoader } from '../../LongListLoader';
import { getChildType } from '../../components/BulkLinking/utils';

const styles = theme => ({
  underlineOnHover: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
});


const initialState = {
  activeStep: 0,
  sources: []
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_active_step':
      return {
        ...state,
        activeStep: action.activeStep,
      }
    case 'set_sources':
      return {
        ...state,
        sources: action.sources,
      }
    case 'set_source_by_object_type':
      return {
        ...state,
        sourceByObjectType: action.sourceByObjectType,
      }
    case 'set_loading_preset_objects':
      return {
        ...state,
        loadingPresetObjects: action.loadingPresetObjects,
      }
    case 'set_upstream_source':
      return {
        ...state,
        upstreamSource: action.upstreamSource,
      }
    case 'set_downstream_source':
      return {
        ...state,
        downstreamSource: action.downstreamSource,
      }
    case 'set_upstream_parent_type':
      return {
        ...state,
        upstreamParentType: action.upstreamParentType,
      }
    case 'set_downstream_parent_type':
      return {
        ...state,
        downstreamParentType: action.downstreamParentType,
      }
    case 'set_upstream_child_type':
      return {
        ...state,
        upstreamChildType: action.upstreamChildType,
      }
    case 'set_downstream_child_type':
      return {
        ...state,
        downstreamChildType: action.downstreamChildType,
      }
    case 'reset_step_one': {
      return {
        ...state,
        isMapChild: null,
        upstreamParentObject: null,
        downstreamParentObject: null,
        upstreamMatchConfig: null,
        downstreamMatchConfig: null,
        childrenUpstreamMatchConfig: null,
        childrenDownstreamMatchConfig: null,
      }
    }
    case 'set_is_map_child':
      return {
        ...state,
        isMapChild: action.isMapChild,
      }
    case 'set_upstream_parent_object':
      return {
        ...state,
        upstreamParentObject: action.upstreamParentObject,
      }
    case 'set_downstream_parent_object':
      return {
        ...state,
        downstreamParentObject: action.downstreamParentObject,
      }
    case 'set_upstream_match_config':
      return {
        ...state,
        upstreamMatchConfig: action.upstreamMatchConfig,
      }
    case 'set_downstream_match_config':
      return {
        ...state,
        downstreamMatchConfig: action.downstreamMatchConfig,
      }
    case 'set_children_upstream_match_config':
      return {
        ...state,
        childrenUpstreamMatchConfig: action.childrenUpstreamMatchConfig,
      }
    case 'set_children_downstream_match_config':
      return {
        ...state,
        childrenDownstreamMatchConfig: action.childrenDownstreamMatchConfig,
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const BulkLinking = props => {

  const {
    history,
    sessionData
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const urlSearch = new URLSearchParams(window.location.search);

  const loadSources = () => {
    CerebrumLongListLoader({
      url: '/api/sources',
      params: {
        active_flag: true,
        reference_flag: false,
        internal_flag: false,
        sort: 'ALPHABETICAL'
      },
      per_page: 50,
      onFinishLoad: ({ data: sources }) => {
        dispatch({ type: 'set_sources', sources })
      }
    })

    axiosSolr
      .get(
        `/solr/search/select`, {
          params: {
            q: "*:*",
            fq: "object_type_srt:(TABLE OR DATASET_TABLE)",
            rows: 0,
            'json.facet': {
              object_type: {
                type: "terms",
                field: "object_type_srt",
                limit: 20,
                facet: {
                  source_id: {
                    type: "terms",
                    field: "source_id_srt",
                    limit: 200
                  }
                }
              }
            }
          }
      }
      )
      .then(res => {
        // format the response so it looks like { TABLE: [ .. sourceids ] }
        let sourceByObjectType = {
          TABLE: [],
          DATASET_TABLE: [],
          COLUMN: [],
          DATASET_FIELD: []
        }
        res.data.facets?.object_type?.buckets?.forEach(bucket => {
          sourceByObjectType[bucket.val] = bucket.source_id?.buckets?.map(b => b.val) || []
        })
        sourceByObjectType.COLUMN = sourceByObjectType.TABLE
        sourceByObjectType.DATASET_FIELD = sourceByObjectType.DATASET_TABLE
        dispatch({ type: 'set_source_by_object_type', sourceByObjectType })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const loadParentObjects = ({ upId, downId }) => {
    dispatch({ type: 'set_loading_preset_objects', loadingPresetObjects: true })
    axiosSolr
      .get(
        `/solr/search/select`, {
        params: {
          q: "*",
          fq: `id:(${[upId, downId].filter(el => el).join(' OR ')})`,
        }
      }
      )
      .then(res => {
        let docs = res.data.response.docs
        let upParent = docs.find(el => el.id === upId)
        let downParent = docs.find(el => el.id === downId)
        if (upParent && downParent) {
          dispatch({ type: 'set_upstream_parent_type', upstreamParentType: upParent.object_type_txt })
          dispatch({ type: 'set_downstream_parent_type', downstreamParentType: downParent.object_type_txt })
          dispatch({ type: 'set_upstream_child_type', upstreamChildType: getChildType(upParent.object_type_txt) })
          dispatch({ type: 'set_downstream_child_type', downstreamChildType: getChildType(downParent.object_type_txt) })
          dispatch({ type: 'set_upstream_parent_object', upstreamParentObject: upParent })
          dispatch({ type: 'set_downstream_parent_object', downstreamParentObject: downParent })
          dispatch({ type: 'set_is_map_child', isMapChild: false })
          dispatch({ type: 'set_active_step', activeStep: 1 })
          dispatch({ type: 'set_loading_preset_objects', loadingPresetObjects: false })
        }
      })
      .catch(err => {
        console.log(err)
        dispatch({ type: 'set_loading_preset_objects', loadingPresetObjects: false })
      })
  }

  useEffect(() => {
    loadSources()
    let upId = urlSearch.get('upstreamParentId')
    let downId = urlSearch.get('downstreamParentId')
    if (upId && downId) {
      loadParentObjects({ upId, downId })
      window.history.replaceState(null, null, removeUrlQueryArg({ url: window.location.toString(), keys: ['upstreamParentId', 'downstreamParentId'] }));
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (state.upstreamParentObject && state.downstreamParentObject && !state.upstreamSource && !state.downstreamSource && state.sources) {
      dispatch({ type: 'set_upstream_source', upstreamSource: state.sources.find(s => s.id === state.upstreamParentObject.source_id_srt) })
      dispatch({ type: 'set_downstream_source', downstreamSource: state.sources.find(s => s.id === state.downstreamParentObject.source_id_srt) })
    }
  }, [state.sources, state.upstreamParentObject, state.downstreamParentObject, state.upstreamSource, state.downstreamSource])

  if (state.loadingPresetObjects) {
    return (
      <div style={{ textAlign: 'center', width: '18.75rem', margin: '20vh auto' }}>
        <Typography>Loading</Typography>
        <LinearProgress style={{ marginTop: 24 }} color="secondary" />
      </div>
    )
  }


  return (
    <div>
      <ProfileLayout
        header={
          <ProfileHeader3
            title={'Lineage mapper'}
            history={history}
            minHeight={108}
            iconLabel={'lineage_mapper'}
            label={"lineage_mapper"}
          />
        }
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
          />
        }
        disableMinContentHeight
      />
    </div>
  )
}


export default withTheme()(withStyles(styles)(BulkLinking));