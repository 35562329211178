import React, {  } from 'react';
import { withTheme, withStyles, Typography, Button,} from '@material-ui/core';

const styles = theme => ({
  infoBox:theme.components.infoBox,
  infoHeader:theme.components.infoBoxHeader,
  infoDescription:theme.components.infoBoxDescription,
  focusTxt:theme.components.infoBoxFocusTxt,
  button:theme.components.infoBoxButtonContained,
  openMonitorButton:theme.components.infoBoxButtonOutlined,
})

const ImportInfoBox = props => {

  const {
    classes,
    // theme,
    history,
    dispatch
    // generateScheduleText
  } = props;
  
  return (
    <div className={classes.infoBox}>
      <Typography className={classes.infoHeader}>Importing Manual Source metadata</Typography>
      <div style={{display:'flex',alignItems:'flex-start'}}>
        <Typography className={classes.infoDescription}>
          The import source function lets you upload source metadata via an excel file. As metadata is uploaded manually, there are a number of K features that are unavailable for imported data assets:
          <ul style={{paddingInlineStart:16}}>
            <li>
              Usage analysis: Usage data (e.g. logs) cannot be imported via excel. This means usage data will not be shown in the respective dashboards, profile and extract
            </li>
            <li>
              Automated lineage: Data assets manually uploaded will need to be manually linked to lineage maps
            </li>
          </ul>
          <span style={{fontWeight:700}}>Note:</span> Upload of source metadata will fully reflect in your Data Ecosystem after the DAILY job has been. You can trigger the DAILY job anytime from the <span className={classes.focusTxt} onClick={()=>history.push('/admin/platform_management?tabName=BATCH MANAGER')}>Batch Manager</span> page.
        </Typography>
        <div>
          <Button className={classes.button} style={{width:172,marginBottom:16}} onClick={()=>{dispatch({type:"set_import_source_modal_open",importSourceModalOpen:true})}}>
            IMPORT SOURCE FILE
          </Button>
        </div>
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(ImportInfoBox));