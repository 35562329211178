import { removeUrlQueryArg } from '../../../../utilities';
import { processTileData, loadTilesByObjectType, loadByPii, loadBySourceRecommendations, loadTotalByPii} from '../loadUtils';
import 'url-search-params-polyfill';

export const loadTileData = async ({data, setData,source}) => {
  if(!data.tileData && !data.tileDataLoading){
    setData({...data,tileDataLoading:true,tileDataError:false});
    let sourceType = source.source_template.type;
    try{
      let objectTypes = [];
      if(sourceType==='DATABASE')objectTypes = ['DATABASE','SCHEMA','TABLE','COLUMN'];
      if(sourceType==='TOOL')objectTypes = ['DATA_PIPELINE','REPORT','DATASET','ML_MODEL'];
      let tileData = [];
      
      await loadTilesByObjectType({objectTypes, source}).then(facet=>{
        tileData = processTileData({facet, objectTypes})
      });


      const urlSearch = new URLSearchParams(window.location.search);
      const presetTile = urlSearch.get('usageSelectedTile') || data.selectedTile;
      let defaultTile;
      if(presetTile && tileData.find(t=>t.id===presetTile)?.clickable){
        defaultTile = presetTile;
      }else{
        defaultTile = sourceType==='DATABASE'?tileData.find(t=>t.id==='TABLE')?'TABLE':undefined:tileData[0]?.id
      }
      window.history.replaceState(null, null,removeUrlQueryArg({url:window.location.toString(),keys:['usageSelectedTile']}));
      setData({
        ...data,
        tileDataLoading:false,
        tileDataError:false,
        tileData,
        selectedTile:defaultTile
      })
      
    }catch(error){
      console.log(error);
      setData({...data,tileDataError:true,titleDataLoading:false,tileData:undefined});
    }

  }
}

export const loadGraphData = async ({data,setData,history,source, state,apiSeq,apiSeqRef}) => {
  setData({
    ...data,
    [`${data.selectedTile}_graphDataLoading`]:true,
    [`${data.selectedTile}_graphDataError`]:false,
    [`${data.selectedTile}_recommendationLoading`]:true,
    [`${data.selectedTile}_recommendationError`]:false,
  });
  let chartsData = {};
  let recommendations;
  try{

    let promsies = [
      loadTotalByPii({source, objectType:'COLUMN',state}).then(d=>chartsData[`COLUMN_BY_PII_TOTAL`] = d),
      loadTotalByPii({source, objectType:'TABLE',state}).then(d=>chartsData[`TABLE_BY_PII_TOTAL`] = d),
      loadByPii({objectType:'COLUMN',history,source, state}).then(d=>chartsData[`COLUMN_BY_PII`] = d),
      loadByPii({objectType:'TABLE',history,source,usage:'DAILY',state}).then(d=>chartsData[`DAILY_TABLE_BY_PII`] = d),
      loadByPii({objectType:'TABLE',history,source,usage:'UNUSED',state}).then(d=>chartsData[`UNUSED_TABLE_BY_PII`] = d),
      loadBySourceRecommendations({source}).then(d=>recommendations = d)
    ]
    
    await Promise.all(promsies)
    
    if(apiSeq!==apiSeqRef.current)return;
    let newDataObj = {}
    Object.keys(data).filter(el=>!el.includes('_graphDataLoading') &&  !el.includes('_graphDataError') && !el.includes('_recommendationError') && !el.includes('_recommendationLoading') )
      .forEach(k=>{
        newDataObj[k] = data[k]
      })
      
    setData({
      ...newDataObj,
      [`${data.selectedTile}_graphDataLoading`]:false,
      [`${data.selectedTile}_graphDataError`]:false,
      [`${data.selectedTile}_graphData`]:chartsData,
      [`${data.selectedTile}_recommendationLoading`]:false,
      [`${data.selectedTile}_recommendationError`]:false,
      [`${data.selectedTile}_recommendationData`]:recommendations
    })
  }catch(error){
    console.log(error);
    if(apiSeq!==apiSeqRef.current)return;
    setData({
      ...data,
      [`${data.selectedTile}_graphDataLoading`]:false,
      [`${data.selectedTile}_graphDataError`]:true,
      [`${data.selectedTile}_graphData`]:undefined,
      [`${data.selectedTile}_recommendationLoading`]:false,
      [`${data.selectedTile}_recommendationError`]:true,
      [`${data.selectedTile}_recommendationData`]:undefined
    })
  }
}
