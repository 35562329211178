import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Modal, Paper, CircularProgress, Typography, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
import useAlert from '../../../hooks/useAlert';
import Scheduler from '../../UI/Scheduler/Scheduler';
import axiosCerebrum from '../../../axios-cerebrum';
import { generateCronByConfig } from '../../UI/Scheduler/utils';
import moment from 'moment';

const styles = theme => ({
  title: {
    fontSize: 20,
    fontWeight: '500',
    color:theme.palette.header.main,
    marginBottom:24,
  },
  modalRoot:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    maxHeight:'70vh',
    overflow:'auto'
  }
})

function EditScheduleModal(props) {

  const {
    theme,
    classes,
    modalOpen, 
    setModalOpen,
    state,
    dispatch
  } = props;

  const [alertOpen, setAlertOpen] = useState();
  // eslint-disable-next-line
  const [alertMessage, setAlertMessage] = useState('')
  // eslint-disable-next-line
  const [saving, setSaving] = useState(false)

  const [frequency, setFrequency] = useState('none')
  const [loadDay, setLoadDay] = useState(-1)
  const [loadTime, setLoadTime] = useState(moment())
  const [customCron, setCustomCron] = useState('')

  const isCancelledRef = useRef(false);
  let alert = modalOpen?.alert;

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    if(!modalOpen){
      setFrequency('none')
      setLoadDay(-1)
      setLoadTime(moment())
      setCustomCron('')
    }
  },[modalOpen])

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  const checkClickable = step => {
    if(frequency==='none')return false;
    if(frequency==='daily')return true;
    if(frequency==='weekly' && loadDay===-1)return false;
    if(frequency==='monthly' && loadDay===-1)return false;
    if(frequency==='custom' && !customCron)return false;
    return true;
  }

  const onSave = (isDisable) => {
    setSaving(true)
    axiosCerebrum
      .put(
        `/api/alerts/${alert.id}`,{
          schedule:isDisable?{}:generateCronByConfig({frequency, customCron, loadTime, loadDay})
        }
      )
      .then(response=>{
        dispatch({
          type:'set_alert_list',
          alertList:{
            ...state.alertList,
            items:state.alertList.items.map(item=>{
              if(item.id===alert.id){
                return response.data
              }else{
                return item
              }
            })
          }
        })
        setSaving(false)
        setModalOpen(false)
        sendAlert({type:"info",message:"Alert updated successfully"})
      })
      .catch(error=>{
        setAlertMessage('Error occurred updating alert')
        setAlertOpen(true)
        setSaving(false)
      })
  }

  return (
    <Modal 
      open={modalOpen} 
      onClose={()=>setModalOpen(false)}
      disableBackdropClick={true}
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    > 
      <div style={{outline:'none',width:'max-content'}}>
        <Paper className={classes.modalRoot} style={{width:720,padding:24}}>
          <div style={{display:'flex',alignItems:'center'}}>
            <Typography className={classes.title}>Edit Alert Schedule</Typography>
          </div>
          <Scheduler
            frequency={frequency}
            setFrequency={setFrequency}
            loadDay={loadDay}
            setLoadDay={setLoadDay}
            loadTime={loadTime}
            setLoadTime={setLoadTime}
            customCron={customCron}
            setCustomCron={setCustomCron}
            customInputTitle={{
              'load_day':'ALERT DAY',
              'load_time':'ALERT TRIGGER TIME'
            }}
          />
          <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginBottom:-12,marginTop:24,position:'sticky',bottom:-12,background:theme.palette.background.main}}>
            {
              !saving && 
              <Button 
                color='primary' 
                data-test-id="alert-disable-button" 
                style={{marginRight:8}} 
                onClick={()=>{
                  onSave(true)
                }}
              >
                DISABLE
              </Button>
            }
            {
              saving &&
              <div style={{width:64,marginRight:8,textAlign:'center'}}>
                <CircularProgress color='secondary' style={{width:24,height:24}}/>
              </div>
            }
            {
              !saving && 
              <Button 
                color='primary' 
                data-test-id="alert-save-button" 
                disabled={!checkClickable()} 
                style={{marginRight:8}} 
                onClick={()=>{
                  onSave()
                }}
              >
                SAVE
              </Button>
            }
            <Button 
              color='secondary' 
              onClick={()=>{
                setModalOpen(false)
              }}
            >
              CANCEL
            </Button>
          </div>
        </Paper>
        <ModalAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          type='error'
        />
      </div>
    </Modal>
  )
}

EditScheduleModal.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
}

export default withTheme()(withStyles(styles)(EditScheduleModal));