import React, { } from 'react';
import { withTheme, withStyles, Select, MenuItem} from '@material-ui/core';


const styles = theme => ({
  selector:{
    ...theme.components.selector,
    width:'100%',
    height:56
  },
  errorBorder:{
    border:`1px solid ${theme.palette.error.main}`
  }
})

const SelectLoadDay = props => {

  const {
    classes,
    activeStep,
    // frequency,
    setActiveStep,
    loadDay,
    setLoadDay,
    customName
  } = props;

  return (
    <div className={classes.root}>
      <Select
        value={loadDay}
        disableUnderline
        onChange={(event)=>{
          setLoadDay(event.target.value)
          if(event.target.value===-1)return;
          setActiveStep?.(activeStep+1)
        }}
        className={classes.selector + (loadDay===-1?' '+classes.errorBorder:'')}
      >
        <MenuItem value={-1}>
          Select {customName?.toLowerCase() || 'load day'}
        </MenuItem>
        <MenuItem value={1}>
          Monday
        </MenuItem>
        <MenuItem value={2}>
          Tuesday
        </MenuItem>
        <MenuItem value={3}>
          Wednesday
        </MenuItem>
        <MenuItem value={4}>
          Thursday
        </MenuItem>
        <MenuItem value={5}>
          Friday
        </MenuItem>
        <MenuItem value={6}>
          Saturday
        </MenuItem>
        <MenuItem value={0}>
          Sunday
        </MenuItem>
      </Select>
    </div>
  )
}

export default withTheme()(withStyles(styles)(SelectLoadDay));