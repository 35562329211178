import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Home from '../TeamsBody/TeamsBody';
import CreateTeam from '../CreateTeam/CreateTeam';
import EditTeam from '../EditTeam/EditTeam';

const styles = theme => ({
  root: {

  }
})

const Body = props => {

  const {
    history,
    classes,
    state,
    dispatch,
  } = props;

  let bodyContent;

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])

  switch (state.tabState) {
    case 0: // home
      bodyContent = <Home history={history} state={state} dispatch={dispatch}  />
      break;
    case 1: // create new
      bodyContent = <CreateTeam history={history} state={state} dispatch={dispatch}  />
      break;
    case 2: // edit 
      bodyContent = <EditTeam isEdit={true} history={history} state={state} dispatch={dispatch} />
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

export default withStyles(styles)(Body);