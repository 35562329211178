import React, { useEffect, useRef, useState,} from 'react';
import { withTheme, withStyles, Paper, Button, CircularProgress} from '@material-ui/core';
import {  collectionIds, formatTimeToUtc, toTitleCase } from '../../../utilities';
import { Typography } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import ModalAlert from '../ModalAlert/ModalAlert';
import {checkFilled,EditFields} from '../CollectionInstanceEditFields/CollectionInstanceEditFields'
import useAlert from '../../../hooks/useAlert';
import { onUpdateCategory } from '../../../CategoryManager';
import moment from 'moment';

const styles = theme => ({
  root:{
    width:800,
    outline:'none',
    maxHeight:'80vh',
    margin:'auto',
    marginTop:'5vh',
    minHeight:0,
    overflowX:'hidden',
    border:`1px solid ${theme.palette.border.main}`
  },
  paper:{
    display:'flex',
    flexDirection:'column',
    maxHeight:'70vh',
    border:theme.palette.border.main,
    background:theme.palette.background.main
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
    marginBottom:24,
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis'
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  modalBody:{
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.main
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      background: theme.palette.primaryText.light,
      borderRadius:4,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{
      
    },
    '&::-webkit-scrollbar': {
      width: 5
    },
  },
  buttons:{
    paddingTop:12,
    paddingBottom:12,
    paddingRight:8,
    flexGrow:0,
    display:'flex',
    justifyContent:'flex-end'
  },
});

const Editor = props => {
  const {
    classes,
    data,
    properties,
    setEditing,
    fetchList,
    userMap, 
    setUserMap,
    collectionMap,
    setCollectionMap,
    onUpdated,
    // dispatch,
    // state
  } = props;
  
  const [alertOpen, setAlertOpen] = useState(false);
  const [values, setValues] = useState({'name':data.name,'description':data.description,...data.properties||{}});
  const [expiry, setExpiry] = useState(moment(data.expiry));
  const [alertMessage, setAlertMessage] = useState('');
  const [saving, setSaving] = useState(false);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  
  useEffect(()=>{
    setValues({
      'name':data.name,'description':data.description,...data.properties||{}
    })
  },[data])

  const onSave = () => {
    setSaving(true)
    let payload = {};
    payload.name = values['name'];
    payload.description = (values['description']||'').replace(/^(<br>|\s)*/,'').replace(/(<br>|\s)*$/,'');
    payload.properties = {};
    payload.source_id = data.source_id;
    if(data.parent.id===collectionIds.warningNotice){
      payload.expiry = formatTimeToUtc(expiry);
    }
    for(let i=0; i<properties.length; i++){
      let propertyId = properties[i].id;
      let required = properties.required;
      if(!required && (values[propertyId]===''||values[propertyId]==='placeholder'))continue;
      payload.properties[propertyId] = values[propertyId];
    }

    axiosCerebrum
      .put(
        `/api/collectioninstances/${data.id}`,
        payload
      )
      .then(async response=>{
        if(response.data.parent?.id===collectionIds.category){
          onUpdateCategory(response.data)
        }
        let msg = "Instance successfully updated";
        if(response.data.parent.name==='Question')msg = 'Question successfully updated'
        if(response.data.parent.category==='GLOSSARY')msg = 'Term successfully updated'
        sendAlert({
          message:msg,
          type:'info'
        })
        fetchList && fetchList()
        onUpdated && onUpdated(response.data)
        setEditing(false)
        window.scrollTo(0,0)
        setSaving(false)
      })
      .catch(error=>{
        let msg = 'Error occurred updating instance, please try again'
        if(error.response && error.response.status && error.response.status===409){
          msg = 'Error occurred. Duplicate instance name. Please review and enter a different name'
        }
        setAlertMessage(msg)
        setAlertOpen(true);
        setSaving(false)
        console.log(error);
      })
  }
  
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.modalBody} style={{flexGrow:1,overflowY:'auto',overflowX:'hidden',padding:24,marginTop:1}}>
          <Typography className={classes.title}>{`Edit ${toTitleCase(data.name)} Instance details`}</Typography>
          <EditFields
            properties={properties}
            values={values}
            setValues={setValues}
            userMap={userMap}
            setUserMap={setUserMap}
            collectionMap={collectionMap}
            setCollectionMap={setCollectionMap}
            collection={data.parent}
            object={data}
            expiry={expiry}
            setExpiry={setExpiry}
          />
        </div>

        <div className={classes.buttons}>
          {
            !saving &&
            <Button classes={{disabled:classes.disabledButton}} color='primary' style={{width:96,height:36,marginRight:16}} onClick={onSave} disabled={!checkFilled(properties,values)}>SAVE</Button>
          }
          {
            saving && <div style={{width:96,height:36,display:'flex',alignItems:'center',justifyContent:'center',marginRight:16}}><CircularProgress color='secondary' style={{width:24,height:24}}/></div>
          }
          <Button color='secondary' style={{width:96,height:36}} onClick={()=>{setEditing(false);window.scrollTo(0,0)}}>CANCEL</Button>
        </div>
      </Paper>
      <ModalAlert
        open={alertOpen}
        setOpen={setAlertOpen}
        message={alertMessage}
        type='error'
      />
    </div>
  )
}


export default withTheme()(withStyles(styles)(Editor));