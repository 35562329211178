import React, {useEffect}  from 'react';
import { withTheme,withStyles,Typography} from '@material-ui/core';
import { getIconComponent } from '../../../utilities'

const styles = theme => ({
  root: {
  },
})


const ModalAlert = props => {

  const {
    classes,
    open,
    setOpen,
    type, // error, info
    message,
    buttons,
    autoCloseTime=5000,
    disableAutoClose
  } = props;

  useEffect(()=>{
    if(open && !disableAutoClose){
      setTimeout(()=>{
        setOpen(false);
      },autoCloseTime)
    }
    // eslint-disable-next-line
  },[open])


  let colour = '';
  switch(type){
    case 'error':
      colour = '#B00020'
      break;
    case 'info':
      colour = '#003C8F';
      break;
    default:
      colour = '#003C8F'
  }

  return (
    <div className={classes.root} style={{width:'100%',background:colour,display:open?'flex':'none',alignItems:'center',padding:'14px 0',borderRadius:6}}>
      <div style={{marginLeft:16,marginRight:16}}>
        {getIconComponent({label:'info',size:24,colour:'#fff'})}
      </div>
      <Typography style={{marginRight:58,whiteSpace:'pre-wrap',color:'#fff',flexGrow:1}}>
        {typeof(message)==='string'?message:'Error occurred'}
      </Typography>
      {buttons}
      {
        buttons && 
        <div style={{flex:'0 0 12px'}}>
        </div>
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(ModalAlert));