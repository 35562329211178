import React from 'react';
import { withStyles} from '@material-ui/core';
import Configuration from '../Configuration/Configuration';
import Results from '../Results/Results';
import LinkedTo from '../LinkedTo/LinkedTo';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';

const styles = theme => ({
  root: {

  }
})

const Body = props => {

  const {
    classes,
    history,
    dispatch,
    state,
    fetchList
  } = props;


  return (
    <div className={classes.root}>
      <div style={{marginBottom:40}}>
        <UpdateInputCerebrum
          url={`/api/dataqualitytests/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
          label="data_quality_test"
          initialValue={state.basicData.description}
          property="description"
          placeholder="Add a description"
          multiline
          data={state.basicData}
          fetchList={fetchList}
          enableMarkDown={true}
          enableWidget={true}
          state={state}
        />
      </div>
      <Configuration state={state} dispatch={dispatch}/>
      <LinkedTo  state={state} dispatch={dispatch} history={history}/>
      <Results state={state} dispatch={dispatch} selectedRecord={state.selectedRecord}/>
    </div>
  )
}


export default withStyles(styles)(Body);