import React, {useRef, useState, useEffect} from 'react';
import { withTheme, withStyles, Typography, Button, FormControl,Select,MenuItem, FormHelperText, Stepper, Step, StepLabel, StepContent, InputAdornment} from '@material-ui/core';
import SearchSelector from '../../UI/SearchSelector/SearchSelector';
import {getIconComponent, getLabelPlural, mapSearchObjectName, toTitleCase} from '../../../utilities';
import axiosSolr from '../../../axios-solr';
import axiosCerebrum from '../../../axios-cerebrum'
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {
    marginTop:16
  },
  block:{
    maxWidth:640,
    marginLeft:6,
    marginTop:8
  },
  title:{
    fontSize:16,
    color:theme.palette.header.main,
  },
  chip: {
    padding: '0.5rem',
    display: 'inline-block',
    backgroundColor: theme.palette.chip.main,
    border:`1px solid ${theme.palette.border.main}`,
    marginBottom: '1rem',
    borderRadius: 5
  },
  selector: {
    ...theme.components.selector,
    width: 618,
    height:56,
    marginTop: '0.5rem',
  },
  stepper:{
    marginBottom:120,
    backgroundColor:theme.palette.background.main
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
})

const Settings = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
  } = props;

  const [objectSearchValue, setObjectSearchValue] = useState('');
  const [schemaSearchValue, setSchemaSearchValue] = useState('');
  const [contentTypeList, setContentTypeList] = useState([]);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  let steps = ['Select a Data Source','Select a Schema','Select a Target','Generate Lineage Map']

  const getStepLabelText = index => {
    switch(index){
      case 0:
        if(state.settingStep!==0 && state.sourceList.length>0){
          return `Source: ${toTitleCase(state.sourceList.find(s=>s.id===state.source).name)}`;
        }
        return steps[index];
      case 1:
        let isContent;
        if(state.source && state.source!=='none' && state.sourceList.length>0){
          isContent = state.sourceList.find(el=>el.id===state.source).source_template.type==='TOOL';
        }
        if(state.settingStep!==1 && state.stepTwoFilter){
          return isContent?`Content type: ${state.stepTwoFilter}`:`Schema: ${toTitleCase(state.stepTwoFilter.name)}`
        }
        if(state.settingStep>1 && !state.stepTwoFilter){
          return isContent?'Content Type: All types':'Schema: All Schemas'
        }
        return isContent?'Select a Content Type':'Select a Schema';
      case 2:
        if(state.settingStep!==2 && state.targetObject){
          return `Target: ${toTitleCase(state.targetObject.name)}`
        }
        return steps[index];
      case 3:
        return steps[index];
      default:
        return ''
    }
  }

  const loadContentType = (sourceId) => {
    axiosSolr
      .get(
        '/solr/search/select',
        {params:{
          q:'*',
          fq:`source_id_srt:${sourceId} AND -object_type_srt:(CODE OR TOOL)`,
          rows:0,
          facet:'on',
          'facet.field':'object_type_srt',
          'facet.mincount':1,
        }}
      ).then(response=>{
        if(response.data.facet_counts && response.data.facet_counts.facet_fields && response.data.facet_counts.facet_fields.object_type_srt){
          setContentTypeList(response.data.facet_counts.facet_fields.object_type_srt.filter((l,index)=>index%2===0));
        }
      }).catch(error=>{
        console.log(error)
      })
  }

  const onSelectSource = event => {
    dispatch({type:'set_source',source:event.target.value})
    setContentTypeList([]);
    if(event.target.value!=='none'){
      dispatch({type:'set_setting_step',settingStep:1})
      if(state.sourceList.find(el=>el.id===event.target.value).source_template.type==='TOOL'){
        loadContentType(event.target.value);
      }
    }
  }
  
  const onSelectSchema = schema => {
    if(state.stepTwoFilter && schema.id===state.stepTwoFilter.id){
      dispatch({type:'set_setting_step',settingStep:2})
      return;
    }
    dispatch({type:'set_step_two_filter',stepTwoFilter:schema})
    dispatch({type:'set_target_object'})
    dispatch({type:'set_setting_step',settingStep:2})
  }

  const onSelectContentType = type => {
    if(type!==state.stepTwoFilter){
      dispatch({type:'set_step_two_filter',stepTwoFilter:type})
      dispatch({type:'set_target_object'})
      dispatch({type:'set_setting_step',settingStep:2})
    }else{
      dispatch({type:'set_setting_step',settingStep:2})
    }
  }

  const onRemoveTarget = () => {
    dispatch({type:'set_target_object'});
  }

  const onSelectTarget = object => {
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(mapSearchObjectName(object.labels,object.code_tyep_txt))}/${object.id}`
      )
      .then(response=>{
        window.history.replaceState(null, null, window.location.toString().split('?')[0]+`?targetObjectId:${response.data.id}`);
        dispatch({type:'set_target_object',targetObject:{...response.data,...object}})
        dispatch({type:'set_setting_step',settingStep:3})
      })
      .catch(error=>{
        sendAlert({message:'Error occurred setting target object',type:'error'})
      })    
  }


  const labelClickable = index => {
    if(index===0)return true;
    if(index===1 && state.source!=='none')return true;
    if(index===2 && state.source!=='none')return true;
    if(index===3 && state.source!=='none')return true;
    if(index===4 && state.lineageData.view!=='none')return true;
    return false
  }

  const onLabelClick = index => {
    if(!labelClickable(index))return;
    dispatch({type:'set_setting_step',settingStep:index})
  }

  const checkRequired = () => {
    if(state.source==='none' || !state.targetObject || state.lineageData.view==='none' )return false;
    return true
  }

  const onRun = () => {
    dispatch({
      type:'set_tab_state',
      tabState:1
    })
  }

  const getStepContent = index => {
    let isContent;
    if(state.source && state.source!=='none' && state.sourceList.length>0){
      isContent = state.sourceList.find(el=>el.id===state.source).source_template.type==='TOOL';
    }
    switch(index){
      case 0:
        return (
          <div className={classes.block}>
            <FormControl>
              <Select
                className={classes.selector}
                value={state.source}
                onChange={onSelectSource}
                startAdornment={
                  <InputAdornment style={{marginLeft:16}}>{getIconComponent({label:'database',size:24,colour:theme.palette.primaryText.light})}</InputAdornment>
                }
                renderValue={el=>{
                  return (
                    (el==='none' || state.sourceList.length===0)
                      ?
                      <Typography style={{color:theme.palette.primaryText.light,fontSize:16}}>
                        Select a Source
                      </Typography>
                      :
                      <div style={{marginLeft:-14}}>
                        <Typography style={{color:theme.palette.primaryText.light,fontSize:12}}>
                          Select the Source
                        </Typography>
                        <Typography style={{fontSize:16,color:theme.palette.primaryText.main}}>
                          {state.sourceList.find(s=>s.id===el).name}
                        </Typography>
                      </div>
                      
                  )
                }}
              > 
                <MenuItem className={classes.menuItem} value={'none'}>
                  Select the Source
                </MenuItem>
                {
                  state.sourceList.filter(el=>![995,998].includes(el.id)).map(el=>(
                    <MenuItem className={classes.menuItem} value={el.id}>
                      {el.name}
                    </MenuItem>
                  ))
                }

              </Select>
              <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>Required</FormHelperText>
            </FormControl>
          </div>
        )
      case 1:
        return (
          <div>
            {
              !isContent && 
              <div className={classes.block}>
                {
                  state.stepTwoFilter
                  ?
                  <div className={classes.chip}>
                    <div style={{marginRight:13,marginLeft:10, display:'inline-block', verticalAlign:'middle'}}>
                      {getIconComponent({label:'schema', size:24,colour:theme.palette.primary.main})}
                    </div>
                    <Typography style={{display:'inline-block', verticalAlign:'middle',color:theme.palette.primaryText.main}}>{state.stepTwoFilter.name}</Typography>
                    {
                      <div style={{marginLeft: 20, display:'inline-block', verticalAlign: 'middle'}}>
                        <Button style={{padding:0,width:24,minWidth:24}} onClick={()=>dispatch({type:'set_step_two_filter'})}>{getIconComponent({label:'clear', size:20})}</Button>
                      </div>
                    }	
                  </div>
                  :
                  <div className={classes.chip}> 
                    <div style={{marginRight:13,marginLeft:10,display:'inline-block', verticalAlign: 'middle'}}>{getIconComponent({label:'schema', size:24, colour:theme.palette.primary.main})}</div>
                    <Typography style={{display:'inline-block', verticalAlign:'middle', paddingRight: '1rem',color:theme.palette.primaryText.main}}>No Schema selected</Typography>
                  </div>
                }
                <SearchSelector
                  url='/solr/search/select'
                  params={{
                    q: `${schemaSearchValue}*`,
                    fq:`object_type_srt:SCHEMA AND reference_srt:NO AND source_id_srt:${state.source}`,
                    fl:"name:name_txt,id,location_txt,labels:object_type_txt",
                    rows: 10
                  }}
                  removeFLModify={false}
                  searchValue={schemaSearchValue}
                  setSearchValue={setSchemaSearchValue}
                  leftIcon={getIconComponent({label:'schema', size:24,colour:theme.palette.primaryText.light})}
                  placeholder='Search for a schema in the source to refine the profile'
                  onResultClick={el=>onSelectSchema(el)}
                />
                <FormHelperText style={{marginLeft:18,color:theme.palette.primaryText.light}}>Optional</FormHelperText>
                <Button variant='contained' color='primary' style={{width:96,height:36,marginTop:24}} onClick={()=>dispatch({type:'set_setting_step',settingStep:2})}>
                  SKIP
                </Button>
              </div>
            }
            {
              isContent && 
              <div className={classes.block}>
                <div>
                  <FormControl>
                    <Select
                      className={classes.selector}
                      style={{width:378}}
                      value={state.stepTwoFilter||'all'}
                      startAdornment={
                        <InputAdornment style={{marginLeft:16}}>{getIconComponent({label:'content',size:24,colour:theme.palette.primaryText.light})}</InputAdornment>
                      }
                      onChange={event=> onSelectContentType(event.target.value)}
                    > 
                      <MenuItem value={'all'}>
                        All Types
                      </MenuItem>
                      {
                        contentTypeList.map(el=>(
                          <MenuItem value={el}>
                            {toTitleCase(el.replace(/_/g,' '))}
                          </MenuItem>
                        ))
                      }
                    </Select>
                    <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>Optional</FormHelperText>
                  </FormControl> 
                </div>
                <Button variant='contained' color='primary' style={{width:96,height:36,marginTop:24}} onClick={()=>dispatch({type:'set_setting_step',settingStep:2})}>
                  NEXT
                </Button>
              </div>
            }
          </div>  
        )
      case 2:
        return (
          <div>
            <div className={classes.block}>
              {
                state.targetObject
                ?
                <div className={classes.chip}>
                  <div style={{marginRight:13,marginLeft:10, display:'inline-block', verticalAlign:'middle'}}>
                    {getIconComponent({label:isContent?'content':'table', size:24,colour:theme.palette.primary.main})}
                  </div>
                  <Typography style={{display:'inline-block', verticalAlign:'middle',color:theme.palette.primaryText.main}}>{state.targetObject.name}</Typography>
                  {
                    <div style={{marginLeft: 20, display:'inline-block', verticalAlign: 'middle'}}>
                      <Button style={{padding:0,width:24,minWidth:24}} onClick={()=>onRemoveTarget()}>{getIconComponent({label:'clear', size:20})}</Button>
                    </div>
                  }	
                </div>
                :
                <div className={classes.chip}> 
                  <div style={{marginRight:13,marginLeft:10,display:'inline-block', verticalAlign: 'middle'}}>{getIconComponent({label:isContent?'content':'table', size:24, colour:theme.palette.primary.main})}</div>
                  <Typography style={{display:'inline-block', verticalAlign:'middle', paddingRight: '1rem',color:theme.palette.primaryText.main}}>No target selected</Typography>
                </div>
              }
              <SearchSelector
                url={'/solr/search/select'}
                params={{
                  q:`${objectSearchValue}*`,
                  fq:isContent?
                        `${state.stepTwoFilter&&state.stepTwoFilter!=='all'?`object_type_srt:${state.stepTwoFilter} AND `:'object_type_srt:(CONTENT OR CONTENT_CHILD OR DATASET OR DATA_PIPELINE) AND '}source_id_srt:${state.source}`
                        :
                        `object_type_srt:TABLE AND reference_srt:NO AND source_id_srt:${state.source} ${state.stepTwoFilter?`AND schema_srt:${state.stepTwoFilter.name}`:''}`,
                  fl:"name:name_txt,labels:object_type_txt,id,location_txt,code_type_txt",
                  rows: 10
                }}
                removeFLModify={false}
                searchValue={objectSearchValue}
                setSearchValue={setObjectSearchValue}
                leftIcon={getIconComponent({label:isContent?'content':'table', size:24,colour:theme.palette.primaryText.light})}
                placeholder={isContent?'Search for a content to assess':'Search for a table to assess'}
                onResultClick={onSelectTarget}
                suggestionListWidth={800}
              />
              <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>Required</FormHelperText>
            </div>
          </div>
        )
        case 3:
          return (
            <div className={classes.block}>
              <Button disabled={!checkRequired()} variant='contained' color='primary' style={{width:96,height:36,marginTop:24}} onClick={()=>onRun()}>
                RUN
              </Button>
            </div>
          )
        default:
          return <div></div>
    }
  }

  return (
    <div className={classes.root}>
      <Typography style={{fontSize:20,marginBottom:12,color:theme.palette.header.main,}}>EXPLORER SETTINGS</Typography>
      
      <Stepper activeStep={state.settingStep} orientation="vertical" className={classes.stepper} >
        {steps.map((label, index) => (
          <Step key={index} className={classes.step}>
            <StepLabel style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}}>
              <Typography color='primary' style={{color:state.settingStep<index?theme.palette.primaryText.light:theme.palette.primaryText.main,fontSize:16,marginLeft:6}}>{getStepLabelText(index)}</Typography> 
            </StepLabel>
            <StepContent>
             {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
     
      
    </div>
  )
}

export default withTheme()(withStyles(styles)(Settings));