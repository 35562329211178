import React, {useEffect, useState, useRef} from 'react';
import { withTheme, withStyles, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Button, Divider} from '@material-ui/core';
import { toTitleCase } from '../../../../utilities';
import '@toast-ui/editor/dist/toastui-editor-viewer';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';
import { Editor as MDEditor} from '@toast-ui/react-editor';
import KTooltip from '../../../UI/KTooltip/KTooltip';

const styles = theme => ({
  chipText:{
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    width:"max-content",
    maxWidth:'100%'
  },
  expansionPanel:{
    // cursor:'default',
    paddingBottom:0,
    borderRadius:`0 !important`,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    '&::before': {
      background: 'none'
    },
  },
  selectedPanel:{
    background:theme.palette.hovered.main
  },
  expansionSummary:{
    padding:0,
    minHeight: 64,
  },
  expanded:{
    margin:0
  },
  summaryRoot:{
    // background:theme.palette.background.main,
    borderBottom: 'none',
    // cursor:'default',
    height: 64,
    padding: 0,
    borderRadius:0,
    margin: 0,
    '&$expanded': {
      height:64,
      padding: 0,
      // cursor:'default'
    },
  },
  summaryContent:{
    width:'100%'
  },
  rootExpanded:{
    margin:0,
    borderRadius:`0 !important`,
    // cursor:"default",
  },
  expansionPanelDetails:{
    flexDirection:'column',
    background:theme.palette.background.main,
    paddingLeft:16,
    paddingRight:16,
    paddingTop:0,
    borderBottom:'none'
  },
  ratingsInput:{
    width:48,
    height:48,
    fontSize:32,
    color:theme.palette.primaryText.light,
    background:theme.palette.background.main,
    border:'none',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  selectedRatingsInput:{
    width:48,
    height:48,
    fontSize:32,
    color:theme.palette.primary.main,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  tooltip:{
    fontSize:13.75,
    maxWidth:'100vw'
  },
  hide:{
    display:'none'
  },
  button:{
    padding:'2px 8px',
    // width:36,
    flexGrow:0,
    minWidth:36,
    // width:'max-content',
    marginRight:16
  },
  inputBase:{
    ...theme.components.inputBase,
    flexGrow:1,
    padding:16,
    maxWidth:768,
  }
})

function ButtonExpandableChip(props) {

  const {
    classes,
    theme,
    processedSurvey,
    onSubmit,
    onSkip,
    rawSurveyObject,
    answeringQuestion,
    selectedId,
    setSelectedId,
    openedId,
    setOpenedId,
    buttonWidth=300
  } = props;

  // Each processedSurvey has below attributes:

  // questionTitle
  // actionType
  // description
  // options
  // objectSignature
  // objectLabel
  const [selectedAnswer, setSelectedAnswer] = useState('none')  
  const [textInput, setTextInput] = useState('')
  const [inputShown, setInputShown] = useState(false)
  const inputShownTimeoutRef = useRef()
  const MDRef = useRef()

  useEffect(()=>{
    if(processedSurvey.targetObject && processedSurvey.targetObject.description && processedSurvey.description.toUpperCase()==='DESCRIPTION'){
      setTextInput(processedSurvey.targetObject.description)
    }
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(openedId===rawSurveyObject.id){
      clearTimeout(inputShownTimeoutRef.current)
      setInputShown(true)
    }
    else{
      inputShownTimeoutRef.current = setTimeout(() => {
        setInputShown(false)
      }, 1000);
    }
  // eslint-disable-next-line
  },[openedId])

  const getButtonStyle = (value) => {
    if(selectedAnswer===value){
      return classes.selectedRatingsInput;
    }else{
      return classes.ratingsInput
    }
  }

  const onClickExpansion = () => {
    if(openedId===rawSurveyObject.id){
      setOpenedId()
    }else{
      setOpenedId(rawSurveyObject.id)
    }
  }

  return (
    <div onMouseEnter={()=>setSelectedId(rawSurveyObject.id)} >
      <ExpansionPanel elevation={0} expanded={openedId===rawSurveyObject.id} className={classes.expansionPanel + (selectedId===rawSurveyObject.id?' '+classes.selectedPanel:'')} classes={{expanded:classes.rootExpanded}} >
        <ExpansionPanelSummary  onClick={()=>onClickExpansion()} classes={{expanded: classes.expanded,root:classes.summaryRoot, content:classes.summaryContent}}  className={classes.expansionSummary}>
          <div style={{display:'flex',width:'100%',paddingRight:0}}>
            <Typography className={classes.chipText} style={{flex:'1 1 500px',paddingLeft:16,whiteSpace:'pre-wrap',maxHeight:42}}>
              {processedSurvey.questionTitle}
            </Typography>
            <div  style={{flex:'2 1 410px',paddingLeft:16, overflow:'hidden'}} >
              <div style={{display:'flex',overflow:'hidden'}}>
                <Typography className={classes.chipText} style={{textDecoration:'none', marginRight:8}}>{toTitleCase(processedSurvey.objectLabel)}: </Typography>
                <KTooltip title={processedSurvey.objectName} onClick={processedSurvey.onItemClick} style={{cursor:'pointer',textDecoration:'underline',color:theme.palette.hyperLink.main}} classes={{tooltip:processedSurvey.objectSignature===''?classes.hide:classes.tooltip}}>
                  <Typography className={classes.chipText}>
                  {processedSurvey.objectName}
                  </Typography>
                </KTooltip>
              </div>
                <KTooltip title={processedSurvey.objectSignature} classes={{tooltip:processedSurvey.objectSignature===''?classes.hide:classes.tooltip}}>
                  <Typography className={classes.chipText} style={{marginTop:2, color:theme.palette.primaryText.light}}>
                    {processedSurvey.objectSignature}
                  </Typography>
                </KTooltip>
            </div>
            <div className={classes.chipText} style={{flex:`0 0 ${buttonWidth}px`,display:'flex',alignItems:'center',paddingLeft:16}}>
              <div style={{display:'flex',justifyContent:'flex-start',flexGrow:0}}>
                <Button disabled={answeringQuestion!==-1} className={classes.button} style={{width:'max-content'}} color='primary' variant='outlined' onClick={()=>onClickExpansion()}>
                  {openedId===rawSurveyObject.id?'HIDE':processedSurvey.targetObject.description && processedSurvey.targetObject.description.trim()!==''?'REVIEW':'ADD'}
                </Button>
              </div>
              <Button disabled={answeringQuestion!==-1} variant="outlined" color="secondary" className={classes.button} style={{marginLeft:0,marginRight:32, flexShrink:0, flexGrow:0}} onClick={()=>onSkip(rawSurveyObject)}>
                SKIP
              </Button>
            </div>
            {/* <div className={classes.chipText} style={{flex:'1 0 250px',paddingLeft:16}}></div> */}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <Divider style={{background:theme.palette.listItemDivider.main,width:'100%'}}/>
          <Typography className={classes.chipText} style={{color:theme.palette.primary.main,marginTop:16,marginBottom:16,fontSize:12,letterSpacing:2}}>
            {processedSurvey.description.toUpperCase()}
          </Typography>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginBottom:32}}>
            {
              ['rating','button'].includes(processedSurvey.actionType) && processedSurvey.options.map((el,index)=>(
              <div key={el.name} style={{display:'flex',flexDirection:'column',marginTop:16,alignItems:"center",marginRight:index!==processedSurvey.options.length-1?40:0}}>
                <Button 
                  className={getButtonStyle(el.name)} 
                  onClick={()=>setSelectedAnswer(el.name)}
                >
                  {el.name}
                </Button>
                <Typography style={{marginTop:4,fontSize:13.75,color:selectedAnswer===el.name?theme.palette.primary.main:theme.palette.primaryText.main,width:48,textAlign:'center'}}>
                  {el.description}
                </Typography>
              </div>
            ))}
            {
              processedSurvey.actionType==='text_box' && inputShown && 
              <div style={{width:"100%",display:'flex'}}>
                <MDEditor
                  autofocus={false}
                  ref={MDRef}
                  height={'300px'}
                  hideModeSwitch
                  onChange={()=>setTextInput(MDRef.current.getInstance().getMarkdown())}
                  initialValue={textInput}
                  initialEditType="wysiwyg"
                  useCommandShortcut={true}
                  previewStyle="vertical"
                  theme={localStorage.hasOwnProperty('dark')?"dark":"white"}
                  usageStatistics={false}
                  toolbarItems={
                    [
                      ['heading', 'bold', 'italic', 'strike'],
                      ['hr', 'quote'],
                      ['ul', 'ol', 'indent', 'outdent'],
                      ['table','link'],
                      ['code', 'codeblock']
                    ]
                  }
                />
              </div>
            }
          </div>
          {
            ['rating','button'].includes(processedSurvey.actionType) && 
            <div style={{marginBottom:16,display:'flex',justifyContent:'center'}}>
              <Button 
                variant='contained' 
                color='primary' 
                disabled={selectedAnswer==='none' || answeringQuestion!==-1} 
                classes={{disabled:classes.disabledButton}} 
                style={{height:36,marginRight:16}} 
                onClick={()=>onSubmit(rawSurveyObject,[selectedAnswer])}
              >
                SUBMIT
              </Button>
              <Button 
                onClick={()=>onSkip(rawSurveyObject)} 
                disabled={answeringQuestion!==-1} 
                classes={{disabled:classes.disabledButton}} 
                style={{color:theme.palette.primaryText.light}}
              >
                SKIP
              </Button>
            </div>
          }
          {
              processedSurvey.actionType==='text_box' && 
              <div style={{marginBottom:16,display:'flex',justifyContent:'flex-start'}}>
                <Button 
                  variant='contained' 
                  color='primary' 
                  disabled={textInput.trim()===''} 
                  classes={{disabled:classes.disabledButton}} 
                  style={{height:36,marginRight:16}} 
                  onClick={()=>onSubmit(rawSurveyObject,[textInput])}
                >
                  SAVE
                </Button>
              </div>
            }
          
          
        </ExpansionPanelDetails>
      </ExpansionPanel>
          
    </div>  
  )
}

export default withTheme()(withStyles(styles)(ButtonExpandableChip));