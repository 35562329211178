import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Dashboard from '../../UI/Dashboard/DirectIndirectDashboard/DirectIndirectDashboard';
import Details from '../Details/Details';
// import ChangeAdder from '../../UI/ChangeAdder/ChangeAdder'
import NoteAdder from '../../UI/NoteAdder/NoteAdder'
import Issues from '../../UI/ProfileIssueSection/Issues';
import NewLineageContainer from '../../UI/Lineage/NewLineageContainer'
import ChildList from '../ChildList/ChildList'
import ChangeManager from '../../UI/ChangeManager/ChangeManager';

const styles = theme => ({
  root: {

  },
  ItemListWrapper: {
  }
})

function Body(props) {

  const {
    classes,
    history,
    sessionData,
    // new props
    state,
    dispatch,
    fetchList,
    setLinkModalOpen,
    tabOptions,
    isLineageLinkable
  } = props;
 
  let bodyContent;

  switch (tabOptions[state.tabState]) {
    case 'SHEETS':
      bodyContent = (
        <ChildList
          state={state}
          dispatch={dispatch}
          history={history}
        />
      )
      break;
    case 'DETAILS': // details
      bodyContent = (
        <Details
          state={state}
          cerebrumLabel={'contents'}
          fetchList={fetchList}
          history={history}
          dispatch={dispatch}
          sessionData={sessionData}
          setLinkModalOpen={setLinkModalOpen}
        />
      )
      break;
    case 'KNOWLEDGE':
      bodyContent = (
        <NoteAdder
          history={history}
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.noteModalOpen}
          setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
        />
      )
      break;
    case 'USAGE': // summary
      bodyContent = (
        <Dashboard
          history={history}
          state={state}
          label='content'
          dispatch={dispatch}
          drillThrough={true}
        />
      )
      break;  
    case 'LINEAGE':
      bodyContent=(
        <NewLineageContainer
          history={history}
          state={state}
          dispatch={dispatch}
          isLinkable={isLineageLinkable}
        />
      )
      break;
    case 'ISSUES':
      bodyContent = (
        <Issues
          history={history}
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    case 'CHANGES':
      bodyContent = (
        <ChangeManager state={state} dispatch={dispatch} history={history}/>
      )
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.object,
  sessionData: PropTypes.object
}

export default withStyles(styles)(Body);