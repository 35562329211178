import React, {useEffect, useRef, useState} from 'react';
import { CircularProgress, withStyles, Typography, IconButton, Modal, Paper, Button, LinearProgress} from '@material-ui/core';
import theme from '../../../theme';
import moment from 'moment';
import { getIconComponent, isInViewport, setHelpWdigetVisibility } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum'
import ModalAlert from '../../UI/ModalAlert/ModalAlert'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  infoBox:theme.components.infoBox,
  infoHeader:theme.components.infoBoxHeader,
  infoDescription:theme.components.infoBoxDescription,
  focusTxt:theme.components.infoBoxFocusTxt,
  button:theme.components.infoBoxButtonContained,
  modalRoot:{
    padding:24,
    // paddingBottom:8,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    margin:'20vh auto 0',
    maxHeight:'70vh',
    overflow:'auto'
  },
  listItem:{
    '&:hover':{
      background:theme.palette.hovered.main
    },
  },
  title:{
    wordBreak:'break-word',
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:12
  },
  subtitle:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    marginBottom:16
  },
  modalBodyText:{
    fontSize:16,
    color:theme.palette.primaryText.light,
    whiteSpace:'pre-wrap'
  },
  buttons:{
    marginTop:24,
    marginRight:-12,
    marginBottom:-16,
    display:'flex',
    justifyContent:'flex-end'
  },
  bannerText: {
    fontSize: 16,
    flexGrow:1,
    color:theme.palette.primaryText.main
  },
  banner: {
    marginTop:16,
    backgroundColor: theme.palette.chip.main,
    border:`1px solid ${theme.palette.border.main}`,
    minHeight:52,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    padding:'0px 4px'
  },
  columnHeader:{
    color:theme.palette.primaryText.light,
    letterSpacing:2,
    fontSize:12,
    paddingRight:16,
  },
  columnBody:{
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    paddingRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  }
})

const KeyList = props => {

  const {
    classes,
    state,
    dispatch,
  } = props;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [regenerateModalOpen, setRegenerateModalOpen] = useState(false)
  const scrollRef = useRef()
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [deleting, setDeleting] = useState(false)

  const [buttonHovered, setButtonHovered] = useState(false)

  const {
    sendAlert
  } = useAlert({
    id:'access-key-list'
  })

  useEffect(()=>{
    setHelpWdigetVisibility(true)
    return ()=>{
      setHelpWdigetVisibility(false)

    }
  // eslint-disable-next-line
  },[])

  const loadKeys = ({page=1}) => {
    dispatch({
      type:'set_key_list',
      keyListLoading:true,
      keyList:page===1?undefined:state.keyList
    })
    axiosCerebrum
      .get(
        `/api/apikeys`,
        {params:{
          page,
          per_page:10,
          sort:'ALPHABETICAL'
        }}
      )
      .then(response=>{
        dispatch({
          type:'set_key_list',
          keyList:{
            ...response.data,
            items:[
              ...(page===1?[]:state.keyList.items),
              ...response.data.items
            ]
          }
        })
      })
      .catch(error=>{
        console.log(error)
      })
    
  }

  useEffect(()=>{
    if(!state.keyList)loadKeys({})
    // eslint-disable-next-line
  },[])

  const shouldLoadMore = () => {
    return state.keyList && !state.keyListLoading && state.keyList.page<state.keyList.pages && isInViewport(scrollRef)
  }

  window.onscroll= () => {
    if(shouldLoadMore())loadKeys({page:state.keyList.page+1})
  }

  useEffect(()=>{
    if(shouldLoadMore())loadKeys({page:state.keyList.page+1})
  // eslint-disable-next-line
  },[state.keyList,state.keyListLoading])

  const onDeleteKey = key => {
    setDeleting(true)
    axiosCerebrum
      .delete(`/api/apikeys/${key.id}`)
      .then(response=>{
        loadKeys({})
        setDeleteModalOpen(false)
        setDeleting(false)
      })
      .catch(error=>{
        console.log(error);
        setAlertMessage('Error occurred deleting the key, please try again')
        setAlertOpen(true)
        setDeleting(false)
      })
  }

  const onRegenerateKey = key => {
    axiosCerebrum
      .put(`/api/apikeys/${key.id}`)
      .then(response=>{
        setRegenerateModalOpen({key,newKey:{key:response.data.key}})
      })
      .catch(error=>{
        setAlertMessage('Error occurred regenerating the key, please try again')
        setAlertOpen(true)
      })
  }

  const onCopyKey = async key => {
    try{
      if(navigator.clipboard){
        await navigator.clipboard.writeText(key);
      }
    }catch{
      sendAlert({type:'error',message:'Error occurred copying the key'})
      return;
    }
    sendAlert({type:'info',message:'Copied code to clipboard'})
  }

  useEffect(()=>{
    if(state.createdKey){
      setRegenerateModalOpen({key:state.createdKey,newKey:{key:state.createdKey.key}})
      dispatch({type:'set_created_key'})
    }
   // eslint-disable-next-line
  },[state.createdKey])
  
  return (
    <div className={classes.root}>
      <div className={classes.infoBox}>
        <Typography className={classes.infoHeader}>About Access Keys</Typography>
        <div style={{display:'flex',alignItems:'flex-start'}}>
          <Typography className={classes.infoDescription}>
          Use Access Keys to enable 3rd party applications to authenticate with K without a username & password. 
          {'\n\n'}
          Make sure to store you Access Key securely after they are created. Access Keys cannot be viewed after being generated. You can regenerate the Access Key if you lose the Access Key for your application.
          </Typography>
          <Button className={classes.button}  onClick={()=>{dispatch({type:'set_tab_state',tabState:1})}}>
            ADD ACCESS KEY
          </Button>
        </div>
      </div>
      <Typography className={classes.title}>{state.keyList?state.keyList.total+' ':''}ACCESS KEY(S)</Typography>
      <Typography className={classes.subtitle}>Click NEW ACCESS KEY to create a new key that can be used by an application integrated to K. </Typography>
      {
        state.keyList && state.keyList.total>0 && 
        <div>
          <div style={{display:'flex',width:'100%',height:24,alignItems:'center',marginBottom:8}}>
            <div style={{flex:'0 0 24px',marginRight:24,marginLeft:8}}></div>
            <Typography className={classes.columnHeader} style={{flex:'1 0 25%'}}>INTEGRATION</Typography>
            <Typography className={classes.columnHeader} style={{flex:'1 0 25%'}}>GENERATED ON</Typography>
            <Typography className={classes.columnHeader} style={{flex:'0 0 92px'}}>ACTION</Typography>
          </div>
          {
            state.keyList.items.map(el=>(
              <div className={classes.listItem} key={el.name + el.source.name} style={{display:'flex',width:'100%',height:48,alignItems:'center',overflow:'hidden',borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,background:buttonHovered?theme.palette.background.main:undefined}}>
                <div style={{flex:'0 0 24px',marginLeft:8,marginRight:24}}>
                  {getIconComponent({label:'access_key',size:24,colour:theme.palette.primary.main})}
                </div>
                <Typography className={classes.columnBody} style={{flex:'1 0 25%'}}>{`${el.name} (${el.source.name})`}</Typography>
                <Typography className={classes.columnBody} style={{flex:'1 0 25%'}}>{moment(el.updated_at).format('ll')}</Typography>
                <div  className={classes.columnBody} style={{flex:'0 0 92px',display:'flex',alignItems:'center'}}>
                  <IconButton
                     onClick={()=>setRegenerateModalOpen({key:el})} 
                     style={{marginRight:8,padding:6}}
                     onMouseEnter={()=>setButtonHovered(true)}
                     onMouseLeave={()=>setButtonHovered(false)}
                    >
                      {getIconComponent({label:'reset',size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  <IconButton 
                    onClick={()=>setDeleteModalOpen({key:el})} 
                    style={{padding:6,marginRight:8}}
                    onMouseEnter={()=>setButtonHovered(true)}
                    onMouseLeave={()=>setButtonHovered(false)}
                  >
                    {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
                  </IconButton>
                </div>
              </div>  
            ))
          }
        </div>
      }
      {
        state.keyList && state.keyList.total===0 &&
        <Typography style={{marginTop:16,color:theme.palette.primaryText.main}}>No key found</Typography>
      }
      <div style={{marginBottom:12,marginTop:8,textAlign:'center'}} ref={scrollRef}>
        {state.keyListLoading && <CircularProgress color='secondary'/>}
      </div>
      {state.keyListError && <Typography style={{marginTop:16,color:theme.palette.primaryText.main}}>Error occurred loading keys</Typography>}

      <Modal open={deleteModalOpen} disableBackdropClick>
        <div style={{width:'max-content',margin:'auto',outline:'none'}}>
          {
            deleteModalOpen && 
            <Paper className={classes.modalRoot} style={{width:350}}>
              <Typography className={classes.title}>Delete {deleteModalOpen.key.name} key</Typography>
              {
                deleting?
                <div style={{textAlign:'center'}}>
                  <LinearProgress color='secondary' style={{height:6,width:'80%',margin:'auto',marginTop:32}}/>
                  <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Deletion in progress</Typography>
                </div>
                :
                <Typography className={classes.modalBodyText}>
                  {`Are you sure?\n\nThis will permanently remove the access key`}
                </Typography>
              }
              <div className={classes.buttons}>
                <Button color='primary' disabled={deleting} onClick={()=>onDeleteKey(deleteModalOpen.key)} style={{marginRight:16}}>DELETE</Button>
                <Button color='secondary' onClick={()=>setDeleteModalOpen(false)}>CANCEL</Button>
              </div>
            </Paper>
          }
          <div style={{margin:'0 auto',width:398}}>
            <ModalAlert
              open={alertOpen}
              setOpen={setAlertOpen}
              message={alertMessage}
              type='error'
            />
          </div>
        </div>
      </Modal>

      <Modal open={regenerateModalOpen} disableBackdropClick>
        <div style={{width:'max-content',margin:'auto',outline:'none'}}>
          {
            regenerateModalOpen && !regenerateModalOpen.newKey && 
            <Paper className={classes.modalRoot} style={{width:350}}>
              <Typography className={classes.title}>Regenerate {regenerateModalOpen.key.name} key</Typography>
              <Typography className={classes.modalBodyText}>
                {`Are you sure?\n\nThis will permanently update the access key`}
              </Typography>
              <div className={classes.buttons}>
                <Button color='primary' onClick={()=>onRegenerateKey(regenerateModalOpen.key)} style={{marginRight:16}}>REGENERATE</Button>
                <Button color='secondary' onClick={()=>setRegenerateModalOpen(false)}>CANCEL</Button>
              </div>
            </Paper>
          }
          {
            regenerateModalOpen && regenerateModalOpen.newKey && 
            <Paper className={classes.modalRoot} style={{width:730}}>
              <div style={{display:'flex',alignItems:'flex-start'}}>
                <Typography className={classes.title} style={{flexGrow:1}}>{regenerateModalOpen.key.name} key</Typography>
                <Button color='primary' style={{marginLeft:8}} variant="outlined" onClick={()=>setRegenerateModalOpen(false)}>CLOSE</Button>
              </div>
              
              <div className={classes.banner} >
                <ErrorOutlineIcon style={{marginRight: 24,marginLeft:16,color: '#FC642D',fontSize: 32}}/>
                <Typography className={classes.bannerText}>Keep this key in a secure location. It cannot be viewed again without being regenerated.</Typography>
              </div>
              <Typography style={{marginTop:24,fontSize:16,color:theme.palette.primary.main}}>Access Key</Typography>
              <Typography style={{fontSize:12,color:theme.palette.primaryText.light,}}>Click copy to save the key to your clipboard</Typography>
              <div className={classes.banner} style={{padding:16,minHeight:24}}>
                <Typography className={classes.bannerText} style={{wordBreak:'break-all',justifyContent:'space-between'}}>{regenerateModalOpen.newKey.key}</Typography>
                <Button color='primary' onClick={()=>onCopyKey(regenerateModalOpen.newKey.key)} style={{marginLeft:40,flexShrink:0}}>COPY</Button>
              </div>
            </Paper>
          }
          <div style={{margin:'0 auto',width:regenerateModalOpen && regenerateModalOpen.newKey?778:398}}>
            <ModalAlert
              open={alertOpen}
              setOpen={setAlertOpen}
              message={alertMessage}
              type='error'
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}


export default withStyles(styles)(KeyList);