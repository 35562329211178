import React, {  useEffect, useReducer, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { Button, Typography, Modal, Paper, Checkbox, ListItem, Divider } from '@material-ui/core';
import Body from '../../components/BlackList/BlackList'
import useGetCerebrum from '../../hooks/useGetCerebrum';
import axiosCerebrum from '../../axios-cerebrum';
import {getIconComponent, toTitleCase} from '../../utilities'
import SearchSelector from '../../components/UI/SearchSelector/SearchSelector';
import useAlert from '../../hooks/useAlert';

const styles = theme => ({
  button: {
    margin: '8px 0px 8px 16px',
    height: '3rem'
  },
  queryModal: {
    width: 400,
    maxHeight: '60vh',
    margin: '-100px auto 0',
    padding: '20px 30px 10px'
  },
  checkbox:{
    padding:0,
  },
  textField:{
    backgroundColor:'#EEE',
    width:'100%',
    '& input':{
      height:35,
      paddingLeft:8
    }
  }
});

const initialState = {
  modalOpen:false,
  userData:[],
  userError:null,
  userLoading:null,
  dbData:[],
  dbError:null,
  dbLoading:null,
  selectedUser:'',
  modalType:'add', // can be add or update,,
  dbCheckBoxes:[],
}

function reducer(state, action) {
  switch (action.type) {
    case 'set_modal_open':
      return {
        ...state,
        modalOpen:action.modalOpen,
        modalType:action.modalType
      }
    case 'set_user_data':
      return {
        ...state,
        userData:action.userData,
        userError:action.userError,
        userLoading:action.userLoading
      }
    case 'set_db_data':
      return {
        ...state,
        dbData:action.dbData,
        dbError:action.dbError,
        dbLoading:action.dbLoading
      }
    case 'set_selected_user':
      return {
        ...state,
        selectedUser:action.selectedUser
      }
    case 'set_db_check_boxes':
      return {
        ...state,
        dbCheckBoxes:action.dbCheckBoxes
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

const BlackList = props => {

  const {
    history,
    classes,
  } = props;

  const [state, dispatch] = useReducer(
    reducer,initialState
  )

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const {
    data:userData,
    loading:userLoading,
    error:userError,
    fetchList:userFetchlist
  } = useGetCerebrum({
    url:'/api/cerebrum/users',params:{blacklist:true}
  })

  const {
    data: dbData,
    loading: dbLoading,
    error:dbError
  } = useGetCerebrum({
    url:'/api/cerebrum/sources'
  })

  useEffect(()=>{
    dispatch({
      type:'set_user_data',userData:userData||[],userLoading,userError
    })
  },[userData, userLoading, userError])

  useEffect(()=>{
    if(!dbData)return;
    dispatch({
      type:'set_db_data',dbData, dbLoading,dbError
    })
    dispatch({
      type:'set_db_check_boxes',dbCheckBoxes:dbData.map(el=>({obj:el, checked:false}))
    })
  },[dbData,dbLoading,dbError])

  const onCheck = el => {
    dispatch({
      type:'set_db_check_boxes',
      dbCheckBoxes:state.dbCheckBoxes.map(check=>({
        ...check,
        checked:check.obj.id===el.obj.id?!check.checked:check.checked
      }))
    })
  }
  
  const onSelectAll = () => {
    dispatch({
      type:'set_db_check_boxes',
      dbCheckBoxes:state.dbCheckBoxes.map(check=>({
        ...check,
        checked:true
      }))
    })
  }

  const onClearAll = () => {
    dispatch({
      type:'set_db_check_boxes',
      dbCheckBoxes:state.dbCheckBoxes.map(check=>({
        ...check,
        checked:false
      }))
    })
  }

  const onRemove = () => {
    const toBeDeleted = [];
    let appUserName = state.selectedUser;
    let AppNodeKey = '';
    state.dbCheckBoxes.forEach(el=>{
      if(el.checked){
        if(state.userData.some(user=>{
          if(user.applicationUsername===appUserName)AppNodeKey=user.applicationUserNodeKey;
          return user.applicationUsername===appUserName&&user.datasourceId===el.obj.id
        })){
          toBeDeleted.push(el.obj.id);
        }
      }
    })
    const promises = [];
    toBeDeleted.forEach(el=>{
      promises.push(axiosCerebrum.delete(`/api/cerebrum/users/blacklist/${AppNodeKey}/${el}`))
    })
    Promise.all(promises).then(el=>{
      onCancel();
      userFetchlist();
    })
  }

  const onAdd = () => {
    if(state.selectedUser.trim()===''){
      sendAlert({message:"A Used Id must be entered",type:'info'})
      return
    }
    if(state.dbCheckBoxes.every(el=>!el.checked)){
      sendAlert({message:"At least one source needs to be selected",type:'info'})
      return;
    }
    if(state.modalType==='add' && state.userData.some(user=>user.applicationUsername===state.selectedUser)){
      sendAlert({message:"The user already exists in the blacklist",type:'info'})
      return;
    }

    let appUserName = state.selectedUser;
    const toBeAdded = [];
    const toBeDeleted = [];
    let AppNodeKey = '';
    state.dbCheckBoxes.forEach(el=>{
      if(el.checked){
        if(state.userData.every(user=>user.applicationUsername!==appUserName||user.datasourceId!==el.obj.id)){
          toBeAdded.push(el.obj.id);
        }
      }
      else{
        if(state.userData.some(user=>{
          if(user.applicationUsername===appUserName)AppNodeKey=user.applicationUserNodeKey;
          return user.applicationUsername===appUserName&&user.datasourceId===el.obj.id
        })){
          toBeDeleted.push(el.obj.id);
        }
      }
    })
    const promises = [];
    toBeDeleted.forEach(el=>{
      promises.push(axiosCerebrum.delete(`/api/cerebrum/users/blacklist/${AppNodeKey}/${el}`))
    })
    toBeAdded.forEach(el=>{
      promises.push(axiosCerebrum.post(`/api/cerebrum/users/blacklist`,{applicationUsername:appUserName,datasourceId:el}))
    })
    Promise.all(promises).then(el=>{
      onCancel();
      userFetchlist();
    }).catch(error=>{
      sendAlert({message:"Error occurred adding user to blacklist",type:'error'})
    })
  }

  const onCancel = () => {
    dispatch({ type: 'set_modal_open', modalopen: false });
    dispatch({type:'set_selected_user',selectedUser:null});
    dispatch({
      type:'set_db_check_boxes',
      dbCheckBoxes:state.dbCheckBoxes.map(check=>({
        ...check,
        checked:false
      }))
    })
    dispatch({type:'set_selected_user',selectedUser:''})
  } 

  return (
    <div>
      <Modal open={state.modalOpen} disableBackdropClick={true} disableEscapeKeyDown={true} hideBackdrop={false}>
        <div style={{height:'100vh',display:'flex',alignItems:'center',outline:'none'}}>
          <Paper className={classes.queryModal}>
            <Typography style={{fontSize:20,marginBottom:16}}>Add a User ID to the Block List</Typography>
            <Typography style={{fontSize:16, marginBottom:8}} color='primary'>USER ID</Typography>
            <div style={{marginBottom:40}}>
              {/* <TextField
                className={classes.textField}
                disabled={state.modalType==='update'?true:false}
                value={state.selectedUser}
                onChange={event=>dispatch({type:'set_selected_user',selectedUser:event.target.value})}
                placeholder='Enter a User Id'
              /> */}
              <SearchSelector
                searchValue={state.selectedUser}
                setSearchValue={(value)=>dispatch({type:'set_selected_user',selectedUser:value})}
                url={`/api/cerebrum/users`}
                disabled={state.modalType==='update'?true:false}
                params={{appusername:state.selectedUser,blacklist:false}}
                placeholder={'Search for a User ID or enter a User ID'}
                renderResults={(items)=>{
                  let arr = [...new Set(items.map(i=>(i.applicationUsername)))].slice(0,10)
                  return arr.map((applicationUsername,index)=>(
                  <div>
                    <ListItem button onClick={() => {
                      dispatch({type:'set_selected_user',selectedUser:applicationUsername})
                    }}>
                        <div style={{display:'flex',alignItems:'center'}}>
                          {getIconComponent({label:'userCircle',size:20,colour:'#888'})}
                          <Typography style={{marginLeft:12}}>{applicationUsername}</Typography>
                        </div>
                    </ListItem>
                    {index!==arr.length-1 && <Divider style={{marginLeft:16}}/>}
                  </div>
                ))}}
              />
            </div>
            <Typography color='primary' style={{fontSize:16,marginBottom:8}}>SOURCES TO BLOCK</Typography>
            <div style={{display:'flex',marginBottom:8}}>
              <Typography style={{fontSize:12,marginRight:14,cursor:'pointer'}} color='primary' onClick={onSelectAll}>Select All</Typography>
              <Typography style={{fontSize:12,color:'#888',cursor:'pointer'}} onClick={onClearAll}>Clear All</Typography>
            </div>
            {
              state.dbCheckBoxes.map(el=>(
                <div style={{display:'flex',alignItems:'center',marginBottom:4}}>
                  <Typography style={{flex:'0 0 88.75%',color:'#888'}}>{toTitleCase(el.obj.alias)}</Typography>
                  <Checkbox color='primary' checked={el.checked} onClick={()=>onCheck(el)} className={classes.checkbox}></Checkbox>
                </div>
              ))
            }
            <div align='right' style={{ marginTop: '30px' }}>
              {
                state.modalType==='update' &&
                <Button color="primary" className={classes.modalButton} style={{ marginRight: '30px' }} onClick={onRemove}>
                  REMOVE
                </Button>
              }
              <Button color="secondary" className={classes.modalButton} style={{ marginRight: '30px' }} onClick={onAdd}>
                {state.modalType==='add'?'ADD':'SAVE'}
              </Button>
              <Button color="primary" className={classes.modalButton} onClick={() => onCancel()}>
                CANCEL
              </Button>
            </div>
          </Paper>
        </div>
      </Modal>

      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              type='blacklist'
              label='blacklist'
              title='Block List'
              subtitle=' '
              description='Manage users that should not be loaded and discoverable by K'
              buttons={[
                <Button className={classes.button} variant='outlined' onClick={() => dispatch({type:'set_modal_open',modalOpen:true,modalType:'add'})}>
                  {getIconComponent({ label: 'add', size: '1.5rem', colour: '#003C8F' })}
                  <Typography color='primary' style={{ marginLeft: '1rem' }}>ADD USER ID</Typography>
                </Button>
              ]}
            />
          </div>)}
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
          />
        }
      />
    </div>)
}

export default withStyles(styles)(BlackList);
