import React,{ } from 'react';
import { withTheme, withStyles, Typography,Button, Modal, Paper, } from '@material-ui/core';
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
import PropTypes from 'prop-types';

const styles = theme => ({
  modalTitle: {
    fontSize: 20,
    color:theme.palette.header.main,
  },
  modalSubTitle: {
    fontSize:13.75,
    marginBottom: 8,
    whiteSpace:'pre-wrap',
    color:theme.palette.primaryText.main
  },
})

function WarningConfirmModal(props) {

  const {
    classes,
    theme,
    title = 'ARE YOU SURE?',
    description,
    confirmText,
    closeText,
    onConfirm,
    disableConfirmButton,
    modalOpen,
    setModalOpen,
    alertOpen,
    setAlertOpen,
    alertMessage,
  } = props;


  return (
    <Modal 
      open={modalOpen} 
      onClose={()=>setModalOpen(false)}
      disableBackdropClick={true}
    > 
      <div style={{outline:'none',width:'max-content',margin:'25vh auto 0 auto'}}>
        <Paper style={{width:480,boxSizing:'border-box',padding:24,paddingBottom:8, background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
          <div style={{display:'flex',alignItems:'flex-start',justifyContent:"space-between",marginBottom:16}}>
            <Typography className={classes.modalTitle}>{title}</Typography>
            <Button onClick={()=>setModalOpen(false)} color='primary' variant='outlined'>{closeText || 'CLOSE'}</Button>
          </div>
          <Typography className={classes.modalSubTitle}>
            {description}
          </Typography>
          <Button disabled={disableConfirmButton} color="secondary" variant='contained' style={{marginTop:40,marginBottom:16}} onClick={onConfirm} >
            {confirmText}
          </Button>
        </Paper>
        <ModalAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          type='error'
        />
      </div>
    </Modal>
  )
}

WarningConfirmModal.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  confirmText: PropTypes.string,
  closeText: PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  alertOpen: PropTypes.bool,
  setAlertOpen: PropTypes.func,
  alertMessage: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  disableConfirmButton: PropTypes.bool
}

export default withTheme()(withStyles(styles)(WarningConfirmModal));