import './customStyle.css'
import introJs from 'intro.js'
import { getUserRoles } from '../../../utilities'
if(localStorage.hasOwnProperty('dark')){
  require('./darkStyle.css')
}


let introPathNames = []

const getHomeOptions = () => [
  {
    title:"Welcome to K! ",
    intro:"Join us for a quick tutorial of K, your Data Trust Platform.\n\nWe'll show you how to track data usage, scale data knowledge and trust, and use some great tools to help govern data that is important."
  },
  {
    title:`Home page (1 of ${introPathNames.length})`,
    element: document.getElementById('home_feed_container')  || 'none',
    intro: 
      "Your homepage is your personalised view of your data ecosystem. It can help you easily navigate to the key data assets that you and your team use or own." + 
      '\n\nBased on your data usage, follows and team, K provides personalised Twitter like feed updates about your data ecosystem.',
  }
]

const getMyEcosystemOptions = () => [
  {
    title:`Your Data Ecosystem (2 of ${introPathNames.length})`,
    element: document.getElementById('profile-tab-bar-container')  || 'none',
    intro: "Your Ecosystem is a personalised view of your data ecosystem. You can see everything you have used, run, created, own and follow. Use Your Ecosystem to quickly find a piece of code you recently run, or check a table you manage. " + 
            "\n\nYour Ecosystem has 7 key tabs: " + 
            "\n\n• List: Data asset lists that you have created or contributed to." + 
            "\n\n• Contributed: A list of data assets that you have recently contributed to made changes to." + 
            "\n\n• Used: Data and content that you have used recently." + 
            "\n\n• Run: Code, pipelines, macros or procedures you have run recently." + 
            "\n\n• Created: Data, Content, Knowledge and other items you have created." + 
            "\n\n• Assigned: Data, Content and other items you are the owner, steward or otherwise assigned to." + 
            "\n\n• Following: Data items you are following." 
    ,
  },
]

const getBasicSearchOptions = () => [
  {
    title:`Search (3 of ${introPathNames.length})`,
    element: document.getElementById('search_tutorial_section')  || 'none',
    intro: "With so many data assets it can be difficult to find what you are looking for."+
          "\n\nK has a helpful google like search that you can use to find the data you need."+
          "\n\nYou can use filters to narrow your search result."
    ,
  },
]

const getKnowledgeOptions = () => [
  {
    title:`Knowledge Centre (4 of ${introPathNames.length})`,
    intro: 
      "Knowledge Centre is the feedback loop for your data ecosystem. K creates targeted surveys to each user that is best able to answer the questions. Helping build data trust and accelerate data maturity." + 
      '\n\nThere are 3 types of surveys:' + 
      '\n\nAccess: These surveys are sent when K notices that you have not used an item in a while. Let the data team know what you no longer need access to.' + 
      '\n\nFeedback: These are sent to recent users to collect feedback on how useful the item was for their work. Hearing feedback will help them improve the quality of the data assets.' + 
      '\n\nKnowledge: These are sent out to top users to collect opinions about data and content where expertise is needed e.g. Whether PII data was present.' + 
      "\n\nIt also has a fun way of tracking your own personal contribution to the team's collective knowledge."
    ,
    element: document.getElementById("knowledge_tabs_container")  || 'none',
    position:'right',
  }
]

const getInventoryOptions = (currentStep) => [
  {
    title:`Inventory (${currentStep} of ${introPathNames.length})`,
    // element: 'none',
    intro: 
      "The Inventory is a structured way to explore data, content, queries and all other items within the ecosystem."
    ,
  }
]

const getDataAppOptions = (currentStep) => [
  {
    title:`Data Applications (${currentStep} of ${introPathNames.length})`,
    element: document.getElementById('data_app_container') || 'none',
    intro: 
      `K has metadata application(s) that solve specific use cases. This includes: ` + 
      (document.getElementById('data_app_ask_k')?'\n\nAsk K: A self service feature to answer common questions about your data ecosystem.':'') + 
      (document.getElementById('data_app_ia')?'\n\nImpact Assessment: Impact assessment allows you to assess the downstream impact of changes to data or content e.g. report. It’s a great tool to use prior to a change and can help you send personalised notifications to each impacted user.':'') + 
      (document.getElementById('data_app_lineage_explorer')?'\n\nLineage Explorer: Helping you understand where your data item sources data from, where it flows to, how it joins to other data items.':'')
    ,
  },
].filter(el=>el!==undefined)

const getSettingsOptions = (currentStep) => [
  {
    title:`Profile Settings (${currentStep} of ${introPathNames.length})`,
    intro: 
      `After you end this tutorial, make sure you double check the Roles you have been assigned, the Teams you are part of, and your linked Accounts.​​` + 
      `\n\nK uses this information to personalize your experience and tailor the views you see in your Homepage and Data Ecosystem.​​​` + 
      `\n\nHope you’ve found this tutorial helpful. If at any stage you have questions, click on the ? icon at the bottom right hand corner to access the K Knowledge Library.​​​`
    ,
  },
]

const getOptions = (pathname) => {
  switch(pathname){
    case '/home':
      return getHomeOptions()
    case '/my_data':
      return getMyEcosystemOptions()
    case '/basic_search':
      return getBasicSearchOptions()
    case '/add_knowledge':
      return getKnowledgeOptions()
    case '/inventory':
      return getInventoryOptions(introPathNames.length===7?5:4)
    case '/data_solutions':
      return getDataAppOptions(introPathNames.length===7?6:5)
    case '/settings':
      return getSettingsOptions(introPathNames.length===7?7:6)
    default:
      return {}
  }
}

export const IntroManagerMain = (history, sessionData) => {

  const introInstance = introJs();

  let roles = getUserRoles(sessionData.user_role)

  introPathNames = [
    '/home',
    '/my_data',
    '/basic_search',
    ['10','20','40','90'].find(el=>roles.includes(el))?'/add_knowledge':undefined,
    '/inventory',
    '/data_solutions',
    '/settings'
  ].filter(el=>el!==undefined)

  const startIntro = (pathname) => {
    let intervalCount = 0;
    let interval = setInterval(()=>{
      intervalCount += 1;
      if(intervalCount>10)clearInterval(interval);
      let steps = getOptions(pathname);
      if(steps.find(el=>el.element==='none'))return;
      introInstance.setOptions({
        steps,
        exitOnOverlayClick:false,
        scrollToElement:false,
        hidePrev: true,
        showBullets:false,
        disableInteraction:true,
        doneLabel:introPathNames.indexOf(pathname)===introPathNames.length-1?'DONE':'NEXT'
      }).start();
      introInstance.oncomplete(() => {
        let currentIndex = introPathNames.indexOf(pathname)
        if(currentIndex===introPathNames.length-1){
          localStorage.setItem('intro_finished',1)
          return;
        }
        let nextIndex = currentIndex + 1;
        history.push(introPathNames[nextIndex])
        startIntro(introPathNames[nextIndex])
      })
      clearInterval(interval)
      try{
        if(!document.getElementById('introjs_skip_button')){
          let skipButton = document.createElement('a');
          let buttonsContainer = document.getElementsByClassName('introjs-tooltipbuttons')[0];
          skipButton.setAttribute('role','button')
          skipButton.setAttribute('id','introjs_skip_button')
          skipButton.setAttribute('tabindex','0')
          skipButton.setAttribute('class','introjs-button')
          skipButton.innerText =  'SKIP'
          let skipButtonWrapper = document.createElement('div')
          skipButtonWrapper.setAttribute('style','flex-grow:1')
          skipButtonWrapper.appendChild(skipButton)
          skipButton.onclick = () => {
            localStorage.setItem("intro_finished",1)
            introInstance.exit()
          }
          buttonsContainer.insertBefore(skipButtonWrapper,buttonsContainer.firstChild)
        }

        if(document.getElementsByClassName('introjs-prevbutton').length===0 && introPathNames.indexOf(pathname)!==0){
          let backButton = document.createElement('a');
          let buttonsContainer = document.getElementsByClassName('introjs-tooltipbuttons')[0];
          backButton.setAttribute('role','button')
          backButton.setAttribute('tabindex','0')
          backButton.setAttribute('class','introjs-button introjs-prevbutton')
          backButton.innerText =  'BACK'
          backButton.onclick = () => {
            let currentIndex = introPathNames.indexOf(pathname)
            if(currentIndex===0)return;
            let nextIndex = currentIndex - 1;
            history.push(introPathNames[nextIndex])
            startIntro(introPathNames[nextIndex])
            introInstance.exit()
          }
          buttonsContainer.insertBefore(backButton,buttonsContainer.lastChild)
        }
      }catch(error){console.log(error)}
    },300)
  }

  if(window.location.pathname!=='/home')history.push('/home')
  startIntro('/home')
  
}