import React, { useEffect } from 'react';
import { withTheme, withStyles, Typography, Button, Stepper, Step, StepLabel, StepContent} from '@material-ui/core';
import {  toTitleCase} from '../../../utilities';
import SourceSelector from './SourceSelector';
import ObjectTypeSelector from './ObjectTypeSelector';
import ObjectSelector from './ObjectSelector';
import PeriodSelector from './PeriodSelector';
import AdditionalSelector from './AdditionalSelector';
import useAlert from '../../../hooks/useAlert';
import axiosSolr from '../../../axios-solr';

const styles = theme => ({
  root: {
    marginTop:16
  },
  block:{
    maxWidth:640,
    marginLeft:6,
    marginTop:8
  },
  title:{
    fontSize:16,
    color:theme.palette.header.main,
  },
  chip: {
    padding: '0.5rem',
    display: 'inline-block',
    backgroundColor: theme.palette.chip.main,
    border:`1px solid ${theme.palette.border.main}`,
    marginBottom: '1rem',
    borderRadius: 5
  },
  selector: {
    ...theme.components.selector,
    width: 618,
    height:56,
    marginTop: '0.5rem',
  },
  collectionListItem:{
    height:48,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    display:'flex',
    alignItems:'center',
    overflow:'hidden'
  },
  collectionItemText:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    fontSize:16,
    flexGrow:1,
    marginRight:8,
  },
  collectionChildChip:{
    height:24,
    flexGrow:0,
    flexShrink:0,
    borderRadius:12,
    border:`1px solid ${theme.palette.secondary.main}`,
    color:theme.palette.secondary.main,
    display:'flex',
    alignItems:'center',
    padding:'0 8px',
    marginRight:8,
    fontSize:12
  },
  stepper:{
    marginBottom:120,
    backgroundColor:theme.palette.background.main
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
  suggestionChip:{
    ...theme.components.suggestionChip
  }
})

const AssessmentDetails = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    onRun,
  } = props;

  const {
    sendAlert
  } = useAlert({
    id:`ia-details`,
  })

  let steps = [
    'Select a source',
    'Select an object type to filter to',
    'Select an assessment target', 
    'Select a usage period',
    'Add additional assessment criteria (optional)',
    'Assess target'
  ]

  useEffect(()=>{
    dispatch({type:"set_multi_ia_selected_ids",multiIASelectedIds:[]})
    // eslint-disable-next-line
  },[])

  const getStepLabelText = index => {
    switch(index){
      case 0:
        if(state.settingStep!==0 && state.sourceList.length>0 && state.source!=='none'){
          return `Source: ${state.sourceList.find(s=>s.id===state.source).name}`;
        }
        return steps[index];
      case 1:
        if(state.settingStep!==1 && state.objectType!=='none'){
          return `Object type: ${toTitleCase(state.objectType.replace(/_/g,' '))}`
        }
        return steps[index]
      case 2:
        if(state.settingStep!==2 && state.targetObject){
          return `Assessment target: ${toTitleCase(state.targetObject.name_txt)}`
        }
        return steps[index];
      case 3:
        if(state.settingStep!==3 && state.usageWindow!==-1){
          return `Assessment timeframe: Used in last ${state.usageWindow} days`
        }
        return steps[index];
      case 4: 
        if(state.settingStep!==4 && state.additionalCriteria.length>0){
          return `Additional criteria: ${state.additionalCriteria.map(c=>c.name_txt).join(', ')}`
        }
        return steps[index];
      case 5:
        return steps[index];
      default:
        return ''
    }
  }

  const onSelectSource = event => {
    dispatch({type:'set_source',source:event.target.value})
    dispatch({type:'set_object_type',objectType:'none'})
    dispatch({type:'set_target_object'})
    if(event.target.value!=='none'){
      dispatch({type:'set_setting_step',settingStep:1})
    }
  }
  
  const onSelectObjectType = event => {
    dispatch({type:'set_object_type',objectType:event.target.value})
    if(event.target.value!=='none'){
      dispatch({type:'set_setting_step',settingStep:2})
    }
  }

  const onRemoveTarget = () => {
    dispatch({type:'set_target_object'});
    dispatch({type:'set_usage_window',usageWindow:-1})
  }

  const onSelectTarget = object => {
    dispatch({type:'set_target_object',targetObject:object})
    dispatch({type:'set_setting_step',settingStep:3})
  }

  const onSelectUsageWindow = event => {
    dispatch({type:'set_usage_window',usageWindow:event.target.value})
    if(event.target.value===-1)return;
    dispatch({type:'set_setting_step',settingStep:4})
  }

  const onPeriodNextClick = () => {
    dispatch({type:'set_setting_step',settingStep:4})
  }

  const onSelectAdditionalCriteria = object => {
    if(state.additionalCriteria.length>=3){
      sendAlert({type:'info',message:"You can only select max 3 additional criterias"})
      return;
    }
    axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:"*",
          fq:`object_type_srt:COLLECTION_INSTANCE AND parent_id_srt:${object.id}`,
          rows:0,
        }}
      )
      .then(res=>{
        dispatch({type:'set_additional_criteria',additionalCriteria:[...state.additionalCriteria,{...object,child_object_count_srt:res.data.response.numFound}]})
      })
      .catch(err=>{
        console.log(err)
        dispatch({type:'set_additional_criteria',additionalCriteria:[...state.additionalCriteria,object]})
      })
  }
  
  const onRemoveAdditionalCriteria = object => {
    dispatch({type:'set_additional_criteria',additionalCriteria:state.additionalCriteria.filter(c=>c.id!==object.id)})
  }

  const onCriteriaNextClick = () => {
    dispatch({type:'set_setting_step',settingStep:5})
  }

  const labelClickable = index => {
    let clickable = false;
    if(index===0 && !state.forceTargetObject)clickable = true;
    if(index===1 && state.source!=='none')clickable =  true;
    if(index===2 && state.objectType!=='none')clickable = true;
    if(index===3 && state.targetObject)clickable = true;
    if(index===4 && state.usageWindow!==-1)clickable = true;
    if(index===5)clickable = true;
    if(index===0 || (index>2 && state.forceTargetObject))return clickable
    return clickable && labelClickable(index-1)
  }

  const onLabelClick = index => {
    if(!labelClickable(index))return;
    dispatch({type:'set_setting_step',settingStep:index})
  }

  const checkRequired = () => {
    if(!state.source || state.objectType==='none' || !state.targetObject || state.usageWindow===-1)return false;
    return true
  }

  const getStepContent = index => {
    switch(index){
      case 0:
        return <SourceSelector classes={classes} state={state} onSelectSource={onSelectSource}/>
      case 1:
        return <ObjectTypeSelector classes={classes} state={state} onSelectObjectType={onSelectObjectType}/>
      case 2:
        return <ObjectSelector classes={classes} state={state} onRemoveTarget={onRemoveTarget} onSelectTarget={onSelectTarget} selectedObjectType={state.objectType}/>
      case 3:
        return <PeriodSelector classes={classes} state={state} onSelectUsageWindow={onSelectUsageWindow} onPeriodNextClick={onPeriodNextClick}/>
      case 4:
        return <AdditionalSelector classes={classes} state={state} onSelectAdditionalCriteria={onSelectAdditionalCriteria} onRemoveAdditionalCriteria={onRemoveAdditionalCriteria} onCriteriaNextClick={onCriteriaNextClick}/>
      case 5:
        return (
          <div className={classes.block}>
            <Button disabled={!checkRequired()} variant='contained' color='primary' style={{width:96,height:36,marginTop:24}} onClick={()=>onRun()}>
              RUN
            </Button>
          </div>
        )
      default:
        return <div></div>
    }
  }

  useEffect(()=>{
    return ()=>{
      dispatch({type:'reset_setting'})
    }
  // eslint-disable-next-line
  },[])

  return (
    <div className={classes.root}>
      <Typography style={{fontSize:20,marginBottom:12,color:theme.palette.header.main,}}>ASSESSMENT CRITERIA</Typography>
      
      <Stepper activeStep={state.settingStep} orientation="vertical" className={classes.stepper} >
        {steps.map((label, index) => (
          <Step key={index} className={classes.step}>
            <StepLabel style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}}>
              <Typography color='primary' style={{color:state.settingStep<index?theme.palette.primaryText.light:theme.palette.primaryText.main,fontSize:16,marginLeft:6}}>{getStepLabelText(index)}</Typography> 
            </StepLabel>
            <StepContent>
             {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
     
      
    </div>
  )
}

export default withTheme()(withStyles(styles)(AssessmentDetails));