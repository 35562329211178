import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Drawer, IconButton, Button } from '@material-ui/core';
import { getIconComponent, onClickResultItem, } from '../../../../utilities';
import DrawerDragTrigger from '../../../UI/DrawerDragTigger/DrawerDragTrigger';
import MiniProfile from '../../../../containers/MiniProfile/MiniProfile';

const styles = theme => ({
  
  drawerPaper:{
    borderLeft:`1px solid ${theme.palette.listItemDivider.main}`,
    height:'100vh',
    overflow:'hidden',
    paddingRight:24,
    maxWidth:'90vw',
    display:'flex',
    flexDirection:'column',
    background:theme.palette.background.main
  },
  // drawerContent:{
  //   ...theme.components.customScroll,
  //   flexGrow:1,
  //   overflowY:'auto',
  //   overflowX:'hidden',
  //   paddingLeft:24
  // },
  // title:{
  //   fontSize:20,
  //   color:theme.palette.header.main,
  //   overflow:'hidden',
  //   textOverflow:'ellipsis',
  //   whiteSpace:'nowrap',
  //   width:'max-content',
  //   maxWidth:'100%',
  //   "&:hover":{
  //     cursor:'pointer',
  //     textDecoration:'underline'
  //   }
  // },
  // subTitle:{
  //   fontSize:12,
  //   color:theme.palette.primaryText.light,
  //   marginBottom:16
  // }
})


function DetailDrawer(props) {
  const {
    classes,
    theme,
    history,
    drawerOpen,
    setDrawerOpen,
    selectedItem,
  } = props;

  let initialWidth = 600;
  try{
    if(localStorage.getItem('sourceMapDrawerWidth')){
      initialWidth = Number(localStorage.getItem('sourceMapDrawerWidth')).valueOf() || 600;
    }
  }catch{}

  const [paperWidth, setPaperWidth] = useState(initialWidth)

  useEffect(() => {
    const handleEsc = (event) => {
      // check if key is 'ESC`
      if (event.key==='Escape') {
        setDrawerOpen(false)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    // eslint-disable-next-line
  }, [])
  
  return (
    <Drawer key={selectedItem?.id} anchor="right" open={drawerOpen} PaperProps={{style:{width:paperWidth,minWidth:500}}} classes={{paper:classes.drawerPaper}} onClose={()=>setDrawerOpen(false)} variant="persistent">
      { 
        selectedItem && 
        <>
          <DrawerDragTrigger
            onChangeWidthOffset={diff=>{
              setPaperWidth(paperWidth+diff)
              localStorage.setItem('sourceMapDrawerWidth',paperWidth+diff)}}
          />
          <div style={{display:'flex',alignItems:'flex-start',paddingLeft:24,paddingTop:16,paddingBottom:16}}>
            <Button 
              color='primary' 
              variant='contained'
              style={{marginRight:12}}
              onClick={()=>onClickResultItem({newWindow:true,item:selectedItem.data.obj,label:selectedItem.data.obj.type || selectedItem.data.obj.object_type_txt, id:selectedItem.data.obj.id })}
            >
              OPEN
            </Button>
            <div style={{flexGrow:1}}></div>
            <IconButton 
              onClick={()=>setDrawerOpen(false)} 
              style={{width:28,height:28,padding:8}}
            >
              {
                getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})
              }
            </IconButton>
          </div>
          {/* <div className={classes.drawerContent}>
            <Typography className={classes.title} onClick={()=>onClickResultItem({item:selectedItem.data.obj,id:selectedItem.data.obj.id,label:'source',newWindow:true})}>
              {getDispFields(selectedItem.data.obj,'dispTitle') || 'Unknown'} 
              <span style={{position:'relative',marginLeft:4,bottom:-1}}>{getIconComponent({label:'open',size:16,colour:theme.palette.header.main})}</span>
            </Typography>
            <Typography className={classes.subTitle}>
              {getDispFields(selectedItem.data.obj,'dispSubtitle')}
            </Typography>
          </div>   */}
          <MiniProfile
            history={history}
            id={selectedItem.data?.obj?.id || selectedItem.id}
            key={selectedItem.id}
          />
        </>
      }
      
    </Drawer>
  )
}

export default withTheme()(withStyles(styles)(DetailDrawer));
