import React from 'react';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import Linked from '../Linked/Linked';
import Details from '../Details/Details';

const styles = theme => ({
  title: {
    display: 'inline-block',
    // fontSize: '1.1rem',
    position: 'relative',
    top: '0.1rem',
    color: theme.palette.header.main,
    fontWeight: '400',
    fontSize: '1.25rem',
    marginBottom:20
  },
})

function ListBody(props) {

  const {
    history,
    classes,
    state,
    dispatch,
    setModifiedUser,
    linkedFetch,
    fetchList,
    editable
  } = props;

  return (
    <div>
      <Details
        state={state}
        dispatch={dispatch}
        history={history}
        fetchList={fetchList}
        linkedFetch={linkedFetch}
        editable={editable}
        setModifiedUser={setModifiedUser}
        isList={true}
      />
      <Typography className={classes.title}>
        INCLUDED IN LIST
      </Typography>
      <Linked 
        state={state}
        dispatch={dispatch}
        history={history}
        isList={true}
        setModifiedUser={setModifiedUser}
      />
    </div>
  )
}

export default withTheme()(withStyles(styles)(ListBody));