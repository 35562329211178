import theme from "../../../../theme";
import { getCerebrumLabel, nodeWidth, groupWidth, thirdLevelObjects, bodyButtonHeight, nodeOneLineHeight, checkHasLineage } from "./loadUtils";
const horizontalGap = 600;
export const nodeGap = 16

export const assignGroupInitialPos = ({groups, target, direction, currentNodes, forceYPosition}) => {
  if(!groups || groups.length===0)return;
  if(!target){
    groups[0].position = {x:0, y:0, initialY:0}
    return;
  }
  let targetX = target.position.x;
  let targetY = target.position.y;
  let iterateTarget = target;
  // eslint-disable-next-line
  while(iterateTarget.parentNode && currentNodes.find(c=>c.id===iterateTarget.parentNode)){
    // eslint-disable-next-line
    iterateTarget = currentNodes.find(c=>c.id===iterateTarget.parentNode);
    targetY += iterateTarget.position.y;
    targetX = iterateTarget.position.x;
  }

  for(let i=0; i<groups.length; i++){
    let g = groups[i];
    g.position = {}
    if(direction==='upstream')g.position.x = targetX-horizontalGap;
    else{g.position.x = targetX+horizontalGap;}
    if(!isNaN(forceYPosition)){
      g.position.y = forceYPosition
      g.forcePosition = true;
    }else{
      let y;
      if(groups.length===1)y = targetY;
      else{
        y = targetY + (Math.floor(groups.length/2) - i) * 100
      }
      g.position.y = y;
      g.position.initialY = y;
    }
  }
}


export const addNodeToGroup = ({children, currentNodes}) => {
  if(children.length===0)return;
  let gap = nodeGap;
  let secondLevelNodes = children.filter(c=>!thirdLevelObjects.includes(getCerebrumLabel(c.data.obj)))
  secondLevelNodes.forEach(l=>{
    let parent = currentNodes.find(n=>n.id===l.parentNode) 
    if(!parent){
      l.position = {x:0, y:0}
      return;
    }
    l.position = {x:0, y:parent.height}
    parent.height += l.height;
    parent.originalHeight = parent.height;
    parent.position.y -= gap
  })

  let thirdLevelNodes = children.filter(c=>thirdLevelObjects.includes(getCerebrumLabel(c.data.obj)))
  thirdLevelNodes.forEach(l=>{
    let parent = currentNodes.find(n=>n.id===l.parentNode) || children.find(n=>n.id===l.parentNode)
    if(!parent){
      l.position = {x:0, y:0}
      return;
    }
    l.position = {x:0, y:parent.height}
    parent.height += l.height;
    if(parent.parentNode){
      let grandParent = currentNodes.find(n=>n.id===parent.parentNode);
      if(grandParent){
        grandParent.height += l.height;
        grandParent.originalHeight = grandParent.height;
        [...currentNodes,...secondLevelNodes].forEach(n=>{
          if(n.parentNode===grandParent.id && n.id!==parent.id && n.position.y>=parent.position.y){
            n.position.y += l.height
          }
        })
      }
    }
  })
  
}

const checkGroupOverLap = (g1, g2) => {
  let gapOffset = 50;
  return (
    (g1.position.y >= g2.position.y && g1.position.y<=g2.position.y+g2.height+gapOffset) || 
    (g1.position.y + g1.height + gapOffset >= g2.position.y && g1.position.y+g1.height-gapOffset <= g2.position.y + g2.height) ||
    (g1.position.y <= g2.position.y && g1.position.y+g1.height+gapOffset >= g2.position.y) || 
    (g1.position.y >= g2.position.y && g1.position.y+g1.height <= g2.position.y + g2.height + gapOffset )
  )
}

export const setTreeLayoutBasedOnGroup = ({nodes, links, targetX, gap, forceOrderAll, showReference, showReferenceXaxis, isReLayout}) => {
  let groups = nodes.filter(n=>forceOrderAll || (['KGroup','KButtonNode'].includes(n.type) && n.position.x===targetX))
  
  if(!showReference){
    groups = groups.filter(n=>{
      if(n.type==='KButtonNode')return true;
      if(nodes.filter(c=>c.parentNode===n.id).every(c=>c.data.obj?.reference_txt==='YES' && !n.isRoot)){
        if(showReferenceXaxis.includes(n.position.x))return true;
        return false
      }
      return true;
    })
  }

  groups.forEach(g=>{
    if(g.type!=='KButtonNode'){
      g.height = g.originalHeight;
    }
  });

  if(!showReference){
    groups.forEach(g=>{
      if(g.type==='KButtonNode')return;
      if(showReferenceXaxis.includes(g.position.x))return;
      nodes.forEach(n=>{
        if(n.isRoot)return;
        if(n.data.obj?.reference_txt==='YES' && nodes.find(p=>p.id===n.parentNode)?.parentNode===g.id){
          g.height -= n.height
        }
        if(n.data.obj?.reference_txt==='YES' && n.parentNode===g.id){
          g.height -= (n.height)
        }
      })
    })
  }

  //hide dataset when the dataset is currently a group
  // groups.forEach(g=>{
  //   let children = nodes.filter(n=>n.parentNode===g.id);
  //   console.log(children)
  //   children.forEach(c=>{
  //     let dupDatasetTable = nodes.find(n=>n.parentNode && !n.isChildLineage && n.data.obj?.object_type_txt==='DATASET_TABLE' && n.parentNode.split('_')[0]===c.id)
  //     if(
  //       c.data.obj?.object_type_txt==='DATASET' && 
  //       dupDatasetTable
  //     ){
  //       g.height -= (c.height+nodeGap)
  //     }
  //   })
  // })

  isReLayout && groups.forEach(g=>{
    if(g.type==='KButtonNode')return;
    if(!g.position.initialY)return;
    g.position.y = g.position.initialY
  })

  if(groups.length<=1)return;
  if(groups.every(g=>!groups.find(ag=>ag.id!==g.id && checkGroupOverLap(g, ag)))){
    return;
  }
  
  
  let groupsSortByY = groups.sort((a,b)=>{
    return a.position.y-b.position.y
  })

  if(!gap)gap = 66;
  let currentY = groupsSortByY[0].position.y - (groupsSortByY.length-1)*gap;

  const checkIsForcePosition = g => {
    return g.forcePosition
  }

  const findOverlapForcePositionGroup = (currentY, height) => {
    let checkedGroups = groupsSortByY.filter(k=>checkIsForcePosition(k));
    return checkedGroups.find(c=>
      (currentY+height>c.position.y && currentY+height<c.position.y+c.height) || 
      (currentY+height<c.position.y && currentY+height>c.position.y+c.height) || 
      (currentY>c.position.y && currentY<c.position.y+c.height) || 
      (currentY<c.position.y && currentY+height>c.position.y+c.height)
    )
  }

  groupsSortByY.forEach((g,index)=>{
    if(checkIsForcePosition(g)){
      currentY += g.height + gap + 120 ;
      return;
    }
    let overlapGroup = findOverlapForcePositionGroup(currentY, g.height)
    while(overlapGroup){
      currentY = overlapGroup.position.y + overlapGroup.height + gap;
      overlapGroup = findOverlapForcePositionGroup(currentY, g.height)
    }
    g.position.y = currentY;
    currentY += g.height + gap;
  })
}

const getParentX = (node, allNodes) => {
  let parent = node;
  if(!node.parentNode)return node.position.x;
  let parentX = 0;
  while(parent){
    if(parent.position){
      parentX = parent.position.x;
    }
      // eslint-disable-next-line
    if(!parent.parentNode || !allNodes.find(n=>n.id===parent.parentNode))break;
    // }
    // eslint-disable-next-line
    parent = allNodes.find(n=>n.id===parent.parentNode)
  }
  return parentX
}

export const layoutGraph = ({newNodes, currentNodes, currentLinks, showReference, showReferenceXaxis, showFullName ,isReLayout, getDisplayedTags}) => {
  let xAxis = [];
  newNodes.forEach(el=>{
    let x = getParentX(el, [...newNodes, ...currentNodes])
    if(!xAxis.includes(x))xAxis.push(x)
  })
  xAxis.forEach(x=>{
    setTreeLayoutBasedOnGroup({targetX:x, nodes:currentNodes, links: currentLinks, showReference, showReferenceXaxis, isReLayout})
  })
  if(showFullName){
    onToggleShowFullName({nodes:[...currentNodes,...newNodes.filter(nn=>!currentNodes.find(cn=>cn.id===nn.id))], showFullName, showReference, getDisplayedTags})
  }
}

export const setReferencePlaceholderPosition = ({node, currentNodes}) => {
  let maxYGroup;
  if(!currentNodes)return;
  let groupNodes = currentNodes.filter(n=>n.type!=='KLineageNode' && n.position.x===node.position.x);
  groupNodes = groupNodes.filter(n=>{
    if(n.type==='KButtonNode')return true;
    if(currentNodes.filter(c=>c.parentNode===n.id).every(c=>c.data.obj?.reference_txt==='YES')){
      return false
    }
    return true;
  })
  if(!groupNodes || groupNodes.length===0)return;
  groupNodes.forEach(g=>{
    if(!maxYGroup)maxYGroup = g;
    if(maxYGroup.position.y< g.position.y)maxYGroup = g;
  })
  let maxY = maxYGroup.position.y + maxYGroup.height;
  node.position = {x:node.position.x, y:maxY+46}
}

const applyHeightOffsetToParent = (offset, n, nodes) => {
  if(!n.parentNode)return;
  let parent = nodes.find(p=>p.id===n.parentNode);
  if(!parent)return;
  parent.height += offset;
  if(parent.type==='KGroup')parent.originalHeight += offset;
  applyHeightOffsetToParent(offset, parent, nodes)
}

const applyYOffsetToSiblings = (offset, n, nodes) => {
  let siblings;
  if(n.type==='KGroup')siblings = nodes.filter(s=>(s.type==='KGroup' || s.type==='KButtonNode') && s.id!==n.id && s.x===n.x);
  else{ siblings = nodes.filter(s=>s.parentNode===n.parentNode && s.id!==n.id);}

  siblings.forEach(s=>{
    if(s.position.y<n.position.y)return;
    s.position.y += offset;
  })
  if(!n.parentNode)return;
  let parent = nodes.find(p=>p.id===n.parentNode);
  if(!parent)return;
  applyYOffsetToSiblings(offset, parent, nodes)
}

const applyYOffsetToChildren = (offset, n, nonGroupNodes) => {
  nonGroupNodes.forEach(c=>{
    if(c.parentNode!==n.id)return;
    c.position.y += offset;
  })
}

export const onToggleShowFullName = ({nodes, showFullName, showReference, getDisplayedTags}) => {
  
  let nonGroupNodes = nodes.filter(n=>n.type!=='KGroup' && n.type!=='KButtonNode');
  let groupNodes = nodes.filter(n=>n.type==='KGroup');
  // let groupNodes = nodes.filter(n=>n.type==='KGroup' || n.type==='KButtonNode');

  let singleTagTitleWidth = document.getElementsByClassName('single-tag-title')[0]?.clientWidth || nodeWidth-86;
  let noTagTitleWidth = document.getElementsByClassName('no-tag-title')[0]?.clientWidth || nodeWidth-86;
  noTagTitleWidth -= 8;
  let groupTitleWidth = document.getElementsByClassName('group-title')[0]?.clientWidth || groupWidth-66;
  let groupSubtitleWidth = document.getElementsByClassName('group-subtitle')[0]?.clientWidth || groupWidth-66;

  const getHeightOffset = (node, title, subTitle) => {

    let titleOffset = 0;
    let subTitleOffset = 0;

    if(title){
      let el = document.createElement('p');
      el.style.fontSize = '13.75px';
      el.style.wordBreak = 'break-word';
      el.style.whiteSpace = 'normal';
      el.style.lineHeight = 1.5
      el.style.letterSpacing = '0.01071em';
      if(getDisplayedTags(node.data?.tags)?.length===0){
        el.style.width = `${noTagTitleWidth}px`;
      }else{
        if(node.type==='KLineageNode'){
          el.style.width = `${singleTagTitleWidth}px`;
        }else{ 
          el.style.width = `${groupTitleWidth}px`;
        }
      }
      // el.style.visibility = 'hidden';
      el.style.fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif'
      el.innerText = title;
      el.style.position = 'absolute';
      el.style.left = '300px'
      el.style.top = '300px'
      document.body.appendChild(el);
      let height = el.clientHeight;
      el.innerText = 'a';
      let oneLineHeight = el.clientHeight
      el.remove()
      titleOffset = height - oneLineHeight
      if(titleOffset>2)titleOffset-=2
    }

    let el = document.createElement('p');
    el.style.fontSize = '12px';
    el.style.wordBreak = 'break-all';
    el.style.width = `${groupSubtitleWidth}px`;
    el.style.whiteSpace = 'normal';
    el.style.visibility = 'hidden';
    el.style.letterSpacing = '0.01071em'
    el.style.lineHeight = 1.5;
    el.style.fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif'
    el.innerText = subTitle;
    document.body.appendChild(el);
    let height = el.clientHeight;
    el.innerText = 'a';
    let oneLineHeight = el.clientHeight;
    subTitleOffset = height - oneLineHeight - 1;
    if(!subTitle){
      subTitleOffset = 0;
      titleOffset = Math.max(titleOffset,0)
    }
    
    return titleOffset + subTitleOffset;
  }

  nonGroupNodes.forEach(n=>{
    if(showFullName && n.data.wrapTitle)return;
    if(!showFullName && !n.data.wrapTitle)return;
    // if(!showReference && n.data.obj?.reference_txt==='YES')return;
    // let lines = Math.ceil(actualHeight/16);
    let heightOffset = getHeightOffset(n,n.data.label)
    if(!showFullName)heightOffset *= -1;
    n.height += heightOffset;
    n.data.wrapTitle = showFullName?true:false;
    applyHeightOffsetToParent(heightOffset, n, nodes)
    applyYOffsetToSiblings(heightOffset, n, nodes)
  })

  groupNodes.forEach(n=>{
    if(showFullName && n.data.wrapTitle)return;
    if(!showFullName && !n.data.wrapTitle)return;
    // if(!showReference && !nonGroupNodes.some(c=>c.parentNode===n.id && c.data.obj?.reference_txt!=='YES'))return;
    let heightOffset = getHeightOffset(n,n.data.label, n.data.subTitle)
    if(!showFullName)heightOffset *= -1;
    n.height += heightOffset;
    n.originalHeight += heightOffset;
    n.data.wrapTitle = showFullName?true:false;
    applyYOffsetToSiblings(heightOffset, n, nodes)
    applyYOffsetToChildren(heightOffset, n, nonGroupNodes)
  })
}

export const onAppendChildren = ({parent, children, nodes}) => {
  let offset = -bodyButtonHeight;
  children.forEach((e,index)=>{
    if(nodes.find(n=>n.id===e.id))return;
    e.position = {y: nodeOneLineHeight + index * nodeOneLineHeight, x:0}
    e.parentNode = parent.id;
    e.isChildLineage = true
    offset += nodeOneLineHeight;
    e.direction = parent.direction;
    e.data.borderColour = 'transparent';
    e.data.borderTopColour = theme.palette.listItemDivider.main
    if(!checkHasLineage({node:e,direction:e.direction,inactive:e.data.obj?.active_txt==='NO'})){
      offset += bodyButtonHeight;
      e.height = nodeOneLineHeight + bodyButtonHeight;
    }
  })

  applyHeightOffsetToParent(offset, parent, nodes)
  applyYOffsetToSiblings(offset, parent, nodes)
}

// lieange flow: 
// 1. load related nodes
// 2. enrich nodes
// 3. construct groups 
// 4. assign original group positions 
// 5. add nodes to groups (update height etc)
// 6. run layout check to confirm group position 
// 7. attach actions to nodes