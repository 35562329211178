import React, { } from 'react';
import { withTheme, Typography, Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';


const SurveyTemplateSelector = props => {

  const {
    classes,
    theme,
    surveyTemplates = [],
    surveyCategory,
    surveyTemplate,
    onSurveyTemplateChange,
  } = props;

  return (
    <div className={classes.modalBlock}>
      <Typography className={classes.modalBlockTitle}>Survey Template</Typography>
      <div style={{ flexGrow: 1 }}>
        <FormControl style={{ width: '100%' }}>
          <Select
            className={classes.selector}
            disableUnderline
            value={surveyTemplate}
            onChange={event => onSurveyTemplateChange(event.target.value)}
            MenuProps={{
              classes: {
                paper: classes.selectPaper
              }
            }}
            renderValue={el => {
              let msg = el === 'none' ? 'Select a survey template' : surveyTemplates.find(t => t.id === el)?.name;
              return (
                <Typography style={{ fontSize: 16, marginLeft: 18, color: el === 'none' ? theme.palette.primaryText.light : theme.palette.primaryText.main }}>{msg}</Typography>
              )
            }}
          >
            <MenuItem className={classes.menuItem} value={'none'}>
              Select a survey template
            </MenuItem>
            {
              surveyTemplates && surveyTemplates.filter(el => el.category === surveyCategory).map(el => (
                <MenuItem className={classes.menuItem} value={el.id}>
                  {el.name}
                </MenuItem>
              ))
            }
          </Select>
          <FormHelperText className={classes.helperText}>
            {'Required'}
          </FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}

SurveyTemplateSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  surveyTemplates: PropTypes.array.isRequired,
  surveyCategory: PropTypes.string.isRequired,
  surveyTemplate: PropTypes.string.isRequired,
  onSurveyTemplateChange: PropTypes.func.isRequired,
}

export default withTheme()(SurveyTemplateSelector);