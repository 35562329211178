import React, {useEffect, useRef , useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Select , MenuItem } from '@material-ui/core';
import { getDispFields, getIconComponent, isInViewport, mapObjectName, onClickResultItem, toTitleCase } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import { getIconLabel } from '../../UI/SearchResults/utils';
import axiosSolr from '../../../axios-solr';
import KTooltip from '../../UI/KTooltip/KTooltip';
import FollowButton from '../../UI/Buttons/FollowButton';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../../UI/ContextMenu/ContextMenu'
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  customScrollBar:{
    /* width */
    '&::-webkit-scrollbar':{
      width: '0px'
    },
  },
  header:{
    fontSize:20,
    color:theme.palette.primaryText.main,
    marginRight:8
  },
  selector:{
    ...theme.components.titleSelector,
    color:theme.palette.primaryText.main,
    marginRight:8,
    maxWidth:300,
  },
  listItem:{
    height:64,
    overflow:'hidden',
    display:'flex',
    alignItems:'center',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main,
    },
    padding:'0 16px'
  },
  listName:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis',
  },
  listSubtitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis',
  }
})

const Follows = props => {

  const {
    theme,
    classes,
    history,
    state,
    dispatch,
  } = props;


  const loadMoreRef = useRef();

  const [innerHeight, setInnerHeight] = useState(window.innerHeight)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const loadObjects = () => {
    dispatch({type:'set_follows_loading',followsLoading:true})
    axiosCerebrum
      .get(
        `/api/me/follows/object_types`,{
          params:{
            per_page:20,
          }
        }
      )
      .then(response=>{
        let data = {};
        const getOrder = (el) => {
          let order = ['TABLE','COLUMN','REPORT','SHEET','DATASET','DATASET_TABLE','DATASET_FIELD'];
          if(order.includes(el))return order.indexOf(el);
          return order.length+1;
        }
        response.data.items.sort((a,b)=>getOrder(a.name)-getOrder(b.name)).forEach(el=>{
          data[el.name] = {...el}
        })
        dispatch({type:'set_follows_object',followsObject:Object.keys(data)[0] })
        dispatch({type:'set_follows_data',followsData:data})
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_follows_error',followsError:true})
      })
  }

  const loadObjectList = ({objectType = state.followsObject, page = 1}) => {
    dispatch({
      type:'set_follows_data',
      followsData:{
        ...state.followsData,
        [objectType]:{
          ...state.followsData[objectType],
          loading:true,
          error:false
        }
      }
    })
    
    axiosCerebrum
      .get(
        `/api/me/follows`,{
          params:{
            object_type:objectType,
            per_page:10,
            sort:'END_DESC',
            page:page
          }
        }
      )
      .then(response=>{
        if(response.data.items.length===0){
          let data = {...state.followsData};
          data[objectType] = {
            ...data[objectType],
            ...response.data,
            loading:false,
            error:false,
          }
          dispatch({type:'set_follows_data',followsData:data})
          return;
        }

        axiosSolr
          .get(
            `/solr/search/select`,{
              params:{
                q:"*",
                fq:`id:(${response.data.items.map(el=>el.object.id).join(' OR ')})`,
                rows:response.data.items.length,
                start:0
              }
            }
          )
          .then(solrRes=>{
            let items = response.data.items.map(el=>{
              let solrItem = solrRes.data.response.docs.find(solrEl=>solrEl.id===el.object.id)
              return {
                ...el.object,
                object_type_txt:objectType,
                ...(solrItem||{}),
                following:true,
              }
            })
            let data = {...state.followsData};
            data[objectType] = {
              ...data[objectType],
              ...response.data,
              loading:false,
              error:false,
              items:page===1?items:[...data[objectType].items,...items]
            }
            dispatch({type:'set_follows_data',followsData:data})

          })
          .catch(error=>{
            console.log(error)
            let data = {...state.followsData};
            data[objectType] = {
              ...data[objectType],
              loading:false,
              error:true,
            }
            dispatch({type:'set_follows_data',followsData:data})
          })
      })
      .catch(error=>{
        console.log(error)
        let data = {...state.followsData};
        data[objectType] = {
          ...data[objectType],
          loading:false,
          error:true,
        }
        dispatch({type:'set_follows_data',followsData:data})
      })
  }

  useEffect(()=>{
    if(!state.followsData || !state.followsObject)return;
    if(!state.followsData[state.followsObject].items && !state.followsData[state.followsObject].loading && !state.followsData[state.followsObject].error){
      loadObjectList({objectType:state.followsObject})
    }
    // eslint-disable-next-line
  },[state.followsData,state.followsObject])

  useEffect(()=>{
    if(!state.followsData && !state.followsLoading)loadObjects()
    // eslint-disable-next-line
  },[])

  window.onresize = () => {
    setInnerHeight(window.innerHeight)
  }

  window.onscroll = () => {
    setInnerHeight(window.innerHeight + window.scrollY)
  }

  const handleFollowClick = el => {
    if(el.following){
      axiosCerebrum
        .delete(`/api/me/follows/${el.id.toLowerCase()}`)
        .then(()=>{
          dispatch({
            type:'set_follows_data',
            followsData:{
              ...state.followsData,
              [state.followsObject]:{
                ...state.followsData[state.followsObject],
                items:state.followsData[state.followsObject].items.map(i=>{
                  if(i.id!==el.id)return i;
                  return {...i,following:false}
                })
              }
            }
          })
        })
        .catch(error=>{
          console.log(error);
          sendAlert({message:"Error occurred unfollowing this item",type:'error'})
        })
    }else{
      axiosCerebrum
      .put(`/api/me/follows/${el.id.toLowerCase()}?type=OPT_IN`)
      .then(()=>{
        dispatch({
          type:'set_follows_data',
          followsData:{
            ...state.followsData,
            [state.followsObject]:{
              ...state.followsData[state.followsObject],
              items:state.followsData[state.followsObject].items.map(i=>{
                if(i.id!==el.id)return i;
                return {...i,following:true}
              })
            }
          }
        })
      })
      .catch(error=>{
        console.log(error);
        sendAlert({message:"Error occurred following this item",type:'error'})
      })
    }
  }

  const shouldLoadMore = () => {
    if(!state.followsData || !state.followsObject)return false;
    if(Object.keys(state.followsData).length===0)return false;
    let data = state.followsData[state.followsObject];
    return data && data.items && data.page<data.pages && isInViewport(loadMoreRef) && !data.error && !data.loading
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadObjectList({objectType:state.followsObject,page:state.followsData[state.followsObject].page+1})
    }
    // eslint-disable-next-line
  },[state.followsData,state.followsObject])


  const onFollowScroll = event => {
    if(shouldLoadMore()){
      loadObjectList({objectType:state.followsObject,page:state.followsData[state.followsObject].page+1})
    }
  }

  return (
    <div style={{width:'100%'}}>
      {/* <div style={{display:'flex',alignItems:'flex-start'}}> */}
        <div id="home_follows_container" style={{height:innerHeight-128,overflow:'auto'}} className={classes.customScrollBar} onScroll={onFollowScroll}>
          {
            state.followsLoading && 
            <div style={{width:'100%',marginTop:20,marginBottom:30,display:'flex',justifyContent:'center'}}>
              <CircularProgress color='secondary' />
            </div>
          }
          {
            state.followsError && 
            <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading notification </Typography>
          }
          {
            state.followsData && Object.keys(state.followsData).length===0 && 
            <Typography style={{color:theme.palette.primaryText.main}}>No items found</Typography>
          }
          {
            state.followsData && Object.keys(state.followsData).length>0 &&
            <>
              <div style={{display:'flex',alignItems:"center",marginBottom:8}}>
                <Typography className={classes.header}>
                  FOLLOWING {state.followsData[state.followsObject].count}
                </Typography>
                <Select
                  className={classes.selector}
                  value={state.followsObject}
                  disableUnderline
                  onChange={event => {
                    dispatch({type:'set_follows_object',followsObject:event.target.value})
                  }}
                  renderValue={value=>mapObjectName(value,true).replace(/_/g,' ')+' (S)'}
                >
                  {
                    Object.keys(state.followsData).map(el=>(
                      <MenuItem value={el}>
                        {mapObjectName(el,true).replace(/_/g,' ')}
                      </MenuItem>
                    ))
                  }
                </Select>
              </div>
              <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:16}}>
                {toTitleCase(mapObjectName(state.followsObject,true).replace(/_/g,' '))} that you are following. Sorted by most recently updated.
              </Typography>
              {
                state.followsData?.[state.followsObject]?.error && 
                <Typography style={{fontSize:13.75}}>Error occurred loading items </Typography>
              }
              {
                state.followsData?.[state.followsObject]?.items?.length===0 &&
                <Typography style={{fontSize:13.75}}>No items found </Typography>
              }
              {
                state.followsData?.[state.followsObject]?.items?.map(el=>(
                  <>
                    <ContextMenuTrigger id={el.id}>
                      <div 
                        className={classes.listItem}
                        data-test-classname="follows-list-item"
                        onClick={()=>{
                          onClickResultItem({item:el,id:el.id,label:el.object_type_txt,history})
                        }}
                      > 
                        <div style={{flexGrow:0,flexShrink:0,width:24,height:24}}>
                          {getIconComponent({label:getIconLabel({label:el.object_type_txt, item:el}),size:24,colour:theme.palette.primary.main})}
                        </div>
                        <div style={{marginLeft:16,marginRight:16,flexGrow:1,overflow:'hidden'}}>
                          <KTooltip title={getDispFields(el,'dispTitle')}>
                            <Typography className={classes.listName}>{getDispFields(el,'dispTitle')}</Typography>
                          </KTooltip>
                          <KTooltip title={getDispFields(el,'dispSubtitle')}>
                            <Typography className={classes.listSubtitle}>{getDispFields(el,'dispSubtitle')}</Typography>
                          </KTooltip>
                        </div>
                        <FollowButton
                          onClick={(event)=>{event.stopPropagation();handleFollowClick(el)}}
                          following={el.following}
                          object={el}
                          disableCount={true}
                          disableMargin={true}
                          size='small'
                        />
                      </div>
                    </ContextMenuTrigger>

                    <ContextMenu id={el.id}>
                      <CustomMenu
                        item={el}
                        actions={[
                          'open_new_tab'
                        ]}
                      />
                    </ContextMenu>
                  </>
                ))
              }
              <div ref={loadMoreRef} style={{width:'100%',marginTop:20,marginBottom:30,display:'flex',justifyContent:'center'}}>
                {
                  state.followsData?.[state.followsObject]?.loading && 
                  <CircularProgress color='secondary' />
                }
              </div>
            </>
          }
        </div>
      
    </div>
  )
}


export default withTheme()(withStyles(styles)(Follows));