import React from 'react';
import { withStyles } from '@material-ui/core';
import { collectionIds, getLabelPlural } from '../../../utilities';
import ProfileSideBar from '../../UI/ProfileSideBar/ProfileSideBar';
import ChildList from '../ChildList/ChildList';
import PropTypes from 'prop-types';
import RelatedList from '../RelatedList/RelatedList';
import SimplifiedNoteAdder from '../../UI/NoteAdder/SimplifiedNoteAdder';
import LineageList from '../LineageList/LineageList';

const styles = theme => ({
  root: {
    // display:"flex",
    // justifyContent:'space-between',
    // marginTop:24
  },
})

function Body(props) {

  const {
    // classes,
    state,
    dispatch,
    sessionData,
    tabOptions,
    history,
    fetchList,
    childTypeName,
    scrollYOffset
  } = props;

  let sideBarMapping = ['tags','domain','sourceType_no_location','category','description_wide','verified','verifiedNot','classification','trustscore', 'owner', 'stewardedBy'];

  if(state.basicData.object_type_txt==='DATASET_FIELD')sideBarMapping.push('definition')
  if(state.basicData.object_type_txt==='CODE')sideBarMapping.push('code')

  if(state.basicData.object_type_txt==='SOURCE'){
    sideBarMapping = sideBarMapping.filter(el=>el!=='tags')
  }

  if(state.basicData.object_type_txt==='COLLECTION_INSTANCE'){

    sideBarMapping = ['tags','description_wide','lastUpdatedBy','lastUpdated','createdOn'];

    if(state.basicData.collection_type_txt==='GLOSSARY')sideBarMapping.push('alias')
    if(state.basicData.collection_type_txt!=='LIST'){
      sideBarMapping.push('collectionType')
    }
    if(['DATA_MANAGEMENT','DATA_GOVERNANCE','GLOSSARY'].includes(state.basicData.collection_type_txt)){
      sideBarMapping.push('domain')
    }
    if(state.basicData.parent.id===collectionIds.domain || ['DATA_MANAGEMENT','DATA_GOVERNANCE','GLOSSARY'].includes(state.collection_type_txt)){
      sideBarMapping.push('verified','verifiedNot','classification')
    }
    if([collectionIds.domain, collectionIds.classification, collectionIds.dqDimensions, collectionIds.verifiedUseCase, collectionIds.dataRole, collectionIds.channel].includes(state.basicData.parent.id) || ['DATA_MANAGEMENT','DATA_GOVERNANCE','GLOSSARY'].includes(state.basicData.collection_type_txt)){
      sideBarMapping.push('owner','stewardedBy')
    }
  }

  let bodyContent;

  switch(tabOptions[state.tabState].name){
    case 'DETAILS':
      bodyContent = (
        <ProfileSideBar
          tabOptions={[]}
          history={history}
          state={state}
          dispatch={dispatch}
          mapping={sideBarMapping}
          fetchList={fetchList}
          sessionData={sessionData}
          cerebrumLabel={getLabelPlural(state.basicData.object.name, true)}
          data={state.basicData}
          alwaysOpenNewTab={true}
        />            
      )
      break;
    case 'KNOWLEDGE':
      bodyContent = (
        <SimplifiedNoteAdder
          state={state}
          dispatch={dispatch}
          history={history}
          sessionData={sessionData}
          object={state.basicData}
        />
      )
      break;
    case 'UPSTREAM':
    case 'DOWNSTREAM':
      bodyContent = (
        <LineageList
          state={state}
          dispatch={dispatch}
          direction={tabOptions[state.tabState].name.toLowerCase()}
          sessionData={sessionData}
          history={history}
          key={tabOptions[state.tabState].name}
        />
      )
      break;
    default:
      bodyContent = (
        state.basicData.object_type_txt==='COLLECTION_INSTANCE'?
        <RelatedList
          state={state}
          dispatch={dispatch}
          history={history}
          sessionData={sessionData}
        />
        :
        <ChildList
          state={state}
          dispatch={dispatch}
          sessionData={sessionData}
          history={history}
          childTypeName={childTypeName}
          scrollYOffset={scrollYOffset}
        />
      )

  }

  return (
    <div>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fetchList: PropTypes.func.isRequired,
  childTypeName: PropTypes.string.isRequired,
}


export default withStyles(styles)(Body);