import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles, CircularProgress, Select, MenuItem} from '@material-ui/core';
import axiosSolr from '../../../axios-solr';
import { formatBusinessnName, formatNumber, getIconComponent, getTestScoreColor, getUserRoles, onClickResultItem, toTitleCase } from '../../../utilities';
import theme from '../../../theme';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import PageController from '../../BasicSearch/MainSearch/PageController/PageController';
import { getNameWidth } from '../../BasicSearch/MainSearch/Utils/Utils';
import { useStore } from 'react-redux';
import KTooltip from '../../UI/KTooltip/KTooltip';
import { getDimensionIcon } from '../Components/utils';
// import BasicSearch from '../../../containers/BasicSearch/BasicSearch';

const styles = theme => ({
  root: {

  },
  titleSelector:{
    ...theme.components.titleSelector,
    marginRight:8,
    maxWidth:'80vw',
  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  caption:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:24,
    whiteSpace:'pre-wrap'
  },
  dimensionFilter:{
    ...theme.components.titleSelector,
    maxWidth:200,
    height:18,
    fontSize:12,
    '& div':{
      fontSize:`12px !important`
    }
  },
  tile:{
    width:220,
    height:100,
    flexShrink:0,
    borderRadius:5,
    padding:'0 12px 0 24px',
    boxSizing:'border-box',
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    alignItems:'center',
    cursor:'pointer',
    display:'flex',
    background:localStorage.hasOwnProperty('dark')?darken(theme.palette.primary.dark,0.7):lighten(theme.palette.primary.dark,0.9),
    '&:hover':{
      background:localStorage.hasOwnProperty('dark')?darken(theme.palette.primary.dark,0.55):lighten(theme.palette.primary.dark,0.75),
    }
  },
  selectedTile:{
    background:theme.palette.primary.dark,
    '&:hover':{
      background:theme.palette.primary.dark,
    }
  },
  columnHeader:{
    color:theme.palette.primary.main,
    letterSpacing:2,
    fontSize:12,
    marginRight:16,
  },
  itemText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    marginRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  listItem:{
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    height:47,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    '&:hover':{
      background:theme.palette.hovered.main,
      cursor:'pointer'
    }
  }
})

const ByTool = props => {

  const {
    classes,
    state,
    dispatch,
    // history
  } = props;

  const store = useStore();
  const sessionData = store.getState().auth.session_user;
  const roles = getUserRoles(sessionData.user_role)


  const getScoreByTool = () => {
    dispatch({type:'set_dq_by_tool',dqByToolLoading:true})

    let scoreBandFacet = {
      '100':{
        type:'query',
        q:'last_data_quality_score_srt:[100 TO 100]',
      },
      '76-99':{
        type:'query',
        q:'last_data_quality_score_srt:[76 TO 99]',
      },
      '51-75':{
        type:'query',
        q:'last_data_quality_score_srt:[51 TO 75]',
      },
      '26-50':{
        type:'query',
        q:'last_data_quality_score_srt:[26 TO 50]',
      },
      '1-25':{
        type:'query',
        q:'last_data_quality_score_srt:[1 TO 25]',
      },
      '0':{
        type:'query',
        q:'last_data_quality_score_srt:[0 TO 0]',
      },
    }

    axiosSolr
      .get(
        `/solr/data_quality_test_result/select`,{params:{
          q:"*",
          fq:`*`,
          rows:0,
          'json.facet':{
            'tools':{
              type:'terms',
              sort:'index',
              field:`data_quality_test_source_srt`,
              limit:200,
              'facet':{
                'dimensions':{
                  type:'terms',
                  field:'data_quality_dimension_srt',
                  limit:100,
                  "facet":scoreBandFacet
                },
                'no_dimension':{
                  type:'query',
                  q:'-data_quality_dimension_srt:*',
                  "facet":scoreBandFacet
                },
                ...scoreBandFacet
              }
            }
          }
        }}
      )
      .then(async response=>{
        let tools = []
        let uniqueDimensionCount;
        await axiosSolr
          .get(
            `/solr/search/select`,{params:{
              q:"*",
              fq:`object_type_srt:DATA_QUALITY_TEST`,
              rows:0,
              'json.facet':{
                'source':{
                  type:'terms',
                  field:`source_srt`,
                  limit:200,
                  'facet':{
                    'dimensions':{
                      type:'terms',
                      field:'data_quality_dimension_kc_msrt',
                      limit:100,
                      minCount:0,
                    },
                    'no_dimension':{
                      type:'query',
                      q:'-data_quality_dimension_kc_msrt:*',
                    }
                  }
                }
              }
            }}
          )
          .then(uniqueRes=>{
            uniqueDimensionCount = {};
            uniqueRes.data.facets?.source?.buckets?.forEach(el=>{
              uniqueDimensionCount[el.val] = {}
              el.dimensions?.buckets?.forEach(d=>{
                uniqueDimensionCount[el.val][d.val] = d.count
              })
              if(el.no_dimension?.count){
                uniqueDimensionCount[el.val]['UNCATEGORISED'] = el.no_dimension.count
              }
              uniqueDimensionCount[el.val]['ALL'] = el.count
            })
          })
          .catch(error=>{
            console.log(error)
          })

        response.data.facets?.tools?.buckets?.forEach(el=>{
          let data = {
            tool:el.val.toUpperCase(),
            dimensions:[],
          };
          data.dimensions.push({
            ...el,
            val:'All',
            uniqueCount:uniqueDimensionCount[el.val.toUpperCase()]?.['ALL']
          })
          el.dimensions?.buckets?.sort((a,b)=>a.val<b.val?-1:1).forEach(d=>{
            data.dimensions.push(uniqueDimensionCount?{...d, uniqueCount: uniqueDimensionCount[el.val.toUpperCase()][d.val.toUpperCase()]}:d)
          })
          if(el.no_dimension?.count && uniqueDimensionCount[el.val.toUpperCase()]['UNCATEGORISED']){
            data.dimensions.push(uniqueDimensionCount?{...el.no_dimension, val:'UNCATEGORISED', uniqueCount: uniqueDimensionCount[el.val.toUpperCase()]['UNCATEGORISED']}:el.no_dimension)
          }
          tools.push(data)
        })
        dispatch({type:'set_dq_by_tool',dqByTool:tools})
        dispatch({type:'set_selected_tool_dimension',selectedToolDimension:tools[0].dimensions[0].val})
        dispatch({type:'set_selected_tool',selectedTool:tools[0].tool})
        if(tools.length===0)return;
        loadTestList({page:1,range:'100',tool:tools[0].tool,dimension:tools[0].dimensions[0].val})
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_dq_by_tool',dqByToolError:true})
      })
  }
  
  const loadTestList = ({page=1, range=state.selectedScoreBand, tool=state.selectedTool, dimension=state.selectedToolDimension, sort=state.byToolSort}) => {
    dispatch({
      type:'set_dq_by_tool_test_list',
      dqByToolTestListLoading:true,
    })
    dispatch({
      type:'set_dq_by_tool_test_page',
      dqByToolTestPage:page,
    })
    let min = range.split('-')[0];
    let max = range.split('-')[1] || min;
    axiosSolr
      .get(
        `/solr/data_quality_test_result/select`,{params:{
          q:"*",
          fq:`data_quality_test_source_srt:"${tool}"` +
             (dimension!=='All'? 
              dimension==='UNCATEGORISED'?
              ` AND -data_quality_dimension_srt:*`:
              `AND data_quality_dimension_srt:"${dimension}"`:''
            ) 
              + 
             ` AND last_data_quality_score_srt:[${min} TO ${max}]`,
          rows:10,
          start:(page-1)*10,
          sort
        }}
      )
      .then(response=>{
        dispatch({
          type:'set_dq_by_tool_test_list',
          dqByToolTestList:response.data.response,
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_dq_by_tool_test_list',
          dqByToolTestListError:true,
        })
      })
  }
  
  useEffect(()=>{
    if(!state.dqByTool && !state.dqByToolLoading){
      getScoreByTool();
    }
  // eslint-disable-next-line
  },[])

  const getSortIcon = (field) => {
    if(!state.byToolSort)return<></>
    if(state.byToolSort.includes(field))return <span style={{position:'relative',bottom:-2}}>{getIconComponent({label:state.byToolSort===`${field} asc`?'up':'down',size:13.75,colour:theme.palette.primary.main})}</span>
    return <></>
  }

  const onClickSort = (field) => {
    let newValue;
    if(state.byToolSort===`${field} asc`){
      newValue = `${field} desc`
    }else if(state.byToolSort===`${field} desc`){
      newValue = ''
    }else{
      newValue = `${field} asc`
    }
    dispatch({type:'set_by_tool_sort',byToolSort:newValue})
    loadTestList({sort:newValue})
  }

  return (
    <div className={classes.root}>
      {
        state.dqByToolLoading && 
        <CircularProgress color='secondary'/>
      }
      {
        state.dqByToolError && 
        <Typography>Error occurred loading summary</Typography>
      }
      {
        state.dqByTool?.length===0 && 
        <Typography>No data quality scores available</Typography>
      }
      {
        state.dqByTool?.length>0 && state.selectedTool && 
        <>
          <div style={{display:'flex',alignItems:'center',marginBottom:4}}>
            <Select
              className={classes.titleSelector}
              value={state.selectedTool}
              disableUnderline
              onChange={event=>{
                dispatch({type:'set_selected_tool',selectedTool:event.target.value})
                let tool = state.dqByTool.find(el=>el.tool===event.target.value)
                dispatch({type:'set_selected_tool_dimension',selectedToolDimension:tool.dimensions[0].val})
                loadTestList({page:1,range:state.selectedScoreBand,tool:event.target.value,dimension:tool.dimensions[0].val})
              }}
            >
              {
                state.dqByTool?.map((el,i)=>{
                  return (
                    <MenuItem value={el.tool}>
                      {el.tool}
                    </MenuItem>
                  )
                })
              }
            </Select>
            <Typography className={classes.header}>
              DATA QUALITY DASHBOARD
            </Typography>
          </div> 
          <Typography className={classes.caption}>
            Data quality summary for {state.selectedTool}
          </Typography>

          <Typography className={classes.header} >
            TEST FUNCTIONS BY DQ DIMENSION
          </Typography>
          <Typography className={classes.caption}>
            Count of unique test functions by DQ Dimension
          </Typography>
          <div style={{display:'flex',alignItems:'center',flexWrap:'wrap',marginBottom:40}}>
            {
              state.dqByTool.find(el=>el.tool===state.selectedTool).dimensions.map((el,i)=>{
                let selected = state.selectedToolDimension===el.val;
                let text = `${toTitleCase(el.val)}`
                if(el.val==='All')text = 'Overall'
                return (
                  <div 
                    className={classes.tile + (selected?' '+classes.selectedTile:'')}
                    onClick={()=>{
                      dispatch({type:'set_selected_tool_dimension',selectedToolDimension:el.val})
                      loadTestList({page:1,range:state.selectedScoreBand,tool:state.selectedTool,dimension:el.val})
                    }}
                  >
                    <div style={{width:32,height:32,marginRight:16}}>
                      {getDimensionIcon({dimension:el.val.toUpperCase(),size:32,colour:selected?theme.palette.background.main:theme.palette.primary.main})}
                    </div>
                    <div>
                      <Typography style={{fontSize:20,color:selected?theme.palette.background.main:theme.palette.primaryText.main}}>
                        {formatNumber(el.uniqueCount)}
                      </Typography>
                      <Typography style={{fontSize:12,color:selected?theme.palette.background.main:theme.palette.primaryText.light}}>
                        {text}
                      </Typography>
                    </div>
                  </div>
                )
              })
            }
          </div>

          <Typography className={classes.header} >
            {state.selectedToolDimension.toUpperCase()} TEST RUNS BY LATEST SCORES
          </Typography>
          <Typography className={classes.caption}>
            Latest test scores for {state.selectedToolDimension==='All'?'':'all '}{state.selectedToolDimension==='All'?'all':toTitleCase(state.selectedToolDimension)} test runs in the last 90 days
          </Typography>
          <div style={{display:'flex',marginBottom:40}}>
            {
              ['100','76-99','51-75','26-50','1-25','0'].map((el,i)=>{
                let count = state.dqByTool.find(el=>el.tool===state.selectedTool).dimensions.find(d=>d.val===state.selectedToolDimension)[el].count
                let selected = state.selectedScoreBand===el;
                return(
                  <div 
                    className={classes.tile + (selected?' '+classes.selectedTile:'')}
                    onClick={()=>{
                      dispatch({type:'set_selected_score_band',selectedScoreBand:el})
                      loadTestList({page:1,range:el,tool:state.selectedTool,dimension:state.selectedToolDimension})
                    }}
                  >
                    <div style={{width:32,height:32,marginRight:16}}>
                      {getIconComponent({label:'data_quality_test',size:32,colour:getTestScoreColor(el.split('-')[0],true)})}
                    </div>
                    <div>
                      <Typography style={{fontSize:20,color:selected?theme.palette.background.main:theme.palette.primaryText.main}}>
                        {formatNumber(count)}
                      </Typography>
                      <Typography style={{fontSize:12,color:selected?theme.palette.background.main:theme.palette.primaryText.light}}>
                        Test runs scored {el}%
                      </Typography>
                    </div>
                  </div>
                )
              })
            }
          </div>
          
          {
            state.selectedScoreBand && state.selectedToolDimension && 
            <div style={{minHeight:700}}>
              <Typography className={classes.header} style={{marginRight:10}}>
                BREAKDOWN TEST RUNS BY SCORE
              </Typography>

              {
                state.dqByToolTestListLoading &&
                <div style={{textAlign:'center'}}>
                  <CircularProgress color='secondary'/>
                </div>
              }
              {
                state.dqByToolTestListError &&
                <Typography>Error occurred loading tests</Typography>
              }
              {
                state.dqByToolTestList?.numFound===0 &&
                <Typography>No tests available</Typography>
              }
              {
                state.dqByToolTestList?.numFound>0 &&
                <>
                  <div style={{display:'flex',alignItems:'center',overflow:'hidden',marginBottom:8,marginTop:24}}>
                    <div style={{flexGrow:0,flexShrink:0,width:24,marginLeft:16,marginRight:16}}> </div>
                    <Typography className={classes.columnHeader} style={{flex:'1 1 200px',cursor:'pointer',maxWidth:getNameWidth([1,2,3,4])}} onClick={()=>onClickSort('data_quality_test_name_srt')}>
                      NAME {getSortIcon('data_quality_test_name_srt')}
                    </Typography>
                    <Typography className={classes.columnHeader} style={{flex:'0 0 200px',cursor:'pointer'}}  onClick={()=>onClickSort('last_data_quality_score_srt')}>
                      LAST DQ SCORE {getSortIcon('last_data_quality_score_srt')}
                    </Typography>
                    <Typography className={classes.columnHeader} style={{flex:'0 0 150px',cursor:'pointer'}} onClick={()=>onClickSort('data_quality_dimension_srt')}>
                      DIMENSION {getSortIcon('data_quality_dimension_srt')}
                    </Typography>
                    <Typography className={classes.columnHeader} style={{flex:'0 0 150px',cursor:'pointer'}} onClick={()=>onClickSort('object_type_srt')}>
                      TARGET TYPE {getSortIcon('object_type_srt')}
                    </Typography>
                    <Typography className={classes.columnHeader} style={{flex:'1 1 150px',cursor:'pointer'}} onClick={()=>onClickSort('object_name_srt')}>
                      TARGET {getSortIcon('object_name_srt')}
                    </Typography>
                  </div>
                  {
                    state.dqByToolTestList?.docs?.map((el,i)=>{
                      let {primaryName, secondaryName} = formatBusinessnName({roles, dispTitle:el.data_quality_test_name_srt,businessName:el.data_quality_alternate_name_srt,item:el, returnCustomPayload:true})
                      return (
                        <div className={classes.listItem} onClick={()=>onClickResultItem({item:el,id:el.data_quality_test_id_srt,label:'data_quality_test',newWindow:true})}>
                          <div style={{flexGrow:0,flexShrink:0,width:24,height:24,marginLeft:16,marginRight:16}}>
                            {getIconComponent({label:'data_quality_test',size:24,colour:theme.palette.primaryText.light})}
                          </div>
                          <div style={{flex:'1 1 200px',paddingRight:16,maxWidth:getNameWidth([1,2,3,4]),overflow:"hidden"}}>
                            <KTooltip title={primaryName+(secondaryName?` (${secondaryName})`:'')}>
                              <Typography className={classes.itemText} >
                                {primaryName} {secondaryName?`(${secondaryName})`:''}
                              </Typography>
                            </KTooltip>
                            {
                              el.data_quality_test_location_srt &&
                              <KTooltip title={el.data_quality_test_location_srt}>
                                <Typography className={classes.itemText} style={{fontSize:12,color:theme.palette.primaryText.light}}>
                                  {el.data_quality_test_location_srt}
                                </Typography>
                              </KTooltip>
                            }
                          </div>
                         
                          <Typography className={classes.itemText} style={{flex:'0 0 200px',color:getTestScoreColor(el.last_data_quality_score_srt,true)}}>
                            {Math.round(el.last_data_quality_score_srt)}
                          </Typography>
                          <Typography className={classes.itemText} style={{flex:'0 0 150px'}}>
                            {el.data_quality_dimension_srt}
                          </Typography>
                          <Typography className={classes.itemText} style={{flex:'0 0 150px'}}>
                            {el.object_type_srt}
                          </Typography>
                          <div style={{flex:'1 1 150px',paddingRight:16,overflow:'hidden'}}>
                            <KTooltip title={el.object_name_srt}>
                              <Typography className={classes.itemText} style={{}}>
                                {el.object_name_srt}
                              </Typography>
                            </KTooltip>
                            {
                              el.object_location_srt && 
                              <KTooltip title={el.object_location_srt }>
                                <Typography className={classes.itemText} style={{fontSize:12,color:theme.palette.primaryText.light}}>
                                  {el.object_location_srt }
                                </Typography>
                              </KTooltip>
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                  <PageController
                    state={{
                      pageNum:state.dqByToolTestPage,
                      resultsTotal:state.dqByToolTestList?.numFound,
                    }}
                    onChangePage={page=>{
                      loadTestList({page})
                      // window.scrollTo({top:0,behavior:'smooth'})
                    }}
                  />
                </>
              }
            </div>
          }
        </>
      }
    </div>
  )
}

ByTool.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sessionData: PropTypes.object
}

export default withStyles(styles)(ByTool);