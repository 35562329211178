import React, { useEffect, useRef } from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ProfileButton from './ProfileButton';
import axiosCerebrum from '../../../axios-cerebrum';
import { sendMessage } from '../../../utilities';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
})

function AddToCartButton(props) {

  const {
    // classes,
    theme,
    object,
  } = props;

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const onClick = () => {
    axiosCerebrum
      .post(
        `/api/me/cart`,{
          object_ids:[object.id]
        }
      )
      .then(response=>{
        sendAlert({message:'Successfully added item to Cart',type:'info'})
        sendMessage({reloadCart:true})
      })
      .catch(error=>{
        console.log(error)
        sendAlert({message:'Error occurred adding item to Cart',type:'error'})
      })
  }

  return (
    <ProfileButton
      id={"add-to-cart-button"}
      onClick={onClick}
      iconLabel='add_cart'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Add to Cart'}
    />
  )
}

AddToCartButton.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(AddToCartButton));