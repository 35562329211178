import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, CircularProgress } from '@material-ui/core';
import { Typography, Modal, Paper, Button, IconButton } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import { collectionIds, toTitleCase, getIconComponent, formatTimeToUtc } from '../../../utilities'
import { checkFilled, EditFields } from '../../UI/CollectionInstanceEditFields/CollectionInstanceEditFields'
import SearchSelector from '../../UI/SearchSelector/SearchSelector';
import { getIconLabel } from '../../UI/SearchResults/utils';
import useAlert from '../../../hooks/useAlert';
import { onUpdateCategory } from '../../../CategoryManager';
import moment from 'moment';

const styles = theme => ({

  modal: {
    width: 752,
    overflowY: 'auto',
    marginTop: '-10vh',
    display: 'flex',
    flexDirection: 'column',
    height: '70vh',
    paddingBottom: 8,
    border: `1px solid ${theme.palette.border.main}`,
    background: theme.palette.background.main
  },
  buttons: {
    paddingTop: 12,
    paddingRight: 24,
    flexGrow: 0,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  modalBody: {
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.main
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primaryText.light,
      borderRadius: 4,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {

    },
    '&::-webkit-scrollbar': {
      width: 5
    },
  },
  propertyContainer: {
    // display:'flex',
    // height:60,
    // marginBottom:32,
    // alignItems:'flex-start',
    overflow: 'visible',
    paddingTop: 20,
    paddingBottom: 20,
    borderBottom: `1px solid ${theme.palette.listItemDivider.main}`
  },
  fieldName: {
    flexShrink: 0,
    marginBottom: 8,
    fontSize: 12,
    letterSpacing: 1,
    textTransform: 'uppercase',
    wordBreak: 'break-word',
    color: theme.palette.primaryText.main
  },
  helperText: {
    color: theme.palette.primaryText.light,
    fontSize: 12,
    marginLeft: 16
  },
  ellipsisText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  subSectionHeader: {
    fontSize: 12,
    color: theme.palette.primaryText.main,
    letterSpacing: 2,
    marginTop: 12,
    marginBottom: 8
  },
})

function AddInstanceModal(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    resetInstancePropertyValue,
    instanceName,
    allowTreeList
  } = props;

  const [userMap, setUserMap] = useState({})
  const [collectionMap, setCollectionMap] = useState({})
  const [creating, setCreating] = useState(false);
  const [parentSearchValue, setParentSearchValue] = useState('')
  const [parent, setParent] = useState()
  const [expiry, setExpiry] = useState(moment().add(1, 'hour'))

  const {
    sendAlert
  } = useAlert({
    id: `collection-profile-add-instance-modal`,
  })

  const insertNewInstance = (data) => {
    let obj = {
      ...data,
      has_children: false,
      upperLevelID: data.hierarchy_parent_id || data.parent_id,
    }

    window.postMessage({ add_instance: { instance: JSON.stringify(obj) } }, document.location.protocol + "//" + document.location.hostname + ':' + document.location.port)

  }

  useEffect(() => {
    if (state.addInstanceModalOpen && state.addInstanceModalOpen.presetData && state.addInstanceModalOpen.presetData.parent) {
      setParent(state.addInstanceModalOpen.presetData.parent)
    } else {
      setParent()
    }
  }, [state.addInstanceModalOpen])

  const onAddInstance = () => {
    setCreating(true)
    let payload = {}
    payload.collection_id = state.basicData.id;
    payload.description = (state.instanceProperties['description'] || '').replace(/^(<br>|\s)*/, '').replace(/(<br>|\s)*$/, '');
    payload.name = state.instanceProperties['name'];
    payload.properties = {};
    payload.source_id = 1000;
    if (state.basicData.id === collectionIds.warningNotice) {
      payload.expiry = formatTimeToUtc(expiry)
    }
    if (parent) {
      payload.hierarchy_parent_id = parent.id
    }
    for (let i = 0; i < state.basicData.properties.length; i++) {
      let propertyId = state.basicData.properties[i].id;
      let required = state.basicData.properties[i].required;
      if (!required && (state.instanceProperties[propertyId] === '' || state.instanceProperties[propertyId] === 'placeholder')) continue;
      payload.properties[propertyId] = state.instanceProperties[propertyId];
    }
    axiosCerebrum
      .post(
        '/api/collectioninstances',
        payload
      )
      .then(response => {
        if (state.basicData.id === collectionIds.category) {
          onUpdateCategory(response.data)
        }

        if (state.instanceData) {
          insertNewInstance(response.data)
        }
        dispatch({ type: 'set_instance_list_data' })
        resetInstancePropertyValue();
        // if(parent){
        //   saveParent(response.data)
        // }
        if (state.basicData.id === collectionIds.list) {
          axiosCerebrum
            .put(`/api/me/follows/${response.data.id}?type=OPT_IN`)
            .catch(error => {
              console.log(error)
            })
        }
        dispatch({ type: 'set_add_instance_modal_open', addInstanceModalOpen: false });
        setCreating(false)
        sendAlert({ type: 'info', message: `${state.basicData.category === 'GLOSSARY' ? 'Term' : 'Instance'} successfully created` })
      })
      .catch(error => {
        console.log(error);
        setCreating(false)
        let msg = "Error occurred creating instance, please try again"
        if (error.response && error.response.status && error.response.status === 409) {
          msg = 'Error occurred. Duplicate instance name. Please review and enter a different name'
        }
        sendAlert({ type: 'error', message: msg })
      })
  }

  return (
    <Modal
      disableEscapeKeyDown={false}
      onClose={() => {
        dispatch({
          type: 'set_add_instance_modal_open',
          addInstanceModalOpen: false
        })
      }}
      open={state.addInstanceModalOpen}
    >
      <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', outline: 'none' }}>
        <Paper className={classes.modal}>
          <div className={classes.modalBody} style={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden', padding: 24 }}>
            <Typography style={{ color: theme.palette.header.main, fontSize: 20, marginBottom: 25 }}>Add a new {toTitleCase(instanceName)}</Typography>
            <EditFields
              values={state.instanceProperties}
              properties={state.basicData.properties}
              setValues={values => dispatch({ type: 'set_instance_properties', instanceProperties: values })}
              userMap={userMap}
              setUserMap={setUserMap}
              collectionMap={collectionMap}
              setCollectionMap={setCollectionMap}
              collection={state.basicData}
              isCreatingNew
              expiry={expiry}
              setExpiry={setExpiry}
            />
            {
              allowTreeList &&
              <div className={classes.propertyContainer}>
                <Typography
                  className={classes.fieldName}
                >
                  Parent
                </Typography>
                <div style={{ flexGrow: 1 }}>
                  <SearchSelector
                    url={`/api/collectioninstances`}
                    params={{ collection_id: state.basicData.id, 'search.name': parentSearchValue, per_page: 10 }}
                    isCerebrum
                    searchValue={parentSearchValue}
                    setSearchValue={setParentSearchValue}
                    placeholder={"Search for a parent"}
                    testID="parent-selector-input"
                    autoSuggestion
                    scrollable
                    popperDisableportal
                    onResultClick={(el) => {
                      setParent(el)
                    }}
                  />
                  <Typography className={classes.helperText}>{'Optional'}</Typography>
                  {
                    parent
                    &&
                    <div>
                      <Typography className={classes.subSectionHeader}>PARENT</Typography>
                      <div style={{ width: 'max-content', maxWidth: 300, overflow: 'hidden', marginRight: 6, marginBottom: 6, padding: '0px 6px', border: `1px solid ${theme.palette.border.main}`, height: 32, display: 'flex', borderRadius: 16, background: theme.palette.chip.main, alignItems: 'center' }}>
                        {
                          getIconComponent({ label: getIconLabel({ label: 'collection_instance', item: parent }), size: 20, colour: theme.palette.primaryText.light })
                        }
                        <Typography className={classes.ellipsisText} style={{ marginLeft: 4, overflow: 'hidden', textOverflow: 'ellipsis', color: theme.palette.primaryText.main }}>
                          {parent.name}
                        </Typography>
                        <IconButton style={{ marginLeft: 20, padding: 3 }} onClick={() => setParent()}>
                          {getIconComponent({ label: 'clear', size: 20, colour: theme.palette.primaryText.light })}
                        </IconButton>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
          </div>
          <div className={classes.buttons}>
            {
              !creating &&
              <Button classes={{ disabled: classes.disabledButton }} color='primary' style={{ width: 96, marginRight: 8 }} disabled={!checkFilled(state.basicData.properties, state.instanceProperties)} onClick={onAddInstance}>ADD</Button>
            }
            {
              creating && <div style={{ width: 96, height: 36, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 8 }}><CircularProgress color='secondary' style={{ width: 24, height: 24 }} /></div>
            }
            <Button color='secondary' style={{ width: 96 }} onClick={() => { dispatch({ type: 'set_add_instance_modal_open', addInstanceModalOpen: false }) }}>CANCEL</Button>
          </div>
        </Paper>
      </div>
    </Modal>
  )
}

export default withTheme()(withStyles(styles)(AddInstanceModal));