import React from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
// import moment from 'moment'

const styles = theme => ({
})

const LineChart = props => {

  const {
    theme,
    id,
    height,
    width,
    series, 
  } = props;


  // const generateDates = () => {
  //   let days = series[0].data.length;
  //   const labels = []
  //   for(let i=days-1; i>=0; i--){
  //     labels.push(moment().add(-i,'day').format('ll'))
  //   }
  //   return labels
  // }

  const options = {
    chart: {
      id:id,
      type: 'line',
      zoom: {enabled: false},
      toolbar:{show:false},
    },
    colors:['#51A5DE','#76DDFB','#47d6b5'],
    legend:{
      position:'top',
      showForSingleSeries:true,
      horizontalAlign:'left',
      height:50,
      fontSize:'12px',
      labels:{
        colors:theme.palette.primaryText.main
      },
      itemMargin:{
        horizontal:8,
      },
      markers:{
        width:10,
        height:10
      }
    },
    stroke: {
      curve: 'smooth',
      width:2
    },
    markers:{
      size:4,
    },
    xaxis:{
      type:'datetime',
      categories:series[0]&&series[0].labels,
      labels:{
        style:{
          colors:theme.palette.primaryText.main
        }
      },
    },
    yaxis:{
      labels: {
        formatter: function(val) {
          if(!val)return val;
          return val.toFixed(0)
        },
        style:{
          colors:theme.palette.primaryText.main
        }
      },
    }
  };

  
  return (
    <ReactApexChart options={options} series={series} height={height} width={width} type="line" />
  )
}

export default withTheme()(withStyles(styles)(LineChart));