import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import InfoBox from '../InfoBox/InfoBox';
import List from '../List/List';


const styles = theme => ({
  
})

function Body(props) {

  const {
    classes,
    state,
    sessionData,
    dispatch,
    history
  } = props;


  return (
    <div className={classes.root}>
      <InfoBox dispatch={dispatch} state={state} history={history} sessionData={sessionData}/>
      <List state={state} dispatch={dispatch} history={history}/>
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default withStyles(styles)(Body);