import React, { useEffect, useRef, useState }  from 'react';
import { withStyles, withTheme, Typography, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import UserChip from '../Chips/UserChip';
import { getIconComponent, getLabelPlural, mapObjectName, onClickResultItem, toTitleCase } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import InteractiveInputBody from '../InteractiveInput/InteractiveInputBody';
import InteractiveViewer from '../InteractiveInput/InteractiveViewer';
import theme from '../../../theme';
import { diffWords} from 'diff'
import sqlFormatter from "sql-formatter";
import Editor from '../Editor/Editor';
import { getIconLabel } from '../SearchResults/utils';
import KTooltip from '../KTooltip/KTooltip';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  cardContainer:{
    padding:'16px 0 16px 0px',
    borderRadius:3,
    border:`1px solid ${theme.palette.listItemDivider.main}`
  },
  chipHeader:{
    fontSize:16,
  },
  sectionHedaer:{
    fontSize:13.75,
    color:theme.palette.header.main,
    marginRight:28
  },
  timeChip:{
    width:'max-content',
    flexShrink:1,
    display:'flex',
    alignItems:'center',
    padding:'1px 8px',
    background:theme.palette.chip.main,
    borderRadius:16,
    border:`1px solid ${theme.palette.border.main}`
  },
  timeChipText:{
    color:theme.palette.primaryText.main,
    fontSize:13.75
  },
  bodyText:{
    fontSize:13.75,
    color:theme.palette.primaryText.light
  },
  inputBase:{
    ...theme.components.inputBase,
    width:'100%',
    padding:16,
    fontSize:13.75,
    boxSizing:'border-box'
  },
  customScroll:{
    ...theme.components.customScroll
  },
  detailItem:{
    height:48,
    boxSizing:'border-box',
    display:'flex',
    padding:'0px 16px',
    alignItems:'center',
    overflow:'hidden',
  },
  expandableChip:{
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  detailText:{
    fontSize:13.75,
    flexGrow:0,
    flexShrink:1,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  capitalisedText:{
    fontWeight:700,
  },
  detailPanel:{
    transition:'all .3s',
    display:'flex',
    overflow:'hidden',
    paddingLeft:16,
  },
  detailSection:{
    width:'50%',
    paddingRight:8,
    boxSizing:'border-box'
  },
  detailSectionTitle:{
    fontSize:12,
    letterSpacing:1.5,
    color:theme.palette.primaryText.main,
    marginBottom:8
  },
  hoverableText:{
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline'
    }
  }
})

const ChangeCard = props => {

  const {
    classes,
    history,
    maxHeight,
    changeItem,
    onUpdateNote, 
    onClose,
    object
  } = props;

  // const [noteEditing, setNoteEditing] = useState(false)
  const [note, setNote] = useState(changeItem.notes)
  const [expanded, setExpanded] = useState(-1)
  const [isShowAllDetail, setIsShowAllDetail] = useState(false)
  const [changeList, setChangeList] = useState([])

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  const onSaveNote = () => {
    axiosCerebrum
      .put(
        `/api/generalisedmetrics/${changeItem.id}`,
        {
          notes:note,
        }
      )
      .then(response=>{
        onUpdateNote(response.data)
        // setNoteEditing(false)
      })
      .catch(error=>{
        console.log(error)
        setNote(changeItem.notes)
        sendAlert({ message: "Error occurred saving note, please try again", type: 'error' })
      })
  }

  const getChangeIcon = (action, type) => {
    let actionIcon;
    switch(action){
      case 'ADDED':
      case 'CREATED':
        actionIcon =  (
          <svg width={12} height={12} viewBox="-6 -6 30 30" version="1.1">
            <title>icon_add</title>
            <g id="icon_add" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <polygon id="Path" fill={theme.palette.primary.main} points="10,0 14,0 14,10 24,10 24,14 14,14 14,24 10,24 10,14 0,14 0,10 10,10"></polygon>
            </g>
          </svg>
        )
        break;
      case 'DELETED':
        actionIcon =  (
          <svg width={12} height={12} viewBox="-6 -6 30 30" style={{transform:'rotate(45deg)'}} version="1.1">
            <title>icon_add</title>
            <g id="icon_add" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <polygon id="Path" fill={theme.palette.error.main} points="10,0 14,0 14,10 24,10 24,14 14,14 14,24 10,24 10,14 0,14 0,10 10,10"></polygon>
            </g>
          </svg>
        )
        break;
      case 'MODIFIED':
        actionIcon = (
          <svg width={12} height={12} viewBox="0 0 24 24" version="1.1" >
            <title>icon_change</title>
            <g id="icon_change" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <polygon id="Path" fill={theme.palette.secondary.main} fill-rule="nonzero" points="12,2 22,20 2,20"></polygon>
            </g>
          </svg>
        )
        break;
      default:
    }
    return (
      <div style={{width:24,height:24,display:'flex',alignItems:'flex-end'}}>
        <div style={{width:24,height:24}}>
          {
            getIconComponent({label:type,size:24,colour:theme.palette.primary.main})
          }
        </div>
        <div style={{position:'relative',left:-12, bottom: -2, width:14,height:14,display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%',background:theme.palette.background.main}}>
          {actionIcon}
        </div>
      </div>
    )
  }

  const generateDiffText = (content, background) => {
    return (
      <span style={{padding:background?'2px 4px':'2px 0px',marginRight:4,background, whiteSpace:'pre-wrap', wordBreak:'break-word', fontSize:12, color:theme.palette.primaryText.light}}>
        {content}
      </span>
    )
  }

  const generateDiffCodeLine = (arr) => {

    const content = [];
    let currentStack = [];
    let currentLine = 1;
    arr.forEach((a,index)=>{
      currentStack.push(generateDiffText(a.value, a.background))
      if(a.value.includes('\n') || index===arr.length-1){
        content.push(
          <div style={{display:'flex',alignItems:'flex-start',marginBottom:2}}>
            <div style={{minWidth:8,marginRight:6, fontSize:12, color:theme.palette.primaryText.main,marginTop:4}}>
              {currentLine}
            </div>
            <div>
              {currentStack}
            </div>
          </div>
        )
        currentLine += 1;
        currentStack = [];
      }
    })

    return content
  }

  const getDiffText = (old, current, variant) => {
    let diffArray;
    diffArray = diffWords(old, current);
    let oldArr = [];
    let currentArr = [];
    diffArray.forEach((a,index)=>{
      if(!a.added && !a.removed){
        let hasEndNewLine = false;
        let allLines = a.value.split('\n').filter(l=>{
          if(l)return true;
          hasEndNewLine = true;
          return false;
        });
        allLines.forEach((l,index)=>{
          let value = l + (index<allLines.length-1 || hasEndNewLine ? '\n':'')
          oldArr.push({value, background:undefined})
          currentArr.push({value, background:undefined})
        })
      }else if(a.added){
        currentArr.push({value:a.value, background:'#00D46A25'})
      }else if(a.removed){
        oldArr.push({value:a.value, background:'#ff385c25'})
      }
    })

    if(variant==='code'){
      oldArr = generateDiffCodeLine(oldArr)
      currentArr = generateDiffCodeLine(currentArr)
    }
    if(variant==='plain'){
      oldArr = oldArr.map((a, index)=>(generateDiffText(a.value, a.background)))
      currentArr = currentArr.map((a, index)=>(generateDiffText(a.value, a.background)))
    }

    return {oldArr, currentArr}
  }

  const generateCIPropertyChangeText = (properties) => {
    let arr = [];
    Object.keys(properties).forEach(k=>{
      arr.push(
        <span style={{color:theme.palette.primaryText.light,fontWeight:700,fontSize:12, whiteSpace:'pre-wrap', wordBreak:'break-word'}}>{k}: </span>
      )
      arr.push(
        <span style={{color:theme.palette.primaryText.light,fontSize:12, whiteSpace:'pre-wrap', wordBreak:'break-word'}}>{properties[k]}{'\n\n'}</span>
      )
    })
    return arr;
  }

  const mapFieldName = (field, shouldFormat) => {
    let name;
    switch(field){
      case 'alternate_name':
        name = 'business name'
        break;
      default:
        name = field
    }
    if(shouldFormat)return toTitleCase(name, false)
    return name;
  }

  const generateChangeDetailItem = async (change, index) => {
    let hasDetail = change.previous || change.current;
    let isExpanded = index===expanded;
    let diffText;


    let isTitleClickable = object.id!==change.object_id;
    let isDetectedChange = changeItem.metric_type.name==='DETECTED_CHANGE';
    let isMetaChange = changeItem.metric_type.name==='K_METADATA_CHANGE';
    let isLinkageChange = changeItem.metric_type.name==='K_LINKAGE_CHANGE';
    
    if(isExpanded){
      if(isMetaChange && change.object_type==='COLLECTION_INSTANCE' && change.modified_field==='properties'){
        diffText = {
          oldArr:generateCIPropertyChangeText(change.previous),
          currentArr:generateCIPropertyChangeText(change.current),
        }
      }else if(isMetaChange){
        change.previous = change.previous===undefined?'':change.previous + ''
        change.current = change.current===undefined?'':change.current + ''
        if(change.modified_field==='description'){
          diffText = {
            oldArr: (
              <InteractiveViewer
                initialValue={change.previous}
                id={'change-detail-prev-'+change.modified_field}
                alwaysShown={true}
                maxHeight={400}
              />
            ),
            currentArr: (
              <InteractiveViewer
                initialValue={change.current}
                id={'change-detail-current-'+change.modified_field}
                alwaysShown={true}
                maxHeight={400}
              />
            )
          }
        }else{
          diffText = {
            oldArr:generateDiffText(change.previous),
            currentArr:generateDiffText(change.current),
          }
        }
      }else if(isDetectedChange){
        change.previous = change.previous===undefined?'':change.previous + ''
        change.current = change.current===undefined?'':change.current + ''
        if(change.modified_field==='code'){
          diffText = getDiffText(sqlFormatter.format(change.previous,{language:'pl/sql'}), sqlFormatter.format(change.current,{language:'pl/sql'}),'code')
        }else{
          diffText = getDiffText(change.previous, change.current, 'plain')
        }
      }
    }

    let linkageComponent;
    if(isLinkageChange){
      linkageComponent = await getLinkageDetailComponent(change)
    }
    
    return (
      <div style={{borderRadius:5,border:isExpanded?`1px solid ${theme.palette.listItemDivider.main}`:undefined}}>
        <div 
          className={classes.detailItem + (hasDetail?' '+classes.expandableChip:'')} 
          data-test-id={`change-detail-${index}`}
          onClick={()=>{
            if(!hasDetail)return;
            if(isExpanded)setExpanded(-1)
            else{setExpanded(index)}
          }}
        >
          {
            isDetectedChange && 
            <>
              <div style={{width:24,height:24,marginRight:16,flexGrow:0,flexShrink:0}}>
                {getChangeIcon(change.action, change.object_type)}
              </div>
              <KTooltip title={change.object_name}>
                <Typography className={classes.detailText + (isTitleClickable?' ' + classes.hoverableText:'')} style={{marginRight:6,fontWeight:700}} onClick={isTitleClickable?()=>onClickResultItem({label:change.object_type,id:change.object_id,history,query:['tabName=CHANGES']}):undefined}>
                  {change.object_name}
                </Typography>
              </KTooltip>
              <Typography  className={classes.detailText} style={{marginRight:6,flexShrink:0}}>
                {mapObjectName(change.object_type).replace(/_/g,' ').toLowerCase()}
              </Typography>
              <Typography className={classes.detailText} style={{flexGrow:1,flexShrink:0 }}>
                {mapFieldName(change.modified_field, !isDetectedChange)} {change.action.toLowerCase()}
              </Typography>
            </>
          }
          {
            isMetaChange && 
            <>
              <Typography className={classes.detailText} style={{flexGrow:1 }}>
                <span className={classes.capitalisedText}>{mapFieldName(change.modified_field, true)}</span> {change.action.toLowerCase()}
              </Typography>
            </>
          }
          {
            isLinkageChange &&
            <>
              <div style={{width:24,height:24,marginRight:16,flexGrow:0,flexShrink:0}}>
                {getIconComponent({label:getIconLabel({label:change.object_type,item:{...change,collection_name:change.parent_object_name}}),size:24,colour:theme.palette.primaryText.light}) }
              </div>
              {
                linkageComponent
              }
            </> 
          }
          {
            hasDetail && 
            <div style={{width:24,height:24,flexGrow:0,flexShrink:0, transition:'all .3s', transform:isExpanded?'rotate(180deg)':undefined}}>
              {
                getIconComponent({label:'triangle_down',size:24,colour:theme.palette.primaryText.main})
              }
            </div>
          }
        </div>
        {
          hasDetail && 
          <div className={classes.detailPanel} style={{maxHeight:isExpanded?undefined:0, paddingTop:isExpanded?12:0, paddingBottom:isExpanded?24:0}}>
            <div className={classes.detailSection}>
              <Typography className={classes.detailSectionTitle}>PREVIOUS</Typography>
              <div data-test-id='prev-value-block'>
                {diffText?.oldArr}
              </div>
            </div>
            <div className={classes.detailSection}>
              <Typography className={classes.detailSectionTitle}>LATEST</Typography>
              <div  data-test-id='latest-value-block'>
                {diffText?.currentArr}
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  const isLinkedBy = change => {
    return !['CLASSIFIED_BY','NOT_VERIFIED_BY','VERIFIED_BY','MEMBER_OF','RELATED','RELATED_BY'].includes(change.relationship)
  }

  const getLinkageDetailComponent = async change => {
    let isObjectDeleted = false;
    await axiosCerebrum
      .get(`/api/${getLabelPlural(change.object_type)}/${change.object_id}`)
      .catch(error=>{
        isObjectDeleted = true;
      })
    if(!isLinkedBy(change)){
      return (
        <>
          <Typography className={classes.detailText + (isObjectDeleted?'':' '+classes.hoverableText)} style={{marginRight:6,fontWeight:700}} onClick={isObjectDeleted?undefined:()=>onClickResultItem({label:change.object_type,id:change.object_id,history})}>
            {change.object_name}
          </Typography>
          <Typography className={classes.detailText}>
            {mapObjectName(change.object_type,true).replace(/_/g,' ').toLowerCase() + (change.action==='DELETED'?' unlinked':' linked')}
          </Typography>
        </>
      )
    }

    if(change.object_type==='COLLECTION_INSTANCE'){

      let changeText = change.action==='DELETED'?' removed from':' added to';
      if(change.relationship==='NOT_VERIFIED_FOR' && change.action==='ADDED')changeText = ' not verified for'
      if(change.relationship==='VERIFIES' && change.action==='ADDED')changeText = ' verified for'
      if(change.relationship==='CLASSIFIES' && change.action==='ADDED')changeText = ' classified as'
      return (
        <KTooltip title={toTitleCase(mapObjectName(object.object.name,true).replace(/_/g,' ').toLowerCase(),false) + (changeText)+' '+change.object_name + ` (${change.parent_object_name} collection)`}>
          <Typography className={classes.detailText} >
            {toTitleCase(mapObjectName(object.object.name,true).replace(/_/g,' ').toLowerCase(),false) + (changeText)} <span className={isObjectDeleted?'':classes.hoverableText} style={{fontWeight:700}} onClick={isObjectDeleted?undefined:()=>onClickResultItem({label:change.object_type,id:change.object_id,history})}>{change.object_name}</span> ({change.parent_object_name} collection)
          </Typography>
        </KTooltip>
      ) 
    }else{
      let fieldName;
      if(change.relationship==='OWNER_OF')fieldName = 'Owner'
      else if(change.relationship==='STEWARD_OF')fieldName =  'Steward'
      else if(change.relationship==='TAG_OF')fieldName =  'Tag'
      return (
        <KTooltip title={change.object_name+' '+(change.action==='DELETED'?'removed from ':'added as ') + fieldName + ' of this ' + mapObjectName(object.object.name,true).replace(/_/g,' ').toLowerCase() }>
          <Typography className={classes.detailText}>
            <span className={isObjectDeleted?'':classes.hoverableText} style={{marginRight:6,fontWeight:700}} onClick={isObjectDeleted?undefined:()=>onClickResultItem({label:change.object_type,id:change.object_id,history})}>{change.object_name}</span> {(change.action==='DELETED'?'removed from ':'added as ') + fieldName + ' of this ' + mapObjectName(object.object.name,true).replace(/_/g,' ').toLowerCase()}
          </Typography>
        </KTooltip>
      ) 
    }
  }


  useEffect(()=>{
    const loadChangeList = async () => {
      let list = [];
      for(let i=0;i<changeItem.change_context.length;i++){
        let item = await generateChangeDetailItem(changeItem.change_context[i],i)
        if(item)list.push(item)
      }
      setChangeList(list)
    }
    loadChangeList()
  // eslint-disable-next-line
  },[changeItem, expanded])

  return (
    <div className={classes.cardContainer}>
      <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:24,paddingRight:24,paddingLeft:24}}>
        <Typography className={classes.chipHeader}>
          {toTitleCase(changeItem.metric_type.name.replace(/_/g,' '))}
        </Typography>
        <Button color='primary' variant='outlined' data-test-id={`change-close-button`} onClick={onClose} style={{height:30}}>CLOSE</Button>
      </div>
      <div style={{maxHeight:maxHeight - 86, overflow:'auto',paddingRight:24,paddingLeft:24}} className={classes.customScroll}>
        {
          changeItem.user && changeItem.metric_type.name!=='DETECTED_CHANGE' &&
          <div style={{display:'flex',alignItems:'center',marginBottom:40}}  data-test-classname="change-by-section">
            <Typography className={classes.sectionHedaer} style={{width:95}}>Change by</Typography>
            <UserChip user={changeItem.user} testID={'change-item-user'} onClick={()=>onClickResultItem({label:'user',id:changeItem.user.id,history,item:changeItem.user})}/>
          </div>
        }
        <div style={{display:'flex',alignItems:'center',marginBottom:40}} data-test-classname="change-on-section">
          <Typography className={classes.sectionHedaer} style={{width:95}}>Detected on</Typography>
          <div className={classes.timeChip}>
            <Typography className={classes.timeChipText}>
              {moment(changeItem.timestamp).format('LL HH:mm:ss')}
            </Typography>
          </div>
        </div>
        {
          changeItem.change_context && changeItem.change_context.length>0 &&
          <div style={{marginBottom:40}} data-test-id="change-detail-section">
            <Typography className={classes.sectionHedaer} style={{marginBottom:8}}>Change Details</Typography>
            {
              changeList.slice(0, isShowAllDetail?changeList.length:5)
            }
            {
              changeItem.change_context.length>5 && 
              <Button color='primary' style={{marginLeft:16,marginTop:4}} onClick={()=>setIsShowAllDetail(!isShowAllDetail)}>{isShowAllDetail?'SEE LESS':`SEE ${changeItem.change_context.length-5} MORE CHANGES`}</Button>
            }
          </div>
        }
        <div style={{marginBottom:12}}>
          <Editor
            title={
              <div style={{display:'flex',alignItems:'center'}}>
                <Typography className={classes.sectionHedaer} style={{width:95}}>
                  {
                    changeItem.notes_last_updated_by_user?
                    'Note added by'
                    :
                    'Note'
                  }
                </Typography>
                {
                  changeItem.notes_last_updated_by_user && 
                  <UserChip user={changeItem.notes_last_updated_by_user} onClick={()=>onClickResultItem({label:'user',id:changeItem.notes_last_updated_by_user.id,history,item:changeItem.notes_last_updated_by_user})}/>
                }
              </div>
            }
            body={
              <InteractiveViewer 
                key={changeItem.id}
                initialValue={note || 'Add details about this change'}
                id={`change-note`}
                // maxHeight={300}
                alwaysShown={true}
              />
            }
            onSave={()=>{
              onSaveNote()
            }}
            onClose={()=>{
              setNote(changeItem.notes)
            }}
            editWindow={
              <InteractiveInputBody
                placeholder="Add a note to this change"
                initialValue={changeItem.notes || ''}
                onChange={(value)=>setNote(value)}
                disableWidget={true}
                height={'160px'}
              />
            }
          />
        </div>      
      </div>

	  </div>
  )
}

ChangeCard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  maxHeight: PropTypes.number.isRequired,
  changeItem:PropTypes.object.isRequired,
  onUpdateNote:PropTypes.func.isRequired,
  onClose:PropTypes.func.isRequired,
}


export default withTheme()(withStyles(styles)(ChangeCard));