import React, {useEffect}  from 'react';
import { withTheme, withStyles, Typography, CircularProgress } from '@material-ui/core';
import VerticalBar from '../Charts/VerticalBar';
import axiosSolr from '../../../../axios-solr';


const styles = theme => ({

})

const UsageChart = props => {

  const {
    classes,
    // theme,
    state,
    data,
    setData,
    variant
  } = props;
  
  const chartData = data.usageData;
  const loading = data.usageLoading;
  const error = data.usageError;
  const setError = () => setData({...data, usageError:true, usageLoading:false})
  const setLoading = () => setData({...data, usageLoading:true, usageError:false})
  const setChartData = d => setData({...data, usageData:d, usageLoading:false, usageError:false})
  
  // let items;
  // if(variant==='data')items = dataItems;
  // if(variant==='content')items = contentItems;

  const loadChartData = ({
    showReference=state.showReference,
    objectType=(variant==='data'?state.resultDataObjectType:state.resultContentObjectType)
  }) => {
    if(!chartData)setLoading()

    axiosSolr
      .get(
        `/solr/impact/select`,
        {params:{
          q:'*:*',
          rows:0,
          fq:`object_type_srt:(${objectType}) AND 
              extract_id:${state.extractData.id} ${showReference?'':'AND -reference_srt:YES'}` + 
              (state.historyObject?` AND starting_object_id:${state.historyObject.id}`:' AND -starting_object_id:*'),
          'json.facet':{
            "usage": {
              "type": "terms",
              "field": "usage_srt",
              "mincount":1,
              "missing":true
            },
          }
        }}
      ).then(response=>{
        let buckets = (response.data.facets && response.data.facets.usage && response.data.facets.usage.buckets) || [];
        let missing = (response.data.facets && response.data.facets.usage && response.data.facets.usage.missing && response.data.facets.usage.missing.count) || 0;
        if(missing!==0){
          buckets.push({val:'unknown', count:missing})
        }
        setChartData(buckets)
      }).catch(error=>{
        setError()
        console.log(error)
      })
  }

  useEffect(()=>{
    if(!chartData && !loading)loadChartData({})
     // eslint-disable-next-line
  },[])

  
  useEffect(()=>{
    if(chartData && variant==='data'){
      loadChartData({showReference:state.showReference})
    }
    // eslint-disable-next-line
  },[state.showReference, state.resultDataObjectType, variant])


  useEffect(()=>{
    if(chartData && variant==='content'){
      loadChartData({showReference:state.showReference})
    }
    // eslint-disable-next-line
  },[state.showReference, state.resultContentObjectType, variant])

  return (
    <div className={classes.root}>
      {loading && <CircularProgress color='secondary'/>}
      {error && <Typography>Error occurred loading chart</Typography>}
      {
        chartData && chartData.length>0 && 
        <VerticalBar
        labels={chartData.map(c=>c.val)}
        data={chartData.map(c=>c.count)}
          id="data-usage-chart"
        />
      }
      {
        chartData && chartData.length===0 && 
        <Typography>No {variant} assets found</Typography>
      }
    </div>  
  )
}

export default withTheme()(withStyles(styles)(UsageChart));