import React from 'react';
import SimpleResultItem from '../UI/SearchResults/SimpleResultItem'
// import ResultItem from '../UI/SearchResults/ResultItem2'
import theme from '../../theme'
import { getDispFields, getIconComponent, getInitials, onClickResultItem } from '../../utilities'
import {palette} from '../../theme'
import { getIconLabel } from '../UI/SearchResults/utils'
 
const getLabel = el => {
  return getIconLabel({label:el.object_type_txt,item:el})
}


export const getObjectCard = (el, history) => {
  let tailObject, bodyObject;
  if(el.collection_txt==='LIST' && el.top_users_txts){
    tailObject = (
      <div style={{display:'flex',alignItems:'center'}}>
        {
          el.top_users_txts.map(u=>(
            <div style={{width:24,height:24,borderRadius:12,fontSize:12,background:palette.avatar.main,color:'#fff',marginLeft:8,display:'flex',alignItems:'center',justifyContent:'center'}}>
              {getInitials(u)}
            </div>
          ))
        }
        
      </div>
    )
  }

  if(el.stats && el.stats.number_of_dq_failed_7d){
    bodyObject = (
      <div style={{background:'#F44137',marginLeft:8,marginRight:24,height:32,display:'flex',alignItems:'center',padding:'0px 12px',borderRadius:16}}>
        <div style={{width:24,height:24,marginRight:6}}>
          {getIconComponent({label:'data_quality_test',size:24,colour:'#fff'})}
        </div>
        <span style={{fontSize:13.75,color:'#fff'}}>Failed DQ Test</span>
      </div>
    )
  }

  let description = el.collection_txt==='LIST'?el.description:getDispFields(el,'dispSubtitle');
  if(!description || description.trim()==='') description = 'No description'
  
  return (
    <SimpleResultItem
      title={getDispFields(el,'dispTitle').length>100?getDispFields(el,'dispTitle').slice(0,97)+'...':getDispFields(el,'dispTitle')}
      titleColour={theme.palette.primaryText.main}
      label={getLabel(el)}
      item={el}
      subTitle={description}
      rightText={el.trust_srt}
      showUnderline={true}
      onClick={() => onClickResultItem({id:el.id,label:el.object_type_txt,item:el,history})}
      hideRight={['COLLECTION_INSTANCE','ISSUE'].includes(el.object_type_txt)}
      tailObject={tailObject}
      bodyObject={bodyObject}
    />
  )
}

export const dedupeList = list => {
  let newList = [];
  list.forEach(l=>{
    if(!newList.find(n=>n.id===l.id))newList.push(l)
  })
  return newList
}

export const assignedObjects = ['ISSUE','TABLE','COLUMN','SCHEMA','CONTENT','DATASET','DATA_PIPELINE','QUERY','CONTENT_CHILD','CONTENT_APP','DATASET_TABLE','DATASET_FIELD','FILE','ML_MODEL'];
export const createdObjects = ['TABLE','CONTENT','DATASET','DATA_PIPELINE','FILE','ML_MODEL']
export const runObjects = ['QUERY','MACRO','PROCEDURE','DATASET','DATA_PIPELINE','ML_MODEL']
export const usedObjects = ['TABLE','CONTENT','DATASET','DATA_PIPELINE','FILE','ML_MODEL']
export const contributedObjects = ['COLLECTION_INSTANCE','ISSUE']