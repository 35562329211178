import React, {useState } from 'react';
import { withTheme, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../axios-cerebrum'
import WarningConfirmModal from '../../UI/ConfirmModals/WarningConfirmModal';

const styles = theme => ({
  boldText:{
    fontWeight:700,
    fontSize:13.75
  }
})

function CancelModal(props) {

  const {
    classes,
    modalOpen,
    setModalOpen,
    loadMoreHistory
  } = props;

  const [loading, setLoading] = useState(false)
  const [alertOpen, setAlertOpen] = useState();
  const [alertMessage, setAlertMessage] = useState('')

  const onKillJob = item => {
    setLoading(true)
    axiosCerebrum
      .delete(`/api/jobexecutions/${item.job_execution?.id||item.id}`)
      .then(response=>{
        loadMoreHistory?.({})
        setModalOpen(false)
        setLoading(false)
      })
      .catch(error=>{
        console.log(error)
        setAlertOpen(true)
        setLoading(false)
        setAlertMessage("Error occurred killing jobs, please try again")
      })
  }

  const onKillAll = () => {
    setLoading(true)
    axiosCerebrum
      .delete(`/api/jobexecutions`)
      .then(response=>{
        loadMoreHistory?.({})
        setModalOpen(false)
        setLoading(false)
      })
      .catch(error=>{
        console.log(error)
        setAlertOpen(true)
        setLoading(false)
        setAlertMessage("Error occurred killing the job, please try again")
      })
  }

  return (
    <WarningConfirmModal
      description={
        <span>You are about to cancel {modalOpen.type==='all'?<span className={classes.boldText}>all</span>:'a'} platform job{modalOpen.type==='all'?'(s)':''}.</span>
      }
      setModalOpen={setModalOpen}
      modalOpen={modalOpen}
      onConfirm={()=>modalOpen.type==='all'?onKillAll():onKillJob(modalOpen.item)}
      alertOpen={alertOpen}
      setAlertOpen={setAlertOpen}
      alertMessage={alertMessage}
      confirmText={modalOpen.type==='all'?'CANCEL ALL JOBS':'CANCEL JOB'}
      disableConfirmButton={loading}
    />
  )
}

CancelModal.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
}

export default withTheme()(withStyles(styles)(CancelModal));