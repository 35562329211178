import React, {useEffect}  from 'react';
import { withTheme, withStyles, Typography, CircularProgress } from '@material-ui/core';
import HorizontalBar from '../Charts/HorizontalBar';
import axiosSolr from '../../../../axios-solr';
import { userItems } from '../../utils';


const styles = theme => ({

})

const TeamChart = props => {

  const {
    classes,
    // theme,
    state,
    data,
    setData,
  } = props;
  
  const chartData = data.teamData;
  const loading = data.teamLoading;
  const error = data.teamError;
  const setError = () => setData({...data, teamError:true, teamLoading:false})
  const setLoading = () => setData({...data, teamLoading:true, teamError:false})
  const setChartData = d => setData({...data, teamData:d, teamLoading:false, teamError:false})

  const loadChartData = ({showReference=state.showReference, type=state.resultUserType}) => {
    if(!chartData)setLoading()
    axiosSolr
      .get(
        `/solr/impact/select`,
        {params:{
          q:'*:*',
          rows:0,
          fq:`object_type_srt:(${userItems.join(' OR ')}) AND user_type_srt:${type} AND extract_id:${state.extractData.id}`+ 
          (state.historyObject?` AND starting_object_id:${state.historyObject.id}`:' AND -starting_object_id:*'),
          'json.facet':{
            "team": {
              "type": "terms",
              "field": "teams_msrt",
              "mincount":1,
              "missing":true
            },
          }
        }}
      ).then(response=>{
        let buckets = (response.data.facets && response.data.facets.team && response.data.facets.team.buckets) || [];
        let missing = (response.data.facets && response.data.facets.team && response.data.facets.team.missing && response.data.facets.team.missing.count) || 0;
        if(missing!==0){
          buckets.push({val:'No Team', count:missing})
        }
        setChartData(buckets)
      }).catch(error=>{
        setError()
        console.log(error)
      })
  }

  useEffect(()=>{
    if(!chartData && !loading)loadChartData({})
     // eslint-disable-next-line
  },[])


  useEffect(()=>{
    if(chartData){
      loadChartData({showReference:state.showReference, type:state.resultUserType})
    }
    // eslint-disable-next-line
  },[state.showReference, state.resultUserType])


  return (
    <div className={classes.root}>
      {loading && <CircularProgress color='secondary'/>}
      {error && <Typography>Error occurred loading chart</Typography>}
      {
        chartData && chartData.length>0 && 
        <HorizontalBar
          labels={chartData.map(c=>c.val)}
          data={chartData.map(c=>c.count)}
          id="user-team-chart"
        />
      }
      {
        chartData && chartData.length===0 && 
        <Typography>No teams found</Typography>
      }
    </div>  
  )
}

export default withTheme()(withStyles(styles)(TeamChart));