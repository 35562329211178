import {
	Button,
	CircularProgress,
	Divider,
	IconButton,
	InputAdornment,
	InputBase,
	ListItem,
	MenuItem,
	Select,
	Typography,
	withStyles,
	withTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import axiosCerebrum from "../../../axios-cerebrum";
import axiosSolr from "../../../axios-solr";
import useAlert from "../../../hooks/useAlert";
import {
	getIconComponent,
	getNameSearchQuery,
} from "../../../utilities";
import UserSuggestionItem from "../../UI/SearchResults/UserSuggestionItem";
import SearchSelector from "../../UI/SearchSelector/SearchSelector";
import TeamSelector from "../../UI/SearchSelector/TeamSelector";
import TabBar from "../../UI/TabBar/TabBar";
import { getPutPayload } from "../../UI/UpdateInput/utils";

const styles = (theme) => ({
	infoBox: theme.components.infoBox,
	infoDescription: theme.components.infoBoxDescription,
	sectionTitle: {
		color: theme.palette.header.main,
		fontSize: 20,
		marginBottom: 5,
	},
	sectionSubTitle: {
		color: theme.palette.primaryText.light,
		fontSize: 12,
		marginBottom: 8,
	},
	inputField: {
		...theme.components.inputBase,
		width: 640,
		padding: "10px",
	},
	nameFilter: {
		...theme.components.inputBase,
		marginRight: 24,
		"& input": {
			paddingTop: 10,
			paddingBottom: 10,
			paddingLeft: 8,
		},
	},
	assigneeChip: {
		width: "max-content",
		minWidth: 240,
		padding: "0.5rem",
		backgroundColor: "#EEE",
		marginBottom: "1rem",
		borderRadius: 5,
		display: "flex",
		alignItems: "center",
	},
	normalText: {
		color: theme.palette.primaryText.main,
	},
	userListItem: {
		height: 48,
		paddingLeft: 16,
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
		borderBottom: `1px solid ${theme.palette.listItemDivider.main}`,
		"&:hover": {
			background: theme.palette.hovered.main,
		},
	},
	selector: {
		...theme.components.selector,
		width: 150,
		"& div div": {
			paddingLeft: 16,
			paddingTop: 10,
			paddingBottom: 10,
			fontSize: 16,
		},
	},
	accountTypeSelector: {
		...theme.components.selector,
		marginRight: 16,
		height: 48,
		width: 200,
	},
	disabledButton: {
		...theme.components.disabledButton,
	},
});

let nameFilterTimeout;

const EditBody = (props) => {
	const { classes, theme, state, dispatch } = props;

	const [description, setDescription] = useState(
		state.selectedTeam.description || "",
	);
	const [parent, setParent] = useState();
	const [originalParent, setOriginalParent] = useState();
	const [parentSearchValue, setParentSearchValue] = useState("");
	const [accountType, setAccountType] = useState("all");
	const [memberSearchValue, setMemberSearchValue] = useState("");
	const [memberSort, setMemberSort] = useState("ALPHABETICAL");
	const [nameFilter, setNameFilter] = useState("");
	const [disableButton, setDisableButton] = useState(false);
	const [loadingMore, setLoadingMore] = useState(false);
	const [memberModified, setMemberModified] = useState(false);
	const listRef = useRef();

	const isCancelledRef = useRef(false);

	const { sendAlert } = useAlert({
		isCancelledRef,
	});

	useEffect(() => {
		return () => {
			isCancelledRef.current = true;
		};
	}, []);

	const sortByItems = [
		{ dispName: "A-Z", value: "ALPHABETICAL" },
		{ dispName: "Z-A", value: "REVERSE_ALPHABETICAL" },
	];

	useEffect(() => {
		if (state.selectedTeam.parent_id) {
			axiosCerebrum
				.get(`/api/groups/${state.selectedTeam.parent_id}`)
				.then((response) => {
					setParent(response.data);
					setOriginalParent(response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		}
		// eslint-disable-next-line
	}, []);

	const loadTeamMember = ({ page, nameFilter, sortBy }) => {
		if (page === 1) {
			dispatch({ type: "set_member_data", memberLoading: true });
		} else {
			setLoadingMore(true);
		}
		axiosCerebrum
			.get(`/api/groups/${state.selectedTeam.id}/related`, {
				params: {
					"search.name":
						!nameFilter || nameFilter.trim() === "" ? undefined : nameFilter,
					relationship: "MEMBERS",
					object_name: "USER",
					page: page,
					per_page: 10,
					sort: sortBy,
				},
			})
			.then((response) => {
				if (response.data.items.length === 0) {
					dispatch({
						type: "set_member_data",
						memberData: { ...response.data },
						memberLoading: false,
					});
					return;
				}

				axiosSolr
					.get(`/solr/search/select`, {
						params: {
							q: "*",
							fq: `id:(${response.data.items.map((el) => el.id).join(" OR ")})`,
							fl: "*",
							rows: response.data.items.length,
						},
					})
					.then((solrResponse) => {
						setLoadingMore(false);
						const newList = response.data.items.map((el) => {
							const solrItem = solrResponse.data.response.docs.find(
								(s) => s.id === el.id,
							);
							return { ...el, ...(solrItem || {}) };
						});
						dispatch({
							type: "set_member_data",
							memberData: {
								...response.data,
								items: [
									...(page === 1 ? [] : state.memberData.items || []),
									...newList,
								],
							},
						});
					})
					.catch((error) => {
						console.log(error);
						dispatch({
							type: "set_member_data",
							memberData: page === 1 ? undefined : state.memberData,
							memberError: true,
						});
					});
				dispatch({
					type: "set_member_data",
					memberData:
						page === 1
							? response.data
							: {
									...response.data,
									items: [...state.memberData.items, ...response.data.items],
								},
				});
			})
			.catch((error) => {
				console.log(error);
				setLoadingMore(false);
				dispatch({
					type: "set_member_data",
					memberData: page === 1 ? undefined : state.memberData,
					memberError: true,
				});
			});
	};

	useEffect(() => {
		if (state.editTabState === 1 && !state.memberData) {
			loadTeamMember({ page: 1, sortBy: memberSort });
		}
		// eslint-disable-next-line
	}, [state.editTabState]);

	const isInViewport = (ref, offset = 0) => {
		if (!ref || !ref.current) return false;
		const bottom = ref.current.getBoundingClientRect().top;
		return bottom + offset >= 0 && bottom + offset <= window.innerHeight;
	};

	useEffect(() => {
		if (
			state.memberData &&
			!loadingMore &&
			isInViewport(listRef) &&
			state.memberData.page < state.memberData.pages
		) {
			setLoadingMore(true);
			loadTeamMember({
				page: state.memberData.page + 1,
				nameFilter,
				sortBy: memberSort,
			});
		}
		// eslint-disable-next-line
	}, [loadingMore, state.memberData]);

	window.onscroll = () => {
		if (
			isInViewport(listRef) &&
			!loadingMore &&
			state.memberData.page < state.memberData.pages
		) {
			setLoadingMore(true);
			loadTeamMember({
				page: state.memberData.page + 1,
				nameFilter,
				sortBy: memberSort,
			});
		}
	};

	const onNameFilterChange = (value) => {
		setNameFilter(value);
		clearTimeout(nameFilterTimeout);
		nameFilterTimeout = setTimeout(() => {
			loadTeamMember({ page: 1, nameFilter: value, sortBy: memberSort });
		}, 300);
	};

	const onSortChange = (value) => {
		setMemberSort(value);
		loadTeamMember({ page: 1, nameFilter, sortBy: value });
	};

	const onCancel = () => {
		dispatch({ type: "set_edit_tab_state", editTabState: 0 });
		dispatch({ type: "set_tab_state", tabState: 0 });
	};

	const updatedParentTeam = async () => {
		if (
			(!originalParent && !parent) ||
			(parent && originalParent && originalParent.id === parent.id)
		)
			return true;
		let isError = false;
		if (originalParent && !parent) {
			await axiosCerebrum
				.put(
					`/api/groups/${state.selectedTeam.id}`,
					getPutPayload({
						property: "parent_id",
						value: null,
						data: state.selectedTeam,
					}),
				)
				.catch((error) => {
					console.log(error);
					isError = true;
				});
			return !isError;
		}
		if (parent) {
			await axiosCerebrum
				.put(
					`/api/groups/${state.selectedTeam.id}`,
					getPutPayload({
						property: "parent_id",
						value: parent.id,
						data: state.selectedTeam,
					}),
				)
				.catch((error) => {
					console.log(error);
					isError = true;
				});
			return !isError;
		}
	};

	const onSave = async () => {
		if (
			description === state.selectedTeam.description &&
			((!originalParent && !parent) ||
				(parent && originalParent && originalParent.id === parent.id))
		) {
			if (memberModified) {
				dispatch({
					type: "set_teams_data",
					teamsData: { ...state.teamsData, page: 0, items: [] },
				});
			}
			dispatch({ type: "set_tab_state", tabState: 0 });
			return;
		}
		const requestBody = {};
		requestBody.description = description;

		const success = await updatedParentTeam();
		if (!success) {
			sendAlert({
				message: "Error occurred updating parent team",
				type: "error",
			});
			setDisableButton(false);
			return;
		}

		requestBody.active_flag = state.selectedTeam.active_flag;
		axiosCerebrum
			.put(`/api/groups/${state.selectedTeam.id}`, requestBody, {
				withCredentials: true,
			})
			.then((response) => {
				dispatch({
					type: "set_teams_data",
					teamsData: { ...state.teamsData, page: 0, items: [] },
				});
				dispatch({ type: "set_tab_state", tabState: 0 });
				setDisableButton(false);
			})
			.catch((error) => {
				sendAlert({ message: "Error occurred updating team", type: "error" });
				setDisableButton(false);
			});
	};

	const onAddUser = (el) => {
		axiosCerebrum
			.put(
				`/api/users/${el.id}/related?object_id=${state.selectedTeam.id}&relationship=MEMBER_OF`,
			)
			.then((response) => {
				setMemberModified(true);
				setMemberSearchValue("");
				dispatch({
					type: "set_member_data",
					memberData: {
						...state.memberData,
						total: state.memberData.total + 1,
						items: [...state.memberData.items, { ...response.data, ...el }],
					},
				});
			})
			.catch((error) => {
				console.log(error);
				sendAlert({ message: "Error occurred adding user", type: "error" });
			});
	};

	const onRemoveUser = (el) => {
		axiosCerebrum
			.delete(
				`/api/users/${el.id}/related?object_id=${state.selectedTeam.id}&relationship=MEMBER_OF`,
			)
			.then((response) => {
				setMemberModified(true);
				dispatch({
					type: "set_member_data",
					memberData: {
						...state.memberData,
						total: state.memberData.total - 1,
						items: state.memberData.items.filter((m) => m.id !== el.id),
					},
				});
			})
			.catch((error) => {
				console.log(error);
				sendAlert({ message: "Error occurred deleting user", type: "error" });
			});
	};

	const renderResults = (data) => {
		if (!data) return [];

		if (data.length === 0) {
			return [
				<div style={{ minWidth: 300 }}>
					<ListItem>
						<div style={{ display: "flex", alignItems: "center" }}>
							<Typography
								style={{
									marginLeft: 12,
									color: theme.palette.primaryText.main,
								}}
							>
								{"No suggestions"}
							</Typography>
						</div>
					</ListItem>
				</div>,
			];
		}

		return data.map((el, index) => (
			<div style={{ minWidth: 300 }}>
				<ListItem
					button
					onClick={() => {
						setParent(el);
						setParentSearchValue("");
					}}
				>
					<div style={{ display: "flex", alignItems: "center" }}>
						<Typography
							style={{ marginLeft: 12, color: theme.palette.primaryText.main }}
						>
							{el.name}
						</Typography>
					</div>
				</ListItem>
				{index !== data.length - 1 && (
					<Divider
						style={{
							marginLeft: 16,
							backgroundColor: theme.palette.listItemDivider.main,
						}}
					/>
				)}
			</div>
		));
	};

	let body;
	switch (state.editTabState) {
		case 0:
			body = (
				<div>
					<Typography className={classes.sectionTitle}>DESCRIPTION</Typography>
					<Typography className={classes.sectionSubTitle}>
						Add a description for this team
					</Typography>
					<InputBase
						value={description}
						onChange={(event) => {
							if (event.target.value.length <= 200)
								setDescription(event.target.value);
						}}
						multiline
						rows={6}
						variant={"filled"}
						placeholder={"Add a description for this team"}
						className={classes.inputField}
					/>
					<Typography
						style={{
							color: theme.palette.primaryText.light,
							fontSize: 12,
							marginLeft: 16,
						}}
					>
						Optional. Max 200 characters
					</Typography>
					<Typography
						className={classes.sectionTitle}
						style={{ marginTop: 24 }}
					>
						PARENT
					</Typography>
					<Typography className={classes.sectionSubTitle}>
						Search for a team to link as the parent of this team
					</Typography>
					<div style={{ width: 640, marginTop: 16 }}>
						<TeamSelector
							renderResults={renderResults}
							url="/solr/search/select"
							params={{
								q: getNameSearchQuery(parentSearchValue),
								fq: `object_type_srt:TEAM AND -id:${state.selectedTeam.id} ${parent ? `AND -id:${parent.id}` : ""}`,
								fl: "*",
								sort: "name_srt asc",
							}}
							searchValue={parentSearchValue}
							setSearchValue={setParentSearchValue}
							selectedGroup={parent}
							setGroup={(el) => setParent(el)}
							clearTeam={(el) => setParent()}
							autoSuggestion={true}
						/>
					</div>
				</div>
			);
			break;
		case 1:
			body = (
				<div>
					<div className={classes.infoBox}>
						<Typography className={classes.sectionTitle}>
							ADD TEAM MEMBER
						</Typography>
						<Typography
							className={classes.infoDescription}
							style={{ marginBottom: 16 }}
						>
							{`Search for users to add to the team.` +
								`\nYou can add User Accounts (users registered in K) and Local Accounts (users found in sources)`}
						</Typography>
						<div style={{ width: 640, display: "flex", alignItems: "center" }}>
							<Select
								value={accountType}
								onChange={(event) => setAccountType(event.target.value)}
								className={classes.accountTypeSelector}
							>
								{[
									{ value: "all", dispValue: "All Types" },
									{ value: "USER_ACCOUNT", dispValue: "User Account" },
									{ value: "LOCAL_ACCOUNT", dispValue: "Local Account" },
								].map((el) => (
									<MenuItem value={el.value}>{el.dispValue}</MenuItem>
								))}
							</Select>
							<SearchSelector
								url="/solr/search/select"
								params={{
									q: `${memberSearchValue}*`,
									fq:
										`object_type_srt:USER ${accountType === "all" ? "" : `AND account_type_srt:"${accountType}"`}` +
										` AND -teams_msrt:"${state.selectedTeam.name}" AND -merge_type_srt:CANDIDATE` +
										(state.memberData?.items.length > 0
											? ` AND -id:(${state.memberData.items.map((el) => el.id).join(" OR ")})`
											: ""),
									fl: "*",
									rows: 10,
								}}
								searchValue={memberSearchValue}
								setSearchValue={setMemberSearchValue}
								placeholder="Search for users to add to this Team"
								onResultClick={onAddUser}
								renderResults={(items) => {
									if (items.length === 0) {
										return [
											<div>
												<ListItem>
													<div
														style={{ display: "flex", alignItems: "center" }}
													>
														<Typography
															style={{
																marginLeft: 12,
																color: theme.palette.primaryText.main,
															}}
														>
															No suggestions found
														</Typography>
													</div>
												</ListItem>
											</div>,
										];
									}
									return items.map((el) => (
										<UserSuggestionItem
											item={el}
											onClick={() => onAddUser(el)}
										/>
									));
								}}
							/>
						</div>
					</div>
					<div
						style={{
							marginTop: 32,
							marginBottom: 16,
							display: "flex",
							alignItems: "flex-start",
						}}
					>
						<Typography
							className={classes.sectionTitle}
							style={{ marginBottom: 0, flexGrow: 1 }}
						>{`${state.memberData ? state.memberData.total : 0} TEAM MEMBER(S)`}</Typography>
						<InputBase
							value={nameFilter}
							onChange={(event) => onNameFilterChange(event.target.value)}
							variant={"filled"}
							placeholder={"Search"}
							className={classes.nameFilter}
							endAdornment={
								<InputAdornment style={{ marginRight: 6 }}>
									{getIconComponent({
										label: "search",
										size: 24,
										colour: "#888",
									})}
								</InputAdornment>
							}
						/>
						<Select
							className={classes.selector}
							value={memberSort}
							onChange={(event) => onSortChange(event.target.value)}
						>
							{sortByItems.map((el) => (
								<MenuItem value={el.value}>
									<span>{el.dispName}</span>
								</MenuItem>
							))}
						</Select>
					</div>
					{state.memberLoading && (
						<CircularProgress color="secondary" style={{ marginLeft: 30 }} />
					)}
					{state.memberError && (
						<Typography className={classes.normalText}>
							Error occurred loading team members
						</Typography>
					)}
					{state.memberData &&
						state.memberData.items.map((el) => (
							<UserSuggestionItem
								item={el}
								tailObject={
									<IconButton onClick={() => onRemoveUser(el)}>
										{getIconComponent({
											label: "clear",
											colour: theme.palette.primaryText.light,
											size: 24,
										})}
									</IconButton>
								}
							/>
						))}
					{state.memberData && state.memberData.total === 0 && (
						<Typography className={classes.normalText}>
							No team members assigned
						</Typography>
					)}
					<div
						style={{
							marginBottom: 10,
							display: "flex",
							justifyContent: "center",
						}}
						ref={listRef}
					>
						{loadingMore && <CircularProgress color="secondary" />}
					</div>
				</div>
			);
			break;
		default:
	}

	return (
		<div style={{ marginBottom: 100 }}>
			<div style={{ marginLeft: -52, marginBottom: 24 }}>
				<TabBar
					tabOptions={["DETAILS", "MEMBER"]}
					tabState={state.editTabState}
					setTabState={(value) =>
						dispatch({ type: "set_edit_tab_state", editTabState: value })
					}
					minWidth={200}
					maxWidth={200}
					disableUnderline={true}
				/>
			</div>
			{body}
			<div
				style={{
					left: 0,
					right: 0,
					height: 80,
					boxShadow: "0px -1px 5px 0px #DDD",
					backgroundColor: theme.palette.background.main,
					borderTop: `1px solid ${theme.palette.border.main}`,
					position: "fixed",
					bottom: 0,
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
				}}
			>
				<Button
					classes={{ disabled: classes.disabledButton }}
					disabled={disableButton}
					style={{
						marginLeft: "60%",
						width: 120,
						height: 48,
						color: theme.palette.primaryText.light,
					}}
					onClick={onCancel}
				>
					CANCEL
				</Button>
				<Button
					classes={{ disabled: classes.disabledButton }}
					disabled={disableButton}
					color="primary"
					variant="contained"
					style={{ marginLeft: 30, width: 120, height: 48, marginRight: "15%" }}
					onClick={onSave}
				>
					SAVE
				</Button>
			</div>
		</div>
	);
};

EditBody.propTypes = {
	classes: PropTypes.object.isRequired,
	state: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default withTheme()(withStyles(styles)(EditBody));
