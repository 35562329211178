import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Radio, } from '@material-ui/core';
import { getItemChildType, titleCaseObjectName } from '../../../utilities';

const styles = theme => ({
  header: {
    fontSize: 20,
    color: theme.palette.primaryText.main,
    marginBottom: 24
  },
  blockTitle: {
    fontSize: 13.75,
    color: theme.palette.primaryText.main,
  },
  block: {
    display: 'flex',
    alignItems: 'center',
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.hovered.main
    },
    maxWidth: 600,
    padding:'8px 16px 8px 8px',
  },
  selectedBlock: {
    background: theme.palette.hovered.main
  },
  radio:{
    width:20,
    height:20,
    marginRight:8
  }
})

function ScopeSelect(props) {

  const {
    classes,
    state,
    dispatch,
  } = props;

  let options = [
    {
      title: `Link ${titleCaseObjectName(state.upstreamChildType)}s to ${titleCaseObjectName(state.downstreamChildType)}s`,
      value: false
    }
  ]

  if(['TABLE','DATASET_TABLE'].includes(state.upstreamChildType)){
    options.push({
      title: `Link ${titleCaseObjectName(state.upstreamChildType)}s to ${titleCaseObjectName(state.downstreamChildType)}s and underlying ${titleCaseObjectName(getItemChildType({object_type_txt:state.upstreamChildType}))}s to ${titleCaseObjectName(getItemChildType({object_type_txt:state.downstreamChildType}))}s`,
      value: true
    })
  }

  const onSelectItem = o => {
    if(o.value===state.isMapChild){
      return;
    }
    dispatch({ type: 'set_is_map_child', isMapChild: o.value })

    if(!o.value){
      dispatch({ type: 'set_children_downstream_match_config'})
      dispatch({ type: 'set_children_upstream_match_config'})
    }

  }

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        Select lineage level to link
      </Typography>
      {
        options.map(o => {
          let selected = o.value === state.isMapChild
          return (
            <div
              key={o.title}
              className={classes.block + (selected ? ' ' + classes.selectedBlock : '')}
              onClick={() => onSelectItem(o)}
            >
              <Radio
                checked={selected}
                color='primary'
                className={classes.radio}
              />
              <Typography className={classes.blockTitle}>{o.title}</Typography>
            </div>
          )
        })
      }
    </div>
  )
}

ScopeSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ScopeSelect));