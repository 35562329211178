import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Details from '../Details/Details';
import Linked from '../Linked/Linked';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
})

function Body(props) {

  const {
    classes,
    tabState,
    history,
    state,
    dispatch,
    fetchList,
    label,
    nodeKey
  } = props;

  let bodyContent;
  switch (tabState) {
    case 0:
      bodyContent = (
        <Details
          history={history}
          state={state}
          dispatch={dispatch}
          fetchList={fetchList}
          label={label}
          nodeKey={nodeKey}
        />
      )
      break;
    case 1:
      bodyContent = (
        <Linked
          history={history}
          state={state}
          dispatch={dispatch}
          fetchList={fetchList}
          nodeKey={nodeKey}
        />
      )
      break;
    default:
      break;
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withStyles(styles)(Body);