import * as actionTypes from '../actions/actionTypes';

const initialState = {
};

const setBulkEditParam = (state, action) => {
  return {
    url:action.data.url,
    params:action.data.params,
    filterId: action.data.filterId,
    instanceId: action.data.instanceId,
    objectIds: action.data.objectIds,
    redirectUrl:action.data.redirectUrl,
    type:action.data.type,
    editMode:action.data.editMode,
    cascadeParentObj: action.data.cascadeParentObj,
    presetDescription:action.data.presetDescription,
    fileName:action.data.fileName,
  }
}

const clearBulkEditParam = (state, action) => { 
  return {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BULK_EDIT_PARAM: return setBulkEditParam(state, action);
    case actionTypes.CLEAR_BULK_EDIT_PARAM: return clearBulkEditParam(state, action)
    default: return state;
  }
  
};

export default reducer;