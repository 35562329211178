import React from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import {palette} from './utils'
import moment from 'moment'
import { formatNumber } from '../../../utilities';

const styles = theme => ({
})

const LineChart = props => {

  const {
    theme,
    id,
    height,
    width,
    series, // takes a array of number
    colours
  } = props;

  const generateDates = () => {
    let days = series[0].data.length;
    const labels = []
    for(let i=days-1; i>=0; i--){
      labels.push(moment().add(-i,'day').format('ll'))
    }
    return labels
  }

  const options = {
    chart: {
      id:id,
      type: 'area',
      zoom: {enabled: false},
      toolbar:{show:false},
      stacked: true,
    },
    colors:colours||palette,
    legend:{
      position:'top',
      showForSingleSeries:true,
      horizontalAlign:'left',
      height:50,
      fontSize:'12px',
      itemMargin:{
        horizontal:8
      },
      labels:{
        colors:theme.palette.primaryText.main
      },
      markers:{
        width:10,
        height:10
      }
    },
    stroke: {
      curve: 'smooth',
      width:2
    },
    markers:{
      size:4,
    }, 
    dataLabels: {
      enabled: false
    },
    xaxis:{
      type:'datetime',
      categories:localStorage.hasOwnProperty('demo')?generateDates():(series[0]&&series[0].labels),
      labels:{
        style:{
          colors:theme.palette.primaryText.main
        },
        minHeight:50,
        hideOverlappingLabels:false,
        rotateAlways:true
      },
    },
    yaxis:{
      labels: {
        formatter: function(val) {
          if(!val)return val;
          let formatted = val.toFixed(0);
          try{
            formatted = formatNumber(formatted,undefined,true)
          }catch(error){
            console.log(error)
          }
          return formatted
        },
        style:{
          colors:theme.palette.primaryText.main
        }
      },
    }
  };

  
  return (
    <ReactApexChart options={options} series={series} height={height} width={width} type="area" />
  )
}

export default withTheme()(withStyles(styles)(LineChart));