import React, { } from 'react';
import { withTheme, withStyles, Select, MenuItem} from '@material-ui/core';


const styles = theme => ({
  selector:{
    ...theme.components.selector,
    width:'100%',
    height:56,
  }
})

const SelectLoadFrequency = props => {

  const {
    classes,
    setActiveStep,
    frequency,
    setFrequency,
  } = props;

  return (
    <div className={classes.root}>
      <Select
        value={frequency}
        disableUnderline
        onChange={(event)=>{
          setFrequency(event.target.value)
          if(event.target.value==='none')return;
          setActiveStep?.(1)
        }}
        className={classes.selector}
      >
        <MenuItem value="none">
          Select frequency
        </MenuItem>
        <MenuItem value="daily">
          Daily
        </MenuItem>
        <MenuItem value="weekly">
          Weekly
        </MenuItem>
        <MenuItem value="monthly">
          Monthly
        </MenuItem>
        <MenuItem value="custom">
          Custom CRON schedule
        </MenuItem>
      </Select>
    </div>
  )
}

export default withTheme()(withStyles(styles)(SelectLoadFrequency));