import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Button } from '@material-ui/core';

const styles = theme => ({
  infoBox:theme.components.infoBox,
  infoHeader:theme.components.infoBoxHeader,
  infoDescription:theme.components.infoBoxDescription,
  focusTxt:theme.components.infoBoxFocusTxt,
  buttonContained:theme.components.infoBoxButtonContained,
  button:theme.components.infoBoxButtonOutlined,
  disabledButton:{
    border:`1px solid ${theme.palette.primaryText.light}`
  }
})

function InfoBox(props) {

  const {
    classes,
    dispatch,
    // state,
    history,
  } = props;


  // const totalCount = state.objectCount?.reduce((acc,el)=>acc+el.count,0)

  return (
    <div className={classes.infoBox}>
      <Typography className={classes.infoHeader}>About Importing Bulk Edit Files</Typography>
      <div style={{display:'flex',alignItems:'flex-start'}}>
        <Typography className={classes.infoDescription}>
          K has the capability to process bulk metadata updates via importing an excel file. You must use a K generated bulk edit template generated from the <span className={classes.focusTxt} onClick={()=>history.push('/cart')}>Cart</span>
          {'\n\n'}
          Key things to check prior to importing:
          {'\n'}
          <div style={{display:'flex'}}>
            <div style={{flexShrink:0,marginRight:4}}>•</div><div>Blank cells will overwrite and delete existing metadata. Make sure cells have been left blank on purpose</div>
          </div>
          <div style={{display:'flex'}}>
            <div style={{flexShrink:0,marginRight:4}}>•</div><div>For properties where you are selecting a Collection Instance (e.g. Domain, Steward), make sure there are no typos. Any value that doesn't match an existing collection instance in K will result in a failed upload</div>
          </div>
          <div style={{display:'flex'}}>
            <div style={{flexShrink:0,marginRight:4}}>•</div><div>Bulk upload templates are only stored in K for 7 days. Check that you have saved an original clean version of the upload template. This version can act as a back-up to reverse any incorrect bulk upload errors. </div>
          </div>
        </Typography>
        <div style={{textAlign:'-webkit-right',flexShrink:0}}>
          <Button  className={classes.buttonContained} style={{marginBottom:24}} onClick={()=>dispatch({type:'set_import_modal_open',importModalOpen:true})}>
            IMPORT BULK EDIT FILE
          </Button>
          {/* <Button className={classes.button} disabled={!totalCount} classes={{disabled:classes.disabledButton}} onClick={()=>{dispatch({type:'set_view',view:'create_template'});history.push('/bulk_edit_templated')}}>
            CREATE BULK EDIT TEMPLATE
          </Button> */}
        </div>
      </div>
    </div>
  )
}

InfoBox.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default withStyles(styles)(InfoBox);