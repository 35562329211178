import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography  } from '@material-ui/core';
import CodeDisplay from '../../UI/CodeDisplay/CodeDisplay';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import AccessAdder from '../../UI/AccessAdder/AccessAdder'
import GlossaryAdder from '../../UI/GlossaryAdder/GlossaryAdder';
import SourceDescription from '../../UI/SourceDescription/SourceDescription';
 

const styles = theme => ({
  grouping: {
    marginTop: '4rem',
    marginBottom: '4rem',
  },
  title: {
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
    fontSize: '1.25rem',
    color:theme.palette.header.main
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
})

const Details = props => {

  const {
		classes,
    detailsData,
    state,
    dispatch,
    fetchList,
    history
  } = props;

  return (
    <div>
      <UpdateInputCerebrum
        url={`/api/macros/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        label="macro"
        initialValue={state.basicData.description}
        property="description"
        placeholder="Add a description"
        multiline
        fetchList={fetchList}
        collection="data"
        data={state.basicData}
        enableMarkDown={true}
        enableWidget={true}
        state={state}
      />

      <SourceDescription
        state={state}
        dispatch={dispatch}
      /> 

      <div style={{ marginTop: 60 }} >
        <GlossaryAdder
          state={state}
          dispatch={dispatch}
          history={history}
          disableModal
        />
      </div>

      <div style={{ marginTop: 60 }} >
        <AccessAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.accessModalOpen}
          setModalOpen={value=>dispatch({type:'set_access_modal_open',accessModalOpen:value})}
        />
      </div>

      <div className={classes.grouping}>
          <Typography className={classes.title}>CODE</Typography>
          {
            (!detailsData || detailsData.every(el=>!el.code)) && <Typography className={classes.normalText}>No code to be displayed</Typography>
          }  
          {
            detailsData && detailsData.map(el=>(
              <CodeDisplay
                code={el.code}
              />
            ))
          }
        </div> 
	  </div>
  )
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  userName: PropTypes.string
}

export default withStyles(styles)(Details);