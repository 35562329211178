import React from 'react';
import { withStyles, Tabs, Tab, Badge } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = theme => ({
  tab: {
    color:theme.palette.primaryText.main,
    '& span':{
      padding: '1px 6.4px',
      whiteSpace:'nowrap'
    },
    '&$tabSelected': {
      color: theme.palette.primary.main
    },
  },
  tabSelected: {},
  badge:{
    backgroundColor:'#DE350C',
    color:'#fff',
  },
  hide:{
    display:'hidden'
  },
  customScrollBar:{
    /* width */
    '&::-webkit-scrollbar':{
      width: '0px'
    },
  },
  tabWrapper:{
    display:'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:"center",
    // overflow:'hidden'
  },
  labelContainer:{
    width:'auto',
    flexShrink:1,
    // overflow:'hidden',
  },
  label:{
    // width:'100%',
    display:'block',
    fontSize:13.75
    // overflow:'hidden',
    // whiteSpace:'nowrap',
    // textOverflow:'ellipsis'
  },
  scrollButton:{
    '& svg':{
      color:theme.palette.primaryText.main
    }
  }
})

function TabBar(props) {

  const {
    classes,
    tabState,
    tabOptions,
    setTabState,
    tabIcons,
    forceMinWidth,
    maxWidth,
    disableUnderline,
    disableScroll,
    badgeContent
  } = props;

  const ellipsisStr = str => {
    let maxLength = Math.round(maxWidth/11);
    if(tabIcons)maxLength -= 4
    if(str.length>maxLength){
      return str.slice(0,maxLength)+'...'
    }
    return str;
  }

  return (
    <Tabs
      style={{ borderBottom: disableUnderline ? '' : '1px solid rgba(0,0,0,0.1)'}}
      value={tabState}
      className={"tab_bar"}
      classes={{scroller:classes.customScrollBar,scrollButtons:classes.scrollButton}}
      variant={disableScroll?"standard":"scrollable"}
      scrollButtons={disableScroll ? "off" : "auto"}
      onChange={(e, val) => setTabState(val)}
      indicatorColor='primary'>
      {tabOptions.map((op, index) => 
          <Tab
            id={'tab_bar_'+op.toLowerCase()}
            data-test-id={'tab-bar-'+op.toLowerCase()}
            icon={tabIcons ? tabIcons[index] : undefined}
            style={{ maxWidth: maxWidth || undefined, minWidth:forceMinWidth || 120, flexGrow:1  }}
            disableRipple
            label={
              (!badgeContent||!badgeContent[index])?
              ellipsisStr(op)
              :
              <Badge badgeContent={badgeContent[index]} classes={{badge:classes.badge}}>
                {ellipsisStr(op)}
              </Badge>
            }
            classes={{ root: classes.tab,wrapper:classes.tabWrapper,labelContainer:classes.labelContainer,label:classes.label, selected: classes.tabSelected }}
          />
      )}
    </Tabs>
  )
}

TabBar.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number.isRequired,
  setTabState: PropTypes.func.isRequired,
  tabOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  tabIcons: PropTypes.arrayOf(PropTypes.node),
  disableUnderline: PropTypes.bool,
  disableScroll: PropTypes.bool,
  forceMinWidth: PropTypes.number
}

TabBar.defaultProps = {
  classes: {},
  tabState: 0,
  setTabState: () => null,
  tabOptions: ['default'],
}

export default withStyles(styles)(TabBar);