import React, { useEffect, useRef } from 'react';
import { withTheme, CircularProgress, withStyles, Typography, Select, MenuItem} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr'
import { mapObjectName, isInViewport } from '../../../utilities'
import { getObjectCard} from '../utils'
 
const styles = theme => ({
  root: {

  },
  title:{
    fontSize:20,
    flexGrow:1,
    color:theme.palette.header.main
  },
  errorText:{
    color:theme.palette.primaryText.main,
    marginTop:24,
  },
  timelineText:{
    color:theme.palette.primary.main,
    letterSpacing:2,
    marginBottom:8,
    fontSize:12
  },
  checkbox:{
    paddingLeft:0
  },
  selector:{
    ...theme.components.selector
  },
  selectorHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:2,
    paddingLeft:16,
    marginTop:4,
    marginBottom:4
  }
})

const AssignedList = props => {

  const {
    classes,
    history,
    theme,
    state,
    dispatch,
    sessionData,
    objectType,
    headerTop
  } = props;

  let data = state.assignedListData[objectType]
  let setData = d => dispatch({type:'set_assigned_list',assignedListData:{...state.assignedListData,[objectType]:d}})
  const scrollRef = useRef()

  const loadListData = async ({sort=state.assignedListSort,page=1}) => {
    setData({
      ...(page===1?{}:data),
      loading:true
    })
    let responseData;
    await axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related`,
        {params:{
          relationship:'ASSIGNEE_OF',
          object_name:objectType,
          per_page:10,
          page:page,
          sort:sort
        }}
      )
      .then(response=>{
        if(response.data.items.length===0){
          setData({
            ...response.data
          })
          return;
        }
       responseData = response.data;
      })
      .catch(error=>{
        console.log(error)
        setData({
          error:true
        })
      })
    
    if(!responseData)return;

    let solrDetails = {}
    await axiosSolr.get(
      `/solr/search/select`,{
        params:{
          q:'*',
          fq:`id:(${responseData.items.map(el=>el.id).join(' OR ')})`,
          fl:'*',
          start:0,
          rows:responseData.items.length,
        }
      }
    )
    .then(solrRes=>{
      solrRes.data.response.docs.forEach(el=>{
        solrDetails[el.id] = el;
      })
      
    })
    .catch(error=>{
      console.log(error)
    })

    let stats = {}
    await axiosCerebrum
      .get(
        '/api/stats',
        {params:{
          object_ids:responseData.items.map(el=>el.id).join(','),
          per_page:responseData.items.map(el=>el.id).length
        }}
      )
      .then(response=>{
        response.data.items.forEach(el=>{
          stats[el.node_id] = el
        })
      })
      .catch(error=>{
        console.log(error)
      })

    setData({
      ...responseData,
      items:[
        ...(page===1?[]:data.items),
        ...responseData.items.map(el=>({
          ...el,
          ...(solrDetails[el.id]||{}),
          stats:stats[el.id]
        }))
      ]
    })
  }

  useEffect(()=>{
    if(!data.items && !data.loading){
      loadListData({})
    }
    // eslint-disable-next-line
  },[])


  const onChangeSort = (event) => {
    loadListData({sort:event.target.value})
    dispatch({type:'set_assigned_list_sort',assignedListSort:event.target.value})
  }

  const shouldPaginate = () => {
    return data.items && data.page<data.pages && !data.loading && isInViewport(scrollRef)
  }


  window.onscroll = () => {
    if(shouldPaginate()){
      loadListData({page:data.page+1})
    }
  }

  useEffect(()=>{
    if(shouldPaginate()){
      loadListData({page:data.page+1})
    }
  // eslint-disable-next-line
  },[data])

  return (
    <div className={classes.root}>
      <div style={{display:'flex',alignItems:'flex-start',paddingBottom:20,background:theme.palette.background.main,position:'sticky',top:headerTop,zIndex:9}}>
        <Typography className={classes.title} data-test-classname="ecosystem-title">{!isNaN(data.total)?data.total+' ':''}{mapObjectName(objectType).replace(/_/g,' ').toUpperCase()}(S) ASSIGNED</Typography>
        {
          (data?.total!==0) && 
          <>
            <Select
              className={classes.selector}
              style={{marginLeft:16,width:150}}
              value={state.assignedListSort}
              onChange={event=>onChangeSort(event)}
              data-test-classname="ecosystem-sort-selector"
              MenuProps={{
                getContentAnchorEl:null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  horizontal: "left"
                },
              }}
            >
              {
                [
                  {dispName:'Recently assigned',value:'LINKAGE_END_DESC'},
                  {dispName:'Trust',value:'TRUST_SCORE_DESC'},
                  {dispName:'A-Z',value:'ALPHABETICAL'},
                  {dispName:'Z-A',value:'REVERSE_ALPHABETICAL'}
                ].map(el=>(
                  <MenuItem value={el.value}>
                    {el.dispName}
                  </MenuItem>
                ))
              }
              
            </Select>
          </>
        }
      </div>
      {
          data.items?.map(el=>(
            getObjectCard(el,history)
         ))
      }
      {
        data.total===0 && 
        <Typography className={classes.errorText}>No items found</Typography>
      }
      <div ref={scrollRef} style={{textAlign:'center',marginBottom:24}}>
        {
          data.loading && 
          <CircularProgress style={{margin:'24px auto'}} color='secondary'/>
        }
      </div>
      
      {
        data.error && 
        <Typography className={classes.errorText}>Error occurred loading assigned items</Typography>
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(AssignedList));