import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, IconButton, Popper, Paper, ClickAwayListener } from '@material-ui/core';
import { getIconComponent } from '../../../utilities';
import { HexColorPicker } from "react-colorful";

const styles = theme => ({
  paper:{
    width:'220px',
    border:`1px solid ${theme.palette.border.main}`
  },
})

function ColourPickerPopper(props) {
  const {
    classes,
    theme,
    value,
    setValue
  } = props;

  const [anchor, setAnchor] = useState(null)

  const handleClick = event => {
    setAnchor(event.currentTarget)
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleClick}
      >
        {getIconComponent({label:'colour_picker', size: 24, colour: theme.palette.primaryText.light})}
      </IconButton>
      <Popper 
        anchorEl={anchor}
        open={Boolean(anchor)}
        placement='top-end'
      >
        <ClickAwayListener onClickAway={()=>setAnchor(null)}>
          <Paper className={classes.paper}>
            <HexColorPicker   
              style={{width:'100%'}}
              color={value||'#FFFFFF'} 
              onChange={colour=>{
                setValue(colour)
              }} 
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

ColourPickerPopper.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(ColourPickerPopper));