import React, { useEffect, useReducer } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/RoleManager/Body/Body';
import 'url-search-params-polyfill';
import axiosCerebrum from '../../axios-cerebrum';
import { setHelpWdigetVisibility } from '../../utilities';
import { addHistory } from '../../HistoryManager';
import { adminPageInfo } from '../NavBar/utils';

const styles = theme => ({
});

const initialState = {
  modifiedMap:{}
};

function reducer(state, action) {
  switch (action.type) {
    // case 'set_roles_data':
    //   return {
    //     ...state,
    //     rolesData: action.rolesData,
    //     rolesLoading: action.rolesLoading,
    //     rolesError: action.rolesError
    //   }
    case 'set_original_settings_data':
      return {
        ...state,
        originalSettingsData: action.originalSettingsData
      }
    case 'set_settings_data':
      return {
        ...state,
        settingsData: action.settingsData,
        settingsLoading:action.settingsLoading,
        settingsError: action.settingsError
      }
    case 'set_editing':
      return {
        ...state,
        editing:action.editing
      }
    case 'set_modified_map':
      return {
        ...state,
        modifiedMap:action.modifiedMap
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const RoleManager = props => {

  const {
    history,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState)

  // const {
  //   data: rolesData,
  //   loading:rolesLoading,
  //   error:rolesError
  // } = useGetCerebrum({
  //   url:'/api/roles',
  // })

  // useEffect(()=>{
  //   dispatch({
  //     type:'set_roles_data',
  //     rolesData,
  //     rolesLoading,
  //     rolesError
  //   })
  // },[rolesData, rolesLoading, rolesError])

  const fetchPermission = () => {
    dispatch({
      type:'set_settings_data',
      settingsLoading:true
    })
    let promises = [
      axiosCerebrum.get(`/api/settings/6000`),
      axiosCerebrum.get(`/api/settings/6010`),
      axiosCerebrum.get(`/api/settings/6020`),
      axiosCerebrum.get(`/api/settings/6030`)
    ];
    Promise
      .all(promises)
      .then(response=>{
        dispatch({
          type:'set_settings_data',
          settingsData:response.map(el=>el.data)
        })
        dispatch({
          type:'set_original_settings_data',
          originalSettingsData:response.map(el=>el.data)
        })
      })
      .catch(error=>{
        dispatch({
          type:'set_settings_data',
          settingsError:true
        })
        console.log(error)
      })
  }

  useEffect(()=>{
    fetchPermission()
  },[])
  
  useEffect(()=>{
    addHistory({
      url:window.location.pathname, 
      iconLabel:adminPageInfo.roles.icon, 
      title: adminPageInfo.roles.name, 
      subTitle:adminPageInfo.roles.description,
      type:'application',
    })
    setHelpWdigetVisibility(true)
    return ()=>{setHelpWdigetVisibility(false)}
  // eslint-disable-next-line
  },[])

  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              label='manage_role'
              title='Manage Roles'
              subtitle=' '
            />
          </div>)}
        body={
          <Body
            state={state}
            history={history}
            dispatch={dispatch}
          />
        }
      />
    </div>
  )
}

export default withStyles(styles)(RoleManager);
