import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, ClickAwayListener } from '@material-ui/core';
import PropTypes from 'prop-types';
// import axiosCerebrum from '../../../../axios-cerebrum'
import axiosCerebrum from '../../../../axios-cerebrum';
import { getLabelPlural, getNameSearchQuery } from '../../../../utilities';
import SearchSelector from '../../SearchSelector/SearchSelector';
import { tileStyles } from './utils/styles';
import { getPlatformSettings } from '../../../../permissionChecker';
import { checkIsClickOnSuggestion } from './utils/utils';
import UserChip from '../../Chips/UserChip';
import useAlert from '../../../../hooks/useAlert';
import { getDefaultSearchParams } from '../../../BasicSearch/MainSearch/Utils/Utils';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
})

function StewardrManager(props) {

  const {
    classes,
    theme,
    history,
    object,
    onAddTileData,
    tileData,
    isEditable,
    alwaysOpenNewTab
  } = props;

  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [deleting, setDeleting] = useState(false)
  const [adding, setAdding] = useState(false)

  let userFilter = getPlatformSettings(4008)?.value;

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  
  const loadSteward = () => {
    setLoading(true)
    axiosCerebrum 
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related`,{
          params:{
            object_name:'USER',
            relationship:'STEWARDED_BY',
            per_page:200
          }
        }
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":response.data.items.map(t=>({
              name:t.name,
              labels:'user',
              id:t.id,
              onClick:()=>{
                alwaysOpenNewTab?
                window.open(`/profile/user/${t.id}`,'_blank'):
                history.push(`/profile/user/${t.id}`)
              }
            })),
            "disp_title":"STEWARD",
            "id":"stewardedBy",
          }
        )
        setLoading(false)
      })
      .catch(error=>{
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(()=>{
    if(!tileData){
      loadSteward()
    }
  // eslint-disable-next-line
  },[])
  
  const onAddSteward = (item) => {
    if(adding)return;
    setAdding(true)
    setSearchValue('')
    axiosCerebrum
      .put(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=STEWARDED_BY&object_id=${item.id}`
      )
      .then(response=>{
        setAdding(false)
        onAddTileData(
          {
            "disp_body":[...tileData.disp_body,{
              name:item.name_txt,
              labels:'user',
              id:item.id,
              onClick:()=>{
                alwaysOpenNewTab?
                window.open(`/profile/user/${item.id}`,'_blank'):
                history.push(`/profile/user/${item.id}`)
              }
            }],
            "disp_title":tileData.disp_title,
            "id":tileData.id,
          }
        )
      })
      .catch(error=>{
        setAdding(false)
        console.log(error)
        sendAlert({ message: 'Error occurred adding steward, please try again', type: 'error' })
      })
  }

  const onDelete = item => {
    setDeleting(true)
    let showLoadingTimeout = setTimeout(()=>{
      setLoading(true)
    },800)
    axiosCerebrum
      .delete(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=STEWARDED_BY&object_id=${item.id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":tileData.disp_body.filter(s=>s.id!==item.id),
            "disp_title":tileData.disp_title,
            "id":tileData.id,
          }
        )
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred deleting steward, please try again', type: 'error' })
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
  }

  if(!tileData)return <></>
  
  return (
    <div data-test-id="steward-data-tile" className={classes.root}>
      <div className={classes.editableHeaderContainer}  onClick={()=>setEditing(true)} >
        <Typography className={classes.title}>{tileData.disp_title.toUpperCase()}</Typography>
      </div>
      <ClickAwayListener 
        onClickAway={(event)=>{
          if(checkIsClickOnSuggestion(event.target))return;
          setSearchValue('')
          setEditing(false)
        }}
      >
       <div className={classes.chipContainer + ' ' + (editing?classes.editingBox:isEditable?classes.clickableBox:'')} onClick={()=>isEditable && setEditing(true)}>
          {
            loading && <CircularProgress color='secondary' size={16}/>
          }
          {
            !loading && tileData && tileData.disp_body.length===0 && 
            <div data-test-classname="steward-data-tile-chip" className={classes.tag} style={{background:theme.palette.chip.main,color:theme.palette.primaryText.main,border:`1px solid ${theme.palette.border.main}`,cursor:'default'}}>
              <span className={classes.tagText} style={{color:theme.palette.primaryText.main}}>None</span>
            </div>
          }
          {
            !loading && tileData && tileData.disp_body.sort((a,b)=>a.name<b.name?-1:1).map(t=>(
              <UserChip
                key={t.name}
                testID={"steward-data-tile-chip"}
                user={t}
                onClick={t.onClick}
                onRemove={isEditable?event=>{event.stopPropagation();!deleting && onDelete(t)}:undefined}
                onlyShowDeleteOnHover={!editing}
                bottomMargin={8}
              />
            ))
          }
          {
            editing && 
            <div style={{marginTop:8,marginBottom:6,width:'100%'}}>
              <SearchSelector
                url={'/solr/search/select'}
                params={{
                  ...getDefaultSearchParams({indexName:'search'}),
                  q:getNameSearchQuery(searchValue),
                  fq:'object_type_srt:USER AND -merge_type_srt:CANDIDATE AND account_type_srt:"USER_ACCOUNT"' + 
                      (userFilter==='data_roles'?' AND data_role_kc_msrt:"DATA STEWARD"':'') + 
                      (tileData.disp_body.length>0?` AND -id:(${tileData.disp_body.map(b=>b.id).join(' OR ')})`:''),
                  fl:'*',
                  qf:'name_srt',
                  sort:'name_srt asc',
                  rows:20
                }}
                testID="user-manager-input"
                clearable
                autoFocus
                height={32}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder={`Search for a user`}
                autoSuggestion={userFilter==='data_roles'}
                suggestionListWidth={290}
                removeSuggestionHeader
                scrollable={true}
                postProcessDataList={data=>{
                  if(!tileData.disp_body)return data;
                  return data.filter(d=>!tileData.disp_body.find(t=>t.id===d.id))
                }}
                isSimplifiedSuggestion={true}
                onResultClick={onAddSteward}
                keepPopperOnClick
              />
            </div>
          }
        </div>
      </ClickAwayListener>
    </div>
  )
}

StewardrManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(StewardrManager));