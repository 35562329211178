import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, ClickAwayListener, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../../axios-cerebrum';
import { getSeverityColour, issueSeverities, toTitleCase,} from '../../../../utilities';
import { tileStyles } from './utils/styles';
import { checkIsClickOnSuggestion } from './utils/utils';
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
  iconButton:{
    ...tileStyles.deleteButton
  },
  selector:{
    ...tileStyles.selector
  }
})

function IssueSeverityManager(props) {

  const {
    classes,
    object,
    onAddTileData,
    tileData,
    isEditable,
  } = props;

  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const popperRef = useRef()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  
  const loadSeverity = () => {
    onAddTileData(
      {
        "disp_body":[{
          name:object.severity,
          labels:'issue_severity',
          id:object.severity
        }],
        "disp_title":"SEVERITY",
        "id":"issueSeverity",
      }
    )
  }

  useEffect(()=>{
    if(!tileData){
      loadSeverity()
    }
  // eslint-disable-next-line
  },[])

  const onAdd = async severity => {
    setEditing(false)
    if(severity===tileData.disp_body[0]?.id)return;
    setLoading(true)

    let requestBody = {
      "name": object.name,
      "description": object.description,
      "type": object.type,
      "severity": severity,
      "status": object.status,
      "source_id":object.source_id
    }

    await axiosCerebrum
      .put(`/api/issues/${object.id}`,requestBody)
      .then(response=>{
        onAddTileData(
          {
            "disp_body":[{
              name:response.data.severity,
              labels:'issue_severity',
              id:response.data.severity,
            }],
            "disp_title":"SEVERITY",
            "id":"issueSeverity",
          }
        )
      })
      .catch(error=>{
        console.log(error)
        sendAlert({message:`Error occurred updating issue severity, please try again`,type:'error'})
      })

    setLoading(false)
  }

  if(!tileData)return <></>
  
  return (
    <div data-test-id="issue-severity-data-tile" className={classes.root}>
      <div className={classes.editableHeaderContainer} >
        <Typography className={classes.title}>SEVERITY</Typography>
      </div>
      <ClickAwayListener 
        onClickAway={(event)=>{
          if(checkIsClickOnSuggestion(event.target))return;
          setEditing(false)
        }}
      >
        <div ref={popperRef} className={classes.chipContainer + ' ' + (editing?classes.editingBox:isEditable?classes.clickableBox:'')} onClick={()=>isEditable && !editing && setEditing(true)}>
          {
            loading && <CircularProgress color='secondary' size={16}/>
          }
          {
            tileData.disp_body.length>0 && !loading && 
            <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',overflow:"hidden",width:'100%'}}>
              <div data-test-classname="issue-severity-data-tile-chip" className={classes.tag} style={{background:getSeverityColour(tileData.disp_body[0].id),border:'none'}} onClick={tileData.disp_body[0].onClick}>
                <span className={classes.tagText}>{toTitleCase(tileData.disp_body[0].name.replace(/_/g,' '))}</span>
              </div>
            </div>
          }
          {
            editing && 
            <div style={{width:'100%',height:0,overflow:'hidden'}}>
              <Select
                value={tileData.disp_body[0]?.id}
                style={{width:0,height:0,opacity:0}}
                onChange={event=>{
                  onAdd(event.target.value)
                }}
                MenuProps={{
                  id:'search_selector_popper_list',
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  style:{marginTop:8},
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl:null,
                }}
                open={editing}
                onClose={()=>setEditing(false)}
                autoFocus
                disableUnderline
                className={classes.selector}
              >
                {
                  issueSeverities.map(s=>{
                    return <MenuItem key={s} value={s} style={{fontSize:13}}>{toTitleCase(s.replace(/_/g,' '))}</MenuItem>
                  })
                }
              </Select>
            </div>
          }
        </div>
      </ClickAwayListener>
    </div>
  )
}

IssueSeverityManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(IssueSeverityManager));