import React, { useEffect, useState } from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import { getLabelPlural, onClickResultItem } from '../../../utilities';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../axios-cerebrum';
import {withRouter} from 'react-router';
import { globalListenerRef } from '../../../GlobalListenerRef';

const styles = theme => ({
  root:{
    display:'flex',
    whiteSpace:'pre-wrap',
    overflow:'hidden',
    color:theme.palette.primaryText.main,
    fontSize:13.75
  },
  breadCrumbText: {
    ...theme.components.breadcrumb
  },
});


const BreadCrumb = props => {

  const {
    classes,
    data,
    history,
    fontSize,
    shouldWrap
  } = props;

  const [breadCrumb, setBreadCrumb] = useState()

  const loadBreadCrumb = async () => {
    let parentArray = []

    await axiosCerebrum
      .get(
        `/api/${getLabelPlural(data.object.name)}/${data.id}/breadcrumbs`
      )
      .then(response=>{
        parentArray = response.data.crumbs.slice(1)
      })
      .catch(error=>{
        console.log(error)
      })

    if(parentArray.length>3){
      parentArray = parentArray.slice(0,3)
      parentArray.push({
        object_id:'more_parent',
        object_name:'...'
      })
    }

    parentArray.push({
      object_id:'source_root',
      object_name:'ECOSYSTEM',
    })
    parentArray.reverse();
    setBreadCrumb(parentArray)
  }

  useEffect(()=>{
    if(!breadCrumb)loadBreadCrumb()
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.reload_breadcrumb ){
        loadBreadCrumb()
      }
    }
    window.removeEventListener('message',globalListenerRef.reloadBreadCrumb);
    globalListenerRef.reloadBreadCrumb = onMsgReceived;
    window.addEventListener("message", globalListenerRef.reloadBreadCrumb);
    return (()=>{window.removeEventListener('message',globalListenerRef.reloadBreadCrumb);})
  // eslint-disable-next-line
  },[data])

  let breadcrumbs =
    breadCrumb && breadCrumb.length>0 &&
    breadCrumb.map((el) =>
      {
        let dispName = el.object_name.toUpperCase()
        if(dispName==='')dispName = 'UNKNOWN'
        return (
          <span
            key={el.object_id}
            style={{fontSize}}
            data-test-id={`breadcrumb-${dispName.toLowerCase()}`}
            className={el.object_id==='more_parent'?undefined:classes.breadCrumbText}
            onClick={
              el.object_id==='source_root'?
              ()=>history.push('/my_ecosystem?tabName=SOURCES'):
              () => onClickResultItem({label:el.object_type, id:el.object_id, history, item:el})
            }
          >
            {dispName}
          </span>
        )
      }
    ).reduce((prev, curr) => [prev, ' / ', curr])

  return (
    <div className={classes.root} style={{fontSize, flexWrap:shouldWrap?'wrap':undefined}}>
      {breadcrumbs}
    </div>
  )
}

BreadCrumb.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  shouldWrap: PropTypes.bool
}



export default withRouter(withTheme()(withStyles(styles)(BreadCrumb)));
