import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import { onClickResultItem, isInViewport, getDispFields } from '../../../utilities';
import { withTheme, withStyles, Typography, CircularProgress, Button} from '@material-ui/core';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import ChipWrapper from '../../UI/ChipWrapper/ChipWrapper'


const styles = theme => ({
  root: {
  },
  block: {
    marginBottom: 40
  },
  title:{
    display: 'inline-block',
    fontSize: '1.25rem',
    fontWeight:400,
    position: 'relative',
    //top: '0.1rem',
    paddingTop:'6px',
    paddingBottom:'6px',
    color:theme.palette.header.main
  },
  subtitle:{
    color:theme.palette.primaryText.light
  },
  chip:{
    padding:6,
    paddingRight:10,
    fontSize:13.75,
    background:theme.palette.chip.main,
    border:`1px solid ${theme.palette.border.main}`,
    borderRadius:4,
    marginRight:8,
    marginBottom:8,
    color:theme.palette.primaryText.main,
    width:'max-content',
    maxWidth:'100%',
    overflow:'hidden',
    textOverflow:"ellipsis",
    whiteSpace:'nowrap'
  }
})

const Body = props => {

  const {
    classes,
    theme,
    history,
    fetchList,
    state,
    loadLinkData,
    onOpenLinkModal
  } = props;

  const linkRef = useRef()

  const linkData = state.linkData;

  window.onscroll = () => {
    if(state.linkData && isInViewport(linkRef) && !state.linkLoading && state.linkData.page<state.linkData.pages){
      loadLinkData({page:state.linkData.page+1});
    }
  }

  useEffect(()=>{
    if(state.linkData && isInViewport(linkRef) && !state.linkLoading && state.linkData.page<state.linkData.pages){
      loadLinkData({page:state.linkData.page+1});
    }
  // eslint-disable-next-line
  },[state.linkData,state.linkLoading])

  useEffect(()=>{ 
    if(!state.linkData){
      loadLinkData({page:1})
    }
  // eslint-disable-next-line
  },[])

  const isExternal = state.basicData.type && state.basicData.type.toLowerCase()==='set_by_external_source';
  
  return (
    <div className={classes.root}>

      <div className={classes.block}>
        <UpdateInputCerebrum
          url={`/api/issues/${state.basicData.id}`}
          data={state.basicData}
          label="issue"
          initialValue={state.basicData.description}
          property="description"
          placeholder="Add a description"
          multiline
          state={state}
          fetchList={fetchList}
          disableEditing={isExternal}
          collection="tags"
          subtitle='Describe the issue, its impacts and how it was found'
          enableMarkDown
          enableWidget={false}
        />
      </div>

      {
        state.basicData.external_url && 
        <div className={classes.block}>
          <Typography style={{fontSize:20, color:theme.palette.header.main}}>EXTERNAL URL</Typography>
          <Typography style={{fontSize:12,marginBottom:16,color:theme.palette.primaryText.light,marginTop:4}}>Link to 3rd party tool used to manage this issue</Typography>
          <span onClick={()=>window.open(state.basicData.external_url,'_blank')} style={{fontSize:13.75,wordBreak:'break-all',color:theme.palette.hyperLink.main,cursor:'pointer',textDecoration:'underline'}}>
            {state.basicData.external_url}
          </span>
        </div>
      }

      <div className={classes.block}>
        <div style={{display:'block'}}>
          <div style={{display:'flex', alignItems:'flex-end'}}>
            <Typography  className = {classes.title}>LINKED TO</Typography>
            <Button color='primary' style={{marginLeft:16,marginBottom:2}} onClick={onOpenLinkModal}>EDIT</Button>    
          </div>
          <Typography variant='caption' className={classes.subtitle}>Where this issue is found</Typography>
        </div>
        {
          linkData && linkData.total===0 &&
          <Typography style={{color:theme.palette.primaryText.main,marginTop:16}}>No item linked to the issue</Typography>
        }
        {
          linkData && 
          <div style={{width: '100%',marginTop:'1rem'}}>
          {
            linkData.items.map(item =>{
              return (          
                <ChipWrapper
                  wrappedComponent={
                    <SimpleResultItem
                      item={item}
                      key={item.id}
                      title={getDispFields(item,'dispTitle').length>47?(getDispFields(item,'dispTitle').slice(0,47)+'...'):getDispFields(item,'dispTitle')}
                      subTitle={getDispFields(item,'dispSubtitle')}
                      label={item.object_type_txt || item.type.toLowerCase()}
                      rightText={item.trust_srt}
                      onClick={() => onClickResultItem({ id:item.id, label: item.type.toLowerCase(), history: history, item: item })}
                    />
                  }
                  showUnderline
                />                         

            )})
          }
          </div>
        }
        <div ref={linkRef} style={{ textAlign: 'center', marginTop: 20,marginBottom:10 }}>
          {
            state.linkLoading && <CircularProgress color="secondary" />
          }
        </div>
        {
          state.linkError && <Typography>Error occurred loading linnked items</Typography>
        }
      </div>

    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
  tabUrlPrefix: PropTypes.string,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
}

export default withTheme()(withStyles(styles)(Body));