export const getAlterName = (data,roles,forceDefaultTitle) => {
  let title = forceDefaultTitle || data.name;
  let description;
  if(data.alternate_name && data.alternate_name.trim()!==''){
    if(data?.object?.name==='HOST'){
      title = data.alternate_name
      description = data.name
    }else{
      if(!roles.every(el=>el==='90')){
        description = `${data.alternate_name}`
      }else{
        title = data.alternate_name
        description = `${forceDefaultTitle || data.name}`
      }
    }
  }
  return {title, description}
}