import React, { } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Switch, Button } from '@material-ui/core';

const styles = theme => ({
  
})

function ClearCartToggler(props) {

  const {
    state,
    dispatch,
  } = props;
  
 
  return (
    <div>
      <div style={{display:'flex',alignItems:'center',marginTop:8}}>
        <Typography style={{fontSize:16,marginRight:24,marginLeft:4}}>
          Clear Cart
        </Typography>
        <Switch
          checked={state.isClearCart===true}
          onChange={()=>{
            dispatch({type:'set_is_clear_cart',isClearCart:!state.isClearCart})
          }}
          inputProps={{
            'data-test-id':'clear-cart-switch'
          }}
          color="primary"
        />
      </div>
      <Button style={{marginTop:24}} onClick={()=>{dispatch({type:'set_setting_step',settingStep:state.settingStep+1})}} color='primary' variant="contained" >NEXT</Button>
    </div>
  )
}

ClearCartToggler.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(ClearCartToggler));