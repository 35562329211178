import React, { } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import { getValidActions } from '../utils';

const styles = theme => ({
  header: {
    fontSize: 20,
    color: theme.palette.primaryText.main,
    marginBottom: 16,
  },
})

function ActionSelector(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    sessionData
  } = props;


  return (
    <div className={classes.root}>
      <Typography className={classes.header}>Select Bulk Action options</Typography>
      <div style={{ maxWidth: 800 }}>
        {
          getValidActions({ sessionData }).map(o => {
            return (
              <SimpleResultItem
                title={o.title}
                titleColour={theme.palette.primaryText.main}
                subTitle={o.subTitle}
                key={o.id}
                hideIcon
                item={o}
                showUnderline
                onClick={() => {
                  if(state.selectedAction!==o.id){
                    dispatch({
                      type: 'set_selected_action',
                      selectedAction: o.id
                    })
                    dispatch({
                      type: 'reset_state'
                    })
                  }
                  dispatch({
                    type: 'set_active_step',
                    activeStep: 1
                  })
                }}
              />
            )
          })
        }
      </div>
    </div>
  )
}

ActionSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ActionSelector));