import {
	Avatar,
	Button,
	IconButton,
	InputBase,
	Modal,
	Paper,
	Switch,
	Typography,
	withStyles,
} from "@material-ui/core";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { CerebrumLongListLoader } from "../../../LongListLoader";
import axiosCerebrum from "../../../axios-cerebrum";
import useAlert from "../../../hooks/useAlert";
import theme from "../../../theme";
import {
	collectionIds,
	getIconComponent,
	getInitials,
	getNameSearchQuery,
	toTitleCase,
} from "../../../utilities";
import KTooltip from "../../UI/KTooltip/KTooltip";
import SearchSelector from "../../UI/SearchSelector/SearchSelector";
import TeamSelector from "../../UI/SearchSelector/TeamSelector";

const styles = (theme) => ({
	title: {
		color: theme.palette.primary.main,
		fontSize: 20,
		marginBottom: 24,
	},
	subtitle: {
		color: theme.palette.primaryText.light,
		fontSize: 12,
		marginBottom: 16,
	},
	modalPaper: {
		width: 409,
		height: 192,
		marginTop: "-15vh",
		background: theme.palette.background.main,
		border: `1px solid ${theme.palette.border.main}`,
		padding: 24,
	},
	modalTitle: {
		fontSize: 20,
		color: theme.palette.primaryText.main,
		marginBottom: 12,
	},
	modalBody: {
		fontSize: 16,
		color: theme.palette.primaryText.light,
		whiteSpace: "pre-wrap",
		height: 128,
	},
	buttons: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		marginTop: 3,
		marginRight: -16,
	},
	disabledButton: {
		...theme.components.disabledButton,
	},
	teamChip: {
		backgroundColor: theme.palette.chip.main,
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: 5,
		marginRight: 6,
		marginBottom: 8,
		padding: "8px 12px",
		display: "flex",
		alignItems: "center",
	},
	avatar: {
		fontSize: 12,
		height: 24,
		width: 24,
		backgroundColor: theme.palette.avatar.main,
		marginRight: 12,
	},
	sectionHeader: {
		fontSize: 12,
		letterSpacing: 2,
		color: theme.palette.primaryText.main,
		marginBottom: 8,
	},
	itemChip: {
		width: "max-content",
		maxWidth: "100%",
		overflow: "hidden",
		display: "flex",
		alignItems: "center",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		borderRadius: 16,
		padding: "4px 6px",
		marginRight: 8,
		marginBottom: 8,
		"& span": {
			padding: "0 8px",
			fontSize: 13.75,
			// marginLeft:4
		},
	},
	targetItemChip: {
		background: theme.palette.primary.dark,
		color: theme.palette.background.main,
	},
	tooltip: {
		fontSize: 13.75,
		whiteSpace: "pre-wrap",
	},
	sttingToggleContainer: {
		display: "flex",
		alignItems: "center",
		height: 48,
	},
	settingToggleHeader: {
		fontSize: 16,
		marginLeft: 16,
		width: 420,
	},
	switchIcon: {
		color: theme.palette.primary.main,
	},
	switchBar: {
		backgroundColor: theme.palette.primary.main,
	},
	disabledSwitchBar: {
		backgroundColor: theme.palette.primaryText.light,
	},
	inputBase: {
		...theme.components.inputBase,
		height: 48,
		width: "100%",
	},
	clearButton: {
		marginLeft: 16,
		width: 16,
		height: 16,
		"&:hover": {
			background: `${lighten(theme.palette.primary.dark, 0.4)} !important`,
		},
	},
});

const Teams = (props) => {
	const { classes, sessionData, state } = props;

	let currentTeam = [];
	let isReceiveEmail = "false";
	let receiveEmailSettingID;
	try {
		currentTeam = localStorage.hasOwnProperty("userGroupData")
			? JSON.parse(localStorage.getItem("userGroupData"))
			: [];
		isReceiveEmail = JSON.parse(
			localStorage.getItem("userSettings"),
		).items.find(
			(el) => el.user_settings_template.name === "Enable Daily Briefing Email",
		).value;
		receiveEmailSettingID = JSON.parse(
			localStorage.getItem("userSettings"),
		).items.find(
			(el) => el.user_settings_template.name === "Enable Daily Briefing Email",
		).id;
	} catch {
		currentTeam = [];
	}

	const [searchValue, setSearchValue] = useState("");
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedTeam, setSelectedTeam] = useState();
	const [jobTypeSearchValue, setJobTypeSearchValue] = useState("");
	const [jobTypes, setJobTypes] = useState();

	const [updating, setUpdating] = useState(false);
	const [displayMode, setDisplayMode] = useState(
		localStorage.hasOwnProperty("dark") ? "dark" : "normal",
	);
	const [newLineage, setNewLineage] = useState(
		localStorage.getItem("lineageVersion") === "v2" ? "false" : "true",
	);
	const [receiveEmail, setReceiveEmail] = useState(isReceiveEmail);

	const isCancelledRef = useRef(false);

	const { sendAlert } = useAlert({
		isCancelledRef,
	});

	useEffect(() => {
		return () => {
			isCancelledRef.current = true;
		};
	}, []);

	const onDeleteTeam = () => {
		if (!selectedTeam) {
			return;
		}
		setUpdating(true);
		axiosCerebrum
			.delete(
				`/api/users/${sessionData.id}/related?object_id=${selectedTeam.id}&relationship=MEMBER_OF`,
			)
			.then((response) => {
				localStorage.setItem(
					"userGroupData",
					JSON.stringify(currentTeam.filter((el) => el.id !== selectedTeam.id)),
				);
				sendAlert({ message: "Team removed successfully", type: "info" });
				setModalOpen(false);
				setUpdating(false);
			})
			.catch((error) => {
				setUpdating(false);
				sendAlert({ message: "Error occurred removing Team", type: "error" });
				console.log(error);
			});
	};

	const saveTeam = () => {
		if (!selectedTeam) {
			return;
		}
		setUpdating(true);
		axiosCerebrum
			.put(
				`/api/users/${sessionData.id}/related?object_id=${selectedTeam.id}&relationship=MEMBER_OF`,
			)
			.then((response) => {
				localStorage.setItem(
					"userGroupData",
					JSON.stringify([...currentTeam, selectedTeam]),
				);
				sendAlert({ message: "Team added successfully", type: "info" });
				setModalOpen(false);
				setUpdating(false);
			})
			.catch((error) => {
				setUpdating(false);
				sendAlert({ message: "Error occurred adding Team", type: "error" });
				console.log(error);
			});
	};

	const loadJobTypes = () => {
		if (!sessionData) return;
		CerebrumLongListLoader({
			url: `/api/users/${sessionData.id}/related/collections`,
			params: {
				category: "PLATFORM",
				parent_name: "job type",
				relationship: "MEMBER_OF",
				sort: "ALPHABETICAL",
			},
			per_page: 50,
			onFinishLoad: (data) => {
				setJobTypes(data.data);
			},
		});
	};

	const onDeleteJobType = (el) => {
		axiosCerebrum
			.delete(`/api/collectioninstances/${el.id}/related`, {
				params: {
					object_id: sessionData.id,
					relationship: "MEMBERS",
				},
			})
			.then((response) => {
				setJobTypes(jobTypes.filter((item) => item.id !== el.id));
			})
			.catch((error) => {
				console.log(error);
				sendAlert({
					message: "Error occurred updating the job type, please try again",
					type: "error",
				});
			});
	};

	const onSaveJobType = (el) => {
		axiosCerebrum
			.put(`/api/collectioninstances/${el.id}/related`, undefined, {
				params: {
					object_id: sessionData.id,
					relationship: "MEMBERS",
				},
			})
			.then((response) => {
				setJobTypes([...(jobTypes || []), response.data]);
			})
			.catch((error) => {
				console.log(error);
				sendAlert({
					message: "Error occurred updating the job type, please try again",
					type: "error",
				});
			});
	};

	useEffect(() => {
		loadJobTypes();
		// eslint-disable-next-line
	}, []);

	const onChangeDisplayMode = (value) => {
		if (value === "normal") {
			localStorage.removeItem("dark");
		} else {
			localStorage.setItem("dark", "");
		}
		if (value !== displayMode) {
			setDisplayMode(value);
			window.location.reload();
		}
	};

	const onChangeReceiveEmail = (value) => {
		axiosCerebrum
			.put(`/api/me/settings/${receiveEmailSettingID}`, { value })
			.then((response) => {
				axiosCerebrum
					.get(`/api/me/settings`, { params: { per_page: 50 } })
					.then((response) => {
						localStorage.setItem("userSettings", JSON.stringify(response.data));
					})
					.catch((error) => {
						console.log(error);
					});
				setReceiveEmail(value);
			})
			.catch((error) => {
				console.log(error);
				sendAlert({
					message: "Error occurred updating the setting, please try again",
					type: "error",
				});
			});
	};

	const onChangeNewLineage = (value) => {
		if (value === "true") {
			localStorage.setItem("lineageVersion", "v3");
			setNewLineage("true");
		} else {
			localStorage.setItem("lineageVersion", "v2");
			setNewLineage("false");
		}
	};

	const getRoleTooltip = (role) => {
		if (role === "kada_business_user") {
			return `This role is for non-technical users like Marketing, Operations etc that frequently consume reports and analytical content`;
		}
		if (role === "kada_user") {
			return `This role is for technical data users like Data Analysts, Engineers, Scientists that frequently used data and analytical content`;
		}
		if (role === "kada_data_manager") {
			return `This role is for data platform and product managers that need access to reports and insights into the data ecosystem`;
		}
		if (role === "kada_data_gov_user") {
			return `This role is for data governance users that manage and govern data, analytical content, classifications, collections, glossaries and more`;
		}
		if (role === "Data Owner") {
			return `This role is for owners of data, content and collections. Owners have oversight of the use and lifecycle of these assets`;
		}
		if (role === "Data Steward") {
			return `This role is for stewards of data, content, and collections. Steward support the correct use and understanding of these assets`;
		}
		if (role === "kada_admin") {
			return `This role is for Administrators of this platform. Admins manage the metadata loads into K, integrations with 3rd party tools, and manage the team, batch and other configuration. `;
		}
		return undefined;
	};

	return (
		<div>
			<Typography className={classes.title}>MY DETAILS</Typography>

			<Typography className={classes.sectionHeader}>EMAIL</Typography>
			<div style={{ width: 560, marginBottom: 24 }}>
				<InputBase
					className={classes.inputBase}
					value={sessionData.email}
					disabled={true}
				/>
				<Typography
					style={{
						color: theme.palette.primaryText.light,
						fontSize: 11,
						marginLeft: 16,
						marginTop: 6,
					}}
				>
					Email is not editable. contact your K Administrator
				</Typography>
			</div>

			<Typography className={classes.sectionHeader}>ROLES</Typography>
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
					marginBottom: 24,
					width: 560,
				}}
			>
				{sessionData.user_role
					.sort()
					.filter((el) => getRoleTooltip(el))
					.map((el) => (
						<KTooltip
							title={getRoleTooltip(el)}
							classes={{ tooltip: classes.tooltip }}
						>
							<div className={classes.itemChip + " " + classes.targetItemChip}>
								{getIconComponent({
									label: "checked",
									size: 24,
									colour: theme.palette.background.main,
								})}
								<span>
									{el === "kada_user"
										? "Data User"
										: toTitleCase(el.split("_").slice(1).join(" "))}
								</span>
							</div>
						</KTooltip>
					))}
				{state.rolesData
					?.sort()
					.filter((el) => getRoleTooltip(el))
					.map((el) => (
						<KTooltip
							title={getRoleTooltip(el)}
							classes={{ tooltip: classes.tooltip }}
						>
							<div className={classes.itemChip + " " + classes.targetItemChip}>
								{getIconComponent({
									label: "checked",
									size: 24,
									colour: theme.palette.background.main,
								})}
								<span>{el}</span>
							</div>
						</KTooltip>
					))}
			</div>

			<Typography className={classes.sectionHeader} style={{ marginTop: 46 }}>
				TEAMS
			</Typography>
			<div style={{ width: 560 }}>
				<TeamSelector
					url={"/solr/search/select"}
					params={{
						q: getNameSearchQuery(searchValue),
						fq:
							(currentTeam.length > 0
								? `-id:(${currentTeam.map((el) => el.id).join(" OR ")}) AND `
								: "") + "object_type_srt:TEAM",
						fl: "name:name_txt,id,labels:object_type_txt",
						sort: "name_srt asc",
						rows: 10,
					}}
					hideTeamChip={true}
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					setGroup={(el) => {
						setSelectedTeam(el);
						setModalOpen({ add: true });
					}}
					autoSuggestion={true}
				/>
				<div
					style={{
						display: "flex",
						marginTop: -8,
						flexWrap: "wrap",
						width: 560,
					}}
				>
					{currentTeam.map((el) => (
						<div className={classes.itemChip + " " + classes.targetItemChip}>
							<Avatar align="center" className={classes.avatar}>
								{getInitials(el.name)}
							</Avatar>
							<Typography style={{ color: theme.palette.background.main }}>
								{el.name}
							</Typography>
							<IconButton
								className={classes.clearButton}
								onClick={() => {
									setSelectedTeam(el);
									setModalOpen({ delete: true });
								}}
							>
								{getIconComponent({
									label: "clear",
									size: 16,
									colour: theme.palette.background.main,
								})}
							</IconButton>
						</div>
					))}
				</div>
			</div>

			<Typography className={classes.sectionHeader} style={{ marginTop: 46 }}>
				JOB TYPE
			</Typography>
			<div style={{ width: 560 }}>
				<SearchSelector
					url={"/solr/search/select"}
					params={{
						q: `${jobTypeSearchValue}*`,
						fq: `object_type_srt:COLLECTION_INSTANCE AND parent_id_srt:${collectionIds.jobType} ${jobTypes?.length > 0 ? `AND -id:(${jobTypes.map((el) => el.id).join(" OR ")})` : ""}`,
						fl: "*",
						sort: "name_srt asc",
						rows: 10,
					}}
					hideTeamChip={true}
					searchValue={jobTypeSearchValue}
					setSearchValue={setJobTypeSearchValue}
					onResultClick={(el) => {
						onSaveJobType(el);
					}}
					placeholder={"Search for a Job Type"}
					autoSuggestion={true}
				/>
				<div
					style={{
						display: "flex",
						marginTop: 12,
						flexWrap: "wrap",
						width: 560,
					}}
				>
					{jobTypes?.map((el) => (
						<div className={classes.itemChip + " " + classes.targetItemChip}>
							{getIconComponent({
								label: "job_type",
								size: 24,
								colour: theme.palette.background.main,
							})}
							<Typography
								style={{ color: theme.palette.background.main, marginLeft: 8 }}
							>
								{el.name}
							</Typography>
							<IconButton
								className={classes.clearButton}
								onClick={() => {
									onDeleteJobType(el);
								}}
							>
								{getIconComponent({
									label: "clear",
									size: 16,
									colour: theme.palette.background.main,
								})}
							</IconButton>
						</div>
					))}
				</div>
			</div>

			<Typography className={classes.title} style={{ marginTop: 40 }}>
				PLATFORM SETTINGS
			</Typography>

			<div className={classes.sttingToggleContainer}>
				<Typography className={classes.settingToggleHeader}>
					Enable Dark Mode
				</Typography>
				<div style={{ display: "flex", alignItems: "center" }}>
					<Typography
						style={{
							fontSize: 13.75,
							marginRight: 8,
							color:
								displayMode === "normal"
									? theme.palette.primary.main
									: theme.palette.primaryText.light,
						}}
					>
						OFF
					</Typography>
					<Switch
						checked={displayMode === "dark"}
						onChange={() => {
							if (displayMode === "normal") onChangeDisplayMode("dark");
							else onChangeDisplayMode("normal");
						}}
						inputProps={{
							"data-test-id": "dark-mode-switch",
						}}
						classes={{
							icon: classes.switchIcon,
							bar:
								displayMode === "dark"
									? classes.switchBar
									: classes.disabledSwitchBar,
						}}
						value={displayMode}
						color="primary"
					/>
					<Typography
						style={{
							fontSize: 13.75,
							marginLeft: 8,
							color:
								displayMode === "dark"
									? theme.palette.primary.main
									: theme.palette.primaryText.light,
						}}
					>
						ON
					</Typography>
				</div>
			</div>

			<div className={classes.sttingToggleContainer}>
				<Typography className={classes.settingToggleHeader}>
					Enable Daily Briefing email
				</Typography>
				<div style={{ display: "flex", alignItems: "center" }}>
					<Typography
						style={{
							fontSize: 13.75,
							marginRight: 8,
							color:
								receiveEmail === "false"
									? theme.palette.primary.main
									: theme.palette.primaryText.light,
						}}
					>
						OFF
					</Typography>
					<Switch
						checked={receiveEmail === "true"}
						onChange={() => {
							if (receiveEmail === "true") onChangeReceiveEmail("false");
							else onChangeReceiveEmail("true");
						}}
						inputProps={{
							"data-test-id": "dark-mode-switch",
						}}
						classes={{
							icon: classes.switchIcon,
							bar:
								receiveEmail === "true"
									? classes.switchBar
									: classes.disabledSwitchBar,
						}}
						value={receiveEmail}
						color="primary"
					/>
					<Typography
						style={{
							fontSize: 13.75,
							marginLeft: 8,
							color:
								receiveEmail === "true"
									? theme.palette.primary.main
									: theme.palette.primaryText.light,
						}}
					>
						ON
					</Typography>
				</div>
			</div>

			<div className={classes.sttingToggleContainer}>
				<Typography className={classes.settingToggleHeader}>
					Enable New Lineage experience
				</Typography>
				<div style={{ display: "flex", alignItems: "center" }}>
					<Typography
						style={{
							fontSize: 13.75,
							marginRight: 8,
							color:
								newLineage === "false"
									? theme.palette.primary.main
									: theme.palette.primaryText.light,
						}}
					>
						OFF
					</Typography>
					<Switch
						checked={newLineage === "true"}
						onChange={() => {
							if (newLineage === "true") onChangeNewLineage("false");
							else onChangeNewLineage("true");
						}}
						inputProps={{
							"data-test-id": "dark-mode-switch",
						}}
						classes={{
							icon: classes.switchIcon,
							bar:
								newLineage === "true"
									? classes.switchBar
									: classes.disabledSwitchBar,
						}}
						value={newLineage}
						color="primary"
					/>
					<Typography
						style={{
							fontSize: 13.75,
							marginLeft: 8,
							color:
								newLineage === "true"
									? theme.palette.primary.main
									: theme.palette.primaryText.light,
						}}
					>
						ON
					</Typography>
				</div>
			</div>

			<Modal
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				disableBackdropClick={true}
			>
				<div
					style={{
						display: "flex",
						width: "100vw",
						height: "100vh",
						alignItems: "center",
						justifyContent: "center",
						outline: "none",
					}}
				>
					<Paper className={classes.modalPaper}>
						<Typography className={classes.modalTitle}>
							Are you sure?
						</Typography>
						<Typography className={classes.modalBody}>
							{modalOpen.delete &&
								`You are about to leave the ${selectedTeam ? selectedTeam.name : ""} team`}
							{modalOpen.add &&
								`You have chosen to join ${selectedTeam ? selectedTeam.name : ""}\n\nMake sure the Team is correct before proceeding.`}
						</Typography>
						<div className={classes.buttons}>
							<Button
								classes={{ disabled: classes.disabledButton }}
								color="primary"
								style={{ width: 96, marginRight: 8 }}
								onClick={modalOpen.delete ? onDeleteTeam : saveTeam}
								disabled={updating}
							>
								YES
							</Button>
							<Button
								classes={{ disabled: classes.disabledButton }}
								color="secondary"
								style={{ width: 96 }}
								onClick={() => setModalOpen(false)}
								disabled={updating}
							>
								CANCEL
							</Button>
						</div>
					</Paper>
				</div>
			</Modal>
		</div>
	);
};

Teams.propTypes = {
	classes: PropTypes.object.isRequired,
	nodeKey: PropTypes.string,
	label: PropTypes.string,
	userName: PropTypes.string,
};

export default withStyles(styles)(Teams);
