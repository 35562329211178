import React,{useState} from 'react';
import theme from '../../../theme';

import {  withStyles,  } from '@material-ui/core';
import { EdgeText, getEdgeCenter } from 'react-flow-renderer';

const styles = theme => ({
  clickableLine:{
    cursor:'pointer',
    transition: "stroke-width .3s ease",
    // '&:hover':{
    //   strokeWidth:5,
    //   opacity:0.8
    // },
  },
  [`@keyframes dash`]: {
    // from: {
    //   strokeDashoffset: 500
    // },
    to: {
      strokeDashoffset: -200
    }
  },
  animated:{
    strokeDasharray:8,
    animation: 'dash 5s linear',
    animationIterationCount: 'infinite',
  }
})

function LineageEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  selected,
  data,
  markerEnd,
  classes
}) {

  const [hovered, setHovered] = useState(false)

  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  })

  let d ;

  let start = {x:sourceX,y:sourceY}
  let end = {x:targetX-(data.noArrow?0:6),y:targetY}
  let middlePoint = {x:(start.x+end.x)/2,y:(start.y+end.y)/2+(data?.curveOffset||0)}

  if(Math.abs(sourceY-targetY)<10 && sourceX>=targetX){
    let xDistanceAbs = Math.max(Math.abs(start.x-end.x),300)
    d = `M${start.x},${start.y} `
    d += `C${start.x+xDistanceAbs/2},${middlePoint.y-Math.max(75,xDistanceAbs/5)} `
    d += `${end.x-xDistanceAbs/2},${middlePoint.y-Math.max(75,xDistanceAbs/5)} `
    d += `${end.x},${end.y}`
  }else{
    if(sourceX<targetX){
      let curveAnchorOffset = (start.x-middlePoint.x)/4;
      if(targetX-sourceX<230)curveAnchorOffset = 260;
      d = `M${start.x},${start.y} `
      d += `Q${middlePoint.x+curveAnchorOffset},${start.y} `
      d += `${middlePoint.x},${middlePoint.y} `
      d += `T${end.x},${end.y}`
    }else{
      let xDistanceAbs = Math.max(Math.abs(start.x-end.x),300)
      d = `M${start.x},${start.y} `
      d += `C${start.x+xDistanceAbs},${middlePoint.y} `
      d += `${end.x-xDistanceAbs},${middlePoint.y} `
      d += `${end.x},${end.y}`
    }
  }

  if(data.lineType==='step'){
    // if(Math.abs(start.y-end.y)<100){
    //   d = `M${start.x},${start.y} `
    //   d += `${end.x},${end.y}`
    // }else{
      d = `M${start.x},${start.y} `
      d += `${start.x + (end.x-start.x)/2},${start.y} `
      d += `${start.x + (end.x-start.x)/2},${end.y} `
      d += `${end.x},${end.y}`
    // } 
  }

  if(data.lineType==='straight'){
    d = `M${start.x},${start.y} `
    d += `${end.x},${end.y}`
  }
  
  return (
    <>
      {
        !data.noArrow &&
        <defs>
          <marker id={`markerArrow_${id}`} markerWidth="3" markerHeight="4" refX="0" refY="1.5" orient="auto">
            <polyline points="0 0, 2 1.5, 0 3" fill={data.colour || theme.palette.primaryText.light}/>
          </marker>
        </defs>
      }

      <path
        id={id}
        fill='transparent'
        d={d}
        // onClick={data.onClick}
        // onMouseEnter={data.onMouseEnter}
        // onMouseLeave={data.onMouseLeave}
        // onMouseDown={event=>event.stopPropagation()} 
        stroke={data.colour || theme.palette.primaryText.light}
        strokeDasharray={data.lineType==='dashed'?4:0}
        strokeWidth={ selected?5:(data.width || 4)}
        opacity={ data.faded?0.1: selected?0.8:(data.opacity || 0.5)}
        markerEnd={!data.noArrow && `url(#markerArrow_${id})`}
        className={(data.animated?classes.animated:'') + (data.onClick?' '+classes.clickableLine:'')}
        style={{
          ...(hovered?{strokeWidth:5,opacity:0.8}:{}),
          ...(data.freezed?{strokeDashoffset:0,animation:'none'}:{}),
        }}
      />


      <path
        id={id+' action-placeholder'}
        fill='transparent'
        d={d}
        onClick={event=>{
          if(data.disableHover){
            event.stopPropagation()
            event.preventDefault()
          }
          data.onClick?.()
        }}
        style={{cursor:'pointer'}}
        onMouseEnter={()=>{
          if(data.disableHover)return;
          setHovered(true)
          data.onMouseEnter && data.onMouseEnter()
        }}
        onMouseLeave={()=>{
          setHovered(false)
          data.onMouseLeave && data.onMouseLeave()
        }}
        onMouseDown={event=>event.stopPropagation()} 
        stroke={'transparent'}
        strokeWidth={26}
        opacity={0}
      />

      <EdgeText
        x={edgeCenterX}
        y={edgeCenterY}
        label={data.text}
        labelStyle={{ fill: data.colour || theme.palette.primaryText.light, fontSize:12 }}
        labelShowBg
        labelBgStyle={{ fill: theme.palette.background.main }}
        labelBgPadding={[2, 6]}
        labelBgBorderRadius={2}
      />
    </>
  );
}

export default withStyles(styles)(LineageEdge)