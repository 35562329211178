import React from 'react';
import { Typography, withStyles, withTheme} from '@material-ui/core';

const styles = theme => ({
  root: {
    display:'flex',
    overflow:'hidden',
    marginBottom:8
  },
  columnText:{
    fontSize:12,
    paddingRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    color:theme.palette.primary.main,
    minWidth:50,
    letterSpacing:1.5
  }
})

const ListColumn = props => {

  const {
    classes,
    isRef
  } = props;

  return (
    <div className={classes.root}>
      <div style={{width:24,height:24,flexGrow:0,flexShrink:0,marginLeft:16}}>
        
      </div>
      <Typography className={classes.columnText} style={{flexGrow:1,marginLeft:16}}>
        SOURCE NAME
      </Typography>
      <Typography className={classes.columnText} style={{flex:'0 1 150px'}}>
        TYPE
      </Typography>
      {
        !isRef && 
        <Typography className={classes.columnText} style={{flex:'0 1 150px'}}>
          INCLUDES
        </Typography>
      }
      <Typography className={classes.columnText} style={{flex:'0 1 190px'}}>
        LAST UPDATED
      </Typography>
    </div>
  )
}

export default withTheme()(withStyles(styles)(ListColumn));