import React from 'react';
import { withStyles} from '@material-ui/core';
import Jobs from '../Jobs/Jobs';
// import AssignedTimeLine from '../../MyEcosystem/Assigned/AssignedTimeLine';
// import BasicSearch from '../../../containers/BasicSearch/BasicSearch';
import Issues from '../Issues/Issues';
import Questions from '../Questions/Questions';
import { checkCanBulkUpdate, checkCanUploadBulkFile, checkIsAdmin, checkIsDataGov, checkIsDataManager } from '../../../permissionChecker';

const styles = theme => ({

})

function Body(props) {

  const {
    history,
    state,
    dispatch,
    sessionData,
    tabOptions
  } = props;

  let bodyContent
  switch (tabOptions[state.tabState]) {
    case 'EXTRACTS':
      const extratcsOptions = [
        {name:'ASK K', type:"EXTRACTS"},
        {name:'CODE SEARCH', type:"CODE_SEARCH"},
        (checkIsDataGov({sessionData}) || checkIsDataManager({sessionData}) || checkIsAdmin({sessionData}))?{name:'COLLECTION BULK EDIT FILE', type:"COLLECTION_BULK_EDIT_EXCEL"}:undefined,
        checkCanUploadBulkFile({sessionData})?{name:'BULK EDIT FILE', type:"BULK_EDIT_EXCEL"}:undefined,
        (checkIsAdmin({sessionData})?{name:'MANUAL SOURCE FILE', type:"BULK_EDIT_SOURCE_EXCEL"}:undefined),
        {name:'IMPACT ASSESSMENT', type:"IMPACT_ANALYSIS_DOWNSTREAM"},
        {name:'DEPENDENCY ASSESSMENT', type:"IMPACT_ANALYSIS_UPSTREAM"}
      ].filter(el=>el);
      bodyContent = (
        <Jobs
          key={'extract'}
          state={state}
          dispatch={dispatch}
          history={history}
          sessionData={sessionData}
          options={extratcsOptions}
          tabState={state.extractTabState}
          setTabState={value=>{
            dispatch({ type: 'set_extract_tab_state', extractTabState: value })
          }}
        />
      )
      break;
    case 'JOBS':
      const jobOptions = [
        (checkIsDataGov({sessionData}) || checkIsDataManager({sessionData}) || checkIsAdmin({sessionData}))?{name:'COLLECTION BULK EDIT', type:"COLLECTION_BULK_EDIT_EXCEL"}:undefined,
        checkCanUploadBulkFile({sessionData})?{name:'BULK EDIT VIA EXCEL', type:"BULK_EDIT_EXCEL"}:undefined,
        checkCanBulkUpdate({sessionData})?{name:'BULK UPDATE', type:"BULK_UPDATE"}:undefined,
        {name:'LINEAGE MAPPING', type:'LINEAGE_AUTOMATION'},
        (checkIsAdmin({sessionData}))?{name:'MANUAL SOURCE IMPORT', type:"BULK_EDIT_SOURCE_EXCEL"}:undefined
      ].filter(el=>el);
      bodyContent = (
        <Jobs
          key={'jobs'}
          state={state}
          dispatch={dispatch}
          history={history}
          sessionData={sessionData}
          options={jobOptions}
          tabState={state.jobTabState}
          setTabState={value=>{
            dispatch({ type: 'set_job_tab_state', jobTabState: value })
          }}
        />
      )
      break;
    case 'ISSUES':
      bodyContent = (
        // <AssignedTimeLine forceObject={'ISSUE'} headerTop={160} state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
        <Issues
          state={state}
          dispatch={dispatch}
          history={history}
          sessionData={sessionData}
        />
      )
      break;
    case 'QUESTIONS':
      bodyContent = (
        <Questions
          state={state}
          dispatch={dispatch}
          history={history}
          sessionData={sessionData}
        />
      )
      break;
    default:
  }

  return (
    bodyContent
  )
}

export default withStyles(styles)(Body);