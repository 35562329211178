import * as actionTypes from './actionTypes';

export const setBulkEditParam = (data) => {
  return {
    type: actionTypes.SET_BULK_EDIT_PARAM,
    data: data
  };
};

export const clearBulkEditParam = data => {
  return {
    type: actionTypes.CLEAR_BULK_EDIT_PARAM,
    data:data
  }
}

