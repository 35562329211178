import React, { useEffect, useReducer } from 'react';
import { withStyles } from '@material-ui/core';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/AddKnowledge/Body/Body'
import TabBar from '../../components/UI/TabBar/TabBar';
import { getUserRoles, removeUrlQueryArg } from '../../utilities';
import 'url-search-params-polyfill';
import { addHistory } from '../../HistoryManager';

const styles = theme => ({
});

const initialState = {
  tabState:0,
  surveyList:{},
  answeringQuestion:-1,
  hoveredQuestion:{},
  openedQuestion:{},
  extractFilters:{}
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState:action.tabState
      }
    case 'set_stats':
      return {
        ...state,
        statsData:action.statsData,
        statsLoading:action.statsLoading,
        statsError:action.statsError
      }
    case 'set_survey_count':
      return {
        ...state,
        surveyCount:action.surveyCount,
        surveyCountLoading:action.surveyCountLoading,
        surveyCountError:action.surveyCountError
      }
    case 'set_selected_survey_category':
      return {
        ...state,
        selectedSurveyCategory:action.selectedSurveyCategory
      }
    case 'set_survey_list':
      return {
        ...state,
        surveyList:action.surveyList
      }
    case 'set_answering_question':
      return {
        ...state,
        answeringQuestion:action.answeringQuestion
      }
    case 'set_hovered_question':
      return {
        ...state,
        hoveredQuestion:action.hoveredQuestion
      }
    case 'set_opened_question':
      return {
        ...state,
        openedQuestion:action.openedQuestion
      }
    case 'set_responses_data':
      return {
        ...state,
        responsesData:action.responsesData,
        responsesLoading:action.responsesLoading,
        responsesError:action.responsesError
      }
    case 'set_survey_templates':
      return {
        ...state,
        surveyTemplates:action.surveyTemplates,
        surveyTemplatesLoading:action.surveyTemplatesLoading,
        surveyTemplatesError:action.surveyTemplatesError
      }
    case 'set_extract_filters':
      return {
        ...state,
        extractFilters:action.extractFilters
      }
    case 'set_extract_valid_object_types':
      return {
        ...state,
        extractValidObjectTypes:action.extractValidObjectTypes
      }
    case 'set_extract_downloading':
      return {
        ...state,
        extractDownloading:action.extractDownloading
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

const AddKnowledge = props => {

  const {
    history,
    sessionData
  } = props;

  const [state, dispatch] = useReducer(
    reducer,
    {
      ...initialState,
      userID:sessionData.id,
    }
  )

  const roles = getUserRoles(sessionData.user_role)

  let tabOptions = ['KNOWLEDGE'];
  if(roles.find(el=>['10','40'].includes(el)))tabOptions.push('RESPONSES')
  useEffect(()=>{
    const urlSearch = new URLSearchParams(window.location.search);
    const tabName = urlSearch.get('tabName')
    if(tabOptions.indexOf(tabName)>=0)dispatch({type:'set_tab_state',tabState:tabOptions.indexOf(tabName)})
    window.history.replaceState(null, null,removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
    addHistory({url:window.location.pathname, iconLabel:'addknowledge', title: 'Knowledge', subTitle:'Knowledge Centre',type:'application'})
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(tabOptions[state.tabState]==='RESPONSES'){
      addHistory({url:window.location.pathname+`?tabName=RESPONSES`, iconLabel:'addknowledge', title: 'Responses', subTitle:'Knowledge Centre',type:'application'})
    }
    // eslint-disable-next-line
  },[state.tabState])


  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              label='addknowledge'
              title='Knowledge Centre'
              subtitle=' '
              buttons={[<div style={{height:48,margin:8}}></div>]}
              description=' '
            />
          </div>
        )}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
          />
        }
      />
    </div>)
}

export default withStyles(styles)(AddKnowledge);
