import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Stepper, Step, StepLabel, Typography, StepContent, Button} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import { getIconComponent } from '../../../utilities';
import fileDownload from 'js-file-download';
import SourceSelector from '../UIComponents/SourceSelector';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  stepper:{
    marginBottom:120,
    backgroundColor:theme.palette.background.main
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
  infoBox:theme.components.infoBox,
  infoHeader:theme.components.infoBoxHeader,
  infoDescription:theme.components.infoBoxDescription,
  focusTxt:theme.components.infoBoxFocusTxt,
  button:theme.components.infoBoxButtonContained,
  openMonitorButton:theme.components.infoBoxButtonOutlined,
})

const DownloadCollector = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    history
  } = props;
  
  const [activeStep, setActiveStep] = useState(0)
  const [selectedTemplate, setSelectedTemplate] = useState('none')

  const {
    sendAlert
  } = useAlert({})

  const steps = [
    `Select a source type`+(selectedTemplate==='none'?'':': '+selectedTemplate),
    'Download collector'
  ]

  window.onpopstate = () => {
    dispatch({type:'set_tab_state',tabState:0})
  }
  
  useEffect(()=>{
    history.push(`/admin/sources`)
    return () => {
      window.onpopstate = undefined;
    }
  // eslint-disable-next-line
  },[])

  const onChangeSourceType = value => {
    setSelectedTemplate(value)
    setActiveStep(1)
  }

  const getCollectorName = artefact => {
    return `${artefact.name} ${artefact.version}`
  }

  const onDownload = id => {
    sendAlert({type:'info',message:'Download will commence shortly'})
    axiosCerebrum
      .get(`/api/artefacts/${id}/download`,{responseType:"blob"})
      .then(response=>{
        let fileName = response.headers['content-disposition'].split('filename=')[1];
        fileDownload(response.data, fileName);
      })
      .catch(error=>{
        console.log(error)
        sendAlert({type:'error',message:'Error occurred downloading collector, please try again'})
      })
  }

  const getStepContent = index => {
    switch(index){
      case 0:
        return (
          <div style={{width:480}}>
            <SourceSelector
              onChangeSource={onChangeSourceType}
              sourceList={state.templates?.items?.filter(el=>el.artefacts?.length>0).filter(el=>el.type!=='INTERNAL' && el.type!=='EXTERNAL_INTEGRATION').sort((a,b)=>a.name<b.name?-1:1)}
            />
          </div>
        )
      case 1:
        if(selectedTemplate==='none')return;
        return (
          <>
            {
              state.templates?.items?.find(i=>i.name===selectedTemplate)?.artefacts?.filter(a=>a.id!==1).map(el=>(
                <div style={{width:640,display:'flex',alignItems:'center'}}>
                  <div style={{width:24,height:24,flexGrow:0,flexShrink:0,marginRight:16}}>
                    {getIconComponent({label:selectedTemplate,size:24,colour:theme.palette.primaryText.light,defaultLabel:'source'})}
                  </div>
                  <Typography style={{fontSize:16,marginRight:16,flexGrow:1,wordBreak:'break-all'}}> 
                     {getCollectorName(el)}
                  </Typography>
                  <Button color='primary' variant='contained' onClick={()=>onDownload(el.id)}>
                    DOWNLOAD
                  </Button>
                </div>
              ))
            }
          </>
        )
      default:
    }
  }

  const labelClickable = index => {
    if(index===0)return true;
    return selectedTemplate!=='none';
  }

  const onLabelClick = index => {
    if(labelClickable(index))setActiveStep(index)
  }

  // let knowledgeBaseUrl = getKnowledgeBaseUrl()
  let knowledgeBaseUrl = 'https://docs.kada.ai/home/collectors'

  return (
    <div className={classes.root}>
      <div className={classes.infoBox}>
        <Typography className={classes.infoHeader}>About Collectors</Typography>
        <div style={{display:'flex',alignItems:'flex-start'}}>
          <Typography className={classes.infoDescription}>
            Collectors are extractors that can are deployed and managed by you,
            {'\n\n'}
            Common reasons why some teams choose to load from files via a collector in lieu of a direct connect extractor:
            {'\n'}
            • Firewall restrictions prevent you from connecting to the KADA SaaS offering 
            {'\n'}
            • There is a preference to push metadata to KADA (and not for KADA to pull data) for Security reasons 
            {'\n\n'}
            KADA provides python libraries that you can use to quickly deploy a Collector. 
            {'\n\n'}
            Note: The latest core library needs to be installed prior to deploying a Collector. Click ‘DOWNLOAD CORE LIBRARY’ to access the latest core library. 
          </Typography>
          <div>
            {
              knowledgeBaseUrl && 
              <Button className={classes.button} style={{width:220,marginBottom:16}} onClick={()=>{window.open(knowledgeBaseUrl,'_blank')}}>
                GO TO KNOWLEDGE BASE
              </Button>
            }
            <Button className={classes.openMonitorButton} style={{width:220}} onClick={()=>{onDownload(1)}}>
              DOWNLOAD CORE LIBRARY
            </Button>
          </div>
        </div>
      </div>
      <Typography style={{fontSize:20,color:theme.palette.primaryText.main}}>COLLECTORS</Typography>
      <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper} >
        {steps.map((label, index) => (
          <Step key={index} >
            <StepLabel className={classes.step} style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}}>
              <Typography color='primary' style={{color:activeStep<index?theme.palette.primaryText.light:theme.palette.primaryText.main,fontSize:13.75,marginLeft:6}}>{label}</Typography> 
            </StepLabel>
            <StepContent style={{width:680,paddingTop:12,paddingLeft:25}}>
             {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

export default withTheme()(withStyles(styles)(DownloadCollector));