import React, {useState} from 'react';
import { withTheme, withStyles, Typography, InputBase, IconButton, Select, MenuItem} from '@material-ui/core';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { getIconComponent, getPartialMatchSearchString, toTitleCase, validateEmail, validateUrl } from '../../../../utilities';
import SearchSelector from '../../../UI/SearchSelector/SearchSelector';
import theme from '../../../../theme';
import KTooltip from '../../../UI/KTooltip/KTooltip';


const styles = theme => ({
  root: {
  },
  block:{
    marginBottom:32
  },
  title:{
    marginBottom:8,
    fontSize:12,
    letterSpacing:2
  },
  selector:{
    ...theme.components.selector,
    height:54,
    width:'100%',
    '& div':{
      paddingLeft:8,
    },
  },
  textField:{
    ...theme.components.inputBase,
    width:800,
    margin:0,
    '& input':{
      height:56,
      padding:0,
      paddingLeft:16,
    },
    '& div':{
      padding:0,
    },
    '& textarea':{
      padding:16,
      wordBreak:'break-word',
    },
    '& p':{
      margin:0,
      background:theme.palette.background.main,
      padding:'8px 12px 0',
      color:theme.palette.primaryText.light
    }
  },
  helperText:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginLeft:16,
    marginTop:4
  },
  normalText:{
    color:theme.palette.primaryText.light
  },
  errorBorder:{
    border:`1px solid ${theme.palette.error.main} !important`,
    borderRadius:3
  },
  errorText:{
    color:theme.palette.error.main
  },
  litsItem:{
    padding:'12px 16px',
    overflow:'hidden',
    textOverflow:'ellipsis',
    color:theme.palette.primaryText.main,
    whiteSpace:'nowrap',
    cursor:'pointer',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },  
  dateSelector:{
    ...theme.components.inputBase,
    height:48,
    '& div':{
      backgroundColor:'transparent',
    },
    '& label':{
      color:theme.palette.primaryText.light
    },
  },
  inputBase:{
    width:300,
    '& input':{
      color:theme.palette.primaryText.main,
      padding:'15px 16px 15px'
    }
  },
  chip:{
    overflow:'hidden',
    maxWidth:600,
    display:'flex',
    alignItems:'center',
    width:'max-content',
    height:32,
    borderRadius:16,    
    paddingRight:16,
    border:`1px solid ${theme.palette.border.main}`,
    background:theme.palette.chip.main,
    color:theme.palette.primaryText.main,
    fontSize:13.75
  },
  iconButton:{
    "&:hover":{
      background:`${theme.palette.hovered.main}40`
    }
  }
})

function AddDetails(props) {

  const {
    classes,
    state,
    dispatch
  } = props;

  const [searchValue, setSearchValue] = useState('')

  const onChangeTemplate = value => {
    dispatch({type:'set_selected_email_template',selectedEmailTemplate:value})
  }

  const onChangeName = name => {
    // dispatch({type:'set_notification_title',notificationTitle:name.slice(0,50)})
    dispatch({type:'set_notification_input',notificationInput:{...state.notificationInput,'title':name.slice(0,50)}})
  }

  const onChangeDescription = description => {
    // dispatch({type:'set_notification_description',notificationDescription:description.slice(0,500)})
    dispatch({type:'set_notification_input',notificationInput:{...state.notificationInput,'description':description.slice(0,500)}})
  }

  const onChangeImpact = impact => {
    dispatch({type:'set_notification_input',notificationInput:{...state.notificationInput,'impact':impact}})
  }

  const onChangeStatus = status => {
    dispatch({type:'set_notification_input',notificationInput:{...state.notificationInput,'status':status}})
  }

  const onChangeDate = value => {
    // dispatch({type:'set_notification_date',notificationDate:value})
    dispatch({type:'set_notification_input',notificationInput:{...state.notificationInput,'date':value}})
  }

  const onChangeLink = value => {
    dispatch({type:'set_notification_input',notificationInput:{...state.notificationInput,'link':value}})
  }
  
  const onChangeContact = value => {
    setSearchValue('');
    dispatch({type:'set_notification_input',notificationInput:{...state.notificationInput,'contact':value}})
  }

  const mapTemplateName = name => {
    switch(name){
      case 'CHANGE_EMAIL':
        return'change'
      case 'INCIDENT_EMAIL':
        return 'incident';
      default:
        return name.replace(/_/g,' ').toLowerCase()
    }
  }

  let templateName = '';
  if(state.selectedEmailTemplate!=='none'){
    templateName = mapTemplateName(state.emailTemplates.find(t=>t.id===state.selectedEmailTemplate).name)
  }

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <Typography style={{fontSize:20,color:theme.palette.header.main}}>NOTIFICATION DETAILS</Typography>
        <Typography  className={classes.normalText} style={{fontSize:12}}>Construct a notification to users of this object</Typography>
      </div>

      <div className={classes.block}>
        <Typography color='primary' className={classes.title}>
          IMPACT TYPE
        </Typography>
        <div style={{width:800}}>
          <Select 
            value={state.selectedEmailTemplate}
            onChange={(event)=>onChangeTemplate(event.target.value)}
            className={classes.selector}
            disableUnderline
          >
            <MenuItem value='none'>Select the notification template</MenuItem>
            {
              state.emailTemplates.map(el=>(
                <MenuItem value={el.id}>{toTitleCase(mapTemplateName(el.name))}</MenuItem>
              ))
            }
          </Select>
        </div>
        <Typography className={classes.helperText}>Required</Typography>
      </div>

      {
        state.selectedEmailTemplate!=='none' && 
        <div>
          <div className={classes.block}>
            <Typography color='primary' className={classes.title}>
              TITLE
            </Typography>
            <InputBase
              id="notification_name"
              variant="filled"
              className={classes.textField + (!state.notificationInput.title||state.notificationInput.title.trim()===''?' '+classes.errorBorder:'')}
              value={state.notificationInput.title}
              onChange={event=>onChangeName(event.target.value)}
              placeholder={`Add a title for this ${templateName}. It will be used in the email subject`}
              margin="normal"
            />
            <Typography className={classes.helperText}>Required. Max 50 char</Typography>
          </div>

          <div className={classes.block}>
            <Typography color='primary' className={classes.title}>
              DESCRIPTION
            </Typography>
            <InputBase
              id="notification_description"
              variant="filled"
              className={classes.textField  + (!state.notificationInput.description||state.notificationInput.description.trim()===''?' '+classes.errorBorder:'')}
              multiline
              rows={5}
              value={state.notificationInput.description}
              onChange={event=>onChangeDescription(event.target.value)}
              placeholder={`Add details about this ${templateName}`}
              margin="normal"
            />
            <Typography className={classes.helperText}>Required. Max 500 char</Typography>
          </div>

          <div className={classes.block}>
            <Typography color='primary' className={classes.title}>
              BUSINESS IMPACT
            </Typography>
            <InputBase
              id="notification_description"
              variant="filled"
              className={classes.textField  + (!state.notificationInput.impact||state.notificationInput.impact.trim()===''?' '+classes.errorBorder:'')}
              multiline
              rows={3}
              value={state.notificationInput.impact}
              onChange={event=>onChangeImpact(event.target.value)}
              placeholder={`Describe how the ${templateName} could impact a business user or process `}
              margin="normal"
            />
            <Typography className={classes.helperText}>Required</Typography>
          </div>

          <div className={classes.block}>
            <Typography color='primary' className={classes.title}>
              STATUS
            </Typography>
            <InputBase
              id="notification_description"
              variant="filled"
              className={classes.textField}
              value={state.notificationInput.status}
              onChange={event=>onChangeStatus(event.target.value)}
              placeholder={`Add the status of the ${templateName}. `}
              margin="normal"
            />
            <Typography className={classes.helperText}>Optional</Typography>
          </div>

          <div className={classes.block}>
            <Typography color='primary' className={classes.title}>
              DATE
            </Typography>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                className={classes.dateSelector + (!state.notificationInput.date?' '+classes.errorBorder:'')}
                style={{margin:0}}
                format='ll'
                value={state.notificationInput.date || null}
                placeholder={
                  templateName==='incident'?
                  "Select when the incident occurred"
                  :
                  "Select a change date"
                }
                onChange={value=>onChangeDate(value)}
                InputProps={{disableUnderline:true,className:classes.inputBase}}
                clearable
                variant="filled"
              />
            </MuiPickersUtilsProvider>
            <Typography className={classes.helperText}>Required</Typography>
          </div>

          <div className={classes.block}>
            <Typography color='primary' className={classes.title}>
              LINK
            </Typography>
            <InputBase
              id="notification_description"
              variant="filled"
              className={classes.textField + (state.notificationInput.link && state.notificationInput.link.trim()!=='' && validateUrl(state.notificationInput.link)===false?' '+classes.errorBorder:'')}
              value={state.notificationInput.link}
              onChange={event=>onChangeLink(event.target.value)}
              placeholder='Add a link to any additional documentation'
              margin="normal"
            />
            <Typography className={classes.helperText + (state.notificationInput.link && state.notificationInput.link.trim()!=='' && validateUrl(state.notificationInput.link)===false?' '+classes.errorText:'')}>
              {
                state.notificationInput.link && state.notificationInput.link.trim()!=='' && validateUrl(state.notificationInput.link)===false?
                'Invalid URL - must begin with http:// or https://'
                :
                `Optional - must begin with http:// or https://`
              }
              
            </Typography>
          </div>

          <div className={classes.block}>
            <Typography color='primary' className={classes.title}>
              CONTACT
            </Typography>
            <div style={{width:800}}>
              <div
                className={searchValue.trim()==='' && !state.notificationInput.contact?classes.errorBorder:''}
              >
                <SearchSelector
                  url={'/solr/search/select'}
                  params={{
                    q:getPartialMatchSearchString(searchValue),
                    fq:`object_type_srt:USER`,
                    fl:'*',
                    qf:'name_srt'
                  }}
                  clearable
                  height={54}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  placeholder={`Select a contact for this ${templateName} or enter an email`}
                  hideBorder={searchValue.trim()==='' && !state.notificationInput.contact}
                  renderResults={list => {
                    let suggestion = [];
                    if(list.length===0){
                      if(validateEmail(searchValue)){
                        suggestion.push(
                          <div className={classes.litsItem} style={{borderBottom:'none'}} onClick={()=>{onChangeContact(searchValue)}}>
                            Add an email: {searchValue}
                          </div>
                        )
                      }else{
                        suggestion = [<Typography style={{fontSize:13.75,marginLeft:16,pointerEvents:"none"}}>No suggestion found</Typography>]
                      }
                    }else{
                      list.forEach((l,index)=>{
                        suggestion.push(
                          <div className={classes.litsItem} style={{borderBottom:list.length===index+1?'none':undefined}} onClick={()=>{onChangeContact(l.name_txt)}}>
                            {l.name_txt}
                          </div>
                        )
                      })
                    }
                    return suggestion
                  }}
                />
              </div>

              <Typography className={classes.helperText}>Required</Typography>

              <Typography style={{color:theme.palette.primaryText.main,fontSize:12,letterSpacing:2,marginBottom:8,marginTop:12}}>CONTACT OR EMAIL ADDED</Typography>
              {
                state.notificationInput.contact?
                <div className={classes.chip} style={{paddingLeft:12,paddingRight:4,background:theme.palette.primary.dark,border:`1px solid ${theme.palette.primary.dark}`}}>
                  <KTooltip title={state.notificationInput.contact}>
                    <Typography style={{overflow:'hidden',textOverflow:'ellipsis',whiteSpace:"nowrap",color:theme.palette.background.main,fontSize:13.75,marginRight:4}}>
                      {state.notificationInput.contact}
                    </Typography>
                  </KTooltip>
                  <IconButton className={classes.iconButton} style={{padding:2,marginLeft:8}} onClick={()=>onChangeContact()}>
                    {getIconComponent({label:'clear',size:20,colour:theme.palette.background.main})}
                  </IconButton>
                </div>
                :
                <div className={classes.chip}>
                  <div style={{height:24,width:24,marginRight:8,marginLeft:4}}>{getIconComponent({label:'help_contained',size:24,colour:theme.palette.primaryText.light})}</div>
                  None
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  )
}

AddDetails.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(AddDetails));

