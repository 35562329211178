import React from 'react';
import { withTheme, withStyles, Paper, ClickAwayListener, Typography, CircularProgress, Divider } from '@material-ui/core';
import { getDispFields, getIconComponent } from '../../../utilities'
// import { useStore } from 'react-redux'
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem'
import { getIconLabel } from '../../UI/SearchResults/utils';

const styles = theme => ({
  suggestionPaper:{
    border:`1px solid ${theme.palette.border.main}`,
    padding:'16px 0',
    minHeight:130,
    maxHeight:'70vh',
    overflow:'auto',
    '&::-webkit-scrollbar':{
      width: '0px'
    },
  },
  title:{
    letterSpacing:2,
    color:theme.palette.header.main,
    marginBottom:16,
    paddingLeft:16
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  suggestionChipContainer:{
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  suggestionChip:{
    display:'flex',
    overflow:'hidden',
    alignItems:'flex-end',
    padding:'12px 16px',
  },
  suggestionChipMainText:{
    fontSize:16,
    color:theme.palette.header.main,
    paddingLeft:16,
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
    marginRight:16,
    marginBottom:-2
  },
  suggestionChipSubText:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
  },
  sectionHedaer:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    paddingLeft:16,
    letterSpacing:2,
    marginBottom:8
  }
});



const SearchBar = props => {

  const {
    classes,
    theme,
    loading,
    error,
    suggestionList,
    setPopperOpen,
    onClickSuggestion,
    isHistory,
  } = props;

  const generateSubText = obj => {
    if(obj.location_txt){
      return `in ${obj.location_txt}`
    }
    if(obj.object_type_txt==='COLLECTION_INSTANCE'){
      return `in ${obj.collection_txt}`
    }
    if(obj.object_type_txt==='USER' && obj.teams_txts){
      return `in ${obj.teams_txts.join(', ')}`
    }
  }

  const getResultChip = ({label,mainText,subText,obj,isHistory}) => {
      return (
        <div className={classes.suggestionChipContainer}>
          <div className={classes.suggestionChip} style={{alignItems:'flex-start'}} onClick={()=>onClickSuggestion(obj,isHistory)}>
            {getIconComponent({label:label,size:24,colour:theme.palette.primary.main})}
            <div style={{overflow:'hidden'}}>
              <Typography className={classes.suggestionChipMainText}>{mainText}</Typography>
              <Typography className={classes.suggestionChipSubText} style={{direction:'rtl',textAlign:'left',paddingLeft:16,marginTop:4}}>{subText}</Typography>
            </div>
          </div>
          <Divider style={{marginLeft:16,background:theme.palette.listItemDivider.main}}/>
        </div>
      )
  }

  return(
    <ClickAwayListener onClickAway={()=>setPopperOpen(false)}>
      <Paper className={classes.suggestionPaper}>
        <Typography className={classes.title}>{isHistory?'SEARCH HISTORY':'SUGGESTIONS'}</Typography>
        {
          loading && <div style={{textAlign:'center'}}><CircularProgress color='secondary'/></div>
        }
        {
          error && 
          <Typography className={classes.normalText} style={{marginLeft:16}}>
            Error occurred loading {isHistory?'history':'suggestions'}
          </Typography>
        }
        {
          !isHistory && suggestionList && suggestionList.slice(0,3).map(g=>
            <div style={{marginBottom:24}}>
              {
                g.groupValue &&
                <Typography className={classes.sectionHedaer}>{g.groupValue.replace(/_/g,' ')}(S)</Typography>
              }
              {
                g.doclist.docs.map(el=>(
                  <div className={classes.suggestionChipContainer}>
                    <div style={{padding:'0 16px'}}>
                      <SimpleResultItem
                        title={getDispFields(el,'dispTitle')}
                        label={el.object_type_txt}
                        iconLabel={['COLLECTION','COLLECTION_INSTANCE'].includes(el.object_type_txt)?getIconLabel({label:el.object_type_txt,item:el}):getIconComponent({label:el.source_type_txt})?el.source_type_txt:el.object_type_txt}
                        item={el}
                        subTitle={generateSubText(el)}
                        rightText={el.trust_srt}
                        removeHorizontalPadding={true}
                        onClick={()=>onClickSuggestion(el,isHistory)}
                      />
                    </div>
                    <Divider style={{marginLeft:16,background:theme.palette.listItemDivider.main}}/>
                  </div>
                ))
              }
            </div>
          )
        }
        {
          isHistory && suggestionList && suggestionList.map(el=>getResultChip({
            label:'time',
            mainText:el.terms,
            obj:el,
            isHistory:true
          }))
        }
        {
          suggestionList && suggestionList.length===0 &&
          <Typography className={classes.normalText} style={{paddingTop:16,marginLeft:16}}>No {isHistory?'history':'suggestions'} found</Typography>
        }
      </Paper>
    </ClickAwayListener>
  )
}

export default withTheme()(withStyles(styles)(SearchBar));
