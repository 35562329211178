import React, {  } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { formatNumber } from '../../../utilities';

const styles = theme => ({
})


function TrendChart(props) {
  const {
    classes,
    theme,
    data,
  } = props;
  
  let labels = data[0].x
  
  let series = [
    {
      name: 'Average score',
      data: data[0].y,
      type:'line',
    },
  ]

  let options = {
      chart: {
        toolbar:{
          show:false
        },
        zoom: {
          enabled: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: 'true',
        curve: 'smooth',
        width: 3
      },
      labels,
      colors:[theme.palette.primary.main],
      xaxis: {
        type: 'datetime',
        labels:{
          style:{
            colors:theme.palette.primaryText.main
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function(val) {
            if(!val)return val;
            return formatNumber(val.toFixed(0),true)
          },
          style:{
            colors:theme.palette.primaryText.main
          }
        },
        max:100,
        min:0,
        tickAmount: 5,
        axisTicks:{
          show:true,
        },
        axisBorder: {
          show: true,
        },
      },
      legend: {
        show:false,
      },
      markers: {
        size: 3,
        style: 'full',
        hover:{
          size:3
        }
      },
      states:{
        active: {
          filter: {
            type: 'none',
            value: 0,
          }
        },
      },
    }

  // if(data[0].y.every(el=>el===0)){
  //   return <Typography>No DQ score recorded</Typography>
  // }
  
  return (
    <div className={classes.root}>
      <ReactApexChart options={options} series={series} height='250' type="line"/>
    </div>
  );
}

TrendChart.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(TrendChart));