import React, {useEffect, useRef,} from 'react';
import { withTheme, CircularProgress, Typography, withStyles} from '@material-ui/core';
import axiosSolr from '../../../axios-solr'
import ListItem from '../ListItem/ListItem';
import ColumnHeader from '../ColumnHeader/ColumnHeader';
import { isInViewport } from '../../../utilities';

const styles = theme => ({
  root: {

  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
    marginBottom:16,
    textTransform:'uppercase'
  },
  seeMoreButton:{
    marginTop:8
  },
  selector:{
    ...theme.components.titleSelector,
    marginBottom:24
  }
})

const Reports = props => {

  const {
    classes,
    theme,
    history,
    state,
    dispatch,
  } = props;
  
  const loadingRef = useRef()

  const loadReportsData = ({view = state.reportsView, start = 0}) => {
    
    let promise;
    if(view==='new'){
      promise = axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:REPORT AND active_srt:YES`,
              rows:10,
              start,
              sort:'created_at_srt desc'
            }
          }
        )
    }
    if(view==='trending'){
      promise = axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`trend_srt:INCREASING AND object_type_srt:REPORT AND active_srt:YES`,
              rows:10,
              start,
            }
          }
        )
    }
    if(!promise)return;

    dispatch({type:'set_reports_data',reportsData:state.reportsData,reportsLoading:true})
    promise
      .then(response=>{
        let data = response.data.response
        let reportsData = {
          ...(state.reportsData || {}),
          [view]:{
            ...data,
            docs:[...(state.reportsData?.[view]?.docs||[]),...data.docs],
          }
        }
        dispatch({
          type:'set_reports_data',
          reportsData,
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_reports_data',
          reportsData:state.reportsData,
          reportsError:true
        })
      })
  }

  useEffect(()=>{
    if(!state.reportsData || !state.reportsData[state.reportsView]){
      loadReportsData({view:state.reportsView})
    }
  // eslint-disable-next-line
  },[state.reportsView])
  
  const shouldLoadMore = () => {
    if(!state.reportsData || !state.reportsData[state.reportsView])return false;
    if(state.reportsData[state.reportsView].numFound <= state.reportsData[state.reportsView].docs.length)return false;
    if(state.reportsLoading)return false;
    if(!isInViewport(loadingRef))return false;
    return true;
  }

  window.onscroll = () => {
    if (shouldLoadMore()) {
      loadReportsData({view:state.reportsView,start:state.reportsData[state.reportsView].docs.length})
    }
  }

  useEffect(()=>{
    if (shouldLoadMore()) {
      loadReportsData({view:state.reportsView,start:state.reportsData[state.reportsView].docs.length})
    }
  // eslint-disable-next-line
  },[state.reportsData])
  
  const getItem = item => {
    return (
      <ListItem
        item={item}
        label={'report'}
        history={history}
        key={item.id}
      />
    )
  }

  let data = state.reportsData?.[state.reportsView] // view: one of ['new','trending']

  return (
    <div className={classes.root}>
    <div style={{paddingBottom:24,background:theme.palette.background.main,position:'sticky',top:180,zIndex:9}}>
      <Typography style={{fontSize:20,color:theme.palette.header.main}}>NEW REPORTS</Typography>
      <Typography style={{color:theme.palette.primaryText.light,fontSize:12}}>New reports added. Sorted by most recently created.</Typography> 
    </div>
      {
        data && data.docs.length>0 && 
        <div>
          <ColumnHeader label={'report'}/>
          {
            data.docs.map(getItem)
          }
        </div>
      }
      <div ref={loadingRef} style={{marginTop:8,display:'flex',justifyContent:'center'}}>
        {
          state.reportsLoading && 
          <CircularProgress color='secondary'/>
        }
      </div>
      {
        state.reportsError && 
        <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading reports</Typography>
      }
      {
        data && data.numFound===0 && 
        <Typography style={{color:theme.palette.primaryText.main}}>No reports found</Typography>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(Reports));