import React from 'react';
import { withStyles} from '@material-ui/core';
import Capabilities from '../Capbilities/Capabilities'
import Integration from '../Integrations/Integrations'
import CreateIntegration from '../CreateIntegration/CreateIntegration'
import CreateEmailIntegration from '../CreateIntegration/CreateEmailIntegration';
import CreateGenAI from '../CreateIntegration/CreateGenAI';

const styles = theme => ({
  root: {
    // maxWidth:800,
    marginTop:24
  }
})

const Body = props => {

  const {
    classes,
    state,
    dispatch,
    capabilityLabelMap,
    emailIntegration,
    hasEmail,
    hasGenAI,
    emailSettings,
    sessionData,
    history
  } = props;

  let bodyContent;
  switch (state.tabState) {
    case 0:
      bodyContent =<Capabilities state={state} dispatch={dispatch} capabilityLabelMap={capabilityLabelMap}/>
      break;
    case 1:
      bodyContent = (
        <Integration 
          state={state} 
          dispatch={dispatch} 
          history={history}
          capabilityLabelMap={capabilityLabelMap}
          emailIntegration={emailIntegration}
          hasEmail={hasEmail}
          hasGenAI={hasGenAI}
          emailSettings={emailSettings}
          sessionData={sessionData}
        />
      )
      break;
    case 2:
      if(state.viewedCapability==='EMAIL'){
        bodyContent = (
          <CreateEmailIntegration 
            emailSettings={emailSettings}
            state={state} 
            dispatch={dispatch} 
            hasEmail={hasEmail}
          />
        )
      }else if(state.viewedCapability==='GENERATIVE AI'){
        bodyContent = (
          <CreateGenAI
            state={state} 
            dispatch={dispatch} 
            hasGenAI={hasGenAI}
          />
        )
      }else{
        bodyContent = <CreateIntegration state={state} dispatch={dispatch} editedIntegration={state.editedIntegration}/>
      }
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}


export default withStyles(styles)(Body);