import React, { useReducer,useEffect } from 'react';
import { withTheme, withStyles, Typography, LinearProgress} from '@material-ui/core';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/KPlatformSetup/Body/Body';
import useGetCerebrum from '../../hooks/useGetCerebrum'
// import { addHistory } from '../../HistoryManager';
import 'url-search-params-polyfill';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import { useStore } from 'react-redux'
import { getUserRoles } from '../../utilities'
import { checkEmailConfigured, setPlatformSettings } from '../../permissionChecker';
import { addHistory } from '../../HistoryManager';
import { adminPageInfo } from '../NavBar/utils';

const styles = theme => ({
  normalText: {
    color: theme.palette.primaryText.main,
  }
})

const initialState = {
  tabState:0,
}

function reducer(state, action) {
  switch (action.type) {
    case 'set_settings_data':
      return {
        ...state,
        settingsData: action.settingsData,
        settingsError: action.settingsError,
        settingsLoading: action.settingsLoading
      }
    case 'set_jobs_data':
      return {
        ...state,
        jobsData: action.jobsData,
        jobsError: action.jobsError,
        jobsLoading: action.jobsLoading
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

function LineageExplorer(props) {
  const {
    classes,
    history,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);


  const store = useStore();
  let sessionData = store.getState().auth.session_user;
  let roles = getUserRoles(sessionData.user_role)

  const {
    data: settingsData,
    loading: settingsLoading,
    error: settingsError,
    fetchList: settingsFetchList
  } = useGetCerebrum({
    url: `/api/settings`,
    params:{
      per_page:100
    },
  });

  useEffect(() => {
    if(settingsData){
      setPlatformSettings(settingsData.items)
    }
    dispatch({
      type: 'set_settings_data',
      settingsData: settingsData ? settingsData.items : undefined,
      settingsError: settingsError,
      settingsLoading: settingsLoading
    })
  }, [settingsData, settingsError, settingsLoading])

  const {
    data: jobsData,
    loading: jobsLoading,
    error: jobsError
  } = useGetCerebrum({
    url: `/api/platformbatches`,
    params:{
      per_page:50,
      internal_flag:false,
      sort:'ALPHABETICAL'
    }
  });

  useEffect(() => {
    dispatch({
      type: 'set_jobs_data',
      jobsData: jobsData ? jobsData.items : undefined,
      jobsError: jobsError,
      jobsLoading: jobsLoading
    })
  }, [jobsData, jobsError, jobsLoading])

  useEffect(()=>{
    addHistory({
      url:window.location.pathname, 
      iconLabel:adminPageInfo.customisation.icon, 
      title: adminPageInfo.customisation.name, 
      subTitle:adminPageInfo.customisation.description,
      type:'application',
    })
  },[])

  if (state.settingsLoading || state.jobsLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.settingsError || state.jobsError ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.settingsData ||  !state.jobsData){
    return <div></div>
  }

  let isDataGovOnly = roles.find(r=>r==='40') && !roles.find(r=>['10','00'].includes(r))

  let bannerdisplayText, bannerCause;
  if(!checkEmailConfigured() && !isDataGovOnly){
    bannerdisplayText = 'Email is not configured. Notifications from the platform will not be delivered.';
    bannerCause = 'emailNotConfigured';
  }

  return (
    <div className={classes.root}>
      <ProfileLayout
        noPadding
        // disableHeaderAutoHeight={true}
        header={(
          <ProfileHeader
            label='customisation'
            title={isDataGovOnly?'Data Governance Settings':'Customisation'}
            description={isDataGovOnly?'Configure the Governance setup of your K platform':'Complete the setup of your K platform'}
            bannerdisplayText={bannerdisplayText}
            bannerCause={bannerCause}
          />
        )}
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
            settingsFetchList={settingsFetchList}
          />
        }
      > 
      </ProfileLayout>
    </div>
  )

}

export default withTheme()(withStyles(styles)(LineageExplorer));