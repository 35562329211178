import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  //logged_in: false,
  session_user: null,
  sessionData: null,
  logoutLoading: false,
  keycloak: undefined,
  permissions:[]
};


const loginSuccess = (state, action) => {
  return updateObject(state, {
    //logged_in: true,
    session_user: {
      ...action.data.user,
      user_name: action.data.user.name,
      user_role: action.data.user.realm_access?action.data.user.realm_access.roles:[],
    },
    sessionData: action.data,
    keycloak: action.data.keycloak
  })
}

const logoutSuccess = (state, action) => {
  // state.keycloak && state.keycloak.logout();
  // state.keycloak && window.open(state.keycloak.createLogoutUrl(),"_self");
  // localStorage.clear();

  return {}
};



const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_AUTH_SUCCESS: return loginSuccess(state, action);
    case actionTypes.LOGOUT_SUCCESS: return logoutSuccess(state, action);
    default: return state;
  }
  
};

export default reducer;