import React, {useEffect} from 'react';
import { withTheme, Typography, withStyles, Select, MenuItem, CircularProgress} from '@material-ui/core';
import axiosSolr from '../../../axios-solr'

const styles = theme => ({
  root: {
    
  },
  selector:{
    ...theme.components.selector,
    width:'100%'
  }
})

const SelectTargetType = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
  } = props;

  const getObjectTypes = () => {
    dispatch({type:'set_valid_object_types',validObjectTypesLoading:true})
    axiosSolr
      .get(
        `/solr/search/select`,
        {params:{
          q:"*",
          rows:0,
          'json.facet':{
            'objects':{
              type:"terms",
              field:'object_type_srt',
              mincount:1
            }
          }
        }}
      )
      .then(response=>{
        if(response.data.facets.objects){
          let buckets = response.data.facets.objects.buckets;
          if(!buckets)return;
          let objectArr = buckets.map(el=>el.val)
          dispatch({type:'set_valid_object_types',validObjectTypes:objectArr})
        }
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_valid_object_types',validObjectTypesError:true})
      })
  }


  useEffect(()=>{
    if(!state.validObjectTypes && !state.validObjectTypesLoading)getObjectTypes()
    // eslint-disable-next-line
  },[])

  const onChangeType = type => {
    if(type!=='none')dispatch({type:'set_merge_tab_state',mergeTabState:1})
    if(type===state.mergeTargetType)return;
    dispatch({type:'set_merge_target_type',mergeTargetType:type})
    dispatch({type:'set_merge_target'})
    dispatch({type:'set_merged_items'})
  }

  return (
    <div className={classes.root}>
      {
        state.validObjectTypesLoading && <CircularProgress color='secondary'/>
      }
      {
        state.validObjectTypesError && <Typography style={{color:theme.palette.primaryText.main}}>Erorr occurred loading types</Typography>
      }
      {
        state.validObjectTypes &&
        <div>
          <Select
            value={state.mergeTargetType || 'none'}
            onChange={(event)=>{onChangeType(event.target.value)}}
            className={classes.selector}
            disableUnderline
          >
            <MenuItem value={'none'}>Select a Target Type</MenuItem>
            {
              state.validObjectTypes.filter(el=>el==='USER').map(el=>(
                <MenuItem value={el}>
                  {el.replace(/_/g,' ')}
                </MenuItem>
              ))
            }
          </Select>
          <Typography style={{color:theme.palette.primaryText.light,fontSize:12,paddingLeft:12,marginTop:4}}>Required</Typography>
        </div>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(SelectTargetType));