import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, StepContent, Typography, Modal, Select, MenuItem, Button, CircularProgress} from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ProfileLayout from '../../UI/ProfileLayoutNew/ProfileLayoutNew'
import ProfileButton from '../../UI/Buttons/ProfileButton';
import axiosCerebrum from '../../../axios-cerebrum'
import axiosSolr from '../../../axios-solr'
import { getIconComponent } from '../../../utilities';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import KTooltip from '../../UI/KTooltip/KTooltip';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {
    paddingRight:40,
    flexGrow:1
  },
  chartBox:{
    padding:8,
    display:'flex',
    alignItems:'flex-start',
    border:`1px solid ${theme.palette.primary.dark}`,
    borderRadius:3,
    width:260,
    overflow:'hidden',
    height:46
  },
  overflowText:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    lineHeight:1
  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:8
  },
  step: {
    width:700,
  },
  stepLabel:{
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
  stepper:{
    backgroundColor:theme.palette.background.main
  },
  selector:{
    ...theme.components.selector,
    width:'100%',
    height:48
  },
  helperText:{
    paddingLeft:8,
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginTop:2
  },
  banner:{
    width:'max-content',
    background:theme.palette.chip.main,
    border:`1px solid ${theme.palette.border.main}`,
    borderRadius:3,
    padding:'8px 12px',
    display:'flex',
    alignItems:'center'
  },
  bannerText:{
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    marginLeft:8
  },
  sectionHeader:{
    color:theme.palette.header.main,
    fontSize:16,
    marginTop:24,
    marginBottom:8
  },
  mergeButton:{
    background:theme.palette.success.main,
    color:'#000',
    '&:hover':{
      background:theme.palette.success.main,
    },
  },
  suggestionChip:{
    ...theme.components.suggestionChip,
    width:'max-content',
    maxWidth:'100%'
  }
})

function AddMergeModal(props) {

  const {
    classes,
    theme,
    history,
    state,
    modalOpen,
    setModalOpen, 
    onFinish,
    dispatch
  } = props;

  const candidate = modalOpen.candidate;
  const [step, setStep] = useState(0)
  const [source, setSource] = useState('none')
  const [database, setDatabase] = useState('none')

  const [sourcesList, setSourcesList] = useState()
  const [databaseList, setDatabaseList] = useState()

  const [merging, setMerging] = useState()
  const [errorMsg, setErrorMsg] = useState('')

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef,
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  const steps = [
    'Select a Target Source',
    'Select a Target Database',
    'Confirm Merge details'
  ]

  const labelClickable = index => {
    if(index===0)return true;
    if(index===1)return source!=='none';
    if(index===2)return source!=='none' && database!=='none';
  }

  const onLabelClick = (index) => {
    if(labelClickable(index))setStep(index)
  } 

  window.onpopstate = () => {
    setModalOpen(false)
  }
  
  useEffect(()=>{
    history.push(`/admin/sources`)
    return () => {
      window.onpopstate = ()=>dispatch({type:'set_tab_state',tabState:0})
    }
  // eslint-disable-next-line
  },[])

  const getStepLabelText = index => {
    if(step<=index)return steps[index]
    switch(index){
      case 0:
        return steps[index] + `: ${sourcesList.find(s=>s.id===source).name}`;
      case 1:
        return steps[index] + `: ${databaseList.find(s=>s.id===database).name_txt}`;
      default:
        return steps[index]
    }
  }

  const loadDatabaseList = (sourceid) => {
    const load = ({prevData=[]}) => {
      axiosSolr
        .get(`/solr/search/select`,{params:{
          q:"*",
          fq:`source_id_srt:${sourceid} AND object_type_srt:DATABASE`,
          fl:"*",
          rows:100,
          start:prevData.length
        }})
        .then(response=>{
          let data = [...prevData,...response.data.response.docs]
          if(data.length>=response.data.response.numFound){
            setDatabaseList(data)
          }else{
            load({prevData:data})
          }
        })
        .catch(error=>{
          console.log(error)
          setDatabaseList([])
        })
    };

    load({})
  }

  const onChangeSource = id => {
    if(id===source)return;
    setSource(id);
    setDatabase('none')
    if(id!=='none'){
      setDatabaseList();
      setStep(1)
      loadDatabaseList(id)
    }
  }


  const onChangeDatabase = id => {
    setDatabase(id)
    if(id!=='none'){
      setStep(2)
    }
  } 

  const onClose = () => {
    setModalOpen(false)
    setDatabase('none')
    setSource('none')
    setDatabaseList()
    setStep(0)
  }


  const getSuggestion = () => {
    if(!databaseList)return;
    if(databaseList.length>0){
      let sameNameDB = databaseList.filter(d=>d.name_txt.toLowerCase()===candidate.name.toLowerCase())
      if(sameNameDB.length===0)return <></>
      else{
        return (
          <div style={{marginTop:12}}>
            <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:6}}>Matching source found</Typography>
            <div style={{display:'flex',flexWrap:'wrap'}}>
              {
                sameNameDB.map(el=>(
                  <div className={classes.suggestionChip} onClick={()=>onChangeDatabase(el.id)}>{el.name_txt}</div>
                ))
              }
            </div>
          </div>
        )
      }
    }
    return <></>
  }

  const onMerge = () => {
    setErrorMsg()
    setMerging(true)
    axiosCerebrum
      .post(
        `/api/merges`,{
          "target": database,
          "object_type": 'DATABASE',
          "candidates": [candidate.id],
          "action":'ADD_TO_EXISTING'
        }
      )
      .then(response=>{
        setMerging(false)
        onClose()
        onFinish()
        sendAlert({type:'info',message:'Successfully merged the Source'})
      })
      .catch(error=>{
        let msg = error.response && error.response.data && error.response.data.errors? JSON.stringify(error.response.data.errors):'Error occurred merging the Source';
        setErrorMsg(msg)
        setMerging(false)
        console.log(error)
      })
  }
  

  const getStepContent = index => {
    switch(index){
      case 0:
        return (
          <div>
            <Select
              disableUnderline
              value={source}
              onChange={event=>onChangeSource(event.target.value)}
              className={classes.selector}
            >
              <MenuItem value='none'>Select a Source</MenuItem>
              {
                sourcesList && sourcesList.filter(el=>el.source_template.type==='DATABASE').map(el=>(
                  <MenuItem value={el.id}>{el.name}</MenuItem>
                ))
              }
            </Select>
            <Typography className={classes.helperText}>Required</Typography>
          </div>
        )
      case 1:
        return (
          <div>
            <Select
              disableUnderline
              value={database}
              onChange={event=>onChangeDatabase(event.target.value)}
              className={classes.selector}
            >
              <MenuItem value='none'>Select a Database</MenuItem>
              {
                databaseList && databaseList.map(el=>(
                  <MenuItem value={el.id}>{el.name_txt}</MenuItem>
                ))
              }
            </Select>
            <Typography className={classes.helperText}>Required</Typography>
            {getSuggestion()}
          </div>
        )
      case 2:
        if(step<2)return;
        return (
          <div>
            <div className={classes.banner} >
              {getIconComponent({label:'warning',size:20,colour:theme.palette.secondary.main})}
              <span className={classes.bannerText}>Merging Sources will link objects from {candidate.name} with the objects found in the Target.</span>
            </div>
            <Typography className={classes.sectionHeader}>{state.selectedSource.name} Source</Typography>
            <SimpleResultItem
              title={candidate.name + ` (${state.selectedSource.name})`}
              subTitle={candidate.location}
              label={'database'}
              iconLabel={state.selectedSource.source_template.name}
              showUnderline
              item={candidate}
              disableContextMenu
            />
            <Typography className={classes.sectionHeader}>Target Source</Typography>
            <SimpleResultItem
              title={databaseList.find(s=>s.id===database).name_txt + ` (${sourcesList.find(s=>s.id===source).name})`}
              subTitle={databaseList.find(s=>s.id===database).location_txt}
              label={'database'}
              iconLabel={sourcesList.find(s=>s.id===source).source_template.name}
              showUnderline
              item={databaseList.find(s=>s.id===database).name_txt}
              disableContextMenu
            />
            <div style={{marginTop:32}}>
              {
                merging?
                <CircularProgress color='secondary' size={20}/>:
                <Button variant="contained" onClick={onMerge}  className={classes.mergeButton}>MERGE</Button>
              }
            </div>
            {
              errorMsg && 
              <Typography style={{fontSize:13.75,marginTop:8,color:theme.palette.error.main}}>{errorMsg}</Typography>
            }
          </div>
        )
      default:
    }
  }

  const loadSources = () => {
    const load = ({prevData=[],page=1}) => {
      axiosCerebrum
        .get(`/api/sources`,{params:{
          page,
          per_page:100,
          internal_flag:false,
          sort:'ALPHABETICAL'
        }})
        .then(response=>{
          if(response.data.page>=response.data.pages){
            setSourcesList([...prevData,...response.data.items])
          }else{
            load({prevData:[...prevData,...response.data.items], page: page+1})
          }
        })
        .catch(error=>{
          console.log(error)
          setSourcesList([])
        })
    };

    load({})
  }

  useEffect(()=>{
    if(!sourcesList)loadSources();
  // eslint-disable-next-line
  },[])
  
  return (
    <div>
      <Modal onClose={()=>history.goBack()} open={modalOpen} disableBackdropClick={true} disableEscapeKeyDown={true} hideBackdrop={false}>
        <div style={{display:'flex',backgroundColor:theme.palette.background.main,outline:'none',position:'absolute',left:40,too:0,bottom:0,right:0,height:'100vh',width:'100vw',overflow:'auto'}}>
          <div className={classes.root}>
            <ProfileLayout
              noPadding
              header={
                <div style={{display:'flex', justifyContent:'space-between',paddingBottom:32}}>
                  <Typography style={{fontSize:34,marginBottom:24,marginTop:24, color:theme.palette.header.main}}>
                    Map Sources
                  </Typography>
                  <ProfileButton 
                    onClick={onClose}
                    text="CLOSE"
                  />
                </div>
                
              }
              disableTabBarOffset
              body={
                <div>
                   <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginBottom:30}}>
                      <div className={classes.chartBox}>
                        <div style={{width:24,height:24,flexShrink:0}}>
                          {getIconComponent({label:state.selectedSource.source_template.name,size:24,cooru:theme.palette.primary.main})}
                        </div>
                        <div style={{marginLeft:12,marginTop:5,overflow:'hidden'}}>
                          <KTooltip title={`${candidate.name} (${state.selectedSource.name})`}>
                            <Typography className={classes.overflowText} style={{fontSize:13.75,color:theme.palette.primaryText.main}}>
                              {candidate.name} ({state.selectedSource.name})
                            </Typography>
                          </KTooltip>
                          <KTooltip title={candidate.location}>
                            <Typography className={classes.overflowText} style={{fontSize:13.75,color:theme.palette.primaryText.main, marginTop:8}}>
                              {candidate.location}
                            </Typography>
                          </KTooltip>
                        </div>
                      </div>
                      <span style={{color:theme.palette.primary.main,letterSpacing:4,fontSize:24,margin:'0 24px'}}>------------{`>`}</span>
                      <div className={classes.chartBox} style={{background:theme.palette.primary.dark}}>
                        <div style={{width:24,height:24,flexShrink:0}}>
                          {getIconComponent({label:source==='none'?'info':sourcesList.find(s=>s.id===source).source_template.name,size:24,colour:theme.palette.background.main})}
                        </div>
                        <div style={{marginLeft:12,marginTop:5,overflow:'hidden'}}>
                          <KTooltip title={ database==='none'?'': databaseList.find(s=>s.id===database).name_txt + ` (${sourcesList.find(s=>s.id===source).name})`}>
                            <Typography className={classes.overflowText} style={{fontSize:13.75,color:theme.palette.background.main}}>
                              {
                                database==='none'?
                                `Select Source to merge to`:
                                databaseList.find(s=>s.id===database).name_txt + ` (${sourcesList.find(s=>s.id===source).name})`
                              }
                            </Typography>
                          </KTooltip>
                          {
                            database!=='none' && 
                            <KTooltip title={databaseList.find(s=>s.id===database).location_txt}>
                              <Typography className={classes.overflowText} style={{fontSize:13.75,color:theme.palette.background.main, marginTop:8}}>
                                {databaseList.find(s=>s.id===database).location_txt}
                              </Typography>
                            </KTooltip>
                          }
                        </div>
                      </div>
                    </div>
                  <Typography className={classes.header}>
                    MAP A {state.selectedSource.name.toUpperCase()} SOURCE TO A SOURCE FOUND IN K
                  </Typography>
                  <Typography className={classes.subTitle}>
                    Link a {state.selectedSource.name} source to a source that has been onboarded into K
                  </Typography>
                  <Stepper className={classes.stepper} activeStep={step} orientation="vertical">
                  {steps.map((label, index) => (
                    <Step className={classes.step} key={index}>
                      <StepLabel className={classes.stepLabel} style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}} >
                        <div style={{display:'flex',alignItems:'center'}}>
                          <Typography color='primary' style={{color:step<index?theme.palette.primaryText.light:undefined,fontSize:16,marginLeft:6,marginRight:12}}>{getStepLabelText(index)}</Typography> 
                        </div>
                      </StepLabel>
                      <StepContent>
                        <div style={{marginTop:16,marginBottom:8}}>
                          {getStepContent(index)}
                        </div>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
                </div>
              }
            >
            </ProfileLayout>
          </div>
        </div>
      </Modal>
    </div>
    
  )
}

AddMergeModal.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(AddMergeModal));