import React, {  } from 'react';
import { withTheme, Typography, withStyles, ListItem} from '@material-ui/core';
import { getIconComponent, toTitleCase } from '../../../utilities';
import PropTypes from 'prop-types';

const styles = theme => ({
})

const UserSuggestionItem = props => {

  const {
    theme,
    onClick,
    item,
    itemSource = 'solr',
    tailObject
  } = props;

  const processSourceText = (item) => {
    let str = item.source.name;
    if(item.reference_sources.length>0){
      str += ` / ${item.reference_sources.map(el=>el.reference_source.name).join(', ')}`
    }
    return str;
  }

  let name, accountType, teamName, sourceName;

  if(itemSource==='solr'){
    name = item.name_txt || item.name_txt || item.name;
    accountType = item.account_type_txt;
    teamName = item.teams_txts;
    sourceName = item.source_txt;
  }
  if(itemSource==='merge'){
    name = item.user.username+(item.user.username!==item.name?` (${item.name})`:'')
    accountType = item.user.account_type;
    sourceName = processSourceText(item)
  }

  if(!itemSource)return <div></div>;

  return (
    <ListItem button={onClick?true:false} onClick={onClick} style={{borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}}>
      <div style={{display:'flex',alignItems:'flex-start',overflow:'hidden',flexGrow:1}}>
        {getIconComponent({label:'user',size:24,colour:theme.palette.primary.main})}
        <div style={{overflow:'hidden',marginLeft:12}}>
          <Typography style={{color:theme.palette.primaryText.main,fontSize:16,whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden'}}>{name}</Typography>
          {
            accountType && 
            <Typography style={{color:theme.palette.primaryText.light,fontSize:13.75,whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden'}}>{toTitleCase(accountType.replace(/_/g,' '))}</Typography>
          }
          {
            accountType==='USER_ACCOUNT' && teamName && teamName.length>0 &&
            <Typography style={{color:theme.palette.primaryText.light,fontSize:13.75,whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden'}}>in {teamName.join(', ')}</Typography>
          }
          {
            accountType==='LOCAL_ACCOUNT' && sourceName &&
            <Typography style={{color:theme.palette.primaryText.light,fontSize:13.75,whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden'}}>Found in {sourceName}</Typography>
          }
        </div>
      </div>
      {
        tailObject
      }
    </ListItem>
  )
}

UserSuggestionItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  itemSource: PropTypes.oneOf(['solr','merge']).isRequired,
  tailObject: PropTypes.object,
}


export default withTheme()(withStyles(styles)(UserSuggestionItem));