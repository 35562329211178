import React, { useState , useRef, useEffect} from 'react';
import { withTheme,withStyles, Typography, InputBase, InputAdornment, IconButton, CircularProgress, Checkbox, Button, Modal, Paper} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchOutlined'
import ClearableSelector from '../../../UI/ClearableSelector/ClearableSelector'
import { getIconComponent, isInViewport, toTitleCase } from '../../../../utilities';
import axiosSolr from '../../../../axios-solr';
// import axiosCerebrum from '../../../../axios-cerebrum'
import KTooltip from '../../../UI/KTooltip/KTooltip';
import useAlert from '../../../../hooks/useAlert';
import axiosCerebrum from '../../../../axios-cerebrum';
import AddContactInput from '../../../UI/AddContactInput/AddContactInput';

const styles = theme => ({
  root: {

  },
  tooltip:{
    fontSize:13.75
  },
  inputBase:{
    ...theme.components.inputBase,
    height:42,
    width:'100%'
  },
  columnRow:{
    height:48,
    fontSize:12,
    color:theme.palette.primaryText.main,
    display:'flex',
    alignItems:'center',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  titleRow:{
    display:'flex',
    marginBottom:18,
    alignItems:'center'
  },
  tableTitle:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primary.main
  },
  checkbox:{
    padding:0,
    paddingLeft:11
  },
  actionButton:{
    height:40
  },
  container:{
    height:'100vh',
    width:'100vw',
    display:'flex',
    justifyContent:'center',
    outline:'none'
  },
  paper:{
    width:740,
    maxHeight:'65vh',
    minHeight:'30vh',
    paddingTop:24,
    height:500,
    boxSizing:'border-box',
    paddingLeft:40,
    paddingBottom:12,
    marginTop:'15vh',
    display:'flex',
    flexDirection:'column',
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  listContainer:{
    '&::-webkit-scrollbar':{
      width: '3px'
    },
    /* Track */
    '&::-webkit-scrollbar-track':{
      background: '#eee',
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      background: '#bbb',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{
      // background: #555;
    },
    flexGrow:1,
    overflow:'auto',
    marginBottom:16,
    paddingRight:40
  },
  listItem:{
    width:'100%',
    height:48,
    display:'flex',
    overflow:'hidden',
    alignItems:'center',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
  }
})

const Results = props => {

  const {
    classes,
    theme,
    state,
    dispatch
  } = props;

  const scrollRef = useRef();
  const searchRef = useRef();
  const [searchValue, setSearchValue] = useState('')
  const [sourceFilter, setSourceFilter] = useState('all')
  // const [levelFilter, setLevelFilter] = useState('all')
  // eslint-disable-next-line
  const [periodFilter, setPeriodFilter] = useState('all')
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [validSources, setValidSources] = useState();
  // const [validLevels, setValidLevels] = useState();
  // const [validPeriod, setValidPeriod] = useState();

  const [addUserModal, setAddUserModal] = useState();

  const {
    sendAlert
  } = useAlert({
    id:`ia-notify-recipients`,
  })

  const loadValidSources = () => {
    let fq = [];
    fq.push(`extract_id:${state.extractData.id}`)
    fq.push(`object_type_srt:USER`);
    axiosSolr
      .get(
        '/solr/impact/select',
        {params:{
          q:'*',
          fq:fq.join(' AND '),
          rows:0,
          facet:'on',
          'facet.field':'source_id_srt',
          'facet.mincount':1
        }}
      )
      .then(response=>{
        if(response.data.facet_counts && response.data.facet_counts.facet_fields && response.data.facet_counts.facet_fields.source_id_srt ){
          setValidSources(response.data.facet_counts.facet_fields.source_id_srt.filter((el,index)=>index%2===0))
        }else{
          setValidSources([])
        }
      })
      .catch(error=>{
        console.log(error);
      })
  }

  // const loadValidLevels = () => {
  //   let fq = [];
  //   fq.push(`extract_id:${state.extractData.id}`)
  //   fq.push(`object_type_srt:USER`);
  //   axiosSolr
  //     .get(
  //       '/solr/impact/select',
  //       {params:{
  //         q:'*',
  //         fq:fq.join(' AND '),
  //         rows:0,
  //         facet:'on',
  //         'facet.field':'max_level_srt',
  //         'facet.mincount':1
  //       }}
  //     )
  //     .then(response=>{
  //       if(response.data.facet_counts && response.data.facet_counts.facet_fields && response.data.facet_counts.facet_fields.max_level_srt ){
  //         setValidLevels(response.data.facet_counts.facet_fields.max_level_srt.filter((el,index)=>index%2===0))
  //       }else{
  //         setValidLevels([])
  //       }
  //     })
  //     .catch(error=>{
  //       console.log(error);
  //     })
  // }


  const onLoadData = ({start=0,search='',level='all',source='all',period='all'}) => {
    setLoading(true);
    if(start===0){
      setData();
    };
    let fq = [];
    fq.push(`extract_id:${state.extractData.id}`)
    fq.push(`object_type_srt:USER`)
    fq.push(`-starting_object_id:*`)
    if(source!=='all')fq.push(`source_id_srt:${source}`);
    // if(level!=='all')fq.push(`max_level_srt:${level}`);
    if(period!=='all')fq.push(`${period}`)
    axiosSolr
      .get(
        '/solr/impact/select',
        {params:{
          q:`name_srt:${search}*`,
          fq:fq.join(' AND '),
          start:start,
          rows:50,
          sort:'name_srt asc'
        }}
      )
      .then(async response=>{
        let newData;
        if(start===0){
          newData = response.data.response;
        }else{
          newData = {
            ...response.data.response,
            docs:[...data.docs,...response.data.response.docs]
          }
        }
        setData(newData)
        setLoading(false)
      })
      .catch(err=>{
        console.log(err);
        setLoading(false)
        setError(true)
      })
  }

  useEffect(()=>{
    onLoadData({});
    loadValidSources();
    // loadValidLevels();
    // loadValidPeriod();
   // eslint-disable-next-line
  },[])

  const onChangeSearch = (value) => {
    setSearchValue(value);
    clearTimeout(searchRef.current);
    searchRef.current = setTimeout(()=>{
      onLoadData({start:0,search:value,source:sourceFilter,period:periodFilter});
    },300)
  }

  const onSourceChange = value => {
    setSourceFilter(value);
    onLoadData({start:0,search:searchValue,source:value,period:periodFilter});
  }

  // const onLevelChange = value => {
  //   setLevelFilter(value);
  //   onLoadData({start:0,search:searchValue,source:sourceFilter,level:value,period:periodFilter});
  // }

  // const onPeriodChange = value => {
  //   setPeriodFilter(value);
  //   onLoadData({start:0,search:searchValue,source:sourceFilter,level:levelFilter,period:value});
  // }

  const shouldLoadMore = () => {
    return (isInViewport(scrollRef) && data && data.docs.length<data.numFound && !loading)
  }

  window.onscroll = () => {
    if(shouldLoadMore())onLoadData({start:data.docs.length,search:searchValue,source:sourceFilter,period:periodFilter})
  }

  useEffect(()=>{
    if(shouldLoadMore())onLoadData({start:data.docs.length,search:searchValue,source:sourceFilter,period:periodFilter})
   // eslint-disable-next-line
  },[data,loading])


  const onSelectAll = async (checked) => {
    dispatch({type:'set_notification_emails_manual',notificationEmailsManual:state.notificationEmailsManual.map(el=>({...el,selected:checked}))})
    dispatch({type:'set_notification_users_manual',notificationUsersManual:state.notificationUsersManual.map(el=>({...el,selected:checked}))})
    if(checked){
      let arr = data.docs;
      if(data.numFound!==data.docs.length){
        try{
          let fq = [];
          fq.push(`extract_id:${state.extractData.id}`)
          fq.push(`object_type_srt:USER`)
          fq.push(`-starting_object_id:*`)
          if(sourceFilter!=='all')fq.push(`source_id:${sourceFilter}`);
          let fullData = await axiosSolr
            .get(
              '/solr/impact/select',
              {params:{
                q:`name_srt:${searchValue}*`,
                fq:fq.join(' AND '),
                start:0,
                rows:data.numFound,
                sort:'name_srt asc'
              }}
            )
          setData(fullData.data.response);
          arr = fullData.data.response.docs;
        }catch{
          console.log("error occurred loadig full data")
        }  
      };
      dispatch({
        type:'set_notification_users',
        notificationUsers:arr.map(el=>el.object_id)
      });
    }else{
      dispatch({
        type:'set_notification_users',
        notificationUsers:[]
      })
    }
  }

  const impactedUserAllSelected = () => {
    return state.notificationUsers.length>0 && data && data.docs && data.docs.every(el=>state.notificationUsers.includes(el.object_id))
  }

  const onCheckUser = (checked,user) => {
    if(checked){
      dispatch({
        type:'set_notification_users',
        notificationUsers:[
          ...state.notificationUsers, user.object_id
        ]
      })
    }else{
      dispatch({
        type:'set_notification_users',
        notificationUsers:state.notificationUsers.filter(el=>el!==user.object_id)
      })
    }
  }

  const onClickAddUser = () => {
    setAddUserModal({type:'user'})
  }

  const additionalUserAllSelected = () => {
    if(state.notificationUsersManual.length===0 && state.notificationEmailsManual.length===0)return true;
    return (state.notificationUsersManual.length>0 || state.notificationEmailsManual.length>0) && state.notificationUsersManual.every(el=>el.selected) && state.notificationEmailsManual.every(el=>el.selected)
  }

  const onAddUser = user => {
    if(state.notificationUsersManual.find(el=>el.id===user.id)){
      sendAlert({type:'info',message:"This user has already been added."})
      return;
    }
    axiosCerebrum
      .get(
        `/api/users/${user.id}`
      )
      .then(response=>{
        let email = response.data.email || '';
        let newUser = {...user,email_srt:email,email_txt:email};
        dispatch({type:'set_notification_users_manual',notificationUsersManual:[...state.notificationUsersManual,{...newUser,selected:true}]})
      })
      .catch(error=>{
        console.log(error)
        sendAlert({type:'error',message:"Error occurred adding user"})
      })
    
    
  }

  const onAddEmail = email => {
    if(state.notificationEmailsManual.find(el=>el.id===email)){
      sendAlert({type:'info',message:"This email has already been added."})
    }else{
      dispatch({type:'set_notification_emails_manual',notificationEmailsManual:[...state.notificationEmailsManual,{id:email,name_srt:email,email_srt:email,object_type_srt:'email',selected:true}]})
    }
  }

  const onCheckAdditionalItem = (checked, item) => {
    if(item.object_type_srt==='email'){
      dispatch({
        type:'set_notification_emails_manual',
        notificationEmailsManual:state.notificationEmailsManual.map(el=>(
          el.id===item.id?
          {...el,selected:checked}:
          el
        ))
      })
    }
    if(item.object_type_srt==='USER'){
      dispatch({
        type:'set_notification_users_manual',
        notificationUsersManual:state.notificationUsersManual.map(el=>(
          el.id===item.id?
          {...el,selected:checked}:
          el
        ))
      })
    }
  }

  const onRemoveManualItem = item => {
    if(item.object_type_srt==='USER'){
      dispatch({
        type:'set_notification_users_manual',
        notificationUsersManual:state.notificationUsersManual.filter(el=>el.id!==item.id)
      })
    }
    if(item.object_type_srt==='email'){
      dispatch({
        type:'set_notification_emails_manual',
        notificationEmailsManual:state.notificationEmailsManual.filter(el=>el.id!==item.id)
      })
    }
  }

  const getGroup = el => { 
    return el.teams_msrt && el.teams_msrt.length>0?el.teams_msrt.map(el=>toTitleCase(el)).join(', '):'No Team'
  }
  
  return (
    <div className={classes.root}>
      <Typography style={{fontSize:20,marginTop:16,marginBottom:5,color:theme.palette.header.main}}>NOTIFICATION CONTACT LIST</Typography>
      <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:16}}>Users to be included in the notification. Click ADD CONTACT to add a user or email to the contact list</Typography>
      <div style={{display:'flex',marginBottom:12}}>
        <div style={{width:240,marginRight:24}}>
          <InputBase
            value={searchValue}
            onChange={(event)=>onChangeSearch(event.target.value)}
            className={classes.inputBase}
            placeholder={'Search'}
            startAdornment={
              <InputAdornment position="end">
                <SearchIcon style={{color:theme.palette.primaryText.light}}/>
              </InputAdornment>
            }
            endAdornment={
              searchValue===''?
              undefined:
              <InputAdornment position="end">
                <IconButton onClick={()=>onChangeSearch('')}>
                  {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <div >
          <ClearableSelector
            value={sourceFilter}
            onChangeValue={event=>onSourceChange(event.target.value)}
            options={[
              {value:'all',name:'All Sources'},
              ...state.sourceList.filter(s=>validSources&&validSources.includes(`${s.id}`)).map(el=>({name:el.name,value:el.id}))
            ]}
            onReset={()=>{onSourceChange('all')}}
          />
        </div>
        <div style={{flexGrow:1}}></div>
        <KTooltip classes={{tooltip:classes.tooltip}} title={'Add users to be notified'}>
          <Button color='primary' variant='contained' className={classes.actionButton} style={{marginLeft:16}} onClick={onClickAddUser}>ADD CONTACT</Button>
        </KTooltip>
      </div>
      <div className={classes.titleRow}>
        <KTooltip classes={{tooltip:classes.tooltip}} title={impactedUserAllSelected() && additionalUserAllSelected()?'Unselect all':'Select all'}>
          <div className={classes.tableTitle} style={{width:76,flexShrink:0,marginLeft:16}}>
            <Checkbox
              color='primary'
              checked={impactedUserAllSelected() && additionalUserAllSelected()}
              onChange={(event,checked)=>onSelectAll(checked)}
              className={classes.checkbox}
            />
          </div>
        </KTooltip>
        <div className={classes.tableTitle} style={{width:100,flexShrink:0,marginLeft:16}}>
          TYPE
        </div>
        <div className={classes.tableTitle}  style={{flexGrow:1,width:160,minWidth:160,paddingRight:16}}>
          NAME
        </div>
        <div className={classes.tableTitle}  style={{flex:'1 1 180px',minWidth:80,paddingRight:16}}>
          EMAIL
        </div>
        <div className={classes.tableTitle}  style={{flex:'0 0 250px',paddingRight:16}}>
          ROLE
        </div>
        <div className={classes.tableTitle}  style={{flex:'0 0 250px',paddingRight:16}}>
          TEAM
        </div>
        <div style={{flex:'0 0 64px'}}></div>
      </div>
      {
        error && !data && 
        <Typography style={{marginTop:16,color:theme.palette.primaryText.main}}>Error occurred loading data</Typography>
      }
      {
        data && data.numFound===0 && [...state.notificationUsersManual,...state.notificationEmailsManual].length===0 && 
        <Typography style={{marginTop:16,color:theme.palette.primaryText.main}}>No user found</Typography>
      }
      {
        sourceFilter==='all' && [...state.notificationUsersManual,...state.notificationEmailsManual].map(el=>(
          <div className={classes.columnRow} >
            <div style={{width:76,flexShrink:0,marginLeft:16}}>
              <Checkbox
                color='primary'
                checked={el.selected}
                onChange={(event,checked)=>onCheckAdditionalItem(checked,el)}
                className={classes.checkbox}
              />
            </div>
            <div style={{width:100,flexShrink:0,marginLeft:16}}>
              {el.object_type_srt.toUpperCase()}
            </div>
            <KTooltip classes={{tooltip:classes.tooltip}} title={el.name_srt}>
              <div style={{flexGrow:1,width:160,minWidth:160,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',paddingRight:16}}>
                {el.name_srt}
              </div>
            </KTooltip>

            <KTooltip classes={{tooltip:classes.tooltip}} title={el.email_srt || 'No email found for this user.\nUser will not receive a notification email.'}>
              <div style={{flex:'1 1 180px',overflow:'hidden',minWidth:80,textOverflow: 'ellipsis',whiteSpace: 'nowrap',paddingRight:16}}>
                {el.email_srt || 'No email'} 
                {!el.email_srt && <span style={{marginLeft:4,position:'relative',top:3}}>{getIconComponent({label:'info',size:16,colour:theme.palette.error.main})}</span>}
              </div>
            </KTooltip>

            <div style={{flex:'0 0 250px',overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',paddingRight:16}}>
            </div>

            <KTooltip classes={{tooltip:classes.tooltip}} title={getGroup(el)}>
              <div style={{flex:'0 0 250px',overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',paddingRight:16}}>
                {getGroup(el)}
              </div>
            </KTooltip>

            <div style={{flex:'0 0 64px'}}>
              <IconButton onClick={()=>{
                onRemoveManualItem(el)
              }}>
                {getIconComponent({label:'delete',size:24,colour:theme.palette.primaryText.light})}
              </IconButton>
            </div>
          </div>
        ))
      }
      {
        data && data.docs && data.docs.map(el=>(
          <div className={classes.columnRow} >
            <div style={{width:76,flexShrink:0,marginLeft:16}}>
              <Checkbox
                color='primary'
                checked={state.notificationUsers.includes(el.object_id)}
                onChange={(event,checked)=>onCheckUser(checked,el)}
                className={classes.checkbox}
              />
            </div>
            <div style={{width:100,flexShrink:0,marginLeft:16}}>
              {el.object_type_srt}
            </div>
            <KTooltip classes={{tooltip:classes.tooltip}} title={el.name_srt}>
              <div style={{flexGrow:1,width:160,minWidth:160,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',paddingRight:16}}>
                {el.name_srt}
              </div>
            </KTooltip>
            <KTooltip classes={{tooltip:classes.tooltip}} title={el.email_srt || 'No email found for this user.\nUser will not receive a notification email.'}>
              <div style={{flex:'1 1 180px',minWidth:80,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',paddingRight:16}}>
                {el.email_srt || 'No email'} 
                {!el.email_srt && <span style={{marginLeft:4,position:'relative',top:3}}>{getIconComponent({label:'info',size:16,colour:theme.palette.error.main})}</span>}
              </div>
            </KTooltip>
            <KTooltip classes={{tooltip:classes.tooltip}} title={el.user_type_srt}>
              <div style={{flex:'0 0 250px',overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',paddingRight:16}}>
                {el.user_type_srt}
              </div>
            </KTooltip>
            <KTooltip classes={{tooltip:classes.tooltip}} title={getGroup(el)}>
              <div style={{flex:'0 0 250px',overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',paddingRight:16}}>
                {getGroup(el)}
              </div>
            </KTooltip>
            <div style={{flex:'0 0 64px'}}></div>
          </div>
        ))
      }
      
      <div ref={scrollRef} style={{display:'flex',justifyContent:'center',marginTop:16,marginBottom:100}}>
        {
          loading && <CircularProgress color='secondary'/>
        }
      </div>

      <Modal
        open={addUserModal}
        onClose={() =>{setAddUserModal()}}
        disableBackdropClick
        disableEnforceFocus
      >
        <div className={classes.container}>
          {
            addUserModal &&
            <Paper className={classes.paper}>
              <div style={{display:'flex',alignItems:'flex-start'}}>
                <div style={{flexGrow:1}}>
                  <Typography color='primary' style={{fontSize:20,marginBottom:6,flexGrow:0,flexShrink:0}}>Add contacts to the notification list</Typography>
                </div>
                <Button color='primary' variant='outlined' onClick={()=>setAddUserModal()} style={{margin:'0px 40px 0px auto'}}>CLOSE</Button>
              </div>
              <AddContactInput
                type={addUserModal.type}
                setType={type=>setAddUserModal({type})}
                onAddUser={onAddUser}
                onAddEmail={onAddEmail}
                onRemoveUser={onRemoveManualItem}
                customRightPadding={40}
                users={[...state.notificationUsersManual,...state.notificationEmailsManual]}
              />
            </Paper>
          }
        </div>
      </Modal>
    </div>  
  )
}

export default withTheme()(withStyles(styles)(Results));