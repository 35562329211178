import React from 'react';
import {  Typography, withStyles, withTheme} from '@material-ui/core';
import { formatNumber, getIconComponent, getSourceObjectCount, titleCaseObjectName } from '../../../utilities';
import KTooltip from '../../UI/KTooltip/KTooltip';
import moment from 'moment';

const styles = theme => ({
  root: {
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    display:'flex',
    overflow:'hidden',
    alignItems:'flex-start',
    height:64,
    paddingTop:16,
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  title:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  columnText:{
    fontSize:13.75,
    paddingRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    minWidth:50,
  }
})

const SourceChip = props => {

  const {
    classes,
    theme,
    history,
    sourceWithStats,
  } = props;

  if(!sourceWithStats)return <div></div>
  
  let isRef = sourceWithStats?.object?.name==='HOST';
  let primaryObjectName = isRef?undefined:getSourceObjectCount(sourceWithStats.source_template.name, sourceWithStats.source_template.type)
  let objectCount = isRef?sourceWithStats.object_count?.[0]:sourceWithStats.object_count?.find(s=>s.val===primaryObjectName)
  let collectionStats = {};
  if(sourceWithStats.node_stats){
    if(sourceWithStats.node_stats.top_5_platform_classifications){
      collectionStats['Classification'] = sourceWithStats.node_stats.top_5_platform_classifications.split(',')
    }
    if(sourceWithStats.node_stats.top_5_platform_domains){
      collectionStats['Domain'] = sourceWithStats.node_stats.top_5_platform_domains.split(',')
    }
    if(sourceWithStats.node_stats.top_5_platform_verified_use_cases){
      collectionStats['Verified Use Case'] = sourceWithStats.node_stats.top_5_platform_verified_use_cases.split(',')
    }
  }
  
  
  let name, hostName;
  if(isRef){
    name = sourceWithStats?.alternate_name || 'Unknown';
    hostName = sourceWithStats?.name || 'Unknown';
  }else{
    name = sourceWithStats?.name || 'Unknown';
    hostName = sourceWithStats?.host || 'Unknown';
  }

  let type = isRef?'REFERENCE':sourceWithStats.source_template.name
  let updatedAt = moment(sourceWithStats.updated_at).format('dddd DD MMMM YYYY')

  return (
    <div data-test-classname="source-chip" className={classes.root} onClick={()=>history.push(`/profile/source/${isRef?sourceWithStats.id:sourceWithStats.host_ids[0]}`)}>
      <div style={{width:24,height:24,flexGrow:0,flexShrink:0,marginLeft:16}}>
        {
          getIconComponent({label:isRef?'host':sourceWithStats.source_template.name,size:24,colour:theme.palette.primaryText.light,defaultLabel:'source'})
        }
      </div>
      <div style={{flexGrow:1,marginLeft:16,overflow:'hidden'}}>
        <KTooltip placement={"top-start"} title={name}>
          <Typography data-test-classname="source-name" className={classes.title}>{name}</Typography>
        </KTooltip>
        <KTooltip placement={"top-start"} title={hostName}>
          <Typography className={classes.subTitle}>{hostName}</Typography>
        </KTooltip>
      </div>
      <KTooltip title={type}>
        <Typography className={classes.columnText} style={{flex:'0 1 150px'}}>
          {type}
        </Typography>
      </KTooltip>
      {
        !isRef && 
        <Typography data-test-classname="source-child-count" className={classes.columnText} style={{flex:'0 1 150px'}}>
          {
            objectCount?
            `${formatNumber(parseInt(objectCount.count))} ${titleCaseObjectName(objectCount.val)}(s)`
            :
            `0 ${primaryObjectName}(s)`
          }
        </Typography>
      }
      <KTooltip title={updatedAt}>
        <Typography className={classes.columnText} style={{flex:'0 1 190px'}}>
          {updatedAt}
        </Typography>
      </KTooltip>
    </div>
  )
}

export default withTheme()(withStyles(styles)(SourceChip));