import React, { useEffect} from 'react';
import { withTheme, withStyles, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import { tileStyles } from './utils/styles';
import axiosSolr from '../../../../axios-solr';
import UserChip from '../../Chips/UserChip';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  suggestionItem:{
    padding:'8px 16px',
    cursor:'pointer',
    fontSize:13.75,
    '&:hover':{
      background:theme.palette.hovered.main
    },
    color:theme.palette.primaryText.main,
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
  iconButton:{
    ...tileStyles.deleteButton
  }
})

function UpDownStreamSources(props) {

  const {
    classes,
    history,
    object,
    onAddTileData,
    tileData,
    direction,
    alwaysOpenNewTab
  } = props;
  
  const loadSourceDetails = async ids => {
    let data = []
    if(!ids || ids.length===0)return data;
    await axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:"*",
            fq:`id:(${ids.join(' OR ')})`,
            rows:ids.length
          }
        }
      )
      .then(res=>{
        data = res.data.response.docs
      })
      .catch(err=>{
        console.log(err)
      })
    return data;
  }

  const loadSources = async () => {
    if(direction==='upstream' && object.boundary_upstream_sources_txts){
      let data = await loadSourceDetails(object.boundary_upstream_sources_ids)
      onAddTileData(
        {
          "disp_body":object.boundary_upstream_sources_txts.map((t,i)=>{
            let id = object.boundary_upstream_sources_ids?.[i]
            return ({
              name:t,
              id:id,
              sourceType:data.find(d=>d.id===id)?.source_type_txt,
              onClick:id?()=>{
                alwaysOpenNewTab?
                window.open(`/profile/source/${id}`,'_blank'):
                history.push(`/profile/source/${id}`)
              }:undefined
            })

          }),
          "disp_title":"UPSTREAM SOURCES",
          "id":"upstreamSources",
        }
      )
    }
    if(direction==='downstream' && object.boundary_downstream_sources_txts){
      let data = await loadSourceDetails(object.boundary_downstream_sources_ids)
      onAddTileData(
        {
          "disp_body":object.boundary_downstream_sources_txts.map((t,i)=>{
            let id = object.boundary_downstream_sources_ids?.[i]
            return ({
              name:t,
              id:id,
              sourceType:data.find(d=>d.id===id)?.source_type_txt,
              onClick:id?()=>{
                alwaysOpenNewTab?
                window.open(`/profile/source/${id}`,'_blank'):
                history.push(`/profile/source/${id}`)
              }:undefined
            })

          }),
          "disp_title":"DOWNSTREAM SOURCES",
          "id":"downstreamSources",
        }
      )
    }
  }

  useEffect(()=>{
    if(!tileData){
      loadSources()
    }
  // eslint-disable-next-line
  },[])
  
  if(!tileData)return null;

  return (
    <div data-test-id="tag-data-tile" className={classes.root}>
      <div className={classes.editableHeaderContainer}>
        <Typography className={classes.title}>{direction.toUpperCase()} SOURCE</Typography>
      </div>
      <div className={classes.chipContainer}>
        {
          tileData && tileData.disp_body.sort((a,b)=>a.name<b.name?-1:1).map(t=>(
            // <div data-test-classname="tag-data-tile-chip" className={classes.tag} style={{background:'#FFAB01',borderColor:'#FFAB01'}} onClick={t.onClick}>
            //   <span className={classes.tagText} style={{color:'#000'}}>{t.name}</span>
            // </div>
            <UserChip
              user={t}
              iconLabel={t.sourceType || 'source'}
              onClick={t.onClick}
              bottomMargin={8}
            />
          ))
        }
      </div>
    </div>
  )
}

UpDownStreamSources.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(UpDownStreamSources));