import React,{useEffect,useRef,useState} from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Popper, ClickAwayListener, Paper, MenuItem, Checkbox } from '@material-ui/core';
import ResultItem2 from '../../UI/SearchResults/ResultItem2';
import { onClickResultItem,getDispFields, isInViewport, mapObjectName, getIconComponent} from '../../../utilities'
import axiosSolr from '../../../axios-solr'
import axiosCerebrum from '../../../axios-cerebrum'
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';

const styles = theme => ({
  root:{
    display:'flex'
  },
  selector: {
    ...theme.components.selector,
    width: 160,
  },
  divButton:{
    height:36,
    width:36,
    display:'flex',
    alignItems:'center',
    justifyContent:'space-around',
    borderRadius:18,
    cursor:'pointer',
    '&:hover':{
      backgroundColor:'#EEE'
    }
  },
  title:{
    display: 'inline-block',
    // fontSize: '1.1rem',
    position: 'relative',
    top: '0.1rem',
    color: theme.palette.header.main,
    fontWeight: '400',
    fontSize: '1.25rem',
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
	menuItem:{
		padding:'10px 10px 10px 16px',
		'&:hover':{
      background: theme.palette.buttonHovered.main 
    }
	},
  searchFilter:{
    ...theme.components.inputBase,
    marginRight:24,
    width:160,
		height:40,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },

  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },
  checkbox:{
    paddingLeft:0,
  },
})

function Related(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    history,
  } = props;


  const [anchor, setAnchor] = useState();
  const [listActionOpen, setListActionOpen] = useState(false);

  let scrollRef = useRef();

  let label, data, loading, isShowDeleted;
  let search = ''
  if(state.relatedTabsData && state.relatedTabsData.length>0){
    label = state.relatedTabsData[state.relatedTabState];
    data = state.relatedData[label];
    loading = data?data.loading:false;
    isShowDeleted = data?data.showDeleted:false;
  }

  const loadTabs = () => {
    dispatch({
      type:'set_related_tabs_data',
      relatedTabsLoading:true
    })
    axiosCerebrum
      .get(
        `/api/datapipelines/${state.basicData.id}/related/objects`,
        {params:{
          per_page:20,
          relationship:'REFERENCES,REFERENCED_BY,K_REFERENCES,K_REFERENCED_BY',
          active_flag:true,
          object_active_flag:true
        }}
      )
      .then(response=>{
        dispatch({
          type:'set_related_tabs_data',
          relatedTabsData:response.data.items.map(el=>el.name).filter(el=>el==='QUERY')
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_related_tabs_data',
          relatedTabsError:true
        })
      })
    
  }

  const shoudLoadMore = () => {
    return data && !loading && data.page<data.pages && isInViewport(scrollRef)
  }

  const loadData = ({page=1, showDeleted = isShowDeleted}) => {

    dispatch({
      type:'set_related_data',
      relatedData:{
        ...state.relatedData,
        [label]:{
          ...data,
          items:page===1?[]:data.items,
          showDeleted,
          loading:true,
          error:false
        }
      }
    })
    let url = `/api/datapipelines/${state.basicData.id}/related`;
    let params = {
      object_name:label,
      page:page,
      relationship:'REFERENCES,REFERENCED_BY,K_REFERENCES,K_REFERENCED_BY',
      per_page:10,
      object_active_flag:showDeleted?undefined:true,
      active_flag:true
    }
    axiosCerebrum
      .get(
        url,
        {params}
      )
      .then(response=>{
        if(response.data.total===0){
          dispatch({
            type:'set_related_data',
            relatedData:{
              ...state.relatedData,
              [label]:{
                ...response.data,
                showDeleted,
              }
            }
          })
          return;
        }
        axiosSolr.get(
          '/solr/search/select',
          {params:{
            q:'*',
            fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
            fl:"*",
            rows:10
          }}
        ).then(solrRes=>{
          let arr = [];
          response.data.items.forEach(el=>{
            arr.push({...(solrRes.data.response.docs.find(s=>s.id===el.id)||{}),relationship:el.relationship})
          }) 
          dispatch({
            type:'set_related_data',
            relatedData:{
              ...state.relatedData,
              [label]:{
                ...response.data,
                items:page===1?arr:[...data.items,...arr],
                showDeleted,
                loading:false,
                error:false
              }
            }
          })
        }).catch(error=>{
          console.log(error)
          dispatch({
            type:'set_related_data',
            relatedData:{
              ...state.relatedData,
              [label]:{
                ...data,
                showDeleted,
                error:true
              }
            }
          })
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_related_data',
          relatedData:{
            ...state.relatedData,
            [label]:{
              ...data,
              error:true
            }
          }
        })
      })
  }

  useEffect(()=>{
    if(!state.relatedTabsData){
      loadTabs()
    }
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(!state.relatedTabsData||state.relatedTabsData.length===0)return;
    if(!data)loadData({page:1,search});
    if(shoudLoadMore())loadData({page:data.page+1,search})
  // eslint-disable-next-line
  },[state.relatedTabsData,state.relatedTabState,state.relatedData])

  window.onscroll=()=>{
    if(!shoudLoadMore())return;
    loadData({page:data.page+1,search})
  }


  const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

  const onClickShowDeleted = deleted => {

    loadData({showDeleted:deleted})
  }
  


  if(state.relatedTabsLoading){
    return (
      <div style={{width:'100%',marginTop:80,display:'flex',justifyContent:'space-around'}}>
        <CircularProgress color='secondary' />
      </div>
    )
  }
  if(state.relatedTabsError){
    return <Typography className={classes.normalText}>Error occurred loading related items</Typography>
  }
  if(!state.relatedTabsData){
    return <div></div>
  }

  if(state.relatedTabsData.length===0){
    return <Typography className={classes.normalText}>No related item found</Typography>
  }

  return (
    <div className={classes.root}>
      <VerticalTabBar
        tabOptions={state.relatedTabsData.map(el=>mapObjectName(el).replace(/_/g,' '))}
        tabState={state.relatedTabState}
        setTabState={value => dispatch({ type: 'set_related_tab_state', relatedTabState: value })}
      />
      <div style={{flexGrow:1,marginLeft:80,overflow:'hidden'}}>
        <div style={{display:'flex',flexWrap:'wrap',marginBottom:16}}>
          <div style={{flexGrow:1,marginRight:12}}>
            <Typography color='primary' className={classes.title}>
              {`${data&&data.total?data.total+' ':''}${mapObjectName(label,true).replace(/_/g,' ').toUpperCase()}(S)`}
            </Typography>
          </div>
          <div style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} onClick={onListActionClick}>
            {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
          </div>
          
          <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
            <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
              <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                <Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
                <MenuItem onClick={()=>{onClickShowDeleted(!isShowDeleted)}} className={classes.menuItem} >
                  <Checkbox key={isShowDeleted} className={classes.checkbox} color='primary' checked={!isShowDeleted}/>
                  <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
                </MenuItem>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
        <div>
          {
            data && data.items && data.items.map(item=>(
              <ResultItem2
                item={item}
                key={item.id}
                dispBody={getDispFields(item,'dispBody')}
                dispSubtitle={getDispFields(item,'dispSubtitle')}
                dispTitle={getDispFields(item,'dispTitle')}
                label={item.object_type_txt}
                showIcon
                showUnderline
                onClick={() => onClickResultItem({id:item.id,label:item.object_type_txt,history:history,item:item})}
              />
            ))
          }
        </div>
        {
          data?.items?.length===0 && !loading && 
          <Typography >No active items found. <span style={{textDecoration:'underline',cursor:'pointer'}} onClick={()=>onClickShowDeleted(true)}>Click to see inactive items</span></Typography>
        }
        <div ref={scrollRef} style={{width:'100%',display:'flex',justifyContent:'center',marginBottom:10,marginTop:16}}>
          { loading && <CircularProgress color='secondary'/>}
        </div>
      </div>
      
    </div>
  )
}



export default withTheme()(withStyles(styles)(Related));