import React, {  } from 'react';
import {withTheme, withStyles, Typography, } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getDispFields,mapObjectName, getIconComponent } from '../../../utilities';
import { getIconLabel } from '../SearchResults/utils';

const styles = theme => ({
  chip:{
    width:144,
    height:40,
    padding:'0px 8px',
    display:'flex',
    alignItems:'center',
    borderRadius:4,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.primary.main}`,
    overflow:'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  chipTextContainer:{
    marginLeft:8,
    overflow:'hidden'
  },
  chipText:{
    fontSize:12,
    color:theme.palette.primary.main,
    overflow:'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  chipSubText:{
    fontSize:10,
    color:theme.palette.primaryText.light,
    overflow:'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
})


const LinkBody = props => {

  const {
    theme,
    // history,
    classes,
    object,
    selectedRelation,
    selectedObjectType
  } = props;

  if(!['REPLACED_BY','REPLACES','SOURCE_OF','SOURCE_FROM'].includes(selectedRelation)){
    return <></>
  }

  return (
    <div style={{display:'flex',alignItems:'center',marginTop:2,justifyContent:'center',marginBottom:24}}>
      {
        selectedRelation==='REPLACED_BY' && 
        <div className={classes.chip}>
          <div style={{flexShrink:0,width:16,height:16}}>
            {getIconComponent({label:getIconLabel({label:object.object.name,item:object}),size:16,colour:theme.palette.primary.main})}
          </div>
          <div className={classes.chipTextContainer}>
            <Typography className={classes.chipText}>{getDispFields(object,'dispTitle')}</Typography>
            <Typography className={classes.chipSubText}>{getDispFields(object,'dispSubtitle')}</Typography>
          </div>
        </div>
      }
      {
        selectedRelation==='REPLACES' && 
        <div className={classes.chip} style={{background:theme.palette.primary.main}}>
          <div style={{flexShrink:0,width:16,height:16}}>
            {getIconComponent({label:getIconLabel({label:object.object.name,item:object}),size:16,colour:theme.palette.background.main})}
          </div>
          <div className={classes.chipTextContainer}>
            <Typography className={classes.chipText} style={{color:theme.palette.background.main,marginTop:2}}>Selected {mapObjectName(selectedObjectType).toLowerCase()}(s)</Typography>
          </div>
        </div>
      }
      {
        ['SOURCE_OF'].includes(selectedRelation) && 
        <div className={classes.chip}>
          <div style={{flexShrink:0,width:16,height:16}}>
            {getIconComponent({label:getIconLabel({label:object.object.name,item:object}),size:16,colour:theme.palette.primary.main})}
          </div>
          <div className={classes.chipTextContainer}>
            <Typography className={classes.chipText}>{getDispFields(object,'dispTitle')}</Typography>
            <Typography className={classes.chipSubText}>{getDispFields(object,'dispSubtitle')}</Typography>
          </div>
        </div>
      }
      {
        ['SOURCE_FROM'].includes(selectedRelation) && 
        <div className={classes.chip} style={{background:theme.palette.primary.main}}>
          <div style={{flexShrink:0,width:16,height:16}}>
            {getIconComponent({label:selectedObjectType.toLowerCase(),size:16,colour:theme.palette.background.main})}
          </div>
          <div className={classes.chipTextContainer}>
            <Typography className={classes.chipText} style={{color:theme.palette.background.main,marginTop:2}}>Selected {mapObjectName(selectedObjectType).toLowerCase()}(s)</Typography>
          </div>
        </div>
      }
      <span style={{color:theme.palette.primary.main,letterSpacing:4,fontSize:24,margin:'0 24px'}}>------------{`>`}</span>
      {
        selectedRelation==='REPLACED_BY' && 
        <div className={classes.chip} style={{background:theme.palette.primary.main}}>
          <div style={{flexShrink:0,width:16,height:16}}>
            {getIconComponent({label:selectedObjectType.toLowerCase(),size:16,colour:theme.palette.background.main})}
          </div>
          <div className={classes.chipTextContainer}>
            <Typography className={classes.chipText} style={{color:theme.palette.background.main,marginTop:2}}>Selected {mapObjectName(selectedObjectType).toLowerCase()}(s)</Typography>
          </div>
        </div>
      }
      {
        selectedRelation==='REPLACES'  && 
        <div className={classes.chip}>
          <div style={{flexShrink:0,width:16,height:16}}>
            {getIconComponent({label:selectedObjectType.toLowerCase(),size:16,colour:theme.palette.primary.main})}
          </div>
          <div className={classes.chipTextContainer}>
            <Typography className={classes.chipText}>{getDispFields(object,'dispTitle')}</Typography>
            <Typography className={classes.chipSubText}>{getDispFields(object,'dispSubtitle')}</Typography>
          </div>
        </div>
      }
      {
        ['SOURCE_OF'].includes(selectedRelation) && 
        <div className={classes.chip} style={{background:theme.palette.primary.main}}>
          <div style={{flexShrink:0,width:16,height:16}}>
            {getIconComponent({label:selectedObjectType.toLowerCase(),size:16,colour:theme.palette.background.main})}
          </div>
          <div className={classes.chipTextContainer}>
            <Typography className={classes.chipText} style={{color:theme.palette.background.main,marginTop:2}}>Selected {mapObjectName(selectedObjectType).toLowerCase()}(s)</Typography>
          </div>
        </div>
      }
      {
        ['SOURCE_FROM'].includes(selectedRelation) && 
        <div className={classes.chip}>
          <div style={{flexShrink:0,width:16,height:16}}>
            {getIconComponent({label:getIconLabel({label:object.object.name,item:object}),size:16,colour:theme.palette.primary.main})}
          </div>
          <div className={classes.chipTextContainer}>
            <Typography className={classes.chipText}>{getDispFields(object,'dispTitle')}</Typography>
            <Typography className={classes.chipSubText}>{getDispFields(object,'dispSubtitle')}</Typography>
          </div>
        </div>
      }
    </div>
  )

}

LinkBody.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  history:PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  selectedRelation: PropTypes.string.isRequired,
  selectedObjectType: PropTypes.string.isRequired,
}


export default withTheme()(withStyles(styles)(LinkBody));