import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Button, Modal, Typography, CircularProgress, IconButton, ListItem} from '@material-ui/core';
import { getIconComponent, getItemChildType, getLabelPlural, getPartialMatchSearchString, mapSearchObjectName } from '../../../../utilities';
import axiosSolr from '../../../../axios-solr';
import SearchSelector from '../../SearchSelector/SearchSelector';
import axiosCerebrum from '../../../../axios-cerebrum';
import Diagram from './Diagram';
import { getContentContainerStyle } from '../../ProfileLayoutNew/layoutUtils';
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  modalRoot:{
    display:'flex',
    background:theme.palette.background.main
  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  sectionHeader:{
    fontSize:20,
    overflow:"hidden",
    textOverflow:"ellipsis",
    whiteSpace:'nowrap'
  },
  hideScroll:{
    ...theme.components.customScroll
  },
  columName:{
    fontSize:16,
    overflow:"hidden",
    textOverflow:"ellipsis",
    whiteSpace:'nowrap'
  },
  actionButton:{
    padding:4,
    '& span':{
      fontSize:12
    }
  },
  linkedSectionHeader:{
    fontSize:12,
    letterSpacing:1.5,
    color:theme.palette.primaryText.main,
    marginTop:8,
    marginBottom:8
  },
  chip:{
    background:theme.palette.primary.dark,
    height:24,
    marginRight:6,
    display:"flex",
    alignItems:'center',
    overflow:'hidden',
    borderRadius:12,
    padding:'0px 2px 0 12px',
    marginBottom:4
  },
  suggestionChip:{
    border:`1px solid ${theme.palette.primary.dark}`,
    background:theme.palette.background.main,
    boxSizing:'border-box',
    cursor:'pointer',
    padding:'0px 8px',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  suggestionChipText:{
    color:`${theme.palette.primary.dark} !important`,
  },
  chipText:{
    color:theme.palette.background.main,
    fontSize:13.75,
    overflow:"hidden",
    textOverflow:"ellipsis",
    whiteSpace:'nowrap'
  },
  iconButton:{
    padding:3,
    marginLeft:8,
    "&:hover":{
      backgroundColor:`${theme.palette.hovered.main}40`
    }
  },
  profileIcon: {
    marginLeft:-58,
    marginRight:12
  },
  '@media (max-width: 1350px)': {
    profileIcon: {
      display: 'none'
    },
  },
})

function ColumnMapper(props) {
  const {
    classes,
    theme,
    source,
    sourceParent,
    target,
    targetParent,
    modalOpen,
    setModalOpen
  } = props;

  const scrollRef = useRef()
  const [columnSearchValue, setColumnSearchValue] = useState({})
  const [mappedColumn, setMappedColumn] = useState({})
  const [suggestionColumns, setSuggestionColumns] = useState({})

  let childTypeName = getItemChildType(source.data.obj)

  const [targetColumns, setTargetColumns] = useState({})

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const loadSuggestions = items => {
    let suggestionsMap = {};
    let promises = [];
    let sourceLabel = source?.data?.obj?.object_type_txt||source?.data?.obj?.type||source?.data?.obj?.object_type
    items.forEach(i=>{
      promises.push(
        axiosSolr
          .get(
            `/solr/search/select`,{
              params:{
                // q:getPartialMatchSearchString(i.name_srt,true),
                q:"*",
                fq:`name_srt:"${i.name_txt}" AND hierarchy_parent_id_srt:${source.data.obj.id} AND object_type_srt:(COLUMN OR DATASET_FIELD)`+
                    (i.object_subtype_txt?` AND object_subtype_srt:"${i.object_subtype_txt}"`:'') + 
                    (i.column_type_txt && sourceLabel==='TABLE'?` AND column_type_srt:"${i.column_type_txt}"`:''),
                rows:10
              }
            }
          )
          .then(response=>{
            suggestionsMap[i.id] = response.data.response.docs
          })
      )
    });
    return Promise
      .all(promises)
      .then(response=>{
        setSuggestionColumns({
          ...suggestionColumns,
          ...suggestionsMap
        })
      })
      .catch(error=>{
        console.log(error)
      })
  }

  const loadSolrDetails = async (items) => {
    let details = []
    await axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:'*',
            fq:`id:(${items.map(i=>i.id).join(' OR ')})`,
            rows:items.length
          }
        }
      )
      .then(response=>{
        items.forEach(i=>{
          details.push({
            ...i,
            ...(response.data.response.docs.find(r=>r.id===i.id)||{})
          })
        })
      })
      .catch(error=>{
        console.log(error)
        details = items
      })
    return details
  }

  const loadExistingMap = (items) => {
    let map = {};
    let promises = [];

    items.forEach(i=>{
      const loadData = async ({page=1}) => {
        await axiosCerebrum.get(
          `/api/${getLabelPlural(mapSearchObjectName(i.object_type_txt,i.code_type_txt))}/${i.id}/related`,{
            params:{
              relationship:'SOURCE_FROM,K_REFERENCES,REFERENCES,REFERENCES_SHORTCUT',
              per_page:200,
              object_name:"DATASET_FIELD,COLUMN",
              page
            }
          }
        )
        .then(async response=>{
          let detailedItems = []
          response.data.items = response.data.items.filter(r=>r.parent_id===source.data.obj.id)
          if(response.data.items.length>0){
            detailedItems = await loadSolrDetails(response.data.items)
          }
          map[i.id] = [...(map[i.id]||[]),...detailedItems.map(r=>({...r,isExistingLink:true,isAuto:r.relationship.includes('REFERENCE')}))];
          if(response.data.pages>response.data.page){
            await loadData({page:response.data.page+1})
          }
        })
      };
      promises.push(loadData({}))
    })

    return Promise
      .all(promises)
      .then(response=>{
        setMappedColumn({...mappedColumn,...map})
      })
      .catch(error=>{
        console.log(error)
      })
  }
  
  const loadTargetColumns = ({start=0}) => {
    setTargetColumns({...targetColumns,loading:true})
    axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:"*",
            fq:`hierarchy_parent_id_srt:${target.data.obj.id} AND object_type_srt:(COLUMN OR DATASET_FIELD)`,
            rows:10,
            start,
            sort:'name_srt asc'
          }
        }
      )
      .then(async response=>{
        await loadExistingMap(response.data.response.docs)
        await loadSuggestions(response.data.response.docs)
        setTargetColumns({
          ...response.data.response,
          docs:[
            ...(targetColumns.docs||[]),
            ...response.data.response.docs
          ]
        })
      })
      .catch(error=>{
        console.log(error)
        setTargetColumns({
          ...targetColumns,
          loading:false
        })
      })
  }

  const onClickColumnResult = (targetID, el) => {
    axiosCerebrum
      .put(
        `/api/${getLabelPlural(el.object_type_txt?mapSearchObjectName(el.object_type_txt,el.code_type_txt):el.type)}/${el.id}/related?` + 
        `object_id=${targetID}&relationship=SOURCE_OF`
      )
      .then(response=>{
        setMappedColumn({
          ...mappedColumn,
          [targetID]:[
            ...(mappedColumn[targetID]||[]),
            el
          ]
        })
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred adding the mapping, please try again', type: 'error' })
      })
  }

  const onRemoveColumn = (targetID, mapped) => {
    axiosCerebrum
      .delete(
        `/api/${getLabelPlural(mapped.object_type_txt?mapSearchObjectName(mapped.object_type_txt,mapped.code_type_txt):mapped.type)}/${mapped.id}/related?` + 
        `object_id=${targetID}&relationship=SOURCE_OF`
      )
      .then(response=>{
        setMappedColumn({
          ...mappedColumn,
          [targetID]:(mappedColumn[targetID]||[]).filter(m=>m.id!==mapped.id)
        })
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred removing the mapping, please try again', type: 'error' })
      })
  }


  useEffect(()=>{
    if(!modalOpen){
      setColumnSearchValue({})
      setTargetColumns({})
      setMappedColumn({})
      setSuggestionColumns({})
    }else{
      loadTargetColumns({})
    }
  // eslint-disable-next-line
  },[modalOpen])

  useEffect(()=>{
    if(!scrollRef.current)return;
    let isOverflow = scrollRef.current.scrollHeight > scrollRef.current.clientHeight
    if(targetColumns.docs && targetColumns.docs.length<targetColumns.numFound && !targetColumns.loading && !isOverflow ){
      loadTargetColumns({start:targetColumns.docs.length})
    }
  // eslint-disable-next-line
  },[targetColumns])

  const getSubType = c => {
    return c.object_subtype_txt||c.column_type_txt
  }


  const generateMapItem = (targetColumn,index) => {
    let searchValue = columnSearchValue[targetColumn.id]
    const setSearchValue = value => setColumnSearchValue({...columnSearchValue,[targetColumn.id]:value})
    return (
      <div style={{display:'flex',alignItems:'flex-start',padding:'24px 0',borderTop:index===0?`2px solid ${theme.palette.listItemDivider.main}`:undefined,borderBottom:`2px solid ${theme.palette.listItemDivider.main}`}}>
        <div style={{flex:'1 1 65%',marginRight:16,overflow:'hidden'}}>
          <div style={{maxWidth:500,marginBottom:12}}>
            <SearchSelector
              url='/solr/search/select'
              testID={`column-search-${targetColumn.id}`}
              params={{
                q: `*`,
                fq:`name_srt:(${getPartialMatchSearchString(searchValue||'', true)||'*'}) AND hierarchy_parent_id_srt:${source.data.obj.id} AND object_type_srt:(COLUMN OR DATASET_FIELD)` + 
                    (mappedColumn[targetColumn.id]?.length>0?` AND -id:(${mappedColumn[targetColumn.id].map(t=>t.id).join(' OR ')})`:'')
                ,
                rows:10,
                sort:'name_srt asc'
              }}
              height={40}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              placeholder={`Search for a ${childTypeName} to map to ${targetColumn.name_txt}`}
              autoSuggestion
              suggestionMinWidth={700}
              scrollable
              renderResults={items=>{
                if(items.length===0){
                  return [
                    <ListItem>
                      <div style={{display:'flex',alignItems:'center'}}>
                        <Typography style={{marginLeft:12,color:theme.palette.primaryText.main}}>No suggestions found</Typography>
                      </div>
                    </ListItem>
                  ]
                }
                return items.map((item,index)=>(
                  <ListItem button onClick={()=>{onClickColumnResult(targetColumn.id,item);setSearchValue('')}}>
                    <div style={{display:'flex',alignItems:"center",overflow:'hidden',width:'100%'}}>
                      {getIconComponent({label:item.object_type_txt,size:24,colour:theme.palette.primary.main})}
                      <Typography style={{marginLeft:12,flexGrow:1,color:theme.palette.primaryText.main,fontSize:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                        {item.name_txt} <span style={{color:theme.palette.secondary.main,marginLeft:12,fontSize:12,position:'relative',top:-1}}>{getSubType(item)}</span>
                      </Typography>
                      {
                        item.name_txt===targetColumn.name_txt && 
                        <div style={{fontSize:13,padding:'0px 9px',flexShrink:0,height:18,borderRadius:9,display:'flex',alignItems:'center',background:theme.palette.chip.main,color:theme.palette.primaryText.main,border:`1px solid ${theme.palette.chipBorder.main}`}}>
                          matches name
                        </div>
                      }
                    </div>
                  </ListItem>
              ))}}
            />
          </div>
          {
            mappedColumn[targetColumn.id]?.length>0 &&
            <div style={{paddingLeft:16}}>
              <Typography className={classes.linkedSectionHeader}>MAPPED {childTypeName.toUpperCase()}(S)</Typography>
              <div style={{display:'flex',flexWrap:'wrap'}}>
                {
                  mappedColumn[targetColumn.id].map(c=>(
                    <div className={classes.chip}>
                      <Typography className={classes.chipText}>{c.name_txt || c.name}{getSubType(c)?` (${getSubType(c)})`:''}</Typography>
                      {
                        c.isAuto?
                        <div style={{width:16,height:16,marginLeft:8,marginRight:6}}>
                          {getIconComponent({label:'auto',size:16,colour:theme.palette.background.main})}
                        </div>
                        :
                        <IconButton className={classes.iconButton} onClick={()=>onRemoveColumn(targetColumn.id,c)}>
                          {getIconComponent({label:'clear',size:13.75,colour:theme.palette.background.main})}
                        </IconButton>
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          }
          {
            suggestionColumns[targetColumn.id]?.filter(c=>!mappedColumn[targetColumn.id]?.find(mc=>mc.id===c.id)).length>0 && !mappedColumn[targetColumn.id]?.length>0 &&
            <div style={{paddingLeft:16}}>
              <Typography className={classes.linkedSectionHeader}>SUGGESTED {childTypeName.toUpperCase()}(S)</Typography>
              <div style={{display:'flex',flexWrap:'wrap'}}>
                {
                  suggestionColumns[targetColumn.id].filter(c=>!mappedColumn[targetColumn.id]?.find(mc=>mc.id===c.id)).map(c=>(
                    <div className={classes.chip+' '+classes.suggestionChip} onClick={()=>onClickColumnResult(targetColumn.id,c)}>
                      <Typography className={classes.chipText+' '+classes.suggestionChipText}>{c.name_txt || c.name}{getSubType(c)?` (${getSubType(c)})`:''}</Typography>
                    </div>
                  ))
                }
              </div>
            </div>
          }
        </div>
        <div style={{flex:'1 1 35%',marginRight:16,overflow:'hidden'}}>
          <Typography className={classes.columName} style={{marginTop:8,marginRight:16}}>
            {targetColumn.name_txt} <span style={{color:theme.palette.secondary.main,marginLeft:12,fontSize:12,position:'relative',top:-1}}>{getSubType(targetColumn)}</span>
          </Typography>
        </div>
      </div>
    )
  }

  let padding = getContentContainerStyle().paddingLeft
  let maxWidth = getContentContainerStyle().width
  
  return (
    <Modal open={modalOpen} onEscapeKeyDown={()=>setModalOpen(false)} classes={{root:classes.modalRoot}}>
      <div style={{width:'100vw',height:'100vw',paddingLeft:40,background:theme.palette.background.main,display:'flex',justifyContent:'center',boxSizing:'border-box'}}>
        <div          
          ref={scrollRef}
          onScroll={(event)=>{
            if(event.target.scrollTop>=event.target.scrollHeight-event.target.clientHeight-10){
              if(targetColumns.docs?.length && !targetColumns.loading &&  targetColumns.numFound>targetColumns.docs.length){
                loadTargetColumns({start:targetColumns.docs.length})
              }
            }
          }} 
          style={{maxWidth,width:'100%',height:'100vh',overflow:'auto',paddingLeft:padding}} 
        >
          <div style={{display:'flex',alignItems:'flex-start',justifyContent:'space-between',paddingRight:padding,marginTop:26,paddingBottom:4,paddingTop:24,position:'sticky',top:0,background:theme.palette.background.main,zIndex:99}}>
            <div style={{display:'flex',alignItems:'center',flexGrow:1}}>
              <div className={classes.profileIcon} >
                {getIconComponent({ label: 'map_column', size: 40, colour: theme.palette.header.main })}
              </div>
              <Typography style={{fontSize:24,color:theme.palette.header.main,flexGrow:1}}>
                Map columns between {source.data.obj.name_txt} and {target.data.obj.name_txt}
              </Typography>
            </div>
            <Button id={"mapped-close-button"} color='primary' variant='outlined' onClick={()=>setModalOpen(false)}>CLOSE</Button>
          </div>

          <Diagram sourceParent={sourceParent} targetParent={targetParent} source={source} target={target} paddingRight={padding} />

          <div style={{display:'flex',overflow:'hidden',paddingRight:padding,position:'sticky',paddingTop:16,paddingBottom:8,top:60,background:theme.palette.background.main,zIndex:99}}>
            <div style={{flex:'1 1 65%',marginRight:16,overflow:'hidden'}}>
              <Typography className={classes.sectionHeader}>Select from {source.data.obj.name_txt} {childTypeName}(s)</Typography>
            </div>
            <div style={{flex:'1 1 35%',overflow:'hidden',marginRight:16}}>
            <Typography className={classes.sectionHeader}>{target.data.obj.name_txt} {childTypeName}(s)</Typography>
            </div>
          </div>
          <div 
            style={{flexShrink:1,marginTop:16,marginBottom:24,paddingRight:padding,paddingBottom:24}}
          >
            {
              targetColumns.docs?.map(generateMapItem)
            }
            {
              targetColumns.numFound===0 && 
              <Typography>No {childTypeName} found</Typography>
            }
            <div style={{display:'flex',justifyContent:'center',marginTop:8}}>
              {
                targetColumns.loading &&
                <CircularProgress color='secondary'/>
              }
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

ColumnMapper.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ColumnMapper));