import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Select, MenuItem, Button, Checkbox, Typography, IconButton, InputBase } from '@material-ui/core';
import { getIconComponent } from '../../../utilities';
import KTooltip from '../KTooltip/KTooltip';

const styles = theme => ({
  selector: {
    ...theme.components.selector,
    width:'100%',
    '& div div': {
      paddingLeft: 0,
      paddingTop:12,
      paddingBottom:11,
      fontSize:16
    },
    '& p':{
      overflow:'hidden'
    }
  },
  selectorText:{
    paddingLeft:`16px !important`,
    color:theme.palette.primaryText.light,
    fontSize:16
  },
  button:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    textTransform:'none',
    padding:0,
    marginTop:2,
    marginLeft:16,
    '& span':{
      fontSize:12
    }
  },
  inputBase:{
    ...theme.components.inputBase,
    height:44,
    width:'100%'
  },
  menu:{
    '& ul':{
      paddingTop:0,
      minWidth:450,
      maxWidth:'50vw',
    },
  },
  valueList:{
    width:450,
    maxHeight:365,
    overflowY:'auto',
    ...theme.components.customScroll,
  },
  menuItem:{
		padding:'10px 10px 10px 16px',
    display:'flex',
    alignItems:'center',
    width:416,
    // boxSizing:'border-box',
    overflow:"hidden",
    whiteSpace:"nowrap",
    textOverflow:"ellipsis",
		color:theme.palette.primaryText.main,
		'&:hover':{
      background: theme.palette.hovered.main 
    },
    '& span':{
      overflow:"hidden",
      whiteSpace:"nowrap",
      textOverflow:"ellipsis",
    }
	},
  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  checkbox:{
    padding:4,
    paddingLeft:0,
    marginRight:8,
    marginLeft:-2
  },
  groupTitle:{
    fontSize:12,
    color:theme.palette.primary.main,
    letterSpacing:2,
    marginBottom:8,
    marginLeft:16
  }
})

const ClearableSelector = props => {

  const {
    classes,
    theme,
    value,
    options,
    onChangeValue,
    onReset,
    multiple,
    renderValue,
    width=180,
    autoWidth,
    title,
    hideReset,
    enableSearch,
    searchValue,
    onSearchChange,
    onPaginate,
    testID,
  } = props;

  if(!options)return <></>

  const onListScroll = (event) => {
    if(event.target.scrollTop>=event.target.scrollHeight-event.target.clientHeight-10){
      onPaginate?.();
    }
  }

  let processedOptions;
  if(options.some(el=>!el.group)){
    processedOptions = options.map(el=>(
      <MenuItem 
        data-test-id={`menu-item-${el.value}`} 
        className={classes.menuItem} 
        value={el.value}
        onClick={()=>{
          if(multiple){
            if(value.includes(el.value)){
              onChangeValue({target:{value:value.filter(v=>v!==el.value)}})
            }else{
              onChangeValue({target:{value:[...value,el.value]}})
            }
          }else{
            onChangeValue({target:{value:el.value}})
          }
        }}
      >
        {
          multiple && el.value!=='all' && 
          <Checkbox 
            color='primary'
            checked={value.includes(el.value)}
            className={classes.checkbox}
          />
        }
        <KTooltip title={el.name}>
          <span>{el.name}</span>
        </KTooltip>
      </MenuItem>
    ))
  }else if(options.length===0){
    processedOptions = <Typography style={{marginTop:12,marginLeft:16,marginBottom:8}}>No results found</Typography>
  }
  else{
    let groups = [];
    options.forEach(el=>{
      if(!groups.includes(el.group))groups.push(el.group)
    })
    processedOptions = groups.map(el=>
      (
        <div style={{marginBottom:16}} onClick={event=>{event.stopPropagation()}} >
          <Typography onClick={event=>{event.stopPropagation()}} className={classes.groupTitle}>{el}</Typography>
          {
            options.filter(o=>o.group===el).map(o=>(
              <MenuItem 
                onClick={event=>{onChangeValue({target:{value:o.value}})}} 
                className={classes.menuItem} 
                data-test-id={`menu-item-${o.value}`}
                value={o.value}
                style={{fontSize:13.75}}
              >
                <KTooltip title={o.name}>
                  <span>{o.name}</span>
                </KTooltip>
              </MenuItem>
            ))
          }
        </div>
      )
    )
  }

  return (
    <div style={autoWidth?{width:'100%'}:{width}}>
      {
        title && <Typography color='primary' style={{fontSize:12,letterSpacing:2,marginBottom:8}}>{title}</Typography>
      }
      <Select
        className={classes.selector}
        value={value}
        multiple={multiple}
        // onChange={onChangeValue}
        disableUnderline
        data-test-id={testID}
        renderValue={
          renderValue || 
          (value=>
            multiple?
              value.length===options.length?'All':value.map(v=>(options.find(o=>o.value===v).dispName||options.find(o=>o.value===v).name)+'').join(', ')
              :
              options.find(o=>o.value===value)?
                options.find(o=>o.value===value).name+'':
                value+''
          )}
        inputProps={{className:classes.selectorText}}
        MenuProps={{
          classes:{
            paper:classes.selectPaper,
          },
          className:classes.menu,
          getContentAnchorEl:null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            horizontal: "left"
          },
        }}
      > 
        <div>
          {
            enableSearch && 
            <div style={{width:450}} onClick={event=>{event.stopPropagation();event.preventDefault();}}>
              <InputBase
                className={classes.inputBase}
                value={searchValue}
                onChange={event=>onSearchChange(event.target.value)}
                placeholder={`Search filter value`}
                endAdornment={
                  <div style={{display:'flex',alignItems:'center'}}>
                    <IconButton id={`filter-clear-search-button`} disabled={searchValue===''} onClick={()=>onSearchChange('')} style={{padding:6,marginRight:16}}>
                      {getIconComponent({label:searchValue===''?'search':'clear',colour:theme.palette.primaryText.light,size:24})}
                    </IconButton>
                  </div>
                }
              />
            </div>
          }
          <div className={classes.valueList} onScroll={onListScroll}>
            {processedOptions}
          </div>
        </div>
      </Select>
      {!hideReset && <Button onClick={onReset} data-test-id={testID?`${testID}-clear-button`:undefined} className={classes.button}>Clear filter</Button>}
    </div>
  )
}

ClearableSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  renderValue: PropTypes.func,
  onReset: PropTypes.func,
  width: PropTypes.number,
  autoWidth:PropTypes.bool,
  title: PropTypes.string,
  hideReset: PropTypes.bool,
  testID: PropTypes.string,
  enableSearch: PropTypes.bool,
  searchValue: PropTypes.string,
  onSearchChange: PropTypes.func,
  onPaginate: PropTypes.func,
}

export default withTheme()(withStyles(styles)(ClearableSelector));