import {
	Avatar,
	IconButton,
	Typography,
	withStyles,
	withTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import {
	getIconComponent,
	getInitials,
	getNameSearchQuery
} from "../../../utilities";
import SearchSelector from "./SearchSelector";

const styles = (theme) => ({
	chip: {
		backgroundColor: "#EEE",
		borderRadius: 5,
		display: "inline-block",
		marginRight: 5,
		marginTop: 5,
		cursor: "pointer",
		padding: "0.5rem 0.75rem",
		"&:hover $avatar": {
			backgroundColor: theme.palette.primary.main,
		},
		"&:hover": {
			backgroundColor: "#DDD",
		},
	},
	avatar: {
		fontSize: 12,
		height: 24,
		width: 24,
		backgroundColor: theme.palette.avatar.main,
		marginRight: 12,
	},
	IconButton: {
		width: 20,
		height: 20,
		marginLeft: 16,
	},
	assigneeChip: {
		width: "max-content",
		backgroundColor: theme.palette.chip.main,
		border: `1px solid ${theme.palette.border.main}`,
		borderRadius: 5,
		marginRight: 4,
		marginBottom: 4,
		padding: "4px 12px 4px 16px",
		display: "flex",
		alignItems: "center",
		minHeight: 32,
	},
	normalText: {
		color: theme.palette.primaryText.main,
	},
});

function TeamSelector(props) {
	const {
		classes,
		theme,
		selectedGroup,
		setGroup,
		suggestedGroups,
		clearTeam,
		url,
		autoSuggestion,
		params,
		renderResults,
		searchValue,
		setSearchValue,
		leftIcon,
		hideTeamChip,
		multiple,
		removeFLModify,
	} = props;

	let selectedArr = [];
	if (selectedGroup) {
		if (multiple) selectedArr = selectedGroup;
		else {
			selectedArr = [selectedGroup];
		}
	}

	return (
		<div>
			<div style={{ marginBottom: 20 }}>
				<SearchSelector
					url={url || "/solr/search/select"}
					params={
						params || {
							q: getNameSearchQuery(searchValue),
							fq: `object_type_srt:TEAM`,
						}
					}
					autoSuggestion={autoSuggestion}
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					placeholder="Search for a team"
					onResultClick={(el) => {
						setSearchValue("");
						setGroup && setGroup(el);
					}}
					removeFLModify={removeFLModify || false}
					renderResults={renderResults}
					leftIcon={leftIcon}
				/>
			</div>
			{suggestedGroups &&
				suggestedGroups.map((el) => (
					<div>
						<Typography
							style={{ color: theme.palette.primaryText.light, fontSize: 12 }}
						>
							Suggested teams
						</Typography>
						<div
							className={classes.chip}
							onClick={() => {
								setGroup && setGroup(el);
							}}
						>
							<div
								style={{
									marginRight: 10,
									display: "inline-block",
									verticalAlign: "middle",
								}}
							>
								<Avatar align="center" className={classes.avatar}>
									{getInitials(el.name)}
								</Avatar>
							</div>
							<Typography
								style={{ display: "inline-block", verticalAlign: "middle" }}
							>
								{el.name + " " + el.suggestText || ""}
							</Typography>
						</div>
					</div>
				))}
			{selectedArr.length > 0 ? (
				<div
					style={{
						display: "flex",
						flexWrap: "wrap",
						marginBottom: 12,
						marginTop: 16,
					}}
				>
					{selectedArr.map((el) => (
						<div className={classes.assigneeChip}>
							<Avatar align="center" className={classes.avatar}>
								{getInitials(el.name || el.name_txt || el.name_txt)}
							</Avatar>
							<Typography style={{ color: theme.palette.primaryText.main }}>
								{el.name || el.name_txt || el.name_txt}
							</Typography>
							{clearTeam && (
								<IconButton
									className={classes.IconButton}
									onClick={() => clearTeam(el)}
								>
									{getIconComponent({
										label: "clear",
										size: 20,
										colour: theme.palette.primaryText.light,
									})}
								</IconButton>
							)}
						</div>
					))}
				</div>
			) : hideTeamChip ? undefined : (
				<div
					className={classes.assigneeChip}
					style={{ marginTop: -8, marginBottom: 16 }}
				>
					{getIconComponent({
						label: "help",
						size: 28,
						colour: theme.palette.primaryText.light,
					})}
					<Typography
						className={classes.normalText}
						style={{ marginRight: 16, marginLeft: 12 }}
					>
						No team selected
					</Typography>
				</div>
			)}
		</div>
	);
}

TeamSelector.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	selectedGroup: PropTypes.object.isRequired,
	setGroup: PropTypes.object.isRequired,
	suggestedGroups: PropTypes.object,
	clearTeam: PropTypes.func.isRequired,
	url: PropTypes.string,
	params: PropTypes.object,
	renderResults: PropTypes.func,
	searchValue: PropTypes.string.isRequired,
	setSearchValue: PropTypes.func.isRequired,
	leftIcon: PropTypes.object,
};

export default withTheme()(withStyles(styles)(TeamSelector));
