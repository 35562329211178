import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Dashboard from '../../UI/Dashboard/DirectIndirectDashboard/DirectIndirectDashboard';
import Description from '../Details/Description';
import FieldList from '../FieldList/FieldList';
// import History from '../History/History';
import Issues from '../../UI/ProfileIssueSection/Issues';
import NewLineageContainer from '../../UI/Lineage/NewLineageContainer';
import NoteAdder from '../../UI/NoteAdder/NoteAdder';
import ChangeManager from '../../UI/ChangeManager/ChangeManager';
import DQInsights from '../../UI/DQInsights/DQInsights';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background:theme.palette.background.main
  },
})

function Body(props) {

  const {
    classes,
    tabState,
    history,
    data,
    fetchList,
    state,
    dispatch,
    sessionData,
    setLinkModalOpen,
    tabOptions,
    isShell,
    isLineageLinkable
  } = props;

  let bodyContent;
  switch (tabOptions[tabState]) {
    case 'FIELDS':
      bodyContent = (
        <FieldList
          history={history}
          data={data}
          state={state}
          dispatch={dispatch}
          isShell={isShell}
        />
      );
      break;
    case 'DETAILS': // details
      bodyContent = (
        <Description
          nodeKey={state.basicData.nodeKey}
          setLinkModalOpen={setLinkModalOpen}
          label='dataset_table'
          history={history}
          fetchList={fetchList}
          userName={sessionData.user_name}
          state={state}
          sessionData={sessionData}
          dispatch={dispatch}
        />
      )
      break;
    case 'KNOWLEDGE':
      bodyContent = (
        <NoteAdder
          history={history}
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.noteModalOpen}
          setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
        />
      )
      break;
    case 'USAGE': // summary
      bodyContent = (
        <Dashboard
          history={history}
          state={state}
          label='dataset_table'
          dispatch={dispatch}
          drillThrough={true}
        />
      )
      break;
    case 'QUALITY':
      bodyContent = (
        <DQInsights
          state={state}
          history={history}
          dispatch={dispatch}
          object={state.basicData}
        />
      )
      break;
    case 'LINEAGE': // Lineage
      bodyContent = (
        <NewLineageContainer
          history={history}
          state={state}
          dispatch={dispatch}
          isLinkable={isLineageLinkable}
        />
      )
      break;
    case 'ISSUES':
      bodyContent = (
        <Issues
          history={history}
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    case 'CHANGES':
      bodyContent = (
        <ChangeManager state={state} dispatch={dispatch} history={history}/>
      )
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
  tabUrlPrefix: PropTypes.string,
  nodeKey: PropTypes.string,
  data: PropTypes.object,
  history: PropTypes.object.isRequired,
  fetchList: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.object.isRequired,
  sessionData: PropTypes.object,
}

export default withStyles(styles)(Body);