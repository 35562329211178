
import React from 'react';
import { Typography, CircularProgress, Button, Switch} from '@material-ui/core';
import DataTile from '../../Components/DataTile'
import KTooltip from '../../../UI/KTooltip/KTooltip';
import QuestionBoardChip from '../../../QuestionBoard/QuestionBoardChip/QuestionBoardChip';
import LineChart from '../../Components/LineChart';
import { processObjectName } from '../loadUtils';
import { getIconComponent, mapSearchObjectName } from '../../../../utilities';
import theme from '../../../../theme';
import { frequencyExplainText } from '../../Components/utils';
import BarChartVertical from '../../Components/BarChartVertical';

export const generateDataTiles = ({classes, data, setData}) => {
  return (
    <div style={{display:'flex',width:'100%',marginBottom:24}}>
      {
        data.tileDataLoading && <CircularProgress color='secondary' style={{margin:'0px auto'}} />
      }
      {
        data.tileDataError && <Typography className={classes.normalText}>Error occurred loading details</Typography>
      }
      {
        data.tileData && data.tileData.map((el,index)=>(
          <DataTile tooltip={el.tooltip} clickable={el.clickable} tileId={el.id} onClick={()=>{if(el.clickable){setData({...data,selectedTile:el.id})}}} selected={data.selectedTile===el.id} title={el.title} value={el.value} footer={el.footer}/>
        ))
      }
      {
        data.tileData && data.tileData.length===0 && <Typography className={classes.normalText}>No data to display</Typography>
      }
    </div>
  )
}

export const generateChartGroup = ({classes,data,tabState,tabOptions, state, dispatch}) => {
  if(data[`${data.selectedTile}_graphDataLoading`]){
    return <div style={{textAlign:'center',marginTop:36,marginBottom:24}}><CircularProgress color='secondary'/></div>
  }
  if(data[`${data.selectedTile}_graphDataError`]){
    return <Typography className={classes.normalText} style={{marginBottom:24}}>Error occurred loading graphs</Typography>
  }
  if(!data[`${data.selectedTile}_graphData`] || !data.selectedTile){
    return <div></div>
  }
  let graphData = data[`${data.selectedTile}_graphData`];
  let chartData = [];
  let objectType = data.selectedTile;
  let displayObjectType = processObjectName(objectType);
  let upperCaseObjectType = displayObjectType.toUpperCase();
  let title, subTitle, tooltip, id;

  switch(tabOptions[tabState]){
    case 'SUMMARY':

      title = `${displayObjectType}s by trust score`
      subTitle = `Count of ${displayObjectType}s by trust score`
      id = `${objectType}_BY_TRUST`
      if(graphData[id]){
        chartData.push({type:'bar',width:'80%',height:260,title,subTitle,series:graphData[id].series,labels:graphData[id].labels})
      }
    
      title = `${displayObjectType}s used last 30 days`
      subTitle = `Count of unique ${displayObjectType}s used last 30 days`
      id = `${objectType}_USED_PER_DAY`
      if(graphData[id]){
        chartData.push({type:'line',width:'80%',height:260,title,subTitle,series:graphData[id].series})
      }

      break;
    case `CONTENT BY USAGE`:
      title = `Content by usage`
      subTitle = `Count of content by usage`
      tooltip = frequencyExplainText;
      id = `${objectType}_BY_USAGE`
      if(graphData[id]){
        chartData.push({type:'bar',width:'80%',height:260,title,subTitle,series:graphData[id].series,labels:graphData[id].labels, tooltip, toggleLabel:graphData[id].toggleLabel})
      }
      break;
    case `${upperCaseObjectType}S BY USAGE`:
      title = `${displayObjectType}s by usage`
      subTitle = `Count of ${displayObjectType}s by usage`
      tooltip = frequencyExplainText;
      id = `${objectType}_BY_USAGE`
      if(graphData[id]){
        chartData.push({type:'bar',width:'80%',height:260,title,subTitle,series:graphData[id].series,labels:graphData[id].labels, tooltip, toggleLabel:graphData[id].toggleLabel})
      }
      break;
    case 'VIEWS BY USAGE':
      title = `Views by usage`
      subTitle = `Count of views by usage`
      tooltip = frequencyExplainText;
      id = `VIEWS_BY_USAGE`
      if(graphData[id]){
        chartData.push({type:'bar',width:'80%',height:260,title,subTitle,series:graphData[id].series,labels:graphData[id].labels, tooltip, toggleLabel:graphData[id].toggleLabel})
      }
      break;
    case `UNUSED & UNKNOWN ${upperCaseObjectType}S BY LOAD FREQUENCY`:
      title = `Unused & Unknown ${displayObjectType}s by load frequency`
      subTitle = `Count of unused & unknown ${displayObjectType}s by load frequency`
      tooltip = ''
      id = `${objectType}_UNUSED_BY_FREQUENCY`
      if(graphData[id]){
        chartData.push({type:'bar',width:'80%',height:260,title,subTitle,series:graphData[id].series,labels:graphData[id].labels, tooltip, toggleLabel:graphData[id].toggleLabel})
      }
      break;
    case `DAILY USED ${upperCaseObjectType}S BY LOAD FREQUENCY`:
      title = `Daily used ${displayObjectType}s by load frequency`
      subTitle = `Count of daily used ${displayObjectType}s by load frequency`
      tooltip = ''
      id = `${objectType}_DAILY_BY_FREQUENCY`
      if(graphData[id]){
        chartData.push({type:'bar',width:'80%',height:260,title,subTitle,series:graphData[id].series,labels:graphData[id].labels, tooltip, toggleLabel:graphData[id].toggleLabel})
      }
      break;
    case `TABLES BY SIZE`:
      title = `Tables by size`
      subTitle = `Total table size by usage`
      tooltip = ''
      id = `TABLE_SIZE_BY_USAGE`
      if(graphData[id]){
        chartData.push({type:'bar',width:'80%',isFileSize:true,height:260,title,subTitle,series:graphData[id].series,labels:graphData[id].labels, tooltip, toggleLabel:graphData[id].toggleLabel})
      }
      break;
    default:
  }

  if(chartData.length===0)return (
    <div style={{marginBottom:24}}>
      <Typography>No data available</Typography>
    </div>
  )
  
  return (
    <div>
      {
        chartData.map(el=>(
          <div className={classes.chartContainer} data-test-id={`chart-${el.title.replace(`${displayObjectType}s `,'').toLowerCase()}`}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
              <div>
                <div style={{display:'flex',alignItems:'center',overflow:'hidden'}}>
                  <Typography className={classes.chartTitle}>{el.title}</Typography>
                  {
                    el.tooltip && 
                    <KTooltip title={el.tooltip}>
                      <div style={{width:16,height:16,marginLeft:6}}>
                        {getIconComponent({label:'info',size:16,colour:theme.palette.primary.main})}
                      </div>
                    </KTooltip>
                  }
                </div>
                <Typography className={classes.chartSubtitle}>{el.subTitle}</Typography>
              </div>
              {
                el.type==='bar' &&
                <div style={{display:'flex',alignItems:'center'}}>
                  <Typography color='primary' style={{fontSize:12,letterSpacing:1,marginRight:0}}>
                    LOG SCALE
                  </Typography>
                  <Switch
                    checked={state.usageLogScale[el.id]}
                    onChange={()=>{
                      dispatch({
                        type:'set_usage_log_scale',
                        usageLogScale:{
                          ...state.usageLogScale,
                          [el.id]:!state.usageLogScale[el.id]
                        }
                      })
                    }}
                    inputProps={{
                      'data-test-id':`${el.id}_log_toggle`
                    }}
                    classes={{icon:classes.switchIcon, bar:classes.switchBar}}
                    value={state.usageLogScale[el.id]}
                    color="primary"
                  />
                </div>
              }
            </div>
            
            {
              el.type==='text' && 
              <div style={{width:el.width,height:el.height}}>
                <Typography style={{marginTop:16}} className={classes.subTitle}>{el.text}</Typography>
              </div>
            }
            {
              el.type==='bar' && 
              <BarChartVertical
                key={el.title}
                id={el.title}
                width={el.width}
                height={el.height}
                series={el.series}
                labels={el.labels}
                isFileSize={el.isFileSize}
                isLog={state.usageLogScale[el.id]}
                toggleLabel={el.toggleLabel}
              />
            }
            {
              el.type==='line' && 
              <LineChart
                key={el.title}
                width={el.width}
                height={el.height}
                series={el.series}
                hideLegend={true}
                hideXGrid={true}
                // colours={series.map(el=>sourceColourMap[el.source_id])}
              />
            }
          </div>
        ))
      }
      
    </div>
  ) 
}



export const generateExtractDownloader = ({  history, data, filterValues, state, dispatch, dbList}) => {

  let button;

  let objectName =  mapSearchObjectName(data.selectedTile);
  if(!objectName)return <div></div>
  let code = `0200`;

  button = (
    <QuestionBoardChip
      key={objectName + filterValues.sourceId}
      buttonText={'USAGE REPORT'}
      buttonOnly
      state={state}
      dispatch={dispatch}
      code={code}
      defaultFilters={{
        source_id:filterValues.sourceId,
        object_type: objectName,
      }}
      removeButtonMargin
    />
  )
  
  return (
    <div style={{display:'flex',justifyContent:'flex-end'}}>
      <Button color='primary' variant='outlined' style={{marginRight:button?16:0}} onClick={()=>history.push(`/profile/source/${dbList.find(d=>d.id===filterValues.sourceId)?.host_ids[0]}`)}>OPEN PROFILE</Button>
      {button}
    </div>
  )
}

export const generateRecommendationList = ({classes, data, tabState, tabOptions}) => {

  if(data[`${data.selectedTile}_recommendationLoading`]){
    return <div style={{textAlign:'center',marginTop:36}}><CircularProgress color='secondary'/></div>
  }

  if(data[`${data.selectedTile}_recommendationError`]){
    return <Typography className={classes.normalText}>Error occurred loading recommendations</Typography>
  }

  if(!data[`${data.selectedTile}_recommendationData`] || !data.selectedTile){
    return <div></div>
  }

  let recommendations = []; 
  let objectType = data.selectedTile;
  let displayObjectType = processObjectName(objectType);
  let upperCaseObjectType = displayObjectType.toUpperCase();

  switch(tabOptions[tabState]){
    case `${upperCaseObjectType}S BY USAGE`:
      recommendations = data[`${data.selectedTile}_recommendationData`].filter(el=>['unused','infrequently_used'].includes(el.id))
      break;
    case 'VIEWS BY USAGE':
      recommendations = data[`${data.selectedTile}_recommendationData`].filter(el=>el.id==='unused_view')
      break;
    case `UNUSED & UNKNOWN ${upperCaseObjectType}S BY LOAD FREQUENCY`:
      let order = ['daily_unused','weekly_unused','monthly_unused', 'daily_unknown','weekly_unknown','monthly_unknown']
      recommendations = data[`${data.selectedTile}_recommendationData`].filter(el=>order.includes(el.id))
      recommendations.sort((a,b)=>order.indexOf(a.id)<order.indexOf(b.id)?-1:1)
      break;
    case `DAILY USED ${upperCaseObjectType}S BY LOAD FREQUENCY`:
      recommendations = data[`${data.selectedTile}_recommendationData`].filter(el=>el.id==='daily_used_stale')
      break;
    case `${upperCaseObjectType}S BY SIZE`:
      recommendations = data[`${data.selectedTile}_recommendationData`].filter(el=>['unused_size','infrequently_used_size'].includes(el.id))
      break;
    default:
      recommendations = []
  }
  
  if(recommendations.length===0)return <Typography className={classes.normalText}>No recommendations currently provided</Typography>
  return (
    <div style={{marginBottom:500}}>
      {
        recommendations.map(el=>(
          <div className={classes.recommendationContainer} onClick={el.onClick}>
            <Typography className={classes.recommendationTitle}>{el.title}</Typography>
            <div className={classes.recommendationCount}>{el.countTxt}</div>
          </div>
        ))
      }
    </div>
  )

}

