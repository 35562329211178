import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, withStyles, withTheme, Typography ,Modal, Paper } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import { getDispFields, getSearchMode, getUsageFreqTooltip, onClickResultItem, toTitleCase } from '../../../utilities'
import { IconButton } from '@material-ui/core';
import { getIconComponent, processCollection, mapObjectName } from '../../../utilities';
import KTooltip from '../KTooltip/KTooltip';
import { useStore } from 'react-redux';
import { checkCollectionLinkable } from '../../../permissionChecker';
import { globalListenerRef } from '../../../GlobalListenerRef';
import SimpleResultItem from '../SearchResults/SimpleResultItem';
import { getIconLabel } from '../SearchResults/utils';
import useAlert from '../../../hooks/useAlert';


const styles = theme => ({
  chip: {
    display: 'flex',
    alignItems: 'center',
    height: 28,
    // paddingTop:6,
    // paddingBottom:6,
    letterSpacing:1,
    fontWeight:400,
    paddingLeft: 8,
    paddingRight: 12,
    borderRadius: 3,
    marginRight: 8,
    fontSize: 12,
    cursor: 'pointer',
    maxWidth: 300,
    marginBottom:6,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow:'ellipsis',
    '&:hover':{
      maxWidth:600,
      width:'max-content',
      filter:'opacity(75%)',
      '& span':{
        textDecoration:'underline',
        textDecorationColor: 'inherit'
      }
    }
  },
  chipText:{
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow:'ellipsis',
  },
  tagClearButton:{
    width:13.75,
    height:13.75,
    marginLeft:6,
    marginRight:-6,
    '&:hover':{
      background:'rgba(150,150,150,0.3)',
    }
  },
  editable:{
    '&:hover':{
      background:`${theme.palette.editHovered.main}`,
      border:`1px solid ${theme.palette.listItemDivider.main}`,
      marginLeft:-7,
      marginTop:-1,
      paddingLeft:6,
    }
  },  
  modalRoot:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    margin:'20vh auto 0',
    width:700,
    maxWidth:'90vw',
  },
  detailModalList:{
    maxHeight:'70vh',
    marginBottom:24,
    paddingLeft:24,
    paddingRight:24,
    ...theme.components.customScroll
  },
  title:{
    color:theme.palette.header.main,
    fontSize:20,
  }
})


const Tags = props => {

  const {
    theme,
    classes,
    isShell,
    data, 
    label = 'default', 
    linkedInstances, 
    linkedInstanceFetch, 
    hoveredTag, 
    setHoveredTag, 
    onClickAddTag,
    // addTagButtonSize,
    // history,
    dispatch,
    tabOptions,
    customTags
  } = props;

  const componentRef = useRef()
  const [isOverflow, setIsOverflow] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  // const [maxHeight, setMaxHeight] = useState(30)

  const [detailModalOpen, setDetailModalOpen] = useState(false)
  const [listButtonHovered, setListButtonHovered] = useState(false)

  let sessionData = useStore().getState().auth.session_user;
  let editable = checkCollectionLinkable({sessionData})

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  
  const setOverflowIcon = () => {
    if(!componentRef.current)return;
    if(componentRef.current.scrollHeight > componentRef.current.clientHeight+6){
      setIsOverflow(true)
      // setMaxHeight(componentRef.current.scrollHeight)
    }else{
      setIsOverflow(false)
    }
  }

  useEffect(()=>{
    setOverflowIcon()

    window.removeEventListener('resize', globalListenerRef.headerTagResizeListener);
    
    globalListenerRef.headerTagResizeListener = setOverflowIcon;
    window.addEventListener('resize', globalListenerRef.headerTagResizeListener)
    
    return () => {
      window.removeEventListener('resize', globalListenerRef.headerTagResizeListener);
    }
    // eslint-disable-next-line
  },[componentRef.current, linkedInstances ])

  if(!data)return <></>

  if (!['data_quality_test','workspace','source','host','content_app','dataset','ml_model','dataset_field','dataset_table','content_child','user','sheet','report','collection','data_pipeline','content','storage','file','query','table','macro','procedure', 'column', 'mode', 'tableau', 'database', 'schema','query cluster','table cluster','location','tool','source','process','issue','collection_instance'].includes(label.toLowerCase())){
    return<></>;
  }
  const onRemoveCollection = id => {
    axiosCerebrum
      .delete(
        `/api/collectioninstances/${id}/related`,
        {params:{
          object_id:data.id,
          relationship:'MEMBERS'
        }}
      )
      .then(response=>{
        linkedInstanceFetch()
        if(detailModalOpen){
          setDetailModalOpen({children:detailModalOpen.children.filter(el=>el.id!==id)})  
        }
      })  
      .catch(error=>{
        console.log(error)
        sendAlert({
          type:'error',
          message:'Error occurred deleting the instance'
        })
      })
  }
  
  
  const onTagClick = (event,tagName, params) => {
    event.stopPropagation()
    let basicSearchValue, basicSearchObjectType, basicSearchQuery;
    if(tagName==='dq_test_failed'){
      tabOptions && tabOptions.includes('QUALITY') && dispatch({type:'set_tab_state',tabState:tabOptions.indexOf('QUALITY')})
      return;
    }
    if(tagName==='dq_tested'){
      basicSearchValue = `number_of_dq_tests_srt:1,[2 TO *]`;
      basicSearchObjectType = mapObjectName(label.toUpperCase())
    }
    if(tagName === 'domain'){
      basicSearchValue = `domain_kc_msrt:${params.domains.map(el=>el.name).join(',')}`;
      basicSearchObjectType = mapObjectName(label.toUpperCase())
    } 
    if(tagName === 'auto_tag'){
      if(params.tag.isGroup){
        setDetailModalOpen({children:params.tag.children})
      }else{
        let parent_name = params.tag.parent_name
        let name = params.tag.obj.name
        basicSearchValue = `${parent_name.replace(/\s/g,'_')}_srt:${name}`;
        if(parent_name==='user usage type')basicSearchValue = `${parent_name.replace(/\s/g,'_')}_msrt:${name}`;
        basicSearchObjectType = mapObjectName(label.toUpperCase())
      }
    }
    if(tagName === 'manual_tag'){
      if(params.tag.isGroup){
        setDetailModalOpen({children:params.tag.children})
      }else{
        window.open(`/profile/collection_instance/${params.tag.id}`,'_blank')
      }
      return;
    }
    if(tagName === 'verified'){
      basicSearchValue = `verified_count:1,[2 TO *]`;
      basicSearchObjectType = mapObjectName(label.toUpperCase())
    } 
    if(tagName==='view'){
      basicSearchValue = `table_type_srt:${params.type}`;
      basicSearchObjectType = mapObjectName(label.toUpperCase())
    }
    if(tagName==='primary_key'){
      basicSearchValue = `primary_key_srt:PRIMARY_KEY`;
      basicSearchObjectType = 'COLUMN'
    }
    if(tagName==='foreign_key'){
      basicSearchValue = `foreign_key_srt:FOREIGN_KEY`;
      basicSearchObjectType = 'COLUMN'
    }
    if(tagName==='reference'){
      basicSearchValue = `reference_srt:YES`;
      basicSearchObjectType = mapObjectName(label.toUpperCase())
    }
    if(tagName==='issueStatus'){
      basicSearchValue = `issue_status_srt:${params.status}`;
      basicSearchObjectType = 'ISSUE'
    }
    if(tagName==='issueType'){
      basicSearchValue = `issue_type_srt:${params.type}`;
      basicSearchObjectType = 'ISSUE'
    }
    if(tagName==='documented'){
      basicSearchValue = `documented_srt:IS_DOCUMENTED`;
      basicSearchObjectType = mapObjectName(label.toUpperCase())
    }
    if(getSearchMode()==='basic_search'){
      window.open(`/basic_search?query=${basicSearchQuery || '*'}${basicSearchValue?`&presetFilter=${basicSearchValue}`:''}&object=${basicSearchObjectType}`,'_blank');
    }
  }
  
  const generateTagToolTip = (colour, name, parent_name) => {
    let str = ''
    if(colour==='#00D46A'){
      str += 'Strong trust attribute - '
      if(parent_name.toLowerCase()==='usage'){
        str += 'Regularly used'
      }
      else{
        str += `${name}`
      }
    }
    if(colour==='#E4E4E4'){
      str += 'Weak trust attribute - '
      if(name.toLowerCase()==='usage: infrequent'){
        str += 'Infrequently used'
      }
      else if(name.toLowerCase()==='usage: unused'){
        str += 'Unused in the last 120 days'
      }
      else{
        str += `${name}`
      }
    }
    if(colour==='#FFAB00'){
      str += 'Profiled or linked by K - '
      if(parent_name.toLowerCase()==='load frequency'){
        str += 'Frequency of data refresh'
      }
      else if(parent_name.toLowerCase()==='load status'){
        str += 'Status of last load'
      }
      else if(name.toLowerCase()==='zone: consumption'){
        str += 'Primarily used by Users'
      }
      else if(name.toLowerCase()==='zone: adhoc'){
        str += 'Created and used by Users'
      }
      else if(name.toLowerCase()==='zone: operational'){
        str += 'Primarily used by System Users / Applications'
      }
      else{
        if(name.split(':')[0]==='Usage'){
          str += `${getUsageFreqTooltip(name.split(':')[1]?.trim())}`
        }else{
          str += `${name}`        
        }
      }
    }
    if(colour==='#FC642D'){
      str = 'Data Governance or Management Label added by Users'
    }
    return str
  }


  let chipArr = [];


  if(label==='column'){
    if(data.primary_key_txt==='PRIMARY_KEY'){
      chipArr.push(
        <div 
          className={classes.chip}
          onClick={(event) => onTagClick(event,'primary_key')}
          style={{ backgroundColor: '#b8d2ff' }}
          data-test-id="header-tag-primary-key"
        >
          <span className={classes.chipText}>{'Primary Key'}</span>
        </div>
      )
    }
    if(data.foreign_key_txt==='FOREIGN_KEY'){
      chipArr.push(
        <div 
          className={classes.chip}
          onClick={(event) => onTagClick(event,'foreign_key')}
          style={{ backgroundColor: '#b8d2ff' }}
          data-test-id="header-tag-foreign-key"
        >
          <span className={classes.chipText}>{'Foreign Key'}</span>
        </div>
      )
    }
  }

  if(isShell){
    chipArr.push(
      <div 
        className={classes.chip}
        data-test-id="header-tag-reference"
        onClick={(event) => onTagClick(event,'reference')}
        style={{ backgroundColor: '#E6F0F2' }}>
          Reference
      </div>
    )
  }

  if(data.table_type_txt && data.table_type_txt!=='TABLE'){
    chipArr.push(
      <div 
        className={classes.chip}
        onClick={(event) => onTagClick(event,'view',{type:data.table_type_txt})}
        style={{ backgroundColor: '#b8d2ff' }}
        data-test-id="header-tag-table-type"
      >
        <span className={classes.chipText}>{toTitleCase(data.table_type_txt)}</span>
      </div>
    )
  }
  
  if(!isNaN(data.number_of_dq_tests_srt) && data.number_of_dq_tests_srt>0 && data.object_type_txt!=='DATA_QUALITY_TEST'){
    chipArr.push(
      <div 
        className={classes.chip}
        onClick={(event) => onTagClick(event,'dq_tested')}
        style={{ backgroundColor: '#00D46A' }}
        data-test-id="header-tag-dq-tested"
      >
        <span className={classes.chipText}>{'DQ Tested'}</span>
        <div style={{width:16,height:16,marginLeft:8}}>
          {getIconComponent({label:'auto',size:16,colour:'#000'})}
        </div>
      </div>
    )
  }

  if(data.data_quality_failed_txt==='YES'){
    chipArr.push(
      <div 
        className={classes.chip}
        onClick={(event) => onTagClick(event,'dq_test_failed')}
        style={{ backgroundColor: '#F44137' }}
        data-test-id="header-tag-dq-failed"
      >
        <span className={classes.chipText} style={{color:'#fff'}}>DQ Test Failed</span>
      </div>
    )
  }


  if (data.description && data.description.trim()!=='' && !['tool','source','collection','collection_instance'].includes(label) && data.object && (data.object.name!=='COLLECTION_INSTANCE'||!data.parent|| (data.parent.category!=='KNOWLEDGE' && data.parent.name!=='List'))){
    chipArr.push(
      <KTooltip key="documented_tag" title="Strong trust attribute - Description documented" classes={{tooltip:classes.tooltip}}>
        <div 
          className={classes.chip}
          onClick={(event) => onTagClick(event,'documented')}
          style={{ backgroundColor: '#00D46A' }}
          data-test-id="header-tag-documented"
        >
          <span className={classes.chipText}>{'Documented'}</span>
          <div style={{width:16,height:16,marginLeft:8}}>
            {getIconComponent({label:'auto',size:16,colour:'#000'})}
          </div>
        </div>
      </KTooltip>
    )
  }

  let autoCollections = linkedInstances?linkedInstances.filter(el=>el.isAuto):[];
  if (autoCollections.length>0) {
    chipArr = [
      ...chipArr,
      ...processCollection(autoCollections,'auto','header','cerebrum').tags.map(el => (
        <KTooltip key={el.id} title={generateTagToolTip(el.colour,el.name,el.parent_name)} classes={{tooltip:classes.tooltip}}>
          <div
            className={classes.chip}
            onClick={(event) => onTagClick(event,'auto_tag', {tag:el})}
            style={{ backgroundColor: el.colour,color:el.fontColour }}
            data-test-id={`header-tag-${el.parent_name.replace(/\s/g,'-')}`}
          >
            <span className={classes.chipText}>{el.name}</span>
            <div style={{width:16,height:16,marginLeft:8}}>
              {getIconComponent({label:'auto',size:16,colour:el.fontColour})}
            </div>
          </div>
        </KTooltip>
      ))
    ]
  }
  let manualCollections = linkedInstances?linkedInstances.filter(el=>!el.isAuto):[];
  if (manualCollections.length>0) {
    chipArr = [
      ...chipArr,
      ...processCollection(manualCollections,'manual','header','cerebrum').tags.map(el => (
        <KTooltip key={el.id} title={generateTagToolTip(el.colour,el.name,el.parent_name)} classes={{tooltip:classes.tooltip}}>
          <div
            key={el.id}
            onMouseEnter={()=>setHoveredTag(el.id)}
            onMouseLeave={()=>setHoveredTag()}
            className={classes.chip}
            data-test-id={`header-tag-${el.parent_name.replace(/\s/g,'-')}`}
            onClick={(event) => {onTagClick(event,'manual_tag', {tag:el})}}
            style={{ backgroundColor: el.colour, color: el.fontColour, maxWidth: 300 }}
          >
            <span className={classes.chipText}>{el.name.length > 33 ? el.name.slice(0, 30) + '...' : el.name}</span>
            {
              hoveredTag===el.id && !el.isGroup && !el.obj?.relationship?.includes('_AUTO') && editable && 
              <IconButton className={classes.tagClearButton} onClick={event=>{event.stopPropagation();onRemoveCollection(el.id)}}>
                {getIconComponent({label:'clear',size:13.75,colour:el.fontColour})}
              </IconButton>
            }
            {
              (el.isGroup?el.children[0]:el).obj.relationship.includes('_AUTO') &&
              <div style={{width:16,height:16,marginLeft:8}}>
                {getIconComponent({label:'auto',size:16,colour:el.fontColour})}
              </div>
            }
          </div>
        </KTooltip>
      ))
    ]
  }
  
  if(label==='issue'){
    // if(data.type && data.type.toLowerCase()!=='set_by_external_source'){
    //   chipArr.push(
    //     <div 
    //       className={classes.chip}
    //       onClick={(event) => onTagClick(event,'issueType',{type:data.type})}
    //       style={{ backgroundColor: '#FA3A37',color:'#fff'}}
    //       data-test-id={`header-tag-issue-type`}
    //     >
    //       <span className={classes.chipText}>{data.type.split('_').join(' ')}</span>
    //     </div>
    //   )
    // }
    // chipArr.push(
    //   <div 
    //     className={classes.chip}
    //     onClick={(event) => onTagClick(event,'issueStatus',{status:data.status})}
    //     style={{ backgroundColor: '#E6F0F2',color:'#000' }}
    //     data-test-id={`header-tag-issue-status`}
    //   >
    //     <span className={classes.chipText}>{data.status}</span>
    //   </div>
    // )
  }
  if(customTags){
    customTags.forEach(el=>{
      chipArr.push(
        <div data-test-id={`header-tag-custom-${el.name.replace(/\s/g,'-')}`} className={classes.chip} onClick={el.onClick} style={{color:el.fontColour,background:el.colour}}>
          {el.name}
        </div>
      )
    })
  }    
  
  return (
    <div style={{display:'flex',overflow:'visible',marginTop:4,marginBottom:2}}>
      <div 
        ref={componentRef}
        id='profile-header-tags' 
        className={onClickAddTag && editable?classes.editable:undefined} 
        onClick={editable && onClickAddTag} 
        style={{ display: 'flex',paddingTop:6,borderRadius:3,flexWrap:'wrap', overflow: 'hidden',height:isExpanded?'max-content':34,flexGrow:1,transition:'height .08s ease' }}
      >
        {chipArr}
        {
          chipArr.length===0 && onClickAddTag && editable && 
          <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:0}}><span style={{fontSize:18,position:'relative',bottom:-1,marginRight:4}}>+</span> LINK COLLECTION</Typography>
        }
      </div>
      {
        isOverflow && 
        <Button 
          variant='outlined'
          style={{padding:0,marginTop:6,marginBottom:6,minWidth:20,marginLeft:8,border:`1px solid ${theme.palette.primaryText.light}`}}
          onClick={()=>{
            setIsExpanded(!isExpanded);
            setTimeout(()=>{
              window.postMessage({updated_profile_layout:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
            })
          }}
        >
          {getIconComponent({
            label:isExpanded?'triangle_up':'triangle_down',
            size:28,
            colour:theme.palette.primaryText.light
          })}
        </Button>
      }

      <Modal open={detailModalOpen} disableBackdropClick>
        <div style={{width:'max-content',margin:'auto',outline:'none'}}>
          {
            detailModalOpen && 
            <Paper className={classes.modalRoot}>
              <div style={{display:'flex',alignItems:'center',justifyContent:"space-between",padding:'24px',zIndex:10,background:theme.palette.background.main}}>
                <Typography className={classes.title}>LINKED TO</Typography>
                <Button color='primary' variant='outlined' style={{padding:4}} onClick={()=>setDetailModalOpen(false)}>CLOSE</Button>
              </div>
              <div className={classes.detailModalList}>
                {
                  detailModalOpen.children.map(el=>(
                    <SimpleResultItem
                      title={getDispFields(el.obj,'dispTitle')}
                      label={getIconLabel({label:'collection_instance',item:el.obj})}
                      item={el.obj}
                      // subTitle={getDispFields(el.obj,'dispSubtitle')}
                      subTitle={el.parent_name}
                      hideRight
                      showUnderline
                      tailObject={
                        editable && 
                        <IconButton 
                          onClick={(event)=>{event.stopPropagation();onRemoveCollection(el.id)}}
                          onMouseLeave={()=>setListButtonHovered(false)}
                          onMouseEnter={()=>setListButtonHovered(true)}
                        > 
                          {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
                        </IconButton>
                      }
                      onClick={listButtonHovered?undefined:() => onClickResultItem({id:el.obj.id,item:el.obj, label:'collection_instance', newWindow:true })}
                    />  
                  ))
                }
              </div>
            </Paper>
          }
        </div>
      </Modal>
    </div>
  )
}


Tags.propTypes = {
  classes: PropTypes.object.isRequired,
}



export default withTheme()(withStyles(styles)(Tags));