import React from 'react';
import { withTheme, withStyles, Typography, Paper } from '@material-ui/core';
import { lighten, darken } from '@material-ui/core/styles/colorManipulator';
import KTooltip from '../../UI/KTooltip/KTooltip';
import { getIconComponent } from '../../../utilities';

const styles = theme => ({
  root:{
    // width:216,
    // height:80,
    flexGrow:1,
    width:'25%',
    maxWidth:300,
    height:136,
    boxSizing:'border-box',
    padding:12,
    display:'flex',
    // flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.tileBorder.main}`
  },
  clickableCard:{
    background:localStorage.hasOwnProperty('dark')?darken(theme.palette.primary.dark,0.7):lighten(theme.palette.primary.dark,0.9),
    '&:hover':{
      background:localStorage.hasOwnProperty('dark')?darken(theme.palette.primary.dark,0.55):lighten(theme.palette.primary.dark,0.75),
    }
  },
  title:{
    fontSize:12,
    whiteSpace:'break-spaces'
  },
  value:{
    fontSize:32,
  },
  footer:{
    fontSize:12
  },
  selectedText:{
    color:theme.palette.background.main
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  clickableText:{
    color:theme.palette.primary.main
  },
  unclickableText:{
    color:theme.palette.primaryText.light
  },
  footerText:{
    color:theme.palette.primaryText.light,
    whiteSpace:'pre-wrap'
  },
  tooltip:{
    fontSize:13.75,
  },
  hide:{
    display:'none'
  }
})

const DataTile = props => {

  const {
    classes,
    theme,
    onClick,
    tileId,
    iconLabel,
    selected,
    clickable,
    title,
    value,
    tooltip,
    forceRootStyle={},
    forceValueColour,
    forceValueFontSize
  } = props;

  const getTextStyle = part => {
    if(selected)return classes.selectedText
    if(part==='title'){
      return classes.normalText
    }
    if(part==='value'){
      if(clickable)return classes.clickableText;
      return classes.unclickableText
    }
    if(part==='footer'){
      return classes.footerText
    }
  }
  
  return (
    <KTooltip title={tooltip} classes={{tooltip:tooltip?classes.tooltip:classes.hide}}>
      <Paper 
        data-test-id={`tile-${title.toLowerCase().replace(/\s/g,'-').toLowerCase()}`}
        className={classes.root + (clickable?` ${classes.clickableCard}`:'')} 
        onClick={onClick} 
        elevation={0}
        style={{background:selected?theme.palette.primary.dark:undefined,cursor:selected?'default':clickable?'pointer':undefined, ...forceRootStyle}}
      >
        <div style={{display:'flex',alignItems:'flex-start'}}>
          {
            <div style={{width:40,height:40,marginRight:24,flexShrink:0,marginTop:4,flexGrow:0}}>
              {getIconComponent({label:iconLabel || tileId,colour:selected?theme.palette.background.main:theme.palette.primary.main,size:40})}
            </div>
          }
          <div style={{flexGrow:1}}>
            <Typography className={`${classes.value} ${getTextStyle('value')}`} style={{color:forceValueColour,fontSize:forceValueFontSize}}>{value}</Typography>
            <Typography className={`${classes.title} ${getTextStyle('title')}`}>{title}</Typography>
          </div>
        </div>
      </Paper>  
    </KTooltip>
  )
}

export default withTheme()(withStyles(styles)(DataTile));