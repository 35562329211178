import React, { useReducer, useEffect } from 'react';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/CollectionAdmin/Body/Body';
import useGetCerebrum from '../../hooks/useGetCerebrum';
import TabBar from '../../components/UI/TabBar/TabBar';
import 'url-search-params-polyfill';
import { removeUrlQueryArg, setInitialState } from '../../utilities';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import { addHistory } from '../../HistoryManager';
import { adminPageInfo } from '../NavBar/utils';

const initialState = {
  collectionData:null,
  collectionError:null,
  collectionLoading:null,
  tabState:0,
  searchFilter:'',
  category:'GLOSSARY',
  sort:'ALPHABETICAL',
  importHistoryFilter:'all',
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState:action.tabState
      }
    case 'set_selected_collection':
      return {
        ...state,
        selectedCollection:action.selectedCollection
      }
    case 'set_collection_data':
      return {
        ...state,
        collectionData:action.collectionData,
        collectionError:action.collectionError,
        collectionLoading:action.collectionLoading
      }
    case 'set_category_list':
      return {
        ...state,
        categoryList:action.categoryList
      }
    case 'set_search_filter':
      return {
        ...state,
        searchFilter:action.searchFilter
      }
    case 'set_category':
      return {
        ...state,
        category:action.category
      }
    case 'set_edit_modal_open':
      return {
        ...state,
        editModalOpen:action.editModalOpen
      }
    case 'set_delete_modal_open':
      return {
        ...state,
        deleteModalOpen:action.deleteModalOpen
      }
    case 'set_sort':
      return {
        ...state,
        sort:action.sort
      }
    case 'set_import_modal_open':{
      return {
        ...state,
        importModalOpen:action.importModalOpen
      }
    }
    case 'set_import_collection':{
      return {
        ...state,
        importCollection:action.importCollection
      }
    }
    case 'set_import_history_filter':{
      return {
        ...state,
        importHistoryFilter:action.importHistoryFilter
      }
    }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

const styles = theme => ({
  haeder:{
    color:theme.palette.primaryText.main
  },
  subtitle:{
    fontSize:13.75,
    color:theme.palette.primaryText.light
  },
  title:{
    color:theme.palette.header.main,
  }
})

function CollectionAdmin(props) {

  const {
    classes,
    history,
    sessionData,
    pageCache,
    storePageCache,
  } = props;

  const tabOptions = ['GLOSSARY','DATA_MANAGEMENT','DATA_GOVERNANCE','K COLLECTIONS','IMPORT']
  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));

  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName');

  const {
    data:categoryData
  } = useGetCerebrum({
    url:`/api/collections/categories`,
    params:{
      per_page:20
    }
  })

  useEffect(()=>{
    addHistory({
      url:window.location.pathname, 
      iconLabel:adminPageInfo.collections.icon, 
      title: adminPageInfo.collections.name, 
      subTitle:adminPageInfo.collections.description,
      type:'application',
    })
  },[])

  useEffect(()=>{
    if(!state)return;
    let cacheObj = {tabState:state.tabState, categoryList:state.categoryList, category:state.category};
    storePageCache({cacheID:window.location.href,...cacheObj})
  },[state,storePageCache])


  useEffect(()=>{
    dispatch({
      type:'set_category_list',
      categoryList:categoryData?categoryData.items:undefined
    })
    if(categoryData){
      if(tabName && (categoryData.items.find(el=>el.name===tabName) || tabOptions.includes(tabName)) ){
        dispatch({type:'set_tab_state',tabState:tabOptions.indexOf(tabName)})
        if(tabName!=='IMPORT'){
          dispatch({type:'set_category',category:tabName})
        }
        window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
      }
      // dispatch({type:'set_category',category:categoryData.items[0].name})
    }
  // eslint-disable-next-line
  },[categoryData])

  let header,title,subtitle;
  // if(state.tabState===0){
    header=<Typography style={{whiteSpace:'pre-wrap'}}>{' '}</Typography>;
    title='Collections';
    subtitle='Collections are used to link and group data and content together.'

  // }
  

  if(!state.categoryList)return <div></div>;

  return (
    <div className={classes.root}>
      <ProfileLayout
        noPadding
        header={
          <ProfileHeader
            label="collection_admin"
            iconLabel='collection'
            title={title}
            description={subtitle}
            subtitle={header}
            minHeight={140}
            // buttons={buttons}
          />
        }
        tabBar={
          <TabBar
            tabOptions={tabOptions.map(el=>el.replace(/_/g,' '))}
            tabState={state.tabState}
            setTabState={value => {
              if(tabOptions[value]==='IMPORT'){
                dispatch({ type: 'set_tab_state', tabState: value })
                return;
              }
              if(value!==state.tabState){
                dispatch({type:'set_collection_data'})
              }
              dispatch({type:'set_search_filter',searchFilter:''})
              if(tabOptions[value]==='K COLLECTIONS'){
                dispatch({type:'set_category',category:state.categoryList.map(el=>el.name).filter(el=>!['GLOSSARY','DATA_MANAGEMENT','DATA_GOVERNANCE'].includes(el)).join(',')})
              }else{
                dispatch({type:'set_category',category:tabOptions[value]})
              }
              dispatch({ type: 'set_tab_state', tabState: value })
            }}
            // minWidth={isShell?200:170}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            state={state}
            dispatch={dispatch}
            history={history}
            sessionData={sessionData}
            tabOptions={tabOptions}
          />
        }
      >
      </ProfileLayout>
    </div>
  )
}

CollectionAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
    permissions: state.auth.permissions
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(CollectionAdmin)));

