import React, {useEffect, useRef,} from 'react';
import { withTheme, CircularProgress, Typography, withStyles, MenuItem, Select} from '@material-ui/core';
import axiosSolr from '../../../axios-solr'
import ListItem from '../ListItem/ListItem';
import ColumnHeader from '../ColumnHeader/ColumnHeader';
import { isInViewport } from '../../../utilities';

const styles = theme => ({
  root: {

  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
    marginBottom:16,
    textTransform:'uppercase'
  },
  seeMoreButton:{
    marginTop:8
  },
  selector:{
    ...theme.components.titleSelector,
    marginBottom:4
  }
})

const Glossary = props => {

  const {
    classes,
    theme,
    history,
    state,
    dispatch,
  } = props;
  
  const loadingRef = useRef()

  const loadData = ({view = state.glossaryView, start = 0}) => {
    
    let promise;
    if(view==='new'){
      promise = axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:COLLECTION_INSTANCE AND collection_type_srt:GLOSSARY AND active_srt:YES`,
              sort:'created_at_srt desc',
              rows:10,
              start,
            }
          }
        )
    }
    if(view==='updated'){
      promise = axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:COLLECTION_INSTANCE AND collection_type_srt:GLOSSARY AND active_srt:YES`,
              sort:'updated_at_srt desc',
              rows:10,
              start,
            }
          }
        )
    }
    if(!promise)return;

    dispatch({type:'set_glossary_data',glossaryData:state.glossaryData,glossaryLoading:true})
    promise
      .then(response=>{
        let data = response.data.response
        let glossaryData = {
          ...(state.glossaryData || {}),
          [view]:{
            ...data,
            docs:[...(state.glossaryData?.[view]?.docs||[]),...data.docs],
          }
        }
        dispatch({
          type:'set_glossary_data',
          glossaryData,
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_glossary_data',
          glossaryData:state.glossaryData,
          glossaryError:true
        })
      })
  }

  useEffect(()=>{
    if(!state.glossaryData || !state.glossaryData[state.glossaryView]){
      loadData({view:state.glossaryView})
    }
  // eslint-disable-next-line
  },[state.glossaryView])
  
  const shouldLoadMore = () => {
    if(!state.glossaryData || !state.glossaryData[state.glossaryView])return false;
    if(state.glossaryData[state.glossaryView].numFound <= state.glossaryData[state.glossaryView].docs.length)return false;
    if(state.glossaryLoading)return false;
    if(!isInViewport(loadingRef))return false;
    return true;
  }

  window.onscroll = () => {
    if (shouldLoadMore()) {
      loadData({view:state.glossaryView,start:state.glossaryData[state.glossaryView].docs.length})
    }
  }

  useEffect(()=>{
    if (shouldLoadMore()) {
      loadData({view:state.glossaryView,start:state.glossaryData[state.glossaryView].docs.length})
    }
  // eslint-disable-next-line
  },[state.glossaryData])
  
  const getItem = item => {
    return (
      <ListItem
        item={item}
        label={'collection_instance'}
        history={history}
        key={item.id}
      />
    )
  }

  let data = state.glossaryData?.[state.glossaryView] // view: one of ['new','trending']

  return (
    <div className={classes.root}>
      <div style={{paddingBottom:24,background:theme.palette.background.main,position:'sticky',top:181,zIndex:9}}>
        <Select
          value={state.glossaryView}
          onChange={(e)=>dispatch({type:'set_glossary_view',glossaryView:e.target.value})}
          className={classes.selector}
          disableUnderline
          data-test-id="glossary-view-selector"
        >
          <MenuItem value="new">NEW TERMS</MenuItem>
          <MenuItem value="updated">RECENTLY UPDATED TERMS</MenuItem>
        </Select>
        <Typography style={{color:theme.palette.primaryText.light,fontSize:12}}>
          {
            state.glossaryView==='new'?
            'New terms added. Sorted by most recently created.':
            'Terms sorted by last updated.'
          }
        </Typography>
      </div>
      {
        data && data.docs.length>0 && 
        <div>
          <ColumnHeader label='term'/>
          {
            data.docs.map(getItem)
          }
        </div>
      }
      <div ref={loadingRef} style={{marginTop:8,display:'flex',justifyContent:'center'}}>
        {
          state.glossaryLoading && 
          <CircularProgress color='secondary'/>
        }
      </div>
      {
        state.glossaryError && 
        <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading terms</Typography>
      }
      {
        data && data.numFound===0 && 
        <Typography style={{color:theme.palette.primaryText.main}}>No terms found</Typography>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(Glossary));