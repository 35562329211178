import React,{useRef,useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, IconButton, Button} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { toTitleCase, getIconComponent, getTrustScoreColor, getTrustScoreBand, processCollection, getUserRoles, getItemChildType, getTrustScoreIcon, formatBusinessnName, getTestScoreColor } from '../../../utilities';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../ContextMenu/ContextMenu'
// import sqlFormatter from "sql-formatter";
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import ResultItemEditor from './ResultItemEditor';
import {useStore} from 'react-redux'
import {getIconLabel, getDomainText, addBadgeToIcon, isShowTrust} from './utils'
import CodeDisplayModal from './CodeDisplayModal';
import { removeMarkDown } from '../InteractiveInput/Templates';
import KTooltip from '../KTooltip/KTooltip';

const styles = theme => ({
  cardActionArea: {
    minHeight: '6rem',
    width:'100%',
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.hovered.main,
      '& #description_inline_edit':{
        display:'inline-block'
      }
    },
    display: 'flex',
    padding: '1rem 1rem 0.5rem 1rem'
  },
  disabledCard:{
    cursor:'default',
    background: theme.palette.background.main,
    '&:hover': {
      background: theme.palette.background.main
    },
  },
  iconWrapper: {
    color: '#000000',
    marginRight: '1rem',
    paddingRight:6
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '1 1 60%',
    overflow:'hidden'
  },
  codeMirror:{
    ...theme.components.codeMirror
  },
  trustScoreWrapper: {
    flex: '0 1 40px',
    textAlign: 'center',
  },
  trustText:{
    color:theme.palette.primaryText.main
  },
  contentTop: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "space-between",
    overflow:'hidden',
    paddingRight:16,
    flexGrow:1,
    //flexGrow: 1,
    //width:'35vw'
  },

  columnType: {
    fontSize: 12,
    color: theme.palette.secondary.main,
    marginLeft: 16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  contentBody: {
    flexGrow: 0,
    margin: '6px 0px 4px',
  },
  contentBodyText:{
    color:theme.palette.primaryText.light,
    width:'max-content',
    maxWidth:'100%',
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  clickableText:{
    '&:hover':{
      // textDecoration:'underline',
    },
  },
  contentBottom: {
    display: 'flex',
    overflow: 'hidden',
  },
  detailsWrapper: {
    display: 'flex'
  },
  contentSubtitle: {
    height: 20,
    overflow: 'hidden',
  },
  contentSubtitleText:{
    color:theme.palette.primaryText.main,
    overflow: 'hidden',
    whiteSpace: 'nowrap !important',
    textOverflow: 'ellipsis !important',
  },
  contentTitle: {
    overflow: 'hidden',
    display: 'flex',
    alignItems:'center'
    // width:'28rem',
  },
  tag: {
    paddingTop:1,
    letterSpacing:1,
    fontWeight:400,
    maxWidth:300,
    // minWidth:30,
    flexShrink: 0,
    fontSize: 12,
    padding: '0 6px',
    marginTop:6,
    borderRadius: 12,
    marginRight: 6,
    overflow: 'hidden !important',
    whiteSpace: 'nowrap !important',
    textOverflow: 'ellipsis !important',
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primaryText.main,
  },
  descriptionEditIcon:{
    marginLeft:4,
    padding:1,
    display:'none',
    "&:hover":{
      background:`${theme.palette.primaryText.light}50`
    }
  }
})

const ellipseStr = str => {
  if (str.length > 16) return str.slice(0, 16) + '...'
  return str
}

function ResultItem(props) {

  let {
    classes,
    theme,
    dispTitle,
    dispSubtitle,
    dispBody,
    dispFooter,
    customFooterElement,
    label='',
    showIcon,
    disableHover,
    item,
    // tagsWidthOffset,
    showUnderline,
    hideTrust,
    showSimplifiedTrustIcon,
    showLineageStatus,
    enableEdit,
    onDescriptionUpdated,
    tailObject,
    tailObjectHoverable,
    showDetailedTestScore,
    showTestScoreIcon,
    showKnowledgeIcon,
    customActions,
    onClick,
  } = props;

  const [editModalOpen, setEditModalOpen] = useState(false)
  const [viewCodeModalOpen, setViewCodeModalOpen] = useState(false)
  const store = useStore();
  const sessionData = store.getState().auth.session_user;
  const roles = getUserRoles(sessionData.user_role)
  const [removeHoverEffect, setRemoveHoverEffect] = useState(false)
  const elementRef = useRef();


  if(!item)return <div></div>

  label = label?label.toLowerCase():''


  if(roles.every(r=>r==='90')){
    enableEdit = false;
  }

  let isDisplayCode = (label==='query' || (label==='code' && item && item.code_type_txt==='QUERY'))

  let trustScore;
  if(!isNaN(item.trustScore))trustScore = item.trustScore;
  if(!isNaN(item.trust_srt))trustScore = item.trust_srt;
  let users = item.top_users_txts || item.users

  let userDetail = null;

  if (users && users.length > 0) {
    if (users.length > 2) {
      userDetail = toTitleCase(users[0]) + ' and ' + (users.length - 1) + ' others';
    }
    else if (users.length === 2) {
      userDetail = toTitleCase(users[0]) + ' and ' + toTitleCase(users[1])
    }
    else {
      userDetail = toTitleCase(users[0]);
    }
  }

  let time = item && item.updated ? moment(item.updated).fromNow() : null
  if(item.last_used_srt)time = moment(item.last_used_srt).fromNow()

  let isShell;
  if(item)isShell = ['table','schema','column','database','tool','user'].includes(label) && (item.reference===true || item.reference_txt==='YES');

  let finalDescription = removeMarkDown(item.description);
  if(isShell && (!finalDescription || finalDescription.trim()==='')){
    if(label!=='tool'){
      finalDescription=`This ${toTitleCase(label)} is referenced in a Source metadata. It is not integrated to K`
    }else{
      finalDescription=`This Tool is referenced in content metadata. It is not integrated to K`
    }
  }else{
    if(!finalDescription || finalDescription==='')finalDescription = 'No description'
  }
  if(label==='data_quality_test'){
    finalDescription = dispBody;
  }
  if(isDisplayCode){
    enableEdit = false;
    finalDescription = label.code;
  }

  let iconLabel = getIconLabel({label, item})

  let shownTitle = dispTitle
  if(label==='code' && dispTitle.toLowerCase().match('query-'))shownTitle = `No title (${dispTitle})`;
  if(label==='code' && item.code_type_txt==='QUERY' && item.alternate_name_txt)shownTitle = formatBusinessnName({roles, dispTitle:shownTitle, item, isBoldText:true})
  if(['column','table','data_quality_test'].includes(label)){
    shownTitle = formatBusinessnName({roles, dispTitle:shownTitle, item, isBoldText:true})
  }
  if(shownTitle===undefined)shownTitle = 'Untitled'

  const getLineageTooltip = () => {
    let fieldName = item.active_txt==='YES'?'active_lineage_inc_reference_txt':'lineage_inc_reference_txt'
    if(['DOWNSTREAM_ONLY'].includes(item[fieldName]))return 'Has downstream lineage only'
    if(item[fieldName]==='UPSTREAM_ONLY')return 'Has upstream lineage only'
    if(item[fieldName]==='UPSTREAM_QUERY_DOWNSTREAM')return 'Has upstream query and downstream lineage'
    if(item[fieldName]==='UPSTREAM_DOWNSTREAM')return 'Has up and downstream lineage'
  }

  const getLineageLabel = () => {
    let fieldName = item.active_txt==='YES'?'active_lineage_inc_reference_txt':'lineage_inc_reference_txt'
    if(['DOWNSTREAM_ONLY'].includes(item[fieldName]))return 'lineage_downstream'
    if(item[fieldName]==='UPSTREAM_ONLY')return 'lineage_upstream'
    if(['UPSTREAM_QUERY_DOWNSTREAM','UPSTREAM_DOWNSTREAM'].includes(item[fieldName]))return 'lineage_up_downstream'
  }

  return (
    <div style={showUnderline?{borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}:undefined}>
      <ContextMenuTrigger id={item.id}>
        <div data-test-classname="result-item" className={classes.cardActionArea + (disableHover || removeHoverEffect?' '+classes.disabledCard:'')} onClick={onClick} >
          {
            showIcon &&
            <div className={classes.iconWrapper}>
              {
                item &&
                addBadgeToIcon({
                  icon: getIconComponent({ label: iconLabel, size: 24, colour: isShell?theme.palette.primaryText.light:theme.palette.primary.main }),
                  modification_badge: item.modification_badge_txt,
                  active: item.active_txt || item.active_flag,
                  obj: item
                })
              }
            </div>
          }
          <div className={classes.contentWrapper}>

            <div style={{display:'flex'}} ref={elementRef}>

              <div className={classes.contentTop}>
                {
                  shownTitle &&
                  <div className={classes.contentTitle} >
                    <KTooltip title={shownTitle}>
                      <Typography data-test-classname="result-item-title" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color:theme.palette.header.main }} variant="body1">
                        {shownTitle}
                      </Typography>
                    </KTooltip>
                    {item.column_type_txt && <Typography className={classes.columnType}>{item.column_type_txt}</Typography>}
                    {(item.object_subtype_txt || item.data_type_txt) && item.object_type_txt==='DATASET_FIELD' && <Typography className={classes.columnType}>{item.data_type_txt || item.object_subtype_txt}</Typography>}
                  </div>
                }
                {
                  dispSubtitle && dispSubtitle.trim() && label!=='query' &&
                  <div className={classes.contentSubtitle} >
                    <Typography data-test-classname="result-item-subtitle" variant="caption" className={classes.contentSubtitleText}>{dispSubtitle}</Typography>
                  </div>
                }
                {
                  finalDescription && finalDescription.trim()!=='' &&
                  <div className={classes.contentBody} >
                    <Typography
                      data-test-classname="result-item-description"
                      className={classes.contentBodyText + (!isShell && enableEdit?' '+classes.clickableText:' ')}
                      variant="body2"
                    >
                      <KTooltip title={finalDescription}>
                        <span style={{flexShrink:1,overflow:'hidden',textOverflow:'ellipsis'}}>
                          {finalDescription}
                        </span>
                      </KTooltip>
                      {
                        !isShell && enableEdit &&
                        <IconButton
                          id="description_inline_edit"
                          className={classes.descriptionEditIcon}
                          onClick={event=>{
                            if(isShell || !enableEdit)return;
                            event.stopPropagation();
                            setEditModalOpen(true);
                          }}
                        >
                          {getIconComponent({label:'edit',size:16,colour:theme.palette.primaryText.light})}
                        </IconButton>
                      }
                    </Typography>
                  </div>
                }
              </div>
              {
                isDisplayCode &&
                <Button
                  variant="outlined"
                  color='primary'
                  data-test-classname="result-item-view-code-button"
                  style={{height:28,width:112,flexShrink:0}}
                  onClick={(event)=>{
                    event.stopPropagation();
                    event.preventDefault();
                    setViewCodeModalOpen(true);
                  }}
                >
                  VIEW CODE
                </Button>
              }
              {
                showLineageStatus && item && ((item.active_txt==='YES' && item.active_lineage_inc_reference_txt) || (item.active_txt==='NO' && item.lineage_inc_reference_txt)) && getLineageLabel() &&
                <KTooltip title={getLineageTooltip()}>
                  <div style={{marginLeft:8,width:22,height:22,flexShrink:0,flexGrow:0,marginRight:16}}>
                    {getIconComponent({label:getLineageLabel(),size:22,colour:theme.palette.primary.main})}
                  </div>
                </KTooltip>
              }

              {
                item && showDetailedTestScore && !isNaN(item.dq_score_overall_srt) && ['table','column','query','code'].includes(label) &&
                <div className={classes.trustScoreWrapper} style={{flex:'0 1 60px',marginRight:24}}>
                  <Typography variant="caption" className={classes.trustText}>{'DQ Score'}</Typography>
                  <Typography style={{ color: getTestScoreColor(item.dq_score_overall_srt,true), height: '16px', fontSize: '11.78px', letterSpacing: '0.4px', lineHeight: '16px', textAlign: 'center' }} variant="h6">
                    {Math.round(item.dq_score_overall_srt)}
                  </Typography>
                </div>
              }
              {
                !hideTrust &&
                isShowTrust(label) &&
                !isNaN(trustScore) &&
                item ?
                  (item.active === false || item.active_txt==='NO') ?
                    <div className={classes.trustScoreWrapper}>
                      <Typography style={{ color: '#EA5455', height: '16px', fontSize: '11.78px', letterSpacing: '0.4px', lineHeight: '16px', textAlign: 'center', marginTop: 20 }} variant="h6">
                        Deleted
                      </Typography>
                    </div>
                    :
                    showSimplifiedTrustIcon?
                      <KTooltip title={getTrustScoreBand({ trustScore: trustScore })}>
                        <div style={{mwidth:22,height:22,flexShrink:0,flexGrow:0}}>
                          {getTrustScoreIcon({trustScore:trustScore,size:22})}
                        </div>
                      </KTooltip>
                      :
                      <div className={classes.trustScoreWrapper}>
                        <Typography variant="caption" className={classes.trustText}>{'Trust'}</Typography>
                        <Typography style={{ color: getTrustScoreColor(trustScore), height: '16px', fontSize: '11.78px', letterSpacing: '0.4px', lineHeight: '16px', textAlign: 'center' }} variant="h6">
                          {getTrustScoreBand({ trustScore: trustScore })}
                        </Typography>
                      </div>
                  :
                  undefined
              }
              {
                item && showTestScoreIcon && !isNaN(item.dq_score_overall_srt) &&
                <KTooltip title={item.dq_score_overall_srt}>
                  <div style={{width:22,height:22,flexShrink:0,flexGrow:0,marginLeft:16}}>
                    {getIconComponent({label:'data_quality_test',size:22,colour:getTestScoreColor(item.dq_score_overall_srt,true)})}
                  </div>
                </KTooltip>
              }
              {
                item && showKnowledgeIcon && ['ONE','MANY'].includes(item.has_knowledge_txt) &&
                <KTooltip title={item.has_knowledge_txt==='ONE'?'Has one note added':'Has more than one notes added'}>
                  <div style={{width:22,height:22,flexShrink:0,flexGrow:0,marginLeft:16}}>
                    {getIconComponent({label:'note',size:22,colour:item.has_knowledge_txt==='ONE'?getTestScoreColor(50,true):getTestScoreColor(100,true)})}
                  </div>
                </KTooltip>
              }
              {
                tailObject
                &&
                <div
                  style={{marginLeft:16}}
                  onMouseEnter={()=>{
                    if(tailObjectHoverable)setRemoveHoverEffect(true)
                  }}
                  onMouseLeave={()=>{
                    if(tailObjectHoverable)setRemoveHoverEffect(false)
                  }}
                >
                  {tailObject}
                </div>
              }
            </div>
            {
              dispFooter
              ||
              <div data-test-classname="result-item-footer" className={classes.contentBottom} style={{maxWidth:'100%',flexWrap:'wrap'}}>
                {
                  customFooterElement
                }
                {
                  time &&
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 12, flexShrink: 0, marginTop:3 }}>
                    {getIconComponent({ label: 'time', size: 16, colour: theme.palette.primary.main })}
                    <Typography style={{ fontSize: 12, marginLeft: 8 }} color='primary'>{time}</Typography>
                  </div>
                }
                {
                  label==='code' && item && !isNaN(item.total_usage_srt) && item.total_usage_srt!==0 &&
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 12, flexShrink: 0, marginTop:3 }}>
                    {getIconComponent({ label: 'time', size: 16, colour: theme.palette.primary.main })}
                    <Typography style={{ fontSize: 12, marginLeft: 8 }} color='primary'>run {item.total_usage_srt} time{item.total_usage_srt===1?'':'s'}</Typography>
                  </div>
                }
                {
                  userDetail && label!=='user' &&
                  <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, marginRight: 12, marginTop:3 }}>
                    {getIconComponent({ label: 'user', size: 16, colour: theme.palette.primary.main})}
                    <Typography style={{ fontSize: 12, marginLeft: 8 }} color='primary'>{userDetail}</Typography>
                  </div>
                }
                {
                  item && ['collection_instance','collection'].includes(label) && item.creatorOf &&
                  <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0, marginRight: 12, marginTop:3 }}>
                    {getIconComponent({ label: 'user', size: 16, colour: theme.palette.primary.main})}
                    <Typography style={{ fontSize: 12, marginLeft: 8 }} color='primary'>Created by {item.creatorOf}</Typography>
                  </div>
                }
                {
                  ['DATABASE','SCHEMA','WORKSPACE','SOURCE','TABLE','CONTENT_APP','REPORT','DATASET','DATASET_TABLE','DATA_PIPELINE'].includes(item.object_type_txt) && item.child_object_count_srt ?
                  <div className={classes.tag} style={{ borderColor: theme.palette.primary.dark }}>
                    {item.child_object_count_srt} {getItemChildType(item, true).toUpperCase()}(S)
                  </div>
                  :<></>
                }
                {
                  item && item.warning_notice_kc_txts?.length>0 &&
                  <div className={classes.tag} style={{ borderColor: theme.palette.warning.main }}>
                    WARNING
                  </div>
                }
                {
                  item &&
                  processCollection({category_kc_txts:item.category_kc_txts},'manual','resultItem','solr').tags.slice(0, 4).map(el => (
                    <div className={classes.tag} style={{ borderColor: el.colour }}>
                      {el.name}
                    </div>
                  ))
                }
                {
                  label==='column' && item && item.primary_key_txt==='PRIMARY_KEY' &&
                  <div className={classes.tag} style={{ borderColor: '#b8d2ff' }}>PRIMARY KEY</div>
                }
                {
                  label==='column' && item && item.foreign_key_txt==='FOREIGN_KEY' &&
                  <div className={classes.tag} style={{ borderColor: '#b8d2ff' }}>FOREIGN KEY</div>
                }
                {
                  item && !isNaN(item.number_of_dq_tests_srt) && item.number_of_dq_tests_srt>0 && item.object_type_txt!=='DATA_QUALITY_TEST' &&
                  <div className={classes.tag} style={{ borderColor: '#00D46A' }}>DQ TESTED</div>
                }
                {
                  item && item.data_quality_failed_txt==='YES' &&
                  <div className={classes.tag} style={{ borderColor: '#F44137' }}>DQ TEST FAILED</div>
                }
                {
                  item && item.domain_kc_txts &&
                  <div className={classes.tag} style={{  borderColor: '#00D46A' }}>DOMAIN:{` ${getDomainText(item.domain_kc_txts)}`}</div>
                }
                {
                  item && item.verified_kc_txts &&
                  <div className={classes.tag} style={{  borderColor: '#00D46A' }}>{'VERIFIED'}</div>
                }
                {
                  isShell &&
                  <div className={classes.tag} style={{ borderColor: '#E6F0F2' }}>REFERENCE</div>
                }
                {
                  item && item.masked_txt==='YES' &&
                  <div className={classes.tag} style={{ borderColor: '#D8D8D8' }}>MASKED</div>
                }
                {
                  item && item.table_type_txt && item.table_type_txt!=='TABLE' &&
                  <div className={classes.tag} style={{ borderColor: '#b8d2ff' }}>{item.table_type_txt}</div>
                }
                {
                  item &&
                  processCollection(item,'auto','resultItem','solr').tags.slice(0, 4).map(el => (
                    <div className={classes.tag} style={{  borderColor: el.colour }}>
                      {el.name}
                    </div>
                  ))
                }
                {
                  item && processCollection(item,'auto','resultItem','solr').tags.length > 4 &&
                  <div className={classes.tag} style={{ borderColor: '#FFAB00' }}>
                    +{processCollection(item,'auto','resultItem','solr').tags.length - 4}
                  </div>
                }
                {
                  item &&
                  processCollection({...item,category_kc_txts:undefined},'manual','resultItem','solr').tags.slice(0, 4).map(el => (
                    <div className={classes.tag} style={{ borderColor: el.colour }}>
                      {el.name}
                    </div>
                  ))
                }
                {
                  item && processCollection({...item,category_kc_txts:undefined},'manual','resultItem','solr').tags.length > 4 &&
                  <div className={classes.tag} style={{ borderColor: '#FC642D' }}>
                    +{processCollection({...item,category_kc_txts:undefined},'manual','resultItem','solr').tags.length - 4}
                  </div>
                }
                {
                  item && item.tags_txts &&
                  item.tags_txts.sort().slice(0, 4).map(el => (
                    <div className={classes.tag} style={{ borderColor: theme.palette.avatar.main }}>
                      {ellipseStr(el)}
                    </div>
                  ))
                }
                {
                  item && item.tags_txts && item.tags_txts.length > 4 &&
                  <div className={classes.tag} style={{ borderColor: theme.palette.avatar.main }}>
                    +{item.tags_txts.length - 4}
                  </div>
                }
              </div>
            }
          </div>

        </div>
      </ContextMenuTrigger>

      <ContextMenu
        id={item.id}
      >
        <CustomMenu
          item={item}
          actions={[
            'open_new_tab',
            'bookmark'
          ]}
          customActions={customActions}
        />
      </ContextMenu>
    {
      enableEdit &&
      <ResultItemEditor
        item={item}
        dispTitle={dispTitle}
        setDescription={value=>{
          onDescriptionUpdated({item,description:value})
        }}
        setModalOpen={setEditModalOpen}
        modalOpen={editModalOpen}
      />
    }
    {
      viewCodeModalOpen &&
      <CodeDisplayModal
        item={item}
        modalOpen={viewCodeModalOpen}
        setModalOpen={setViewCodeModalOpen}
        onOpen={onClick}
      />
    }

    </div>
  );

}

ResultItem.propTypes = {
  classes: PropTypes.object.isRequired,
  dispTitle: PropTypes.string,
  dispSubtitle: PropTypes.string,
  dispBody: PropTypes.string,
  dispFooter: PropTypes.string,
  label: PropTypes.string,
  trustScore: PropTypes.any,
  showUnderline: PropTypes.any,
  showIcon: PropTypes.bool,
  hideTrust: PropTypes.bool,
  disableHover: PropTypes.bool,
  item: PropTypes.object,
  enableEdit: PropTypes.bool,
  onDescriptionUpdated: PropTypes.func,
  customActions: PropTypes.array,
  showLineageStatus: PropTypes.bool,
  showSimplifiedTrustIcon: PropTypes.bool,
  onClick: PropTypes.func,
  tailObject: PropTypes.object,
  tailObjectHoverable: PropTypes.bool,
  customFooterElement: PropTypes.object,
  showDetailedTestScore: PropTypes.bool,
  showTestScoreIcon: PropTypes.bool,
  showKnowledgeIcon: PropTypes.bool,
}

export default withTheme()(withStyles(styles)(ResultItem));
