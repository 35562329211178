import { CerebrumLongListLoader } from "./LongListLoader";
import { collectionIds } from "./utilities";

let categories = [];

export const loadCategory = async () => {
  return new Promise((resolve, reject) => {
    CerebrumLongListLoader({
      url:`/api/collectioninstances`,
      params:{
        collection_id:collectionIds.category
      },
      per_page:100,
      onFinishLoad: data => {
        categories = data.data;
        resolve();
      },
      onError: error => {
        console.log(error)
        reject();
      }
    })
  })
}

export const onUpdateCategory = category => {
  let index = categories.findIndex(c => c.id === category.id);
  if(index > -1){
    categories[index] = category;
  }else{
    categories.push(category);
  }
}

export const getCategory = () => {
  return categories;
}

export const getCategoryColourByName = (name='') => {
  let category = categories.find(category => category.name?.toUpperCase() === name.toUpperCase());
  return category?.properties?.[1];
}