import React,{useEffect} from 'react';
import { withTheme, withStyles, Typography, CircularProgress} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import DataTile from './DataTile/DataTile'
import LineChart from './LineChart/LineChart'
import ExtractDownloader from './ExtractDownloader/ExtractDownloader';

const styles = theme => ({
  header:{
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:4
  },
  subHeader:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  chartTitle:{
    color:theme.palette.header.main,
    fontSize:16,
    marginBottom:4
  },
  chartSubTitle:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    marginBottom:16
  }
})

function Responses(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
  } = props;

  useEffect(()=>{
    if(!state.responsesData && !state.responsesLoading){
      const loadResponseData = async () => {
        dispatch({type:'set_responses_data',responsesLoading:true})
        try{
          let tileMetrics;
          await axiosCerebrum
            .get(
              `/api/metrics`,
              {params:{
                measures:'SURVEYS_30D,SURVEYS_RESPONSES_30D,SURVEYS_USERS_30D,SURVEYS_RESPONDENTS_30D,SURVEYS_ITEMS_30D,SURVEYS_ITEMS_WITH_RESPONSES_30D',
                // per_page:6,
                period:30,
                source_ids:'1000'
              }}
            )
            .then(response=>{
              tileMetrics = response.data.items;
            })
          let tiles = [
            {
              header:'RESPONSES',
              value:tileMetrics.find(el=>el.measure==='SURVEYS_RESPONSES_30D').value,
              footer:`of ${tileMetrics.find(el=>el.measure==='SURVEYS_30D').value} surveys sent last 30 days`,
              selected:true
            },
            {
              header:'RESPONDENTS',
              value:tileMetrics.find(el=>el.measure==='SURVEYS_RESPONDENTS_30D').value,
              footer:`of ${tileMetrics.find(el=>el.measure==='SURVEYS_USERS_30D').value} users sent surveys last 30 days`
            },
            {
              header:'ITEM WITH RESPONSES',
              value:tileMetrics.find(el=>el.measure==='SURVEYS_ITEMS_WITH_RESPONSES_30D').value,
              footer:`of ${tileMetrics.find(el=>el.measure==='SURVEYS_ITEMS_30D').value} items surveyed last 30 days`
            }
          ];

          let lineChartMetrics;
          await axiosCerebrum
            .get(
              `/api/metrics`,
              {params:{
                measures:'SURVEYS_FEEDBACK_COMPLETED_1D,SURVEYS_OPINION_COMPLETED_1D,SURVEYS_ACCESS_COMPLETED_1D',
                timeseries_flag:true,
                period:30,
                per_page:3,
                source_ids:'1000'
              }}
            )
            .then(response=>{
              lineChartMetrics = response.data.items
            })
          let responsesLineChartData = {
            series:[
              {name:'Feedback',data:lineChartMetrics.find(el=>el.measure==='SURVEYS_FEEDBACK_COMPLETED_1D').dataset.y,labels:lineChartMetrics.find(el=>el.measure==='SURVEYS_FEEDBACK_COMPLETED_1D').dataset.x},
              {name:'Opinion',data:lineChartMetrics.find(el=>el.measure==='SURVEYS_OPINION_COMPLETED_1D').dataset.y,labels:lineChartMetrics.find(el=>el.measure==='SURVEYS_OPINION_COMPLETED_1D').dataset.x},
              {name:'Access',data:lineChartMetrics.find(el=>el.measure==='SURVEYS_ACCESS_COMPLETED_1D').dataset.y,labels:lineChartMetrics.find(el=>el.measure==='SURVEYS_ACCESS_COMPLETED_1D').dataset.x},
            ]
          }
          dispatch({
            type:'set_responses_data',responsesData:{responsesLineChartData,tiles}
          })
        }catch(error){
          console.log(error)
          dispatch({type:'set_responses_data',responsesError:true})
        }
        
      }
      loadResponseData();
    }
  // eslint-disable-next-line
  },[])


  return (
    <div className={classes.root}>
      <Typography className={classes.header}>SURVEY METRICS LAST 30 DAYS</Typography>
      <Typography className={classes.subHeader}>Response metrics to surveys sent to data and report users</Typography>
      {
        state.responsesLoading && 
        <div style={{textAlign:'center'}}>
          <CircularProgress color='secondary'/>
        </div>
      }
      {
        state.responsesError && 
        <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading data</Typography>
      }
      {
        state.responsesData && 
        <div>
          <div style={{display:'flex',marginBottom:40}}>
            {
              state.responsesData.tiles.map((el,index)=>(
                <DataTile
                  key={el.header}
                  header={el.header}
                  value={el.value}
                  footer={el.footer}
                  rightMargin={index!==state.responsesData.tiles.length-1}
                  selected={el.selected}
                />
              ))
            }
          </div>
          <Typography className={classes.chartTitle}>Responses submitted last 30 days</Typography>
          <Typography className={classes.chartSubTitle}>Count of responses by survey type</Typography>
          <LineChart series={state.responsesData.responsesLineChartData.series} width='100%' height={320}/>
          <ExtractDownloader state={state} dispatch={dispatch}/>
        </div>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(Responses));