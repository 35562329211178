import React, { useEffect, useRef, useState } from 'react';
import { withTheme, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import SearchSelector from '../../../UI/SearchSelector/SearchSelector';
import { getIconComponent, mapObjectName, titleCaseObjectName } from '../../../../utilities';
import axiosSolr from '../../../../axios-solr';
import ClearableSelector from '../../../UI/ClearableSelector/ClearableSelector';


const ObjectSelector = props => {

  const {
    classes,
    theme,
    code,
    objectType='TABLE',
    object,
    setObject,
    objectList,
    setObjectList,
    isMulti,
    objectSearchValue,
    setObjectSearchValue,
    dataSource,
    schema='none',
    schemaList,
    required,
  } = props;

  const [locationList, setLocationList] = useState([]);
  const [isAllLoaded, setIsAllLoaded] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('all');
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef()

  const loadLocations = ({type, source, offset=0, search=searchValue}) => {
    let fq = `object_type_srt:${mapObjectName(type)}`;
    // let fq = `object_type_srt:*`;
    if(source!=='none'){
      fq += ` AND source_id_srt:${source}`;
    }
    axiosSolr
      .get('/solr/search/select',{
        params:{
          q: `*:*`,
          fq,
          rows: 0,
          'json.facet':{
            locations:{
              type: 'terms',
              field: 'location_srt',
              limit: 50,
              offset,
              mincount: 1,
              prefix: search
            }
          }
        }
      })
      .then(response=>{
        const locations = response.data.facets.locations.buckets.map(el=>({val:el.val, count:el.count}));
        setLocationList(offset===0?locations:[...locationList, ...locations]);
        if(locations.length<50){
          setIsAllLoaded(true);
        }
      })
      .catch(error=>{
        console.log(error);
      })
  }

  useEffect(()=>{
    if(objectType){
      setIsAllLoaded(false)
      setSearchValue('')
      loadLocations({type:objectType, source:dataSource, search:""})
    }
  // eslint-disable-next-line
  },[objectType, dataSource])

  const onChangeSearch = value => {
    setSearchValue(value)
    clearTimeout(searchRef.current)
    searchRef.current = setTimeout(()=>{
      setLocationList([])
      loadLocations({type:objectType, source:dataSource, search:value})
    },250)
  }

  return (
    <div className={classes.modalBlock}>
      <Typography color='primary' className={classes.modalBlockTitle}>
        {
          code==='0390'?
          (objectType!=='none'?mapObjectName(objectType).replace(/_/g,' ')+"(S)":'CONTENT OBJECT(S)'):
          'Object'
        }
      </Typography>
      {
        ['REPORT','SHEET','DATA_PIPELINE','DATASET','DATASET_TABLE','CONTENT_APP','ML_MODEL'].includes(mapObjectName(objectType)) &&
        <div style={{marginBottom:16}}>
          <ClearableSelector
            value={selectedLocation}
            onChangeValue={event=>{
              setSelectedLocation(event.target.value)
            }}
            options={[
              searchValue.trim()===''?{value:'all',name:'All'}:undefined,
              ...locationList.map(el=>({value:el.val,name:el.val + ` (${el.count})`}))
            ].filter(el=>el)}
            width={300}
            onReset={()=>{
              setSelectedLocation('all')
            }}
            renderValue={value=>{
              return `Location: ${value==='all'?'All':value}`
            }}
            searchValue={searchValue}
            onSearchChange={value=>{
              onChangeSearch(value)
            }}
            enableSearch
            onPaginate={()=>{
              if(!isAllLoaded){
                loadLocations({type:objectType, source:dataSource, offset:locationList.length})
              }
            }}
          />
        </div>
      }
      <div style={{flexGrow:1}}>
        <SearchSelector
          url='/solr/search/select'
          params={{
            q:`${objectSearchValue}*`,
            fq: `object_type_srt:${objectType==='none'?'*':mapObjectName(objectType)}` + 
              (dataSource==='none'?'':` AND source_id_srt:${dataSource}`) +
              (schema==='none'?'':` AND schema_srt:${schemaList.find(s=>s.id===schema).name_txt}`) + 
              (isMulti && objectList.length>0?` AND -id:(${objectList.map(el=>el.id).join(' OR ')})`:'') + 
              (selectedLocation==='all'?'':` AND location_srt:"${selectedLocation}"`)
              ,
            rows: 10
          }}
          autoSuggestion
          // removeFLModify={false}
          searchValue={objectSearchValue}
          setSearchValue={setObjectSearchValue}
          placeholder={
            code==='0390'?
            `Select a specific ${objectType==='none'?'object':titleCaseObjectName(mapObjectName(objectType)).toLowerCase()}`:
            `Search for an object`
        }
          keepPopperOnClick={isMulti}
          onResultClick={el=>{
            if(isMulti){
              if(!objectList.find(o=>o.id===el.id)){
                setObjectList([...objectList,el])
              }
            }else{
              setObject(el)
            }
          }}
        />
        <Typography style={{fontSize:12,marginLeft:18,marginBottom:16,color:theme.palette.primaryText.light,marginTop:6}}>{required?'Required':'Optional'}</Typography>
        <div style={{display:'flex',overflow:'hidden',flexWrap:'wrap'}}>
          {
            (isMulti?objectList:[object]).filter(el=>el).map(o=>(
              <div className={classes.userChip}>
                <div style={{marginRight:20,marginLeft:10, display:'inline-block', verticalAlign:'middle'}}>
                  {getIconComponent({label:o.object_type_txt, size:24,colour:theme.palette.primary.main})}
                </div>
                <Typography style={{display:'inline-block', verticalAlign:'middle', color:theme.palette.primaryText.main}}>{o.name_txt}</Typography>
                {
                  <div style={{marginLeft: 20, display:'inline-block', verticalAlign: 'middle'}}>
                    <Button 
                      style={{padding:0,width:24,minWidth:24}} 
                      onClick={()=>{
                        if(isMulti){
                          setObjectList(objectList.filter(el=>el.id!==o.id))
                        }else{
                          setObject()
                        }
                      }}
                    >
                      {getIconComponent({label:'clear', size:20, colour:theme.palette.primaryText.light})}
                    </Button>
                  </div>
                }	
              </div>
            ))
          }
        </div>
        {
          (isMulti?objectList:[object]).filter(el=>el).length===0 && 
          <div className={classes.userChip}> 
            <Typography style={{display:'inline-block', verticalAlign:'middle', paddingRight: '1rem', color:theme.palette.primaryText.main,paddingLeft:12}}>
             {
              code==='0390'?
              `All ${objectType==='none'?'content':titleCaseObjectName(mapObjectName(objectType)).toLowerCase()}(s) selected. Select a ${objectType==='none'?'content object':titleCaseObjectName(mapObjectName(objectType)).toLowerCase()} above to filter this extract`
              :
              'No object selected'
             }
            </Typography>
          </div>
        }
      </div>
    </div>
  )
}

ObjectSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  object: PropTypes.object,
  setObject: PropTypes.func.isRequired,
  objectSearchValue: PropTypes.string.isRequired,
  setObjectSearchValue: PropTypes.func.isRequired,
  dataSource: PropTypes.string.isRequired,
  schema: PropTypes.string.isRequired,
  schemaList: PropTypes.array.isRequired
}

export default withTheme()(ObjectSelector);