import axiosSolr from "../../../axios-solr"
import { formatNumber, sendMessage, toTitleCase } from "../../../utilities";

export const processObjectName = (name) => {
  if(!name)return '';
  if(name==='ML_MODEL')return 'ML Model';
  if(name==='DATA_PIPELINE')return 'Pipeline'
  return toTitleCase(name.replace(/_/g,' '))
}

export const loadTilesByObjectType = ({objectTypes, source }) => {
  return axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectTypes.join(' OR ')}) AND active_srt:YES AND -source_id_srt:998 AND usage_srt:(DAILY OR WEEKLY OR MONTHLY OR INFREQUENT)` + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "object_types": {
              "type": "terms",
              "field": "object_type_srt",
              "mincount":1,
              "limit":50,
            }
          }
        }
      }
    )
}

export const processTileData = ({facet, objectTypes}) => {
  if(!facet.data.facets.object_types)return []
  let buckets = facet.data.facets.object_types.buckets;
  let tiles = objectTypes.filter(o=>buckets.find(b=>b.val===o)).map(o=>({
    id:o,
    clickable:false,
    title:`${toTitleCase(o.replace(/_/g,' '))}s`,
    value:formatNumber(buckets.find(b=>b.val===o)?.count || 0)
  }))
  return tiles
}

export const loadTotalByPii = async ({source, objectType, state}) => {
  let chartData;

  let validSources;
  if(state.objectCountBySource){
    validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
  }

  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:${objectType} AND active_srt:YES AND -source_id_srt:998` + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "pii": {
              "type": "terms",
              "field": "pii_meta_linked_kc_msrt",
              "mincount":1,
              "limit":50,
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.pii)return;
      let buckets = facet.data.facets.pii.buckets;
      if(buckets.length===0)return;
      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }
      else if(validSources && validSources.length>0){
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      chartData = {
        labels:buckets.map(b=>b.val), 
        series:[
          {
            name:'total',
            data:buckets.map(b=>b.count),
            clickFns:buckets.map(b=>()=>{
              sendMessage({
                modalQuery:"*",
                modalObject:objectType,
                modalFilter:`usage_srt:UNUSED AND active_srt:YES${sourceFq} AND pii_meta_linked_kc_msrt:${b.val}`
              })
            })
          }
        ]
      }
    })
  return chartData
}

export const loadByPii = async ({source, objectType,usage, state}) => {

  let chartData;
  let validSources;
  if(state.objectCountBySource){
    validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
  }

  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:${objectType} AND active_srt:YES AND -source_id_srt:998` + (source?` AND source_id_srt:${source.id}`:'')+(usage?` AND usage_srt:${usage}`:''),
          rows:0,
          'json.facet':{
            "pii": {
              "type": "terms",
              "field": "pii_meta_linked_kc_msrt",
              "mincount":1,
              "limit":50,
              'facet':{
                'masked':{
                  'type':'query',
                  'q':`masked_srt:YES`
                }
              
              }
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.pii)return;
      let buckets = facet.data.facets.pii.buckets;
      if(buckets.length===0)return;
      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }
      else if(validSources && validSources.length>0){
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      chartData = {
        labels:buckets.map(b=>b.val), 
        series:[
          {
            name:'total',
            data:buckets.map(b=>b.count),
            clickFns:buckets.map(b=>()=>{
              sendMessage({
                modalQuery:"*",
                modalObject:objectType,
                modalFilter:`usage_srt:UNUSED AND active_srt:YES${sourceFq} AND pii_meta_linked_kc_msrt:${b.val}` + (usage?` AND usage_srt:${usage}`:'')
              })
            })
          },
          {
            name:'masked',
            data:buckets.map(b=>b.masked.count),
            clickFns:buckets.map(b=>()=>{
              sendMessage({
                modalQuery:"*",
                modalObject:objectType,
                modalFilter:`usage_srt:UNUSED AND active_srt:YES AND pii_meta_linked_kc_msrt:${b.val} AND masked_srt:YES${sourceFq}` + (usage?` AND usage_srt:${usage}`:'')
              })
            })
          }
        ]
      }
    })
  return chartData
}

export const loadBySourceRecommendations = async ({objectType, source}) => {
  let recommendations = [undefined,undefined, undefined];
  let promises = [];
  
  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:COLUMN AND active_srt:YES AND pii_meta_linked_kc_msrt:* AND source_id_srt:${source.id} AND masked_srt:NO`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        recommendations[0] = {
          title:`PII detected columns with no masking`,
          id:'pii_column_no_masking',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:'COLUMN',
              modalFilter:`pii_meta_linked_kc_msrt:* AND active_srt:YES AND pii_meta_linked_kc_msrt:* AND source_srt:${source.name} AND masked_srt:NO`
            })
          }
        }
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:TABLE AND active_srt:YES AND pii_meta_linked_kc_msrt:* AND usage_srt:DAILY AND source_id_srt:${source.id} AND masked_srt:NO`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        recommendations[1] = {
          title:`PII detected tables used daily with no masking`,
          id:'pii_table_daily_no_masking',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`usage_srt:DAILY AND active_srt:YES AND pii_meta_linked_kc_msrt:* AND source_srt:${source.name} AND masked_srt:NO`
            })
          }
        }
      })
  )
  
  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:TABLE AND active_srt:YES AND pii_meta_linked_kc_msrt:* AND usage_srt:UNUSED AND source_id_srt:${source.id} AND masked_srt:NO`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        recommendations[2] = {
          title:`PII detected tables that are unused with no masking`,
          id:'pii_table_unused_no_masking',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`usage_srt:INFREQUENT AND active_srt:YES AND source_srt:${source.name}`
            })
          }
        }
      })
  )


  await Promise.all(promises)

  return recommendations.filter(r=>r)
}