import React from 'react';
import { withTheme, withStyles,  } from '@material-ui/core';
// import People from './People/People'
import BySource from './BySource/BySource';

const styles = theme => ({
  root:{
    // display:'flex'
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
    marginBottom:6,
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  }
})

const Usage = props => {

  const {
    classes,
    // theme,
    history,
    sessionData,
    state,
    dispatch
  } = props;

  
  return (
    <div className={classes.root}>
      <BySource state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
    </div>
  )
}

export default withTheme()(withStyles(styles)(Usage));