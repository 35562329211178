import React, { useRef, useEffect, useState} from 'react';
import { withTheme,withStyles,Typography, CircularProgress, IconButton} from '@material-ui/core';
import { getDispFields, getIconComponent, isInViewport, onClickResultItem } from '../../../../utilities';
import KTooltip from '../../../UI/KTooltip/KTooltip';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';
import CustomMenu from '../../../UI/ContextMenu/ContextMenu'
import DetailModal from './DetailModal';

const styles = theme => ({
  root:{
  },
  columnRow:{
    display:'flex',
    marginBottom:16,
    paddingLeft:16,
  },
  columnText:{
    fontSize:12,
    color:theme.palette.primary.main,
    marginRight:16,
    textTransform:'uppercase',
    letterSpacing:1.5
  },
  tableRow:{
    display:'flex',
    overflow:'hidden',
    alignItems:'center',
    paddingLeft:16,
    height:60,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  clickableRow:{
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  tableNameText:{
    fontSize:16,
    color:theme.palette.primary.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  tableText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    marginRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    boxSizing:'border-box'
  }
})

const List = props => {

  const {
    classes,
    // history,
    theme,
    state,
    data,
    columns,
    selectedTile,
    onLoadData,
    direction
  } = props;

  const scrollRef = useRef()

  const [modalOpen, setModalOpen] = useState(false)
  const [modalObject, setModalObject] = useState()
  const [iconHovered, setIconHovered] = useState(false)

  let flexConfig = {};
  columns.forEach(c=>{
    if(c.field==='max_level_srt'){
      flexConfig[c.field] = '0 0 80px'
    }
    else if(c.field==='name_srt'){
      flexConfig[c.field] = '2 0 280px'
    }
    else{
      flexConfig[c.field] = `1 1 ${['data','content','people','all'].includes(selectedTile)?100:136}px`
    }
  })


  const shouldLoadMore = () => {
    return data && !data.loading && data.docs && data.docs.length<data.numFound && isInViewport(scrollRef)
  }

  window.onscroll = () => {
    if(shouldLoadMore()){
      onLoadData({start:data.docs.length})
    }
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      onLoadData({start:data.docs.length})
    }
   // eslint-disable-next-line
  },[data])

  useEffect(()=>{
    if(!data){
      onLoadData({})
    }
   // eslint-disable-next-line
  },[selectedTile,data])

  if(!columns)return <div></div>

  const getDispValue = (data, field, name) => {
    if(['users_msrt','teams_msrt'].includes(field)){
      return data[field]?data[field].length:0
    }
    let value = data[field] ;
    if(Array.isArray(data[field]))value = data[field].join(', ')
    if(field==='max_level_srt')return value;
    if(!value){
      if(field.includes('_impact_count_srt'))return 0;
      if(field==='data_roles_msrt')return 'NONE'
      return `No ${name}`.toUpperCase()
    }
    return value;
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.columnRow}>

        <div style={{flex:'0 0 24px',flexGrow:0,flexShrink:0,marginRight:24}}>
        </div>
        {
          data && data.numFound>0 && columns.map(c=>(
            <Typography style={{flex:flexConfig[c.field]}} className={classes.columnText}>
              {c.name}
            </Typography>
          ))
        }
        {
          selectedTile==='all' && 
          <Typography style={{flex:'0 0 100px'}} className={classes.columnText}>
            ACTIONS
          </Typography>
        }
      </div>
      {
        data && data.docs && data.docs.map(d=>(
          <div>
            <ContextMenuTrigger id={d.id}>
              <div  
                onClick={()=>{
                  if(!['data','content','people'].includes(selectedTile)){
                    let item = {...d,id:d.object_id};
                    onClickResultItem({item, id:item.id, label:item.object_type_srt,newWindow:true})
                    return;
                  };
                  setModalObject(d)
                  setModalOpen(true)
                }}
                className={classes.tableRow + ' '+(iconHovered?'':classes.clickableRow)}
              >
                <div style={{flex:'0 0 24px',flexGrow:0,flexShrink:0,height:24,marginRight:24,marginTop:-16}}>
                  {getIconComponent({label:d.object_type_txt,size:24,colour:theme.palette.primary.main})}
                </div>
                {
                  columns.map(c=>(
                    c.field==='name_srt'?
                    <div style={{flex:flexConfig[c.field], marginRight:16, overflow:'hidden'}}>
                      <KTooltip title={getDispFields(d, 'dispTitle')}>
                        <Typography className={classes.tableNameText} style={{marginBottom:4}} >
                          {getDispFields(d, 'dispTitle')}
                        </Typography>
                      </KTooltip>
                      <KTooltip title={getDispFields(d, 'dispSubtitle')?.toUpperCase()}>
                        <Typography className={classes.tableText} style={{marginRight:0,fontSize:12,color:theme.palette.primaryText.light}} >
                          {getDispFields(d, 'dispSubtitle')?.toUpperCase()}
                        </Typography>
                      </KTooltip>
                    </div>
                    :
                    <KTooltip title={getDispValue(d, c.field, c.name)+''} >
                      <Typography style={{flex:flexConfig[c.field], paddingLeft:c.field==='max_level_srt'?16:0}} className={classes.tableText}>
                        {getDispValue(d, c.field, c.name)}
                      </Typography>
                    </KTooltip>
                  ))
                }
                {
                  selectedTile==='all' &&
                  <div style={{flex:'0 0 100px',marginRight:16}}>
                    <KTooltip title={`Run single target ${direction==='upstream'?'DA':'IA'} of this object.`}>
                      <IconButton
                        onClick={(event)=>{
                          event.stopPropagation()
                          window.open(`/${direction==='upstream'?'dependency_assessment':'impact_assessment'}?targetObjectId=${d.id}`)
                        }}
                        onMouseEnter={()=>setIconHovered(true)}
                        onMouseOut={()=>setIconHovered(false)}
                        onMouseOver={()=>setIconHovered(true)}
                        style={{padding:6}}
                      >
                        {getIconComponent({label:'play',size:24,colour:theme.palette.primary.main})}
                      </IconButton>
                    </KTooltip>
                  </div>
                }
              </div>
            </ContextMenuTrigger>
            <ContextMenu id={d.id}>
              <CustomMenu
                item={{...d,id:d.object_id}}
                actions={[
                  'open_new_tab'
                ]}
              />
            </ContextMenu>
          </div>
        ))
      }
      {
        data && data.numFound===0 && 
        <Typography>No item found</Typography>
      }
      <div style={{display:'flex',justifyContent:'center',marginTop:8}} ref={scrollRef}>
        {
          data && data.loading && 
          <CircularProgress color='secondary'/>
        }
      </div>

      <DetailModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        object={modalObject}
        state={state}
      />

    </div>  
  )
}

export default withTheme()(withStyles(styles)(List));