import React, {useEffect, useRef} from 'react';
import { withStyles, Typography, Button, Stepper, Step, StepLabel, StepContent, Select, FormControl, InputAdornment, MenuItem, FormHelperText, InputBase, Checkbox} from '@material-ui/core';
import theme from '../../../theme';
import axiosCerebrum from '../../../axios-cerebrum'
import { getIconComponent } from '../../../utilities'
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  title:{
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:8
  },
  block:{
    maxWidth:640,
    marginLeft:6,
    marginTop:8
  },
  buttons:{
    position:'fixed',
    backgroundColor:theme.palette.background.main,
    height:80,
    bottom:0,
    right:0,
    left:40,
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end',
    boxShadow:'0px -1px 5px 0px #DDD'
  },
  stepper:{
    marginBottom:120,
    backgroundColor:theme.palette.background.main,
    // paddingLeft:0
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
  selector: {
    ...theme.components.selector,
    width: 618,
    height:56,
    marginTop: 8,
  },
  inputBase:{
    ...theme.components.inputBase,
    width: 618,
    height:56,
    marginTop: 8,
  },
  checkbox:{
    marginLeft:-16,
    
  },
  selectedCheckbox:{
    '& svg':{
      color:theme.palette.primary.main,
    }
  }
})

const CreateKey = props => {

  const {
    classes,
    state,
    dispatch,
    // sessionData,
    // history
  } = props;

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const loadSource = ({page=1,previousData=[]}) => {
    axiosCerebrum
      .get(
        `/api/sources`,
        {params:{
          page,
          per_page:10,
          types:'EXTERNAL_INTEGRATION',
          sort:'ALPHABETICAL'
        }}
      )
      .then(response=>{
        if(response.data.page<response.data.pages){
          loadSource({page:response.data.page+1,previousData:[...previousData,...response.data.items]})
        }else{
          dispatch({
            type:'set_source_list',
            sourceList:[...previousData,...response.data.items]
          })
        }
      })
  }

  const loadCapabilities = ({page=1,previousData=[]}) => {
    axiosCerebrum
      .get(
        `/api/capabilities`,
        {params:{
          page,
          per_page:10,
        }}
      )
      .then(response=>{
        if(response.data.page<response.data.pages){
          loadCapabilities({page:response.data.page+1,previousData:[...previousData,...response.data.items]})
        }else{
          dispatch({
            type:'set_capabilities',
            capabilities:[...previousData,...response.data.items]
          })
        }
      })
  }

  useEffect(()=>{
    if(!state.sourceList)loadSource({})
    if(!state.capabilities)loadCapabilities({})
    // eslint-disable-next-line
  },[])

  // to do, need to be dynamic
  let steps = [
    'Select an integration',
    'Add a name',
    'Create key'
  ]

  let isGenericIntegration = false;
  if(state.source!=='none' && state.sourceList.find(el=>el.id===state.source).source_template.name==='GENERIC_INTEGRATION')isGenericIntegration = true

  if(isGenericIntegration){
    steps = [
      'Select an integration',
      'Add a name',
      'Select the capabilities for this key',
      'Create key'
    ]
  }

  // to do 
  const labelClickable = index => {
    switch(index){
      case 0:
        return true;
      case 1:
        return state.source!=='none'
      case 2:
        return labelClickable(1) && state.keyName.trim()!==''
      case 3:
        return labelClickable(2) && state.selectedCapabilities.length>0
      default:
    }
  }

  const getStepLabelText = index => {
    switch(index){
      case 0:
        if(state.source!=='none')return `${steps[0]}: ${state.sourceList.find(el=>el.id===state.source).name}`
        return steps[0];
      case 1:
        if(state.createKeyStep<=1)return steps[1];
        return `${steps[1]}: ${state.keyName}`
      case 2:
        if(state.selectedCapabilities.length>0 && isGenericIntegration)return `${steps[2]}: ${state.selectedCapabilities.join(', ')}`
        return steps[2]
      case 3:
        return steps[3]
      default:
    }
  }

  const onReset = () => {
    dispatch({type:'set_create_key_step',createKeyStep:0})
    dispatch({type:'set_key_name',keyName:''})
    dispatch({type:'set_source',source:'none'})
    dispatch({type:'set_selected_capabilities',selectedCapabilities:[]})
  }

  const onLabelClick = index => {
    if(labelClickable(index))dispatch({type:'set_create_key_step',createKeyStep:index})
  }

  const onSelectSource = sourceId => {
    dispatch({type:'set_source',source:sourceId})
    if(sourceId==='none')dispatch({type:'set_key_name',keyName:''})
    if(sourceId!=='none')dispatch({type:'set_create_key_step',createKeyStep:1})
    dispatch({type:'set_selected_capabilities',selectedCapabilities:[]})
  }

  const onKeyNameChange = name => {
    dispatch({type:'set_key_name',keyName:name})
  }

  const onConfirmKeyName = () => {
    axiosCerebrum
      .get(
        `/api/apikeys`,{params:{name:state.keyName,per_page:0}}
      ).then(response=>{
        if(response.data.total!==0){
          sendAlert({message:'The key name already exists',type:'error'})
        }else{
          dispatch({type:'set_create_key_step',createKeyStep:2})
        }
      })
    
  }

  const onClickCapability = id => {
    if(state.selectedCapabilities.includes(id)){
      dispatch({type:'set_selected_capabilities',selectedCapabilities:state.selectedCapabilities.filter(el=>el!==id)})
    }else{
      dispatch({type:'set_selected_capabilities',selectedCapabilities:[...state.selectedCapabilities,id]})
    }
  }

  const onCreateKey = () => {
    axiosCerebrum
      .post(
        `/api/apikeys`,
        {
          "name": state.keyName,
          "source_id": state.source,
          "inherit_source": isGenericIntegration?false:true,
          "capabilities": isGenericIntegration?state.selectedCapabilities:[]
        }
      )
      .then(response=>{
        dispatch({type:'set_created_key',createdKey:response.data})
        dispatch({type:'set_key_list'});
        dispatch({type:'set_tab_state',tabState:0});
        onReset();
      })
      .catch(error=>{
        console.log(error)
        let msg = "Error occurred creating the key";
        if(error.response && error.response.status && error.response.status===409){
          msg = 'The key name already exists'
        }
        sendAlert({message:msg,type:'error'})
      })
  }

  const sourceSelector = (
    <div className={classes.block}>
      <FormControl>
        <Select
          className={classes.selector}
          value={state.source}
          onChange={event=>onSelectSource(event.target.value)}
          startAdornment={
            <InputAdornment style={{marginLeft:16}}>{getIconComponent({label:'database',size:24,colour:theme.palette.primaryText.light})}</InputAdornment>
          }
          renderValue={el=>{
            return (
              (el==='none' || !state.sourceList || state.sourceList.length===0)
                ?
                <Typography style={{color:theme.palette.primaryText.light,fontSize:16}}>
                  Select an Integration
                </Typography>
                :
                <div style={{marginLeft:-14}}>
                  <Typography style={{color:theme.palette.primaryText.light,fontSize:12}}>
                    Select an Integration
                  </Typography>
                  <Typography style={{fontSize:16,color:theme.palette.primaryText.main}}>
                    {state.sourceList.find(s=>s.id===el).name}
                  </Typography>
                </div>
                
            )
          }}
        > 
          <MenuItem className={classes.menuItem} value={'none'}>
            Select an Integration
          </MenuItem>
          {
            state.sourceList && state.sourceList.filter(el=>!state.keyList || !state.keyList.items.find(k=>k.source.id===el.id)).map(el=>(
              <MenuItem className={classes.menuItem} value={el.id} key={el.id}>
                {el.name}
              </MenuItem>
            ))
          }
        </Select>
        <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>Required</FormHelperText>
      </FormControl>
    </div>
  )

  const nameInput = (
    <div className={classes.block}>
      <FormControl>
        <InputBase
          value={state.keyName}
          className={classes.inputBase}
          onChange={event=>onKeyNameChange(event.target.value.slice(0,50))}
          startAdornment={
            <InputAdornment style={{marginLeft:16,marginRight:16}}>{getIconComponent({label:'info',size:24,colour:theme.palette.primaryText.light})}</InputAdornment>
          }
          placeholder="Give this key a name"
        />
        <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>Required</FormHelperText>
      </FormControl>
      <Button 
        style={{marginTop:16}} 
        onClick={()=>onConfirmKeyName()} 
        color="primary" 
        variant="contained" 
        disabled={state.keyName.trim()===''}
      >
        NEXT
      </Button>
    </div>
  )

  const capabilitiesSelector = (
    <div className={classes.block}>
      {
        state.capabilities && state.capabilities.map(el=>(
          <div key={el.name} style={{display:'flex',alignItems:'center'}}>
            <Checkbox
              color='primary'
              className={classes.checkbox}
              classes={{checked:classes.selectedCheckbox}}
              onClick={()=>onClickCapability(el.name)}
              selected={state.selectedCapabilities.includes(el.name)}
            />
            <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>{el.name}</Typography>
          </div>
        ))
      }
      
      <Button style={{marginTop:16}} onClick={()=>dispatch({type:'set_create_key_step',createKeyStep:3})} color="primary" variant="contained" disabled={state.selectedCapabilities.length===0}>NEXT</Button>
    </div>
  )

  const createButton = (
    <div className={classes.block}>
      <Button style={{marginTop:16}} onClick={onCreateKey} color="primary" variant="contained" disabled={state.keyName.trim()===''}>CREATE KEY</Button>
    </div>
  )

  // to do 
  const getStepContent = index => {
    switch(index){
      case 0:
        return sourceSelector;
      case 1:
        return nameInput;
      case 2:
        return isGenericIntegration?capabilitiesSelector:createButton
      case 3:
        return createButton
      default:
    }
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>ACCESS KEY DETAILS</Typography>

      <Stepper activeStep={state.createKeyStep} orientation="vertical" className={classes.stepper} >
        {steps.map((label, index) => (
          <Step key={index} className={classes.step}>
            <StepLabel style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}}>
              <Typography color='primary' style={{color:state.settingStep<index?theme.palette.primaryText.light:theme.palette.primaryText.main,fontSize:16,marginLeft:6}}>
                {getStepLabelText(index)}
              </Typography> 
            </StepLabel>
            <StepContent>
             {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>

      <div className={classes.buttons}>
        <Button color='primary' onClick={onReset} style={{height:48,width:120}} >
          RESET
        </Button>
        <Button variant='contained' color='primary' style={{marginLeft:30,height:48,marginRight:180,width:120}} onClick={()=>{onReset();dispatch({type:'set_tab_state',tabState:0})}}>
          CANCEL
        </Button>
    </div>
    </div>
  )
}


export default withStyles(styles)(CreateKey);