import React from 'react';
import { withStyles} from '@material-ui/core';
import Reports from '../../Discover/Reports/Reports';
import Glossary from '../../Discover/Glossary/Glossary';
import Collection from '../../Discover/Collection/Collection';
import Lists from '../../Discover/Lists/Lists';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import Issues from '../../Discover/Issues/Issues';
import Tags from '../../Discover/Tags/Tags';

const styles = theme => ({
  root: {
    // display:'flex',
    // overflow:'hidden',
    marginTop:6
  }
})

const Discover = props => {

  const {
    classes,
    history,
    state,
    dispatch,
  } = props;

  let tabOptions = ['REPORTS','TERMS','COLLECTIONS','LISTS','ISSUES','TAGS']

  let bodyContent;

  switch (tabOptions[state.discoverTabState]) {
    case 'REPORTS':
      bodyContent = <Reports state={state} dispatch={dispatch} history={history} />
      break;
    case 'TERMS':
      bodyContent = <Glossary state={state} dispatch={dispatch} history={history} />
      break;
    case 'COLLECTIONS':
      bodyContent = <Collection state={state} dispatch={dispatch} history={history}/>
      break;
    case 'LISTS':
      bodyContent = <Lists state={state} dispatch={dispatch} history={history}/>
      break;
    case 'ISSUES':
      bodyContent = <Issues state={state} dispatch={dispatch} history={history}/>
      break;
    case 'TAGS':
      bodyContent = <Tags state={state} dispatch={dispatch} history={history}/>
      break;
    default:
  }
  return (
    <div className={classes.root}>
      <div style={{float:'left'}}>
        <VerticalTabBar
          tabOptions={tabOptions}
          tabState={state.discoverTabState}
          setTabState={value=>dispatch({type:'set_discover_tab_state',discoverTabState:value})}
        />
      </div>
      <div style={{marginLeft:280}}>
        {bodyContent}
      </div>
    </div>
  )
}

export default withStyles(styles)(Discover);