import React,{useState,useEffect, useRef } from 'react';
import { withTheme, withStyles,  Modal, } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import ManualCollectionEditor from '../../UI/ProfileEditor/ManualCollectionEditor';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
})

function InstanceEditModal(props) {

  const {
    classes,
    modalOpen,
    setModalOpen,
    data,
    dispatch,
    state
  } = props;

  const [userMap, setUserMap] = useState({})
  const [collectionMap, setCollectionMap] = useState({})
  const [object, setObject] = useState()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  useEffect(()=>{
    if(modalOpen){
      axiosCerebrum
        .get(`/api/collectioninstances/${data.id}`)
        .then(response=>{
          setObject(response.data)
        })
        .catch(error=>{
          console.log(error)
          sendAlert({message:"Error occurred loading edit modal",type:'error'})
          setModalOpen(false)
        })
    }
  // eslint-disable-next-line
  },[modalOpen])

  return (
    <Modal className={classes.modal} open={modalOpen} onClose={() => setModalOpen(false)} disableBackdropClick>
      {
        object ?
        <ManualCollectionEditor
          data={object}
          properties={object.parent.properties}
          setEditing={setModalOpen}
          userMap={userMap}
          onUpdated={(data)=>{
            if(state.instanceData){
              dispatch({
                type:'set_instance_data',
                instanceData:state.instanceData.map(el=>{
                  if(el.id!==data.id)return el;
                  return {
                    ...el,
                    name:data.name
                  }
                })
              })
            }
            if(state.instanceListData){
              dispatch({
                type:'set_instance_list_data',
                instanceListData:{
                  ...state.instanceListData,
                  items:state.instanceListData.items.map(el=>{
                    if(el.id!==data.id)return el;
                    return {
                      ...el,
                      name:data.name
                    }
                  })
                }
              })
            }
            
          }}
          setUserMap={setUserMap}
          collectionMap={collectionMap}
          setCollectionMap={setCollectionMap}
        />
        :
        <div></div>
      }
    </Modal>
  )
}

export default withTheme()(withStyles(styles)(InstanceEditModal));