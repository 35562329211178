import React, { useEffect } from 'react';
import { withTheme, withStyles, Drawer, IconButton, Button, Typography } from '@material-ui/core';
import { getIconComponent, onClickResultItem, toTitleCase } from '../../../utilities';
import ProfileSideBar from '../ProfileSideBar/ProfileSideBar';

const styles = theme => ({
  
  drawerPaper:{
    borderLeft:`1px solid ${theme.palette.listItemDivider.main}`,
    height:'100vh',
    overflow:'hidden',
    width:600,
    paddingRight:24,
    maxWidth:'40vw',
    display:'flex',
    flexDirection:'column',
    background:theme.palette.background.main,
    cursor:'default'
  },
  customScroll:{
    ...theme.components.hideScroll
  },
  caption:{
    fontSize:12,
    marginBottom:12,
    color:theme.palette.primaryText.light
  },
  header:{
    marginBottom:24
  },
})


function DetailDrawer(props) {
  const {
    classes,
    history,
    theme,
    drawerOpen,
    setDrawerOpen,
    selectedItem,
    state,
    dispatch,
    // additionalDetail
  } = props;

  const getTitleFontSize = text => {
    let fontSize = 20;
    if(text.length>70){
      fontSize = 16;
    }
    if(text.length>120){
      fontSize = 13.75;
    }
    return fontSize
  }

  let isQuestion = selectedItem?.collection_txt==='QUESTION' || selectedItem?.parent?.name==='Question'
  let isLink = selectedItem?.collection_txt==='LINK' || selectedItem?.parent?.name==='Link'
  let title = selectedItem?.name;

  // if(isLink){
  //   title = additionalDetail?.objDetail?.properties?.[3] || selectedItem?.name || '';
  // }

  useEffect(() => {
    const handleEsc = (event) => {
      // check if key is 'ESC`
      if (event.key==='Escape') {
        setDrawerOpen(false)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    // eslint-disable-next-line
  }, [])
  

  return (
    <Drawer style={{zIndex:drawerOpen?999:998}} anchor="right" open={drawerOpen} classes={{paper:classes.drawerPaper}} onClose={()=>setDrawerOpen(false)} variant="persistent">
      { 
        selectedItem && drawerOpen && 
        <>
          <div style={{display:'flex',alignItems:'flex-start',paddingLeft:24,paddingTop:16,paddingBottom:24}}>
            <Button 
              color='primary' 
              variant='contained'
              style={{marginRight:12}}
              data-test-id="open-detail-drawer-button"
              onClick={()=> onClickResultItem({item:selectedItem,id:selectedItem.id,label:'collection_instance',newWindow:true})}
            >
              OPEN
            </Button>
            <div style={{flexGrow:1}}></div>
            <IconButton 
              onClick={(event)=>{event.stopPropagation();setDrawerOpen(false)}} 
              style={{width:28,height:28,padding:8}}
            >
              {
                getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})
              }
            </IconButton>
          </div>
          <div style={{paddingLeft:24}}>
            <Typography classes={classes.caption}>
              {toTitleCase(selectedItem.collection_txt)}
            </Typography>
            <Typography 
              className={classes.header} 
              style={{fontSize:getTitleFontSize(title)}}
              // onClick={()=>{
              //   if(!isLink)return;
              //   if(!additionalDetail?.objDetail?.properties?.[3] )return;
              //   onGoToLink(additionalDetail?.objDetail?.properties?.[3])
              // }}
            >
              {title}
            </Typography>
            <ProfileSideBar
              tabOptions={[]}
              history={history}
              state={state}
              dispatch={dispatch}
              mapping={[isLink?'link_wide':undefined,'description_wide',isQuestion?'answer_wide':undefined].filter(el=>el)}
              data={{...selectedItem, object:{name:'COLLECTION_INSTANCE'}}}
              alwaysOpenNewTab={true}
            />      
          </div>
        </>
      }
      
    </Drawer>
  )
}

export default withTheme()(withStyles(styles)(DetailDrawer));
