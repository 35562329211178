import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Feed from '../Feed/Feed';
// import Discover from '../Discover/Discover';
// import MyEcosystem from '../MyEcosystem/MyEcosystem'
// import Dashboard from '../Dashboard/Dashboard'
import RightSideBar from '../RightSideBar/RightSideBar'
import TabBar from '../../UI/TabBar/TabBar';
import Follows from '../Follows/Follows';

const styles = theme => ({
  root: {
    marginTop:48,
    display:'flex',
  }
})

const Body = props => {

  const {
    classes,
    history,
    state,
    dispatch,
    sessionData,
    roles
  } = props;


  return (
    <div className={classes.root} >
      <div style={{flex:'0 1 544px',overflow:'hidden'}}>
        <div style={{marginLeft:-52,marginBottom:16}}>
          <TabBar
            tabOptions={['MY FEED','FOLLOWING']}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        </div>
        {
          state.tabState===0 &&
          <Feed
            history={history}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
          />
        }
        {
          state.tabState===1 && 
          <Follows
            history={history}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
          />
        }
      </div>
      <div style={{flex:'0 0 280px',marginLeft:40,overflow:'hidden'}}>
        <RightSideBar 
          history={history}
          state={state}
          dispatch={dispatch}
          sessionData={sessionData}
          roles={roles}
        />
      </div>
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func
}

export default withStyles(styles)(Body);