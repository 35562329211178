import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, ClickAwayListener, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
// import axiosCerebrum from '../../../../axios-cerebrum'
import axiosCerebrum from '../../../../axios-cerebrum';
import { collectionIds, getIconComponent,  getLabelPlural} from '../../../../utilities';
import { tileStyles } from './utils/styles';
import { checkIsClickOnSuggestion, measureText } from './utils/utils';
import { globalListenerRef } from '../../../../GlobalListenerRef';
import useAlert from '../../../../hooks/useAlert';
import HierarchySearchSelector from '../../SearchSelector/HierarchySearchSelector';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
  iconButton:{
    ...tileStyles.deleteButton
  },
})

function DomainManager(props) {

  const {
    classes,
    theme,
    history,
    object,
    onAddTileData,
    tileData,
    isEditable,
    alwaysOpenNewTab
  } = props;

  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [deleting, setDeleting] = useState(false)
  const [adding, setAdding] = useState(false)
  const popperRef = useRef()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  
  const loadDomain = () => {
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related/collections`,
        {params:{
          category:'PLATFORM',
          parent_name:'Domain',
          relationship:'MEMBER_OF,MEMBER_OF_AUTO',
          per_page:200
        }}
      )
      .then(response=>{
        let domain = response.data.items;
        onAddTileData(
          {
            "disp_body":domain.map(el=>({
              labels:'collection_instance',
              name:el.name,
              is_auto:Boolean(el.relationship==='MEMBER_OF_AUTO'),
              id:el.id,
              onClick:()=>{
                alwaysOpenNewTab?
                window.open(`/profile/collection_instance/${el.id}`, '_blank'):
                history.push(`/profile/collection_instance/${el.id}`)
              }
            })),
            "disp_title": "Domain",
            "id": 'domain',
            "modalData":domain
          }
        )
      })
      .catch(error=>{
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(()=>{
    if(!tileData){
      loadDomain()
    }
    window.removeEventListener('message',globalListenerRef.sidebarDomainListener)
    globalListenerRef.sidebarDomainListener = (message) => {
      if(message.data.reload_sidebar_domain)loadDomain()
    }
    window.addEventListener('message',globalListenerRef.sidebarDomainListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.sidebarDomainListener)
    }
  // eslint-disable-next-line
  },[])

  const onAdd = async item => {
    if(adding)return;
    setSearchValue('')
    setAdding(true)
    axiosCerebrum
      .put(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=MEMBER_OF&object_id=${item.id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":[...tileData.disp_body,{
              name:item.name_txt || item.name,
              labels:'collection_instance',
              id:item.id,
              is_auto:false,
              onClick:()=>{
                alwaysOpenNewTab?
                window.open(`/profile/collection_instance/${item.id}`, '_blank'):
                history.push(`/profile/collection_instance/${item.id}`)
              }
            }],
            "disp_title":"DOMAIN",
            "id":"domain",
          }
        )
        setAdding(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred adding domain, please try again', type: 'error' })
        setAdding(false)
      })
  }

  const onDelete = (item) => {
    setDeleting(true)
    let showLoadingTimeout = setTimeout(()=>{
      setLoading(true)
    },800)
    axiosCerebrum
      .delete(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=MEMBER_OF&object_id=${item.id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":tileData.disp_body.filter(b=>b.id!==item.id),
            "disp_title":"DOMAIN",
            "id":"domain",
          }
        )
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred deleting domain, please try again', type: 'error' })
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
  }

  if(!tileData)return <></>


  return (
    <div data-test-id="domain-data-tile" className={classes.root}>
      <div className={classes.editableHeaderContainer}>
        <Typography className={classes.title}>DOMAIN</Typography>
      </div>
      <ClickAwayListener 
        onClickAway={(event)=>{
          if(checkIsClickOnSuggestion(event.target))return;
          setSearchValue('')
          setEditing(false)
        }}
      >
        <div ref={popperRef} className={classes.chipContainer + ' ' + (editing?classes.editingBox:isEditable?classes.clickableBox:'')} onClick={()=>isEditable && setEditing(true)}>
          {
            loading && <CircularProgress color='secondary' size={16}/>
          }
          {
            !loading && tileData && tileData.disp_body.length===0 && 
            <div data-test-classname="domain-data-tile-chip" className={classes.tag} style={{background:theme.palette.chip.main,color:theme.palette.primaryText.main,border:`1px solid ${theme.palette.border.main}`,cursor:'default'}}>
              <span className={classes.tagText} style={{color:theme.palette.primaryText.main}}>None</span>
            </div>
          }
          {
            !loading && tileData && tileData.disp_body.sort((a,b)=>a.name<b.name?-1:1).map(t=>(
              <div data-test-classname="domain-data-tile-chip" style={{background:theme.palette.primary.dark,border:'none',width:editing||tileData.disp_body.length===1?undefined:measureText(t.name)+(t.is_auto?24:0)+24}} className={classes.tag} onClick={t.onClick}>
                <span className={classes.tagText} style={{color:theme.palette.background.main}}>{t.name}</span>
                {
                  t.is_auto ?
                  <div style={{width:16,height:16,marginLeft:8,marginRight:6}}>
                    {getIconComponent({label:'auto',size:16,colour:theme.palette.background.main})}
                  </div>
                  :
                  isEditable &&
                  <IconButton className={classes.iconButton + ' chip-delete-button'}  style={{display:editing?'block':undefined}} onClick={event=>{event.stopPropagation();!deleting && onDelete(t)}}>
                    {getIconComponent({label:'clear',size:13.75,colour:theme.palette.background.main})}
                  </IconButton>
                }
              </div>
            ))
          }
          {
            editing && 
            <div style={{marginTop:8,marginBottom:6,width:'100%'}}>
              <HierarchySearchSelector
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                rootId={collectionIds.domain}
                fq={
                  `object_type_srt:COLLECTION_INSTANCE AND collection_srt:DOMAIN` +
                  (tileData.disp_body.length>0?` AND -id:(${tileData.disp_body.map(b=>b.id).join(' OR ')})`:'')
                }
                rootLabel={'collection'}
                height={32}
                onResultClick={item=>{
                  if(tileData.disp_body.map(b=>b.id).includes(item.id)){
                    if(deleting)return;
                    onDelete(item)
                  }else{
                    onAdd(item)
                  }
                }}
                clearable
                autoFocus
                testID="domain-manager-input"
                placeholder={`Search for a domain`}
                isItemSelected={item=>{
                  return tileData.disp_body.map(b=>b.id).includes(item.id)
                }}
              />
            </div>
          }
        </div>
      </ClickAwayListener>
    </div>
  )
}

DomainManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(DomainManager));