import React, { useEffect, useReducer } from 'react';
import { withStyles } from '@material-ui/core';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/CodeSearch/Body/Body';
import 'url-search-params-polyfill';
import { addHistory } from '../../HistoryManager';
import axiosSolr from '../../axios-solr';
import TabBar from '../../components/UI/TabBar/TabBar';

const styles = theme => ({
  underlineOnHover: {
    cursor: 'pointer',
    color: theme.palette.primaryText.main,
    '&:hover': {
      textDecoration: 'underline',
    }
  },
});

const initialState = {
  tabState:0,
  step:0,
  maxLimit:1000,
  sourceIDs:[],
  objectTypes:[],
  searchStr:''
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState:action.tabState
      }
    case 'set_source_list':
      return {
        ...state,
        sourceList:action.sourceList
      }
    case 'set_step':
      return {
        ...state,
        step:action.step
      }
    case 'set_object_types':
      return {
        ...state,
        objectTypes:action.objectTypes
      }
    case 'set_source_ids':
      return {
        ...state,
        sourceIDs:action.sourceIDs
      }
    case 'set_search_str':
      return {
        ...state,
        searchStr:action.searchStr
      }
    case 'set_max_limit':
      return {
        ...state,
        maxLimit:action.maxLimit
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const CodeSearch = props => {

  const {
    history,
    sessionData
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState)

  const loadSourceList = () => {
    axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:"*",
            fq:"code:*",
            rows:0,
            'json.facet':{
              sources:{
                type:'terms',
                sort:'index asc',
                field:'source_srt',
                limit:200,
                'facet':{
                  'source_id':{
                    type:'terms',
                    field:'source_id_srt',
                    limit:1
                  },
                  'object_types':{
                    type:'terms',
                    field:'object_type_srt',
                    limit:100
                  },
                  'code_types':{
                    type:'terms',
                    field:'code_type_srt',
                    limit:100
                  }
                }
              }
            }
          }
        }
      )
      .then(response=>{
        let sources = [];
        response.data.facets.sources?.buckets?.forEach(el=>{
          sources.push({
            name:el.val,
            id:el.source_id?.buckets[0]?.val,
            objectTypes:[
              ...el.object_types?.buckets?.map(el=>el.val).filter(el=>el!=='CODE'),
              ...el.code_types?.buckets?.map(el=>el.val)
            ].sort()
          })
        })
        dispatch({type:'set_source_list',sourceList:sources})
      })
      .catch(error=>{
        console.log(error)
      })
  }

  useEffect(()=>{
    if(!state.sourceList)loadSourceList()
    addHistory({url:window.location.pathname, iconLabel:'code_search', title: 'Code Search', subTitle:'Code Search',type:'application'})
    // eslint-disable-next-line
  },[])

  let tabOptions = ['SEARCH','EXTRACTS']

  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              label='code_search'
              title='Code Search'
              description={'Use this application to find code such as SQL or DAX in items across queries, macros, procedures and measures in your data ecosystem'}
              iconLabel='code_search'
            />
          </div>
        )}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            state={state}
            history={history}
            dispatch={dispatch}
            sessionData={sessionData}
            tabOptions={tabOptions}
          />
        }
      />
    </div>
  )
}

export default withStyles(styles)(CodeSearch);
