import React, { useEffect}  from 'react';
import { withTheme, withStyles, Typography, CircularProgress } from '@material-ui/core';
import axiosSolr from '../../../../axios-solr';
import { contentItems, dataItems } from '../../utils';
import StackHorizontalBar from '../Charts/StackHorizontalBar';

const styles = theme => ({

})

const ByObjectChart = props => {


  const {
    classes,
    // theme,
    data,
    state,
    setData,
    variant
  } = props;
  
  const chartData = data.byObjectData;
  const loading = data.byObjectLoading;
  const error = data.byObjectError;
  const setError = () => setData({...data, byObjectError:true, byObjectLoading:false})
  const setLoading = () => setData({...data, byObjectLoading:true, byObjectError:false})
  const setChartData = d => setData({...data, byObjectData:d, byObjectLoading:false, byObjectError:false})
  
  let items = [...dataItems,...contentItems]

  const loadChartData = ({showReference=state.showReference}) => {
    if(!chartData)setLoading(true)

    let targetFacet;
    targetFacet = {
      'object':{
        'type': 'terms',
        'field': 'starting_object_id',
        'limit':10,
        'mincount':1,
        'facet':{
          'name':{
            'type': 'terms',
            'field': 'starting_object_name_srt',
          }
        }
      }
    }

    axiosSolr
      .get(
        `/solr/impact/select`,
        {params:{
          q:'*:*',
          rows:0,
          fq:`object_type_srt:(${items.join(' OR ')}) AND
              extract_id:${state.extractData.id} ${showReference?'':'AND -reference_srt:YES'}`,
          'json.facet':{
            ...targetFacet,
            "types": {
              "type": "terms",
              "field": "object_type_srt",
              "mincount":1,
              'facet':{
                'object':{
                  ...targetFacet.object,
                  limit:500
                }
              }
            },
          }
        }}
      ).then(response=>{
        let Originlabels = response.data.facets.object.buckets?.map(b=>({id:b.val,name: b.name?.buckets[0].val})) || [];
        let labels = [...Originlabels]
        let series = [];

        response.data.facets.types.buckets.forEach(b=>{
          let data = labels.map(l=>b.object.buckets.find(sb=>sb.val===l.id)?.count || 0)
          let others = b.object.buckets.filter(sb=>!labels.find(l=>l.id===sb.val)).reduce((a,b)=>a+b.count,0)
          data.push(others)
          series.push({
            name:b.val.toUpperCase().replace(/_/g,' '),
            id:b.object.buckets[0].val,
            data:data
          })
        })
        labels = labels.map(l=>`${l.name}${state.multiHistoryObjects.find(o=>o.id===l.id)?.location_txt?` (${state.multiHistoryObjects.find(o=>o.id===l.id).location_txt})`:''}`)
        if(series.some(s=>s.data[s.data.length-1]!==0)){
          labels.push('Others')
        }else{
          series.forEach(s=>s.data.pop())
        }
        setChartData({
          series, labels, labelIds:Originlabels.map(l=>l.id)
        })
      }).catch(error=>{
        setError(true)
        console.log(error)
      })
  }

  useEffect(()=>{
    if(!chartData && !loading)loadChartData({})
     // eslint-disable-next-line
  },[])


  useEffect(()=>{
    if(chartData){
      loadChartData({showReference:state.showReference})
    }
    // eslint-disable-next-line
  },[state.showReference])
  
  return (
    <div className={classes.root}>
      {loading && <CircularProgress color='secondary'/>}
      {error && <Typography>Error occurred loading chart</Typography>}
      {
        chartData && chartData.labels.length>0 &&
        <StackHorizontalBar
          labels={chartData.labels}
          data={chartData.series}
          id="data-type-chart"
        />
      }
      {
        chartData && chartData.labels.length===0 && 
        <Typography>No {variant} assets found</Typography>
      }
    </div>  
  )
}

export default withTheme()(withStyles(styles)(ByObjectChart));