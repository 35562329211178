import React, {useEffect} from 'react';
import { withStyles} from '@material-ui/core';
import PlatformSetup from '../PlatformSetup/PlatformSetup';
import DataGovernanceSetup from '../DataGovernanceSetup/DataGovernanceSetup';
import DataManagementSetup from '../DataManagementSetup/DataManagementSetup';
import { useStore } from 'react-redux'
import { getUserRoles, setHelpWdigetVisibility } from '../../../utilities'

const styles = theme => ({
})

const Body = props => {

  const {
    classes,
    state,
    dispatch,
    history
  } = props;

  const store = useStore();
  let sessionData = store.getState().auth.session_user;
  let roles = getUserRoles(sessionData.user_role);


  useEffect(()=>{
    setHelpWdigetVisibility(true)
    return ()=>{setHelpWdigetVisibility(false)}
  // eslint-disable-next-line
  },[])

  return (
    <div className={classes.root}>
      {
        roles.some(r=>['00'].includes(r)) &&
        <PlatformSetup state={state} dispatch={dispatch}/>
      }
      {
        roles.some(r=>['00','40'].includes(r)) &&
        <DataGovernanceSetup history={history} state={state} dispatch={dispatch}/>
      }
      {
        roles.some(r=>['00','10'].includes(r)) &&
        <DataManagementSetup history={history} state={state} dispatch={dispatch}/>
      }
    </div>
  )
}

export default withStyles(styles)(Body);