import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {  Typography } from '@material-ui/core';
import { getIconComponent } from '../../utilities';


const styles = theme => ({
  left:{
    flex:'0 1 370px',
  },
  right:{
  },
  chip:{
    display:'flex',
    alignItems:'center',
    borderBottom:'1px solid #DDD', 
    maxWidth:950, 
    padding:'10px 0',
    cursor:'pointer',
    '&:hover':{
      backgroundColor:'#EEE'
    }
  }
});


const BlackList = props => {

  const {
    classes,
    state,
    dispatch
  } = props;

  let userList = {};
  state.userData.forEach(el=>{
    if(!userList[el.applicationUsername])userList[el.applicationUsername]={appUserName:el.applicationUsername,id:el.cerebrumUserId,dataSources:[]}
    userList[el.applicationUsername].dataSources.push(el);
  })

  let userArr = Object.keys(userList).map(el=>userList[el])

  const onClickChip = el => {
    dispatch({type:'set_modal_open',modalOpen:true,modalType:'update'})
    dispatch({type:'set_selected_user',selectedUser:el.appUserName})
    dispatch({
      type:'set_db_check_boxes',
      dbCheckBoxes:state.dbCheckBoxes.map(check=>({
        ...check,
        checked:el.dataSources.some(db=>{return db.datasourceId===check.obj.id})?true:false
      }))
    })
  }

  return (
    <div>
      <Typography color='primary' style={{fontSize:16}}>Users</Typography>
      <Typography style={{fontSize:12,color:'#888'}}>Click on ‘ADD USER ID’ to add a new user id to the list. Select a User ID below to edit what sources it is blocked fromed</Typography>
      <div style={{display:'flex',marginTop:16,marginBottom:16}}>
        <div className={classes.left}>
          <Typography color='primary' style={{letterSpacing:1.5, fontSize:12}}>USER ID</Typography>
        </div>
        <div className={classes.right}>
          <Typography color='primary' style={{letterSpacing:1.5, fontSize:12}}>BLOCKED FROM SOURCES</Typography>
        </div>
      </div>
      {
        state.userData.length===0 && <Typography style={{fontSize:13.75}}>No user ID's added to Block List</Typography>
      }
      {
        userArr.map(el=>(
          <div className={classes.chip} onClick={()=>{onClickChip(el)}}>
            <div className={classes.left}>
              <div style={{display:'flex',alignItems:'flex-end'}}>
                {getIconComponent({label:'user',size:24,colour:'#003C8F'})}
                <Typography color='primary' style={{marginLeft:12}}>
                  {/* {el.appUserName.split('_').join(' ')} */}
                  {el.appUserName}
                </Typography>
              </div>  
            </div>
            <div className={classes.right}>
              <Typography>{el.dataSources.map(el=>el.datasourceAlias).join(', ')}</Typography>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default withStyles(styles)(BlackList);
