import React,{useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withTheme, Typography, withStyles, Button,  CircularProgress } from '@material-ui/core';
import {getIconComponent,getLabelPlural, handleShareClick, onClickResultItem, onGoToLink} from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import 'url-search-params-polyfill';
import InteractiveViewer from '../InteractiveInput/InteractiveViewer';
import moment from 'moment';
import { globalListenerRef } from '../../../GlobalListenerRef';
import UserChip from '../Chips/UserChip';

const styles = theme => ({
  emptyText:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
  },
  sectionTitleContainer:{
    cursor:'pointer',
    display:'flex',
    alignItems:'center',
    height:32
  },
  sectionTitle:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:1,
    marginRight:4
  },
  collapseIcon:{
    width:20,
    height:20,
    transition: "transform .3s ease",
  },
  sectionListContainer:{
    overflow:'hidden',
    transition: "max-height .3s ease",
    marginBottom:16,
  },
  card:{
    maxHeight:272,
    marginBottom:8,
    borderRadius:3,
    padding:12,
    boxSizing:'border-box',
    paddingBottom:4,
    border:`1px solid ${theme.palette.listItemDivider.main}`
  },
  clickableText:{
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline'
    }
  },
  avatar:{
    ...theme.components.avatar,
    width:24,
    height:24,
    fontSize:12
  },

  timeChip:{
    width:'max-content',
    marginRight:8,
    marginBottom:2,
    flexShrink:1,
    display:'flex',
    alignItems:'center',
    padding:'1px 8px',
    height:26,
    marginTop:2,
    boxSizing:'border-box',
  },
  timeChipText:{
    color:theme.palette.primary.main,
    fontSize:13.75
  },
})

function SimplifiedNoteAdder(props) {

  const {
    theme,
    classes,
    object,
    state,
    dispatch,
    history,
  } = props;

  const [expandedId,setExpandedId] = useState([]);

  const loadNoteList = async ({parentId, page=1}) => {
    let data;
    let isError;
    await axiosCerebrum.get(
      `/api/${getLabelPlural(object.object.name)}/${object.id}/related/collections`,
        {params:{
          category:'KNOWLEDGE,LIST',
          parent_id:parentId,
          page:page,
          sort:'END_DESC',
          per_page:5,
        }}
      )
      .then(response=>{
        data = response.data;
      })
      .catch(error=>{
        console.log(error)
        isError = true;
      })
    if(isError){
      return Promise.reject('Error occurred loading notes')
    }
    if(data.items.length>0){
      let promises = []
      let details = {}
      data.items.forEach(el=>{
        promises.push(
          axiosCerebrum 
            .get(`/api/collectioninstances/${el.id}`)
            .then(repsonse=>{
              details[el.id] = repsonse.data
            })
        )
      })
      await Promise.all(promises).catch(error=>{isError=true})
      data.items = data.items.map((el,i)=>(
        {
          ...(details[el.id] || {}),
          ...el,
        }
      ))
    }
    if(isError){
      return Promise.reject('Error occurred loading notes')
    }
    
    return Promise.resolve({data});
  }

  const loadNoteCategories = async () => {
    dispatch({
      type:'set_notes',
      notesLoading:true
    })
    let noteData = {};
    await axiosCerebrum
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related/collections`,
        {params:{
          category:'KNOWLEDGE,LIST',
          page:1,
          parent_flag:true,
          per_page:10,
        }}
      )
      .then(response=>{
        response.data.items.sort((a,b)=>a.name<b.name?-1:1).forEach(el=>{
          noteData[el.id] = {name:el.name, type:'note'}
        })
      })
      .catch(error=>{
        console.log(error)
      })

    let promises = []
    let allItems = []
    Object.keys(noteData).forEach(id=>{
      promises.push(
        loadNoteList({parentId:id})
          .then(response=>{
            allItems.push(...response.data.items)
            noteData[id] = {...noteData[id],...response.data}
          })
          .catch(error=>{
            console.log(error)
            noteData[id].error = true
          })
      )
    });
    await Promise.all(promises)
    loadDetails(allItems)
    dispatch({
      type:'set_notes',
      notes:noteData
    })
  }

  useEffect(()=>{
    if(!state.notes){
      loadNoteCategories()
    }
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data[`load-note-${state.basicData.id}`] ){
        loadNoteCategories()
      } 
    }
    window.removeEventListener('message',globalListenerRef.miniProfileNoteListener);
    globalListenerRef.miniProfileNoteListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.miniProfileNoteListener);
    return (()=>{window.removeEventListener('message',globalListenerRef.miniProfileNoteListener);})
  // eslint-disable-next-line
  },[])


  const loadDetails = async (items) => {
    let detailMap = {};

    let promises = []
    items.forEach(item=>{
      promises.push(
        axiosCerebrum
          .get(
            `/api/collectioninstances/${item.id}/related`,{
              params:{relationship:'UPDATED_BY',per_page:1,sort:'END_DESC'}
            }
          )
          .then(response=>{
            let updatedBy = response.data.items[0]
            if(!detailMap[item.id]){
              detailMap[item.id] = {}
            }
            detailMap[item.id].updatedBy = updatedBy
          })
      )
      promises.push(
        axiosCerebrum
          .get(
            `/api/collectioninstances/${item.id}/related`,{
              params:{relationship:'CREATED_BY',per_page:1,sort:'END_DESC'}
            }
          )
          .then(response=>{
            let createdBy = response.data.items[0]
            if(!detailMap[item.id]){
              detailMap[item.id] = {}
            }
            detailMap[item.id].createdBy = createdBy
          })
      )
    })

    await Promise
      .all(promises)
      .then(()=>{
        dispatch({
          type:'set_note_detail_map',
          noteDetailMap:{
            ...state.noteDetailMap,
            ...detailMap
          }
        })
      })
  }

  const formatDescription = description => {
    if(!description||description.trim()==='')return 'No description provided.';
    if(description.length>280)return description.slice(0,297)+'...';
    return description
  }


  const onClickHeader = (parentId) => {
    if(expandedId.includes(parentId)){
      setExpandedId(expandedId.filter(el=>el!==parentId))
    }else{
      setExpandedId([...expandedId,parentId])
    }
  }

  const getItemHeight = data => {
    let height = 0;
    if(data.items)height += data.items.length * 272;
    if(data.loading)height += 48;
    if(data.error)height = 40
    return height;
  }


  const generateChip = ({el,parent}) => {    
    let isLink = false
    if(parent.name==='Link'){
      isLink = true
    }

    let updator = state.noteDetailMap[el.id]?.updatedBy
    let creator = state.noteDetailMap[el.id]?.createdBy
    let user = updator || creator;
    
    return (
      <div className={classes.card}>
        <Typography 
          data-test-classname={`knowledge-chip-header`} 
          onClick={isLink?()=>onGoToLink(el.properties[3]):undefined} 
          className={isLink?classes.clickableText:undefined} 
          style={{fontSize:16,fontWeight:700,marginBottom:16,color:theme.palette.primary.main,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}
        >
          {el.name}
        </Typography>
        <div style={{display:'flex',flexWrap:'wrap',alignItems:'center',marginBottom:20}}>
          {
            user && 
            <UserChip
              user={user}
              hideBorder
              onClick={()=>window.open(`/profile/user/${user.id}`,"_blank")}
            />
          }
          <div className={classes.timeChip}>
            <div style={{flexGrow:0,flexShrink:0,width:18,height:18,marginRight:8}}>
              {getIconComponent({label:'time',size:18,colour:theme.palette.primary.main})}
            </div>
            <Typography className={classes.timeChipText}>
              {moment(el.updated_at).fromNow()}
            </Typography>
          </div>
          {
            // el.parent.name==='Question' && 
            // <div  className={classes.timeChip}>
            //   <Typography className={classes.timeChipText}>
            //     Asked {moment(el.created_at).fromNow()}
            //   </Typography>
            // </div>
          }
        </div>
        {
          el.parent?.name!=='Question' && 
          <div style={{width:'100%',zIndex:0,position:'relative'}}>
            <InteractiveViewer initialValue={formatDescription(el.description)} id={el.id} alwaysShown maxHeight={300}/>
          </div>
        }
        {
          el.parent?.name==='Question' && 
          <div style={{width:'100%',zIndex:0,position:'relative'}}>
            <InteractiveViewer initialValue={el.properties[3] && el.properties[3].trim()!==''?formatDescription(el.properties[3]):'Not answered'} id={el.id} alwaysShown maxHeight={300}/>
          </div>
        }
        {
          ['link','roadmap'].includes(el.collection_name) && el.properties[3] && el.properties[3].trim()!=='' && 
          <div>
            <Typography className={classes.detailHeader} data-test-classname={`knowledge-chip-link`}>
              LINK
            </Typography>
            <Typography className={classes.detailBodyText} onClick={()=>onGoToLink(el.properties[3])} style={{paddingRight:22,wordBreak:'break-word',color:theme.palette.hyperLink.main,textDecoration:'underline', cursor:'pointer'}}>
              {el.properties[3]}
            </Typography>
          </div>
        }
        <div style={{display:'flex',marginTop:24}}>
          <Button color='primary' data-test-classname="knowledge-chip-see-more-button" style={{marginRight:8}} onClick={()=>onClickResultItem({label:'collection_instance',item:el,id:el.id,history:history,newWindow:true})}>
            SEE MORE
          </Button>
          <Button color='primary' data-test-classname="knowledge-chip-share-button" onClick={()=>handleShareClick( window.location.hostname +':'+ window.location.port + onClickResultItem({label:'collection_instance',item:el,id:el.id,history:history,urlOnly:true}))}>
            SHARE
          </Button>
        </div>
      </div>
    )
  }

  const onClickAskQuestion = () => {
   dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}})
  }

  const onClickContribute = () => {
    dispatch({type:'set_note_modal_open',noteModalOpen:true})
  }

  const onLoadMoreList = ({parentId, page}) => {
    dispatch({
      type:'set_notes',
      notes:{
        ...state.notes,
        [parentId]:{
          ...(state.notes[parentId]||{}),
          loading:true,
          error:false
        }
      }
    })
    loadNoteList({parentId,page})
      .then(response=>{
        loadDetails(response.data.items)
        dispatch({
          type:'set_notes',
          notes:{
            ...state.notes,
            [parentId]:{
              ...(state.notes[parentId]||{}),
              ...response.data,
              items:[
                ...(state.notes[parentId].items||[]),
                ...response.data.items
              ],
            }
          }
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_notes',
          notes:{
            ...state.notes,
            [parentId]:{
              ...(state.notes[parentId]||{}),
              items:undefined,
              loading:false,
              error:true
            }
          }
        })
      })

  }
  
  return (
    <div className={classes.root}>
      <div id='mini-profile-note-section'>
        {
          state.notesLoading && <CircularProgress style={{marginTop:16}} color='secondary'/>
        }
        {
          state.notesError && <Typography style={{fontSize:12,color:theme.palette.primaryText.main,marginBottom:16}}>Error occurred loading notes</Typography>
        }
        {
          state.notes && Object.keys(state.notes).length>0 && Object.keys(state.notes).map(parentId=>(
            <div>
              <div className={classes.sectionTitleContainer} onClick={()=>onClickHeader(parentId)}>
                <Typography className={classes.sectionTitle}>
                  {state.notes[parentId].name.toUpperCase()}{isNaN(state.notes[parentId]?.total)?'':` (${state.notes[parentId]?.total})`}
                </Typography>
                <div className={classes.collapseIcon} style={{transform:expandedId.includes(parentId)?'rotate(-90deg)':'rotate(0deg)'}}>
                  {getIconComponent({label:'triangle_down',size:20,colour:theme.palette.primaryText.main})}
                </div>
              </div>
              <div 
                className={classes.sectionListContainer} 
                style={{maxHeight:expandedId.includes(parentId)?0:getItemHeight(state.notes[parentId]),marginBottom:expandedId.includes(parentId)?0:16}}
              >
                {
                  state.notes[parentId].type==='note' && state.notes[parentId].items && state.notes[parentId].items.map(el=>(
                    generateChip({el,parent:state.notes[parentId]})
                  ))
                }
                {
                  state.notes[parentId].loading && 
                  <div style={{marginTop:16}}> 
                    <CircularProgress color='secondary'/>
                  </div>
                }
                {
                  state.notes[parentId].total===0 && 
                  <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>No items found</Typography>
                }
                {
                  state.notes[parentId].error && 
                  <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>Error occurred loading items</Typography>
                }
                {
                  state.notes[parentId].total>5 && state.notes[parentId].page<state.notes[parentId].pages && !state.notes[parentId].loading && 
                  <Button color='primary' style={{marginTop:8}} onClick={()=>{onLoadMoreList({parentId,page:state.notes[parentId].page+1})}}>
                    LOAD MORE
                  </Button>
                }
              </div>
            </div>
          ))
        }
        {
          state.notes && Object.keys(state.notes).length===0 && 
          <div style={{textAlign:'center',marginTop:80}}>
            <Typography className={classes.emptyText} style={{marginBottom:24}}>No questions have been asked or knowledge contributed. </Typography>
            <Typography className={classes.emptyText}>Be the first to contribute</Typography>
            <div style={{display:'flex',justifyContent:'center',marginTop:40}}>
              <Button color='primary' style={{marginRight:24}} variant="contained" onClick={()=>onClickAskQuestion(true)}>ASK A QUESTION</Button>
              <Button color='primary' variant="contained" onClick={()=>onClickContribute(true)}>ADD KNOWLEDGE</Button>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

SimplifiedNoteAdder.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(SimplifiedNoteAdder));