import React, { useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Typography, Button,  ListItem, Modal, Paper} from '@material-ui/core';
import SearchSelector from '../../UI/SearchSelector/SearchSelector';
import { getIconComponent } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import KTooltip from '../../UI/KTooltip/KTooltip';
import UserSuggestionItem from '../../UI/SearchResults/UserSuggestionItem';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  block: {
    marginBottom: '5rem',
  },
  body:{
    width:'100%'
  },
  tooltip:{
    fontSize:13.75
  },
  headerSubText: {
    marginBottom: '2rem',

    height: '16px',
    width: '46rem',
    color: theme.palette.primaryText.light,
    
    fontSize: '11.78px',
    letterSpacing: '0.4px',
    lineHeight: '16px'
  },
  headerTextOverflow: {
    fontSize: 20,
    color: theme.palette.primary.main,
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  modalPaper: {
    width:600,
    // height:192,
    marginTop:'-15vh',
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    padding:24,
    paddingBottom:8
  },
  modalTitle:{
    fontSize:20,
    color:theme.palette.primaryText.main,
    marginBottom:12
  },
  modalBody:{
    fontSize:16,
    color:theme.palette.primaryText.light,
    whiteSpace:'pre-wrap',
    marginBottom:24
  },
  buttons:{
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end',
    marginTop:3,
    marginRight:-16
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  selector:{
    ...theme.components.selector
  },
  itemChip:{
    width:'max-content',
    maxWidth:'100%',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    borderRadius:16,
    padding:'4px 0',
    '& span':{
      padding:'0 8px',
      fontSize:13.75
    }
  },
  targetItemChip:{
    background:theme.palette.primary.dark,
    color:theme.palette.background.main
  },
})

const Identifiers = props => {

  const {
    theme,
    classes,
    state,
    // dispatch,
    sessionData,
    aliasFetch
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [saving, setSaving] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAlias, setSelectedAlias] = useState();

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const onClickResult = user => {
    setSearchValue('');
    setSelectedAlias(user);
    setModalOpen(true);
  }

  const constructDisplayedList = () => {
    return state.originalAlias
  }

  const onAdd = () => {
    setSaving(true);
    axiosCerebrum
      .post(`/api/merges`,
      {
        target:sessionData.id,
        object_type:'USER',
        candidates:[selectedAlias.id],
        "action":'ADD_TO_EXISTING'
      })
      .then(response=>{
        sendAlert({message:'ID saved successfully',type:'info'})                                              
        aliasFetch()              
        setModalOpen(false)                 
        setSaving(false)
      })
      .catch(error=>{
        setSaving(false)
        sendAlert({message:'Error occurred saving ID',type:'error'})
      })
  }

  return (
    <div className={classes.block}>
      <div className={classes.body}>
        <Typography className={classes.headerTextOverflow}>LINKED ACCOUNTS</Typography>
        <Typography className={classes.headerSubText}>
          Search for accounts (local accounts) that you use in the Sources connected to K
        </Typography>
        {/* <Select
          className={classes.selector}
          value={state.selectedSource}
          disableUnderline
          onChange={event=>dispatch({type:'set_selected_source',selectedSource:event.target.value})}
        >
          <MenuItem value='all'>All Sources</MenuItem>
          {
            state.sourcesData && state.sourcesData.map(el=>(
              <MenuItem value={el.id}>{el.name}</MenuItem>
            ))
          }
        </Select> */}
        <div style={{width:640,marginTop:16}}>
          <SearchSelector
            searchValue={searchValue}
            setSearchValue={value=>setSearchValue(value)}
            url={`/api/merges/search`}
            isCerebrum={true}
            removeFLModify={false}
            params={{'object_name':searchValue,type:'CANDIDATE',per_page:20,source_id:state.selectedSource==='all'?undefined:state.selectedSource,object_type:'USER'}}
            placeholder={'Search for your accounts that you use across the data ecosystem'}
            renderResults={(items)=>{
              let suggests = items.filter(el=>!constructDisplayedList().find(a=>a.id===el.id));
              if(suggests.length===0){
                return [
                  <div>
                    <ListItem>
                      <div style={{display:'flex',alignItems:'center'}}>
                        <Typography style={{marginLeft:12, color:theme.palette.primaryText.main}}>No suggestions found</Typography>
                      </div>
                    </ListItem>
                  </div>
                ]
              }
              return suggests.map((item,index)=>(
                <UserSuggestionItem item={item} onClick={()=>onClickResult(item)}  itemSource="merge"/>
            ))}}
          />
        </div>
        <div>
          <div style={{display:'flex',marginTop:24}}>
            <Typography color='primary' style={{fontSize:12,flex:'0 1 300px',marginLeft:56,paddingRight:16}}>NAME</Typography>
            <Typography color='primary' style={{fontSize:12,flex:'0 1 200px',paddingRight:16}}>ACCOUNT TYPE</Typography>
            <Typography color='primary' style={{fontSize:12,flex:'0 1 300px',paddingRight:16}}>SOURCE</Typography>
            <Typography color='primary' style={{fontSize:12,flex:'1 1 300px'}}>ALTERNATE SOURCE</Typography>
          </div>
          <div style={{marginTop:10}}>
            {
              constructDisplayedList().length===0 &&
              <Typography style={{color:theme.palette.primaryText.light,marginLeft:58}}>No accounts selected</Typography>
            }
            {
              constructDisplayedList().map(el=>(
                <div style={{width:'100%', display:'flex',paddingLeft:16,height:48,alignItems:'center',overflow:'hidden',borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}}>
                  {getIconComponent({label:'user',size:24,colour:theme.palette.primaryText.light})}
                  <Typography style={{fontSize:16,color:theme.palette.primaryText.main,flex:'0 1 300px',marginLeft:16,paddingRight:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                    <KTooltip title={`${el.user.username}${el.user.username!==el.name?` (${el.name})`:''}`} classes={{tooltip:classes.tooltip}}>
                      <div className={classes.itemChip + ' ' + classes.targetItemChip}>
                        <span>{el.user.username}{el.user.username!==el.name?` (${el.name})`:''}  </span>
                      </div>
                    </KTooltip>
                  </Typography>
                  <Typography style={{fontSize:16,color:theme.palette.primaryText.main,flex:'0 1 200px',paddingRight:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                    {el.user.account_type}  
                  </Typography>
                  <Typography style={{fontSize:16,color:theme.palette.primaryText.main,flex:'0 1 300px',paddingRight:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                    {el.source.name}
                  </Typography>
                  <Typography style={{fontSize:16,color:theme.palette.primaryText.main,flex:'1 1 300px',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                    {el.reference_sources.map(el=>el.reference_source.name).join(', ')}
                  </Typography>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <Modal 
        open={modalOpen}
        onClose={()=>setModalOpen(false)}
        disableBackdropClick={true}
      >
        <div style={{display:'flex',width:'100vw',height:'100vh',alignItems:'center',justifyContent:'center',outline:'none'}}>
          <Paper className={classes.modalPaper}>
            <Typography className={classes.modalTitle}>Are you sure?</Typography>
            <Typography className={classes.modalBody}>
              {
                selectedAlias && `You have chosen to link to ${selectedAlias.user.username}${selectedAlias.user.username!==selectedAlias.name?` (${selectedAlias.name})`:''}\n\nMake sure this ID is correct before proceeding.\n\n`
              }
              <span style={{fontSize:16,color:theme.palette.secondary.main}}>
                Note: Only Admin users can remove incorrectly linked accounts via Platform Settings, Merge page
              </span>
            </Typography>
            <div className={classes.buttons}>
              <Button classes={{disabled:classes.disabledButton}} color='primary' style={{width:96,marginRight:8}} onClick={onAdd}  disabled={saving}>YES</Button>
              <Button classes={{disabled:classes.disabledButton}} color='secondary' style={{width:96}} onClick={()=>setModalOpen(false)} disabled={saving}>CANCEL</Button>
            </div>
          </Paper>
        </div>
      </Modal>

    </div>
  )
}

Identifiers.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
}

export default withTheme()(withStyles(styles)(Identifiers));