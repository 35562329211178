import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, IconButton, ClickAwayListener } from '@material-ui/core';
import PropTypes from 'prop-types';
// import axiosCerebrum from '../../../../axios-cerebrum'
import axiosCerebrum from '../../../../axios-cerebrum';
import { getIconComponent, getLabelPlural, getNameSearchQuery, isTagValid } from '../../../../utilities';
import SearchSelector from '../../SearchSelector/SearchSelector';
import { tileStyles } from './utils/styles';
import KTooltip from '../../KTooltip/KTooltip';
import { measureText } from './utils/utils';
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  suggestionItem:{
    padding:'8px 16px',
    cursor:'pointer',
    fontSize:13.75,
    '&:hover':{
      background:theme.palette.hovered.main
    },
    color:theme.palette.primaryText.main,
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
  iconButton:{
    ...tileStyles.deleteButton
  }
})

function TagManager(props) {

  const {
    classes,
    theme,
    history,
    object,
    onAddTileData,
    tileData,
    alwaysOpenNewTab
  } = props;

  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [adding, setAdding] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  
  const loadTags = () => {
    const load = ({prevData=[], page=1}) => {
      axiosCerebrum 
        .get(
          `/api/${getLabelPlural(object.object.name)}/${object.id}/related`,{
            params:{
              object_name:'TAG',
              relationship:'TAGGED_BY',
              page,
              per_page:100
            }
          }
        )
        .then(response=>{
          let data = [...prevData,...response.data.items]
          if(response.data.page<response.data.pages){
            load({prevData:data,page:page+1})
          }else{
            onAddTileData(
              {
                "disp_body":data.map(t=>({
                  name:t.name,
                  labels:'tag',
                  id:t.id,
                  onClick:()=>{
                    alwaysOpenNewTab?
                    window.open(`/profile/tag/${t.id}`,'_blank'):
                    history.push(`/profile/tag/${t.id}`)
                  }
                })),
                "disp_title":"TAGS",
                "id":"tags",
              }
            )
            setLoading(false)
          }
        })
        .catch(error=>{
          console.log(error)
          setLoading(false)
          onAddTileData(
            {
              "disp_body":prevData.map(t=>({
                name:t.name,
                labels:'tag',
                id:t.id,
                onClick:()=>{
                  alwaysOpenNewTab?
                  window.open(`/profile/tag/${t.id}`,'_blank'):
                  history.push(`/profile/tag/${t.id}`)
                }
              })),
              "disp_title":"TAGS",
              "id":"tags",
            }
          )
        })
    }
    setLoading(true)
    load({})
  }

  useEffect(()=>{
    if(!tileData){
      loadTags()
    }
  // eslint-disable-next-line
  },[])


  const onLinkTag = tag => {
    setSearchValue('')
    axiosCerebrum
      .put(
        `/api/tags/${tag.id}/related?relationship=TAG_OF&object_id=${object.id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":[
              ...(tileData?tileData.disp_body:[]),
              {
                name:response.data.name,
                labels:'tag',
                id:response.data.id,
                onClick:()=>{
                  alwaysOpenNewTab?
                  window.open(`/profile/tag/${response.data.id}`,'_blank'):
                  history.push(`/profile/tag/${response.data.id}`)
                }
              }
            ],
            "disp_title":"TAGS",
            "id":"tags",
          }
        )
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred linking tag, please try again', type: 'error' })
      })
  }

    
  const onAddTag = name => {
    if(adding)return;
    setSearchValue('')
    setAdding(true)
    axiosCerebrum.post(
      '/api/tags', 
      {
        description: "",
        name: name
      },
    ).then(response=>{
      setAdding(false)
      onLinkTag(response.data)
    }).catch(error=>{
      console.log(error)
      sendAlert({ message: 'Error occurred creating tag, please try again', type: 'error' })
      setAdding(false)
    })
  }

  const onDeleteTag = tag => {
    setDeleting(true)
    let showLoadingTimeout = setTimeout(()=>{
      setLoading(true)
    },800)
    axiosCerebrum
      .delete(
        `/api/tags/${tag.id}/related?relationship=TAG_OF&object_id=${object.id}`
      )
      .then(response=>{
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
        if(!tileData)return;
        onAddTileData(
          {
            "disp_body":tileData.disp_body.filter(d=>d.id!==tag.id),
            "disp_title":"TAGS",
            "id":"tags",
          }
        )
      })
      .catch(error=>{
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
        console.log(error)
        sendAlert({ message: 'Error occurred deleting tag, please try again', type: 'error'})
      })
  }
  
  return (
    <div data-test-id="tag-data-tile" className={classes.root}>
      <div className={classes.editableHeaderContainer}>
        <Typography className={classes.title}>TAGS</Typography>
        <KTooltip title="Add tags to organise your items for compliance, discovery, protection and usage use cases. Tags are separate from collections and terms. ">
          <div style={{marginBottom:-3,marginLeft:6}}>{getIconComponent({label:'info',size:16,colour:theme.palette.primaryText.light})}</div>
        </KTooltip>
      </div>
      <ClickAwayListener 
        onClickAway={(event)=>{
          if(event.target.className &&  event.target.className.includes && event.target.className.includes("tag_suggestion")){
            return;
          }
          if(event.target.id && event.target.id==='search_selector_popper_list'){
            return;
          }
          setSearchValue('')
          setEditing(false)
        }}
      >
        <div className={classes.chipContainer + ' ' + (editing?classes.editingBox:classes.clickableBox)} onClick={()=>setEditing(true)}>
          {
            loading && <CircularProgress color='secondary' size={16}/>
          }
          {
            !loading && tileData && tileData.disp_body.length===0 && 
            <div data-test-classname="tag-data-tile-chip" className={classes.tag} style={{background:theme.palette.chip.main,color:theme.palette.primaryText.main,border:`1px solid ${theme.palette.border.main}`,cursor:'default'}}>
              <span className={classes.tagText} style={{color:theme.palette.primaryText.main}}>None</span>
            </div>
          }
          {
            !loading && tileData && tileData.disp_body.sort((a,b)=>a.name<b.name?-1:1).map(t=>(
              <div data-test-classname="tag-data-tile-chip" className={classes.tag} style={{width:editing||tileData.disp_body.length===1?undefined:measureText(t.name)+20}} onClick={t.onClick}>
                <span className={classes.tagText}>{t.name}</span>
                <IconButton className={classes.iconButton + '  chip-delete-button'} style={{display:editing?'block':undefined}} onClick={event=>{event.stopPropagation();!deleting && onDeleteTag(t)}}>
                  {getIconComponent({label:'clear',size:13.75,colour:'#fff'})}
                </IconButton>
              </div>
            ))
          }
          {
            editing && 
            <div style={{marginTop:8,marginBottom:6,width:'100%'}}>
              <SearchSelector
                url={'/solr/search/select'}
                params={{
                  q:getNameSearchQuery(searchValue),
                  fq:`object_type_srt:TAG`,
                  fl:'*',
                  qf:'name_srt',
                  sort:'name_srt asc',
                }}
                testID="tag-manager-input"
                // endAdornment={(
                //   <IconButton onClick={()=>setEditing(false)} style={{padding:2,marginRight:4}}>
                //     {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
                //   </IconButton>
                // )}
                clearable
                autoFocus
                height={32}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder={`Search for a Tag or add a new Tag`}
                autoSuggestion={true}
                suggestionListWidth={290}
                removeSuggestionHeader
                keepPopperOnClick
                // hideBorder
                renderResults={list => {
                  let suggestion = [];
                  if(!list.find(l=>l.name_txt.toLowerCase()===searchValue.toLowerCase()) && searchValue.trim()!=='' && isTagValid(searchValue)){
                    suggestion.push(
                      <div className={classes.suggestionItem+' tag_suggestion'} style={{borderBottom:list.length===0?'none':undefined}} onClick={()=>{onAddTag(searchValue)}}>
                        Add a new Tag: {searchValue}
                      </div>
                    )
                  }
                  list.filter(el=>!tileData || !tileData.disp_body.find(d=>d.id===el.id)).forEach((l,index)=>{
                    suggestion.push(
                      <div  className={classes.suggestionItem+' tag_suggestion'} style={{borderBottom:list.length===index+1?'none':undefined}} onClick={()=>{onLinkTag(l)}}>
                        {l.name_txt}
                      </div>
                    )
                  })
                  if(suggestion.length===0){
                    return [<Typography className='tag_suggestion' onClick={event=>event.stopPropagation()} style={{fontSize:13.75,marginLeft:16}}>No suggestion found</Typography>]
                  }
                  return suggestion
                }}
              />
            </div>
          }
        </div>
      </ClickAwayListener>
    </div>
  )
}

TagManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(TagManager));