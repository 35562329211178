import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, InputBase, Select, MenuItem, Button, IconButton, CircularProgress, Popper, ClickAwayListener, MenuList, Paper } from '@material-ui/core';
import { getIconComponent, isInViewport } from '../../../utilities';
import UserList from './UserList';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr';
import ClearableSelector from '../../UI/ClearableSelector/ClearableSelector';


const styles = theme => ({
  header:{
    fontSize:20,
    marginBottom:16
  },
  inputBase:{
    ...theme.components.inputBase,
    marginRight:24,
    height:44,
    marginBottom:16,
  },
  selector:{
    ...theme.components.selector,
    width:180,
    display:"block"
  },
  selectorText:{
    color:theme.palette.primaryText.light,
    paddingLeft:`16px !important`
  },
  selectorMenu:{
    ...theme.components.customScroll
  },
  filterButton:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    textTransform:'none',
    padding:0,
    marginTop:2,
    marginLeft:16,
    '& span':{
      fontSize:12
    }
  },
  listActionSectionTitle:{
    fontSize:12,
    marginTop:16,
    marginLeft:16,
    letterSpacing:1.5,
  }
})

function List(props) {

  const {
    classes,
    theme,
    state,
    sessionData,
    dispatch,
    history
  } = props;

  const scrollRef = useRef()
  const inputRef = useRef()
  const searchTimeoutRef = useRef()

  const [listActionOpen, setListActionOpen] = React.useState(false)

  const loadUser = ({page=1, search=state.searchValue, searchField=state.searchField, type=state.typeFilter||'all', usageType=state.usageTypeFilter||'all', team=state.teamFilter||'all'}) => {
    dispatch({
      type:'set_user_data',
      userLoading:true,
      userData:page===1?undefined:state.userData
    })

    axiosCerebrum
      .get(
        `/api/users`,{
          params:{
            page,
            per_page:10,
            [`search.${searchField}`]:search,
            reference:type==='all'?undefined:type==='reference'?true:false,
            system_user_flag:usageType==='all'?undefined:usageType==='user'?false:true,
            group_ids:team==='all'?undefined:team
          }
        }
      )
      .then(response=>{
        if(response.data.total===0){
          dispatch({type:'set_user_data',userData:response.data})
          return;
        }
        axiosSolr
          .get(
            `/solr/search/select`,{
              params:{
                q:"*",
                fq:`id:(${response.data.items.map(item=>item.id).join(' OR ')})`,
                rows:10,
              }
            }
          )
          .then(solrResponse=>{
            response.data.items = response.data.items.map(item=>{
              let solrItem = solrResponse.data.response.docs.find(solrItem=>solrItem.id===item.id)||{}
              return {
                ...item,
                ...solrItem
              }
            })
            dispatch({
              type:'set_user_data',
              userData:{
                ...response.data,
                items:page===1?response.data.items:[...state.userData.items,...response.data.items]
              }
            })
          })
          .catch(error=>{
            dispatch({
              type:'set_user_data',
              userError:error
            })
          })
      })
      .catch(error=>{
        dispatch({
          type:'set_user_data',
          userError:error
        })
      })
  }

  useEffect(()=>{
    if(!state.userData && !state.userLoading){
      loadUser({})
    }
    // eslint-disable-next-line
  },[])

  const onChangeSearch = value => {
    dispatch({type:'set_search_value',searchValue:value})
    clearTimeout(searchTimeoutRef.current)
    searchTimeoutRef.current = setTimeout(()=>{
      loadUser({search:value})
    },500)
  }

  const onChangeSearchFiled = value => {
    dispatch({type:'set_search_field',searchField:value})
    loadUser({searchField:value})
    setListActionOpen(false)
  }

  const onChangeType = value => {
    dispatch({type:'set_type_filter',typeFilter:value})
    loadUser({type:value})
  }

  const onChangeUsageType = value => {
    dispatch({type:'set_usage_type_filter',usageTypeFilter:value})
    loadUser({usageType:value})
  }

  const loadTeam = ({page=1}) => {
    dispatch({
      type:'set_available_teams',
      availableTeamsLoading:true,
      availableTeams:page===1?undefined:state.availableTeams
    })
    axiosCerebrum
      .get(
        `/api/groups`,{
          params:{
            page,
            per_page:10
          }
        }
      )
      .then(response=>{
        dispatch({
          type:'set_available_teams',
          availableTeams:{
            ...response.data,
            items:page===1?response.data.items:[...state.availableTeams.items,...response.data.items]
          }
        })
      })
      .catch(error=>{
        dispatch({
          type:'set_available_teams',
          availableTeamsError:error
        })
      })
  }

  const onChangeTeam = value => {
    dispatch({type:'set_team_filter',teamFilter:value})
    loadUser({team:value})
  }

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && !state.userLoading && state.userData?.pages>state.userData?.page
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadUser({page:state.userData.page+1})
    }
    // eslint-disable-next-line
  },[state.userData, state.userDataLoading])

  window.onscroll = () => {
    if(shouldLoadMore()){
      loadUser({page:state.userData.page+1})
    }
  }

  const userTypeOptions = [
    {value:'all',name:'All'},
    {value:'onboarded',name:'Onboarded'},
    {value:'reference',name:'Reference'}
  ]

  const usageTypeOptions = [
    {value:'all',name:'All'},
    {value:'user',name:'User'},
    {value:'system',name:'System'}
  ]

  const searchFieldOptions = [
    {value:'username',name:'User name'},
    {value:'first_name',name:'First name'},
    {value:'last_name',name:'Last name'},
    {value:'email',name:'Email'}
  ]

  return (
    <div className={classes.root}>
      <div style={{position:'sticky',background:theme.palette.background.main,top:120,zIndex:999}}>
        <Typography className={classes.header}>
          {state.userData?state.userData.total:''} USER(S)
        </Typography>
        <div style={{display:'flex',flexWrap:'wrap',alignItems:'flex-start',marginBottom:16}}>
          <InputBase
            value={state.searchValue}
            onChange={event=>{
              onChangeSearch(event.target.value)
            }}
            placeholder={`Search by ${searchFieldOptions.find(el=>el.value===state.searchField)?.name?.toLowerCase()}`}
            className={classes.inputBase}
            inputProps={{
              ref:inputRef,
            }}
            endAdornment={
              <div style={{display:'flex'}}>
                <IconButton 
                  disabled={state.searchValue===''} 
                  onClick={()=>onChangeSearch('')}
                  style={{width:32,height:32}}
                >
                  {getIconComponent({label:state.searchValue===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
                <IconButton 
                  onClick={()=>{
                    setListActionOpen({
                      anchor:inputRef.current,
                    })
                  }}
                  style={{width:32,height:32,marginRight:6}}
                >
                  {getIconComponent({label:'triangle_down',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              </div>
            }
          />
          <Popper open={listActionOpen} anchorEl={listActionOpen?.anchor} placement='bottom-start'>
            <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
              <Paper style={{marginTop:8,width:255,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                <Typography className={classes.listActionSectionTitle}>SEARCH BY</Typography>
                <MenuList className={classes.listContainer}>
                  {
                    searchFieldOptions.map(el=>(
                      <MenuItem key={el.value} onClick={()=>{onChangeSearchFiled(el.value)}} className={classes.menuItem} >
                        <Typography style={{ fontSize:16, color:theme.palette.primaryText.main }}>
                          {el.name}
                        </Typography>
                      </MenuItem>
                    ))
                  }
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Popper>
          <div style={{marginRight:24}}>
            <ClearableSelector
              value={state.typeFilter || 'all'}
              onChangeValue={event=>{
                onChangeType(event.target.value)
              }}
              renderValue={value=>{
                return `User Type: ${userTypeOptions.find(el=>el.value===value)?.name}`
              }}
              testID={`type-filter`}
              options={userTypeOptions}
              onReset={()=>{onChangeType('all')}}
            />
          </div>
          <div style={{marginRight:24}}>
            <ClearableSelector
              value={state.usageTypeFilter || 'all'}
              onChangeValue={event=>{
                onChangeUsageType(event.target.value)
              }}
              testID={`usage-type-filter`}
              renderValue={value=>{
                return `User Usage Type: ${usageTypeOptions.find(el=>el.value===value)?.name}`
              }}
              width={220}
              options={usageTypeOptions}
              onReset={()=>{onChangeUsageType('all')}}
            />
          </div>
          <div>
            <Select
              value={state.teamFilter||'all'}
              onChange={event=>{
                onChangeTeam(event.target.value)
              }}
              inputProps={{
                'data-test-id':'team-filter',
                className:classes.selectorText
              }}
              renderValue={value=>{
                return `Team: ${state.availableTeams?.items.find(el=>el.id===value)?.name || 'All'}`
              }}
              onClick={()=>{
                if(!state.availableTeams && !state.availableTeamsLoading){
                  loadTeam({})
                }
              }}
              disableUnderline
              className={classes.selector}
              MenuProps={{
                className:classes.selectorMenu,
                MenuListProps:{
                  style:{width:'max-content',maxWidth:'80vw',minWidth:300}
                },
                PaperProps:{
                  style:{maxHeight:300,overflow:'auto'},
                  onScroll:e=>{
                    if(e.target.scrollTop<e.target.scrollHeight-e.target.clientHeight-10)return;
                    if(!state.availableTeamsLoading && state.availableTeams?.pages>state.availableTeams?.page){
                      loadTeam({page:state.availableTeams?.page+1})
                    }
                  },
                },
              }}
            >
              <MenuItem value='all'>All</MenuItem>
              {
                state.availableTeams?.items?.map(team=>(
                  <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>
                ))
              }
              {
                state.availableTeamsLoading && 
                <MenuItem disabled>
                  <CircularProgress color='secondary' style={{width:20,height:20}}/>
                </MenuItem>
              }
              {
                state.availableTeamsError && 
                <MenuItem disabled>Error loading teams</MenuItem>
              }
            </Select>
            <Button onClick={()=>onChangeTeam('all')} data-test-id={`team-clear-button`} className={classes.filterButton}>
              Clear filter
            </Button>
          </div>
        </div>
      </div>
      {
        state.userData?.total>0 && 
        <UserList
          dispatch={dispatch}
          state={state}
          sessionData={sessionData}
          history={history}
        />
      }
      {
        state.userData?.total===0 && 
        <Typography>No users found</Typography>
      }
      {
        state.userError && 
        <Typography>Error occurred loading users</Typography>
      }
      <div ref={scrollRef} style={{display:'flex',justifyContent:'center',marginTop:8}}>
        {
          state.userLoading && 
          <CircularProgress color='secondary'/>
        }
      </div>
    </div>
  )
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(List));