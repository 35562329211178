import React,{useEffect, useRef, useState} from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Modal, Button, Paper} from '@material-ui/core';
import axiosCerebrum from '../../../../axios-cerebrum'
import axiosSolr from '../../../../axios-solr'
import { isInViewport, getDispFields, onClickResultItem, getIconComponent, getLabelPlural, mapObjectName } from '../../../../utilities'
// import ExpandableChip from './ExpandableChip'
import BaiscChip from './BasicChip'
import ButtonExpandableChip from './ButtonExpandableChip'
import PacMan from 'react-pacman'
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  normalText:{
    color:theme.palette.primaryText.main
  },
  columnHeader:{
    color:theme.palette.primaryText.light,
    fontSize:13.75
  },
  pacManContainer:{
    // transform: 'scale(1.5)',
    zoom:1.5,
    height:436,
    overflow:'hidden',
    '& .pacman-scores':{
      color:theme.palette.primaryText.main,
      '& *':{
        fontSize:10
      }
    },
    '& .pacman-board rect':{
      fill:theme.palette.background.main
    },
    '& .pacman-board path':{
      stroke:theme.palette.primary.main
    },
    '& .pacman-player path':{
      fill:theme.palette.primary.main
    },
    '& .food':{
      background:theme.palette.secondary.main
    }
  }
})  

function List(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    loadSurveyCount,
    loadStats
  } = props;

  const scrollRef = useRef()
  const [pacManModalOpen, setPacManModalOpen] = useState(false)
  const [pacManFlag, setPacManFlag] = useState(0)
  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`add-knowledge`,
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  let data = state.surveyList[state.selectedSurveyCategory];
  let loading = state.surveyList[`${state.selectedSurveyCategory}_loading`]
  let error = state.surveyList[`${state.selectedSurveyCategory}_error`]
  let itemTypeSort = state.surveyList[`${state.selectedSurveyCategory}_item_type_sort`] || 'OBJECT_TYPE_ASC'

  const selectedId = state.hoveredQuestion[state.selectedSurveyCategory];
  const setSelectedId = id => dispatch({type:'set_hovered_question',hoveredQuestion:{...state.hoveredQuestion,[state.selectedSurveyCategory]:id}})
  const openedId = state.openedQuestion[state.selectedSurveyCategory];
  const setOpenedId = id => dispatch({type:'set_opened_question',openedQuestion:{...state.openedQuestion,[state.selectedSurveyCategory]:id}})

  const loadSurveyList = ({page, category, sort='OBJECT_TYPE_ASC'}) => {
    dispatch({
      type:'set_survey_list',
      surveyList:{
        ...state.surveyList,
        [category]:page===1?undefined:state.surveyList[category],
        [`${category}_loading`]:true,
        [`${category}_error`]:false,
        [`${category}_item_type_sort`]:sort
      }
    })
    axiosCerebrum 
      .get(
        `/api/me/surveys`,
        {params:{
          page,
          category,
          per_page:20,
          status:'OPEN',
          sort:sort
        }}
      )
      .then(async response=>{
        if(page===1 && response.data.items.length>0){
          setSelectedId(response.data.items[0].id)
        }
        if(response.data.items.length>0){
          let extraData = await axiosSolr.get(
            `/solr/search/select`,
            {params:{
              q:'*',
              fq:`id:(${response.data.items.map(el=>el.target_object.id).join(' OR ')})`,
              fl:'location:location_txt,id,sourceType:source_type_txt,description,object_type_txt,object_subtype_txt',
              rows:20
            }}
          )
          response.data.items.forEach((el,index)=>{
            if(extraData.data.response.docs.find(p=>p.id===el.target_object.id)){
              response.data.items[index].target_object = {
                ...response.data.items[index].target_object,
                ...extraData.data.response.docs.find(p=>p.id===el.target_object.id)
              }
            }
          })
        }
        dispatch({
          type:'set_survey_list',
          surveyList:{
            ...state.surveyList,
            [category]:page===1?response.data:{...response.data,items:[...state.surveyList[category].items,...response.data.items]},
            [`${category}_loading`]:false,
            [`${category}_error`]:false,
            [`${category}_item_type_sort`]:sort
          }
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_survey_list',
          surveyList:{
            ...state.surveyList,
            [category]:undefined,
            [`${category}_loading`]:false,
            [`${category}_error`]:true,
            [`${category}_item_type_sort`]:sort
          }
        })
      })
  }

  const onChangeItemTypeSort = ({category,currentSort}) => {
    let newSort = currentSort==='OBJECT_TYPE_ASC'?'OBJECT_TYPE_DESC':'OBJECT_TYPE_ASC';
    loadSurveyList({page:1,category,sort:newSort})
  }

  const shouldLoadMore = () => {
    if(!state.surveyCount || !state.selectedSurveyCategory)return false;
    if(!state.surveyList[state.selectedSurveyCategory])return false;
    if(state.surveyList[`${state.selectedSurveyCategory}_loading`])return false;
    if(state.surveyList[`${state.selectedSurveyCategory}_error`])return false;
    if(state.surveyList[state.selectedSurveyCategory].page>=state.surveyList[state.selectedSurveyCategory].pages)return false;
    return true;
  }

  useEffect(()=>{
    if(!state.surveyCount || !state.selectedSurveyCategory)return;
    if(!state.surveyList[state.selectedSurveyCategory]){
      if(state.surveyCount[state.selectedSurveyCategory]===0){
        dispatch({type:'set_survey_list',surveyList:{...state.surveyList,[state.selectedSurveyCategory]:{items:[],total:0,page:0}}})
      }else{
        loadSurveyList({page:1,category:state.selectedSurveyCategory,sort:itemTypeSort})
      }
    }
  // eslint-disable-next-line
  },[state.selectedSurveyCategory,state.surveyCount])


  useEffect(()=>{
    if(shouldLoadMore() && isInViewport(scrollRef)){
      loadSurveyList({page:state.surveyList[state.selectedSurveyCategory].page+1,category:state.selectedSurveyCategory, sort:itemTypeSort})
    }
  // eslint-disable-next-line
  },[state.surveyList])

  window.onscroll = () => {
    if(shouldLoadMore() && isInViewport(scrollRef)){
      loadSurveyList({page:state.surveyList[state.selectedSurveyCategory].page+1,category:state.selectedSurveyCategory, sort:itemTypeSort})
    }
  }

  if(!state.surveyCount || !state.selectedSurveyCategory)return <div></div>

  const processQuestionTemplates = (item) => {
    let objectName = item.target_object.name;
    let questionTemplate = item.survey_template.question_templates[0];
    let description = '';
    let title =  questionTemplate.question_template.name.replace('[object]',objectName);
    switch(questionTemplate.question_template.id+''){
      case '1':  // access
        description = `You last used ${objectName} more than 90 days ago.\n\n` 
                      + `Do you still need access to it?`;
        break
      case '2': /// feed back
        description = `Give ${objectName} a rating from your recent use?`;
        break;
      case '3':  ///// pii data
        description = `We need your help checking if ${objectName} had PII data.\n\n`
                      + `Complete the survey to help us crowdsource the answer.`;
        break;
      case '4':  ///// description
        description = `Description`;
        if(!item.target_object.description || item.target_object.description.trim()===''){
          title = title.replace('Review','Add')
        }
        break;
      default:
    }
    let objectLabel = item.target_object.object_type_txt || mapObjectName(item.target_object.object.name);
    if(objectLabel==='REPORT' && item.target_object.object_subtype_txt){
      objectLabel = item.target_object.object_subtype_txt
    }
    if(item.target_object.sourceType){
      objectLabel = item.target_object.sourceType + ' '  + objectLabel;
    }
    return {
      questionTitle:title,
      actionType:questionTemplate.question_template.template.question_action,
      description:description,
      options:questionTemplate.question_template.template.action_data,
      objectSignature: getDispFields(item.target_object,'dispSubtitle'),
      objectName:objectName,
      objectLabel: objectLabel,
      targetObject:item.target_object,
      onItemClick: () => onClickResultItem({item:item.target_object,id:item.target_object.id,label:item.target_object.object.name,newWindow:true})
    }
  }

  const onSkip = item => {
    if(item.id===state.answeringQuestion)return;
    if(state.answeringQuestion!==-1)return;
    dispatch({type:'set_answering_question',answeringQuestion:item.id})
    axiosCerebrum
      .put(
        `/api/surveys/${item.id}`,
        {
          "status": "PASS",
          "answers": []
        }
      )
      .then(()=>{
        loadStats({isReload:true})
        loadSurveyCount({isReload:true})
        loadSurveyList({page:1,category:state.selectedSurveyCategory,sort:itemTypeSort})
        dispatch({type:'set_answering_question',answeringQuestion:-1})
      })
      .catch(error=>{
        console.log(error);
        sendAlert({type:'error',message:'Error occurred passing the survey'})
        dispatch({type:'set_answering_question',answeringQuestion:-1})
      })
  }

  const rolePacManDice = () => {
    if(!localStorage.hasOwnProperty('easter_egg'))return;
    let dice = Math.ceil(Math.random()*6);
    if(dice===6 || localStorage.hasOwnProperty('pacman_cheat')){
      setPacManModalOpen(true)
    }
  }

  const onSubmit = (item, selectedAnswer) =>{
    let payload = {
      "status": "COMPLETE",
      "answers": item.survey_template.question_templates.map((el,index)=>{
        let id = el.question_template.id;
        let response = selectedAnswer[index]
        return {
          "question_template_id": id,
          "response": response
        }
      })
    }
    dispatch({type:'set_answering_question',answeringQuestion:item.id})
    axiosCerebrum.put(
      `/api/surveys/${item.id}`,
      payload
    ).then(response=>{
      sendAlert({type:'info',message:'Answer saved'})
      rolePacManDice()
      loadStats({isReload:true})
      loadSurveyCount({isReload:true})
      loadSurveyList({page:1,category:state.selectedSurveyCategory,sort:itemTypeSort})
      dispatch({type:'set_answering_question',answeringQuestion:-1})
      window.postMessage({surveyAnswered:true})

      if(item.survey_template.question_templates[0].question_template.id+''==='4'){
        let targetObj = item.target_object;
        axiosCerebrum.put(
          `/api/${getLabelPlural(targetObj.object.name)}/${targetObj.id}`,
          {
            'replace_date':targetObj.replace_date||null,
            'description':selectedAnswer[0]
          }
        )
      }
    }).catch(error=>{
      console.log(error);
      sendAlert({type:'error',message:'Error occurred submitting answer'})
      dispatch({type:'set_answering_question',answeringQuestion:-1})
    })
  }

  const getQuestionColumnText = () => {
    if(state.selectedSurveyCategory==='ACCESS')return 'ACCESS REVIEW';
    if(state.selectedSurveyCategory==='FEEDBACK')return 'FEEDBACK REQUEST';
    return 'QUESTION'
  }


  return (
    <div>
      <div style={{display:'flex',paddingBottom:8,position:'sticky',top:218,background:theme.palette.background.main,zIndex:9}}>
        <Typography className={classes.columnHeader} style={{flex:'1 1 500px',paddingLeft:16}}>{getQuestionColumnText()}</Typography>
        <div style={{flex:'2 1 410px',display:'flex',alignItems:'center',paddingLeft:16,cursor:'pointer'}} onClick={()=>onChangeItemTypeSort({category:state.selectedSurveyCategory,currentSort:itemTypeSort})}>
          <Typography className={classes.columnHeader} style={{marginRight:8}}>ITEM</Typography>
          {getIconComponent({label:itemTypeSort==='OBJECT_TYPE_DESC'?'down':'up',size:16,colour:theme.palette.primaryText.light})}
        </div>
        <Typography className={classes.columnHeader} style={{flex:`0 0 ${state.selectedSurveyCategory==='FEEDBACK'?300:204}px`,paddingLeft:16}}>{'ACTION'}</Typography>
      </div>
      {
        data && data.total===0 && 
        <Typography className={classes.normalText} style={{marginTop:16}}>No surveys found.</Typography>
      }
      {
        data && data.total!==0 && 
        data.items.map(el=>{
          if(processQuestionTemplates(el).actionType==='text_box'){
            return (
              <ButtonExpandableChip
                key={el.id}
                processedSurvey={processQuestionTemplates(el)}
                rawSurveyObject={el}
                onSubmit={onSubmit}
                onSkip={onSkip}
                answeringQuestion={state.answeringQuestion}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                openedId={openedId}
                setOpenedId={setOpenedId}
                buttonWidth={state.selectedSurveyCategory==='FEEDBACK'?300:204}
              />
            )
          }
          return (
            <BaiscChip
              key={el.id}
              processedSurvey={processQuestionTemplates(el)}
              rawSurveyObject={el}
              onSubmit={onSubmit}
              onSkip={onSkip}
              answeringQuestion={state.answeringQuestion}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              buttonWidth={state.selectedSurveyCategory==='FEEDBACK'?300:204}
            />
          )
        }
          
        )
      }
      
      {
        error && <Typography className={classes.normalText}>Error occurred loading surveys.</Typography>
      }

      <div ref={scrollRef} style={{marginBottom:8}}>
        {
          loading && <div style={{marginTop:12,textAlign:'center'}}><CircularProgress color='secondary' /></div>
        }
      </div>

      {
        pacManModalOpen && 
        <Modal 
          open={pacManModalOpen} 
          onClose={()=>setPacManModalOpen(false)}
          disableBackdropClick={true}
        > 
          <div style={{height:'100vh',width:'100vw',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Paper style={{margin:'auto',width:'max-content',maxWidth:'90%',maxHeight:'90%',overflow:'auto',padding:20,border:`1px solid ${theme.palette.border.main}`}}>
              <div style={{display:'flex',justifyContent:'space-between',marginBottom:16}}>
                <Typography style={{fontSize:20}} color='primary'>
                  You've found the Easter Egg!
                </Typography>
              </div>
              <div className={classes.pacManContainer}>
                <PacMan key={pacManFlag} />
              </div>
              <div style={{display:'flex',justifyContent:'flex-end',marginBottom:-8}}>
                <Button 
                  color='primary' 
                  style={{marginRight:8}} 
                  onClick={()=>{
                    setPacManFlag(pacManFlag+1);
                    try{
                      document.getElementsByClassName('pacman-board')[0].focus()
                    }catch(error){}
                  }}
                >
                  RETRY
                </Button>
                <Button color='secondary' onClick={()=>setPacManModalOpen(false)}>
                  CLOSE
                </Button>
              </div>
            </Paper>

          </div>
        </Modal>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(List));