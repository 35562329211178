import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Select, MenuItem } from '@material-ui/core';
import { generateChartGroup, generateDataTiles, generateRecommendationList, generateSummaryChart } from './componentGenerator'
import { loadGraphData, loadTileData, loadUsageByObject} from './dataLoader'
import { useRef } from 'react';
import { loadDomainList } from '../loadUtils';
import { mapObjectName, toTitleCase } from '../../../utilities';
import UsageSelector from '../../Grid/Governance/UsageSelector';
import { allUsage } from '../../Grid/Governance/loadUtils';

const styles = theme => ({
  chartContainer:{
    width:'50%',
    flexGrow:1,
    boxSizing:'border-box',
    padding:16,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:4,
    marginBottom:24
  },
  chartTitle:{
    fontSize:16,
    color:theme.palette.header.main,
  },
  chartSubtitle:{
    fontSize:12,
    color:theme.palette.primaryText.light
  },
  headerSelector:{
    ...theme.components.titleSelector,
    marginRight:12
  },
  selector:{
    ...theme.components.selector,
    width:160,
    height:48
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  subTitle:{
    color:theme.palette.primaryText.light,
    fontSize:13.75
  },
  menuItem:{
    fontSize:13.75,
    paddingLeft:0,
    color:theme.palette.primaryText.main,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  basicMenuItem:{
    color:theme.palette.primaryText.main,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  dropdownCaption:{
    color:theme.palette.primary.main,
    fontSize:12,
    letterSpacing:2,
    marginLeft:16,
    marginTop:16,
    marginBottom:8
  },
  tooltip:{
    fontSize:13.75,
    whiteSpace:'pre-wrap'
  },
  selectorHeader:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.overline.main,
    marginBottom:8
  },
  suggestionChip:{
    ...theme.components.suggestionChip
  },
  suggestionHeader:{
    fontSize:12,
    letterSpacing:2,
    marginTop:16,
    marginBottom:8,
    color:theme.palette.primaryText.light
  },
  header:{
    fontSize:20,
    color:theme.palette.primaryText.main,
  },
  subHeader:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:8
  },
  recommendationContainer:{
    height:48,
    display:'flex',
    alignItems:'center',
    padding:'0px 16px',
    overflow:'hidden',
    maxWidth:800,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:"pointer",
    '&:hover':{
      backgroundColor:theme.palette.hovered.main
    }
  },
  recommendationTitle:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    flexGrow:1,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginRight:16
  },
  recommendationCount:{
    height:32,
    borderRadius:16,
    fontSize:13,
    backgroundColor:theme.palette.primary.dark,
    color:theme.palette.background.main,
    display:'flex',
    alignItems:'center',
    padding:'0px 8px',
  },
})

const Data = props => {

  const {
    classes,
    // theme,
    sessionData,
    state,
    dispatch,
    isDataGovUser,
    variant,
  } = props;

  const [domainList, setDomainList] = useState(state.domainStates?.domainList)
  const [usage, setUsage] = useState(state.domainStates?.usage || allUsage)
  const [selectedDomain, setSelectedDomain] = useState(state.domainStates?.selectedDomain)
  const [tileData, setTileData] = useState(state.domainStates?.tileData)
  const [selectedTile, setSelectedTile] = useState(state.domainStates?.selectedTile)
  const viewedUser = state.viewedUser;
  const [detailData, setDetailData] = useState(state.domainStates?.detailData || {})

  const apiSeq = useRef(1)

  useEffect(()=>{
    dispatch({
      type:'set_domain_states',
      domainStates:{
        domainList,
        selectedDomain,
        tileData,
        selectedTile,
        detailData,
        usage,
      }
    })
  // eslint-disable-next-line
  },[domainList, selectedDomain, tileData, selectedTile, detailData, usage])

  useEffect(()=>{
    if(!domainList){
      setDomainList({loading:true})
      loadDomainList({ variant, userName:viewedUser?.name || sessionData.name, isDataGovUser})
        .then(d=>{
          let domains = d.data.response.docs
          setSelectedDomain(domains[0]?.name_txt)
          setDomainList({data:domains})
        })
        .catch(error=>{
          console.log(error)
          setDomainList({error:true})
        })
    }
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(!selectedDomain)return;
    loadUsageByObject(selectedDomain, data=>dispatch({type:'set_usage_count_by_object', usageCountByObject:data}))
  // eslint-disable-next-line
  },[selectedDomain, state.viewedUser])

  useEffect(()=>{
    if(!selectedDomain)return;
    if(tileData)return;
    apiSeq.current+=1
    setUsage(allUsage)
    loadTileData({ domain:selectedDomain, usage:allUsage, data:tileData, setData:setTileData, setSelectedTile,  apiSeq:apiSeq.current, apiSeqRef:apiSeq})
  // eslint-disable-next-line
  },[selectedDomain])

  useEffect(()=>{
    if(!selectedTile)return;
    if(detailData[selectedTile+'_graphData'] || detailData[selectedTile+'_graphDataLoading'])return;
    apiSeq.current+=1;
    loadGraphData({selectedTile, tileData, setTileData, state, domain:selectedDomain, data:detailData, usage:usage, setData:setDetailData,  apiSeq:apiSeq.current, apiSeqRef:apiSeq})
  // eslint-disable-next-line
  },[selectedTile])
  
  
  return (
    <div className={classes.root}>
      <div style={{marginBottom:24, display:'flex',alignItems:'center'}}>
        {
          domainList && domainList.data && domainList.data.length>0 &&
          <Select
            className={classes.headerSelector}
            value={selectedDomain}
            disableUnderline
            onChange={event=>{
              setSelectedDomain(event.target.value)
              apiSeq.current+=1
              setSelectedTile()
              setUsage(allUsage)
              setDetailData({})
              loadTileData({domain:event.target.value,usage:allUsage, data:tileData, setData:setTileData, setSelectedTile,  apiSeq:apiSeq.current, apiSeqRef:apiSeq})
            }}
          >
            {
              domainList.data.map(d=>(
                <MenuItem value={d.name_txt}>{d.name_txt.toUpperCase()}</MenuItem>
              ))
            }
          </Select>
        }
        <Typography className={classes.header}>DOMAIN DASHBOARD</Typography>
      </div>
      {
        domainList?.loading && <CircularProgress color='secondary'/>
      }
      {
        domainList?.error && <Typography>Failed to load domains</Typography>
      }
      {
        domainList?.data?.length===0 && <Typography>No domains found</Typography>
      }
      <div style={{display:'flex',alignItems:'center'}}>
        <UsageSelector
          selectedUsages={usage}
          removeCount={!state.usageCountByObject}
          labelText='FILTER BY'
          onChangeUsage={usages=>{
            apiSeq.current+=1
            setUsage(usages)
            setDetailData({})
            loadTileData({ domain:selectedDomain, usage:usages, data:tileData, setData:setTileData, setSelectedTile,  apiSeq:apiSeq.current, apiSeqRef:apiSeq})
            // loadGraphData({selectedTile, tileData, setTileData, state,domain:selectedDomain, data:detailData, usage:usages, setData:setDetailData,  apiSeq:apiSeq.current, apiSeqRef:apiSeq})
          }}
          availableUsages={allUsage.map(el=>({val:el, count:state.usageCountByObject?.[el]?.count || 0 })).filter(el=>el.count!==0 || !state.usageCountByObject)}
        />
      </div>
      {
        usage.length===0 &&
        <Typography>Select at least one usage type to view insights</Typography>
      }
      { 
        tileData &&
        generateDataTiles({classes, data:tileData, setData:setTileData, selectedTile, setSelectedTile})
      }
      {
        selectedTile && usage.length>0 && 
        <>
          <div id="summary-section">
            <Typography className={classes.header} >SUMMARY</Typography>
            <Typography className={classes.subHeader}>Overall progress of governance actions across {toTitleCase(mapObjectName(selectedTile).replace(/_/g,' '))}s</Typography>
            {generateSummaryChart({classes,data:detailData, selectedTile})}
          </div>
          <div  id="insights-section">
            <Typography className={classes.header}>INSIGHTS</Typography>
            <Typography className={classes.subHeader}>Select a metric to drill through to the details in Search</Typography>
            {generateChartGroup({classes,data:detailData, selectedTile})}
          </div>
          <div id="recommendation-section">
            <Typography className={classes.header} >RECOMMENDATIONS</Typography>
            <Typography className={classes.subHeader}>Select a recommendation to see more details</Typography>
            {generateRecommendationList({classes,data: detailData, selectedTile})}
          </div>
        </>
      } 
    </div>
  )
}

export default withTheme()(withStyles(styles)(Data));