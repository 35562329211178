import axiosSolr from "../../../axios-solr"
import { formatNumber, sendMessage, toTitleCase } from "../../../utilities";
import { defaultColumnMap } from "../../BasicSearch/MainSearch/Utils/Utils";

export const allUsage = ['DAILY','WEEKLY','MONTHLY','INFREQUENT','UNUSED','UNKNOWN','NO USAGE']

export const processObjectName = (name) => {
  if(name==='ML_MODEL')return 'ML Model';
  if(name==='DATA_PIPELINE')return 'Pipeline'
  return toTitleCase(name.replace(/_/g,' '))
}

export const generateUsageQuery = (usage) => {
  if(usage.length===7)return ''
  if(usage.length===1 && usage[0]==='NO USAGE')return `AND -usage_srt:[* TO *]`
  if(usage.includes('NO USAGE')){
    return ` AND (usage_srt:(${usage.filter(el=>el!=='NO USAGE').join(' OR ')}) OR (*:* -usage_srt:[* TO *]) )`
  }
  return ` AND usage_srt:(${usage.join(' OR ')})`
}

export const generateUsageUrlQuery = (usage, removeAnd) => {
  if(usage.length===6)return ''
  return `${removeAnd?'':' AND'} usage_srt:${usage.join(',')}`
}

export const loadTilesByObjectType = ({objectTypes, source, usage }) => {
  return axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectTypes.join(' OR ')}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)}` + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "object_types": {
              "type": "terms",
              "field": "object_type_srt",
              "mincount":1,
              "limit":50,
            },
          }
        }
      }
    )
}

export const processTileData = ({facet, objectTypes}) => {
  if(!facet.data.facets.object_types)return []

  let buckets = facet.data.facets.object_types.buckets;
  let tiles = objectTypes.filter(o=>buckets.find(b=>b.val===o)).map(o=>({
    id:o,
    clickable:true,
    title:`${toTitleCase(o.replace(/_/g,' '))}s used`,
    value:formatNumber(buckets.find(b=>b.val===o).count),
  }))
  return tiles
}

export const loadGovProgress = async ({objectType, source, usage, state}) => {
  let chartData;

  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)}` + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "gov_progress": {
              "type": "terms",
              "field": "governance_progress_srt",
              "mincount":1,
              "limit":100,
              "missing":true
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.gov_progress)return;
      let buckets = facet.data.facets.gov_progress.buckets;
      // if(buckets.length===0)return;
      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }else{
        let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
        if(state.objectCountBySource){
          validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
        }
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      let labels = ['count']

      const getColour = value => {
        if(value==='NOT_STARTED')return '#E01F21'
        if(value==='IN_PROGRESS')return '#F76A1C'
        if(value==='COMPLETE')return '#00D46A'
      }

      const getFontColour = value => {
        if(value==='NOT_STARTED')return '#fff'
        if(value==='IN_PROGRESS')return '#fff'
        if(value==='COMPLETE')return '#000'
      }

      const getName = value => {
        if(value==='NOT_STARTED')return 'No governance properties set'
        if(value==='IN_PROGRESS')return 'Some governance properties set'
        if(value==='COMPLETE')return 'All governance properties set'
      }

      let series = [];

      buckets.forEach(b=>{
        series.push({
          name:getName(b.val),
          data:[b.count],
          color:getColour(b.val),
          fontColor:getFontColour(b.val),
          clickFns:[
            ()=>{
              sendMessage({
                modalQuery:"*",
                modalObject:objectType,
                modalFilter:`governance_progress_srt:${b.val} AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
              })
            }
          ]
        })
      })

      if(facet.data.facets.gov_progress.missing?.count){
        series.push({
          name:'Unknown',
          data:[facet.data.facets.gov_progress.missing.count],
          color:'#AEAEAE',
          clickFns:[
            ()=>{
              sendMessage({
                modalQuery:"*",
                modalObject:objectType,
                modalFilter:`governance_progress_srt:NO GOVERNANCE PROGRESS AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
              })
            }
          ]
        })
      }

      chartData = {
        labels, 
        series
      }
    })
  return chartData
}

export const loadClassificationChart = async ({objectType, source, usage, state}) => {
  let chartData;

  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)}` + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "classification": {
              "type": "terms",
              "field": "classification_kc_msrt",
              "mincount":1,
              "limit":100,
              "missing":true
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.classification)return;
      let buckets = facet.data.facets.classification.buckets;
      // if(buckets.length===0)return;
      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }else{
        let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
        if(state.objectCountBySource){
          validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
        }
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      let labels = []
      let data = []
      let clickFns = []
      let toggleLabelArr = [];

      data = buckets.map(b=>b.count);
      labels = buckets.map(b=>toTitleCase(b.val));
      clickFns = buckets.map(b=>()=>{
        sendMessage({
          modalQuery:"*",
          modalObject:objectType,
          modalFilter:`classification_kc_msrt:${b.val} AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
        })
      })    

      if(facet.data.facets.classification.missing?.count){
        labels.push('No classification')
        data.push(facet.data.facets.classification.missing.count)
        toggleLabelArr.push('No classification')
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`classification_kc_msrt:NO CLASSIFICATION AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
          })
        })
      }

      chartData = {
        labels, 
        toggleLabel:{name:'No classification',value:toggleLabelArr},
        series:[{
          name:'count',
          data,
          clickFns
        }]
      }
    })
  return chartData
}

export const loadCategoryChart = async ({objectType, source, usage, state}) => {
  // same as classification chart, but replace classification_kc_msrt with category_kc_msrt
  // generate all code at once
  let chartData;

  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)}` + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "category": {
              "type": "terms",
              "field": "category_kc_msrt",
              "sort": {
                "index": "asc"
              },
              "mincount":1,
              "limit":500,
              "missing":true
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.category)return;
      let buckets = facet.data.facets.category.buckets;
      // if(buckets.length===0)return;
      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }else{
        let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
        if(state.objectCountBySource){
          validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
        }
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      
      let labels = []
      let data = []
      let clickFns = []
      let toggleLabelArr = [];

      data = buckets.map(b=>b.count);
      labels = buckets.map(b=>toTitleCase(b.val));
      clickFns = buckets.map(b=>()=>{
        sendMessage({
          modalQuery:"*",
          modalObject:objectType,
          modalFilter:`category_kc_msrt:${b.val} AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`,
          modalColumn:['category_kc_msrt',...defaultColumnMap[objectType]].join(','),
        })
      })    

      if(facet.data.facets.category.missing?.count){
        labels.push('No category')
        data.push(facet.data.facets.category.missing.count)
        toggleLabelArr.push('No category')
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`category_kc_msrt:NO CATEGORY AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
          })
        })
      }

      chartData = {
        labels, 
        toggleLabel:{name:'No category',value:toggleLabelArr},
        series:[{
          name:'count',
          data,
          clickFns
        }]
      }
    }
  )
  return chartData
}

  

export const loadDomainStatus = async ({objectType, source, usage, state}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)}`  + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "domain": {
              "type": "terms",
              "field": "domain_kc_msrt",
              "mincount":1,
              "limit":200,
              'missing':true
            },
            "has_domain":{
              "type":"query",
              "query":'domain_kc_msrt:[* TO *]'
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.domain)return;
      let buckets = facet.data.facets.domain.buckets;
      if(buckets.length===0 && !facet.data.facets.domain.missing?.count)return;
      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }
      else{
        let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
        if(state.objectCountBySource){
          validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
        }
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      let domainNames = buckets.map(b=>b.val);
      let hasDomainCounts = facet.data.facets?.has_domain?.count;
      let labels = []
      let data = []
      let clickFns = []
      let toggleLabelArr = [];
      if(hasDomainCounts){
        labels.push('Domain assigned')
        data.push(hasDomainCounts)
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`domain_kc_msrt:${domainNames.join(',')} AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
          })
        })
      }
      if(facet.data.facets.domain.missing?.count){
        labels.push('No domain assigned')
        data.push(facet.data.facets.domain.missing.count)
        toggleLabelArr.push('No domain assigned')
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`domain_kc_msrt:NO DOMAIN AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
          })
        })
      }
      chartData = {
        labels,
        toggleLabel:{name:'No domain assigned', value:toggleLabelArr},
        series:[{
          name:'count',
          data,
          clickFns
        }]
      }
    })
  return chartData
}

export const loadUseCaseStatus = async ({objectType, source, usage, state}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)}`  + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "verified": {
              "type": "terms",
              "field": "verified_kc_msrt",
              "mincount":1,
              "limit":200,
              'missing':true
            },
            "verified_count": {
              "type": "query",
              "query": "verified_kc_msrt:[* TO *]",
            },
            "not_verified": {
              "type": "terms",
              "field": "not_verified_kc_msrt",
              "mincount":1,
              "limit":200,
              'missing':true
            },
            "not_verified_count": {
              "type": "query",
              "query": "not_verified_kc_msrt:[* TO *]",
            },
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.verified && !facet.data.facets.not_verified)return;

      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }
      else{
        let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
        if(state.objectCountBySource){
          validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
        }
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }

      let labels = []
      let data = []
      let clickFns = []
      let toggleLabelArr = [];

      let verifiedBuckets = facet.data.facets.verified.buckets;
      if(verifiedBuckets.length!==0 || facet.data.facets.verified.missing?.count){
        let useCaseNames = verifiedBuckets.map(b=>b.val);
        let hasUseCaseCounts = facet.data.facets.verified_count?.count || 0;
        if(hasUseCaseCounts){
          labels.push(`Verified 'use case'`)
          data.push(hasUseCaseCounts)
          clickFns.push(()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`verified_kc_msrt:${useCaseNames.join(',')} AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
            })
          })
        }
        if(facet.data.facets.verified.missing?.count){
          labels.push(`No 'use case' verified`)
          toggleLabelArr.push(`No 'use case' verified`)
          data.push(facet.data.facets.verified.missing.count)
          clickFns.push(()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`verified_kc_msrt:NO VERIFIED AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
            })
          })
        }
      }

      let notVerifiedBuckets = facet.data.facets.not_verified.buckets;
      if(notVerifiedBuckets.length!==0 || facet.data.facets.not_verified.missing?.count){
        let useCaseNames = notVerifiedBuckets.map(b=>b.val);
        let hasUseCaseCounts = facet.data.facets.not_verified_count?.count || 0;
        if(hasUseCaseCounts){
          labels.push(`Verified 'do not use case'`)
          data.push(hasUseCaseCounts)
          clickFns.push(()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`not_verified_kc_msrt:${useCaseNames.join(',')} AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
            })
          })
        }
        if(facet.data.facets.not_verified.missing?.count){
          labels.push(`No 'do not use case' verified`)
          toggleLabelArr.push(`No 'do not use case' verified`)
          data.push(facet.data.facets.not_verified.missing.count)
          clickFns.push(()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`not_verified_kc_msrt:NO NOT VERIFIED AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
            })
          })
        }
      }
      chartData = {
        labels,
        toggleLabel:{name:'No verified use cases',value:toggleLabelArr},
        series:[{
          name:'count',
          data,
          clickFns
        }]
      }
    })
  return chartData
}

export const loadDocumentedStatus = async ({objectType, source, usage, state}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)}`  + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "documented": {
              "type": "query",
              "q":'description:*'
            },
            "not_documented": {
              "type": "query",
              "q":'-description:[* TO *]'
            },
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.documented?.count && !facet.data.facets.not_documented?.count)return;

      let sourceFq = '';
      if(source){
        sourceFq = `source_srt:${source.name}`
      }else{
        let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
        if(state.objectCountBySource){
          validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
        }
        sourceFq = `source_srt:${validSources.join(',')}`
      }

      let labels = []
      let data = []
      let clickFns = []
      let toggleLabelArr = [];
      if(facet.data.facets.documented?.count){
        labels.push('Has documentation')
        data.push(facet.data.facets.documented.count)
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"description:*",
            modalObject:objectType,
            modalFilter:`${sourceFq} AND active_srt:YES${generateUsageUrlQuery(usage)}`
          })
        })
      }
      if(facet.data.facets.not_documented?.count){
        labels.push('No documentation')
        toggleLabelArr.push('No documentation')
        data.push(facet.data.facets.not_documented.count)
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"-description:*",
            modalObject:objectType,
            modalFilter:`${sourceFq} AND active_srt:YES${generateUsageUrlQuery(usage)}`
          })
        })
      }
      chartData = {
        labels,
        toggleLabel:{name:'No documentation',value:toggleLabelArr},
        series:[{
          name:'count',
          data,
          clickFns
        }]
      }
    })
  return chartData
}


export const loadOwnerStatus = async ({objectType, source, usage,state}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)}`  + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "owned": {
              "type": "query",
              "q":'owned_srt:IS_OWNED'
            },
            "not_owned": {
              "type": "query",
              "q":'owned_srt:NOT_OWNED'
            },
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.owned?.count && !facet.data.facets.not_owned?.count)return;

      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }else{
        let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
        if(state.objectCountBySource){
          validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
        }
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      let labels = []
      let data = []
      let clickFns = []
      let toggleLabelArr = [];
      
      if(facet.data.facets.owned?.count){
        labels.push('Data owner assigned')
        data.push(facet.data.facets.owned.count)
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`owned_srt:IS_OWNED AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
          })
        })
      }
      if(facet.data.facets.not_owned?.count){
        labels.push('No data owner assigned')
        toggleLabelArr.push('No data owner assigned')
        data.push(facet.data.facets.not_owned.count)
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`owned_srt:NOT_OWNED AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
          })
        })
      }

      chartData = {
        labels,
        toggleLabel:{name:'No data owner assigned',value:toggleLabelArr},
        series:[{
          name:'count',
          data,
          clickFns
        }]
      }
    })
  return chartData
}

export const loadStewardStatus = async ({objectType, source, usage, state}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 ${generateUsageQuery(usage)}`  + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "stewarded": {
              "type": "query",
              "q":'steward_count:[1 TO *]'
            },
            "not_stewarded": {
              "type": "query",
              "q":'steward_count:0'
            },
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.stewarded?.count && !facet.data.facets.not_stewarded?.count)return;

      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }else{
        let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
        if(state.objectCountBySource){
          validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
        }
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      
      let labels = []
      let data = []
      let clickFns = []
      let toggleLabelArr = [];
      
      if(facet.data.facets.stewarded?.count){
        labels.push('Data steward assigned')
        data.push(facet.data.facets.stewarded.count)
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`steward_count:1,[2 TO *] AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
          })
        })
      }
      if(facet.data.facets.not_stewarded?.count){
        labels.push('No data steward assigned')
        toggleLabelArr.push('No data steward assigned')
        data.push(facet.data.facets.not_stewarded.count)
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalFilter:`steward_count:0 AND active_srt:YES${generateUsageUrlQuery(usage)}${sourceFq}`
          })
        })
      }

      chartData = {
        labels,
        toggleLabel:{name:'No data steward assigned',value:toggleLabelArr},
        series:[{
          name:'count',
          data,
          clickFns
        }]
      }
    }
  )
  return chartData
}

export const getHighestUsage = usage => {
  if(usage.includes('DAILY'))return 'DAILY'
  if(usage.includes('WEEKLY'))return 'WEEKLY'
  if(usage.includes('MONTHLY'))return 'MONTHLY'
  if(usage.includes('INFREQUENT'))return 'INFREQUENT'
  if(usage.includes('UNUSED'))return 'UNUSED'
  if(usage.includes('UNKNOWN'))return 'UNKNOWN'
  if(usage.includes('NO USAGE'))return 'NO USAGE'
  return 'NO USAGE'
}

const getUsageText = usage => {
  if(usage==='UNUSED')return 'that are unused'
  if(usage==='UNKNOWN')return 'that have unknown usage'
  if(usage==='NO USAGE')return 'that have no usage data'
  return `used ${usage.toLowerCase()}`
}

export const loadRecommendations = async ({objectType, usage ,source, state}) => {
  let recommendations = [undefined,undefined,undefined,undefined,undefined,undefined];
  let promises = [];

  let heighestUsage = getHighestUsage(usage)
  if(heighestUsage==='UNUSED' || heighestUsage==='NO USAGE')return [];

  let sourceFq = '';
  if(source){
    sourceFq = ` AND source_srt:${source.name}`
  }else{
    let validSources = state.dbList.filter(d=>d.id!==998).map(el=>el.name)
    if(state.objectCountBySource){
      validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
    }
    sourceFq = ` AND source_srt:${validSources.join(',')}`
  }

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND governance_progress_srt:NOT_STARTED AND -source_id_srt:998 ${generateUsageQuery(usage)}` + (source?` AND source_id_srt:${source.id}`:''),
            rows:0,
            'json.facet':{
              'usage':{
                type:'terms',
                field:'usage_srt',
                mincount:1,
                missing:true,
                limit:5
              }
            }
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        if(!response.data.facets.usage)return;

        let usages = response.data.facets.usage.buckets?.map(el=>el.val) || []
        if(response.data.facets.usage.missing?.count)usages.push('NO USAGE')
        let heighestUsage = getHighestUsage(usages)
        let count;
        if(heighestUsage==='NO USAGE')count = (response.data.facets.usage.missing?.count || 0) + (response.data.facets.usage.buckets?.find(e=>e.val==='UNKNOWN')?.count || 0)
        else{count = response.data.facets.usage.buckets?.find(e=>e.val===heighestUsage)?.count||0}
        recommendations[0] = {
          title:`Review ${processObjectName(objectType)}s ${getUsageText(heighestUsage)} with no governance properties completed`,
          countTxt:formatNumber(count) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`${generateUsageUrlQuery([heighestUsage],true)} AND active_srt:YES AND governance_progress_srt:NOT_STARTED${sourceFq}`
            })
          }
        }
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND governance_progress_srt:IN_PROGRESS AND -source_id_srt:998 ${generateUsageQuery(usage)}` + (source?` AND source_id_srt:${source.id}`:''),
            rows:0,
            'json.facet':{
              'usage':{
                type:'terms',
                field:'usage_srt',
                mincount:1,
                missing:true,
                limit:5
              }
            }
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        if(!response.data.facets.usage)return;

        let usages = response.data.facets.usage.buckets?.map(el=>el.val) || []
        if(response.data.facets.usage.missing?.count)usages.push('NO USAGE')
        let heighestUsage = getHighestUsage(usages)
        let count;
        if(heighestUsage==='NO USAGE')count = (response.data.facets.usage.missing?.count || 0) + (response.data.facets.usage.buckets?.find(e=>e.val==='UNKNOWN')?.count || 0)
        else{count = response.data.facets.usage.buckets?.find(e=>e.val===heighestUsage)?.count||0}
        
        recommendations[1] = {
          title:`Review ${processObjectName(objectType)}s ${getUsageText(heighestUsage)} with some governance properties set`,
          countTxt:formatNumber(count) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`${generateUsageUrlQuery([heighestUsage],true)} AND active_srt:YES AND governance_progress_srt:IN_PROGRESS${sourceFq}`
            })
          }
        }
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND owned_srt:NOT_OWNED AND -source_id_srt:998 ${generateUsageQuery(usage)}` + (source?` AND source_id_srt:${source.id}`:''),
            rows:0,
            'json.facet':{
              'usage':{
                type:'terms',
                field:'usage_srt',
                mincount:1,
                missing:true,
                limit:5
              }
            }
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        if(!response.data.facets.usage)return;

        let usages = response.data.facets.usage.buckets?.map(el=>el.val) || []
        if(response.data.facets.usage.missing?.count)usages.push('NO USAGE')
        let heighestUsage = getHighestUsage(usages)
        let count;
        if(heighestUsage==='NO USAGE')count = (response.data.facets.usage.missing?.count || 0) + (response.data.facets.usage.buckets?.find(e=>e.val==='UNKNOWN')?.count || 0)
        else{count = response.data.facets.usage.buckets?.find(e=>e.val===heighestUsage)?.count||0}
        
        recommendations[2] = {
          title:`Assign Owners to ${processObjectName(objectType)}s ${getUsageText(heighestUsage)}`,
          countTxt:formatNumber(count) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`${generateUsageUrlQuery([heighestUsage],true)} AND active_srt:YES AND owned_srt:NOT_OWNED${sourceFq}`
            })
          }
        }
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND steward_count:0 AND -source_id_srt:998 ${generateUsageQuery(usage)}` + (source?` AND source_id_srt:${source.id}`:''),
            rows:0,
            'json.facet':{
              'usage':{
                type:'terms',
                field:'usage_srt',
                mincount:1,
                missing:true,
                limit:5
              }
            }
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        if(!response.data.facets.usage)return;

        let usages = response.data.facets.usage.buckets?.map(el=>el.val) || []
        if(response.data.facets.usage.missing?.count)usages.push('NO USAGE')
        let heighestUsage = getHighestUsage(usages)
        let count;
        if(heighestUsage==='NO USAGE')count = (response.data.facets.usage.missing?.count || 0) + (response.data.facets.usage.buckets?.find(e=>e.val==='UNKNOWN')?.count || 0)
        else{count = response.data.facets.usage.buckets?.find(e=>e.val===heighestUsage)?.count||0}
        
        recommendations[3] = {
          title:`Assign Stewards to ${processObjectName(objectType)}s${getUsageText(heighestUsage)}`,
          countTxt:formatNumber(count) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalFilter:`${generateUsageUrlQuery([heighestUsage],true)} AND active_srt:YES AND steward_count:0${sourceFq}`
            })
          }
        }
      })
  )


  if(['DATABASE','SCHEMA','TABLE','COLUMN'].includes(objectType)){

    promises.push(
      axiosSolr
        .get(
          '/solr/search/select',{
            params: {
              q: '*:*',
              fq:`object_type_srt:(${objectType}) AND -classification_kc_msrt:[* TO *] AND pii_data_det_kc_msrt:* AND -source_id_srt:998 ${generateUsageQuery(usage)}` + (source?` AND source_id_srt:${source.id}`:''),
              rows:0,
              'json.facet':{
                'usage':{
                  type:'terms',
                  field:'usage_srt',
                  mincount:1,
                  missing:true,
                  limit:5
                }
              }
            }
          }
        )
        .then(response=>{
          if(response.data.response.numFound===0)return;
          if(!response.data.facets.usage)return;
  
          let usages = response.data.facets.usage.buckets?.map(el=>el.val) || []
          if(response.data.facets.usage.missing?.count)usages.push('NO USAGE')
          let heighestUsage = getHighestUsage(usages)
          let count;
          if(heighestUsage==='NO USAGE')count = (response.data.facets.usage.missing?.count || 0) + (response.data.facets.usage.buckets?.find(e=>e.val==='UNKNOWN')?.count || 0)
          else{count = response.data.facets.usage.buckets?.find(e=>e.val===heighestUsage)?.count||0}
          
          recommendations[4] = {
            title:`Review unclassified ${processObjectName(objectType)}s ${getUsageText(heighestUsage)} with PII detected through scanning  `,
            countTxt:formatNumber(count) + ' ' + processObjectName(objectType) + 's',
            onClick:()=>{
              sendMessage({
                modalQuery:"pii_data_det_kc_msrt:*",
                modalObject:objectType,
                modalFilter:`${generateUsageUrlQuery([heighestUsage],true)} ADN classification_kc_msrt:NO CLASSIFICATION AND active_srt:YES${sourceFq}`
              })
            }
          }
        })
    )

    promises.push(
      axiosSolr
        .get(
          '/solr/search/select',{
            params: {
              q: '*:*',
              fq:`object_type_srt:(${objectType}) AND -classification_kc_msrt:[* TO *] AND pii_meta_linked_kc_msrt:* AND -source_id_srt:998 ${generateUsageQuery(usage)}` + (source?` AND source_id_srt:${source.id}`:''),
              rows:0,
              'json.facet':{
                'usage':{
                  type:'terms',
                  field:'usage_srt',
                  mincount:1,
                  missing:true,
                  limit:5
                }
              }
            }
          }
        )
        .then(response=>{
          if(response.data.response.numFound===0)return;
          if(!response.data.facets.usage)return;
  
          let usages = response.data.facets.usage.buckets?.map(el=>el.val) || []
          if(response.data.facets.usage.missing?.count)usages.push('NO USAGE')
          let heighestUsage = getHighestUsage(usages)
          let count;
          if(heighestUsage==='NO USAGE')count = (response.data.facets.usage.missing?.count || 0) + (response.data.facets.usage.buckets?.find(e=>e.val==='UNKNOWN')?.count || 0)
          else{count = response.data.facets.usage.buckets?.find(e=>e.val===heighestUsage)?.count||0}
          
          recommendations[5] = {
            title:`Review unclassified ${processObjectName(objectType)}s ${getUsageText(heighestUsage)} that have suspected PII based on metadata`,
            countTxt:formatNumber(count) + ' ' + processObjectName(objectType) + 's',
            onClick:()=>{
              sendMessage({
                modalQuery:"pii_meta_linked_kc_msrt:*",
                modalObject:objectType,
                modalFilter:`${generateUsageUrlQuery([heighestUsage],true)} AND classification_kc_msrt:NO CLASSIFICATION AND active_srt:YES${sourceFq}`
              })
            }
          }
        })
    )
  }

  await Promise.all(promises)
  return recommendations.filter(r=>r)
}

export const sortByTotalUsage = (metricA, metricsB) => {
  const sum = arr => arr.reduce((a,b) => a + b, 0)
  return  sum(metricsB.dataset.y)-sum(metricA.dataset.y)
}