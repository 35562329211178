import React, { useState} from 'react';
import { withTheme,withStyles, Typography,Button,Paper, IconButton, MenuItem, Popover} from '@material-ui/core';
import { getIconComponent,getInitials } from '../../../utilities';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../../UI/ContextMenu/ContextMenu'
import PropTypes from 'prop-types';

const styles = theme => ({
  root:{
    display:'flex',
    flexDirection:'column',
    padding:16,
    paddingBottom:6,
    margin:2,
    backgroundColor:theme.palette.background.main,
    border:`1px solid ${theme.palette.tileBorder.main}`,
    '& span':{
      fontSize:13.75
    }
  },
  button:{
    width:80,
    height:36,
    marginRight:8
  },
  IconButton:{
    padding:0
  },
  menuItem:{
    height:'auto'
  }
})

const FeedCard = props => {

  const {
    theme,
    id,
    classes,
    userName,
    overline,
    subTitle,
    title,
    time,
    body,
    mainActions,
    subActions
  } = props;

  const [anchor, setAnchor] = useState();
  const [subButtonOpen, setSubButtonOpen] = useState(false)
  const [loadedSubBottom, setLoadedSubBotttom] = useState([]);

  const onClickSubButton = async event => {
    setAnchor(event.currentTarget);
    if(typeof(subActions) === 'object'){
      setSubButtonOpen(true);
      return;
    }
    if(typeof(subActions) === 'function'){
      let data = await subActions();
      setLoadedSubBotttom(data);
      setSubButtonOpen(true);
      return;
    }
  }

  let popoverButtons = typeof(subActions) === 'object'?subActions:loadedSubBottom;
  
  return (
    <Paper className={classes.root} data-test-id={id} elevation={0}>
      <div style={{ width:'100%',minHeight:177,overflow:'hidden'}} >
        <div style={{display:'flex',alignItems:'center',marginBottom:8,flexGrow:0}}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',fontSize:11,color:'#FFF',backgroundColor:theme.palette.avatar.main,width:20,height:20,borderRadius:10}}>
            {getInitials(userName)}
          </div>
          <Typography data-test-classname="feed-card-overline" style={{fontSize:13.75,color:theme.palette.primaryText.light,marginLeft:12,flexGrow:1,wordBreak:'break-word'}}>{overline}</Typography>
          {
            subActions &&
            <IconButton data-test-classname="feed-card-action-button" className={classes.IconButton} onClick={(event)=>{onClickSubButton(event)}}>
              {getIconComponent({label:'more_horiz',size:24,colour:theme.palette.primaryText.light})}
            </IconButton>
          }
        </div>
        <Typography data-test-classname="feed-card-title" style={{color:theme.palette.primaryText.main,fontSize:24,flexGrow:0,wordBreak:'break-word'}}>{title}</Typography>
        {
          subTitle && 
          <Typography data-test-classname="feed-card-subtitle" style={{fontSize:12,color:theme.palette.primary.main,marginBottom:4,whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',direction:'rtl',textAlign:'left'}}>{subTitle}</Typography>
        }
        <Typography data-test-classname="feed-card-time" style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:20,flexGrow:0}}>{time}</Typography>
        <div data-test-classname="feed-card-body" style={{fontSize:13.75,color:theme.palette.primaryText.light,flexGrow:1,lineHeight:1.5,whiteSpace:'pre-wrap',wordBreak:'break-word'}}>{body}</div>
        <div style={{flexGrow:0,paddingTop:32,display:'flex'}}>
          {
            mainActions && mainActions.map(a=>{
              let btn  =  <Button color='secondary' data-test-classname="feed-card-main-action-option" onClick={a.onClick} className={classes.button}>{a.name}</Button>
              if(a.url){
                return (
                  <div>
                    <ContextMenuTrigger id={id+'_'+a.name}>
                      {btn}
                    </ContextMenuTrigger>

                    <ContextMenu id={id+'_'+a.name}>
                      <CustomMenu
                        itemUrl={a.url}
                        actions={[
                          'open_new_tab'
                        ]}
                      />
                    </ContextMenu>
                  </div>
                )
              }
              return btn
            })
          }
        </div>
      </div>
      {
        subActions && 
        <Popover
          id="simple-menu"
          anchorEl={anchor}
          open={subButtonOpen}
          anchorOrigin={{'vertical':'bottom','horizontal':'left'}}
          transformOrigin={{horizontal:'center'}}
          onClose={()=>setSubButtonOpen(false)}
        >
          {
            popoverButtons && popoverButtons.map(el=>(
              <MenuItem data-test-classname="feed-card-sub-action-option" className={classes.menuItem} onClick={()=>{setSubButtonOpen(false);el.onClick()}}>
                <div style={{display:'flex', alignItems: el.description?'flex-start':'center'}}>
                  {getIconComponent({label:el.iconLabel,size:24, colour:theme.palette.primary.main})}
                  <div style={{marginLeft:20}}>
                    <Typography color='primary' style={{fontSize:16}}>{el.name}</Typography> 
                    {el.description && <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light}}>{el.description}</Typography> }
                  </div>
                </div>
              </MenuItem>
            ))
          }
        </Popover>
      }
    </Paper>
  )
}

FeedCard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  overline: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  mainActions: PropTypes.array.isRequired,
  subActions: PropTypes.array.isRequired
}


export default withTheme()(withStyles(styles)(FeedCard));