import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, ClickAwayListener, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../../axios-cerebrum';
import {  getIconComponent,  getLabelPlural, getPartialMatchSearchString} from '../../../../utilities';
import { tileStyles } from './utils/styles';
import SearchSelector from '../../SearchSelector/SearchSelector';
import { checkIsClickOnSuggestion } from './utils/utils';
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
  clickableText:{
    ...tileStyles.clickableText
  },
  iconButton:{
    ...tileStyles.deleteButton
  },
})

function DQDimensionManager(props) {

  const {
    classes,
    theme,
    history,
    object,
    onAddTileData,
    tileData,
    isEditable,
    alwaysOpenNewTab
  } = props;

  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [deleting, setDeleting] = useState(false)
  const popperRef = useRef()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  
  const loadDimension = () => {
    setLoading(true)
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related/collections`,
        {params:{
          category:'PLATFORM',
          parent_name:'Data Quality Dimension',
          relationship:'MEMBER_OF',
          per_page:1
        }}
      )
      .then(response=>{
        onAddTileData({
          "disp_body": response.data.items.map(dimension=>({
            "name": dimension.name,
            onClick:()=>{
              alwaysOpenNewTab?
              window.open(`/profile/collection_instance/${dimension.id}`,'_blank'):
              history.push(`/profile/collection_instance/${dimension.id}`)
            },
            id: dimension.id
          })),
          "disp_title": "DQ DIMENSION",
          "disp_type": "text",
          "id": "dq_dimension",
        })
        setLoading(false)
      })
      .catch(error=>{
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(()=>{
    if(!tileData){
      loadDimension()
    }
  // eslint-disable-next-line
  },[])
    
  const onAdd = async item => {
    setSearchValue('')
    setEditing(false)
    if(item.id===tileData.disp_body[0]?.id)return;
    setLoading(true)
    await axiosCerebrum
      .put(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=MEMBER_OF&object_id=${item.id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":[{
              name:item.name_txt,
              labels:'collection_instance',
              id:item.id,
              onClick:()=>{
                alwaysOpenNewTab?
                window.open(`/profile/collection_instance/${item.id}`,'_blank'):
                history.push(`/profile/collection_instance/${item.id}`)
              }
            }],
            "disp_title":tileData.disp_title,
            "id":tileData.id,
          }
        )
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred updating dq dimension, please try again', type: 'error'})
      })
    setLoading(false)
  }

  const onDelete = () => {
    setDeleting(true)
    let showLoadingTimeout = setTimeout(()=>{
      setLoading(true)
    },800)
    axiosCerebrum
      .delete(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=MEMBER_OF&object_id=${tileData.disp_body[0].id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":[],
            "disp_title":tileData.disp_title,
            "id":tileData.id,
          }
        )
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred deleting dq dimension, please try again', type: 'error'})
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
  }
  
  if(!tileData)return <></>

  let hasDimension = !loading && tileData.disp_body && tileData.disp_body[0]

  return (
    <div data-test-id="dimension-data-tile" className={classes.root}>
      <div className={classes.editableHeaderContainer} >
        <Typography className={classes.title}>{tileData.disp_title.toUpperCase()}</Typography>
      </div>
      <ClickAwayListener 
        onClickAway={(event)=>{
          if(checkIsClickOnSuggestion(event.target))return;
          setSearchValue('')
          setEditing(false)
        }}
      >
        <div ref={popperRef} className={classes.chipContainer + ' ' + (editing?classes.editingBox:isEditable?classes.clickableBox:'')} onClick={()=>isEditable && setEditing(true)}>
          {
            loading && <CircularProgress color='secondary' size={16}/>
          }
          <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',overflow:"hidden",width:'100%'}}>
            {
              hasDimension && 
              <div data-test-classname="dimension-data-tile-chip" className={classes.tag} style={{background:theme.palette.primary.dark,border:'none'}} onClick={tileData.disp_body[0].onClick}>
                <span className={classes.tagText} style={{color:theme.palette.background.main}}>{tileData.disp_body[0].name}</span>
                {
                  isEditable && 
                  <IconButton className={classes.iconButton + ' chip-delete-button'} style={{display:editing?'block':undefined}} onClick={event=>{event.stopPropagation();!deleting && onDelete()}}>
                    {getIconComponent({label:'clear',size:13.75,colour:theme.palette.background.main})}
                  </IconButton>
                }
              </div>
            }
            {
              !loading && tileData.disp_body.length===0 &&
              <div data-test-classname="dq-dimension-data-tile-chip" className={classes.tag} style={{background:theme.palette.chip.main,color:theme.palette.primaryText.main,border:`1px solid ${theme.palette.border.main}`,cursor:'default'}}>
                <span className={classes.tagText} style={{color:theme.palette.primaryText.main}}>None</span>
              </div>
            }
          </div>
          {
            editing && 
            <div style={{marginTop:8,marginBottom:6,width:'100%'}}>
              <SearchSelector
                url={'/solr/search/select'}
                params={{
                  q:getPartialMatchSearchString(searchValue),
                  fq:`object_type_srt:COLLECTION_INSTANCE AND collection_srt:"DATA QUALITY DIMENSION"` +
                      (tileData.disp_body.length>0?` AND -id:(${tileData.disp_body.map(b=>b.id).join(' OR ')})`:''),
                  fl:'*',
                  qf:'name_srt',
                  sort:'name_srt asc',
                  rows:20,
                }}
                testID="dq-dimension-manager-input"
                clearable
                autoFocus
                height={32}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder={`Search for a dq dimension`}
                autoSuggestion={true}
                suggestionListWidth={290}
                removeSuggestionHeader
                scrollable={true}
                isSimplifiedSuggestion={true}
                onResultClick={onAdd}
              />
            </div>
          }
        </div>
      </ClickAwayListener>
    </div>
  )
}

DQDimensionManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(DQDimensionManager));