import React, { useReducer, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/MergeItems/Body/Body';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
// import TabBar from '../../components/UI/TabBar/TabBar';
import 'url-search-params-polyfill';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import axiosCerebrum from '../../axios-cerebrum'
import { addHistory } from '../../HistoryManager';
import { adminPageInfo } from '../NavBar/utils';

const styles = theme => ({
});

const initialState = {
  tabState:0,
  mergeTabState:1,
  mergeRecords:{},
  listSourceFilter:{},
  listNameFilter:{},
  objectType:'USER',
  mergeTargetType:'USER',
};

function reducer(state, action) {
  switch (action.type) {
    case  "set_tab_state":
      return {
        ...state,
        tabState:action.tabState
      }
    case 'set_merge_records':
      return {
        ...state,
        mergeRecords:action.mergeRecords,
      }
    case 'set_object_type':
      return {
        ...state,
        objectType:action.objectType
      }
    //////////////////////////////
    // list 
    case 'set_list_source_filter':
      return {
        ...state,
        listSourceFilter:action.listSourceFilter
      }
    case 'set_list_name_filter':
      return {
        ...state,
        listNameFilter:action.listNameFilter
      }
    //////////////////////////////////
    // create merge
    case 'set_merge_tab_state':
      return {
        ...state,
        mergeTabState:action.mergeTabState
      }
    case 'set_merge_target_type':
      return {
        ...state,
        mergeTargetType:action.mergeTargetType
      }
    case 'set_merge_target':
      return {
        ...state,
        mergeTarget:action.mergeTarget
      }
    case 'set_merged_items':
      return {
        ...state,
        mergedItems:action.mergedItems
      }
    case 'set_valid_object_types':
      return {
        ...state,
        validObjectTypes:action.validObjectTypes,
        validObjectTypesLoading:action.validObjectTypesLoading,
        validObjectTypesError:action.validObjectTypesError
      }
    case 'set_valid_sources':
      return {
        ...state,
        validSources:action.validSources
      }
    case 'set_merge_source_filter':
      return {
        ...state,
        mergeSourceFilter:action.mergeSourceFilter
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

const MergeItems = props => {
  const {
    // classes,
    history,
    sessionData,
    // pageCache,
    // storePageCache
  } = props;


  const [state, dispatch] = useReducer(reducer,initialState);

  // useEffect(()=>{
  //   if(!state)return;
  //   storePageCache({
  //     cacheID:window.location.href,
  //     ...state
  //   })
  // // eslint-disable-next-line
  // },[state])

  let buttons = [];

  if(state.tabState===-1){
    buttons.push(
      <div style={{marginTop:32}}>
        <ProfileButton
          text="CLOSE"
          onClick={()=>{
            dispatch({type:'set_tab_state',tabState:0})
            dispatch({type:'set_merge_target_type',mergeTargetType:'USER'})
            dispatch({type:'set_merge_tab_state',mergeTabState:1})
            dispatch({type:'set_merge_target'})
            dispatch({type:'set_merged_items'})
          }}
        />
      </div>
    )
  }

  const tabOptions = ['MERGE']

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])

  useEffect(()=>{
    addHistory({
      url:window.location.pathname, 
      iconLabel:adminPageInfo.merge.icon, 
      title: adminPageInfo.merge.name, 
      subTitle:adminPageInfo.merge.description,
      type:'application',
    })
  },[])

  useEffect(()=>{
    if(!state.validSources){
      axiosCerebrum 
        .get(
          `/api/sources`,{
            params:{
              internal_flag:false,
              per_page:100,
              sort:'ALPHABETICAL'
            }
          }
        )
        .then(response=>{
          dispatch({type:'set_valid_sources',validSources:response.data.items})
        })
        .catch(error=>{
          console.log(error)
        })
    }
  // eslint-disable-next-line
  },[])

  let title = "Merge"
  let description = "The same item may exist in multiple sources. Use this page to merge them into a single entity in the data ecosystem."

  if(state.tabState===-1){
    title = 'Merge Items'
    description = ''
  }

  return (
    <ProfileLayout
      header={
        <div>
          <ProfileHeader
            title={title}
            subtitle=" "
            label='merge'
            description={description}
            buttons={buttons}
          />
        </div>}
      disableTabBarOffset={true}
      body={
        <Body
          history={history}
          state={state}
          dispatch={dispatch}
          sessionData={sessionData}
          tabOptions={tabOptions}
        />
      }
    />
  )
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MergeItems));