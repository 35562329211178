import React  from 'react';
import { Button, Typography, withStyles} from '@material-ui/core';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import GlossaryAdder from '../../UI/GlossaryAdder/GlossaryAdder';
import { copyToClipboard } from '../../../utilities';
import sqlFormatter from "sql-formatter";
import CodeDisplay from '../../UI/CodeDisplay/CodeDisplay';
import AdditionalPropertyAdder from '../../UI/AdditionalPropertyAdder/AdditionalPropertyAdder'
import SourceDescription from '../../UI/SourceDescription/SourceDescription';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  title: {
    fontSize: '1.25rem',
    color:theme.palette.header.main
  },
})

const Details = props => {

  const {
    state,
    fetchList,
    history,
    dispatch,
    classes
  } = props;

  const {
    sendAlert
  } = useAlert({
    id:`dataset-field-detail`
  })

  const handleCodeClick = () => {
    copyToClipboard(sqlFormatter.format(state.basicData.code,{language:'pl/sql'}))
    sendAlert({type:'info',message:"Copied code to clipboard"})
  }

  return (
    <div>
      <UpdateInputCerebrum
        url={`/api/datasetfields/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        data={state.basicData}
        label="dataset_field"
        initialValue={state.basicData.description}
        property="description"
        placeholder="Add a description"
        multiline
        fetchList={fetchList}
        collection="content"
        enableMarkDown={true}
        enableWidget={true}
        state={state}
      />

      <SourceDescription
        state={state}
        dispatch={dispatch}
      /> 

      <div style={{ marginTop: 60 }} >
        <GlossaryAdder
          state={state}
          dispatch={dispatch}
          history={history}
          disableModal
        />
      </div>

      <div style={{ marginTop: 60 }} >
        <AdditionalPropertyAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.additionalPropertiesModalOpen}
          setModalOpen={value=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:value})}
        />
      </div>

      {
        state.basicData.code && 
        <div style={{ marginTop: 60 }} >
          <div style={{display:'flex',alignItems:'flex-start'}}>
            <Typography className={classes.title}>DEFINITION</Typography>
            <Button style={{marginLeft:16,marginTop:-2}} color='primary' onClick={handleCodeClick}>COPY</Button>
          </div>
          <CodeDisplay code={state.basicData.code} noStyle/>
        </div>
      }

	  </div>
  )
}


export default withStyles(styles)(Details);