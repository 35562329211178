import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, ClickAwayListener, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
// import axiosCerebrum from '../../../../axios-cerebrum'
import axiosCerebrum from '../../../../axios-cerebrum';
import { getIconComponent,  getLabelPlural, getPartialMatchSearchString} from '../../../../utilities';
import { tileStyles } from './utils/styles';
import SearchSelector from '../../SearchSelector/SearchSelector';
import { checkIsClickOnSuggestion, measureText } from './utils/utils';
import KTooltip from '../../KTooltip/KTooltip';
import useAlert from '../../../../hooks/useAlert';
// import { globalListenerRef } from '../../../../GlobalListenerRef';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
  iconButton:{
    ...tileStyles.deleteButton
  },
})

function PIIDetManager(props) {

  const {
    classes,
    theme,
    history,
    object,
    onAddTileData,
    tileData,
    isEditable,
    alwaysOpenNewTab
  } = props;

  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [deleting, setDeleting] = useState(false)
  const [adding, setAdding] = useState(false)
  const popperRef = useRef()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])
  
  const loadPIIDet = () => {
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related/collections`,
        {params:{
          category:'PLATFORM',
          parent_name:'PII Data Detected',
          relationship:'MEMBER_OF,MEMBER_OF_AUTO',
          per_page:200
        }}
      )
      .then(response=>{
        let pii = response.data.items;
        onAddTileData(
          {
            "disp_body":pii.map(el=>({
              labels:'collection_instance',
              name:el.name,
              is_auto:Boolean(el.relationship==='MEMBER_OF_AUTO'),
              id:el.id,
              onClick:()=>{
                alwaysOpenNewTab?
                window.open(`/profile/collection_instance/${el.id}`,'_blank'):
                history.push(`/profile/collection_instance/${el.id}`)
              }
            })),
            "disp_title": "PII DETECTED",
            "id": 'piiDet',
          }
        )
      })
      .catch(error=>{
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(()=>{
    if(!tileData){
      loadPIIDet()
    }
  // eslint-disable-next-line
  },[])

  const onAdd = async item => {
    if(adding)return;
    setSearchValue('')
    setAdding(true)
    axiosCerebrum
      .put(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=MEMBER_OF&object_id=${item.id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":[...tileData.disp_body,{
              name:item.name_txt,
              labels:'collection_instance',
              id:item.id,
              is_auto:false,
              onClick:()=>{
                alwaysOpenNewTab?
                window.open(`/profile/collection_instance/${item.id}`,'_blank'):
                history.push(`/profile/collection_instance/${item.id}`)
              }
            }],
            "disp_title":"PII DETECTED",
            "id":"piiDet",
          }
        )
        setAdding(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred adding pii data detected, please try again', type: 'error' })
        setAdding(false)
      })
  }

  const onDelete = (item) => {
    setDeleting(true)
    let showLoadingTimeout = setTimeout(()=>{
      setLoading(true)
    },800)
    axiosCerebrum
      .delete(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=MEMBER_OF&object_id=${item.id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":tileData.disp_body.filter(b=>b.id!==item.id),
            "disp_title":"PII DETECTED",
            "id":"piiDet",
          }
        )
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred deleting pii data detected, please try again', type: 'error' })
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
  }

  if(!tileData)return <></>

  return (
    <div data-test-id="pii-det-data-tile" className={classes.root}>
      <div className={classes.editableHeaderContainer}>
        <Typography className={classes.title}>PII DETECTED</Typography>
      </div>
      <ClickAwayListener 
        onClickAway={(event)=>{
          if(checkIsClickOnSuggestion(event.target))return;
          setSearchValue('')
          setEditing(false)
        }}
      >
        <div ref={popperRef} className={classes.chipContainer + ' ' + (editing?classes.editingBox:isEditable?classes.clickableBox:'')} onClick={()=>isEditable && setEditing(true)}>
          {
            loading && <CircularProgress color='secondary' size={16}/>
          }
          {
            !loading && tileData && tileData.disp_body.length===0 && 
            <div data-test-classname="pii-det-data-tile-chip" className={classes.tag} style={{background:theme.palette.chip.main,color:theme.palette.primaryText.main,border:`1px solid ${theme.palette.border.main}`,cursor:'default'}}>
              <span className={classes.tagText} style={{color:theme.palette.primaryText.main}}>None</span>
            </div>
          }
          {
            !loading && tileData && tileData.disp_body.sort((a,b)=>a.name<b.name?-1:1).map(t=>(
              <KTooltip key={t.name} title={t.is_auto && 'Linked automatically by KADA Sensitive Data Scanner'}>
                <div data-test-classname="pii-det-data-tile-chip" style={{background:'#B73029',border:'none',width:editing||tileData.disp_body.length===1?undefined:measureText(t.name)+(t.is_auto?24:0)+20}} className={classes.tag} onClick={t.onClick}>
                  <span className={classes.tagText} style={{color:'#fff'}}>{t.name}</span>
                  {
                    t.is_auto ?
                    <div style={{width:16,height:16,marginLeft:8,marginRight:6}}>
                      {getIconComponent({label:'auto',size:16,colour:'#fff'})}
                    </div>
                    :
                    isEditable &&
                    <IconButton className={classes.iconButton + ' chip-delete-button'}  style={{display:editing?'block':undefined}} onClick={event=>{event.stopPropagation();!deleting && onDelete(t)}}>
                      {getIconComponent({label:'clear',size:13.75,colour:'#fff'})}
                    </IconButton>
                  }
                </div>
              </KTooltip>
            ))
          }
          {
            editing && 
            <div style={{marginTop:8,marginBottom:6,width:'100%'}}>
              <SearchSelector
                url={'/solr/search/select'}
                params={{
                  q:getPartialMatchSearchString(searchValue),
                  fq:`object_type_srt:COLLECTION_INSTANCE AND collection_srt:"PII DATA DETECTED"` +
                      (tileData.disp_body.length>0?` AND -id:(${tileData.disp_body.map(b=>b.id).join(' OR ')})`:''),
                  fl:'*',
                  qf:'name_srt',
                  sort:'name_srt asc',
                  rows:20,
                }}
                testID="pii-det-manager-input"
                clearable
                autoFocus
                height={32}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder={`Search for a PII data`}
                autoSuggestion={true}
                suggestionListWidth={290}
                postProcessDataList={data=>{
                  if(!tileData.disp_body)return data;
                  return data.filter(d=>!tileData.disp_body.find(t=>t.id===d.id))
                }}
                removeSuggestionHeader
                scrollable={true}
                isSimplifiedSuggestion={true}
                keepPopperOnClick
                onResultClick={onAdd}
              />
            </div>
          }
        </div>
      </ClickAwayListener>
    </div>
  )
}

PIIDetManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(PIIDetManager));