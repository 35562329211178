import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, CircularProgress, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import VerticalTabBar from '../VerticalTabBar/VerticalTabBar';
import Details from './Details/Details';
import Runs from './Runs/Runs';
import axiosSolr from '../../../axios-solr';
import Summary from './Summary/Summary';

const styles = theme => ({
  root: {
  },
  subtitle: {
    fontSize: '0.75rem',
    color: theme.palette.primaryText.light,
    fontWeight: 400,
    // marginTop:8,
  },
  normalText:{
    color:theme.palette.primaryText.main,
    fontSize:16
  },
  errorMsg:{
    fontSize:16,
    color:theme.palette.primaryText.light
  },
  bannerButton:{
    padding:'2px 4px',
    marginBottom:-2
  },
})


function DQInsights(props) {

  const {
    classes,
    theme,
    history,
    state,
    dispatch,
    object,
  } = props;

  const [dqInstances, setDqInstances] = useState(state.dqInsightsData?.dqInstances)
  const [dqInstanceLoading, setDqInstanceLoading] = useState(false)
  

  const [tabState, setTabState] = useState(state.dqInsightsData?.tabState || 0)

  const [overviewByDimension, setOverviewByDimension] = useState(state.dqInsightsData?.overviewByDimension)
  const [overviewSelectedTile, setOverviewSelectedTile] = useState(state.dqInsightsData?.overviewSelectedTile || 'all')
  const [overviewMetrics, setOverviewMetrics] = useState(state.dqInsightsData?.overviewMetrics)
  const [overviewMetricsCache, setOverviewMetricsCache] = useState(state.dqInsightsData?.overviewMetricsCache || {})
  const [overviewPeriod, setOverviewPeriod] = useState(state.dqInsightsData?.overviewPeriod || 30)

  const [summaryByDimension, setSummaryByDimension] = useState(state.dqInsightsData?.summaryByDimension)
  const [summaryList, setSummaryList] = useState(state.dqInsightsData?.summaryList)
  const [summaryExpandedItem, setSummaryExpandedItem] = useState()
  const [summarySelectedTile, setSummarySelectedTile] = useState(state.dqInsightsData?.summarySelectedTile || 'all' )

  const [detailsByDimension, setDetailsByDimension] = useState(state.dqInsightsData?.detailsByDimension)
  const [selectedDetailDimension, setSelectedDetailDimension] = useState(state.dqInsightsData?.selectedDetailDimension)
  const [detailMetrics, setDetailMetrics] = useState(state.dqInsightsData?.detailMetrics)
  const [detailMetricsCache, setDetailMetricsCache] = useState(state.dqInsightsData?.detailMetricsCache || {})
  const [detailPeriod, setDetailPeriod] = useState(state.dqInsightsData?.detailPeriod || 30)
  const [selectedDetailDate, setSelectedDetailDate] = useState(state.dqInsightsData?.selectedDetailDate)
  const [detailDateData, setDetailDateData] = useState(state.dqInsightsData?.detailDateData)
  const [detailDateSort, setDetailDateSort] = useState(state.dqInsightsData?.detailDateSort || 'a-z')

  useEffect(()=>{
    dispatch({
      type:'set_dq_insights_data',
      dqInsightsData:{
        tabState,
        dqInstances,
        summaryByDimension,
        summaryList,
        detailsByDimension,
        selectedDetailDimension,
        detailMetrics,
        detailMetricsCache,
        detailPeriod,
        selectedDetailDate,
        detailDateData,
        detailDateSort,
        summarySelectedTile,
        overviewByDimension,
        overviewSelectedTile,
        overviewMetrics,
        overviewMetricsCache,
        overviewPeriod
      }
    })
  // eslint-disable-next-line
  },[
    dqInstances,
    tabState,
    summaryByDimension,
    summaryList,
    detailsByDimension,
    selectedDetailDimension,
    detailMetrics,
    detailMetricsCache,
    detailPeriod,
    selectedDetailDate,
    detailDateData, 
    detailDateSort, 
    summarySelectedTile,
    overviewByDimension,
    overviewSelectedTile,
    overviewMetrics,
    overviewMetricsCache,
    overviewPeriod
  ])


  const loadDqInstances = () => {
    setDqInstanceLoading(true)
    axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:'*',
          fq:'collection_srt:"DATA QUALITY DIMENSION" AND object_type_srt:COLLECTION_INSTANCE',
          fl:'name_txt,id',
          rows:300
        }}
      )
      .then(response=>{
        setDqInstanceLoading(false)
        setDqInstances(response.data.response.docs)
      })
      .catch(error=>{
        setDqInstanceLoading(false)
        setDqInstances([])
        console.log(error)
      })
  }

  useEffect(()=>{
    if(!dqInstances)loadDqInstances()
  // eslint-disable-next-line
  },[])

  if(dqInstanceLoading)return <CircularProgress color='secondary' />
  if(!dqInstances)return<></>

  return (
    <div className={classes.root}>
      <div style={{display:'flex',height:48,justifyContent:'center',alignItems:'center',marginBottom:16,borderRadius:3,border:`1px solid ${theme.palette.listItemDivider.main}`}}>
        <Button onClick={()=>dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}})} data-test-id="knowledge-manager-create-question-button" variant="outlined" className={classes.bannerButton} color='primary'>ASK A QUESTION</Button>
        <span className={classes.bannerText} style={{marginLeft:8,marginRight:8,color:theme.palette.primaryText.main}}>or</span>
        <Button  onClick={() => dispatch({ type: 'set_dqModal_open', dqModalOpen: true })} data-test-id="issue-section-add-issue-button" variant="outlined" className={classes.bannerButton} color='primary'>ADD AN ISSUE</Button>
      </div> 
      <div style={{display:'flex',overflow:'hidden'}}>
        <VerticalTabBar
          tabOptions={['SUMMARY','DETAILS','RUNS']}
          // tabOptions={['DQ SUMMARY']}
          setTabState={setTabState}
          tabState={tabState}
        />
        <div style={{marginLeft:80,flexGrow:1}}>
          {
            tabState===0 && 
            <Summary  
              object={object}
              dqInstances={dqInstances}
              overviewByDimension={overviewByDimension}
              setOverviewByDimension={setOverviewByDimension}
              history={history}
              selectedTile={overviewSelectedTile}
              setSelectedTile={setOverviewSelectedTile}
              overviewMetrics={overviewMetrics}
              setOverviewMetrics={setOverviewMetrics}
              overviewMetricsCache={overviewMetricsCache}
              setOverviewMetricsCache={setOverviewMetricsCache}
              overviewPeriod={overviewPeriod}
              setOverviewPeriod={setOverviewPeriod}
            />
          }
          {
            tabState===1 && 
            <Details  
              object={object}
              dqInstances={dqInstances}
              summaryByDimension={summaryByDimension}
              setSummaryByDimension={setSummaryByDimension}
              summaryList={summaryList}
              setSummaryList={setSummaryList}
              expandedItem={summaryExpandedItem}
              setExpandedItem={setSummaryExpandedItem}
              history={history}
              selectedTile={summarySelectedTile}
              setSelectedTile={setSummarySelectedTile}
            />
          }
          {
            tabState===2 &&
            <Runs
              object={object}
              dqInstances={dqInstances}
              detailsByDimension={detailsByDimension}
              setDetailsByDimension={setDetailsByDimension}
              history={history}
              selectedDetailDimension={selectedDetailDimension}
              setSelectedDetailDimension={setSelectedDetailDimension}
              detailMetrics={detailMetrics}
              setDetailMetrics={setDetailMetrics}
              detailMetricsCache={detailMetricsCache}
              setDetailMetricsCache={setDetailMetricsCache}
              detailPeriod={detailPeriod}
              setDetailPeriod={setDetailPeriod}
              selectedDetailDate={selectedDetailDate}
              setSelectedDetailDate={setSelectedDetailDate}
              detailDateData={detailDateData}
              setDetailDateData={setDetailDateData}
              detailDateSort={detailDateSort}
              setDetailDateSort={setDetailDateSort}
            />
          }
        </div>
      </div>
    </div>
  )
}

DQInsights.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(DQInsights));