import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Typography, withStyles, Button } from '@material-ui/core';
import SearchBar from '../../SearchBar/SearchBar'
import { formatNumber, getIconComponent } from '../../../../utilities'
import theme from '../../../../theme';
import VerticalTabBar from '../../../UI/VerticalTabBar/VerticalTabBar';
import FilterList from '../FilterList/FilterList'
import ResultList from '../ResultList/ResultList'
import PageController from '../PageController/PageController'
// import {useDispatch, useStore} from 'react-redux'
// import * as actions from '../../../../store/actions/actionTypes';
import { globalListenerRef } from '../../../../GlobalListenerRef'
import { getContentContainerStyle } from '../../../UI/ProfileLayoutNew/layoutUtils';
import SaveFilterModal from '../SaveFilterModal/SaveFilterModal';
import axiosCerebrum from '../../../../axios-cerebrum';
import { formatColumnNames, generateSearchFq, generateUrl, getDefaultColumn, getSearchFilterList, getSearchParams, getSearchQuery } from '../Utils/Utils';
import useAlert from '../../../../hooks/useAlert';
// import RecommendList from '../../Landing/RecommendList/RecommendList';
import * as actions from '../../../../store/actions/actionTypes';
import { useDispatch } from 'react-redux';
import axiosSolr from '../../../../axios-solr';
import fileDownloader from 'js-file-download';

const styles = theme => ({
  root: {
    // margin:'0px auto 40px',
    marginBottom: 40,
    paddingTop: 32,
    minWidth: 800,
    boxSizing: 'border-box'
  },
  noResultText: {
    fontSize: 16,
    color: theme.palette.primaryText.light,
    marginBottom: 40,
    marginTop: 40
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 4,
    marginBottom: 16,
    paddingTop: 8,
    position: 'sticky',
    background: theme.palette.background.main,
    zIndex: 1200,
    top: 0,
  },
  searchFilterContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: 8,
    paddingTop: 6,
    minHeight: 56,
  },
  searchBarWrapper: {
    position: 'sticky',
    top: 0,
    zIndex: 1280,
    // width:400,
    maxWidth: '100%',
    paddingBottom: 4,
    paddingTop: 12,
    background: theme.palette.background.main,
    transition: 'width .2s ease'
  },
  icon: {
    width: 40,
    height: 40,
    marginRight: 16,
    marginLeft: -56,
  },
  headerText: {
    fontSize: 28,
    flexGrow:1,
    color: theme.palette.header.main
  },
  '@media (max-width: 1350px)': {
    icon: {
      display: 'none'
    }
  },
  hideScroll: {
    ...theme.components.hideScroll
  }
});



const MainSearchBody = props => {

  const {
    classes,
    state,
    sessionData,
    dispatch,
    isNotSearchPage,
    onChangeTab,
    onSearch,
    loadSearchResults,
    onChangePage,
    onUpdateFilter,
    onResetAllFilters,
    onUpdateSort,
    history,
    tabGrouping,
    getDefaultParams,
    hiddenComponents,
    addtioonalComponents,
    removeContainerStyle,
    customHeaderFormatter,
    resultItemVariant,
    alwaysOpenNewTab,
    isCreateFilter,
    indexName,
    customEmptyMsg,
    forceGlobalFilterStr,
    forceGlobalQueryStr,
    forceOnItemClick
  } = props;

  // const reduxDispatch = useDispatch()
  const [rootStyle, setRootStyle] = useState(getContentContainerStyle())
  const [saveFilterModalOpen, setSaveFilterModalOpen] = useState(false)
  const isCancelledRef = useRef(false)
  const reduxDispatch = useDispatch()

  const {
    sendAlert
  } = useAlert({
    id: `basic-search-main-body`,
    isCancelledRef
  })

  const {
    sendAlert: sendAlertInstant
  } = useAlert({})

  useEffect(() => {
    if (state.selectedObjectType) {
      let group = tabGrouping.find(el => el.tabs.includes(state.selectedObjectType))
      if (group) {
        window.postMessage({ verticalTabBarExpand: group.name }, document.location.protocol + "//" + document.location.hostname + ':' + document.location.port)
      }
    }
    // eslint-disable-next-line
  }, [state.selectedObjectType, state.searchTabs])

  const getTabLabel = el => {
    let label = el.objectType;
    if (label === 'ALL') label = 'all_search'
    if (el.isCollection || el.collectionType) {
      switch (el.collectionType) {
        case 'GLOSSARY':
          label = 'glossary'
          break;
        case 'LIST':
          label = 'list'
          break;
        case 'CHANGE':
          label = 'change'
          break;
        case 'COLLABORATION':
          label = 'channel'
          break;
        case 'KNOWLEDGE':
          label = 'note'
          break;
        default:
          label = 'collection'
      }
    }
    return label
  }

  const onBulkActionFilter = () => {
    reduxDispatch({
      type:actions.SET_BULK_EDIT_PARAM,
      data:{
        url:`/solr/${indexName}/select`,
        type:'solr',
        filterId: state.isSavedFilter.id,
        redirectUrl:window.location.pathname + window.location.search,
        fileName:`Assets from filter: ${state.isSavedFilter.name}`.trim(),
      }
    })

		history.push('/bulk_update')
  }

  const onBulkAction = ({fileName}) => {
    if(state.isSavedFilter){
      onBulkActionFilter()
      return
    }
    let params = {};
    params = {
      q: getSearchQuery({queryInput:state.mainSearchQuery, searchMode:state.searchMode, indexName:indexName}),
      searchQuery: state.mainSearchQuery,
      fq: generateSearchFq({
        tabs:state.searchTabs,
        filters:state.mainSearchFilters,
        objectType:state.selectedObjectType,
        urlFilter:state.mainSearchUrlFilter,
        indexName:indexName,
        forceFq:forceGlobalFilterStr
      })
    }

    reduxDispatch({
      type:actions.SET_BULK_EDIT_PARAM,
      data:{
        url:`/solr/${indexName}/select`,
        params,
        redirectUrl:window.location.pathname + window.location.search,
        fileName:fileName || `${formatNumber(state.resultsTotal)} assets from search`.trim(),
      }
    })

		history.push('/bulk_update')
  }

  const onDownloadAll = () => {
    let q = getSearchQuery({queryInput:state.mainSearchQuery, searchMode:state.searchMode, indexName});
    let fq = generateSearchFq({
      tabs:state.searchTabs,
      filters:state.mainSearchFilters,
      objectType:state.selectedObjectType,
      urlFilter:state.mainSearchUrlFilter,
      indexName:indexName
    })

    if(state.mainSearchFilters.length>0)fq += ` AND ${state.mainSearchFilters.join(' AND ')}`;

    let flList = ['NAME:name_txt','DESCRIPTION:description','LOCATION:location_txt']
    state.selectedColumns.forEach(el=>{
      flList.push(`${formatColumnNames(el).replace(/\s/g,'_')}:${el}`)
    })

    let promises = [];

    sendAlert({type:'info',message:'Download started'})

    promises = [
      axiosSolr.get(`/solr/${indexName}/select`, {params:{
        q:q,
        fq:fq,
        fl:flList.join(','),
        rows:100000,
        ...getSearchParams({queryInput:state.mainSearchQuery, searchMode:state.searchMode}),
        wt:'csv'
      }}).then(response=>{
        fileDownloader(response.data, `kada_search_results.csv`);
      })
    ]

  Promise
    .all(promises)
    .then(response=>{
      if(state.resultsTotal>100000){
        sendAlert({type:'info',message:'Download completed. A limit of 100k items can be downloaded at a time'})
      }else{
        sendAlert({type:'info',message:'Download completed'})
      }
    })
    .catch(error=>{
      console.log(error)
      sendAlert({type:'error',message:'Error occurred exporting results, please try again'})
      })
  }

  useEffect(() => {
    setRootStyle(getContentContainerStyle());
    window.removeEventListener('resize', globalListenerRef.serachMainBodyResieListener)
    window.removeEventListener('message', globalListenerRef.searchBodyMessageListener)
    globalListenerRef.serachMainBodyResieListener = () => {
      setRootStyle(getContentContainerStyle());
    }
    globalListenerRef.searchBodyMessageListener = (e) => {
      if (e.data && e.data.searchBulkAction) {
        onBulkAction({fileName:e.data.fileName})
      }
    }
    window.addEventListener('message', globalListenerRef.searchBodyMessageListener)
    window.addEventListener('resize', globalListenerRef.serachMainBodyResieListener)

    return () => {
      window.removeEventListener('resize', globalListenerRef.serachMainBodyResieListener)
      window.removeEventListener('message', globalListenerRef.searchBodyMessageListener)
      isCancelledRef.current = true
    }
  // eslint-disable-next-line
  }, [state.mainSearchQuery, state.mainSearchFilters, state.mainSearchUrlFilter, state.selectedObjectType, state.searchMode, state.searchTabs, state.resultsTotal])

  const onSaveFilter = () => {
    let searchConfig = generateUrl({
      mainSearchQuery: state.mainSearchQuery,
      searchFilters: getSearchFilterList({ filterStatus: state.filterStatus, negativeFilters: state.negativeFilters }),
      selectedObjectType: state.selectedObjectType,
      defaultColumns: getDefaultColumn({ objectType: state.selectedObjectType, tabs: state.searchTabs }),
      selectedColumns: state.selectedColumns,
      searchMode: state.searchMode,
      andFilters: state.andFilters,
    });

    let rawPrams = loadSearchResults({ isGenerateParamOnly: true })
    let solrQuery = {
      "q": searchConfig.query,
      "q_raw": rawPrams.q,
      "fq": searchConfig.presetFilter,
      "fq_raw": rawPrams.fq,
      "columns": searchConfig.presetColumn,
      "object_type": searchConfig.object,
      'and_filters': searchConfig.andFilters,
      'search_mode': searchConfig.searchMode
    }
    axiosCerebrum
      .put(
        `/api/filteredlists/${state.isSavedFilter.id}`, {
        // "name": state.isSavedFilter.name,
        // "description": state.isSavedFilter.description,
        // "public": permission==='public',
        "solr_query": solrQuery,
        // "filtered_list_type": "LIST"
      }
      )
      .then(response => {
        sendAlertInstant({ type: 'info', message: `Filter "${response.data.name}" successfully saved` })
        history.push('/filters')
      })
      .catch(error => {
        console.log(error)
        sendAlert({ type: 'error', message: `Error occurred saving filter` })
      })
  }

  const isTabsShown = state.searchTabs && state.searchTabs.length > 0 && (!state.isSavedFilter || state.isSavedFilter.solr_query?.object_type === 'ALL') && !hiddenComponents.includes('tab');

  const listMinHeight = removeContainerStyle ?
    isNaN(state.resultsTotal) ?
      undefined :
      Math.min(10, state.resultsTotal) * 96 + (state.resultsTotal < 10 ? 200 : 0) :
    1550

  const isEmptyPage = !state.searchTabs && !state.searchTabsLoading && !state.searchTabsError;

  return (
    <div className={classes.root} style={removeContainerStyle ? { minWidth: 0, padding: 0, margin: 0 } : rootStyle}>
      {
        !hiddenComponents.includes('header') &&
        <div className={classes.header}>
          <div className={classes.icon}>
            {getIconComponent({ label: 'search', size: 40, colour: theme.palette.header.main })}
          </div>
          <Typography className={classes.headerText}>
            {
              isCreateFilter ?
                'Create Filter' :
                (state.isSavedFilter?.name || 'Search')
            }
          </Typography>
          {
            state.isSavedFilter && state.isSavedFilter.created_by?.id === sessionData.id &&
            <Button
              color='primary'
              variant='outlined'
              style={{ marginLeft: 24, padding: '2px 12px' }}
              disabled={!state.isFilterOrColumnChanged}
              onClick={() => {
                onSaveFilter()
              }}
              data-test-id="filter-save-button"
            >
              SAVE
            </Button>
          }
          <Button
            color='primary'
            variant='outlined'
            style={{ marginLeft: 24, padding: '2px 12px' }}
            onClick={() => {
              setSaveFilterModalOpen(true)
            }}
            disabled={isEmptyPage}
            data-test-id="filter-create-button"
          >
            {isCreateFilter ? 'CREATE FILTER' : 'SAVE AS'}
          </Button>
          {
            !isCreateFilter && !state.isSavedFilter &&
            <Button
              color='primary'
              variant='outlined'
              style={{ marginLeft: 24, padding: '2px 12px' }}
              onClick={onBulkAction}
              disabled={isEmptyPage || state.cartItems.length !== 0}
              data-test-id="bulk-action-button"
            >
              BULK ACTION
            </Button>
          }
          <Button
            color='primary'
            variant='outlined'
            style={{ marginLeft: 24, padding: '2px 12px' }}
            onClick={onDownloadAll}
            disabled={isEmptyPage}
            data-test-id="download-button"
          >
            DOWNLOAD
          </Button>
        </div>
      }
      {
        !hiddenComponents.includes('searchBar') && !isNotSearchPage &&
        <div className={classes.searchBarWrapper} style={{...(isNotSearchPage?{ position:'inherit' }:{})}}>
          <SearchBar isNotSearchPage={isNotSearchPage} indexName={indexName} state={state} dispatch={dispatch} onSearch={onSearch} placeholder={isCreateFilter ? 'Search' : ''} hideLabel={!isCreateFilter || !isEmptyPage} />
        </div>
      }
      {
        isCreateFilter && !state.searchTabs && !state.searchTabsLoading && !state.searchTabsError &&
        <Typography style={{ fontSize: 16, marginTop: 24, paddingLeft: 16 }}>Add search criteria to see your filter results</Typography>
      }
      {
        !hiddenComponents.includes('filter') &&
        <div className={classes.searchFilterContainer}>
          {
            !hiddenComponents.includes('searchBar') && isNotSearchPage &&
            <div style={{display:'flex',alignItems:'center',marginRight:32,position:'relative',bottom:-3,width:isCreateFilter?'100%':260}}>
              <SearchBar
                isNotSearchPage={isNotSearchPage}
                indexName={indexName}
                state={state}
                dispatch={dispatch}
                onSearch={onSearch}
                placeholder={isCreateFilter ? 'Search' : ''}
                hideLabel={!isCreateFilter || !isEmptyPage}
                hideActions
              />
            </div>
          }
          {
            state.searchTabs && state.searchTabs.length > 0 &&
            <FilterList
              state={state}
              dispatch={dispatch}
              onUpdateFilter={onUpdateFilter}
              onResetAllFilters={onResetAllFilters}
              rootWidth={rootStyle.width - 280 - rootStyle.paddingLeft * 2}
              getDefaultParams={getDefaultParams}
              hiddenComponents={hiddenComponents}
              addtioonalComponents={addtioonalComponents}
              indexName={indexName}
              forceGlobalFilterStr={forceGlobalFilterStr}
              forceGlobalQueryStr={forceGlobalQueryStr}
            />
          }
        </div>
      }
      {
        isTabsShown &&
        <div className={classes.hideScroll} style={{ marginTop: 4, float: 'left', height: '100vh', minHeight: listMinHeight, overflow: 'auto' }}>
          <VerticalTabBar
            tabOptions={state.searchTabs.map(el => el.objectType)}
            renderOptions={value => `${state.searchTabs.find(el => el.objectType === value).displayName} (${formatNumber(state.searchTabs.find(el => el.objectType === value).count)})`}
            tabState={state.searchTabs.map(el => el.objectType).indexOf(state.selectedObjectType)}
            tabIcons={state.searchTabs.map(el => getTabLabel(el))}
            setTabState={value => {
              onChangeTab(state.searchTabs[value].objectType)
            }}
            grouping={tabGrouping}
            alwaysShowFullTab
            highlightOnSelected
            width={220}
            hideGroupTitleNames={['ALL']}
          />
          <div style={{ height: 24 }}></div>
        </div>
      }
      <div
        style={{
          flexGrow: 1,
          minHeight: listMinHeight,
          marginLeft: isTabsShown ? 280 : 0,
          flexShrink: 1
        }}
      >
        {
          ((state.searchTabs && state.searchTabs.length === 0)) &&
          <div>
            <Typography className={classes.noResultText}>
              {
                customEmptyMsg ||
                `No results found for "${state.searchValue}"`
              }
            </Typography>
            {
              // <RecommendList state={state} dispatch={dispatch} history={history} onSearch={onSearch} variant={'result_suggestion'}/>
            }
          </div>

        }
        {
          (state.searchTabsError || (state.searchResults[state.pageNum] && state.searchResults[state.pageNum].error)) &&
          <Typography style={{ marginTop: 32, color: theme.palette.primaryText.main }}>Error occurred loading results</Typography>
        }
        {
          (state.searchTabsLoading || (state.searchResults[state.pageNum] && state.searchResults[state.pageNum].loading)) &&
          <div>
            <div style={{ textAlign: 'center', position: 'relative', bottom: -24 }}>
              <CircularProgress color='secondary' />
            </div>
          </div>
        }
        {
          state.searchResults[state.pageNum] && state.searchResults[state.pageNum].data && state.searchTabs &&
          <div>
            <ResultList
              state={state}
              dispatch={dispatch}
              isNotSearchPage={isNotSearchPage}
              history={history}
              onUpdateSort={onUpdateSort}
              loadSearchResults={loadSearchResults}
              tabGrouping={tabGrouping}
              hiddenComponents={hiddenComponents}
              customHeaderFormatter={customHeaderFormatter}
              resultItemVariant={resultItemVariant}
              alwaysOpenNewTab={alwaysOpenNewTab}
              indexName={indexName}
              customEmptyMsg={customEmptyMsg}
              forceOnItemClick={forceOnItemClick}
            />
            {
              state.resultsTotal > 0 &&
              <PageController
                state={state}
                dispatch={dispatch}
                onChangePage={onChangePage}
                isNotSearchPage={isNotSearchPage}
              />
            }
          </div>
        }
      </div>
      <SaveFilterModal
        state={state}
        modalOpen={saveFilterModalOpen}
        setModalOpen={setSaveFilterModalOpen}
        isCreateFilter={isCreateFilter}
        history={history}
        loadSearchResults={loadSearchResults}
      />
    </div>
  )
}

export default withStyles(styles)(MainSearchBody);
