import React, { useState} from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Button,Modal, Typography } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SourceSelector from './SourceSelector';
import KTooltip from '../../../UI/KTooltip/KTooltip';
import FilterSelector from './FilterSelector';
import { toTitleCase } from '../../../../utilities';
import ObjectSelector from './ObjectSelector';
import { getFileName } from '../../Utils/Utils';


const styles = theme => ({
  root: {
    width: 640,
    maxWidth:'80vw',
    height:624,
    maxHeight:'80vh',
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    padding:24,
    paddingRight:0,
    boxSizing:'border-box',
    display:"flex",
    flexDirection:'column',
    overflow:"hidden"
  },
  labelText: {
    color:theme.palette.primaryText.light,
  },
  selectedLabelText:{
    color:`${theme.palette.primary.main} !important`,
    fontWeight:'400 !important'
  },
  stepIcon:{
    cursor:'pointer'
  },
  button:{
    minWidth:96,
    height:36,
    marginLeft:8
  },
  header:{
    color:theme.palette.header.main,
    fontSize:20,
    paddingRight:24
  },
  subHeader:{
    color:theme.palette.primaryText.light,
    fontSize:13.75,
    marginBottom:8,
    paddingRight:24,
    whiteSpace:'pre-wrap'
  },
  buttons:{
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end',
    marginBottom:-12,
    marginRight:16
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  stepper:{
    backgroundColor:theme.palette.background.main,
    paddingRight:24
  },
  customScroll:{
    ...theme.components.customScroll,
  },
  configTitle:{
    fontSize:16,
    marginBottom:12,
    paddingRight:24,
    color:theme.palette.primaryText.main
  },
  selector: {
    ...theme.components.selector,
    height:56,
    width:'100%',
    boxSizing:'border-box'
  },
  
})

function ScannerConfigs(props) {

  const {
    classes,
    state,
    submitExtract,
    modalOpen, 
    setModalOpen,
  } = props;

  const [activeStep, setActiveStep] = useState(0)
  const [source, setSource] = useState('none')
  const [filterType, setFilterType] = useState('none')
  const [objects, setObjects] = useState([])
  const sourceList = state.sources || [];
  const steps = ['Select Source',`Select Filter`,`Select Objects`];

  const getStepLabel = step => {
    switch(step){
      case 0:
        if(source!=='none'){
          return `Source: ${sourceList.find(s=>s.id===source).name}`
        }
        return steps[0];
      case 1:
        if(filterType!=='none'){
          return `Filter by ${toTitleCase(filterType)}`
        }
        return steps[1];
      case 2:
        return steps[2];
      default:
        return ''
    }
  }

  const labelClickable = index => {
    if(index===0)return true;
    if(index===1)return source!=='none'
    if(index===2)return filterType!=='none' && labelClickable(1)
  }

  const formatLabelText = text => {
    if(text.length>30)return text.slice(0,27)+'...'
    return text;
  }

  const onBackClick = () => {
    setActiveStep(Math.max(activeStep-1,0))
  }

  const onNextClick = () => {
    if(activeStep===2){
      let payload={
        type:'EXTRACTS',
        domain:document.location.protocol + "//" + document.location.hostname,
        lookup_code:'0360',
        filters:{
          source_id:source,
          object_type:filterType.toUpperCase(),
          object_ids:objects.map(el=>el.id)
        }
      }
      submitExtract(payload, '0360', getFileName('0360',{objectType:filterType,objects}))
      setModalOpen(false)
    }else{
      setActiveStep(activeStep+1)
    }
  }

  const onCancelClick = () => {
    setModalOpen(false)
  }

  const onSelectSource = event => {
    let id = event.target.value;
    setSource(id)
    setFilterType('none')
    setObjects([])
    if(id!=='none'){
      setActiveStep(1)
    }
  }

  const onSetFilterType = type => {
    setFilterType(type)
    setActiveStep(2)
    setObjects([])
  }

  const onAddObject = el => {
    if(objects.find(o=>o.id===el.id))return;
    setObjects([...objects,el])
  }

  const onDeleteObject = el => {
    setObjects(objects.filter(o=>o.id!==el.id))
  }

  let bodyContent;

  if(activeStep===0){
    bodyContent = (
      <SourceSelector classes={classes} source={source} onSelectSource={onSelectSource} sourceList={sourceList}/>
    )
  }
  if(activeStep===1){
    bodyContent = (
      <FilterSelector classes={classes} filterType={filterType} onSetFilterType={onSetFilterType} />
    )
  }
  if(activeStep===2){
    bodyContent = (
      <ObjectSelector classes={classes} objects={objects} source={source} filterType={filterType} onAddObject={onAddObject} onDeleteObject={onDeleteObject} />
    )
  }

  return (
    <Modal open={modalOpen} disableEscapeKeyDown>
      <div style={{width:'100vw',height:'100vh',display:"flex",alignItems:'center',justifyContent:'center'}}>
        <div className={classes.root}>
          <Typography className={classes.header}>CREATE SCANNER CONFIGURATION</Typography>
          <Typography className={classes.subHeader}>
            Select the database tables you want to profile using the K scanner.{'\n'}
            The extract created will be used by the K scanner as a configuration file
          </Typography>
          <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel>
            {steps.map((label,index) => (
              <Step className={classes.step}  key={index} completed={false}>
                <KTooltip title={getStepLabel(index)}>
                  <StepLabel 
                    onClick={()=>{
                      if(labelClickable(index))setActiveStep(index)
                    }}
                    data-test-id={`scanner-config-step-${index+1}`}
                    classes={{
                      active:classes.selectedLabelText,
                      iconContainer:classes.stepIcon,
                      label:classes.labelText
                    }}
                  >
                    {formatLabelText(getStepLabel(index))}
                  </StepLabel>
                </KTooltip>
              </Step>
            ))}
          </Stepper>
          <div style={{marginTop:0,marginBottom:24,flexGrow:1,overflow:"auto",boxSizing:'border-box'}} className={classes.customScroll}>
            {bodyContent}
          </div>
          <div className={classes.buttons}>
            <Button data-test-id="scanner-back-button" color='primary' style={{visibility:activeStep>0?'visible':'hidden'}} onClick={onBackClick}>
              BACK
            </Button>
            <Button 
              data-test-id="scanner-next-button" 
              color='primary' 
              style={{marginLeft:16}} 
              onClick={onNextClick} 
              disabled={(activeStep===2 && objects.length===0) || (activeStep!==2 && !labelClickable(activeStep+1))}
            >
              {activeStep<2?
                'NEXT'
                :
                'CREATE'
              } 
            </Button>
            <Button data-test-id="scanner-cancel-button" color='secondary' style={{marginLeft:16}} onClick={onCancelClick}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

ScannerConfigs.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  submitExtract: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default withTheme()(withStyles(styles)(ScannerConfigs));