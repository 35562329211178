import React, { useEffect } from 'react';
import { withTheme, withStyles, Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import IconDataTile from './TilesComponents/IconDataTile';
import { getIconComponent, getLabelPlural, toTitleCase, onClickResultItem, getInitials } from '../../../../utilities'; 
import { globalListenerRef } from '../../../../GlobalListenerRef';
import axiosCerebrum from '../../../../axios-cerebrum';
import ListTile from './TilesComponents/ListTile';

const styles = theme => ({
  dataBox: {
    marginBottom: '2rem'
  },
  avatar: {
    backgroundColor: theme.palette.avatar.main,
    width:24,
    height:24,
    fontSize:12
  },
})

function ManagedByTile(props) {

  const {
    theme,
    history,
    object,
    classes,
    onAddTileData,
    tileData,
    alwaysOpenNewTab
  } = props;


  const loadManagedTile = async () => {

    await axiosCerebrum
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related`,
        {params:{
          relationship:'CREATED_BY,MODIFIED_BY',
          object_name:'USER',
          sort:'END_DESC',
          per_page:100
        }}
      )
      .then(response=>{
        let managedBys = [];
        response.data.items.forEach(e=>{
          if(!managedBys.find(m=>m.id===e.id))managedBys.push(e)
        });
        if(managedBys.length>0){
          if(managedBys.length===1){
            onAddTileData(
              {
                "disp_body":[{"name":managedBys[0].name,"labels":'user',id:managedBys[0].id}],
                "disp_title": "MANAGED BY",
                "id": 'managedBy',
              }
            )
          }else{
            onAddTileData(
              {
                "disp_body":managedBys.map(el=>({
                  labels:'user',
                  name:el.name,
                  id:el.id,
                })),
                "disp_title": "MANAGED BY",
                "id": 'managedBy',
              }
            )
          }
        }
      })
    
  }

  useEffect(()=>{
    if(!tileData)loadManagedTile()
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.managedByTileListener)
    globalListenerRef.managedByTileListener = (event) => {
      if(event.data?.reload_sidebar?.includes('managedBy')){
        loadManagedTile()
      }
    }
    window.addEventListener('message',globalListenerRef.managedByTileListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.managedByTileListener)
    }
  // eslint-disable-next-line
  },[])
  
  if(!tileData)return <></>

  if( typeof(tileData.disp_body)==='string'){
    return (
      <IconDataTile
        title={tileData.disp_title}
        text={tileData.disp_body}
        left={getIconComponent({label: 'help', size: 24, colour: theme.palette.primaryText.light})}
        action={tileData.box_action}
      />
    )
  }

  if(tileData.disp_body.length===1){
    return (
      <IconDataTile
        title={tileData.disp_title}
        text={toTitleCase(tileData.disp_body[0].name)}
        left={<Avatar className={classes.avatar}>{getInitials(tileData.disp_body[0].name)}</Avatar>}
        onClick={
          tileData.disp_body[0].name==='KADA'?
          null:
          ()=>onClickResultItem({history:history,label: tileData.disp_body[0].labels,id: tileData.disp_body[0].id, newWindow:alwaysOpenNewTab})
        }
      />
    )
  }

  return (
    <ListTile
      title={tileData.disp_title}
      list={
        tileData.disp_body.map(item => ({
          name: item.name,
          onClick: () => {
            alwaysOpenNewTab?
            window.open(`/profile/${item.labels}/${encodeURIComponent(item.id)}`, '_blank'):
            history.push(`/profile/${item.labels}/${encodeURIComponent(item.id)}`);
          }
        }))
      }
    />
  )

}

ManagedByTile.propTypes = {
  classes: PropTypes.object.isRequired,
  boxData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ManagedByTile));