import React, { useEffect } from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getIconComponent, getLabelPlural } from '../../../../utilities'; 
import { globalListenerRef } from '../../../../GlobalListenerRef';
import axiosCerebrum from '../../../../axios-cerebrum';
import ListTile from './TilesComponents/ListTile';

const styles = theme => ({
})

function KnowledgeTile(props) {

  const {
    theme,
    object,
    onAddTileData,
    tileData,
  } = props;


  const loadKnowledgeTile = async () => {
    let knowledgeCollections = [];
    await axiosCerebrum
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related/collections`,
        {params:{
          category:'KNOWLEDGE',
          page:1,
          parent_flag:true,
          per_page:20,
        }}
      )
      .then(response=>{
        let sort = ['Question','Decision','Business Logic','Note','Roadmap','How To','Link']
        knowledgeCollections = response.data.items.sort((a,b)=>sort.indexOf(a.name)-sort.indexOf(b.name))
      })
    
    const onOpenKnowledge = () => {
      window.postMessage({sidebar_action:'open_knowledge'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
    };
    if(knowledgeCollections.length!==0){
      let items = []
      for(let i=0; i<knowledgeCollections.length; i++){
        let c = knowledgeCollections[i]
        await axiosCerebrum
          .get(
            `/api/${getLabelPlural(object.object.name)}/${object.id}/related/collections`,
            {params:{
              category:'KNOWLEDGE',
              parent_id:c.id,
              per_page:0,
            }}
          )
          .then(response=>{
            items.push({name:c.name,count:response.data.total,id:c.id})
          })
      }
      onAddTileData(
        {
          "disp_body":items.map((el,index)=>({
            name:el.name+'(s)',
            count:el.count,
            labels:el.name==='Question'?'question':'note',
            onClick:()=>{
              window.postMessage({sidebar_action:'open_knowledge',sub_tab_id:el.id},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
            }
          })),
          "box_action":[{
            action:onOpenKnowledge,
            text:'OPEN KNOWLEDGE'
          }],
          "disp_title":"KNOWLEDGE",
          "disp_type":"list",
          "id":"knowledge",
        }
      )
    }else{
      onAddTileData(
        {
          "disp_body":[{count:'No',name:'note added',labels:'note'}],
          "box_action":[{
            action:onOpenKnowledge,
            text:'OPEN KNOWLEDGE'
          }],
          "disp_title":"KNOWLEDGE",
          "disp_type":"list",
          "id":"knowledge",
        }
      )
    }
  }

  useEffect(()=>{
    if(!tileData)loadKnowledgeTile()
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.knowledgeTileListener)
    globalListenerRef.knowledgeTileListener = (event) => {
      if(event.data?.reload_sidebar?.includes('knowledge')){
        loadKnowledgeTile()
      }
    }
    window.addEventListener('message',globalListenerRef.knowledgeTileListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.knowledgeTileListener)
    }
  // eslint-disable-next-line
  },[])
  
  if(!tileData)return <></>

  return (
    <ListTile
      title={tileData.disp_title}
      list={
        tileData.disp_body.map(item => ({
          name:`${item.count} ${item.name}`,
          onClick:item.onClick
        }))
      }
      icons={
        tileData.disp_body.map(item => {
          return getIconComponent({label: item.labels, size: 24,colour:theme.palette.primary.main})
        })
      }
      action={tileData.box_action}
      disableCollapse={true}
    />
  )

}

KnowledgeTile.propTypes = {
  classes: PropTypes.object.isRequired,
  boxData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(KnowledgeTile));