import React, {useEffect, useRef} from 'react';
import { withTheme, withStyles, Typography, CircularProgress} from '@material-ui/core';
import theme from '../../../theme';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr';
import moment from 'moment';
import { collectionIds, getIconComponent, isInViewport, onClickResultItem } from '../../../utilities';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';
import CustomMenu from '../../UI/ContextMenu/ContextMenu'

const styles = theme => ({

  columnText:{
    fontSize:12,
    paddingRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    color:theme.palette.primary.main,
    minWidth:50,
    letterSpacing:1.5
  },
  tableText:{
    fontSize:12,
    paddingRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    minWidth:50,
  },
  listItem:{
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    height:48,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:'pointer',
    '&:hover':{
      backgroundColor:theme.palette.hovered.main
    }
  }
})

function Questions(props) {

  const {
    history,
    classes,
    state,
    dispatch,
    sessionData
  } = props;

  const scrollRef = useRef()

  const flexConfig = [
    '1 1 200px',
    '0 1 300px',
  ]

  const loadQuestions = ({page=1}) => {
    dispatch({
      type:'set_question_data',
      questionLoading:true,
      questiondata:page===1?undefined:state.questionData
    })
    axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related/collections`,{
          params:{
            relationship:'CREATOR_OF',
            category:'KNOWLEDGE',
            parent_id:collectionIds.question,
            page:page,
            sort:'END_DESC',
            per_page:20,
          }
        }
      )
      .then(response=>{
        if(response.data.total===0){
          dispatch({type:'set_question_data',questionData:response.data})
          return;
        }
        axiosSolr
          .get(
            `/solr/search/select`,{
              params:{
                q:"*",
                fq:`id:(${response.data.items.map(item=>item.id).join(' OR ')})`,
                rows:20,
              }
            }
          )
          .then(solrResponse=>{
            let questionData = {
              ...response.data,
              items:response.data.items.map((item,index)=>({
                ...item,
                ...(solrResponse.data.response.docs.find(doc=>doc.id===item.id) || {})
              }))
            };
            if(page>1){
              questionData.items = state.questionData.items.concat(questionData.items)
            }
            dispatch({type:'set_question_data',questionData})
          })
          .catch(error=>{
            dispatch({type:'set_question_data',questionError:error})
          })
      })
      .catch(error=>{
        dispatch({type:'set_question_data',questionError:error})
      })
  }

  useEffect(()=>{
    if(!state.questionData){
      loadQuestions({})
    }
  // eslint-disable-next-line
  },[])

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && !state.questionLoading && state.questionData?.pages>state.questionData?.page
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadQuestions({page:state.questionData.page+1})
    }
  // eslint-disable-next-line
  },[state.questionData,state.questionLoading])

  window.onscroll = () => {
    if(shouldLoadMore()){
      loadQuestions({page:state.questionData.page+1})
    }
  }

  return (
    <div>
      <div style={{display:'flex',paddingBottom:24,alignItems:'flex-start',position:'sticky',top:214,background:theme.palette.background.main}}>
        <div style={{flexGrow:1}}>
          <Typography style={{fontSize:20}}>
            QUESTIONS 
          </Typography>
          <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>
            My questions asked in K
          </Typography>
        </div>
      </div>
      {
        state.questionData?.total===0 &&
        <Typography style={{fontSize:12}}>No questions found</Typography>
      }
      {
        state.questionData?.total>0 &&
        <>
          <div style={{display:"flex",marginBottom:8}}>
            <div style={{width:24,height:24,flexGrow:0,flexShrink:0,marginLeft:16,marginRight:16}}>
              
            </div>
            <Typography className={classes.columnText} style={{flex:flexConfig[0]}}>
              QUESTION
            </Typography>
            <Typography className={classes.columnText} style={{flex:flexConfig[1]}}>
              LAST UPDATED
            </Typography>
          </div>
          {
            state.questionData?.items.map(el=>(
              <>
                <ContextMenuTrigger id={el.id}>
                  <div className={classes.listItem} onClick={()=>onClickResultItem({item:el,id:el.id,label:el.type,history})} >
                    <div style={{width:24,height:24,flexGrow:0,flexShrink:0,marginLeft:16,marginRight:16}}>
                      {getIconComponent({label:'question',size:24,colour:props.theme.palette.primary.main})}
                    </div>
                    <Typography className={classes.tableText} style={{flex:flexConfig[0]}}>
                      {el.name_txt||el.name}
                    </Typography>
                    <Typography className={classes.tableText} style={{flex:flexConfig[1]}}>
                      {moment(el.last_updated_srt || el.updated_at).format('MM/DD/YYYY')}
                    </Typography> 
                  </div>
                </ContextMenuTrigger>
                <ContextMenu id={el.id}>
                  <CustomMenu
                    item={el}
                    actions={[
                      'open_new_tab'
                    ]}
                  />
                </ContextMenu>
              </>
            ))
          }
        </>
      }
      <div ref={scrollRef} style={{display:'flex',justifyContent:'center',marginBottom:24,marginTop:8}}>
        {
          state.questionLoading && 
          <CircularProgress color='secondary'/>
        }
      </div>
      {
        state.questionError && 
        <Typography>Error occurred loading questions</Typography>
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(Questions));