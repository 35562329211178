import React, {useEffect, useState} from 'react';
import { withStyles, Typography, CircularProgress, Button, withTheme} from '@material-ui/core';
import { getIconComponent, getTestScoreColor, onClickResultItem } from '../../../utilities'
import axiosSolr from '../../../axios-solr'
import KTooltip from '../../UI/KTooltip/KTooltip';
import moment from 'moment';
import { getIconLabel } from '../../UI/SearchResults/utils';
import { ContextMenuTrigger, ContextMenu } from 'react-contextmenu';
import CustomMenu from '../../UI/ContextMenu/ContextMenu'

const styles = theme => ({
  root: {
    marginBottom:40
  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  normalText:{
    color:theme.palette.primaryText.main,
    fontSize:13.75
  },
  chip:{
    background:'#DCDDDE',
    color:'#2b2a36',
    padding:'4px 8px',
    marginRight:24,
    fontSize:13.75,
    borderRadius:12
  },
  columnHeader:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    letterSpacing:1.5
  },
  listText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  listItem:{
    display:'flex',
    overflow:'hidden',
    alignItems:'center',
    height:48,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:"pointer",
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  selectedItem:{
    background:theme.palette.hovered.main
  }
})

const LinkedTo = props => {

  const {
    classes,
    theme,
    history,
    dispatch,
    state,
  } = props;

  const [iconHovered, setIconHovered] = useState(false)

  const loadLinkedTo = ({start=0, isLoadMore, sort=state.linkedSort, forceShowRows}) => {
    dispatch({type:"set_linked_to_data",linkedToLoading:true,linkedToData:start===0?undefined:state.linkedToData})
    if(start===0){
      dispatch({type:'set_selected_record'})
    }
    if(forceShowRows && forceShowRows>200)forceShowRows=200
    axiosSolr
      .get(
        `/solr/data_quality_test_result/select`,{params:{
          q:'*',
          fq:`data_quality_test_id_srt:${state.basicData.id}`,
          rows:forceShowRows||5,
          start,
          sort
        }}
      )
      .then(response=>{
        let shownRows = state.linkedToData && start!==0?state.linkedToData.shownRows:5;
        if(isLoadMore)shownRows+=5
        if(forceShowRows)shownRows=forceShowRows
        dispatch({
          type:"set_linked_to_data",
          linkedToData:{
            ...response.data.response,
            docs:[...(start===0?[]:state.linkedToData.docs),...response.data.response.docs],
            shownRows
          }
        })
        if(start===0 && response.data.response.docs.length>0){
          dispatch({type:'set_selected_record',selectedRecord:response.data.response.docs[0]})
        }
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:"set_linked_to_data",linkedToError:true})
      })
  }

  useEffect(()=>{
    if(!state.linkedToData && !state.linkedToLoading)loadLinkedTo({})
  // eslint-disable-next-line
  },[])

  const onClickShowMore = () => {
    let newRows = state.linkedToData.shownRows

    if(newRows>=state.linkedToData.docs.length){
      setTimeout(()=>loadLinkedTo({start:newRows, isLoadMore:true}))
    }else{
      dispatch({
        type:'set_linked_to_data',
        linkedToData:{
          ...state.linkedToData,
          shownRows:newRows+5
        }
      })
    }
  }

  const onClickSeeLess = () => {
    dispatch({
      type:'set_linked_to_data',
      linkedToData:{
        ...state.linkedToData,
        shownRows:5
      }
    })
  }
  
  const onChangeSort = (field) => {
    // dispatch({type:'set_selected_record'})
    let currentSort = state.linkedSort;
    let newSort;
    if(field==='object_name')newSort = currentSort==='object_name_srt asc'?'object_name_srt desc':'object_name_srt asc';
    if(field==='last_score')newSort = currentSort==='last_data_quality_score_srt asc'?'last_data_quality_score_srt desc':'last_data_quality_score_srt asc';
    if(field==='last_run')newSort = currentSort==='last_run_date_srt asc'?'last_run_date_srt desc':'last_run_date_srt asc';
    dispatch({type:'set_linked_sort',linkedSort:newSort})
    loadLinkedTo({sort:newSort, forceShowRows:state.linkedToData?.shownRows})
  }

  const getSortIcon = (field) => {
    if(!state.linkedSort)return<></>
    if(field==='object_name' && state.linkedSort.includes('object_name_srt'))return <span style={{position:'relative',bottom:-2}}>{getIconComponent({label:state.linkedSort==='object_name_srt asc'?'up':'down',size:13.75,colour:theme.palette.primaryText.light})}</span>
    if(field==='last_score' && state.linkedSort.includes('last_data_quality_score_srt'))return <span style={{position:'relative',bottom:-2}}>{getIconComponent({label:state.linkedSort==='last_data_quality_score_srt asc'?'up':'down',size:13.75,colour:theme.palette.primaryText.light})}</span>
    if(field==='last_run' && state.linkedSort.includes('last_run_date_srt'))return <span style={{position:'relative',bottom:-2}}>{getIconComponent({label:state.linkedSort==='last_run_date_srt asc'?'up':'down',size:13.75,colour:theme.palette.primaryText.light})}</span>
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>LINKED TO</Typography>
      <Typography className={classes.subTitle}>Items that this test is run on. Click to see more details</Typography>
      {
        state.linkedToData && state.linkedToData.docs?.length===0 && 
        <Typography className={classes.normalText}>No items found</Typography>
      }
      {
        (state.linkedToLoading || state.linkedToData?.docs?.length>0)  &&
        <div style={{display:'flex',alignItems:'center',marginBottom:8}}>
          <Typography onClick={()=>onChangeSort('object_name')} className={classes.columnHeader} style={{marginRight:16,marginLeft:56,flex:'0 0 25%',cursor:'pointer'}}>NAME {getSortIcon('object_name')}</Typography>
          <Typography onClick={()=>onChangeSort('last_score')} className={classes.columnHeader} style={{marginRight:16,flex:'0 0 45%',cursor:'pointer'}}>LATEST SCORE {getSortIcon('last_score')}</Typography>
          <Typography onClick={()=>onChangeSort('last_run')} className={classes.columnHeader} style={{marginRight:8,flex:'0 1 30%',cursor:'pointer'}}>LAST RUN {getSortIcon('last_run')}</Typography>
        </div>
      }
      {
        state.linkedToData && state.linkedToData.docs.slice(0,state.linkedToData.shownRows).map(el=>(
          <>
            <ContextMenuTrigger id={el.id}>
              <div 
                // onClick={(event)=>{event.stopPropagation();onClickResultItem({item:el,id:el.object_id_srt,label:el.object_type_srt,history})}}  
                onClick={event=>{
                  event.stopPropagation();
                  if(state.resultLoading)return;
                  dispatch({type:'set_selected_record',selectedRecord:el})
                }}
                data-test-classname="linked-item" 
                className={classes.listItem + (el.id===state.selectedRecord?.id?' '+classes.selectedItem:'')}
                style={{
                  background:(iconHovered || state.resultLoading) && el.id!==state.selectedRecord?.id?theme.palette.background.main:undefined,
                  opacity:state.resultLoading && el.id!==state.selectedRecord?.id?0.6:1,
                  cursor:state.resultLoading?'default':undefined
                }}
              >
                <div style={{flex:'0 0 24px',height:24,marginLeft:16,marginRight:16}}>
                  {getIconComponent({label:getIconLabel({label:el.object_type_srt, item:el}),size:24,colour:theme.palette.primaryText.light})}
                </div>
                <div style={{marginRight:16,flex:'0 0 25%',overflow:'hidden'}}>
                  <KTooltip title={el.object_name_srt}>
                    <Typography className={classes.listText}>
                      {el.object_name_srt}
                    </Typography>
                  </KTooltip>
                  {
                    ['COLUMN','DATASET_FIELD'].includes(el.object_type_srt) &&
                    <KTooltip title={el.table_name_srt || el.dataset_table_name_srt}>
                      <Typography className={classes.listText} style={{fontSize:12,color:theme.palette.primaryText.light}}>
                        {el.table_name_srt || el.dataset_table_name_srt}
                      </Typography>
                    </KTooltip>
                  }
                </div>
                <Typography className={classes.listText} style={{marginRight:16,flex:'0 0 45%',}}>
                  <div style={{width:'max-content',maxWidth:'100%',boxSizing:'border-box',color:'#000',borderRadius:2,padding:'0px 8px',background:getTestScoreColor(el.last_data_quality_score_srt)}}>{el.last_data_quality_score_srt}</div>
                </Typography>
                <div style={{flex:'0 1 30%',marginRight:8,display:'flex',justifyContent:'space-between',overflow:'hidden',alignItems:'center'}}>
                  <Typography className={classes.listText}>
                    {moment(el.last_run_date_srt).format('DD MMM YYYY')}
                  </Typography>
                  <Button 
                    onMouseEnter={()=>setIconHovered(true)}
                    onMouseLeave={()=>setIconHovered(false)}
                    onClick={event=>{event.stopPropagation();onClickResultItem({item:el,id:el.object_id_srt,label:el.object_type_srt,history})}}
                    color='primary'
                    variant='outlined'
                    style={{padding:'0px'}}
                  >
                    {/* {getIconComponent({label:'open',size:24,colour:theme.palette.primaryText.light})} */}
                    OPEN
                  </Button>
                </div>
              </div>
            </ContextMenuTrigger>

            <ContextMenu id={el.id}>
              <CustomMenu
                // item={item}
                itemUrl={onClickResultItem({item:el,id:el.object_id_srt,label:el.object_type_srt,history,urlOnly:true})}
                // customActions={customActions}
                actions={[
                  'open_new_tab'
                ]}
              />
            </ContextMenu>
          </>
        ))
      }
      {
        state.linkedToLoading && <CircularProgress color='secondary'/>
      }
      {
        state.linkedToError && <Typography className={classes.normalText}>Error occurred loading items</Typography>
      }
      {
        state.linkedToData && state.linkedToData.numFound>5 && 
        <div style={{marginTop:8}}>
          {
            state.linkedToData.shownRows<state.linkedToData.numFound && 
            <Button color='primary' onClick={()=>{onClickShowMore()}} style={{marginRight:8}}>SEE MORE</Button>
          }
          {
            state.linkedToData.shownRows>5 && 
            <Button color='primary' onClick={()=>{onClickSeeLess()}}>SEE LESS</Button>
          }
        </div>
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(LinkedTo));