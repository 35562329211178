import React, { useEffect } from 'react';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import VerticalTabBar from '../../../UI/VerticalTabBar/VerticalTabBar'
// import TypeChart from './TypeChart';
import TeamChart from './TeamChart';

const styles = theme => ({
  title:{
    fontSize:16,
    color:theme.palette.primaryText.main
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  }
})

const DataSummaryBody = props => {

  const {
    // theme,
    state,
    classes,
    dispatch,
    direction
  } = props;

  const data = state.summaryChartData['user']
  const setData = d => dispatch({type:'set_summary_chart_data',summaryChartData:{...state.summaryChartData,user:d}})

  useEffect(()=>{
    if(!data){
      setData({tabState:0})
    }
  // eslint-disable-next-line
  },[])
  
  if(!data)return <div></div>


  let tabOptions = ['BY TEAM']

  if(state.multiHistoryObjects && !state.historyObject){
    tabOptions = ['BY TEAM']
  }

  let title, subTitle, chartComponent;
  let impactText = direction==='downstream'?'IMPACTED':'DEPENDENT';
  switch(tabOptions[data.tabState]){
    case 'BY TEAM':
      title = `${direction.toUpperCase()} ${impactText} USERS BY TEAM`;
      subTitle = `Count of ${direction} ${impactText.toLowerCase()} users by team. Note: Users in multiple teams will be counted in each team separately.`
      chartComponent = <TeamChart state={state} data={data} setData={setData}/>
      break;
    // case 'BY TYPE':
    //   title = `${direction.toUpperCase()} ${impactText} USERS BY TYPE`;
    //   subTitle = `Count of ${direction} ${impactText.toLowerCase()} users by type`
    //   chartComponent =  <TypeChart state={state} data={data} setData={setData}/>
    //   break;
    default:
  }


  return (
    <div style={{display:'flex',alignItems:'flex-start'}}>
      <VerticalTabBar
        tabOptions={tabOptions}
        tabState={data.tabState || 0}
        setTabState={value=>setData({...data, tabState:value})}
      />
      <div style={{flexGrow:1,marginLeft:80,overflow:'hidden'}}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.subTitle}>{subTitle}</Typography>
        {
          chartComponent
        }
      </div>
    </div>
    
  )
}

export default withTheme()(withStyles(styles)(DataSummaryBody));