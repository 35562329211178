export const bindNodeAction = ({constructDataJoins, groups=[], node, onCollapseLink, onShowLink}) => {
  if(groups.length===0)return;
  if(node.isRoot)return;
  // if(node.data.rightActionIcon || node.data.lefttActionIcon || node.loaded)return;
  let group = groups.find(g=>g.id===node.parentNode);
  if(!group)return;
  // if(group.x > sourceX){
  node.data.rightAction = async function(){
    if(!node.data.loaded){
      node.data.rightActionIcon = 'loading';
      await constructDataJoins(node)
    }else{
      if(node.data.rightActionIcon==='remove'){
        onCollapseLink(node)
        node.data.rightActionIcon = 'add';
      }else{
        onShowLink(node)
        node.data.rightActionIcon = 'remove';
      }
      
    }
  }
  node.data.rightActionIcon = 'add'
  // }else{
  //   node.data.leftAction = function(){constructDataJoins(node.data)}
  //   node.data.lefttActionIcon = 'add'
  // }
} 