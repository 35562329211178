import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import { withStyles, Select, MenuItem, Popper, Paper, withTheme, InputBase, CircularProgress, Typography, Button} from '@material-ui/core';
import { getIconComponent } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr';

const styles = theme => ({
  root: {

  },
  titleSelector:{
    ...theme.components.titleSelector,
    marginRight:8,
    maxWidth:'80vw',
  },

  inputBase:{
    ...theme.components.inputBase,
    width:'100%',
    height:42,
    borderBottom:`1px solid ${theme.palette.primaryText.main}`,
    marginBottom:8
  },
  selectPaper:{
    padding:0,
    minWidth:240
  },
})

const InstanceSelector = props => {

  const {
    classes,
    theme,
    state,
    onSelectInstance
  } = props;

  const [anchor, setAnchor] = useState();
  const [subAnchor, setSubAnchor] = useState();
  const [hoveredFocus, setHoveredFocus] = useState();
  const [subFocusSearch, setSubFocusSearch] = useState('');
  const popperExitTimeout = useRef();
  const subFocusLoadTimeout = useRef();
  const [instancesData, setInstancesData] = useState({});
  const searchTimeout = useRef();
  const [emptyInstanceMap, setEmptyInstanceMap] = useState({})

  const loadInstances = ({collectionId=hoveredFocus, page=1, searchValue = subFocusSearch}) => {
    setInstancesData({
      loading:true,
      data:page===1?undefined:instancesData.data
    })
    axiosCerebrum
      .get(
        `/api/collectioninstances`,{
          params:{
            collection_id:collectionId,
            page,
            per_page:10,
            'search.name':searchValue || undefined
          }
        }
      )
      .then(async response=>{
        if(response.data.total===0){
          setInstancesData({
            data:response.data
          })
          return;
        }
        let facetPayload = {};
        response.data.items.forEach(instance=>{
          facetPayload[instance.id] = {
            type: 'query',
            query: `${collectionId}_kc_msrt:"${instance.name}"`
          }
        })

        let emptyMap = {}
        await axiosSolr
          .get(
            `/solr/data_quality_test_result/select`,{
              params:{
                q:"*",
                fq:`${collectionId}_kc_msrt:*`,
                rows:0,
                'json.facet':facetPayload
              }
            }
          )
          .then(solrRes=>{

            response.data.items.forEach(instance=>{
              let count = solrRes.data.facets[instance.id]?.count || 0
              emptyMap[instance.id] = count===0
            })
            
            setEmptyInstanceMap({...emptyInstanceMap,...emptyMap})
          })
          .catch(error=>{
            console.log('error',error)
          })
        
        response.data.items = response.data.items.filter(instance=>{
          return !emptyMap[instance.id]
        })

        setInstancesData({
          data:{
            ...response.data,
            items: page===1?response.data.items:instancesData.data.items.concat(response.data.items)
          }
        })
      })
      .catch(error=>{
        setInstancesData({error:error})
      })
  }

  const generateCollectionList = () => {
    let categories = ['DATA_GOVERNANCE','DATA_MANAGEMENT','GLOSSARY'];
    let collectionMap = {};
    categories.forEach(c=>{
      let list = state.dqCollectionList.filter(o=>o.category===c)
      if(list.length>0)collectionMap[c] = list
    })
    
    return (
      <div>
        {
          Object.keys(collectionMap).map(category=>(
            <div key={category}>
              <Typography style={{fontSize:12,paddingLeft:16,paddingTop:16,paddingBottom:8,letterSpacing:1}}>{category.replace(/_/g,' ')}</Typography>
              {
                collectionMap[category].map(o=>(
                  <MenuItem 
                    key={o.id}
                    onMouseEnter={(event)=>{
                      let currentTarget = event.currentTarget;
                      setHoveredFocus(o.id)
                      clearTimeout(subFocusLoadTimeout.current)
                      subFocusLoadTimeout.current = setTimeout(()=>{
                        setSubAnchor(currentTarget)
                        loadInstances({collectionId: o.id})
                      },500)
                    }}
                    style={{paddingLeft:24}}
                    selected={hoveredFocus===o.id}
                  >
                    <span style={{flexGrow:1}}>{o.name}</span>
                    {getIconComponent({label:'triangle_right',size:24,colour:theme.palette.primaryText.light})}
                  </MenuItem>
                ))
              }
            </div>
          ))
        }
      </div>
    )
  }
  
  return (
    <div className={classes.root}>
      <Select
        className={classes.titleSelector}
        disableUnderline
        value={'default'}
        open={false}
        onClick={(event)=>{setAnchor(event.currentTarget)}}
        onOpen={()=>{}}
        onClose={()=>{}}
        renderValue={value => {
          if(state.byCollectionSelectedInstance)return state.byCollectionSelectedInstance.name;
          return 'Select an instance';
        }}
      >
      </Select>
      <Popper open={anchor} anchorEl={anchor} placement='bottom-start'>
        <Paper 
          style={{marginTop:6,minWidth:320,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}
          onMouseLeave={event=>{
            clearTimeout(popperExitTimeout.current)
            clearTimeout(subFocusLoadTimeout.current)
            popperExitTimeout.current = setTimeout(()=>{
              setAnchor()
              setHoveredFocus()
              setSubAnchor()
            },200)
          }}
          onMouseEnter={event=>{
            clearTimeout(popperExitTimeout.current)
            setSubFocusSearch('')
          }}
        >
          {
            generateCollectionList()
          }
        </Paper>
      </Popper>
      <Popper open={subAnchor} anchorEl={subAnchor} placement='right-start'>
        <Paper 
          style={{marginLeft:6,minWidth:260,paddingBottom:8,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}
          onMouseLeave={event=>{
            clearTimeout(popperExitTimeout.current)
            popperExitTimeout.current = setTimeout(()=>{
              setAnchor()
              setHoveredFocus()
              setSubAnchor()
            },200)
          }}
          onMouseEnter={event=>{
            clearTimeout(popperExitTimeout.current)
          }}
        >
          {
            hoveredFocus &&
            <>
              <InputBase
                value={subFocusSearch}
                onChange={event=>{
                  let value = event.target.value
                  clearTimeout(searchTimeout.current)
                  setSubFocusSearch(value)
                  searchTimeout.current = setTimeout(()=>{
                    loadInstances({collectionId:hoveredFocus, searchValue:value})
                  },300)
                }}
                className={classes.inputBase}
                placeholder={`Search instances`}
              />
              {
                instancesData.data?.items?.map(option=>{
                  return (
                    <MenuItem 
                      onClick={(event)=>{
                        onSelectInstance(option)
                        setAnchor()
                        setSubAnchor()
                      }} 
                      disabled={emptyInstanceMap[option.id]}
                      key={option.id} 
                      value={option.id}
                    >
                      {option.name}
                    </MenuItem>
                  )
                })
              }
              {
                instancesData.loading && 
                <div style={{display:'flex',justifyContent:'center',marginTop:8}}>
                  <CircularProgress style={{width:20,height:20}} color="secondary"/>
                </div>
              }
              {
                instancesData.data?.items.length===0 && 
                <Typography style={{fontSize:13,paddingLeft:16}}>No results found</Typography>
              }
              {
                instancesData.error && 
                <Typography style={{fontSize:13,paddingLeft:16}}>Error occurred loading instances</Typography>
              }
              {
                instancesData.data?.page<instancesData.data?.pages && !instancesData.loading && 
                <div style={{display:'flex',justifyContent:'center'}}>
                  <Button color="primary" style={{width:'100%',padding:"4px 0px"}} onClick={()=>{loadInstances({page:instancesData.data.page+1})}}>
                    {getIconComponent({label:'more_horiz',size:24,colour:theme.palette.primaryText.light})}
                  </Button>
                </div>
              }
            </>
          }
        </Paper>
      </Popper>
    </div>
  )
}

InstanceSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sessionData: PropTypes.object
}

export default withTheme()(withStyles(styles)(InstanceSelector));