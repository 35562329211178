import React, {} from 'react';
import { withTheme, Typography,Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';


const SchemaSelector = props => {

  const {
    classes,
    theme,
    schema,
    setSchema,
    schemaList,
  } = props;


  return (
    <div className={classes.modalBlock}>
      <Typography  className={classes.modalBlockTitle}>
        Schema
      </Typography>
      <div style={{flexGrow:1}}>
        <FormControl style={{width:'100%'}}>
          <Select
            className={classes.selector}
            value={schema}
            onChange={event=>{setSchema(event.target.value)}}
            disableUnderline
            MenuProps={{
              classes:{
                paper:classes.selectPaper
              }
            }}
            renderValue={el=>{
              return (
                el==='none'
                  ?
                  <Typography style={{color:theme.palette.primaryText.light,fontSize:16}}>
                    Select a Schema
                  </Typography>
                  :
                  <>
                    <Typography style={{fontSize:16,color:theme.palette.primaryText.main}}>
                      {schemaList.find(s=>s.id+''===el+'').name_txt}
                    </Typography>
                  </>
              )
            }}
          > 
            <MenuItem   className={classes.menuItem} value={'none'}>
              Select a Schema
            </MenuItem>
            {
              schemaList.map(el=>(
                <MenuItem className={classes.menuItem} value={el.id}>
                  {el.name_txt}
                </MenuItem>
              ))
            }

          </Select>
          <FormHelperText className={classes.helperText}>
            {'Optional'}  
          </FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}

SchemaSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  schema: PropTypes.string.isRequired,
  setSchema: PropTypes.func.isRequired,
  schemaList: PropTypes.array.isRequired,
}

export default withTheme()(SchemaSelector);