import React, { useEffect } from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { getIconComponent, setHelpWdigetVisibility } from '../../../utilities';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        justifyContent:'center'
    },
    emot:{
      fontSize:40,
      color:theme.palette.primaryText.main
    },
    text:{
        fontSize:16,
        color:theme.palette.primaryText.light,
        marginTop:24
    }
});

function DeadEnd(props) {

    const {
      theme,
      classes,
      mainText,
      subText,
      variant
    } = props;

    useEffect(()=>{
      setHelpWdigetVisibility(true)
      return ()=>{setHelpWdigetVisibility(false)}
    // eslint-disable-next-line
    },[])

    if(variant==='narrow'){
      return (
        <div style={{display:'flex',flexDirection:'column',width:'100%',height:'100vh',alignItems:'center',justifyContent:'center'}}>
          <div className={classes.root}>
              <Typography className={classes.text}>{'There is an error loading this panel.'}</Typography>
              <Typography className={classes.text}>{'Please try again.'}</Typography>
              <Typography className={classes.text} style={{whiteSpace:'pre-wrap',textAlign:'center'}}>
                If the problem persists please let your Administration{'\n'}
                or KADA via the support portal know
              </Typography>
          </div>
        </div>
      )
    }
    
    return (
      <div style={{display:'flex',flexDirection:'column',width:'100%',height:'100vh',alignItems:'center',justifyContent:'center'}}>
        <div className={classes.root}>
            <Typography className={classes.emot}>¯\_(ツ)_/¯</Typography>
            <Typography className={classes.text}>{mainText || 'There is an error loading your page.'}</Typography>
            <Typography className={classes.text}>{subText || 'Please refresh and try again.'}</Typography>
            <Typography className={classes.text} style={{display:'flex',alignItems:'center'}}>If the problem persists, raise a ticket with KADA via the <div style={{width:20,height:20,margin:'0 4px'}}>{getIconComponent({label:'help_contained',size:20,colour:theme.palette.primary.main})}</div> icon in the bottom right corner</Typography>
        </div>
      </div>
    )

}

export default withTheme()(withStyles(styles)(DeadEnd));