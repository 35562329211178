// import React from 'react';
import { getIconComponent, getTrustScoreBand, getTestScoreColor, getTrustScoreColor } from '../../../utilities';
import { palette} from '../../../theme'

export const generateTags = (obj, loadDQFailed) => {
  const tags = [];
  if(!obj)return tags;
  if(obj.reference_txt==='YES')return tags;
  if(obj.active_txt==='NO'){
    return [
      {
        id:'deleted',
        tooltip:"This object has been deleted",
        icon:<svg width={20} height={20}><rect width={8} height={8} fill="#fff" x={4} y={4}/>{getIconComponent({label:'clear_circle',size:20, colour:palette.error.main, background:'#fff'})}</svg>
      }
    ]
  }
  let label = obj.object_type_txt || obj.labels;
  if(label==='COLLECTION')return tags;
  if(!isNaN(obj.trust_srt) && !['TOOL','DATABASE','COLLECTION_INSTANCE'].includes(label)){
    tags.push({
      id:'trust',
      tooltip:"Trust score: " + getTrustScoreBand({trustScore:obj.trust_srt}),
      icon:<svg width={16} height={16}><circle r={7} cx={8} cy={8} stroke={palette.primary.dark} fill={getTrustScoreColor(obj.trust_srt)} /></svg>
    })
    // tags.push({
    //   id:'trust',
    //   tooltip:"Trust score: " + getTrustScoreBand({trustScore:obj.trust_srt}),
    //   icon:<svg width={20} height={20}><rect width={8} height={8} fill="#fff" x={4} y={4} />{getTrustScoreIcon({trustScore:obj.trust_srt,size:20})}</svg>
    // })
  }
  if(['TABLE','COLUMN','DATASET','DATASET_TABLE','DATASET_FIELD','CODE'].includes(label) && obj.number_of_dq_tests_srt){
    let score = obj.dq_score_overall_srt;
    let isNoDQScore = isNaN(score)
    tags.push({
      id:'dq_score',
      tooltip:isNoDQScore?"No DQ score found":("Overall DQ score: " + score),
      icon:
          isNoDQScore?
          getIconComponent({size:16, colour:palette.chartDisabledIcon.main}):
          <svg width={16} height={16}><circle r={7} cx={8} cy={8} stroke={palette.primary.dark} fill={getTestScoreColor(score,true)} /></svg>
    })
    // tags.push({
    //   id:'dq_score',
    //   tooltip:isNoDQScore?"No DQ score found":("Overall DQ score: " + score),
    //   icon:(
    //       isNoDQScore?
    //       getIconComponent({size:16, colour:palette.chartDisabledIcon.main}):
    //       getIconComponent({label:'data_quality_test',size:20,colour:getTestScoreColor(score,true)})
    //   )
    // })
  }
  if(obj.has_knowledge_txt && ['ONE','MANY'].includes(obj.has_knowledge_txt)){
    let score, text;
    if(obj.has_knowledge_txt==='ONE'){
      score = 50;
      text = 'One note added';
    }
    else if(obj.has_knowledge_txt==='MANY'){
      score = 100;
      text = 'More than one notes added';
    }
    tags.push({
      id:'knowledge',
      tooltip:text,
      icon:(<svg width={16} height={16}><circle r={7} cx={8} cy={8} stroke={palette.primary.dark} fill={getTestScoreColor(score,true)} /></svg>)
    })
  }
  return tags
}