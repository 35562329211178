import React, {useEffect, useRef} from 'react';
import { withTheme, withStyles, Modal, Button, Paper, LinearProgress, Typography} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import SourceScheduler from '../../OnBoardSource/ScheduleSource/SourceScheduler';
import useAlert from '../../../hooks/useAlert';
import ModalAlert from '../../UI/ModalAlert/ModalAlert';

const styles = theme => ({
  modalRoot:{
    padding:24,
    // paddingBottom:8,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    margin:'20vh auto 0',
    maxHeight:'70vh',
    overflow:'auto'
  },
  modalTitle:{
    wordBreak:'break-word',
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:12
  },
  modalBodyText:{
    fontSize:16,
    color:theme.palette.primaryText.light,
    whiteSpace:'pre-wrap'
  },
  buttons:{
    marginTop:24,
    marginRight:-12,
    marginBottom:-16,
    display:'flex',
    justifyContent:'flex-end'
  },
})

const BatchScheduler = props => {

  const {
    state,
    classes,
    theme,
    dispatch,
    history
  } = props;

  const isCancelledRef = useRef(false)


  const [deleting, setDeleting] = React.useState(false)
  const [alertOpen, setAlertOpen] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState('')

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  window.onpopstate = () => {
    dispatch({type:'set_view',view:'home'})
  }
    
  useEffect(()=>{
    history.push(`/admin/platform_management`)
    return () => {
      window.onpopstate = undefined;
    }
  // eslint-disable-next-line
  },[])

  const setDisableSchedule = () => {
    let payload = {}
    payload.cron = {};
    payload.args = {load_frequency: ''}
    setDeleting(true)
    axiosCerebrum
      .put(`/api/platformbatches/${state.selectedJob.id}`,payload)
      .then(response=>{
        setDeleting(false)
        dispatch({type:'set_disable_schedule_modal_open',disableScheduleModalOpen:false})
        dispatch({
          type: 'set_jobs_data',
          jobsData: state.jobsData.map(j=>{
            if(j.id===state.selectedJob.id){
              return response.data
            }else{
              return j
            }
          })
        })
        sendAlert({message:'Schedule removed',type:'info'})
        dispatch({type:'set_view',view:'home'})
      }).catch((error)=>{
        console.log(error.response);
        setDeleting(false)
        setAlertOpen(true)
        setAlertMessage(`Error occurred disabling schedule, please try again`)
      })
  }
  

  const onSave = ({cron, load_frequency}) => {
    axiosCerebrum.put(
      `/api/platformbatches/${state.selectedJob.id}`, {cron, args:{load_frequency}}
      // `/api/platformbatches/${state.selectedJob.id}`, {cron}
    ).then((response) => {
      dispatch({
        type: 'set_jobs_data',
        jobsData: state.jobsData.map(j=>{
          if(j.id===state.selectedJob.id){
            return response.data
          }else{
            return j
          }
        })
      })
      sendAlert({message:'Schedule updated',type:'info'})
      dispatch({type:'set_view',view:'home'})
    }).catch(error => {
      console.log(error)
      sendAlert({message:'Error occurred updating schedule',type:'error'})
    })
  }

  return (
    <div>     
      <SourceScheduler
        state={{selectedSource:state.selectedJob}}
        removePopStateBehaviour
        forceOnSave={onSave}
      />
      <Modal open={state.disableScheduleModalOpen}>
        <div style={{width:'max-content',margin:'auto',outline:'none'}}>
          {
            state.disableScheduleModalOpen && 
            <Paper className={classes.modalRoot} style={{width:400}}>
              <Typography className={classes.modalTitle}>Are you sure?</Typography>
              {
                deleting?
                <div style={{textAlign:'center'}}>
                  <LinearProgress color='secondary' style={{height:6,width:'80%',margin:'auto',marginTop:32}}/>
                  <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Disabling schedule</Typography>
                </div>
                :
                <Typography className={classes.modalBodyText}>
                  {`You are about to remove the schedule for ${state.selectedJob.name}`}
                </Typography>
              }
              <div className={classes.buttons}>
                <Button color='primary' disabled={deleting} onClick={()=>setDisableSchedule()} style={{marginRight:16}}>YES</Button>
                <Button color='secondary' onClick={()=>dispatch({type:'set_disable_schedule_modal_open',disableScheduleModalOpen:false})}>CANCEL</Button>
              </div>
            </Paper>
          }
          <div style={{margin:'0 auto',width:650}}>
            <ModalAlert
              open={alertOpen}
              setOpen={setAlertOpen}
              message={alertMessage}
              type='error'
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default withTheme()(withStyles(styles)(BatchScheduler));