import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import AdditionalPropertyAdder from '../../UI/AdditionalPropertyAdder/AdditionalPropertyAdder'
import SourceDescription from '../../UI/SourceDescription/SourceDescription';

const styles = theme => ({

})

const Details = props => {

  const {
    state,
    fetchList,
    dispatch,
    // isShell,
    // history
  } = props;

  return (
    <div>
      <UpdateInputCerebrum
        url={`/api/workspaces/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        label="workspaces"
        initialValue={state.basicData.description}
        property="description"
        placeholder="Add a description"
        multiline
        fetchList={fetchList}
        collection="data"
        textfieldRows={12}
        data={state.basicData}
        enableMarkDown={true}
        enableWidget={true}
        state={state}
      />

      <SourceDescription
        state={state}
        dispatch={dispatch}
      /> 

      <div style={{ marginTop: 60 }} >
        <AdditionalPropertyAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.additionalPropertiesModalOpen}
          setModalOpen={value=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:value})}
        />
      </div>
     
    </div>


    
  )
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  userName: PropTypes.string,
  tableData: PropTypes.object,
  state:PropTypes.object.isRequired,
  dispatch:PropTypes.func.isRequired
}

export default withStyles(styles)(Details);