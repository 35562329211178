import React from 'react';
import { withStyles} from '@material-ui/core';
import KeyList from '../KeyList/KeyList'
import CreateKey from '../CreateKey/CreateKey'

const styles = theme => ({
  root: {
    // maxWidth:800,
    marginTop:24
  }
})

const Body = props => {

  const {
    classes,
    state,
    dispatch,
    // sessionData,
    // history
  } = props;

  let bodyContent;
  switch (state.tabState) {
    case 0:
      bodyContent = <KeyList state={state} dispatch={dispatch}/>
      break;
    case 1:
      bodyContent = <CreateKey state={state} dispatch={dispatch}/>
      break;
      default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}


export default withStyles(styles)(Body);