import React from 'react';
import { withTheme, FormControl,Select,MenuItem, FormHelperText, Button} from '@material-ui/core';


const PeriodSelector = props => {

  const {
    classes,
    theme,
    state,
    onSelectUsageWindow,
    onPeriodNextClick
  } = props;

  return (
    <div className={classes.block}>
      <div>
        <FormControl>
          <Select
            className={classes.selector}
            style={{width:378}}
            value={state.usageWindow}
            onChange={ onSelectUsageWindow}
          > 
            <MenuItem value={-1}>
              Select a timeframe
            </MenuItem>
            <MenuItem value={30}>
              Used in the last 30 days
            </MenuItem>
            <MenuItem value={90}>
              Used in the last 90 days
            </MenuItem>
            <MenuItem value={180}>
              Used in the last 180 days
            </MenuItem>
            <MenuItem value={365}>
              Used in the last 365 days
            </MenuItem>
          </Select>
          <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>Required</FormHelperText>
        </FormControl> 
      </div>
      <Button style={{marginTop:24}} disabled={state.usageWindow===-1} onClick={()=>onPeriodNextClick()} color='primary' variant="contained" >NEXT</Button>
    </div>
  )
}

export default withTheme()(PeriodSelector);