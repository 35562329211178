import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Stepper, Step, StepLabel, Typography, StepContent} from '@material-ui/core';
import SelectLoadFrequency from '../../UI/Scheduler/SelectLoadFrequency';
import SelectLoadTime from '../../UI/Scheduler/SelectLoadTime';
import SelectLoadDay from '../../UI/Scheduler/SelectLoadDay';
// import SelectLoadSchedule from './SelectLoadSchedule';
import FinaliseSchedule from './FinaliseSchedule';
import CustomCron from '../../UI/Scheduler/CustomCron';
import { dayOfWeekAsString } from '../../UI/Scheduler/utils';
import { checkIsValidCron } from '../../../utilities';


const styles = theme => ({
  stepper:{
    marginBottom:120,
    backgroundColor:theme.palette.background.main
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
  banner: {
    flexGrow:1,
    backgroundColor: theme.palette.inputBackground.main,
    border:`1px solid ${theme.palette.border.main}`,
    minWidth:700,
    minHeight:52,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    padding:'0px 4px'
  },
  bannerText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    marginLeft:16,
    marginRight:16
  }
})

const SourceScheduler = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    history,
    removePopStateBehaviour,
    forceOnSave
  } = props;
  
  const [activeStep, setActiveStep] = useState(0)
  const [frequency, setFrequency] = useState('none')
  const [loadDay, setLoadDay] = useState(-1)
  const [loadTime, setLoadTime] = useState()
  // const [loadSchedule, setLoadSchedule] = useState('none')
   // eslint-disable-next-line
  const [loadSchedule, setLoadSchedule] = useState('first')
  const [customCron, setCustomCron] = useState('')

  const steps = [`Select a load frequency${frequency!=='none'?`: ${frequency}`:''}`]

  const selectTimeStr = `Select a load time${loadTime?`: ${loadTime.format('HH:mm')}`:``}`
  const selectDayStr = `Select a load day${loadDay!==-1?`: ${dayOfWeekAsString(loadDay)}`:""}`
  // const selectScheduleStr = `Select a load schedule${loadSchedule!=='none'?`: ${loadSchedule} of the month`:""}`
  const cronScheduleStr = `Add cron schedule (UTC)${customCron && activeStep>1?`: ${customCron}`:""}`

  if(frequency==='daily'){
    steps.push(selectTimeStr)
  }
  if(frequency==='weekly'){
    steps.push(selectDayStr,selectTimeStr)
  }
  if(frequency==='monthly'){
    // steps.push(selectDayStr,selectScheduleStr,selectTimeStr)
    steps.push(selectDayStr,selectTimeStr)
  }
  if(frequency==='custom'){
    steps.push(cronScheduleStr)
  }
  steps.push('Finalise schedule')

  useEffect(()=>{
    if(frequency==='none'){
      setActiveStep(0)
      setLoadDay(-1)
      setLoadTime(undefined)
      // setLoadSchedule('none')
      setCustomCron('')
    }
  },[frequency])
  
  useEffect(()=>{
    if(!removePopStateBehaviour){
      window.onpopstate = () => {
        dispatch && dispatch({type:'set_tab_state',tabState:0})
      }
      history && history.push(`/admin/sources`)
    }
   
    return () => {
      if(removePopStateBehaviour)return;
      window.onpopstate = undefined;
    }
  // eslint-disable-next-line
  },[])

  const getStepContent = index => {
    switch(index){
      case 0:
        return <SelectLoadFrequency frequency={frequency} setFrequency={setFrequency} setActiveStep={setActiveStep}/>
      case 1:
        if(frequency==='daily'){
          return <SelectLoadTime loadTime={loadTime} setLoadTime={setLoadTime} activeStep={activeStep} setActiveStep={setActiveStep} />
        }
        if(frequency==='weekly' || frequency==='monthly'){
          return <SelectLoadDay loadDay={loadDay} setLoadDay={setLoadDay} activeStep={activeStep} setActiveStep={setActiveStep} />
        }
        if(frequency==='custom'){
          return <CustomCron customCron={customCron} setCustomCron={setCustomCron}  activeStep={activeStep} setActiveStep={setActiveStep}/>
        }
        return <></>
      case 2:
        if(frequency==='daily'){
          return <FinaliseSchedule forceOnSave={forceOnSave} dispatch={dispatch} state={state} frequency={frequency} loadTime={loadTime} loadDay={loadDay} loadSchedule={loadSchedule} customCron={customCron}/>
        }
        if(frequency==='weekly'){
          return <SelectLoadTime loadTime={loadTime} setLoadTime={setLoadTime} activeStep={activeStep} setActiveStep={setActiveStep} />
        }
        // if(frequency==='monthly'){
        //   return <SelectLoadSchedule loadSchedule={loadSchedule} setLoadSchedule={setLoadSchedule} activeStep={activeStep} setActiveStep={setActiveStep} />
        // }
        if(frequency==='monthly'){
          return <SelectLoadTime loadTime={loadTime} setLoadTime={setLoadTime} activeStep={activeStep} setActiveStep={setActiveStep} />
        }
        if(frequency==='custom'){
          return <FinaliseSchedule forceOnSave={forceOnSave} dispatch={dispatch} state={state} frequency={frequency} loadTime={loadTime} loadDay={loadDay} loadSchedule={loadSchedule} customCron={customCron}/>
        }
        return <></>
      case 3:
        if(frequency==='weekly'){
          return <FinaliseSchedule forceOnSave={forceOnSave} dispatch={dispatch} state={state} frequency={frequency} loadTime={loadTime} loadDay={loadDay} loadSchedule={loadSchedule} customCron={customCron}/>
        }

        if(frequency==='monthly'){
          return <FinaliseSchedule forceOnSave={forceOnSave} dispatch={dispatch} state={state} frequency={frequency} loadTime={loadTime} loadDay={loadDay} loadSchedule={loadSchedule} customCron={customCron}/>
        }
        // if(frequency==='monthly'){
        //   return <SelectLoadTime loadTime={loadTime} setLoadTime={setLoadTime} activeStep={activeStep} setActiveStep={setActiveStep} />
        // }
        return <></>
      // case 4:
      //   if(frequency==='monthly'){
      //     return <FinaliseSchedule forceOnSave={forceOnSave} dispatch={dispatch} state={state} frequency={frequency} loadTime={loadTime} loadDay={loadDay} loadSchedule={loadSchedule} customCron={customCron}/>
      //   }
      //   return <></>
      default:
    }
  }

  const labelClickable = index => {
    if(index===0)return true;
    if(index===1)return frequency!=='none';
    if(index===2){
      if(frequency==='daily')return loadTime!==undefined;
      if(frequency==='weekly')return loadDay!==-1;
      if(frequency==='monthly')return loadDay!==-1;
      if(frequency==='custom')return checkIsValidCron(customCron);
      return false;
    }
    if(index===3){
      if(frequency==='daily')return true;
      if(frequency==='weekly')return loadTime!==undefined;
      // if(frequency==='monthly')return loadSchedule!=='none';
      if(frequency==='monthly')return loadTime!==undefined;
      return false;
    }
    if(index===4){
      if(frequency==='daily')return false;
      if(frequency==='weekly')return false;
      // if(frequency==='monthly')return loadTime!==undefined;
      return false;
    }
  }

  const onLabelClick = index => {
    if(labelClickable(index))setActiveStep(index)
  }

  return (
    <div className={classes.root}>
      <Typography style={{fontSize:20,marginBottom:6}}>
        {state.selectedSource.scheduled_flag?'UPDATE SCHEDULE':'SET SCHEDULE'}
      </Typography>
      <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper} >
        {steps.map((label, index) => (
          <Step key={index} >
            <StepLabel className={classes.step} style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}}>
              <Typography color='primary' style={{color:activeStep<index?theme.palette.primaryText.light:theme.palette.primaryText.main,fontSize:13.75,marginLeft:6}}>{label}</Typography> 
            </StepLabel>
            <StepContent style={{width:600,paddingTop:12,paddingLeft:25}}>
             {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

export default withTheme()(withStyles(styles)(SourceScheduler));