import React from 'react';
import { withStyles} from '@material-ui/core';
import CollectionList from '../CollectionList/CollectionList'
import Import from '../Import/Import';

const styles = theme => ({
})

function Body(props) {

  const {
    history,
    state,
    tabOptions,
    dispatch,
    sessionData,
  } = props;

  let bodyContent;


  switch(tabOptions[state.tabState]){
    case 'IMPORT':
      bodyContent = (
        <Import state={state} dispatch={dispatch} history={history} sessionData={sessionData}/>
      )
      break;
    default:
      bodyContent=(
        <CollectionList
          key={state.category}
          history={history}
          state={state}
          dispatch={dispatch}
          sessionData={sessionData}
          tabOptions={tabOptions}
        />
      )
  }

  return <div>{bodyContent}</div>;
}

export default withStyles(styles)(Body);