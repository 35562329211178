import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Modal, Paper, CircularProgress, Typography, Stepper, Step, StepLabel, Button, InputBase} from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../axios-cerebrum'
import { getIconLabelByIntegration, onClickResultItem } from '../../../utilities';
import SimpleResultItem from '../SearchResults/SimpleResultItem'
import ModalAlert from '../ModalAlert/ModalAlert';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  title: {
    fontSize: 20,
    fontWeight: '500',
    color:theme.palette.header.main,
  },
  subTitle:{
    marginTop:4,
    color:theme.palette.primaryText.light,
    fontSize:12,
    marginBottom:24
  },
  selector:{
    ...theme.components.header,
    '& div div':{
      padding:'4px 24px 4px 8px',
      fontSize:20
    },
    '& svg':{
      color:theme.palette.header.main
    },
    border:`1px solid ${theme.palette.header.main}`,
    color:theme.palette.header.main,
    borderRadius:4,
    fontSize:20,
  },
  stepper:{
    paddingTop:0,
    paddingLeft:0,
    paddingRight:24,
    backgroundColor:theme.palette.background.main,
    // paddingLeft:0
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
  subHeader:{
    fontSize:16,
    color:theme.palette.primary.main,
    marginBottom:16
  },
  customScroll:{
    ...theme.components.customScroll
  },
  inputBase:{
    ...theme.components.inputBase,
    width:'100%',
    '& textarea':{
      padding:'8px 16px'
    }
  }
})

function IntegrationIssueInput(props) {

  const {
    history,
    theme,
    classes,
    dqModalOpen,
    setDqModalOpen,
    id,
    state,
    dispatch,
    integrations,
    presetData,
    onSuccess
  } = props;

  const [mode, setMode] = useState('create') // create or link
  const [step, setStep] = useState(0)
  const [selectedIntegration, setSelectedIntegration] = useState()
  const [title, setTitle] = useState((presetData && presetData.title) || '')
  const [creating, setCreating] = useState(false)
  const [createdIssue, setCreatedIssue] = useState()
  const [issueLinked, setIssueLinked] = useState()

  const [alertOpen, setAlertOpen] = useState();
  const [alertMessage, setAlertMessage] = useState('')

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  useEffect(()=>{
    if(!dqModalOpen){
      setMode('create')
      setStep(0)
      setSelectedIntegration()
      setTitle('')
      setCreating(false)
      setCreatedIssue()
    }
  },[dqModalOpen])

  const onSelectIntegration = integration => {
    setSelectedIntegration(integration)
    setStep(1)
  }

  const onIssueLinked = (issue) => {
    if(state && state.issueData && dispatch){
      dispatch({
        type:'set_issue_data',
        issueData:{
          ...state.issueData,
          total:state.issueData.total+1,
          items:[
            {
              issue_type_txt:issue.type,
              issue_status_txt:issue.status,
              name_txt:'ISSUE-' + issue.display_id + ': ' + issue.name,
              description:issue.description,
              id:issue.id,
              issue_severity:issue.severity,
              object_type_txt:'ISSUE'
            },
            ...state.issueData.items,
          ]
        }
      })
    }
  }

  const onCreateIssue = async () => {
    setCreating(true);
    let requestBody = {
      "name": title,
      "description":'',
      severity:'SET_BY_EXTERNAL_SOURCE',
      status:'OPEN',
      type:'SET_BY_EXTERNAL_SOURCE',
      source_id:selectedIntegration.id
    }
    let issueObj = createdIssue;
    let error = false;
    let isNewIssue = issueObj && issueObj.name!==title
    if(!issueObj || isNewIssue){
      await axiosCerebrum
        .post(`/api/issues`,requestBody)
        .then(response=>{
          issueObj = response.data
          setCreatedIssue(response.data)
          onSuccess && onSuccess({issue:issueObj})
        })
        .catch(err=>{
          console.log(err);
          setCreating(false);
          error = true;
        })
    }
    if(error){
      setAlertOpen(true);
      setAlertMessage('Error occurred creating issue, please try again')
      return;
    }
    if(!issueLinked || isNewIssue){
      await axiosCerebrum
        .put(`/api/issues/${issueObj.id}/related?object_id=${id}&relationship=IMPACTS`)
        .then(()=>{ 
          setIssueLinked(true)
          onIssueLinked(issueObj)
        })
        .catch(err=>{
          error = true;
          console.log(err);
          setCreating(false);
        })
    }
    if(error){
      setAlertOpen(true);
      setAlertMessage('Error occurred creating issue, please try again')
      return;
    }
    await axiosCerebrum
      .get(
        `/api/sources/${selectedIntegration.id}/deeplink`,
        {params:{
          summary:title,
          object_link:`${document.location.protocol}//${document.location.host}${onClickResultItem({label:'issue',id:issueObj.id,urlOnly:true})}`
        }}
      )
      .then(url=>{
        window.open(url.data.link,'_blank')
        setStep(2)
        setCreating(false)
      })
      .catch(error=>{
        console.log(error);
        setCreating(false);
        setAlertOpen(true);
        setAlertMessage('Error occurred creating redirect link, please try again')
      })
  }

  const getStepContent = (index) => {
    if(index===0){
      return (
        <div className={classes.customScroll} style={{maxHeight:'40vh',overflow:'auto',paddingRight:24}}>
          <Typography className={classes.subHeader}>Select a source to add your issue</Typography>
          {
            integrations.map(el=>(
              <SimpleResultItem
                item={el}
                label={getIconLabelByIntegration(el)}
                titleColour={theme.palette.primaryText.main}
                title={el.name}
                hideRight={true}
                showUnderline
                onClick={()=>onSelectIntegration(el)}
              />
            ))
          }
        </div>
      )
    }
    if(index===1){
      return (
        <div className={classes.customScroll} style={{maxHeight:'40vh',overflow:'auto',paddingRight:24}}>
          <Typography className={classes.subHeader}>Add a summary for the issue</Typography>
          <InputBase
            value={title}
            onChange={event=>setTitle(event.target.value)}
            className={classes.inputBase}
            inputProps={{
              'data-test-id':'issue-modal-summary'
            }}
            multiline
            rows={3}
            placeholder={`The summary will be added to the summary field in the ${selectedIntegration.name} ticket`}
          />
        </div>
      )
    }
    if(index===2){
      return (
        <div className={classes.customScroll} style={{maxHeight:'40vh',overflow:'auto',paddingRight:24}}>
          <Typography className={classes.subHeader}>A new issue was created and will be synchonised with the {selectedIntegration.name} ticket</Typography>
          <SimpleResultItem
            item={createdIssue}
            label={'issue'}
            titleColour={theme.palette.primaryText.main}
            title={createdIssue.name}
            hideRight={true}
            showUnderline
            onClick={()=>onClickResultItem({label:'issue',item:createdIssue,id:createdIssue.id,history})}
          />
        </div>
      )
    }
  }

  const createModeInput = (
    <div>
      <Stepper activeStep={step}  className={classes.stepper}  alternativeLabel>
        {['Select an issue source','Add issue summary',`Add details in ${selectedIntegration?selectedIntegration.source_template.name:'tool'}`].map((label, index) => (
          <Step key={index} className={classes.step}>
            <StepLabel>
              <Typography color='primary' style={{color:step<index?theme.palette.primaryText.light:theme.palette.primary.main,fontSize:16,marginLeft:6}}>
                {label}
              </Typography> 
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {getStepContent(step)}
      <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginRight:24,marginBottom:-12,marginTop:24}}>
        {
          step===1 && 
          <Button color='primary' data-test-id="add-issue-back-button" style={{marginRight:8}} disabled={creating} onClick={()=>{setStep(0);setSelectedIntegration()}}>
            BACK
          </Button>
        }
        {
          step===1 && !creating &&
          <Button color='primary' data-test-id="add-issue-next-button" style={{marginRight:8}} disabled={title.trim()===''} onClick={()=>{onCreateIssue()}}>
            NEXT
          </Button>
        }
        {
          step===1 && creating &&
          <div style={{width:64,marginRight:8,textAlign:'center'}}>
            <CircularProgress color='secondary' style={{width:24,height:24}}/>
          </div>
        }
        <Button 
          color='secondary' 
          onClick={()=>{
            setDqModalOpen(false)
            if(step===2){
              sendAlert({
                message:'Issue successfully created',
                type:'info'
              })
            }
        }}>
          {step!==2?'CANCEL':'CLOSE'}
        </Button>
      </div>
    </div>
    
  )

  return (
    <Modal 
      open={dqModalOpen} 
      onClose={()=>setDqModalOpen(false)}
      disableBackdropClick={true}
    > 
      <div style={{outline:'none',width:'max-content',margin:'7vh auto 0 auto'}}>
        <Paper style={{width:720,padding:24,paddingRight:0,background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
          <div style={{display:'flex',alignItems:'center'}}>
            <Typography className={classes.title}>Add Issue</Typography>
          </div>
          <Typography className={classes.subTitle}>Add a new issue in an external source</Typography>
          {
            mode==='create' && 
            createModeInput
          }
        </Paper>
        <ModalAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          type='error'
        />
      </div>
    </Modal>
  )
}

IntegrationIssueInput.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  dqModalOpen: PropTypes.bool.isRequired,
  setDqModalOpen: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  issueFetch: PropTypes.func.isRequired
}

export default withTheme()(withStyles(styles)(IntegrationIssueInput));