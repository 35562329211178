import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles, CircularProgress, Select, MenuItem, Button} from '@material-ui/core';
import axiosSolr from '../../../axios-solr';
import SummaryTile from '../../UI/DQInsights/UI/SummaryTile';
import BasicSearch from '../../../containers/BasicSearch/BasicSearch';
import { defaultFilterMap } from '../../BasicSearch/MainSearch/Utils/Utils';
import timezoneDetect from 'jstimezonedetect';
import TrendChart from '../Components/TrendChart';
import { getJobResult, formatNumber } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';

const styles = theme => ({
  root: {
  },
  titleSelector:{
    ...theme.components.titleSelector,
    marginRight:8,
    maxWidth:'80vw',
  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  caption:{
    fontSize:12,
    marginTop:4,
    color:theme.palette.primaryText.light,
    marginBottom:24,
    whiteSpace:'pre-wrap'
  },
})

const BySource = props => {

  const {
    classes,
    state,
    dispatch,
    history
  } = props;

  const getScoreBySource = () => {
    dispatch({type:'set_dq_by_sources',dqBySourcesLoading:true})
    axiosSolr
      .get(
        `/solr/data_quality_test_result/select`,{params:{
          q:"*",
          fq:"-object_source_type_srt:(DBT_CLOUD OR DBT_CORE OR GREAT_EXPECTATIONS)",
          rows:0,
          'json.facet':{
            'source':{
              type:'terms',
              field:'object_source_srt',
              sort:'index',
              limit:200,
              'facet':{
                'dimensions':{
                  type:'terms',
                  field:'data_quality_dimension_srt',
                  limit:100,
                  mincount:0,
                  "facet":{
                    "avg":"avg(last_data_quality_score_srt)",
                  }
                },
                'overall_avg':"avg(last_data_quality_score_srt)",
                'object_type':{
                  type:'terms',
                  field:'object_type_srt',
                  sort:'index',
                  limit:100,
                }
              }
            }
          }
        }}
      )
      .then(response=>{
        let sources = []
        response.data.facets?.source?.buckets?.forEach(el=>{
          let data = {
            source:el.val.toUpperCase(),
            tiles:[],
            objectTypes:(el.object_type?.buckets || []).map(el=>el.val.toUpperCase()),
          }
          data.tiles.push({
            name:'overall',
            score:el.overall_avg,
            count:el.count,
          })
          el.dimensions?.buckets?.sort((a,b)=>a.val<b.val?-1:1).forEach(d=>{
            data.tiles.push({
              name:d.val,
              score:d.avg,
              count:d.count,
            })
          })
          sources.push(data)
        })
        dispatch({type:'set_by_source_selected_dimension',bySourceSelectedDimension:'overall'})
        dispatch({type:'set_dq_by_sources',dqBySources:sources})
        if(sources.length>0){
          dispatch({type:'set_selected_source',selectedSource:sources[0].source})
          loadTrendChart({source_name:sources[0].source,dimension:'overall'})
          if(sources[0].objectTypes.includes("TABLE")){
            dispatch({type:'set_by_source_selected_object',bySourceSelectedObject:"TABLE"})
          }else{
            dispatch({type:'set_by_source_selected_object',bySourceSelectedObject:sources[0].objectTypes[0]})
          }
        }
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_dq_by_sources',dqBySourcesError:true})
      })
  }

  
  const loadTrendChart = ({source_name, dimension=state.bySourceSelectedDimension, period=state.bySourcePeriod}) => {
    let cacheID = `${source_name}_${dimension}_${period}`
    if(state.bySourceTrendCache[cacheID]){
      dispatch({type:'set_by_source_trend',bySourceTrend:state.bySourceTrendCache[cacheID]})
      return; 
    }

    let source = state.dbList.find(d=>d.name.toUpperCase()===source_name.toUpperCase())
    if(!source){
      dispatch({type:'set_by_source_trend',bySourceTrendError:true})
      return;
    }
    dispatch({type:'set_by_source_trend',bySourceTrendLoading:true})
    axiosCerebrum
      .post(
        '/api/platformbatches/0',
        {
          "adhoc_args":{
            "job": "_0060_metrics",
            'agg_method':['AVG'],
            "tz": timezoneDetect.determine().name(),
            'object_id':source.host_ids[0],
            'days':period,
            "metric_type":`DATA_QUALITY_${dimension.toUpperCase()}_SCORE`
          }
        }
      )
      .then(response=>{
        let id = response.data.id;
        getJobResult(
          id,
          data=>{
            dispatch({type:'set_by_source_trend',bySourceTrend:data.result.data})
            dispatch({type:'set_by_source_trend_cache',bySourceTrendCache:{...state.bySourceTrendCache,[cacheID]:data.result.data}})
          },
          ()=>{
            dispatch({type:'set_by_source_trend',bySourceTrendError:true})
          }
        )
      })
      .catch(error=>{
        console.log(error);
        dispatch({type:'set_by_source_trend',bySourceTrendError:true})
      })
  }


  useEffect(()=>{
    if(!state.dqBySources && !state.dqBySourcesLoading){
      getScoreBySource();
    }
  // eslint-disable-next-line
  },[])

  let propFilter =`source_srt:${state.selectedSource} AND active_srt:YES AND -dq_score_overall_srt:NO DQ SCORE OVERALL`
  let propColumn = 'dq_score_overall_srt,trust,usage_srt,owner_msrt,stewards_msrt'
  let propSort = 'dq_score_overall_srt desc'

  const sectionHeader = (
    <div style={{display:'flex',alignItems:'center',marginBottom:24}}>
      <Select
        className={classes.titleSelector}
        value={state.selectedSource}
        disableUnderline
        onChange={event=>{
          dispatch({type:'set_selected_source',selectedSource:event.target.value})
          dispatch({type:'set_by_source_selected_dimension',bySourceSelectedDimension:'overall'})
          dispatch({type:'set_by_source_selected_object',bySourceSelectedObject:state.dqBySources.find(el=>el.source===event.target.value).objectTypes[0]})
          loadTrendChart({source_name:event.target.value, dimension:'overall'})
        }}
      >
        {
          state.dqBySources?.map((el,i)=>{
            return (
              <MenuItem value={el.source}>
                {el.source}
              </MenuItem>
            )
          })
        }
      </Select>
      <Typography className={classes.header}>
        DATA QUALITY DASHBOARD
      </Typography>
    </div> 
  )

  const getBody = tabState => {
    if(tabState===0){
      return (
        <>
          {sectionHeader}
          <Typography className={classes.header}>
            LATEST {state.selectedSource.toUpperCase()} DQ SCORE BY SOURCE
          </Typography>
          <Typography className={classes.caption}>
            Average Data quality score for all assets linked to this Source over the last 120 days
          </Typography>
          <div style={{display:'flex',marginBottom:40,flexWrap:'wrap'}}>
            {
              state.dqBySources.find(el=>el.source===state.selectedSource).tiles.map((el,i)=>{
                return(
                  <SummaryTile
                    key={i}
                    id={el.name}
                    name={el.name}
                    value={el.score}
                    testCount={el.count}
                    footer={`From ${formatNumber(el.count)} test runs`}
                    onClick={el.score && !state.bySourceTrendLoading?()=>{
                      dispatch({type:'set_by_source_selected_dimension',bySourceSelectedDimension:el.name})
                      loadTrendChart({source_name:state.selectedSource,dimension:el.name})
                    }:undefined}
                    isSelected={el.name===state.bySourceSelectedDimension}
                  />
                )
              })
            }
          </div>

          <div style={{marginBottom:40}}>
            <Typography className={classes.header}>
              {state.selectedSource.toUpperCase()} DQ SCORE OVER
              <Select
                className={classes.titleSelector}
                style={{marginLeft:12}}
                value={state.bySourcePeriod}
                disableUnderline
                onChange={event=>{
                  dispatch({type:'set_by_source_period',bySourcePeriod:event.target.value})
                  loadTrendChart({source_name:state.selectedSource, dimension:state.bySourceSelectedDimension, period:event.target.value})
                }}
              >
                {
                  [30,90,365].map(el=>(
                    <MenuItem disabled={state.bySourceTrendLoading} className={classes.menuItem} value={el}>
                      {el} DAYS
                    </MenuItem>
                  ))
                }
              </Select>
            </Typography>
            <Typography className={classes.caption}>
              Average DQ score for {state.selectedSource} over the last {state.bySourcePeriod} days
            </Typography>
            {
              state.bySourceTrendLoading &&
              <div style={{textAlign:'center',height:140}}>
                <CircularProgress color='secondary'/>
              </div>
            }
            {
              state.bySourceTrendError &&
              <Typography>Error occurred loading chart</Typography>
            }
            {
              state.bySourceTrend && 
              <TrendChart
                data={state.bySourceTrend}
              />
            }
          </div>
        </>
      )
    }
    if(tabState===1){
      return (
        <>
          {sectionHeader}
          <div style={{display:'flex',alignItems:'center',marginBottom:4}}>
            <Typography className={classes.header} style={{marginRight:10}}>
              BREAKDOWN {state.selectedSource} DQ SCORE BY 
            </Typography>
            <Select
              className={classes.titleSelector}
              value={state.bySourceSelectedObject}
              disableUnderline
              onChange={event=>dispatch({type:'set_by_source_selected_object',bySourceSelectedObject:event.target.value})}
            >
              {
                state.dqBySources.find(el=>el.source===state.selectedSource).objectTypes.map((el,i)=>{
                  return (
                    <MenuItem value={el}>
                      {el.replace(/_/g,' ')}
                    </MenuItem>
                  )
                })
              }
            </Select>
            <div style={{flexGrow:1}}></div>
            <Button 
              color='primary' 
              variant="outlined" 
              onClick={()=>history.push(`/basic_search?query=*&object=${state.bySourceSelectedObject}&presetFilter=${propFilter}&presetColumn=${propColumn}`)}
            >
              OPEN IN SEARCH
            </Button>
          </div> 
          <Typography className={classes.caption}>
            DQ score for {state.bySourceSelectedObject} linked to this Source. {'\n'}
            DQ score for {state.bySourceSelectedObject} are not correlated to the DQ scores in the summary.
          </Typography>
          <BasicSearch
            key={state.bySourceSelectedObject+state.selectedSource}
            customID={'bySourceSearch'}
            initialView={'main_search'}
            history={history} 
            alwaysOpenNewTab={true}
            removeContainerStyle={true}
            propObjectType={state.bySourceSelectedObject}
            propQuery={'*'} 
            propFilter={propFilter}
            propColumn={propColumn}
            propSort={propSort}
            propSelectedFilters={defaultFilterMap[state.bySourceSelectedObject]?.filter(el=>!['source_type_srt','source_srt'].includes(el)).join(',')}
            resultItemVariant={"simplified"}
            hiddenComponents={['columnSelector','filterEdit','listTitle','searchBar','header','tab','cartButton','downloadButton']}
          />
        </>
      )
    }
  }

  return (
    <div className={classes.root}>
      {
        state.dqBySourcesLoading && 
        <CircularProgress color='secondary'/>
      }
      {
        state.dqBySourcesError && 
        <Typography>Error occurred loading summary</Typography>
      }
      {
        state.dqBySources?.length===0 && 
        <Typography>No data quality scores available</Typography>
      }
      {
        state.dqBySources?.length>0 && state.selectedSource &&
        <div>
          <div style={{float:'left',position:'sticky',top:186}}>
            <VerticalTabBar
              tabOptions={['SUMMARY','DETAILS']}
              tabState={state.bySourceTabState}
              setTabState={value=>dispatch({type:'set_by_source_tab_state',bySourceTabState:value})}
            />
          </div>
          <div style={{marginLeft:280}}>
            {getBody(state.bySourceTabState)}
          </div>
        </div>
      }
    </div>
  )
}

BySource.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sessionData: PropTypes.object
}

export default withStyles(styles)(BySource);