import React, {  useState } from 'react';
import 
{ withTheme, withStyles, Modal, Paper, Typography, Button, LinearProgress} 
from '@material-ui/core';
import ModalAlert from '../../UI/ModalAlert/ModalAlert'
import axiosCerebrum from '../../../axios-cerebrum'
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root:{
    width:'100vw',
    height:'100vh',
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'center',
  },
  modalBody:{
    flexGrow:1,
    background:theme.palette.background.main,
    padding: '24px 40px',
    height: 570,
    width:537
  },
  title: {
    fontSize: 20,
    fontWeight: '500',
    color:theme.palette.primaryText.main,
    marginBottom: 16,
  },
  subTitle: {
    fontSize:16,
    marginBottom: 8,
    whiteSpace:'pre-wrap',
    color:theme.palette.primaryText.light
  },
  buttons: {
    flexGrow:0,
    backgroundColor: theme.palette.background.main,
    paddingTop: 12,
    marginRight:-16,
    marginTop:5,
    width:'100%',
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
})

function DeleteModal(props) {

  const {
    classes,
    theme,
    history,
    modalOpen,
    setModalOpen,
    state
  } = props;

  const [disableButton, setDisableButton] = useState(false); 
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const onCancel = () => {setModalOpen(false)}

  const {
    sendAlert
  } = useAlert({})

  const onDelete = () => {
    setDisableButton(true)
    axiosCerebrum
      .delete(`/api/tags/${state.basicData.id}`)
      .then(response=>{
        sendAlert({type:'info',message:`Tag "${state.basicData.name}" successfully deleted`})
        history.goBack();
      })
      .catch(error=>{
        console.log(error);
        setDisableButton(false)
        setAlertMessage(`Error occurred deleting tag, please try again`)
        setAlertOpen(true)
      })
  }

  return (
    <div >
      <Modal 
        className={classes.root}
        open={modalOpen} 
        onClose={()=>onCancel()}
        disableBackdropClick={true}
      > 
        <div style={{width:400,marginTop:'30vh',outline:'none'}}>
          <Paper style={{width:350,padding:24,paddingBottom:8,background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
            <Typography className={classes.title}>Delete this Tag</Typography>
            {
              disableButton?
              <div style={{textAlign:'center'}}>
                <LinearProgress color='secondary' style={{height:6,width:'80%',margin:'auto',marginTop:32}}/>
                <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Deletion in progress</Typography>
              </div>
              :
              <Typography className={classes.subTitle}>{`Are you sure?\nThis will permanently remove this tag`}</Typography>
            }
            <div className={classes.buttons} align='right'>
              <Button color="primary" classes={{disabled:classes.disabledButton}} style={{width:80}} onClick={onDelete} disabled={disableButton}>
                {'DELETE'}
              </Button>
              <Button color="secondary" classes={{disabled:classes.disabledButton}} style={{width:80, marginLeft: 8}}  onClick={onCancel} disabled={disableButton}>
                CLOSE
              </Button>
            </div>
          </Paper>

          <ModalAlert
            open={alertOpen}
            setOpen={setAlertOpen}
            message={alertMessage}
            type='error'
          />
        </div>
      </Modal>
    </div>
  )
}
export default withTheme()(withStyles(styles)(DeleteModal));