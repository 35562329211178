import React, { useRef, useState, useEffect } from 'react';
import { withTheme, withStyles, Typography,Button, Modal, LinearProgress} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import ModalAlert from '../../UI/ModalAlert/ModalAlert'
import {useDispatch,} from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  modal:{
    width:'100vw',
    height:'100vh',
    display:'flex',
    alignItems:'flex-start',
    justifyContent:"center"
  },
  deleteModal: {
    width: 500,
    padding: 24,
    paddingBottom:8,
    backgroundColor: theme.palette.background.main,
    borderRadius: 5,
    border:`1px solid ${theme.palette.border.main}`
  },
  modalTitle:{
    color:theme.palette.primaryText.main,
    fontSize: '19.64px',
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: '26px',
    marginBottom:'12px'
  },
  normalText:{
    color:theme.palette.primaryText.main
  },

  deleteSubTitle:{
    color:theme.palette.primaryText.light,
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
},
})

const DeleteModal = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    history
  } = props;

  const [disableButton, setDisablebutton] = useState(false);

  const reduxDispatch = useDispatch()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`issue-delete-modal`,
    isCancelledRef,
    disableStateCache:true
  })

  useEffect(()=>{ 
    return () => {
      isCancelledRef.current = true
    }
  },[])


  const onDelete = () => {
    setDisablebutton(true);
    axiosCerebrum
      .delete(`/api/issues/${state.basicData.id}`)
      .then(response=>{
        sendAlert({type:'info',message:'Issue successfully deleted'})
        dispatch({type:'set_delete_modal_open',deleteModalOpen:false})
        setDisablebutton(false);
        reduxDispatch({
          type:actions.REMOVE_PAGE_CACHE,
          data:window.location.href
        })
        history.goBack();
      })
      .catch(error=>{
        dispatch({
          type:'set_link_alert',
          alertOpen:true,
          alertType:'error',
          alertMessage:`Error deleting the issue. Please try again. If the problem persist please contact the Admin`
        })
        setDisablebutton(false);
      })
  }

  return (
    <Modal className={classes.modal} onClose={()=>dispatch({type:'set_delete_modal_open',deleteModalOpen:false})} open={state.deleteModalOpen} disableBackdropClick disableAutoFocus>
      <div style={{marginTop:'15vh',width:550}}>
        <div className={classes.deleteModal}>
          <Typography className = {classes.modalTitle}>Delete this issue</Typography>
          {
            disableButton ?
            <div style={{textAlign:'center'}}>
              <LinearProgress color='secondary' style={{height:6,width:'80%',margin:'auto',marginTop:32}}/>
              <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Deletion in progress</Typography>
            </div>
            :
            <div>
              <Typography className = {classes.deleteSubTitle}>{`Are you sure?`}</Typography>
              <Typography className = {classes.deleteSubTitle} >{`This will permanently remove this issue`}</Typography>
            </div>
          }
          <div align="end" style={{marginTop:24}}>
            <Button style={{ marginRight: '2rem', width: '4rem'}} color='primary' onClick={onDelete} disabled={disableButton}>
              DELETE
            </Button>
            <Button color="secondary" style={{width: '4rem'}} onClick={()=>dispatch({type:'set_delete_modal_open',deleteModalOpen:false})} disabled={disableButton}>
              CANCEL
            </Button>
          </div>
        </div>
        {
          state.deleteModalOpen && 
          <ModalAlert
            setOpen={open=>dispatch({type:'set_alert_open',alertOpen:open})}
            open={state.alertOpen}
            message={state.alertMessage}
            type={state.alertType}
          />
        }
      </div>
    </Modal>
  )
}


export default withTheme()(withStyles(styles)(DeleteModal));