import React from 'react';
import { withStyles} from '@material-ui/core';
import Details from '../Details/Details'
import Instances from '../Instances/Instances'
import EditableInstances from '../EditableInstances.js/EditableInstances';
// import Map from '../Map/Map';
// import { collectionIds } from '../../../utilities';
import DomainMap from '../DomainMap/DomainMap';

const styles = theme => ({

})

function Body(props) {

  const {
    classes,
    history,
    state,
    dispatch,
    instanceName,
    editable,
    allowTreeList,
    tabOptions,
  } = props;



  let bodyContent
  switch (tabOptions[state.tabState]) {
    case 'DETAILS':
      bodyContent = (
        <div className={classes.root}>
          <Details state={state}/>
          {
            state.instanceView==='list' || !allowTreeList?
            <Instances  state={state} dispatch={dispatch} history={history} instanceName={instanceName} allowTreeList={allowTreeList} editable={editable}/>
            :
            <EditableInstances state={state} dispatch={dispatch} history={history} instanceName={instanceName} editable={editable} />
          }
          {/* {bodyContent} */}
        </div>
      );
      break;
    case 'MAP':
      // if(state.basicData.id===collectionIds.domain){
        bodyContent = (
          <DomainMap state={state} dispatch={dispatch} history={history} />
        )
      // }else{
      //   bodyContent = (
      //     <Map state={state} dispatch={dispatch} history={history}/>
      //   )
      // }
      break;
    default:
  }

  return (
    bodyContent
  )
}

export default withStyles(styles)(Body);