import React, { useEffect, useReducer } from 'react';
import { withStyles } from '@material-ui/core';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/MyAlerts/Body/Body';
import 'url-search-params-polyfill';
import { addHistory } from '../../HistoryManager';
import PropTypes from 'prop-types';
import useGetCerebrum from '../../hooks/useGetCerebrum';
import { checkEmailConfigured } from '../../permissionChecker';

const styles = theme => ({
});

const initialState = {
  alertSort:'ALPHABETICAL',
  tabState:0
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState
      }
    case 'set_alert_list':
      return {
        ...state,
        alertList: action.alertList,
        alertListLoading: action.alertListLoading,
        alertListError: action.alertListError,
      }
    case 'set_sources':
      return {
        ...state,
        sources:action.sources
      }
    case 'set_alert_sort':
      return {
        ...state,
        alertSort:action.alertSort
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const MyAlerts = props => {

  const {
    history,
    sessionData,
    variant
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(()=>{
    if(variant==='admin'){
      addHistory({url:window.location.pathname, iconLabel:'alert', title: 'Alerts', subTitle:'Alerts',type:'application'})
    }else{
      addHistory({url:window.location.pathname, iconLabel:'alert', title: 'My Alerts', subTitle:'My Alerts',type:'application'})
    }
    // eslint-disable-next-line
  },[])

  const {
    data:sourceData,
  } = useGetCerebrum({
    url:'/api/sources',
    params:{
      per_page:200,
      types:'DATABASE,TOOL',
      sort:'ALPHABETICAL',
      active_flag:true
    }
  })

  useEffect(()=>{
    if(sourceData){
      dispatch({type:'set_sources',sources:sourceData.items})
    }
  },[sourceData])

  let bannerCause, bannerdisplayText;
  
  if (!checkEmailConfigured()) {
    if(variant==='admin'){
      bannerdisplayText = 'Email integration not configured. Alerts configured will fail to send.'
      bannerCause = 'emailNotConfigured';
    }else{
      bannerdisplayText = 'Email integration not configured. Your Alerts will not send. Contact your K administrator.'
      bannerCause = 'none'
    }
  }


  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              label='my_alert'
              iconLabel="alert"
              title={variant==='admin'?'Alerts':'My Alerts'}
              subtitle=' '
              bannerdisplayText={bannerdisplayText}
              bannerCause={bannerCause}
            />
          </div>
        )}
        body={
          <Body
            state={state}
            history={history}
            dispatch={dispatch}
            sessionData={sessionData}
            variant={variant}
          />
        }
      />
    </div>
  )
}

MyAlerts.propTypes = {
  history: PropTypes.object,
  sessionData: PropTypes.object,
  variant: PropTypes.oneOf(['user', 'admin'])
}

export default withStyles(styles)(MyAlerts);
