import React, { useEffect, useState, useRef, useCallback } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Select, MenuItem, Modal, Paper, Button} from '@material-ui/core';
import DetailDrawer from './DetailDrawer'
import axiosCerebrum from '../../../axios-cerebrum'
import axiosSolr from '../../../axios-solr'
import ListView from './ListView';
import { generateTags } from '../KLineageFramework/utils'
import { graphPalette, onDownloadMap } from '../Lineage/utils'
import ObjectDetailDrawer from '../KLineageFramework/ObjectDetailDrawer';
import {useStore} from 'react-redux'
import { checkCanAddToCart, checkCollectionLinkable, checkProfileEditable } from '../../../permissionChecker';
import ReactFlowWrapper from '../ReactFlowWrapper/ReactFlowWrapper';
import ELK from 'elkjs'
import { getDispFields, getIconComponent, onClickResultItem } from '../../../utilities';
import { bindNodeAction } from './utils';
import { globalListenerRef } from '../../../GlobalListenerRef';
import KTooltip from '../KTooltip/KTooltip';
import useAlert from '../../../hooks/useAlert';
import SnackBar from '../SnackBar/SnackBar';

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.header.main
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginTop:4
  },
  selector:{
    ...theme.components.titleSelector,
  },
  normalText:{
    color:theme.palette.primaryText.main
  },

  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  echarts:{
    '& canvas':{
      cursor:'grab'
    }
  },
  button:{
    width:20,
    height:20,
    borderRadius:16,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
})


function Joins(props) {
  const {
    classes,
    theme,
    state,
    history,
    targetObject,
    joinsData,
    setJoinsData,
    joinsView,
    setJoinsView
  } = props;

  const store = useStore();
  const sessionData = store.getState().auth.session_user;

  let isEditable = checkProfileEditable({sessionData, isStewardOrOwner: state.isStewardOrOwner})
  let collectionLinkable = checkCollectionLinkable({sessionData})

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedItemGroup, setSelectedItemGroup] = useState({target:undefined,selected:undefined})
  const [objectDrawerOpen, setObjectDrawerOpen] = useState(false);
  const [objectSelectedItem, setObjectSelectedItem] = useState()
  const chartWrapperRef = useRef()
  const nodeWidth = 200
  const [graphHeight, setGraphHeight] = useState(500)
  const [graphWidth, setGraphWidth] = useState(1000)
  const isDragging = useRef(false)

  const [fullScreen, setFullScreen] = useState(false)
  const [fullScreenGraphWidth, setFullScreenGraphWidth] = useState(window.innerWidth-180)
  const [fullScreenGraphHeight, setFullScreenGraphHeight] = useState(window.innerHeight-180)

  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const nodes = useRef()
  const links = useRef()

  const [, updateState] = useState();
  const forceUpdateTree = useCallback(() => updateState({}), []);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  useEffect(()=>{
    window.removeEventListener('resize',globalListenerRef.joinsFullScreenResizeListener)
    globalListenerRef.joinsFullScreenResizeListener = () => {
      setFullScreenGraphWidth(window.innerWidth-180)
      setFullScreenGraphHeight(window.innerHeight-180)
      setGraphWidth(document.getElementById('profile-content').getBoundingClientRect().width)
      setGraphHeight(window.innerHeight - chartWrapperRef.current.getBoundingClientRect().top - 100)
    }
    window.addEventListener('resize',globalListenerRef.joinsFullScreenResizeListener)
    return ()=>window.removeEventListener('resize',globalListenerRef.joinsFullScreenResizeListener)
  },[])

  useEffect(()=>{
    if(chartWrapperRef.current){
      setGraphWidth(document.getElementById('profile-content').getBoundingClientRect().width)
      setGraphHeight(window.innerHeight - chartWrapperRef.current.getBoundingClientRect().top - 100)
    }
  // eslint-disable-next-line
  },[chartWrapperRef.current])


  const setFocusNode = ({root=[],target=[]}) => {
    if(!nodes.current)return;
    for(let i=0; i<nodes.current.length; i++){
      let currentNode = nodes.current[i]
      // let detail = currentNode.data.obj;
      if(currentNode.type==='KGroup')continue;
      if(root.includes(currentNode.id) || currentNode.isRoot){
        currentNode.selected = root.includes(currentNode.id);
        currentNode.data.ignoreHoverEffect = true;
      }
      else if(target.includes(currentNode.id)){
        currentNode.selected = false;
        currentNode.data.ignoreHoverEffect = true;
      }
      else{
        currentNode.selected = false;
        currentNode.data.ignoreHoverEffect = false;
      }
    }
    forceUpdateTree()
  }

  const setFocusLink = (ids) => {
    if(!links.current)return
    for(let i=0; i<links.current.length; i++){
      let currentLink = links.current[i]
      if(ids.includes(currentLink.id)){
        currentLink.selected = true
        currentLink.data.opacity = 0.8;
        // currentLink.data.width = 5;
      }else{
        currentLink.selected = false
        currentLink.data.opacity = 0.3;
        // currentLink.data.width = 4;
      }
    }
  }


  const onNodeClick = function(){
    // if(isDragging.current)return
    setDrawerOpen(false)
    setObjectSelectedItem(this)
    // setSelectedItemGroup({})
    setObjectDrawerOpen(true)
    setFocusNode({root:[this.id],target:[]})
    setFocusLink(links.current.filter(l=>[l.target_id,l.source_id].includes(this.id)).map(l=>l.id))
  }


  const generateGroupDetail = async (allNodes) => {
    if(!allNodes)return;
    allNodes = allNodes.filter(el=>el.type!=='KGroup')
    if(allNodes.length===0)return [];
    let ids = [];
    let groups = []
    allNodes.forEach(n=>{
      if(!ids.includes(n.parentNode) && n.parentNode){
        ids.push(n.parentNode)
      }
    })

    await axiosSolr
      .get(`/solr/search/select`,{params:{q:'*',fq:`id:(${ids.map(el=>el.split('_')[0]).join(' OR ')})`,fl:'*',rows:ids.length}})
      .then(response=>{
        allNodes.forEach(n=>{
          let groupID = n.parentNode;
          if(!groupID)return;
          let detail = response.data.response.docs.find(r=>r.id===groupID.split('_')[0]);
          if(!detail){
            groups.push({
              id:groupID,
              selectable:false,
              type:'KGroup',
              width:nodeWidth,
              height:n.height + 60,
              data:{
                obj:{},
                label: 'Unknown',
                subTitle: ' ',
                icon:'unknown',
              },
            })
          }else{
            groups.push({
              id:groupID,
              selectable:false,
              type:'KGroup',
              width:nodeWidth,
              height:n.height + 60,
              data:{
                obj:detail,
                label: getDispFields(detail, 'dispTitle'),
                subTitle: getDispFields(detail, 'dispSubtitle'),
                icon:detail.object_type_txt,
                rightIcon:detail.source_type_txt==='REFERENCE'?detail.object_type_txt:detail.source_type_txt,
                rightIconColour:detail.reference_txt==='YES'?theme.palette.primaryText.light:theme.palette.primary.main,
                onTitleClick:()=>onClickResultItem({item:detail,id:detail.id,newWindow:true,label:detail.object_type_txt}),
              },
            })
          }
        })
      })
      .catch(error=>{
        console.log(error)
      })

    return groups;
  }

  const onCollapseLink = node => {
    if(!links.current)return;
    links.current.forEach(l=>{
      if(l.source_id===node.id && !l.forceShown)l.hidden = true
    })
    forceUpdateTree()
  }

  const onShowLink = node => {
    if(!links.current)return;
    links.current.forEach(l=>{
      if(l.source_id===node.id)l.hidden = false
    })
    forceUpdateTree()
  }

  function onLinkMouseEnter(){
    if(isDragging.current)return
    // if(nodes.current.find(n=>n.selected && n.id===this.source_id))return;
    // setFocusNode({root:[],target:[]})
    // setFocusLink([this.id])
    // forceUpdateTree()
  }

  function onLinkMouseLeave(){
    if(isDragging.current)return
    // if(nodes.current.find(n=>n.selected && n.id===this.source_id))return;
    // setFocusNode({root:[],target:[]})
    // setFocusLink([])
    // forceUpdateTree()
  }

  const layoutGraph = async ({nodesTmp, linksTmp, sourceNode, edgeLength = 420, isRoot, rootObject}) => {

    let groups = await generateGroupDetail(nodesTmp);
    if(!groups){
      setJoinsData({nodes:nodesTmp,links:linksTmp,groupDetail:{},mapError:true});
      nodes.current = nodesTmp;
      links.current = linksTmp;
      return;
    }

    let center = {x:0, y:0}
    let sourceParent;
    if(sourceNode)sourceNode = (nodes.current || []).find(n=>n.id===sourceNode.id)
    if(sourceNode){
      sourceParent = nodes.current?.find(n=>n.id===sourceNode.parentNode);
      if(sourceParent){
        center = {x:sourceParent.x, y:sourceParent.y}
      }
    }

    let childrenLength = nodesTmp.length;
    let radianGap = Math.floor(360/childrenLength);
    // let edgeLength = 400;
    for(let i=0; i<childrenLength; i++){
      let angle = i * radianGap
      if(!isNaN(nodesTmp[i].x) && !isNaN(nodesTmp[i].y))continue;
      nodesTmp[i].x = center.x + edgeLength * Math.cos(angle)
      nodesTmp[i].y = center.y + edgeLength * Math.sin(angle)
    }


    let elk = new ELK();
        elk
          .layout({
            id:'overlap_removal',
            layoutOptions: {
              'elk.algorithm':'sporeOverlap',
              'elk.spacing.nodeNode':180,
              'elk.overlapRemoval.maxIterations':100
            },
            children:[...(nodes.current||[]).filter(n=>n.type==='KGroup'),...nodesTmp],
            edges:[]
          })
          .then(g=>{
            nodesTmp.forEach(n=>{
              if(n.type==='KGroup')return;
              if(!groups.find(gr=>gr.id===n.parentNode))return;
              let detail = g.children.find(c=>c.id===n.id);
              groups.find(gr=>gr.id===n.parentNode).x = detail.x;
              groups.find(gr=>gr.id===n.parentNode).y = detail.y;
              n.x = 0;
              n.y = 0;
            })

            nodesTmp.forEach(n=>{
              if(sourceNode && n.id===sourceNode.id)return;
              bindNodeAction({constructDataJoins, groups, node:n, onCollapseLink, onShowLink})
            });
            nodes.current = [...groups,...(nodes.current ||[]),...nodesTmp];
            links.current = [...(links.current ||[]),...linksTmp];
            // nodes.current = [...groups, ...(nodes.current || []), ...nodesTmp];
            // links.current = [...(links.current || []),...linksTmp];
            if(!isRoot){
              setFocusLink(linksTmp.map(l=>l.id))
            }
            setJoinsData({nodes:nodes.current,links:links.current,groupDetail:{}});
            isDragging.current = false;
          // })
      })
      .catch(error=>{
        if(isRoot){
          // setJoinsData({error:true});
          setJoinsData({nodes:nodesTmp,links:linksTmp,groupDetail:{}, mapError:true});
          nodes.current = nodesTmp;
          links.current = linksTmp;
          // setJoinsView('list')
        }else{
          setSnackBarOpen({obj:rootObject.data.obj})
        }
        isDragging.current = false;
      });
  }

  const constructDataJoins = async (rootObject, isRoot) => {
    setFocusLink([])
    setFocusNode({root:[],target:[]})
    let outputItems = {};
    await axiosCerebrum
      .get(
        `/api/tables/${rootObject.id}/joins`,
        {params:{
          object_active_flag:true,
          active_flag:true,
          per_page:100,
        }}
      )
      .then(response=>{
        response.data.items.filter(el=>el.id!==targetObject.id).forEach(el=>{
          outputItems[el.id] = {...el, codeComponents:el.code_components.map(c=>({...c,linkedTableIds:[el.id,rootObject.id]}))}
        })
      })
    let outputItemDetails = {};
    let isRootLoaded = (nodes.current||[]).find(n=>n.id===rootObject.id)
    if(Object.keys(outputItems).length!==0 || !isRootLoaded){
      let ids = Object.keys(outputItems);
      if(!isRootLoaded)ids.push(rootObject.id)
      await axiosSolr
        .get(`/solr/search/select`,{params:{q:'*',fq:`id:(${ids.join(' OR ')})`,fl:'*',rows:ids.length}})
        .then(response=>{
          response.data.response.docs.forEach(el=>{
            outputItemDetails[el.id] = el;
          })
        })
    }

    let nodesTmp = [];
    let linksTmp = [];

    let rootDetail = outputItemDetails[rootObject.id] || rootObject;
    let parentId;
    if(rootObject.parent){
      parentId = rootObject.parent.id
    }
    if(rootObject.obj){
      parentId = rootObject.obj.parent_id
    }
    if(!parentId)parentId = 'root-parent';
    if(!isRootLoaded){
      let rootNode = {
        id:rootObject.id,
        labels:'TABLE',
        width:nodeWidth,
        // height:generateTags(rootDetail).length<=1?42:56,
        height:42,
        type:'KLineageNode',
        parentNode:parentId+'_'+rootObject.id,
        group:parentId+'_'+rootObject.id,
        isRoot,
        selected:true,
        loaded:true,
        x:0,
        y:0,
        data:{
          // forceStyle:true,
          id:rootObject.id,
          obj:rootDetail,
          tags:generateTags(rootDetail),
          label:rootObject.name,
          backgroundColour: theme.palette.chartNodeSelected.main,
          titleColour: theme.palette.primaryText.main,
          onClick:()=>{
            if(isDragging.current)return
            setDrawerOpen(false)
            setObjectSelectedItem()
            setSelectedItemGroup({})
            setObjectDrawerOpen(false)
            setFocusNode({root:[rootObject.id],target:[]})
            setFocusLink([])
          }
        }
      }
      rootNode.data.onClick = onNodeClick.bind(rootNode)
      nodesTmp.push(rootNode)

    }else{
      // let targetNode = (nodes.current||[]).find(n=>n.id===rootObject.id)
      // targetNode.loaded = true;
      // targetNode.data.leftAction = undefined;
      // targetNode.data.rightAction = undefined;
    }

    Object.keys(outputItems).forEach((el,index)=>{
      let currentNode = (nodes.current||[]).find(n=>n.id===el)
      let item = outputItems[el];
      let detail = {...(outputItemDetails[item.id]||{}),...item}
      if(!currentNode){
        currentNode =  {
          id:el,
          labels:'TABLE',
          parentNode:item.parent_id+'_'+el,
          group:item.parent_id+'_'+el,
          type:'KLineageNode',
          width:nodeWidth,
          // height:generateTags(detail).length<=1?42:56,
          height:42,
          data:{
            id:el,
            // forceStyle:true,
            codeComponents:item.codeComponents,
            tags:generateTags(detail),
            label:detail.name_txt || detail.name,
            borderColour:graphPalette.TABLE,
            iconColour:detail.reference_txt==='YES'?theme.palette.primaryText.light:graphPalette.TABLE,
            obj:{...detail,codeComponents:item.codeComponents},
          }
        };
        currentNode.data.customContextMenuItems = generateCustomRightClickAction(currentNode)
        currentNode.data.onClick = onNodeClick.bind(currentNode)
        currentNode.data.onContextMenu = ()=>{setFocusNode({root:[currentNode.id]});}
        nodesTmp.push(currentNode)
      }else{
        currentNode.data.codeComponents.push(...item.codeComponents)
        currentNode.data.obj.codeComponents.push(...item.codeComponents)
      }

      let source_id = rootObject.id;
      let target_id = el;
      let linkID = source_id + '_' + target_id;
      if( !(links.current || []).find(l=> [l.source_id, l.target_id].every(id=>[source_id,target_id].includes(id)))){
        let link =  {
          id:linkID,
          source_id,
          target_id,
          type:'KLineageEdge',
          data:{
            lineType: 'straight',
            colour:theme.palette.primary.main,
            onClick:()=>{
              if(isDragging.current)return
              setSelectedItemGroup({target:rootObject.data || rootObject , selected:currentNode.data});
              setDrawerOpen(true)
              setObjectDrawerOpen(false)
              setFocusNode({root:[rootObject.id],target:[el]})
              setFocusLink([linkID])
            }
          }
        }
        link.data.onMouseEnter = onLinkMouseEnter.bind(link)
        link.data.onMouseLeave = onLinkMouseLeave.bind(link)
        linksTmp.push(link)
      }
    })

    setFocusNode({root:[rootObject.id],target:[]})
    // nodesTmp =  [...(nodes.current||[]), ...nodesTmp]
    // linksTmp = [...(links.current||[]), ...linksTmp]
    if(nodesTmp.length===0 && linksTmp.length===0){
      sendAlert({
        type:'info',
        message:'No joins found'
      })
      rootObject.data.rightAction = undefined;
      rootObject.data.rightActionIcon = undefined;
      forceUpdateTree()
      return;
    }else{
      if(!isRoot){
        rootObject.data.loaded = true;
        rootObject.data.rightActionIcon = 'remove';
      }

    }
    layoutGraph({nodesTmp,linksTmp, sourceNode:rootObject, edgeLength:isRoot?420:160, isRoot, rootObject})

  }

  // construct nodes & links for query profile
  const constructQueryJoins = async (rootObject) => {
    let tables = {};
    let nodesTmp = [];
    let linksTmp = [];
    await axiosCerebrum
      .get(
        `/api/queries/${rootObject.id}/joins`,
        {params:{
          object_active_flag:true,
          active_flag:true,
          per_page:100,
        }}
      )
      .then(response=>{

        response.data.items.forEach(el=>{
          if(!tables[el.id])tables[el.id] = {...el, codeComponents:[], linkedIds:[]}
          el.code_components.forEach(c=>{
            let outTable = c.contributing_tables[0];
            if(outTable.id===el.id)return;
            tables[el.id].codeComponents.push({...c,linkedTableIds:[el.id,outTable.id]})
            tables[el.id].linkedIds.push(outTable.id)

            if(!tables[outTable.id])tables[outTable.id] = {...outTable, codeComponents:[], linkedIds:[]}
            tables[outTable.id].codeComponents.push({...c,linkedTableIds:[el.id,outTable.id]})
            tables[outTable.id].linkedIds.push(el.id)

            let source_id = el.id;
            let target_id = outTable.id;
            let linkID = source_id + '_' + target_id;
            linksTmp.push({
              id:linkID,
              source_id,
              target_id,
              forceShown: true,
              type:'KLineageEdge',
              data:{
                lineType:'step',
                colour:theme.palette.primary.main,
              }
            })
          })
        })
      })

    let tableDetails = {};
    if(Object.keys(tables).length!==0){
      await axiosSolr
        .get(`/solr/search/select`,{params:{q:'*',fq:`id:(${Object.keys(tables).join(' OR ')})`,fl:'*',rows:Object.keys(tables).length}})
        .then(response=>{
          response.data.response.docs.forEach(el=>{
            tableDetails[el.id] = el;
          })
        })
    }

    let groupIds = []

    Object.keys(tables).forEach((el,index)=>{

      let item = tables[el];
      let detail = {...(tableDetails[item.id]||{}),...item}
      if(item.parent_id && !groupIds.includes(item.parent_id))groupIds.push(item.parent_id)
      let currentNode =  {
        id:el,
        labels:'TABLE',
        parentNode:item.parent_id+'_'+el,
        group:item.parent_id+'_'+el,
        type:'KLineageNode',
        width:nodeWidth,
        // height:generateTags(detail).length<=1?42:56,
        height:42,
        data:{
          id:el,
          // forceStyle:true,
          codeComponents:item.codeComponents,
          tags:generateTags(detail),
          label:detail.name_txt || detail.name,
          borderColour:graphPalette.TABLE,
          iconColour:detail.reference_txt==='YES'?theme.palette.primaryText.light:graphPalette.TABLE,
          obj:{...detail,codeComponents:item.codeComponents},
        }
      };
      currentNode.data.onClick = onNodeClick.bind(currentNode)
      currentNode.data.onContextMenu = ()=>setFocusNode({root:[currentNode.id]})
      currentNode.data.customContextMenuItems = generateCustomRightClickAction(currentNode)
      nodesTmp.push(currentNode)
    })

    for(let i=0; i<linksTmp.length; i++){
      let currentLink = linksTmp[i];
      currentLink.data.onClick = ()=>{
        if(isDragging.current)return
        setSelectedItemGroup({target:nodesTmp.find(n=>n.id===currentLink.source_id).data, selected:nodesTmp.find(n=>n.id===currentLink.target_id).data});
        setDrawerOpen(true)
        setObjectDrawerOpen(false)
        setObjectSelectedItem()
        setFocusNode({root:[currentLink.source_id],target:[currentLink.target_id]})
        setFocusLink([currentLink.id])
      }
      currentLink.data.onMouseEnter = onLinkMouseEnter.bind(currentLink)
      currentLink.data.onMouseLeave = onLinkMouseLeave.bind(currentLink)
    }

    // nodesTmp =  [...(nodes.current||[]), ...nodesTmp.filter(n=>!nodes.current || !nodes.current.find(c=>c.id===n.id))]
    // linksTmp = [...(linksTmp.current||[]), ...linksTmp.filter(l=>!links.current || !links.current.find(c=>c.source_id===l.source_id && c.target_id===l.target_id))]
    layoutGraph({nodesTmp,linksTmp, rootObject})
  }

  const loadJoinsData = async () => {
    setJoinsData({loading:true})
    try{
      if(['TABLE'].includes(targetObject.object.name)){
        await constructDataJoins(targetObject, true);
      }
      if(targetObject.object.name==='QUERY'){
        await constructQueryJoins(targetObject);
      }
    }catch(error){
      console.log(error)
      setJoinsData({error:true})
    }
  };

  useEffect(()=>{
    loadJoinsData();
    return ()=>{
      setJoinsData()
    }
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(!drawerOpen){
      setSelectedItemGroup({...selectedItemGroup,selected:undefined});
    }
  // eslint-disable-next-line
  },[drawerOpen])


  useEffect(()=>{
    setDrawerOpen(false);
  },[joinsView])


  let totalJoins;
  if(nodes.current ){
    if(['TABLE'].includes(targetObject.object.name)){
      totalJoins = nodes.current.filter(n=>n.type!=='KGroup').length-1;
    }
    if(targetObject.object.name==='QUERY'){
     totalJoins = nodes.current.filter(n=>n.type!=='KGroup').length
    }
  }

  const onRestore = () => {
    setFocusNode({root:[],target:[]})
    setFocusLink([])
    nodes.current = undefined;
    links.current = undefined;
    loadJoinsData()
  }

  const onSethideMiniMap = () => {
    setJoinsData({...joinsData,showMiniMap:!joinsData.showMiniMap})
  }

  const generateCustomRightClickAction = (item) => {
    return [
      isEditable?{
        name:'Edit',
        iconLabel:'edit',
        isStandalone:true,
        onClick:()=>{
          onNodeClick.call({data:{obj:item,id:item.id}})
          setTimeout(()=>window.postMessage({mini_profile_edit:'true'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port))
        }
      }:undefined,
      checkCanAddToCart({sessionData,objectType:(item.data.obj?.type||'').toUpperCase()})?{
        id:'addToCart'
      }:undefined,
      collectionLinkable?{
        name:'Add to Collection Instance',
        iconLabel:'collection',
        onClick:()=>{
          onNodeClick.call({data:{obj:item,id:item.id}})
          setTimeout(()=>window.postMessage({mini_profile_link_collection:'true'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port))
        }
      }:undefined,
      {
        name:'Add Tag',
        iconLabel:'tag',
        onClick:()=>{
          onNodeClick.call({data:{obj:item,id:item.id}})
          setTimeout(()=>window.postMessage({mini_profile_add_tag:'true'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port))
        }
      }
    ].filter(el=>el!==undefined)
  }

  const postProcessNodes = nodes => {

    const isNodeVisible = n => {
      return links.current.length===0 || n.id===targetObject.id || links.current.find(l=>!l.hidden && (l.source_id===n.id || l.target_id===n.id))
    }

    const getOrder = n => {
      if(n.parentNode && nodes.find(p=>p.id===n.parentNode)?.isDragging)return 3;
      if(n.isDragging)return 2;
      if(n==='KGroup')return 0;
      return 1;
    }

    return nodes
      .filter(n=>n.type!=='KGroup' || nodes.find(c=>c.parentNode===n.id && isNodeVisible(c)))
      .filter(n=>n.type==='KGroup' || isNodeVisible(n))
      .filter(n=>!isNaN(n.x) && !isNaN(n.y))
      .map((n,index)=>({
        ...n,
        position:{
          x:n.x,
          y:n.type==='KGroup'?n.y:62
        },
        draggable:n.type==='KGroup',
        extent:n.type!=='KGroup'?[[0,62],[0,62]]:undefined,
        // draggable:n.type==='KGroup'?true:false,
        data:{
          ...(n.data||{}),
          height:n.height,
          width:n.width,
          isConnectedToCentre:true,
          tags:n.data?.tags?.filter(t=>t.id==='trust')
          // ignoreHoverEffect:true
        },
        // sourcePosition: 'right',
        // targetPosition: 'left',
      }))
      .sort((a,b)=>{
        return getOrder(a)-getOrder(b)
      })
  }

  const postProcessLinks = links => {
    return links.filter(el=>!el.hidden).map(l=>({
      ...l,
      source:l.source_id,
      target:l.target_id,
      data:{
        ...l.data,
        noArrow:true
      }
    }))
  }


  const onNodeDragStart = (event, node) => {
    isDragging.current = true;
    nodes.current.forEach(n=>{
      if(n.id===node.id)n.isDragging = true;
      else{n.isDragging = false}
    })
    forceUpdateTree()
  }

  window.onblur = () => {
    isDragging.current = false
  }

  const onNodeDragStop = (event, node) => {
    isDragging.current = false
    nodes.current.find(n=>n.id===node.id).x = node.position.x;
    nodes.current.find(n=>n.id===node.id).y = node.position.y;
  }

  let joinsComponent = (
    <div className={classes.root} ref={chartWrapperRef}>
      <div style={{display:'flex',justifyContent:'space-between',marginBottom:16}}>
        <div>
          <div style={{display:'flex',alignItems:'center'}}>
            {
              nodes.current &&
              <Select
                className={classes.selector}
                value={joinsView}
                onChange={event=>setJoinsView(event.target.value)}
                disableUnderline
                MenuProps={{
                  classes:{
                    paper:classes.selectPaper
                  }
                }}
              >
                <MenuItem  className={classes.menuItem} value='list'>LIST</MenuItem>
                <MenuItem  className={classes.menuItem} value='map'>MAP</MenuItem>
              </Select>
            }
            {
              nodes.current && <Typography className={classes.title} style={{marginLeft:12,marginRight:8}}>OF</Typography>
            }
            <Typography className={classes.title}>
              {totalJoins} JOIN(S)
            </Typography>
          </div>
          <Typography className={classes.subTitle}>Click on a link to another Table to see the join details</Typography>
        </div>

      </div>

      {
        joinsView==='map' &&
        <div style={{marginTop:-48,marginBottom:20,display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
          <KTooltip title={`Download joins map`}>
            <div className={classes.button} style={{marginRight:16}} onClick={()=>onDownloadMap(`${targetObject.name}_joins_map`)}>
              {getIconComponent({label:'download',size:18,colour:theme.palette.primaryText.light})}
            </div>
          </KTooltip>
          <KTooltip title={`${joinsData && joinsData.showMiniMap?'Hide':'Show'} minimap`}>
            <div className={classes.button} style={{marginRight:16}} onClick={onSethideMiniMap}>
              {getIconComponent({label:joinsData && joinsData.showMiniMap?'hide_window':'show_window',size:18,colour:theme.palette.primaryText.light})}
            </div>
          </KTooltip>
          <KTooltip title={`Full screen`}>
            <div className={classes.button} style={{marginRight:16}} onClick={()=>setFullScreen(!fullScreen)}>
              {getIconComponent({label:fullScreen?"zoom_in_map":'zoom_out_map',size:18,colour:theme.palette.primaryText.light})}
            </div>
          </KTooltip>
          <KTooltip title={`Reload map`}>
            <div className={classes.button} style={{marginRight:16}} onClick={onRestore}>
              {getIconComponent({label:'refresh',size:18,colour:theme.palette.primaryText.light})}
            </div>
          </KTooltip>
        </div>
      }
      {
        joinsData && joinsData.loading && <div style={{textAlign:'center'}}><CircularProgress color='secondary' style={{margin:'64px 0'}}/></div>
      }
      {
        joinsData?.mapError && joinsView==='map' &&
        <div>
          <Typography className={classes.normalText} style={{color:theme.palette.primary.main,whiteSpace:'pre-wrap',textAlign:'center',marginTop:88}}>
            Oops, looks like there is too many joins to display.
            {'\n\n'}
            <span
              style={{cursor:'pointer',textDecoration:'underline'}}
              onClick={()=>setJoinsView('list')}
            >
              Click to see a list view
            </span>
          </Typography>
        </div>
      }
      {
        joinsData?.error &&
        <Typography style={{marginTop:24}}>Error occurred loading joins data</Typography>
      }
      {
        nodes.current && nodes.current.length>0 && links.current && joinsView==='map' && chartWrapperRef.current && joinsData && !joinsData.loading && !joinsData.error && !joinsData.mapError &&
        <div style={{width:fullScreen?fullScreenGraphWidth:graphWidth,height:fullScreen?fullScreenGraphHeight:graphHeight}}>
          <ReactFlowWrapper
            initialNodes={postProcessNodes(nodes.current)}
            initialLinks={postProcessLinks(links.current)}
            presetCenter={{x:nodes.current[0].x+nodeWidth/2,y:nodes.current[0].y+28}}
            centerOffset={{x:nodeWidth/2,y:300}}
            hideMiniMap={!joinsData.showMiniMap}
            onFocusOffset={{x:260,y:0}}
            // fitView={true}
            initialZoom={0.6}
            onNodeDragStop={onNodeDragStop}
            onNodeDragStart={onNodeDragStart}
            showEdgeBelowNode={true}
          />
        </div>
      }
      {
        joinsData && joinsView==='list' && nodes.current &&
        <ListView
          list={nodes.current.filter(el=>el.type!=='KGroup').map(el=>el.data.obj).filter(el=>targetObject.object.name!=='TABLE' || el.id!==targetObject.id)}
          targetObject={targetObject}
          history={history}
        />
      }
      {
        nodes.current && nodes.current.length===0 && joinsView==='map' &&
        <Typography className={classes.normalText}>No joins data found</Typography>
      }
      {
        nodes.current && nodes.current.length>0 &&
        <DetailDrawer
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          selectedItemGroup={selectedItemGroup}
          history={history}
        />
      }
      {
        nodes.current &&
        <ObjectDetailDrawer
          history={history}
          drawerOpen={objectDrawerOpen}
          setDrawerOpen={setObjectDrawerOpen}
          selectedItem={objectSelectedItem}
        />
      }
      <SnackBar
        snackBarOpen={snackBarOpen}
        setSnackBarOpen={setSnackBarOpen}
        message={snackBarOpen && `Error occurred loading joins for ${getDispFields(snackBarOpen?.obj,'dispTitle')}, click button to go to its profile page`}
        buttons={
          [
            snackBarOpen && <Button
              color='primary'
              variant='outlined'
              onClick={()=>{
                onClickResultItem({
                  item:snackBarOpen?.obj,
                  id:snackBarOpen?.obj?.id,
                  label:snackBarOpen?.obj?.object_type_txt,
                  history,
                  query:['tabName=JOINS']
                })
                window.scrollTo(0,0)
              }}
            >
              OPEN
            </Button>
          ]
        }
        type="error"
      />
    </div>
  )

  return (
    fullScreen?
    <Modal open={fullScreen} onClose={()=>setFullScreen(false)}>
      <Paper style={{width:fullScreenGraphWidth,padding:30,marginLeft:60, marginTop:30, height:fullScreenGraphHeight+66}}>
        {joinsComponent}
      </Paper>
    </Modal>
    :
    joinsComponent
  )
}

export default withTheme()(withStyles(styles)(Joins));
