import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Button, } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import axiosCerebrum from '../../../axios-cerebrum'
import LoadingScreen from '../LoadingScreen/LoadingScreen'
import useAlert from '../../../hooks/useAlert';
import ActionSelector from '../ActionSelector/ActionSelector';
import Review from '../Review/Review';
import ActionDetails from '../ActionDetails/ActionDetails';
import { checkFilled } from '../../UI/CollectionInstanceEditFields/CollectionInstanceEditFields';
import { actionIds } from '../utils';
import { collectionIds } from '../../../utilities';

const styles = theme => ({
  root: {
    width: '100%',
  },
  labelText: {
    color: theme.palette.primaryText.light
  },
  selectedLabelText: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: '400 !important'
  },
  stepIcon: {
    cursor: 'pointer'
  },
  buttons: {
    position: 'fixed',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.background.main,
    height: 80,
    bottom: 0,
    right: 0,
    left: 40,
    zIndex: 10,
    //width:'100%',
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.listItemDivider.main}`
  },
  step: {
    '& svg': {
      color: theme.palette.primaryText.light
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  stepper: {
    backgroundColor: theme.palette.background.main
  },
  normalText: {
    color: theme.palette.primaryText.main
  }
})

function Body(props) {

  const {
    classes,
    state,
    dispatch,
    history,
    sessionData
  } = props;

  const [loadingSteps, setLoadingSteps] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(() => {
    return () => {
      isCancelledRef.current = true
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state.activeStep, state.loadingScreen])

  let steps = [
    'Select bulk action',
    'Bulk action details',
    'Review'
  ]

  if (state.configs.editMode === 'cascade') {
    steps = [
      'Update details',
      'Review'
    ]
  }

  const getBodyContent = () => {
    if (state.loadingScreen) {
      return (
        <LoadingScreen
          state={state}
          dispatch={dispatch}
          history={history}
          sessionData={sessionData}
        />
      )
    }
    let stepName = steps[state.activeStep];
    if (stepName === 'Select bulk action') {
      return (
        <ActionSelector
          state={state}
          dispatch={dispatch}
          sessionData={sessionData}
        />
      )
    }
    if (['Bulk action details', 'Update details'].includes(stepName)) {
      return (
        <ActionDetails
          state={state}
          dispatch={dispatch}
          history={history}
          sessionData={sessionData}
        />
      )
    }
    if (stepName === 'Review') {
      return (
        <Review
          state={state}
          dispatch={dispatch}
          history={history}
          sessionData={sessionData}
        />
      )
    }
  }

  const onConfirm = async () => {
    setLoadingSteps(true)

    if ([actionIds.createAndLinkToList, actionIds.createAndLinkToWarning].includes(state.selectedAction)) {
      let values = state.instanceValues;
      let properties = {};
      Object.keys(values).filter(el => !['name', 'description'].includes(el)).forEach(k => {
        properties[k] = values[k]
      })
      let isError = false;
      await axiosCerebrum
        .post(
          `/api/collectioninstances`,
          {
            "collection_id": state.collectionTemplate.data.id,
            "description": (values['description'] || '').replace(/^(<br>|\s)*/, '').replace(/(<br>|\s)*$/, ''),
            "name": values['name'],
            "properties": properties,
            "expiry": state.selectedAction === actionIds.createAndLinkToWarning ? state.instanceExpiry.utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z' : undefined,
            "source_id": 1000
          }
        )
        .then(response => {
          setLoadingSteps(false)
          state.propertyValues[state.collectionTemplate.data.id].valueDetails = [response.data]
          state.propertyValues[state.collectionTemplate.data.id].values = [response.data.id]

          if (response.data.parent.id === collectionIds.list) {
            axiosCerebrum
              .put(`/api/me/follows/${response.data.id}?type=OPT_IN`)
              .catch(error => {
                console.log(error)
              })
          }
        })
        .catch(error => {
          setLoadingSteps(false)
          console.log(error)
          let msg = 'Error occurred updating the objects, please try again';
          if (error && error.response && error.response.status && error.response.status === 409) {
            msg = "The instance name already exists, please try again with a different name";
          }
          sendAlert({ message: msg, type: 'error' })
          isError = true;
        })
      if (isError) return;
    }


    let solr_query, object_ids, filter_id;
    if (state.configs?.params && state.configs?.url) {
      let index = state.configs.url.split('/solr/')[1]?.split('/')?.[0];
      let q = state.configs.params.q;
      let fq = state.configs.params.fq;
      solr_query = { index, q, fq };
    }
    if (state.configs?.filterId) {
      filter_id = state.configs.filterId;
      solr_query = null;
    }
    if (state.configs?.objectIds) {
      object_ids = state.configs.objectIds;
    }

    let payloadFilters = {}
    if (solr_query) payloadFilters.solr_query = solr_query;
    if (object_ids) payloadFilters.object_ids = object_ids;
    if (filter_id) payloadFilters.filter_id = filter_id;

    axiosCerebrum
      .post(
        '/api/platformbatches/112',
        {
          ...payloadFilters,
          name: state.configs?.fileName,
          collection_instance_id: state.configs?.instanceId,
          "updates": state.properties.map(el => {
            let detail = state.propertyValues[el.id]
            return {
              collection_id: detail.collection_id,
              field: detail.field,
              operation: detail.operation,
              values: detail.field === 'DESCRIPTION' && detail.values[0]?.replace ? [detail.values[0].replace(/^(<br>|\s)*/, '').replace(/(<br>|\s)*$/, '')] : detail.values
            }
          })
        }
      )
      .then(response => {
        setLoadingSteps(false)
        dispatch({ type: 'set_loading_screen', loadingScreen: true })
        dispatch({ type: 'set_batch_status', batchLoading: true })
        history.push(`/my_tasks?tabName=JOBS&jobName=BULK UPDATE&isSubmitted=true`)
      })
      .catch(error => {
        setLoadingSteps(false)
        console.log(error);
        sendAlert({ message: "Error occurred submitting request, please try again", type: 'error' })
      })
  }
  
  const onSetPropertiesForInstance = () => {
    dispatch({ type: 'set_properties', properties: [state.collectionTemplate.data] });
    dispatch({
      type: 'set_property_values',
      propertyValues: {
        [state.collectionTemplate.data.id]: {
          field: 'MEMBER_OF',
          collection_id: state.collectionTemplate.data.id,
          operation: 'ADD_TO_EXISTING',
          values: [''],
          valueDetails: [{ name: state.instanceValues['name'] }]
        }
      }
    })
  }

  const onNextClick = () => {
    if (state.activeStep !== steps.length - 1) {
      if (state.activeStep === 1 && [actionIds.createAndLinkToList, actionIds.createAndLinkToWarning].includes(state.selectedAction)) {
        onSetPropertiesForInstance()
      }
      dispatch({ type: 'set_active_step', activeStep: state.activeStep + 1 })
      return;
    }
    onConfirm();
  }

  const onBackClick = () => {
    dispatch({ type: 'set_active_step', activeStep: state.activeStep - 1 })
  }

  const stepClickable = (step) => {
    if (state.configs.editMode === 'cascade') step += 1;
    if (step === 0) return true;
    if (step === 1) return state.selectedAction;
    if (step === 2) {
      if ([actionIds.createAndLinkToList, actionIds.createAndLinkToWarning].includes(state.selectedAction)) {
        if (!state.collectionTemplate?.data) return false;
        return checkFilled(state.collectionTemplate.data.properties, state.instanceValues)
      }

      return state.properties.length > 0 && state.properties.every(el => {
        let detail = state.propertyValues[el.id];
        if (detail.operation ==='CLEAR')return true;
        return detail.values.length > 0 && detail.values.every(val => val !== '')
      })
    }
    return true;
  }
  
  const onCancelClick = () => {
    let redirectUrl = state.configs?.redirectUrl;
    if (redirectUrl) history.push(redirectUrl);
    else history.push('/home')
  }

  const onStepLabelClick = step => {
    if (stepClickable(step)) dispatch({ type: 'set_active_step', activeStep: step })
  }

  return (
    <div className={classes.root}>
      {
        !state.loadingScreen &&
        <Stepper className={classes.stepper} activeStep={state.activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step className={classes.step} key={label} completed={false}>
              <StepLabel
                data-test-id={`step-label-${index}`}
                onClick={() => onStepLabelClick(index)}
                classes={{
                  active: classes.selectedLabelText,
                  iconContainer: classes.stepIcon,
                  label: classes.labelText
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      }

      <div style={{ marginBottom: 110, marginTop: 32 }}>
        {getBodyContent()}
      </div>

      {
        !state.loadingScreen &&
        <div className={classes.buttons}>
          {
            state.activeStep !== 0 &&
            <Button
              color='primary'
              style={{ width: 120, height: 48 }}
              disabled={loadingSteps}
              onClick={onBackClick}
              data-test-id="back-button"
            >
              BACK
            </Button>
          }
          <Button data-test-id="cancel-button" color='primary' style={{ marginLeft: 30, width: 120, height: 48 }} onClick={onCancelClick} >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            data-test-id="next-button"
            style={{ marginLeft: 30, width: 120, height: 48, marginRight: '10%' }}
            disabled={(state.activeStep !== steps.length && !stepClickable(state.activeStep + 1)) || loadingSteps}
            onClick={onNextClick}
          >
            {state.activeStep === steps.length - 1 ? 'CONFIRM' : 'NEXT'}
          </Button>
        </div>
      }

    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(Body));