import FeedCard from '../../UI/FeedCard/FeedCard';
import theme from '../../../theme'
import { getCustomerName, getKnowledgeBaseUrl } from '../../../utilities';

export const generateStartCards = ({sessionData, history}) => {

  let customerName = getCustomerName();
  let knowledgeBaseUrl = getKnowledgeBaseUrl();

  const cards = [
    <div style={{marginBottom:48}}>
      <FeedCard
        id={"home_feed_card_1"}
        userName={'K'}
        overline={'Getting started'}
        title={'Welcome to K'}
        body={
          `Hi ${sessionData.name},` + 
          `\n\nWelcome to K. The ${customerName===''?'':customerName+' '}Data Discovery & Governance Platform. ` + 
          `\n\nK helps you find, understand, organise and govern your data. Check this tailored feed regularly. We'll keep you posted on relevant changes to your data ecosystem.`
        }
      />
    </div>,
    <div style={{marginBottom:48}}>
      <FeedCard
        id={"home_feed_card_2"}
        userName={'K'}
        overline={'Getting started'}
        title={'Complete your setup'}
        mainActions={
          [{name:'open',onClick:()=>history.push('/settings')}]
        }
        body={
          `Open Profile Settings to complete your setup. ` + 
          `\n\nRemember to add your Team and Accounts so that K displays data that is relevant to you. You can also try out our fun features like dark mode.`
        }
      />
    </div>
  ];

  if(knowledgeBaseUrl.trim()!==''){
    cards.push(
      <div style={{marginBottom:48}}>
        <FeedCard
          id={"home_feed_card_3"}
          userName={'K'}
          overline={'Getting started'}
          title={'Learn about K'}
          mainActions={
            [{name:'open',onClick:()=>window.open(knowledgeBaseUrl, '_blank')}]
          }
          body={
            <span>
              Check out <span onClick={()=>window.open(knowledgeBaseUrl, '_blank')} style={{cursor:'pointer',textDecoration:'underline',color:theme.palette.hyperLink.main}}>{customerName===''?'':customerName+"'s "}Knowledge Base</span> for more information about how {customerName===''?'':customerName+' and '}K work{customerName===''?'s':' together'}.
              {'\n\n'}
              You can also visit the <span onClick={()=>window.open('https://kada-knowledge-library.scrollhelp.site/home/', '_blank')} style={{cursor:'pointer',textDecoration:'underline',color:theme.palette.hyperLink.main}}>KADA Knowledge Library</span> anytime in K via the help button on the bottom right hand corner.
            </span>
          }
        />
      </div>
    )
  }else{
    cards.push(
      <div style={{marginBottom:48}}>
        <FeedCard
          id={"home_feed_card_3"}
          userName={'K'}
          overline={'Getting started'}
          title={'Learn about K'}
          mainActions={
            [{name:'open',onClick:()=>window.open('https://kada-knowledge-library.scrollhelp.site/home/', '_blank')}]
          }
          body={
            <span>
              Check out our <span onClick={()=>window.open('https://kada-knowledge-library.scrollhelp.site/home/', '_blank')} style={{cursor:'pointer',textDecoration:'underline',color:theme.palette.hyperLink.main}}>KADA Knowledge Library</span> to search for helpful articles, user guides and raise support tickets.
              {'\n\n'}
              You can access this anytime in K via the help button on the bottom right hand corner.
            </span>
          }
        />
      </div>
    )
  }


  return cards;
}


export const processAdditionalContext = ({additional_context, details, isCollection}) => {
  let updates = []
  let deletes = []
  if(additional_context && (additional_context.ADD || additional_context.UPDATE || additional_context.CLEAR)){
    let changes = {
      ...(additional_context.ADD||{}),
      ...(additional_context.UPDATE||{}),
    }
    Object.keys(changes).forEach(el=>{
      if(['-1','-2','-3','-4'].includes(el+''))return;
      if(isCollection && !details.parent.properties.find(p=>p.id===el))return;
      updates.push({
        isDescription:(isCollection?el+''==='2':el==='description'),
        name:(isCollection?details.parent.properties.find(p=>p.id===el).name:el).toUpperCase(),
        value:changes[el].length>500?changes[el].slice(0,500)+'...':changes[el]
      })
    })
    if(additional_context.CLEAR){
      Object.keys(additional_context.CLEAR).forEach(el=>{
        if(['-1','-2','-3','-4'].includes(el+''))return;
        if(isCollection && !details.parent.properties.find(p=>p.id===el))return;
        deletes.push({
          isDescription:(isCollection?el+''==='2':el==='description'),
          name:(isCollection?details.parent.properties.find(p=>p.id===el).name:el).toUpperCase(),
          value:additional_context.CLEAR[el].length>500?additional_context.CLEAR[el].slice(0,500)+'...':additional_context.CLEAR[el]
        })
      })
    }
  }
  return {updates, deletes}
}