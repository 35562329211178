import axiosCerebrum from "../../../axios-cerebrum"

export const loadReferences = ({page = 1, search, state, dispatch}) => {
  dispatch({
    type:'set_reference_sources',
    referenceSourcesLoading:true,
    referenceSources:page===1?undefined:state.referenceSources,
  })
  axiosCerebrum
    .get(
      `/api/hosts`,
      {params:{
        per_page:20,
        page,
        sort:'ALPHABETICAL',
        'search.alternate_name':search,
        reference:true
      }}
    )
    .then(response=>{
      let data = page===1?response.data.items:state.referenceSources.items.concat(response.data.items)
      dispatch({
        type:'set_reference_sources',
        referenceSources:{...response.data,items:data}
      })
    })
    .catch(error=>{
      console.log(error)
      dispatch({
        type:'set_reference_sources',
        referenceSourcesError:true
      })
    })
}