import React, {  } from 'react';
import { withTheme, FormControl, FormHelperText, InputBase, Button,} from '@material-ui/core';

const ObjectTypeSelector = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    onLimitChange,
  } = props;

  let validTypes = [];
  state.sourceIDs.forEach(id=>{
    let source = state.sourceList.find(s=>s.id===id);
    source?.objectTypes?.forEach(type=>{
      if(!validTypes.includes(type)){
        validTypes.push(type)
      }
    })
  })

  return (
    <div className={classes.block}>
      <FormControl style={{width:'100%'}}>
        <InputBase
          value={state.maxLimit}
          onChange={onLimitChange}
          className={classes.inputBase}
          placeholder='Max number of results to return'
        />
        <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>Required</FormHelperText>
      </FormControl>
      <Button disabled={!state.maxLimit} style={{marginTop:20}} onClick={()=>dispatch({type:'set_step',step:3})} color='primary' variant='contained'>NEXT</Button>
    </div>
  )
}

export default withTheme()(ObjectTypeSelector);