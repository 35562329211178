import axiosSolr from "../../../axios-solr"
import { formatNumber, sendMessage, toTitleCase } from "../../../utilities";
import { defaultColumnMap } from "../../BasicSearch/MainSearch/Utils/Utils";

export const processObjectName = (name) => {
  if(!name)return '';
  if(name==='ML_MODEL')return 'ML Model';
  if(name==='DATA_PIPELINE')return 'Pipeline'
  return toTitleCase(name.replace(/_/g,' '))
}

export const loadTilesByObjectType = ({objectTypes, source }) => {
  return axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectTypes.join(' OR ')}) AND active_srt:YES AND -source_id_srt:998 AND usage_srt:(DAILY OR WEEKLY OR MONTHLY OR INFREQUENT)` + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "object_types": {
              "type": "terms",
              "field": "object_type_srt",
              "mincount":1,
              "limit":50,
            }
          }
        }
      }
    )
}

export const processTileData = ({facet, objectTypes}) => {
  if(!facet.data.facets.object_types)return []
  let buckets = facet.data.facets.object_types.buckets;
  let tiles = objectTypes.filter(o=>buckets.find(b=>b.val===o)).map(o=>({
    id:o,
    clickable:true,
    title:`${toTitleCase(o.replace(/_/g,' '))}s used`,
    value:formatNumber(buckets.find(b=>b.val===o)?.count || 0)
  }))
  return tiles
}

export const loadChangeStatusChart = async ({objectType, source, state}) => {
  let chartData;
  let validSources;
  if(state.objectCountBySource){
    validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
  }
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 AND usage_srt:(DAILY OR WEEKLY OR MONTHLY OR INFREQUENT)` + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "change_status": {
              "type": "terms",
              "field": "modification_badge_srt",
              "mincount":1,
              "limit":50,
            }
          }
        }
      }
    )
    .then(facet=>{
      let sort = {
        'NEW':1,
        'MODIFIED':2,
        'DELETED':3
      }
      if(!facet.data.facets.change_status)return;
      let buckets = facet.data.facets.change_status.buckets.sort((a,b)=>sort[a.val]-sort[b.val]);
      if(buckets.length===0)return;
      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }
      else if(validSources && validSources.length>0){
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      chartData = {
        labels:buckets.map(b=>b.val), 
        series:[{
          name:'count',
          data:buckets.map(b=>b.count),

          clickFns:buckets.map(b=>()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap[objectType]].join(','),
              modalFilter:`usage_srt:DAILY,WEEKLY,MONTHLY,INFREQUENT AND active_srt:YES AND modification_badge_srt:${b.val}${sourceFq}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadBySourceChart = async ({objectType,dbList,state, dispatch, history}) => {
  let chartData;
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 AND usage_srt:(DAILY OR WEEKLY OR MONTHLY OR INFREQUENT)`,
          rows:0,
          'json.facet':{
            "source": {
              "type": "terms",
              "field": "source_srt",
              "mincount":1,
              "limit":50,
            }
          }
        }
      }
    )
    .then(facet=>{
      if(!facet.data.facets.source)return;
      let buckets = facet.data.facets.source.buckets;
      if(buckets.length===0)return;
      chartData = {
        labels:buckets.map(b=>b.val), 
        series:[{
          name:'count',
          data:buckets.map(b=>b.count),
          clickFns:buckets.map(b=>{
            return ()=>{
              let source = dbList.find(el=>el.name.toUpperCase()===b.val);
              if(!source){
                sendMessage({
                  modalQuery:"*",
                  modalObject:objectType,
                  modalColumn:['parent_name_srt',...defaultColumnMap[objectType]].join(','),
                  modalFilter:`source_srt:${b.val} AND active_srt:YES AND usage_srt:DAILY,WEEKLY,MONTHLY,INFREQUENT`
                })
                return;
              }
              let url =  `/insights/usage?tabName=BY SOURCES&usageSourceId=${source.id}&usageSelectedTile=${objectType}`
              // window.open(url,'_blank')
              history.push(url)

              // history.push(window.location.pathname)
              let currentTabState = state.usageTabState;
              window.onpopstate = () => {
                dispatch({type:'set_usage_tab_state',usageTabState:currentTabState})
              }
            }
          })
        }]
      }
    })
  return chartData
}

export const loadbyFrequencyChart = async ({objectType, source, state, isView, isExcludeView}) => {
  let chartData;

  let validSources;
  if(state.objectCountBySource){
    validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
  }

  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType})${isView?` AND table_type_srt:view`:''}${isExcludeView?' AND -table_type_srt:view':''} AND active_srt:YES AND -source_id_srt:998 AND usage_srt:(DAILY OR WEEKLY OR MONTHLY OR INFREQUENT OR UNUSED OR UNKNOWN)` + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "frequency": {
              "type": "terms",
              "field": "usage_srt",
              "mincount":1,
              "limit":50,
            }
          }
        }
      }
    )
    .then(facet=>{
      let sort = {
        'DAILY':1,
        'WEEKLY':2,
        'MONTHLY':3,
        'INFREQUENT':4,
        'UNUSED':5,
        'UNKNOWN':6
      }
      if(!facet.data.facets.frequency)return;
      let buckets = facet.data.facets.frequency.buckets.sort((a,b)=>sort[a.val]-sort[b.val]);
      if(buckets.length===0)return;
      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }
      else if(validSources && validSources.length>0){
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      chartData = {
        labels:buckets.map(b=>b.val), 
        toggleLabel:{name:'Unused & Unknown',value:['UNUSED','UNKNOWN']},
        series:[{
          name:'count',
          data:buckets.map(b=>b.count),
          clickFns:buckets.map(b=>()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap[objectType]].join(','),
              modalFilter:`usage_srt:${b.val} AND active_srt:YES${sourceFq}` + (isView?` AND table_type_srt:view`:'') + (isExcludeView?' AND -table_type_srt:view':'')
            })
          })
        }]
      }
    })
  return chartData
}

export const loadByTrustChart = async ({objectType, source, state}) => {
  let chartData;
  let validSources;
  if(state.objectCountBySource){
    validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
  }
  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 AND usage_srt:(DAILY OR WEEKLY OR MONTHLY OR INFREQUENT)` + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "trust": {
              "type": "terms",
              "field": "trust",
              "mincount":1,
              "limit":50,
            }
          }
        }
      }
    )
    .then(facet=>{
      let sort = {
        'HIGHEST':5,
        'HIGH':4,
        'MEDIUM':3,
        'LOW':2,
        'LOWEST':1
      }
      if(!facet.data.facets.trust)return;
      let buckets = facet.data.facets.trust.buckets.sort((a,b)=>sort[a.val]-sort[b.val]);
      if(buckets.length===0)return;
      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }
      else if(validSources && validSources.length>0){
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      chartData = {
        labels:buckets.map(b=>b.val), 
        series:[{
          name:'count',
          data:buckets.map(b=>b.count),
          clickFns:buckets.map(b=>()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap[objectType]].join(','),
              modalFilter:`usage_srt:DAILY,WEEKLY,MONTHLY,INFREQUENT AND active_srt:YES AND trust:${b.val}${sourceFq}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadRecommendations = async ({objectType, source}) => {
  let recommendations = [undefined,undefined];
  let promises = [];

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND usage_srt:UNUSED AND load_status_srt:FRESH AND source_id_srt:${source.id}`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        recommendations[0] = {
          title:`Archive or decommission unused ${processObjectName(objectType)}s`,
          id:'archive_or_decommission',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap[objectType]].join(','),
              modalFilter:`usage_srt:UNUSED AND active_srt:YES AND load_status_srt:FRESH AND source_srt:${source.name}`
            })
          }
        }
      })
  )
  
  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND usage_srt:INFREQUENT AND load_status_srt:FRESH AND source_id_srt:${source.id}`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        recommendations[1] = {
          title:`Review infrequently used ${processObjectName(objectType)}s for archiving`,
          id:'infrequently_used',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap[objectType]].join(','),
              modalFilter:`usage_srt:INFREQUENT AND active_srt:YES AND load_status_srt:FRESH AND source_id_srt:${source.id}`
            })
          }
        }
      })
  )


  await Promise.all(promises)

  return recommendations.filter(r=>r)
}

export const sortByTotalUsage = (metricA, metricsB) => {
  const sum = arr => arr.reduce((a,b) => a + b, 0)
  return  sum(metricsB.dataset.y)-sum(metricA.dataset.y)
}

/////// new source usage chart

export const loadAllUsageTilesByObjectType = ({objectTypes, source }) => {
  return axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectTypes.join(' OR ')}) AND active_srt:YES AND -source_id_srt:998 ` + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "used": {
              "type": "query",
              'q':'usage_srt:(DAILY OR WEEKLY OR MONTHLY OR INFREQUENT)',
              'facet':{
                "object_types": {
                  "type": "terms",
                  "field": "object_type_srt",
                  "mincount":1,
                  "limit":50,
                }
              }
            },
            "total": {
              "type": "terms",
              "field": "object_type_srt",
              "mincount":1,
              "limit":50,
            }
          }
        }
      }
    )
}

export const processAllUsageTileData = ({facet, objectTypes}) => {
  let tiles = [];
  if(facet.data.facets.used){
    let buckets = facet.data.facets.used.object_types.buckets;
    tiles = objectTypes.filter(o=>buckets.find(b=>b.val===o)).map(o=>{
      let totalCount = facet.data.facets.total.buckets.find(b=>b.val===o)?.count || 0;
      return {
        id:o,
        clickable:true,
        title:`of ${formatNumber(totalCount)} ${toTitleCase(o.replace(/_/g,' '))}s used`,
        value:formatNumber(buckets.find(b=>b.val===o)?.count || 0)
      }
    })
  }
  return tiles
}

export const loadUnusedByFrequencyChart = async ({objectType, source, state}) => {
  let chartData;

  let validSources;
  if(state.objectCountBySource){
    validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
  }

  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 AND usage_srt:(UNUSED OR UNKNOWN)` + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "usage": {
              "type": "terms",
              "field": "usage_srt",
              "mincount":0,
              "limit":2,
              "facet":{
                "frequency": {
                  "type": "terms",
                  "field": "load_frequency_srt",
                  "mincount":0,
                  "limit":50,
                }
              }
            }
          }
        }
      }
    )
    .then(facet=>{
      let sort = {
        'DAILY':1,
        'WEEKLY':2,
        'MONTHLY':3,
        'INFREQUENT':4,
        'UNUSED':5,
        'UNKNOWN':6
      }
      if(!facet.data.facets.usage)return;
      // let buckets = facet.data.facets.frequency.buckets.sort((a,b)=>sort[a.val]-sort[b.val]);
      let unusedBuckets = facet.data.facets.usage.buckets.find(b=>b.val==='UNUSED')?.frequency?.buckets || [];
      let unknownBuckets = facet.data.facets.usage.buckets.find(b=>b.val==='UNKNOWN')?.frequency?.buckets || [];
      unusedBuckets.sort((a,b)=>sort[a.val]-sort[b.val]);
      unknownBuckets.sort((a,b)=>sort[a.val]-sort[b.val]);
      let commonLabels = [];
      ['DAILY','WEEKLY','MONTHLY','INFREQUENT','UNUSED','UNKNOWN'].forEach(f=>{
        if(unusedBuckets.find(b=>b.val===f) || unknownBuckets.find(b=>b.val===f)){
          commonLabels.push(f)
        }
      })
      if(commonLabels.length===0)return;
      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }
      else if(validSources && validSources.length>0){
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      chartData = {
        labels:commonLabels, 
        // toggleLabel:{name:'Not Loaded',value:['NOT LOADED']},
        series:[
          {
            name:'unused',
            colour: '#FC642D',
            data:commonLabels.map(f=>unusedBuckets.find(b=>b.val===f)?.count || 0),
            clickFns:unusedBuckets.map(b=>()=>{
              sendMessage({
                modalQuery:"*",
                modalObject:objectType,
                modalColumn:['parent_name_srt',...defaultColumnMap[objectType],'load_status_srt','load_frequency_srt'].join(','),
                modalFilter:`load_frequency_srt:${b.val} AND usage_srt:UNUSED AND active_srt:YES${sourceFq}`
              })
            })
          },
          {
            name:'unknown',
            colour: '#aaa',
            data:commonLabels.map(f=>unknownBuckets.find(b=>b.val===f)?.count || 0),
            clickFns:unknownBuckets.map(b=>()=>{
              sendMessage({
                modalQuery:"*",
                modalObject:objectType,
                modalColumn:['parent_name_srt',...defaultColumnMap[objectType],'load_status_srt','load_frequency_srt'].join(','),
                modalFilter:`load_frequency_srt:${b.val} AND usage_srt:UNKNOWN AND active_srt:YES${sourceFq}`
              })
            })
          },
        ]
      }
    })
    .catch(e=>{
      console.log(e)
    })
  return chartData
}

export const loadDailyByFrequencyChart = async ({objectType, source, state}) => {
  let chartData;

  let validSources;
  if(state.objectCountBySource){
    validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
  }

  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998 AND usage_srt:DAILY` + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            "frequency": {
              "type": "terms",
              "field": "load_frequency_srt",
              "mincount":1,
              'missing':true,
              "limit":50,
            }
          }
        }
      }
    )
    .then(facet=>{
      let sort = {
        'DAILY':1,
        'WEEKLY':2,
        'MONTHLY':3,
        'INFREQUENT':4,
        'UNUSED':5
      }
      if(!facet.data.facets.frequency)return;
      let buckets = facet.data.facets.frequency.buckets.sort((a,b)=>sort[a.val]-sort[b.val]);
      if(facet.data.response.numFound===0)return;
      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }
      else if(validSources && validSources.length>0){
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }

      let labels = buckets.map(b=>b.val);
      let data = buckets.map(b=>b.count);
      let clickFns = buckets.map(b=>()=>{
        sendMessage({
          modalQuery:"*",
          modalObject:objectType,
          modalColumn:['parent_name_srt',...defaultColumnMap[objectType],'load_status_srt','load_frequency_srt'].join(','),
          modalFilter:`load_frequency_srt:${b.val} AND usage_srt:DAILY AND active_srt:YES${sourceFq}`
        })
      });

      // if missing
      if(facet.data.facets.frequency?.missing?.count){
        labels.push('NOT LOADED')
        data.push(facet.data.facets.frequency.missing.count)
        clickFns.push(()=>{
          sendMessage({
            modalQuery:"*",
            modalObject:objectType,
            modalColumn:['parent_name_srt',...defaultColumnMap[objectType],'load_status_srt','load_frequency_srt'].join(','),
            modalFilter:`load_frequency_srt:NO LOAD FREQUENCY AND usage_srt:DAILY AND active_srt:YES${sourceFq}`
          })
        })
      }

      chartData = {
        labels, 
        toggleLabel:{name:'Not Loaded',value:['NOT LOADED']},
        series:[{
          name:'count',
          data,
          clickFns,
        }]
      }
    })
  return chartData
}

export const loadSizebyUsage = async ({objectType, source, state}) => {

  let chartData;

  let validSources;
  if(state.objectCountBySource){
    validSources = Object.keys(state.objectCountBySource).filter(k=>state.objectCountBySource[k].objects.find(o=>o.val===objectType))
  }

  await axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`object_type_srt:(${objectType}) AND active_srt:YES AND -source_id_srt:998` + (source?` AND source_id_srt:${source.id}`:''),
          rows:0,
          'json.facet':{
            'frequency':{
              type:'terms',
              field:'usage_srt',
              limit:30,
              'facet':{
                'avg':"sum(size_mb_srt)"
              }
            },
          }
        }
      }
    )
    .then(facet=>{
      let sort = {
        'DAILY':1,
        'WEEKLY':2,
        'MONTHLY':3,
        'INFREQUENT':4,
        'UNUSED':5,
        'UNKNOWN':6
      }
      if(!facet.data.facets.frequency)return;
      let buckets = facet.data.facets.frequency.buckets.sort((a,b)=>sort[a.val]-sort[b.val]);
      if(buckets.length===0)return;
      let sourceFq = '';
      if(source){
        sourceFq = ` AND source_srt:${source.name}`
      }
      else if(validSources && validSources.length>0){
        sourceFq = ` AND source_srt:${validSources.join(',')}`
      }
      chartData = {
        labels:buckets.map(b=>b.val), 
        toggleLabel:{name:'Unused & Unknown',value:['UNUSED','UNKNOWN']},
        series:[{
          name:'count',
          data:buckets.map(b=>b.avg),
          clickFns:buckets.map(b=>()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap.TABLE,'size_mb_srt'].join(','),
              modalSort:'size_mb_srt desc',
              modalFilter:`usage_srt:${b.val} AND active_srt:YES${sourceFq}`
            })
          })
        }]
      }
    })
  return chartData
}

export const loadBySourceRecommendations = async ({objectType, source}) => {
  let recommendations = [];
  let promises = [];
  
  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND usage_srt:DAILY AND -load_frequency_srt:[* TO *] AND source_id_srt:${source.id}`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        recommendations.push({
          title:`Review daily used ${processObjectName(objectType)}s that are not loaded`,
          id:'daily_used_stale',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap[objectType],'load_frequency_srt','load_status_srt'].join(','),
              modalFilter:`usage_srt:DAILY AND active_srt:YES AND load_frequency_srt:NO LOAD FREQUENCY AND source_id_srt:${source.id}`
            })
          }
        })
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND usage_srt:UNUSED AND source_id_srt:${source.id}` + (objectType==='TABLE'?' AND -table_type_srt:VIEW':''),
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        recommendations.push({
          title:`Review unused ${processObjectName(objectType)}s for archiving`,
          id:'unused',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap[objectType]].join(','),
              modalFilter:`usage_srt:UNUSED AND active_srt:YES AND source_id_srt:${source.id}` + (objectType==='TABLE'?' AND -table_type_srt:VIEW':''),
            })
          }
        })

        recommendations.push({
          title:`Review unused ${processObjectName(objectType)}s for archiving`,
          id:'unused_size',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap.TABLE,'size_mb_srt'].join(','),
              modalSort:'size_mb_srt desc',
              modalFilter:`usage_srt:UNUSED AND active_srt:YES AND  source_id_srt:${source.id}` + (objectType==='TABLE'?' AND -table_type_srt:VIEW':''),
            })
          }
        })
      })
  )

  if(objectType==='TABLE'){
    promises.push(
      axiosSolr
        .get(
          '/solr/search/select',{
            params: {
              q: '*:*',
              fq:`object_type_srt:(${objectType}) AND active_srt:YES AND usage_srt:UNUSED AND source_id_srt:${source.id} AND table_type_srt:VIEW`,
              rows:0,
            }
          }
        )
        .then(response=>{
          if(response.data.response.numFound===0)return;
          recommendations.push({
            title:`Review unused Views for archiving`,
            id:'unused_view',
            countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
            onClick:()=>{
              sendMessage({
                modalQuery:"*",
                modalObject:objectType,
                modalColumn:['parent_name_srt',...defaultColumnMap['TABLE']].join(','),
                modalFilter:`usage_srt:UNUSED AND active_srt:YES AND source_id_srt:${source.id} AND table_type_srt:VIEW`
              })
            }
          })
        })
    )
  }
  
  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND usage_srt:INFREQUENT AND source_id_srt:${source.id}`
                + (objectType==='TABLE'?` AND -table_type_srt:VIEW`:'')
              ,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        recommendations.push({
          title:`Review infrequently used ${processObjectName(objectType)}s for archiving`,
          id:'infrequently_used',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap[objectType]].join(','),
              modalFilter:`usage_srt:INFREQUENT AND active_srt:YES AND source_id_srt:${source.id}` + (objectType==='TABLE'?` AND -table_type_srt:VIEW`:'')
            })
          }
        })

        recommendations.push({
          title:`Review infrequently used ${processObjectName(objectType)}s for archiving`,
          id:'infrequently_used_size',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap.TABLE,'size_mb_srt'].join(','),
              modalSort:'size_mb_srt desc',
              modalFilter:`usage_srt:INFREQUENT AND active_srt:YES AND source_id_srt:${source.id}` + (objectType==='TABLE'?` AND -table_type_srt:VIEW`:'')
            })
          }
        })
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND usage_srt:UNUSED AND load_frequency_srt:DAILY AND source_id_srt:${source.id}`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        recommendations.push({
          title:`Review daily loaded ${processObjectName(objectType)}s that are unused`,
          id:'daily_unused',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap[objectType],'load_frequency_srt','load_status_srt'].join(','),
              modalFilter:`usage_srt:UNUSED AND active_srt:YES AND load_frequency_srt:DAILY AND source_id_srt:${source.id}`
            })
          }
        })
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND usage_srt:UNUSED AND load_frequency_srt:WEEKLY AND source_id_srt:${source.id}`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        recommendations.push({
          title:`Review weekly loaded ${processObjectName(objectType)}s that are unused`,
          id:'weekly_unused',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap[objectType],'load_frequency_srt','load_status_srt'].join(','),
              modalFilter:`usage_srt:UNUSED AND active_srt:YES AND load_frequency_srt:WEEKLY AND source_id_srt:${source.id}`
            })
          }
        })
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND usage_srt:UNUSED AND load_frequency_srt:MONTHLY AND source_id_srt:${source.id}`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        recommendations.push({
          title:`Review monthly loaded ${processObjectName(objectType)}s that are unused`,
          id:'monthly_unused',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap[objectType],'load_frequency_srt','load_status_srt'].join(','),
              modalFilter:`usage_srt:UNUSED AND active_srt:YES AND load_frequency_srt:MONTHLY AND source_id_srt:${source.id}`
            })
          }
        })
      })
  )

  // do the same daily_unused, weekly_unused, monthly_unused for usage: UNKNOWN

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND usage_srt:UNKNOWN AND load_frequency_srt:DAILY AND source_id_srt:${source.id}`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        recommendations.push({
          title:`Review daily loaded ${processObjectName(objectType)}s that have unknown usage`,
          id:'daily_unknown',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap[objectType],'load_frequency_srt','load_status_srt'].join(','),
              modalFilter:`usage_srt:UNKNOWN AND active_srt:YES AND load_frequency_srt:DAILY AND source_id_srt:${source.id}`
            })
          }
        })
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND usage_srt:UNKNOWN AND load_frequency_srt:WEEKLY AND source_id_srt:${source.id}`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        recommendations.push({
          title:`Review weekly loaded ${processObjectName(objectType)}s that have unknown usage`,
          id:'weekly_unknown',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap[objectType],'load_frequency_srt','load_status_srt'].join(','),
              modalFilter:`usage_srt:UNKNOWN AND active_srt:YES AND load_frequency_srt:WEEKLY AND source_id_srt:${source.id}`
            })
          }
        })
      })
  )

  promises.push(
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectType}) AND active_srt:YES AND usage_srt:UNKNOWN AND load_frequency_srt:MONTHLY AND source_id_srt:${source.id}`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)return;
        recommendations.push({
          title:`Review monthly loaded ${processObjectName(objectType)}s that have unknown usage`,
          id:'monthly_unknown',
          countTxt:formatNumber(response.data.response.numFound) + ' ' + processObjectName(objectType) + 's',
          onClick:()=>{
            sendMessage({
              modalQuery:"*",
              modalObject:objectType,
              modalColumn:['parent_name_srt',...defaultColumnMap[objectType],'load_frequency_srt','load_status_srt'].join(','),
              modalFilter:`usage_srt:UNKNOWN AND active_srt:YES AND load_frequency_srt:MONTHLY AND source_id_srt:${source.id}`
            })
          }
        })
      })
  )

  await Promise.all(promises)

  return recommendations.filter(r=>r)
}