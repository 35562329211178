import { loadIssueAssignedToChart, loadIssueCreatedByChart, loadIssueCreatedChart, loadIssueIsAssignedChart, loadIssueOpenRateChart, loadIssueSeverityChart, loadIssueSourceChart, loadIssueTypeChart, loadRecommendations, loadTiles, processTileData } from './loadUtils';

export const loadTileData = async ({data, setData,source}) => {
  if(!data.tileData && !data.tileDataLoading){
    setData({...data,tileDataLoading:true,tileDataError:false});
    try{
      let tileData = [];
      
      await loadTiles().then(facet=>{
        tileData = processTileData({facet})
      });

      setData({
        ...data,
        tileDataLoading:false,
        tileDataError:false,
        tileData,
        selectedTile:tileData[0]?.id
      })

    }catch(error){
      console.log(error);
      setData({...data,tileDataError:true,titleDataLoading:false,tileData:undefined});
    }
  }
}

export const loadGraphData = async ({data,setData}) => {
  let graphData = data[`${data.selectedTile}_graphData`];
  let graphLoading = data[`${data.selectedTile}_graphDataLoading`];
  if(!graphData && !graphLoading){
    setData({
      ...data,
      [`${data.selectedTile}_graphDataLoading`]:true,
      [`${data.selectedTile}_graphDataError`]:false,
      [`${data.selectedTile}_recommendationLoading`]:true,
      [`${data.selectedTile}_recommendationError`]:false,
    });

    try{
      let status = data.selectedTile;
      let chartsData = {}
      let recommendations;

      let promises = [
        loadIssueSeverityChart({status}).then(d=>chartsData[`${status}_BY_SEVERITY`] = d),
        loadIssueTypeChart({status}).then(d=>chartsData[`${status}_BY_TYPE`] = d),
        status!=='CLOSED'?loadIssueIsAssignedChart({status}).then(d=>chartsData[`${status}_IS_ASSIGNED`] = d):undefined,
        loadIssueSourceChart({status}).then(d=>chartsData[`${status}_BY_SOURCE`] = d),
        status!=='CLOSED'?loadIssueCreatedChart({status}).then(d=>chartsData[`${status}_BY_CREATED`] = d):undefined,
        status==='OPEN'?loadIssueOpenRateChart({status}).then(d=>chartsData[`${status}_OPEN_RATE`] = d):undefined,
        status!=='CLOSED'?loadIssueAssignedToChart({status}).then(d=>chartsData[`${status}_TOP_ASSIGNEE`] = d):undefined,
        status==='OPEN'?loadIssueCreatedByChart({status}).then(d=>chartsData[`${status}_TOP_REPORTER`] = d):undefined,
        status!=='CLOSED'?loadRecommendations({status}).then(d=>recommendations = d):undefined
      ].filter(el=>el)
      await Promise.all(promises)

      setData({
        ...data,
        [`${data.selectedTile}_graphDataLoading`]:false,
        [`${data.selectedTile}_graphDataError`]:false,
        [`${data.selectedTile}_graphData`]:chartsData,
        [`${data.selectedTile}_recommendationData`]:recommendations,
        [`${data.selectedTile}_recommendationLoading`]:false,
        [`${data.selectedTile}_recommendationError`]:false,
      })

    }catch(error){
      console.log(error);
      setData({
        ...data,
        [`${data.selectedTile}_graphDataLoading`]:false,
        [`${data.selectedTile}_graphDataError`]:true,
        [`${data.selectedTile}_graphData`]:undefined,
        [`${data.selectedTile}_recommendationLoading`]:false,
        [`${data.selectedTile}_recommendationError`]:true,
        [`${data.selectedTile}_recommendationData`]:undefined
      })
    }
  }
}
