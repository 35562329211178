import React from 'react';
import { withTheme, withStyles,  Snackbar, SnackbarContent} from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = theme => ({
  snackbar:{
    // background:theme.palette.snackbarBackground.main
  },
})

function SnackBar(props) {

  const {
    classes,
    theme,
    snackBarOpen,
    setSnackBarOpen,
    message,
    onClose,
    buttons,
    type,
    keepOpen,
    autoHideDuration = 5000
  } = props;
  
  return (
    <Snackbar
      open={snackBarOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={keepOpen?999999:autoHideDuration}
      onClose={()=>{setSnackBarOpen(false);onClose && onClose();}}
      style={{marginLeft: 50,marginTop:24}}
    >
      <SnackbarContent
        className={classes.snackbar}
        style={{background:type==='error'?'#b00020':theme.palette.snackbarBackground.main}}
        message={<span style={{display:'flex',alignItems:'flex-start',whiteSpace:'pre-wrap',color:type==='error'?'#fff':theme.palette.snackbarContent.main}}>
          {message}
        </span>}
        action={buttons}
      />
    </Snackbar>
  )
}

SnackBar.propTypes = {
  snackBarOpen: PropTypes.bool.isRequired,
  setSnackBarOpen: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  buttons: PropTypes.array,
  autoHideDuration: PropTypes.number,
  type: PropTypes.string,
  keepOpen: PropTypes.bool
}


export default withTheme()(withStyles(styles)(SnackBar));