import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import moment from 'moment'

const initialState = {
  pageCache: []
};


const pruneLoadingStates = (obj,loadingObj) => {
  if(!obj)return null;
  let prunedObj = {}
  Object.keys(obj).forEach(el=>{
    if(loadingObj){
      prunedObj[el] = false;
      return;
    }
    if(!el.toLowerCase().includes('loading')){
      prunedObj[el] = obj[el];
    }else{
      if(typeof(obj[el])==='object' && !Array.isArray(obj[el])){
        prunedObj[el] = pruneLoadingStates(obj[el],true)
      }else{
        prunedObj[el] = false;
      }
    }
  })
  return prunedObj
}

const storePageCache = (state, action) => {
  let url = action.data.cacheID.split('?')[0]
  let newPageCache = state.pageCache.filter(el=>el.cacheID!==url);
  let cache = {...pruneLoadingStates(action.data),cacheID:url,updated:moment()};
  newPageCache = [cache,...newPageCache].slice(0,10)
  return updateObject(state, {
    pageCache: newPageCache
  })
}

const removePageCache = (state, action) => {
  return updateObject(state,{
    pageCache: state.pageCache.filter(el=>!el.cacheID.split('?')[0].includes(action.data))
  })
}

const bulkRemovePageCache = (state, action) => {
  let filterFn = action.data;
  return updateObject(state,{
    pageCache: state.pageCache.filter(el=>filterFn(el))
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_PAGE_CACHE: return storePageCache(state, action);
    case actionTypes.REMOVE_PAGE_CACHE: return removePageCache(state, action);
    case actionTypes.BULK_REMOVE_PAGE_CACHE: return bulkRemovePageCache(state, action);
    default: return state;
  }
  
};

export default reducer;