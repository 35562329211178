import React, { useReducer, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { toTitleCase, removeUrlQueryArg } from '../../utilities';
import Body from '../../components/CreateCollection/Body/Body';
import useGetSolr from '../../hooks/useGetSolr';
import useGetCerebrum from '../../hooks/useGetCerebrum'
import 'url-search-params-polyfill';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';

const initialState = {
  activeStep: 0,
  editMode:false,
  dataRoles:[],
  collectionType:'DATA_GOVERNANCE',
  collectionName:'',
  collectionShortName:'',
  enableDQ:false,
  description:'',
  categoryData:null,
  categoryLoading:null,
  categoryError:null,
  propertyData:null,
  propertyLoading:null,
  propertyError:null,
  rolesData:null,
  rolesLoading:null,
  rolesError:null,
  linkBy:[],
  editableBy:['KADA_DATA_GOV_USER','KADA_DATA_MANAGER'],
  idSeq:1,
  // isMultiValue:true,
  properties:[
  ],
  shouldUpdate:false
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_should_update':
      return {
        ...state,
        shouldUpdate: action.shouldUpdate
      } 
    case 'set_active_step':
      return {
        ...state,
        activeStep: action.activeStep
      }
    case 'set_data_roles':{
      return {
        ...state, dataRoles:action.dataRoles
      }
    }
    case 'set_category_data':
      return {
        ...state,
        categoryData:action.categoryData,
        categoryLoading:action.categoryLoading,
        categoryError:action.categoryError
      }
    case 'set_property_data':
      return {
        ...state,
        propertyData:action.propertyData,
        propertyError:action.propertyError,
        propertyLoading:action.propertyLoading
      }
    case 'set_roles_data':
      return {
        ...state,
        rolesData:action.rolesData,
        rolesError:action.rolesError,
        rolesLoading:action.rolesLoading
      }
    case 'set_collection_type':
      return {
        ...state,
        collectionType:action.collectionType
      }
    case 'set_enable_dq':
      return {
        ...state,
        enableDQ:action.enableDQ
      }
    case 'set_id_seq':
      return {
        ...state,
        idSeq:action.idSeq
      }
    case 'set_collection_name':
      return {
        ...state,
        collectionName:action.collectionName
      }
    case 'set_edit_mode':
      return {
        ...state,
        editMode: action.editMode
      }
    case 'set_collection_short_name':
      return {
        ...state,
        collectionShortName:action.collectionShortName
      }
    case 'set_description':
      return {
        ...state,
        description:action.description
      }

    case 'set_editable_by':
      let editableByChecked = action.checked;
      let editableByItem = action.item;
      let newArray = [];
      if(editableByChecked){
        newArray = [...state.editableBy,editableByItem];
      }else{
        newArray = state.editableBy.filter(el=>el!==editableByItem);
      }
      return {
        ...state,
        editableBy:newArray
      }
    case 'set_link_by':
      let linkByChecked = action.checked;
      let linkByItem = action.item;
      newArray = [];
      if(linkByChecked){
        newArray = [...state.linkBy,linkByItem];
      }else{
        newArray = state.linkBy.filter(el=>el!==linkByItem);
      }
      return {
        ...state,
        linkBy:newArray
      }
    case 'set_properties':
      return {
        ...state,
        properties:action.properties
      }
    case 'set_created_collection':
      return {
        ...state,
        createdCollection:action.createdCollection
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

const styles = theme => ({
  root: {
  },
  subtitle:{
    fontSize:13.75,
    color:theme.palette.primaryText.light
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  title:{
    color:theme.palette.header.main,
  },
  underlineOnHover: {
    color:theme.palette.primaryText.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }
})

function CreateCollection(props) {

  const {
    classes,
    history,
    sessionData,
    presetStates,
    onSave,
    onCancel,
    onActiveStepChange
  } = props;

  const [state, dispatch] = useReducer(reducer, presetStates?{...initialState,...presetStates}:initialState);


  const urlSearch = new URLSearchParams(window.location.search);
  const category = urlSearch.get('category');

  const {
    data: categoryData,
    loading:categoryLoading,
    error:categoryError
  } = useGetCerebrum({url:'/api/collections/categories'})

  const {
    data: propertyData,
    loading:propertyLoading,
    error:propertyError
  } = useGetCerebrum({url:'/api/datatypes',params:{per_page:50, filter:'COLLECTIONS'}})

  const {
    data: rolesData,
    loading:rolesLoading,
    error:rolesError
  } = useGetCerebrum({url:'/api/roles'})

  const {
    data:dataRoles
  } = useGetSolr({
    url:`/solr/search/select`,
    params:{
      q:'*',
      fq:'collection_srt:"DATA ROLE"',
      fl:'name:name_txt,id',
      per_page:200
    }
  })

  useEffect(()=>{
    if(category && state.categoryData && state.categoryData.find(el=>el.name===category)){
      dispatch({type:'set_collection_type',collectionType:category})
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['category']}));
    }
  // eslint-disable-next-line
  },[state.categoryData])

  useEffect(()=>{
    if(!dataRoles)return;
    dispatch({type:'set_data_roles',dataRoles:dataRoles.response.docs})
  },[dataRoles])

  useEffect(()=>{
    dispatch({type:'set_category_data',categoryData:categoryData?categoryData.items:undefined,categoryError,categoryLoading})
  },[categoryData,categoryError,categoryLoading])

  useEffect(()=>{
    dispatch({type:'set_property_data',propertyData:propertyData?propertyData.items:undefined,propertyError,propertyLoading})
  },[propertyData,propertyError,propertyLoading])

  useEffect(()=>{
    dispatch({type:'set_roles_data',rolesData:rolesData?rolesData.items:undefined,rolesError,rolesLoading})
  },[rolesData,rolesError,rolesLoading])

  let title,subtitle;
  if(state.editMode || state.createdCollection){
    title='Edit ' + toTitleCase(state.editMode?state.collectionName:state.createdCollection.name) + ` details`
    subtitle=`Use this form to edit the properties for this instance. Editing and removing properties may impact a large number of instances and can take some time to complete.`
  }
  else{
    title=`Create a Collection`;
    subtitle=`A collection contain instances that can be used to link up parts of the ecosystem. `+
    `For example you can create a collection called “Source of Truth with instances for Customer, `+
    `Sales and Transactions and then tag tables and reports to each instance to help organise your ecosystem.`
  }

  let breadcrumbs = 
    [{object_name:'platform settings',name:'platform settings',id:''},{object_name:'collection_admin',name:'collection',id:'',onClick:onCancel || (()=>history.push('/admin/collection'))}].map((el) =>
      <span data-testid={`breadcrumb-${el.object_name.toLowerCase()}`} className={el.object_name==='collection_admin'?classes.underlineOnHover:classes.normalText} onClick={el.onClick}>{el.name.toUpperCase()}</span>
    ).reduce((prev, curr) => [prev, ' / ', curr])

  return (
      <ProfileLayout
        noPadding
        header={
          <ProfileHeader
            label="create_collection_admin"
            iconLabel='collection'
            title={title}
            description={subtitle}
            subtitle={breadcrumbs}
            minHeight={136}
            // buttons={buttons}
          />
        }
        body={
          <Body
            state={state}
            dispatch={dispatch}
            history={history}
            sessionData={sessionData}
            onSave={onSave}
            onCancel={onCancel}
            onActiveStepChange={onActiveStepChange}
          />
        }
      >
      </ProfileLayout>
  )
}

CreateCollection.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CreateCollection);

