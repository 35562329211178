import React, { useEffect } from 'react';
import { withTheme,withStyles, Typography, LinearProgress, Select, MenuItem, Checkbox, InputBase, IconButton} from '@material-ui/core';
// import CountBarChart from './CountBarChart';
// import DepthChart from './DepthChart';
import ImpactSummary from './ImpactSummary'
import TabBar from '../../UI/TabBar/TabBar'
// import Details from '../Details/Details'
// import Map from '../Map/Map'
import DataSummaryBody from './DataSummary/DataSummaryBody';
import UserSummaryBody from './UserSummary/UserSummaryBody';
import CollectionSummaryBody from './CollectionSummary/CollectionSummaryBody';
import Details from './Details/Details';
import Map from './Map/Map';
import { getDispFields, getIconComponent, mapObjectName, removeUrlQueryArg } from '../../../utilities';
import AllSummaryBody from './AllSummary/AllSummaryBody';

const styles = theme => ({
  root: {

  },
  barColor: {
    backgroundColor:'#D8D8D8'
  },
  barCompleteColor:{
    backgroundColor:'#001861'
  },
  selector:{
    ...theme.components.titleSelector,
    height:24,
    '& div div':{
      fontSize:13.75,
      paddingLeft:8,
      paddingTop:0,
      paddingBottom:0
    }
  },
  inputBase:{
    ...theme.components.inputBase,
    width:'100%',
    height:48
  },
})

const Results = props => {

  const {
    classes,
    theme,
    history,
    state,
    dispatch,
    direction
  } = props; 
  
  const [searchValue, setSearchValue] = React.useState('');

  useEffect(()=>{
    return ()=>{
      window.onpopstate=undefined;
    }
  // eslint-disable-next-line
  },[])

  let componnetKey = state.historyObject?.id || 'all'

  window.onpopstate = () => {
    dispatch({type:'set_tab_state',tabState:1})
    window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['extractId']}));
  }

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.resultTabState])


  let tabOptions = ['DASHBOARD','DETAILS'];
  if(['data','content'].includes(state.selectedDashboard) && state.historyObject)tabOptions = ['DASHBOARD','MAP','DETAILS'];
  if(state.selectedDashboard==='people' && state.resultUserType!=='user')tabOptions = ['DETAILS']

  useEffect(()=>{
    if(state.multiHistoryObjects){
      if(state.historyObject){
        dispatch({type:'set_selected_dashboard',selectedDashboard:'data'})
      }else{
        dispatch({type:'set_selected_dashboard',selectedDashboard:'all'})
        dispatch({ type: 'set_result_tab_state', resultTabState: 'DASHBOARD' })
      }
    }
    // eslint-disable-next-line
  },[state.multiHistoryObjects,state.historyObject])

  useEffect(()=>{
    if(!['data','content'].includes(state.selectedDashboard) && state.resultTabState==='MAP'){
      dispatch({ type: 'set_result_tab_state', resultTabState: 'DASHBOARD' })
    }
    if(state.selectedDashboard==='people' && state.resultUserType!=='user'){
      dispatch({ type: 'set_result_tab_state', resultTabState: 'DETAILS' })
    }
    // eslint-disable-next-line
  },[state.selectedDashboard, state.resultUserType])

  if(state.extractLoading)return (
    <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',marginTop:30}}>
      <Typography style={{fontSize:13.75,marginTop:60, color:theme.palette.primaryText.main}}>Generating results</Typography>
      <LinearProgress color='secondary' style={{width:400,marginTop:24}}/>
    </div>
  )
  if(state.extractError)return (
    <div style={{width:'100%',display:'flex',flexDirection:'column',alignItems:'center',marginTop:30}}>
      <Typography style={{fontSize:40,color:theme.palette.primaryText.light}}>¯\_(ツ)_/¯</Typography>
      <Typography style={{fontSize:13.75,marginTop:20, color:theme.palette.primaryText.light}}>Error occurred generating results, please try again.</Typography>
    </div>
  )
  if(!state.extractData)return <div></div>
  
  let bodyContent;
  if(state.selectedDashboard==='all'){
    switch(state.resultTabState){
      case 'DASHBOARD':
        bodyContent = <AllSummaryBody  key={componnetKey+'summary-body'} variant="overall" state={state} dispatch={dispatch} selectedTile={state.selectedDashboard} direction={direction}/>
        break;
      case 'DETAILS':
        bodyContent = <Details  key={componnetKey+'detail'} state={state} dispatch={dispatch} selectedTile={state.selectedDashboard} direction={direction}/>
        break;
      default:
    }
  }
  else if(state.selectedDashboard==='data'){
    switch(state.resultTabState){
      case 'DASHBOARD':
        bodyContent = <DataSummaryBody key={componnetKey + 'data'} state={state} dispatch={dispatch} variant='data' direction={direction}/>
        break;
      case 'MAP':
        bodyContent = <Map  key={componnetKey+'map'} state={state} dispatch={dispatch} history={history} direction={direction}/>
        break;
      case 'DETAILS':
        bodyContent = <Details  key={componnetKey+'detail'} state={state} dispatch={dispatch} selectedTile={state.selectedDashboard} direction={direction}/>
        break;
      default:
    }
  }
  else if(state.selectedDashboard==='content'){
    switch(state.resultTabState){
      case 'DASHBOARD':
        bodyContent = <DataSummaryBody  key={componnetKey + 'content'} state={state} dispatch={dispatch} variant='content'  direction={direction}/>
        break;
      case 'MAP':
        bodyContent = <Map key={componnetKey+'map'} state={state} dispatch={dispatch} history={history} direction={direction}/>
        break;
      case 'DETAILS':
        bodyContent = <Details key={componnetKey+'detail'} state={state} dispatch={dispatch} selectedTile={state.selectedDashboard} direction={direction}/>
        break;
      default:
    }
  }
  else if(state.selectedDashboard==='people'){
    switch(state.resultTabState){
      case 'DASHBOARD':
        bodyContent = <UserSummaryBody  key={componnetKey+'people'} state={state} dispatch={dispatch}  direction={direction}/>
        break;
      case 'DETAILS':
        bodyContent = <Details key={componnetKey+'detail'} state={state} dispatch={dispatch} selectedTile={state.selectedDashboard} direction={direction}/>
        break;
      default:
    }
  }else{
    switch(state.resultTabState){
      case 'DASHBOARD':
        bodyContent = <CollectionSummaryBody direction={direction}  key={componnetKey + state.selectedDashboard} state={state} dispatch={dispatch} collectionID={state.selectedDashboard}/>
        break;
      case 'DETAILS':
        bodyContent = <Details  key={componnetKey+'detail'} state={state} dispatch={dispatch} selectedTile={state.selectedDashboard} direction={direction}/>
        break;
      default:
    }
  }

  const getObjectTypes  = objects => {
    let objectTypes = [];
    objects.forEach(o=>{
      if(!objectTypes.includes(o.object_type_txt)){
        objectTypes.push(o.object_type_txt)
      }
    })
    return objectTypes;
  }
  
  return (
    <div className={classes.root}>
      <div style={{display:'flex',marginBottom:24,alignItems:'center'}}>
        {
          state.multiHistoryObjects && 
          <>
            <Typography style={{fontSize:13.75,marginRight:8}}>
              ASSESSMENT SCOPE:
            </Typography>
            <Select
              renderValue={value=>{
                if(value==='all')return `ALL (${state.multiHistoryObjects.length})`
                return state.multiHistoryObjects.find(s=>s.id===value)?.name_txt?.toUpperCase()
              }}
              value={state.historyObject?.id || 'all'}
              className={classes.selector}
              disableUnderline
            >
              <div style={{marginTop:-6}}>
                <InputBase
                  className={classes.inputBase}
                  value={searchValue}
                  onChange={e=>{
                    setSearchValue(e.target.value)
                  }}
                  placeholder='Search'
                  onClick={e=>{
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  endAdornment={
                    <IconButton 
                      disabled={searchValue===''} 
                      onClick={()=>setSearchValue('')}
                      style={{width:32,height:32,marginRight:6}}
                    >
                      {getIconComponent({label:searchValue===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  }
                />
              </div>
              <div style={{padding:'0px 16px',marginBottom:16,marginTop:16,display:'flex',justifyContent:"space-between",alignItems:'center'}}>
                <Typography color='primary' style={{fontSize:12,letterSpacing:1.5}}>{state.historyObject?1:state.multiHistoryObjects.length} SELECTED</Typography>
                <Typography 
                  color='secondary' 
                  style={{fontSize:12,letterSpacing:1.5,cursor:"pointer"}} 
                  onClick={()=>{
                    dispatch({type:'set_history_object'})
                  }}
                >
                  RESET
                </Typography>
              </div>
              {
                getObjectTypes(state.multiHistoryObjects.filter(o=>!searchValue.trim() || o.name_txt.toLowerCase().includes(searchValue.toLowerCase()))).map(ot=>(
                  <div style={{marginBottom:16}}>
                    <Typography style={{fontSize:12,color:theme.palette.primaryText.main,marginLeft:16,letterSpacing:1.5,marginBottom:6}}>{mapObjectName(ot).replace(/_/g,' ')}S</Typography>
                    {
                      [...state.multiHistoryObjects]
                        .filter(o=>!searchValue.trim() || o.name_txt.toLowerCase().includes(searchValue.toLowerCase()))
                        .filter(ho=>ho.object_type_txt===ot)
                        .sort((a,b)=>{
                          let labelIds = state.summaryChartData.overall?.byObjectData?.labelIds
                          if(labelIds){
                            return Math.max(labelIds.indexOf(a.id),0) - Math.max(labelIds.indexOf(b.id),0)
                          }
                          return 0
                        })
                        .map(ho=>(
                          <MenuItem 
                            style={{borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,height:56,boxSizing:'border-box',maxWidth:'90vw',overflow:'hidden'}} 
                            key={ho.id} 
                            value={ho.id}
                            onClick={()=>{
                              if(state.historyObject?.id===ho.id)return;
                              dispatch({type:'reset_result'})
                              setTimeout(() => {
                                dispatch({type:'set_history_object',historyObject:state.multiHistoryObjects.find(s=>s.id===ho.id)})
                              }, 300);
                            }}
                          >
                            <div style={{flex:'0 0 24px',marginRight:16,height:24}}>
                              {getIconComponent({label:ho.object_type_txt,colour:theme.palette.primaryText.light,size:24})}
                            </div>
                            <div>
                              <Typography style={{fontSize:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                                {ho.name_txt}
                              </Typography>
                              <Typography style={{fontSize:12,color:theme.palette.primaryText.light,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                                {getDispFields(ho, 'dispSubtitle')}
                              </Typography>
                            </div>
                          </MenuItem>
                        ))
                    }
                  </div>
                ))
              }
              {
                state.multiHistoryObjects.filter(o=>!searchValue.trim() || o.name_txt.toLowerCase().includes(searchValue.toLowerCase())).length===0 &&
                <Typography style={{marginLeft:16,marginBottom:8}}>No items found</Typography>
              }
            </Select>
          </>
        }
        <div style={{flexGrow:1}}></div>
        <Typography style={{fontSize:13.75,marginRight:8}}>
          VIEWING:
        </Typography>
        <Select
          value={'default'}
          renderValue={()=>{
            if(state.showReference)return 'INCLUDING REFERENCES'
            else{return 'EXCLUDING REFERENCES'}
          }}
          className={classes.selector}
          disableUnderline
        > 
          <div>
            <MenuItem onClick={()=>{
              dispatch({type:'set_show_reference',showReference:!state.showReference})
            }}>
              <Checkbox color='primary' checked={state.showReference} style={{paddingLeft:0}}/>
              <span>Show References</span>
            </MenuItem>
          </div>
        </Select>
      </div>
      <ImpactSummary
        state={state}
        history={history}
        dispatch={dispatch}
        direction={direction}
        key={componnetKey+'summary'}
      />
      {
        state.summaryData && 
        <div style={{marginLeft:-56,marginBottom:12,marginTop:16,position:"sticky",top:120,zIndex:999,background:theme.palette.background.main}}>
          <TabBar
            tabOptions={tabOptions}
            tabState={tabOptions.indexOf(state.resultTabState)}
            setTabState={value => {
              dispatch({ type: 'set_result_tab_state', resultTabState: tabOptions[value] })

              if(tabOptions[value]==='MAP'){
                setTimeout(()=>{
                  window.scrollTo({
                    top: 430,
                    behavior: 'smooth'
                  })
                },250)
              }
            }}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        </div>
      }
      {
        state.summaryData && 
        bodyContent
      }
    </div>  
  )
}

export default withTheme()(withStyles(styles)(Results));