import React from 'react';
import { withTheme, FormControl, FormHelperText,} from '@material-ui/core';
import GenericSourceSelector from '../../OnBoardSource/UIComponents/SourceSelector'

const SourceSelector = props => {

  const {
    classes,
    theme,
    state,
    onSelectSource
  } = props;
  
  return (
    <div className={classes.block}>
      <FormControl style={{width:'100%'}}>
        {/* <div style={{width:'100%'}}> */}
          <GenericSourceSelector
            onChangeSource={(name, s)=>onSelectSource({target:{value:s.id}})}
            sourceList={state.sourceList.filter(el=>['DATABASE','TOOL'].includes(el.source_template.type))}
            isDatabaseLevel={true}
            placeholder={'Select the Source'}
            height={56} 
          />
        {/* </div> */}
        {/* <Select
          className={classes.selector}
          value={state.source}
          onChange={onSelectSource}
          renderValue={el=>{
            return (
              (el==='none' || state.sourceList.length===0)
                ?
                <Typography style={{color:theme.palette.primaryText.light,fontSize:16}}>
                  Select a Source
                </Typography>
                :
                <div>
                  <Typography style={{color:theme.palette.primaryText.light,fontSize:12}}>
                    Select the Source
                  </Typography>
                  <Typography style={{fontSize:16,color:theme.palette.primaryText.main}}>
                    {state.sourceList.find(s=>s.id+""===el+"").name}
                  </Typography>
                </div>
                
            )
          }}
        > 
          <MenuItem value={'none'}>
            Select the Source
          </MenuItem>
          {
            state.sourceList.filter(el=>['DATABASE','TOOL'].includes(el.source_template.type)).map(el=>(
              <MenuItem value={el.id}>
                {el.name}
              </MenuItem>
            ))
          }

        </Select> */}
        <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>Required</FormHelperText>
      </FormControl>
    </div>
  )
}

export default withTheme()(SourceSelector);