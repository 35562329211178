import React, { useEffect, useRef } from 'react';
import { withTheme, CircularProgress, withStyles, Typography} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import moment from 'moment'
import axiosSolr from '../../../axios-solr'
import { isInViewport } from '../../../utilities'
import {getObjectCard} from '../utils'
 
const styles = theme => ({
  root: {

  },
  errorText:{
    color:theme.palette.primaryText.main,
    marginTop:24,
  },
  timelineText:{
    color:theme.palette.primary.main,
    letterSpacing:2,
    marginBottom:8,
    fontSize:12
  }
})

const FollowedTimeLine = props => {

  const {
    classes,
    history,
    state,
    dispatch,
    sessionData
  } = props;


  const scrollRef = useRef()

  const loadTimeLineData = async ({page=1}) => {
    dispatch({type:'set_followed_timeline',followedTimeLineData:page===1?undefined:state.followedTimeLineData,followedTimeLineLoading:true})
    let data;
    await axiosCerebrum
      .get(
        `/api/follows`,
        {params:{
          user_id:sessionData.id,
          per_page:10,
          page:page,
          sort:'END_DESC'
        }}
      )
      .then(response=>{
        if(response.data.items.length===0){
          dispatch({
            type:'set_followed_timeline',
            followedTimeLineData:response.data
          })
          return;
        }
        data = response.data;
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_followed_timeline',followedTimeLineError:true})
      })
    
    if(!data)return;
      
    let solrDetails = {};
    await axiosSolr.get(
      `/solr/search/select`,{
        params:{
          q:'*',
          fq:`id:(${data.items.map(el=>el.object.id).join(' OR ')})`,
          fl:'*',
          start:0,
          rows:data.items.length,
        }
      }
    )
    .then(solrRes=>{
      solrRes.data.response.docs.forEach(el=>{
        solrDetails[el.id] = el;
      })
    })
    .catch(error=>{
      console.log(error)
      dispatch({type:'set_followed_timeline',followedTimeLineError:true})
    })

    let stats = {}
    await axiosCerebrum
      .get(
        '/api/stats',
        {params:{
          object_ids:data.items.map(el=>el.object.id).join(','),
          per_page:data.items.length
        }}
      )
      .then(response=>{
        response.data.items.forEach(el=>{
          stats[el.node_id] = el
        })
      })
      .catch(error=>{
        console.log(error)
      })

    dispatch({
      type:'set_followed_timeline',
      followedTimeLineData:{
        ...data,
        items:[
          ...(page===1?[]:state.followedTimeLineData.items),
          ...data.items.map(el=>({
            ...el.object,
            ...(solrDetails[el.object.id]||{}),
            stats:stats[el.object.id]
          }))
        ]
      }
    })
  }

  useEffect(()=>{
    if(!state.followedTimeLineData && !state.followedTimeLineLoading){
      loadTimeLineData({})
    }
    // eslint-disable-next-line
  },[])


  const shouldPaginate = () => {
    return state.followedTimeLineData && state.followedTimeLineData.page<state.followedTimeLineData.pages && !state.followedTimeLineLoading && isInViewport(scrollRef)
  }

  window.onscroll = () => {
    if(shouldPaginate()){
      loadTimeLineData({page:state.followedTimeLineData.page+1})
    }
  }

  useEffect(()=>{
    if(shouldPaginate()){
      loadTimeLineData({page:state.followedTimeLineData.page+1})
    }
  // eslint-disable-next-line
  },[state.followedTimeLineData])

  let todayList = []
  let yesterdayList = [];
  let last7DList = [];
  let lastMonthList = [];
  let moreThanAMonth = []

  if(state.followedTimeLineData){
    let today = moment(moment().format('YYYY-MM-DD'));
    const getUpdatedTime = el => moment(el.updated_at).format('YYYY-MM-DD')
    todayList = state.followedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')===0)
    yesterdayList = state.followedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')===1)
    last7DList = state.followedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')>1 && today.diff(getUpdatedTime(el),'day')<=7)
    lastMonthList = state.followedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')>7 && today.diff(getUpdatedTime(el),'day')<=30)
    moreThanAMonth = state.followedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')>30)
  }

  return (
    <div className={classes.root}>
      {
        todayList.length > 0 && 
        <Typography className={classes.timelineText}>TODAY</Typography>
      }
      {
        todayList.length > 0 && 
        <div style={{marginBottom:24}}>
          {todayList.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        yesterdayList.length > 0 && 
        <Typography className={classes.timelineText}>YESTERDAY</Typography>
      }
      {
        yesterdayList.length > 0 && 
        <div style={{marginBottom:24}}>
          {yesterdayList.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        last7DList.length > 0 && 
        <Typography className={classes.timelineText}>IN THE LAST 7 DAYS</Typography>
      }
      {
        last7DList.length > 0 && 
        <div style={{marginBottom:24}}>
          {last7DList.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        lastMonthList.length > 0 && 
        <Typography className={classes.timelineText}>IN THE LAST MONTH</Typography>
      }
      {
        lastMonthList.length > 0 && 
        <div style={{marginBottom:24}}>
          {lastMonthList.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        moreThanAMonth.length > 0 && 
        <Typography className={classes.timelineText}>MORE THAN A MONTH</Typography>
      }
      {
        moreThanAMonth.length > 0 && 
        <div style={{marginBottom:24}}>
          {moreThanAMonth.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        state.followedTimeLineData && state.followedTimeLineData.total===0 && 
        <Typography className={classes.errorText}>No items found</Typography>
      }
      <div ref={scrollRef} style={{textAlign:'center',marginBottom:24}}>
        {
          state.followedTimeLineLoading && 
          <CircularProgress style={{margin:'24px auto'}} color='secondary'/>
        }
      </div>
      {
        state.followedTimeLineError && 
        <Typography className={classes.errorText}>Error occurred loading followed items</Typography>
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(FollowedTimeLine));