import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Checkbox} from '@material-ui/core';
import { mapObjectName, tabGroup, titleCaseObjectName  } from '../../../utilities';
import { templateObjects } from '../../../permissionChecker';

const styles = theme => ({
  section:{
    marginBottom:24,
  },
  sectionTitle:{
    fontSize:12,
    letterSpacing:1.5,
    marginBottom:8,
  },
  listItem:{
    display:'flex',
    alignItems:'center',
    paddingLeft:16,
    overflow:'hidden',
    height:48,
    '&:hover':{
      backgroundColor:theme.palette.hovered.main,
      cursor:'pointer',
    }
  },
  itemText:{
    fontSize:16,
    marginLeft:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
})

function ChooseObjectType(props) {

  const {
    classes,
    state,
    theme,
    selectedTypes, 
    setSelectedTypes
  } = props;

  const onClickCheckbox = (type) => {
    if(selectedTypes.includes(type))setSelectedTypes(selectedTypes.filter(s=>s!==type))
    else{setSelectedTypes([...selectedTypes,type])}
  }

  return (
    <div className={classes.root}>
      <Typography style={{fontSize:20}}>Select an item by type from your cart</Typography>
      <Typography style={{fontSize:12,marginBottom:40,color:theme.palette.primaryText.light}}>
        Bulk edit templates can include multiple types of items from your cart.
      </Typography>
      {
        state.objectCount && tabGroup.filter(g=>g.tabs.some(t=>state.objectCount.filter(el=>templateObjects.includes(el.objectType)).map(el=>el.objectType).includes(t))).map((group,i)=>(
          <div className={classes.section}>
            <Typography className={classes.sectionTitle}>{group.name}</Typography>
            {
              state.objectCount.filter(el=>templateObjects.includes(el.objectType)).filter(el=>group.tabs.includes(el.objectType)).map((item,i)=>(
                <div className={classes.listItem} onClick={()=>{onClickCheckbox(item.objectType)}}>
                  <Checkbox color='primary' checked={selectedTypes.includes(item.objectType)} />
                  <Typography className={classes.itemText}>{titleCaseObjectName(mapObjectName(item.objectType))}s ({item.count})</Typography>
                </div>
              ))
            }
          </div>
        ))
      }
    </div>
  )
}

ChooseObjectType.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ChooseObjectType));