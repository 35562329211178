import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, MenuItem, Radio, Select, Typography, IconButton } from '@material-ui/core';
import { getIconComponent, getTestScoreColor, getTrustScoreColor } from '../../../../../../utilities';
import { globalListenerRef } from '../../../../../../GlobalListenerRef';
import KTooltip from '../../../../KTooltip/KTooltip';

const styles = theme => ({
  root:{
    position:'fixed',
    bottom:40,
    height:48,
    borderRadius:4,
    transition:'max-width 0.3s',
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.2)',
    display:'flex',
    zIndex:99,
    alignItems:'center',
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    overflow:'hidden',
  },
  expandTrigger:{
    width:48,
    height:48,
    flexGrow:0,
    flexShrink:0,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
  },
  selector: {
    ...theme.components.titleSelector,
    height:24,
    borderRadius:12,
    border:"none",
    maxWidth:210,
    '& div div':{
      fontSize:13,
      width:'max-content',
      maxWidth:178,
      padding:'1px 24px 0px 8px'
    }
  },
  legendItemText:{
    marginLeft:8,
    color:theme.palette.primaryText.light,
    fontSize:12, 
  },
  checkbox:{
    paddingLeft:0,
  },
})

function TagSelector(props) {
  const {
    classes,
    theme,
    tagView,
    onChangeTagView
  } = props;

  const [left, setLeft] = useState(120);
  const [expanded, setExpanded] = useState(false);

  const getLeft = () => {
    setLeft(document.getElementById('profile-content')?.getBoundingClientRect().left || 180)
  }

  useEffect(()=>{
    getLeft()

    window.removeEventListener('resize',globalListenerRef.lineageTagSelectorResizeListener)
    globalListenerRef.lineageTagSelectorResizeListener = () => {
      getLeft()
    }
    window.addEventListener('resize',globalListenerRef.lineageTagSelectorResizeListener)
    return ()=>window.removeEventListener('resize',globalListenerRef.lineageTagSelectorResizeListener)
  },[])

  let legendArr;

  if(tagView==='trust'){
    legendArr = [
      {text:'Lowest',colour:getTrustScoreColor(0)},
      {text:'Low',colour:getTrustScoreColor(20)},
      {text:'Medium',colour:getTrustScoreColor(40)},
      {text:'High',colour:getTrustScoreColor(60)},
      {text:'Highest',colour:getTrustScoreColor(80)}
    ]
  }
  if(tagView==='dq_score'){
    legendArr = [
      {text:'0',colour:getTestScoreColor(0,true)},
      {text:'1-25',colour:getTestScoreColor(10,true)},
      {text:'26-50',colour:getTestScoreColor(30,true)},
      {text:'51-75',colour:getTestScoreColor(60,true)},
      {text:'76-99',colour:getTestScoreColor(89,true)},
      {text:'100',colour:getTestScoreColor(100,true)}
    ]
  }
  if(tagView==='knowledge'){
    legendArr = [
      {text:'ONE',colour:getTestScoreColor(50,true)},
      {text:'MANY',colour:getTestScoreColor(100,true)}
    ]
    // legendText = 'KNOWLEDGE'
  }

  const viewOptions = [
    {id:'dq_score',label:'Data Quality'},
    {id:'knowledge',label:'Knowledge'},
    {id:'trust',label:'Trust'}
  ]

  return (
    <div 
      style={{
        left,
        maxWidth:expanded?'100vw':48,
      }}
      className={classes.root}
    >
      <div 
        className={classes.expandTrigger}
        style={{
          marginRight:expanded?8:0
        }}
      >
        <KTooltip title={expanded?'Hide legend':'Show legend'}>
          <IconButton
            onClick={()=>{
              setExpanded(!expanded)
            }}
          >
            {
              getIconComponent({
                label:expanded?'expand_right':'legend',
                size:expanded?30:24,
                colour:theme.palette.primaryText.light,
              })
            }
          </IconButton>
        </KTooltip>
      </div>
      <Select
        inputProps={{
          'data-test-id':'lineage-legend-button'
        }}
        className={classes.selector}
        style={{marginRight:6,marginBottom:0}}
        value={[]}
        disableUnderline
        renderValue={value=>{
          let shownArr = []
          shownArr.push(viewOptions.find(v=>v.id===tagView).label.toUpperCase())
          return shownArr.join(', ')
        }}
        displayEmpty
        multiple
      >
        <div>
          {
            viewOptions.map(el=>(
              <MenuItem key={el.id} value={el.id} data-test-id={`lineage-show-${el.id}-tag`} onClick={()=>{onChangeTagView(el.id)}} >
                <Radio key={tagView} className={classes.checkbox} color='primary' checked={tagView===el.id}/>
                {el.label}
              </MenuItem>
            ))
          }
        </div>
      </Select>
      {
        legendArr.map(el=>(
          <div key={el.text} style={{display:'flex',alignItems:'center',marginLeft:12}}>
            <div style={{width:16,height:16,borderRadius:8,background:el.colour}}></div>
            <Typography className={classes.legendItemText}>{el.text}</Typography>
          </div>
        ))
      }
      <div style={{width:24}}></div>
    </div>
  )
}

TagSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  tagView: PropTypes.string.isRequired,
  onChangeTagView: PropTypes.func.isRequired,
}

export default withTheme()(withStyles(styles)(TagSelector));