import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Modal, Paper, Radio, Typography, Button, IconButton} from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../axios-cerebrum'
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import SnackBar from '../../UI/SnackBar/SnackBar';
import { getIconComponent } from '../../../utilities';
import { globalListenerRef } from '../../../GlobalListenerRef'

const styles = theme => ({
  title: {
    fontSize: 20,
    color:theme.palette.header.main,
    marginBottom:16
  },
  loadSelect:{
    height:48,
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  loadSelectText:{
    fontSize:16,
    color:theme.palette.primaryText.main
  },
  buttons: {
    marginTop:32,
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end',
  },
  sectionHeader:{
    fontSize:13.75,
    color:theme.palette.header.main,
    marginBottom:8
  },
  inputBase:{
    ...theme.components.inputBase,
    '& div':{
      backgroundColor:'transparent',
    },
    '& div:hover':{
      backgroundColor:'transparent',
    },
    '& label':{
      color:theme.palette.primaryText.light
    },
    '& input':{
      color:theme.palette.primaryText.main
    }
  },
  suggestionChip:{
    ...theme.components.suggestionChip
  },

  iconButton:{
    '&:hover':{
      background:`${theme.palette.hovered.main}30 !important`
    }
  }
})

function ManualLoad(props) {

  const {
    history,
    theme,
    classes,
    modalOpen, 
    setModalOpen,
    source
  } = props;


  const [alertOpen, setAlertOpen] = useState();
  const [alertMessage, setAlertMessage] = useState('')
  const [loadType, setLoadType] = useState('run_from_last')
  const [loading, setLoading] = useState(false)
  
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment())
 
  const setStartDateByDiff = days => {
    setStartDate(moment().add(-days,'days'));
    setEndDate(moment());
  }
  
  const onLoad = () => {
    let payload;
    if(loadType==='set_date'){
      payload = {
        start_at:moment(startDate).utc().format('YYYY-MM-DDT00:00:00Z'),
        end_at:moment(endDate).utc().format('YYYY-MM-DDTHH:mm:ssZ'),
      }
    };
    setLoading(true)
    axiosCerebrum.post(
      `/api/batches/${source.source_id}`,payload
    ).then(extractResponse=>{
      setSnackBarOpen(true)
      setModalOpen(false)
      setLoading(false)
    }).catch(error=>{
      console.log(error);
      setAlertMessage("There was a problem running the job Please try again. Contact KADA support if the problem persists");
      setAlertOpen(true)
      setLoading(false)
    })
  }

  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.manual_load_source ){
        let source = JSON.parse(msg.data.manual_load_source)
        axiosCerebrum.post(
          `/api/batches/${source.source_id}`
        ).then(extractResponse=>{
          setSnackBarOpen(true)
          setModalOpen(false)
          setLoading(false)
        }).catch(error=>{
          console.log(error);
          setAlertMessage("There was a problem running the job Please try again. Contact KADA support if the problem persists");
          setAlertOpen(true)
          setLoading(false)
        })
      }
    }
    window.removeEventListener('message',globalListenerRef.manualLoadListener);
    globalListenerRef.manualLoadListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.manualLoadListener);
    return (()=>{window.removeEventListener('message',globalListenerRef.manualLoadListener);})
  // eslint-disable-next-line
  },[])

  return (
    <div>
      {
        modalOpen && 
        <Modal 
          open={modalOpen} 
          onClose={()=>setModalOpen(false)}
          disableBackdropClick={true}
        > 
          <div style={{outline:'none',width:'max-content',margin:'7vh auto 0 auto'}}>
            <Paper style={{width:520,padding:24,paddingBottom:6,background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
              <Typography className={classes.title}>Load Source</Typography>
              <Typography className={classes.sectionHeader}>Select a Load approach</Typography>
              <div className={classes.loadSelect} onClick={()=>{setLoadType("run_from_last")}}>
                <Radio color='primary' checked={loadType==='run_from_last'}/>
                <Typography className={classes.loadSelectText}>Run from last extract date</Typography>
              </div>
              <div className={classes.loadSelect} onClick={()=>{setLoadType("set_date")}}>
                <Radio color='primary' checked={loadType==='set_date'}/>
                <Typography className={classes.loadSelectText}>Set load Start & End date </Typography>
              </div>
              {
                loadType==='set_date' && 
                <div style={{marginTop:16}}>
                  <Typography className={classes.sectionHeader}>Select a load period</Typography>
                    <div style={{display:'flex'}}>
                    <div style={{width:200}}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          autoOk
                          className={classes.inputBase}
                          style={{margin:0}}
                          label="start"
                          format='ll'
                          maxDate={endDate}
                          value={startDate}
                          onChange={setStartDate}
                          variant="filled"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div style={{width:200,marginLeft:64}}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          autoOk
                          className={classes.inputBase}
                          style={{margin:0}}
                          label="end"
                          format='ll'
                          minDate={startDate}
                          value={endDate}
                          onChange={setEndDate}
                          variant="filled"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                  <Typography style={{fontSize:12,letterSpacing:2,marginTop:16,marginBottom:8,color:theme.palette.primaryText.light}}>SUGGESTIONS</Typography>
                  <div style={{display:'flex'}}>
                    <div className={classes.suggestionChip} onClick={()=>setStartDateByDiff(7)}>Last 7 days</div>
                    <div className={classes.suggestionChip} onClick={()=>setStartDateByDiff(30)}>Last 30 days</div>
                    <div className={classes.suggestionChip} onClick={()=>setStartDateByDiff(90)}>Last 90 days</div>
                  </div>
                </div>
              }
              <div className={classes.buttons}>
                <Button color="primary" style={{width:96}} disabled={loading} onClick={onLoad}>
                  LOAD
                </Button>
                <Button color="secondary" style={{width:96, marginLeft: 16}}  onClick={()=>setModalOpen(false)}>
                  CANCEL
                </Button>
              </div>
            </Paper>
            <ModalAlert
              open={alertOpen}
              setOpen={setAlertOpen}
              message={alertMessage}
              type='error'
            />
          </div>

        </Modal>
      }
      {
        source && 
        <SnackBar
          snackBarOpen={snackBarOpen}
          setSnackBarOpen={setSnackBarOpen}
          message={`Source load job for ${source.name} has been started`}
          buttons={
            [
              <Button 
                color='secondary'
                variant='outlined' 
                onClick={()=>{
                  history.push(`/admin/platform_management?tabName=MONITOR&sourceIdFilter=${source.source_id}`)
                  window.scrollTo(0,0)
                }}
              >
                GO TO MONITOR
              </Button>,
              <IconButton className={classes.iconButton} style={{marginLeft:12,padding:6}} onClick={()=>setSnackBarOpen(false)}>{getIconComponent({label:'clear', size: 16, colour: theme.palette.snackbarContent.main})}</IconButton>
            ]
          }
        />
      }
    </div>
  )
}

ManualLoad.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired
}

export default withTheme()(withStyles(styles)(ManualLoad));