import React, { useEffect, useRef } from 'react';
import { withStyles, withTheme} from '@material-ui/core';
import axiosSolr from '../../../axios-solr'
import 'url-search-params-polyfill';
import Settings from '../Settings/Settings';
import LineageMap from '../LineageMap/LineageMap';
import axiosCerebrum from '../../../axios-cerebrum'
import { getLabelPlural, mapSearchObjectName } from '../../../utilities';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
})

const Body = props => {

  const {
    classes,
    state,
    dispatch
  } = props;

  const urlSearch = new URLSearchParams(window.location.search);
  const targetObjectId = urlSearch.get('targetObjectId');

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  useEffect(()=>{
    if(targetObjectId){
      axiosSolr
        .get(
          `/solr/search/select`,
          {params:{q:"*",fq:`id:${targetObjectId}`,fl:' id, labels:object_type_txt, datasourceId:source_id_srt, name:name_txt, code_type_txt,location_txt'}}
        )
        .then(solrRes=>{
          if(solrRes.data.response.docs.length===1){
            let object = solrRes.data.response.docs[0]
            axiosCerebrum
              .get(
                `/api/${getLabelPlural(mapSearchObjectName(object.labels,object.code_type_txt))}/${object.id}`
              )
              .then(response=>{
                dispatch({type:'set_source',source:object.datasourceId});
                dispatch({type:'set_setting_step',settingStep:4})
                dispatch({type:'set_lineage_data',lineageData:{view:'basic_map'}})
                dispatch({type:'set_target_object',targetObject:{...response.data,...object}})
                dispatch({type:'set_tab_state',tabState:1})
              })
            .catch(error=>{
              sendAlert({message:'Error occurred setting target object',type:'error'})
            })
          }else{
            throw new Error('Object not found')
          }
        })
        .catch(error=>{
          console.log(error);
          sendAlert({message:'Target object not found',type:'error'})
        })
    }
    // eslint-disable-next-line
  },[])

  let bodyContent;

  switch(state.tabState){
    case 0:
      bodyContent = (
        <Settings
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    case 1:
      bodyContent = (
        <LineageMap
          state={state}
          dispatch={dispatch}
          key={state.lineageData.view}
        />
      )
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

export default withTheme()(withStyles(styles)(Body));