import React, { } from 'react';
import { withStyles, Button, IconButton, Typography } from '@material-ui/core';
import PreviousIcon from '@material-ui/icons/ChevronLeft';
import NextIcon from '@material-ui/icons/ChevronRight';
import MoreIcon from '@material-ui/icons/MoreHoriz'
import theme from '../../../../theme';
import PropTypes from 'prop-types';

const styles = theme => ({
  pageBtnRoot:{
    marginTop:40,
    width:'100%',
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  pageControl:{
    padding:6
  },
  pageControlIcon:{
    width:24,
    height:24,
    color:theme.palette.primaryText.light
  },
  pageBtn:{
    height:36,
    minWidth:40
  },
  pageInfoTxt:{
    color:theme.palette.primaryText.light,
    fontSize:13.75,
    marginTop:20
  }
});



const PageController = props => {

  const {
    classes,
    state,
    onChangePage,
    presetTotalPages,
  } = props;

  let totalPages = presetTotalPages || Math.ceil(state.resultsTotal/10);

  // returns a list of page numbers e.g. [1,2,3,4], ['...',5,6,7,'...',30]
  const generatePageList = () => {
    let pages = [];
    if(totalPages<=5){
      for(let i=1; i<=totalPages; i++){
        pages.push(i)
      }
      return pages;
    }
    if([1,2].includes(state.pageNum)){
      return [1,2,3,'...',totalPages]
    }
    if([totalPages, totalPages-1].includes(state.pageNum)){
      return [1,'...',totalPages-2,totalPages-1,totalPages];
    }
    return [1,'...',state.pageNum-1,state.pageNum, state.pageNum+1,'...',totalPages]
  }

  let perPage = 10;
  try{
    let defaultParams = JSON.parse(JSON.parse(localStorage.getItem('platformSettings')).items.find(el=>el.id===8000).value);
    perPage = defaultParams.rows || 10;
  }catch{}

  return (
    <div style={{width:'100%',textAlign:'center',marginBottom:60}}>
      <div className={classes.pageBtnRoot}>
        <IconButton className={classes.pageControl} onClick={()=>onChangePage(state.pageNum-1)} style={{marginRight:16, visibility:state.pageNum>1?'visible':'hidden'}}>
          <PreviousIcon className={classes.pageControlIcon}/>
        </IconButton>
        {
          generatePageList().map((el,index)=>{
            if(typeof(el)=='number'){
              return (
                <Button 
                  variant={el===state.pageNum?'contained':'outlined'} 
                  color='primary' 
                  data-test-id={`search-pagination-button-${el}`}
                  className={classes.pageBtn} 
                  style={{marginRight:index===generatePageList().length-1?0:16}}
                  onClick={()=>onChangePage(el)}
                >
                  {el}
                </Button>
              )
            }
            else{
              return <MoreIcon style={{width:24,height:24,marginRight:16,color:theme.palette.primaryText.light}}/>
            }
          })
        }
        <IconButton className={classes.pageControl} onClick={()=>onChangePage(state.pageNum+1)} style={{marginLeft:16,visibility:state.pageNum<totalPages?'visible':'hidden'}}>
          <NextIcon className={classes.pageControlIcon}/>
        </IconButton>
      </div>
      <Typography className={classes.pageInfoTxt} data-test-id="result-count-msg">
        Showing {(state.pageNum-1)*perPage+1}-{Math.min(state.resultsTotal,state.pageNum*perPage)} of {state.resultsTotal} results
      </Typography>
    </div>
  )
}

PageController.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  onChangePage: PropTypes.func.isRequired,
  presetTotalPages: PropTypes.number
};

export default withStyles(styles)(PageController);
