import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Modal, Paper, CircularProgress, Typography, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
import useAlert from '../../../hooks/useAlert';
import AddContactInput from '../../UI/AddContactInput/AddContactInput';
import axiosCerebrum from '../../../axios-cerebrum';

const styles = theme => ({
  title: {
    fontSize: 20,
    fontWeight: '500',
    color:theme.palette.header.main,
    marginBottom:24,
  },
  modalRoot:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    maxHeight:'70vh',
    overflow:'auto'
  }
})

function EditRecipientsModal(props) {

  const {
    theme,
    classes,
    modalOpen, 
    setModalOpen,
    state,
    dispatch,
  } = props;

  const [step, setStep] = useState(0)
  const [alertOpen, setAlertOpen] = useState();
  const [alertType, setAlertType] = useState('error')
  const [alertMessage, setAlertMessage] = useState('')
  const [saving, setSaving] = useState(false)

  const [contactType, setContactType] = useState('user')
  const [contacts, setContacts] = useState([])

  const isCancelledRef = useRef(false);


  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    if(modalOpen){
      let emails = modalOpen.alert?.alert_channel_args?.recipients || [];
      setContacts(emails.map(email=>({id:email,email_srt:email,name_srt:email,object_type_srt:'email'})))
    }
  },[modalOpen])

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  const checkClickable = step => {
    return contacts.length>0 
  }

  const onSave = () => {
    setSaving(true)
    axiosCerebrum
      .put(
        `/api/alerts/${modalOpen.alert?.id}`,{
          alert_channel_args:{
            recipients:contacts.map(contact=>contact.email_srt)
          }
        }
      )
      .then(response=>{
        dispatch({
          type:'set_alert_list',
          alertList:{
            ...state.alertList,
            items:state.alertList.items.map(item=>{
              if(item.id===modalOpen.alert?.id){
                return response.data
              }else{
                return item
              }
            })
          }
        })
        setSaving(false)
        setModalOpen(false)
        sendAlert({type:"info",message:"Alert updated successfully"})
      })
      .catch(error=>{
        setAlertMessage('Error occurred updating alert')
        setAlertOpen(true)
        setSaving(false)
      })
  }

  const onAddEmail = email => {
    let emailObj = {
      id:email,
      name_srt:email,
      email_srt:email,
      object_type_srt:'email'
    }
    if(contacts.find(item=>item.id===email)){
      setAlertType('info')
      setAlertMessage('Recipient already added')
      setAlertOpen(true)
      return;
    }
    setContacts([...contacts,emailObj])
  }

  const onAddUser = user => {
    if(contacts.find(item=>item.id===user.id))return;
    axiosCerebrum
      .get(
        `/api/users/${user.id}`
      )
      .then(response=>{
        if(!response.data || !response.data.email){
          setAlertType('error')
          setAlertMessage('User email not found')
          setAlertOpen(true)
        }else{
          if(contacts.find(c=>c.email_srt===response.data.email)){
            setAlertType('info')
            setAlertMessage('Recipient already added')
            setAlertOpen(true)
          }else{
            setContacts([...contacts,{...user, email_srt:response.data.email}])
          }
        }
      })
      .catch(error=>{
        console.log(error)
        setAlertType('error')
        setAlertMessage('User email not found')
        setAlertOpen(true)
      })
  }

  const onRemoveUser = item => {
    setContacts(contacts.filter(user=>user.id!==item.id))
  }

  return (
    <Modal 
      open={modalOpen} 
      onClose={()=>setModalOpen(false)}
      disableBackdropClick={true}
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    > 
      <div style={{outline:'none',width:'max-content'}}>
        <Paper className={classes.modalRoot} style={{width:720,padding:24}}>
          <div style={{display:'flex',alignItems:'center'}}>
            <Typography className={classes.title}>Edit Alert recipients</Typography>
          </div>
          <AddContactInput
            type={contactType}
            setType={setContactType}
            onAddUser={onAddUser}
            onAddEmail={onAddEmail}
            onRemoveUser={onRemoveUser}
            users={contacts}
          />
          <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginBottom:-12,marginTop:24,position:'sticky',bottom:-12,background:theme.palette.background.main}}>
            {
              saving &&
              <div style={{width:64,marginRight:8,textAlign:'center'}}>
                <CircularProgress color='secondary' style={{width:24,height:24}}/>
              </div>
            }
            {
              !saving && 
              <Button 
                color='primary' 
                data-test-id="add-alert-next-button" 
                disabled={!checkClickable()} 
                style={{marginRight:8}} 
                onClick={()=>{
                  if(step===0)setStep(1)
                  onSave()
                }}
              >
                SAVE
              </Button>
            }
            <Button 
              color='secondary' 
              onClick={()=>{
                setModalOpen(false)
              }}
            >
              CANCEL
            </Button>
          </div>
        </Paper>
        <ModalAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          type={alertType}
        />
      </div>
    </Modal>
  )
}

EditRecipientsModal.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
}

export default withTheme()(withStyles(styles)(EditRecipientsModal));