import React, { useState} from 'react';
import { withTheme, withStyles, Button } from '@material-ui/core';
import { getIconComponent } from '../../../utilities';
import KTooltip from '../KTooltip/KTooltip';
import PropTypes from 'prop-types';

const styles = theme => ({
  button: {
    margin: '8px 0px 8px 16px',
    height: 40,
    minWidth:72,
    display:'flex',
    alignItems:'center',
    paddingLeft:'8px',
    paddingRight:'8px',
    borderColor:theme.palette.primary.main,
    '&:hover':{
      background:theme.palette.hovered.main,
    }
  },
  hoveredButton:{
    '&:hover':{
      background:theme.palette.primary.main,
    }
  },
  tooltip:{
    fontSize:13.75,
    whiteSpace:'pre-wrap'
  },
  hidden:{
    display:'none'
  }
})

function ProfileButton(props) {

  const {
    classes,
    id,
    onClick,
    testID,
    width,
    iconLabel,
    text,
    theme,
    iconOnly,
    iconColour,
    tooltip,
    borderLess,
    size,
    disabled
  } = props;

  const [hovered, setHovered] = useState(false)

  return (
    <KTooltip classes={{tooltip:tooltip?classes.tooltip:classes.hidden}} title={tooltip}>
      <div>
        <Button
          id={id}
          data-test-id={`profile-button-${(text||tooltip||id||iconLabel||'').toLowerCase().replace(/[\s_]/g,'-')}`}
          onMouseOver={()=>setHovered(true)}
          onMouseEnter={()=>setHovered(true)}
          onMouseLeave={()=>setHovered(false)}
          disabled={disabled}
          className={classes.button + (iconOnly?'':` ${classes.hoveredButton}`)}
          style={{
            width:width,
            border:(iconOnly||borderLess)?'none':undefined,
            minWidth:iconOnly?0:undefined,
            height:size==='small'?32:undefined,
            paddingLeft:iconOnly?undefined:16,
            paddingRight:iconOnly?undefined:16,
          }}
          variant='outlined'
          disableFocusRipple={true}
          data-testid = {testID}
          onClick={onClick}
        >
          {iconLabel && iconOnly && getIconComponent({ label: iconLabel, size: iconOnly?24:20, colour: iconColour || (hovered?theme.palette.background.main:theme.palette.primary.main ) })}
          {text && <span style={{ color:hovered?theme.palette.background.main:disabled?theme.palette.primaryText.light:theme.palette.primary.main,fontSize:13.75 }}>{text}</span>}
        </Button>
      </div>
    </KTooltip>
  )
}

ProfileButton.propTypes = {
  classes: PropTypes.object.isRequired,
  testID: PropTypes.string.isRequired,
  id:PropTypes.string.isRequired,
  onClick:PropTypes.func.isRequired,
  width: PropTypes.number,
  iconLabel:PropTypes.string,
  text:PropTypes.string.isRequired,
  iconOnly:PropTypes.bool,
  iconColour:PropTypes.string,
  tooltip:PropTypes.string,
  borderLess:PropTypes.bool,
  size:PropTypes.bool,
  disabled:PropTypes.bool,
}

export default withTheme()(withStyles(styles)(ProfileButton));