import { allUsage, loadCategoryChart, loadClassificationChart, loadDocumentedStatus, loadDomainStatus, loadGovProgress, loadOwnerStatus, loadRecommendations, loadStewardStatus, loadTilesByObjectType, loadUseCaseStatus, processTileData } from '../loadUtils';

export const loadTileData = async ({data, setData, usage = allUsage}) => {
  setData({...data,tileDataLoading:true,tileDataError:false,tileData:undefined});
  try{
    if(usage.length===0){
      setData({usage,selectedTile:data.selectedTile})
      return;
    }
    let objectTypes = ['REPORT','DATASET','DATA_PIPELINE','ML_MODEL'];
    let tileData = [];
    await loadTilesByObjectType({objectTypes, usage}).then(facet=>{
      tileData = processTileData({facet, objectTypes})
    });

    let defaultTile = data.selectedTile;
    if(!tileData.find(el=>el.id===defaultTile))defaultTile = tileData[0]?.id;    
    setData({
      tileDataLoading:false,
      tileDataError:false,
      tileData,
      selectedTile:defaultTile,
      usage
    })
  }catch(error){
    console.log(error);
    setData({...data,tileDataError:true,titleDataLoading:false,tileData:undefined});
  }
}

export const loadGraphData = async ({data,setData, usage, state,apiSeq,apiSeqRef}) => {
  setData({
    ...data,
    usage,
    [`${data.selectedTile}_graphDataLoading`]:true,
    [`${data.selectedTile}_graphDataError`]:false,
    [`${data.selectedTile}_recommendationLoading`]:true,
    [`${data.selectedTile}_recommendationError`]:false,
  });

  try{
    let objectType = data.selectedTile;
    let chartsData = {}
    let recommendations;

    if(usage.length>0){
      let promises = [
        loadGovProgress({objectType, usage, state}).then(d=>chartsData[`${objectType}_GOV_PROGRESS`] = d),
        loadCategoryChart({objectType, usage, state}).then(d=>chartsData[`${objectType}_BY_CATEGORY`] = d),
        loadClassificationChart({objectType, usage, state}).then(d=>chartsData[`${objectType}_BY_CLASSIFICATION`] = d),
        loadDomainStatus({objectType, usage, state}).then(d=>chartsData[`${objectType}_DOMAIN_STATUS`] = d),
        loadUseCaseStatus({objectType, usage, state}).then(d=>chartsData[`${objectType}_USE_CASE_STATUS`] = d),
        loadDocumentedStatus({objectType, usage, state}).then(d=>chartsData[`${objectType}_DOCUMENTED_STATUS`] = d),
        loadOwnerStatus({objectType, usage, state}).then(d=>chartsData[`${objectType}_OWNER_STATUS`] = d),
        loadStewardStatus({objectType, usage, state}).then(d=>chartsData[`${objectType}_STEWARD_STATUS`] = d),
        loadRecommendations({objectType, usage, state}).then(d=>recommendations = d)
      ]
      await Promise.all(promises)
    }

    if(apiSeq!==apiSeqRef.current)return;
    setData({
      ...data,
      usage,
      [`${data.selectedTile}_graphDataLoading`]:false,
      [`${data.selectedTile}_graphDataError`]:false,
      [`${data.selectedTile}_graphData`]:chartsData,
      [`${data.selectedTile}_recommendationLoading`]:false,
      [`${data.selectedTile}_recommendationError`]:false,
      [`${data.selectedTile}_recommendationData`]:recommendations
    })
  }catch(error){
    console.log(error);
    if(apiSeq!==apiSeqRef.current)return;
    setData({
      ...data,
      usage,
      [`${data.selectedTile}_graphDataLoading`]:false,
      [`${data.selectedTile}_graphDataError`]:true,
      [`${data.selectedTile}_graphData`]:undefined,
      [`${data.selectedTile}_recommendationLoading`]:false,
      [`${data.selectedTile}_recommendationError`]:true,
      [`${data.selectedTile}_recommendationData`]:undefined
    })
  }
}