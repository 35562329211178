import React, { } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, InputBase } from '@material-ui/core';
import SubArrowIcon from '@material-ui/icons/SubdirectoryArrowRight'

const styles = theme => ({
  root: {
    ...theme.components.inputBase,
    width: 276,
    height: 56,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: 3,
    background: theme.palette.background.main,
    border: `1px solid ${theme.palette.listItemDivider.main}`,
  },
  errorBorder:{
    border: `1px solid ${theme.palette.error.main}`,
  },
  subArrow: {
    width: 20,
    height: 20,
    color: theme.palette.primaryText.light,
    marginLeft: 16,
    marginRight: 8,
    marginTop: -4,
  }
})

function MatchInput(props) {

  const {
    classes,
    onChange,
    value,
    prefix,
  } = props;


  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
      <SubArrowIcon className={classes.subArrow} />
      <InputBase
        className={classes.root + (value.trim()===''?' '+classes.errorBorder:'')}
        value={`${prefix}: ${value}`}
        onChange={event => {
          if (event.target.value.length <= prefix.length + 1) {
            onChange('')
            return;
          }
          onChange(event.target.value.replace(`${prefix}: `, '').trim())
        }}
      />
    </div>
  )
}

MatchInput.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  interactiveBody: PropTypes.object,
}

export default withTheme()(withStyles(styles)(MatchInput));