import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import KTooltip from '../../../KTooltip/KTooltip';
import { tileStyles } from '../utils/styles';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    fontSize: 12,
    color:theme.palette.primaryText.main,
    letterSpacing:1,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    flexShrink:0,
    width:tileStyles.headerContainer.width,
    marginRight:tileStyles.headerContainer.marginRight+8,
  },
  middleMessage:{
    fontSize: 13.75,
    flexGrow:1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color:theme.palette.primaryText.main
  },
  tooltip:{
    whiteSpace:'pre-wrap',
    fontSize:13.75
  },
})

const IconDataTile = props => {

  const {
    classes,
    title,
    text,
    tooltip,
  } = props;

  return (
    <KTooltip classes={{tooltip:classes.tooltip}} title={tooltip} disableHoverListener={tooltip?false:true}  disableFocusListener={tooltip?false:true} disableTouchListener={tooltip?false:true}> 
      <div className={classes.root} data-test-classname="single-line-data-tile">
        <Typography className={classes.title} data-test-classname="single-line-data-tile-title">{title}</Typography>
        <Typography className={classes.middleMessage} data-test-classname="single-line-data-tile-text">{text}</Typography>
      </div>
    </KTooltip>

  )
}

IconDataTile.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  subtext: PropTypes.string,
  left: PropTypes.any,
}

export default withStyles(styles)(IconDataTile);