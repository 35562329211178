import React, { useState } from 'react';
import { withTheme, withStyles, Typography } from '@material-ui/core';
// import { getIconComponent } from '../../../utilities';
import PropTypes from 'prop-types';
import SearchSelector from '../../UI/SearchSelector/SearchSelector';

const styles = theme => ({
  listItem:{
    height:30,
    display:'flex',
    alignItem:'center',
    overflow:'hidden'
  },
  selectedItem:{
    background:theme.palette.hovered.main
  },
  root:{
    overflow:'hidden'
  },
  itemName:{
    fontSize:13.75,
    overflow:"hidden",
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginRight:8
  },
  sectionHeader:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primaryText.main,
    marginBottom:8
  },
  chip:{
    height:24,
    display:'flex',
    alignItems:'center',
    borderRadius:12,
    fontSize:13.75,
    paddingLeft:12,
    paddingRight:12,
    maxWidth:400,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    width:'max-content'
  },
  selectedChip:{
    border:`1px solid ${theme.palette.primary.dark}`,
    background:theme.palette.primary.dark,
    color:theme.palette.background.main
  }
})

function InputSelector(props) {

  const {
    classes,
    state,
    rootId,
    instanceId,
    // selectedInstance,
    setSelectedInstance
  } = props;

  const [value, setValue] = useState('')
  // const [selectedItem, setSelectedItem] = useState()

  let categoryName = state.basicData.parent.category==='GLOSSARY'?'TERM':'INSTANCE'
  
  return (
    <div style={{marginTop:16}}>
      <Typography className={classes.sectionHeader}>{categoryName}S</Typography>
      <SearchSelector
        url={`/api/collectioninstances`}
        params={{collection_id:rootId, 'search.name':value, per_page:10}}
        isCerebrum
        searchValue={value}
        setSearchValue={setValue}
        placeholder={"Search for a parent"}
        testID="parent-selector-input"
        clearable
        autoSuggestion
        scrollable
        postProcessDataList = {data => {
          return data.filter(d=>d.id!==instanceId)
        }}
        onResultClick={(el)=>{
          setSelectedInstance(el)
        }}
      />
      {
        // selectedItem && 
        // <div style={{marginTop:16}}>
        //   <Typography className={classes.sectionHeader}>SELECTED {categoryName}</Typography>
        //   <div className={classes.chip + ' ' + classes.selectedChip}>
        //     {selectedItem.name}
        //   </div>
        // </div>
      }
    </div>
  )
}

InputSelector.propTypes = {
  rootId: PropTypes.string.isRequired,
  selectedInstance: PropTypes.string.isRequired,
  setSelectedInstance: PropTypes.func.isRequired
}

export default withTheme()(withStyles(styles)(InputSelector));