import React from 'react';
import { withStyles} from '@material-ui/core';
import Details from '../Details/Details';
import Linked from '../Linked/Linked';


const styles = theme => ({
})

function Body(props) {

  const {
    classes,
    history,
    state,
    dispatch,
    fetchList,
    tabOptions
  } = props;

  let bodyContent;

  switch (tabOptions[state.tabState]) {
    case 'DETAILS': // details
      bodyContent = (
        <Details
          state={state}
          dispatch={dispatch}
          history={history}
          fetchList={fetchList}
        />
      )
      break;
    case 'RELATED':
      bodyContent = (
        <Linked 
          state={state}
          dispatch={dispatch}
          history={history}
        />
      )
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

export default withStyles(styles)(Body);