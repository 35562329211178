import React, { } from 'react';
import { withTheme,  LinearProgress, Typography} from '@material-ui/core';
import theme from '../../../theme';
import { getPlatformSettings } from '../../../permissionChecker';

const LoadingIcon = props => {
  return (
    <div style={{textAlign:'center'}}>
      <LinearProgress color='secondary' style={{height:6,width:'80%',maxWidth:300,margin:'auto',marginTop:32}}/>
      <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Generating description</Typography>
    </div>
  )
}

export const GenAILoadingIcon = withTheme()(LoadingIcon);

export const hasLLMKeySet = () => {
  return getPlatformSettings(10003)?.value==='true'
}

export const checkIsObjectValidForGenAI = objectType => {
  if(!objectType || !objectType.toLowerCase) return false
  return ['table','column','dataset_table','dataset_field','content','content_child','report','sheet','query','data_quality_test'].includes(objectType.toLowerCase())
}

export const triggerText = 'Need help with adding a description? Ask K.ai'
export const overwriteText = 'Overwrite the current description with a suggested description from K.ai'
export const overwriteTextShort = `Overwrite current description with suggestion from K.ai`
export const explainationText = 'Accuracy can be improve by adding domains, verified use cases, tags etc which can be used by K.ai for additional context.'