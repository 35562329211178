import React, { } from 'react';
import { withTheme, Typography, Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';


const SurveyCategorySelector = props => {

  const {
    classes,
    theme,
    surveyTemplates,
    surveyCategory,
    setSurveyCategory,
    setSurveyTemplate,
    setObjectType
  } = props;

  let surveyCategories = [];
  surveyTemplates && surveyTemplates.forEach(el=>{
    if(!surveyCategories.includes(el.category)){
      surveyCategories.push(el.category)
    }
  })

  return (
    <div className={classes.modalBlock}>
      <Typography className={classes.modalBlockTitle}>Survey Category</Typography>
      <div style={{ flexGrow: 1 }}>
        <FormControl style={{ width: '100%' }}>
          <Select
            className={classes.selector}
            disableUnderline
            value={surveyCategory}
            onChange={event => { setSurveyCategory(event.target.value); setSurveyTemplate('none'); setObjectType('none') }}
            MenuProps={{
              classes: {
                paper: classes.selectPaper
              }
            }}
            renderValue={el => {
              let msg = el === 'none' ? 'Select a survey category' : el;
              return (
                <Typography style={{ fontSize: 16, color: el === 'none' ? theme.palette.primaryText.light : theme.palette.primaryText.main }}>{msg}</Typography>
              )
            }}
          >
            <MenuItem className={classes.menuItem} value={'none'}>
              Select a survey category
            </MenuItem>
            {
              surveyCategories.map(el => (
                <MenuItem className={classes.menuItem} value={el}>
                  {el}
                </MenuItem>
              ))
            }
          </Select>
          <FormHelperText className={classes.helperText}>
            {'Required'}
          </FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}

SurveyCategorySelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  surveyTemplates: PropTypes.array.isRequired,
  surveyCategory: PropTypes.string.isRequired,
  setSurveyCategory: PropTypes.func.isRequired,
  setSurveyTemplate: PropTypes.func.isRequired,
  setObjectType: PropTypes.func.isRequired
}

export default withTheme()(SurveyCategorySelector);