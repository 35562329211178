import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import AdditionalPropertyAdder from '../../UI/AdditionalPropertyAdder/AdditionalPropertyAdder'
import AccessAdder from '../../UI/AccessAdder/AccessAdder'
import GlossaryAdder from '../../UI/GlossaryAdder/GlossaryAdder';
import SourceDescription from '../../UI/SourceDescription/SourceDescription';

const styles = theme => ({

})

const Details = props => {

  const {
    profileState,
    fetchList,
    profileDispatch,
    isShell,
    history
  } = props;

  return (
    <div>
      <UpdateInputCerebrum
        url={`/api/schemas/${profileState.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        label="schema"
        initialValue={profileState.basicData.description}
        property="description"
        placeholder="Add a description"
        multiline
        fetchList={fetchList}
        collection="data"
        textfieldRows={12}
        data={profileState.basicData}
        enableMarkDown={true}
        enableWidget={true}
        state={profileState}
      />

      <SourceDescription
        state={profileState}
        dispatch={profileDispatch}
      /> 

      <div style={{ marginTop: 60 }} >
        <GlossaryAdder
          state={profileState}
          dispatch={profileDispatch}
          history={history}
          disableModal
        />
      </div>

      {
        !isShell && 
        <div style={{ marginTop: 60 }} >
          <AdditionalPropertyAdder
            object={profileState.basicData}
            state={profileState}
            dispatch={profileDispatch}
            modalOpen={profileState.additionalPropertiesModalOpen}
            setModalOpen={value=>profileDispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:value})}
          />
        </div>
      }
     
      {
        !isShell && 
        <div style={{ marginTop: 60 }} >
          <AccessAdder
            object={profileState.basicData}
            state={profileState}
            dispatch={profileDispatch}
            modalOpen={profileState.accessModalOpen}
            setModalOpen={value=>profileDispatch({type:'set_access_modal_open',accessModalOpen:value})}
          />
        </div>
      }

    </div>


    
  )
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  userName: PropTypes.string,
  tableData: PropTypes.object,
  profileState:PropTypes.object.isRequired,
  profileDispatch:PropTypes.func.isRequired
}

export default withStyles(styles)(Details);