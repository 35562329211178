import Keycloak from 'keycloak-js';

const keycloakInstance = new Keycloak(
  {
    "realm": "kada",
    "url": document.location.protocol + "//" + document.location.hostname+ "/keycloak/auth",
    "ssl-required": "external",
    "clientId":"know-app",
    "public-client": true,
    "confidential-port": 0
  } 
);


export default keycloakInstance;