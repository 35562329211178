import React from 'react';
import PropTypes from 'prop-types';
import { withStyles} from '@material-ui/core';
import Details from '../Details/Details';
import NoteAdder from '../../UI/NoteAdder/NoteAdder'
import NewLineageContainer  from '../../UI/Lineage/NewLineageContainer';
import Issues from '../../UI/ProfileIssueSection/Issues';
import ChildList from '../ChildList/ChildList';

const styles = theme => ({
  root: {

  }
})

const Body = props => {

  const {
    classes,
    history,
    dispatch,
    state,
    fetchList,
    tabOptions
  } = props;

  let bodyContent;
  switch (tabOptions[state.tabState]) {
    case 'REPORTS':
      bodyContent = (
        <ChildList
          state={state}
          dispatch={dispatch}
          history={history}
        />
      )
      break;
    case 'DETAILS': // DETAILS
      bodyContent = (
        <Details
          state={state}
          dispatch={dispatch}
          fetchList={fetchList}
        >
        </Details>
      );
      break;
    case 'KNOWLEDGE':
      bodyContent = (
        <NoteAdder
          history={history}
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.noteModalOpen}
          setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
        />
      )
      break;
    case 'LINEAGE':
      bodyContent = (
        <NewLineageContainer
          history={history}
          state={state}
          dispatch={dispatch}
        />
      ) 
      break;
    case 'ISSUES':
      bodyContent = (
        <Issues
          history={history}
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    default:
  }
  

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
  tabUrlPrefix: PropTypes.string,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.object,
  sessionData: PropTypes.object
}

export default withStyles(styles)(Body);