import React from 'react';
import { withStyles, Typography} from '@material-ui/core';
import { mapStepListToStr } from '../utils';

const styles = theme => ({
  root: {
    display:'flex',
    marginBottom:12
  },
  text:{
    whiteSpace:'pre',
    fontSize:13.75,
    color:theme.palette.primary.main
  },
  clickableText:{
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline'
    }
  }
})

const Hierarchy = props => {

  const {
    classes,
    state,
    dispatch,
    history
  } = props;
  
  let hierarchyEl = []
  if(state.stepList.length>2){
    hierarchyEl.push(<Typography className={classes.text}>... / </Typography>)
  }else{
    hierarchyEl.push(
      <Typography 
        className={classes.text + ' '  + classes.clickableText}
        onClick={()=>{
          dispatch({type:'set_step_list',stepList:[]})
          let url = `/inventory?category=${state.category}`;
          if(url!==window.location.pathname+window.location.search){
            history.push(url)
          }
        }}
      >
        {state.category.toUpperCase()}
      </Typography>
    )
    if(state.stepList.length>0){
      hierarchyEl.push(<Typography className={classes.text}> / </Typography>)
    }
  }
  
  state.stepList.forEach((s,index)=>{
    if(state.stepList.length>3 && index<state.stepList.length-3){
      return;
    }
    hierarchyEl.push(
      <Typography 
        className={classes.text + ' '  + classes.clickableText}
        data-test-classname="inventory-hierarchy-el" 
        onClick={()=>{
          let newList = state.stepList.slice(0,index+1)
          let url = `/inventory?category=${state.category}&stepList=${mapStepListToStr(newList)}`;
          if(url!==window.location.pathname+window.location.search){
            history.push(url)
          }
          dispatch({type:'set_step_list',stepList:newList})
        }}
      >
        {(s.name || s.name_txt || 'Unknown').toUpperCase()}
      </Typography>
    )
    if(index<state.stepList.length-1){
      hierarchyEl.push(<Typography className={classes.text}> / </Typography>)
    }
  })

  return (
    <div className={classes.root} data-test-id="inventory-hierarchy-container">
      {hierarchyEl}
    </div>
  )
}

export default withStyles(styles)(Hierarchy);