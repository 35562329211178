import React, {useState} from 'react';
import { withStyles, Typography,  Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getIconComponent, getInitials } from '../../../../../utilities';
import KTooltip from '../../../KTooltip/KTooltip';
import { tileStyles } from '../utils/styles';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
    marginTop:16,
  },
  topMessage: {
    fontSize: 12,
    marginTop:6,
    color:theme.palette.primaryText.main,
    letterSpacing:1,
    flexGrow:1
  },
  actionText:{
    fontSize:10,
    marginRight:8,
    marginTop:4,
    color:theme.palette.hyperLink.main,
    cursor:'pointer',
    textDecoration:'underline',
    width:'max-content',
    maxWidth:'100%'
  },
  bottomMessage: {
    fontSize: 14
  },
  avatar: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.avatar.main,
    fontSize: 12
  },
  chip: {
    display: 'flex',
    alignItems: 'center',
    paddingTop:2.5,
    height:26,
    paddingBottom: 2.5,
    '&:hover $avatar': {
      backgroundColor: theme.palette.primary.main
    }
  },
  listText: {
    marginLeft: 16,
    marginRight:20,
    color:theme.palette.primaryText.main,
    maxWidth:240,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize:13.75
  },
  clickableText:{
    '&:hover':{
      textDecoration:'underline'
    }
  },
  clickableListItem:{
    '&:hover svg':{
      filter: 'brightness(130%)'
      // color:`#7289DA !important`,
      // fill:`#7289DA !important`
    },
    '&:hover g':{
      filter: 'brightness(130%)'
      // color:`#7289DA !important`,
      // fill:`#7289DA !important`
    }
  },
  tooltip:{
    fontSize:13.75,
    whiteSpace:'pre-wrap',
  },
  hidden:{
    display:'none'
  },
  pureChip:{
    ...tileStyles.tag,
    background:theme.palette.chip.main,
    border:`1px solid ${theme.palette.chipBorder.main}`,
  },
  chipText:{
    ...tileStyles.tagText,
    color:theme.palette.primaryText.main
  }
})

const ListTile = props => {

  const {
    classes,
    title,
    altMessage,
    list,
    icons,
    hideIcons,
    disableCollapse,
    displayAsChip,
    action
  } = props;

  const [expanded, setExpanded] = useState(false)
  const [listHeight, setListHeight] = useState(disableCollapse?list.length*31:Math.min(list.length,3)*31)

  if(!list && altMessage){
    return (
      <div className={classes.root}>
      <Typography className={classes.topMessage}>{title}</Typography>
        <div className={classes.chip}>
          {getIconComponent({label: 'help', size: 28, colour: '#003C8F'})}
          <Typography className={classes.listText}>{altMessage}</Typography>
        </div>
      </div>
    )
  }

  const onClickExpand = () => {
    let interval;
    let currentHeight = listHeight;
    if(expanded){
      interval = setInterval(()=>{
        if(currentHeight>Math.min(3,list.length)*31){
          setListHeight(Math.max(currentHeight-4,Math.min(3,list.length)*31));
          currentHeight -= 4;
        }else{
          clearInterval(interval)
        }
      },1)
    }else{
      interval = setInterval(()=>{
        if(currentHeight<list.length*31){
          setListHeight(Math.min(currentHeight+4,list.length*31));
          currentHeight += 4;
        }else{
          clearInterval(interval)
        }
      },1)
    }
    setExpanded(!expanded)
  }

  return (
    <div className={classes.root} style={{alignItems:'flex-start'}} data-test-classname="list-data-tile">
      <div style={{width:tileStyles.headerContainer.width,marginRight:tileStyles.headerContainer.marginRight+8,flexShrink:0}}>
        <Typography className={classes.topMessage} data-test-classname="list-data-tile-title" >{title}</Typography>
        {
          action &&
          <div style={{display:'flex',flexWrap:'wrap'}}>
            {
              action.map(el=>(
                <Typography key={el.text} className={classes.actionText} onClick={el.action} style={el.actionColour?{color:el.actionColour,textDecoration:'none'}:undefined}>{el.text}</Typography>
              ))
            }
          </div>
        }
      </div>
      <div style={{overflow:'hidden',flexGrow:1,flexShrink:1}}>
        <div style={displayAsChip?{display:'flex',flexWrap:'wrap'}:{height:listHeight,overflow:'hidden'}}>
          {list.map((el, index) => (
            displayAsChip?
            <KTooltip title={el.name}>
              <div onClick={el.onClick} className={classes.pureChip} key={el.name}>
                <span className={classes.chipText}>{el.name}</span>
              </div>
            </KTooltip>
            :
            <KTooltip key={el.name} classes={{tooltip:el.tooltip?classes.tooltip:classes.hidden}} title={el.tooltip}>
              <div className={classes.chip+(el.onClick?' '+classes.clickableListItem:'')} onClick={el.onClick} style={{cursor:el.onClick?'pointer':'default'}}>
                {
                  icons?
                  <div style={{width:24,height:24,display:'flex',alignItems:'center',justifyContent:'center'}}>{icons[index]}</div>
                  :
                  hideIcons?undefined:
                  <Avatar className={classes.avatar} sizes>{getInitials(el.name)}</Avatar>
                }
                <Typography data-test-classname="list-data-tile-text" className={classes.listText + (el.onClick?' '+classes.clickableText:'')} style={hideIcons?{marginLeft:0}:undefined}>{el.name}</Typography>
              </div>
            </KTooltip>
          ))}
        </div>
        {
          list.length>3 && !disableCollapse && !displayAsChip &&
          <Typography onClick={onClickExpand}  data-test-classname="list-data-tile-expand-button" color='primary' style={{cursor:'pointer',marginLeft:0,letterSpacing:2,fontSize:12,marginTop:12}}>{expanded?'SEE LESS':'SEE MORE'}</Typography>
        }
      </div>
    </div>
  )
}

ListTile.propTypes = {
  classes: PropTypes.object.isRequired,
  topMessage: PropTypes.string,
  list: PropTypes.array,
  altMessage: PropTypes.string,
  displayAsChip: PropTypes.bool,
}

export default withStyles(styles)(ListTile);