import React, {useEffect, useState, useRef} from 'react';
import { withTheme, withStyles, Typography, CircularProgress, InputBase, InputAdornment, Select, MenuItem, IconButton, Modal, Paper, Button, LinearProgress } from '@material-ui/core';
import { getIconComponent, onClickResultItem, setHelpWdigetVisibility, toTitleCase} from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import CreateCollection from '../../../containers/CreateCollection/CreateCollection'
import InfoBox from './InfoBox';
import { getIconLabel } from '../../UI/SearchResults/utils';
import moment from 'moment';
import KTooltip from '../../UI/KTooltip/KTooltip';
import {useDispatch} from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import useAlert from '../../../hooks/useAlert';
import { checkIsAdmin, checkIsDataGov, checkIsDataManager } from '../../../permissionChecker';
import { editablePlatformCollections } from '../utils';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';
import CustomMenu from '../../UI/ContextMenu/ContextMenu'

const styles = theme => ({
  root:{
    marginBottom:30,
    position:'relative'
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
    flexGrow:1
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  selector: {
    ...theme.components.selector,
		width: 180,
  },
  hoverableText:{
    cursor:'pointer',
    width:"max-content",
    maxWidth:'100%',
    '&:hover':{
      textDecoration:'underline',
    }
  },
  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  nameFilter:{
    ...theme.components.inputBase,
    width:160,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
  listItem:{
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`
  },
  deleteModal:{
    width:480,
    padding:20,
    marginTop:'-10vh',
    paddingBottom:8,
    background:theme.palette.background.main,
    border:theme.palette.border.main
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  iconWrapper:{
    width:48,
    height:48,
  },
  columnHeader:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primary.main,
    marginLeft:16,
    overflow:'hidden'
  },
  columnText:{
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    marginLeft:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  chip:{
    display:'flex', 
    // padding:'4px 0', 
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`, 
    width:'100%',
    alignItems:'center',
    overflow:'hidden',
    height:48,
    // cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
})

function CollectionList(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    history,
    sessionData,
    tabOptions
  } = props;

  const [disableButton, setDisableButton] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const listRef = useRef();
  const searchTimeoutRef = useRef();

  const [buttonHovered, setButtonHovered] = useState(false);
  const reduxDispatch = useDispatch()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`basic-search-save-filter-modal`,
    isCancelledRef
  })

  useEffect(()=>{ 
    return () => {
      isCancelledRef.current = true
    }
  },[])

  useEffect(()=>{
    if(state.selectedCollection){
      setHelpWdigetVisibility(false)
    }else{
      setHelpWdigetVisibility(true)
    }
    return ()=>{setHelpWdigetVisibility(false)}
  // eslint-disable-next-line
  },[state.selectedCollection])
  // const categoryItems = [
  //   {dispName:'All Types',value:'all'},
  //   ...state.categoryList.map(el=>({
  //     dispName:toTitleCase(el.name.split('_').join(' ')),
  //     value:el.name
  //   }))
  // ]

  const loadCollections = ({page = 1, search = state.searchFilter, category = state.category, sort = state.sort}) => {
    if(page===1)dispatch({type:'set_collection_data',collectionLoading:true})
    else{setLoadingMore(true)}
    axiosCerebrum
      .get(
        `/api/collections`,
        {params:{
          page:page,
          per_page:10,
          'search.name':search && search.trim()!=='' ? search:undefined,
          category:category==='all'?undefined:category,
          sort
        }}
      ).then(response=>{
        dispatch({type:'set_collection_data',collectionData:page===1?response.data:{...response.data,items:[...state.collectionData.items,...response.data.items]}})
        setLoadingMore(false)
      }).catch(error=>{
        console.log(error);
        dispatch({type:'set_collection_data',collectionData:state.collectionData,collectionError:true})
        setLoadingMore(false)
      })
  }

  useEffect(()=>{
    loadCollections({ category:state.category})
  // eslint-disable-next-line
  },[])

  const onNameFilterChange = value => {
    dispatch({type:'set_search_filter',searchFilter:value})
    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(()=>{
      loadCollections({search:value})
    },200)
  }

  const onSortChange = value => {
    dispatch({type:'set_sort',sort:value})
    loadCollections({sort:value})
  }


  const isInViewport = (ref, offset = 0) => {
		if (!ref || !ref.current) return false;
		const bottom = ref.current.getBoundingClientRect().top;
		return (bottom + offset) >= 0 && (bottom + offset) <= window.innerHeight;
  }

  useEffect(()=>{
    if(state.collectionData && !state.collectionError && isInViewport(listRef) && !state.collectionLoading &&  !loadingMore && state.collectionData.page<state.collectionData.pages){
      loadCollections({page:state.collectionData.page+1})
    }
   // eslint-disable-next-line
  },[loadingMore, state.collectionData])

  window.onscroll = () => {
    if(state.collectionData && isInViewport(listRef) && !loadingMore && state.collectionData.page<state.collectionData.pages){
      loadCollections({page:state.collectionData.page+1})
    }
  }
  
  const onSave = () => {
    loadCollections({page:1})
    dispatch({type:'set_selected_collection',selectedCollection:undefined})
    dispatch({type:'set_edit_modal_open',editModalOpen:false});
    sendAlert({type:'info',message:'Collection successfully updated'})
  }

  const onDelete = () => {
    setDisableButton(true);
    axiosCerebrum.delete(
      `/api/collections/${state.selectedCollection.id}`
    ).then(response=>{
      setDisableButton(false);
      loadCollections({page:1,search:state.searchFilter, category:state.category});
      dispatch({type:'set_delete_modal_open',deleteModalOpen:false})
      reduxDispatch({
        type:actions.REMOVE_PAGE_CACHE,
        data:`${document.location.protocol + "//" + document.location.host}`+onClickResultItem({item:state.selectedCollection,urlOnly:true,id:state.selectedCollection.id,label:`collection`})
      })
      sendAlert({type:'info',message:'Collection successfully deleted'})
    }).catch(error=>{
      setDisableButton(false);
      console.log(error);  
      sendAlert({type:'error',message:'Error occurred deleting collection'})
    })
  }

  const checkEditable = collection => {
    if(!sessionData.user_role || sessionData.user_role.length===0)return false;
    let editable = false;
    let userRoles = sessionData.user_role.map(el=>el.toUpperCase());
    userRoles.forEach(el=>{
      if(collection.roles[el] && collection.roles[el].includes('EDIT')){
        editable = true;
      }
    })
    return editable
  }

  const checkCanBulkEdit = collection => {
    return (
      checkEditable(collection) || 
      editablePlatformCollections.includes(collection.id)
    )
  }

  const onCreateTemplate = (id) => {
    const payload = {
      "domain": document.location.protocol + "//" + document.location.hostname,
      "filters": {
        "collection_id": id
      },
      "ignore_cache":true,
      "lookup_code": "collection_bulk_edit_excel",
      "type": "COLLECTION_BULK_EDIT_EXCEL"
    }
    axiosCerebrum
      .post('/api/extracts',payload)
      .then(extractResponse=>{
        history.push(`/my_tasks?tabName=EXTRACTS&jobName=COLLECTION BULK EDIT FILE`)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({message:"Error occurred submitting the request, please try again",type:'error'})
      })
  }
  
  return (
    <div className={classes.root}>

      <InfoBox
        state={state}
        history={history}
      />

      <div style={{paddingTop:8,paddingBottom:16,display:'flex',alignItems:'flex-start',position:'sticky',top:162,background:theme.palette.background.main,zIndex:10}}>
        <Typography className={classes.title}>{`${state.collectionData?state.collectionData.total+' ':''}${state.category.includes(',')?'':state.category.replace(/_/g,' ')+' '}COLLECTION(S)`}</Typography>
        <InputBase
          inputProps={{
            'data-test-id':"collection-admin-search"
          }}
          value={state.searchFilter}
          onChange={event => onNameFilterChange(event.target.value)}
          variant={'filled'}
          placeholder={'Search'}
          className={classes.nameFilter}
          endAdornment={<InputAdornment style={{marginRight:6}}>{getIconComponent({label:'search',size:24,colour:theme.palette.primaryText.light})}</InputAdornment>}
        />
        <Select
          data-test-id="collection-admin-sort"
          className={classes.selector}
          style={{marginLeft:24}}
          value={state.sort}
          onChange={event => onSortChange(event.target.value)}
          MenuProps={{
            classes:{
              paper:classes.selectPaper
            }
          }}
        >
          {
            [
              {name:'A-Z',value:'ALPHABETICAL'},
              {name:'Z-A',value:'REVERSE_ALPHABETICAL'},
              {name:'Last modified',value:'END_DESC'},
              {name:'Created on',value:'START_DESC'},
              // {name:'Z-A',value:'REVERSE_ALPHABETICAL'},
              // {name:'A-Z',value:'alphabetical'},
            ].map(el => (
              <MenuItem  className={classes.menuItem} value={el.value}>
                <span>{el.name}</span>
              </MenuItem>
            ))
          }
        </Select>
      </div>
      {
        state.collectionLoading && <div style={{marginTop:60,textAlign:'center'}}><CircularProgress color='secondary'/></div>
      }
      {
        state.collectionError && <Typography className={classes.normalText} style={{marginTop:30}}>Error occurred loading collections</Typography>
      }
      {
        !state.collectionError && !state.collectionLoading && state.collectionData && state.collectionData.total>0 && 
        <div>
          <div style={{display:'flex',paddingBottom:12,position:'sticky',top:224,zIndex:10,background:theme.palette.background.main}}>
            <div className={classes.columnHeader} style={{flex:'0 0 24px'}}></div>
            <div className={classes.columnHeader} style={{flex:'1 1 100px',minWidth:80}}>NAME</div>
            <div className={classes.columnHeader} style={{flex:'1 1 160px'}}>DESCRIPTION</div>
            <div className={classes.columnHeader} style={{flex:'0 1 130px'}}>LAST MODIFIED</div>
            <div className={classes.columnHeader} style={{flex:'0 1 130px'}}>CREATED ON</div>
            <div className={classes.columnHeader} style={{flex:'0 0 154px'}}>
              ACTION
            </div>
          </div>
          
          {
            state.collectionData.items.map(el=>(
              <>
                <ContextMenuTrigger id={el.id}>
                  <div 
                    className={classes.chip} 
                    style={{background:buttonHovered?theme.palette.background.main:undefined}}
                  >
                    <div className={classes.columnText} style={{flex:'0 0 24px'}}>
                      {getIconComponent({label:getIconLabel({label:'collection',item:el}),size:24, colour:theme.palette.primaryText.light})}
                    </div>
                    <KTooltip title={el.name} placement="bottom-start">
                      <div 
                        data-test-classname='collection-list-item-name' 
                        className={classes.columnText } 
                        style={{flex:'1 1 100px',minWidth:80}}
                      >
                        <span
                          className={classes.hoverableText}
                          onClick={()=>{
                            onClickResultItem({item:el,history,id:el.id,label:`collection`,newWindow:true})
                          }}
                        >
                          {el.name}
                        </span>
                      </div>
                    </KTooltip>
                    <KTooltip title={el.description} placement="bottom-start">
                      <div data-test-classname='collection-list-item-description' className={classes.columnText} style={{flex:'1 1 160px'}}>
                        {el.description && el.description.trim()!==''?el.description:'No description'}
                      </div>
                    </KTooltip>
                    <div data-test-classname='collection-list-item-updated-at' className={classes.columnText} style={{flex:'0 1 130px',color:theme.palette.primaryText.light}}>
                      {moment(el.updated_at).format('ll')}
                    </div>
                    <div data-test-classname='collection-list-item-created-at' className={classes.columnText} style={{flex:'0 1 130px',color:theme.palette.primaryText.light}}>
                      {moment(el.created_at).format('ll')}
                    </div>
                    <div className={classes.columnText} style={{flex:'0 0 154px',display:'flex'}}>
                      {
                        checkEditable(el)  && 
                        <IconButton 
                          data-test-id={`collection-list-edit-${el.name.toLowerCase().replace(/\s/g,'_')}`}
                          style={{padding:6}}
                          onClick={(event)=>{
                            event.stopPropagation()
                            dispatch({type:'set_selected_collection',selectedCollection:el})
                            dispatch({type:'set_edit_modal_open',editModalOpen:true})
                          }}
                          onMouseEnter={()=>setButtonHovered(true)}
                          onMouseLeave={()=>setButtonHovered(false)}
                        >
                          {getIconComponent({label:'edit',size:24,colour:theme.palette.primaryText.light})}
                        </IconButton>
                      }
                      {
                        checkCanBulkEdit(el) && (checkIsAdmin({sessionData}) || checkIsDataGov({sessionData}) || checkIsDataManager({sessionData})) ?
                        <KTooltip title="Generate bulk edit file">
                          <IconButton 
                            data-test-id={`collection-list-download-file-${el.name.toLowerCase().replace(/\s/g,'_')}`}
                            style={{padding:6}}
                            onClick={(event)=>{
                              event.stopPropagation()
                              onCreateTemplate(el.id)
                            }}
                            onMouseEnter={()=>setButtonHovered(true)}
                            onMouseLeave={()=>setButtonHovered(false)}
                          >
                            {getIconComponent({label:'bulk_edit_k',size:24,colour:theme.palette.primaryText.light})}
                          </IconButton>
                        </KTooltip>
                        :
                        <div style={{width:36,height:36}}></div>
                      }
                      {
                        checkCanBulkEdit(el) && (checkIsAdmin({sessionData}) || checkIsDataGov({sessionData}) || checkIsDataManager({sessionData}))?
                        <KTooltip title="Import bulk edit file">
                          <IconButton 
                            data-test-id={`collection-list-bulk-edit-${el.name.toLowerCase().replace(/\s/g,'_')}`}
                            style={{padding:6}}
                            onClick={(event)=>{
                              event.stopPropagation()
                              dispatch({type:'set_import_collection',importCollection:el})
                              dispatch({type:'set_import_modal_open',importModalOpen:{forceCollection:true}})
                              dispatch({type:'set_tab_state',tabState:tabOptions.indexOf('IMPORT')})
                            }}
                            onMouseEnter={()=>setButtonHovered(true)}
                            onMouseLeave={()=>setButtonHovered(false)}
                          >
                            {getIconComponent({label:'bulk_edit',size:24,colour:theme.palette.primaryText.light})}
                          </IconButton>
                        </KTooltip>
                        :
                        <div style={{width:36,height:36}}></div>
                      }
                      {
                        checkEditable(el) && (checkIsAdmin({sessionData}) || checkIsDataGov({sessionData}) || checkIsDataManager({sessionData})) &&
                        <IconButton 
                          data-test-id={`collection-list-delete-${el.name.toLowerCase().replace(/\s/g,'_')}`}
                          style={{padding:6}}
                          onClick={(event)=>{
                            event.stopPropagation()
                            dispatch({type:'set_selected_collection',selectedCollection:el})
                            dispatch({type:'set_delete_modal_open',deleteModalOpen:true})
                          }}
                          onMouseEnter={()=>setButtonHovered(true)}
                          onMouseLeave={()=>setButtonHovered(false)}
                        >
                          {getIconComponent({label:'delete',size:24,colour:theme.palette.primaryText.light})}
                        </IconButton>
                      }
                    </div>
                  </div>
                </ContextMenuTrigger>
                <ContextMenu id={el.id}>
                  <CustomMenu
                    item={el}
                    actions={[
                      'open_new_tab'
                    ]}
                  />
                </ContextMenu>
              </>
            ))
          }
        </div>
      }
      {
        !state.collectionError && !state.collectionLoading && state.collectionData && state.collectionData.total===0 &&
        <Typography className={classes.normalText}  style={{marginTop:30}}>No collections found</Typography>
      }
      {
        state.selectedCollection &&
        <Modal open={state.editModalOpen} disableBackdropClick={true} disableEscapeKeyDown={true} hideBackdrop={false}>
          <div id='edit-collecion-modal-container' style={{backgroundColor:theme.palette.background.main,outline:'none',position:'absolute',left:40,too:0,bottom:0,right:0,height:'100vh',width:'100vw',overflow:'auto'}}>
            <CreateCollection
              history={history}
              presetStates={{
                editMode:true,
                // linkBy:Object.keys(state.selectedCollection.instance_roles).filter(el=>state.selectedCollection.instance_roles[el].includes('LINK')),
                editableBy:Object.keys(state.selectedCollection.roles).filter(el=>state.selectedCollection.roles[el].includes('EDIT')),
                description:state.selectedCollection.description,
                collectionId:state.selectedCollection.id,
                collectionName:state.selectedCollection.name,
                collectionShortName:state.selectedCollection.short_name,
                collectionType:state.selectedCollection.category,
                properties:state.selectedCollection.properties.map((el,index)=>({...el,position:index+1})),
                idSeq:state.selectedCollection.id_seq,
                enableDQ:state.selectedCollection.enable_dq_dashboarding,
              }}
              onActiveStepChange={()=>{
                let el = document.getElementById('edit-collecion-modal-container');
                el && el.scrollTo(0,0)
              }}
              onSave={()=>onSave()}
              onCancel={()=>{dispatch({type:'set_edit_modal_open',editModalOpen:false});dispatch({type:'set_selected_collection'})}}
            />
          </div>
        </Modal>
      }
      {
        state.selectedCollection &&
        <Modal open={state.deleteModalOpen} disableBackdropClick={true} disableEscapeKeyDown={true} hideBackdrop={false}>
          <div style={{height:'100vh',width:'100vw',display:'flex',outline:'none',alignItems:'center',justifyContent:'center'}}>
            <Paper className={classes.deleteModal}>
              <Typography style={{fontSize:20,color:theme.palette.primaryText.main}}>{`Delete ${toTitleCase(state.selectedCollection.name)} collection`}</Typography>
              {
                disableButton?
                <div style={{textAlign:'center',marginBottom:24}}>
                  <LinearProgress color='secondary' style={{height:6,width:'80%',margin:'auto',marginTop:32}}/>
                  <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Deletion in progress</Typography>
                </div>
                :
                <Typography style={{fontSize:16,marginTop:16,marginBottom:40,whiteSpace:'pre-wrap',color:theme.palette.primaryText.light}}>
                  {
                    `Are you sure?\n\nThis will permanetly remove the collection and cannot be undone.`
                  }
                </Typography>
              }
              <div style={{display:'flex',justifyContent:'flex-end'}}>
                <Button classes={{disabled:classes.disabledButton}} color='primary' style={{marginRight:8}} onClick={onDelete} disabled={disableButton}>DELETE PERMANENTLY</Button>
                <Button classes={{disabled:classes.disabledButton}} color='secondary' onClick={()=>{dispatch({type:'set_delete_modal_open',deleteModalOpen:false})}} disabled={disableButton}>CANCEL</Button>
              </div>
            </Paper>
          </div>
        </Modal>
      }
      <div
        style={{ marginBottom: 40, marginTop:16, display:'flex',justifyContent:'center' }}
        ref={listRef}
      >
        {
          loadingMore && <CircularProgress color='secondary'/>
        }
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(CollectionList));