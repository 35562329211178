import React, { useEffect } from 'react';
import { withTheme, withStyles, Typography, CircularProgress } from '@material-ui/core';
import {  onClickResultItem } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum'
import ListItem from '../ListItem/ListItem';

const styles = theme => ({
  title: {
    color: theme.palette.header.main,
    fontSize: 20,
  },
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    marginBottom:8,
    letterSpacing:1.5
  },
  chip:{
    height:24,
    display:'flex',
    alignItems:'center',
    borderRadius:12,
    fontSize:13.75,
    paddingLeft:12,
    paddingRight:12,
    maxWidth:400,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    width:'max-content'
  },
  emptyChip:{
    border:`1px solid ${theme.palette.border.main}`,
    background:theme.palette.chip.main,
    color:theme.palette.primaryText.main
  },
  selectedChip:{
    border:`1px solid ${theme.palette.primary.dark}`,
    background:theme.palette.primary.dark,
    color:theme.palette.background.main,
    cursor:'pointer',
    paddingLeft:6
  }
})

function Parent(props) {

  const {
    classes,
    state,
    history,
    dispatch,
  } = props;



  const loadParent = () => {
    if(!state.basicData.parent_id){
      dispatch({
        type:'set_parent',
        parentData:{total:0}
      })
      return;
    }
    dispatch({
      type:'set_parent',
      parentLoading:true
    })
    axiosCerebrum
      .get(
        `/api/groups/${state.basicData.parent_id}`
      )
      .then(response=>{
        dispatch({
          type:'set_parent',
          parentData:{
            total:response.data?1:0,
            items:response.data?[response.data]:[]
          }
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_parent',
          parentError:true
        })
      })
  }

  const loadChildren = () => {
    dispatch({
      type:'set_children',
      childrenLoading:true
    })
    axiosCerebrum
      .get(
        `/api/groups`,{
          params:{
            parent_id:state.basicData.id,
            per_page:200
          }
        }
      )
      .then(response=>{
        dispatch({
          type:'set_children',
          childrenData:response.data
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_children',
          childrenError:true
        })
      })
  }

  useEffect(()=>{
    if(!state.parentData)loadParent();
    if(!state.childrenData)loadChildren();
  // eslint-disable-next-line
  },[])
  
  return (
    <div>
      <div style={{display:'flex',alignItems:'center',marginBottom:16}}>
        <Typography className={classes.title}>TEAM HIERARCHY</Typography>
      </div>

      {
        state.childrenData && state.childrenData.total===0 && state.parentData && state.parentData.total===0 && 
        <Typography style={{fontSize:13.75}}>
          No parent or child found
        </Typography>
      }

      {
        (state.parentLoading || state.childrenLoading) && 
        <CircularProgress color='secondary'/>
      }

      {
        (state.parentError || state.childrenError) && 
        <Typography style={{fontSize:13.75}}>Error occurred loading parent/children</Typography>
      }

      {
        state.parentData && state.parentData.items?.length>0 && 
        <div>
          <Typography className={classes.sectionHeader}>CHILD OF</Typography>
          {
            state.parentData && state.parentData.items[0] && 
            <ListItem
              history={history}
              onClick={()=>onClickResultItem({label:'group', id:state.parentData.items[0].id, item:state.parentData.items[0],history})}
              item={state.parentData.items[0]}
            />
          }
        </div>
      }

      {
        state.childrenData && state.childrenData.items?.length>0 &&
        <div>
          <Typography className={classes.sectionHeader} style={{marginTop:16}}>PARENT OF</Typography>
          {
            state.childrenData && state.childrenData.items && state.childrenData.items.map(c=>(
              <ListItem
                history={history}
                onClick={()=>onClickResultItem({label:'group', id:c.id, item:c,history})}
                item={c}
              />
            ))
          }
        </div>
      }
     
    </div>
  )
}

export default withTheme()(withStyles(styles)(Parent));