import React,{useEffect} from 'react';
import { withTheme, withStyles} from '@material-ui/core';
import Contribution from './Contributions/Contributions'
import axiosCerebrum from '../../../axios-cerebrum'
import SurveyList from './SurveyList/SurveyList'

const styles = theme => ({
  root:{
    minHeight:window.innerHeight - 300
  }
})

function Knowledge(props) {

  const {
    classes,
    history,
    state,
    dispatch,
  } = props;

  const loadStats = ({isReload=false}) => {
    if(!isReload)dispatch({type:'set_stats',statsLoading:true})
    axiosCerebrum.get(
        `/api/me/surveys/stats`
      )
      .then(response=>{
        dispatch({type:'set_stats',statsData:response.data})
      })
      .catch(error=>{
        console.log(error);
        dispatch({type:'set_stats',statsError:true})
      })
  }

  useEffect(()=>{
    if(!state.statsData && !state.statsLoading)loadStats({});
    // eslint-disable-next-line
  },[])


  return (
    <div className={classes.root} id={'knowledge_container'}>
      <Contribution state={state} history={history} dispatch={dispatch}/>
      <SurveyList state={state} history={history} dispatch={dispatch} loadStats={loadStats}/>
    </div>
  )
}

export default withTheme()(withStyles(styles)(Knowledge));