import React, { useEffect, useReducer } from 'react';
import { withStyles,} from '@material-ui/core';
import PropTypes from 'prop-types';
import TabBar from '../../components/UI/TabBar/TabBar';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import 'url-search-params-polyfill';
import { setInitialState} from '../../utilities'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import { addHistory } from '../../HistoryManager';
import 'url-search-params-polyfill';
import Body from '../../components/DataProducts/Body/Body';

const styles = theme => ({
  button: {
    margin: '8px 0px 8px 16px',
    height: '3rem',
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
});


const initialState = {
    tabState: 0,
    subTabState: 0,
    selectedScoreBand:'100',
    byCollectionInstancePageNum:1
  }

const reducer = (state, action) => {
  switch (action.type) {
    case "set_tab_state":
      return {
        ...state,
        tabState: action.tabState
      }
    case 'set_sub_tab_state':
      return {
        ...state,
        subTabState: action.subTabState
      }
    case 'set_products':
      return {
        ...state,
        productsData:action.productsData,
        productsLoading:action.productsLoading,
        productsError:action.productsError,
      }
    default:
      throw new Error("Action type not supported");
  }
}

const DataProducts = props => {


  const {
    history,
    // classes,
    sessionData,
    pageCache,
    storePageCache,
  } = props;

  
  let storedInitialState = {...setInitialState(pageCache,initialState)};
  const [state, dispatch] = useReducer(reducer, storedInitialState);

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  // eslint-disable-next-line
  },[state])

  let tabOptions = ['Raw','Business','Enterprise','External']

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])

  useEffect(()=>{
    addHistory({url:window.location.pathname, iconLabel:'data_product', title: 'Data Products', subTitle: 'Data Products',type:'application'})
    // eslint-disable-next-line
  },[])
  
  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              title={'Data Products'}
              subtitle={' '}
              minHeight={100}
              label={'data_product'}
            />
          </div>
        )}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            state={state}
            dispatch={dispatch}
            tabOptions={tabOptions}
            sessionData={sessionData}
            history={history}
          />
        }
      />
    </div>
  )
}


DataProducts.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sessionData: PropTypes.object.isRequired,
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DataProducts));