import React, { memo, useEffect, useState, } from 'react';
import { Button, Typography, withStyles, withTheme } from '@material-ui/core';
import theme from '../../../theme';
import { formatNumber, getIconComponent, mapObjectName } from '../../../utilities';
import { getIconLabel } from '../SearchResults/utils';
import { Handle } from 'react-flow-renderer';
import CustomMenu from '../ContextMenu/ContextMenu'

const styles = theme => ({
  statsBox:{
    padding:'0 6px',
    height:32,
    display:'flex',
    alignItems:'center',
    border:`1px solid ${theme.palette.primary.main}`,
    background:theme.palette.background.main,
    borderRadius:3,
    marginBottom:8,
  },
  clickableBox:{
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  statsText:{
    fontSize:13.75,
    marginLeft:10,
    color:theme.palette.primaryText.main,
  },
  collectionText:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    whiteSpace:"pre-wrap",
    wordBreak:'break-word', 
    display:'flex', 
    flexWrap:'wrap',
    fontWeight:400
  },
  collectionNameText:{
    fontWeight:700,
    wordBreak:'break-word',
    color:theme.palette.hyperLink.main,
    textDecoration:"underline",
    cursor:'pointer',
    marginRight:4
  }
})

const InsightsNode =  memo(({ data, isConnectable, selected, type, classes }) => {

  const [seeMore, setSeeMore] = useState(false)
  const [contextMenuOpen, setContextMenuOpen] = useState(false)

  useEffect(()=>{
    if(!selected){
      setContextMenuOpen(false)
    }
  },[selected])
  
  return (
    <>
      <Handle
        type="target"
        position="left"
        style={{ 
          background: '#555',
          visibility:'hidden', 
          top:'50%'
        }}
      />
      <div
        style={{
          background: theme.palette.kChartGroupBackground.main,
          borderRadius:5,
          padding:'8px 8px',
          border:`1px solid ${theme.palette.primary.main}`,
          width:data.width,
        }}
        onContextMenu={(event)=>{
          event.preventDefault()
          data.onContextMenu && data.onContextMenu()
          setContextMenuOpen(true)
        }}
        onClick={data.onContainerClick}
      >
        <div 
          style={{display:'flex',alignItems:'center',overflow:"hidden",minHeight:44,marginBottom:8}}
        >
          {
            (data.obj || data.icon) &&
            <div style={{width:24,height:24,marginRight:8,marginLeft:4}}>
              {
                getIconComponent({
                  label: data.icon || getIconLabel({label:data.obj.object_type_txt || data.obj.object.name, item:data.obj}),
                  size:24,
                  colour:data.iconColour || theme.palette.primaryText.light
                })
              }
            </div>
          }
          <div style={{flexGrow:1,overflow:'hidden'}}>
            <Typography 
              style={{
                fontSize:13.75,
                color: data.titleColour || theme.palette.primaryText.main,
                cursor:'pointer',
                textDecoration:'underline',
              }}
             
              className={'overflow-text'}
            >
              <span 
                style={{width:"max-content"}}
                onClick={event=>{
                  if(data.onClick){
                    data.onClick();
                    event.stopPropagation();
                  }
                }}
              >
                {data.label}
              </span>
              
            </Typography>
            {
              data.subTitle && 
              <Typography 
                style={{
                  fontSize:12,
                  color: theme.palette.primaryText.light,
                  marginTop:4
                }}
                className={'overflow-text'}
              >
                {data.subTitle}
              </Typography>
            }
          </div>
        </div>
        <div style={{height:seeMore || !data.stats || data.stats.length<=2 ?undefined:80, overflow:'hidden'}}>
          {
            data.stats && data.stats.map((el,index)=>(
              <div 
                className={classes.statsBox + (el.onClick?' '+classes.clickableBox:'')} 
                onContextMenu={(event)=>{
                  setContextMenuOpen({url:el.url, top:index*40+60});
                  event.stopPropagation();
                  event.preventDefault();
                }} 
                onClick={el.onClick}
              >
                {getIconComponent({label:el.name,size:20,colour:theme.palette.primary.main})}
                <Typography className={classes.statsText}>
                  {`${formatNumber(Number(el.value).valueOf())} ${mapObjectName(el.name,true).replace(/_/g,' ')}(S)`}
                </Typography>
                 
              </div>
            ))
          }
        </div>
        {
          data.stats && data.stats.length>2 && 
          <Button 
            color='primary'
            onClick={(event)=>{event.stopPropagation();setSeeMore(!seeMore)}}
          >
            {seeMore?'SEE LESS':'SEE MORE'}
          </Button>
        }
        {
          data.collections && data.collections.length>0 &&
          <div style={{marginTop:8,marginBottom:8}}>
            {
              data.collections.map(c=>(
                <Typography className={classes.collectionText}>
                  {c.name}: {c.values.slice(0,5).map((v,index)=><span className={classes.collectionNameText} onClick={event=>{event.stopPropagation();v.onClick()}}>{v.text}{index<Math.min(5,c.values.length)-1?',':''}</span>)}
                  {c.values.length>5?'and more':''}
                </Typography>
              ))
            }
          </div>
        }
      </div>
      <Handle
        type="source"
        position="right"
        style={{ 
          background: '#555',
          visibility:'hidden', 
          top:'50%'
        }}
      />

      <div
        style={{
          position:"absolute",
          display:'inline-flex',
          visibility:contextMenuOpen?'visible':'hidden',
          pointerEvents:contextMenuOpen?'auto':'none',
          top:contextMenuOpen.top || 24,
          right:-320
        }}
      >
        <CustomMenu
          item={data.obj}
          actions={[
            'open_new_tab',
          ]}
          itemUrl={contextMenuOpen.url}
        />
      </div>
    </>
  );
});

export default withTheme()(withStyles(styles)(InsightsNode));