import React from 'react';
import PropTypes from 'prop-types';
import { withStyles} from '@material-ui/core';
import Reference from '../Reference/Reference'
import Content from '../Content/Content'
import NewLineageContainer from '../../UI/Lineage/NewLineageContainer'

const styles = theme => ({
  root: {

  },
  ItemListWrapper: {
  }
})

function Body(props) {

  const {
    classes,
    tabState,
    history,
    basicData,
    // new props
    state,
    dispatch,
    isLineageLinkable
  } = props;

  let bodyContent;
  if(basicData.reference===false){
    switch (tabState) {
      case 0: //contentList
        bodyContent = (
          <Content
            history={history}
            state={state}
            dispatch={dispatch}
          />
        );
        break;
      case 1: // summary
        bodyContent = (
          <NewLineageContainer
            history={history}
            state={state}
            dispatch={dispatch}
            isLinkable={isLineageLinkable}
          />
        )
        break;
      default:
        break;
    }
  }
  else{
    switch (tabState) {
      case 0: //contentList
        bodyContent = (
          <Content
            history={history}
            state={state}
            dispatch={dispatch}
          />
        );
        break;
      case 1: // summary
        bodyContent = (
          <NewLineageContainer
            history={history}
            state={state}
            dispatch={dispatch}
            isLinkable={isLineageLinkable}
          />
        )
        break;
      case 2:
        bodyContent = (
          <Reference
            history={history}
            state={state}
            dispatch={dispatch}
          />
        )
        break;
      default:
    }
  }
  
  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.object,
  sessionData: PropTypes.object
}

export default withStyles(styles)(Body);