import React from 'react';
import { withStyles, Typography} from '@material-ui/core';

const styles = theme => ({
  root:{
    marginBottom:60
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
    marginBottom:16,
    marginTop:8
  },
  description:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    wordBreak: 'break-word',
    whiteSpace:'pre-wrap',
    minWidth:540,
    width:'65%'
  }
})

function Details(props) {

  const {
    classes,
    state,
  } = props;


  return (
    <div className={classes.root}>
      <Typography className={classes.title}>DESCRIPTION</Typography>
      <Typography className={classes.description}>
        {
          state.basicData.description && state.basicData.description.trim()!==''?
          state.basicData.description:'No description provided'
        }
      </Typography>
    </div>
  )
}

export default withStyles(styles)(Details);