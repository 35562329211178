import React  from 'react';
import PropTypes from 'prop-types';
import { withStyles} from '@material-ui/core';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import Member from '../Member/Member';
import Parent from '../Parent/Parent';

const styles = theme => ({

})

const Details = props => {

  const {
		history,
    state,
    dispatch,
    fetchList,
  } = props;  
  

  return (
    <div>
      <UpdateInputCerebrum
        url={`/api/groups/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        label="group"
        initialValue={state.basicData.description}
        property="description"
        placeholder="Add a description"
        multiline
        enableMarkDown={true}
        data={state.basicData}
        fetchList={fetchList}
        state={state}
      />
      <div style={{marginTop:60}}>
        <Parent
          state={state}
          dispatch={dispatch}
          history={history}
        />
      </div>
      <div style={{marginTop:60}}>
        <Member
          state={state}
          dispatch={dispatch}
          history={history}
        />
      </div>
	  </div>
  )
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  userName: PropTypes.string,
  profileState: PropTypes.object.isRequired,
  profileDispatch: PropTypes.func.isRequired
}

export default withStyles(styles)(Details);