import React, { } from 'react';
import { withTheme, withStyles, Button, Typography, Switch} from '@material-ui/core';


const styles = theme => ({
  switchText:{
    color:theme.palette.primary.main,
    fontSize:13.75,
  }
})

const DisplayAccessRole = props => {

  const {
    classes,
    theme,
    setActiveStep,
    displayAccess, 
    setDisplayAccess
  } = props;


  
  return (
    <div className={classes.root}>
      <div style={{display:'flex',alignItems:'flex-start',justifyContent:'space-between'}}>
        <Typography style={{width:500,whiteSpace:'pre-wrap',color:theme.palette.primaryText.main,fontSize:13.75}}>
          Display access roles linked to the asset that the user is requesting for?
          {'\n\n'}
          Note: Access roles are not loaded for all sources.
        </Typography>
        <div style={{display:'flex',width:142,justifyContent:'center',alignItems:'center',marginRight:13.75}}>
          <Typography className={classes.switchText} style={{width:20,color:displayAccess?theme.palette.primaryText.light:undefined}}>
            OFF
          </Typography>
          <Switch
            checked={displayAccess}
            onChange={(event, checked)=>{
              setDisplayAccess(checked)
            }}
            value={displayAccess}
            data-test-id='display-access-switch'
            color="primary"
          />
          <Typography className={classes.switchText} style={{width:30,color:!displayAccess?theme.palette.primaryText.light:undefined}}>
            ON
          </Typography>
        </div>
      </div>
      <Button onClick={()=>setActiveStep(2)} data-test-id='display-access-next-button' style={{marginTop:24}} color='primary' variant='contained' >NEXT</Button>
    </div>
  )
}

export default withTheme()(withStyles(styles)(DisplayAccessRole));