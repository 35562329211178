import React, {useEffect, useState} from 'react';
import { withTheme, Typography, IconButton, Button} from '@material-ui/core';
import SearchSelector from '../../UI/SearchSelector/SearchSelector';
import {  collectionIds, getIconComponent } from '../../../utilities';
import LabeledSelector from '../../UI/LabeledSelector/LabeledSelector'
import { getIconLabel } from '../../UI/SearchResults/utils';
import axiosSolr from '../../../axios-solr';

const AdditionalSelector = props => {

  const {
    classes,
    theme,
    state,
    onSelectAdditionalCriteria,
    onRemoveAdditionalCriteria,
    onCriteriaNextClick
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [collectionType, setCollectionType] = useState('all')

  const [suggestion, setSuggestion] = useState([])

  const loadSuggestion = () => {
    axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:"*",
          fq:`id:(${collectionIds.verifiedUseCase} OR ${collectionIds.classification} OR ${collectionIds.domain} OR ${collectionIds.category})`,
          rows:4,
          sort:'name_srt asc'
        }}
      )
      .then(res=>{
        setSuggestion(res.data.response.docs)
      })
      .catch(err=>{
        console.log(err)
      })
  }

  useEffect(()=>{
    loadSuggestion()
  },[])
  
  return (
    <div className={classes.block}>
      <div style={{display:'flex',flexWrap:'wrap',marginBottom:16}}>
        <LabeledSelector  
          title="Collection Type"  
          value={collectionType}
          testID="colection-type-select"
          onChange={event=>setCollectionType(event.target.value)}
          options={[
            {
              name:'DATA MANAGEMENT',
              value:'DATA_MANAGEMENT',
            },
            {
              name:'DATA GOVERNANCE',
              value:'DATA_GOVERNANCE',
            },
            {
              name:'PLATFORM',
              value:'PLATFORM',
            },
          ]}
          placeholder="Add domain, classification and collections to assessment"
        />
      </div>
      <SearchSelector
        url={'/solr/search/select'}
        params={{
          q:`${searchValue}*`,
          fq:'collection_type_srt:(DATA_MANAGEMENT OR DATA_GOVERNANCE OR PLATFORM) AND object_type_srt:COLLECTION' + (collectionType!=='all'?` AND collection_type_srt:${collectionType}`:'')
            + (state.additionalCriteria.length>0?` -id:(${state.additionalCriteria.map(el=>el.id).join(' OR ')})`:'')
          ,
          fl:"*",
          rows: 10
        }}
        testID="collection-search-input"
        autoSuggestion={collectionType!=='all'}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        placeholder={`Search for Collections`}
        onResultClick={onSelectAdditionalCriteria}
      />
      {
        suggestion.filter(el=>!state.additionalCriteria.find(a=>a.id===el.id)).length>0 &&
        <div style={{marginLeft:16}}>
          <Typography style={{fontSize:12,marginBottom:8,marginTop:4,color:theme.palette.primaryText.light}}>SUGGESTED COLLECTIONS</Typography>
          <div style={{display:'flex'}}>
            {
              suggestion.filter(el=>!state.additionalCriteria.find(a=>a.id===el.id)).map(el=>(
                <div className={classes.suggestionChip} onClick={()=>onSelectAdditionalCriteria(el)}>
                  {el.name_txt}
                </div>
              ))
            }
          </div>
        </div>
      }
      <Typography style={{fontSize:12,marginTop:32,marginBottom:12}}>ADDITIONAL CRITERIA SELECTED (MAX 3)</Typography>
      {
        state.additionalCriteria.length===0 && 
        <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>None</Typography>
      }
      {
        state.additionalCriteria.map(el=>(
          <div className={classes.collectionListItem}>
            <div style={{width:24,height:24,marginLeft:16,marginRight:16}}>
              {getIconComponent({size:24,colour:theme.palette.primaryText.light,label:getIconLabel({label:'collection',item:el})})}
            </div>
            <Typography className={classes.collectionItemText}>
              {el.name_txt}
            </Typography>
            <div className={classes.collectionChildChip}>
              {el.child_object_count_srt} {el.name_txt}(s)
            </div>
            <IconButton onClick={()=>onRemoveAdditionalCriteria(el)}>
              {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
            </IconButton>
          </div>
        ))
      }
      <Button style={{marginTop:24}} onClick={()=>onCriteriaNextClick()} color='primary' variant="contained" >NEXT</Button>
    </div>
  )
}

export default withTheme()(AdditionalSelector);