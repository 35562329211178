import React, { useEffect } from 'react';
import { withTheme, withStyles, Typography, Select, MenuItem } from '@material-ui/core';
import axiosSolr from '../../../axios-solr';
import PropTypes from 'prop-types';
import { mapObjectName, titleCaseObjectName } from '../../../utilities';
import LabeledSelector from '../LabeledSelector/LabeledSelector';
import { filterMap, recoverFilterValues } from './filterHistoryManager';
import { getFilterHistorys } from './filterHistoryManager';
import KTooltip from '../KTooltip/KTooltip';
import { globalListenerRef } from '../../../GlobalListenerRef';
import { SolrLongListLoader } from '../../../LongListLoader';


const styles = theme => ({
  historySelector: {
    width: 130,
    paddingLeft: 8,
    fontSize: 13.75,
    marginBottom: 12,
    height: 24,
    background: theme.palette.chip.main,
    color: theme.palette.primaryText.main,
    '& svg': {
      color: theme.palette.primaryText.main
    },
    border: `1px solid ${theme.palette.chipBorder.main}`,
    borderRadius: 12,
  },
  menuItem: {
    fontSize: 13.75,
    // background:theme.palette.background.main,
    '&:hover': {
      background: `${theme.palette.hovered.main} !important`,
    }
  }
})


const Filter = props => {

  const {
    theme,
    classes,
    // history,
    // object,
    setSelectedObjectType,
    selectedObjectType,
    // selectedRelation,
    state,
    dispatch,
    isPluginComponent,
    linkableObjects,
    enableFilterHistory
  } = props;

  const {
    collections, setCollections,
    collectionParentList, setCollectionParentList,
    sources, setSources,
    issueSources, setIssueSources,
    teams, setTeams,
    userTypes, setUserTypes,
    databases, setDatabases,
    schemas, setSchemas,
    tables, setTables,
    locations, setLocations
  } = filterMap({ state, dispatch })

  // const filterSearchValue = state.filterSearchValue;
  // const setFilterSearchValue = value => dispatch({type:'set_filter_search_value',filterSearchValue:value})

  const loadCollections = type => {
    const load = ({ prevData = [] }) => {
      axiosSolr
        .get(
          `/solr/search/select`, {
          params: {
            q: "*",
            fq: `object_type_srt:COLLECTION AND collection_type_srt:(DATA_GOVERNANCE OR DATA_MANAGEMENT)${type === 'all' ? '' : ` AND collection_type_srt:${type}`}`,
            rows: 50,
            start: prevData.length,
            sort: 'name_srt asc'
          }
        }
        )
        .then(response => {
          let data = [...prevData, ...response.data.response.docs]
          if (response.data.response.numFound > data.length) {
            load({ prevData: data })
          } else {
            setCollections(data)
          }
        })
        .catch(error => {
          console.log(error)
          setCollections(prevData)
        })
    };
    load({})
  }

  const loadGlossary = () => {
    const load = ({ prevData = [] }) => {
      axiosSolr
        .get(
          `/solr/search/select`, {
          params: {
            q: "*",
            fq: `object_type_srt:COLLECTION AND collection_type_srt:GLOSSARY`,
            rows: 50,
            start: prevData.length,
            sort: 'name_srt asc'
          }
        }
        )
        .then(response => {
          let data = [...prevData, ...response.data.response.docs]
          if (response.data.response.numFound > data.length) {
            load({ prevData: data })
          } else {
            setCollections(data)
          }
        })
        .catch(error => {
          console.log(error)
          setCollections(prevData)
        })
    };
    load({})
  }

  const loadCollectionParent = ({ collection = state.collection }) => {
    const load = ({ prevData = [] }) => {
      axiosSolr
        .get(
          `/solr/search/select`, {
          params: {
            q: "*",
            fq: `parent_id_srt:${collection === 'all' ? '*' : collection}`,
            rows: 0,
            facet: 'on',
            'facet.field': 'hierarchy_parent_srt',
            'facet.mincount': 1,
            'facet.limit': 50,
            'facet.offset': prevData.length
          }
        }
        )
        .then(response => {
          let data = [];
          if (response.data.facet_counts.facet_fields && response.data.facet_counts.facet_fields.hierarchy_parent_srt) {
            let facet = response.data.facet_counts.facet_fields.hierarchy_parent_srt;
            facet.forEach((el, index) => {
              if (index % 2 === 0) {
                data.push({
                  name: facet[index],
                  value: facet[index + 1]
                })
              }
            })
          }
          if (data.length === 50) {
            load({ prevData: [...prevData, ...data] })
          } else {
            setCollectionParentList([...prevData, ...data])
          }
        })
        .catch(error => {
          console.log(error)
          setCollections(prevData)
        })
    };
    load({})
  }

  const loadSources = () => {
    const load = ({ prevData = [], page = 1 }) => {
      axiosSolr
        .get(
          `/api/sources`, {
          params: {
            per_page: 50,
            page,
            internal_flag: false,
            sort: 'ALPHABETICAL'
          }
        }
        )
        .then(response => {
          let data = [...prevData, ...response.data.items]
          if (response.data.pages > response.data.page) {
            load({ prevData: data, page: response.data.page + 1 })
          } else {
            setSources(data)
          }
        })
        .catch(error => {
          console.log(error)
          setSources(prevData)
        })
    };
    load({})
  }


  const loadIssueSources = () => {
    const load = () => {
      axiosSolr
        .get(
          `/solr/search/select`, {
          params: {
            q: "*",
            fq: `object_type_srt:ISSUE`,
            rows: 0,
            facet: 'on',
            'facet.field': 'source_srt',
            'facet.mincount': 1,
            'facet.limit': 100
          }
        }
        )
        .then(response => {
          if (response.data.facet_counts && response.data.facet_counts.facet_fields && response.data.facet_counts.facet_fields.source_srt) {
            let data = response.data.facet_counts.facet_fields.source_srt.filter(el => typeof (el) === 'string')
            setIssueSources(data)
          }
        })
        .catch(error => {
          console.log(error)
          setIssueSources([])
        })
    };
    load({})
  }

  const loadTeams = () => {
    const load = ({ prevData = [] }) => {
      axiosSolr
        .get(
          `/solr/search/select`, {
          params: {
            q: "*",
            fq: `object_type_srt:TEAM`,
            rows: 50,
            start: prevData.length
          }
        }
        )
        .then(response => {
          let data = [...prevData, ...response.data.response.docs]
          if (response.data.response.numFound > data.length) {
            load({ prevData: data })
          } else {
            setTeams(data)
          }
        })
        .catch(error => {
          console.log(error)
          setTeams(prevData)
        })
    };
    load({})
  }

  const loadUserTypes = () => {
    const load = () => {
      axiosSolr
        .get(
          `/solr/search/select`, {
          params: {
            q: "*",
            fq: `object_type_srt:USER`,
            rows: 0,
            facet: 'on',
            'facet.field': 'account_type_srt',
            'facet.mincount': 1,
            'facet.limit': 100
          }
        }
        )
        .then(response => {
          if (response.data.facet_counts && response.data.facet_counts.facet_fields && response.data.facet_counts.facet_fields.account_type_srt) {
            let data = response.data.facet_counts.facet_fields.account_type_srt.filter(el => typeof (el) === 'string')
            setUserTypes(data)
          }
        })
        .catch(error => {
          console.log(error)
          setUserTypes([])
        })
    };
    load({})
  }


  const loadDatabases = async sourceID => {
    if (sourceID === 'all') {
      setDatabases([])
      return;
    }
    let dbList = []
    let promise = new Promise((resolve, reject) => {
      SolrLongListLoader({
        url: `/solr/search/select`,
        params: {
          q: "*",
          fq: `object_type_srt:DATABASE${sourceID === 'all' ? '' : ` AND source_id_srt:${sourceID}`}`,
          rows: 50,
          sort: 'name_srt asc'
        },
        rows: 50,
        onFinishLoad: ({ data }) => {
          setDatabases(data)
          dbList = data;
          resolve()
        },
        onError: (error) => {
          reject();
          console.log(error)
          setDatabases([])
        }
      })
    })

    await promise
    return dbList;
  }

  const loadSchemas = async ({ sourceID = state.source, dbID, dbList = databases }) => {
    if (dbID === 'all') {
      setSchemas([])
      return;
    }
    let schemaList = []
    let promise = new Promise((resolve, reject) => {
      SolrLongListLoader({
        url: `/solr/search/select`,
        params: {
          q: "*",
          fq: `object_type_srt:SCHEMA${sourceID === 'all' ? '' : ` AND source_id_srt:${sourceID}`}${dbID === 'all' ? '' : ` AND database_srt:"${dbList.find(d => d.id === dbID)?.name_txt}"`}`,
          rows: 50,
          sort: 'name_srt asc'
        },
        rows: 50,
        onFinishLoad: ({ data }) => {
          setSchemas(data)
          schemaList = data;
          resolve()
        },
        onError: (error) => {
          reject();
          console.log(error)
          setSchemas([])
        }
      })
    })

    await promise;

    return schemaList;
  }

  const loadTables = async ({ sourceID = state.source, dbId = state.database, dbList = databases, schemaID, schemaList = schemas }) => {
    if (schemaID === 'all') {
      setTables([])
      return;
    }
    let promise = new Promise((resolve, reject) => {
      SolrLongListLoader({
        url: `/solr/search/select`,
        params: {
          q: "*",
          fq: `object_type_srt:TABLE${sourceID === 'all' ? '' : ` AND source_id_srt:${sourceID}`}${dbId === 'all' ? '' : ` AND database_srt:"${dbList.find(d => d.id === dbId)?.name_txt}"`}${schemaID === 'all' ? '' : ` AND schema_srt:"${schemaList.find(d => d.id === schemaID)?.name_txt}"`}`,
          rows: 50,
          sort: 'name_srt asc'
        },
        rows: 50,
        onFinishLoad: ({ data }) => {
          setTables(data)
          resolve()
        },
        onError: (error) => {
          reject();
          console.log(error)
          setTables([])
        }
      })
    })

    await promise
  }

  const loadLocation = ({ value, objectType = selectedObjectType }) => {
    if (value === 'all') {
      setLocations([])
      return;
    }
    const load = ({ prevData = [] }) => {
      axiosSolr
        .get(
          `/solr/search/select`, {
          params: {
            q: "*",
            fq: `object_type_srt:${mapObjectName(objectType, undefined, true)}${value === 'all' ? '' : ` AND source_id_srt:${value}`}`,
            rows: 0,
            facet: 'on',
            'facet.field': 'location_srt',
            'facet.mincount': 1,
            'facet.sort': 'index',
            'facet.limit': 50,
            'facet.offset': prevData.length
          }
        }
        )
        .then(response => {
          let data = [];
          if (response.data.facet_counts.facet_fields && response.data.facet_counts.facet_fields.location_srt) {
            let facet = response.data.facet_counts.facet_fields.location_srt;
            facet.forEach((el, index) => {
              if (index % 2 === 0) {
                data.push({
                  name: facet[index],
                  value: facet[index + 1]
                })
              }
            })
          }
          if (data.length === 50) {
            load({ prevData: [...prevData, ...data] })
          } else {
            setLocations([...prevData, ...data])
          }
        })
        .catch(error => {
          console.log(error)
          setLocations(prevData)
        })
    };
    load({})
  }


  const onObjectTypeChange = objectType => {
    // same as above 

    dispatch({ type: 'set_collection', collection: 'all' })
    dispatch({ type: 'set_collection_type', collectionType: 'all' })
    dispatch({ type: 'set_source', source: 'all' })
    dispatch({ type: 'set_database', database: 'all' })
    dispatch({ type: 'set_schema', schema: 'all' })
    dispatch({ type: 'set_team', team: 'all' })
    dispatch({ type: 'set_user_type', userType: 'all' })
    dispatch({ type: 'set_reference', reference: 'all' })

    if (objectType === 'HOST') return;
    if (objectType === 'TOOL') return;

    if (['COLLECTION_INSTANCE'].includes(objectType)) {
      loadCollections('all')
    } else if (['TERM'].includes(objectType)) {
      loadGlossary()
    } else if (['USER'].includes(objectType)) {
      loadTeams()
      loadUserTypes()
      dispatch({ type: 'set_user_type', userType: 'USER_ACCOUNT' })
    }
    else if (['ISSUE'].includes(objectType)) {
      loadIssueSources()
    }
    else {
      loadSources()
    }
  }

  useEffect(() => {
    onObjectTypeChange(selectedObjectType)
    const onMsgReceived = (msg) => {
      if (msg.data.selected_object_type) {
        onObjectTypeChange(msg.data.selected_object_type)
      }
    }
    window.removeEventListener('message', globalListenerRef.linkModalFilterMsgListener);
    globalListenerRef.linkModalFilterMsgListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.linkModalFilterMsgListener);
    return (() => { window.removeEventListener('message', globalListenerRef.linkModalFilterMsgListener); })
    // eslint-disable-next-line
  }, [])

  const onChangeCollectionType = value => {
    dispatch({ type: 'set_collection_type', collectionType: value })
    dispatch({ type: 'set_collection', collection: 'all' })
    loadCollections(value)
  }

  const onChangeCollection = value => {
    if (value === state.collection) return;
    dispatch({ type: 'set_collection', collection: value })
    if (value !== 'all') loadCollectionParent({ collection: value })
    dispatch({ type: 'set_collection_parent', collectionParent: 'all' })
  }

  const onChangeCollectionParent = (value) => {
    dispatch({ type: 'set_collection_parent', collectionParent: value })
  }

  const onChangeSource = (value, type) => {
    dispatch({ type: 'set_source', source: value })
    dispatch({ type: 'set_database', database: 'all' })
    dispatch({ type: 'set_schema', schema: 'all' })
    dispatch({ type: 'set_table', table: 'all' })
    dispatch({ type: 'set_location', location: 'all' })
    if (type === 'database') {
      loadDatabases(value)
    }
    if (type === 'tool') {
      loadLocation({ value })
    }
  }

  const onChangeIssueSource = (value) => {
    dispatch({ type: 'set_issue_source', issueSource: value })
  }

  const onChangeUserType = value => {
    dispatch({ type: 'set_user_type', userType: value })
  }

  const onChangeTeam = value => {
    dispatch({ type: 'set_team', team: value })
  }

  const onChangeDatabase = value => {
    dispatch({ type: 'set_database', database: value })
    dispatch({ type: 'set_schema', schema: 'all' })
    dispatch({ type: 'set_table', table: 'all' })
    loadSchemas({
      dbID: value
    })
  }

  const onChangeSchema = value => {
    dispatch({ type: 'set_schema', schema: value })
    dispatch({ type: 'set_table', table: 'all' })
    loadTables({
      schemaID: value
    })
  }

  const onChangeTable = value => {
    dispatch({ type: 'set_table', table: value })
  }

  const onChangeLocation = value => {
    dispatch({ type: 'set_location', location: value })
  }

  const onChangeReference = value => {
    dispatch({ type: 'set_reference', reference: value })
  }


  let CollectionTypeFilter = (
    <LabeledSelector
      title="Collection Type"
      value={state.collectionType}
      testID="link-modal-collection-type-filter"
      onChange={event => onChangeCollectionType(event.target.value)}
      options={['DATA_GOVERNANCE', 'DATA_MANAGEMENT'].map(d => ({ value: d, name: d.replace(/_/g, ' ') }))}
      placeholder="Search for a Collection Type"
    />
  )


  let CollectionFilter = (
    <LabeledSelector
      title="Collection"
      value={state.collection}
      testID="link-modal-collection-filter"
      onChange={event => onChangeCollection(event.target.value)}
      options={collections.map(d => ({ value: d.id, name: d.name_txt }))}
      placeholder="Search for a Collection"
    />
  )

  let GlossaryFilter = (
    <LabeledSelector
      title="Glossary"
      value={state.collection}
      testID="link-modal-glossary-filter"
      onChange={event => onChangeCollection(event.target.value)}
      options={collections.map(d => ({ value: d.id, name: d.name_txt }))}
      placeholder="Search for a Glossary"
    />
  )

  let selectedCollectionName = collections?.find(d => d.id === state.collection)?.name_txt || ''
  let collectionParentFilter = (
    <LabeledSelector
      title='Parent'
      value={state.collectionParent}
      testID="link-modal-collection-parent-filter"
      onChange={event => onChangeCollectionParent(event.target.value)}
      options={collectionParentList.map(d => ({ name: d.name.toLowerCase()===selectedCollectionName?.toLowerCase()?'NO PARENT':d.name, value: d.name }))}
      placeholder="Search for a Parent"
    />
  )

  let userTypeFilter = (
    <LabeledSelector
      title="Account Type"
      value={state.userType}
      testID="link-modal-account-type-filter"
      onChange={event => onChangeUserType(event.target.value)}
      options={userTypes.map(d => ({ value: d, name: d }))}
      placeholder="Search for a User Type"
    />
  )


  let TeamFilter = (
    <LabeledSelector
      title="Team"
      value={state.team}
      testID="link-modal-team-filter"
      onChange={event => onChangeTeam(event.target.value)}
      options={teams.map(d => ({ value: d.id, name: d.name_txt }))}
      placeholder="Search for a Team"
    />
  )


  let DBSourceFilter = (
    <LabeledSelector
      title="Source"
      value={state.source}
      testID="link-modal-source-filter"
      onChange={event => onChangeSource(event.target.value, 'database')}
      options={sources.filter(el => el.source_template.type === 'DATABASE').map(d => ({ value: d.id, name: d.name }))}
      placeholder="Search for a Source"
    />
  )

  let IssueSourceFilter = (
    <LabeledSelector
      title="Source"
      value={state.issueSource}
      testID="link-modal-issue-source-filter"
      onChange={event => onChangeIssueSource(event.target.value)}
      options={issueSources.map(d => ({ value: d, name: d }))}
      placeholder="Search for a Source"
    />
  )

  let ToolSourceFilter = (
    <LabeledSelector
      title="Source"
      value={state.source}
      testID="link-modal-source-filter"
      onChange={event => onChangeSource(event.target.value, 'tool')}
      options={sources.filter(el => el.source_template.type === 'TOOL').map(d => ({ value: d.id, name: d.name }))}
      placeholder="Search for a Tool"
    />
  )



  let databaseFilter = (
    <LabeledSelector
      title="Database"
      value={state.database}
      testID="link-modal-database-filter"
      onChange={event => onChangeDatabase(event.target.value)}
      options={databases.map(d => ({ value: d.id, name: d.name_txt }))}
      placeholder="Search for a Database"
    />
  )




  let schemaFilter = (
    <LabeledSelector
      title="Schema"
      value={state.schema}
      testID="link-modal-schema-filter"
      onChange={event => onChangeSchema(event.target.value)}
      options={schemas.map(d => ({ value: d.id, name: d.name_txt }))}
      placeholder="Search for a Schema"
    />
  )



  let tableFilter = (
    <LabeledSelector
      title="Table"
      value={state.table}
      testID="link-modal-table-filter"
      onChange={event => onChangeTable(event.target.value)}
      options={tables.map(d => ({ value: d.id, name: d.name_txt }))}
      placeholder="Search for a Table"
    />
  )

  let locationFilter = (
    <LabeledSelector
      title="Location"
      value={state.location}
      testID="link-modal-location-filter"
      onChange={event => onChangeLocation(event.target.value)}
      options={locations.map(d => ({ value: d.name, name: `${d.name} (${d.value})` }))}
      placeholder="Search for a Location"
    />
  )

  let referenceFilter = (
    <LabeledSelector
      title="Reference"
      value={state.reference}
      testID="link-modal-reference-filter"
      onChange={event => onChangeReference(event.target.value)}
      options={[
        { value: 'all', name: 'ALL' },
        { value: 'NO', name: 'NO' },
        { value: 'YES', name: 'YES' }
      ]}
      hideSearch
      removeDefaultOption
    />
  )

  let filterArr = []

  if (selectedObjectType === 'COLLECTION_INSTANCE') {
    filterArr = [CollectionTypeFilter, CollectionFilter]
  }
  if (selectedObjectType === 'TERM') {
    filterArr = [GlossaryFilter]
    if (state.collection !== 'all') filterArr.push(collectionParentFilter)
  }
  if (selectedObjectType === 'USER') {
    filterArr = [TeamFilter, userTypeFilter]
  }

  if (['TABLE', 'COLUMN', 'DATABASE', 'SCHEMA'].includes(selectedObjectType)) {
    filterArr.push(DBSourceFilter)
    if (['SCHEMA', 'TABLE', 'COLUMN'].includes(selectedObjectType) && state.source !== 'all') filterArr.push(databaseFilter)
    if (['TABLE', 'COLUMN'].includes(selectedObjectType) && state.database !== 'all') filterArr.push(schemaFilter)
    if (selectedObjectType === 'COLUMN' && state.schema !== 'all') filterArr.push(tableFilter)
  }
  if (['CONTENT_APP', 'CONTENT', 'CONTENT_CHILD', 'DATA_PIPELINE', 'DATASET', 'FILE', 'DATASET_FIELD', 'DATASET_TABLE', 'ML_MODEL'].includes(selectedObjectType)) {
    filterArr.push(ToolSourceFilter)
    if (state.source !== 'all') filterArr.push(locationFilter)
  }
  if (['MACRO', 'PROCEDURE', 'QUERY'].includes(selectedObjectType)) {
    filterArr.push(DBSourceFilter)
    if (state.source !== 'all') filterArr.push(databaseFilter)
  }
  if (['ISSUE'].includes(selectedObjectType)) {
    filterArr.push(IssueSourceFilter)
  }
  if (['HOST', 'TOOL'].includes(selectedObjectType)) {
    filterArr.push(referenceFilter)
  }

  const onSelectFilterHistory = async h => {
    let filters = h.filters;
    // set filters first 
    setSelectedObjectType(h.objectType)
    recoverFilterValues({ filters, dispatch })
    loadSources()
    // need to load the data for the filters if any, e.g. when a source is slected, the databases need to be loaded
    let dbs, schemas;
    if (filters.source !== 'all') {
      let sourceType = sources.find(s => s.id === filters.source).source_template.type;
      if (sourceType !== 'TOOL') {
        dbs = await loadDatabases(filters.source)
      } else {
        loadLocation({ value: filters.source, objectType: h.objectType })
      }
    }
    if (filters.database !== 'all') {
      schemas = await loadSchemas({ sourceID: filters.source, dbID: filters.database, dbList: dbs })
    }
    if (filters.schema !== 'all') {
      await loadTables({ sourceID: filters.source, dbId: filters.database, dbList: dbs, schemaID: filters.schema, schemaList: schemas })
    }
  }

  if (filterArr.length === 0) return <></>

  const filterList = enableFilterHistory ? getFilterHistorys({ validObjectTypes: linkableObjects }) : []

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography style={{ fontSize: 13.75, marginBottom: 12 }}>Filter by:</Typography>
        {
          filterList.length > 0 &&
          <Select
            renderValue={() => 'Previous filters'}
            value={'all'}
            onChange={event => {
              onSelectFilterHistory(filterList.find(h => h.dispName === event.target.value))
            }}
            disableUnderline
            className={classes.historySelector}
          >
            {
              filterList.map((h, i) => (
                <MenuItem selected={false} key={h.dispName} value={h.dispName} className={classes.menuItem} style={{ background: theme.palette.background.main }}>
                  <KTooltip title={h.dispName}>
                    <span>{titleCaseObjectName(h.objectType)}: {h.dispName}</span>
                  </KTooltip>
                </MenuItem>
              ))
            }
          </Select>
        }
      </div>
      <div style={{ display: 'flex', paddingBottom: 24, borderBottom: isPluginComponent ? undefined : `1px solid ${theme.palette.listItemDivider.main}`, marginBottom: isPluginComponent ? 0 : 24 }}>
        {filterArr}
      </div>
    </div>
  )

}

Filter.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  selectedObjectType: PropTypes.string.isRequired,
  selectedRelation: PropTypes.string.isRequired,
}


export default withTheme()(withStyles(styles)(Filter));