import React, { useState} from 'react';
import { withTheme, withStyles, Modal, Paper, Typography, Radio, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../../axios-cerebrum';
import fileDownloader from 'js-file-download';
import ModalAlert from '../../../UI/ModalAlert/ModalAlert';

const styles = theme => ({
  title: {
    fontSize: 20,
    textTransform:'uppercase',
    color:theme.palette.header.main,
    marginBottom:16
  },
  sectionHeader:{
    fontSize:12,
    letterSpacing:1,
    color:theme.palette.primaryText.main,
    marginBottom:16
  },
  optionContainer:{
    height:42,
    marginBottom:8,
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    cursor:'pointer',
    "&:hover":{
      background:theme.palette.hovered.main
    }
  },
  radio:{
    marginLeft:16,
    marginRight:16,
    padding:0,
  },
  optionText:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  }
})

function DownloadModal(props) {

  const {
    theme,
    classes,
    state,
    extractData,
    targetObject,
    targetObjects, 
    modalOpen, 
    setModalOpen,
    direction,
    appName
  } = props;

  const [type, setType] = useState('none')
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertType, setAlertType] = useState('info')

  let directionText = direction==='downstream'?'Impact':'Dependency'

  let byCriteriaText = `${directionText} by object by additional criteria`;
  let collections = state.extractCollectionDetails
  if(collections && collections.length>0){
    let names = collections.map(el=>el.name)
    if(collections.length<=2)byCriteriaText  = `${directionText} by object by ${names.join(' & ')}`
    else{
      byCriteriaText  = `${directionText} by object by ${names.slice(0,names.length-1).join(', ')} & ${names[names.length-1]}`
    }
  }
  
  const types = [
    {value:'user',text:`${directionText} by user by object`},
    {value:'collection',text:byCriteriaText, disabled: !extractData.args.filters.collection_ids||extractData.args.filters.collection_ids.length===0},
    {value:'lineage',text:`Detailed lineage report`},
  ]

  const onDownload = () => {
    let url = `/api/extracts/${extractData.id}/download`;
    if(type==='collection')url += '?extract_postfix=COLLECTION_BY_OBJECT'
    if(type==='lineage')url += '?extract_postfix=DEPENDENCY'
    let fileName = ''
    if(targetObject){
      fileName = `${appName.replace(/\s/g,'_').toLowerCase()}_${extractData.args.filters.period}_days_${targetObject.name_txt}_by_${type==='user'?'user':'criteria'}.csv`
    }
    if(targetObjects){
      fileName = `${appName.replace(/\s/g,'_').toLowerCase()}_${extractData.args.filters.period}_days_${targetObjects.length}_items_by_${type==='user'?'user':'criteria'}.csv`
    }
    if(type==='lineage'){
      fileName = `${appName.replace(/\s/g,'_').toLowerCase()}_${extractData.args.filters.period}_days_detailed_lineage.csv`
    }
    setAlertOpen(true)
    setAlertType('info')
    setAlertMessage('Download will commence shortly')
    axiosCerebrum.get(url).then(response=>{
      fileDownloader(response.data.replace(/\|/g,','), fileName);
    }).catch(error=>{
      console.log(error);
      setAlertOpen(true)
      setAlertMessage('Error occurred downloading the report, please try again')
      setAlertType('error')
    })
  }

  return (
    <Modal 
      open={modalOpen} 
      onClose={()=>setModalOpen(false)}
      disableBackdropClick={true}
    > 
      <div style={{margin:'30vh auto 0 auto',width:720,maxWidth:'90%',maxHeight:'90%',}}>
        <Paper style={{width:'100%',overflow:'auto',maxHeight:'90%',boxSizing:'border-box',padding:24,paddingBottom:8,background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
          <Typography className={classes.title}>
            DOWNLOAD {direction==='downstream'?'IMPACT':'DEPENDENCY'} ASSESSMENT RESULTS
          </Typography>
          <div style={{marginBottom:32}}>
            <Typography className={classes.sectionHeader}>SELECT AN EXTRACT</Typography>
            {
              types.map(t=>(
                <div onClick={()=>setType(t.value)} className={classes.optionContainer} style={t.disabled?{pointerEvents:'none'}:undefined}>
                  <Radio
                    color='primary'
                    className={classes.radio}
                    checked={t.value===type}
                    disabled={t.disabled}
                  />
                  <Typography className={classes.optionText} style={t.disabled?{color:theme.palette.primaryText.light}:undefined}>
                    {t.text}
                  </Typography>
                </div>
              ))
            }
          </div>
          <div style={{display:'flex',justifyContent:'flex-end'}}>
            <Button color='primary' disabled={type==='none'} style={{marginRight:12}} onClick={()=>onDownload()}>DOWNLOAD</Button>
            <Button color='secondary' onClick={()=>setModalOpen(false)}>CLOSE</Button>
          </div>
        </Paper>
        <div style={{width:720}}>
          <ModalAlert
            open={alertOpen}
            setOpen={setAlertOpen}
            message={alertMessage}
            type={alertType}
          />
        </div>
      </div>
    </Modal>
  )
}

DownloadModal.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(DownloadModal));