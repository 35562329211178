import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { Typography } from '@material-ui/core';
import { formatColumnNames, getColumnWidth, isColumnExtendable } from '../Utils/Utils';


const SortableColumn = (props) => {

  const {
    classes,
    field,
    isSortable, 
    getSortIcon
  } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({id: props.id});
  
  const style = {
    transform: CSS.Transform.toString({x:transform?.x,y:transform?.y,scaleX:1,scaleY:1}),
    transition,
    cursor:isDragging?'grabbing':isSortable(field)?'pointer':'grab',
    zIndex:isDragging?999:undefined
  };
  
  return (
    <div 
      ref={setNodeRef} 
      className={classes.columnBoxes + (isSortable(field)?` ${classes.sortable}`:'')} 
      style={{width:getColumnWidth(field),flexGrow:isColumnExtendable(field)?1:0,...style}} 
      {...attributes} 
      {...listeners} 
    >
      <Typography data-test-classname="search-column-text" className={classes.columnText} >
        {formatColumnNames(field)}
      </Typography>
      <div className={classes.sortIconWrapper}>
        {getSortIcon(field)}
      </div>
    </div>
  );
}

export default SortableColumn;