import React from 'react';
import { withStyles} from '@material-ui/core';
import Discover from '../Discover/Discover';
import Sources from '../Sources/Sources';
import DomainMap from '../../CollectionProfile/DomainMap/DomainMap';
import Map from '../Map/Map';
import { collectionIds } from '../../../utilities';

const styles = theme => ({
  root: {
  }
})

const Body = props => {

  const {
    classes,
    history,
    sessionData,
    state,
    dispatch,
    tabOptions,
  } = props;

  
  let bodyContent;

  switch (tabOptions[state.tabState]) {
    case 'SOURCES':
      bodyContent=<Sources state={state} dispatch={dispatch} history={history} sessionData={sessionData}/>
      break;
    case 'MAP':
      // bodyContent=<Grid state={state} dispatch={dispatch} history={history} sessionData={sessionData}/>
      bodyContent=<Map state={state} dispatch={dispatch} history={history} sessionData={sessionData}/>
      break;
    case 'DOMAIN MAP':
      bodyContent=<DomainMap state={{...state,basicData:{id:collectionIds.domain}}} dispatch={dispatch} history={history} sessionData={sessionData} />
      break;
    case 'DISCOVER':
      bodyContent=<Discover state={state} dispatch={dispatch} history={history} sessionData={sessionData}/>
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

export default withStyles(styles)(Body);