import React, {useEffect, useRef, useState} from 'react';
import { withStyles, Typography, CircularProgress, Button, Modal, Paper, IconButton, Select, MenuItem, InputBase, LinearProgress } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import theme from '../../../theme';
import { isInViewport, getIconComponent } from '../../../utilities';
import ModalAlert from '../../UI/ModalAlert/ModalAlert'
import KTooltip from '../../UI/KTooltip/KTooltip';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import UserChip from '../../UI/Chips/UserChip';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {
    display:'flex',
    overflow:"hidden"
  },
  listItem:{

    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  title:{
    fontSize:20,
    color:theme.palette.primary.main,
    marginBottom:4
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:24
  },
  columnHeader:{
    fontSize:12,
    color:theme.palette.primary.main,
    letterSpacing:2
  },
  tableText:{
    color:theme.palette.primaryText.main,
    fontSize:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  tooltip:{
    fontSize:13.75
  },
  modalRoot:{
    padding:24,
    // paddingBottom:8,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    margin:'20vh auto 0',
    maxHeight:'70vh',
    overflow:'auto'
  },  
  modalTitle:{
    wordBreak:'break-word',
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:12
  },
  modalBodyText:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    whiteSpace:'pre-wrap'
  },
  buttons:{
    marginTop:24,
    marginRight:-12,
    marginBottom:-16,
    display:'flex',
    justifyContent:'flex-end'
  },
  selector:{
    ...theme.components.selector,
    alignSelf:'flex-start',
    width:180
  },
  inputBase:{
    ...theme.components.inputBase,
    marginRight:16,
    alignSelf:'flex-start',
    // paddingLeft:16,
    height:39,
    width:160,
  },
  clickableText:{
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline'
    }
  }
})

const clearIcon = (
  <svg enable-background="new 0 0 24 24" height={24} style={{transform:'rotate(90deg)'}} viewBox="0 0 24 24" width={24} fill={theme.palette.primaryText.light}>
    <g>
      <path d="M0,0h24v24H0V0z" fill="none"/>
    </g>
    <g>
      <g>
        <polygon points="16,7 19,7 15,3 11,7 14,7 14,11.17 16,13.17"/>
        <polygon points="2.81,2.81 1.39,4.22 8,10.83 8,17.01 5,17.02 9,21 13,17 10,17.01 10,12.83 19.78,22.61 21.19,21.19"/>
      </g>
    </g>
  </svg>
)

const MergedList = props => {

  const {
    classes,
    state,
    dispatch,
    history,
  } = props;

  const searchRef = useRef()

  const scrollRef = useRef()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [modalButtons, setModalButtons] = useState()
  const [deleting, setDeleting] = useState(false)

  const [buttonHovered, setButtonHovered] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`merge-list`,
    isCancelledRef
  })

  useEffect(()=>{ 
    return () => {
      isCancelledRef.current = true
    }
  },[])

  const getMergeRecords = ({page=1,source=state.listSourceFilter[state.objectType]||'all', search=state.listNameFilter[state.objectType]||'', type=state.objectType}) => {
    dispatch({
      type:'set_merge_records',
      mergeRecords:{
        ...state.mergeRecords,
        [type]:{
          ...(state.mergeRecords[type]||{}),
          loading:true,
          error:false,
        }
      }
    });
    axiosCerebrum
      .get(
        `/api/merges`,
        {params:{
          page,
          per_page:20,
          source_id:source==='all'?undefined:source,
          object_name:search,
          object_type:type,
          type:'CANDIDATE'
        }}
      )
      .then(response=>{
        dispatch({
          type:'set_merge_records',
          mergeRecords:{
            ...state.mergeRecords,
            [type]:{
              ...response.data,
              items:page===1?response.data.items:[...state.mergeRecords[type].items,...response.data.items]
            }
          }
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_merge_records',
          mergeRecords:{
            ...state.mergeRecords,
            [type]:{
              ...(state.mergeRecords[type]||{}),
              loading:false,
              error:true,
            }
          }
        });
      })
  }

  const onDelete = (merge) => {
    setDeleting(true)
    axiosCerebrum
      .post(
        `/api/merges`,{
          "target": merge.target_node.id,
          "object_type": merge.target_node.object.name,
          "candidates": [merge.candidate.id],
          "action":'CLEAR'
        }
      )
      .then(response=>{
        setDeleting(false)
        getMergeRecords({page:1})
        setDeleteModalOpen(false)
        sendAlert({type:'info',message:'Item successfully deleted'})
      })
      .catch(error=>{
        setDeleting(false)
        console.log(error)
        let msg = 'Error occurred deleting the item';
        let buttons;
        if(error.response && error.response.status && error.response.status===422){
          msg = 'Error occurred - raise a ticket with KADA Support to investigate'
          buttons = (
            <Button style={{color:'#fff'}} onClick={()=>window.open('https://kadaai.atlassian.net/servicedesk/customer/portal/1/group/5/create/15','_blank')}>OPEN KADA SUPPORT </Button>
          )
        }
        setModalButtons(buttons)
        setAlertMessage(msg)
        setAlertOpen(true)
      })
  }

  useEffect(()=>{
    if(!state.mergeRecords[state.objectType])getMergeRecords({})
    // eslint-disable-next-line
  },[state.objectType])

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && state.mergeRecords[state.objectType] && state.mergeRecords[state.objectType].page<state.mergeRecords[state.objectType].pages && !state.mergeRecords[state.objectType].loading
  }

  window.onscroll = () => {
    if(shouldLoadMore())getMergeRecords({page:state.mergeRecords[state.objectType].page+1})
  }

  useEffect(()=>{
    if(shouldLoadMore())getMergeRecords({page:state.mergeRecords[state.objectType].page+1})
  // eslint-disable-next-line
  },[state.mergeRecords])

  const onSourceFilterChange = event => {
    dispatch({
      type:'set_list_source_filter', listSourceFilter:{...state.listSourceFilter, [state.objectType]:event.target.value} 
    })
    getMergeRecords({page:1,source:event.target.value})
  }

  const onNameFilterChange = value => {
    dispatch({
      type:'set_list_name_filter', listNameFilter:{...state.listNameFilter, [state.objectType]:value}
    })
    clearTimeout(searchRef.current)
    searchRef.current = setTimeout(()=>{
      getMergeRecords({page:1,search:value})
    },250)
  }

  const generateSourceText = item => {
    let str = item.source.name;
    if(item.reference_sources.length>0){
      str = `${item.reference_sources.map(el=>el.reference_source.name).join(', ')}`
    }
    return str;
  }
  
  let tabOps = ['USER','DATABASE']

  return (
    <div className={classes.root}>
      <VerticalTabBar
        tabOptions={tabOps}
        tabState={tabOps.indexOf(state.objectType)}
        setTabState={value => dispatch({ type: 'set_object_type', objectType: tabOps[value] })}
      />
      <div style={{flexGrow:1,marginLeft:80,overflow:'hidden'}}>
        <div style={{display:'flex'}}>
          <div style={{flexGrow:1}}>
            <Typography className={classes.title}>{state.mergeRecords[state.objectType] && state.mergeRecords[state.objectType].items?state.mergeRecords[state.objectType].total+' ':''}{state.objectType}(S) MERGED</Typography>
            <Typography className={classes.subTitle}>
              {
                state.objectType==='DATABASE'?
                'List of databases merged. Click the action item to unmerge the items':
                'List of users merged. Click the action item to unmerge the items'
              }
            </Typography>
          </div>
          <InputBase
            value={state.listNameFilter[state.objectType]||''}
            onChange={event => onNameFilterChange(event.target.value)}
            // variant={'filled'}
            placeholder={'Search'}
            className={classes.inputBase}
            endAdornment={
              <IconButton 
                disabled={state.listNameFilter===''} 
                onClick={()=>onNameFilterChange('')}
                style={{width:32,height:32,marginRight:6}}
              >
                {getIconComponent({label:state.listNameFilter===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
              </IconButton>
            }
          />
          <Select
            disableUnderline
            className={classes.selector}
            value={state.listSourceFilter[state.objectType]||'all'}
            onChange={onSourceFilterChange}
          >
            <MenuItem value='all'>All Merge Sources</MenuItem>
            {
              state.validSources && state.validSources.map(s=>(
                <MenuItem value={s.id}>{s.name}</MenuItem>
              ))
            }
          </Select>
        </div>
        
        {
          state.mergeRecords[state.objectType] && state.mergeRecords[state.objectType].total===0 && 
          <Typography style={{color:theme.palette.primaryText.main}}>No records found</Typography>
        }
        {
          state.mergeRecords[state.objectType] && state.mergeRecords[state.objectType].total!==0 && state.mergeRecords[state.objectType].items && 
          <div>
            {
              state.objectType==='USER' && 
              <>
               <div style={{display:'flex', marginBottom:8}}>
                  <Typography className={classes.columnHeader} style={{marginLeft:16,flex:'250px 0 1',marginRight:16}}>MERGED SOURCE</Typography>
                  <Typography className={classes.columnHeader} style={{marginLeft:16,flex:'250px 1 1',marginRight:16}}>MERGED USER</Typography>
                  <Typography className={classes.columnHeader} style={{marginLeft:16,flex:'250px 0 1',marginRight:16}}>TARGET SOURCE</Typography>
                  <Typography className={classes.columnHeader} style={{marginLeft:16,flex:'250px 1 1',marginRight:16}}>TARGET USER</Typography>
                  <Typography className={classes.columnHeader} style={{marginLeft:16,flex:'60px 0 0',marginRight:16}}>ACTIONS</Typography>
                </div>
                {
                  state.mergeRecords[state.objectType].items.map(el=>(
                    <div className={classes.listItem} style={{display:'flex', overflow:'hidden',alignItems:'center',height:48,borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,background:buttonHovered?theme.palette.background.main:undefined}}>
                      <div  style={{marginLeft:16,flex:'250px 0 1',marginRight:16,overflow:"hidden"}}>
                        <KTooltip title={generateSourceText(el.candidate)}>
                          <Typography className={classes.tableText}>{generateSourceText(el.candidate)}</Typography>
                        </KTooltip>
                      </div>
                      <div  style={{marginLeft:16,flex:'250px 1 1',marginRight:16,overflow:"hidden"}}>
                        <UserChip 
                          user={{
                            name:`${el.candidate.user.username}${el.candidate.user.username!==el.candidate.name?` (${el.candidate.name})`:''}`,
                            id:el.candidate.id
                          }}
                          maxWidth={'100%'}
                          showTooltip
                          onClick={()=>history.push(`/profile/user/${el.candidate.id}`)}
                        />
                      </div>
                      <div  style={{marginLeft:16,flex:'250px 0 1',marginRight:16,overflow:"hidden"}}>
                        <KTooltip title={generateSourceText(el.target_node)}>
                          <Typography className={classes.tableText}>{generateSourceText(el.target_node)}</Typography>
                        </KTooltip>
                      </div>
                      <div  style={{marginLeft:16,flex:'250px 1 1',marginRight:16,overflow:"hidden"}}>
                        <UserChip 
                          user={{
                            name:`${el.target_node.user.username}${el.target_node.user.username!==el.target_node.name?` (${el.target_node.name})`:''}`,
                            id:el.target_node.id
                          }}
                          maxWidth={'100%'}
                          showTooltip
                          onClick={()=>history.push(`/profile/user/${el.target_node.id}`)}
                        />
                      </div>
                      <div className={classes.tableText} style={{marginLeft:16,flex:'60px 0 0',marginRight:16}}>
                        <KTooltip title={'Unmerge items'} classes={{tooltip:classes.tooltip}}>
                          <IconButton 
                            onClick={()=>setDeleteModalOpen(el)} 
                            style={{padding:4}}
                            onMouseEnter={()=>setButtonHovered(true)}
                            onMouseLeave={()=>setButtonHovered(false)}
                          >
                            {clearIcon}
                          </IconButton>
                        </KTooltip>
                      </div>
                    </div>
                  ))
                }
              </>
            }
            {
              state.objectType==='DATABASE' && 
              <>
                <div style={{display:'flex', marginBottom:8}}>
                  <Typography className={classes.columnHeader} style={{marginLeft:16,flex:'250px 0 1',marginRight:16}}>MERGED SOURCE</Typography>
                  <Typography className={classes.columnHeader} style={{marginLeft:16,flex:'250px 1 1',marginRight:16}}>MERGED DATABASE</Typography>
                  <Typography className={classes.columnHeader} style={{marginLeft:16,flex:'250px 0 1',marginRight:16}}>TARGET SOURCE</Typography>
                  <Typography className={classes.columnHeader} style={{marginLeft:16,flex:'250px 1 1',marginRight:16}}>TARGET DATABASE</Typography>
                  <Typography className={classes.columnHeader} style={{marginLeft:16,flex:'60px 0 0',marginRight:16}}>ACTIONS</Typography>
                </div>
                {
                  state.mergeRecords[state.objectType].items.map(el=>(
                    <div className={classes.listItem} style={{display:'flex', overflow:'hidden',alignItems:'center',height:63,borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,background:buttonHovered?theme.palette.background.main:undefined}}>
                      <div  style={{marginLeft:16,flex:'250px 0 1',marginRight:16,overflow:"hidden"}}>
                        <KTooltip title={generateSourceText(el.candidate)}>
                          <Typography className={classes.tableText}>{generateSourceText(el.candidate)}</Typography>
                        </KTooltip>
                      </div>
                      <div  style={{marginLeft:16,flex:'250px 1 1',marginRight:16,overflow:"hidden"}}>
                        <KTooltip title={`${el.candidate.name}`}>
                          <Typography className={classes.tableText}>{el.candidate.name}</Typography>
                        </KTooltip>
                        <KTooltip title={el.candidate.location}>
                          <Typography className={classes.tableText} style={{fontSize:12,color:theme.palette.primaryText.light,marginTop:2}}>{el.candidate.location}</Typography>
                        </KTooltip>
                      </div>
                      <div  style={{marginLeft:16,flex:'250px 0 1',marginRight:16,overflow:"hidden"}}>
                        <KTooltip title={generateSourceText(el.target_node)}>
                          <Typography className={classes.tableText}>{generateSourceText(el.target_node)}</Typography>
                        </KTooltip>
                      </div>
                      <div  style={{marginLeft:16,flex:'250px 1 1',marginRight:16,overflow:"hidden"}}>
                        <KTooltip title={`${el.target_node.name}`}>
                          <Typography className={classes.tableText}>{el.target_node.name}</Typography>
                        </KTooltip>
                        <KTooltip title={el.target_node.location}>
                          <Typography className={classes.tableText} style={{fontSize:12,color:theme.palette.primaryText.light,marginTop:2}}>{el.target_node.location}</Typography>
                        </KTooltip>
                      </div>
                      <div className={classes.tableText} style={{marginLeft:16,flex:'60px 0 0',marginRight:16}}>
                        <KTooltip title={'Unmerge items'} classes={{tooltip:classes.tooltip}}>
                          <IconButton 
                            onClick={()=>setDeleteModalOpen(el)} style={{padding:4}}
                            onMouseEnter={()=>setButtonHovered(true)}
                            onMouseLeave={()=>setButtonHovered(false)}
                          >
                            {clearIcon}
                          </IconButton>
                        </KTooltip>
                      </div>
                    </div>
                  ))
                }
              </>
            }
          </div>
        }
        {
          state.mergeRecords[state.objectType] && state.mergeRecords[state.objectType].error && 
          <Typography style={{color:theme.palette.primaryText.main}}>Error loading records</Typography>
        }
        <div style={{textAlign:'center',marginBottom:100}} ref={scrollRef}>
          {
            state.mergeRecords[state.objectType] && state.mergeRecords[state.objectType].loading && <CircularProgress style={{marginTop:8}} color='secondary'/>
          }
        </div>
        
      </div>


      <Modal open={deleteModalOpen} disableBackdropClick>
        <div style={{width:'max-content',margin:'auto',outline:'none'}}>
          {
            deleteModalOpen && 
            <Paper className={classes.modalRoot} style={{width:700}}>
              <Typography className={classes.modalTitle}>Are you sure?</Typography>
              {
                deleting?
                <div style={{textAlign:'center'}}>
                  <LinearProgress color='secondary' style={{height:6,width:'80%',margin:'auto',marginTop:32}}/>
                  <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Deletion in progress</Typography>
                </div>
                :
                <Typography className={classes.modalBodyText}>
                  {
                    `Removing the link between ${deleteModalOpen.target_node.name} (${deleteModalOpen.target_node.source.name}) and ${deleteModalOpen.candidate.name} (${deleteModalOpen.candidate.source.name}) will split the 2 items going forward.\n\n`+
                    `Previously merged metrics will ` 
                  }
                  <span style={{fontSize:13.75,fontWeight:700}}>NOT</span> {`be repaired. `}
                </Typography>
              }
              <div className={classes.buttons}>
                <Button color='primary' onClick={()=>onDelete(deleteModalOpen)} style={{marginRight:16}}>REMOVE</Button>
                <Button color='secondary' onClick={()=>setDeleteModalOpen(false)}>CLOSE</Button>
              </div>
            </Paper>
          }
          <div style={{margin:'0 auto',width:750}}>
            <ModalAlert
              open={alertOpen}
              setOpen={setAlertOpen}
              message={alertMessage}
              buttons={modalButtons}
              type='error'
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default withStyles(styles)(MergedList);