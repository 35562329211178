
import React, {useEffect, useRef,} from 'react';
import { withTheme, CircularProgress, Typography, withStyles} from '@material-ui/core';
import axiosSolr from '../../../axios-solr'
import ListItem from '../ListItem/ListItem';
import ColumnHeader from '../ColumnHeader/ColumnHeader';
import { isInViewport } from '../../../utilities';

const styles = theme => ({
  root: {

  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
    marginBottom:16,
    textTransform:'uppercase'
  },
  seeMoreButton:{
    marginTop:8
  },
  selector:{
    ...theme.components.titleSelector,
    marginBottom:24
  }
})

const Issues = props => {

  const {
    classes,
    theme,
    history,
    state,
    dispatch,
  } = props;
  
  const loadingRef = useRef()

  const loadData = ({view = state.issueView, start = 0}) => {
    
    let promise;
    if(view==='new'){
      promise = axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:ISSUE AND active_srt:YES`,
              sort:'created_at_srt desc',
              rows:10,
              start,
            }
          }
        )
    }
    if(!promise)return;

    dispatch({type:'set_issue_data',issueData:state.issueData,issueLoading:true})
    promise
      .then(response=>{
        let data = response.data.response
        let issueData = {
          ...(state.issueData || {}),
          [view]:{
            ...data,
            docs:[...(state.issueData?.[view]?.docs||[]),...data.docs],
          }
        }
        dispatch({
          type:'set_issue_data',
          issueData,
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_issue_data',
          issueData:state.issueData,
          issueError:true
        })
      })
  }

  useEffect(()=>{
    if(!state.issueData || !state.issueData[state.issueView]){
      loadData({view:state.issueView})
    }
  // eslint-disable-next-line
  },[state.issueView])
  
  const shouldLoadMore = () => {
    if(!state.issueData || !state.issueData[state.issueView])return false;
    if(state.issueData[state.issueView].numFound <= state.issueData[state.issueView].docs.length)return false;
    if(state.issueLoading)return false;
    if(!isInViewport(loadingRef))return false;
    return true;
  }

  window.onscroll = () => {
    if (shouldLoadMore()) {
      loadData({view:state.issueView,start:state.issueData[state.issueView].docs.length})
    }
  }

  useEffect(()=>{
    if (shouldLoadMore()) {
      loadData({view:state.issueView,start:state.issueData[state.issueView].docs.length})
    }
  // eslint-disable-next-line
  },[state.issueData])
  
  const getItem = item => {
    return (
      <ListItem
        item={item}
        label={'issue'}
        history={history}
        key={item.id}
      />
    )
  }

  let data = state.issueData?.[state.issueView] // view: one of ['new','trending']

  return (
    <div className={classes.root}>
      <div style={{paddingBottom:24,background:theme.palette.background.main,position:'sticky',top:182,zIndex:9}}>
        <Typography style={{fontSize:20,color:theme.palette.header.main}}>NEW ISSUES</Typography>
        <Typography style={{color:theme.palette.primaryText.light,fontSize:12}}>
          {
            state.issueView==='new'?
            'New issues added. Sorted by most recently created.':
            ''
          }
        </Typography>
      </div>
      {
        data && data.docs.length>0 && 
        <div>
          <ColumnHeader label="issue"/>
          {
            data.docs.map(getItem)
          }
        </div>
      }
      <div ref={loadingRef} style={{marginTop:8,display:'flex',justifyContent:'center'}}>
        {
          state.issueLoading && 
          <CircularProgress color='secondary'/>
        }
      </div>
      {
        state.issueError && 
        <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading issues</Typography>
      }
      {
        data && data.numFound===0 && 
        <Typography style={{color:theme.palette.primaryText.main}}>No issues found</Typography>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(Issues));