import React, {useEffect} from 'react';
import { withStyles, Typography} from '@material-ui/core';
import classnames from 'classnames'
import PropTypes from 'prop-types';
import { getIconComponent } from '../../../utilities';
import theme from '../../../theme';
import { globalListenerRef } from '../../../GlobalListenerRef'
import KTooltip from '../KTooltip/KTooltip';

const styles = theme => ({
  root: {
    '& *':{
      userSelect: 'none'
    }
  },
  tab:{
    cursor:'pointer',
    color:theme.palette.primaryText.light,
    overflow:'hidden',
    borderRight:`3px solid ${theme.palette.primaryText.light}`,
    textTransform:'uppercase',
    opacity:0.6,
    fontSize:13.75,
    '&:hover':{
      background:theme.palette.hovered.main
    },
    display:'flex',
    alignItems:'center',
  },
  tabText:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    flexGrow:1,
  },
  wrapText:{
    whiteSpace:'normal',
    wordBreak:'break-word',
  },
  selectedTab:{
    opacity:1,
    color:theme.palette.primary.main,
    borderRight:`3px solid ${theme.palette.primary.main}`,
  },
  higlightTab:{
    background:theme.palette.primary.main+'20',
    '&:hover':{
      background:theme.palette.primary.main+'20',
    },
  },
  unselectedTab:{
    opacity:1,
    borderRight:`3px solid ${theme.palette.primaryText.light}50`,
  },
  groupHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:2,
    marginLeft:8,
  },
  groupList:{
    transition: "height .3s ease",
    overflow:'hidden'
  },
  groupTitle:{
    cursor:'pointer',
    display:"flex",
    alignItems:'center',
    paddingBottom:8,
  },
  collapseIcon:{
    width:20,
    height:20,
    transition: "transform .3s ease",
  }
})

const VerticalTabBar = props => {

  const {
    classes,
    tabOptions,
    tabState,
    setTabState,
    tabIcons,
    renderOptions,
    grouping,
    alwaysShowFullTab,
    highlightOnSelected,
    width=200,
    hideGroupTitleNames = [],
    hideIconTabNames = [],
    enableTooltip,
    wrapText
  } = props;


  const generateElement = (el, i) => {
    return (
      <KTooltip title={enableTooltip?el:undefined}>
        <div 
          id={`search-tab-${i}`}
          key={`search-tab-${i}`}
          className={
            classnames(
              classes.tab,
              i===tabState && highlightOnSelected?classes.higlightTab:undefined,
              i===tabState?classes.selectedTab:alwaysShowFullTab?classes.unselectedTab:undefined
            )
          } 
          style={{padding:grouping?'12px 12PX 12px 8px':'18px 12PX 18px 24px'}} 
          onClick={()=>{
            setTabState(i);
          }}
        >
          {
            tabIcons && 
            <div style={{flexGrow:0,width:24,height:24}}>
              {!hideIconTabNames.includes(el) && getIconComponent({label:tabIcons[i],size:24,colour:i===tabState?theme.palette.primary.main:theme.palette.primaryText.light}) }
            </div>
          }
          <span className={classes.tabText + (wrapText?' '+classes.wrapText:'')} style={{marginLeft:tabIcons?8:0}}>
            {renderOptions?renderOptions(el):el}
          </span>
        </div>
      </KTooltip>
    )
  }

  let tabs = [];

  for(let i=0; i<tabOptions.length; i++){
    let el = tabOptions[i];
    tabs.push(
      {
        name:el,
        element: generateElement(el, i)
         
      }
    )
  }


  const getTabCount = (group) => {
    return group.tabs.filter(g=>(tabs.find(t=>t.name===g))).length
  }

  const onClickGroupName = (groupName, tabsCount, forceExpand) => {
    let listEl = document.getElementById(`v_tab_bar_group_${groupName}`);
    let iconEl = document.getElementById(`v_tab_bar_group_icon_${groupName}`);
    if(!listEl || !iconEl)return;
    if(listEl.style.height==='0px' || forceExpand){
      listEl.style.visibility='visible'
      listEl.style.height=`${tabsCount*48}px`
      iconEl.style.transform=`rotate(0deg)`
    }
    else{
      listEl.style.height='0px'
      setTimeout(()=>{
        listEl.style.visibility='hidden'
      },200)
      iconEl.style.transform=`rotate(-90deg)`
    }
  }
  
  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.verticalTabBarExpand ){
        if(!grouping)return;
        let group = grouping.find(g=>g.name===msg.data.verticalTabBarExpand)
        group && onClickGroupName(group.name, getTabCount(group), true)
      }
    }
    window.removeEventListener('message',globalListenerRef.verticalTabBarListener);
    globalListenerRef.verticalTabBarListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.verticalTabBarListener);
    return (()=>{window.removeEventListener('message',globalListenerRef.verticalTabBarListener);})
   // eslint-disable-next-line
  },[grouping])
  
  return (
    <div data-test-id="vertical-tab-bar" className={classes.root} style={{flexShrink:0,flexGrow:0,width}}>
      {
        grouping?
        grouping.map((el,index)=>(
          tabs.find(t=>el.tabs.includes(t.name))?
          <div>
            {
              !hideGroupTitleNames.includes(el.name) &&
              <div className={classes.groupTitle} style={{borderRight:alwaysShowFullTab?`3px solid ${theme.palette.primaryText.light}50`:undefined,paddingTop:index===0?undefined:16}} onClick={()=>onClickGroupName(el.name, getTabCount(el))}>
                <div id={`v_tab_bar_group_icon_${el.name}`} className={classes.collapseIcon} style={{transform:'rotate(0deg)'}}>
                  {getIconComponent({label:'triangle_down',size:20,colour:theme.palette.primaryText.main})}
                </div>
                <Typography className={classes.groupHeader}>{el.name}</Typography>
              </div>
            }
            <div id={`v_tab_bar_group_${el.name}`} style={{height:getTabCount(el)*48}} className={classes.groupList}>
              {
                el.tabs.map(g=>(
                  tabs.find(t=>t.name===g)?
                  tabs.find(t=>t.name===g).element:
                  undefined
                ))
              }
            </div>
          </div>
          :
          undefined
        ))
        :
        tabs.map(el=>el.element)
      }
    </div>
  )
}

VerticalTabBar.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number.isRequired,
  setTabState: PropTypes.func.isRequired,
  tabOptions: PropTypes.array.isRequired,
  tabIcons: PropTypes.array,
  renderOptions: PropTypes.func,
  alwaysShowFullTab: PropTypes.bool,
  highlightOnSelected:PropTypes.bool,
  grouping: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    tabs: PropTypes.array
  })),
  hideIconTabNames: PropTypes.array,
  hideGroupTitleNames: PropTypes.array,
  enableTooltip: PropTypes.bool,
  wrapText: PropTypes.bool,
}

export default withStyles(styles)(VerticalTabBar);