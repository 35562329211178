import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Typography, withStyles, withTheme } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { formatNumber, getJobResult } from '../../../../utilities';
import DateDetail from './DateDetail';
import axiosCerebrum from '../../../../axios-cerebrum';
import timezoneDetect from 'jstimezonedetect';

const styles = theme => ({
  root: {
    marginTop:12,
  },
  legendText:{
    fontSize:12,
    color:theme.palette.primaryText.main
  },
  legendBox:{
    width:12,
    height:12,
    marginRight:'8px',
    borderRadius:2
  },
  chartTitle:{
    fontSize:16,
    color:theme.palette.primary.main,
    marginBottom:8
  }
})


function Chart(props) {
  const {
    classes,
    theme,
    object,
    history,
    data,
    dimensionID,
    selectedDate,
    setSelectedDate,
    detailDateData,
    setDetailDateData,
    detailDateSort,
    setDetailDateSort
  } = props;

  let dateDataRef = useRef(detailDateData||{})


  const apiRef = useRef(0)

  const [shownSeries, setShownSeries] = useState(['Average score','Total tests run'])

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const dateDetailLoading = useRef(false)

  let labels = data[0].x
  
  let avg = data.find(el=>el.name==='AVG').y
  let count = data.find(el=>el.name==='COUNT').y

  let countSeries = [{
    name: 'Total tests run',
    data: count,
    type:'column',
    colour:'#A1C4FD'
  }]

  let avgSeries = [{
    name: 'Average score',
    data: avg,
    type: 'line',
    colour:'#00D46A'
  }]


  const [avgScoreOptions, setAvgScoreOptions] = useState()
  const [countOptions, setCountOptions] = useState()
  let avgScoreRef = useRef()
  let countRef = useRef()

  useEffect(()=>{
    setAvgScoreOptions(
      {
        colors:[avgSeries[0].colour],
        chart: {
          toolbar:{
            show:false
          },
          zoom: {
            enabled: false
          },
          events: {
            click: function (e, chart, opts) {
              if(dateDetailLoading.current)return;
              let pointIndex = opts.dataPointIndex;
              let time = labels[pointIndex]
              if(!chart.w.globals.selectedDataPoints || !chart.w.globals.selectedDataPoints[0] || !chart.w.globals.selectedDataPoints[0].includes(pointIndex)){
                avgScoreRef.current.chart.toggleDataPointSelection(0,pointIndex)
              }
              if(time===selectedDate)return;
              setSelectedDate(time)
              if(dateDataRef.current[time]){
                forceUpdate()
                return;
              }
              apiRef.current+=1;
              loadDateData(time,apiRef.current)
            },
            dataPointSelection:function(e, chart, opts){
              // let pointIndex = opts.dataPointIndex;
              return;
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: 'true',
          curve: 'smooth',
          width: 3
        },
        labels,
        xaxis: {
          type: 'datetime',
          labels:{
            style:{
              colors:theme.palette.primaryText.main
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              if(!val)return '';
              return formatNumber(val.toFixed(0),true)
            },
            style:{
              colors:theme.palette.primaryText.main
            }
          },
          max:100,
          min:0,
          title:{
            show:false,
          },
          axisTicks:{
            show:true,
          },
          axisBorder: {
            show: true,
          },
        },
        legend: {
          show:false,
        },
        markers: {
          size: 3,
          style: 'full',
          hover:{
            size:3
          }
        },
        states:{
          active: {
              filter: {
                  type: 'none',
                  value: 0,
              }
          },
        },
      }
    )

    setCountOptions({
      colors:[countSeries[0].colour],
      chart: {
        toolbar:{
          show:false
        },
        zoom: {
          enabled: false
        },
        events: {
          click: function (e, chart, opts) {
            if(dateDetailLoading.current)return;
            let pointIndex = opts.dataPointIndex;
            let time = labels[pointIndex]
            if(!chart.w.globals.selectedDataPoints || !chart.w.globals.selectedDataPoints[0] || !chart.w.globals.selectedDataPoints[0].includes(pointIndex)){
              countRef.current.chart.toggleDataPointSelection(0,pointIndex)
            }
            if(time===selectedDate)return;
            setSelectedDate(time)
            if(dateDataRef.current[time]){
              forceUpdate()
              return;
            }
            apiRef.current+=1;
            loadDateData(time,apiRef.current)
          },
          dataPointSelection:function(e, chart, opts){
            // let pointIndex = opts.dataPointIndex;
            return;
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: 'true',
        curve: 'smooth',
        width: 3
      },
      labels,
      xaxis: {
        type: 'datetime',
        labels:{
          style:{
            colors:theme.palette.primaryText.main
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function(val) {
            if(!val)return '';
            return formatNumber(val.toFixed(0),true)
          },
          style:{
            colors:theme.palette.primaryText.main,
          }
        },
        title:{
          show:false,
        },
        axisTicks:{
          show:true,
        },
        axisBorder: {
          show: true,
        },
      },
      legend: {
        show:false,
      },
      markers: {
        size: 3,
        style: 'full',
        hover:{
          size:3
        }
      },
      states:{
        active: {
            filter: {
                type: 'none',
                value: 0,
            }
        },
      },
    })
  // eslint-disable-next-line
  },[selectedDate, shownSeries])

  useEffect(()=>{
    avgScoreRef.current && labels.indexOf(selectedDate)>=0 && avgScoreRef.current.chart.toggleDataPointSelection(0,labels.indexOf(selectedDate)) ;
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    setDetailDateData(JSON.parse(JSON.stringify(dateDataRef.current)))
    // eslint-disable-next-line
  },[JSON.stringify(dateDataRef.current)])

  const loadDateData = (date, apiSeq) => {
    dateDataRef.current[date] = {loading:true}
    forceUpdate()
    dateDetailLoading.current = true
    axiosCerebrum
      .post(
        '/api/platformbatches/0',
        {
          "adhoc_args":{
            "job": "_0065_metrics_drill_through",
            "object_id": object.id,
            "tz": timezoneDetect.determine().name(),
            'related_collection_instance_id':dimensionID,
            "metric_type":'DATA_QUALITY_SCORE',
            "selected_date":date
          }
        }
      )
      .then(response=>{
        let id = response.data.id;
        if(apiSeq!==apiRef.current)return;
        getJobResult(
          id,
          data=>{
            if(apiSeq!==apiRef.current)return;
            dateDetailLoading.current = false
            dateDataRef.current[date] = {data:data.result.data}
            forceUpdate()
          },
          ()=>{
            dateDataRef.current[date] = {error:true}
            dateDetailLoading.current = false
            forceUpdate()
          }
        )
      })
      .catch(error=>{
        console.log(error);
        if(apiSeq!==apiRef.current)return;
        dateDetailLoading.current = false
        dateDataRef.current[date] = {error:true}
        forceUpdate()
      })
  }

  const onToggleLegend = name => {
    if(!shownSeries.includes(name)){
      setShownSeries([...shownSeries,name])
    }else{
      setShownSeries(shownSeries.filter(el=>el!==name))
    }
  }
  
  return (
    <div className={classes.root}>
      <div style={{display:'flex',justifyContent:'flex-end',marginTop:-28}}>
        <div style={{display:'flex',alignItems:'center',marginRight:8,cursor:'pointer',height:14}} onClick={()=>{onToggleLegend('Average score')}}>
          <Checkbox checked={shownSeries.includes('Average score')} color='primary' />
          <span className={classes.legendText} style={{marginRight:24}}>DQ SCORE</span>
        </div>
        <div style={{display:'flex',alignItems:'center',marginRight:8,cursor:'pointer',height:14}} onClick={()=>{onToggleLegend('Total tests run')}}>
          <Checkbox checked={shownSeries.includes('Total tests run')} color='primary' />
          <span className={classes.legendText}>TOTAL DQ TESTS</span>
        </div>
      </div>
      <div style={{opacity:dateDetailLoading.current?0.6:1,marginTop:32}}>
        {
          shownSeries.includes('Average score') && avgScoreOptions &&
          <div style={{marginBottom:24}}>
            <Typography className={classes.chartTitle}>Average overall DQ Score per day</Typography>
            <ReactApexChart
              options={avgScoreOptions}
              series={avgSeries}
              type="line"
              height={200}
              ref={avgScoreRef}
            />
          </div>
        }
        {
          shownSeries.includes('Total tests run') && countOptions && 
          <div>
            <Typography className={classes.chartTitle}>Count of DQ Tests per day</Typography>
            <ReactApexChart
              options={countOptions}
              series={countSeries}
              type="bar"
              height={200}
              ref={countRef}
            />
          </div>
        }
        {
          shownSeries.length===0 && 
          <Typography style={{marginBottom:40}}>No charts selected</Typography>
        }
      </div>
      {
        selectedDate && 
        <DateDetail
          history={history}
          object={object}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          detailDateData={dateDataRef.current}
          setDetailDateData={setDetailDateData}
          detailDateSort={detailDateSort}
          setDetailDateSort={setDetailDateSort}
        />
      }
    </div>
  );
}

Chart.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  selectedDate: PropTypes.string.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  detailDateData: PropTypes.object.isRequired,
  setDetailDateData: PropTypes.func.isRequired,
}

export default withTheme()(withStyles(styles)(Chart));