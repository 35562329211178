import React,{ useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import {withTheme, withStyles, Typography, IconButton} from '@material-ui/core';
import { getIconComponent, getInitials, getNoteColour, mapObjectName, onGoToLink } from '../../../utilities';
import { getIconLabel } from '../SearchResults/utils';
import KTooltip from '../KTooltip/KTooltip';
import moment from 'moment';
import DetailDrawer from './DetailDrawer';

function reducer(state, action) {
  switch (action.type) {
    case 'set_insights_data':{
      return {
        ...state,
        insightsData: action.insightsData,
      }
    }
    default:
      console.log(action.type)
      throw new Error("Reducer action not supported.", action);
  }
}

const styles = theme => ({
  root:{
    height:56,
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    cursor:'pointer',
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:4,
    marginBottom:16,
    '&:hover':{
      backgroundColor:theme.palette.hovered.main,
    }
  },
  selected:{
    backgroundColor:theme.palette.hovered.main,
  },
  icon:{
    width:24,
    height:24,
    marginLeft:16,
    marginRight:16,
    flexGrow:0,
    flexShrink:0,
  },
  bodyText:{
    color:theme.palette.primaryText.light,
    fontSize:13.75,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginRight:16,
    flexGrow:1,
  },
  tag:{
    height:22,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    borderRadius:12,
    width:'max-content',
    flexGrow:0,
    flexShrink:0,
    fontSize:12,
    padding:'0px 6px',
    marginRight:16,
    border:`1px solid ${theme.palette.chipBorder.main}`
  },
  avatar:{
    ...theme.components.avatar,
    marginRight:16,
    width:24,
    height:24,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontSize:12,
    flexGrow:0,
    flexShrink:0,
    borderRadius:12,
  },
  link:{
    textDecoration:'underline',
    cursor:'pointer'
  }
})

function NoteChip(props) {

  const {
    theme,
    classes,
    note,
    history,
    isEdit,
    onRemoveNote,
    additionalDetail,
    selectedItem,
    setSelectedItem,
    isFeedback,
    object
  } = props;

  const [state, dispatch] = useReducer(reducer, {});
  
  const [drawerOpen, setDrawerOpen] = useState(false);

  let iconColour = theme.palette.primary.main;
  let bodyText = note.name;
  let isLink = note.collection_txt==='LINK' || note.parent?.name==='Link';

  if(isLink){
    bodyText = additionalDetail?.objDetail?.properties?.[3] || note.name;
  }

  if(isFeedback){
    let actionData = note.question_template.template.action_data;
    bodyText = note.question_template.response_template.replace(/\[user\]/g,note.user_name).replace(/\[object\]/g,object.name).replace(/\[response\]/g,actionData.find(a=>a.name===note.response).description.toLowerCase()).replace(/\[object\stype\]/g,mapObjectName(object.object.name).replace(/_/g,' ').toLowerCase())
  }else{
    iconColour = getNoteColour(note.collection_txt || note.parent?.name) || theme.palette.primary.main;
  }

  let tailTag;
  if(note.collection_txt==='QUESTION' || note.parent?.name==='Question'){
    let answer = additionalDetail?.objDetail?.properties?.[3]
    if(answer){
      iconColour = theme.palette.success.main;
      tailTag = (
        <div className={classes.tag} style={{background:theme.palette.success.main,color:theme.palette.background.main,borderColor:theme.palette.success.main}}>
          Answered
        </div>
      )
    }else{
      iconColour = theme.palette.primaryText.light;
      tailTag = (
        <div className={classes.tag} style={{background:theme.palette.chip.main,color:theme.palette.primaryText.main}}>
          Unanswered
        </div>
      )
    }
  }else{
    let lastUpdated = note.last_updated_srt || additionalDetail?.objectDetail?.updated_at;
    if(lastUpdated){
      // check if last updated before 6 months 
      let lastUpdatedDate = moment(lastUpdated);
      let sixMonthsAgo = moment().subtract(6,'months');
      if(lastUpdatedDate.isBefore(sixMonthsAgo)){
        tailTag = (
          <div className={classes.tag} style={{background:theme.palette.chip.main,color:theme.palette.primaryText.main}}>
            Last updated {'>'} 6m
          </div>
        )
      }
    }
  }

  let userTag;
  let user = additionalDetail?.createdBy || additionalDetail?.updatedBy
  if(user){
    userTag = (
      <div className={classes.avatar}>
        {getInitials(user.name)}
      </div>
    )
  }

  let iconComponent;
  if(isFeedback){
    switch(note.response){
      case 'Yes':
        iconComponent = getIconComponent({label:'checked_contained',size:24,colour:theme.palette.success.main})
        break;
      case 'Slightly':
        iconComponent = getIconComponent({label:'feedback_medium',size:24,colour:'#FF9800'})
        break;
      case 'No':
        iconComponent = getIconComponent({label:'clear_circle',size:24,colour:theme.palette.error.main})
        break;
      default:
    }
    
    userTag = (
      <div className={classes.avatar}>
        {getInitials(note.user_name)}
      </div>
    )

    tailTag = (
      <div className={classes.tag} style={{background:theme.palette.chip.main,color:theme.palette.primaryText.main}}>
        on {moment(note.updated_at).format('DD MMM YYYY')}
      </div>
    )
  }

  return (
    <div 
      className={classes.root + (selectedItem && selectedItem.id===note.id?' '+classes.selected:'')} 
      onClick={()=>{if(isEdit)return;setSelectedItem(note);setDrawerOpen(true)}}
      style={isEdit || isFeedback?{cursor:'default',background:theme.palette.background.main}:undefined}
    >
      <KTooltip title={note.collection_txt || note?.parent?.name}>
        <div className={classes.icon}>
          {
            iconComponent || 
            getIconComponent({
              label:getIconLabel({label:'collection_instance',item:note}),
              size:24,
              colour:iconColour
            })
          }
        </div>
      </KTooltip>
      <KTooltip title={bodyText}>
        <Typography className={classes.bodyText + (isLink?' '+classes.link:'')}>
          <span onClick={(event)=>{if(!isLink)return;event.stopPropagation();onGoToLink(additionalDetail?.objDetail?.properties?.[3])}}>{bodyText}</span>
        </Typography>
      </KTooltip>
      {tailTag}
      {userTag}
      {
        isEdit && 
        <IconButton
          onClick={(event)=>{
            event.stopPropagation();
            onRemoveNote(note)
          }}
          style={{marginRight:8,padding:4}}
        >
          {getIconComponent({label:'clear',size:20,colour:theme.palette.primaryText.light})}
        </IconButton>
      }
      <DetailDrawer
        history={history}
        selectedItem={selectedItem?{...selectedItem,...(additionalDetail?.objDetail||{})}:undefined}
        currentItem={note}
        setDrawerOpen={setDrawerOpen}
        drawerOpen={drawerOpen && selectedItem?.id===note.id}
        state={state}
        dispatch={dispatch}
        additionalDetail={additionalDetail}
      />
    </div>
  )
}

NoteChip.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  note: PropTypes.object.isRequired,
  onRemoveNote: PropTypes.func.isRequired,
}

export default withTheme()(withStyles(styles)(NoteChip));