import * as actionTypes from './actionTypes';

export const storePageCache = (data) => {
  return {
    type: actionTypes.STORE_PAGE_CACHE,
    data: data
  };
};

export const removePageCache = data => {
  return {
    type: actionTypes.REMOVE_PAGE_CACHE,
    data:data
  }
}

