import React from 'react';
import { withStyles} from '@material-ui/core';
import DataProducts from '../DataProducts/DataProducts';

const styles = theme => ({
  
})

function Body(props) {

  const {
    history,
    state,
    dispatch,
    tabOptions,
  } = props;

  return (
    <DataProducts
      history={history}
      state={state}
      dispatch={dispatch}
      tabOptions={tabOptions}
    />
  );
}

export default withStyles(styles)(Body);