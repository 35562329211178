import React, { useReducer, useEffect } from 'react';
import { withStyles} from '@material-ui/core';
import Body from '../../components/UserHomeProfile/Body/Body';
import PropTypes from 'prop-types';
import HorizontalLayout from '../../components/UI/HorizontalLayout/HorizontalLayout';
import SideBar from '../../components/UserHomeProfile/SideBar/SideBar';
import { checkOwnedCount, getUserRoles,setInitialState, collectionIds, getSourceObjectCount, setHelpWdigetVisibility, checkStewardedCount, removeUrlQueryArg } from '../../utilities';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import 'url-search-params-polyfill';
import axiosCerebrum from '../../axios-cerebrum'
import axiosSolr from '../../axios-solr'
import { addHistory } from '../../HistoryManager';
import useAlert from '../../hooks/useAlert';
// import keycloakInstance from '../../keycloak-instance';
// import { IntroManagerMain } from '../../components/UI/IntroManager/IntroManager';

const styles = theme => ({
});

const initialState = {
  tabState:0,
  userData:undefined,
  insightsDataCache:{},
  metricsData:[],
  // collectionRecommendations:,
  objectTagCache:{},
  dashboardFilter:'',
  dashboardSort:'A-Z',
  ecosystemTabsData:{},
  ecosystemTabState:0,
  ecosystemData:{},
  ecosystemLoading:{},
  ecosystemError:{},
  ecosystemFilter:{},
  ecosystemSort:{},
  feedObjectDetailsMap:{},
  searchValue:'',
}

const reducer = (state, action) => {
  switch (action.type) {
    case "set_tab_state":
      return {
        ...state,
        tabState: action.tabState
      }
    case 'set_metrics_data':
      return {
        ...state,
        metricsData:action.metricsData
      }
    case 'set_collection_recommendations':
      return {
        ...state,
        collectionRecommendations:action.collectionRecommendations
      }
    case 'set_search_value':
      return {
        ...state,
        searchValue:action.searchValue
      }
    case 'set_list_data':
      return {
        ...state,
        listData:action.listData
      }
    case 'set_filter_data':
      return {
        ...state,
        filterData:action.filterData
      }
    case 'set_notification_data':
      return {
        ...state,
        notificationData:action.notificationData,
        notificationError:action.notificationError,
        notificationLoading:action.notificationLoading
      }
    case 'set_feed_object_details_map':
      return {
        ...state,
        feedObjectDetailsMap:action.feedObjectDetailsMap
      }
    case 'set_source_count_data':
      return {
        ...state,
        sourceCountData:action.sourceCountData
      }
    case 'set_follows_data':
      return {
        ...state,
        followsData:action.followsData,
        followsError:action.followsError,
        followsLoading:action.followsLoading
      }
    case 'set_follows_object':
      return {
        ...state,
        followsObject:action.followsObject
      }
    default:
      return state
  }
}

const UserHomeProfile = props => {

  const {
    history,
    sessionData,
    pageCache,
    storePageCache,
  } = props;

  const urlSearch = new URLSearchParams(window.location.search);
  const isOnboarded = urlSearch.get('isOnboarded')
  const roles = getUserRoles(sessionData.user_role)
  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));

  const {
    sendAlert
  } = useAlert({})

  // disable intro for now, to be fixed

  // useEffect(()=>{
  //   if(!localStorage.hasOwnProperty('intro_finished')){
  //     let hasToken = Boolean(keycloakInstance.token)
  //     if(hasToken && roles.length>0){
  //       IntroManagerMain(history, sessionData)
  //     }
  //   }
  //   // eslint-disable-next-line
  // },[])

  useEffect(()=>{
    setHelpWdigetVisibility(true)
    addHistory({
      url:window.location.pathname, 
      iconLabel:'home', 
      title: 'Home Page', 
      subTitle:`Home Page`,
      type:'application',
    })

    if(isOnboarded){
      sendAlert({ message: "Onboarding completed. It may take up to 24 hours to complete the linking process. In that time some information about you may be missing.", type: 'info' })
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['isOnboarded']}));
    }

    return ()=>{setHelpWdigetVisibility(false)}
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state,notificationData:undefined,notificationLoading:undefined,notificationError:undefined,insightsDataCache:{},selectedFeed:undefined})
  },[state,storePageCache,sessionData])

  const fetchRecommendation = async () => {
    let domains, useCases;
    await axiosCerebrum 
      .get('/api/collectioninstances/recommendations',{params:{per_page:10,categories:'PLATFORM',collection_ids:`${collectionIds.domain}`,sort:'LINKAGES_DESC'}})
      .then(response=>{
        if(response.data.total===0)return;
        domains = response.data;
      })
      .catch(error=>{
        console.log(error)
      })
    await axiosCerebrum 
      .get('/api/collectioninstances/recommendations',{params:{per_page:10,categories:'PLATFORM',collection_ids:`${collectionIds.verifiedUseCase}`,sort:'LINKAGES_DESC'}})
      .then(response=>{
        if(response.data.total===0)return;
        useCases = response.data;
      })
      .catch(error=>{
        console.log(error)
      })
    if(!domains && !useCases){
      dispatch({
        type:'set_collection_recommendations',collectionRecommendations: {}
      })
    }
    dispatch({
      type:'set_collection_recommendations',collectionRecommendations: {[collectionIds.domain]:domains,[collectionIds.verifiedUseCase]: useCases}
    })
  }

  const fetchMetrics = async () => {
    let consumer = await axiosCerebrum.get(`/api/users/${sessionData.id}/related`,{params:{relationship:'VIEWS',object_name:"TABLE,CONTENT,DATASET,DATA_PIPELINE,FILE,ML_MODEL",per_page:0}})
    let owner = await checkOwnedCount(sessionData)
    let producer = await axiosCerebrum.get(`/api/users/${sessionData.id}/related`,{params:{relationship:'CREATOR_OF',object_name:"TABLE,CONTENT,DATASET,DATA_PIPELINE,FILE,ML_MODEL",per_page:0}}) 
    let steward = await checkStewardedCount(sessionData)
    dispatch({
      type:'set_metrics_data',
      metricsData:[
        consumer?{name:'Consumer',value:consumer.data.total,onClick:()=>history.push('/my_data?tabName=USED'),url:'/my_data?tabName=USED'}:undefined,
        owner?{name:'Owner',value:owner.total,onClick:()=>history.push('/dashboard/data_owner'),url:'/dashboard/data_owner'}:undefined,
        producer?{name:'Producer',value:producer.data.total,onClick:()=>history.push('/my_data?tabName=CREATED'),url:'/my_data?tabName=CREATED'}:undefined,
        steward?{name:'Steward',value:steward.total,onClick:()=>history.push('/dashboard/data_steward'),url:'/dashboard/data_steward'}:undefined
      ].filter(el=>el)
    })           
  }


  const fetchSourceCount = () => {
    axiosSolr
      .get(
        '/solr/search/select',{
          params:{
            q:"*",
            fq:"-source_id_srt:(995 OR 998 OR 1000) AND reference_srt:NO AND active_srt:YES",
            rows:0,
            'json.facet':{
              "source": {
                "type": "terms",
                "field": "source_srt",
                "mincount":1,
                'facet':{
                  "object_type":{
                    "type":"terms",
                    "field": "object_type_srt",
                    "mincount":1,
                  },
                  "source_type":{
                    "type":"terms",
                    "field": "source_type_srt",
                  },
                  "source_id":{
                    "type":"terms",
                    "field": "source_id_srt",
                  }
                }
              }
            }
          }
        }
      )
      .then(async response=>{
        let sourceFacet = response.data.facets.source
        let buckets = sourceFacet.buckets;
        const sourceList = [];
        if(buckets){
          let promises = []
          for(let i=0; i<buckets.length; i++){
            let el = buckets[i];
            promises.push(axiosCerebrum.get(`/api/sources/${el.source_id.buckets[0].val}`))
          }

          await Promise
            .all(promises)
            .then(response=>{
              let sources = response.map(el=>el.data);
              for(let i=0; i<buckets.length; i++){
                let el = buckets[i];
                let source = sources.find(s=>s.id===el.source_id.buckets[0].val)
                let source_type = source.source_template.type;
                if(source_type==='IDENTITY_STORE' || source_type==='EXTERNAL_INTEGRATION' || source.active_flag===false){
                  continue;
                }
                let source_template_name = source.source_template.name;
                let source_name = el.val
                sourceList.push({
                  name:source_name,
                  count:el.count,
                  maxCount:el.object_type.buckets.find(el=>el.val===getSourceObjectCount(source_template_name, source_type)) || {val:getSourceObjectCount(source_template_name, source_type),count:0},
                  source_type:el.source_type.buckets[0].val,
                  source_id:el.source_id.buckets[0].val,
                  onClick:()=>history.push("/profile/source/"+el.source_id.buckets[0].val+"?assetTabName="+getSourceObjectCount(source_template_name, source_type)),
                  url: "/profile/source/"+el.source_id.buckets[0].val+"?assetTabName="+getSourceObjectCount(source_template_name, source_type)
                })
              }
            })
            .catch(error=>{
              console.log(error)
              for(let i=0; i<buckets.length; i++){
                let el = buckets[i];
                sourceList.push({
                  name:el.val,
                  count:el.count,
                  maxCount:el.object_type.buckets.filter(o=>!['CODE','COLLECTION_INSTANCE','COLUMN'].includes(o.val))[0],
                  source_type:el.source_type.buckets[0].val,
                  id:el.source_id.buckets[0].val,
                  host:' ',
                  onClick:()=>history.push("/profile/source/"+el.source_id.buckets[0].val),
                  url:"/profile/source/"+el.source_id.buckets[0].val,
                  object_type_txt:'SOURCE'
                })
              }
            })
          dispatch({
            type:'set_source_count_data',
            sourceCountData:sourceList.sort((a,b)=>b.maxCount.count-a.maxCount.count)
          })
        } 
      })
      .catch(error=>{
        console.log(error)
      })
  }

  const fetchListData = () => {
    axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related/collections`,
        {params:{
          relationship:'CREATOR_OF,MODIFIES',
          parent_name:"LIST",
          per_page:10,
          page:1,
          sort:'END_DESC'
        }}
      )
      .then(response=>{
        let items = [];
        response.data.items.forEach(el=>{
          if(!items.find(i=>i.id===el.id)){
            items.push({
              ...el,
              onClick:()=>history.push(`/profile/collection_instance/${el.id}`),
              url:`/profile/collection_instance/${el.id}`
            })
          }
        })
        dispatch({type:'set_list_data',listData:{...response.data,items}})
      })
      .catch(error=>{
        console.log(error)
      })
  }

  const fecthFilterData = () => {
    axiosCerebrum
      .get(
        `/api/filteredlists`,
        {params:{
          per_page:10,
          page:1,
        }}
      )
      .then(response=>{
        dispatch({type:'set_filter_data',filterData:response.data})
      })
      .catch(error=>{
        console.log(error)
      })
  }
  
  useEffect(()=>{
    if(!state.sourceCountData)fetchSourceCount();
    if(!state.listData)fetchListData();
    if(!state.filterData)fecthFilterData();
    fetchMetrics();
    if(!state.collectionRecommendations)fetchRecommendation();
   // eslint-disable-next-line
  },[])

  return (
    <HorizontalLayout
      left={
        <SideBar 
          history={history}
          state={state}
          dispatch={dispatch}
          roles={roles}
          sessionData={sessionData}
        />
      }
      children={
        <Body
          roles={roles}
          tabState={state.tabState}
          dispatch={dispatch}
          state={state}
          history={history}
          sessionData={sessionData}
        />
      }
    />
  )
}



UserHomeProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sessionData: PropTypes.object.isRequired
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserHomeProfile));