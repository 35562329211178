
import React from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
// import DonutChart from '../Components/DonutChart'
import BarChart from '../Components/BarChart'
import DataTile from '../Components/DataTile'
import StepLineChart from '../Components/StepLineChart';
// import { palette } from '../Components/utils';
import KTooltip from '../../UI/KTooltip/KTooltip';
import { toTitleCase } from '../../../utilities';

export const generateDataTiles = ({classes, data, setData}) => {
  return (
    <div style={{display:'flex',width:'100%',marginBottom:24}}>
      {
        data.tileDataLoading && <CircularProgress color='secondary' style={{margin:'0px auto'}} />
      }
      {
        data.tileDataError && <Typography className={classes.normalText}>Error occurred loading details</Typography>
      }
      {
        data.tileData && data.tileData.map((el,index)=>(
          <DataTile tooltip={el.tooltip} clickable={el.clickable} forceValueFontSize={18} iconLabel={'issue'} tileId={el.id} onClick={()=>{if(el.clickable){setData({...data,selectedTile:el.id})}}} selected={data.selectedTile===el.id} title={el.title} value={el.value} footer={el.footer}/>
        ))
      }
      {
        data.tileData && data.tileData.length===0 && <Typography className={classes.normalText}>No data to display</Typography>
      }
    </div>
  )
}

export const generateChartGroup = ({classes,data}) => {
  if(data[`${data.selectedTile}_graphDataLoading`]){
    return <div style={{textAlign:'center',marginBottom:24}}><CircularProgress color='secondary'/></div>
  }
  if(data[`${data.selectedTile}_graphDataError`]){
    return <Typography className={classes.normalText} style={{marginBottom:24}}>Error occurred loading graphs</Typography>
  }
  if(!data[`${data.selectedTile}_graphData`]){
    return <div style={{marginBottom:24}}></div>
  }
  let graphData = data[`${data.selectedTile}_graphData`];
  let chartData = [];

  let status = toTitleCase(data.selectedTile).replace(/_/g,' ')
  let id = data.selectedTile

  let title = `${status} issues by severity`
  let subTitle = `Count of ${status.toLowerCase()} issues by issue severity`;
  if(graphData[`${id}_BY_SEVERITY`]){
    chartData.push({type:'bar',width:'80%',height:250,title,subTitle,...graphData[`${id}_BY_SEVERITY`]})
  }

  title = `${status} issues by type`
  subTitle = `Count of ${status.toLowerCase()} issues by issue type`;
  if(graphData[`${id}_BY_TYPE`]){
    chartData.push({type:'bar',width:'80%',height:250,title,subTitle,...graphData[`${id}_BY_TYPE`]})
  }

  title = `${status} issues by assigned status`
  subTitle = `Count of ${status.toLowerCase()} issues by assigned status`;
  if(graphData[`${id}_IS_ASSIGNED`]){
    chartData.push({type:'bar',width:'80%',height:250,title,subTitle,...graphData[`${id}_IS_ASSIGNED`]})
  }

  title = `${status} issues by source`
  subTitle = `Count of ${status.toLowerCase()} issues by issue source`;
  if(graphData[`${id}_BY_SOURCE`]){
    chartData.push({type:'bar',width:'80%',height:250,title,subTitle,...graphData[`${id}_BY_SOURCE`]})
  }

  title = `Issues ageing`
  subTitle = `Count of ${status.toLowerCase()} issues by created date`;
  if(graphData[`${id}_BY_CREATED`]){
    chartData.push({type:'bar',width:'80%',height:250,title,subTitle,...graphData[`${id}_BY_CREATED`]})
  }

  title = `${status} issues open rate`
  subTitle = `Count of ${status.toLowerCase()} issues opened each day for the last 30 days`;
  if(graphData[`${id}_OPEN_RATE`]){
    chartData.push({type:'step_line',width:'80%',height:250,title,subTitle,...graphData[`${id}_OPEN_RATE`]})
  }

  title = `Top assignees`
  subTitle = `Count of ${status.toLowerCase()} issues by assignee. Top 5 assignees shown`;
  if(graphData[`${id}_TOP_ASSIGNEE`]){
    chartData.push({type:'bar',width:'80%',height:250,title,subTitle,...graphData[`${id}_TOP_ASSIGNEE`]})
  }

  title = `Top reporters`
  subTitle = `Count of ${status.toLowerCase()} issues by reporter. Top 5 reporters shown`;
  if(graphData[`${id}_TOP_REPORTER`]){
    chartData.push({type:'bar',width:'80%',height:250,title,subTitle,...graphData[`${id}_TOP_REPORTER`]})
  }

  if(chartData.length===0)return <Typography style={{marginBottom:24}}>No insights found</Typography>
  
  return (
    <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}>
      {
        chartData.map(el=>(
          <div className={classes.chartContainer}>
            <KTooltip title={el.tooltip} classes={{tooltip:el.tooltip?classes.tooltip:classes.hidden}}>
              <div>
                <Typography className={classes.chartTitle}>{el.title}</Typography>
                <Typography className={classes.chartSubtitle}>{el.subTitle}</Typography>
              </div>
            </KTooltip>
            
            {
              el.type==='text' && 
              <div style={{width:el.width,height:el.height}}>
                <Typography style={{marginTop:16}} className={classes.subTitle}>{el.text}</Typography>
              </div>
            }
            {
              el.type==='bar' && 
              <BarChart
                id={el.title+status}
                key={el.title+status}
                width={el.width}
                height={el.height}
                series={el.series}
                labels={el.labels}
              />
            }
            {
              el.type==='step_line' && 
              <StepLineChart
                id={el.title+status}
                key={el.title+status}
                width={el.width}
                height={el.height}
                series={el.series}
                labels={el.labels}
              />
            }
            
          </div>
        ))
      }
      
    </div>
  ) 
}

export const generateRecommendationList = ({classes, data}) => {

  if(data[`${data.selectedTile}_recommendationLoading`]){
    return <div style={{textAlign:'center',marginTop:36}}><CircularProgress color='secondary'/></div>
  }

  if(data[`${data.selectedTile}_recommendationError`]){
    return <Typography className={classes.normalText}>Error occurred loading recommendations</Typography>
  }

  if(!data[`${data.selectedTile}_recommendationData`] || !data.selectedTile){
    return <div></div>
  }

  let recommendations = data[`${data.selectedTile}_recommendationData`];
  if(recommendations.length===0)return <Typography className={classes.normalText}>No recommendations currently provided</Typography>
  
  return (
    <div style={{marginBottom:500}}>
      {
        recommendations.map(el=>(
          <div className={classes.recommendationContainer} onClick={el.onClick}>
            <Typography className={classes.recommendationTitle}>{el.title}</Typography>
            <div className={classes.recommendationCount}>{el.countTxt}</div>
          </div>
        ))
      }
    </div>
  )
}
