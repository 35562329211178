import React, { useState } from 'react';
import {withTheme, withStyles, Typography, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import FilterSearchInput from './FilterSearchInput';
import SimpleResultItem from '../SearchResults/SimpleResultItem';
import { getDispFields } from '../../../utilities';
import { getIconLabel } from '../SearchResults/utils';


const styles = theme => ({
  selectoContainer:{
    flex:'0 1',
    width:162,
    minWidth:162,
    marginRight:16,
  },
  selector:{
    ...theme.components.selector,
    width:'100%',
    height:42,
    borderColor:theme.palette.primaryText.light,
    background:`${theme.palette.background.main} !important`,      
    '& div div': {
      paddingLeft: 8,
      paddingTop:6,
      paddingBottom:6,
      fontSize:16,
      background:`${theme.palette.background.main} !important`,      
    },
  },
  filterLabel:{
    marginBottom:-8,
    marginLeft:8,
    position:'relative',
    zIndex:99, 
    width:'max-content', 
    overflow:'hidden', 
    textOverflow:'ellipsis', 
    whiteSpace:'nowrap', 
    maxWidth:136, 
    background:theme.palette.background.main, 
    color:theme.palette.primaryText.light,
    padding:'0 6px'
  },
  resultItem:{
    paddingTop:0,
    paddingBottom:0,
    height:66
  }
})


const LabeledSelector = props => {

  const {
    classes,
    theme,
    title,
    onChange,
    value,
    testID,
    placeholder,
    options,
    isResultItem,
    hideSearch,
    removeDefaultOption
  } = props;

  const [searchValue, setSearchValue] = useState('')

  return (
    <div className={classes.selectoContainer}>
      <Typography className={classes.filterLabel}> 
        {title}
      </Typography>
      <Select
        value={value}
        className={classes.selector}
        disableUnderline
        data-test-id={testID}
        onChange={onChange}
        renderValue={value=>{
          return options.find(o=>o.value===value)?.name || options.find(o=>o.value===value)?.name_txt || 'All'
        }}
      >
        {
          !hideSearch && 
          <FilterSearchInput 
            searchValue={searchValue} 
            setSearchValue={value => setSearchValue(value)}
            placeholder={placeholder}
          />
        }
        {
          !removeDefaultOption &&
          <MenuItem value='all' className={isResultItem?classes.resultItem:''} style={{height:isResultItem?48:undefined}}>
            {
              isResultItem?
              <div style={{width:700,maxWidth:'100vw'}}>
                <SimpleResultItem
                  title='All'
                  iconLabel={options[0]?.object_type_txt||'info'}
                  item={{name:'All'}}
                  hideRight={true}
                  disableContextMenu
                  keepPropagation
                  removeHorizontalPadding
                  iconColour={theme.palette.primaryText.light}
                  titleColour={theme.palette.primaryText.main}
                  showUnderline
                  hideIcon
                />
              </div>
              :
              'All'
            }
          </MenuItem>
        }
        {
          options?.filter(el=>searchValue.trim()==='' || (isResultItem?getDispFields(el,'dispTitle'):el.name).toLowerCase().includes(searchValue.toLowerCase())).map(el=>(
            <MenuItem value={el.value} style={{borderTop:isResultItem?undefined:`1px solid ${theme.palette.listItemDivider.main}`}}  className={isResultItem?classes.resultItem:''}>
              {
                isResultItem?
                <div style={{width:700,maxWidth:'100vw'}}>
                  <SimpleResultItem
                    title={getDispFields(el,'dispTitle')}
                    label={getIconLabel({label:el.object_type_txt,item:el})}
                    item={el}
                    subTitle={getDispFields(el,'dispSubtitle')}
                    hideRight={true}
                    disableContextMenu={true}
                    keepPropagation
                    removeHorizontalPadding
                    iconColour={theme.palette.primaryText.light}
                    titleColour={theme.palette.primaryText.main}
                    showUnderline
                    hideIcon
                  />
                </div>
                :
                el.name
              }
            </MenuItem>
          ))
        }
      </Select>
    </div> 
  )

}

LabeledSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  testID: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  hideSearch: PropTypes.bool,
  removeDefaultOption: PropTypes.bool,
}


export default withTheme()(withStyles(styles)(LabeledSelector));