import React from 'react';
import { withStyles} from '@material-ui/core';
import CategorySelector from '../CategorySelector/CategoryaSelector';
import ResultList from '../ResultList/ResultList';

const styles = theme => ({
  root: {
    display:'flex'
  }
})

const Body = props => {

  const {
    classes,
    history,
    state,
    dispatch,
  } = props;

  return (
    <div className={classes.root}>
      <CategorySelector state={state} dispatch={dispatch} history={history}/>
      <ResultList state={state} dispatch={dispatch} history={history}/>
    </div>
  )
}

export default withStyles(styles)(Body);