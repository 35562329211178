import { Typography, withTheme, withStyles, IconButton } from '@material-ui/core';
import React, { memo } from 'react';

import theme from '../../../theme';
import { getIconComponent } from '../../../utilities';

import { Handle } from 'react-flow-renderer';
import KTooltip from '../KTooltip/KTooltip';

const styles = theme => ({
  undraggableNode:{
    cursor:'default !important',
  },
  clickable:{
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline',
      textUnderlineOffset:'2px',
      textDecorationColor:theme.palette.primary.main,
    }
  },
  actionButton:{
    border:`1px solid ${theme.palette.background.main}`,
    background:theme.palette.primaryText.light,
    padding:2,
    width:24,
    marginBottom:8,
    height:24,
    position:'absolute',
    zIndex:999,
    '&:hover':{
      width:28,
      height:28,
      background:theme.palette.primaryText.light,
    }
  },
  leftActionButton:{
    left:-35,
    alignSelf:'center',
    '&:hover':{
      left:-37,
    }
  },
  rightActionButton:{
    right:-35,
    alignSelf:'center',
    '&:hover':{
      right:-37,
    }
  },
  loadingIcon:{
    border:`1px solid ${theme.palette.background.main}`,
    width:24,
    height:24,
    marginBottom:8,
    alignItems:'center',
    justifyContent:"center",
    display:'inline-flex',
    position:'absolute',
    borderRadius:'50%',
    background:theme.palette.primaryText.light,
    animationName: 'loading-spin',
    animationDuration: '800ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    cursor:'default'
  },
  [`@keyframes loading-spin`]: {
    from: {
      transform:'rotate(0deg)',
    },
    to: {
      transform:'rotate(360deg)',
    },
  },
  leftLoadingIcon:{
    left:-35,
    alignSelf:'center',
  },
  rightLoadingIcon:{
    right:-35,
    alignSelf:'center',
  },
})


const GroupNode = memo(({ data, draggable,selected,viewOnly, type, classes, id }) => {
  
  return (
    <div
      style={{
        border:`2px solid ${data.borderColour || theme.palette.primary.main}`,
        borderRadius:5,
        width:data.width,
        minWidth:100,
        height:data.height,
        // marginLeft:-48,
        opacity:data.faded?0.5:1,
        // background: theme.palette.kChartGroupBackground['main'],
      }}
      onClick={event=>{
        event.preventDefault();
        // event.stopPropagation();
      }}
      className={data.draggable===false?classes.undraggableNode:''}
    >
      <div style={{display:'flex',alignItems:'center'}}>
        {
          !viewOnly && 
          <Handle
            type="target"
            position="left"
            style={{ 
              background: '#555',
              visibility:'hidden', 
              position:'relative',
              top:0,
              width:0,
              left:isNaN(data.leftHandleOffset)?(data.leftAction?8:42):data.leftHandleOffset
            }}
          />
        }
        {
          data.leftAction && !viewOnly &&
          (
            data.leftActionIcon==='loading'?
            <div className={classes.loadingIcon + ' ' + classes.leftLoadingIcon}>
              {getIconComponent({label:'refresh', size:20, colour:theme.palette.background.main})}
            </div>
            :
            <KTooltip title={data.leftActionTooltip || ''}>
              <IconButton 
                className={classes.actionButton + ' ' + classes.leftActionButton + ' node-action'}
                onClick={event=>{
                  // event.stopPropagation();
                  data.leftAction()
                }}
              >
                {getIconComponent({label:data.leftActionIcon || 'add', size:16, colour:theme.palette.background.main})}
              </IconButton>
            </KTooltip>
          )
        }
        <div style={{
            display:'flex',
            width:'100%',
            alignItems:'center',
            overflow:"hidden",
            minHeight:44,
            padding:'8px 16px',
            borderBottom:`2px solid ${data.borderColour || theme.palette.primary.main}`,
            background: theme.palette.background.main,
          }}
        >
          {
            data.icon &&
            <div 
              style={{width:24,height:24,marginRight:8,marginTop:-1}}
            >
              {
                getIconComponent({
                  label:data.icon,
                  size:24,
                  colour:(data.iconColour || theme.palette.primary.main)
                })
              }
            </div>
          }
          <div onClick={data.onTitleClick} className={data.onTitleClick?classes.clickable:''}  style={{flexGrow:1,overflow:'hidden'}}>
            <KTooltip title={data.label}>
              <Typography 
                style={{
                  fontSize:13.75,
                  color: data.titleColour || theme.palette.primaryText.main,
                  fontWeight:700,
                  ...(data.wrapTitle?{whiteSpace:'normal',wordBreak:'break-word'}:{})
                }}
                className={'overflow-text group-title'}
              >
                {data.label}
              </Typography>
            </KTooltip>
            {
              data.subTitle && 
              <KTooltip title={data.subTitleStyled || data.subTitle}>
                <Typography 
                  style={{
                    fontSize:12,
                    color: theme.palette.primaryText.light,
                    marginTop:4,
                    ...(data.wrapTitle?{whiteSpace:'normal',wordBreak:'break-word'}:{})
                  }}
                  className={'overflow-text group-subtitle'}
                >
                  {data.subTitleStyled || data.subTitle}
                </Typography>
              </KTooltip>
            }
          </div>

          {
            data.rightIcon &&
            <div style={{width:24,height:24,marginLeft:8}}>
              {
                getIconComponent({
                  label: data.rightIcon,
                  size:24,
                  colour:data.rightIconColour || theme.palette.primaryText.light
                })
              }
            </div>
          }
        </div>

        {
          data.rightAction && !viewOnly &&
          (
            data.rightActionIcon==='loading'?
            <div className={classes.loadingIcon + ' ' + classes.rightLoadingIcon}>
              {getIconComponent({label:'refresh', size:20, colour:theme.palette.background.main})}
            </div>
            :
            <KTooltip title={data.rightActionTooltip || ''}>
              <IconButton 
                className={classes.actionButton + ' ' + classes.rightActionButton + ' node-action'}
                onClick={event=>{
                  // event.stopPropagation();
                  data.rightAction()
                }}
              >
                {getIconComponent({label:data.rightActionIcon || 'add', size:16, colour:theme.palette.background.main})}
              </IconButton>
            </KTooltip>
          )
        }
        {
          !viewOnly && 
          <Handle
            type="source"
            position="right"
            style={{ 
              background: '#555',
              visibility:'hidden', 
              position:'relative',
              top:0,
              width:0,
              right:isNaN(data.rightHandleOffset)?(data.rightAction?11:42):data.rightHandleOffset
            }}
            // isConnectable={isConnectable}
          />
        }

      </div>
    </div>
  );
});


export default withTheme()(withStyles(styles)(GroupNode));