import React, { } from 'react';
import { withTheme, withStyles, Typography, LinearProgress } from '@material-ui/core';

const styles = theme => ({
  root:{
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    marginTop:40
  },
  text:{
    fontSize:16,
    marginBottom:4
  }
})


function ChartLoadingIcon(props) {

  const {
    classes,
  } = props;

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>Fetching Data</Typography>
      <Typography className={classes.text} style={{marginBottom:24}}>This may take some time to complete</Typography>
      <LinearProgress style={{width:280}} color='secondary'/>
    </div>
  )
}


export default withTheme()(withStyles(styles)(ChartLoadingIcon));