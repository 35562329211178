import React from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { graphPalette } from '../../utils';
// import moment from 'moment'

const styles = theme => ({
})

const StackHorizontalBar = props => {

  const {
    theme,
    id,
    data,
    labels
  } = props;

  let series = data;
  let options = {
    chart: {
      id,
      type: 'bar',
      toolbar:{show:false},
      stacked:true
    },
    legend:{
      position:"top",
      horizontalAlign:"right",
      markers:{
        offsetY:2,
        offsetX:-4
      },
      itemMargin:{
        horizontal:8
      },
      labels:{
        colors:[theme.palette.primaryText.main],
      }
    },
    colors:data.map(d=>graphPalette[d.name.replace(/\s/g,'_').toUpperCase()]),
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        }
      },
      hover: {
        filter: {
          type: 'darken',
          value: 0.75,
        }
      },
      active: {
        filter: {
          type: 'none',
          value: 0,
        }
      },
    },
    yaxis:{
      labels: {
        style:{
          colors:theme.palette.primaryText.main
        }
      },
    },
    xaxis:{
      labels: {
        formatter: function(val) {
          if(!val)return val;
          if(Number(val.toFixed(0)).valueOf()!==val)return '';
          return val.toFixed(0)
        },
        style:{
          colors:theme.palette.primaryText.main
        }
      },
    },
    labels:labels,
  }

  
  return (
    <ReactApexChart key={id} options={options} series={series} height={Math.max(labels.length*80,200)} type="bar" />
  )
}

export default withTheme()(withStyles(styles)(StackHorizontalBar));