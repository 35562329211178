import React, { useEffect } from 'react';
import { withTheme,withStyles, Typography, CircularProgress, Select, MenuItem } from '@material-ui/core';
import { onClickResultItem,getDispFields } from '../../../utilities';
import ResultItem2 from '../../UI/SearchResults/ResultItem2';
import axiosSolr from '../../../axios-solr';
import axiosCerebrum from '../../../axios-cerebrum'

const styles = theme => ({
	selector: {
    ...theme.components.selector,
    width: 180,
	},
})

const Reference = props => {

	const {
    history,
    theme,
		classes,
		state,
		dispatch,
	} = props;


	const sortByItems = [
		{ dispName: 'Frequently used', value: 'total_usage_srt desc' },
		{ dispName: 'Recently used', value: 'last_used_srt desc' },
		{ dispName: 'A-Z', value: 'name_srt asc' },
		{ dispName: 'Z-A', value: 'name_srt desc' }
  ]
  
  const loadData = (filter) => {
    dispatch({type:'set_reference_data',referenceLoading:true})
    axiosCerebrum
      .get(
        `/api/tools/${state.basicData.id}/related`,
        {params:{
          object_name:'DATABASE',
          relationship:'VIEWS'
        }}
      )
      .then(response=>{
        if(response.data.total===0){
          dispatch({type:'set_reference_data',referenceData:[]})
          return;
        }
        axiosSolr.get(
          '/solr/search/select',
          {params:{
            q:'*',
            fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
            fl:'*',
            sort:filter
          }}
        ).then(response=>{
          dispatch({type:'set_reference_data',referenceData:response.data.response.docs})
        }).catch(error=>{
          dispatch({type:'set_reference_data',referenceError:true})
        })
      })

  }

  useEffect(()=>{
    if(!state.referenceData)loadData(state.referenceFilter);
    // eslint-disable-next-line
  },[])


  if(state.referenceError)return <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading references</Typography>

	return (
		<div className={classes.root}>
			<div style={{ display: 'flex', alignItems: 'flex-end',justifyContent:'space-between' }}>
				<div>
          <Typography style={{fontSize:20,color:theme.palette.header.main}}>{state.basicData.reference_sources.length} REFERENCE(S)</Typography>
					<Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>This Tool may be referenced in 1 or more Sources. Select a Source to see more details</Typography>
				</div>
				<div>
					<Select
						className={classes.selector}
						value={state.referenceFilter}
						onChange={event =>{loadData(event.target.value);dispatch({ type: 'set_reference_filter', referenceFilter: event.target.value })}}
					>
						{
							sortByItems.map(el => (
								<MenuItem className={classes.menuItem} value={el.value}>
									<span>{el.dispName}</span>
								</MenuItem>
							))
						}
					</Select>
				</div>
			</div>

			<div style={{marginTop:30}}>
        {
          state.referenceLoading && 
          <div style={{width:"100%",display:'flex',justifyContent:'center'}}>
            <CircularProgress color='secondary' style={{marginTop:50}}/>
          </div>
        }
        {
          state.referenceData && 
          state.referenceData.map(item=>(
            <div style={{borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}}> 
              <ResultItem2
                item={item}
                key={item.id}
                dispBody={getDispFields(item,'dispBody')}
                dispSubtitle={getDispFields(item,'dispSubtitle')}
                dispTitle={getDispFields(item,'dispTitle')}
                label={item.object_type_txt}
                showIcon
                onClick={() => onClickResultItem({id:item.id, label: item.object_type_txt,  history: history, item: item })}
              />
            </div>
          ))
        }
			</div>
		</div>
	)
}


export default withTheme()(withStyles(styles)(Reference));