import React from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment'
import { formatNumber } from '../../../utilities';

const styles = theme => ({
})

const StepLineChart = props => {

  const {
    theme,
    id,
    height,
    width,
    series, 
  } = props;

  const generateLabels = (dataset) => {
    let days = dataset.length-1;
    const labels = []
    for(let i=days; i>=0; i--){
      labels.push(moment().add(-i,'day').format('ll'))
    }
    return labels
  }

  const options = {
    chart: {
      id:id,
      type: 'area',
      zoom: {enabled: false},
      toolbar:{show:false},
      stacked: true,
    },
    colors:['#A1C4FD'],
    legend:{
      show:false,
      // position:'top',
      // showForSingleSeries:true,
      // horizontalAlign:'left',
      // height:50,
      // fontSize:'12px',
      // itemMargin:{
      //   horizontal:8
      // },
      // labels:{
      //   colors:theme.palette.primaryText.main
      // },
      // markers:{
      //   width:10,
      //   height:10
      // }
    },
    markers:{
      size:4,
    }, 
    dataLabels: {
      enabled: false
    },
    tooltip:{
      y:{formatter:value=>value.toFixed(0)}
    },
    stroke: {
      curve: 'stepline',
      width: 5
    },
    xaxis: {
      categories:generateLabels(series[0].data),
      labels:{show:false}
    },
    yaxis:{ 
      labels: {
        minWidth:24,
        formatter: function(val) {
          if(val!==Math.floor(val))return ''
          let formatted = val.toFixed(0);
          try{
            formatted = formatNumber(formatted,undefined,true)
          }catch(error){
            console.log(error)
          }
          return formatted
        },
        style:{
          colors:theme.palette.primaryText.light
        }
      },
      forceNiceScale:true,
      axisTicks:{
        show:true,
      },
      axisBorder: {
        show: true,
      }
    },
    grid:{
      xaxis:{
        lines:{
          show:false
        }
      }
    }
  };

  
  return (
    <ReactApexChart options={options} series={series} height={height} width={width} style={{justifyContent:'center', display:'flex'}} type="line" />
  )
}

export default withTheme()(withStyles(styles)(StepLineChart));