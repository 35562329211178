import React, { useState } from 'react';
import { withTheme, Typography, withStyles, IconButton, Select, MenuItem, Button, ListItem} from '@material-ui/core';
import SearchSelector from '../../UI/SearchSelector/SearchSelector'
import { getIconComponent, toTitleCase } from '../../../utilities';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem'
import UserSuggestionItem from '../../UI/SearchResults/UserSuggestionItem';

const styles = theme => ({
  root: {
    
  },
  selector:{
    ...theme.components.selector,
    marginBottom:20
  },
})

const SelectTarget = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
  } = props;

  const [searchValue, setSearchValue] = useState('')

  const onItemSelected = item => {
    setSearchValue('')
    dispatch({
      type:'set_merged_items',
      mergedItems:[item,...(state.mergedItems||[])]
    })
  }

  const onRemoveItem = item => {
    dispatch({
      type:'set_merged_items',
      mergedItems:state.mergedItems.filter(m=>m.id!==item.id)
    })
  }

  return (
    <div className={classes.root}>
      <Typography style={{fontSize:16, marginBottom:8, color:theme.palette.primary.main}}>Filters</Typography>
      <Select
        value={state.mergeSourceFilter || 'all'}
        onChange={(event)=>{dispatch({type:'set_merge_source_filter',mergeSourceFilter:event.target.value})}}
        className={classes.selector}
        disableUnderline
      >
        <MenuItem value={'all'}>All Sources</MenuItem>
        {
          state.validSources && state.validSources.map(el=>(
            <MenuItem value={el.id}>
              {el.name}
            </MenuItem>
          ))
        }
      </Select>

      <SearchSelector 
        url={`/api/merges/search`}
        isCerebrum={true}
        clearable={true}
        params={{'object_name':searchValue,type:'CANDIDATE',per_page:20,source_id:state.mergeSourceFilter==='all'?undefined:state.mergeSourceFilter,object_type:state.mergeTargetType}}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        placeholder={`Search ${toTitleCase(state.mergeTargetType.replace(/_/g,' '))}(s) to link`}
        onResultClick={el=>onItemSelected(el)}
        renderResults={(rawItems)=>{
          let items = rawItems.filter(i=>!state.mergedItems || !state.mergedItems.find(m=>m.id===i.id))
          if(items.length===0){
            return [
              <div>
                <ListItem>
                  <div style={{display:'flex',alignItems:'center'}}>
                    <Typography style={{marginLeft:12,color:theme.palette.primaryText.main}}>No suggestions found</Typography>
                  </div>
                </ListItem>
              </div>
            ]
          }
          return items.map((item,index)=>(
            <UserSuggestionItem item={item} onClick={()=>onItemSelected(item)} itemSource="merge"/>
        ))}}
      />
      <Typography style={{marginTop:24,marginBottom:8,fontSize:16,color:theme.palette.primary.main}}>{toTitleCase(state.mergeTargetType.replace(/_/g,' '))}(s) to merge</Typography>
      {
        (!state.mergedItems || state.mergedItems.length===0) && 
        <Typography style={{color:theme.palette.primaryText.main}}>No items selected</Typography>
      }
      {
        state.mergedItems && state.mergedItems.map(el=>(
          <SimpleResultItem
            item={el}
            label={el.object.name}
            title={el.user.username+(el.user.username!==el.name?` (${el.name})`:'')}
            subTitle={el.user.account_type}
            footer={[el.source.name,...el.reference_sources.map(el=>el.reference_source.name)].join(', ')}
            hideRight={true}
            showUnderline
            tailObject={
              <IconButton onClick={()=>onRemoveItem(el)}>
                {getIconComponent({label:'clear',size:20,colour:theme.palette.primaryText.light})}
              </IconButton>
            }
          />
        ))
      }

      <Button color='primary' variant='contained' style={{marginTop:24}} onClick={()=>dispatch({type:'set_merge_tab_state',mergeTabState:3})} disabled={!state.mergedItems || state.mergedItems.length===0}>
        NEXT
      </Button>

    </div>
  )
}

export default withTheme()(withStyles(styles)(SelectTarget));