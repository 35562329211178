import React, { useEffect, } from 'react';
import { withTheme, CircularProgress, withStyles, Typography} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import { mapObjectName, titleCaseObjectName } from '../../../utilities'
import { usedObjects} from '../utils'
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import UsedList from './UsedList';
 
const styles = theme => ({
  root: {
    // display:'flex',
    // overflow:'hidden'
  },
})

const UsedTabLoader = props => {

  const {
    classes,
    history,
    state,
    dispatch,
    sessionData,
    headerTop
  } = props;

  const loadListTabs = async () => {
    dispatch({type:'set_used_list',usedListLoading:true})
    await axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related/objects`,
        {params:{
          relationship:'VIEWS',
          per_page:50,
          page:1,
        }}
      )
      .then(response=>{
        response.data.items = response.data.items.filter(el=>usedObjects.includes(el.name))
        if(response.data.items.length===0){
          dispatch({
            type:'set_used_list',
            usedListData:{}
          })
          return;
        }else{
          let objMap = {}
          response.data.items.forEach(i=>{
            objMap[i.name] = {}
          })
          dispatch({
            type:'set_used_list',
            usedListData:objMap,
          })
        }
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_used_list',usedListError:true})
      })
  }

  useEffect(()=>{
    if((!state.usedListData && !state.usedListLoading)){
      loadListTabs()
    }
    // eslint-disable-next-line
  },[])

  if(state.usedListLoading){
    return <CircularProgress color='secondary' />
  }
  if(state.usedListError){
    return <Typography>Error occurred loading items</Typography>
  }
  if(!state.usedListData)return <></>
  if(Object.keys(state.usedListData).length===0){
    return <Typography>No items found</Typography>
  }
  
  return (
    <div className={classes.root}>
      <div style={{float:'left'}}>
        <VerticalTabBar
          tabOptions={Object.keys(state.usedListData).map(el=>titleCaseObjectName(mapObjectName(el,true)).toUpperCase())}
          tabState={state.usedTabState}
          setTabState={value => {
            dispatch({ type: 'set_used_tab_state', usedTabState: value })
            window.scroll({top:0,behavior:'smooth'})
          }}
        />
      </div>
      <div style={{marginLeft:280,}}>
        <UsedList 
          key={state.usedTabState}
          state={state} 
          dispatch={dispatch} 
          sessionData={sessionData} 
          objectType={Object.keys(state.usedListData)[state.usedTabState]} 
          history={history}
          headerTop={headerTop}
        />
      </div>
    </div>
  )
}


export default withTheme()(withStyles(styles)(UsedTabLoader));