import React, { useEffect, useState } from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getIconComponent } from '../../../../utilities'; 
import axiosCerebrum from '../../../../axios-cerebrum';
import ListTile from './TilesComponents/ListTile';

const styles = theme => ({
  dataBox: {
    marginBottom: '2rem'
  },
  avatar: {
    backgroundColor: theme.palette.avatar.main,
    width:24,
    height:24,
    fontSize:12
  },
})

function JoinsTile(props) {

  const {
    theme,
    object,
    onAddTileData,
    tileData,
  } = props;

  const [loading, setLoading] = useState(false);

  const loadJoinsTile = () => {
    let target = object;
    if(object.object.name==='COLUMN')target = object.parent;
    const onOpenJoins = ()=>{
      window.postMessage({sidebar_action:'open_joins'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
    }
    setLoading(true)
    axiosCerebrum
      .get(
        `/api/tables/${target.id}/joins`,
        {params:{
          active_flag:true,
          object_active_flag:true,
          per_page:1,
        }}
      )
      .then(response=>{
        onAddTileData({
          "disp_body":[{
            name:'Table',
            count:response.data.total,
            labels:'table',
            onClick:onOpenJoins
          }],
          "box_action":[{
            action:onOpenJoins,
            text:'OPEN JOINS'
          }],
          "disp_title":"JOINS",
          "disp_type":"list",
          "id":"joins",
        })
        setLoading(false)
      })
      .catch(error=>{
        setLoading(false)
        console.log(error)
      })
  }

  useEffect(()=>{
    if(!tileData && !loading)loadJoinsTile()
  // eslint-disable-next-line
  },[])
  
  if(!tileData)return <></>

  return (
    <ListTile
      title={tileData.disp_title}
      list={
        tileData.disp_body.map(item => ({
          name:`${item.count} ${item.name}(s)`,
          onClick:item.onClick
        }))
      }
      icons={
        tileData.disp_body.map(item => {
          return getIconComponent({label: item.labels, size: 24,colour:theme.palette.primary.main})
        })
      }
      action={tileData.box_action}
      disableCollapse={true}
    />
  )

}

JoinsTile.propTypes = {
  classes: PropTypes.object.isRequired,
  boxData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(JoinsTile));