import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, CircularProgress, IconButton } from '@material-ui/core';
import {  getIconComponent, getInitials, isInViewport, setHelpWdigetVisibility } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import KTooltip from '../../UI/KTooltip/KTooltip';
import theme from '../../../theme';


const styles = theme => ({
  title: {
    fontSize: '1.25rem',
    color:theme.palette.header.main,
  },
  columnText:{
    fontSize:12,
    marginRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    color:theme.palette.primary.main,
    letterSpacing:1.5
  },
  listItemText: {
    fontSize: '13.75px',
    marginRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  listItem:{
    display:'flex',
    alignItems:'center',
    height:47,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    "&:hover":{
      background:theme.palette.hovered.main
    }
  },
  subTitle: {
    color: theme.palette.primaryText.light,
    fontSize: '11.78px',
  },
  avatar:{
    width:24,
    height:24,
    background:theme.palette.avatar.main,
    color:'#fff',
    borderRadius:12,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontSize:12,
  }
})

const TeamsBody = props => {

  const {
    classes,
    state,
    dispatch
  } = props;

  const scrollRef = useRef()
  const [buttonHovered, setButtonHovered] = useState(false)

  useEffect(()=>{
    setHelpWdigetVisibility(true)
    return ()=>{setHelpWdigetVisibility(false)}
  // eslint-disable-next-line
  },[])

  const loadTeams = ({page=1}) => {
    dispatch({
      type:'set_teams_data',
      teamsLoading:true,
      teamsData:page===1?undefined:state.teamsData
    })
    axiosCerebrum
      .get(
        '/api/groups',
        {params:{
          per_page:10,
          page,
          // sort:'ALPHABETICAL'
        }}
      )
      .then(response=>{
        dispatch({
          type:"set_teams_data",
          teamsData:
            page===1?response.data:{...response.data,items:[...state.teamsData.items,...response.data.items]}
        })
      })
      .catch(error=>{
        dispatch({
          type:'set_teams_data',
          teamsError:true,
        })
      })
  }

  useEffect(()=>{
    if(!state.teamsData && !state.teamsLoading){
      loadTeams({})
    }
  // eslint-disable-next-line
  },[])

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && state.teamsData && state.teamsData.page<state.teamsData.pages && !state.teamsLoading
  }

  window.onscroll = () => {
    if(shouldLoadMore())loadTeams({page:state.teamsData.page+1})
  }

  useEffect(()=>{
    if(shouldLoadMore())loadTeams({page:state.teamsData.page+1})
  // eslint-disable-next-line
  },[state.teamsData,state.teamsLoading])

  const editTeam = (item) => {
    dispatch({ type: 'set_tab_state', tabState: 2 })
    dispatch({ type: 'set_selected_team', selectedTeam: item })
  }

  const flexConfig = [
    '0 0 24px',
    '1 1 200px',
    '1 1 80px',
    '1 1 80px',
    '0 0 64px'
  ]

  return (
    <div style={{marginBottom:30}}>
      <div style={{marginBottom:24}}>
        <Typography  className={classes.title} >{state.teamsData?state.teamsData.total+' ':''}TEAM(S)</Typography> 
      </div> 
      {
        state.teamsData && state.teamsData.total > 0 && 
        <>
          <div style={{display:"flex",marginBottom:8}}>
            <div className={classes.columnText} style={{flex:flexConfig[0],marginLeft:16,marginRight:16}}>

            </div>
            <Typography className={classes.columnText} style={{flex:flexConfig[1]}}>
              NAME
            </Typography>
            <Typography className={classes.columnText} style={{flex:flexConfig[2]}}>
              STATUS
            </Typography>
            <Typography className={classes.columnText} style={{flex:flexConfig[3]}}>
              TEAM MEMBERS
            </Typography>
            <Typography className={classes.columnText} style={{flex:flexConfig[4]}}>
              ACTIONS
            </Typography>
          </div>
          {
            state.teamsData.items.map((item, index) => 
              <div className={classes.listItem} style={{background:buttonHovered?theme.palette.background.main:undefined}}>
                <div style={{flex:flexConfig[0],marginRight:16,marginLeft:16}}>
                  <div className={classes.avatar}>
                    {getInitials(item.name)}
                  </div>
                </div>
                <KTooltip title={item.name}>
                  <Typography className={classes.listItemText} style={{flex:flexConfig[1]}}>
                    {item.name}
                  </Typography>
                </KTooltip>
                <KTooltip title= {item.active_flag ? 'Active' : 'Deactivated'}>
                  <Typography className={classes.listItemText} style={{flex:flexConfig[2]}}>
                    {item.active_flag ? 'Active' : 'Deactivated'}
                  </Typography>
                </KTooltip>
                <Typography className={classes.listItemText} style={{flex:flexConfig[3]}}>
                  {item.user_count} 
                </Typography>
                <div style={{display:'flex',flex:flexConfig[4],alignItems:'center'}}>
                  <IconButton 
                    onClick={()=>editTeam(item)}
                    onMouseEnter={()=>setButtonHovered(true)}
                    onMouseLeave={()=>setButtonHovered(false)}
                  >
                    {getIconComponent({label:'edit',size:24,colour:theme.palette.primaryText.light})}
                  </IconButton>
                </div>
              </div>
            )
          }
        </>
      }
      <div style={{ marginTop: 16 }} ref={scrollRef}>
        {
          state.teamsLoading && 
          <div style={{ textAlign: 'center'}}>
            <CircularProgress color="secondary" />
          </div>
        }
      </div>
    </div>
  )
}

TeamsBody.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch:PropTypes.func.isRequired
}

export default withStyles(styles)(TeamsBody);