import React, { useReducer, useEffect, useState, useRef } from 'react';
import { withTheme, withStyles, Drawer, Typography, Button, CircularProgress, IconButton, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Checkbox } from '@material-ui/core';
import { getDispFields, getIconComponent, getLabelPlural, onClickResultItem, sendMessage } from '../../../utilities';
import axiosSolr from '../../../axios-solr'
import CodeDisplay from '../CodeDisplay/CodeDisplay';
import sqlFormatter from "sql-formatter";
import axiosCerebrum from '../../../axios-cerebrum';
import KTooltip from '../KTooltip/KTooltip';
import { useStore } from 'react-redux';
import ProfileSideBar from '../ProfileSideBar/ProfileSideBar';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDown';
import IconDataTile from '../ProfileSideBar/Tiles/TilesComponents/IconDataTile';
import ColumnMapper from './ColumnMapper/ColumnMapper';
import useAlert from '../../../hooks/useAlert';
import DrawerDragTrigger from '../DrawerDragTigger/DrawerDragTrigger';

const initialState = {

}

function reducer(state, action) {
  switch (action.type) {
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataError:action.basicDataError,
        basicDataLoading:action.basicDataLoading
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    flexGrow:1,
    marginRight:8
  },
  listItem:{
    width:'100%',
    boxSizing:'border-box',
    display:'flex',
    alignItems:'center',
    height:47,
    paddingLeft:8,
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  customScroll:{
    '&::-webkit-scrollbar':{
      width: '0px'
    },
  },
  overlineText:{
    letterSpacing:1,
    fontSize:12,
    color:theme.palette.primaryText.main,
  },
  drawerPaper:{
    borderLeft:`1px solid ${theme.palette.listItemDivider.main}`
  },
  /////////////////////////
  // expansion panel styles
  expansionTitle:{
    fontSize:16,
    paddingLeft:16,
    color:theme.palette.header.main
  },
  expansionPanel:{
    marginBottom:24,
    paddingBottom:0,
    borderRadius:`6px !important`,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    '&::before': {
      background: 'none'
    },
  },
  expansionSummary:{
    padding:0,
    width:'100%',
    // minHeight: 64,
  },
  expanded:{
    margin:0
  },
  summaryRoot:{
    // background:theme.palette.background.main,
    borderBottom: '0',
    // height: 64,
    padding: 0,
    borderRadius:6,
    overflow:'hidden',
    margin: 0,
    '&$expanded': {
      // height:64,
      padding: 0,
    },
  },
  expansionPanelDetails:{
    flexDirection:'column',
    background:theme.palette.background.main,
    paddingLeft:16,
    paddingRight:16,
    paddingTop:0,
    paddingBottom:0,
    borderRadius:12
  },
})


function LineageDetailDrawer(props) {
  const {
    classes,
    history,
    theme,
    drawerOpen,
    setDrawerOpen,
    selectedItem,
    nodes,
    linkedItem,
  } = props;


  const store = useStore();
  const sessionData = store.getState().auth.session_user;

  const [mapperModalOpen, setMapperModalOpen] = useState(false)
  
  const [queryListExpanded, setQueryListExpanded] = useState(true);
  const [queryDetailExpanded, setQueryDetailExpanded] = useState(true);
  const [queryList, setQueryList] = useState();
  const [queryListShowMore, setQueryListShowMore] = useState(false)
  const [selectedQueryIndex,setSelectedQueryIndex] = useState(0);
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const textAreaRef = useRef()
  const [state, dispatch] = useReducer(reducer, initialState);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  let initialWidth = 600;
  try{
    if(localStorage.getItem('linkDrawerWidth')){
      initialWidth = Number(localStorage.getItem('linkDrawerWidth')).valueOf() || 600;
    }
  }catch{}

  const [paperWidth, setPaperWidth] = React.useState(initialWidth)


  const onRemoveLink = () => {
    axiosCerebrum
      .delete(
        `/api/${getLabelPlural(selectedItem.data.obj.type)}/${selectedItem.data.obj.id}/related` + 
        `?object_id=${linkedItem.data.obj.id}&relationship=SOURCE_OF`
      )
      .then(response=>{
        setDrawerOpen(false)
        sendMessage({'reload_lineage':true})
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred removing the link', type: 'error'})
      })
  }

  useEffect(()=>{
    if(state.basicData && queryList && queryList.total>0 && queryList.items[selectedQueryIndex].id===state.basicData.id)return;
    dispatch({type:'set_insights_data'})
    if(queryList && queryList.items[selectedQueryIndex]){
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`id:${queryList.items[selectedQueryIndex].id}`,
              rows:1
            }
          }
        )
        .then(response=>{
          dispatch({type:'set_basic_data', basicData:{...(response.data.response.docs[0]||{}), ...queryList.items[selectedQueryIndex]}})
        })
        .catch(error=>{
          dispatch({type:'set_basic_data', basicData:{...queryList.items[selectedQueryIndex], object_type_txt:'CODE', code_type_txt:'QUERY'}})
        })
      
    }
    // eslint-disable-next-line
  },[selectedQueryIndex, queryList])

  const isEndCode = selectedItem && linkedItem && selectedItem.data.id===linkedItem.data.id;

  const loadQueries = ({page=1}) => {
    setQueryListExpanded(true)
    setLoading(true)
    setError(false)
    if(page===1){
      setQueryList()
      setSelectedQueryIndex(0)
    }
    axiosCerebrum
      .get(
        '/api/queries',
        {params:{
          lineage_upstream_id:isEndCode?undefined:selectedItem.data.obj.id,
          lineage_upstream_edges:isEndCode?undefined:'REFERENCES,K_REFERENCES,REFERENCES_SHORTCUT',
          lineage_downstream_id:linkedItem.data.obj.id,
          lineage_downstream_edges:'REFERENCES,K_REFERENCES,REFERENCES_SHORTCUT',
          per_page:5,
          active_flag:true,
          page,
          sort:'END_DESC'
        }}
      )
      .then(response=>{
        if(response.data.total!==0)setQueryListExpanded(false);
        let list = {
          ...response.data,
          items:[...(page===1?[]:queryList.items),...response.data.items]
        }
        if(response.data.page>=response.data.pages)setQueryListShowMore(true)
        setQueryList(list);
        setLoading(false);
      })
      .catch(error=>{
        console.log(error);
        setLoading(false);
        setError(true);
      })
  }

  useEffect(()=>{
    if(!drawerOpen || !selectedItem || !linkedItem)return;
    if(!isEndCode && !drawerOpen.data?.shouldLoadQuery)return;
    loadQueries({})
    // eslint-disable-next-line
  },[selectedItem,linkedItem,drawerOpen])

  const onClickSeeMore = () => {
    if(queryList && queryList.page<queryList.pages && !loading){
      loadQueries({page:queryList.page+1});
    }else{
      setQueryListShowMore(!queryListShowMore)
    }
  }

  const onCopyCode = () => {
    textAreaRef.current.select();
    document.execCommand('copy');
    sendAlert({ message: 'Copied code to clipboard', type: 'info'})
  }

  const getPorcessedCode = code => {
    let formattedCode = sqlFormatter.format(code,{language:'pl/sql'});
    let lines = formattedCode.split('\n');
    return {value:lines.length>30?lines.slice(0,30).join('\n')+"\n...":lines.join('\n'),isOverflow:lines.length>30}
  }
  
  const getTitle = () => {
    if(isEndCode){
      return `${selectedItem.data.obj?.name_txt} upstream Code`
    }else{
      return `${selectedItem.data.obj?.name_txt} to ${linkedItem.data.obj?.name_txt}`
    }
  }

  useEffect(() => {
    const handleEsc = (event) => {
      // check if key is 'ESC`
      if (event.key==='Escape') {
        setDrawerOpen(false)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    // eslint-disable-next-line
  }, [])

  if(!selectedItem || !linkedItem)return <></>;

  const enableMap = (
    ((selectedItem.data.obj?.type||selectedItem.data.obj?.object_type_txt)==='TABLE' && (linkedItem.data.obj?.type||linkedItem.data.obj?.object_type_txt)==='TABLE') || 
    (['TABLE','DATASET_TABLE'].every(d=>[(selectedItem.data.obj?.type||selectedItem.data.obj?.object_type_txt),(linkedItem.data.obj?.type||linkedItem.data.obj?.object_type_txt)].find(e=>e===d))) || 
    ((selectedItem.data.obj?.type||selectedItem.data.obj?.object_type_txt)==='DATASET_TABLE' && (linkedItem.data.obj?.type||linkedItem.data.obj?.object_type_txt)==='DATASET_TABLE')
  )
  
  const isReplace = selectedItem.relationship?.includes('REPLACE') || linkedItem.relationship?.includes('REPLACE')
  
  return (
    <>
      {
        enableMap && 
        <ColumnMapper
          source={selectedItem}
          sourceParent={selectedItem.parentNode && nodes && nodes.find(c=>c.id===selectedItem.parentNode)}
          target={linkedItem}
          targetParent={linkedItem.parentNode && nodes && nodes.find(c=>c.id===linkedItem.parentNode)}
          modalOpen={mapperModalOpen}
          setModalOpen={setMapperModalOpen}
        />
      }
      <Drawer
        anchor="right" 
        open={drawerOpen}  
        classes={{paper:classes.drawerPaper}} 
        onClose={()=>setDrawerOpen(false)} 
        variant="persistent"
        disabl
      >
        {
          selectedItem && linkedItem && 
          <>
            <DrawerDragTrigger
              onChangeWidthOffset={diff=>{
                setPaperWidth(paperWidth+diff)
                localStorage.setItem('linkDrawerWidth',paperWidth+diff)}}
            />
            <div style={{height:'100vh',width:paperWidth,minWidth:450,maxWidth:'90vw',padding:'0 24px',display:'flex',flexDirection:'column',overflow:'auto',background:theme.palette.background.main}}>
              <div style={{flexShrink:0,flexGrow:0}}>
                <div style={{display:'flex',marginTop:24,marginBottom:12,justifyContent:"space-between",alignItems:'flex-start'}}>
                  {
                    enableMap && !isReplace && selectedItem.id!==linkedItem.id ?
                    <Button data-test-id="map-column-button" disableFocusRipple color='primary' variant='outlined' onClick={()=>setMapperModalOpen(true)}>MAP COLUMNS</Button>:
                    <div></div>
                  }
                  <IconButton 
                    onClick={()=>setDrawerOpen(false)} 
                    style={{width:36,height:36}}
                  >
                    {
                      getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})
                    }
                  </IconButton>
                </div>
                <KTooltip title={getTitle()}>
                  <Typography className={classes.title}>{getTitle()}</Typography>
                </KTooltip>
              </div>
              <div className={classes.customScroll} style={{marginTop:24,flexGrow:1,flexShrink:1,marginBottom:24,overflow:'auto'}}>
                {
                  !drawerOpen.data?.isManual && (drawerOpen.data?.shouldLoadQuery || isEndCode) && 
                  <div style={{marginBottom:40}}>
                    <ExpansionPanel onChange={(event,expanded)=>setQueryListExpanded(expanded)} expanded={queryListExpanded} elevation={0} className={classes.expansionPanel } >
                      <ExpansionPanelSummary onClick={undefined} classes={{expanded: classes.expanded,root:classes.summaryRoot}}  expandIcon={<ExpandMoreIcon style={{color:theme.palette.primaryText.main}} />}  className={classes.expansionSummary}>
                        <Typography className={classes.expansionTitle}>
                          Lineage selector
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                        <Typography style={{fontSize:12,letterSpacing:1,marginBottom:8}}>MOST RECENT CODE RUN</Typography>
                        <div style={{paddingBottom:24}}>
                          {
                            queryList && queryList.total===0 && <Typography style={{color:theme.palette.primaryText.main,marginTop:16}}>No code found</Typography>
                          }
                          <div>
                            {
                              queryList && (queryListShowMore || queryList.page<queryList.pages?queryList.items:queryList.items.slice(0,3)).map((el,index)=>(
                                <div onClick={()=>{setSelectedQueryIndex(index);setQueryListExpanded(false)}} className={classes.listItem}>
                                  <Checkbox
                                    color='primary'
                                    checked={index===selectedQueryIndex}
                                  />
                                  <Typography style={{color:theme.palette.primaryText.main,fontSize:13.75,marginLeft:8}}>
                                    {getDispFields(el, 'dispTitle')}
                                  </Typography>
                                </div>
                              ))
                            }
                          </div>
                          {
                            loading && <CircularProgress color='secondary'/>
                          }
                          {
                            error && <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading code</Typography>
                          }
                          {
                            queryList && queryList.pages>1 && !loading &&
                            <Button color='primary' onClick={()=>onClickSeeMore()} style={{marginTop:4,marginLeft:10}}>
                              SEE {queryListShowMore?'LESS':'MORE'}
                            </Button>
                          }
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                }
                
                {
                  queryList && queryList.total>0 && (drawerOpen.data?.shouldLoadQuery || isEndCode) && 
                  <div >
                    <ExpansionPanel defaultExpanded={true} onChange={(event,expanded)=>setQueryDetailExpanded(expanded)} expanded={queryDetailExpanded} elevation={0} className={classes.expansionPanel } >
                      <ExpansionPanelSummary onClick={undefined} classes={{expanded: classes.expanded,root:classes.summaryRoot}}  expandIcon={<ExpandMoreIcon style={{color:theme.palette.primaryText.main}} />}  className={classes.expansionSummary}>
                        <div style={{overflow:'hidden'}}>
                          <Typography className={classes.expansionTitle}>Lineage details</Typography>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                        <div style={{paddingBottom:24}}>
                          <div style={{display:'flex',alignItems:'center',overflow:'hidden',justifyContent:'space-between',marginBottom:24}}>
                            <div style={{flexGrow:1,overflow:"hidden",marginRight:8}}>
                              <Typography style={{fontSize:16,color:theme.palette.primary.main,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}>
                                {getDispFields(queryList.items[selectedQueryIndex], 'dispTitle')}
                              </Typography>
                              <KTooltip placement="bottom-start" title={queryList.items[selectedQueryIndex].parent?.location}>
                                <Typography style={{color:theme.palette.primaryText.light,fontSize:12,marginTop:2,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                                  {queryList.items[selectedQueryIndex].parent?.location}
                                </Typography>
                              </KTooltip>
                            </div>
                            <IconButton 
                              onClick={()=>onClickResultItem({label:queryList.items[selectedQueryIndex].object.name,id:queryList.items[selectedQueryIndex].id,newWindow:true,history})} 
                              style={{width:36,height:36}}
                            >
                              {
                                getIconComponent({label:'open',size:24,colour:theme.palette.primaryText.light})
                              }
                            </IconButton>
                          </div>
                          {
                            state.basicData && 
                            <ProfileSideBar
                              tabOptions={[]}
                              history={history}
                              state={state}
                              dispatch={dispatch}
                              mapping={
                                ['sourceType','lastRun','runs']
                              }
                              fetchList={()=>{}}
                              sessionData={sessionData}
                              cerebrumLabel={getLabelPlural(state.basicData.object.name, true)}
                              data={state.basicData}
                              ignoreGrouping
                            />
                          }
                          {
                            <div >
                              <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:8,marginTop:-8}}>
                                <Typography className={classes.overlineText}>CODE SNIPPET (FIRST 30 LINES SHOWN)</Typography>
                                <IconButton 
                                  onClick={onCopyCode} 
                                  style={{width:36,height:36}}
                                >
                                  {
                                    getIconComponent({label:'copy',size:24,colour:theme.palette.primaryText.light})
                                  }
                                </IconButton>
                              </div>
                              <CodeDisplay code={getPorcessedCode(queryList.items[selectedQueryIndex].code || '').value}/>
                              <form style={{ opacity: 0, height: 0 }}>
                                <textarea
                                  ref={textAreaRef}
                                  value={sqlFormatter.format(queryList.items[selectedQueryIndex].code || '' ,{language:'pl/sql'})}
                                />
                              </form>
                            </div>
                          }
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                }

                {
                  drawerOpen.data?.isManual && 
                  <div >
                    <ExpansionPanel defaultExpanded={true} onChange={(event,expanded)=>setQueryDetailExpanded(expanded)} expanded={queryDetailExpanded} elevation={0} className={classes.expansionPanel } >
                      <ExpansionPanelSummary onClick={undefined} classes={{expanded: classes.expanded,root:classes.summaryRoot}}  expandIcon={<ExpandMoreIcon style={{color:theme.palette.primaryText.main}} />}  className={classes.expansionSummary}>
                        <div style={{overflow:'hidden'}}>
                          <Typography className={classes.expansionTitle}>Lineage details</Typography>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                        <IconDataTile
                          title={'TYPE'}
                          text={'Manual link'}
                          left={getIconComponent({label: 'default', size: 24, colour: theme.palette.primaryText.light}) || getIconComponent({label: 'info', size: 24, colour: theme.palette.primary.main})}
                        />
                      </ExpansionPanelDetails>
                      <Button onClick={()=>onRemoveLink()} color='primary' variant='contained' style={{margin:16}}>REMOVE LINK</Button>
                    </ExpansionPanel>
                  </div>
                }

                {
                  drawerOpen.data?.isAuto && !drawerOpen.data?.shouldLoadQuery && 
                  <div >
                    <ExpansionPanel defaultExpanded={true} onChange={(event,expanded)=>setQueryDetailExpanded(expanded)} expanded={queryDetailExpanded} elevation={0} className={classes.expansionPanel } >
                      <ExpansionPanelSummary onClick={undefined} classes={{expanded: classes.expanded,root:classes.summaryRoot}}  expandIcon={<ExpandMoreIcon style={{color:theme.palette.primaryText.main}} />}  className={classes.expansionSummary}>
                        <div style={{overflow:'hidden'}}>
                          <Typography className={classes.expansionTitle}>Lineage details</Typography>
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                        <IconDataTile
                          title={'TYPE'}
                          text={'Automated lineage'}
                          left={getIconComponent({label: 'lineage_up_downstream', size: 24, colour: theme.palette.primaryText.light})}
                        />
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                }
              </div>
            </div>
          </>
        }
        
      </Drawer>
    </>
  )
}

export default withTheme()(withStyles(styles)(LineageDetailDrawer));
