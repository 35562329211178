import React, { useEffect, useRef } from 'react';
import { withStyles, withTheme, Button, Stepper, StepLabel, Step} from '@material-ui/core';
import Details from '../Details/Details'
import Recipients from '../Recipients/Recipients';
import Preview from '../Preview/Preview'
import axiosCerebrum from '../../../../axios-cerebrum'
import 'url-search-params-polyfill';
import moment from 'moment';
import { validateUrl } from '../../../../utilities';
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  root: {

  },
  labelText: {
    color:theme.palette.primaryText.light
  },
  selectedLabelText:{
    color:`${theme.palette.primary.main} !important`,
    fontWeight:'400 !important'
  },
  stepIcon:{
    cursor:'pointer'
  },
  stepper:{
    marginBottom:20
  },
  step:{
    '& svg':{
      color:theme.palette.primaryText.light
    },
  }
  
})

const Body = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    history
  } = props;

  const isCancelledRef = useRef(false)

  useEffect(()=>{ 
    return () => {
      isCancelledRef.current = true
    }
  },[])

  const {
    sendAlert
  } = useAlert({
    id:`ia-notify`,
    isCancelledRef,
    disableStateCache:true
  })

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.notifyStep])

  const onResetNotificationTemplate = () => {
    dispatch({type:'set_notification_emails_manual',notificationEmailsManual:[] });
    dispatch({type:'set_notification_users_manual',notificationUsersManual:[]});
    dispatch({type:'set_notification_users',notificationUsers:[]});
    dispatch({type:'set_notification_input',notificationInput:{title:'',description:'',impact:''}})
    dispatch({type:'set_notify_step',notifyStep:0})
    dispatch({type:'set_selected_email_template',selectedEmailTemplate:'none'})
  }

  const loadEmailTemplates = () => {
    axiosCerebrum
      .get(
        `/api/notifications/email/templates`,{
          params:{
            category:'IMPACT',
            per_page:10
          }
        }
      )
      .then(response=>{
        dispatch({type:'set_email_templates',emailTemplates:response.data.items})
      })
      .catch(error=>{
        console.log(error)
      })
  }
  


  useEffect(()=>{
    if(state.emailTemplates){
      loadEmailTemplates()
    }
    history.push(window.location.pathname+window.location.search)
    return ()=>{
      window.onpopstate=undefined;
      onResetNotificationTemplate()
    }
    // eslint-disable-next-line
  },[])

  window.onpopstate = () => {
    if(state.notifyStep===0){
      dispatch({type:'set_tab_state',tabState:2})
    }else{
      history.push(window.location.pathname)
      dispatch({type:'set_notify_step',notifyStep:state.notifyStep-1})
    }
    
  }

  let bodyContent;

  switch(state.notifyStep){
    case 0:
      bodyContent = (
        <Details
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    case 1:
      bodyContent = (
        <Recipients 
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    case 2:
      bodyContent = (
        <Preview 
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    default:
  }

  const onCancel = () => {
    history.goBack();
  }

  const requiredFilled = (step) => {
    if(step===0){
      return state.notificationInput.title.trim()!=='' && 
            state.notificationInput.description.trim()!=='' && 
            state.notificationInput.contact && 
            state.selectedEmailTemplate!=='none' && 
            state.notificationInput.impact.trim()!=='' && 
            state.notificationInput.date && 
            (!state.notificationInput.link || state.notificationInput.link.trim()==='' || validateUrl(state.notificationInput.link)===true)
    }
    if(step===1){
      return state.notificationUsers.length>0 || state.notificationEmailsManual.filter(el=>el.selected).length>0 || state.notificationUsersManual.filter(el=>el.selected).length>0 ;
    }
    return true;
  }

  const onButtonClick = (step) => {
    if(step===2){
      axiosCerebrum
        .post(
          '/api/notifications/email',
          {
            "recipient_user_ids": [...state.notificationUsers,...state.notificationUsersManual.filter(el=>el.selected).map(el=>el.id)],
            "additional_recipient_emails": state.notificationEmailsManual.filter(el=>el.selected).map(el=>el.id),
            "template_id": state.selectedEmailTemplate,
            "template_data": {
              "extract_id": state.extractData.id,
              "title": state.notificationInput.title,
              "business_impact":state.notificationInput.impact,
              "description": state.notificationInput.description.replaceAll("\n",'<br/>'),
              "date": moment(state.notificationInput.date).format('YYYY-MM-DD'),
              "status":state.notificationInput.status || '',
              "link": state.notificationInput.link || '',
              "contact": state.notificationInput.contact,
              'object_id':state.historyObject?.id
            },
          }
        )
        .then(response=>{
          sendAlert({type:'info',message:"Notification successfully sent"})
          dispatch({type:'set_tab_state',tabState:2})
        })
        .catch(error=>{
          sendAlert({type:'error',message:"Error occurred sending notification"})
        })
    }else{
      dispatch({type:'set_notify_step',notifyStep:step+1})
    }
  }

  const labelClickable = step => {
    if(step===0)return true;
    if(step===1)return requiredFilled(0);
    if(step===2)return requiredFilled(0) && requiredFilled(1);
  }


  return (
    <div className={classes.root}>
      <Stepper className={classes.stepper} activeStep={state.notifyStep} alternativeLabel>
        {['Add notification details','Confirm contact list','Preview & Send'].map((label,index) => (
          <Step  className={classes.step}  key={label} completed={false}>
            <StepLabel 
              onClick={()=>{if(labelClickable(index)){dispatch({type:'set_notify_step',notifyStep:index})}}}
              classes={{
                active:classes.selectedLabelText,
                iconContainer:classes.stepIcon,
                label:classes.labelText
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {bodyContent}
      <div style={{height:120}}></div>
      {
        <div style={{ left:0,right: 0, height:80, borderTop:`1px solid ${theme.palette.listItemDivider.main}`, backgroundColor: theme.palette.background.main, position: 'fixed', bottom: 0,display:'flex',alignItems:'center',justifyContent:'flex-end' }}>
          {
            state.notifyStep>0 &&
            <Button color='primary' onClick={()=>dispatch({type:'set_notify_step',notifyStep:state.notifyStep-1})} style={{marginLeft:30,width:120,height:48,marginRight:8}}>
              BACK
            </Button>
          }
          <Button style={{width:120,height:48,color:theme.palette.primaryText.light,marginRight:8}} data-testid = 'reset' onClick={onCancel}>
            CANCEL
          </Button>
          <Button color='primary' variant='contained' disabled={!requiredFilled(state.notifyStep)} onClick={()=>onButtonClick(state.notifyStep)} style={{marginLeft:30,width:120,height:48,marginRight:'15%'}}>
            {state.notifyStep===2?'SEND':'NEXT'}
          </Button>
        </div>
      }
      
    </div>
  )
}

export default withTheme()(withStyles(styles)(Body));