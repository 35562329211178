import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Select, MenuItem, Checkbox, InputBase, Typography, Popper, Button, Paper } from '@material-ui/core';
import { autoCollectionShortNames, getIconComponent, platformCollectionShortNames, toTitleCase } from '../../../../utilities';
import ToggleButton from '../../ToggleButton/ToggleButton';

const styles = theme => ({
  root:{
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    flexWrap:'wrap',
    maxWidth:'100%',
    // height:34,
    flexGrow:1,
    flexShrink:0,
    // width:'max-content',
    marginRight:24
  },
  selector: {
    ...theme.components.titleSelector,
    height:24,
    borderRadius:12,
    maxWidth:180,
    '& div div':{
      fontSize:13,
      width:'max-content',
      maxWidth:146,
      padding:'1px 24px 0px 8px'
    }
  },
  button:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    display:'block',
    textTransform:'none',
    padding:0,
    marginTop:2,
    marginLeft:2,
    minWidth:0,
    paddingLeft:6,
    paddingRight:6,
    '& span':{
      fontSize:12
    }
  },
  inputBase:{
    ...theme.components.inputBase,
    width:'100%',
    height:42,
    borderBottom:`1px solid ${theme.palette.primaryText.main}`,
    marginBottom:8
  },
  selectPaper:{
    padding:0,
    minWidth:240
  },
  buttonLabel:{
    fontSize:12,
    lineHeight:'normal',
    textTransform:'none',
  },
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    marginLeft:8,
    marginTop:20,
    letterSpacing:1,
    marginBottom:4
  },
  popperPaper:{
    maxHeight:'60vh',
    overflow:'auto',
    ...theme.components.customScroll
  }
})

function ViewSelector(props) {
  const {
    classes,
    theme,
    focusView,
    onChangeFocus,
    subFocusView,
    onChangeSubFocus,
    onToggleType,
    onToggleInclude,
    type, //and/or
    isExclude,
    nodes
  } = props;

  const [subFocusSearch, setSubFocusSearch] = useState('')

	const [anchor, setAnchor] = useState()
  const [subAnchor, setSubAnchor] = useState()
  const [hoveredFocus, setHoveredFocus] = useState()
  const popperExitTimeout = useRef()

  const getDefaultSubFocus = value => {
    if(value==='none'){
      return [];
    }
    return generateSubOptions(value).map(el=>el.value)
  }

  const generateSubOptions = (forceFocusView) => {
    let view = forceFocusView ;
    if(view==='none')return;
    let objectNodes = nodes.filter(n=>n.type==='KLineageNode')
    let options = [];
    if(view==='trust'){
      if(objectNodes.find(n=>n.data?.obj?.trust==='HIGHEST'))options.push({ value: 'HIGHEST', label: 'HIGHEST' })
      if(objectNodes.find(n=>n.data?.obj?.trust==='HIGH'))options.push({ value: 'HIGH', label: 'HIGH' })
      if(objectNodes.find(n=>n.data?.obj?.trust==='MEDIUM'))options.push({ value: 'MEDIUM', label: 'MEDIUM' })
      if(objectNodes.find(n=>n.data?.obj?.trust==='LOW'))options.push({ value: 'LOW', label: 'LOW' })
      if(objectNodes.find(n=>n.data?.obj?.trust==='LOWEST'))options.push({ value: 'LOWEST', label: 'LOWEST' })
      if(objectNodes.find(n=>n.type==='KLineageNode' && !n.data?.obj?.trust))options.push({ value: 'NONE', label: 'NO TRUST CALCULATED' })
    }
    if(view==='classification'){
      let classifications = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.classification_kc_txts){
          n.data?.obj?.classification_kc_txts.forEach(c=>{
            if(!classifications.find(cl=>cl===c))classifications.push(c)
          })
        }
      })
      options = classifications.map(c=>({value:c,label:c}))
      if(objectNodes.find(n=>n.type==='KLineageNode' &&  !n.data?.obj?.classification_kc_txts))options.push({value:'NONE',label:'NO CLASSIFICATION'})
    }
    if(view==='domain'){
      let domains = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.domain_kc_txts){
          n.data?.obj?.domain_kc_txts.forEach(d=>{
            if(!domains.find(dm=>dm===d))domains.push(d)
          })
        }
      })
      options = domains.map(d=>({value:d,label:d}))
      if(objectNodes.find(n=>n.type==='KLineageNode' && !n.data?.obj?.domain_kc_txts))options.push({value:'NONE',label:'NO DOMAIN'})
    }
    if(view==='category'){
      let categories = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.category_kc_txts){
          n.data?.obj?.category_kc_txts.forEach(c=>{
            if(!categories.find(ct=>ct===c))categories.push(c)
          })
        }
      })
      options = categories.map(c=>({value:c,label:c}))
      if(objectNodes.find(n=>n.type==='KLineageNode' && !n.data?.obj?.category_kc_txts))options.push({value:'NONE',label:'NO CATEGORY'})
    }
    if(view==='verified'){
      let verified = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.verified_kc_txts){
          n.data?.obj?.verified_kc_txts.forEach(v=>{
            if(!verified.find(vr=>vr===v))verified.push(v)
          })
        }
      })
      options = verified.map(v=>({value:v,label:v}))
      if(objectNodes.find(n=>n.type==='KLineageNode' && !n.data?.obj?.verified_kc_txts))options.push({value:'NONE',label:'NO USE CASE'})
    }
    if(view==='not_verified'){
      let notVerified = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.not_verified_kc_txts){
          n.data?.obj?.not_verified_kc_txts.forEach(v=>{
            if(!notVerified.find(vr=>vr===v))notVerified.push(v)
          })
        }
      })
      options = notVerified.map(v=>({value:v,label:v}))
      if(objectNodes.find(n=>n.type==='KLineageNode' && !n.data?.obj?.not_verified_kc_txts))options.push({value:'NONE',label:'NO USE CASE'})
    }
    if(view==='dq_score'){
      for(let i=0; i<9; i++){
        let low = i*10;
        let high = i*10+(i===9?10:9);
        if(objectNodes.find(n=>n.data?.obj?.dq_score_overall_srt>=low && n.data?.obj?.dq_score_overall_srt<=high)){
          options.push({value:`${low} TO ${high}`,label:`${low} TO ${high}`})
        }
      }
      if(objectNodes.find(n=>isNaN(n.data?.obj?.dq_score_overall_srt)))options.push({value:'NONE',label:'UNKNOWN'})
    }
    if(view==='dq_failed'){
      options.push({value:'last_7d',label:'FAILED (LAST 7D)'})
    }
    if(view==='owned_txt'){
      if(objectNodes.find(n=>n.data?.obj?.owned_txt==='IS_OWNED'))options.push({value:'IS_OWNED',label:'OWNED'})
      if(objectNodes.find(n=>n.data?.obj?.owned_txt==='NOT_OWNED'))options.push({value:'NOT_OWNED',label:'NOT OWNED'})
    }
    if(view==='steward_count'){
      if(objectNodes.find(n=>n.data?.obj?.steward_count===0))options.push({value:'NONE',label:'NONE'})
      if(objectNodes.find(n=>n.data?.obj?.steward_count===1))options.push({value:'ONE',label:'ONE'})
      if(objectNodes.find(n=>n.data?.obj?.steward_count>1))options.push({value:'MANY',label:'MANY'})
    }
    if(view==='documented'){
      let documented = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.documented_txt && !documented.find(t=>t===n.data?.obj?.documented_txt)){
          documented.push(n.data?.obj?.documented_txt)
        }
      })
      options = documented.map(t=>({value:t,label:t.replace(/_/g,' ')}))
    }
    if(view==='issue_count'){
      if(objectNodes.find(n=>n.data?.obj?.number_of_issues_srt===0))options.push({value:'NONE',label:'NONE'})
      if(objectNodes.find(n=>n.data?.obj?.number_of_issues_srt===1))options.push({value:'ONE',label:'ONE'})
      if(objectNodes.find(n=>n.data?.obj?.number_of_issues_srt>1))options.push({value:'MANY',label:'MANY'})
    }
    if(view==='usage_txt'){
      let usages = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.usage_txt){
          if(!usages.includes(n.data?.obj?.usage_txt))usages.push(n.data?.obj?.usage_txt)
        }
      })
      let usageSeq = ['DAILY','WEEKLY','MONTHLY','INFREQUENT','UNUSED']
      options = usages.map(d=>({value:d,label:d})).sort((a,b)=>usageSeq.indexOf(a.value)-usageSeq.indexOf(b.value))
      if(objectNodes.find(n=>n.type==='KLineageNode' && !n.data?.obj?.usage_txt))options.push({value:'NONE',label:'NO USAGE'})
    }
    if(view==='load_frequency_txt'){
      let usages = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.load_frequency_txt){
          if(!usages.includes(n.data?.obj?.load_frequency_txt))usages.push(n.data?.obj?.load_frequency_txt)
        }
      })
      let usageSeq = ['DAILY','WEEKLY','MONTHLY','INFREQUENT','UNUSED']
      options = usages.map(d=>({value:d,label:d})).sort((a,b)=>usageSeq.indexOf(a.value)-usageSeq.indexOf(b.value))
      if(objectNodes.find(n=>n.type==='KLineageNode' && !n.data?.obj?.load_frequency_txt))options.push({value:'NONE',label:'NO LOAD FREQUENCY'})
    }
    // instance options
    if(view.includes('_kc_txts')){
      let instances = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.[view]){
          n.data?.obj?.[view].forEach(v=>{
            if(!instances.find(c=>c===v))instances.push(v)
          })
        }
      })
      options = instances.map(v=>({value:v,label:v}))
      if(objectNodes.find(n=>!n.data?.obj?.[view]))options.push({value:'NONE',label:'NO VALUE'})
    }
    return options;
  }

  const generateOptions = () => {
    let options = [];
    let objectNodes = nodes?.filter(n=>n.type==='KLineageNode')
    if(!objectNodes || objectNodes.length===0)return options;
    if(objectNodes.find(n=>n.data?.obj?.trust))options.push({ value: 'trust', label: 'Trust' })
    if(objectNodes.find(n=>n.data?.obj?.classification_kc_txts))options.push({ value: 'classification', label: 'Classification' })
    if(objectNodes.find(n=>n.data?.obj?.domain_kc_txts))options.push({ value: 'domain', label: 'Domain' })
    if(objectNodes.find(n=>n.data?.obj?.category_kc_txts))options.push({ value: 'category', label: 'Category' })
    if(objectNodes.find(n=>n.data?.obj?.verified_kc_txts))options.push({ value: 'verified', colour:theme.palette.success.main, label: 'Verified for' })
    if(objectNodes.find(n=>n.data?.obj?.not_verified_kc_txts))options.push({ value: 'not_verified',colour:theme.palette.error.main,  label: 'Not verified for' })
    if(objectNodes.find(n=>!isNaN(n.data?.obj?.dq_score_overall_srt)))options.push({ value: 'dq_score', label: 'DQ Overall Score' })
    if(objectNodes.find(n=>n.data?.obj?.data_quality_failed_txt==='YES'))options.push({ value: 'dq_failed', label: 'DQ Issues last 7d' })
    if(objectNodes.find(n=>n.data?.obj?.owned_txt==='IS_OWNED'))options.push({ value: 'owned_txt', label: 'Owned' })
    if(objectNodes.find(n=>n.data?.obj?.steward_count!==undefined))options.push({ value: 'steward_count', label: 'Steward' })
    if(objectNodes.find(n=>n.data?.obj?.documented_txt))options.push({ value: 'documented', label: 'Documented' })
    if(objectNodes.find(n=>n.data?.obj?.number_of_issues_srt!==undefined))options.push({ value: 'issue_count', label: 'Issues' })
    if(objectNodes.find(n=>n.data?.obj?.usage_txt!==undefined))options.push({ value: 'usage_txt', label: 'Usage' })
    if(objectNodes.find(n=>n.data?.obj?.load_frequency_txt!==undefined))options.push({ value: 'load_frequency_txt', label: 'Load Frequency' })    
    return options.sort((a,b)=>a.label<b.label?-1:1);
  }

  const generateDataGovMgmOptions = () => {
    let options = [];
    let objectNodes = nodes.filter(n=>n.type==='KLineageNode')
    objectNodes.forEach(el=>{
      let collectionKeys = Object.keys(el.data?.obj||{}).filter(el=>el.includes('_kc_txts'));
      collectionKeys = collectionKeys.filter(el=>![...autoCollectionShortNames,...platformCollectionShortNames].includes(el.replace(/_kc_txts/g,'').replace(/_/g,' ')))
      collectionKeys.forEach(key=>{
        if(!options.find(o=>o.value===key))options.push({value:key,label:key.replace(/_kc_txts/g,'').replace(/_/g,' ')})
      })
    })
    options.sort((a,b)=>a.label<b.label?-1:1)
    return options;
  }

  const onUnselectAll = (event, focus) => {
    event.stopPropagation();
    event.preventDefault();
    if(subFocusView[focus].length===0){
      onChangeSubFocus(focus,getDefaultSubFocus(focus))
      return;
    }
    onChangeSubFocus(focus,[])
  }

  const getOptionMenuItem = o => {
    return (
      <MenuItem 
        key={o.value}
        onMouseEnter={(event)=>{
          setSubAnchor(event.currentTarget)
          setHoveredFocus(o.value)
        }}
        onClick={()=>{
          if(focusView.includes(o.value)){
            onChangeSubFocus(o.value,[])
            onChangeFocus(focusView.filter(f=>f!==o.value))
          }else{
            onChangeSubFocus(o.value,getDefaultSubFocus(o.value))
            onChangeFocus([...focusView,o.value])
          }
        }}
        selected={hoveredFocus===o.value}
      >
        <Checkbox key={focusView} color='primary' style={{paddingLeft:0}} checked={focusView.includes(o.value)}/> 
        <span style={{flexGrow:1}}>{o.label}</span>
        {
          o.colour && 
          <div style={{width:20,height:20,marginRight:12,background:o.colour}}></div>
        }
        {getIconComponent({label:'triangle_right',size:24,colour:theme.palette.primaryText.light})}
      </MenuItem>
    )
  }

  let options = generateOptions()
  let instanceOptions = generateDataGovMgmOptions()
  let allOptions = options.concat(instanceOptions)

  if(!nodes || nodes.length===0)return <div style={{marginLeft:200}}></div>
  return (
    <div className={classes.root} >
      <Typography style={{letterSpacing:1,marginRight:8,color:theme.palette.primary.main,fontSize:12,marginBottom:6}}>FILTER{focusView?.length>0?` (${type.toUpperCase()})`:''}: </Typography>
      {
        focusView?.length>0 && 
        focusView.filter(f=>allOptions.find(o=>o.value===f)).map(f=>(
          <Select
            className={classes.selector}
            style={{
              marginRight:16,
              marginBottom:6, 
              borderColor: allOptions.find(o=>o.value===f).colour,
              color: allOptions.find(o=>o.value===f).colour,
            }}
            value={subFocusView[f]}
            disableUnderline
            renderValue={value=>{
              let fieldName = allOptions.find(o=>o.value===f).label.toUpperCase()
              if(value.length===0)return `${fieldName}: None`
              let selectedText = value.map(v=>generateSubOptions(f).find(option=>option.value===v)?.label?.toUpperCase()).filter(el=>el).map(el=>toTitleCase(el)).join(', ');
              if(value.length===generateSubOptions(f).length)selectedText='All'
              return `${toTitleCase(fieldName)}: ${selectedText}`
            }}
            onClick={()=>{
              setSubFocusSearch('')
            }}
            displayEmpty
            multiple
            MenuProps={{
              MenuListProps:{
                classes:{
                  root:classes.selectPaper
                }
              }
            }}
          >
            <InputBase
              value={subFocusSearch}
              onChange={event=>setSubFocusSearch(event.target.value)}
              className={classes.inputBase}
              placeholder={`Search ${allOptions.find(option=>option.value===f).label}`}
            />
            <div style={{display:'flex',padding:'0 8px',marginBottom:8}}>
              <Typography style={{color:theme.palette.primary.main,fontSize:12,letterSpacing:1}}>
                {subFocusView[f].filter(s=>generateSubOptions(f).find(c=>c.value===s)).length} SELECTED
              </Typography>
              <div style={{flexGrow:1}}></div>
              <Typography onClick={event=>onUnselectAll(event,f)} style={{color:theme.palette.secondary.main,fontSize:12,letterSpacing:1,cursor:'pointer'}}>
                {subFocusView[f].length===0?'SELECT':'UNSELECT'} ALL
              </Typography>
            </div>
            <div>
              {
                generateSubOptions(f).filter(o=>subFocusSearch.trim()==='' || o.label.toLowerCase().includes(subFocusSearch.toLowerCase())).map(option=>{
                  return (
                    <MenuItem 
                      onClick={(event)=>{
                        if(subFocusView[f]?.includes(option.value)){
                          onChangeSubFocus(f,subFocusView[f].filter(el=>el!==option.value))
                        }else{
                          onChangeSubFocus(f,[...(subFocusView[f]||[]),option.value])
                        }
                      }} 
                      key={option.value} 
                      value={option.value}
                    >
                      <Checkbox color='primary' style={{paddingLeft:0}} checked={subFocusView[f]?.includes(option.value)}/> 
                      {toTitleCase(option.label)} 
                    </MenuItem>
                  )
                })
              }
            </div>
          </Select>
        ))
      }
      <Button 
        style={{padding:'0px 8px',color:theme.palette.primary.main,minWidth:0,marginBottom:6,borderRadius:12,height:24,borderColor:theme.palette.primary.main}}
        onClick={event=>setAnchor(event.currentTarget)}
        variant='outlined'
        classes={{label:classes.buttonLabel}}
      >
        {focusView.length===0?'Add':'Edit'} +
      </Button>
      <Popper open={anchor} anchorEl={anchor} placement='right-start'>
        <Paper 
          style={{marginTop:0,marginLeft:16,minWidth:320,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}
          className={classes.popperPaper}
          onMouseLeave={event=>{
            clearTimeout(popperExitTimeout.current)
            popperExitTimeout.current = setTimeout(()=>{
              setAnchor()
              setHoveredFocus()
              setSubAnchor()
            },200)
          }}
          onMouseEnter={event=>{
            clearTimeout(popperExitTimeout.current)
            setSubFocusSearch('')
          }}
        >
          <div style={{display:'flex',padding:'0 8px',alignItems:'center',marginBottom:8,marginTop:8}}>
            <Typography style={{color:theme.palette.primary.main,fontSize:12,letterSpacing:1,marginRight:8}}>FILTER:</Typography>
            <ToggleButton
              options={['INCLUDE','EXCLUDE']}
              selectedValue={isExclude?'EXCLUDE':'INCLUDE'}
              setSelectedValue={onToggleInclude}
              testID={`lineage-filter-include-toggle`}
            />
            <div style={{width:8}}></div>
            <ToggleButton
              options={['AND','OR']}
              selectedValue={type==='or'?'OR':'AND'}
              setSelectedValue={onToggleType}
              testID={`lineage-filter-or-and-toggle`}
            />
            <div style={{flexGrow:1}}></div>
            {
              focusView.length>0 &&
              <Typography 
                onClick={event=>{
                  onChangeFocus([])
                  onChangeSubFocus(undefined,undefined,{})
                }}
                style={{color:theme.palette.secondary.main,fontSize:12,letterSpacing:1,cursor:'pointer'}}
              >
                CLEAR ALL
              </Typography>
            }
          </div>
          <Typography className={classes.sectionHeader}>PROPERTIES</Typography>
          {
            options.map(getOptionMenuItem)
          }
          {
            instanceOptions.length>0 && 
            <>
              <Typography className={classes.sectionHeader}>DATA GOVERNANCE / MANAGEMENT</Typography>
              {
                instanceOptions.map(getOptionMenuItem)
              }
              <div style={{height:16}}></div>
            </>
          }
        </Paper>
      </Popper>
      <Popper open={subAnchor} anchorEl={subAnchor} placement='right-start'>
        <Paper 
          style={{marginLeft:6,minWidth:260,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}
          className={classes.popperPaper}
          onMouseLeave={event=>{
            clearTimeout(popperExitTimeout.current)
            popperExitTimeout.current = setTimeout(()=>{
              setAnchor()
              setHoveredFocus()
              setSubAnchor()
            },200)
          }}
          onMouseEnter={event=>{
            clearTimeout(popperExitTimeout.current)
          }}
        >
          {
            hoveredFocus &&
            <>
              <InputBase
                value={subFocusSearch}
                onChange={event=>setSubFocusSearch(event.target.value)}
                className={classes.inputBase}
                placeholder={`Search ${allOptions.find(option=>option.value===hoveredFocus).label}`}
              />
               <div style={{display:'flex',justifyContent:'space-between',padding:'0 8px',marginBottom:8}}>
                <Typography style={{color:theme.palette.primary.main,fontSize:12,letterSpacing:1}}>{subFocusView[hoveredFocus]?.length||0} SELECTED</Typography>
                <Typography onClick={event=>onUnselectAll(event,hoveredFocus)} style={{color:theme.palette.secondary.main,fontSize:12,letterSpacing:1,cursor:'pointer'}}>
                  {subFocusView[hoveredFocus]?.length>0?'UNSELECT':'SELECT'} ALL
                </Typography>
              </div>
              {
                generateSubOptions(hoveredFocus).filter(el=>subFocusSearch.trim()===''||el.value.toLowerCase().includes(subFocusSearch.toLowerCase())).map(option=>{
                  return (
                    <MenuItem 
                      onClick={(event)=>{
                        if(subFocusView[hoveredFocus]?.includes(option.value)){
                          onChangeSubFocus(hoveredFocus,subFocusView[hoveredFocus].filter(el=>el!==option.value))
                        }else{
                          onChangeSubFocus(hoveredFocus,[...(subFocusView[hoveredFocus]||[]),option.value])
                          if(!focusView.includes(hoveredFocus))onChangeFocus([...focusView,hoveredFocus])
                        }
                      }} 
                      key={option.value} 
                      value={option.value}
                    >
                      <Checkbox key={subFocusView[hoveredFocus]} color='primary' style={{paddingLeft:0}} checked={subFocusView[hoveredFocus]?.includes(option.value)}/> {toTitleCase(option.label)}
                    </MenuItem>
                  )
                })
              }
            </>
          }
        </Paper>
      </Popper>
    </div>
  )
}

ViewSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  focusView: PropTypes.string.isRequired,
  onChangeFocus: PropTypes.func.isRequired,
  subFocusView: PropTypes.string.isRequired,
  onChangeSubFocus: PropTypes.func.isRequired,
  nodes: PropTypes.array.isRequired,
}

export default withTheme()(withStyles(styles)(ViewSelector));