import axiosCerebrum from "../../../axios-cerebrum";
import { getJobResult, mapSearchObjectName } from "../../../utilities";
import { hasLLMKeySet } from "./genAIComponents";

export const generateDescription = async ({data, onLoading, onError, onSuccess}) => {

  let objectType = mapSearchObjectName(data.object_type_srt || data.object?.name);
  if(!objectType || !hasLLMKeySet()){
    onError?.();
    return;
  }
  if(objectType==='COLLECTION_INSTANCE'){
    let category = data.parent?.category || data.collection_type_srt || ''; 
    if(category.toUpperCase()==='GLOSSAY'){
      objectType = 'COLLECTION_INSTANCE_TERM'
    }
  }
  onLoading?.();
  axiosCerebrum
    .post(
      '/api/platformbatches/0',
      {
        "adhoc_args":{
          "job": "_0070_llm_generative_descriptions",
          "object_type": objectType,
          "object_id": data.id,
        }
      }
    )
    .then(response=>{
      let id = response.data.id;
      getJobResult(
        id,
        data=>{
          if(data.result?.error?.message){
            if(data.result?.error?.code+''==='429'){
              onError?.('You have reached the per minute limit for K.ai requests. Please retry in a minute. If the limit is a significant issue please contact your KADA admin or the KADA team (support@kada.ai)');
              return;
            }
            onError?.();
            return;
          }
          if(!data.result?.choices?.[0]?.message?.content){
            onError?.();
            return;
          }
          onSuccess?.(data.result.choices[0].message.content)
        },
        (error)=>{
          console.log(error)
          onError?.(error)
        }
      )
    })
    .catch(error=>{
      console.log(error);
      onError?.()
    })
}
