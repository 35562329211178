import React from 'react';
import { withStyles} from '@material-ui/core';
import classnames from 'classnames'
import { mapStepListToStr } from '../utils';
import KTooltip from '../../UI/KTooltip/KTooltip';
import { getIconComponent } from '../../../utilities';
import theme from '../../../theme';
import { getIconLabel } from '../../UI/SearchResults/utils';

const styles = theme => ({
  root: {
    '& *':{
      userSelect: 'none'
    },
    flexShrink:0,
    flexGrow:0,
    width:300
  },
  tab:{
    cursor:'pointer',
    color:theme.palette.primaryText.light,
    overflow:'hidden',
    borderRight:`3px solid ${theme.palette.primaryText.light}`,
    textTransform:'uppercase',
    opacity:0.6,
    fontSize:13.75,
    '&:hover':{
      background:theme.palette.hovered.main
    },
    display:'flex',
    alignItems:'center',
  },
  tabText:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    flexGrow:1,
    marginLeft:8
  },
  selectedTab:{
    opacity:1,
    color:theme.palette.primary.main,
    borderRight:`3px solid ${theme.palette.primary.main}`,
  },
  higlightTab:{
    background:theme.palette.primary.main+'20',
    '&:hover':{
      background:theme.palette.primary.main+'20',
    },
  },
  focusedTab:{
    color:theme.palette.primary.main,
  },
  unselectedTab:{
    opacity:1,
    borderRight:`3px solid ${theme.palette.primaryText.light}50`,
  },
})

const CategorySelector = props => {

  const {
    classes,
    state,
    history,
    dispatch,
  } = props;

  let options = ['content','data','reference sources','glossaries','domains','verified use case','knowledge','data management','data governance','lists','tags','teams','issues']
  let icon = ['content','data','host','glossary','domain','verified','note','collection','collection','list','tag','group','issue']

  let selectedTab = state.category;
  if(state.sidebarSourceList && state.stepList.length>0){
    selectedTab = state.stepList[0].id
  }

  const mapTabName = el => {
    if(el==='content')return 'content sources';
    if(el==='data')return 'data sources';
    return el;
  }
  
  return (
    <div data-test-id="vertical-tab-bar" className={classes.root}>
      {
        options.map((el,index)=>(
          <div>
            <div 
              id={`vertical-tab-${el}`}
              key={`vertical-tab-${el}`}
              className={
                classnames(
                  classes.tab,
                  selectedTab===el?classes.higlightTab:undefined,
                  selectedTab===el?classes.selectedTab:classes.unselectedTab,
                  state.category===el?classes.focusedTab:undefined,
                )
              } 
              style={{padding:'18px 12px 18px 8px'}} 
              onClick={()=>{
                dispatch({type:'set_step_list',stepList:[]})
                if(el!==state.category){
                  history.push(`/inventory?category=${el}`)
                  dispatch({ type: 'set_category', category: el })
                }
              }}
            >
              {getIconComponent({label:icon[index],size:20,colour:state.category===el?theme.palette.primary.main:theme.palette.primaryText.light})}
              <KTooltip title={el.toUpperCase()}>
                <span className={classes.tabText}>
                  {mapTabName(el)}
                </span>
              </KTooltip>
            </div>
            {
              el===state.category && state.stepList.length>0 && state.sidebarSourceList && state.sidebarSourceList.map(s=>{
                let name = s.name||s.name_txt || 'Unknown'
                if(s.object?.name==='HOST'){
                  name = s.alternate_name || 'Unknown'
                }
                return (
                  <div 
                    id={`vertical-tab-${s.id}`}
                    key={`vertical-tab-${s.id}`}
                    className={
                      classnames(
                        classes.tab,
                        selectedTab+''===s.id+''?classes.higlightTab:undefined,
                        selectedTab+''===s.id+''?classes.selectedTab:classes.unselectedTab,
                        state.category===el?classes.focusedTab:undefined,
                      )
                    } 
                    style={{padding:'18px 12PX 18px 32px'}} 
                    onClick={()=>{
                      let newList = [{name, id:s.id}]
                      dispatch({type:'set_step_list',stepList: newList})
                      history.push(`/inventory?category=${state.category}&stepList=${mapStepListToStr(newList)}`)
                    }}
                  >
                    <div style={{width:20,height:20,flexShrink:0,flexGrow:0}}>
                      {getIconComponent({label: getIconLabel({label:s.object_type_txt, item:s}),size:20,colour:state.category===el?theme.palette.primary.main:theme.palette.primaryText.light})}
                    </div> 
                    <KTooltip title={name.toUpperCase()}>
                      <span className={classes.tabText}>
                        {name.toUpperCase()}
                      </span>
                    </KTooltip>
                  </div>
                )
              })
            }
          </div>
        ))
      }
    </div>
  )

}

export default withStyles(styles)(CategorySelector);