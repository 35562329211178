import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, ClickAwayListener,  IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
// import axiosCerebrum from '../../../../axios-cerebrum'
import axiosCerebrum from '../../../../axios-cerebrum';
import {  collectionIds, getCollectionColour, getIconComponent,  getLabelPlural } from '../../../../utilities';
import { tileStyles } from './utils/styles';
import { checkIsClickOnSuggestion } from './utils/utils';
import { globalListenerRef } from '../../../../GlobalListenerRef';
import useAlert from '../../../../hooks/useAlert';
import HierarchySearchSelector from '../../SearchSelector/HierarchySearchSelector';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
  iconButton:{
    ...tileStyles.deleteButton
  },
})

function ClassificationManager(props) {

  const {
    classes,
    theme,
    history,
    object,
    onAddTileData,
    tileData,
    isEditable,
    alwaysOpenNewTab
  } = props;

  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const popperRef = useRef()
  const [deleting, setDeleting] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  
  const loadClassification = () => {
    axiosCerebrum 
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related`,{
          params:{
            relationship:'CLASSIFIED_BY',
            per_page:1
          }
        }
      )
      .then(response=>{
        let item = response.data.items[0];
        if(item){
          axiosCerebrum
            .get(
              `/api/collectioninstances/${item.id}`
            )
            .then(objResp=>{
              item = {
                ...item,
                ...objResp.data
              }
              onAddTileData(
                {
                  "disp_body":[{
                    name:item.name,
                    labels:'collection_instance',
                    id:item.id,
                    onClick:()=>{
                      alwaysOpenNewTab?
                      window.open(`/profile/collection_instance/${item.id}`, '_blank'):
                      history.push(`/profile/collection_instance/${item.id}`)
                    },
                    ...item
                  }],
                  "disp_title":"CLASSIFICATION",
                  "id":"classification",
                }
              )
            })
            .catch(error=>{
              console.log(error)
              setLoading(false)
            })
        }else{
          onAddTileData(
            {
              "disp_body":[],
              "disp_title":"CLASSIFICATION",
              "id":"classification",
            }
          )
        }
        setLoading(false)
      })
      .catch(error=>{
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(()=>{
    if(!tileData){
      loadClassification()
    }

    window.removeEventListener('message',globalListenerRef.sidebarClassListener)
    globalListenerRef.sidebarClassListener = (message) => {
      if(message.data.reload_sidebar_classification)loadClassification()
    }
    window.addEventListener('message',globalListenerRef.sidebarClassListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.sidebarClassListener)
    }
  // eslint-disable-next-line
  },[])

    
  const onAdd = async item => {
    setSearchValue('')
    setEditing(false)
    if(item.id===tileData.disp_body[0]?.id)return;

    setLoading(true)
    await axiosCerebrum
      .put(
        `/api/collectioninstances/${item.id}/related?relationship=CLASSIFIES&object_id=${object.id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":[{
              name:item.name_txt || item.name,
              labels:'collection_instance',
              id:item.id,
              onClick:()=>{
                alwaysOpenNewTab?
                window.open(`/profile/collection_instance/${item.id}`, '_blank'):
                history.push(`/profile/collection_instance/${item.id}`)
              },
              ...item,
            }],
            "disp_title":"CLASSIFICATION",
            "id":"classification",
          }
        )
      })
      .catch(error=>{
        console.log(error)
        sendAlert({
          message: "Error occurred updating classification, please try again",
          type: 'error'
        })
      })
    setLoading(false)
  }

  const onDelete = () => {
    setDeleting(true)
    let showLoadingTimeout = setTimeout(()=>{
      setLoading(true)
    },800)
    axiosCerebrum
      .delete(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=CLASSIFIED_BY&object_id=${tileData.disp_body[0].id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":[],
            "disp_title":"CLASSIFICATION",
            "id":"classification",
          }
        )
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({
          message: "Error occurred deleting classification, please try again",
          type: 'error'
        })
        setLoading(false)
        setDeleting(false)
      })
  }

  if(!tileData)return <></>

  let hasClassification = !loading && tileData.disp_body && tileData.disp_body[0]

  return (
    <div data-test-id="classification-data-tile" className={classes.root}>
      <div className={classes.editableHeaderContainer} >
        <Typography className={classes.title}>CLASSIFICATION</Typography>
      </div>
      <ClickAwayListener 
        onClickAway={(event)=>{
          if(checkIsClickOnSuggestion(event.target))return;
          setSearchValue('')
          setEditing(false)
        }}
      >
        <div ref={popperRef} className={classes.chipContainer + ' ' + (editing?classes.editingBox:isEditable?classes.clickableBox:'')} onClick={()=>isEditable && setEditing(true)}>
          {
            loading && <CircularProgress color='secondary' size={16}/>
          }
          <div style={{display:"flex",alignItems:'center',justifyContent:'space-between',overflow:"hidden",width:'100%'}}>
            {
              hasClassification && !loading && 
              <div data-test-classname="classification-data-tile-chip" className={classes.tag} style={{background:getCollectionColour('classification').colour,border:'none'}} onClick={tileData.disp_body[0].onClick}>
                <span className={classes.tagText} style={{color:getCollectionColour('classification').fontColour}}>{tileData.disp_body[0].name}</span>
                {
                  isEditable &&
                  <IconButton className={classes.iconButton + ' chip-delete-button'} style={{display:editing?'block':undefined}}  onClick={event=>{event.stopPropagation();!deleting && onDelete()}}>
                    {getIconComponent({label:'clear',size:13.75,colour:getCollectionColour('classification').fontColour})}
                  </IconButton>
                }
              </div>
            }
            {
              !loading && tileData.disp_body.length===0 &&
              <div data-test-classname="classification-data-tile-chip" className={classes.tag} style={{background:theme.palette.chip.main,color:theme.palette.primaryText.main,border:`1px solid ${theme.palette.border.main}`,cursor:'default'}}>
                <span className={classes.tagText} style={{color:theme.palette.primaryText.main}}>None</span>
              </div>
            }
          </div>
          {
            editing && 
            <div style={{marginTop:8,marginBottom:6,width:'100%'}}>
              <HierarchySearchSelector
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                rootId={collectionIds.classification}
                selectedItem = {tileData.disp_body[0]?{...tileData.disp_body[0],object:{name:'COLLECTION_INSTANCE'}}:null}
                fq={
                  `object_type_srt:COLLECTION_INSTANCE AND collection_srt:CLASSIFICATION` +
                  (tileData.disp_body.length>0?` AND -id:(${tileData.disp_body.map(b=>b.id).join(' OR ')})`:'')
                }
                rootLabel={'collection'}
                height={32}
                onResultClick={item=>{
                  if(tileData.disp_body.map(b=>b.id).includes(item.id)){
                    if(deleting)return;
                    onDelete(item)
                  }else{
                    onAdd(item)
                  }
                }}
                clearable
                autoFocus
                testID="classification-manager-input"
                placeholder={`Search for a classification`}
                isItemSelected={item=>{
                  return tileData.disp_body.map(b=>b.id).includes(item.id)
                }}
              />
            </div>
          }
        </div>
      </ClickAwayListener>
    </div>
  )
}

ClassificationManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ClassificationManager));