import React, { useRef } from 'react';
import { withTheme, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types'

const styles = theme => ({
  bar:{
    '&:hover':{
      background:theme.palette.primary.main,
    },
    // on dragging,
    '&:active':{
      background:theme.palette.primary.main,
    }
  }
})


function DrawerDragTrigger(props) {
  const {
    onChangeWidthOffset,
    classes
  } = props;

  const initialX = useRef(null)

  const onMouseMove = e =>{
    const diff = e.clientX - initialX.current
    onChangeWidthOffset(-diff)
  }

  const onMouseUp = e =>{
    window.removeEventListener('mousemove',onMouseMove)
  }

  return (
    <div 
      style={{position:'absolute',height:'100vh',userSelect:'none',width:3,cursor:'col-resize'}}
      onMouseDown={e=>{
        initialX.current = e.clientX
        window.addEventListener('mousemove',onMouseMove)
        window.addEventListener('mouseup',onMouseUp)
      }}
      className={classes.bar}
    >
    </div>
  )
}

DrawerDragTrigger.propTypes = {
  onChangeWidthOffset:PropTypes.func.isRequired
}

export default withTheme()(withStyles(styles)(DrawerDragTrigger));
