import React, {} from 'react';
import { withTheme, withStyles, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatNumber, getTestScoreColor } from '../../../../utilities';
import theme from '../../../../theme';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';


const styles = theme => ({
  tile:{
    width:150,
    overflow:'hidden',
    flexGrow:0,
    flexShrink:0,
    // flex:'1 1 120px',
    textAlign:'center',
    boxSizing:'border-box',
    height:123,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:4,
  },
  clickable:{
    background:localStorage.hasOwnProperty('dark')?darken(theme.palette.primary.dark,0.7):lighten(theme.palette.primary.dark,0.9),
    '&:hover':{
      cursor:'pointer',
      background:localStorage.hasOwnProperty('dark')?darken(theme.palette.primary.dark,0.55):lighten(theme.palette.primary.dark,0.75),
    },
  },
  disabled:{
    pointerEvents:'none',
    '&:hover':{
      cursor:'default',
      background:theme.palette.background.main
    }
  },
  selected:{
    background:theme.palette.primary.dark,
    '&:hover':{
      background:theme.palette.primary.dark
    },
    '& p':{
      color:theme.palette.background.main
    }
  },
  tileName:{
    fontSize:12,
    letterSpacing:1.5,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    paddingLeft:8,
    paddingRight:8,
    marginTop:8
  },
  tileValue:{
    fontSize:20,
    color:theme.palette.primaryText.main,
    marginTop:16,
  },
})


function SummaryTile(props) {

  const {
    classes,
    isSelected,
    id,
    name,
    value,
    testCount,
    onClick,
    footer
  } = props;

  return (
    <div  
      onClick={()=>{if(!id)return;onClick?.() }} 
      className={classes.tile + (onClick?' '+classes.clickable:'') + (isSelected?' '+classes.selected:'') + (!id?' '+classes.disabled:'')}
    >
      <Typography className={classes.tileName}>
        {name.toUpperCase()}
      </Typography>
      <div style={{width:60,height:0,overflow:'visible',margin:'auto',marginTop:6}}>
        {
          !isNaN(value) &&
          <>
            <div style={{width:60,height:60,borderRadius:30,boxSizing:'border-box',border:`5px solid ${isSelected?theme.palette.background.main:'#aaaaaa75'}`}}></div>
            <CircularProgress
              value={value}
              style={{color:getTestScoreColor(value,true),width:60,height:60,position:'relative',top:-60}}
              variant='static'
            />
          </> 
        }
      </div>
      <Typography className={classes.tileValue} style={{marginTop:isNaN(value)?24:16}}>
        {isNaN(value)?'-':Math.round(value)}
      </Typography>
      <Typography style={{color:isSelected?theme.palette.background.main:theme.palette.primaryText.light,fontSize:11,marginTop:22}}>
        {
          footer || (isNaN(value)?'No tests':`From ${formatNumber(testCount)} tests`)
        }
      </Typography>
    </div>
  )
}

SummaryTile.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  testCount: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

export default withTheme()(withStyles(styles)(SummaryTile));