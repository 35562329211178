import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Drawer, IconButton, Button, Typography } from '@material-ui/core';
import { getDispFields, getIconComponent, onClickResultItem, } from '../../../../utilities';
import DrawerDragTrigger from '../../../UI/DrawerDragTigger/DrawerDragTrigger';
import GenericLinkModal from '../../../UI/GenericLinkModal/GenericLinkModal';

const styles = theme => ({
  
  drawerPaper:{
    borderLeft:`1px solid ${theme.palette.listItemDivider.main}`,
    height:'100vh',
    overflow:'hidden',
    paddingRight:24,
    maxWidth:'90vw',
    display:'flex',
    flexDirection:'column',
    background:theme.palette.background.main
  },
  title:{
    fontSize:20,
    color: theme.palette.header.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  subTitle:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    marginBottom:24
  },
  descriptionSection:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    marginBottom:46
  },
  sectionTitle:{
    color:theme.palette.header.main,
    fontSize:16,
    marginBottom:16
  }
})


function LayerEditDrawer(props) {
  const {
    classes,
    theme,
    history,
    drawerOpen,
    setDrawerOpen,
    onUpdate
  } = props;

  let initialWidth = 600;
  try{
    if(localStorage.getItem('sourceMapDrawerWidth')){
      initialWidth = Number(localStorage.getItem('sourceMapDrawerWidth')).valueOf() || 600;
    }
  }catch{}

  const [paperWidth, setPaperWidth] = useState(initialWidth)

  useEffect(() => {
    const handleEsc = (event) => {
      // check if key is 'ESC`
      if (event.key==='Escape') {
        setDrawerOpen(false)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    // eslint-disable-next-line
  }, [])

  
  let node = drawerOpen?.obj

  return (
    <Drawer key={node?.obj?.id} anchor="right" open={drawerOpen} PaperProps={{style:{width:paperWidth,minWidth:500}}} classes={{paper:classes.drawerPaper}} onClose={()=>setDrawerOpen(false)} variant="persistent">
      { 
        drawerOpen && 
        <>
          <DrawerDragTrigger
            onChangeWidthOffset={diff=>{
              setPaperWidth(paperWidth+diff)
              localStorage.setItem('sourceMapDrawerWidth',paperWidth+diff)}}
          />
          <div style={{display:'flex',alignItems:'flex-start',paddingLeft:24,paddingTop:16,paddingBottom:16}}>
            <Button 
              color='primary' 
              variant='contained'
              style={{marginRight:12}}
              onClick={()=>onClickResultItem({newWindow:true,item:node.data.obj,label:'collection_instance', id:node.data.obj.id })}
            >
              OPEN
            </Button>
            <div style={{flexGrow:1}}></div>
            <IconButton 
              onClick={()=>setDrawerOpen(false)} 
              style={{width:28,height:28,padding:8}}
            >
              {
                getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})
              }
            </IconButton>
          </div>
          <div style={{padding:'0px 0px 24px 24px'}}>
            <Typography className={classes.title}>
              {getDispFields(node?.data.obj,'dispTitle') || 'Unknown'}
            </Typography>
            <Typography className={classes.subTitle}>
              Layer
            </Typography>

            <Typography className={classes.sectionTitle}>Description</Typography>
            <Typography className={classes.descriptionSection}>
              {node?.data.obj?.description || 'No description provided'}
            </Typography>
            
            <Typography className={classes.sectionTitle}>Sources</Typography>
            <GenericLinkModal
              key={node?.id}
              object={node?.data.obj}
              history={history}
              linkableObjects={['HOST']}
              relations={['MEMBERS']}
              onLinkUpdated={()=>{
                onUpdate()
              }}
              hideTitle
              modalTitle={`Edit ${node?.data?.obj?.name}`}
              listTitle={`SOURCES IN THIS LAYER`}
              isPluginComponent
            />
          </div>
        </>
      }
      
    </Drawer>
  )
}

export default withTheme()(withStyles(styles)(LayerEditDrawer));
