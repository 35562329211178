import React, { } from 'react';
import { withTheme, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

const DateRangeSelector = props => {

  const {
    classes,
    theme,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setStartDateByDiff
  } = props;


  return (
    <div className={classes.modalBlock}>
      <Typography className={classes.modalBlockTitle}>Date Range</Typography>
      <div style={{ flexGrow: 1 }}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: 180, marginRight: 24 }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk
                className={classes.inputBase}
                style={{ margin: 0 }}
                label="start"
                format='ll'
                maxDate={endDate}
                value={startDate}
                onChange={setStartDate}
                variant="filled"
                data-test-id={'start-date-selector'}
              />
            </MuiPickersUtilsProvider>
            <Typography style={{ fontSize: 12, marginLeft: 18, color: theme.palette.primaryText.light, marginTop: 6 }}>Required</Typography>
          </div>
          <div style={{ width: 180 }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk
                className={classes.inputBase}
                style={{ margin: 0 }}
                label="end"
                format='ll'
                minDate={startDate}
                value={endDate}
                onChange={setEndDate}
                variant="filled"
                data-test-id={'end-date-selector'}
              />
            </MuiPickersUtilsProvider>
            <Typography style={{ fontSize: 12, marginLeft: 18, color: theme.palette.primaryText.light, marginTop: 6 }}>Required</Typography>
          </div>
        </div>
        <Typography style={{ fontSize: 12, letterSpacing: 2, marginTop: 16, marginBottom: 8, color: theme.palette.primaryText.light }}>SUGGESTIONS</Typography>
        <div style={{ display: 'flex' }}>
          <div className={classes.suggestionChip} onClick={() => setStartDateByDiff(7)}>Last 7 days</div>
          <div className={classes.suggestionChip} onClick={() => setStartDateByDiff(30)}>Last 30 days</div>
          <div className={classes.suggestionChip} onClick={() => setStartDateByDiff(90)}>Last 90 days</div>
        </div>
      </div>
    </div>
  )
}

DateRangeSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  setStartDate: PropTypes.func.isRequired,
  endDate: PropTypes.object.isRequired,
  setEndDate: PropTypes.func.isRequired,
  setStartDateByDiff: PropTypes.func.isRequired,
}

export default withTheme()(DateRangeSelector);