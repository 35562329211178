import React, {useState, useRef, useEffect} from 'react';
import { withTheme, withStyles, Typography, Select, MenuItem } from '@material-ui/core';
import Data from './Data/Data'
import Content from './Content/Content';
import Source from './Source/Source';
import { getIconComponent, scrollIntoElemenet } from '../../../utilities';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.primary.main,
    // marginBottom:6,
    textTransform:'uppercase'
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  selector:{
    ...theme.components.titleSelector,
    marginBottom:4,
    marginRight:16,
  },

  sectionHeader:{
    fontSize:12,
    letterSpacing:1,
    marginLeft:16,
    marginTop:12,
    marginBottom:12,
    pointerEvents:'none',
  },

  releaseStateChip:{
    padding:'4px 8px',
    borderRadius:12,
    height:24,
    boxSizing:'border-box',
    display:'flex',
    alignItems:'center',
    fontSize:13.75,
    marginLeft:8
  },
})

const Governance = props => {

  const {
    classes,
    history,
    theme,
    state,
    dispatch
  } = props;

  const [viewTabState, setViewTabState] = useState(0)

  const [transformTop, setTransformTop] = useState(0)
  const transformTimeout = useRef()

  window.onscroll = event => {
    if(window.scrollY>1300){
      setViewTabState(2)
    }
    else if(window.scrollY>460){
      setViewTabState(1)
    }
    else{
      setViewTabState(0)
    }
    clearTimeout(transformTimeout.current)
    setTransformTop(Math.max(0,window.scrollY-16))
  }

  useEffect(()=>{
    window.scrollTo({top:0,behavior:'smooth'})
    setViewTabState(0)
  },[state.governanceTabState])

  const titles = [
    'DATA GOVERNANCE DASHBOARD',
    'CONTENT GOVERNANCE DASHBOARD',
    'DASHBOARD'
  ]

  const subTitles = [
    // `Select a data asset type see more details`,
    '',
    `Select a content type see more details`
  ]

  
  const getSourceListItem = el =>  {
    
    let isNoUsage = ['DAILY','WEEKLY','MONTHLY','INFREQUENT','UNUSED'].every(u=>!state.usageCountByObjectBySource?.[el.id]?.[el.source_template.type]?.[u]?.count)
    // let isNoUsage = false;
    return (
      <MenuItem value={el.id} disabled={isNoUsage} style={{display:'flex',alignItems:'center'}}>
        <div style={{width:24,height:24,marginRight:16}}>
          {getIconComponent({label:el.source_template.name, size:24, colour:theme.palette.primaryText.light,defaultLabel:'source'})}
        </div>
        <Typography style={{fontSize:16,overflow:'hidden',flexGrow:1,whiteSpace:'nowrap',textOverflow:'ellipsis',marginRight:16}}>
          {el.name}
        </Typography>
        {
          isNoUsage && 
          <div className={classes.releaseStateChip} style={{color:'#fff',background:'#D32F2F'}}>
            No usage available
          </div>
        }
      </MenuItem>
    )
  }

  if(!state.dbList.find(el=>['DATABASE','TOOL'].includes(el.source_template.type)) && state.governanceTabState===2){
    return <Typography>No sources found</Typography>
  }

  let isDatabase = state.governanceData['source']?.selectedTile==='DATABASE' && state.governanceTabState===2
  
  return (
    <div style={{display:'flex'}}>
      <div style={{flex:'0 0 200px',transform:`translate(0px,${transformTop}px)`,height:300 }}>
        <VerticalTabBar
          tabOptions={['SUMMARY','INSIGHTS',isDatabase?undefined:'RECOMMENDATIONS'].filter(el=>el)}
          tabState={viewTabState}
          setTabState={value=>{
            if(value===0)window.scrollTo({top:0,behavior:'smooth'})
            if(value===1){
              window.scrollTo({top:isDatabase?220:550,behavior:'smooth'})
            }
            if(value===2)scrollIntoElemenet('recommendation-section',0)
          }}
        />
      </div>
      <div className={classes.root} style={{marginLeft:80,flexGrow:1,minHeight:'120vh'}}>
        <div style={{display:'flex',justifyContent:"space-between",alignItems:'flex-start'}}>
          <div style={{flexGrow:1,marginRight:16}}>
            {
              state.governanceTabState===2 && 
              <div style={{display:'flex',alignItems:'center'}}>
                <Select
                  className={classes.selector}
                  value={state.governanceSource}
                  disableUnderline
                  onChange={event=>{
                    dispatch({type:'set_governance_source',governanceSource:event.target.value})
                  }}
                  renderValue={value => {
                    let source = state.dbList.find(db=>db.id===value);
                    if(!source)return value;
                    return (
                      <div style={{display:'flex',alignItems:'center',overflow:'hidden',paddingTop:0,paddingBottom:0,paddingRight:12,paddingLeft:8}}>
                        {
                          getIconComponent({label:source.source_template.name, size:24, colour:theme.palette.primaryText.light,defaultLabel:'source'})
                        }
                        <Typography style={{flexGrow:1,marginLeft:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',textTransform:'uppercase',color:theme.palette.header.main,fontSize:20}}>{source.name}</Typography>
                      </div>
                    )
                  }}
                >
                  {
                    state.dbList.find(el=>el.source_template.type==='DATABASE') &&
                    <Typography className={classes.sectionHeader}>DATA</Typography>
                  }
                  {
                    state.dbList.filter(el=>['DATABASE'].includes(el.source_template.type)).map(getSourceListItem)
                  }
                  {
                    state.dbList.find(el=>el.source_template.type==='DATABASE') &&
                    <Typography className={classes.sectionHeader}>CONTENT</Typography>
                  }
                  {
                    state.dbList.filter(el=>['TOOL'].includes(el.source_template.type)).map(getSourceListItem)
                  }
                </Select>
                <Typography className={classes.title}>
                  {titles[state.governanceTabState]}
                </Typography>
              </div>      
            }
            {
              [0,1].includes(state.governanceTabState) &&
              <Typography className={classes.title}>
                {titles[state.governanceTabState]}
              </Typography>
            }
            <Typography className={classes.subTitle}>
              {subTitles[state.governanceTabState]}
            </Typography>
          </div>
        </div>
      
        <div style={state.governanceTabState===0?undefined:{width:0,height:0,overflow:'hidden'}}>
          <Data state={state} dispatch={dispatch} history={history}/>
        </div>

        <div style={state.governanceTabState===1?undefined:{width:0,height:0,overflow:'hidden'}}>
          <Content state={state} dispatch={dispatch} history={history}/>
        </div>

        <div style={state.governanceTabState===2?undefined:{width:0,height:0,overflow:'hidden'}}>
          <Source state={state} dispatch={dispatch} history={history}/>
        </div>
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(Governance));