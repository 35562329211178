import React,{ useEffect, useState } from 'react';
import { withTheme, withStyles, Typography,Button, Modal, Paper, LinearProgress, } from '@material-ui/core';
import PropTypes from 'prop-types';
import ModalAlert from '../ModalAlert/ModalAlert';
import { formatNumber } from '../../../utilities';

const styles = theme => ({
  modalTitle: {
    fontSize: 20,
    color:theme.palette.header.main,
    marginBottom:24
  },
  modalDescription: {
    fontSize:16,
    whiteSpace:'pre-wrap',
    color:theme.palette.primaryText.main,
    marginBottom:48
  },
})

function AddCartBulkAdder(props) {

  const {
    classes,
    theme,
    history,
    modalOpen,
    setModalOpen,
    progress,
    onProgressiveAddToCart,
    alertOpen, 
    setAlertOpen,
    alertMessage,
    setAlertMessage,
    objectIds = [],
    onFinish,
    onCancel,
    onModalClose
  } = props;

  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    if(!modalOpen){
      setLoading(false)
      setAlertOpen(false)
    }else{
      if(progress===objectIds.length){
        setLoading(false)
      }else{
        if(!loading){
          setLoading(true)
          onProgressiveAddToCart()
        }
      }
    }
  // eslint-disable-next-line 
  },[modalOpen, objectIds, progress, loading])

  useEffect(()=>{
    
  // eslint-disable-next-line 
  },[objectIds, progress])

  return (
    <Modal 
      open={modalOpen} 
      onClose={()=>setModalOpen(false)}
      disableBackdropClick={true}
    > 
      <div style={{outline:'none',width:'max-content',margin:'25vh auto 0 auto'}}>
        <Paper style={{width:480,boxSizing:'border-box',padding:24,paddingBottom:12, background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
          <div style={{display:'flex',alignItems:'flex-start',justifyContent:"space-between",marginBottom:0}}>
            <Typography className={classes.modalTitle}>Add to Cart</Typography>
          </div>
          {
            !loading && objectIds.length!==progress &&
            <Typography className={classes.modalDescription}>
              You are adding {formatNumber(objectIds.length, undefined, undefined, true) } items to your cart. 
              {'\n'}
              The process may take a while.
            </Typography>
          }
          {
            !loading && objectIds.length===progress &&
            <Typography className={classes.modalDescription}>
              All {formatNumber(progress, undefined, undefined, true)} items have been successfully added to your cart.
            </Typography>
          }
          {
            loading && 
            <div style={{width:'100%',textAlign:'center',marginBottom:24}}>
              <Typography style={{marginBottom:16,whiteSpace:'pre-wrap'}}>
                Adding items to Cart. This may take a few seconds.
                {'\n'}
                {formatNumber(progress, undefined, undefined, true)} of {formatNumber(objectIds.length, undefined, undefined, true)} items added.
              </Typography>
              <LinearProgress
                style={{width:'80%',marginLeft:'10%'}}
                color='secondary'
              />
            </div>
          }
          <div style={{display:'flex',justifyContent:'flex-end'}}>
            {
              !loading && 
              <Button 
                onClick={()=>{
                  if(progress===objectIds.length){
                    onFinish?.()
                    history.push('/cart')
                  }else{
                    onProgressiveAddToCart()
                    setLoading(true)
                  }
                }}
                style={{marginRight:8}}
                color='primary' 
              >
                {progress===objectIds.length?'GO TO CART':'ADD TO CART'}
              </Button>
            }
            <Button 
              onClick={()=>{
                if(progress===0 && !loading){
                  onModalClose?.()
                  setModalOpen(false)
                }
                if(objectIds.length===progress){
                  setModalOpen(false)
                  onFinish?.()
                }else{
                  onCancel?.()
                  setAlertOpen(true)
                  setAlertMessage('Canceling action... Please wait for the process to finish.')
                }
              }}
              color='secondary' 
            >
              {progress===objectIds.length?'CLOSE':'CANCEL'}
            </Button>
          </div>
        </Paper>
        <ModalAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          type='error'
        />
      </div>
    </Modal>
  )
}

AddCartBulkAdder.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
}

export default withTheme()(withStyles(styles)(AddCartBulkAdder));