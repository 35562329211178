
import React from 'react';
import { Typography, Select, MenuItem, CircularProgress } from '@material-ui/core';
import BarChart from '../../Components/BarChart'
import DataTile from '../../Components/DataTile'
import LineChart from '../../Components/LineChart'
import StackedAreaChart from '../../Components/StackedAreaChart'
import {palette,genericColour} from '../../Components/utils'
import KTooltip from '../../../UI/KTooltip/KTooltip';
import QuestionBoardChip from '../../../QuestionBoard/QuestionBoardChip/QuestionBoardChip';
import { processObjectName } from '../loadUtils';
import StackBar from '../../Components/StackBar';

const lineageColourMap = {
  'Upstream only':palette[0],
  'Downstream only':palette[1],
  'Upstream & Downstream':palette[2],
  'No lineage':palette[3]
}

export const generateDataTiles = ({classes, data, setData}) => {
  return (
    <div style={{display:'flex',width:'100%',marginBottom:24}}>
      {
        data.tileDataLoading && <CircularProgress color='secondary' style={{margin:'0px auto'}} />
      }
      {
        data.tileDataError && <Typography className={classes.normalText}>Error occurred loading details</Typography>
      }
      {
        data.tileData && data.tileData.map((el,index)=>(
          <DataTile tooltip={el.tooltip} clickable={el.clickable} tileId={el.id} onClick={()=>{if(el.clickable){setData({...data,selectedTile:el.id})}}} selected={data.selectedTile===el.id} title={el.title} value={el.value} footer={el.footer}/>
        ))
      }
      {
        data.tileData && data.tileData.length===0 && <Typography className={classes.normalText}>No insights to display</Typography>
      }
    </div>
  )
}


export const generateSummaryChart = ({classes,data}) => {
  if(data[`${data.selectedTile}_graphDataLoading`]){
    return <div style={{textAlign:'center',marginTop:36, marginBottom:24}}><CircularProgress color='secondary'/></div>
  }
  if(data[`${data.selectedTile}_graphDataError`]){
    return <Typography className={classes.normalText} style={{marginBottom:24}}>Error occurred loading graphs</Typography>
  }
  if(!data[`${data.selectedTile}_graphData`]){
    return <div></div>
  }
  let graphData = data[`${data.selectedTile}_graphData`];
  let chartData = [];

  let objectType = data.selectedTile;
  let title = `Governance progress`
  if(graphData[`${objectType}_GOV_PROGRESS`]){
    chartData.push({type:'stack',width:'90%',height:120,title,...graphData[`${objectType}_GOV_PROGRESS`]})
  }

  if(chartData.length===0)return <Typography style={{marginBottom:24}}>No summary provided</Typography>
  
  return (
    <div style={{display:'flex',flexWrap:'wrap'}}>
      {
        chartData.map(el=>(
          <div className={classes.chartContainer} style={{width:'100%'}} data-test-id={`chart-${el.title.toLowerCase().replace(/\s/g,'-')}`}>
            <KTooltip title={el.tooltip} classes={{tooltip:classes.tooltip}}>
              <div style={{marginBottom:16}}>  
                <Typography className={classes.chartTitle}>{el.title}</Typography>
              </div>
            </KTooltip>
            {
              el.type==='stack' && 
              <StackBar
                id={el.title}
                key={el.title}
                width={el.width}
                height={el.height}
                series={el.series}
                labels={el.labels}
              />
            }
          </div>
        ))
      }
    </div>
  ) 
}


export const generateChartGroup = ({classes,data}) => {
  if(data[`${data.selectedTile}_graphDataLoading`]){
    return <div style={{textAlign:'center',marginTop:36, marginBottom:24}}><CircularProgress color='secondary'/></div>
  }
  if(data[`${data.selectedTile}_graphDataError`]){
    return <Typography className={classes.normalText} style={{marginBottom:24}}>Error occurred loading graphs</Typography>
  }
  if(!data[`${data.selectedTile}_graphData`]){
    return <div></div>
  }
  let graphData = data[`${data.selectedTile}_graphData`];
  let chartData = [];

  let objectType = data.selectedTile;
  let displayObjectType = processObjectName(objectType)

  let title = `${displayObjectType}s by Category`
  let subTitle = `Count of ${displayObjectType}s by Category`
  if(graphData[`${objectType}_BY_CATEGORY`]){
    chartData.push({type:'bar',width:'80%',height:260,title,subTitle,...graphData[`${objectType}_BY_CATEGORY`]})
  }

  title = `${displayObjectType}s by Classification`
  subTitle = `Count of ${displayObjectType}s by Classification`
  if(graphData[`${objectType}_BY_CLASSIFICATION`]){
    chartData.push({type:'bar',width:'80%',height:260,title,subTitle,...graphData[`${objectType}_BY_CLASSIFICATION`]})
  }

  title = `${displayObjectType}s by Domain status`
  subTitle = `Count of ${displayObjectType}s by Domain status`
  if(graphData[`${objectType}_DOMAIN_STATUS`]){
    chartData.push({type:'bar',width:'80%',height:260,title,subTitle,...graphData[`${objectType}_DOMAIN_STATUS`]})
  }

  title = `${displayObjectType}s by Use Case status`
  subTitle = `Count of ${displayObjectType}s by Use Case status`
  if(graphData[`${objectType}_USE_CASE_STATUS`]){
    chartData.push({type:'bar',width:'80%',height:260,title,subTitle,...graphData[`${objectType}_USE_CASE_STATUS`]})
  }

  title = `${displayObjectType}s by Data Owner status`
  subTitle = `Count of ${displayObjectType}s that have an owner assigned`
  if(graphData[`${objectType}_OWNER_STATUS`]){
    chartData.push({type:'bar',width:'80%',height:260,title,subTitle,...graphData[`${objectType}_OWNER_STATUS`]})
  }

  title = `${displayObjectType}s by Data Steward status`
  subTitle = `Count of ${displayObjectType}s that have stewards assigned`
  if(graphData[`${objectType}_STEWARD_STATUS`]){
    chartData.push({type:'bar',width:'80%',height:260,title,subTitle,...graphData[`${objectType}_STEWARD_STATUS`]})
  }
  
  title = `${displayObjectType}s by Documentation status`
  subTitle = `Count of ${displayObjectType}s that have been documented`
  if(graphData[`${objectType}_DOCUMENTED_STATUS`]){
    chartData.push({type:'bar',width:'80%',height:260,title,subTitle,...graphData[`${objectType}_DOCUMENTED_STATUS`]})
  }


  if(chartData.length===0)return <Typography style={{marginBottom:24}}>No insights found</Typography>
  
  return (
    <div style={{display:'flex',flexWrap:'wrap'}}>
      {
        chartData.map(el=>(
          <div className={classes.chartContainer} data-test-id={`chart-${el.title.replace(`${displayObjectType}s `,'').toLowerCase()}`}>
            <KTooltip title={el.tooltip} classes={{tooltip:classes.tooltip}}>
              <div>  
                <Typography className={classes.chartTitle}>{el.title}</Typography>
                <Typography className={classes.chartSubtitle}>{el.subTitle}</Typography>
              </div>
            </KTooltip>
           
            {
              el.type==='text' && 
              <div style={{width:el.width,height:el.height}}>
                <Typography style={{marginTop:16}} className={classes.subTitle}>{el.text}</Typography>
              </div>
            }
            {
              el.type==='bar' && 
              <BarChart
                id={el.title}
                key={el.title + objectType}
                width={el.width}
                height={el.height}
                series={el.series}
                labels={el.labels}
                toggleLabel={el.toggleLabel}
              />
            }
            
          </div>
        ))
      }
      
    </div>
  ) 
}

export const generateSwitchableLineGraph = ({classes, data, setData }) => {
  if(!data)return;
  if(!data[`${data.selectedTile}_graphData`] || data[`${data.selectedTile}_graphDataError`] || data[`${data.selectedTile}_graphDataLoading`] ){
    return <div></div>
  }
  let graphData = data[`${data.selectedTile}_graphData`];
  let title, subTitle, chartData;
  if(data.selectedTile==='TABLES_CLASSIFIED'){
    title = 'Table usage by Classification';
    subTitle = 'Count of unique tables used by Classification last 30 days';
    chartData = graphData['TABLES_USAGE_BY_CLASSIFICATION']
    // tooltip = 'Count of distinct Queries used each day'
  }

  if(data.selectedTile==='TABLES_WITH_OWNERS'){
    title = 'Table usage by Ownership status';
    subTitle = 'Count of unique tables used by Ownership status last 30 days';
    chartData = graphData['TABLES_USAGE_BY_OWNERSHIP_STATUS']
    // tooltip = 'Count of distinct Queries used each day'
  }

  if(data.selectedTile==='TABLES_WITH_LINEAGE'){
    title = 'Table usage by Lineage status';
    subTitle = 'Count of unique tables used by Lineage status last 30 days';
    chartData = graphData['TABLES_USAGE_BY_LINEAGE_STATUS']
  }

  let series = chartData && chartData.series;
  
  return (
    <div className={classes.chartContainer} style={{width:'100%'}}>
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-start'}}>
        <div style={{flexGrow:1}}>
          <Typography className={classes.chartTitle}>{title}</Typography>
          <Typography className={classes.chartSubtitle} style={{marginBottom:12}}>{subTitle}</Typography>
        </div>
       
        <Select
          className={classes.selector}
          value={data.lineStyle}
          MenuProps={{
            classes:{
              paper:classes.selectPaper
            }
          }}
          onChange={(event)=>{
            let newData = {...data,lineStyle:event.target.value};
            setData(newData)
          }}
        >
          <MenuItem className={classes.basicMenuItem} value={'line'}>
            Line
          </MenuItem>
          {
             chartData && chartData.series.length>1 &&
             <MenuItem className={classes.basicMenuItem} value={'stacked'}>
              Stacked
            </MenuItem>
          }
        </Select>
      </div>
      {
        (!chartData || (data.lineStyle==='stacked' && chartData.series.length===1))
        ?
        <div>
          <Typography style={{marginTop:16}} className={classes.subTitle}>No data to display</Typography>
        </div>
        :
        <div>
          {
            data.lineStyle==='line' && 
            <LineChart
              width={'100%'}
              height={'300px'}
              series={series}
              colours={data.selectedTile==='TABLES_WITH_LINEAGE'?series.map(s=>lineageColourMap[s.name]):[genericColour,'#FFCF4A']}
            />
          }
          {
            data.lineStyle==='stacked' && 
            <StackedAreaChart
              width={'100%'}
              height={'300px'}
              series={series}
              colours={data.selectedTile==='TABLES_WITH_LINEAGE'?series.map(s=>lineageColourMap[s.name]):[genericColour,'#FFCF4A']}
            />
          }
        </div>
      }
      
    </div>
  )
}


export const generateExtractDownloader = ({ data, state, dispatch, filterValues}) => {
  if(!data)return;

  let button = (
    <QuestionBoardChip
      buttonText={'USAGE REPORT'}
      buttonOnly
      state={state}
      dispatch={dispatch}
      code={`0200_TABLE`}
      defaultFilters={{
        source_id:filterValues.sourceId
      }}
    />
  )

  if(button){
    return (
      <div style={{display:'flex',justifyContent:'flex-end',marginTop:-72,marginBottom:16}}>
       {button}
      </div>
    )
  }
  return undefined;
}

export const generateRecommendationList = ({classes, data}) => {

  if(data[`${data.selectedTile}_recommendationLoading`]){
    return <div style={{textAlign:'center',marginTop:36}}><CircularProgress color='secondary'/></div>
  }

  if(data[`${data.selectedTile}_recommendationError`]){
    return <Typography className={classes.normalText}>Error occurred loading recommendations</Typography>
  }

  if(!data[`${data.selectedTile}_recommendationData`] || !data.selectedTile){
    return <div></div>
  }

  let recommendations = data[`${data.selectedTile}_recommendationData`];
  if(recommendations.length===0)return <Typography className={classes.normalText}>No recommendations currently provided</Typography>
  return (
    <div style={{marginBottom:500}}>
      {
        recommendations.map(el=>(
          <div className={classes.recommendationContainer} onClick={el.onClick}>
            <Typography className={classes.recommendationTitle}>{el.title}</Typography>
            <div className={classes.recommendationCount}>{el.countTxt}</div>
          </div>
        ))
      }
    </div>
  )
}
