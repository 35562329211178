import React from 'react';
import { withStyles } from '@material-ui/core';
import LinkingRules from '../LinkingRules/LinkingRules';


const styles = theme => ({
})

function Body(props) {

  const {
    classes,
    state,
    dispatch,
    history
  } = props;

  let bodyContent;
  switch (state.tabState) {
    case 0:
      bodyContent = (
        <LinkingRules
          history={history}
          state={state}
          dispatch={dispatch}
        />
      );
      break;
    default:
  }
 

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}


export default withStyles(styles)(Body);