import React, {useState, useEffect, useRef} from 'react';
import { withTheme,withStyles, Typography,CircularProgress, Select, MenuItem, Button } from '@material-ui/core';
import ReactEcharts from 'echarts-for-react';

const styles = theme => ({
  selector: {
    ...theme.components.titleSelector,
  },

  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  block: {
    marginRight:20,
    width:160
  },
  title: {
    letterSpacing:2,
    fontSize:12,
    marginBottom:8,
  },
  checkbox:{
    display:'flex',
    alignItems:'center',
    marginRight:16
  },
  boxContainer:{
    display:'flex',
    flexWrap:'wrap'
  },
  checkboxDiv:{
    width:16,
    height:16,
    borderRadius:8,
    //border:'1px solid',
    cursor:'pointer',
    marginRight:8
  },
  legendText:{
    fontSize:12
  }
})

const Map = props => {

  const {
    classes,
    state,
    dispatch,
    theme,
  } = props;

  const chartRef = useRef()

  let ownerPalette=['#2dac5a','#696b6f'];
  let classificationPalette=['#A0B2B8','#696b6f','#2dac5a','#15a1c4','#248cc0','#d9aa00','#FC642D','#d93600','#FA3A37','#FFCF4A'];
  let trustscorePalette=['#49906f ','#2dac5a','#d9aa00','#FC642D','#d93600'];
  let usagePalette=['#FA3A37','#FF991C','#FFCF4A','#6F7E82','#A0B2B8'];
  let piiDetectedPalette = ['#FA3A37','#696b6f'];

  const [checkState,setCheckState] = useState({})


  useEffect(()=>{
    setCheckState({})
  },[state.mapGroup])

  if(state.mapLoading)return ( 
    <div style={{ textAlign: 'center', marginTop: '20%' }}>
      <CircularProgress color="secondary" />
      <Typography style={{color:theme.palette.primaryText.main}}>Loading</Typography>
    </div>
  )

  if(!state.mapData || state.mapError)return <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading map data</Typography>


  const OwnerArr = ['Owner','No Owner']
  const ClassificationArr = ['No Classification']
  const TrustscoreArr = ['Highest','High','Medium','Low','Lowest']
  const UsageArr = ['Daily','Weekly','Monthly','Infrequent','Unused']
  const PIIArray = ['PII Detected','PII Not Detected'];

  state.mapData.forEach(el=>{
    if(el.classification_kc_txts){
      el.classification_kc_txts.forEach(p=>{
        if(!ClassificationArr.includes(p))ClassificationArr.push(p)
      })
    }
  })

  const handleChange = index => event => {
    setCheckState({...checkState,[index]:checkState[index]===false?true:false});
  };

  // process data sereis based on grouping
  let dataSeries = [];
  let count;
  if(state.mapGroup==='Owner'){
    count = {'Owner':0,'No Owner':0}
    let Owner = {name:'Owner',children:[],color:[ownerPalette[0]]};
    let noOwner = {name:'No Owner',children:[],color:[ownerPalette[1]]}
    state.mapData.forEach(el=>{
      const logBase = Math.E;
      let value = el.frequency>0?Math.log(el.frequency + 1) / Math.log(logBase):1
      if(el.owner){
        Owner.children.push({value:value,name:el.name,path:el.name,link:`/profile/table/${el.id}`});
        count['Owner'] += 1;
      }
      else{
        noOwner.children.push({value:value,name:el.name,path:el.name,link:`/profile/table/${el.id}`});
        count['No Owner'] += 1;
      }
    })
    if(checkState[0]!==false)dataSeries.push(Owner)
    if(checkState[1]!==false)dataSeries.push(noOwner)
  }
  if(state.mapGroup==='Classification'){
    count = {}
    let seriesmMap = {}
    ClassificationArr.forEach((p,index)=>{
      count[p] = 0;
      seriesmMap[p] = {name:p,children:[],color:[classificationPalette[index % classificationPalette.length]]}
    })

    state.mapData.forEach(el=>{
      let c = el.classification_kc_txts;
      if(c){
        c.forEach(p=>{
          count[p] += 1;
          seriesmMap[p].children.push({value:1,name:el.name,path:el.name,link:`/profile/table/${el.id}`});
        })
      }else{
        count['No Classification'] += 1;
        seriesmMap['No Classification'].children.push({value:1,name:el.name,path:el.name,link:`/profile/table/${el.id}`});
      }
    })
    ClassificationArr.forEach((key,index)=>{
      if(checkState[index]!==false)dataSeries.push(seriesmMap[key]);
    })
  }
  if(state.mapGroup==='Trustscore'){
    count = {'Highest':0,'High':0,'Medium':0,'Low':0,'Lowest':0}
    let Highest = {name:'Highest',children:[],color:[trustscorePalette[0]]}
    let High = {name:'High',children:[],color:[trustscorePalette[1]]}
    let Medium = {name:'Medium',children:[],color:[trustscorePalette[2]]}
    let Low = {name:'Low',children:[],color:[trustscorePalette[3]]}
    let Lowest = {name:'Lowest',children:[],color:[trustscorePalette[4]]}
    state.mapData.forEach(el=>{
      const logBase = Math.E;
      let value = el.total_usage_srt>0?Math.log(el.total_usage_srt + 1) / Math.log(logBase):1
      if(el.trust==='HIGHEST'){
        count['Highest'] += 1;
        Highest.children.push({value:value,name:el.name,path:el.name,link:`/profile/table/${el.id}`});
      }
      if(el.trust==='HIGH'){
        count['High'] += 1;
        High.children.push({value:value,name:el.name,path:el.name,link:`/profile/table/${el.id}`});
      }
      if(el.trust==='MEDIUM'){
        count['Medium'] += 1;
        Medium.children.push({value:value,name:el.name,path:el.name,link:`/profile/table/${el.id}`});
      }
      if(el.trust==='LOW'){
        count['Low'] += 1;
        Low.children.push({value:value,name:el.name,path:el.name,link:`/profile/table/${el.id}`});
      }
      if(el.trust==='LOWEST'){
        count['Lowest'] += 1;
        Lowest.children.push({value:value,name:el.name,path:el.name,link:`/profile/table/${el.id}`});
      }
    })
    if(checkState[0]!==false)dataSeries.push(Highest);
    if(checkState[1]!==false)dataSeries.push(High);
    if(checkState[2]!==false)dataSeries.push(Medium);
    if(checkState[3]!==false)dataSeries.push(Low);
    if(checkState[4]!==false)dataSeries.push(Lowest);
  }
  if(state.mapGroup==='Usage'){
    count = {'Daily':0,'Weekly':0,'Monthly':0,'Infrequent':0,'Unused':0}
    let Daily = {name:'Daily',children:[],color:[usagePalette[0]]}
    let Weekly = {name:'Weekly',children:[],color:[usagePalette[1]]}
    let Monthly = {name:'Monthly',children:[],color:[usagePalette[2]]}
    let Infrequent = {name:'Infrequent',children:[],color:[usagePalette[3]]}
    let Unused = {name:'Unused',children:[],color:[usagePalette[4]]}
    state.mapData.forEach(el=>{
      const logBase = Math.E;
      let value = el.frequency>0?Math.log(el.frequency + 1) / Math.log(logBase):1;
      let usageVal = el.usage_txt?el.usage_txt:'UNUSED'
      if(usageVal==='INFREQUENT'){
        count['Infrequent'] += 1;
        Infrequent.children.push({value:value,name:el.name,path:el.name,link:`/profile/table/${el.id}`});
      }
      if(usageVal==='DAILY'){
        count['Daily'] += 1;
        Daily.children.push({value:value,name:el.name,path:el.name,link:`/profile/table/${el.id}`});
      }
      if(usageVal==='WEEKLY'){
        count['Weekly'] += 1;
        Weekly.children.push({value:value,name:el.name,path:el.name,link:`/profile/table/${el.id}`,label:{color:'#000'}});
      }
      if(usageVal==='MONTHLY'){
        count['Monthly'] += 1;
        Monthly.children.push({value:value,name:el.name,path:el.name,link:`/profile/table/${el.id}`,label:{color:'#000'}});
      }
      if(usageVal==='UNUSED'){
        count['Unused'] += 1;
        Unused.children.push({value:value,name:el.name,path:el.name,link:`/profile/table/${el.id}`});
      }
    })
    if(checkState[0]!==false)dataSeries.push(Daily);
    if(checkState[1]!==false)dataSeries.push(Weekly);
    if(checkState[2]!==false)dataSeries.push(Monthly);
    if(checkState[3]!==false)dataSeries.push(Infrequent);
    if(checkState[4]!==false)dataSeries.push(Unused);
  }
  if(state.mapGroup==='PII Detected'){
    count = {'PII Detected':0,'PII Not Detected':0}
    let detected = {name:'PII Detected',children:[],color:[piiDetectedPalette[0]]};
    let notDetected = {name:'PII Not Detected',children:[],color:[piiDetectedPalette[1]]}
    state.mapData.forEach(el=>{
      if(el.pii_data_det_kc_txts){
        detected.children.push({value:1,name:el.name,path:el.name,link:`/profile/table/${el.id}`});
        count['PII Detected'] += 1;
      }
      else{
        notDetected.children.push({value:1,name:el.name,path:el.name,link:`/profile/table/${el.id}`});
        count['PII Not Detected'] += 1;
      }
    })
    if(checkState[0]!==false)dataSeries.push(detected)
    if(checkState[1]!==false)dataSeries.push(notDetected)
  }

  const getCheckboxColour = (group, index) => {
    if(group==='Owner')return ownerPalette[index];
    if(group==='Classification')return classificationPalette[index%classificationPalette.length];
    if(group==='Trustscore')return trustscorePalette[index]
    if(group==='Usage')return usagePalette[index]
    if(group==='PII Detected')return piiDetectedPalette[index%piiDetectedPalette.length]
  }

  const generateCheckBox = () => {
    //if(state.mapGroup==='None')return undefined;
    if(state.mapGroup==='Owner')return (
      <div className={classes.boxContainer}>
        {OwnerArr.map((el,index)=>(
          <div className={classes.checkbox}>
            <div 
              className={classes.checkboxDiv} 
              style={{backgroundColor:checkState[index]!==false?getCheckboxColour(state.mapGroup,index):'#CCC'}}
              onClick={handleChange(index)}
            ></div>
           <Typography className={classes.legendText} style={{color:checkState[index]!==false?theme.palette.primaryText.main:theme.palette.primaryText.light}}>{el+` (${count[`${el}`]})`}</Typography>
          </div>
        ))}
      </div>
    )
    if(state.mapGroup==='Classification')return (
      <div className={classes.boxContainer}>
        {ClassificationArr.map((el,index)=>(
          <div className={classes.checkbox}>
            <div 
              className={classes.checkboxDiv} 
              style={{backgroundColor:checkState[index]!==false?getCheckboxColour(state.mapGroup,index):'#CCC'}}
              onClick={handleChange(index)}
            ></div>
            <Typography className={classes.legendText} style={{color:checkState[index]!==false?theme.palette.primaryText.main:theme.palette.primaryText.light}}>{el+` (${count[`${el}`]})`}</Typography>
          </div>
        ))}
      </div>
    )
    if(state.mapGroup==='Trustscore')return (
      <div className={classes.boxContainer}>
        {TrustscoreArr.map((el,index)=>(
          <div className={classes.checkbox}>
            <div 
              className={classes.checkboxDiv} 
              style={{backgroundColor:checkState[index]!==false?getCheckboxColour(state.mapGroup,index):'#CCC'}}
              onClick={handleChange(index)}
            ></div>
            <Typography className={classes.legendText} style={{color:checkState[index]!==false?theme.palette.primaryText.main:theme.palette.primaryText.light}}>{el+` (${count[`${el}`]})`}</Typography>
          </div>
        ))}
      </div>
    )
    if(state.mapGroup==='Usage')return (
      <div className={classes.boxContainer}>
        {UsageArr.map((el,index)=>(
          <div className={classes.checkbox}>
            <div 
              className={classes.checkboxDiv} 
              style={{backgroundColor:checkState[index]!==false?getCheckboxColour(state.mapGroup,index):'#CCC'}}
              onClick={handleChange(index)}
            ></div>
            <Typography className={classes.legendText} style={{color:checkState[index]!==false?theme.palette.primaryText.main:theme.palette.primaryText.light}}>{el+` (${count[`${el}`]})`}</Typography>
          </div>
        ))}
      </div>
    )
    if(state.mapGroup==='PII Detected')return (
      <div className={classes.boxContainer}>
        {PIIArray.map((el,index)=>(
          <div className={classes.checkbox}>
            <div 
              className={classes.checkboxDiv} 
              style={{backgroundColor:checkState[index]!==false?getCheckboxColour(state.mapGroup,index):'#CCC'}}
              onClick={handleChange(index)}
            ></div>
            <Typography className={classes.legendText} style={{color:checkState[index]!==false?theme.palette.primaryText.main:theme.palette.primaryText.light}}>{el+` (${count[`${el}`]})`}</Typography>
          </div>
        ))}
      </div>
    )
  }

  let groupOptions=['Usage','Owner','Trustscore','Classification','PII Detected'];
  let tableNum = state.mapLength
  // if(state.mapGroup==='Usage'){
  //   tableNum -= state.mapData.filter(el=>!el.autoCollections).length;
  // }

  const onRestore = () => {
    // if(!optionsRef.current || !optionsRef.current.series || !optionsRef.current.series[0])return;
    // optionsRef.current.series[0].zoom = 1;
    // chartRef.current && chartRef.current.getEchartsInstance().setOption(optionsRef.current);
    chartRef.current && chartRef.current.getEchartsInstance().dispatchAction({type:'restore'})
  }

  return (
    <div>
      <div>
        <div>
          <div style={{display:'flex',alignItems:'center'}}>
            <Typography style={{fontSize: 20,color:theme.palette.header.main,marginRight:12}}>{`SCHEMA MAP BY`}</Typography>
            <Select
              className={classes.selector}
              value={state.mapGroup}
              onChange={event=>dispatch({type:'set_map_group',mapGroup:event.target.value})}
              disableUnderline
              MenuProps={{
                classes:{
                  paper:classes.selectPaper
                }
              }}
            > 
              {
                groupOptions.map(el=>(
                  <MenuItem  className={classes.menuItem} value={el}>
                    {el.toUpperCase()}
                  </MenuItem>
                ))
              }
            </Select>
            <Typography style={{fontSize: 20,color:theme.palette.header.main,marginLeft:12}}>{`(${tableNum} TABLES)`}</Typography>
          </div>
          
          <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginTop:4}}>Tables are sized by usage</Typography>
          <div style={{display:'flex',alignItems:'center',marginTop:24}}>
            {state.mapGroup!=='None' && <Typography color='primary' style={{fontSize:12,letterSpacing:2,marginRight:16}}>LEGEND</Typography>}
            {generateCheckBox()}
            <div style={{flexGrow:1}}></div>
            <Button color='primary' style={{height:32}} onClick={onRestore}>RESET</Button>
          </div>
        </div>
      </div>

      <ReactEcharts
        ref={chartRef}
        style={{height:'50vh',marginTop:20}}
        option={{
          // color:getColours(),
          tooltip: {
            formatter: function (info) {
                return info.name
            }
          }, 
          series: [
            {
                name: 'Map',
                type: 'treemap',
                nodeClick:'link',
                label: {
                    show: true,
                },
                breadcrumb:{
                  show : false
                },
                data: dataSeries,
            }
          ]
        }}
        notMerge={true}
      /> 
    </div>
  )
}

export default withTheme()(withStyles(styles)(Map));