import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import QuestionBoardChip from '../QuestionBoardChip/QuestionBoardChip'
import { getExtractName } from '../Utils/Utils'

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  selector: {
    minWidth: 185,
    height:16,
    marginLeft:6,
    backgroundColor: '#EEE',
    padding:'10px 14px',
  },
})

const Scanner = props => {

  const {
    classes,
		state,
		dispatch,
    history
  } = props;

  const Configuration = [
    {
      title:getExtractName('0360'),
      subtitle:'Select tables by database or schema to use in the K scanner',
      code:'0360'
    },
  ]


  const onExtractSubmitted = () => history.push(`/my_tasks?tabName=EXTRACTS&jobName=ASK K`)

  return (
    <div >
      <div>
			  <Typography  className={classes.title}>CONFIGURATION</Typography>
      </div>  
      <div style={{marginLeft:16,marginTop:16}}>
        {
          Configuration.map(el=>(
            <QuestionBoardChip
              state={state}
              dispatch={dispatch}
              code={el.code}
              title={el.title}
              subtitle={el.subtitle}
              disableAutoDownload={true}
              onExtractSubmitted={onExtractSubmitted}
            />
          ))
        }
      </div> 
    </div>
    
  )
}

export default withStyles(styles)(Scanner);