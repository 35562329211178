import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import axiosSolr from '../axios-solr';

function useGetSolr({url, params, preventAuto = false}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const source = useRef(axios.CancelToken.source());

  //const axiosSolr = createSolr();

  const fetchList = useCallback( async () => {
    if (!url) return;
    source.current.cancel();
    source.current = axios.CancelToken.source();

    let queryURL=url;

    // source.current.cancel();
    // source.current = axios.CancelToken.source();
    
    setLoading(true);
    setError(false);
    try {
      const response = await axiosSolr.get(
        queryURL, 
        {
          params, 
          withCredentials: true, 
          cancelToken: source.current.token
        });
      setData(response.data);
    } catch (error) {
      setLoading(false)
      if(error.message)setError(error);
      console.log(error)
    }
    setLoading(false);
  // eslint-disable-next-line
  }, [url, JSON.stringify(params)]);


  useEffect(() => {
    if (preventAuto) return;
    fetchList();
  // eslint-disable-next-line
  }, [url, preventAuto, fetchList, source]);


  return {
    data,
    loading,
    error,
    fetchList
  }
}
export default useGetSolr;