import axiosSolr from '../../../axios-solr';
import { loadTiles, processTileData, loadClassificationChart, loadDocumentedStatus, loadGovProgress, loadUseCaseStatus, loadRecommendations, loadStewardStatus, loadOwnerStatus, loadByCategoryChart } from '../loadUtils';

export const loadUsageByObject = (domain, setData) => {
  // let objectTypes = ['TABLE','COLUMN','REPORT','SHEET','DATASET','DATA_PIPELINE','ML_MODEL']
  setData()
  axiosSolr
    .get(
      '/solr/search/select',{
        params: {
          q: '*:*',
          fq:`-source_id_srt:998 AND active_srt:YES AND -object_type_srt:(COLLECTION_INSTANCE OR CODE) AND domain_kc_msrt:"${domain}"`,
          rows:0,
          'json.facet':{
            "usage":{
              "type":"terms",
              "field":"usage_srt",
              "mincount":1,
              "limit":10,
              "missing":true,
            }
          }
        }
      }
    )
    .then(response=>{
      let countObj = {};
      response.data.facets?.usage?.buckets?.forEach(el=>{
        countObj[el.val] = {count:el.count};
      })
      if(response.data.facets?.usage?.missing?.count){
        countObj['NO USAGE'] = {count:response.data.facets?.usage?.missing?.count}
      }
      setData(countObj)
    })
    .catch(error=>{
      console.log(error)
      setData()
    })
}

export const loadTileData = async ({ domain, setSelectedTile, usage, setData, apiSeq, apiSeqRef}) => {
  setData({loading:true});
  if(usage.length===0){
    setData({});
    return
  }
  try{
    setSelectedTile()
    let tileData = [];
    
    await loadTiles({ domain, usage }).then(facet=>{
      tileData = processTileData({facet})
    });

    if(apiSeq!==apiSeqRef.current)return;

    setData({
      data:tileData
    })    
    setSelectedTile(tileData[0]?.id)
    return tileData[0]?.id
  }catch(error){
    console.log(error);
    setData({error:true});
  }
}

export const loadGraphData = async ({selectedTile,tileData, setTileData, data,setData, domain, usage, apiSeq,apiSeqRef, state, isReload}) => {
  setData({
    ...(isReload?{}:data),
    [`${selectedTile}_graphDataLoading`]:true,
    [`${selectedTile}_graphDataError`]:false,
    [`${selectedTile}_recommendationLoading`]:true,
    [`${selectedTile}_recommendationError`]:false,
  });

  setTileData({
    data:tileData.data.map(el=>el.id===selectedTile?{...el,selectedUsage:usage}:el)
  })

  try{
    let objectType = selectedTile;
    let chartsData = {}
    let recommendations;
    if(usage.length>0){
      let promises = [
        loadGovProgress({objectType, usage, domain, state}).then(d=>chartsData[`${objectType}_GOV_PROGRESS`] = d),
        loadByCategoryChart({objectType, usage,  domain, state}).then(d=>chartsData[`${objectType}_BY_CATEGORY`] = d),
        loadClassificationChart({objectType, usage,  domain, state}).then(d=>chartsData[`${objectType}_BY_CLASSIFICATION`] = d),
        loadStewardStatus({objectType, usage,  domain, state}).then(d=>chartsData[`${objectType}_STEWARD_STATUS`] = d,
        loadOwnerStatus({objectType, usage,  domain, state}).then(d=>chartsData[`${objectType}_OWNER_STATUS`] = d)),
        loadUseCaseStatus({objectType, usage,  domain, state}).then(d=>chartsData[`${objectType}_USE_CASE_STATUS`] = d),
        loadDocumentedStatus({objectType, usage, domain, state}).then(d=>chartsData[`${objectType}_DOCUMENTED_STATUS`] = d),
        loadRecommendations({objectType, usage, domain, state}).then(d=>recommendations = d)
      ];
      await Promise.all(promises)
    }

    if(apiSeq!==apiSeqRef.current)return;
    setData({
      ...(isReload?{}:data),
      [`${selectedTile}_graphDataLoading`]:false,
      [`${selectedTile}_graphDataError`]:false,
      [`${selectedTile}_graphData`]:chartsData,
      [`${selectedTile}_recommendationLoading`]:false,
      [`${selectedTile}_recommendationError`]:false,
      [`${selectedTile}_recommendationData`]:recommendations
    })
  }catch(error){
    console.log(error);
    if(apiSeq!==apiSeqRef.current)return;
    setData({
      ...(isReload?{}:data),
      [`${selectedTile}_graphDataLoading`]:false,
      [`${selectedTile}_graphDataError`]:true,
      [`${selectedTile}_graphData`]:undefined,
      [`${selectedTile}_recommendationLoading`]:false,
      [`${selectedTile}_recommendationError`]:true,
      [`${selectedTile}_recommendationData`]:undefined
    })
  }
}
