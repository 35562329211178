import React, { useEffect, useState } from 'react';
import { withTheme,  Typography, FormControl,Select,MenuItem, FormHelperText,} from '@material-ui/core';
import axiosSolr from '../../../axios-solr';
import useAlert from '../../../hooks/useAlert';

const ObjectTypeSelector = props => {

  const {
    classes,
    theme,
    state,
    onSelectObjectType
  } = props;

  const [types, setTypes] = useState([]);

  const {
    sendAlert
  } = useAlert({
    id:`ia-object-type-selector`,
  })


  const validTypes = ['TABLE','COLUMN','REPORT','SHEET','DATASET_FIELD','DATASET_TABLE','DATA_PIPELINE']

  const loadAvailableTypes = sourceID => {
    axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:"*",
            fq:`source_id_srt:${sourceID} AND object_type_srt:(${validTypes.join(' OR ')})`,
            rows:0,
            'json.facet':{
              'types':{
                "type": "terms",
                "field": "object_type_srt",
                "mincount":1,
              }
            }
          }
        }
      )
      .then(response=>{
        let buckets = (response.data.facets && response.data.facets.types && response.data.facets.types.buckets) || [];
        setTypes(buckets.map(el=>el.val))
        if(response.data.response.numFound===0){
          sendAlert({type:'info',message:"No item available for selected source"})
        }
       
      })
      .catch(error=>{
        console.log(error)
        sendAlert({type:'error',message:"Error occurred loading object types"})
      })
  }

  useEffect(()=>{
    if(state.source==='none'){
      setTypes([])
      return;
    }
    loadAvailableTypes(state.source)
  // eslint-disable-next-line
  },[state.source])

  return (
    <div className={classes.block}>
      <FormControl>
        <Select
          className={classes.selector}
          value={state.objectType}
          onChange={onSelectObjectType}
          renderValue={el=>{
            return (
              el==='none'
                ?
                <Typography style={{color:theme.palette.primaryText.light,fontSize:16}}>
                  Select an object type
                </Typography>
                :
                <div>
                  <Typography style={{color:theme.palette.primaryText.light,fontSize:12}}>
                    Select an object type
                  </Typography>
                  <Typography style={{fontSize:16,color:theme.palette.primaryText.main}}>
                    {el.replace(/_/g,' ')}
                  </Typography>
                </div>
                
            )
          }}
        > 
          <MenuItem className={classes.menuItem} value={'none'}>
            Select an object type
          </MenuItem>
          {
            types.map(el=>(
              <MenuItem className={classes.menuItem} value={el}>
                {el.replace(/_/g,' ')}
              </MenuItem>
            ))
          }
        </Select>
        <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>Required</FormHelperText>
      </FormControl>
    </div>
  )
}

export default withTheme()(ObjectTypeSelector);