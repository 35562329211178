import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import axiosCerebrum from '../axios-cerebrum';

function useGetCerebrum({url, params, preventAuto=false, sessionCache = false}) {

  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const [error, setError] = useState();
  const source = useRef(axios.CancelToken.source());

  //const axiosCerebrum = createAxiosCerebrum()

  const fetchList = useCallback( async () => {
    if (!url) return
    source.current.cancel();
    source.current = axios.CancelToken.source();
    setLoading(true);
    setError(false);
    try {
      const response = await axiosCerebrum.get(
        url, 
        {params},
        { 
          withCredentials: true, 
          cancelToken: source.current.token,
        }
        )
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false)
      console.log(error)
      setError(true);
    }
  // eslint-disable-next-line
  }, [url, JSON.stringify(params)])

  useEffect(() => {
    if(preventAuto)return;
    fetchList();
  // eslint-disable-next-line
  }, [url, source, preventAuto, fetchList]);

  return {
    data,
    loading,
    error,
    fetchList
  }

}


export default useGetCerebrum;
