import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Typography, Button, Modal, Paper, InputBase } from '@material-ui/core';


const styles = theme => ({
  modalContainer: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      outline: 'none',
    },
  },
  paperContainer: {
    background: theme.palette.background.main,
    border: `1px solid ${theme.palette.border.main}`,
    maxWidth: '80vw',
    width: 500,
    padding: '24px 24px 8px',
    outline: 'none',
  },
  sectionTitle: {
    fontSize: 13.75,
    flex: '0 0 80px'
  },
  inputBase: {
    ...theme.components.inputBase,
    width: 100,
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`
  }
})

const RangeEditor = props => {

  const {
    classes,
    theme,
    modalOpen,
    setModalOpen,
    onRangeChange
  } = props;

  const getInitialRanges = () => {
    let ranges = []
    try {
      let arr = localStorage.getItem('lineageComplexityRange').split(',').slice(1, -1);
      ranges = arr.map((item, index) => {
        let min, max;
        if (item.includes('-')) {
          min = parseInt(item.split('-')[0]);
          max = parseInt(item.split('-')[1]);
        } else {
          min = parseInt(item);
          max = parseInt(item);
        }
        return { min, max }
      })
    } catch (error) {
      ranges = [
        { min: 1, max: 1 },
        { min: 2, max: 5 },
        { min: 6, max: 10 }
      ]
    }
    return ranges;
  }

  const [ranges, setRanges] = useState(getInitialRanges());

  useEffect(() => {
    if (modalOpen) {
      setRanges(getInitialRanges())
    }
  }, [modalOpen])

  const checkInputValid = ({ type, index, value }) => {
    if (type === 'min') {
      if (index > 0 && Number(value).valueOf() <= Number(ranges[index - 1].max).valueOf()) {
        return false;
      }
    } else {
      if (Number(value).valueOf() < Number(ranges[index].min).valueOf()) {
        return false;
      }
    }
    return true;
  }

  const getInputComponent = (index) => {
    if (index === -1) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.sectionTitle}>
            Range {index + 2}:
          </Typography>
          <InputBase
            value={0}
            disabled
            className={classes.inputBase}
          />
        </div>
      )
    }
    if (index > 2) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.sectionTitle}>
            Range {index + 2}:
          </Typography>
          <InputBase
            value={ranges[2].max + '+'}
            disabled
            className={classes.inputBase}
          />
        </div>
      )
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={classes.sectionTitle}>
          Range {index + 2}:
        </Typography>
        <InputBase
          value={ranges[index].min}
          // disabled={index === 0}
          disabled
          onChange={(event) => {
            let newRanges = [...ranges];
            newRanges[index].min = event.target.value;
            setRanges(newRanges);
          }}
          type='number'
          className={classes.inputBase + (checkInputValid({ type: 'min', index, value: ranges[index].min }) ? '' : ` ${classes.error}`)}
        />
        <Typography className={classes.sectionTitle} style={{ flex: '0 0 20px', marginLeft: 16, marginRight: 16 }}>
          TO
        </Typography>
        <InputBase
          value={ranges[index].max}
          onChange={(event) => {
            let newRanges = [...ranges];
            newRanges[index].max = event.target.value;
            if(index < 2){
              newRanges[index + 1].min = Number(event.target.value) + 1;
            }
            setRanges(newRanges);
          }}
          type='number'
          className={classes.inputBase + (checkInputValid({ type: 'max', index, value: ranges[index].max }) ? '' : ` ${classes.error}`)}
        />
      </div>
    )
  }

  const checkAllFilled = () => {
    return [0, 1, 2].every(index => {
      return checkInputValid({ type: 'min', index, value: ranges[index].min }) && checkInputValid({ type: 'max', index, value: ranges[index].max })
    })
  }

  const onSave = () => {
    let newRanges = ranges.map(item => {
      return `${item.min}-${item.max}`
    })
    newRanges = ['none', ...newRanges, `${newRanges[newRanges.length - 1].split('-')[1]}+`];
    localStorage.setItem('lineageComplexityRange', newRanges.join(','));
    setModalOpen(false);
    onRangeChange?.();
  }

  return (
    <Modal
      open={modalOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      hideBackdrop={false}
      className={classes.modalContainer}
    >
      {
        modalOpen &&
        <Paper className={classes.paperContainer}>
          <Typography style={{ fontSize: 20, marginBottom: 24, color: theme.palette.header.main }}>
            Configure Complexity Ranges
          </Typography>
          <div>
            {
              [-1, 0, 1, 2, 3].map(index => {
                return (
                  <div key={index} style={{ marginBottom: 16 }}>
                    {getInputComponent(index)}
                  </div>
                )
              })
            }
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button color='primary' style={{ width: 90, height: 36, marginTop: '24px' }} disabled={!checkAllFilled()} onClick={onSave}>SAVE</Button>
            <Button color='secondary' style={{ width: 90, height: 36, marginTop: '24px' }} onClick={(event) => { setModalOpen(false); }}>CANCEL</Button>
          </div>
        </Paper>
      }
    </Modal>
  )
}

export default withTheme()(withStyles(styles)(RangeEditor));