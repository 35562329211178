import moment from 'moment';
import { mapObjectName, toTitleCase } from '../../../utilities'

export const getFileName = (code, {extract, dataSource, user, type, userNum, trend, zone, team, period, startDate, endDate, sourceList, object, objects, objectType, objectNames, instance}) => {
  if(code==='0070' && !user)code='0070_all';
  if(code==='0340'){
    if(user){
      if(type==='OWNER')code='0340_OWNER'
      if(type==='STEWARD')code='0340_STEWARD'
    }
    if(objects)code='0340_OBJECT'
  }
  let selectedSource = sourceList?.find(el=>el.id+''===dataSource+'')?.name?.split(' ').join('') || '';
  switch(code){
    case '0005':
      return `K_${selectedSource}_tables_used_by_all_teams_from_${moment(startDate).format('DDMMYYYY')}_to_${moment(endDate).format('DDMMYYYY')}`
    case '0050':
      return `K_${selectedSource}_query_by_users_per_month_${moment().format('DDMMYYYY')}`
    case '0070':
      return `K_${selectedSource}_table_used_by_${(user?.name_txt || user?.name || '').split(' ').join('_')}_from_${moment(startDate).format('DDMMYYYY')}_to_${moment(endDate).format('DDMMYYYY')}`
    case '0070_all':
      return `K_${selectedSource}_table_used_by_all_from_${moment(startDate).format('DDMMYYYY')}_to_${moment(endDate).format('DDMMYYYY')}`
    case '0080':
      return `K_${selectedSource}_tables_${userNum}_users_from_${moment(startDate).format('DDMMYYYY')}_to_${moment(endDate).format('DDMMYYYY')}`
    case '0100':
      return `K_${selectedSource}_table_trend_${trend}_usage_last_90_days`
    case '0110':
      return `K_${selectedSource}_tables_potentially_flagged_for_PII`
    case '0140':
      return `K_${selectedSource}_tables_used_by_${team?.name_txt?.replace(/\s+/g,'_')}_from_${moment(startDate).format('DDMMYYYY')}_to_${moment(endDate).format('DDMMYYYY')}`
    case '0150':
      return `K_${selectedSource}_user_access_review_unused_in_${period}_days_from_${moment().format('DDMMYYYY')}`
    case '0160':
      return `K_user_behaviour_review`;
    case '0170':
      return `K_${selectedSource}_adhoc_data_details`;
    case '0200':
    case '0200_TABLE':
    case '0200_CONTENT':
      if(!objectType){
        return `K_${selectedSource}_object_usage_from_${moment(startDate).format('DDMMYYYY')}_to_${moment(endDate).format('DDMMYYYY')}_details`;
      }
      return `K_${selectedSource}_${objectType.toLowerCase()}_usage_from_${moment(startDate).format('DDMMYYYY')}_to_${moment(endDate).format('DDMMYYYY')}_details`;
    case '0210':
      return `K_${selectedSource}_unused_table`;
    case '0220':
      return `K_${selectedSource}_survey_results_from_${moment(startDate).format('DDMMYYYY')}_to_${moment(endDate).format('DDMMYYYY')}`
    case '0230':
      return `K_${selectedSource}_survey_metrics`
    case '0240':
      return `K_survey_user_contribution`
    case '0250':
      return `K_${selectedSource}_infrequently_&_unused_${zone}_tables`
    case '0280':
      return `K_${selectedSource}_usage_allocation_by_teams_${period}`
    case '0290':
      return `K_issues_by_source`
    case '0300':
      return `K_${selectedSource}_table_classification`
    case '0320':
      return `K_users_not_assigned_to_team`
    case '0330':
      return `K_${object?.object_type_txt}_usage_report_${object?.name_txt}_${period}d`
    case '0340_OWNER':
      return `K_owner_${(user?.name_txt || user?.name || '').replace(/\s+/g,'_')}_usage_report_${period}d`
    case '0340_STEWARD':
      return `K_steward_${(user?.name_txt || user?.name || '').replace(/\s+/g,'_')}_usage_report_${period}d`
    case '0340_OBJECT':
      return `K_${objects?.map(el=>el.name_txt.replace(/\s+/g,'_')).join('_')}_access_usage_report_${period}d`
    case '0350':
      return `K_${selectedSource}_${objectNames?objectNames.join('_').toLowerCase():'object'}_usage_by_users_from_${moment(startDate).format('DDMMYYYY')}`
    case '0360':
      return `K_scan_by_${objectType}_results`
    case '0370':
      return `K_instance_governance_report_${(instance?.name_txt || instance?.name || '').replace(/\s+/g,'_')}`
    case '0390':
      return `K_all_content_access_report_${objectType}_${period}_days_from_${moment(extract.user_created_at).format('DDMMYYYY')}`
    case '0400':
      return `Unused_access_to_${selectedSource}_last_${period}_days_from_${moment(extract.user_created_at).format('DDMMYYYY')}`
    default:
      return 'new_file'
  }
}

export const getExtractName = (code,filters={}) => {
  switch(code){
    // cost 
    case '0250':
      return 'Infrequently & Unused Tables';
    case '0280':
      return 'Usage allocation by Teams';
    // data governance
    case '0300':
      return 'Classification by Data Source';
    case '0110':
      return 'Potential PII by Data Source';
    case '0290':
      return 'Issues by Data Source'
    // data management
    case '0200_TABLE':
      return 'Table Usage details'
    case '0200_CONTENT':
      return 'Content Usage details'
    case '0200':
      if(!filters.object_type)return 'Object Usage details';
      return `${toTitleCase(filters.object_type.replace(/_/g,' '))} Usage details`;
    case '0100':
      return 'Tables usage trend';
    case '0050':
      return 'Query by Users per month';
    case '0070':
      return 'Table usage by a user';
    case '0070_all':
      return 'Table usage by all users';
    case '0080':
      return 'Tables with a limited number of users';
    case '0160':
      return 'Users by behaviour';
    case '0140':
      return 'Table usage by a Team';
    case '0005':
      return 'Table usage by all Teams';
    case '0350_CONTENT':
      return 'Content Usage by Users'
    case '0350':
      if(filters.object_types && Array.isArray(filters.object_types) && filters.object_types.length>0)return `${filters.object_types.map(el=>toTitleCase(el.replace(/_/g,' '))).join(', ')} Usage by Users`
      return 'Usage by Users'
      // break;
    // data risk 
    case '0150':
      return 'Unused access to Data';
    case '0170':
      return 'Identify adhoc data to remove';
    // surveys
    case '0220':
      return 'Survey results';
    case '0230':
      return 'Survey performance';
    case '0240':
      return 'User contribution';
    case '0310':
      return 'Download Collection details'
    case '0320':
      return 'Users not assigned to a Team';
    case '0330':
      return 'Usage report';
    case '0340_OWNER':
      return 'Data Owner access report';
    case '0340_STEWARD':
      return 'Data Steward access report';
    case '0340_OBJECT':
      return 'Data asset access report';
    case '0340':
      if(filters.object_ids)return 'Data asset access report'
      if(filters.type)return `Data ${toTitleCase(filters.type)} access report`
      break;
    case '0360':
      return 'Generate Scanner Configuration'
    case '0370':
      return `Collection instance governance report`
    case '0390':
      return 'All content access report'
    case '0400':
      return 'Unused access to Source'
    default:
      return ''
  }
}

export const getIdsToLoad = ({filters,currentMap={}}) => {
  let ids = [];
  filters?.object_ids?.forEach(id=>{
    if(!currentMap[id] && !ids.includes(id))ids.push(id)
  })
  filters?.collection_ids?.forEach(id=>{
    if(!currentMap[id] && !ids.includes(id))ids.push(id)
  })
  if(filters?.userId && !ids.includes(filters?.userId)){
    if(!currentMap[filters?.userId] && !ids.includes(filters?.userId))ids.push(filters?.userId)
  }
  if(filters?.exclusion_list && Array.isArray(filters?.exclusion_list)){
    filters?.exclusion_list.forEach(id=>{
      if(!currentMap[id] && !ids.includes(id))ids.push(id)
    })
  }
  if(filters?.user_id && !ids.includes(filters?.user_id)){
    if(!currentMap[filters?.user_id] && !ids.includes(filters?.user_id))ids.push(filters?.user_id)
  }
  if(filters?.source_id && !ids.includes(filters?.source_id)){
    if(!currentMap[filters?.source_id] && !ids.includes(filters?.source_id))ids.push(filters?.source_id)
  }
  if(filters?.groupId && !ids.includes(filters?.groupId)){
    if(!currentMap[filters?.groupId] && !ids.includes(filters?.groupId))ids.push(filters?.groupId)
  }
  if(filters?.object_id && !ids.includes(filters?.object_id)){
    if(!currentMap[filters?.object_id] && !ids.includes(filters?.object_id))ids.push(filters?.object_id)
  }
  if(filters?.collection_instance_id && !ids.includes(filters?.collection_instance_id)){
    if(!currentMap[filters?.collection_instance_id] && !ids.includes(filters?.collection_instance_id))ids.push(filters?.collection_instance_id)
  }
  if(filters?.collection_id && !ids.includes(filters?.collection_id)){
    if(!currentMap[filters?.collection_id] && !ids.includes(filters?.collection_id))ids.push(filters?.collection_id)
  }
  if(filters?.object_ids && Array.isArray(filters?.object_ids) ){
    filters?.object_ids.forEach(id=>{
      if(!currentMap[id] && !ids.includes(id))ids.push(id)
    })
  }
  return ids;
}


export const getExtractDetails = (filters={},ellipsis,extract,sources,objectDetailMap) => {
  let filterOrder = {
    sourceId:10,
    source_id:11,
    numUsers:20,
    objectId:30,
    object_types:40,
    object_type:41,
    object_ids:45,
    userId:50,
    groupId:60,
    trend:70,
    zone:80,
    period:90,
    startDate:100,
    start_at:101,
    endDate:110,
    end_at:111,
  };
  let detailsArr = [];
  Object.keys(filters).sort((a,b)=>(filterOrder[a] || 200)-(filterOrder[b]||200)).forEach(el=>{
    if(!filters[el])return;
    if(el==='tz')return;
    if(el==='sourceId' && sources.find(s=>s.id+''===filters[el]+'')){
      detailsArr.push(`Source: ${sources.find(s=>s.id+''===filters[el]+'').name}`);
      return;
    }
    if(el==='source_id' && sources.find(s=>s.id+''===filters[el]+'')){
      detailsArr.push(`Source: ${sources.find(s=>s.id+''===filters[el]+'').name}`);
      return;
    }
    if(el==='userId' || el==='groupId'){
      if(filters[el]==='*'){
        detailsArr.push(`${el==='userId'?'User':'Group'}: *`);
        return;
      }
      if(!objectDetailMap[filters[el]])return;
      detailsArr.push(`${el==='userId'?'User':'Group'}: ${objectDetailMap[filters[el]].name_txt}`);
      return;
    }
    if(el==='object_types'){
      detailsArr.push(`Object Types: ${filters[el].map(o=>toTitleCase(mapObjectName(o).replace(/_/g,' ')))}`)
      return;
    }
    if(el==='object_type'){
      if(extract.args.lookup_code==='0360'){
        detailsArr.push(`Filter by: ${toTitleCase(mapObjectName(filters[el]).replace(/_/g,' '))}`)
        return;
      }
      detailsArr.push(`Object Type: ${toTitleCase(mapObjectName(filters[el]).replace(/_/g,' '))}`)
      return;
    }
    if(el==='user_id'){
      if(filters[el]===null)return;
      if(filters[el]==='*'){
        detailsArr.push(`User: *`);
        return;
      }
      if(!objectDetailMap[filters[el]])return;
      detailsArr.push(`Owner: ${objectDetailMap[filters[el]].name_txt}`);
      return;
    }
    if(el==='exclusion_list' ){
      if(filters[el]?.length===0)return;
      let users = filters[el].map(id=>objectDetailMap[id]?.name_txt).filter(el=>el)
      if(users.length>0){
        detailsArr.push(`Excluded Users: ${users.join(', ')}`)
      }
      return;
    }
    if(el==='object_id' && objectDetailMap[filters[el]]){
      detailsArr.push(`Object: ${objectDetailMap[filters[el]].name_txt}`)
      detailsArr.push(`Labels: ${objectDetailMap[filters[el]].object_type_txt}`)
      return;
    }
    if(el==='collection_instance_id' && objectDetailMap[filters[el]]){
      detailsArr.push(`Instance: ${objectDetailMap[filters[el]].name_txt}`)
      return;
    }
    if(el==='object_ids' && filters[el] && Array.isArray(filters[el])){
      let objects = []
      filters[el].forEach(id=>{
        if(objectDetailMap[id]){
          objects.push(objectDetailMap[id])
        }
      })
      if(objects.length>0){
        detailsArr.push(`Objects: ${objects.map(el=>el.name_txt+' '+el.object_type_txt).join(', ')}`)
      }
      return;
    }
    let fieldName = el;
    if(fieldName==='startdate')fieldName='start';
    if(fieldName==='enddate')fieldName='end';
    detailsArr.push(toTitleCase(fieldName)+': '+filters[el])
  })
  return detailsArr.map(el=>el.length<30||!ellipsis?el:(el.slice(0,30)+'...')).join(', ')
}