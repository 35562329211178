import React, {useEffect, useRef, useState} from 'react';
import { CircularProgress, withStyles, Typography, IconButton, Modal, Paper, Button, LinearProgress} from '@material-ui/core';
import theme from '../../../theme';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import { getIconComponent, getIconLabelByIntegration, isInViewport } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum'
import { checkIsAdmin, getPlatformSettings, setPlatformSettings, updatePlatformSettings } from '../../../permissionChecker';
import ConfigModal from '../../PlatformManagement/Settings/ConfigModal';
import KTooltip from '../../UI/KTooltip/KTooltip';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  modalRoot:{
    padding:24,
    paddingBottom:8,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    margin:'20vh auto'
  },
  title:{
    color:theme.palette.header.main,
    fontSize:20,
  },
  subtitle:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    marginBottom:16
  },
  modalBodyText:{
    fontSize:16,
    marginTop:12,
    color:theme.palette.primaryText.light,
    whiteSpace:'pre-wrap'
  },
  buttons:{
    marginTop:24,
    marginRight:-12,
    display:'flex',
    justifyContent:'flex-end'
  },
  columnHeader:{
    color:theme.palette.primaryText.light,
    letterSpacing:2,
    fontSize:12,
    paddingRight:16,
  },
  columnBody:{
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    paddingRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  }
})

const Integrations = props => {

  const {
    classes,
    state,
    dispatch,
    // capabilityLabelMap,
    history,
    emailIntegration,
    hasEmail,
    hasGenAI,
    emailSettings,
    sessionData
  } = props;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [configModalOpen, setConfigModalOpen] = useState(false)
  const [llmSettings, setLlmSettings] = useState([10002,10004,10005,10006,10007,10008,10009].map(el=>getPlatformSettings(el)))
  const [deleting, setDeleting] = useState(false)
  const scrollRef = useRef()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`integrations`,
    isCancelledRef
  })

  useEffect(()=>{ 
    return () => {
      isCancelledRef.current = true
    }
  },[])

  let listData = state.integrationList[state.viewedCapability];
  const setListData = data => dispatch({type:'set_integration_list',integrationList:{...state.integrationList,[state.viewedCapability]:data}})

  // note need to store integration for different capabilities
  const loadIntegrations = ({page=1}) => {
    if(['EMAIL','GENERATIVE AI'].includes(state.viewedCapability))return;
    setListData({
      data:page===1?undefined:listData.data,
      loading:true
    })

    axiosCerebrum
      .get(
        `/api/sources`,
        {params:{
          capabilities:state.viewedCapability,
          internal_flag:false,
          types:'EXTERNAL_INTEGRATION',
          sort:'ALPHABETICAL',
          per_page:10
        }}
      )
      .then(response=>{
        setListData({
          data:{
            ...response.data,
            items:[
              ...(page===1?[]:listData.data.items),
              ...response.data.items
            ]
          }
        })
      })
      .catch(error=>{
        console.log(error)
        setListData({error:true})
      })
  }


  useEffect(()=>{
    history.push(window.location.pathname)
    return () => {
      window.onpopstate = undefined;
    }
  // eslint-disable-next-line
  },[])

  window.onpopstate = () => {
    dispatch({type:'set_tab_state',tabState:0})
  }

  useEffect(()=>{
    if(!listData)loadIntegrations({})
    // eslint-disable-next-line
  },[])

  const shouldLoadMore = () => {
    return listData && listData.data && !listData.loading && listData.data.page<listData.data.pages && isInViewport(scrollRef)
  }

  //  to do
  window.onscroll= () => {
    if(shouldLoadMore())loadIntegrations({page:listData.data.page+1})
  }

  useEffect(()=>{
    if(shouldLoadMore())loadIntegrations({page:listData.data.page+1})
  // eslint-disable-next-line
  },[listData])

  // to do 
  const onDeleteIntegration = async integration => {
    setDeleting(true)
    if(integration.id==='email_integration'){
      try{
        for(let i=0; i<emailSettings.length; i++){
          let value;
          if(emailSettings[i].id===5002)value = '0';
          else if(emailSettings[i].id===5006){value='false'}
          else{value=''}
          await axiosCerebrum.put(
            `/api/settings/${emailSettings[i].id}`, 
            { value }
          )
        }
        await axiosCerebrum.get(
          `/api/settings`,{params:{per_page:100}}
        )
        .then(response=>{
          localStorage.setItem('platformSettings',JSON.stringify(response.data))
          setPlatformSettings(response.data.items)
        })
        setDeleting(false)
        dispatch({type:'set_tab_state',tabState:1})
        setDeleteModalOpen(false);
        sendAlert({type:'info',message:'Integration successfully deleted'})
      }catch(error){
        console.log(error)
        setDeleting(false)
        let msg = 'Error occurred deleting the integration';
        if(error?.response?.data?.errors){
          msg = JSON.stringify(error.response.data.errors)
        }
        sendAlert({type:'error',message: msg})
      }
    }else if(integration.id==='generative_ai'){
      axiosCerebrum.delete(
        `/api/integrations/llm`
      )
      .then(response=>{
        setDeleting(false)
        setDeleteModalOpen(false);
        sendAlert({type:'info',message:'Integration successfully deleted'})
        updatePlatformSettings({...(getPlatformSettings(10003)||{}),value:'false'})
        updatePlatformSettings({...(getPlatformSettings(10000)||{}),value:''})
        updatePlatformSettings({...(getPlatformSettings(10001)||{}),value:''})
        dispatch({type:'set_has_gen_ai',hasGenAI:false})
      })
      .catch(error=>{
        console.log(error)
        setDeleting(false)
        let msg = 'Error occurred deleting the integration';
        if(error?.response?.data?.errors){
          msg = JSON.stringify(error.response.data.errors)
        }
        sendAlert({type:'error',message: msg})
      })
    }else{
      axiosCerebrum
        .delete(`/api/sources/${integration.id}`)
        .then(response=>{
          loadIntegrations({});
          setDeleteModalOpen(false);
          sendAlert({type:'info',message:'Integration successfully deleted'})
          setDeleting(false)
        })
        .catch(error=>{
          console.log(error)
          let msg = 'Error occurred deleting the integration';
          if(error?.response?.data?.errors){
            msg = JSON.stringify(error.response.data.errors)
          }
          sendAlert({type:'error',message: msg})
          setDeleting(false)
        })
    }
    
  }

  const onEditIntegration = integration => {
    dispatch({type:'set_edited_integration',editedIntegration:integration})
    dispatch({type:'set_tab_state',tabState:2})
  }


  let title, subTitle;
  if(state.viewedCapability==='EMAIL'){
    if(hasEmail){
      title = 'EMAIL SERVICE CONFIGURED'
      subTitle = 'Configure your email service by creating a new integration or editing an existing integration '
      // title = emailIntegration.platform_email_smtp_sender_email;
      // subTitle = emailIntegration.platform_email_smtp_server;
    }else{
      title = 'NO EMAIL SERVICE CONFIGURED'
      subTitle = 'Configure your email service by creating a new integration or editing an existing integration '
    }
  }
  if(state.viewedCapability==='GENERATIVE AI'){
    if(hasGenAI){
      title = 'GENERATIVE AI SERVICE CONFIGURED'
      subTitle = 'Configure your generative AI service by creating a new integration or editing an existing integration '
    }
    else{
      title = 'NO GENERATIVE AI SERVICE CONFIGURED'
      subTitle = 'Configure your generative AI service by creating a new integration or editing an existing integration '
    }
  }
  else{
    title = `${listData && listData.data?listData.data.total+' ':''}INTEGRATION(S)`
    subTitle = `Each integration will appear as an option for users when they create an issue`
  }
  
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subtitle}>{subTitle}</Typography>
      
      {
        !['EMAIL','GENERATIVE AI'].includes(state.viewedCapability) && 
        <div>
          <div style={{display:'flex',width:'100%',height:24,alignItems:'center',marginBottom:8}}>
            <div style={{flex:'0 0 24px',marginRight:24,marginLeft:8}}></div>
            <Typography className={classes.columnHeader} style={{flex:'1 0 25%'}}>SOURCE NAME</Typography>
            <Typography className={classes.columnHeader} style={{flex:'1 0 25%'}}>TYPE</Typography>
            <Typography className={classes.columnHeader} style={{flex:'0 0 200px'}}>SOURCE ID</Typography>
            <Typography className={classes.columnHeader} style={{flex:'0 0 92px'}}>ACTION</Typography>
          </div>
          {
            listData && listData.data && listData.data.items.map(el=>(
              <div style={{display:'flex',width:'100%',height:48,alignItems:'center',overflow:'hidden',borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}}>
                <div style={{flex:'0 0 24px',marginLeft:8,marginRight:24}}>
                  {getIconComponent({label:getIconLabelByIntegration(el),size:24,colour:theme.palette.primary.main})}
                </div>
                <Typography className={classes.columnBody} style={{flex:'1 0 25%'}}>{el.name}</Typography>
                <Typography className={classes.columnBody} style={{flex:'1 0 25%'}}>{el.source_template.name}</Typography>
                <Typography className={classes.columnBody} style={{flex:'0 0 200px'}}>{el.id}</Typography>
                <div  className={classes.columnBody} style={{flex:'0 0 92px',display:'flex',alignItems:'center'}}>
                  <IconButton onClick={()=>onEditIntegration(el)} style={{marginRight:8,padding:6}}>{getIconComponent({label:'edit',size:24,colour:theme.palette.primaryText.light})}</IconButton>
                  <IconButton onClick={()=>setDeleteModalOpen({integration:el})} style={{padding:6,marginRight:8}}>{getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}</IconButton>
                </div>
              </div>  
            ))
          }
        </div>
      }
      {
        ['EMAIL','GENERATIVE AI'].includes(state.viewedCapability) &&
        <div style={{display:'flex',width:'100%',height:24,alignItems:'center',marginBottom:8}}>
          <div style={{flex:'0 0 24px',marginRight:24,marginLeft:16}}></div>
          <Typography className={classes.columnHeader} style={{flex:'1 0 25%'}}>TYPE</Typography>
          <Typography className={classes.columnHeader} style={{flex:'0 0 80px'}}>ACTIONS</Typography>
        </div>
      }
      {
        state.viewedCapability==='EMAIL' && 
        <>
          {
            hasEmail && 
            <SimpleResultItem
              item={{id:'email_integration',name:emailIntegration.platform_email_smtp_sender_email}}
              label={'email'}
              titleColour={theme.palette.primaryText.main}
              title={emailIntegration.platform_email_smtp_sender_email}
              subTitle={emailIntegration.platform_email_smtp_server}
              hideRight={true}
              showUnderline
              alwaysEnableHoverEffect
              tailObject={ 
                <div style={{display:'flex',alignItems:'center'}}>
                  <IconButton onClick={()=>onEditIntegration({id:'email_integration'})} style={{marginRight:4}}>{getIconComponent({label:'edit',size:24,colour:theme.palette.primaryText.light})}</IconButton>
                  <IconButton onClick={()=>setDeleteModalOpen({integration:{id:'email_integration',name:emailIntegration.platform_email_smtp_sender_email}})}>{getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}</IconButton>
                </div>
              }
            />
          }
          {
            !hasEmail && 
            <Typography style={{marginTop:16,color:theme.palette.primaryText.main}}>
              No Email Service configured. Click New Integration to add.
            </Typography>
          }
        </>
      }
      {
        state.viewedCapability==='GENERATIVE AI' &&
        <>
          {
            hasGenAI && 
            <SimpleResultItem
              item={{id:'generative_ai',name:'GENERATIVE AI'}}
              label={'gpt'}
              titleColour={theme.palette.primaryText.main}
              title={'AZURE GPT4'}
              hideRight={true}
              showUnderline
              alwaysEnableHoverEffect
              tailObject={ 
                <div style={{display:'flex',alignItems:'center'}}>
                  <IconButton onClick={()=>onEditIntegration({id:'generative_ai'})} style={{marginRight:4}}>{getIconComponent({label:'edit',size:24,colour:theme.palette.primaryText.light})}</IconButton>
                  <IconButton onClick={()=>setDeleteModalOpen({integration:{id:'generative_ai',name:'GENERATIVE AI'}})}>{getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}</IconButton>
                </div>
              }
            />
          }
          {
            !hasGenAI && 
            <Typography style={{marginTop:16,color:theme.palette.primaryText.main}}>
              No Generative AI Service configured. Click New Integration to add.
            </Typography>
          }

          {
            checkIsAdmin({sessionData}) && 
            <>
              <Typography className={classes.title} style={{marginTop:40}}>CUSTOMISATIONS</Typography>
              <Typography className={classes.subtitle}>Update the property to customise the prompt used</Typography>
              <div style={{display:'flex',width:'100%',height:24,alignItems:'center',marginBottom:8}}>
                <div style={{flex:'0 0 24px',marginRight:24,marginLeft:16}}></div>
                <Typography className={classes.columnHeader} style={{flex:'1 0 25%'}}>PROPERTY</Typography>
                <Typography className={classes.columnHeader} style={{flex:'1 0 50%'}}>VALUE</Typography>
                <Typography className={classes.columnHeader} style={{flex:'0 0 80px'}}>ACTIONS</Typography>
              </div>
              {
                llmSettings.filter(el=>el).map(el=>(
                  <div className={classes.listItem} style={{display:'flex',width:'100%',height:48,alignItems:'center',overflow:'hidden',borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}}>
                    <KTooltip title={el.description}>
                      <div style={{flex:'0 0 24px',marginLeft:16,marginRight:24}}>
                        {getIconComponent({label:'info',size:24,colour:theme.palette.primary.main})}
                      </div>
                    </KTooltip>
                    <Typography className={classes.columnBody} style={{flex:'1 0 25%'}}>{el.display_name}</Typography>
                    <Typography className={classes.columnBody} style={{flex:'1 0 50%'}}>{el.value}</Typography>
                    <div  className={classes.columnBody} style={{flex:'0 0 80px',display:'flex',alignItems:'center'}}>
                      <IconButton onClick={()=>setConfigModalOpen(el)} style={{marginRight:4}}>
                        {getIconComponent({label:'edit',size:24,colour:theme.palette.primaryText.light})}
                      </IconButton>
                    </div>
                  </div>  
                ))
              }
            </>
          }
        </>
      }
      {
        listData && listData.data && listData.data.total===0 && 
        <Typography style={{marginTop:16,color:theme.palette.primaryText.main}}>No integration found</Typography>
      }
      <div style={{marginBottom:12,marginTop:8,textAlign:'center'}} ref={scrollRef}>
        {listData && listData.loading && <CircularProgress color='secondary'/>}
      </div>

      {listData && listData.error && <Typography style={{marginTop:16,color:theme.palette.primaryText.main}}>Error occurred loading integrations</Typography>}

      <Modal open={deleteModalOpen} disableBackdropClick>
        {
          deleteModalOpen && 
          <Paper className={classes.modalRoot} style={{width:350}}>
            <Typography className={classes.title}>Delete {deleteModalOpen.integration.name}</Typography>
            {
              deleting?
              <div style={{textAlign:'center'}}>
                <LinearProgress color='secondary' style={{height:6,width:'80%',margin:'auto',marginTop:32}}/>
                <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Deletion in progress</Typography>
              </div>
              :
              <Typography className={classes.modalBodyText}>
                {`Are you sure?\n\nThis will permanently remove the integration`}
              </Typography>
            }
            <div className={classes.buttons}>
              <Button color='primary' disabled={deleting} onClick={()=>onDeleteIntegration(deleteModalOpen.integration)} style={{marginRight:16}}>DELETE</Button>
              <Button color='secondary'  disabled={deleting}  onClick={()=>setDeleteModalOpen(false)}>CANCEL</Button>
            </div>
          </Paper>
        }
      </Modal>
      <ConfigModal
        key={configModalOpen?.id}
        state={state}
        modalOpen={configModalOpen}
        setModalOpen={setConfigModalOpen}
        clickedItem={configModalOpen}
        setClickedItem={()=>setConfigModalOpen(false)}
        onUpdate={setting=>{
          setLlmSettings(
            llmSettings.map(l=>{
              if(l.id!==setting.id)return l;
              return setting
            })
          )
        }}
      />
    </div>
  )
}


export default withStyles(styles)(Integrations);