import React, {} from 'react';
import { withTheme, Typography, FormHelperText, Button } from '@material-ui/core';
import { getIconComponent,} from '../../../../utilities'
import { dbOnlyExtracts, toolOnlyExtracts } from '../utils';
import PropTypes from 'prop-types';
import SourceSelector from '../../../OnBoardSource/UIComponents/SourceSelector';


const AskKSourceSelector = props => {

  const {
    classes,
    theme,
    code,
    onChangeDataSource,
    dataSource,
    sources,
    // history
  } = props;

  let sourceName = 'source';
  if(toolOnlyExtracts.includes(code))sourceName = 'tool'
  let sourceSubLineText = `Select a ${['0220','0230','0200_CONTENT'].includes(code)?'':'data '}${sourceName}`

  let selectableSources = []
  if(dbOnlyExtracts.includes(code)){
    selectableSources = sources.filter(el=>el.source_template.type==='DATABASE')
  }else if(toolOnlyExtracts.includes(code)){
    selectableSources = sources.filter(el=>el.source_template.type==='TOOL')
  }else{
    selectableSources = sources;
  }

  let selectedSource;
  if(dataSource!=='none'){
    selectedSource = sources.find(s=>s.id+''===dataSource+'')
  }
  
  return (
    <div className={classes.modalBlock}>
      <Typography  className={classes.modalBlockTitle}>
        { 
          ['0220','0230'].includes(code)?
          'Source':
          toolOnlyExtracts.includes(code)?
            'Tool':
            'Data Source'
        }
      </Typography>
      <div style={{flexGrow:1}}>
        {/* <FormControl style={{width:'100%'}}>
          <Select
            className={classes.selector}
            value={dataSource}
            onChange={event=>{onChangeDataSource(event.target.value)}}
            disableUnderline
            MenuProps={{
              classes:{
                paper:classes.selectPaper
              }
            }}
            inputProps={{
              'data-test-id':'source-selector'
            }}
            renderValue={el=>{
              return (
                el==='none'
                  ?
                  <Typography style={{color:theme.palette.primaryText.light,fontSize:16}}>
                    {sourceSubLineText}
                  </Typography>
                  :
                  <>
                    <Typography style={{fontSize:16,color:theme.palette.primaryText.main}}>
                      {sources.find(s=>s.id+''===el+'')?sources.find(s=>s.id+''===el+'').name:''}
                    </Typography>
                  </>
              )
            }}
          > 
            <MenuItem   className={classes.menuItem} value={'none'}>
              {sourceSubLineText}
            </MenuItem>
            {
              selectableSources.map(el=>(
                <MenuItem className={classes.menuItem} value={el.id}>
                  <div style={{width:24,height:24,marginRight:16}}>
                    {
                      getIconComponent({label:el.source_template.name,size:24,colour:theme.palette.primary.main}) ||
                      getIconComponent({label:'database',size:24,colour:theme.palette.primary.main})
                    }
                  </div>
                  {el.name}
                </MenuItem>
              ))
            }

          </Select>
          <FormHelperText className={classes.helperText}>
            {code==='0220'?'Optional':'Required'}  
          </FormHelperText>
        </FormControl> */}
        <SourceSelector
          onChangeSource={(name, s)=>onChangeDataSource(s.id)}
          sourceList={selectableSources}
          isDatabaseLevel={true}
          placeholder={sourceSubLineText}
          height={56} 
        />
        <FormHelperText className={classes.helperText}>
          {code==='0220'?'Optional':'Required'}  
        </FormHelperText>
        {
          selectedSource &&
          <div className={classes.userChip} style={{marginTop:8}}>
            <div style={{marginRight:20,marginLeft:10, display:'inline-block', verticalAlign:'middle'}}>
              {getIconComponent({label:selectedSource.source_template.name, size:24,colour:theme.palette.primary.main})}
            </div>
            <Typography style={{display:'inline-block', verticalAlign:'middle', color:theme.palette.primaryText.main}}>{selectedSource.name}</Typography>
            {
              <div style={{marginLeft: 20, display:'inline-block', verticalAlign: 'middle'}}>
                <Button 
                  style={{padding:0,width:24,minWidth:24}} 
                  onClick={()=>{
                    onChangeDataSource('none')
                  }}
                >
                  {getIconComponent({label:'clear', size:20, colour:theme.palette.primaryText.light})}
                </Button>
              </div>
            }	
          </div>
        }
      </div>
    </div>
  )
}

AskKSourceSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  code: PropTypes.string.isRequired,
  onChangeDataSource: PropTypes.func.isRequired,
  selectableSources: PropTypes.array.isRequired,
  dataSource: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  sources: PropTypes.array.isRequired,
}

export default withTheme()(AskKSourceSelector);