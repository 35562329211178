import React, { useState, useEffect } from 'react';
import { withTheme, withStyles, Drawer, IconButton, Button } from '@material-ui/core';
import { getIconComponent, mapObjectName } from '../../../utilities';
import RelatedList from './RelatedList';
import DrawerDragTrigger from '../../UI/DrawerDragTigger/DrawerDragTrigger';
// import { useStore } from 'react-redux';

const styles = theme => ({
  
  drawerPaper:{
    borderLeft:`1px solid ${theme.palette.listItemDivider.main}`,
    height:'100vh',
    overflow:'hidden',
    paddingRight:24,
    maxWidth:'90vw',
    display:'flex',
    flexDirection:'column',
    transform:"none !important",
    background:theme.palette.background.main
  },
  customScroll:{
    ...theme.components.hideScroll
  }
})


function RelatedListDrawer(props) {
  const {
    classes,
    history,
    theme,
    object,
    drawerOpen,
    setDrawerOpen,
    state,
    dispatch
  } = props;

  const initialWidth = 600;
  // const store = useStore();
  // let sessionData = store.getState().auth.session_user;
  const [shouldAddToCart, setShouldAddToCart] = useState(false)
  const [paperWidth, setPaperWidth] = React.useState(initialWidth)

  useEffect(() => {
    const handleEsc = (event) => {
      // check if key is 'ESC`
      if (event.key==='Escape') {
        setDrawerOpen(false)
      }
    }
    window.addEventListener('keydown', handleEsc)
    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
    // eslint-disable-next-line
  }, [])
  

  return (
    <Drawer 
      anchor="right" 
      open={drawerOpen} 
      classes={{paper:classes.drawerPaper}} 
      PaperProps={{style:{width:paperWidth,minWidth:500}}}
      onClose={()=>setDrawerOpen(false)}
      //disable slide animation
      SlideProps={{
        timeout: {enter:0,exit:0}
      }}  
    >
      <>
        <DrawerDragTrigger
          onChangeWidthOffset={diff=>{
            setPaperWidth(paperWidth+diff)
            localStorage.setItem('objectDrawerWidth',paperWidth+diff)}}
        />
        <div style={{display:'flex',paddingTop:16,paddingBottom:42,paddingLeft:24}}>
          {
            !drawerOpen?.isCollection && 
            <Button 
              color='primary' 
              variant='contained'
              onClick={()=>{
                window.open(
                  `/basic_search?query=*&object=${mapObjectName(drawerOpen?.type)}&presetFilter=${object.parent.short_name?.toLowerCase().replace(/\s/g,'_')}_kc_msrt:${object.name.toUpperCase()}`,
                  '_blank'
                )
              }}
            >
              OPEN IN SEARCH
            </Button>
          }
          <div style={{flexGrow:1}}></div>
          <IconButton 
            onClick={()=>setDrawerOpen(false)} 
            style={{width:28,height:28,padding:8}}
          >
            {
              getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})
            }
          </IconButton>
        </div>
        <RelatedList
          object={object}
          type={drawerOpen.type}
          history={history}
          key={drawerOpen.type}
          shouldAddToCart={shouldAddToCart}
          setShouldAddToCart={setShouldAddToCart}
          state={state}
          dispatch={dispatch}
          setDrawerOpen={setDrawerOpen}
          isCollection={drawerOpen.isCollection}
        />
      </>
    </Drawer>
  )
}

export default withTheme()(withStyles(styles)(RelatedListDrawer));
