import React from 'react';
import { withStyles, Typography} from '@material-ui/core';
import ListItem from '../Components/ListItem';
import moment from 'moment'
import { checkEmailConfigured } from '../../../permissionChecker';
import { collectionIds } from '../../../utilities';

const styles = theme => ({
  root:{
    marginBottom:40
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
    marginBottom:16
  }
})

const PlatformSetup = props => {

  const {
    classes,
    state,
    dispatch,
  } = props;
  
  const knowledgeBase = state.settingsData.find(s=>s.id===7000)?state.settingsData.find(s=>s.id===7000).value.trim():''
  const customerName = state.settingsData.find(s=>s.id===200)?state.settingsData.find(s=>s.id===200).value.trim():''
  const supportEmailValue = state.settingsData.find(s=>s.id===5011)?state.settingsData.find(s=>s.id===5011).value:''
  const singleSignOnValue = state.settingsData.find(s=>s.id===50)?state.settingsData.find(s=>s.id===50).value:'false'
  const dailyEmailBatch = state.jobsData.find(s=>s.id===702)

  return (  
    <div className={classes.root}>
      <Typography className={classes.title}>PLATFORM SETUP</Typography>
      {
        state.settingsData.find(s=>s.id===200) && 
        <ListItem 
          state={state}
          dispatch={dispatch}
          type="platform_setting" 
          platformSettingID={200} 
          title={`Customer name`}
          dispValue={customerName}
          platformSettingName={'customer name'}
          initSetting={state.settingsData.find(s=>s.id===200)}
        />
      }
      {
        state.settingsData.find(s=>s.id===7000) && 
        <ListItem 
          state={state}
          dispatch={dispatch}
          type="platform_setting" 
          platformSettingID={7000} 
          title={`Customer Knowledge Base for K`}
          dispValue={knowledgeBase}
          platformSettingName={'customer knowledge base'}
          initSetting={state.settingsData.find(s=>s.id===7000)}
        />
      }
      {
        state.settingsData.find(s=>s.id===50) && 
        <ListItem 
          state={state}
          dispatch={dispatch}
          type="platform_setting" 
          platformSettingID={50} 
          title={`Enable Single Sign On`}
          platformSettingName={'single sign on'}
          initSetting={state.settingsData.find(s=>s.id===50)}
        />
      }
      <ListItem 
        state={state}
        dispatch={dispatch}
        type="link" 
        title={singleSignOnValue==='true'?'Configure Single Sign On':'Configure Local Users'}
        onClick={()=>{window.open(document.location.protocol + "//" + document.location.hostname + "/keycloak/auth/admin/kada/console",'_blank')}}
      />
      <ListItem 
        state={state}
        dispatch={dispatch}
        type="link" 
        title={'Configure Data Job Type'}
        onClick={()=>{window.open(`/profile/collection/${collectionIds.jobType}`,'_blank')}}
      />
      <ListItem 
        state={state}
        dispatch={dispatch}
        type="link" 
        title={'Configure Email notification'}
        onClick={()=>{window.open("/admin/integrations?capability=EMAIL",'_blank')}}
      />
      {
        state.settingsData.find(s=>s.id===5011) && 
        <ListItem 
          state={state}
          dispatch={dispatch}
          type="platform_setting" 
          platformSettingID={5011} 
          title={`Send Platform emails to`}
          dispValue={(supportEmailValue!==''?supportEmailValue.split(',').join(', '):'Not configured')}
          platformSettingName={'support email notification'}
          initSetting={state.settingsData.find(s=>s.id===5011)}
          disabled={!checkEmailConfigured()}
        />
      }
      {
        state.jobsData.find(s=>s.id===702) && 
        <ListItem 
          state={state}
          dispatch={dispatch}
          type="platform_job" 
          platformJobID={702} 
          title={`Send Daily Briefing emails to K users`}
          platformSettingName={'Send Daily Briefing emails to K users'}
          initSetting={state.jobsData.find(s=>s.id===702).cron}
          disabled={!checkEmailConfigured()}
        />
      }
      {
        dailyEmailBatch && dailyEmailBatch.cron && dailyEmailBatch.cron.hour && dailyEmailBatch.cron.minute &&
        <ListItem 
          state={state}
          dispatch={dispatch}
          type="platform_job" 
          platformJobID={702} 
          title={`Send Daily email at`}
          platformSettingName={'Send Daily email at'}
          dispValue={`${moment(dailyEmailBatch.cron).format('HH:mm')} UTC / ${moment(dailyEmailBatch.cron).add(moment().utcOffset(),'minute').format('HH:mm')} Local Time`}
          initSetting={moment(dailyEmailBatch.cron)}
          disabled={!checkEmailConfigured()}
        />
      }
    </div>
  )
}

export default withStyles(styles)(PlatformSetup);