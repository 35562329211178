import axiosSolr from "../../axios-solr"
import Alert from 'react-s-alert'


const loadContentTypes = ({state, dispatch, setLoading, currentID, apiIdRef, search, setError}) => {
  setLoading(true)
  axiosSolr
    .get(
      `/solr/search/select`,{
        params:{
          q:"*",
          fq:`object_type_srt:(CONTENT_APP OR REPORT OR DATA_PIPELINE OR DATASET OR ML_MODEL OR DATA_QUALITY_TEST OR WORKSPACE) AND source_id_srt:${state.stepList[0].id}`,
          rows:0,
          'json.facet':{
            "type":{
              "type":"terms",
              'field':'object_type_srt',
              "mincount":1,
            }
          }
        }
      }
    )
    .then(response=>{
      if(currentID!==apiIdRef.current)return;
      let items = []
      if(response.data.facets.count!==0){
        items = response.data.facets.type.buckets.map(el=>({id:el.val, name:el.val, object_type_txt:'content_type'})).filter(el=>(search.trim()==='' || el.name.toLowerCase().includes(search.toLowerCase())) )
      }
      dispatch({type:'set_list',list:{docs:items, numFound:items.length}})
      setLoading(false)
    })
    .catch(error=>{
      setLoading(false)
      setError(true)
      console.log(error)
      Alert.error('Error loading the items', {
        position: 'top',
        effect: 'stackslide',
      });
    })
}

export const loadWorkspaceChildrenTypes = ({state, dispatch, setLoading, currentID, apiIdRef, search, setError}) => {
  setLoading(true)
  axiosSolr
    .get(
      `/solr/search/select`,{
        params:{
          q:"*",
          fq:`hierarchy_parent_id_srt:${state.stepList[2].id}`,
          rows:0,
          'json.facet':{
            "type":{
              "type":"terms",
              'field':'object_type_srt',
              "mincount":1,
            }
          }
        }
      }
    )
    .then(response=>{
      if(currentID!==apiIdRef.current)return;
      let items = []
      if(response.data.facets.count!==0){
        items = response.data.facets.type.buckets.map(el=>({id:el.val, name:el.val, object_type_srt:'content_type'})).filter(el=>(search.trim()==='' || el.name.toLowerCase().includes(search.toLowerCase())) )
      }
      dispatch({type:'set_list',list:{docs:items, numFound:items.length}})
      setLoading(false)
    })
    .catch(error=>{
      setLoading(false)
      setError(true)
      console.log(error)
      Alert.error('Error loading the items', {
        position: 'top',
        effect: 'stackslide',
      });
    })
}

export const loadContentSteps = ({state, dispatch, setLoading, currentID, apiIdRef, search, setError}) => {
  if(state.stepList.length===1){
    loadContentTypes({state, dispatch, setLoading, currentID, apiIdRef, search, setError})
  }
  if(state.stepList.length===3 && state.stepList[1].id==='WORKSPACE'){
    loadWorkspaceChildrenTypes({state, dispatch, setLoading, currentID, apiIdRef, search, setError})
  }
}