import React,{ } from 'react';
import PropTypes from 'prop-types';
import {withTheme, withStyles} from '@material-ui/core';
import GenericLinkModal from '../GenericLinkModal/GenericLinkModal';

const styles = theme => ({
  modalContainer:{
    width:880,
    maxWidth:'85vw',
    margin:'auto',
    marginTop:'15vh',
    outline:'none'
  },
  formBody:{
    // minHeight:'30vh',
    paddingLeft:24,
    paddingBottom:8,
    paddingRight:24,
    paddingTop:24,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    overflow:'hidden'
  },
})

function GlossaryModalAdder(props) {

  const {
    // theme,
    // classes,
    history,
    object, // in cerebrum api format
    modalOpen,
    setModalOpen,
  } = props;

  // const [alertOpen, setAlertOpen] = useState(false)
  
  return (
    <GenericLinkModal
      object={object}
      history={history}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      linkableObjects={['TERM']}
      relations={['RELATED']}
      // onLinkUpdated={linkModalOpen.onLinkUpdated}
    />
  )

}

GlossaryModalAdder.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  state: PropTypes.object,
  dispatch: PropTypes.func
}

export default withTheme()(withStyles(styles)(GlossaryModalAdder));