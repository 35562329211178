import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles } from '@material-ui/core';
import KTooltip from '../../../UI/KTooltip/KTooltip';

const styles = theme => ({
  tableRow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 16
  },
  itemRow: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    paddingLeft: 16,
    paddingBottom: 12,
    paddingTop: 12,
    fontSize: 13.75,
    color: theme.palette.primaryText.main,
    borderBottom: `1px solid ${theme.palette.listItemDivider.main}`,
  },
  hoverable: {
    cursor: "pointer",
    '&:hover': {
      backgroundColor: theme.palette.hovered.main
    }
  },
  tableTitle: {
    fontSize: 12,
    letterSpacing: 2,
    color: theme.palette.primaryText.main
  },
  tooltip: {
    fontSize: 13.75
  },
})

function ReviewProperties(props) {

  const {
    classes,
    state,
  } = props;

  const getOperation = op => {
    if (op === 'ADD_TO_EXISTING') return 'Add to existing';
    if (op === 'REPLACE') return 'Replace';
    if (op === 'CLEAR') return 'Clear'
  }

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.tableRow} style={{ marginBottom: 16, marginTop: 24 }}>
          <div className={classes.tableTitle} style={{ width: 180, flexShrink: 0, flexGrow: 0 }}>
            PROPERTY
          </div>
          <div className={classes.tableTitle} style={{ width: 300, flexBasis: 300, flexShrink: 1, flexGrow: 0, minWidth: 140 }}>
            ACTION
          </div>
          <div className={classes.tableTitle} style={{ flex: '1 1 500px' }}>
            VALUE
          </div>
        </div>
        {
          state.properties.map(el => {

            let {
              // field,
              operation,
              values,
              valueDetails
            } = state.propertyValues[el.id];

            let dispValue = valueDetails?.map(el => el.name || el.name_txt).join(', ');
            if(el.id==='description')dispValue = values[0]?.replace(/^(<br>|\s)*/,'').replace(/(<br>|\s)*$/,'')

            return (
              <div className={classes.itemRow} data-test-classname="change-item" style={{ height: 'max-content' }} >
                <div data-test-classname="change-item-name" style={{ width: 180, flexShrink: 0, flexGrow: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {el.name_txt || el.name}
                </div>
                <div style={{ width: 300, flexBasis: 300, flexShrink: 1, flexGrow: 0, minWidth: 140 }} data-test-classname="change-item-operation">
                  {getOperation(operation)}
                </div>
                <KTooltip title={dispValue} placement={'top-start'}>
                  <div style={{ flex: '1 1 500px', wordBreak: 'break-word' }} data-test-classname="change-item-value">
                    {dispValue}
                  </div>
                </KTooltip>
              </div>
            )
          })
        }
      </div>

    </div>
  )
}

ReviewProperties.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ReviewProperties));