import React, {useState} from 'react';
import { withTheme, withStyles, InputBase, Button} from '@material-ui/core';

const styles = theme => ({
  root:{
    display:'flex',
    width:'100%'
  },
  textField:{
    ...theme.components.inputBase,
    flexGrow:1,
    '& input':{
      padding: '18px 16px',
      color:theme.palette.primaryText.main
    },
    '& textarea':{
      padding: '8px 16px',
    },
    '& div':{
      marginTop: '0px !important'
    },
  },
})

const MultiStringInput = props => {

  const {
    classes,
    onAdd,
    placeholder,
    disabled
  } = props;

  const [value, setValue] = useState('')

  return (
    <div className={classes.root}>
      <InputBase
        className={classes.textField}
        variant="filled"
        disabled={disabled}
        value={value}
        onChange={(event)=>{setValue(event.target.value)}}
        placeholder={placeholder}
        onKeyDown={e=>{
          if(e.key==='Enter'){
            onAdd(value)
            setValue('')
          }
        }}
      />
      <Button color='primary' disabled={value.trim()===''} style={{marginLeft:16, flexGrow:0, flexShrink:0, alignSelf:'center'}} onClick={()=>{onAdd(value);setValue('')}}>ADD</Button>
    </div>
  )
}

export default withTheme()(withStyles(styles)(MultiStringInput));