import React from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import KTooltip from '../KTooltip/KTooltip';

const styles = theme => ({
  root: {
    height: 20,
    borderRadius: 2,
    overflow: 'hidden',
    display:'flex',
    justifyContent:'space-between'
  },
  tooltip:{
    fontSize:13.75
  }
})

function BlockBar(props) {

  const {
    classes,
    width,
    value,
    total,
    numOfBlocks = 5,
    tooltip='',
    theme,
  } = props;

  const totalWidth = width || 100;
  let color = theme.palette.usageBar.main;

  let blocks = []
  for(let i=0; i<numOfBlocks; i++){
    blocks.push(
      <div style={{marginRight:i===numOfBlocks-1?0:8, flex:'1 1', background:value> i * (total/numOfBlocks)?color+(i===numOfBlocks-1?'':`${40+60*i/(numOfBlocks-1)}`):undefined, border:`1px solid ${color}`, borderRadius:2}}>
      </div>
    )
  }
  
  return (
    <KTooltip title={tooltip} classes={{tooltip:classes.tooltip}}>
      <div className={classes.root} style={{ width: totalWidth }}>
        {blocks}
      </div>
    </KTooltip>

  )
}

BlockBar.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.any,
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  numOfBlocks: PropTypes.number
}

export default withTheme()(withStyles(styles)(BlockBar));