import React from 'react';
import PropTypes from 'prop-types';
import { withStyles} from '@material-ui/core';
import BySource from '../BySource/BySource';
import ByDomain from '../ByDomain/ByDomain';
import ByTool from '../ByTool/ByTool';
import ByCollection from '../ByCollection/ByCollection';
import ByUseCase from '../ByUseCase/ByUseCase';
import ByCategory from '../ByCategory/ByCategory';

const styles = theme => ({
  root: {

  }
})

const Body = props => {

  const {
    classes,
    state,
    dispatch,
    tabOptions,
    history,
    sessionData
  } = props;

  let bodyContent;
  switch (tabOptions[state.tabState]) {
    case 'BY SOURCE':
      bodyContent = (
        <BySource
          state={state}
          dispatch={dispatch}
          history={history}
        />
      )
      break;
    case 'BY DOMAIN':
      bodyContent = (
        <ByDomain
          state={state}
          dispatch={dispatch}
          history={history}
          sessionData={sessionData}
        />
      )
      break;
    case 'BY CATEGORY':
      bodyContent = (
        <ByCategory
          state={state}
          dispatch={dispatch}
          history={history}
          sessionData={sessionData}
        />
      )
      break;
    case 'BY USE CASE':
      bodyContent = (
        <ByUseCase
          state={state}
          dispatch={dispatch}
          history={history}
          sessionData={sessionData}
        />
      )
      break;
    case 'BY COLLECTION':
      bodyContent = (
        <ByCollection
          state={state}
          dispatch={dispatch}
          history={history}
        />
      )
      break;
    case 'BY TOOL':
      bodyContent = (
        <ByTool
          state={state}
          dispatch={dispatch}
          history={history}
        />
      )
      break;
    default:
  }
  

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sessionData: PropTypes.object
}

export default withStyles(styles)(Body);