import React, { useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import theme from '../../../theme';

function KTooltip(props) {
  const { children, title, ...other } = props;

  const [formattedTitle, setFormattedTitle] = useState(title);

  useEffect(()=>{
    if(typeof title !== 'string')return;

    // check if title includes https:// (link), if so construct it using <a> tag
    // e.g. go to this link for more details: httpsL//www.google.com, contact us at 123456789
    // format it to: <span>go to this link for more details: <a href="https://www.google.com">https://www.google.com</a>, contact us at 123456789</span>
    if(title.includes('https://')){
      const titleArr = title.split('https://');
      const formattedTitleArr = titleArr.map((item, index) => {
        if(index === 0)return item;
        let link = item.split(' ')[0];
        let rest = ' ' + item.split(' ').slice(1).join(' ');
        // if link has comma or dot at the end, remove it
        let lastChar = link[link.length-1];
        if(['.', ','].includes(lastChar)){
          link = link.slice(0, -1);
          rest = lastChar + rest;
        }
        return (
          <span key={index}>
            <a href={`https://${link}`} style={{color: theme.palette.hyperLink.main}} target="_blank" rel="noopener noreferrer">https://{link}</a>{rest}
          </span>
        )
      });
      setFormattedTitle(formattedTitleArr);
    }
    // eslint-disable-next-line
  },[]);

  return (
    <Tooltip enterDelay={260} leaveDelay={260} title={formattedTitle || ''}  placement="bottom-start" {...other}>
      {children}
    </Tooltip>
  );
}

KTooltip.propTypes = { ...Tooltip.propTypes }

export default KTooltip;