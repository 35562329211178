import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        justifyContent:'center'
    },
    text:{
        fontSize:16,
        color:theme.palette.primaryText.main,
        marginTop:24
    },
    subTitle:{
      fontSize:13.75,
      color:theme.palette.primaryText.light
    }
});

class NoRoleDeadEnd extends Component {

  render() {
    const {
      classes,
    } = this.props;

    let isSingleSignOn;
    try{
      isSingleSignOn = JSON.parse(localStorage.getItem('platformSettings')).items.find(el=>el.id===50).value;
    }catch{
      isSingleSignOn = 'false'
    }

    let message = isSingleSignOn==='false'?
      [
        'Oops. Looks like you are missing a role for K.',
        'Ask the Admin to fix the problem and then try logging again'
      ]:
      [
        'Oops. Looks like you have not been added to a Single Sign On group that has access to K.',
        'Ask the Admin(s) to fix the problem and then try logging in again.'
      ]
    
    return (
      <div>
        <div style={{marginLeft:40,marginTop:40}}>
          <Typography color='primary' style={{fontSize:34}}>Welcome</Typography>
          <Typography className={classes.subTitle}>K is data knowledge platform for your organisation</Typography>
        </div>
        <div style={{display:'flex',flexDirection:'column',width:'95%',alignItems:'center',justifyContent:'center',marginTop:160}}>
          <div className={classes.root}>
            <Typography style={{fontSize:40}}>¯\_(ツ)_/¯</Typography>
            {
              message.map(el=>(
                <Typography className={classes.text}>{el}</Typography>
              ))
            }
          </div>
        </div>
      </div>
    )
  }

}

export default withStyles(styles)(NoRoleDeadEnd);