import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Stepper, Step, StepLabel, Typography, StepContent, Button} from '@material-ui/core';
import AddInstruction from './AddInstruction';
import DisplayAccessRole from './DisplayAccessRole';
import AddLink from './AddLink';
import axiosCerebrum from '../../../axios-cerebrum';
import { getIconComponent } from '../../../utilities';
import { generateDefaultPayload } from '../utils';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  stepper:{
    marginBottom:120,
    backgroundColor:theme.palette.background.main
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
  banner: {
    flexGrow:1,
    backgroundColor: theme.palette.inputBackground.main,
    border:`1px solid ${theme.palette.border.main}`,
    minWidth:700,
    minHeight:52,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    padding:'0px 4px'
  },
  bannerText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    marginLeft:16,
    marginRight:16
  }
})

const AccessRequest = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    history
  } = props;
  
  const [activeStep, setActiveStep] = useState(0)
  const [instructions, setInstructions] = useState( state.selectedSource.access_request_instructions || '')
  const [displayAccess, setDisplayAccess] = useState(state.selectedSource.access_request_show_roles || false)
  const [link, setLink] = useState(state.selectedSource.access_request_url || '')

  const [loading, setLoading] = useState(false)

  const {
    sendAlert
  } = useAlert({})

  const steps = [
    `Add instructions for requesting access to ${state.selectedSource.name}`,
    'Display access roles',
    'Add a link (optional)'
  ]

  window.onpopstate = () => {
    dispatch({type:'set_tab_state',tabState:0})
  }
  
  useEffect(()=>{
    history.push(`/admin/sources`)
    return () => {
      window.onpopstate = undefined;
    }
  // eslint-disable-next-line
  },[])

  const onSave = () => {
    let payload = generateDefaultPayload(state.selectedSource)

    payload.enable_access_docs = true;
    payload.access_request_instructions = instructions;
    payload.access_request_url = link;
    payload.access_request_show_roles = displayAccess;

    setLoading(true);
    axiosCerebrum
      .put('/api/sources/'+state.selectedSource.source_id, payload)
      .then(response=>{
        dispatch({type:'set_tab_state',tabState:0});
        dispatch({type:'set_sources_updated',sourcesUpdated:true})
        setLoading(false);
        sendAlert({message:`Successfully enabled access request for ${state.selectedSource.name}`,type:'info'})
      }).catch((error)=>{
        console.log(error.response);
        sendAlert({message:`Error occurred enabling access request for ${state.selectedSource.name}`,type:'error'})
        setLoading(false)
      })
  }

  const onEnable = () => {
    let payload = generateDefaultPayload(state.selectedSource)

    payload.enable_access_docs = true;

    setLoading(true);
    axiosCerebrum
      .put('/api/sources/'+state.selectedSource.source_id, payload)
      .then(response=>{
        dispatch({type:'set_sources_updated',sourcesUpdated:true})
        dispatch({type:'set_selected_source',selectedSource:{...response.data,source_id:response.data.id,id:response.data.host_ids[0]}})
        setLoading(false);
        sendAlert({message:`Successfully enabled access request for ${state.selectedSource.name}`,type:'info'})
      }).catch((error)=>{
        console.log(error.response);
        sendAlert({message:`Error occurred enabling access request for ${state.selectedSource.name}`,type:'error'})
        setLoading(false)
      })
  }

  const getStepContent = index => {
    switch(index){
      case 0:
        return <AddInstruction setActiveStep={setActiveStep} instructions={instructions} setInstructions={setInstructions}  />
      case 1:
        return <DisplayAccessRole setActiveStep={setActiveStep} displayAccess={displayAccess} setDisplayAccess={setDisplayAccess}  />
      case 2:
        return <AddLink onSave={onSave} link={link} setLink={setLink} loading={loading} />
      default:
    }
  }

  const labelClickable = index => {
    if(index===0)return true;
    return instructions.trim()!=='';
  }

  const onLabelClick = index => {
    if(labelClickable(index))setActiveStep(index)
  }

  return (
    <div className={classes.root}>
      {
        state.selectedSource.enable_access_docs && 
        <div className={classes.banner}>
          {getIconComponent({label:'checked',size:24,colour:theme.palette.success.main})}
          <Typography className={classes.bannerText} data-test-classname='banner-text'>The access request for {state.selectedSource.name} is ENABLED.</Typography>
        </div>
      }
      {
        !state.selectedSource.enable_access_docs && state.selectedSource.access_request_instructions && 
        <div className={classes.banner}>
          {getIconComponent({label:'warning',size:24,colour:theme.palette.secondary.main})}
          <Typography className={classes.bannerText} data-test-classname='banner-text'>The access request for {state.selectedSource.name} is DISABLED. Click to </Typography>
          <Button variant='contained' color='primary' data-test-id='enable-button' onClick={()=>onEnable()}>ENABLE</Button>
        </div>
      }
      <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper} >
        {steps.map((label, index) => (
          <Step key={index} >
            <StepLabel className={classes.step} style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}}>
              <Typography color='primary' style={{color:activeStep<index?theme.palette.primaryText.light:theme.palette.primaryText.main,fontSize:13.75,marginLeft:6}}>{label}</Typography> 
            </StepLabel>
            <StepContent style={{width:680,paddingTop:12,paddingLeft:25}}>
             {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

export default withTheme()(withStyles(styles)(AccessRequest));