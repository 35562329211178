import React from 'react';
import { withTheme, withStyles, Typography,  } from '@material-ui/core';
import Data from './Data/Data'
import Content from './Content/Content'
// import People from './People/People'
import BySource from './BySource/BySource';

const styles = theme => ({
  root:{
    // display:'flex'
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
    marginBottom:6,
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  }
})

const Usage = props => {

  const {
    classes,
    // theme,
    history,
    sessionData,
    state,
    dispatch
  } = props;

  const titles = [
    'DATA USAGE DASHBOARD',
    'CONTENT USAGE DASHBOARD'
    // 'USER INSIGHTS'
  ]
  const subTitles = [
    'Usage of data assets across the data ecosystem',
    'Usage of content assets across the data ecosystem'
    // 'User activity across the data system',
  ]
  
  return (
    <div className={classes.root}>
      {
        state.usageTabState!==2 && 
        <>
          <Typography className={classes.title}>{titles[state.usageTabState]}</Typography>
          <Typography className={classes.subTitle}>{subTitles[state.usageTabState]}</Typography>
        </>
      }
      {
        state.usageTabState===0 && 
        <Data state={state} dispatch={dispatch} history={history}/>
      }
      {
        state.usageTabState===1 && 
        <Content state={state} dispatch={dispatch} history={history}/>
      }
      {
        // state.usageTabState===2 && 
        // <People state={state} dispatch={dispatch} history={history}/>
      }
      {
        state.usageTabState===2 && 
        <BySource state={state} dispatch={dispatch} sessionData={sessionData} history={history}/>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(Usage));