import React, {useState } from 'react';
import { withTheme, withStyles,InputBase,Popper, Paper, ClickAwayListener, Typography, IconButton} from '@material-ui/core';
import { getIconComponent } from '../../../utilities';
import PropTypes from 'prop-types';

const styles = theme => ({
  paper:{
    background:theme.palette.background.main,
    maxHeight:'50vh',
    overflow:'auto',
    padding:'12px 0',
    border:`1px solid ${theme.palette.border.main}`,
    paddingBottom:8,
    ...theme.components.customScroll
  },
  inputBase:{
    ...theme.components.inputBase,
    width:'100%',
    height:50,
  },
  listItem:{
    height:48,
    overflow:"hidden",
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  releaseStateChip:{
    padding:'4px 8px',
    borderRadius:12,
    fontSize:13.75,
    marginRight:16
  }
})

const SourceSelector = props => {

  const {
    classes,
    theme,
    onChangeSource,
    sourceList=[],
    isDatabaseLevel,
    height,
    placeholder,
    defaultOpen
  } = props;
  
  const [value, setValue] = useState('')
  const [inputRef, setInputRef] = useState()
  const [popperOpen, setPopperOpen] = useState(defaultOpen)

  const generateGoups = (list) => {
    if(list.length===0)return <Typography style={{marginLeft:16,marginTop:4,marginBottom:4}}>No source found</Typography>
    let groups = {
      'data':[],
      'content':[],
      'other':[]
    };
    list.forEach(el=>{
      if(el.type==='DATABASE' || (isDatabaseLevel && el.source_template.type==='DATABASE') )groups.data.push(el);
      else if(el.type==='TOOL' || (isDatabaseLevel &&  el.source_template.type==='TOOL'))groups.content.push(el);
      else{groups.other.push(el)}
    })
    let sections = Object.keys(groups).filter(k=>groups[k].length>0).map(k=>(
      <div style={{marginBottom:16}}>
        <Typography style={{fontSize:12,letterSpacing:1,marginLeft:16}}>{k.toUpperCase()}</Typography>
        {
          groups[k].map(el=>(
            <div key={el.id} className={classes.listItem} onClick={()=>{onChangeSource(el.name, el);setValue('');setPopperOpen(false)}}>
              <div style={{width:24,height:24,marginRight:16,marginLeft:16}}>
                {getIconComponent({label:isDatabaseLevel?el.source_template.name:el.name, size:24, colour:theme.palette.primaryText.light,defaultLabel:'source'})}
              </div>
              <Typography style={{fontSize:16,overflow:'hidden',flexGrow:1,whiteSpace:'nowrap',textOverflow:'ellipsis',marginRight:16}}>
                {el.name}
              </Typography>
              {
                (isDatabaseLevel?el.source_template:el).release_state.name==='ALPHA' && 
                <div className={classes.releaseStateChip} style={{color:'#fff',background:'#D32F2F'}}>
                  Alpha
                </div>
              }
              {
                (isDatabaseLevel?el.source_template:el).release_state.name==='BETA' && 
                <div className={classes.releaseStateChip} style={{color:'#fff',background:'#FC642D'}}>
                  Beta
                </div>
              }
            </div>
          ))
        }
      </div>
    ))
    return sections;
  }

  return (
    <div>
      <InputBase
        className={classes.inputBase}
        style={{height}}
        inputRef={node => {setInputRef(node);}}
        value={value}
        placeholder={placeholder || 'Search for a Source type'}
        onChange={event=>{
          setValue(event.target.value)
          if(event.target.value!=='')setPopperOpen(true)
          else{setPopperOpen(false)}
        }}
        autoFocus
        onClick={()=>setPopperOpen(true)}
        endAdornment={
          <IconButton 
            disabled={value===''} 
            onClick={()=>setValue('')}
            style={{width:32,height:32,marginRight:6}}
          >
            {getIconComponent({label:value===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
          </IconButton>
        }
      />
      <Popper key={value} open={popperOpen} anchorEl={inputRef} placement={'bottom-start'} style={{marginTop:6,width:inputRef?inputRef.getBoundingClientRect().width+38:undefined}}>
        <ClickAwayListener onClickAway={()=>setPopperOpen(false)}>
          <Paper className={classes.paper} id="search_selector_popper">
            {
              generateGoups(sourceList.filter(s=>value.trim()==='' || s.name.toLowerCase().includes(value.toLowerCase())))
            }
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}

SourceSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onChangeSource: PropTypes.func.isRequired,
  sourceList: PropTypes.array.isRequired,
  isDatabaseLevel: PropTypes.bool,
  height: PropTypes.number,
  placeholder: PropTypes.string
};

export default withTheme()(withStyles(styles)(SourceSelector));