import React,{useEffect} from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Button} from '@material-ui/core';
import axiosCerebrum from '../../../../axios-cerebrum'
import List from './List'
import 'url-search-params-polyfill';
import { removeUrlQueryArg } from '../../../../utilities';

const styles = theme => ({
  normalText:{
    color:theme.palette.primaryText.main
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  subTitile:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  button:{
    minWidth:144,
    height:36,
    marginRight:24,
  },
  containedButton:{
    '&:hover':{
      background:'#5080cc'
    }
  }
})  

function SurveyList(props) {

  const {
    classes,
    state,
    dispatch,
    loadStats
  } = props;

  const loadSurveyCount = async ({isReload}) => {
    if(!isReload)dispatch({type:'set_survey_count',surveyCountLoading:true})
    let categories;
    try{
      categories = await axiosCerebrum.get('/api/surveys/categories')
    }catch(error){
      dispatch({type:'set_survey_count',surveyCountError:true})
      console.log(error)
    }
    let surveyCount = {}
    for(let i=0; i<categories.data.items.length; i++){
      let count;
      try{
        count = await axiosCerebrum.get(`/api/me/surveys`,{params:{category:categories.data.items[i].name,per_page:0,status:'OPEN'}});
        surveyCount[categories.data.items[i].name] = count.data.total;
      }catch(error){
        console.log(error)
      }
    }
    const urlSearch = new URLSearchParams(window.location.search);
    const category = urlSearch.get('category')
    if(category && surveyCount[category]){
      dispatch({type:'set_selected_survey_category',selectedSurveyCategory:category})
      window.history.replaceState(null, null,removeUrlQueryArg({url:window.location.toString(),keys:['category']}));
    }
    else if(!isReload){
      dispatch({type:'set_selected_survey_category',selectedSurveyCategory:Object.keys(surveyCount)[0]})
    }
    dispatch({type:'set_survey_count',surveyCount})
  }

  useEffect(()=>{
    if(!state.surveyCount || !state.surveyCountLoading)loadSurveyCount({})
    // eslint-disable-next-line
  },[])

  if(state.surveyCountLoading)return <CircularProgress color='secondary'/>
  if(state.surveyCountError)return <Typography className={classes.normalText}>Error occurred loading surveys</Typography>
  if(!state.surveyCount)return <div></div>

  if( Object.keys(state.surveyCount).length===0 )return <Typography className={classes.normalText}>No surveys available</Typography>

  let totalSurvey = 0;
  Object.keys(state.surveyCount).forEach(el=>{
    totalSurvey += state.surveyCount[el];
  })

  const getTabDispName = el => {
    if(el==='OPINION')return 'SHARE KNOWLEDGE'
    if(el==='FEEDBACK')return 'GIVE FEEDBACK'
    if(el==='ACCESS')return 'REVIEW ACCESS'
  }


  return (
    <div>
      <Typography className={classes.title}>{totalSurvey} PENDING ACTIONS</Typography>
      <Typography className={classes.subTitile}>Complete the actions to approve workflows, contribute knowledge and support a richer data ecosystem</Typography>
      <div style={{display:'flex',marginBottom:16, width:'max-content', maxWidth:'100%'}} id="knowledge_tabs_container">
        {
          Object.keys(state.surveyCount).map(el=>(
            <Button key={el} id={`knowledge_tab_${el.toLowerCase()}`} className={classes.button} classes={{contained:classes.containedButton}} color='primary' onClick={()=>dispatch({type:'set_selected_survey_category',selectedSurveyCategory:el})} variant={el===state.selectedSurveyCategory?"contained":'outlined'}>
              {getTabDispName(el)} ({state.surveyCount[el]})
            </Button>
          ))
        }
      </div>
      <List state={state} dispatch={dispatch} loadSurveyCount={loadSurveyCount} loadStats={loadStats}/>
    </div>
  )
}

export default withTheme()(withStyles(styles)(SurveyList));