import React, { useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, LinearProgress} from '@material-ui/core';
import theme from '../../../theme';
import ReactFlowWrapper from '../../UI/ReactFlowWrapper/ReactFlowWrapper';
import { globalListenerRef } from '../../../GlobalListenerRef';
import useAlert from '../../../hooks/useAlert';
import { getLinkList, getNodeList, initialiseMap, onReloadSwimlane } from './loadUtils';
import DetailDrawer from './DetailDrawer/DetailDrawer';
import MainControls from './MainControls/MainControls';
import { collectionIds, sendMessage } from '../../../utilities';
import LayerEditDrawer from './DetailDrawer/LayerEditDrawer';
import { nodeHorizontalGap, nodeTypes } from '../../UI/Lineage/LineageV3/layoutUtils';

const styles = theme => ({
  buttonText:{
    cursor:"pointer",
    fill:theme.palette.primary.main,
    fontSize:13.75,
  },
  button:{
    width:20,
    height:20,
    borderRadius:16,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  selectorText:{
    fontSize:13,
    letterspacing:1,
    marginRight:6
  }
})


function Map(props) {
  const {
    history,
    state,
    dispatch,
    sessionData
  } = props;

  const [graphHeight, setGraphHeight] = useState(500)
  const [graphWidth, setGraphWidth] = useState(900)
  const mapReRenderTimeout = useRef(null)

  const [allSourcesSolr, setAllSourcesSolr] = useState({})
  // eslint-disable-next-line
  const [nodes, setNodes] = useState(state.mapData?.nodes || [])
  // eslint-disable-next-line
  const [links, setLinks] = useState(state.mapData?.links || [])
  const [selectedItem, setSelectedItem] = useState(null)
  const [detailDrawerOpen, setDetailDrawerOpen] = useState(false)
  const [layerEditDrawerOpen, setLayerEditDrawerOpen] = useState(false)
  const [selectedSwimlane, setSelectedSwimlane] = useState(null)

  const [viewMode, setViewMode] = useState()

  // const [linkModalOpen, setLinkModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  // eslint-disable-next-line
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })


  const setDimensions = () => {
    setGraphWidth(document.getElementById('profile-content').getBoundingClientRect().width)
    setGraphHeight(window.innerHeight - 175)
  }

  useEffect(()=>{
    setDimensions()
    window.removeEventListener('resize',globalListenerRef.ecosytemMapListener)
    globalListenerRef.ecosytemMapListener = () => {
      setDimensions()
    }
    window.addEventListener('resize',globalListenerRef.ecosytemMapListener)
    return ()=>window.removeEventListener('resize',globalListenerRef.ecosytemMapListener)
  },[])

  useEffect(()=>{ 
    window.removeEventListener('message',globalListenerRef.sourceMapListener)
    globalListenerRef.sourceMapListener = (msg) => {
      if(!msg.data)return;
      if(msg.data.map_update_map){
        reRenderMap()
      }
      if(msg.data.map_alert_message){
        sendAlert({type:"info",message:msg.data.map_alert_message})
      }
      if(msg.data.map_open_detail_drawer){
        let id = msg.data.id;
        if(id===selectedItem?.id && detailDrawerOpen)return;
        let item = nodes.find(n=>n.id===id)
        setSelectedItem(item)
        setDetailDrawerOpen(true)
        setLayerEditDrawerOpen(false)
        reRenderMap()
      }
      if(msg.data.map_view_mode){
        setViewMode(msg.data.map_view_mode)
        reRenderMap()
      }
      if(msg.data.swimlane_click){
        let item = nodes.find(n=>n.id===msg.data.id)
        // setLinkModalOpen({obj:item.data.obj})
        setSelectedItem()
        setLayerEditDrawerOpen({obj:item})
        setSelectedSwimlane(item)
        setDetailDrawerOpen(false)
      }
      if(msg.data.map_error){
        setError(true)
        setLoading(false)
      }
    }
    window.addEventListener('message',globalListenerRef.sourceMapListener)

    return () => {
      isCancelledRef.current = true
      window.removeEventListener('message',globalListenerRef.sourceMapListener)
      dispatch({
        type:'set_map_data',
        mapData:{
          nodes:nodes,
          links:links
        }
      })
    }
  // eslint-disable-next-line
  },[sendAlert, nodes, links, selectedItem, detailDrawerOpen, dispatch, error, loading, history]) 

  
  useEffect(()=>{
    if(!detailDrawerOpen){
      setViewMode()
    }
  },[detailDrawerOpen])


  const reRenderMap = () => {
    // to prevent frequent re-rendering
    clearTimeout(mapReRenderTimeout.current)
    mapReRenderTimeout.current = setTimeout(()=>{
      forceUpdate();
    },100)
  }

  const loadMap = () => {
    initialiseMap({nodes, links, setLoading, setAllSourcesSolr, sessionData})
  }

  useEffect(()=>{
    if(!state.mapData?.nodes){
      loadMap()
    }
  // eslint-disable-next-line
  },[])

  const onRestore = () => {
    nodes.splice(0,nodes.length)
    links.splice(0,links.length)
    loadMap()
  }

  const getPresetCenter = (nodeList) => {
    let y = window.innerHeight / 2 + 40;
    let middleX = 0;

    let minX = 0;
    let maxX = 0;

    nodeList.forEach(n=>{
      if(n.type===nodeTypes.swimLaneType){
        let x = n.position.x;
        if(x<minX)minX = x;
        if(x>maxX)maxX = x;
      }
    })

    maxX += nodeHorizontalGap;
    middleX = (maxX + minX) / 2;

    return {x:middleX,y:y}
  }
  
  return (
    <div>
      <div style={{display:'flex',marginBottom:8}}>
        <div style={{flexGrow:1}}>
          <Typography style={{fontSize:20,color:theme.palette.header.main,}}>DATA ECOSYSTEM</Typography>
          <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>
            Data Sources, Tool and Reporting Platforms that make up the Data Ecosystem
          </Typography>
        </div>
      </div>
      {
        loading && 
        <div style={{textAlign:'center',marginTop:168}}>
          <LinearProgress color='secondary' style={{width:200,margin:'auto',marginBottom:24}}/>
          <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>Generating ecosystem map</Typography>
          <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main,marginTop:8}}>Geez you have a lot … this may take a few seconds.</Typography>
        </div>
      }
      {
        nodes?.length===0 && !loading && !error && 
        <Typography style={{marginTop:24}}>
          No layers added. <span onClick={()=>history.push(`/profile/collection/${collectionIds.sourceLayer}`)} style={{color:theme.palette.hyperLink.main, textDecoration:'underline', cursor:'pointer'}}>Add layers and sources to the map</span>
        </Typography>
      }
      {
        error && 
        <Typography style={{marginTop:24}}>Error occurred loading data</Typography>
      }
      {
        nodes?.length>0 && !loading && !error &&
        <div 
          style={{width:graphWidth,height:graphHeight}}
          onClick={()=>{
            sendMessage({close_control_drawer:true})
          }}
        >
          <ReactFlowWrapper
            initialNodes={getNodeList({nodes, selectedItem})}
            initialLinks={getLinkList({nodes, links, selectedItem, viewMode})}
            alwaysShowAllNodes={true}
            showEdgeBelowNode
            onFocusOffset={{x:300,y:170}}
            presetCenter={getPresetCenter(nodes)}
            hideMiniMap
            removeSwimlaneAutoFocus
            draggable={false}
          />
        </div>
      }
      {
        !error && !loading && nodes?.length>0 &&
        <>
          <DetailDrawer
            history={history}
            drawerOpen={detailDrawerOpen}
            setDrawerOpen={setDetailDrawerOpen}
            selectedItem={selectedItem}
          />
          <MainControls
            onRestore={onRestore}
            nodes={[...nodes]}
            history={history}
            sessionData={sessionData}
          />
          <LayerEditDrawer
            history={history}
            state={state}
            dispatch={dispatch}
            drawerOpen={layerEditDrawerOpen}
            setDrawerOpen={setLayerEditDrawerOpen}
            onUpdate={()=>{
              onReloadSwimlane({swimlane:selectedSwimlane, nodes, links, allSourcesSolr})
            }}
          />
        </>
      }
    </div>
  )

}

Map.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(Map));