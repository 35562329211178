import React,{useState,useEffect, createRef, useRef} from 'react';
import { withTheme, withStyles, Typography, CircularProgress, InputBase, IconButton, Select, MenuItem, Button} from '@material-ui/core';
import axiosCererbrum from '../../../axios-cerebrum'
import { onClickResultItem, getIconComponent, formatNumber } from '../../../utilities'
import { getIconLabel } from '../../UI/SearchResults/utils';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';
import CustomMenu from '../../UI/ContextMenu/ContextMenu'
import InstanceEditModal from '../EditableInstances.js/InstanceEditModal';
import WarningConfirmModal from '../../UI/ConfirmModals/WarningConfirmModal';

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  selector: {
    ...theme.components.selector,
		width: 150,
  },
  nameFilter:{
    ...theme.components.inputBase,
    marginRight:16,
    width:160,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
  listItem:{
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    height:48,
    display:'flex',
    alignItems:'center',
    boxSizing: 'border-box',
    overflow:'hidden',
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  itemName:{
    fontSize:13.75,
    overflow:"hidden",
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginRight:8,
    width:'max-content',
    flexShrink:1,
    flexGrow:1,
  },
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },

  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
})

function Details(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    history,
    instanceName,
    allowTreeList,
    editable
  } = props;

  const listRef = createRef();
  const [loadingMore, setLoadingMore] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const searchTimeoutRef = useRef();

  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [iconHovered, setIconHovered] = useState(false)

  const [alertOpen, setAlertOpen] = useState(false)

	// const [anchor, setAnchor] = useState()
	// const [listActionOpen, setListActionOpen] = useState(false);

  const sortByItems = [
    {dispName:'A-Z',value:'ALPHABETICAL'},
    {dispName:'Z-A',value:'REVERSE_ALPHABETICAL'},
  ]

  const loadInstances = ({page=1, sort, searchFilter=''}) => {
    if(page===1){
      dispatch({
        type: 'set_instance_list_data',
        instanceListData:undefined,
        instanceListLoading:true
      })
    }else{
      setLoadingMore(true)
    }
    axiosCererbrum
      .get(
        `/api/collectioninstances`,
        {params:{
          collection_id:state.basicData.id,
          per_page:20,
          page:page,
          'search.name':searchFilter===''?undefined:searchFilter,
          sort:sort
        }}
      ).then(response=>{
        dispatch({
          type: 'set_instance_list_data',
          instanceListData:
            page===1?
              response.data
              :
              {...response.data,items:[...state.instanceListData.items, ...response.data.items]}
        });
        setLoadingMore(false)
      }).catch(error=>{
        console.log(error)
        dispatch({
          type: 'set_instance_list_data',
          instanceListData:state.instanceListData,
          instanceListError:true
        })
        setLoadingMore(false)
      })
  }

  useEffect(()=>{
    if(state.instanceListData)return;
    loadInstances({searchFilter:state.instanceSearch,sort:state.instanceSort})
  // eslint-disable-next-line
  },[state.instanceListData, state.tabState])

  const onNameFilterChange = value => {
    dispatch({type:'set_instance_search',instanceSearch:value})
    clearTimeout(searchTimeoutRef.current);
    setIsSearching(true)
    searchTimeoutRef.current = setTimeout(()=>{
      loadInstances({searchFilter:value,sort:state.instanceSort})
      setIsSearching(false)
    },200)
    
  }
  
  const onSortChange = value => {
    dispatch({type:'set_instance_sort',instanceSort:value})
    loadInstances({searchFilter:state.instanceSearch,sort:value})
  }

  const isInViewport = (ref, offset = 0) => {
		if (!ref || !ref.current) return false;
		const bottom = ref.current.getBoundingClientRect().top;
		return (bottom + offset) >= 0 && (bottom + offset) <= window.innerHeight;
  }

  useEffect(()=>{
    if(state.instanceListData && isInViewport(listRef) && !loadingMore && state.instanceListData.page<state.instanceListData.pages){
      loadInstances({page:state.instanceListData.page+1,nameFilter:state.instanceSearch,sort:state.instanceSort})
    }
   // eslint-disable-next-line
  },[loadingMore, state.instanceListData])

  window.onscroll = () => {
    if(isInViewport(listRef) && !loadingMore && state.instanceListData.page<state.instanceListData.pages){
      loadInstances({page:state.instanceListData.page+1,nameFilter:state.instanceSearch,sort:state.instanceSort})
    }
  }

  const onDelete = () => {
    axiosCererbrum
      .delete(`/api/collectioninstances/${deleteModalOpen.item.id}`)
      .then(response=>{
        dispatch({
          type:'set_instance_list_data',
          instanceListData:{
            ...state.instanceListData,
            items:state.instanceListData.items.filter(el=>el.id!==deleteModalOpen.item.id),
            total:state.instanceListData.total-1
          }
        })
        setDeleteModalOpen(false)
      })
      .catch(error=>{
        console.log(error)
        setAlertOpen(true)
      })
  }
  
  return (
    <div className={classes.root}>
      <div style={{marginTop:8,marginBottom:16,display:'flex',alignItems:'flex-start'}}>
        <div style={{display:'flex',flexGrow:1,alignItems:'flex-start'}}>
          <Typography className={classes.title}>{`${state.instanceListData?formatNumber(state.instanceListData.total):'0'} ${instanceName}(S)`}</Typography>
          {
            editable && (state.instanceListData?.items?.length!==0 || state.instanceSearch || state.instanceListLoading || isSearching) &&
            <Button 
              color='primary' 
              data-test-id="child-edit-button"
              onClick={()=>{
                dispatch({type:'set_instance_editing',instanceEditing:!state.instanceEditing})
              }}
              style={{marginLeft:12}}
            >
              {state.instanceEditing?'CLOSE':'EDIT'}
            </Button>
          }
        </div>
        {
          (state.instanceListData?.items?.length!==0 || state.instanceSearch || state.instanceListLoading || isSearching) &&
          <>
            <InputBase
              value={state.instanceSearch}
              onChange={event => onNameFilterChange(event.target.value)}
              variant={'filled'}
              placeholder={'Search'}
              className={classes.nameFilter}
              data-test-id="child-search-input"
              endAdornment={
                <IconButton 
                  disabled={state.instanceSearch===''} 
                  onClick={()=>onNameFilterChange('')}
                  style={{width:32,height:32,marginRight:6}}
                >
                  {getIconComponent({label:state.instanceSearch===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              }
            />
            <Select
              className={classes.selector}
              value={state.instanceSort}
              data-test-id="child-sort-selector"
              onChange={event => onSortChange(event.target.value)}
            >
              {
                sortByItems.map(el => (
                  <MenuItem   className={classes.menuItem} value={el.value}>
                    <span>{el.dispName}</span>
                  </MenuItem>
                ))
              }
            </Select>
            {
              allowTreeList && 
              <Select
                className={classes.selector}
                value={state.instanceView}
                style={{width:100,marginLeft:16}}
                data-test-id="child-view-selector"
                onChange={event => dispatch({type:'set_instance_view',instanceView:'tree'})}
              >
                <MenuItem  className={classes.menuItem} value={'tree'}>
                  Tree
                </MenuItem>
                <MenuItem  className={classes.menuItem} value={'list'}>
                  List
                </MenuItem>
              </Select>
            }
          </>
        }
      </div>
      {
        state.instanceListError && <Typography className={classes.normalText}>Error occurred loading instances</Typography>
      }
      {
        state.instanceListLoading && <div style={{textAlign:'center',width:'100%'}}><CircularProgress color='secondary' style={{marginTop:50}}/></div>
      }
      {
        !state.instanceListError && !state.instanceListLoading && state.instanceListData && state.instanceListData.items && 
        state.instanceListData.items.map(item=>(
          <div>
            <ContextMenuTrigger id={item.id}>
              <div 
                data-test-classname="instance-list-item"
                className={classes.listItem}
                style={{background:iconHovered?theme.palette.background.main:undefined}}
                onClick={() => onClickResultItem({
                  item,
                  id:item.id,
                  label: 'collection_instance',
                  collection: 'collections',
                  history: history
                })}
              >
                <div 
                  style={{width:24,height:24,marginLeft:8,display:'flex',justifyContent:'center',alignItems:'center'}}
                >
                  {
                    getIconComponent({label:"dot",size:16,colour:theme.palette.primaryText.light})
                  }
                </div>

                <div style={{width:20,height:20,marginLeft:4,marginRight:8}}>
                  {
                    getIconComponent({
                      label:getIconLabel({label:'collection',item:state.basicData}),
                      size:20,
                      colour:theme.palette.primaryText.light
                    })
                  }
                </div>
                <Typography 
                  className={classes.itemName}
                  data-test-classname="instance-list-item-name"
                >
                  {item.name}
                </Typography>
                {
                  state.instanceEditing && 
                  <div style={{display:'flex',justifyContent:'flex-end',flexGrow:1}}>
                    <IconButton 
                      data-test-id={`expandable-item-edit-button-${item.name.replace(/\s/g,'-')}`}
                      data-test-classname={`expandable-item-edit-button`}
                      onClick={(event)=>{event.stopPropagation();setEditModalOpen({item})}} 
                      className={classes.iconButton}
                      onMouseEnter={()=>setIconHovered(true)}
                      onMouseOut={()=>setIconHovered(false)}
                      onMouseOver={()=>setIconHovered(true)}
                    >
                      {getIconComponent({label:'edit',size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                    <IconButton 
                      data-test-id={`expandable-item-clear-button-${item.name.replace(/\s/g,'-')}`}
                      data-test-classname={`expandable-item-clear-button`}
                      onClick={(event)=>{
                        event.stopPropagation();setDeleteModalOpen({item})
                      }} 
                      className={classes.iconButton}
                      onMouseEnter={()=>setIconHovered(true)}
                      onMouseOut={()=>setIconHovered(false)}
                      onMouseOver={()=>setIconHovered(true)}
                    >
                      {getIconComponent({label:'clear_circle',size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  </div>
                }
              </div>
            </ContextMenuTrigger>
            <ContextMenu id={item.id}>
              <CustomMenu
                item={item}
                actions={[
                  'open_new_tab',
                  'bookmark'
                ]}
              />
            </ContextMenu>
          </div>
        ))
      }
      {
        !state.instanceListError && !state.instanceListLoading && state.instanceListData && state.instanceListData.total===0 &&
        <Typography className={classes.normalText}>No {instanceName.toLowerCase()} found</Typography>
      }
      <div
        style={{ marginBottom: 40, display:'flex',justifyContent:'center' }}
        ref={listRef}
      >
        {
          loadingMore && <CircularProgress color='secondary'/>
        }
      </div>
      {
        editModalOpen && 
        <InstanceEditModal modalOpen={editModalOpen} setModalOpen={setEditModalOpen} data={editModalOpen.item} dispatch={dispatch} state={state}/>
      }
      <WarningConfirmModal
        description={`You are about to delete the ${deleteModalOpen.item?.name} instance.\n\nThis action cannot be reversed`}
        setModalOpen={setDeleteModalOpen}
        modalOpen={deleteModalOpen}
        onConfirm={()=>onDelete(false)}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        alertMessage={'Error occurred deleting instance'}
        confirmText={'YES'}
      />
    </div>
  )
}

export default withTheme()(withStyles(styles)(Details));