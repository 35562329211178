import React, { useRef, useState, useEffect } from 'react';
import { withTheme, withStyles, Modal, Paper, Typography, Button, LinearProgress } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import ModalAlert from '../../UI/ModalAlert/ModalAlert'
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  paper:{
    border:`1px solid ${theme.palette.border.main}`,
    padding:24,
    width:432,
    margin:'15vh auto 0px',
  },
  container:{
    width:482,
    margin:'auto',
    outline:'none'
  },
  title:{
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:16
  },
  bodyText:{
    fontSize:16,
    whiteSpace:'pre-wrap',
    color:theme.palette.primaryText.light,
  },
  buttons:{
    display:'flex',
    justifyContent:"flex-end",
    marginRight:-12,
    marginBottom:-12,
    marginTop:24
  },
})

function AddRuleModal(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    loadRules,
    loadAvailableCollections,
    selectedRule
  } = props;

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [deleting, setDeleting] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`linking-rule-delete`,
    isCancelledRef,
    disableStateCache:true
  })

  useEffect(()=>{ 
    return () => {
      isCancelledRef.current = true
    }
  },[])


  const onCancel = () => {
    dispatch({type:'set_delete_rule_modal_open',deleteRuleModalOpen:false})
  }

  const onDelete = () => {
    setDeleting(true)
    axiosCerebrum
      .delete(
        `/api/collectioninstances/rules/${selectedRule.id}`
      )
      .then(response=>{
        setDeleting(false)
        onCancel()
        loadRules({page:1})
        loadAvailableCollections()
        sendAlert({type:'info',message:"Rule successfully deleted"})
      })
      .catch(error=>{
        setDeleting(false)
        console.log(error)
        setAlertOpen(true)
        setAlertMessage(`Error occurred deleting the rule`)
      })
  }

  return (
    <Modal open={state.deleteRuleModalOpen} disableBackdropClick>
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Typography className={classes.title}>Delete {selectedRule && selectedRule.collection_instance.name} Linking Rule</Typography>
          {
            deleting?
            <div style={{textAlign:'center'}}>
              <LinearProgress color='secondary' style={{height:6,width:'80%',margin:'auto',marginTop:32}}/>
              <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Deletion in progress</Typography>
            </div>
            :
            <Typography className={classes.bodyText}>
              {`Are you sure?\n\nThis will permanently remove the rule`}
            </Typography>
          }
          <div className={classes.buttons}>
            <Button color='primary' disabled={deleting} style={{marginRight:24}} onClick={onDelete}>DELETE</Button>
            <Button color='secondary' disabled={deleting} onClick={onCancel}>CANCEL</Button>
          </div>
        </Paper>
        <ModalAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          type='error'
        />
      </div>
      
    </Modal>
  )
}


export default withTheme()(withStyles(styles)(AddRuleModal));