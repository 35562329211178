import React, {useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosSolr from '../../../../axios-solr';
import timezoneDetect from 'jstimezonedetect';
import axiosCerebrum from '../../../../axios-cerebrum';
import { getJobResult } from '../../../../utilities';
import Chart from './Chart';
import ChartLoadingIcon from './ChartLoadingIcon';

const styles = theme => ({
  headerSelector:{
    ...theme.components.titleSelector,
    marginRight:12
  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  tile:{
    maxWidth:150,
    flexGrow:1,
    flexShrink:1,
    textAlign:'center',
    height:123,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:4,
    '&:hover':{
      cursor:'pointer',
      background:theme.palette.hovered.main
    }
  },
  unclickable:{
    pointerEvent:'none',
    '&:hover':{
      cursor:'default',
      background:theme.palette.background.main
    }
  },
  selected:{
    background:theme.palette.primary.dark,
    '&:hover':{
      background:theme.palette.primary.dark
    },
    '& p':{
      color:theme.palette.background.main
    }
  },
  disabled:{
    cursor:'default',
    '&:hover':{
      cursor:'default',
    },
    pointerEvent:'none',
    opacity:0.5
  },
  tileName:{
    fontSize:12,
    letterSpacing:1.5,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginTop:8
  },
  tileValue:{
    fontSize:20,
    color:theme.palette.primaryText.main,
    marginTop:16,
  },
})


function DetailMetrics(props) {

  const {
    classes,
    dqInstances,
    object,
    history,
    detailsByDimension,
    setDetailsByDimension,
    selectedDetailDimension, 
    setSelectedDetailDimension,
    detailMetrics,
    setDetailMetrics,
    detailPeriod,
    setDetailPeriod,
    detailMetricsCache,
    setDetailMetricsCache,
    selectedDetailDate,
    setSelectedDetailDate,
    detailDateData,
    setDetailDateData,
    detailDateSort,
    setDetailDateSort
  } = props;

  const [loading, setLoading] = useState(false);
  let label = object.object_type_srt || object.object?.name 
  let apiRef = useRef(0)

  const loadDetailsByDimension = () => {
    let fq = `object_id_srt:${object.id}`
    if(label==='TABLE')fq += ` OR table_id_srt:${object.id}`
    if(label==='DATASET_TABLE')fq += ` OR dataset_table_id_srt:${object.id}`
    if(label==='DATASET')fq = `${fq} OR dataset_id_srt:${object.id}`

    setDetailsByDimension({loading:true})
    axiosSolr
      .get(
        `/solr/data_quality_test_result/select`,{params:{
          q:'*',
          fq:fq,
          rows:0,
          'json.facet':{
            'overall_avg':"avg(last_data_quality_score_srt)",
            'dimensions':{
              type:'terms',
              field:'data_quality_dimension_srt',
              limit:100,
              "facet":{
                "avg":"avg(last_data_quality_score_srt)",
                "dimension_id":{
                  type:'terms',
                  field:'data_quality_dimension_id_srt',
                  limit:1,
                }
              }
            }
          }
        }}
      )
      .then(response=>{
        if(response.data.response.numFound===0){
          setDetailsByDimension({data:[]})
          return;
        }
        let data = [];
        data.push({name:'OVERALL',value:response.data.facets.overall_avg,id:'overall',count:response.data.response.numFound})
        setSelectedDetailDimension('overall')
        apiRef.current += 1;
        loadMetrics({dimension:'overall', apiSeq:apiRef.current})

        let emptyDqs = dqInstances.filter(d=>!response.data.facets.dimensions?.buckets?.find(el=>el.val.toLowerCase()===d.name_txt.toLowerCase()));
        [...(response.data.facets.dimensions?.buckets||[]),...emptyDqs.map(el=>({val:el.name_srt || el.name_txt}))].sort((a,b)=>a.val.localeCompare(b.val)).forEach(bucket=>{
          data.push({name:bucket.val.toUpperCase(),value:bucket.avg,id:bucket.val,dimension_id:bucket.dimension_id?.buckets[0]?.val,count:bucket.count})
        })
        setDetailsByDimension({data})
      })
      .catch(error=>{
        console.log(error)
        setDetailsByDimension({error:true})
      })
  }

  const loadMetrics = ({dimension=selectedDetailDimension, period=detailPeriod, apiSeq}) => {
    let cacheID = `${object.id}_${dimension}_${period}`
    if(detailMetricsCache?.[cacheID]){
      setDetailMetrics({result:detailMetricsCache[cacheID].result})
      return;
    }
    let dimensionID = detailsByDimension?.data?.find(el=>el.id===dimension)?.dimension_id
    setDetailMetrics({loading:true})
    setLoading(true)
    axiosCerebrum
      .post(
        '/api/platformbatches/0',
        {
          "adhoc_args":{
            "job": "_0060_metrics",
            "object_id": object.id,
            'agg_method':['COUNT','AVG'],
            "tz": timezoneDetect.determine().name(),
            'related_collection_instance_id':dimensionID,
            // 'related_collection_id':dimension==='other'?collectionIds.dqDimensions:undefined,
            'days':period,
            "metric_type":'DATA_QUALITY_SCORE'
          }
        }
      )
      .then(response=>{
        let id = response.data.id;
        if(apiSeq!==apiRef.current)return;
        getJobResult(
          id,
          data=>{
            if(apiSeq!==apiRef.current)return;
            setLoading(false)
            setDetailMetrics({result:data.result.data})
            setDetailMetricsCache({...detailMetricsCache,[cacheID]:{result:data.result.data}})
          },
          (error)=>{
            console.log(error)
            setLoading(false)
            setDetailMetrics({error:true})
          }
        )
      })
      .catch(error=>{
        console.log(error);
        setLoading(false)
        setDetailMetrics({error:true})
      })
  }

  useEffect(()=>{
    if(!detailsByDimension)loadDetailsByDimension()
  // eslint-disable-next-line
  },[])
  
  return (
    <div className={classes.root}>
      {
        !selectedDetailDimension && 
        <>
          <Typography className={classes.header}>
            DQ SCORE PER RUN
          </Typography>
          <Typography className={classes.subTitle}>
            Select a dimension to see the tests run for that dimension
          </Typography>
        </>
      }
      {
        detailsByDimension?.loading &&
        <CircularProgress color='secondary'/>
      }
      {
        detailsByDimension?.error &&
        <Typography>
          Error loading data
        </Typography>
      }
      {
        detailsByDimension?.data?.length===0 &&
        <Typography>
          No DQ run found for this asset
        </Typography>
      }
      {
        selectedDetailDimension && 
        <>
          <div style={{display:'flex',alignItems:'center',marginBottom:4}}>
            <Select
              className={classes.headerSelector}
              value={selectedDetailDimension}
              disabled={loading}
              disableUnderline
              onChange={event=>{
                if(loading)return;
                setSelectedDetailDimension(event.target.value);
                setDetailDateData();
                setSelectedDetailDate();
                if(detailMetrics?.[event.target.value]?.result)return;
                apiRef.current+=1;
                loadMetrics({dimension:event.target.value, apiSeq:apiRef.current})
              }}
            >
              {
                detailsByDimension?.data?.map((item,index)=>(
                  <MenuItem key={item.id} value={item.id} disabled={!item.value}>
                    {item.name.toUpperCase()}
                  </MenuItem>
                ))
              }
            </Select>
            <Typography className={classes.header}>
              DQ SCORE PER RUN
              <Select
                className={classes.headerSelector}
                style={{marginLeft:12}}
                value={detailPeriod}
                disabled={detailMetrics?.loading}
                disableUnderline
                onChange={event=>{
                  setDetailPeriod(event.target.value)
                  apiRef.current+=1;
                  loadMetrics({dimension:selectedDetailDimension, period:event.target.value, apiSeq:apiRef.current})
                }}
              >
                {
                  [30,90,365].map(el=>(
                    <MenuItem disabled={detailMetrics?.loading} className={classes.menuItem} value={el}>
                      {el} DAYS
                    </MenuItem>
                  ))
                }
              </Select>
            </Typography>
          </div>
          <Typography className={classes.subTitle}>
            Select a day to see the tests run on that day
          </Typography>
          {
            detailMetrics?.loading &&
            <ChartLoadingIcon/>
          }
          {
            detailMetrics?.error &&
            <Typography>
              Error occurred loading details
            </Typography>
          }
          {
            detailMetrics?.result &&
            <Chart
              key={selectedDetailDimension+detailPeriod}
              object={object}
              history={history}
              data={detailMetrics.result}
              setSelectedDate={setSelectedDetailDate}
              selectedDate={selectedDetailDate}
              detailDateData={detailDateData}
              setDetailDateData={setDetailDateData}
              detailDateSort={detailDateSort}
              setDetailDateSort={setDetailDateSort}
              dimensionID={detailsByDimension?.data?.find(el=>el.id===selectedDetailDimension)?.dimension_id}
            />
          }
        </>
      }
    </div>
  )
}

DetailMetrics.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  detailsByDimension: PropTypes.object,
  setDetailsByDimension: PropTypes.func,
  selectedDetailDimension: PropTypes.string,
  setSelectedDetailDimension: PropTypes.func,
  detailMetrics: PropTypes.object,
  setDetailMetrics: PropTypes.func,
  selectedDetailDate: PropTypes.string,
  setSelectedDetailDate: PropTypes.func,
}

export default withTheme()(withStyles(styles)(DetailMetrics));