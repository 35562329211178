import axiosCerebrum from "./axios-cerebrum";
import axiosSolr from "./axios-solr";

export const CerebrumLongListLoader = ({url, params, per_page, onStartLoad, onFinishLoad, onError}) => {
  onStartLoad?.();

  const load = ({page, prevData=[]}) => {
    axiosCerebrum
      .get(
        url,
        {
          params: {
            ...params,
            page,
            per_page
          }
        }
      )
      .then(response=>{
        let currentData = response.data.items;
        if(response.data.pages===1){
          onFinishLoad?.({data: currentData});
        }else{
          let promises = [];
          if(response.data.pages>50){
            onError?.();
            return;
          }
          for(let i=2;i<=response.data.pages;i++){
            promises.push(
              axiosCerebrum
                .get(
                  url,
                  {
                    params: {
                      ...params,
                      page: i,
                      per_page
                    }
                  }
                )
            )
          }
          Promise.all(promises)
            .then(responses=>{
              responses.forEach(response=>{
                currentData = currentData.concat(response.data.items)
              })
              onFinishLoad?.({data: currentData});
            })
            .catch(error=>{
              onError?.(error);
            })
        }
      })
      .catch(error=>{
        onError?.(error);
      })
  }

  load({page: 1, prevData: []})
}

export const SolrLongListLoader = ({url, params, rows, onStartLoad, onFinishLoad, onError}) => {
  onStartLoad?.();
  const load = ({start}) => {
    axiosSolr
      .post(
        url,
        {
          params: {
            ...params,
            start,
            rows
          }
        }
      )
      .then(response=>{
        let currentData = response.data.response.docs;
        if(response.data.response.numFound===currentData.length){
          onFinishLoad?.({data: currentData});
        }else{
          if(response.data.response.numFound/rows>50){
            onError?.();
            return;
          }
          let promises = [];
          for(let i=rows;i<=response.data.response.numFound;i+=rows){
            promises.push(
              axiosSolr
                .get(
                  url,
                  {
                    params: {
                      ...params,
                      start: i,
                      rows
                    }
                  }
                )
            )
          }
          Promise.all(promises)
            .then(responses=>{
              responses.forEach(response=>{
                currentData = currentData.concat(response.data.response.docs)
              })
              onFinishLoad?.({data: currentData});
            })
            .catch(error=>{
              onError?.(error);
            })
        }
      })
      .catch(error=>{
        onError?.(error);
      })
  }

  load({page: 1, prevData: []})
}