
export const checkFilterApplied = (state) => {
  return state.collection!=='all' || state.collectionType!=='all' || state.source!=='all' || state.team!=='all' || state.userType!=='all' || state.issueSource!=='all' || state.database!=='all' || state.schema!=='all' || state.table!=='all' || state.location!=='all' || state.reference!=='all'
}

export const getFilterValues = state => {
  return {
    collection: state.collection,
    collectionType: state.collectionType,
    collectionParent: state.collectionParent,
    source: state.source,
    team: state.team,
    userType: state.userType,
    issueSource: state.issueSource,
    database: state.database,
    schema: state.schema,
    table: state.table,
    location: state.location,
    reference: state.reference
  }
}

export const filterMap = ({state, dispatch, getValuesOnly }) => {
  const collections = state.filterCollections;
  const setCollections = value => dispatch({type:'set_filter_collections',filterCollections:value})
  const collectionParentList = state.filterCollectionParentList;
  const setCollectionParentList = value => dispatch({type:'set_filter_collection_parent_list',filterCollectionParentList:value})
  const sources = state.filterSources;
  const setSources = value => dispatch({type:'set_filter_sources',filterSources:value})
  const issueSources = state.filterIssueSources;
  const setIssueSources = value => dispatch({type:'set_filter_issue_sources',filterIssueSources:value})
  const teams = state.filterTeams;
  const setTeams = value => dispatch({type:'set_filter_teams',filterTeams:value})
  const userTypes = state.filterUserTypes;
  const setUserTypes = value => dispatch({type:'set_filter_user_types',filterUserTypes:value})
  const databases = state.filterDatabases;
  const setDatabases = value => dispatch({type:'set_filter_databases',filterDatabases:value})
  const schemas = state.filterSchemas;
  const setSchemas = value => dispatch({type:'set_filter_schemas',filterSchemas:value})
  const tables = state.filterTables; 
  const setTables = value => dispatch({type:'set_filter_tables',filterTables:value})
  const locations = state.filterLocations;
  const setLocations = value => dispatch({type:'set_filter_locations',filterLocations:value})

  if(getValuesOnly){
    return {
      collections, collectionParentList, sources, issueSources, teams, userTypes, databases, schemas, tables, locations
    }
  }

  return {
    collections, setCollections,
    collectionParentList, setCollectionParentList,
    sources, setSources,
    issueSources, setIssueSources,
    teams, setTeams,
    userTypes, setUserTypes,
    databases, setDatabases,
    schemas, setSchemas,
    tables, setTables,
    locations, setLocations
  }
}

export const recoverFilterValues = ({filters, dispatch}) => {
  dispatch({type:'set_collection',collection:filters.collection})
  dispatch({type:'set_collection_parent',collectionParent:filters.collectionParent})
  dispatch({type:'set_collection_type',collectionType:filters.collectionType})
  dispatch({type:'set_source',source:filters.source})
  dispatch({type:'set_database',database:filters.database})
  dispatch({type:'set_schema',schema:filters.schema})
  dispatch({type:'set_team',team:filters.team})
  dispatch({type:'set_user_type',userType:filters.userType})
  dispatch({type:'set_reference',reference:filters.reference})
}

export const getFilterHistorys = ({validObjectTypes}) => {
  let history = [];
  try{
    history = JSON.parse(localStorage.getItem('filterHistorys')) || [];
  }
  catch(e){
    console.error(e);
    localStorage.removeItem('filterHistorys');
  }
  return history.filter(h => !validObjectTypes || validObjectTypes.includes(h.objectType));
}

export const getFilterDispName = ({filters, state}) => {
  let dispName = '';
  let arr = []
  if(filters.collection && filters.collection!=='all'){
    arr.push(state.filterCollections.find(c => c.id === filters.collection).name_txt)
  }
  if(filters.collectionType && filters.collectionType!=='all'){
    arr.push(state.filterCollectionTypes.find(c => c.id === filters.collectionType).name)
  }
  if(filters.collectionParent && filters.collectionParent!=='all'){
    arr.push(filters.collectionParent)
  }
  if(filters.source && filters.source!=='all'){
    arr.push(state.filterSources.find(c => c.id === filters.source).name)
  }
  if(filters.team && filters.team!=='all'){
    arr.push(state.filterTeams.find(c => c.id === filters.team).name_txt)
  }
  if(filters.userType && filters.userType!=='all'){
    arr.push(state.filterUserTypes.find(c => c.id === filters.userType).name)
  }
  if(filters.issueSource && filters.issueSource!=='all'){
    arr.push(state.filterIssueSources.find(c => c.id === filters.issueSource).name)
  }
  if(filters.database && filters.database!=='all'){
    arr.push(state.filterDatabases.find(c => c.id === filters.database).name_txt)
  }
  if(filters.schema && filters.schema!=='all'){
    arr.push(state.filterSchemas.find(c => c.id === filters.schema).name_txt)
  }
  if(filters.table && filters.table!=='all'){
    arr.push(state.filterTables.find(c => c.id === filters.table).name_txt)
  }
  if(filters.location && filters.location!=='all'){
    arr.push(filters.location)
  }
  // if(filters.reference!=='all'){
  //   arr.push(`reference: ${filters.reference}`)
  // }
  dispName = arr.join(', ');
  return dispName;
}

export const onAddFilterHistory = ({filters, objectType, state}) => {
  let history = getFilterHistorys({});
  let newRecord = {
    dispName: getFilterDispName({filters, state}),
    filters,
    objectType
  }
  if(history.find(h => JSON.stringify(h.filters) === JSON.stringify(filters) && h.objectType === objectType)){
    return;
  }
  // add to the start of history
  history.unshift(newRecord);
  history.slice(0,10)
  localStorage.setItem('filterHistorys', JSON.stringify(history));
}
