import React, {useEffect, useRef, useState} from 'react';
import { withTheme, Typography, withStyles, Button, CircularProgress } from '@material-ui/core';
import { sendMessage } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import SnackBar from '../SnackBar/SnackBar';
import PropTypes from 'prop-types'
import useAlert from '../../../hooks/useAlert';
import { withRouter } from 'react-router-dom'
import AddCartConfirmModal from './AddCartConfirmModal';
import AddCartBulkAdder from './AddCartBulkAdder';

const styles = theme => ({
});

export const addToCartLimit = 10000;

const CartAdder = props => {

  const {
    classes,
    history,
    theme,
    objectIds,
    completeMsgFormatter,
    onFinish,
    onError,
    onModalClose
  } = props;

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [snackBarButton, setSnackBarButton] = useState([]);
  const [keepSnackbarOpen, setKeepSnackBarOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [bulkModalOpen, setBulkModalOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const progressRef = useRef(0);
  const [bulkModalAlertMessage, setBulkModalAlertMessage] = useState('');
  const [bulkModalAlertOpen, setBulkModalAlertOpen] = useState(false);

  const stopAddingRef = useRef(false)

  const isCancelledRef = useRef(false)
  const snackbarTimeout = useRef(null)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  const processAddToCart = () => {
    let isModalAdder = objectIds.length>1000;
    if(isModalAdder){
      clearTimeout(snackbarTimeout.current)
    }
    let promises = [];
    
    let chunks = objectIds.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index/500)
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])

    if(!isModalAdder){
      chunks.forEach(chunk=>{
        promises.push(axiosCerebrum.post(`/api/me/cart`,{object_ids:chunk}))
      })
  
      Promise
        .all(promises)
        .then(response=>{
          setConfirmModalOpen(false)
          clearTimeout(snackbarTimeout.current)
          setKeepSnackBarOpen(false)
          setSnackBarOpen(true)
          let msg = `${objectIds.length} item(s) added to cart`;
          if(completeMsgFormatter){
            msg = completeMsgFormatter(msg)
          }
          setSnackBarMessage(msg)
          setSnackBarButton(<Button color='secondary' variant="outlined" onClick={()=>history.push('/cart')}>GO TO CART</Button>)
          sendMessage({reloadCart:true})
          onFinish?.()
        })
        .catch(error=>{
          console.log(error)
          setSnackBarOpen(false)
          onFinish?.()
          onError?.()
          sendAlert({message:'Error occurred adding items to Cart',type:'error'})
        })
    }else{
      // same as the non bulk mode, but only call two chunks at a time and acummulate the progress
      setConfirmModalOpen(false)
      setBulkModalOpen(true)
    }
  }

  const onProgressiveAddToCart = async () => {

    let promises = [];
    
    let chunks = objectIds.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index/500)
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])

    let isError;
    let isUserCancelled;
    for(let i=0;i<chunks.length;i++){
      if(isCancelledRef.current){
        break;
      }
      promises.push(axiosCerebrum.post(`/api/me/cart`,{object_ids:chunks[i]}))
      await Promise
        .all(promises)
        .then(response=>{
          let addedItemsCount = chunks[i].length
          progressRef.current += addedItemsCount
          setProgress(progressRef.current)
        })
        // eslint-disable-next-line
        .catch(error=>{
          console.log(error)
          isError = true
        })
      if(stopAddingRef.current){
        isUserCancelled = true
        break;
      }
      if(isError){
        onError?.()
        setBulkModalAlertMessage(`Error occurred adding items to Cart. ${progressRef.current} of ${objectIds.length} items added.`)
        setBulkModalAlertOpen(true)
      }
    }
    
    if(isUserCancelled){
      setBulkModalOpen(false)
      onError?.()
      sendAlert({message:`Action cancelled by user. ${progressRef.current} of ${objectIds.length} items added.`,type:'error'})
      onFinish?.()
      stopAddingRef.current = false
    }

  }

  const onAddToCart = () => {

    snackbarTimeout.current = setTimeout(()=>{
      setKeepSnackBarOpen(true)
      setSnackBarOpen(true)
      setSnackBarMessage(
        <div style={{display:'flex'}}>
          <CircularProgress color='secondary' style={{width:24,height:24}}/>
          <Typography style={{fontSize:13.75,marginLeft:16,color:theme.palette.snackbarContent.main}}>Adding to cart...</Typography>
        </div>
      )
    },1000)

    axiosCerebrum
      .get(
        `/api/me/cart`,{
          params:{
            per_page:0
          }
        }
      )
      .then(response=>{
        if(response.data.total===0){
          processAddToCart()
        }else{
          clearTimeout(snackbarTimeout.current)
          setSnackBarOpen(false)
          setConfirmModalOpen(true)
        }
      })
      .catch(error=>{
        console.log(error)
        setSnackBarOpen(false)
        onFinish && onFinish()
        sendAlert({message:'Error occurred adding items to Cart',type:'error'})
      })
  }

  useEffect(()=>{
    setProgress(0)
    progressRef.current = 0
    if(!objectIds)return;
    if(objectIds.length===0){
      sendAlert({message:'No item to be added',type:'info'})
      onFinish && onFinish()
      return;
    }
    if(objectIds.length>0){
      onAddToCart()
    }
  // eslint-disable-next-line
  },[objectIds])


  return (
    <div className={classes.root}>
      <SnackBar
         snackBarOpen={snackBarOpen}
         keepOpen={keepSnackbarOpen}
         setSnackBarOpen={setSnackBarOpen}
         message={snackBarMessage}
         buttons={snackBarButton}
      />
      <AddCartConfirmModal
        modalOpen={confirmModalOpen}
        setModalOpen={setConfirmModalOpen}
        processAddToCart={processAddToCart}
        onModalClose={onModalClose}
      />
      <AddCartBulkAdder
        history={history}
        modalOpen={bulkModalOpen}
        setModalOpen={setBulkModalOpen}
        progress={progress}
        onProgressiveAddToCart={onProgressiveAddToCart}
        alertOpen={bulkModalAlertOpen}
        setAlertOpen={setBulkModalAlertOpen}
        alertMessage={bulkModalAlertMessage}
        setAlertMessage={setBulkModalAlertMessage}
        objectIds={objectIds}
        onFinish={onFinish}
        onCancel={()=>{
          stopAddingRef.current = true
        }}
        onModalClose={onModalClose}
      />
    </div>
  )
}

CartAdder.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  objectIds: PropTypes.array.isRequired,
  onFinish: PropTypes.func.isRequired,
  completeMsgFormatter: PropTypes.func,
}

export default withTheme()(withStyles(styles)(withRouter(CartAdder)));
