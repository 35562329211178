import React, { useEffect, useRef, useState } from 'react';
import { withTheme,withStyles, Typography, Modal, Paper, Button, Select, MenuItem, Checkbox, InputBase, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../axios-cerebrum';
import { updatePlatformSettings } from '../../../permissionChecker';
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
import WarningConfirmModal from '../../UI/ConfirmModals/WarningConfirmModal';
import useAlert from '../../../hooks/useAlert';
import { settingsHidden, settingsPrefixHidden } from './utils';
import MultiStringInput from '../../OnBoardSource/CreateSource/MultiStringInput';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import { getIconComponent } from '../../../utilities';

const styles = theme => ({
  checkbox:{
    paddingLeft:0
  },
  textField:{
    width:'100%',
    boxSizing:'border-box',
    // background:theme.palette.background.main,
    margin:0,

    '&:hover':{
      backgroundColor:'transparent'
    },
    '& input':{
      height:46,
      padding:0,
      paddingLeft:12,
      paddingRight:12,
      color:theme.palette.primaryText.main,
      border:`1px solid ${theme.palette.border.main}`,
      backgroundColor:theme.palette.chip.main,
    },
    '& div':{
      padding:0,
    },
    '& textarea':{
      padding:12,
      wordBreak:'break-word',
      color:theme.palette.primaryText.main,
      border:`1px solid ${theme.palette.border.main}`,
      backgroundColor:theme.palette.chip.main,
    },
    '& p':{
      margin:0,
      // background:theme.palette.background.main,
      padding:'8px 12px 0',
      color:theme.palette.primaryText.light,
      lineHeight:'normal',
      whiteSpace:'pre-wrap',
    }
  },
  selector:{
    ...theme.components.selector,
    overflow:'hidden',
    width:'100%',
    minWidth:140,
    height:48
  },
  modalSubTitle:{
    marginBottom:16,
    color:theme.palette.primaryText.light,
    fontSize:12
  },
  errorBorder:{
    '& input':{
      border:`1px solid ${theme.palette.error.main}`
    },
    '& textarea':{
      border:`1px solid ${theme.palette.error.main}`
    }
  }
});

const ConfigModal = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    clickedItem,
    setClickedItem,
    modalOpen, 
    setModalOpen,
    onUpdate
  } = props;

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  let initialValue = clickedItem?(clickedItem.cron?JSON.stringify(clickedItem.cron):clickedItem.value):undefined;
  if(clickedItem?.type==='JSON' && initialValue){
    try{
      initialValue =  JSON.stringify(JSON.parse(initialValue),null,2)
    }catch{}
  }
  const [updatedValue, setUpdatedValue] = useState(initialValue);


  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [resetView, setResetView] = useState(false);

  // const secretIds = [5, 8]

  const resetModal = () => {
    setUpdatedValue()
    setClickedItem()
  }


  const onSave = ({value=updatedValue}) => {
    setLoading(true)
    if (clickedItem.key) {
      let modifiedItem = { value: value }
      axiosCerebrum.put(
        `/api/settings/${clickedItem.id}`, modifiedItem
      ).then((response) => {
        dispatch && dispatch({
          type: 'set_settings_data',
          settingsData: updatePlatformSettings(response.data).filter(el=>settingsPrefixHidden.every(p=>!el.key.includes(p)) && !settingsHidden.includes(el.id))
        })
        setResetView(false)
        setLoading(false)
        onUpdate && onUpdate(response.data)
        setModalOpen(false);
        sendAlert({message:'Setting updated',type:'info'})
      }).catch(error => {
        console.log(error)
        setLoading(false)
        setAlertMessage(`There was a problem. Please try again. Contact KADA support if the problem persists`)
        setAlertOpen(true)
      })
      return;
    }
    if (clickedItem.cron) {
      let modifiedItem;
      try{
        modifiedItem = { cron: JSON.parse(value) }
      }catch(error){
        console.log(error)
        setLoading(false)
        setAlertMessage(`The value entered is not a valid JSON`)
        setAlertOpen(true)
      }
      if(!modifiedItem){
        setLoading(false)
        return;
      }
      axiosCerebrum.put(
        `/api/platformbatches/${clickedItem.id}`, modifiedItem
      ).then(() => {
        dispatch && dispatch({
          type: 'set_jobs_data',
          jobsData: [...state.jobsData.filter(el => el.id !== clickedItem.id),
          {
            ...clickedItem,
            "cron": JSON.parse(value)
          }]
        })
        setLoading(false)
        sendAlert({message:'Setting updated',type:'info'})
      }).catch(error => {
        console.log(error)
        setLoading(false)
        setAlertMessage(`There was a problem. Please try again. Contact KADA support if the problem persists`)
        setAlertOpen(true)
      })
      setModalOpen(false);
      return;
    }

  }

  const onSetDefault = () => {
    setUpdatedValue(clickedItem.default_value);
    setTimeout(()=>onSave({value:clickedItem.default_value}));
  }

  const checkInputValid = (value, type) => {
    if(type==='INTEGER'){
      return value.match(/^\d+$/)
    }
    if(type==='JSON'){
      let valid = true;
      try{
        JSON.parse(value)
      }catch{
        valid = false
      }
      return valid;
    }
    return true;
  }
  
  if(!clickedItem)return <div></div>
  
  if(resetView){
    return (
      <WarningConfirmModal
        description={
          <span>You are about to reset this property.{'\n'}Any customisations you have made will be lost. </span>
        }
        setModalOpen={setResetView}
        modalOpen={resetView}
        onConfirm={()=>onSetDefault()}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        alertMessage={alertMessage}
        confirmText={'RESET'}
        closeText={'CANCEL'}
        disableConfirmButton={loading}
      />
    )
  }

  return (
    <Modal
      open={modalOpen}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      hideBackdrop={false}
      style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      {
        modalOpen &&
        <div>
          <Paper style={{ width: 800,maxWidth:'95vw', padding: '24px 24px 8px', outline:'none',background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}` }}>
            <Typography  style={{color:theme.palette.header.main, fontSize: 20, marginBottom: 4 }}>
              EDIT SETTING
              {
                // clickedItem.key ? `${clickedItem.cron?"SCHEDULE":'EDIT'} ${clickedItem.key.toUpperCase()}` : `${clickedItem.cron?"SCHEDULE":'EDIT'} ${clickedItem.name.toUpperCase()}`
              }
            </Typography>
            {
              <Typography className={classes.modalSubTitle}>
                {
                  clickedItem.id===6000 && 'Users that can edit data and content verified, classification, owner and steward properties.'
                }
                {
                  clickedItem.id===6030 && "Users that can link data and content."
                }
              </Typography>
            }
            <div style={{display:'flex',alignItems:'flex-start',overflow:"hidden"}}>
              <Typography style={{fontSize:16,flex:'0 1 200px',minWidth:80,marginTop:12,marginRight:20,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                {clickedItem.display_name}
              </Typography>
              <div style={{flex:'1 1 50%',overflow:'hidden'}}>
                {
                  clickedItem.type==='DROPDOWN_MULTI' && 
                  <Select
                    value={updatedValue.split(',')}
                    multiple
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      }
                    }}
                    disableUnderline
                    className={classes.selector}
                    onChange={(event)=>{
                      setUpdatedValue(event.target.value.join(','))
                    }}
                    renderValue={()=>{
                      return updatedValue.split(',').join(', ')
                    }}
                  >
                    {
                      clickedItem.dropdown_values.map(el=>(
                        <MenuItem value={el}>
                          <Checkbox className={classes.checkbox} checked={updatedValue.split(',').includes(el)} color='primary'/>
                          {el}
                        </MenuItem>
                      ))
                    }
                  </Select>
                }
                {
                  clickedItem.type==='DROPDOWN_SINGLE' && 
                  <Select
                    value={updatedValue}
                    onChange={(event)=>setUpdatedValue(event.target.value)}
                    className={classes.selector}
                  >
                    {
                      clickedItem.dropdown_values.map(el=>(
                        <MenuItem value={el}>
                          {el}
                        </MenuItem>
                      ))
                    }
                  </Select>
                }

                {
                  clickedItem.type==='BOOLEAN' && 
                  <Select
                    value={updatedValue}
                    onChange={(event)=>setUpdatedValue(event.target.value)}
                    className={classes.selector}
                    disableUnderline
                  >
                    <MenuItem value='true'>
                      true
                    </MenuItem>
                    <MenuItem value='false'>
                      false
                    </MenuItem>
                  </Select>
                }
                
                {
                  ['STRING','TEXT_BOX','PASSWORD','INTEGER','JSON','URL'].includes(clickedItem.type) && 
                  <InputBase
                    id="setting_value"
                    variant="filled"
                    className={classes.textField + (checkInputValid(updatedValue,clickedItem.type)?'':(' '+classes.errorBorder))}
                    InputProps={{disableUnderline:true}}
                    type={clickedItem.type==='PASSWORD'?"password":undefined}
                    value={updatedValue}
                    onChange={event => {
                      if(event.target.value.trim()==='')setUpdatedValue('');
                      if(clickedItem.id!==200){
                        setUpdatedValue(event.target.value)
                      }else{
                        if(event.target.value.length<=30){setUpdatedValue(event.target.value)}
                      }
                    }}
                    multiline={['JSON','TEXT_BOX'].includes(clickedItem.type)}
                    rows={['JSON','TEXT_BOX'].includes(clickedItem.type)?10:undefined}
                    // helperText={clickedItem.type==='INTEGER'?'Integer only':undefined}
                    margin="normal"
                  />
                }
                {
                  ['TEXT_FIELD_MULTI'].includes(clickedItem.type) &&
                  <div>
                    <MultiStringInput
                      onAdd={value=>{
                        setUpdatedValue([...updatedValue.split(',').filter(el=>el),value].join(','))
                      }}
                      placeholder={ `Add a value`}
                    />
                    {
                      updatedValue && 
                      <div style={{marginBottom:16}}></div>
                    }
                    {
                      updatedValue?.split?.(',').map((el,index)=>(
                        <SimpleResultItem
                          title={el}
                          item={el}
                          titleColour={theme.palette.primaryText.main}
                          showUnderline
                          hideIcon
                          tailObject={
                            <IconButton 
                              onClick={()=>{
                                let newValues = updatedValue.split(',').filter((v,i)=>v!==el).join(',');
                                setUpdatedValue(newValues)
                              }}
                            >
                              {getIconComponent({label:'clear',size:16,color:theme.palette.primaryText.light})}
                            </IconButton>
                          }
                        />
                      ))
                    }
                  </div>
                }
                {
                  <Typography style={{fontSize:12,marginTop:2,marginLeft:12,color:checkInputValid(updatedValue,clickedItem.type)?theme.palette.primaryText.light:theme.palette.error.main}}>
                    {
                      clickedItem.type==='INTEGER' && 'Integer only'
                    }
                    {
                      clickedItem.type==='JSON' && 'Must be valid JSON'
                    }
                  </Typography>
                }
              </div>  
            </div>  
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end',marginTop:16 }}>
              {
                clickedItem.default_value &&
                <Button color='primary' style={{ height: 36, marginRight: 8 }} onClick={()=>setResetView(true)} >RESET</Button>
              }
              <Button color='primary' disabled={!checkInputValid(updatedValue,clickedItem.type)} style={{ width: 90, height: 36, marginRight: 8 }} onClick={()=>onSave({})} >SAVE</Button>
              <Button color='secondary' style={{ width: 90, height: 36 }} onClick={() => { resetModal(); setModalOpen(false) }}>CLOSE</Button>
            </div>
          </Paper>
          <ModalAlert
            open={alertOpen}
            setOpen={setAlertOpen}
            message={alertMessage}
            type='error'
          />
        </div>
      }
    </Modal>
  )
}


ConfigModal.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  nodeKey: PropTypes.string.isRequired,
  memberData: PropTypes.array,
  memberNum: PropTypes.number,
  dispatch: PropTypes.func
}

export default withTheme()(withStyles(styles)(ConfigModal));
