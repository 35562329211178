import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import AdditionalPropertyAdder from '../../UI/AdditionalPropertyAdder/AdditionalPropertyAdder'
import AccessAdder from '../../UI/AccessAdder/AccessAdder'
import GlossaryAdder from '../../UI/GlossaryAdder/GlossaryAdder';
import SourceDescription from '../../UI/SourceDescription/SourceDescription';

const styles = theme => ({
  root: {

  },
  title: {
    fontSize: '1.25rem'
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
})

function Details(props) {

  const {
    classes,
    fetchList,
    history,
    state,
    dispatch,
    cerebrumLabel,
  } = props;

  return (
    <div className={classes.root}>

      <UpdateInputCerebrum
        url={`/api/${cerebrumLabel}/${state.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        label={'content'}
        data={state.basicData}
        initialValue={state.basicData.description}
        property="description"
        placeholder="Add a description"
        multiline
        fetchList={fetchList}
        collection="content"
        enableMarkDown={true}
        enableWidget={true}
        state={state}
      />

      <SourceDescription
        state={state}
        dispatch={dispatch}
      /> 

      <div style={{ marginTop: 60 }} >
        <GlossaryAdder
          state={state}
          dispatch={dispatch}
          history={history}
          disableModal
        />
      </div>

      <div style={{ marginTop: 60 }} >
        <AdditionalPropertyAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.additionalPropertiesModalOpen}
          setModalOpen={value=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:value})}
        />
      </div>

      <div style={{ marginTop: 60 }} >
        <AccessAdder
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.accessModalOpen}
          setModalOpen={value=>dispatch({type:'set_access_modal_open',accessModalOpen:value})}
        />
      </div>

    </div>
  )
}

Details.propTypes = {
  root: PropTypes.object.isRequired,
  statement: PropTypes.string,
  description: PropTypes.string,
  userName: PropTypes.string,
  fetchList: PropTypes.func.isRequired
}

export default withStyles(styles)(Details);