import React from 'react';
import { withStyles } from '@material-ui/core';
import QuestionsContainer from '../QuestionsContainer/QuestionsContainer';
import History from '../History/History'

const styles = theme => ({
  root:{
    marginBottom:30
  }
})

function Body(props) {

  const {
    classes,
    history,
    state,
    dispatch,
    showQuestions
  } = props;
  
  let bodyContent;
  if(showQuestions){
    switch (state.tabState) {
      case 0: 
        bodyContent = (
          <QuestionsContainer
            state={state}
            dispatch={dispatch}
            history={history}
          />
        );
        break;
      case 1:
        bodyContent = (
          <History
            state={state}
            dispatch={dispatch}
            history={history}
          />
        );
        break;
      default:
    }
  }else{
    switch (state.tabState) {
      case 0:
        bodyContent = (
          <History
            state={state}
            dispatch={dispatch}
            history={history}
          />
        );
        break;
      default:
    }
  }


  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

export default withStyles(styles)(Body);