import React from 'react';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import LineageV3 from '../../../UI/Lineage/LineageV3/LineageV3';
import LineageChart from '../../../UI/Lineage/LineageGraph/LineageChart';
import { mapSearchObjectName } from '../../../../utilities';
import { isV3Lineage } from '../../../UI/Lineage/utils';

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.primaryText.main,
    marginBottom:12
  },
})

const Map = props => {

  const {
    classes,
    history,
    state,
    dispatch,
    direction
  } = props;
  
  if(!state.historyObject)return null;

  return (
    <div>
      <Typography className={classes.title}>
        MAP VIEW
      </Typography>
      {
        isV3Lineage()?
        <LineageV3
          key={state.showReference}
          history={history}
          root={state.historyObject}
          state={state}
          dispatch={dispatch}
          downstreamOnly={direction==='downstream'?true:false}
          upstreamOnly={direction==='upstream'?true:false}
          hideReplaced={true}
          disableCache
          hideControls
          forceIsShowReference={state.showReference?true:false}
        />
        :
        <LineageChart
          key={state.showReference}
          history={history}
          root={state.historyObject}
          rootLabel={mapSearchObjectName(state.historyObject.object_type_txt)}
          state={state}
          dispatch={dispatch}
          downstreamOnly={direction==='downstream'?true:false}
          upstreamOnly={direction==='upstream'?true:false}
          hideReplaced={true}
          disableCache
          forceIsShowReference={state.showReference?true:false}
        />
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(Map));