import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import { withTheme, Typography, withStyles, ClickAwayListener, InputBase, InputAdornment, CircularProgress, Select, MenuItem, Button } from '@material-ui/core';
import { getIconComponent, onClickResultItem, getDispFields, getPartialMatchSearchString } from '../../../utilities';
import { addDetail, templates } from './Templates';
import axiosSolr from '../../../axios-solr'
import SimpleResultItem from '../SearchResults/SimpleResultItem';
import axiosCerebrum from '../../../axios-cerebrum'
import ModalAlert from '../ModalAlert/ModalAlert';
import { useStore } from 'react-redux';
import { checkCollectionLinkable, checkTermLinkable } from '../../../permissionChecker';

const styles = theme => ({
  listContainer:{
    width:'max-content',
    minWidth:400,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:4,
  },
  listItem:{
    padding:"12px 16PX",
    display:'flex',
    alignItems:'flex-start',
    cursor:'pointer',
    overflow:'hidden',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  itemTitle:{
    fontSize:16,
    color:theme.palette.header.main,
  },
  itemSubTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
  },
  ellipsisText:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:"nowrap"
  },
  shortcutWrapper:{
    width:28,
    height:24,
    background:'#E0E0E0',
    color:'#000',
    borderRadius:6,
    fontSize:13.75,
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  },
  inputBase:{
    ...theme.components.inputBase,
    border:'none',
    height:48,
    width:'100%',
  },
  termInputBase:{
    ...theme.components.inputBase,
    height:40,
    width:'100%',
    '& input':{
      paddingLeft:8
    }
  },
  termInputField:{
    ...theme.components.inputBase,
    width:'100%',
    '& textarea':{
      paddingLeft:8
    }
  },
  inputContainer:{
    display:'flex',
    alignItems:'center',
    marginBottom:24
  },
  inputTitle:{
    width:60,
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    marginRight:32
  },
  selector:{
    ...theme.components.selector,
    width:'100%'
  },

  highlightInput:{
    border:`1px solid ${theme.palette.error.main}`
  }
})

function AddCIModal(props) {

  const {
    classes,
    setPopperAnchor, 
    editorRef,
    theme,
    targetItem,
  } = props;
  
  const [selectedType, setSelectedType] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [resultsLoading, setResultsLoading] = useState(false)
  const [searchResults, setSearchResults] =  useState([])
  const [suggestions, setSuggestions] = useState([])
  const searchRef = useRef()
  
  const [addMode, setAddMode] = useState(false)
  const [glossaryList, setGlossaryList] = useState()
  const [selectedGlossary, setSelectedGlossary] = useState('none')
  const [termName, setTermName] = useState('')
  const [termDescription, setTermDescription] = useState('')

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertButtons, setAlertButtons] = useState();


  let sessionData = useStore().getState().auth.session_user;
  let termLinkable = checkTermLinkable({sessionData})
  let collectionLinkable = checkCollectionLinkable({sessionData})

  const onClose = () => {
    setPopperAnchor();
    setAddMode(false);
    setSelectedType();
    setSelectedGlossary('none');
    setTermName('')
    setTermDescription('')
  }
  
  useEffect(()=>{
    window.onkeydown = event => {
      if(event.target.className?.split(' ').includes('popper-search-input') || event.target.className?.split(' ').includes('add-term-input') )return;
      let key = event.key.toUpperCase()
      event.stopPropagation();
      event.preventDefault();
      if(key==='ESCAPE'){
        onClose();
        return;
      }
      if(selectedType)return;
      if(['M','G','T'].includes(key)){
        if(key==='M'){
          setSelectedType('management')
          loadSuggestions('management')
        }
        if(key==='G'){
          setSelectedType('governance')
          loadSuggestions('governance')
        }
        if(key==='T'){
          if(!termLinkable)return;
          setSelectedType('glossary')
          loadSuggestions('glossary')
        }
      }
    }
    return ()=>window.onkeydown = undefined
  // eslint-disable-next-line
  },[selectedType])

  const getFq = type => {
    switch(type){
      case 'management':
        return `object_type_srt:COLLECTION_INSTANCE AND collection_type_srt:DATA_MANAGEMENT` + (targetItem?` AND -id:${targetItem.id}`:'')
      case 'governance':
        return 'object_type_srt:COLLECTION_INSTANCE AND collection_type_srt:DATA_GOVERNANCE' + (targetItem?` AND -id:${targetItem.id}`:'')
      case 'glossary':
        return 'object_type_srt:COLLECTION_INSTANCE AND collection_type_srt:GLOSSARY' + (targetItem?` AND -id:${targetItem.id}`:'')
      default:
        return 'object_type_srt:COLLECTION_INSTANCE' + (targetItem?` AND -id:${targetItem.id}`:'')
    }
  }

  const getPlaceHolder = type => {
    switch(type){
      case 'management':
        return 'Search for a Data Management instance'
      case 'governance':
        return 'Search for a Data Governance instance'
      case 'glossary':
        return 'Search for a Term'
      default:
        return 'Search for an instance'
    }
  }

  const getGlossary = () => {
    const loadData = ({prevData = []}) => {
      axiosSolr 
        .get(
          '/solr/search/select', {
            params:{
              q:'*',
              fq:'object_type_srt:COLLECTION AND collection_type_srt:GLOSSARY',
              rows:100,
              start:prevData.length
            }
          }
        )
        .then(response=>{
          let data = [...prevData, ...response.data.response.docs];
          if(data.length>=response.data.response.numFound){
            setGlossaryList(data)
          }else{
            loadData({prevData:data})
          }
        })
        .catch(error=>{
          console.log(error)
        })
    }

    loadData({})
    
  }

  const onLinkToExisting = (glossaryID, termName) => {
    axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:'*',
            fq:`name_srt:"${termName}" AND collection_srt:"${glossaryList.find(g=>g.id===glossaryID).name_txt}" AND object_type_srt:COLLECTION_INSTANCE AND collection_type_srt:GLOSSARY`,
            rows:1,
          }
        }
      )
      .then(response=>{
        if(response.data.response.docs[0]){
          let item = response.data.response.docs[0];
          const selection = editorRef.getSelection();   
          editorRef.replaceSelection(templates.termSuggestion.formatString({name:item.name_txt,link:onClickResultItem({item:item,id:item.id,label:'collection_instance',urlOnly:true}),isLinked:true})  , selection[0]-1, selection[1]);
          onClose();
        }else{
          setAlertMessage('Error occurred adding the Term, please try again');
          setAlertButtons();
          setAlertOpen(true)
        }
      })
      .catch(error=>{
        setAlertMessage('Error occurred adding the Term, please try again');
        setAlertButtons();
        setAlertOpen(true)
      })


  }

  const onAddGlossary = () => {
    let payload = {}
    payload.collection_id = selectedGlossary;
    payload.description = termDescription;
    payload.name = termName;
    payload.properties = {};
    payload.source_id = 1000;
    axiosCerebrum
      .post(
        '/api/collectioninstances',
        payload
      )
      .then(response=>{
        let item = response.data;
        addDetail({
          id:item.id,
          name_txt:termName,
          object_type_txt:'COLLECTION_INSTANCE',
          collection_type_txt:'GLOSSARY',
          collection_txt:item.parent.name
        })
        const selection = editorRef.getSelection();  
        editorRef.replaceSelection(templates.termSuggestion.formatString({name:item.name,link:onClickResultItem({item:item,id:item.id,label:'collection_instance',urlOnly:true}),isLinked:true})  , selection[0]-1, selection[1]);
        onClose();
      })
      .catch(error=>{
        setAlertButtons();
        let msg = 'Error occurred adding the Term, please try again'
        if(error.response && error.response.status && error.response.status===403){
          msg = 'You do not have permissions to add a Term to this Glossary'
        }
        if(error.response && error.response.status && error.response.status===409){
          msg = 'Your new Term exists in this glossary'
          setAlertButtons(
            <Button variant='outlined' style={{color:'#fff',border:`1px solid #fff`}} onClick={()=>onLinkToExisting(selectedGlossary, termName)}>
              LINK TO TERM
            </Button>
          )
        }
        setAlertMessage(msg)
        setAlertOpen(true)
        console.log(error)
      })
  }

  const loadSuggestions = (type) => { 
    setResultsLoading(true)
    axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:`*`,
            fq:getFq(type),
            rows:10,
            sort:'last_updated_srt desc'
          }
        }
      )
      .then(response=>{
        setSuggestions(response.data.response.docs)
        setResultsLoading(false)
      })
      .catch(error=>{
        setSuggestions([])
        setResultsLoading(false)
      })
  }

  const onSearch = (value, type) => {
    clearTimeout(searchRef.current)
    setSearchValue(value);
    setSearchResults([]);
    if(value.trim()===''){
      return;
    }
    setResultsLoading(true)
    setTimeout(()=>{
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:value.trim()===''?'*':getPartialMatchSearchString(value),
              fq:getFq(type),
              rows:10
            }
          }
        )
        .then(response=>{
          setSearchResults(response.data.response.docs)
          setResultsLoading(false)
        })
        .catch(error=>{
          setSearchResults([])
          setResultsLoading(false)
        })
    },250)
  }

  let options = [
    collectionLinkable?{label:'collection',id:'management',name:'Data Management Instance', description:'Link to a Data Management instance',shortcut:'M'}:undefined,
    collectionLinkable?{label:'collection',id:'governance',name:'Data Governance Instance', description:'Link to a Data Governance instance',shortcut:'G'}:undefined,
    termLinkable?{label:'glossary',id:'glossary',name:'Glossary Term', description:'Link to an existing or create a new Term',shortcut:'T'}:undefined
  ].filter(el=>el)

  return (
    <ClickAwayListener onClickAway={(event)=>{
      if(addMode)return;
      onClose();
    }}>
      <div>
        <div 
          className={classes.listContainer}
        >
          {
            options.length===0 && 
            <div style={{display:'flex',alignItems:'center',height:48,color:theme.palette.primaryText.main,fontSize:13.75,paddingLeft:16}}>
              You are not allowed to link collections and terms.
            </div>
          }
          {
            !selectedType && 
            options.map(el=>(
              <div key={el.shortcut} className={classes.listItem} onClick={()=>{setSelectedType(el.id);loadSuggestions(el.id)}} >
                {getIconComponent({label:el.label,size:24,colour:theme.palette.primary.main})}
                <div style={{flexGrow:1, overflow:'hidden',marginLeft:16}}>
                  <Typography className={classes.itemTitle + ' ' + classes.ellipsisText}>{el.name}</Typography>
                  <Typography className={classes.itemSubTitle + ' ' + classes.ellipsisText}>{el.description}</Typography>
                </div>
                <div className={classes.shortcutWrapper}>
                  {el.shortcut}
                </div>
              </div>
            ))
          }

          {
            selectedType && !addMode &&
            <div>
              <div style={{borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}}>
                <InputBase
                  className={classes.inputBase}
                  value={searchValue}
                  placeholder={getPlaceHolder(selectedType)}
                  onChange={(event)=>{
                    onSearch(event.target.value, selectedType)
                  }}
                  inputProps={{
                    'data-test-id':'inline-popup-search',
                    'className':'popper-search-input'
                  }}
                  autoFocus
                  startAdornment={
                    <InputAdornment position="start" style={{marginLeft:16,marginRight:0}} >
                      {getIconComponent({label:'search',size:24,colour:theme.palette.primaryText.light})}
                    </InputAdornment>
                  }
                />
              </div>
              {
                selectedType==='glossary' && 
                <div className={classes.listItem} onClick={()=>{getGlossary();setAddMode(true)}} >
                  {getIconComponent({label:'add',size:24,colour:theme.palette.primaryText.light})}
                  <div style={{flexGrow:1, overflow:'hidden',marginLeft:16}}>
                    <Typography className={classes.itemTitle + ' ' + classes.ellipsisText}>Add a Term</Typography>
                  </div>
                </div>
              }
              {
                <div style={{marginTop:16,marginBottom:16}}>
                  <Typography style={{fontSize:12,color:theme.palette.primaryText.light,letterSpacing:2,marginLeft:18,marginBottom:8}}>
                    {searchValue.trim()!==''?'SEARCH RESULTS':'SUGGESTIONS'}
                  </Typography>
                  {
                    (searchValue.trim()!==''?searchResults:suggestions).map((item,index)=>(
                      <SimpleResultItem
                        title={getDispFields(item,'dispTitle')}
                        titleColour={theme.palette.primaryText.main}
                        label={item.object_type_txt}
                        item={item}
                        subTitle={getDispFields(item,'dispSubtitle')}
                        hideRight={true}
                        showUnderline={index!==(searchValue.trim()!==''?searchResults:suggestions).length-1?true:false}
                        onClick={() => {
                          const selection = editorRef.getSelection();   
                          let formattedString = templates.linkCI.formatString({name:item.name_txt,link:onClickResultItem({item:item,id:item.id,label:item.object_type_txt,urlOnly:true,isSimplifiedInstanceUrl:true})})
                          if(selectedType==='glossary'){
                            formattedString = templates.termSuggestion.formatString({name:item.name_txt,link:onClickResultItem({item:item,id:item.id,label:'collection_instance',urlOnly:true}),isLinked:true}) 
                          }
                          editorRef.replaceSelection(formattedString , selection[0]-1, selection[1]);
                          onClose();
                        }}
                      />
                    ))
                  }
                  {
                    (searchValue.trim()!==''?searchResults:suggestions).length===0 && !resultsLoading && 
                    <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main,marginLeft:18}}>No item found</Typography>
                  }
                  {
                    resultsLoading && 
                    <div style={{textAlign:'center'}}>
                      <CircularProgress color='secondary' style={{margin:'auto'}}/>
                    </div>
                  }
                </div>
              }
            </div>
          }

          {
            selectedType && addMode &&
            <div style={{width:568,padding:'16px 24px',}}>
              <Typography style={{fontSize:16,color:theme.palette.header.main,marginBottom:24}}>Add a new Term</Typography>
              <div className={classes.inputContainer}>
                <Typography className={classes.inputTitle}>
                  Glossary
                </Typography>
                <div style={{flexGrow:1}}>
                  <Select
                    className={classes.selector  + (selectedGlossary==='none'?' '+classes.highlightInput:'')}
                    value={selectedGlossary}
                    onChange={event=>{setSelectedGlossary(event.target.value)}}
                    disableUnderline
                    MenuProps={{
                      id:'add-ci-modal-menu',
                    }}
                  >
                    <MenuItem value='none'>Select a Glossary</MenuItem>
                    {
                      glossaryList && glossaryList.map(el=>(
                        <MenuItem key={el.id} value={el.id}>{el.name_txt}</MenuItem>
                      ))
                    }
                  </Select>
                </div>
              </div>

              <div className={classes.inputContainer}>
                <Typography className={classes.inputTitle}>
                  Term
                </Typography>
                <div style={{flexGrow:1}}>
                  <InputBase
                    className={classes.termInputBase  + (termName.trim()===''?' '+classes.highlightInput:'')}
                    value={termName}
                    onChange={event=>{setTermName(event.target.value)}}
                    placeholder="Enter a name for the Term"
                    inputProps={{
                      'data-test-id':'inline-popup-add-term',
                      'className':'add-term-input'
                    }}
                  />
                </div>
              </div>
              <div className={classes.inputContainer} style={{alignItems:'flex-start'}}>
                <Typography className={classes.inputTitle} style={{marginTop:6}}>
                  Description
                </Typography>
                <div style={{flexGrow:1}}>
                  <InputBase
                    className={classes.termInputField }
                    value={termDescription}
                    multiline
                    rows={3}
                    onChange={event=>{setTermDescription(event.target.value)}}
                    placeholder="Enter a description for the Term"
                    inputProps={{
                      'data-test-id':'inline-popup-add-description',
                      'className':'add-term-input'
                    }}
                  />
                </div>
              </div>
              <div style={{display:'flex',justifyContent:'flex-end'}}>
                <Button style={{marginRight:16}} disabled={selectedGlossary==='none' || termName.trim()===''} onClick={()=>onAddGlossary()} color='primary'>ADD</Button>
                <Button color='secondary' onClick={()=>onClose()}>CANCEL</Button>
              </div>
            </div>
          }
        </div>
        <ModalAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          type='error'
          buttons={alertButtons}
        />
      </div>
    </ClickAwayListener>
    
  )
}

AddCIModal.propTypes = {
  classes: PropTypes.object.isRequired,
  setPopperAnchor: PropTypes.func.isRequired,
  editorRef: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(AddCIModal));