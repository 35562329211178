import React, { } from 'react';
import { withTheme, Typography, Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';


const PeriodSelector = props => {

  const {
    classes,
    theme,
    period,
    setPeriod,
    code,
  } = props;


  return (
    <div className={classes.modalBlock}>
      <Typography className={classes.modalBlockTitle}>{code.includes('0340') || code==='0390' ? 'Usage Period' : 'Period'}</Typography>
      <div style={{ flexGrow: 1 }}>
        <FormControl style={{ width: '100%' }}>
          <Select
            className={classes.selector}
            disableUnderline
            value={period}
            onChange={event => setPeriod(event.target.value)}
            renderValue={el => {
              return (
                el === 'none'
                  ?
                  <Typography style={{ color: theme.palette.primaryText.light, fontSize: 16 }}>
                    Set a {code.includes('0340') || code==='0390' ? 'usage ' : ''}period
                  </Typography>
                  :
                  <>
                    <Typography style={{ fontSize: 16, color: theme.palette.primaryText.main }}>
                      {`Last ${el} days`}
                    </Typography>
                  </>
              )
            }}
          >
            <MenuItem className={classes.menuItem} value={'none'}>
              Set a {code.includes('0340') || code==='0390' ? 'usage ' : ''}period
            </MenuItem>
            {
              [90, 180].map(el => (
                <MenuItem className={classes.menuItem} value={el}>
                  {`Last ${el} days`}
                </MenuItem>
              ))
            }
          </Select>
          <FormHelperText className={classes.helperText}>
            {'Required'}
          </FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}

PeriodSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  period: PropTypes.number.isRequired,
  setPeriod: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
}

export default withTheme()(PeriodSelector);