import React, { useEffect, useState } from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import EditModal from './EditModal';
import { globalListenerRef } from '../../../GlobalListenerRef';

const styles = theme => ({
  title: {
    color: theme.palette.header.main,
    fontSize: 20,
  },
  chip:{
    height:24,
    display:'flex',
    alignItems:'center',
    borderRadius:12,
    fontSize:13.75,
    paddingLeft:12,
    paddingRight:12,
    maxWidth:400,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    width:'max-content'
  },
  emptyChip:{
    border:`1px solid ${theme.palette.border.main}`,
    background:theme.palette.chip.main,
    color:theme.palette.primaryText.main
  },
  selectedChip:{
    border:`1px solid ${theme.palette.primary.dark}`,
    background:theme.palette.primary.dark,
    color:theme.palette.background.main,
    cursor:'pointer',
    paddingLeft:6
  }
})

function ParentEditor(props) {

  const {
    state,
    dispatch,
  } = props;

  const [editModalOpen, setEditModalOpen] = useState(false)
  const [initialParent, setInitialParent] = useState()

  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.open_instance_parent_modal ){
        setEditModalOpen(true)
        try{  
          setInitialParent(JSON.parse(msg.data.parent))
        }catch(error){
          console.log(error)
        }
      }
    }
    window.removeEventListener('message',globalListenerRef.parentEditorModalListener);
    globalListenerRef.parentEditorModalListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.parentEditorModalListener);
    return (()=>{window.removeEventListener('message',globalListenerRef.parentEditorModalListener);})
  // eslint-disable-next-line
  },[])

  return (
    <div>
      <EditModal
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        state={state}
        dispatch={dispatch}
        initialParent={initialParent}
      />
    </div>
  )
}

export default withTheme()(withStyles(styles)(ParentEditor));