import React, { useEffect } from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import IconDataTile from './TilesComponents/IconDataTile';
import { getIconComponent } from '../../../../utilities'; 
import { globalListenerRef } from '../../../../GlobalListenerRef';
import axiosSolr from '../../../../axios-solr';

const styles = theme => ({
  dataBox: {
    marginBottom: '2rem'
  },
  avatar: {
    backgroundColor: theme.palette.avatar.main,
    width:24,
    height:24,
    fontSize:12
  },
})

function LineageTile(props) {

  const {
    theme,
    object,
    onAddTileData,
    tileData,
  } = props;


  const loadLineageTile = async () => {
    let stat;
    await axiosSolr
      .get(
        '/solr/search/select',
        {params:{
          q:"*",
          fq:`id:${object.id}`,
          rows:1
        }}
      )
      .then(response=>{
        stat = response.data.response.docs[0]
      })
      .catch(error=>{
        console.log(error)
      })
    
    let isInactive = object.active_flag===false || object.active_txt==='NO'
    let fieldName = 'active_lineage_inc_reference_txt';
    if(isInactive)fieldName = 'lineage_inc_reference_txt';

    const onOpenLineage = () => {
      window.postMessage({sidebar_action:'open_lineage'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
    }
    
    let text = 'No lineage'
    let label = 'help'
    if(stat?.[fieldName]==='UPSTREAM_ONLY'){
      text = 'Upstream sources'
      label = 'lineage_upstream'
    }
    if(stat?.[fieldName]==='UPSTREAM_QUERY_ONLY'){
      text = 'Upstream sources'
      label = 'lineage_upstream'
    }
    if(stat?.[fieldName]==='UPSTREAM_DOWNSTREAM'){
      text = 'Up & Downstream sources'
      label = 'lineage_up_downstream'
    }
    if(['DOWNSTREAM_ONLY'].includes(stat?.[fieldName])){
      text = 'Downstream sources'
      label = 'lineage_downstream'
    }
    if(['UPSTREAM_QUERY_DOWNSTREAM'].includes(stat?.[fieldName])){
      text = 'Up & Downstream sources'
      label = 'lineage_up_downstream'
    }
  
    onAddTileData(
      {
        "disp_body":[{
          name:text,
          label:label
        }],
        "box_action":[{
          action:onOpenLineage,
          text:'OPEN LINEAGE'
        }],
        "disp_title":isInactive?"LINEAGE":"ACTIVE LINEAGE",
        "disp_type":"list",
        "id":"lineage",
      }
    )
  }

  useEffect(()=>{
    if(!tileData)loadLineageTile()
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.lineageTileListener)
    globalListenerRef.lineageTileListener = (event) => {
      if(event.data?.reload_sidebar?.includes('lineage')){
        loadLineageTile()
      }
    }
    window.addEventListener('message',globalListenerRef.lineageTileListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.lineageTileListener)
    }
  // eslint-disable-next-line
  },[])
  
  if(!tileData)return <></>

  return (
    <IconDataTile
      title={tileData.disp_title}
      left={getIconComponent({size: 24,colour:tileData.disp_body[0].label==='help'?theme.palette.primaryText.light:theme.palette.primary.main,label:tileData.disp_body[0].label})}
      text={tileData.disp_body[0].name}
      action={tileData.box_action}
      wrapText
    />
  )

}

LineageTile.propTypes = {
  classes: PropTypes.object.isRequired,
  boxData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(LineageTile));