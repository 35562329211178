import React, { useEffect, useState, useRef } from 'react';
import { withStyles, Typography, CircularProgress, IconButton, InputBase, InputAdornment, Checkbox, Paper, ClickAwayListener, MenuItem, Popper, Button, Badge} from '@material-ui/core';
import { checkShowChildCount, constructHeader, generateFq, getChildCountText, mapStepListToStr, mapStepStringToList } from '../utils';
import axiosSolr from '../../../axios-solr';
import { getIconComponent, isInViewport, onClickResultItem } from '../../../utilities';
import 'url-search-params-polyfill';
import theme from '../../../theme';
import KTooltip from '../../UI/KTooltip/KTooltip';
import { getIconLabel } from '../../UI/SearchResults/utils';
import { loadIssueSteps } from '../issueUtils';
import { loadContentSteps } from '../contentUtils';
import AscIcon from '@material-ui/icons/ArrowUpward'
import DescIcon from '@material-ui/icons/ArrowDownward'
import { globalListenerRef } from '../../../GlobalListenerRef';
import useAlert from '../../../hooks/useAlert';
import { loadRefSources } from '../refSourceUtils';

const styles = theme => ({
  listItem:{
    height:48,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:'pointer',
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  listText:{
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
    overflow:'hidden'
  },
  columnText:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primaryText.main
  },
  textfield:{
    ...theme.components.inputBase,
    width:200,
    height:40
  },
  sortIcon:{
    width:16,
    height:16,
    marginLeft:6,
    color:theme.palette.primaryText.light,
    position:'relative',
    bottom:-2
  },
  checkbox:{
    paddingLeft:0,
  },
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    ...theme.components.hideScroll,
    overflow:"auto"
  },
	menuItem:{
		padding:'10px 10px 10px 16px',
		color:theme.palette.primaryText.main,
		'&:hover':{
      background: theme.palette.hovered.main
    }
	},
  badge:{
    padding: '0 0px',
    width: '16px',
    height: '16px',
    minWidth: '16px',
    borderRadius:8,
    backgroundColor: '#B00020',
    color: '#fff',
  },
  hiddenBadge:{
    display:'none'
  }
})

const List = props => {

  const {
    classes,
    history,
    state,
    dispatch,
  } = props;


  const scrollRef = useRef()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const apiIdRef = useRef(0)
  const searchTimeoutRef = useRef()
	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);
  const [openButtonHovered, setOpenButtonHovered] = useState(false)

  const [listHeight, setListHeight] = useState(window.innerHeight);

  const listRef = useRef()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    }
  },[])


  useEffect(()=>{
    setListHeight(window.innerHeight - listRef.current.getBoundingClientRect().top-52)
    window.removeEventListener('resize',globalListenerRef.inventoryListResize)
    globalListenerRef.inventoryListResize = () => {
      setListHeight(window.innerHeight - listRef.current.getBoundingClientRect().top-52)
    }
    window.addEventListener('resize',globalListenerRef.inventoryListResize)
    return ()=>window.removeEventListener('resize',globalListenerRef.inventoryListResize)
  },[])

  const loadSidebarList = ({category}) => {

    let fq = generateFq({stepList: [], category:category})

    const load = ({prevData=[]}) => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:'*',
              fq,
              rows:20,
              sort:'name_srt asc',
              start:prevData.length,
            }
          }
        )
        .then(response=>{
          let data = [...prevData, ...response.data.response.docs]
          if(data.length>=response.data.response.numFound){
            dispatch({
              type:'set_sidebar_source_list',
              sidebarSourceList:data
            })
          }else{
            load({prevData:data})
          }
        })
        .catch(error=>{
          console.log(error)
          dispatch({
            type:'set_sidebar_source_list',
            sidebarSourceList:prevData
          })
        })
    }
    load({})
  }

  const generateInitialList = ({category, search=state.search, loadSideBarListOnly, nameSort=state.nameSort, showDeleted=state.showDeleted, showReference=state.showReference}) => {
    if(!category){
      dispatch({ type:'set_list'})
      dispatch({type:'set_sidebar_source_list'})
      return;
    }
    if(category==='data'){
      let sources = state.sourceList.filter(el=>el.source_template.type==='DATABASE' && (showDeleted || el.active_flag===true))
      dispatch({
        type:'set_sidebar_source_list',
        sidebarSourceList:sources
      })
      if(loadSideBarListOnly)return;
      let filteredSources = sources.map(s=>({...s,name:state.hosts?.find(h=>h.id===s.host_ids[0])?.alternate_name || s.name }))
      filteredSources = filteredSources.filter(el=>(search.trim()==='' || el.name.toLowerCase().includes(search.toLowerCase())) )
      filteredSources.sort((a,b)=>a.name.localeCompare(b.name))
      if(nameSort==='name_srt desc')filteredSources.reverse();
      dispatch({
        type:'set_list',
        list:{docs:filteredSources,numFound:filteredSources.length}
      })
    }
    else if(category==='content'){
      let sources = state.sourceList.filter(el=>el.source_template.type==='TOOL' && (showDeleted || el.active_flag===true) )
      dispatch({
        type:'set_sidebar_source_list',
        sidebarSourceList:sources
      })
      if(loadSideBarListOnly)return;
      let filteredSources = sources.map(s=>({...s,name:state.hosts?.find(h=>h.id===s.host_ids[0])?.alternate_name || s.name }))
      filteredSources = filteredSources.filter(el=>(search.trim()==='' || el.name.toLowerCase().includes(search.toLowerCase())) )
      filteredSources.sort((a,b)=>a.name.localeCompare(b.name))
      if(nameSort==='name_srt desc')filteredSources.reverse();
      dispatch({
        type:'set_list',
        list:{docs:filteredSources,numFound:filteredSources.length}
      })
    }
    else{
      if(loadSideBarListOnly){
        loadSidebarList({category})
        return;
      }
      loadList({search, nameSort, showDeleted, showReference})
    }
  }

  window.onpopstate = () => {
    const urlSearch = new URLSearchParams(window.location.search);
    const stepList = urlSearch.get('stepList');
    const category = urlSearch.get('category');
    if(category){
      dispatch({type:'set_category',category})
    }
    if(stepList){
      dispatch({type:'set_step_list',stepList:mapStepStringToList(stepList)})
    }else{
      dispatch({type:'set_step_list',stepList:[]})
      // generateInitialList({category})
    }
  }

  const checkIsItemExpandable = (item) => {
    if(item.source_template)return true;
    if(['USER','COLUMN','ISSUE','TAG','DATASET_FIELD'].includes(item.object_type_txt)){
      return false
    }
    if(item.object_type_txt==='COLLECTION_INSTANCE' && ['LIST','KNOWLEDGE'].includes(item.collection_type_txt)){
      return false
    }
    if(state.category==='content' && state.stepList.length===3){
      if(state.stepList[1].id==='WORKSPACE')return true;
      if(item.object_type_txt==='DATASET_TABLE')return true;
      return false
    }
    if(item.child_object_count_srt===0)return false;
    return true;
  }

  const onClickItem = item => {
    if(!checkIsItemExpandable(item)){
      onItemOpenClick(item);
      return;
    }
    let newList = [...state.stepList, {name:item.name || item.name_txt, id:item.id, source_id:item.source_id}];
    if(!generateFq({stepList: newList, category:state.category}) && state.category!=='issues' )return;
    dispatch({type:'set_step_list',stepList:newList})
    history.push(`/inventory?category=${state.category}&stepList=${mapStepListToStr(newList)}`)
  }

  const loadList = ({isPaginate, search=state.search, nameSort=state.nameSort, showDeleted=state.showDeleted, showReference=state.showReference}) => {

    if(!state.sidebarSourceList && state.stepList.length>0 && ['data','content','glossaries','data management','data governance','knowledge'].includes(state.category)){
      generateInitialList({category: state.category, loadSideBarListOnly:true})
    }

    let currentID = apiIdRef.current+1;
    apiIdRef.current += 1;

    if(state.category==='issues' && state.stepList.length<2){
      loadIssueSteps({state, dispatch, setLoading, nameSort, currentID, apiIdRef, search, setError})
      return;
    }

    if(state.category==='content' && state.stepList.length===1){
      loadContentSteps({state, dispatch, setLoading, currentID, apiIdRef, search, setError})
      return;
    }

    if(state.category==='content' && state.stepList.length===3 && state.stepList[1].id==='WORKSPACE'){
      loadContentSteps({state, dispatch, setLoading, currentID, apiIdRef, search, setError})
      return;
    }

    if(state.category==='reference sources' && state.stepList.length===0){
      loadRefSources({state, dispatch, setLoading, currentID, apiIdRef, search, setError, isPaginate, showDeleted})
      return;
    }

    let fq = generateFq({stepList: state.stepList, category:state.category, showDeleted})

    if(!showDeleted && ['data','content'].includes(state.category))fq+=' AND active_srt:YES'
    if(!showReference && ['data','content'].includes(state.category))fq+=' AND reference_srt:NO'
    setLoading(true)
    axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:search.trim()===''?'*':search.trim()+'*',
            "mm": "2<-1",
            "qf": "name_txt name_srt",
            "pf": "name_txt",
            "bf":"",
            fq,
            rows:20,
            sort:search.trim()===''?nameSort:undefined,
            start:isPaginate?state.list.docs.length:0,
          }
        }
      )
      .then(async response=>{
        if(currentID!==apiIdRef.current)return;

        if(['glossaries','data management','data governance','domains','verified use case','teams','knowledge'].includes(state.category) && response.data.response.docs.length>0){
          await axiosSolr
            .get(
              `/solr/search/select`,{params:{
                q:"*",
                fq:`object_type_srt:${state.category==='teams'?'TEAM':'COLLECTION_INSTANCE'} AND hierarchy_parent_id_srt:(${response.data.response.docs.map(d=>d.id).join(' OR ')})`,
                rows:0,
                'json.facet':{
                  child_count:{
                    type:'terms',
                    field:'hierarchy_parent_id_srt',
                  }
                }
              }}
            )
            .then(facet=>{
              response.data.response.docs = response.data.response.docs.map(d=>{
                let childCount = facet.data.facets.child_count?.buckets?.find(b=>b.val===d.id)
                d.child_object_count_srt = childCount?childCount.count:0
                return d
              })
            })
            .catch(error=>{
              console.log(error)
            })
        }

        if(isPaginate){
          let newDocs;
          newDocs = [...state.list.docs, ...response.data.response.docs]
          dispatch({type:'set_list',list:{...response.data.response, docs:newDocs}})
        }else{
          dispatch({type:'set_list',list:response.data.response})
        }
        setLoading(false)
      })
      .catch(error=>{
        console.log(error)
        setLoading(false)
        setError(true)
        sendAlert({
          alertType:'error',
          message:'Error loading the items'
        })
      })
  }

  useEffect(()=>{
    setError(false)
    dispatch({type:'set_list'})
    dispatch({type:'set_search',search:''})
    if(state.stepList.length!==0){
      loadList({})
    }else{
      generateInitialList({category: state.category, search:''})
    }
  // eslint-disable-next-line
  },[state.stepList])

  useEffect(()=>{
    dispatch({type:'set_sidebar_source_list'})
  // eslint-disable-next-line
  },[state.category])

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && state.list && !loading && state.list.docs.length<state.list.numFound
  }

  const onListScroll = (event) => {
    if(event.target.scrollTop>=event.target.scrollHeight-event.target.clientHeight-10){
      if(shouldLoadMore()){
        loadList({isPaginate:true})
      }
    }
  }


  useEffect(()=>{
    shouldLoadMore() && loadList({isPaginate:true})
  // eslint-disable-next-line
  },[state.list,loading])

  const onItemOpenClick = item => {
    if(state.category==='content' && state.stepList.length===1){
      history.push(`/profile/source/${state.sourceList.find(s=>s.id+''===state.stepList[0].id+'')?.host_ids[0]}?assetTabName=${item.name}`)
      return;
    }
    if(['content','data'].includes(state.category) && state.stepList.length===0){
      history.push(`/profile/source/${item.host_ids[0]}`)
      return;
    }
    onClickResultItem({item:item, id: item.id, history, label:item.object_type_txt || item.object?.name})
  }

  const onSearchChange = str => {
    dispatch({type:'set_search',search:str})
    clearTimeout(searchTimeoutRef.current)
    searchTimeoutRef.current = setTimeout(()=>{
      dispatch({type:'set_list'})
      if(state.stepList.length!==0){
        loadList({search:str})
      }else{
        generateInitialList({category: state.category, search: str})
      }
    },250)
  }

  const onChangeNameSort = () => {
    let newSort = state.nameSort==='name_srt asc'?'name_srt desc':'name_srt asc'
    dispatch({
      type:'set_name_sort', nameSort:newSort
    })
    if(state.stepList.length!==0){
      loadList({nameSort:newSort})
    }else{
      generateInitialList({category: state.category, nameSort: newSort})
    }
  }

  const getItemLabel = item => {
    if(item.object_type_txt==='severity')return 'issue';
    if(item.object_type_txt==='content_type')return item.name;
    if(item.object?.name==='HOST')return 'host'
    return getIconLabel({label:item.object_type_txt,item})
  }

  const checkIsSortable = () => {
    if(state.category==='content' && state.stepList.length===1)return false;
    if(state.category==='issues' && state.stepList.length===1)return false;
    if(state.category==='reference sources' && state.stepList.length===0)return false;
    return true
  }

  const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

  const onClickShowDeleted = show => {
    dispatch({
      type:'set_show_deleted',
      showDeleted:show
    })
    if(state.stepList.length!==0){
      loadList({showDeleted:show})
    }else{
      generateInitialList({category: state.category, showDeleted: show})
    }
  }

  const onClickShowReference = show => {
    dispatch({
      type:'set_show_reference',
      showReference:show
    })
    if(state.stepList.length!==0){
      loadList({showReference:show})
    }else{
      generateInitialList({category: state.category, showReference: show})
    }
  }

  const getListName = l => {
    if(l.object?.name==='HOST')return l.alternate_name || 'Unknown'
    return (l.name || l.name_txt || 'Unknown').replace(/_/g,' ')
  }

  return (
    <div className={classes.root}>
      <div style={{display:'flex',alignItems:'center',flexWrap:'wrap',paddingBottom:12}}>
        <Typography style={{flexGrow:1,fontSize:20,marginRight:16,marginBottom:8,color:theme.palette.primaryText.main,textTransform:'uppercase'}}>
          {constructHeader({category:state.category, stepList: state.stepList, list:state.list})}
        </Typography>
        <div style={{flexGrow:0,flexShrink:0,display:'flex',alignItems:'center',marginBottom:8}}>
          <InputBase
            className={classes.textfield}
            variant='filled'
            placeholder={'Search'}
            value={state.search}
            inputProps={{
              'data-test-id':'inventory-search-input'
            }}
            onChange={event=>onSearchChange(event.target.value)}
            endAdornment={
              < InputAdornment position="end" >
                <IconButton
                  style={{width:32,height:32,marginRight:4}}
                  onClick={()=>onSearchChange('')}
                  disabled={state.search===''}
                >
                  {
                    getIconComponent({label:state.search!==''?'clear':'search',size:24,colour:theme.palette.primaryText.light})
                  }
                </IconButton>
              </InputAdornment>
            }
          />
          <div style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} data-test-id="inventory-action-button" onClick={onListActionClick}>
            {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
          </div>
          <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
            <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
              <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                <Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
                {
                  state.category!=='reference sources' &&
                  <MenuItem  data-test-id="inventory-show-reference-button" onClick={()=>{onClickShowReference(!state.showReference)}} className={classes.menuItem} >
                    <Checkbox className={classes.checkbox} color='primary' checked={state.showReference}/>
                    <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show reference</Typography>
                  </MenuItem>
                }
                <MenuItem  data-test-id="inventory-show-deleted-button" onClick={()=>{onClickShowDeleted(!state.showDeleted)}} className={classes.menuItem} >
                  <Checkbox className={classes.checkbox} color='primary' checked={!state.showDeleted}/>
                  <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
                </MenuItem>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
      </div>
      {
        state.list && state.list.numFound>0 &&
        <div style={{display:'flex',marginBottom:8}}>
          <div style={{marginLeft:16,marginRight:16,width:20,height:20,flex:'0 0 20px'}}></div>
          <Typography data-test-id="inventory-name-column" className={classes.columnText} style={{flex:'1 0 100px',marginRight:16, cursor:checkIsSortable()?"pointer":undefined}} onClick={()=>{checkIsSortable() && onChangeNameSort()}}>
            {
              state.stepList.length===0 && ['glossaries'].includes(state.category)?'GLOSSARY ':''
            }
            {
              state.stepList.length===0 && ['data management','data governance'].includes(state.category)?'COLLECTION ':''
            }
            NAME
            {
              checkIsSortable() && (
                state.nameSort==='name_srt asc'?
                <AscIcon className={classes.sortIcon}/>:
                <DescIcon className={classes.sortIcon}/>
              )
            }
          </Typography>
          {
            state.category==='reference sources' && state.stepList.length===0 &&
            <Typography className={classes.columnText} style={{flex:'1 0 100px',marginRight:16}}>HOST</Typography>
          }
          <Typography className={classes.columnText} style={{flex:'2 1',minWidth:0,marginRight:16,overflow:'hidden'}}>DESCRIPTION</Typography>
          {
            checkShowChildCount({category:state.category, stepList:state.stepList, list:state.list}) &&
            <Typography className={classes.columnText} style={{flex:'0 1 130px',minWidth:80,marginRight:16}}>INCLUDES</Typography>
          }
          <Button
            disabled
            style={{width:'max-content',flexGrow:0,padding:'2px 8px',flexShrink:0,marginLeft:16,marginRight:8,visibility:'hidden'}}
          >
            OPEN
          </Button>
        </div>
      }
      <div ref={listRef} onScroll={onListScroll} className={classes.listContainer} style={{height:listHeight}}>
        {
          state.list && state.list.docs.map(l=>(
            <div id={l.id} data-test-classname="inventory-list-item" data-test-id={`inventory-list-item-${l.id}`} onClick={()=>{onClickItem(l)}} className={classes.listItem} style={{background:openButtonHovered?'transparent':undefined}}>
              <div style={{marginLeft:16,marginRight:16,width:20,height:20,flex:'0 0 20px'}}>
                <Badge
                  variant='standard'
                  badgeContent={'✕'}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  classes={{
                    badge:l.active_txt==='NO' || (l.source_template && l.active_flag===false)?classes.badge:classes.hiddenBadge
                  }}
                >
                  {
                    getIconComponent({
                      label:getItemLabel(l),
                      size:20,
                      colour:theme.palette.primaryText.light
                    })
                  }
                </Badge>
              </div>
              <KTooltip title={getListName(l)}>
                <Typography  className={classes.listText} style={{flex:'1 0 100px',marginRight:16}}>
                  {getListName(l)}
                </Typography>
              </KTooltip>
              {
                state.category==='reference sources' && state.stepList.length===0 &&
                <KTooltip title={l.name || 'Unknown'}>
                  <Typography className={classes.listText} style={{flex:'1 0 100px',marginRight:16}}>
                    {l.name || 'Unknown'}
                  </Typography>
                </KTooltip>
              }
              <KTooltip title={l.description}>
                <Typography className={classes.listText} style={{flex:'2 1',marginRight:16, color:theme.palette.primaryText.light}}>{l.description}</Typography>
              </KTooltip>
              {
                checkShowChildCount({category:state.category, stepList:state.stepList, list:state.list}) &&
                <Typography className={classes.listText} style={{flex:'0 1 130px',minWidth:80,marginRight:16}}>
                  {getChildCountText(l)}
                </Typography>
              }
              {
                // (state.stepList.length!==1 || state.category!=='issues') && checkIsItemExpandable(l) ?
                (state.stepList.length!==1 || state.category!=='issues')?
                <Button
                  color='primary'
                  variant='outlined'
                  onMouseEnter={()=>setOpenButtonHovered(true)}
                  onMouseLeave={()=>setOpenButtonHovered(false)}
                  style={{width:'max-content',flexGrow:0,padding:'2px 8px',flexShrink:0,marginLeft:16,marginRight:8}}
                  onClick={(event)=>{event.stopPropagation();onItemOpenClick(l)}}
                >
                  OPEN
                </Button>
                // <IconButton style={{marginLeft:32,marginRight:16,flex:'0 0'}} onClick={(event)=>{event.stopPropagation();onItemOpenClick(l)}}>
                //   {getIconComponent({label:'open',size:20,colour:theme.palette.primaryText.light})}
                // </IconButton>
                :
                <Button
                  disabled
                  style={{width:'max-content',flexGrow:0,padding:'2px 8px',flexShrink:0,marginLeft:16,marginRight:8,visibility:'hidden'}}
                >
                  OPEN
                </Button>
              }
            </div>
          ))
        }
        {
          state.list && state.list.numFound===0 &&
          <Typography style={{marginTop:16}}>No item found</Typography>
        }
        {
            error  &&
          <Typography style={{marginTop:16}}>Error occurred loading items</Typography>
        }
        <div style={{display:'flex',justifyContent:'center',marginTop:6}} ref={scrollRef}>
          {
            loading &&
            <CircularProgress color='secondary'/>
          }
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(List);
