import React, { useEffect}  from 'react';
import { withTheme, withStyles, Typography, CircularProgress } from '@material-ui/core';
import axiosSolr from '../../../../axios-solr';
// import { contentItems, dataItems } from '../../utils';
import StackHorizontalBar from '../Charts/StackHorizontalBar';

const styles = theme => ({

})

const BySourceChart = props => {


  const {
    classes,
    // theme,
    data,
    state,
    setData,
    variant
  } = props;
  
  const chartData = data.bySourceData;
  const loading = data.bySourceLoading;
  const error = data.bySourceError;
  const setError = () => setData({...data, bySourceError:true, bySourceLoading:false})
  const setLoading = () => setData({...data, bySourceLoading:true, bySourceError:false})
  const setChartData = d => setData({...data, bySourceData:d, bySourceLoading:false, bySourceError:false})
  
  // let items;
  // if(variant==='data')items = dataItems;
  // if(variant==='content')items = contentItems;


  const loadChartData = ({
    showReference=state.showReference, 
    objectType=(variant==='data'?state.resultDataObjectType:state.resultContentObjectType)
  }) => {
    if(!chartData)setLoading(true)

    let sourceFacet;
    if(variant==='data'){
      sourceFacet = {
        'source':{
          'type': 'terms',
          'field': 'database_srt',
          'mincount':1,
        }
      }
    }
    if(variant==='content'){
      sourceFacet = {
        'source':{
          'type': 'terms',
          'field': 'source_srt',
          'mincount':1,
        }
      }
    }

    axiosSolr
      .get(
        `/solr/impact/select`,
        {params:{
          q:'*:*',
          rows:0,
          fq:`object_type_srt:(${objectType}) AND
              extract_id:${state.extractData.id} ${showReference?'':'AND -reference_srt:YES'}` + 
              (state.historyObject?` AND starting_object_id:${state.historyObject.id}`:' AND -starting_object_id:*'),
          'json.facet':{
            ...sourceFacet,
            "types": {
              "type": "terms",
              "field": "object_type_srt",
              "mincount":1,
              'facet':sourceFacet
            },
          }
        }}
      ).then(response=>{
        let labels = response.data.facets.source?.buckets?.map(b=>b.val) || [];
        let series = [];

        response.data.facets.types?.buckets.forEach(b=>{
          let data = labels.map(l=>b.source.buckets.find(sb=>sb.val===l)?.count || 0)
          series.push({
            name:b.val.toUpperCase().replace(/_/g,' '),
            data:data
          })
        })

        setChartData({
          series, labels
        })
        
      }).catch(error=>{
        setError(true)
        console.log(error)
      })
  }

  useEffect(()=>{
    if(!chartData && !loading)loadChartData({})
     // eslint-disable-next-line
  },[])


  useEffect(()=>{
    if(chartData && variant==='data'){
      loadChartData({showReference:state.showReference})
    }
    // eslint-disable-next-line
  },[state.showReference, state.resultDataObjectType, variant])


  useEffect(()=>{
    if(chartData && variant==='content'){
      loadChartData({showReference:state.showReference})
    }
    // eslint-disable-next-line
  },[state.showReference, state.resultContentObjectType, variant])
  
  return (
    <div className={classes.root}>
      {loading && <CircularProgress color='secondary'/>}
      {error && <Typography>Error occurred loading chart</Typography>}
      {
        chartData && chartData.labels.length>0 &&
        <StackHorizontalBar
          labels={chartData.labels}
          data={chartData.series}
          id="data-type-chart"
        />
      }
      {
        chartData && chartData.labels.length===0 && 
        <Typography>No {variant} assets found</Typography>
      }
    </div>  
  )
}

export default withTheme()(withStyles(styles)(BySourceChart));