import React, { useRef, useState, useEffect } from 'react';
import { withTheme, withStyles, Typography, IconButton, Modal, Paper, InputBase, Button, Switch} from '@material-ui/core';
import { getIconComponent, validateEmail } from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import moment from 'moment';
import { MuiPickersUtilsProvider, TimePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import TimeIcon from '@material-ui/icons/AvTimer';
import { updatePlatformSettings } from '../../../permissionChecker';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root:{
    height:47,
    maxWidth:1160,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    overflow:'hidden',
    padding:'0 16px'
  },
  clickableChip:{
    cursor:'pointer',
    "&:hover":{
      background:theme.palette.hovered.main
    }
  },
  normalChip:{
    "&:hover":{
      background:theme.palette.hovered.main
    }
  },
  title:{
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginRight:24
  },
  boldText:{
    fontSize:13.75,
    fontWeight:700
  },
  selector:{
    ...theme.components.selector,
    overflow:'hidden',
    width:'100%',
    minWidth:140
  },
  inputBase:{
    ...theme.components.inputBase,
    width:'100%',
    "& input":{
      padding:'10px 8px'
    }
  },
  checkbox:{
    paddingLeft:0
  },
  switchIcon:{
    color:theme.palette.primary.main
  },
  switchBar:{
    backgroundColor:theme.palette.primary.main
  },
  disabledIcon:{
    color:theme.palette.primaryText.light
  },
  textField:{
    ...theme.components.inputBase,
    width:200,
    '& input':{
      padding: '18px 8px',
      color:theme.palette.primaryText.main
    },
    '& div':{
      marginTop: '0px !important'
    },
  },
})

const ListItem = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    type,
    platformSettingID,
    platformSettingName,
    initSetting,
    platformJobID,
    // settingsFetchList,
    title,
    dispValue,
    onClick,
    disabled
  } = props;

  const [modalOpen, setModalOpen] = useState(false)
  const [value, setValue] = useState(type==='platform_setting'?initSetting.value:type==='platform_job'?initSetting||{}:'')

  const [buttonHovered, setButtonHovered] = useState(false)
  
  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`setting-list-item`,
    isCancelledRef,
    disableStateCache:true
  })

  useEffect(()=>{ 
    return () => {
      isCancelledRef.current = true
    }
  },[])


  const onPlatformBatchSave = (updatedValue) => {
    axiosCerebrum
      .put(
        `/api/platformbatches/${platformJobID}`, { cron: updatedValue }
      )
      .then((response) => {
        dispatch({
          type: 'set_jobs_data',
          jobsData: [...state.jobsData.filter(el => el.id !== platformJobID),response.data]
        })
        sendAlert({type:'info',message:'Setting updated'})
      })
      .catch(error => {
        console.log(error)
        sendAlert({type:'error',message:'Error updating the setting'})
      })
    setModalOpen(false);
  }

  const onPlatformSettingSave = (updatedValue) => {
      let modifiedItem = { value: updatedValue }
      axiosCerebrum
        .put(
          `/api/settings/${platformSettingID}`, modifiedItem
        )
        .then((response) => {
          let newSettings = [...state.settingsData.filter(el => el.id !== platformSettingID),response.data]
          localStorage.setItem('platformSettings',JSON.stringify({items:newSettings}))
          updatePlatformSettings(response.data)
          dispatch({
            type: 'set_settings_data',
            settingsData: newSettings
          })
          sendAlert({type:'info',message:'Setting updated'})
        })
        .catch(error => {
          console.log(error)
          sendAlert({type:'error',message:'Error updating the setting'})
        })
      setModalOpen(false);
  }

  const resetModal = () => {
    if(type==='platform_job'){
      setValue(initSetting)
    }else{
      setValue(initSetting.value)
    }
  }

  const switchSettingIDs = [4008,50]
  const switchJobSettingIDs = [702]

  let isSwitch = (type==='platform_setting' && switchSettingIDs.includes(platformSettingID)) || (type==='platform_job' && switchJobSettingIDs.includes(platformJobID))
  if(platformSettingName==='Send Daily email at')isSwitch = false

  return (
    <div className={classes.root + (onClick?' '+classes.clickableChip:' '+classes.normalChip)} onClick={onClick} style={{background:buttonHovered?theme.palette.background.main:undefined}}>
      <Typography className={classes.title} style={disabled?{color:theme.palette.primaryText.light}:undefined}>
        {title}{ !isSwitch && platformSettingName && (type==='platform_setting' || type==='platform_job') && <span className={classes.boldText}>: {dispValue && dispValue!==''?dispValue:`No ${platformSettingName} added`}</span>}
      </Typography>
      {
        (type==='platform_setting' || type==='platform_job') && !isSwitch &&
        <IconButton 
          onClick={()=>setModalOpen(true)} 
          style={{padding:8}} 
          disabled={disabled}
          onMouseEnter={()=>setButtonHovered(true)}
          onMouseLeave={()=>setButtonHovered(false)}
        >
          {getIconComponent({label:'edit',size:24,colour:theme.palette.primaryText.light})}
        </IconButton>
      }
      {
        type==='platform_setting' && platformSettingID===4008 &&
        <div style={{display:'flex',alignItems:'center'}}>
          <Typography style={{fontSize:13.75,marginRight:8,color:value==='data_roles'?theme.palette.primary.main:theme.palette.primaryText.light}}>DATA OWNER / STEWARD ROLES (DATA ROLES)</Typography>
          <Switch
            disabled={disabled}
            checked={value==='any_user'}
            onChange={()=>{
              let newValue;
              if(value==='any_user'){
                newValue =  'data_roles'
              }else{
                newValue = 'any_user'
              }
              setValue(newValue)
              onPlatformSettingSave(newValue)
            }}
            classes={{icon:disabled?classes.disabledIcon:classes.switchIcon, bar:classes.switchBar}}
            value={value}
            color="primary"
          />
          <Typography style={{fontSize:13.75,marginLeft:8,color:value==='any_user'?theme.palette.primary.main:theme.palette.primaryText.light}}>ANY USER</Typography>
        </div>
      }
      {
        type==='platform_setting' && platformSettingID===50 &&
        <div style={{display:'flex',alignItems:'center'}}>
          <Typography style={{fontSize:13.75,marginRight:8,color:value==='false'?theme.palette.primary.main:theme.palette.primaryText.light}}>OFF</Typography>
          <Switch
            disabled={disabled}
            checked={value==='true'}
            onChange={()=>{
              let newValue;
              if(value==='true'){
                newValue =  'false'
              }else{
                newValue = 'true'
              }
              setValue(newValue)
              onPlatformSettingSave(newValue)
            }}
            classes={{icon: disabled?classes.disabledIcon:classes.switchIcon, bar:classes.switchBar}}
            value={value}
            color="primary"
          />
          <Typography style={{fontSize:13.75,marginLeft:8,color:value==='true'?theme.palette.primary.main:theme.palette.primaryText.light}}>ON</Typography>
        </div>
      }
      {
        type==='platform_job' && platformJobID===702 && isSwitch && 
        <div style={{display:'flex',alignItems:'center'}}>
        <Typography style={{fontSize:13.75,marginRight:8,color:value==='false'?theme.palette.primary.main:theme.palette.primaryText.light}}>OFF</Typography>
          <Switch
            disabled={disabled}
            checked={value && value.hour && value.minute}
            onChange={()=>{
              let newValue;
              if(value && value.hour && value.minute){
                newValue =  {}
              }else{
                newValue = {hour:moment().utc().format('HH'),minute:moment().utc().format('mm')}
              }
              setValue(newValue)
              onPlatformBatchSave(newValue)
            }}
            classes={{icon: disabled?classes.disabledIcon:classes.switchIcon, disabled:classes.disabledSwicth}}
            value={value}
            color="primary"
          />
          <Typography style={{fontSize:13.75,marginLeft:8,color:value==='true'?theme.palette.primary.main:theme.palette.primaryText.light}}>ON</Typography>
        </div>
      }

      {
        type==='link' && 
        <div style={{width:24,height:24,marginRight:8,marginLeft:8}}>
          {getIconComponent({label:'open',size:24,colour:theme.palette.primaryText.light})}
        </div>
      }


      {
        (type==='platform_setting' || type==='platform_job') && 
        <Modal
          open={modalOpen}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          hideBackdrop={false}
          style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {
            modalOpen &&
            <Paper style={{ width: 544, padding: '24px 24px 8px', outline:'none',background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}` }}>
              <Typography  style={{color:theme.palette.header.main, fontSize: 20, marginBottom: 24 }}>
                Edit {platformSettingName} {type==='platform_job'?'(UTC)':''}
              </Typography>
              
              {
                ![4008].includes(platformSettingID) && type==='platform_setting' && 
                <InputBase
                  // variant="filled"
                  className={classes.inputBase}
                  value={value}
                  onChange={event => {
                    if(platformSettingID!==200){
                      setValue(event.target.value)
                    }else{
                      setValue(event.target.value.slice(0,30))
                    }
                  }}
                />
              }
              {
                type==='platform_job' && 
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <TimePicker
                    className={classes.textField}
                    value={value}
                    ampm={false}
                    onChange={(time)=>{setValue(time)}}
                    variant="filled"
                    InputProps={{
                      startAdornment: <TimeIcon style={{width:24,height:24,color:'#888'}}/>
                    }}
                  />
                </MuiPickersUtilsProvider>
              }
              {
                platformSettingID===5011 && 
                <Typography style={{color:value==='' || validateEmail(value)?theme.palette.primaryText.light:theme.palette.error.main,fontSize:12,marginTop:4,marginLeft:8}}>
                  {value==='' || validateEmail(value)? 'Enter an email address':'Not a valid email address'}
                </Typography>
              }
              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end',marginTop:32 }}>
                <Button 
                  disabled={platformSettingID===5011 && value!=='' && !validateEmail(value)} 
                  color='primary' 
                  style={{ width: 90, height: 36, marginRight: 8 }} 
                  onClick={()=>{
                    if(type==='platform_setting')onPlatformSettingSave(value)
                    if(type==='platform_job'){
                      onPlatformBatchSave({hour:moment(value).format('HH'),minute:moment(value).format('mm')})
                    }
                  }}
                >
                  SAVE
                </Button>
                <Button color='secondary' style={{ width: 90, height: 36 }} onClick={() => { resetModal(); setModalOpen(false) }}>CLOSE</Button>
              </div>
            </Paper>
          }
        </Modal>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(ListItem));