import React, {  } from 'react';
import { withTheme,  Typography, FormControl,Select,MenuItem, FormHelperText,} from '@material-ui/core';

const ObjectTypeSelector = props => {

  const {
    classes,
    theme,
    state,
    onSelectObjectType
  } = props;

  let validTypes = [];
  state.sourceIDs.forEach(id=>{
    let source = state.sourceList.find(s=>s.id===id);
    source?.objectTypes?.forEach(type=>{
      if(!validTypes.includes(type)){
        validTypes.push(type)
      }
    })
  })

  return (
    <div className={classes.block}>
      <FormControl>
        <Select
          className={classes.selector}
          value={state.objectTypes}
          onChange={onSelectObjectType}
          disableUnderline
          displayEmpty
          renderValue={el=>{
            return (
              state.objectTypes.length===0
                ?
                <Typography style={{color:theme.palette.primaryText.light,fontSize:16}}>
                  Select an object type
                </Typography>
                :
                <div>
                  <Typography style={{color:theme.palette.primaryText.light,fontSize:12}}>
                    Select an object type
                  </Typography>
                  <Typography style={{fontSize:16,color:theme.palette.primaryText.main}}>
                    {state.objectTypes.map(el=>el.replace(/_/g,' ')).join(', ')}
                  </Typography>
                </div>
                
            )
          }}
        > 
          <MenuItem className={classes.menuItem} value={'none'}>
            Select an object type
          </MenuItem>
          {
            validTypes.map(el=>(
              <MenuItem className={classes.menuItem} value={el}>
                {el.replace(/_/g,' ')}
              </MenuItem>
            ))
          }
        </Select>
        <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>Required</FormHelperText>
      </FormControl>
    </div>
  )
}

export default withTheme()(ObjectTypeSelector);