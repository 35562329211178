import React, { } from 'react';
import { withTheme, withStyles, Button, InputBase, Typography} from '@material-ui/core';
import { checkIsValidCron } from '../../../utilities';

const styles = theme => ({
  inputBase:{
    ...theme.components.inputBase,
    width:'100%',
    height:56,
  },
  errorBorder:{
    border:`1px solid ${theme.palette.error.main}`
  }
})

const customCron = props => {

  const {
    classes,
    theme,
    activeStep,
    setActiveStep,
    customCron,
    setCustomCron,
    hideButton
  } = props;

  
  return (
    <div className={classes.root}>
      <InputBase
        value={customCron}
        onChange={(event)=>{
          setCustomCron(event.target.value)
        }}
        placeholder='0 * * * *'
        className={classes.inputBase + (checkIsValidCron(customCron)?'':' '+classes.errorBorder )}
      />
      {
        !checkIsValidCron(customCron) && customCron && 
        <Typography style={{marginTop:2,marginLeft:16,color:theme.palette.error.main,fontSize:12}}>Please enter a valid cron</Typography>
      }
      {
        !hideButton &&
        <Button color='primary' variant='contained' style={{marginTop:16}} disabled={!checkIsValidCron(customCron)} onClick={()=>{setActiveStep?.(activeStep+1)}}>NEXT</Button>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(customCron));