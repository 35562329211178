import React, { useEffect, useReducer } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body from '../../components/DataQualityDashboard/Body/Body';
import TabBar from '../../components/UI/TabBar/TabBar';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import 'url-search-params-polyfill';
import { setInitialState } from '../../utilities'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import 'url-search-params-polyfill';

const styles = theme => ({
  button: {
    margin: '8px 0px 8px 16px',
    height: '3rem',
  },
  normalText: {
    color: theme.palette.primaryText.main
  }
});


const initialState = {
  tabState: 0,
  selectedDomains: [],
  byDomainSelectedDimensions: ['overall'],
  byDomainSort: 'name asc',
  selectedCategories: [],
  byCategorySelectedDimensions: ['overall'],
  byCategorySort: 'name asc',
  selectedUseCases: [],
  byUseCaseSelectedDimensions: ['overall'],
  byUseCaseSort: 'name asc',
}

const reducer = (state, action) => {
  switch (action.type) {
    case "set_tab_state":
      return {
        ...state,
        tabState: action.tabState
      }
    case 'set_by_domain_data':
      return {
        ...state,
        byDomainData: action.byDomainData
      }
    case 'set_selected_domains':
      return {
        ...state,
        selectedDomains: action.selectedDomains
      }
    case 'set_by_domain_selected_dimensions':
      return {
        ...state,
        byDomainSelectedDimensions: action.byDomainSelectedDimensions
      }
    case 'set_by_domain_sort':
      return {
        ...state,
        byDomainSort: action.byDomainSort
      }
    case 'set_by_category_data':
      return {
        ...state,
        byCategoryData: action.byCategoryData
      }
    case 'set_selected_categories':
      return {
        ...state,
        selectedCategories: action.selectedCategories
      }
    case 'set_by_category_selected_dimensions':
      return {
        ...state,
        byCategorySelectedDimensions: action.byCategorySelectedDimensions
      }
    case 'set_by_category_sort':
      return {
        ...state,
        byCategorySort: action.byCategorySort
      }
    case 'set_by_use_case_data':
      return {
        ...state,
        byUseCaseData: action.byUseCaseData
      }
    case 'set_selected_use_cases':
      return {
        ...state,
        selectedUseCases: action.selectedUseCases
      }
    case 'set_by_use_case_selected_dimensions':
      return {
        ...state,
        byUseCaseSelectedDimensions: action.byUseCaseSelectedDimensions
      }
    case 'set_by_use_case_sort':
      return {
        ...state,
        byUseCaseSort: action.byUseCaseSort
      }
    default:
      throw new Error("Action type not supported");
  }
}

const DataQualityInsights = props => {


  const {
    history,
    // classes,
    sessionData,
    pageCache,
    storePageCache,
  } = props;


  let storedInitialState = { ...setInitialState(pageCache, initialState) };
  const [state, dispatch] = useReducer(reducer, storedInitialState);

  useEffect(() => {
    if (!state) return;
    storePageCache({ cacheID: window.location.href, ...state })
    // eslint-disable-next-line
  }, [state])

  // let tabOptions = (checkIsDataGov({ sessionData }) || checkIsDataManager({ sessionData })) ? ['BY DOMAIN', 'BY CATEGORY', 'BY USE CASE', 'BY COLLECTION', 'BY TOOL'] : ['BY DOMAIN']
  let tabOptions = ['BY DOMAIN', 'BY CATEGORY', 'BY USE CASE']

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [state.tabState])


  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              title={'Data Quality Dashboard'}
              subtitle={' '}
              minHeight={100}
              label={'data_quality_test'}
            />
          </div>)}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            state={state}
            dispatch={dispatch}
            tabOptions={tabOptions}
            sessionData={sessionData}
            history={history}
          />
        }
      />
    </div>
  )
}


DataQualityInsights.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sessionData: PropTypes.object.isRequired,
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DataQualityInsights));