import React from 'react';
import { withTheme, withStyles, Typography, Paper } from '@material-ui/core';
import { lighten, darken } from '@material-ui/core/styles/colorManipulator';
import KTooltip from '../../UI/KTooltip/KTooltip';

const styles = theme => ({
  root:{
    flex:'1 1 240px',
    overflow:'hidden',
    height:136,
    boxSizing:'border-box',
    padding:12,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.tileBorder.main}`,
    textAlign:'center'
  },
  smallCard:{
    height:80,
    flex:'0 1 240px'
  },
  clickableCard:{
    cursor:'pointer',
    background:localStorage.hasOwnProperty('dark')?darken(theme.palette.primary.dark,0.7):lighten(theme.palette.primary.dark,0.9),
    '&:hover':{
      background:localStorage.hasOwnProperty('dark')?darken(theme.palette.primary.dark,0.55):lighten(theme.palette.primary.dark,0.75),
    }
  },
  title:{
    fontSize:12,
    letterSpacing:2,
    // marginBottom:24,
    maxWidth:'100%',
    height:38,
    marginBottom:4,
    overflow:"hidden",
    wordBreak:'break-word'
    // textOverflow:'ellipsis',
    // overflow:'hidden',
    // whiteSpace:'nowrap'
  },
  value:{
    fontSize:32,
  },
  selectedText:{
    color:theme.palette.background.main
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  tooltip:{
    fontSize:13.75,
  },
  hide:{
    display:'none'
  }
})

const DataTile = props => {

  const {
    classes,
    theme,
    onClick,
    selected,
    clickable,
    title,
    value,
    tooltip,
    isSmallCard,
    valueClassName
  } = props;

  const getTextStyle = () => {
    if(selected)return classes.selectedText
    return classes.normalText
  }
  
  return (
    <KTooltip title={tooltip} classes={{tooltip:tooltip?classes.tooltip:classes.hide}}>
      <Paper 
        className={classes.root + (clickable?` ${classes.clickableCard}`:'') + (isSmallCard?` ${classes.smallCard}`:'')} 
        onClick={clickable?onClick:undefined} 
        elevation={0}
        style={{background:selected?theme.palette.primary.dark:undefined}}
      >
        <KTooltip title={title} placement="top">
          <Typography style={{height:isSmallCard?16:undefined}} className={`${classes.title} ${getTextStyle()}`}>{title}</Typography>
        </KTooltip>
        <Typography data-test-classname={valueClassName} style={{fontSize:isSmallCard?24:undefined}} className={`${classes.value} ${getTextStyle()}`}>{value}</Typography>
      </Paper>  
    </KTooltip>
  )
}

export default withTheme()(withStyles(styles)(DataTile));