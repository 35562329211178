import React, { useEffect } from 'react';
import { withStyles, Typography, CircularProgress, Select, MenuItem } from '@material-ui/core';
import { onClickResultItem,getDispFields } from '../../../utilities';
import ResultItem2 from '../../UI/SearchResults/ResultItem2';
import axiosCerebrum from '../../../axios-cerebrum';

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.header.main
  },
	selector: {
    ...theme.components.selector,
    width: 180,
	},
  normalText:{
    color:theme.palette.primaryText.main
  },
  subtitle:{
    fontSize:12,
    color:theme.palette.primaryText.light
  },
})

const Reference = props => {

	const {
		history,
		classes,
		state,
		dispatch,
	} = props;


	const sortByItems = [
		{ dispName: 'Frequently used', value: 'total_usage_srt desc' },
		{ dispName: 'Recently used', value: 'last_used_srt desc' },
		{ dispName: 'A-Z', value: 'name_srt asc' },
		{ dispName: 'Z-A', value: 'name_srt desc' }
  ]
  
  const loadData = async () => {
    if(!state.basicData.reference_sources || state.basicData.reference_sources.length===0){
      dispatch({type:'set_reference_data',referenceData:[]})
    }
    dispatch({type:'set_reference_data',referenceLoading:true})
    try{
      let sourcesData = []
      for(let i=0; i<state.basicData.reference_sources.length; i++){
        let sourceId = state.basicData.reference_sources[i].reference_source.id;
        await axiosCerebrum
          .get(`/api/sources/${sourceId}`)
          .then(response=>{
            if(response.data)sourcesData.push(response.data)
          })
      }
      dispatch({type:'set_reference_data',referenceData:sourcesData})
    }catch(error){
      console.log(error)
      dispatch({type:'set_reference_data',referenceError:true})
    }
  }

  useEffect(()=>{
    if(!state.referenceData)loadData(state.referenceFilter);
    // eslint-disable-next-line
  },[])


  if(state.referenceError)return <Typography className={classes.normalText}>Error occurred loading sources</Typography>

	return (
		<div className={classes.root}>
			<div style={{ display: 'flex', alignItems: 'flex-end',justifyContent:'space-between' }}>
				<div>
          <Typography className={classes.title}>{state.basicData.reference_sources.length} SOURCE(S)</Typography>
					<Typography className={classes.subtitle}>This Table may be referenced in 1 or more Sources. Select a Source to see more details</Typography>
				</div>
				<div>
					<Select
						className={classes.selector}
						value={state.referenceFilter}
						onChange={event =>{loadData(event.target.value);dispatch({ type: 'set_reference_filter', referenceFilter: event.target.value })}}
					>
						{
							sortByItems.map(el => (
								<MenuItem className={classes.menuItem} value={el.value}>
									<span>{el.dispName}</span>
								</MenuItem>
							))
						}
					</Select>
				</div>
			</div>

			<div style={{marginTop:30}}>
        {
          state.referenceLoading && 
          <div style={{width:"100%",display:'flex',justifyContent:'center'}}>
            <CircularProgress color='secondary' style={{marginTop:50}}/>
          </div>
        }
        {
          state.referenceData && 
          state.referenceData.map(item=>(
            <ResultItem2
              item={item}
              key={item.id}
              dispBody={getDispFields(item,'dispBody')}
              dispSubtitle={getDispFields(item,'dispSubtitle')}
              dispTitle={getDispFields(item,'dispTitle')}
              label={'source'}
              showIcon
              showUnderline
              onClick={() => onClickResultItem({id:item.id, label: 'source', history: history, item: item })}
            />
          ))
        }
			</div>
		</div>
	)
}


export default withStyles(styles)(Reference);