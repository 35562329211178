import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Typography, CircularProgress, Select, MenuItem } from '@material-ui/core';
import {  formatBusinessnName, getIconComponent, getTestScoreColor, getUserRoles, onClickResultItem } from '../../../../utilities';
import moment from 'moment';
import KTooltip from '../../KTooltip/KTooltip';
import { ContextMenuTrigger, ContextMenu } from 'react-contextmenu';
import CustomMenu from '../../../UI/ContextMenu/ContextMenu'
import { useStore } from 'react-redux';

const styles = theme => ({
  root: {
    marginTop:12,
  },
  legendText:{
    fontSize:12,
    color:theme.palette.primaryText.main
  },
  legendBox:{
    width:13.75,
    height:13.75,
    margin:'0 8px'
  },
  columnHeader:{
    fontSize:12,
    color:theme.palette.primary.main,
    letterSpacing:1.5,
    marginRight:16
  },
  listItem:{
    display:'flex',
    overflow:'hidden',
    alignItems:'center',
    cursor:'pointer',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    height:47,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  listText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginRight:16
  },
  select:{
    ...theme.components.selector,
    width:200
  }
})


function DateDetail(props) {
  const {
    classes,
    theme,
    history,
    selectedDate,
    // setSelectedDate,
    detailDateData,
    detailDateSort,
    setDetailDateSort
  } = props;

  const store = useStore();
  const sessionData = store.getState().auth.session_user;
  const roles = getUserRoles(sessionData.user_role)

  const sortItems = (a,b)=> {
    if(detailDateSort==='a-z') return a.related.name.localeCompare(b.related.name)
    if(detailDateSort==='z-a') return b.related.name.localeCompare(a.related.name)
    if(detailDateSort==='score desc') return b.value-a.value
    if(detailDateSort==='score asc') return a.value-b.value
  }

  return (
    <div className={classes.root}>
      <div style={{display:'flex',justifyContent:'space-between',marginBottom:16}}>
        <div style={{flexGrow:1}}> 
          <Typography style={{fontSize:16,color:theme.palette.primary.main}}>DQ Tests run on {moment(selectedDate).format('DD MMM YYYY')}</Typography>
          <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>Results for DQ tests performed on this day</Typography>
        </div>
        <Select
          value={detailDateSort}
          className={classes.select}
          onChange={e=>setDetailDateSort(e.target.value)}
          disableUnderline
        >
          <MenuItem value={'a-z'}>A-Z</MenuItem>
          <MenuItem value={'z-a'}>Z-A</MenuItem>
          <MenuItem value={'score desc'}>Highest DQ Score</MenuItem>
          <MenuItem value={'score asc'}>Lowest DQ Score</MenuItem>
        </Select>
      </div>
      {
        detailDateData?.[selectedDate]?.loading &&
        <CircularProgress color='secondary'/>
      }
      {
        detailDateData?.[selectedDate]?.error &&
        <Typography>
          Error occurred loading data
        </Typography>
      }
      {
        detailDateData?.[selectedDate]?.data?.length===0 &&
        <Typography>
          No tests run on this day
        </Typography>
      }
      {
        detailDateData?.[selectedDate]?.data?.length>0 && 
        <>
          <div style={{display:'flex',marginBottom:8}}>
            <div style={{flex:'0 0 24px',marginLeft:16,marginRight:16}}></div>
            <Typography className={classes.columnHeader} style={{flex:'1 1 200px',minWidth:160}}>NAME</Typography>
            <Typography className={classes.columnHeader} style={{flex:'1 1 200px',minWidth:100}}>SCORE</Typography>
          </div>
          {
            detailDateData?.[selectedDate]?.data.sort(sortItems).map(el=>(
              <>
                <ContextMenuTrigger id={el.related.id+el.object.id}>
                  <div className={classes.listItem} onClick={()=>onClickResultItem({item:el,id:el.related.id,label:'data_quality_test',history,newWindow:true})}>
                    <div style={{flex:'0 0 24px',height:24,marginLeft:16,marginRight:16}}>
                      {getIconComponent({label:"data_quality_test",size:24,colour:theme.palette.primaryText.light})}
                    </div>
                    <KTooltip title={formatBusinessnName({roles, dispTitle:el.related.name, item:el.related})}>
                      <Typography className={classes.listText} style={{flex:'1 1 200px',minWidth:160}}>{formatBusinessnName({roles, dispTitle:el.related.name, item:el.related})}</Typography>
                    </KTooltip>
                    <Typography className={classes.listText} style={{flex:'1 1 200px',minWidth:160}}>
                      <div style={{width:'max-content',fontSize:13.75,maxWidth:'100%',color:'#000',boxSizing:'border-box',borderRadius:2,padding:'2px 8px',background:getTestScoreColor(el.value)}}>
                        {Math.round(el.value*100)/100}
                      </div>
                    </Typography>
                  </div>
                </ContextMenuTrigger>
                <ContextMenu id={el.related.id+el.object.id}>
                  <CustomMenu
                    itemUrl={onClickResultItem({item:el,id:el.related.id,label:'data_quality_test',urlOnly:true})}
                    actions={[
                      'open_new_tab'
                    ]}
                  />
               </ContextMenu>
              </>
            ))
          }
        </>
      }
    </div>
  );
}

DateDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  selectedDate: PropTypes.string.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  detailDateData: PropTypes.object.isRequired,
  setDetailDateData: PropTypes.func.isRequired,
}

export default withTheme()(withStyles(styles)(DateDetail));