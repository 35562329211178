import React, {useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { CircularProgress,Typography } from '@material-ui/core';
import keycloakInstance from '../../keycloak-instance';
import axiosCerebrum from '../../axios-cerebrum'
import { setPlatformSettings } from '../../permissionChecker';
import { loadCategory } from '../../CategoryManager';

const KeyCloakLogin = props => {

  const {
    onLoginSuccess,
    history
  } = props

  const loadVersionNumber = () => {
    axiosCerebrum
      .get('/api/status')
      .then(response=>{
        localStorage.setItem('k_platform_version',response.data.version)
      })
      .catch(error=>{
        console.log(error)
      })
  }

  useEffect(()=>{
    keycloakInstance.init({onLoad: 'login-required', checkLoginIframe: false, promiseType: 'native'}).then(authenticated => {
      if(authenticated){
        const data = {};
        loadVersionNumber()
        ///////////////////
        // get user info
        axiosCerebrum
          .get('/api/me')
          .then(async response=>{
            data.user={...keycloakInstance.tokenParsed,...response.data};
            data.user.id=response.data.id;
            data.keycloak = keycloakInstance;

            if(localStorage.hasOwnProperty('userInfo')){
              try{
                let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                if(userInfo.id!==response.data.id){
                  localStorage.clear();
                }
              }catch(error){
                console.log(error)
              }
            }

            localStorage.setItem('userInfo',JSON.stringify(response.data));

            await new Promise(resolve=>{setTimeout(resolve,200)})

            /////////////////////
            // get setting
            await loadCategory();
            
            await axiosCerebrum
              .get('/api/settings?per_page=100')
              .then(response=>{
                localStorage.setItem('platformSettings',JSON.stringify(response.data))
                setPlatformSettings(response.data.items)
              })
              .catch(error=>{
                console.log(error)
              })
            
            await axiosCerebrum
              .get('/api/me/settings',{
                params:{
                  per_page:50
                }
              })
              .then(response=>{
                localStorage.setItem('userSettings',JSON.stringify(response.data))
              })
              .catch(error=>{
                console.log(error)
              })

            await axiosCerebrum
              .get(
                `/api/users/${response.data.id}/related?object_name=GROUP&relationship=MEMBER_OF&per_page=50`,
              )
              .then(res => {
                if(res.data.items.length>0){
                  localStorage.setItem('userGroupData', JSON.stringify(res.data.items));
                }
                onLoginSuccess(data);
                if(response.data.new_user===true){
                  history.push('/user_onboard')
                }else{
                  if(window.location.pathname==='/user_data_error') history.push('/home')
                }
              })
              .catch(error=>{
                onLoginSuccess(data);
                if(response.data.new_user===true){
                  history.push('/user_onboard')
                }else{
                  if(window.location.pathname==='/user_data_error') history.push('/home')
                }
              })
          })
          .catch(error => {
            onLoginSuccess({user:{name:keycloakInstance.tokenParsed.name}});
            history.push('/user_data_error')
            console.log(error, 'Error getting user data');
          });
          
      }
    })
  // eslint-disable-next-line
  }, [])
  keycloakInstance.onAuthLogout = () => {

  }
  return (
    <div align='center'>
      <div style={{ textAlign: 'center', marginTop: '5%' }}>
      <CircularProgress color="secondary" />
      <Typography >Loading</Typography>
    </div>
    </div>
  )

}

//export default KeyCloakLogin;

const mapStateToProps = state => {
  return {
    //loggedIn: state.auth.logged_in
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onLoginSuccess: (data) => dispatch(actions.loginSuccess(data)),
    onLogoutSuccess: data=>dispatch(actions.logoutSuccess(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyCloakLogin);
