import theme, { palette } from "../../../../theme";
import { getIconComponent, getTestScoreColor, getTrustScoreBand, getTrustScoreColor } from "../../../../utilities";

export const setNodeFocus = ({focus, subFocus, children, group, type, isExclude}) => {
  const shouldNodeBeFocused = node => {
    if(focus.length===0)return [true];
    let borderColour;
    let funcName = type==='and'?'every':'some';
    let shouldFocus = focus[funcName](f=>{
      let result = false;
      if(f==='trust'){
        if(type==='and' && subFocus.trust.length>1)return false;
        result = subFocus.trust.some(s=>node.data?.obj?.trust===s || (!node.data?.obj?.trust && s==='NONE'));
      }
      if(f==='classification'){
        if(type==='and' && subFocus.classification.length>1)return false;
        result = subFocus.classification.some(s=>node.data?.obj?.classification_kc_txts?.includes(s) || (!node.data?.obj?.classification_kc_txts && s==='NONE'));
      }
      if(f==='domain'){
        if(type==='and' && subFocus.domain.length>1 && subFocus.domain.find(s=>s==='NONE'))return false;
        result = subFocus.domain[funcName](s=>node.data?.obj?.domain_kc_txts?.includes(s) || (!node.data?.obj?.domain_kc_txts && s==='NONE'));
      }
      if(f==='category'){
        if(type==='and' && subFocus.category.length>1)return false;
        result = subFocus.category.some(s=>node.data?.obj?.category_kc_txts?.includes(s) || (!node.data?.obj?.category_kc_txts && s==='NONE'));
      }
      if(f==='verified'){
        if(type==='and' && subFocus.verified.length>1 && subFocus.verified.find(s=>s==='NONE'))return false;
        result = subFocus.verified[funcName](s=>node.data?.obj?.verified_kc_txts?.includes(s) || (!node.data?.obj?.verified_kc_txts && s==='NONE'));
        borderColour = subFocus.verified[funcName](s=>node.data?.obj?.verified_kc_txts?.includes(s))?palette.success.main:undefined;
      }
      if(f==='not_verified'){
        if(type==='and' && subFocus.not_verified.length>1 && subFocus.not_verified.find(s=>s==='NONE'))return false;
        result = subFocus.not_verified[funcName](s=>node.data?.obj?.not_verified_kc_txts?.includes(s) || (!node.data?.obj?.not_verified_kc_txts && s==='NONE'));
        borderColour = subFocus.not_verified[funcName](s=>node.data?.obj?.not_verified_kc_txts?.includes(s))?palette.error.main:undefined;
      }
      if(f==='dq_score'){
        if(type==='and' && subFocus.dq_score.length>1)return false;
        result = (
          (subFocus.dq_score.includes('NONE') && isNaN(node.data.obj?.dq_score_overall_srt)) ||
          subFocus.dq_score.filter(el=>el!=='NONE').some(s=>node.data.obj?.dq_score_overall_srt>=Number(s.split(' TO ')[0]).valueOf() && node.data.obj?.dq_score_overall_srt<=Number(s.split(' TO ')[1]).valueOf() )
        )
      }
      if(f==='dq_failed'){
        result = node.data?.obj?.data_quality_failed_txt==='YES';
      }
      if(f==='owned_txt'){
        result = subFocus.owned_txt[funcName](s=>node.data?.obj?.owned_txt?.includes(s));
      }
      if(f==='steward_count'){
        if(type==='and' && subFocus.steward_count.length>1)return false;
        result =  (
          (subFocus.steward_count.includes('NONE') && node.data.obj?.steward_count===0) ||
          (subFocus.steward_count.includes('ONE') && node.data.obj?.steward_count===1) ||
          (subFocus.steward_count.includes('MANY') && node.data.obj?.steward_count>1)
        )
      }
      if(f==='documented'){
        result =  subFocus.documented[funcName](s=>node.data?.obj?.documented_txt?.includes(s));
      }
      if(f==='issue_count'){
        if(type==='and' && subFocus.issue_count.length>1)return false;
        result =  (
          (subFocus.issue_count.includes('NONE') && node.data.obj?.number_of_issues_srt===0) ||
          (subFocus.issue_count.includes('ONE') && node.data.obj?.number_of_issues_srt===1) ||
          (subFocus.issue_count.includes('MANY') && node.data.obj?.number_of_issues_srt>1)
        )
      }
      if(f==='usage_txt'){
        if(type==='and' && subFocus.usage_txt.length>1)return false;
        result =  subFocus.usage_txt.some(s=>node.data?.obj?.usage_txt===s || (!node.data?.obj?.usage_txt && s==='NONE'));
      }
      if(f==='load_frequency_txt'){
        if(type==='and' && subFocus.load_frequency_txt.length>1)return false;
        result =  subFocus.load_frequency_txt.some(s=>node.data?.obj?.load_frequency_txt===s || (!node.data?.obj?.load_frequency_txt && s==='NONE'));
      }
      // instances focus
      if(f.includes('_kc_txts')){
        if(type==='and'){
          return subFocus[f].every(s=>node.data?.obj?.[f]?.includes(s) || (!node.data?.obj?.[f] && s==='NONE'));
        }else{
          return subFocus[f].some(s=>node.data?.obj?.[f]?.includes(s) || (!node.data?.obj?.[f] && s==='NONE'));
        }
      }
      return result
    })

    return [
      isExclude?!shouldFocus:shouldFocus,
      {
        borderColour
      }
    ]
  }

  let isAllChildrenFaded = true;

  children.forEach(n=>{
    let results = shouldNodeBeFocused(n);
    n.data.faded = !results[0];
    if(!n.data.faded)isAllChildrenFaded = false;
    if(results[1]?.borderColour){
      n.data.overwriteBorderColour = results[1].borderColour;
      n.data.borderWidth = 4;
    }else{
      n.data.overwriteBorderColour = undefined;
      n.data.borderWidth = 2;
    }
  })
  if(children.length===0 && !group.data.childLoading)isAllChildrenFaded = false;
  group.data.faded = isAllChildrenFaded;
}

export const setLinkFocus = ({links}) => {
  links.forEach(l=>{
    l.data.faded = l.targetRef?.data.faded || l.sourceRef?.data.faded;
  })
}

export const addDuplicateTag = ({node}) => {
  if(node.data?.tags?.find(t=>t.id==='duplicated'))return;
  let dupTag = {
    id:'duplicated',
    tooltip:"Duplicated on map",
    icon:<svg width={16} height={16}><rect width={8} height={8} fill="transparent" x={4} y={4}/>{getIconComponent({label:'duplicate',size:20, colour:palette.error.main, background:theme.palette.error.main})}</svg>
  }
  node.data.tags = [dupTag, ...(node.data.tags||[])]
}

export const addWarningTag = ({node}) => {
  if(node.data?.tags?.find(t=>t.id==='warning'))return;
  let warning = node.data.obj?.warning_notice_kc_txts?.join('\n')
  let warningTag = {
    id:'warning',
    tooltip:warning,
    icon:<div style={{width:16, height: 16}}>{getIconComponent({label:'warning_notice',size:16, colour:palette.error.main, background:theme.palette.error.main})}</div>
  }
  node.data.tags = [warningTag, ...(node.data.tags||[])]
}


export const setNodeTag = ({tagView, children}) => {

  children.forEach(c=>{
    let obj = c.data.obj;
    let label = obj.object_type_txt || obj.object_type;
    if(obj.active_txt==='NO'){
      c.data.tags = [
        {
          id:'deleted',
          tooltip:"This object has been deleted",
          icon:<svg width={16} height={16}><rect width={8} height={8} fill="#fff" x={4} y={4}/>{getIconComponent({label:'clear_circle',size:16, colour:palette.error.main, background:'#fff'})}</svg>
        }
      ]
      return;
    }

    if(tagView==='trust'){
      if(obj.reference_txt==='YES'){
        c.data.tags = [];
        return;
      }
      if(!isNaN(obj.trust_srt) && !['TOOL','DATABASE','COLLECTION_INSTANCE'].includes(label)){
        c.data.tags = [{
          id:'trust',
          tooltip:"Trust score: " + getTrustScoreBand({trustScore:obj.trust_srt}),
          icon:<svg width={16} height={16}><circle r={7} cx={8} cy={8} stroke={palette.primary.dark} fill={getTrustScoreColor(obj.trust_srt)} /></svg>
        }]
        return;
      }
    }

    if(tagView==='dq_score'){
      if(['TABLE','COLUMN','DATASET','DATASET_TABLE','DATASET_FIELD','CODE'].includes(label) && obj.number_of_dq_tests_srt){
        let score = obj.dq_score_overall_srt;
        let isNoDQScore = isNaN(score)
        c.data.tags = [{
          id:'dq_score',
          tooltip:isNoDQScore?"No DQ score found":("Overall DQ score: " + score),
          icon:
              isNoDQScore?
              getIconComponent({size:16, colour:palette.chartDisabledIcon.main}):
              <svg width={16} height={16}><circle r={7} cx={8} cy={8} stroke={palette.primary.dark} fill={getTestScoreColor(score,true)} /></svg>
        }]
        return;
      }
    }

    if(tagView==='knowledge'){
      if(obj.has_knowledge_txt && ['ONE','MANY'].includes(obj.has_knowledge_txt)){
        let score, text;
        if(obj.has_knowledge_txt==='ONE'){
          score = 50;
          text = 'One note added';
        }
        else if(obj.has_knowledge_txt==='MANY'){
          score = 100;
          text = 'More than one notes added';
        }
        c.data.tags = [{
          id:'knowledge',
          tooltip:text,
          icon:(<svg width={16} height={16}><circle r={7} cx={8} cy={8} stroke={palette.primary.dark} fill={getTestScoreColor(score,true)} /></svg>)
        }]
        return;
      }
    }
    c.data.tags = [];
  })
}
