import React from 'react';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toTitleCase } from '../../../utilities';


const styles = theme => ({
  title:{
    color:theme.palette.primaryText.main,
    fontSize:13.75,
    marginBottom:12
  },
  subtitle:{
    fontSize:16,
    color:theme.palette.primaryText.light
  },
  block:{
    marginBottom:32
  }
})

function CreateCollection(props) {

  const {
    classes,
    theme,
    state,
  } = props;

  
  return (
    <div className={classes.root}>
      <Typography color='primary' style={{fontSize:20}}>REVIEW COLLECTION DETAILS</Typography>
      <Typography style={{color:theme.palette.primaryText.light, whiteSpace:'pre-wrap',fontSize:12,marginBottom:32}}>
        {`Check the details below are correct before creating the collection`}
      </Typography>
      <div style={{display:'flex',width:'100%'}}>
        <div style={{flexBasis:'45%',marginRight:'10%'}}>
          {
            !state.editMode &&
            <div className={classes.block}>
              <Typography color='primary' className={classes.title}>COLLECTION TYPE</Typography>
              <Typography className={classes.subtitle}>{state.collectionType.split('_').join(' ')}</Typography>
            </div>
          }
          {
            !state.editMode &&
            <div className={classes.block}>
              <Typography color='primary' className={classes.title}>COLLECTION NAME</Typography>
              <Typography className={classes.subtitle}>{state.collectionName}</Typography>
            </div>
          }
          
          <div className={classes.block}>
            <Typography color='primary' className={classes.title}>DESCRIPTION</Typography>
            <Typography style={{color:theme.palette.primaryText.light,fontSize:13.75,overflowWrap:"break-word"}}>{state.description.trim()===''?'No description':state.description}</Typography>
          </div>
          <div className={classes.block}>
            <Typography color='primary' className={classes.title}>LINKED TO</Typography>
            <Typography className={classes.subtitle}>{state.linkTo.map(el=>toTitleCase(el.split('_').join(' '))).join(', ')}</Typography>
          </div>
          <div className={classes.block}>
            <Typography color='primary' className={classes.title}>EDITABLE BY</Typography>
            <Typography className={classes.subtitle}>{state.linkBy.map(el=>toTitleCase(el.split('_').join(' '))).join(', ')}</Typography>
          </div>
        </div>
        <div style={{flexBasis:'45%'}}>
          <div className={classes.block}>
            <Typography color='primary' className={classes.title}>COLLECTION PROPERTIES</Typography>
            {
              state.properties.map(el=>(
                <div style={{width:344, marginLeft:16, height:64,display:'flex',alignItems:'center',borderBottom:'1px solid #DDD'}}>
                  <div>
                    <Typography style={{fontSize:16,color:theme.palette.primaryText.main}}>{el.name}</Typography>
                    <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light}}>{el.data_type}</Typography>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

CreateCollection.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(CreateCollection));

