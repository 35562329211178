import React, { useEffect, } from 'react';
import { withTheme, CircularProgress, withStyles, Typography} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import { mapObjectName, removeUrlQueryArg, titleCaseObjectName } from '../../../utilities'
import {  contributedObjects} from '../utils'
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import 'url-search-params-polyfill';
import ContributedList from './ContributedList';
 
const styles = theme => ({
  root: {
    // display:'flex',
    // overflow:'hidden'
  },
})

const ContributedTabLoader = props => {

  const {
    classes,
    history,
    state,
    dispatch,
    sessionData,
    headerTop
  } = props;

  const loadListTabs = async () => {
    dispatch({type:'set_contributed_list',contributedListLoading:true})
    await axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related/objects`,
        {params:{
          relationship:'CREATOR_OF',
          per_page:50,
          page:1,
        }}
      )
      .then(response=>{
        response.data.items = response.data.items.filter(el=>contributedObjects.includes(el.name))
        if(response.data.items.length===0){
          dispatch({
            type:'set_contributed_list',
            contributedListData:{}
          })
          return;
        }else{
          let objMap = {}
          response.data.items.forEach(i=>{
            objMap[i.name] = {}
          })
          const urlSearch = new URLSearchParams(window.location.search);
          const subTabName = urlSearch.get('subTabName')
          if(subTabName && Object.keys(objMap).includes(subTabName)){
            dispatch({type:'set_contributed_tab_state',contributedTabState:Object.keys(objMap).indexOf(subTabName)})
            window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['subTabName']}));
          }
          dispatch({
            type:'set_contributed_list',
            contributedListData:objMap,
          })
        }
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_contributed_list',contributedListError:true})
      })
  }

  useEffect(()=>{
    if((!state.contributedListData && !state.contributedListLoading)){
      loadListTabs()
    }
    if(state.contributedListData){
      const urlSearch = new URLSearchParams(window.location.search);
      const subTabName = urlSearch.get('subTabName')
      if(subTabName && Object.keys(state.contributedListData).includes(subTabName)){
        dispatch({type:'set_contributed_tab_state',contributedTabState:Object.keys(state.contributedListData).indexOf(subTabName)})
        window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['subTabName']}));
      }
    }
    // eslint-disable-next-line
  },[])

  if(state.contributedListLoading ){
    return <CircularProgress color='secondary' />
  }
  if(state.contributedListError ){
    return <Typography>Error occurred loading items</Typography>
  }
  if(!state.contributedListData)return <></>
  if(Object.keys(state.contributedListData).length===0){
    return <Typography>No items found</Typography>
  }
  return (
    <div className={classes.root}>
      <div style={{float:'left'}}>
        <VerticalTabBar
          tabOptions={Object.keys(state.contributedListData).map(el=>titleCaseObjectName(mapObjectName(el,true)).toUpperCase())}
          tabState={state.contributedTabState}
          setTabState={value => {
            dispatch({ type: 'set_contributed_tab_state', contributedTabState: value })
            window.scroll({top:0,behavior:'smooth'})
          }}
        />
      </div>
      <div style={{marginLeft:280,}}>
        <ContributedList 
          key={state.contributedTabState}
          state={state} 
          dispatch={dispatch} 
          sessionData={sessionData} 
          objectType={Object.keys(state.contributedListData)[state.contributedTabState]} 
          history={history}
          headerTop={headerTop}
        />
      </div>
    </div>
  )
}


export default withTheme()(withStyles(styles)(ContributedTabLoader));