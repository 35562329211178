import React, { useEffect } from 'react';
import { withStyles, withTheme, Button, Stepper, StepLabel, Step} from '@material-ui/core';
import ObjectSelector from './ObjectSelector';
import AssessmentDetails from './AssessmentDetails';

const styles = theme => ({
  root: {

  },
  labelText: {
    color:theme.palette.primaryText.light
  },
  selectedLabelText:{
    color:`${theme.palette.primary.main} !important`,
    fontWeight:'400 !important'
  },
  stepIcon:{
    cursor:'pointer'
  },
  stepper:{
    marginBottom:20
  },
  step:{
    '& svg':{
      color:theme.palette.primaryText.light
    },
  }
  
})

const MultiAssessmentDetails = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    history,
    presetObjectIDs,
    onRun
  } = props;

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.multiIAStep])

  let bodyContent;

  switch(state.multiIAStep){
    case 0:
      bodyContent = (
        <ObjectSelector
          state={state}
          dispatch={dispatch}
          history={history}
          presetObjectIDs={presetObjectIDs}
        />
      )
      break;
    case 1:
      bodyContent = (
        <AssessmentDetails
          state={state}
          dispatch={dispatch}
          history={history}
          onRun={onRun}
        />
      )
      break;
    default:
  }

  const onCancel = () => {
    history.goBack();
  }

  const requiredFilled = (step) => {
    if(step===0){
      return state.multiIAObjects?.length===0?false:true;
    }
    return true;
  }

  const onButtonClick = (step) => {
    if(step===2){
      
    }else{
      dispatch({type:'set_multi_ia_step',multiIAStep:step+1})
    }
  }

  return (
    <div className={classes.root}>
      <Stepper className={classes.stepper} activeStep={state.multiIAStep} alternativeLabel>
        {['Select items to assess','Select assessment properties'].map((label,index) => (
          <Step  className={classes.step}  key={label} completed={false}>
            <StepLabel 
              onClick={()=>{dispatch({type:'set_multi_ia_step',multiIAStep:index})}}
              classes={{
                active:classes.selectedLabelText,
                iconContainer:classes.stepIcon,
                label:classes.labelText
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {bodyContent}
      <div style={{height:120}}></div>

      <div style={{ left:0,right: 0, zIndex:99, height:80, borderTop:`1px solid ${theme.palette.listItemDivider.main}`, backgroundColor: theme.palette.background.main, position: 'fixed', bottom: 0,display:'flex',alignItems:'center',justifyContent:'flex-end' }}>
        {
          state.multiIAStep>0 &&
          <Button color='primary' onClick={()=>dispatch({type:'set_multi_ia_step',multiIAStep:state.multiIAStep-1})} style={{marginLeft:30,width:120,height:48,marginRight:8}}>
            BACK
          </Button>
        }
        <Button style={{width:120,height:48,color:theme.palette.primaryText.light,marginRight:state.multiIAStep===0?8:'15%'}} data-testid = 'reset' onClick={onCancel}>
          CANCEL
        </Button>
        {
          state.multiIAStep===0 && 
          <Button color='primary' variant='contained' disabled={!requiredFilled(state.multiIAStep)} onClick={()=>onButtonClick(state.multiIAStep)} style={{marginLeft:30,width:120,height:48,marginRight:'15%'}}>
            NEXT
          </Button>
        }
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(MultiAssessmentDetails));