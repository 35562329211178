import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, IconButton, ClickAwayListener } from '@material-ui/core';
import PropTypes from 'prop-types';
// import axiosCerebrum from '../../../../axios-cerebrum'
import axiosCerebrum from '../../../../axios-cerebrum';
import { getIconComponent, getLabelPlural, getPartialMatchSearchString } from '../../../../utilities';
import SearchSelector from '../../SearchSelector/SearchSelector';
import { getPutPayload } from '../../UpdateInput/utils';
import { tileStyles } from './utils/styles';
import KTooltip from '../../KTooltip/KTooltip';
import { measureText } from './utils/utils';
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  suggestionItem:{
    padding:'8px 16px',
    cursor:'pointer',
    fontSize:13.75,
    '&:hover':{
      background:theme.palette.hovered.main
    },
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    overflow:'hidden',
    // borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    color:theme.palette.primaryText.main,
  },
  collectionChip:{
    background:theme.palette.collectionChip.main,
    color:'#000',
    padding:'2px 8px',
    borderRadius:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  tag:{
    ...tileStyles.tag,
    background:theme.palette.chip.main,
    border:`1px solid ${theme.palette.chipBorder.main}`,
  },
  tagText:{
    ...tileStyles.tagText,
    color:theme.palette.primaryText.main
  },
  iconButton:{
    ...tileStyles.deleteButton
  }
})

function AliasManager(props) {

  const {
    classes,
    theme,
    // history,
    object,
    onAddTileData,
    tileData,
  } = props;

  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [updating, setUpdating] = useState(false)
  const timeoutRef = useRef()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const loadAlias = () => {
    onAddTileData(
      {
        "disp_body":object.synonyms?object.synonyms.map(t=>({
          name:t,
        })):[],
        "disp_title":"ALIAS",
        "id":"alias",
      }
    )   
    setLoading(false)     
  }

  useEffect(()=>{
    if(!tileData){
      loadAlias()
    }
  // eslint-disable-next-line
  },[])


  const onUpdateAlias = (synonyms) => {
    setUpdating(true)
    axiosCerebrum
      .put(
        `/api/${getLabelPlural(object.object.name)}/${object.id}`,
        getPutPayload({property:'synonyms',value:synonyms,data:object})
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":response.data.synonyms?response.data.synonyms.map(t=>({
              name:t,
            })):[],
            "disp_title":"ALIAS",
            "id":"alias",
          }
        )   
        setUpdating(false)
        setLoading(false)     
        clearTimeout(timeoutRef.current)
      })
      .catch(error=>{
        console.log(error)
        setUpdating(false)
        setLoading(false)     
        clearTimeout(timeoutRef.current)
        sendAlert({ message: 'Error occurred updating alias, please try again', type: 'error'})
      })
  }


  const onLinkTerm = term => {
    if(updating)return;
    setSearchValue('')
    setUpdating(true)
    axiosCerebrum
      .put(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=RELATED&object_id=${term.id}`
      )
      .then(response=>{
        onUpdateAlias([...(tileData?tileData.disp_body.map(el=>el.name):[]),term.name_txt])
        window.postMessage({reload_related_terms:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
      })
      .catch(error=>{
        if(error.response && error.response.status && error.response.status===409){
          onUpdateAlias([...(tileData?tileData.disp_body.map(el=>el.name):[]),term.name_txt])
          return;
        }
        console.log(error)
        sendAlert({ message: 'Error occurred linking the Term, please try again', type: 'error' })
      })
  }
    
  const onAddAlias = name => {
    if(updating)return;
    setSearchValue('')
    onUpdateAlias([...(tileData?tileData.disp_body.map(el=>el.name):[]),name])
  }

  const onDeleteAlias = alias => {
    if(updating)return;
    setSearchValue('')
    timeoutRef.current = setTimeout(()=>{
      setLoading(true)
    },800)
    onUpdateAlias((tileData?tileData.disp_body.map(el=>el.name):[]).filter(t=>t!==alias.name))
  }
  
  return (  
    <div className={classes.root} data-test-id="alias-data-tile">
      <div className={classes.editableHeaderContainer} >
        <Typography className={classes.title}>ALIAS</Typography>
        <KTooltip title="Add acronyms, names or related terms that this Term is known as. Alias is used to improve search">
          <div style={{marginBottom:-3,marginLeft:6}}>{getIconComponent({label:'info',size:16,colour:theme.palette.primaryText.light})}</div>
        </KTooltip>
      </div>
      <ClickAwayListener 
        onClickAway={(event)=>{
          if(event.target.className &&  event.target.className.includes && event.target.className.includes("alias_suggestion")){
            return;
          }
          if(event.target.id && event.target.id==='search_selector_popper_list'){
            return;
          }
          setSearchValue('')
          setEditing(false)
        }}
      >
        <div className={classes.chipContainer + ' ' + (editing?classes.editingBox:classes.clickableBox)} onClick={()=>setEditing(true)}>
          {
            loading && <CircularProgress color='secondary' size={16}/>
          }
          {
            !loading && tileData && tileData.disp_body.length===0 && 
            <div data-test-classname="alias-data-tile-chip" className={classes.tag} style={{background:theme.palette.chip.main,color:theme.palette.primaryText.main,border:`1px solid ${theme.palette.border.main}`,cursor:'default'}}>
              <span className={classes.tagText} style={{color:theme.palette.primaryText.main}}>None</span>
            </div>
          }
          {
            !loading && tileData && tileData.disp_body.sort((a,b)=>a.name<b.name?-1:1).map(t=>(
              <div data-test-classname="alias-data-tile-chip" className={classes.tag} style={{width:editing||tileData.disp_body.length===1?undefined:measureText(t.name)+24}}>
                <span className={classes.tagText}>{t.name}</span>
                <IconButton  className={classes.iconButton+'  chip-delete-button'} style={{display:editing?'block':undefined}}  onClick={event=>{event.stopPropagation();onDeleteAlias(t)}}>
                  {getIconComponent({label:'clear',size:13.75,colour:theme.palette.primaryText.main})}
                </IconButton>
              </div>
            ))
          }
          {
            editing && 
            <div style={{marginTop:8,marginBottom:6,width:'100%'}}>
              <SearchSelector
                url={'/solr/search/select'}
                params={{
                  q:getPartialMatchSearchString(searchValue),
                  fq:`object_type_srt:COLLECTION_INSTANCE AND collection_type_srt:GLOSSARY AND -id:${object.id}`,
                  fl:'*',
                  qf:'name_srt',
                  sort:'name_srt asc',
                }}
                keepPopperOnClick
                testID="alias-manager-input"
                clearable
                autoFocus
                height={32}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder={`Select a Term or enter an alias`}
                autoSuggestion={true}
                suggestionListWidth={290}
                removeSuggestionHeader
                // hideBorder
                renderResults={list => {
                  let suggestion = [];
                  if(!list.find(l=>l.name_txt.toLowerCase()===searchValue.toLowerCase()) && searchValue.trim()!==''){
                    suggestion.push(
                      <div className={classes.suggestionItem+' alias_suggestion'} style={{borderBottom:list.length===0?'none':undefined}} onClick={()=>{onAddAlias(searchValue)}}>
                        Add a new Alias: {searchValue}
                      </div>
                    )
                  }
                  list.filter(el=>!tileData || !tileData.disp_body.find(d=>d.name.toLowerCase()===el.name_txt.toLowerCase())).forEach((l,index)=>{
                    suggestion.push(
                      <div  className={classes.suggestionItem+' alias_suggestion'} style={{borderBottom:list.length===index+1?'none':undefined}} onClick={()=>{onLinkTerm(l)}}>
                        {l.name_txt}
                        <div className={classes.collectionChip+' alias_suggestion'}>
                          {l.collection_txt}
                        </div>
                      </div>
                    )
                  })
                  if(suggestion.length===0){
                    return [<Typography className='alias_suggestion' onClick={event=>event.stopPropagation()} style={{fontSize:13.75,marginLeft:16}}>No suggestion found</Typography>]
                  }
                  return suggestion
                }}
              />
            </div>
          }
        </div>
      </ClickAwayListener>
    </div>
  )
}

AliasManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(AliasManager));