import React, { useEffect, useReducer } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/BulkUpdate/Body/Body';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import { useStore } from 'react-redux'
import ProfileHeader3 from '../../components/UI/ProfileHeader/ProfileHeader3';
import axiosCerebrum from '../../axios-cerebrum';
import { actionIds } from '../../components/BulkUpdate/utils';
import moment from 'moment';

const styles = theme => ({
  underlineOnHover: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
});

const initialState = {
  activeStep: 0,
  configs: {},
  changedFields: [], // array of objects { field, operation, values }
  properties: [],
  propertyValues: {},
  instanceValues: {},
  instanceExpiry: moment()
};

function reducer(state, action) {
  switch (action.type) {
    case 'reset_state':
      return {
        ...state,
        properties: [],
        propertyValues: {},
        instanceValues: {},
        instanceSuggestions: null,
        collectionTemplate: null,
      }
    case 'set_active_step':
      return {
        ...state,
        activeStep: action.activeStep,
      }
    case 'set_configs':
      if (typeof action.configs !== 'object') {
        return state;
      }
      return {
        ...state,
        configs: action.configs
      }
    case 'set_selected_action':
      return {
        ...state,
        selectedAction: action.selectedAction,
      }
    ///////// for bulk update properties
    case 'set_properties':
      return {
        ...state,
        properties: action.properties,
      }
    case 'set_property_values':
      return {
        ...state,
        propertyValues: action.propertyValues,
      }
    /////// for create list/warning properties
    case 'set_collection_template':
      return {
        ...state,
        collectionTemplate: action.collectionTemplate
      }
    case 'set_instance_values':
      return {
        ...state,
        instanceValues: action.instanceValues
      }
    case 'set_instance_expiry':
      return {
        ...state,
        instanceExpiry: action.instanceExpiry
      }
    case 'set_instance_suggestions':
      return {
        ...state,
        instanceSuggestions: action.instanceSuggestions
      }
    ///////////// for instance update 
    case 'number_of_items_to_update':
      return {
        ...state,
        numberOfItemsToUpdate: action.numberOfItemsToUpdate
      }
    case 'set_instance_detail':
      return {
        ...state,
        instanceDetail: action.instanceDetail
      }
    ////////////
    case 'set_batch_status':
      return {
        ...state,
        batchLoading: action.batchLoading,
        batchError: action.batchError,
        batchComplete: action.batchComplete
      }
    case 'set_loading_screen':
      return {
        ...state,
        loadingScreen: action.loadingScreen
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const BulkEdit = props => {

  const {
    history,
    sessionData
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const store = useStore();

  useEffect(() => {
    let presetConfigs = store.getState().bulkEdit;

    if (presetConfigs) {
      dispatch({
        type: 'set_configs',
        configs: {
          // url approach
          url: presetConfigs.url,
          params: presetConfigs.params,
          // filter id approach
          filterId: presetConfigs.filterId,
          // object ids approach
          objectIds: presetConfigs.objectIds,
          // collection id approach 
          instanceId: presetConfigs.instanceId,
          ///////
          presetDescription: presetConfigs.presetDescription,
          redirectUrl: presetConfigs.redirectUrl,
          editMode: presetConfigs.editMode,
          cascadeParentObj: presetConfigs.cascadeParentObj,
          fileName: presetConfigs.fileName,
        }
      })

      if (presetConfigs.presetDescription) {
        dispatch({
          type: 'set_selected_action',
          selectedAction: actionIds.bulkUpdateProperties
        })
        dispatch({
          type: 'set_properties',
          properties: [{ name_txt: 'Description', id: 'description' }]
        })
        dispatch({
          type: 'set_property_values',
          propertyValues: {
            description: {
              field: 'DESCRIPTION',
              operation: 'REPLACE',
              values: [presetConfigs.presetDescription]
            }
          }
        })
        dispatch({
          type: 'set_active_step',
          activeStep: 1
        })
      }

      if (presetConfigs.instanceId) {
        axiosCerebrum
          .get(`/api/collectioninstances/${presetConfigs.instanceId}/related`, {
            params: {
              relationship: 'MEMBERS',
              per_page: 0
            }
          })
          .then(res => {
            dispatch({
              type: 'number_of_items_to_update',
              numberOfItemsToUpdate: res.data.total
            })
          })
          .catch(err => {
            console.log(err)
          })

        axiosCerebrum
          .get(`/api/collectioninstances/${presetConfigs.instanceId}`)
          .then(res => {
            dispatch({
              type: 'set_instance_detail',
              instanceDetail: res.data
            })
          })
          .catch(err => {
            console.log(err)
          })
      }

      if (presetConfigs.editMode === 'cascade') {
        dispatch({
          type: 'set_selected_action',
          selectedAction: actionIds.bulkUpdateProperties
        })  
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let title = 'Bulk Action';

  let subTitle;
  let bannerdisplayText = "Changes from Bulk Update are not currently captured in the objects' change timeline"
  let bannerCause = "bulk_edit_info"

  if (state.operation === "link_to_collection") {
    bannerdisplayText = "Linked collections are immediately visible on the updated item's profile page. Updates to search results / filters will occur overnight."
    bannerCause = "bulk_edit_link_collection_info"
  }

  if (state.configs.editMode === 'cascade') {
    title = state.configs.fileName;
  }

  return (
    <div>
      <ProfileLayout
        header={
          <ProfileHeader3
            title={title}
            history={history}
            subtitle={subTitle}
            minHeight={108}
            bannerdisplayText={bannerdisplayText}
            bannerCause={bannerCause}
          />
        }
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
          />
        }
      />
    </div>
  )

}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(BulkEdit)));