import cronstrue from 'cronstrue';
import moment from 'moment';

export const dayOfWeekAsString = (dayIndex) => {
  return ["Sunday", "Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"][dayIndex] || '';
}

export const generateScheduleText = ({cron, frequency}) => {
  let cronText;
  let cronArr = [];
  if(!cron || Object.keys(cron).length===0) return 'N/A';
  if(frequency==='custom' || !['daily','weekly','monthly'].includes(frequency)){
    cronArr.push(cron.minute?cron.minute:'*');
    cronArr.push(cron.hour?cron.hour:'*');
    cronArr.push(cron.day_of_month?cron.day_of_month:'*');
    cronArr.push(cron.month_of_year?cron.month_of_year:'*');
    cronArr.push(cron.day_of_week?cron.day_of_week:'*');
    try{
      cronText = cronstrue.toString(cronArr.join(' '), { use24HourTimeFormat: true })+' (UTC)'
      let splitted = cronText.split('At ')
      if(splitted.length>1){
        cronText = splitted.slice(1).join('At ')
      }
    }catch(error){
      cronText = JSON.stringify(cron)
      // console.log(error)
    }
  }else{
    try{
      if(frequency==='daily')cronText = `Every day at ${cron.hour}:${cron.minute}`;
      if(frequency==='weekly')cronText = `Every ${dayOfWeekAsString(parseInt(cron.day_of_week))} at ${cron.hour}:${cron.minute}`;
      if(frequency==='monthly')cronText = `${cron.day_of_month==='1-7'?'First':'Last'} ${dayOfWeekAsString(parseInt(cron.day_of_week))} of the month at ${cron.hour}:${cron.minute}`;
      cronText+=' (UTC)'
    }catch(error){
      cronText = JSON.stringify(cron)
      // console.log(error)
    }
  }
  
  return cronText
}


export const generateCronByConfig = ({frequency, customCron, loadTime, loadDay, loadSchedule='first'}) => {
  let cron = {load_frequency:frequency};
  if(frequency!=='custom'){
    let timeTextUTC, dayIndexUTC;
    if(frequency!=='custom'){
      timeTextUTC = moment(loadTime).add(-moment().utcOffset(),'minute').format('HH:mm')
      dayIndexUTC = moment().day(loadDay).hour(loadTime.format('HH')).minute(loadTime.format('mm')).utc().weekday() + ''
    }
    cron.hour = timeTextUTC.split(':')[0];
    cron.minute = timeTextUTC.split(':')[1];
    if(frequency==='weekly'){
      cron.day_of_week = dayIndexUTC;
    }
    if(frequency==='monthly'){
      cron.day_of_week = dayIndexUTC;
      if(loadSchedule==='first'){
        cron.day_of_month = '1-7';
      }else{
        cron.day_of_month = 'L-7';
      }
    }
  }else{
    let length = customCron.split(' ').length;
    let formattedCron = customCron;
    if(length!==5){
      let diff = 5 - length;
      for(let i=0;i<diff;i++){
        formattedCron = formattedCron + ' *'
      }
    }
    let splitted = formattedCron.trim().split(' ');
    cron.minute = splitted[0];
    cron.hour = splitted[1];
    cron.day_of_month = splitted[2];
    cron.month_of_year = splitted[3];
    cron.day_of_week = splitted[4];
  }

  return cron;
}