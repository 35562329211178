import React, { useEffect, useRef } from 'react';
import { withStyles, Typography, CircularProgress} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr';
 // eslint-disable-next-line
import { isInViewport, collectionIds, toTitleCase } from '../../../utilities';
import ProductCard from './ProductCard';

const styles = theme => ({
  header:{
    color:theme.palette.primaryText.main,
    fontSize:20,
    marginBottom:24
  }
})

function DataProducts(props) {

  const {
    history,
    state,
    dispatch,
    classes,
    tabOptions
  } = props;

  const scrollRef = useRef()
  const mockCollectionId = localStorage.getItem('data_product_collection_id') || 'f3a4cf67-e38f-385c-8f2f-8c892ecb5b44'

  const loadProducts = ({page=1}) => {
    dispatch({
      type:'set_products',
      productsLoading:true,
      productsData:page===0?undefined:state.productsData,
    })
    axiosCerebrum
      .get(
        `/api/collectioninstances`,{params:{
          collection_id:mockCollectionId,
          // collection_id:'f3a4cf67-e38f-385c-8f2f-8c892ecb5b44',
          per_page:200,
          page,
          // 'search.name':searchFilter===''?undefined:searchFilter,
          // sort:sort
        }}
      )
      .then(async response=>{
        let productsData = response.data;
        if(productsData.total===0){
          dispatch({
            type:'set_products',
            productsData
          })
          return;
        }
        let statsData;
        await axiosCerebrum
          .get(
            `/api/stats`,
            {params:{
              object_ids:productsData.items.map(el=>el.id).join(','),
              per_page:productsData.items.length
            }}
          )
          .then(r=>{
            statsData = r.data.items;
            productsData.items = productsData.items.map(el=>{
              el.stats = statsData.find(s=>s.node_id===el.id);
              return el;
            })
          })
          .catch(error=>{
            console.log(error)
          })
        
        await axiosSolr
          .post(
            `/solr/search/select`,{
              params:{
                q:"*",
                fq:`id:(${productsData.items.map(el=>el.id).join(' OR ')})`,
                rows:productsData.items.length,
              }
            }
          )
          .then(r=>{
            productsData.items = productsData.items.map(el=>{
              el =  {...el, ...(r.data.response.docs.find(s=>s.id===el.id) || {} )};
              return el;
            })
          })
          .catch(error=>{
            console.log(error)
          })

          let collection = productsData.items[0].parent;
        
          await axiosSolr
            .post(
              `/solr/search/select`,{
                params:{
                  q:"*",
                  fq:`*`,
                  rows:0,
                  'json.facet':JSON.stringify({
                    'products':{
                      type:'terms',
                      field:`${collection.short_name.replace(/\s/g,'_')}_kc_msrt`,
                      limit:1000,
                      'facet':{
                        'object_count':{
                          type:"terms",
                          field:"object_type_srt",
                          limit:20,
                        },
                        'classification':{
                          type:"terms",
                          field:"classification_kc_msrt",
                          limit:100,
                        },
                        'domain':{
                          type:"terms",
                          field:"domain_kc_msrt",
                          limit:100,
                        },
                        'verified':{
                          type:"terms",
                          field:"verified_kc_msrt",
                          limit:100,
                        },
                      }
                    }
                  })
                }
              }
            )
            .then(solrFacet=>{
              productsData.items = productsData.items.map(el=>{
                let facet = solrFacet.data.facets.products.buckets.find(s=>s.val===el.name.toUpperCase()) || {};
                el.facets = facet;
                return el;
              })
            })
            .catch(error=>{
              console.log(error)
            })
        
        if(page>1){
          productsData.items = [...state.productsData.items,...productsData.items];
        }
        dispatch({
          type:'set_products',
          productsData
        })
      })
      .catch(error=>{
        dispatch({
          type:'set_products',
          productsError:error
        })
      })
  }

  useEffect(()=>{
    if(!state.productsData && !state.productsLoading)loadProducts({})
  // eslint-disable-next-line
  },[])
  
  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && state.productsData && !state.productsLoading && state.productsData?.page<state.productsData?.pages
  }

  window.onscroll = () => {
    if(shouldLoadMore()){
      loadProducts({page:state.productsData?.page+1})
    }
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadProducts({page:state.productsData?.page+1})
    }
  // eslint-disable-next-line
  },[state.productsData, state.productsLoading])

  // if(state.productsData?.total===0){
  //   return (
  //     <div style={{display:'flex',alignItems:'flex-start',overflow:"hidden"}}>
  //       <div>
  //         <Typography className={classes.header}>
  //           {state.productsData?.total} Data Product(s)
  //         </Typography>
  //         <Typography>
  //           No data products found
  //         </Typography>
  //       </div>
  //     </div>
  //   )
  // }

  let typePropertyId = 2;
  if(state.productsData?.total>0){
    typePropertyId = state.productsData?.items[0].parent.properties.find(el=>el.name==='Type')?.id
  }

  let selectedType = tabOptions[state.tabState]
  let list = state.productsData?.items?.filter(el=>el.properties[typePropertyId]===selectedType)

  return (
    <div>
      <Typography className={classes.header}>
        {list?.length} {selectedType} Data Product(s)
      </Typography>
      <div style={{display:'flex',flexWrap:'wrap',marginLeft:-16}}>
        {
          list?.map(el=>(
            <ProductCard
              key={el.id}
              history={history}
              state={state}
              dispatch={dispatch}
              product={el}
            />
          ))
        }
      </div>
      <div ref={scrollRef} style={{textAlign:'center',marginTop:16,marginBottom:16}}>
        {
          state.productsLoading && 
          <CircularProgress color='secondary'/>
        }
      </div>
      {
        state.productsError && 
        <Typography>
          Error occurred loading products
        </Typography>
      }
      {
        list?.length===0 && !state.productsLoading && !state.productsError &&
        <Typography>
          No {toTitleCase(selectedType)} data products found
        </Typography>
      }
    </div>
  )
}

export default withStyles(styles)(DataProducts);