import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
// import axiosCerebrum from '../../../../axios-cerebrum'
import axiosCerebrum from '../../../../axios-cerebrum';
import { getIconComponent, sendMessage} from '../../../../utilities';
import { tileStyles } from './utils/styles';
import KTooltip from '../../KTooltip/KTooltip';
import { getIconLabel } from '../../SearchResults/utils';
import { globalListenerRef } from '../../../../GlobalListenerRef';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  // title: {
  //   ...tileStyles.title
  // },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
  title: {
    fontSize: 12,
    color:theme.palette.primaryText.main,
    letterSpacing:1,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    width:132,
    marginRight:12,
    flexShrink:0
  },
  middleMessage:{
    fontSize: 13.75,
    marginLeft:16,
    // flexGrow:1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width:'max-content',
    maxWidth:200,
    color:theme.palette.primaryText.main
  },
  clickableText:{
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline',
    }
  }
})

function InstanceParentTile(props) {

  const {
    classes,
    theme,
    history,
    object,
    onAddTileData,
    tileData,
    isEditable,
    alwaysOpenNewTab
  } = props;

  const [loading, setLoading] = useState(false)
  const popperRef = useRef()
  
  const loadParent = (forceData) => {
    setLoading(true)
    let url;
    let data = forceData || object
    if(data.hierarchy_parent_id){
      url = `/api/collectioninstances/${data.hierarchy_parent_id}`
    }else{
      url = `/api/collections/${data.parent_id}`
    }
    axiosCerebrum
      .get(url)
      .then(response=>{
        setLoading(false)
        let parent = response.data
        if(parent){
          let label = getIconLabel({label:data.object.name,item:parent})
          onAddTileData({
            id:'instanceParent',
            disp_body:{
              'disp_value':parent.name + (parent.id===data.parent?.id?' (root)':''),
              obj:parent,
              label,
              onClick:()=>{
                alwaysOpenNewTab?
                window.open(`/profile/${parent.object.name.toLowerCase()}/${parent.id}`,'_blank'):
                history.push(`/profile/${parent.object.name.toLowerCase()}/${parent.id}`)
              }
            },
          })
        }
      })
      .catch(error=>{
        setLoading(false) 
        console.log(error)
      })
  }

  useEffect(()=>{
    if(!tileData){
      loadParent()
    };
    const onMsgReceived = (msg) => {
      if(msg.data.load_instance_parent_tile ){
        loadParent(msg.data.load_instance_parent_tile);
      }
    }
    window.removeEventListener('message',globalListenerRef.instanceParentTileListener);
    globalListenerRef.instanceParentTileListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.instanceParentTileListener);
    return (()=>{window.removeEventListener('message',globalListenerRef.instanceParentTileListener);})
  // eslint-disable-next-line
  },[])



  if(!tileData)return <></>

  return (
    <div data-test-id="instance-parent-data-tile" className={classes.root}>
      <div className={classes.editableHeaderContainer} >
        <Typography className={classes.title}>PARENT</Typography>
      </div>
      <div 
        data-test-id='instance-parent-tile' 
        ref={popperRef} 
        className={classes.chipContainer + ' ' + (isEditable?classes.clickableBox:'')} 
        style={{flexWrap:'nowrap',paddingBottom:10}} 
        onClick={()=>{isEditable && sendMessage({open_instance_parent_modal:true,parent:JSON.stringify(tileData.disp_body.obj)})}}
      >
        {
          loading && <CircularProgress color='secondary' size={16}/>
        }
        {
          !loading &&
          <>
            <div className={classes.left}>
              <div style={{display: 'flex',justifyContent:'center',alignItems:'center',width:24,height:24}}>{getIconComponent({label:tileData.disp_body.label,size:24,colour:theme.palette.primaryText.light})}</div>
            </div>
            <KTooltip title={tileData.disp_body.disp_value}>
              <Typography 
                onClick={event=>{event.stopPropagation();tileData.disp_body.onClick && tileData.disp_body.onClick()}}
                className={classes.middleMessage + (tileData.disp_body.onClick?' '+classes.clickableText:'')} 
                data-test-classname="icon-data-tile-main-text"
              >
                {tileData.disp_body.disp_value}
              </Typography>
            </KTooltip>
          </>
        }
      </div>
    </div>
  )
}

InstanceParentTile.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(InstanceParentTile));