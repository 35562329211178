import React, {useEffect, useState} from 'react';
import { withStyles, Typography, Modal, Paper, Button, InputBase,} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import ModalAlert from '../../UI/ModalAlert/ModalAlert'
import useAlert from '../../../hooks/useAlert';
import { getExtractName } from '../../QuestionBoard/Utils/Utils';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import { getIconComponent } from '../../../utilities';
import theme from '../../../theme';
import QuestionBoardChip from '../../QuestionBoard/QuestionBoardChip/QuestionBoardChip';
import { checkRequired, generatePayload } from '../../QuestionBoard/QuestionBoardChip/utils';

const styles = theme => ({
  modalRoot:{
    padding:24,
    // paddingBottom:8,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    maxHeight:'70vh',
    overflow:'auto'
  },
  title:{
    wordBreak:'break-word',
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:24
  },
  subtitle:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    marginBottom:24
  },
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:1.5,
    marginBottom:16
  },
  section:{
    marginBottom:24
  },
  inputBase:{
    ...theme.components.inputBase,
    height:48,
    width:'100%'
  },
  selector:{
    ...theme.components.selector,
    height:48,
    width:'100%',
    '& div div':{
      paddingLeft:16
    }
  },
  errorBorder:{
    border:`1px solid ${theme.palette.error.main}`
  },
  buttons:{
    marginTop:36,
    marginRight:-12,
    marginBottom:-16,
    display:'flex',
    justifyContent:'flex-end',
    position:'sticky',
    bottom:-25,
    background:theme.palette.background.main
  },
  detailText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    whiteSpace:'pre-wrap'
  }
})

const DetailEditModal = props => {

  const {
    classes,
    history,
    state,
    dispatch,
    setModalOpen,
    modalOpen
  } = props;

  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [params, setParams] = useState({})

  const [updating, setUpdating] = useState(false)

  const [alertName, setAlertName] = useState('')
  let alert = modalOpen?.alert;

  const {
    sendAlert
  } = useAlert({})

  useEffect(()=>{
    if(modalOpen){
      let alert = modalOpen?.alert;
      if(alert){
        setAlertName(alert.name)
      }
    }else{
      setAlertName('')
    }
  // eslint-disable-next-line
  },[modalOpen])

  const onSave = () => {
    setUpdating(true)
    let payload = {
      name:alertName
    };
    if(alert?.alert_source_args){
      payload.alert_source_args = {
        ...generatePayload(params),
        "type": "EXTRACTS"
      }
    }
    axiosCerebrum
      .put(
        `/api/alerts/${alert.id}`,payload
      )
      .then(response=>{
        dispatch({
          type:'set_alert_list',
          alertList:{
            ...state.alertList,
            items:state.alertList.items.map(item=>{
              if(item.id===alert.id){
                return response.data
              }else{
                return item
              }
            })
          }
        })
        setUpdating(false)
        setModalOpen(false)
        sendAlert({type:"info",message:"Alert updated successfully"})
      })
      .catch(error=>{
        setAlertMessage('Error occurred updating alert')
        setAlertOpen(true)
        setUpdating(false)
      })
  }

  const checkAllFilled = () => {
    if(alert?.filtered_list)return alertName?.trim()!==''
    if(alert?.alert_source_args){
      return checkRequired(params) && alertName?.trim()!==''
    }
    return alertName?.trim()!==''
  }

  const getTypeText = el => {
    if(!el)return ''
    if(el.filtered_list)return 'Filter';
    return 'Ask K: ' + (getExtractName(alert.alert_source_args?.lookup_code, alert.alert_source_args?.filters) || alert.extract_template?.name)
  }

  return (
    <Modal 
      open={modalOpen} 
      disableBackdropClick
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
      <div style={{width:'max-content',margin:'auto',outline:'none'}}>
        <Paper className={classes.modalRoot} style={{width:450}}>
          <Typography className={classes.title}>Edit Alert details</Typography>
          <div className={classes.section}>
            <Typography className={classes.sectionHeader}>ALERT NAME</Typography>
            <InputBase
              className={classes.inputBase + (alertName?.trim()===''?` ${classes.errorBorder}`:'')}
              value={alertName}
              onChange={e=>setAlertName(e.target.value)}
              placeholder='Name'
            />
          </div>
          <div className={classes.section}>
            <Typography className={classes.sectionHeader}>TYPE</Typography>
            <InputBase
              className={classes.inputBase}
              disabled
              value={getTypeText(alert)}
              placeholder=''
            />
          </div>
          {
            alert?.filtered_list && 
            <div className={classes.section}>
              <Typography className={classes.sectionHeader}>FILTER DETAILS</Typography>
              <SimpleResultItem
                title={alert.filtered_list.name}
                showUnderline
                iconColour={theme.palette.primaryText.light}
                item={alert}
                titleColour={theme.palette.primaryText.main}
                tailObject={getIconComponent({label:'open',size:24,colour:theme.palette.primaryText.light})}
                iconLabel={'filter'}
                onClick={()=>{
                  history.push(`/basic_search?filterListId=${alert.filtered_list.id}`)
                }}
              />
            </div>
          }
          {
            alert?.alert_source_args && 
            <div className={classes.section}>
              {/* <Typography className={classes.sectionHeader}>ASK K DETAILS</Typography> */}
              <QuestionBoardChip
                state={state}
                dispatch={dispatch}
                code={alert.alert_source_args?.lookup_code}
                inputFieldOnly
                defaultFilters={alert.alert_source_args?.filters}
                onParamChange={param=>{
                  setParams(param)
                }}
              />
            </div>
          }
          <div className={classes.buttons}>
            <Button color='primary' disabled={!checkAllFilled()||updating} onClick={()=>onSave()} style={{marginRight:16}}>SAVE</Button>
            <Button color='secondary' onClick={()=>setModalOpen(false)}>CANCEL</Button>
          </div>
        </Paper>
        <div style={{margin:'0 auto',width:450}}>
          <ModalAlert
            open={alertOpen}
            setOpen={setAlertOpen}
            message={alertMessage}
            type='error'
          />
        </div>
      </div>
    </Modal>
  )
}


export default withStyles(styles)(DetailEditModal);