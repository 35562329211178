import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import QuestionBoardChip from '../QuestionBoardChip/QuestionBoardChip'
import { getExtractName } from '../Utils/Utils'

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.header.main,
  },
})

const Surveys = props => {

  const {
    classes,
		state,
		dispatch,
    history
  } = props;

  const surveyMetrics = [
    {
      title:getExtractName('0220'),
      subtitle:'See all details for surveys answered',
      code:'0220'
    },
    {
      title:getExtractName('0230'),
      subtitle:'See how each survey type has been asked and answered',
      code:'0230'
    },
  ]

  const userMetrics = [
    {
      title:getExtractName('0240'),
      subtitle:'See how each user has answered surveys and contributed to the knowledge base',
      code:'0240'
    }
  ]

  const onExtractSubmitted = () => history.push(`/my_tasks?tabName=EXTRACTS&jobName=ASK K&isSubmitted=true`)

  return (
    <div >
      <Typography  className={classes.title}>SURVEY DETAILS</Typography>
      <div style={{marginLeft:16,marginTop:16}}>
        {
          surveyMetrics.map(el=>(
            <QuestionBoardChip
              state={state}
              dispatch={dispatch}
              code={el.code}
              title={el.title}
              subtitle={el.subtitle}
              disableAutoDownload={true}
              onExtractSubmitted={onExtractSubmitted}
            />
          ))
        }
      </div> 
			<Typography className={classes.title} style={{marginTop:40}}>USER METRICS</Typography>
      <div style={{marginLeft:16,marginTop:16}}>
        {
          userMetrics.map(el=>(
            <QuestionBoardChip
              state={state}
              dispatch={dispatch}
              code={el.code}
              title={el.title}
              subtitle={el.subtitle}
              disableAutoDownload={true}
              onExtractSubmitted={onExtractSubmitted}
            />
          ))
        }
      </div> 
    </div>
    
  )
}

export default withStyles(styles)(Surveys);