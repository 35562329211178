import React, { useEffect } from 'react';
import NavBar from '../../containers/NavBar/NavBar';
import Aux from '../../hoc/Aux/Aux';
import { withStyles } from '@material-ui/core';
import { getContentContainerStyle } from '../UI/ProfileLayoutNew/layoutUtils';
import { globalListenerRef } from '../../GlobalListenerRef';



const styles = theme => ({
    root: {
      marginTop: '1rem',
      background:theme.palette.background.main
    },
    menuContainer:{
        flex:'0 0 40px',
        height:'100vh'
    },
    content:{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        // overflow:'hidden',
        flexGrow:1,
        background:theme.palette.background.main
    },
    // '@media (max-width: 1240px)': {
    //     content:{
    //         marginLeft:40
    //     }
    // },
    container:{
        display:'flex',
        background:theme.palette.background.main
    }
  });
  

const Layout = ( props ) => {
    const {
        classes,
    } = props;

    const [navBarWidth, setNavBarWidth] = React.useState(40);

    useEffect(()=>{
        setNavBarWidth(getContentContainerStyle().navBarWidth); 
        window.removeEventListener('message',globalListenerRef.layoutMsgListener)
        window.removeEventListener('resize',globalListenerRef.layoutResizeListener)
        globalListenerRef.layoutResizeListener = () => {
            setNavBarWidth(getContentContainerStyle().navBarWidth); 
        }
        globalListenerRef.layoutMsgListener = (event) => {
            if(event.data.newNavbarWidth){
                setNavBarWidth(event.data.newNavbarWidth);
            }
        }
        window.addEventListener('message',globalListenerRef.layoutMsgListener)
        window.addEventListener('resize',globalListenerRef.layoutResizeListener)
        return ()=>{
            window.removeEventListener('message',globalListenerRef.layoutMsgListener)
            window.removeEventListener('resize',globalListenerRef.layoutResizeListener)
        }
    },[])

    return (
        <Aux>
            <div className={classes.container}>
                <div className={classes.menuContainer} style={{flex:`0 0 ${navBarWidth}px`,transition:'width 0.2s ease-in-out',}} id='K-nav-bar'><NavBar session_user={props.session_user} /></div>
                <main className={classes.content}>
                    {props.children}
                </main>
            </div>
        </Aux>
    )

};

export default withStyles(styles)(Layout);