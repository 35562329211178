import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, CircularProgress, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../../axios-cerebrum';
import { getStatusColour, issueStatus,} from '../../../../utilities';
import { tileStyles } from './utils/styles';
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  
  selector:{
    ...theme.components.selector,
    '& div div':{
      paddingLeft:16,
      paddingRight:32,
      paddingTop:8,
      paddingBottom:8,
    },
    color:`${theme.palette.background.main} !important`,
    '& svg':{
      color:`${theme.palette.background.main} !important`,
    }
  },
  selectorSelect:{
    paddingRight:8
  }
})

function IssueStatusManager(props) {

  const {
    classes,
    object,
    onAddTileData,
    tileData,
    isEditable,
  } = props;

  const [loading, setLoading] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  
  const loadStatus = () => {
    onAddTileData(
      {
        "disp_body":[{
          name:object.status,
          labels:'issue_status',
          id:object.status
        }],
        "disp_title":"STATUS",
        "id":"issueStatus",
      }
    )
  }

  useEffect(()=>{
    if(!tileData){
      loadStatus()
    }
  // eslint-disable-next-line
  },[])

  const onAdd = async status => {
    if(status===tileData.disp_body[0]?.id)return;
    setLoading(true)

    let requestBody = {
      "name": object.name,
      "description": object.description,
      "type": object.type,
      "severity": object.severity,
      "status": status,
      "source_id":object.source_id
    }

    await axiosCerebrum
      .put(`/api/issues/${object.id}`,requestBody)
      .then(response=>{
        onAddTileData(
          {
            "disp_body":[{
              name:response.data.status,
              labels:'issue_status',
              id:response.data.status,
            }],
            "disp_title":"STATUS",
            "id":"issueStatus",
          }
        )
      })
      .catch(error=>{
        console.log(error)
        sendAlert({message:`Error occurred updating issue status, please try again`,type:'error'})
      })

    setLoading(false)
  }

  if(!tileData)return <></>
  
  return (
    <div data-test-id="issue-status-data-tile" className={classes.root}>
      {
        loading ?
        <div style={{height:40,display:"flex",alignItems:'center'}}>
          <CircularProgress style={{width:24,height:24}} color='secondary'/>
        </div>
        :
        <div style={{pointerEvents:isEditable?undefined:'none'}}>
          <Select
            value={tileData.disp_body[0]?.id}
            onChange={event=>{
              onAdd(event.target.value)
            }}
            onClick={event=>{
              event.stopPropagation()
              event.preventDefault()
            }}
            MenuProps={{
              id:'search_selector_popper_list',
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl:null,
            }}
            autoFocus
            disableUnderline
            className={classes.selector}
            style={{
              background:getStatusColour(tileData.disp_body[0]?.id),
              border:`1px solid ${getStatusColour(tileData.disp_body[0]?.id)}`,
            }}
            classes={{select:isEditable?undefined:classes.selectorSelect}}
            IconComponent={isEditable?undefined:()=>null}
          >
            {
              issueStatus.map(s=>{
                return <MenuItem key={s} value={s}>{s.replace(/_/g,' ')}</MenuItem>
              })
            }
          </Select>
        </div>
      }
    </div>
  )
}

IssueStatusManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(IssueStatusManager));