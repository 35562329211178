import React, { useEffect } from 'react';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import ByObjectChart from './ByObjectChart';

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.primaryText.main
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  }
})

const AllSummaryBody = props => {

  const {
    // theme,
    state,
    classes,
    dispatch,
    variant,
  } = props;
  
  const data = state.summaryChartData[variant]
  const setData = d => dispatch({type:'set_summary_chart_data',summaryChartData:{...state.summaryChartData,[variant]:d}})

  useEffect(()=>{
    if(!data){
      setData({})
    }
  // eslint-disable-next-line
  },[])

  if(!data)return <></>;

  return (
    <div>
      <Typography className={classes.title}>TOP TARGETS BY IMPACT</Typography>
      <Typography className={classes.subTitle}>Count of impacted data, content and user impacts by targets assessed</Typography>
      <ByObjectChart
        state={state}
        data={data}
        setData={setData}
        variant={variant}
      />
    </div>
    
  )
}

export default withTheme()(withStyles(styles)(AllSummaryBody));