import React, {useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosSolr from '../../../../axios-solr';
import timezoneDetect from 'jstimezonedetect';
import axiosCerebrum from '../../../../axios-cerebrum';
import { getJobResult } from '../../../../utilities';
import Chart from './Chart';
import ChartLoadingIcon from '../Runs/ChartLoadingIcon';
import SummaryTile from '../UI/SummaryTile';

const styles = theme => ({
  header:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  tile:{
    maxWidth:150,
    flexGrow:1,
    flexShrink:1,
    textAlign:'center',
    height:123,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:4,
    '&:hover':{
      cursor:'pointer',
      background:theme.palette.hovered.main
    }
  },
  titleSelector:{
    ...theme.components.titleSelector,
    marginRight:8,
    maxWidth:'80vw',
  },
  unclickable:{
    pointerEvent:'none',
    '&:hover':{
      cursor:'default',
      background:theme.palette.background.main
    }
  },
  selected:{
    background:theme.palette.primary.dark,
    '&:hover':{
      background:theme.palette.primary.dark
    },
    '& p':{
      color:theme.palette.background.main
    }
  },
  disabled:{
    cursor:'default',
    '&:hover':{
      cursor:'default',
    },
    pointerEvent:'none',
    opacity:0.5
  },
  tileName:{
    fontSize:12,
    letterSpacing:1.5,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginTop:8
  },
  tileValue:{
    fontSize:20,
    color:theme.palette.primaryText.main,
    marginTop:16,
  },
})


function Summary(props) {

  const {
    classes,
    dqInstances,
    object,
    overviewByDimension,
    setOverviewByDimension,
    selectedTile,
    setSelectedTile,
    overviewMetrics,
    setOverviewMetrics,
    overviewMetricsCache, 
    setOverviewMetricsCache,
    overviewPeriod,
    setOverviewPeriod,
  } = props;

  const [loading, setLoading] = useState(false);
  let label = object.object_type_srt || object.object?.name 
  let apiRef = useRef(0)

  const loadoverviewByDimension = () => {
    let fq = `object_id_srt:${object.id}`
    if(label==='TABLE')fq += ` OR table_id_srt:${object.id}`
    if(label==='DATASET_TABLE')fq += ` OR dataset_table_id_srt:${object.id}`
    if(label==='DATASET')fq = `${fq} OR dataset_id_srt:${object.id}`

    setOverviewByDimension({loading:true})
    axiosSolr
      .get(
        `/solr/data_quality_test_result/select`,{params:{
          q:'*',
          fq:fq,
          rows:0,
          'json.facet':{
            'overall_avg':"avg(last_data_quality_score_srt)",
            'dimensions':{
              type:'terms',
              field:'data_quality_dimension_srt',
              limit:100,
              "facet":{
                "avg":"avg(last_data_quality_score_srt)",
                "dimension_id":{
                  type:'terms',
                  field:'data_quality_dimension_id_srt',
                  limit:1,
                }
              }
            }
          }
        }}
      )
      .then(response=>{
        if(response.data.response.numFound===0){
          setOverviewByDimension({data:[]})
          return;
        }
        let data = [];
        data.push({name:'OVERALL',value:response.data.facets.overall_avg,id:'overall',count:response.data.response.numFound})
        setSelectedTile('overall')
        apiRef.current += 1;
        loadMetrics({dimension:'overall', apiSeq:apiRef.current})
        let emptyDqs = dqInstances.filter(d=>!response.data.facets.dimensions?.buckets?.find(el=>el.val.toLowerCase()===d.name_txt.toLowerCase()));
        [...(response.data.facets.dimensions?.buckets||[]),...emptyDqs.map(el=>({val:el.name_srt || el.name_txt}))].sort((a,b)=>a.val.localeCompare(b.val)).forEach(bucket=>{
          data.push({name:bucket.val.toUpperCase(),value:bucket.avg,id:bucket.val,dimension_id:bucket.dimension_id?.buckets[0]?.val,count:bucket.count})
        })
        setOverviewByDimension({data})
      })
      .catch(error=>{
        console.log(error)
        setOverviewByDimension({error:true})
      })
  }

  const loadMetrics = ({dimension=selectedTile, period=overviewPeriod, apiSeq}) => {
    let cacheID = `${object.id}_${dimension}_${period}`
    if(overviewMetricsCache?.[cacheID]){
      setOverviewMetrics({result:overviewMetricsCache[cacheID].result})
      return;
    }
    let dimensionObj = overviewByDimension?.data?.find(el=>el.id===dimension)
    setOverviewMetrics({loading:true})
    setLoading(true)
    let metric_type = `DATA_QUALITY_${dimensionObj?.name?.toUpperCase()}_SCORE`
    if(!dimensionObj)metric_type = `DATA_QUALITY_OVERALL_SCORE`
    axiosCerebrum
      .post(
        '/api/platformbatches/0',
        {
          "adhoc_args":{
            "job": "_0060_metrics",
            "object_id": object.id,
            'agg_method':['AVG'],
            "tz": timezoneDetect.determine().name(),
            'days':period,
            "metric_type":metric_type
          }
        }
      )
      .then(response=>{
        let id = response.data.id;
        if(apiSeq!==apiRef.current)return;
        getJobResult(
          id,
          data=>{
            if(apiSeq!==apiRef.current)return;
            setLoading(false)
            setOverviewMetrics({result:data.result.data})
            setOverviewMetricsCache({...overviewMetricsCache,[cacheID]:{result:data.result.data}})
          },
          ()=>{
            setLoading(false)
            setOverviewMetrics({error:true})
          }
        )
      })
      .catch(error=>{
        console.log(error);
        setLoading(false)
        setOverviewMetrics({error:true})
      })
  }

  useEffect(()=>{
    if(!overviewByDimension)loadoverviewByDimension()
  // eslint-disable-next-line
  },[])

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        DQ SUMMARY
      </Typography>
      <Typography className={classes.subTitle}>
        Select a dimension to see more details
      </Typography>
      {
        overviewByDimension?.loading &&
        <CircularProgress color='secondary'/>
      }
      {
        overviewByDimension?.error &&
        <Typography>
          Error loading summary data
        </Typography>
      }
      {
        overviewByDimension?.data?.length===0 &&
        <Typography>
          No DQ summary found for this asset
        </Typography>
      }
      {
        overviewByDimension?.data &&
        <div style={{display:'flex',marginBottom:40,flexWrap:'wrap'}}>
          {
            overviewByDimension.data.map((item,index)=>{
              return (
                <SummaryTile
                  key={index}
                  isSelected={item.id===selectedTile}
                  id={item.id}
                  name={item.name}
                  value={item.value}
                  testCount={item.count}
                  onClick={
                    item.value?
                    ()=>{
                      if(loading)return;
                      if(!item.value)return;
                      setSelectedTile(item.id);
                      if(overviewMetrics?.[item.id]?.result)return;
                      apiRef.current+=1;
                      loadMetrics({dimension:item.id, apiSeq:apiRef.current})
                    }
                    :
                    null
                  }
                />
              )
            })
          }
        </div>
      }
      {
        selectedTile && overviewByDimension?.data?.length>0 && 
        <>
          <Typography className={classes.header} style={{marginBottom:6}}>
            {selectedTile.toUpperCase()} DQ TREND
            <Select
              className={classes.titleSelector}
              style={{marginLeft:12}}
              value={overviewPeriod}
              disabled={overviewMetrics?.loading}
              disableUnderline
              onChange={event=>{
                setOverviewPeriod(event.target.value)
                apiRef.current += 1;
                loadMetrics({dimension:selectedTile, period:event.target.value, apiSeq:apiRef.current})
              }}
            >
              {
                [30,90,365].map(el=>(
                  <MenuItem disabled={overviewMetrics?.loading} className={classes.menuItem} value={el}>
                    {el} DAYS
                  </MenuItem>
                ))
              }
            </Select>
          </Typography>
          <Typography className={classes.subTitle}>
            DQ Score over time. Score is updated for every run.
          </Typography>
          {
            overviewMetrics?.loading &&
            <ChartLoadingIcon/>
          }
          {
            overviewMetrics?.error &&
            <Typography>
              Error occurred loading details
            </Typography>
          }
          {
            overviewMetrics?.result &&
            <Chart
              key={selectedTile+overviewPeriod}
              data={overviewMetrics.result}
              object={object}
            />
          }
        </>
      }
    </div>
  )
}

Summary.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  dqInstances: PropTypes.array.isRequired,
  object: PropTypes.object.isRequired,
  overviewByDimension: PropTypes.object.isRequired,
  setOverviewByDimension: PropTypes.func.isRequired,
  selectedTile: PropTypes.string.isRequired,
  setSelectedTile: PropTypes.func.isRequired,
  overviewMetrics: PropTypes.object.isRequired,
  setOverviewMetrics: PropTypes.func.isRequired,
}

export default withTheme()(withStyles(styles)(Summary));