import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, Typography, withStyles, CircularProgress, Checkbox, Button } from '@material-ui/core';
import { formatNumber, getDispFields, mapObjectName, tabGroup } from '../../../utilities';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import axiosSolr from '../../../axios-solr';
import PageController from '../../BasicSearch/MainSearch/PageController/PageController';
import KTooltip from '../../UI/KTooltip/KTooltip';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root:{
    display:'flex',
  },
  header:{
    fontSize:16,
    color:theme.palette.header.main
  },
  listItem:{
    display:'flex',
    alignItems:'center',
    overflow:"hidden",
    height:64,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  listTitle:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    overflow:"hidden",
    textOverflow:"ellipsis",
    whiteSpace:"nowrap",
  },
  listSubtitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    overflow:"hidden",
    textOverflow:"ellipsis",
    whiteSpace:"nowrap",
  },
})

function ObjectSelector(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    // history,
    presetObjectIDs,
  } = props;
  
  const [tabState, setTabState] = useState(0)
  const [page, setPage] = useState({})

  const {
    sendAlert
  } = useAlert({
    id:`multi-ia-details`,
  })


  const getTabs = list => {
    let order = ['TABLE','COLUMN','REPORT','CONTENT_APP','DATASET']
    let tabs = []
    list.forEach(el=>{
      if(!tabs.includes(el.object_type_txt)){
        tabs.push(el.object_type_txt)
      }
    })
    tabs.sort((a,b)=>order.indexOf(a)-order.indexOf(b))
    return tabs
  }

  const loadObjects = () => {
    dispatch({
      type:'set_multi_ia_objects',
      multiIAObjectsLoading:true
    })
    axiosSolr
      .post(
        `/solr/search/select`,{params:{
          q:"*",
          fq:`id:(${presetObjectIDs.join(' OR ')})`,
          sort:"name_srt asc",
          rows:presetObjectIDs.length
        }}
      )
      .then(response=>{
        let selectedIDs = []
        let docs = response.data.response.docs;
        if(docs.length<=25){
          docs.forEach(el=>{
            selectedIDs.push(el.id)
          })
        }else{
          let types = getTabs(docs)
          let maxSelected = 25;
          types.forEach(t=>{
            if(selectedIDs.length>=maxSelected)return;
            for(let i=0;i<docs.length;i++){
              if(selectedIDs.length>=maxSelected)break;
              if(docs[i].object_type_txt===t){
                selectedIDs.push(docs[i].id)
              }
            }
          })
        }
        dispatch({
          type:'set_multi_ia_selected_ids',
          multiIASelectedIds:selectedIDs
        })
        dispatch({
          type:'set_multi_ia_objects',
          multiIAObjects:docs
        })
      })
      .catch(error=>{
        console.log(error);
        dispatch({
          type:'set_multi_ia_objects',
          multiIAObjectsError:true
        })
      })
  }

  useEffect(()=>{
    if(!state.multiIAObjects && presetObjectIDs){
      loadObjects()
    }
  // eslint-disable-next-line
  },[])

  if(state.multiIAObjectsLoading){
    return (
      <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <CircularProgress color='secondary'/>
      </div>
    )
  }

  if(state.multiIAObjectsError){
    return (
      <Typography>Error occurred loading objects</Typography>
    )
  }

  if(!state.multiIAObjects)return <></>

  if(state.multiIAObjects.length===0)return <Typography>No items to assess</Typography>
  
  const tabs = getTabs(state.multiIAObjects)

  let list = state.multiIAObjects?.filter(el=>el.object_type_txt===tabs[tabState])
  const count = list?.length || 0
  const selectedCount = list?.filter(l=>state.multiIASelectedIds.includes(l.id)).length || 0
  const currentPage = page[tabState] || 1
  
  return (
    <div>
      <div style={{marginBottom:24}}>
        <Typography style={{fontSize:20,color:theme.palette.primaryText.main}}>
          SELECT ITEM(S) TO ASSESS
        </Typography>
        <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>
          Confirm items to be included in a multi item impact assessment
        </Typography>
      </div>
      <div style={{width:220,float:'left',zIndex:1}}>
        <VerticalTabBar
          tabOptions={tabs}
          renderOptions={value=>`${value} (${formatNumber(state.multiIAObjects.filter(el=>el.object_type_txt===value).length)})`}
          tabState={tabState}
          tabIcons={tabs}
          setTabState={setTabState}
          grouping={tabGroup}
          alwaysShowFullTab
          highlightOnSelected
          width={220}
        />
      </div>

      <div style={{display:'flex',alignItems:'center',marginLeft:260,paddingBottom:16,background:theme.palette.background.main,zIndex:1}}>
        <Typography className={classes.header}>{selectedCount} of {count} Selected</Typography>
        <Button
          variant='outlined'
          color='primary'
          style={{marginLeft:16,padding:'2px 12px'}}
          onClick={()=>{
            if(selectedCount>0){
              dispatch({
                type:'set_multi_ia_selected_ids',
                multiIASelectedIds:state.multiIASelectedIds.filter(id=>state.multiIAObjects.find(o=>o.id===id).object_type_txt!==tabs[tabState])
              })
            }else{
              if(state.multiIASelectedIds.length>=25){
                sendAlert({
                  type:'info',
                  message:'You can only select max 25 items to assess'
                })
                return;
              }
              let newSelectedIds = [...state.multiIASelectedIds]
              state.multiIAObjects.forEach(el=>{
                if(newSelectedIds.length>=25)return;
                if(el.object_type_txt===tabs[tabState]){
                  newSelectedIds.push(el.id)
                }
                if(newSelectedIds.length>=25){
                  sendAlert({
                    type:'info',
                    message:'You can only select max 25 items to assess'
                  })
                }
              })
              dispatch({
                type:'set_multi_ia_selected_ids',
                multiIASelectedIds:newSelectedIds
              })
            }
          }}
        >
          {selectedCount>0?`CLEAR SELECTED ${mapObjectName(tabs[tabState])}(S)`:`SELECT ALL ${tabs[tabState]}(S)`}
        </Button>
      </div>

      <div style={{marginLeft:260,overflow:'hidden'}}>
        {
          list.slice((currentPage-1)*10,currentPage*10).map(el=>(
            <div className={classes.listItem}>
              <div style={{width:24,height:24,marginLeft:16,marginRight:16,flexGrow:0,flexShrink:0,marginTop:-12}}>
                <Checkbox
                  checked={state.multiIASelectedIds.includes(el.id)}
                  color='primary'
                  style={{padding:0}}
                  onClick={()=>{
                    let newSelectedIds = [...state.multiIASelectedIds]
                    if(newSelectedIds.includes(el.id)){
                      newSelectedIds.splice(newSelectedIds.indexOf(el.id),1)
                    }else{
                      newSelectedIds.push(el.id)
                    }
                    if(newSelectedIds.length>25){
                      sendAlert({
                        type:'info',
                        message:'You can only select max 25 items to assess'
                      })
                      return;
                    }
                    dispatch({
                      type:'set_multi_ia_selected_ids',
                      multiIASelectedIds:newSelectedIds
                    })
                  }}
                />
              </div>
              <div style={{flexGrow:1,overflow:'hidden'}}>
                <KTooltip title={getDispFields(el,'dispTitle')}>
                  <Typography className={classes.listTitle}>{getDispFields(el,'dispTitle')}</Typography>
                </KTooltip>
                <KTooltip title={getDispFields(el,'dispSubtitle')}>
                  <Typography className={classes.listSubtitle}>{getDispFields(el,'dispSubtitle')}</Typography>
                </KTooltip>
              </div>
            </div>
          ))
        }
        <PageController
          state={{
            pageNum:currentPage,
            resultsTotal:state.multiIAObjects.filter(o=>o.object_type_txt===tabs[tabState]).length,
          }}
          onChangePage={page=>{
            setPage({...page,[tabState]:page})
            // window.scrollTo({top:0,behavior:'smooth'})
          }}
        />
      </div> 
    </div>
  )
}

ObjectSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(ObjectSelector));