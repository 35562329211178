import React, {} from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { formatNumber } from '../../../utilities'
import PropTypes from 'prop-types'

const styles = theme => ({
  chart:{
    '& text':{
      whiteSpace:'pre',
    },
    '& .apexcharts-series path':{
      cursor:'pointer'
    },
    '& .apexcharts-datalabel':{
      letterSpacing:1.5
    }
  },
  clickabledChart:{
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline'
    }
  }
})

const StackBar = props => {

  const {
    theme,
    classes,
    id,
    height,
    width,
    series, // takes array of objects {name:..., data:[...]}
    labels,
    // colours
  } = props;
  
  const options = {
    chart: {
      type: 'bar',
      id:id,
      stacked:true,
      toolbar:{show:false},
      parentHeightOffset:0,
      events:{
        click: function(event, chartContext, config) {
          if(config.seriesIndex<0 || config.dataPointIndex<0)return;
          series[config.seriesIndex]?.clickFns?.[config.dataPointIndex]?.()
        }
      }
    },
    colors:series.map(el=>el.color),
    plotOptions: {
      bar: {
        // borderRadius:4,
        horizontal: true,
        // barHeight:shownLabels.length<4?'30%' : '60%',
        dataLabels: {
          // position: 'top',
        },
      }
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        }
      },
      hover: {
        filter: {
          type: 'darken',
          value: 0.75,
        }
      },
      active: {
        filter: {
          type: 'none',
          value: 0,
        }
      },
    },
    dataLabels:{
      textAnchor:'end',
      offsetX: 16,
      style:{
        colors:series.map(el=>el.fontColor||theme.palette.primaryText.main),
        fontSize:'15px'
      },
      formatter: function(val) {
        let formatted = val;
        try{
          formatted = (formatNumber(val,undefined,true) + '')
          if((val+'').length>3){
            formatted = formatted.padStart((val+'').length*1.5,' ');
          }
        }catch(error){
          console.log(error)
        }
        return formatted
      },
    },
    grid:{
      show:false
    },
    xaxis:{
      show:false,
      categories:labels,
      axisBorder: {
        show: false,
      },
      labels:{
        show:false
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis:{
      show:false,
      showAlways:false,
      axisBorder: {
        show: false,
      },
      labels:{
        show:false
      },
      axisTicks: {
        show: false,
      },
    },
    legend:{
      position:'top',
      showForSingleSeries:true,
      labels:{
        colors:theme.palette.primaryText.main
      },
      itemMargin:{
        horizontal:8
      },
      markers:{
        width:10,
        height:10
      },
      horizontalAlign:'left',
      fontSize:'12px',
    },
    tooltip: {
      shared: false,
      intersect: true,
      y:{
        formatter: function(val) {
          if(val!==Math.floor(val))return ''
          return formatNumber(val.toFixed(0))
        },
      }
    },
  };

  return (
    <div>
      <ReactApexChart 
        className={classes.chart} 
        key={id} 
        options={options} 
        series={series} 
        height={height} 
        width={width} 
        style={{justifyContent:'center', display:'flex'}} 
        type="bar" 
      />
    </div>
  )
}

StackBar.prototype = {
  id:PropTypes.string.isRequired,
  series:PropTypes.arrayOf(PropTypes.shape({
    name:PropTypes.string.isRequired,
    data:PropTypes.arrayOf(PropTypes.number).isRequired,
    clickFns:PropTypes.arrayOf(PropTypes.func)
  })).isRequired,
  labels:PropTypes.arrayOf(PropTypes.string).isRequired,
  height:PropTypes.number,
  width:PropTypes.number,
}



export default withTheme()(withStyles(styles)(StackBar));