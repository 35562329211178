import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Typography, Popper, ClickAwayListener, MenuItem, Paper } from '@material-ui/core';
import { getIconComponent } from '../../../utilities';

const styles = theme => ({
  listActionSectionTitle: {
    color: theme.palette.primary.main,
    fontSize: 12,
    letterSpacing: 2,
    marginLeft: 16,
    marginBottom: 8,
    marginTop: 12
  },
  menuItem: {
    padding: '10px 10px 10px 16px',
    minWidth: 'max-content',
    width: '100%',
    boxSizing: 'border-box',
    height: 42,
    color: theme.palette.primaryText.main,
    '&:hover': {
      background: theme.palette.hovered.main
    }
  },
});



const SmallBurgerButton = props => {

  const {
    theme,
    testId,
    classes,
    iconColour,
    options
  } = props;

  const [listActionOpen, setListActionOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const onButtonClick = event => {
    setAnchor(event.currentTarget);
    setListActionOpen(!listActionOpen);
  }


  return (
    <>
      <div data-test-id={testId} style={{ width: 24, height: 24, cursor: 'pointer' }} onClick={onButtonClick}>
        {getIconComponent({ label: 'menu', size: 24, colour: iconColour || theme.palette.primaryText.main })}
      </div>
      <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
        <ClickAwayListener onClickAway={() => setTimeout(() => setListActionOpen(false))}>
          <Paper style={{ marginTop: 20, marginRight: -2, minWidth: 200, border: `1px solid ${theme.palette.border.main}`, background: theme.palette.background.main }}>
            {
              options.map(o => {
                return (
                  <>
                    {options.length > 1 && <Typography className={classes.listActionSectionTitle}>{o.name}</Typography>}
                    {
                      o.actions.map(a => {
                        return (
                          <MenuItem onClick={a.onClick} className={classes.menuItem} >
                            <Typography style={{ fontSize: 13.75, color: theme.palette.primaryText.main }}>{a.text}</Typography>
                          </MenuItem>
                        )
                      })
                    }
                  </>
                )
              })
            }
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}


SmallBurgerButton.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  warnings: PropTypes.array.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    }))
  })),
}



export default withTheme()(withStyles(styles)(SmallBurgerButton));