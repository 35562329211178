import React from 'react';
import { withTheme,  Typography, Select,MenuItem,} from '@material-ui/core';
import { getIconComponent } from '../../../../utilities';


const SourceSelector = props => {

  const {
    classes,
    theme,
    source,
    onSelectSource,
    sourceList
  } = props;
  
  return (
    <div>
      <Typography className={classes.configTitle}>Select a Source</Typography>
      <div style={{paddingRight:24}}>
        <Select
          className={classes.selector}
          value={source}
          onChange={onSelectSource}
          data-test-id="scanner-source-selector"
          renderValue={el=>el==='none'?'Select a Source':sourceList.find(s=>s.id===el).name}
        > 
          <MenuItem value={'none'}>
            Select a Source
          </MenuItem>
          {
            sourceList.filter(el=>['DATABASE'].includes(el.source_template.type)).map(el=>(
              <MenuItem value={el.id}>
                <div style={{width:24,height:24,marginRight:16}}>
                  {getIconComponent({label:el.source_template.name,size:24,colour:theme.palette.primary.main})}
                </div>
                {el.name}
              </MenuItem>
            ))
          }

        </Select>

      </div>
    </div>
  )
}

export default withTheme()(SourceSelector);