import { withTheme, withStyles, Typography } from '@material-ui/core';
import React from 'react';

const styles = theme => ({
  root:{
    '&:hover':{
      background:`${theme.palette.swimlaneSelected.main} !important`
    }
  }
})


const KSwimLane = (props) => {
  const { data, id, classes, } = props;


  return (
    <div
      style={{
        background: data.background,
        width: data.width,
        height: data.height,
      }}
      data-swimlane-label={data.label}
      className={data.onClick?classes.root:undefined}
      onClick={data.onClick}
      id={id}
    >
      {
        data.showStaticLabel && 
        <Typography style={{paddingLeft:16,paddingTop:16,fontSize:13.75}}>
          {data.label}
        </Typography>
      }
    </div>
  );
};


export default withTheme()(withStyles(styles)(KSwimLane));