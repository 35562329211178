import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, Typography, withStyles, Button, Modal, Paper, Checkbox } from '@material-ui/core';
import VerticalTabBar from '../../../../VerticalTabBar/VerticalTabBar';
import { getDispFields, tabGroup, titleCaseObjectName } from '../../../../../../utilities';
import SimpleResultItem from '../../../../SearchResults/SimpleResultItem';
import { getIconLabel } from '../../../../SearchResults/utils';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../../../store/actions/actionTypes';
import { getFullNodeList } from '../../layoutUtils';

const styles = theme => ({
  modalContainer: {
    width: 880,
    maxWidth: '85vw',
    margin: 'auto',
    marginTop: '15vh',
    outline: 'none',
  },
  formBody: {
    // minHeight:'60vh',
    paddingLeft: 24,
    paddingBottom: 8,
    paddingRight: 24,
    paddingTop: 24,
    background: theme.palette.background.main,
    border: `1px solid ${theme.palette.border.main}`,
    overflow: 'hidden'
  },
  modalTitle: {
    fontSize: 20,
    color: theme.palette.primary.main,
    marginBottom: 8,
    wordBreak: 'break-word'
  },
  listContainer: {
    maxHeight: '50vh',
    overflow: 'auto',
    marginTop: 8,
    marginBottom: 24,
    display: 'flex',
    ...theme.components.customScroll
  },
  tabSectionHeader: {
    fontSize: 12,
    letterSpacing: 1,
    marginBottom: 8
  },
  tabContainer: {
    width: 200,
    marginRight: 24,
    position: 'sticky',
    top: 0,
    flexShrink: 0,
    flexGrow: 0,
    overflow: 'auto',
    ...theme.components.hideScroll
  },
  listHeader: {
    fontSize: 12,
    letterSpacing: 1,
  }
})

function BulkActionModal(props) {

  const {
    theme,
    classes,
    history,
    modalOpen,
    setModalOpen,
    mapControls,
    currentGroups,
    childrenMap,
    currentLinks,
    root
  } = props;
  
  const [selectedType, setSelectedType] = useState('');
  const [nodesByTypeMap, setNodesByTypeMap] = useState({});
  const [selectedIdsByType, setSelectedIdsByType] = useState({});
  const reduxDispatch = useDispatch()

  const fullNodes = getFullNodeList({
    currentGroups, 
    childrenMap, 
    currentLinks, 
    rootObject:root,
    mapControls,
    isGetInfoOnly: true
  })
  
  const setAllNodeByType = () => {
    let byTypeMap = {};
    let idsByType = {}
    currentGroups.forEach(group => {
      if(!fullNodes.find(n=>n.id===group.id))return;
      let groupObj = group.data.obj;
      if (groupObj) {
        let groupType = groupObj.type || groupObj.object_type_txt;
        if (groupType) {
          if (!byTypeMap[groupType]) {
            byTypeMap[groupType] = [];
            idsByType[groupType] = [];
          }
          if (!byTypeMap[groupType].find(node => node.id === groupObj.id)) {
            byTypeMap[groupType].push(groupObj);
            idsByType[groupType].push(groupObj.id);
          }
        }
      }

      let children = childrenMap[group.id].filter(child => fullNodes.find(n=>n.id===child.id));
      if (children) {
        children.forEach(child => {
          let childObj = child.data.obj;
          if (childObj) {
            let childType = childObj.object_type_txt || childObj.object_type || childObj.object?.name;
            if (childType) {
              if (!byTypeMap[childType]) {
                byTypeMap[childType] = [];
                idsByType[childType] = [];
              }
              if (!byTypeMap[childType].find(node => node.id === childObj.id)) {
                byTypeMap[childType].push(childObj);
                idsByType[childType].push(childObj.id);
              }
            }
          }
        });
      }
    });

    for (let i = 0; i < tabGroup.length; i++) {
      let options = tabGroup[i].tabs.filter(t => byTypeMap[t]);
      if (options.length > 0) {
        setSelectedType(options[0]);
        break;
      }
    }

    setNodesByTypeMap(byTypeMap);
    setSelectedIdsByType(idsByType);
  }

  useEffect(() => {
    if (modalOpen) {
      setAllNodeByType();
    } else {
      setNodesByTypeMap({});
      setSelectedIdsByType({});
    }
    // eslint-disable-next-line 
  }, [modalOpen])

  const onBulkAction = () => {
    let ids = [];
    Object.values(selectedIdsByType).forEach(arr => {
      ids = ids.concat(arr);
    });
    reduxDispatch({
      type:actions.SET_BULK_EDIT_PARAM,
      data:{
        objectIds: ids,
        redirectUrl:window.location.pathname + '?tabName=LINEAGE',
        fileName:`Assets from ${root.name_txt} ${titleCaseObjectName(root.object_type_txt)} lineage`.trim(),
      }
    })
    
		history.push('/bulk_update')
  }

  let totalSelected = Object.values(selectedIdsByType).reduce((acc, val) => acc + val.length, 0);

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      disableBackdropClick={true}
    >
      <div className={classes.modalContainer}>
        <Paper className={classes.formBody} style={{ maxHeight: '65vh', overflow: 'auto' }}>
          <Typography className={classes.modalTitle} style={{ marginBottom: 24 }}>
            Bulk action
          </Typography>
          <div className={classes.listContainer}>
            <div className={classes.tabContainer}>
              {
                tabGroup
                  .filter(g => g.tabs.some(t => nodesByTypeMap[t]))
                  .map(g => {
                    let options = g.tabs.filter(t => nodesByTypeMap[t]);
                    let optionsDisplay = options.map(o => `${o} (${selectedIdsByType[o].length})`);
                    return (
                      <div style={{ marginBottom: 12 }}>
                        <Typography className={classes.tabSectionHeader}>{g.name}</Typography>
                        <VerticalTabBar
                          tabOptions={optionsDisplay}
                          tabState={options.indexOf(selectedType)}
                          setTabState={value => setSelectedType(options[value])}
                        />
                      </div>
                    )
                  })
              }
            </div>
            <div style={{ flexGrow: 1, flexShrink: 1 }}>
              <div style={{ display: 'flex', alignItems: 'center', position: 'sticky', top: 0, zIndex: 1, background: theme.palette.background.main }}>
                <Checkbox
                  color='primary'
                  style={{ paddingRight: 16 }}
                  checked={selectedIdsByType[selectedType]?.length === nodesByTypeMap[selectedType]?.length}
                  onClick={() => {
                    if (selectedIdsByType[selectedType]?.length === nodesByTypeMap[selectedType]?.length) {
                      setSelectedIdsByType({ ...selectedIdsByType, [selectedType]: [] });
                    } else {
                      setSelectedIdsByType({
                        ...selectedIdsByType, [selectedType]: nodesByTypeMap[selectedType].map(node => node.id)
                      });
                    }
                  }
                  }
                />
                <Typography className={classes.listHeader}>
                  {selectedIdsByType[selectedType]?.length} OF {nodesByTypeMap[selectedType]?.length} SELECTED
                </Typography>
              </div>
              {
                selectedType &&
                nodesByTypeMap[selectedType]?.map(node => (
                  <SimpleResultItem
                    item={node}
                    key={node.id}
                    iconLabel={getIconLabel({ label: node.object_type_txt, item: node })}
                    title={getDispFields(node, 'dispTitle')}
                    titleColour={theme.palette.primaryText.main}
                    subTitle={getDispFields(node, 'dispSubtitle')}
                    showUnderline
                    hideIcon
                    onClick={() => {
                      let newSelectedIds = selectedIdsByType[selectedType].slice();
                      let index = newSelectedIds.indexOf(node.id);
                      if (index > -1) {
                        newSelectedIds.splice(index, 1);
                      } else {
                        newSelectedIds.push(node.id);
                      }
                      setSelectedIdsByType({ ...selectedIdsByType, [selectedType]: newSelectedIds });
                    }}
                    headObject={
                      <Checkbox
                        style={{ paddingRight: 0 }}
                        color='primary'
                        checked={selectedIdsByType[selectedType].includes(node.id)}
                      />
                    }
                  />
                ))
              }
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'sticky', bottom: 0, paddingTop: 16, marginTop: 20, background: theme.palette.background.main }}>
            <Button data-test-id="bulk-action-confirm-button" color='primary' disabled={totalSelected === 0} onClick={onBulkAction} style={{ marginRight: 8 }}>BULK ACTION ({totalSelected})</Button>
            <Button data-test-id="bulk-action-cancel-button" color='secondary' onClick={() => setModalOpen(false)}>CANCEL</Button>
          </div>
        </Paper>
      </div>
    </Modal>
  )
}

BulkActionModal.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  currentGroups: PropTypes.array.isRequired,
  childrenMap: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(BulkActionModal));