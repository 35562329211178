import React, { } from 'react';
import { withTheme,withStyles, Typography,Button,  Modal, Paper} from '@material-ui/core';


const styles = theme => ({
  modalContainer:{
    display:'flex',
    width:'100vw',
    height:'100vh',
    alignItems:'center',
    justifyContent:'center',
    '&:hover': {
      outline: 'none',
    },
  },
  paperContainer:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    width:'80vw',
    padding:'24px 24px 8px',
    outline: 'none',
  },
})

const ErrorModal = props => {

  const {
    classes,
    theme,
    modalOpen,
    setModalOpen
  } = props;

  return (
    <Modal 
      open={modalOpen} 
      disableBackdropClick={true} 
      disableEscapeKeyDown={true} 
      hideBackdrop={false} 
      className = {classes.modalContainer}
    >
      {
        modalOpen &&
        <Paper className = {classes.paperContainer}>
          <Typography style={{fontSize:20,marginBottom:24,color:theme.palette.header.main}}>
            Error details
          </Typography>
          <Typography 
            style={{
              fontSize:13.75,
              color:theme.palette.primaryText.main,
              whiteSpace:'pre-wrap',
              maxHeight:'60vh',
              overflowY:'auto',
            }}
          >
            {modalOpen.error}
          </Typography>
          <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
            <Button color='secondary' style={{width:90,height:36,marginTop:'24px'}} onClick={(event)=>{setModalOpen(false);}}>CLOSE</Button>
          </div>
        </Paper>  
      }
    </Modal>
  )
}

export default withTheme()(withStyles(styles)(ErrorModal));