import { createMuiTheme } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import {getAlertTheme} from './alertThemeManager';
import toastUIStyle from './toast-ui-custom';
import { getGlobalTextTheme } from './globalTextTheme';

export const graphPalette = {
  data:'#008FFB',
  content:'#00E396',
  people:'#FC642D',
  code:'#FEB019'
}

export const darkBackground = '#2c2f33';
export const lightBackground = '#ffffff';

export const checkIsDarkMode = () => localStorage.hasOwnProperty('dark')?true:false

export const palette = {
  primary: {
    main: localStorage.hasOwnProperty('dark')?'#b8d2ff':'#003c8f',
    light: '#4c66c0',
    dark: localStorage.hasOwnProperty('dark')?'#82b2ff':'#001861',
  },
  secondary: {
    main: '#fc642d',
    light: '#ff965b',
    dark: '#c23100',
  },
  primaryText:{
    main:localStorage.hasOwnProperty('dark')?'rgb(234,234,234)':'#000000',
    light:localStorage.hasOwnProperty('dark')?'#DCDDDE':'#888888',
    dark: localStorage.hasOwnProperty('dark')?'#DCDDDE':'#666666',
  },
  chartNodeSelected:{
    main: localStorage.hasOwnProperty('dark')?'#515151':'#DCDDDE',
  },
  header:{
    main: localStorage.hasOwnProperty('dark')?'rgb(234,234,234)':'#003c8f',
  },
  overline:{
    main: localStorage.hasOwnProperty('dark')?'rgb(234,234,234)':'rgba(128,128,128,1)',
  },
  chartDisabledIcon:{
    main:localStorage.hasOwnProperty('dark')?'#888888':'#888888'
  },
  tileBorder:{
    main: localStorage.hasOwnProperty('dark')?'rgb(234,234,234)':'#dddddd',
  },
  avatar:{
    main:'#4c66c0'
  },
  hyperLink:{
    main:localStorage.hasOwnProperty('dark')?'rgb(234,234,234)':'#53A8E2'
  },
  inlineCIBackground:{
    main:'#53A8E2'
  },
  background:{
    main:localStorage.hasOwnProperty('dark')?darkBackground:lightBackground,
    light:localStorage.hasOwnProperty('dark')?'rgba(128,128,128,1)':'rgba(225,225,225,1)',
    dark:localStorage.hasOwnProperty('dark')?'#23272a':'#001861',
  },
  contextMenuBackground:{
    main:localStorage.hasOwnProperty('dark')?'rgba(80,80,80,1)':'rgba(255,255,255,1)',
  },
  hovered:{
    main:localStorage.hasOwnProperty('dark')?'#667078':'#eeeeee'
  },
  textSelectedBackground:{
    main:localStorage.hasOwnProperty('dark')?'#2D62CC':'#2D62CC'
  },
  editHovered:{
    main:localStorage.hasOwnProperty('dark')?'#66707850':'#eeeeee'
  },
  buttonHovered:{
    main:localStorage.hasOwnProperty('dark')?'#4b5257':'#ECF4FA'
  },
  swimlaneSelected:{
    main:localStorage.hasOwnProperty('dark')?'#4b5257':'#ECF4FA'
  },
  buttonHoverText:{
    main:localStorage.hasOwnProperty('dark')?'#ffffff':'#000000'
  },
  inventorySelected:{
    main:localStorage.hasOwnProperty('dark')?'#4b5257':lighten('#0F6AC0', 0.9)
  },
  listItemDivider:{
    main:localStorage.hasOwnProperty('dark')?'#aaaaaa':'#dddddd'
  },
  metrics:{
    main: localStorage.hasOwnProperty('dark')?'#ffffff':'#003c8f',
  },
  border:{
    main:'#ffffff'
  },
  chip:{
    main:localStorage.hasOwnProperty('dark')?'#2c2f33':'#e4e4e4'
  },
  chipBorder:{
    main:localStorage.hasOwnProperty('dark')?'#e4e4e4':'#e4e4e4'
  },
  collectionChip:{
    main:'#e4e4e4'
  },
  inputBackground:{
    main:localStorage.hasOwnProperty('dark')?'#2c2f33':'#ededed'
  },
  success:{
    main:'#00D46A'
  },
  infoBoxButtonColour:{
    main:localStorage.hasOwnProperty('dark')?'#00D46A':'#408741'
  },
  error:{
    main:localStorage.hasOwnProperty('dark')?'#ff385c':'#B00020',
    light:'#F24472'
  },
  warning:{
    main: '#D21202'
  },
  usageBar:{
    main:localStorage.hasOwnProperty('dark')?'#D7D8D8':'#003c8f',
    dark:localStorage.hasOwnProperty('dark')?'#2c2f33':'#ddd',
  },
  snackbarContent:{
    main:localStorage.hasOwnProperty('dark')?'rgba(0,0,0,0.87)':'#fff'
  },
  snackbarBackground:{
    main:localStorage.hasOwnProperty('dark')?'#dfd7d7':'#001a6c'
  },
  unfollowedButtonColor:{
    main:localStorage.hasOwnProperty('dark')?'#272C30':'#ffffff'
  },
  unfollowedButtonBackground:{
    main:localStorage.hasOwnProperty('dark')?'#ffffff':'#272C30'
  },
  unfollowedButtonHover:{
    main:localStorage.hasOwnProperty('dark')?'#dddddd':'#616466'
  },
  kChartGroupBackground:{
    main:localStorage.hasOwnProperty('dark')?'#dddddd20':'#B5B5B520',
    solid:localStorage.hasOwnProperty('dark')?'#383838':'#f5f5f5',
  }
}

const customKStyle = {
  breadcrumb:{
    color: palette.primaryText.main,
    fontSize:13.75,
    maxWidth:325,
    display:'inline-block',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
      maxWidth:'100vw',
      width:'max-content'
    }
  },
  suggestionChip:{
    minWidth:118,
    height:24,
    display:'flex',
    paddingLeft:12,
    paddingRight:12,
    boxSizing:"border-box",
    alignItems:'center',
    justifyContent:'center',
    backgroundColor:palette.background.main,
    color:palette.primaryText.main,
    border:`1px solid ${palette.primaryText.light}`,
    borderRadius:12,
    fontSize:13.75,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    cursor:'pointer',
    marginRight:16,
    '&:hover':{
      backgroundColor:palette.hovered.main
    }
  },
  genAIButton:{
    fontSize:13,
    color:palette.secondary.main,
    cursor:'pointer',
    marginTop:8,
    height:24,
    display:'flex',
    alignItems:'center',
    boxSizing:'border-box',
    width:'max-content',
    maxWidth:'100%',
    borderRadius:12,
    border:`1px solid ${palette.secondary.main}`,
    padding:'0px 8px',
    '&:hover':{
      background:lighten(palette.secondary.main,0.9)
    }
  },
  overwriteGenAIButton:{
    fontSize:13,
    color:palette.primaryText.main,
    cursor:'pointer',
    marginTop:8,
    height:24,
    display:'flex',
    alignItems:'center',
    boxSizing:'border-box',
    width:'max-content',
    maxWidth:'100%',
    borderRadius:12,
    border:`1px solid ${palette.primaryText.light}`,
    padding:'0px 8px',
    '&:hover':{
      background:palette.hovered.main
    }
  },
  codeMirror:{
    background:'transparent !important',
    overflow:'visible !important',
    paddingRight:`32px !important`,
    textShadow:'none !important',
    margin:`0 !important`,
    '& .react-syntax-highlighter-line-number':{
      paddingTop:3,
      paddingBottom:1,
      display:'block'
    },
    '& .token':{
      color:`${palette.primary.main} !important`
    },
    '& code':{
      fontSize:`13.75px !important`,
      textShadow:'none !important',
      color:`${palette.primaryText.main} !important`
    },
    '& span':{
      fontSize:13.75,
      color:`${palette.primaryText.main} !important`,
      background:'transparent !important'
    }
  },
  selector:{
    border:`1px solid ${palette.border.main}`,
    boxSizing:'border-box',
    borderRadius:4,
    background:palette.inputBackground.main,
    color:palette.primaryText.main,
    '& svg':{
      color:palette.primaryText.main,
      marginRight:8,
    },
    '& div div': {
      paddingLeft: 8,
      paddingTop:10,
      paddingBottom:10,
      fontSize:16
    },
    '& span':{
      fontSize:16
    }
  },
  titleSelector:{
    '& div div':{
      padding:'4px 24px 4px 8px',
      fontSize:20
    },
    '& svg':{
      color:palette.header.main
    },
    border:`1px solid ${palette.header.main}`,
    boxSizing:'border-box',
    color:palette.header.main,
    borderRadius:4,
    fontSize:20,
  },
  inputBase:{
    background:palette.inputBackground.main,
    color:palette.primaryText.main,
    border:`1px solid ${palette.border.main}`,
    boxSizing:'border-box',
    borderRadius:4,
    '& input':{
      paddingLeft:16
    },
    '& input:-webkit-autofill': {
      color:`${palette.primaryText.main} !important`,
      '-webkit-text-fill-color': `${palette.primaryText.main} !important`,
      '-webkit-box-shadow': `0 0 0 30px ${palette.background.main} inset !important`,
      borderRadius:`4px !important`,
      'caret-color': palette.primaryText.main,
    },
    '& input:-webkit-autofill:focus': {
      '-webkit-text-fill-color': `${palette.primaryText.main} !important`,
      '-webkit-box-shadow': `0 0 0 30px ${palette.background.main} inset !important`,
      borderRadius:`4px !important`
    }
  },
  disabledButton:{
    color:`${palette.primaryText.light} !important`,
  },
  avatar:{
    color: '#fff',
    backgroundColor: palette.avatar.main
  },
  hideScroll:{
    '&::-webkit-scrollbar-track': {
      borderRadius:0,
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      borderRadius:0,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{

    },
    '&::-webkit-scrollbar': {
      width: 0,
      height:0
    },
  },
  customScroll:{
    '&::-webkit-scrollbar-track': {
      background: palette.background.light,
      borderRadius:6,
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      background: palette.primaryText.light,
      borderRadius:6,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{

    },
    '&::-webkit-scrollbar': {
      width: 5,
      height:5
    },
  },
  MDViewer:{
    '& *':{
      caretColor: 'transparent'
    },
    '& .toastui-editor-toolbar':{
      display:'none'
    },
    '& .toastui-editor-defaultUI':{
      border:'none',
    },
    '& .ProseMirror':{
      padding:0,
      paddingTop:6,
      paddingBottom:6,
      background:palette.background.main
    },
    '& .toastui-editor-ww-container > .toastui-editor':{
      minHeight:`0px !important`
    }
  },
  /////////////
  // info box
  infoBox:{
    padding:'16px 24px',
    borderRadius:3,
    border:`1px solid ${palette.listItemDivider.main}`,
    marginBottom:40,
    boxSizing:'border-box',
    maxWidth:1920
  },
  infoBoxHeader:{
    fontSize:16,
    color:palette.primaryText.main,
    marginBottom:8
  },
  infoBoxDescription:{
    color:palette.primaryText.light,
    fontSize:13.75,
    whiteSpace:'pre-wrap',
    marginBottom:20,
    marginRight:40,
    flexShrink:1,
    flexGrow:1
  },
  infoBoxFocusTxt:{
    fontSize:13.75,
    cursor:'pointer',
    color:palette.hyperLink.main,
    textDecoration:'underline'
  },
  infoBoxButtonContained:{
    background:palette.success.main,
    minWidth:90,
    color:'#000',
    // marginRight:40,
    display:"block",
    flexShrink:0,
    '&:hover':{
      background:palette.success.main,
    }
  },
  infoBoxButtonOutlined:{
    display:"block",
    border:`1px solid ${palette.infoBoxButtonColour.main}`,
    minWidth:90,
    paddingLeft:16,
    paddingRight:16,
    color:palette.infoBoxButtonColour.main,
    flexShrink:0,
  }
}

const theme = createMuiTheme({
  palette: palette,
  overrides:{
    MuiInputBase:{
      disabled:{
        color:`${palette.primaryText.light}${localStorage.hasOwnProperty('dark')?'75':''} !important`,
        // border:localStorage.hasOwnProperty('dark')?`1px solid ${palette.border.main}75 !important`:undefined,
      },
      input:{
        '&::selection':{
          backgroundColor:palette.textSelectedBackground.main,
          color:'#fff'
        }
      }
    },
    MuiIconButton:{
      root:{
        padding:8,
        '&:hover':{
          backgroundColor:palette.buttonHovered.main
        }
      },
    },
    MuiCheckbox:{
      root:{
        color:palette.primaryText.main
      },
      disabled:{
        color:`${palette.chartDisabledIcon.main} !important`
      }
    },
    MuiMenu:{
      paper:{
        ...customKStyle.customScroll
      }
    },
    MuiRadio:{
      root:{
        color:palette.primaryText.main
      }
    },
    MuiPickersSlideTransition:{
      transitionContainer:{
        '& > *':{
          color:`${palette.primaryText.main}`
        }
      }
    },
    // MuiSwitch:{
    //   icon:{
    //     color:palette.primaryText.main
    //   },
    //   bar:{
    //     backgroundColor:palette.primary.main
    //   }
    // },
    MuiTooltip:{
      tooltip:{
        fontSize:13.75,
        maxWidth:450,
        maxHeight:'80vh',
        overflow:'hidden',
        wordBreak:'break-word',
        whiteSpace:'pre-wrap',
      },
    },
    MuiSnackbarContent:{
      root:{
        flexWrap:'nowrap',
      },
      message:{
        wordBreak:'break-word',
      },
      action:{
        flexShrink:0,
      }
    },
    MuiPickersToolbarButton:{
      toolbarBtn:{
        color:palette.background.main
      },
      toolbarBtnSelected:{
        color:palette.background.main
      }
    },
    MuiPickersCalendarHeader:{
      dayLabel:{
        color:palette.primaryText.light
      },
      iconButton:{
        backgroundColor:palette.background.main,
        color:palette.primaryText.light,
        '& > *':{
          backgroundColor:palette.background.main,
        },
      }
    },
    MuiTypography:{
      body2:{
        color:palette.primaryText.main,
      }
    },
    MuiPickersClockNumber:{
      clockNumber:{
        color:palette.primaryText.main
      },
      selected:{
        color:palette.background.main
      }
    },
    MuiPickersDay:{
      day:{
        color:palette.primaryText.main
      },
      isSelected:{
        color:palette.background.main
      }
    },
    MuiPaper:{
      root:{
        backgroundColor:palette.background.main,
        // border:`1px solid ${palette.primaryText.main}`
      }
    },
    MuiButton:{
      label:{
        fontSize:13.75
      },
      disabled:{
        color:`${palette.primaryText.light} !important`
      },
      contained:{
        boxShadow:'none',
      }
    },
    MuiPopover:{
      paper:{
        border:`1px solid ${palette.border.main}`
      }
    },
    MuiMenuItem:{
      root:{
        color:palette.primaryText.main,
        '&:hover':{
          background: palette.hovered.main
        },
        '& span':{
          fontSize:16
        }
      },
      selected:{
        backgroundColor: `${palette.hovered.main} !important`
      }
    }
  },
  components: customKStyle,
  containers: {
    grid: {
      width: '100% !important',
      margin: 0
    },
    card: {
      border: '1px solid #EDEDED',
      borderRadius: '4px',
      boxShadow: 'none',
      color: '#4c66c0'
    },
    cardSelected: {
      backgroundColor: 'rgb(0, 60, 143, 0.08)',
    },
    dashboardWrapper: {
      borderRadius: 4,
      border: '1px solid #75757530',
    }
  },
  typography: {
    useNextVariants: true,
    button2: {
      letterSpacing: '0.1em',
      fontSize: "0.875rem",
      textTransform: "uppercase",
      fontWeight: 400,
      fontFamily: "Helvetica, Arial, sans-serif",
    },
    contextHeading: {
      letterSpacing: '0.1em',
      fontSize: "1rem",
      textTransform: "uppercase",
      fontWeight: 400,
      fontFamily: "Helvetica, Arial, sans-serif",
    },
    code: {
      fontSize: "0.875rem",
      fontWeight: 400,
      fontFamily: "Roboto Mono",
      lineHeight: "1.46429em",
      color: "rgba(0, 0, 0, 0.87)",
    },
    overline: {
      letterSpacing: 2,
      color: 'rgba(0, 0, 0, 0.54)',
      lineHeight: 'unset',
    }
  }
});

const alertThemeCSS = getAlertTheme(palette);
let darkThemeStyle = document.getElementById('alert_dark')
if(darkThemeStyle)darkThemeStyle.remove()
let lightThemeStyle  = document.getElementById('alert_light')
if(lightThemeStyle)lightThemeStyle.remove()
let globalTextTheme = document.getElementById('global_text_theme')
if(globalTextTheme)globalTextTheme.remove()

const head = document.head;
const alertStyle = document.createElement('style');
alertStyle.appendChild(document.createTextNode(alertThemeCSS))
alertStyle.id = localStorage.hasOwnProperty('dark')?'alert_dark':'alert_light';
head.appendChild(alertStyle);

const toastUIThemeStyle = document.createElement('style');
toastUIThemeStyle.appendChild(document.createTextNode(toastUIStyle))
toastUIThemeStyle.id = localStorage.hasOwnProperty('dark')?'toast_ui_dark':'toast_ui_light';
head.appendChild(toastUIThemeStyle);

const globalTextThemeStyle = document.createElement('style');
globalTextThemeStyle.appendChild(document.createTextNode(getGlobalTextTheme(palette)))
globalTextThemeStyle.id = 'global_text_theme';
head.appendChild(globalTextThemeStyle);

export default theme;
