import React from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';

const styles = theme => ({
  url:{
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline'
    }
  },
  hoverEffect:{
    cursor:'pointer',
    '&:hover':{
      backgroundColor:theme.palette.hovered.main
    }
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
})

function Details(props) {

  const {
    state,
    fetchList,
  } = props;

  let description = state.basicData.description || ''
  if(state.basicData.parent.name==='Table Cluster' && state.memberTables && state.memberTables.length>0){
    description = `This table cluster includes ${state.memberTables.length} tables: ` + state.memberTables.map(el=>el.name).join(', ')
  }

  return (  
    <div>
      <UpdateInputCerebrum
        url={`/api/collectioninstances/${state.basicData.id}`}
        label={'collection_instance'}
        data={state.basicData}
        state={state}
        initialValue={description}
        property="description"
        placeholder="Add a description"
        multiline
        subtitle={`Describe the purpose of this collection instance`}
        fetchList={fetchList}
        disableEditing={true}
      />
    </div>

  )
}



export default withTheme()(withStyles(styles)(Details));