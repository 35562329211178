import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import History from '../History/History';
import CreateTemplate from '../CreateTemplate/CreateTemplate';
import SnackBar from '../../UI/SnackBar/SnackBar';

const styles = theme => ({
  
})

function Body(props) {

  const {
    state,
    dispatch,
    history,
  } = props;


  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarButtons, setSnackbarButtons] = useState([]);

  let bodyContent;

  if(state.view==='create_template'){
    bodyContent = <CreateTemplate state={state} dispatch={dispatch} history={history} setSnackBarOpen={setSnackbarOpen} setSnackbarMessage={setSnackbarMessage} setSnackbarButtons={setSnackbarButtons} />
  }else if(state.view==='history'){
    bodyContent = <History state={state} dispatch={dispatch} history={history} />
  }


  return (
    <div>
      {bodyContent}
      <SnackBar
        snackBarOpen={snackbarOpen}
        setSnackBarOpen={setSnackbarOpen}
        message={snackbarMessage}
        buttons={snackbarButtons}
      />
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default withStyles(styles)(Body);