import React, { useEffect, useRef } from 'react';
import { withTheme, FormControl, InputBase, Button, Typography, IconButton, CircularProgress} from '@material-ui/core';
import { getIconComponent } from '../../../utilities';
import KTooltip from '../../UI/KTooltip/KTooltip';
import useAlert from '../../../hooks/useAlert';

const AddDBName = props => {

  const {
    classes,
    theme,
    dbNames,
    setDBNames,
    originalDBs,
    onUpdateDB,
    sourceObject,
    dbNamesLoading,
    dbNamesError,
    creating
  } = props;

  const [dbName, setDBName] = React.useState('')

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  if(dbNamesLoading){
    return <CircularProgress color='secondary'/>
  }

  if(dbNamesError){
    return <Typography>Error occurred loading details </Typography>
  }
  
  let maxLimitReached = false;
  if(dbNames.length>=1 && sourceObject==='Tool'){
    maxLimitReached = true
  }

  return (
    <div className={classes.block}>
      {
        dbNames.map(el=>(
          <div style={{display:'flex',alignItems:'center',padding:'8px 16px',overflow:"hidden",borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}}>
            <div style={{width:24,height:24,flexGrow:0,flexShrink:0}}>
              {getIconComponent({label:sourceObject,size:24,colour:theme.palette.primaryText.light})}
            </div>
            <Typography style={{fontSize:13.75,marginLeft:16,marginRight:16,flexGrow:1,flexShrink:1,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
              {el}
            </Typography>
            {
              !originalDBs.map(el=>el.name.toUpperCase()).includes(el.toUpperCase()) &&
              <IconButton style={{padding:6}} onClick={()=>{setDBNames(dbNames.filter(d=>d!==el))}}>
                {getIconComponent({label:'delete',size:20,colour:theme.palette.primaryText.light})}
              </IconButton>
            }
          </div>
        ))
      }
      <FormControl style={{width:'100%',marginTop:dbNames.length>0?16:0}}>
        <InputBase
          value={dbName}
          onChange={event=>setDBName(event.target.value)}
          className={classes.inputBase}
          placeholder={maxLimitReached?'Only one tool can be added for a source':`Add a ${sourceObject} name`}
          disabled={maxLimitReached}
        />
      </FormControl>
      <KTooltip placement={'bottom-start'} title={maxLimitReached?'Only one tool can be added for a source':''}>
        <div>
          <Button 
            color='primary' 
            style={{marginTop:8,marginBottom:24,display:'block'}} 
            disabled={!dbName.trim() || maxLimitReached} 
            onClick={()=>{
              if(dbNames.map(el=>el.toUpperCase()).includes(dbName.toUpperCase())){
                sendAlert({message:"The name already exists",type:'error'})
                return;
              }
              setDBNames([...dbNames,dbName]);setDBName('')
            }}
          >
            ADD
          </Button>
        </div>
      </KTooltip>
      <div>
        {
          creating?
          <CircularProgress color='secondary' style={{width:20,height:20}}/>:
          <Button color='primary' variant='contained' style={{display:'block'}} disabled={dbNames.length===0} onClick={()=>onUpdateDB()}>
            SAVE
          </Button>
        }
      </div>
    </div>
  )
}

export default withTheme()(AddDBName);