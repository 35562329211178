import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Details from '../Details/Details';
import UsedList from '../UsedList/UsedList';

const styles = theme => ({
  root: {
  }
})

const Body = props => {

  const {
    classes,
    tabState,
    history,
    state,
    dispatch,
    fetchList,
    loadDataTabOption,
    loadContentTabOption
  } = props;

  let bodyContent;

  switch (tabState) {
    case 0: // usage
      bodyContent = (
        <Details
          state={state}
          dispatch={dispatch}
          fetchList={fetchList}
          history={history}
        />
      )
      break;
    case 1: // data
      bodyContent = (
        <UsedList
          key='data'
          variant="data"
          history={history}
          state={state}
          dispatch={dispatch}
          tabOptions={state.dataTabOption}
          tabState={state.dataTabState}
          setTabState={(value)=>dispatch({type:'set_data_tab_state',dataTabState:value})}
          tabOptionsLoading={state.dataTabOptionLoading}
          tabOptionsError={state.dataTabOptionError}
          loadTabOptions={loadDataTabOption}
        />
      )
      break;
    case 2: // content
      bodyContent = (
        <UsedList
          key='content'
          variant="content"
          history={history}
          state={state}
          dispatch={dispatch}
          tabOptions={state.contentTabOption}
          tabState={state.contentTabState}
          setTabState={(value)=>dispatch({type:'set_content_tab_state',contentTabState:value})}
          tabOptionsLoading={state.contentTabOptionLoading}
          tabOptionsError={state.contentTabOptionError}
          loadTabOptions={loadContentTabOption}
        />
      )
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
  nodeKey: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
}

export default withStyles(styles)(Body);