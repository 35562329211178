import React, { useState, useRef } from 'react';
import { withTheme, FormControl, InputBase, Button, Typography, MenuItem, CircularProgress, FormHelperText, Select } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';

const AddHostDetail = props => {

  const {
    classes,
    theme,
    sourceName,
    onChangeSourceName,
    onChangeHostName,
    hostDescription,
    onChangeHostDescription,
    editedHost,
    sourceObject,
    onChangeSourceObject,
    dbNames,
    dbNamesLoading,
    dbNamesError,
    onCreate,
    creating,
    detailsFilled
  } = props;


  const [searchValue, setSearchValue] = useState(editedHost?.name||'')
  const searchTimeoutRef = useRef()
  const [searchResult, setSearchResult] = useState({})

  const searchHosts = (value) => {
    setSearchResult({loading:true})
    axiosCerebrum
      .get(
        `/api/hosts`,{params:{
          per_page:0,
          name:value
        }}
      )
      .then(response => {
        setSearchResult(response.data)
        if(response.data.total===0)onChangeHostName(value)
      })
      .catch(error => {
        console.log(error)
        setSearchResult({error:true})
      })
  }

  const searchHost = (value) => {
    onChangeHostName('')
    clearTimeout(searchTimeoutRef.current)
    if (value.trim()==='') {
      setSearchResult({items:[],total:0})
      return;
    }
    searchTimeoutRef.current = setTimeout(() => {
      searchHosts(value)
    }, 500)
  }


  if(dbNamesLoading){
    return <CircularProgress color='secondary'/>
  }

  if(dbNamesError){
    return <Typography>Error occurred loading details </Typography>
  }
  

  return (
    <div className={classes.block}>
      <Typography className={classes.sectionHeader}>SOURCE NAME</Typography>
      <FormControl style={{width:'100%'}}>
        <InputBase
          value={sourceName}
          onChange={onChangeSourceName}
          className={classes.inputBase + (sourceName?.trim()?'':` ${classes.errorBorder}`)}
          placeholder='Add a source name'
        />
        <FormHelperText style={{marginLeft:18, color:sourceName?.trim()?theme.palette.primaryText.light:theme.palette.error.main}}>
          Required
        </FormHelperText>
      </FormControl>
      
      <Typography className={classes.sectionHeader} style={{marginTop:24}}>HOST NAME</Typography>
      <FormControl style={{width:'100%'}}>
        <InputBase
          value={searchValue}
          onChange={event=>{
            setSearchValue(event.target.value)
            searchHost(event.target.value)
          }}
          disabled={editedHost}
          className={classes.inputBase + (searchResult.total || !searchValue?.trim()?` ${classes.errorBorder}`:'')}
          placeholder='Select a host or a host name'
        />
        <FormHelperText style={{marginLeft:18, color:searchResult.total || !searchValue?.trim()?theme.palette.error.main:theme.palette.primaryText.light}}>
          {
            editedHost?
            'Host name cannot be changed once created':
              searchResult.total?
              'This host name already exists'
              :
              'Host name is typically the server name or service URL'
          }
        </FormHelperText>
      </FormControl>

      <Typography className={classes.sectionHeader} style={{marginTop:24}}>DESCRIPTION</Typography>
      <FormControl style={{width:'100%'}}>
        <InputBase
          value={hostDescription}
          onChange={onChangeHostDescription}
          className={classes.inputArea}
          multiline
          rows={6}
          placeholder='Add a host description'
        />
      </FormControl>

      <Typography className={classes.sectionHeader} style={{marginTop:24}}>SOURCE OBJECT</Typography>
      <FormControl style={{opacity:editedHost?0.7:1,marginBottom:24}}>
        <Select
          className={classes.selector + (sourceObject==='none'?` ${classes.errorBorder}`:'')}
          value={sourceObject}
          onChange={onChangeSourceObject}
          disabled={editedHost && dbNames.length>0}
          disableUnderline
          displayEmpty
          renderValue={el=>{
            return (
              el==='none'
                ?
                <Typography style={{color:theme.palette.primaryText.light,fontSize:16}}>
                  Select a Source object
                </Typography>
                :
                <div>
                  <Typography style={{color:theme.palette.primaryText.light,fontSize:12}}>
                    Select a Source object
                  </Typography>
                  <Typography style={{fontSize:16,color:theme.palette.primaryText.main, opacity:editedHost && dbNames.length>0?0.6:1}}>
                    {sourceObject}
                  </Typography>
                </div>
            )
          }}
        > 
        <MenuItem value={'none'}>
          Select a Source object
        </MenuItem>
        <MenuItem value={'Database'}>
          Database
        </MenuItem>
        <MenuItem value={'Tool'}>
          Tool
        </MenuItem>
      </Select>
      <FormHelperText style={{marginLeft:18, color:sourceObject==='none'?theme.palette.error.main:theme.palette.primaryText.light}}>
        Required
      </FormHelperText>
    </FormControl>

    <div>
      {
        creating?
        <CircularProgress color='secondary' style={{width:20,height:20}}/>:
        <Button color='primary' variant='contained' style={{display:'block'}} disabled={!detailsFilled()} onClick={()=>onCreate()}>
          {editedHost?'SAVE':'CREATE'}
        </Button>
      }
    </div>
  </div>
  )
}

export default withTheme()(AddHostDetail);