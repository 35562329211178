import React from 'react';
import PropTypes from 'prop-types';
import { withStyles} from '@material-ui/core';
import ByInstance from '../ByInstance/ByInstance';
import { collectionIds } from '../../../utilities';

const styles = theme => ({
  root: {

  }
})

const Body = props => {

  const {
    classes,
    state,
    dispatch,
    tabOptions,
    history,
    sessionData
  } = props;

  let bodyContent;
  switch (tabOptions[state.tabState]) {
    case 'BY SOURCE':
      break;
    case 'BY DOMAIN':
      bodyContent = (
        <ByInstance
          state={state}
          dispatch={dispatch}
          tabOptions={tabOptions}
          history={history}
          sessionData={sessionData}
          collection={{
            id: collectionIds.domain,
            name: 'Domain',
            object_type_txt: 'COLLECTION',
          }}
        />
      )
      break;
    case 'BY CATEGORY':
      bodyContent = (
        <ByInstance
          state={state}
          dispatch={dispatch}
          tabOptions={tabOptions}
          history={history}
          sessionData={sessionData}
          collection={{
            id: collectionIds.category,
            name: 'Category',
            object_type_txt: 'COLLECTION',
          }}
        />
      )
      break;
    case 'BY USE CASE':
      bodyContent = (
        <ByInstance
          state={state}
          dispatch={dispatch}
          tabOptions={tabOptions}
          history={history}
          sessionData={sessionData}
          collection={{
            id: collectionIds.verifiedUseCase,
            name: 'Verified Use Case',
            object_type_txt: 'COLLECTION',
          }}
        />
      )
      break;
    default:
  }
  

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sessionData: PropTypes.object
}

export default withStyles(styles)(Body);