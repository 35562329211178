export const checkIsClickOnSuggestion = (rootEl) => {
  let isSuggestion = false;
  let el = rootEl;
  if(!el)return false;
  while(el){
    if(el.id === 'search_selector_popper_list'){
      isSuggestion = true;
      break;
    }
    el = el.parentElement;
  }
  return isSuggestion;
}


export const measureText = txt => {
  let canvasEl = document.createElement('canvas')
  let ctx  = canvasEl.getContext('2d')
  ctx.font = '13.75px Roboto'
  let width = ctx.measureText(txt).width;
  canvasEl.remove();
  return width;
}