import React, {useEffect, useRef, useState} from 'react';
import { withTheme, withStyles, Popper, ClickAwayListener, Paper, Typography} from '@material-ui/core';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import axiosCerebrum from '../../../axios-cerebrum';

const styles = theme => ({
})

const HostSelector = props => {

  const {
    theme,
    inputRef,
    searchValue, 
    setHost,
  } = props;

  const [popperOpen, setPopperOpen] = useState(false);
  const [data, setData] = useState({});
  const searchRef = useRef()
  const shouldStopSearch = useRef(false)

  const loadSuggestion = (value) => {
    setData({loading:true})
    axiosCerebrum
      .get(
        `/api/hosts`,{
          params:{
            per_page:20,
            page:1,
            reference:true,
            sort:'ALPHABETICAL',
            'search.name':value
          }
        }
      )
      .then(response=>{
        setData({...response.data})
      })
      .catch(error=>{
        console.log(error)
        setData({error:true})
      })
  }

  useEffect(()=>{
    clearTimeout(searchRef.current)
    if(shouldStopSearch.current)return;
    if(searchValue.trim()){
      setPopperOpen(true)
      searchRef.current = setTimeout(()=>{
        loadSuggestion(searchValue)
      },300)
    }
    else{
      setPopperOpen(false)
      setData({})
    }
  },[searchValue])
  
  if(!inputRef)return <></>;
  if(!searchValue.trim())return <></>;
  if(data?.total===0 || !data.items)return <></>
  
  return (
    <Popper  open={popperOpen} anchorEl={inputRef} placement={'bottom-start'} style={{marginTop:6, marginLeft:0,width:inputRef?inputRef.getBoundingClientRect().width:undefined}}>
        <ClickAwayListener onClickAway={()=>setPopperOpen(false)}>
        <Paper id="search_selector_popper" style={{background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`,width:'100%',paddingBottom:8,overflow:'auto',maxHeight:520}}>
          <Typography style={{letterSpacing:2,fontSize:12,color:theme.palette.header.main,marginLeft:16,marginTop:16}}>
            REFERENCE HOST MATCHES
          </Typography>
          <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:6,marginTop:4,marginLeft:16,}}>
            Select a matching host to merge its assets to this onboarded source
          </Typography>
          {
            data.items?.map((item,index)=>{
              return (
                <SimpleResultItem
                  title={item.alternate_name || 'Unknown'}
                  titleColour={theme.palette.primaryText.main}
                  label={'host'}
                  item={item}
                  subTitle={item.name}
                  hideRight={true}
                  // noPadding={true}
                  showUnderline={index!==data.items.length-1?true:false}
                  onClick={() => {
                    setHost(item)
                    setTimeout(()=>{
                      setPopperOpen(false)
                    },300)
                    shouldStopSearch.current = true
                    setTimeout(() => {
                      shouldStopSearch.current = false
                    }, 300);
                  }}
                />
              )
            })
          }
        </Paper>
        </ClickAwayListener>
      </Popper>
  )
}

export default withTheme()(withStyles(styles)(HostSelector));