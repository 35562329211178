import axiosCerebrum from "../../../axios-cerebrum";
import { CerebrumLongListLoader, SolrLongListLoader } from "../../../LongListLoader"
import { checkIsDataGov, checkIsDataManager } from "../../../permissionChecker";
import { checkIsDarkMode, palette } from "../../../theme";
import { collectionIds, getDispFields, sendMessage } from "../../../utilities";
import { groupHeaderHeight, nodeHorizontalGap, nodeTypes, nodeWidth } from "../../UI/Lineage/LineageV3/layoutUtils";
import { addDuplicateTag } from "../../UI/Lineage/LineageV3/MapControlUtils";
import { getLineageRelation } from "../../UI/Lineage/utils";

const loadAllSources = () => {
  // consider use solr to load sources
  let promise = new Promise((resolve, reject) => {
    SolrLongListLoader({
      url: '/solr/search/select',
      params: {
        q: '*:*',
        fq: 'object_type_srt:SOURCE AND reference_srt:NO',
      },
      rows: 100,
      onError: () => {
        reject();
      },
      onFinishLoad: ({ data }) => {
        resolve(data);
      }
    })
  })

  return promise;
}

const loadHierarchyInstances = () => {
  let hierarchyCollectionID = collectionIds.sourceLayer;

  let loadPromise = new Promise((resolve, reject) => {
    CerebrumLongListLoader({
      url: '/api/collectioninstances',
      params: {
        collection_id: hierarchyCollectionID,
      },
      per_page: 50,
      onError: () => {
        reject();
      },
      onFinishLoad: ({ data }) => {
        resolve(data);
      }
    })
  })

  return loadPromise;
}

const generateSwimlanes = ({instances, sessionData}) => {
  let swimlanes = [];
  let swimlaneWidth = nodeHorizontalGap;

  let whiteBackground = checkIsDarkMode() ? '#4B5057' : '#F8F8F8';
  let darkBackground = checkIsDarkMode() ? '#60646A' : palette.hovered.main

  instances
    .sort((a, b) => {
      let aPosition = Number.parseInt(a.properties[1])
      let bPosition = Number.parseInt(b.properties[1])
      if(aPosition === bPosition) return a.name.localeCompare(b.name);
      return aPosition - bPosition;
    })
    .forEach((el, index) => {
      let data = {
        type: nodeTypes.swimLaneType,
        id: el.id,
        position: {
          x: swimlaneWidth * index - swimlaneWidth * (instances.length - 1) / 2,
          y: 0,
        },
        data: {
          label: '',
          background: index % 2 === 0 ? darkBackground : whiteBackground,
          width: swimlaneWidth,
          height: 7000,
          // showStaticLabel:true,
          obj: { ...el },
          onClick: () => {
            sendMessage({
              swimlane_click: true,
              id: el.id
            })
          }
        }
      }

      swimlanes.push(data);

      let canEdit = checkIsDataGov({ sessionData }) || checkIsDataManager({ sessionData });

      let editButton = {
        type: nodeTypes.swimLaneControlType,
        id: `${el.id}-edit`,
        position: {
          x: data.position.x + 16,
          y: 4,
        },
        data: {
          label: el.name,
          hideIcon: !canEdit,
          onClick: canEdit ? () => {
            sendMessage({
              swimlane_click: true,
              id: el.id
            })
          } : undefined
        }
      }

      swimlanes.push(editButton);

    })
  return swimlanes;
}

const loadSourceByInstance = ({ instanceId }) => {
  let promise = new Promise((resolve, reject) => {
    CerebrumLongListLoader({
      url: `/api/collectioninstances/${instanceId}/related`,
      params: {
        relationship: 'MEMBERS',
        object_name: 'HOST',
        // object_reference:false,
        // active_flag:true,
        // object_active_flag:true,
      },
      per_page: 50,
      onError: () => {
        reject();
      },
      onFinishLoad: ({ data }) => {
        resolve(data);
      }
    })
  })

  return promise;
}

const constructSourceNodes = async ({ sources, instanceId, nodes, allSourcesSolr }) => {

  let defaultX = nodes.find(n => n.id === instanceId)?.position.x + (nodeHorizontalGap - nodeWidth) / 2;
  let startY = 118;

  let refIds = sources.filter(s => !allSourcesSolr.find(solr => solr.id === s.id)).map(s => s.id);
  let refDetails = {};

  if (refIds.length > 0) {
    let refDetailsPromise = new Promise((resolve, reject) => {
      SolrLongListLoader({
        url: '/solr/search/select',
        params: {
          q: '*',
          fq: `id:(${refIds.join(' OR ')})`,
          rows: 50,
        },
        rows: 50,
        onError: () => {
          reject();
        },
        onFinishLoad: ({ data }) => {
          data.forEach(d => {
            refDetails[d.id] = d;
          })
          resolve();
        }
      })
    })

    await refDetailsPromise;
  }

  sources.forEach(s => {
    let detail = {
      ...s,
      ...(allSourcesSolr?.find(solr => solr.id === s.id) || {}),
      ...(refDetails[s.id] || {})
    };
    let id = instanceId + '-' + s.id
    let n = {
      id,
      type: nodeTypes.groupNodeType,
      swimlaneId: instanceId,
      position: {
        x: defaultX,
        y: startY
      },
      data: {
        label: getDispFields(detail, 'dispTitle') || 'Unknown',
        subTitle: getDispFields(detail, 'dispSubtitle'),
        subTitleStyled: getDispFields(detail, 'dispSubtitle', true),
        // icon: detail.object_type_txt || detail.object_type || 'Unknown',
        // iconColour: detail.reference?palette.primaryText.light:undefined,
        rightIcon: detail.source_type_txt,
        width: nodeWidth,
        height: groupHeaderHeight,
        obj: { ...detail },
        onClick: () => {
          sendMessage({ map_open_detail_drawer: true, id })
        }
      }
    }

    nodes.push(n)
    startY += groupHeaderHeight + 20
  })
}

const onAddEmptySourcePlaceholder = ({ nodes, instanceId, sessionData }) => {

  let canEdit = checkIsDataGov({ sessionData }) || checkIsDataManager({ sessionData });
  if(!canEdit) return;

  let defaultX = nodes.find(n => n.id === instanceId)?.position.x + (nodeHorizontalGap - nodeWidth) / 2;
  let startY = 118;

  let onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    document.getElementById(`${instanceId}-edit`)?.click();
  }

  let n = {
    id: `${instanceId}-empty`,
    type: nodeTypes.customNode,
    position: {
      x: defaultX,
      y: startY
    },
    data: {
      label: <span style={{fontSize: 16}}><span onClick={onClick} style={{cursor:'pointer', textDecoration:'underline', color: palette.hyperLink.main}}>Add a source</span> to this layer</span>,
      width: nodeWidth,
      height: groupHeaderHeight,
    }
  }

  nodes.push(n)
}

const loadSourcesForAllInstances = async ({ instances, nodes, allSourcesSolr, sessionData }) => {
  let allSources = [];
  let promises = instances.map(instance => {
    return loadSourceByInstance({ instanceId: instance.id })
      .then(async sources => {
        if(sources.length === 0){
          onAddEmptySourcePlaceholder({ nodes, instanceId: instance.id, sessionData });
          sendMessage({ map_update_map: true })
          return;
        }
        allSources.push(...sources);
        await constructSourceNodes({ sources, instanceId: instance.id, nodes, allSourcesSolr });
        sendMessage({ map_update_map: true })
      })
  })

  await Promise.all(promises);

  return allSources;
}

const loadLinksForAllSources = ({ allSources, links }) => {
  let promises = [];

  allSources.forEach(source => {
    promises.push(
      axiosCerebrum
        .get(
          `/api/hosts/${source.id}/related`, {
          params: {
            per_page: 100,
            relationship: getLineageRelation('SOURCE', 'downstream').join(','),
            object_reference: false,
            active_flag: true,
            object_active_flag: true,
          }
        }
        )
        .then(response => {
          response.data.items.forEach(t => {
            let l = {
              id: `${source.id}-${t.id}`,
              source: source.id,
              target: t.id,
              type: nodeTypes.edgeType,
              data: {
                lineType: 'solid',
                disableHover: true,
              }
            }
            if (links.find(cl => cl.id === l.id)) return;
            links.push(l);
          })

          sendMessage({ map_update_map: true })
        })
    )
  })

  return Promise.all(promises)

}

export const onReloadSwimlane = ({ swimlane, nodes, links, allSourcesSolr }) => {
  let currentNodes = nodes.filter(n => n.swimlaneId === swimlane.id);

  loadSourceByInstance({ instanceId: swimlane.id })
    .then(async sources => {
      // remove current nodes from swimlane, nodes is a const, can't be redefined, use splice
      currentNodes.forEach(n => {
        let index = nodes.findIndex(node => node.id === n.id);
        if (index >= 0) nodes.splice(index, 1);
      })

      await constructSourceNodes({ sources, instanceId: swimlane.id, nodes, allSourcesSolr });
      sendMessage({ map_update_map: true })

      loadLinksForAllSources({ allSources: [...sources], links })
        .then(() => {
          sendMessage({ map_update_map: true })
        })
    })

}

export const initialiseMap = async ({ nodes, links, setLoading, setAllSourcesSolr, sessionData }) => {
  setLoading(true)

  let hierarchyInstances;

  try {
    hierarchyInstances = await loadHierarchyInstances();

    if (hierarchyInstances.length === 0) {
      setLoading(false)
      return;
    }

    let allSourcesSolr = await loadAllSources();
    let swimlanes = generateSwimlanes({instances: hierarchyInstances, sessionData});

    nodes.push(...swimlanes)

    setLoading(false)
    sendMessage({ map_update_map: true })

    setAllSourcesSolr(allSourcesSolr)

    let allSources = await loadSourcesForAllInstances({ instances: hierarchyInstances, nodes, allSourcesSolr, sessionData });

    await loadLinksForAllSources({ allSources, links });

  } catch (error) {
    console.log(error)
    sendMessage({ map_error: true })
    return;
  }

}

export const getNodeList = ({ nodes, selectedItem }) => {
  let sameObjIdMap = {};

  nodes.forEach(n => {
    if (!n.data?.obj) return;
    if (!sameObjIdMap[n.data.obj.id]) sameObjIdMap[n.data.obj.id] = [];
    sameObjIdMap[n.data.obj.id].push(n);
  })

  Object.keys(sameObjIdMap).forEach(id => {
    if (sameObjIdMap[id].length > 1) {
      sameObjIdMap[id].forEach((node) => {
        addDuplicateTag({ node })
      })
    }
  })

  return nodes.map(n => {
    if (n.id !== selectedItem?.id) return n;
    return { ...n, selected: true }
  })
}

export const getLinkList = ({ links, nodes, selectedItem, viewMode }) => {
  // need logic to handle dup sources 
  let finalLinks = [];
  links.forEach(l => {
    let sources = nodes.filter(n => n.data?.obj?.id === l.source);
    let targets = nodes.filter(n => n.data?.obj?.id === l.target);
    if (sources.length === 0 || targets.length === 0) return;
    sources.forEach(s => {
      targets.forEach(t => {
        let link = { ...l, source: s.id, target: t.id, id: `${s.id}-${t.id}` }
        let shouldFocus = false;
        if (selectedItem) {
          if (s.id === selectedItem.id || t.id === selectedItem.id) {
            if (viewMode === 'upstream') {
              shouldFocus = t.id === selectedItem.id
            }
            else if (viewMode === 'downstream') {
              shouldFocus = s.id === selectedItem.id
            } else {
              shouldFocus = true;
            }
          }
        }
        if (selectedItem) {
          if (shouldFocus) {
            link.selected = true;
            link.data.faded = false;
          }
          else {
            link.data.faded = true;
            link.selected = false;
          }
        } else {
          link.data.faded = false;
          link.selected = false;
        }

        finalLinks.push(link)
      })
    })
  })
  return finalLinks;
}