import React, { useReducer, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/AccessKey/Body/Body';
import 'url-search-params-polyfill';
import { setInitialState, } from '../../utilities'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import { addHistory } from '../../HistoryManager';
import { adminPageInfo } from '../NavBar/utils';

const styles = theme => ({

});

const initialState = {
  tabState:0,
  createKeyStep:0,
  source:'none',
  keyName:'',
  selectedCapabilities:[]
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return{
        ...state,
        tabState:action.tabState
      }
    case 'set_key_list':
      return {
        ...state,
        keyList:action.keyList,
        keyListLoading:action.keyListLoading,
        keyListError:action.keyListError
      }
    //////////////////////////
    // below for create key
    case 'set_create_key_step':
      return {
        ...state,
        createKeyStep:action.createKeyStep
      }
    case 'set_source_list':
      return {
        ...state,
        sourceList:action.sourceList
      }
    case 'set_source':
      return {
        ...state,
        source:action.source
      }
    case 'set_key_name':
      return {
        ...state,
        keyName:action.keyName
      }
    case 'set_capabilities':
      return {
        ...state,
        capabilities:action.capabilities
      }
    case 'set_selected_capabilities':
      return {
        ...state,
        selectedCapabilities:action.selectedCapabilities
      }
    case 'set_created_key':
      return {
        ...state,
        createdKey:action.createdKey
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

const AccessKey = props => {
  const {
    history,
    sessionData,
    pageCache,
    storePageCache
  } = props;

  const [state, dispatch] = useReducer(reducer,setInitialState(pageCache,initialState));

  useEffect(()=>{
    addHistory({
      url:window.location.pathname, 
      iconLabel:adminPageInfo.accessKeys.icon, 
      title: adminPageInfo.accessKeys.name, 
      subTitle:adminPageInfo.accessKeys.description,
      type:'application',
    })
  },[])

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  // eslint-disable-next-line
  },[state])

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])

  let buttons = [];


  let title,description;
  if(state.tabState===0){
    title = 'Access keys'
    description = 'Access keys are used to authenticate 3rd party application requests to K. \nKeep your keys in a secure location and replace them often.'
  }
  if(state.tabState===1){
    title = "Create new Access key"
    description = 'Create a new Access key for your applications to integrate with K'
  }

  if(buttons.length===0){
    buttons.push(<div style={{height:48,margin:'8px 8px 8px 16px'}}></div>)
  }
  
  return (
    <ProfileLayout
      header={
        <div>
          <ProfileHeader
            title={title}
            subtitle={' '}
            description={description}
            label='access_key'
            buttons={buttons}
          />
        </div>
      }
      body={
        <Body
          history={history}
          state={state}
          dispatch={dispatch}
          sessionData={sessionData}
        />
      }
    />
  )
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccessKey));