import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    isolation: 'isolate',
    overflow: 'auto',
    minHeight: '100vh',
    display:'flex',
    justifyContent:'center'
  },
  left: {
    flex:'0 0 280px',
    minWidth:280,
    marginTop:70,
    marginLeft:64,
  },
  right: {
    flex:'1 1 800px',
    minWidth: 400,
    marginRight:64,
    marginLeft:40,
    maxWidth:900,
    // marginLeft: '20rem',
    // position:'sticky'
    //width: '60%',
  },
  header: {
    marginTop: '70px !important',
    width: '100%',
    display: 'inline-block',
    position: 'sticky',
    paddingTop: '1rem',
    backgroundColor: theme.palette.background.main,
    top: 0,
    zIndex: 10,
  },
  body: {
  },
  // buttons: {
  //   marginTop: '1rem',
  //   marginBottom: '-3.5rem',
  //   marginLeft: '35%'
  // }
  buttons: {
    width: '50%',
    display: 'inline-block',
    position: 'sticky',
    paddingTop: '1rem',
    paddingLeft: '50%',
    backgroundColor: '#FFF',
    top: 0,
    zIndex: 10,
  },
  '@media (max-width: 760px)': {
    left: {
      display:'none'
    }
  }
});

const HorizontalLayout = props => {

  const {
    classes,
    header,
    children,
    noPadding,
    left,
    buttons,
  } = props;
  return (
    <div className={classes.root}>
      <div style={{display:'flex',width:'100%',maxWidth:1920,justifyContent:'center'}}>
        <div className={classes.left}>
          {left}
        </div>
        <div className={classes.right}>
          {buttons?<div className={classes.buttons}>{buttons}</div>:null}
          {header?
            <div className={classes.header}>
              {header}
            </div>:null}
          <div className={classes.body} style={{ padding: noPadding ? 0 : undefined }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

HorizontalLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.any,
  children: PropTypes.any,
  noPadding: PropTypes.bool,
  left: PropTypes.any,
  buttons: PropTypes.array
}

export default withStyles(styles)(HorizontalLayout);