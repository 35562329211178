import React from 'react';
import { withTheme, Typography, withStyles, IconButton, Button, CircularProgress} from '@material-ui/core';
import { getIconComponent, toTitleCase } from '../../../utilities';
import WarningIcon from '@material-ui/icons/Warning';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem'

const styles = theme => ({
  root: {
    
  },
  bannerText: {
    fontSize: 13.75,
    flexGrow:0,
    color:theme.palette.primaryText.main,
  },
  banner: {
    // height: '56px',
    flexGrow:1,
    backgroundColor: theme.palette.chip.main,
    border:`1px solid ${theme.palette.border.main}`,
    width:'max-content',
    minHeight:32,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    padding:'0px 12px',
    borderRadius:3,
    marginBottom:24
  },
  header:{
    fontSize:16,
    color:theme.palette.primary.main,
    marginBottom:16
  },
  chip:{
    padding:'8px 16px',
    display:'flex',
    overflow:'hidden',
    alignItems:'center',
    background:theme.palette.primary.dark,
    width:'max-content',
    maxWidth:"100%",
    marginBottom:24,
    borderRadius:24
  },
  chipText:{
    color:theme.palette.background.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    fontSize:13.75,
    marginLeft:16,
    marginRight:20,
  },
  mergeButton:{
    background:theme.palette.success.main,
    color:'#000',
    marginTop:24,
    marginRight:40,
    width:120,
    flexShrink:0,
    '&:hover':{
      background:theme.palette.success.main,
    }
  },
  disabledButton:{
    ...theme.components.disabledButton,
    background:'rgba(0, 0, 0, 0.12)'
  }
})

const ConfirmMerge = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    onMerge,
    merging
  } = props;

  const onRemoveItem = item => {
    dispatch({
      type:'set_merged_items',
      mergedItems:state.mergedItems.filter(m=>m.id!==item.id)
    })
  }

  return (
    <div className={classes.root}>  
      <div className={classes.banner}>
        <WarningIcon style={{marginRight: 12,color: theme.palette.secondary.main,fontSize: 24}}/>
        <Typography className={classes.bannerText}>
          Merging {toTitleCase(state.mergeTargetType.replace(/_/g,' '))}(s) will link their historical usage. These links can be reversed but history will not be fixed. Check the {toTitleCase(state.mergeTargetType.replace(/_/g,' '))}(s) details before merging.
        </Typography>
      </div>
      <Typography className={classes.header}>Target</Typography>
      <div className={classes.chip} >
        {getIconComponent({label:state.mergeTargetType,size:24,colour:theme.palette.background.main})}
        <Typography className={classes.chipText}>{state.mergeTarget.name} ({[state.mergeTarget.source.name,...state.mergeTarget.reference_sources.map(el=>el.reference_source.name)].join(', ')})</Typography>
      </div>
      <Typography className={classes.header}>{toTitleCase(state.mergeTargetType.replace(/_/g,' '))}(s) to merge</Typography>
      {
        (!state.mergedItems || state.mergedItems.length===0) && 
        <Typography style={{color:theme.palette.primaryText.main}}>No items selected, please go to previous step and select items to merge</Typography>
      }
      {
        state.mergedItems && state.mergedItems.map(el=>(
          <SimpleResultItem
            item={el}
            label={el.object.name}
            title={el.user.username+(el.user.username!==el.name?` (${el.name})`:'')}
            subTitle={el.user.account_type}
            footer={[el.source.name,...el.reference_sources.map(el=>el.reference_source.name)].join(', ')}
            hideRight={true}
            showUnderline
            tailObject={
              <IconButton onClick={()=>onRemoveItem(el)}>
                {getIconComponent({label:'clear',size:20,colour:theme.palette.primaryText.light})}
              </IconButton>
            }
          />
        ))
      }
      {
        merging ?
        <CircularProgress style={{marginTop:24}} color='secondary'/>:
        <Button onClick={onMerge} className={classes.mergeButton} classes={{disabled:classes.disabledButton}} disabled={!state.mergedItems || state.mergedItems.length===0}>
          MERGE
        </Button>
      }
      
    </div>
  )
}

export default withTheme()(withStyles(styles)(ConfirmMerge));