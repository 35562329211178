import axiosSolr from "../../../axios-solr"
import { sendMessage } from "../../../utilities"
import { defaultColumnMap } from "../../BasicSearch/MainSearch/Utils/Utils"

export const defaultFilter = "-source_id_srt:998 usage_srt:* AND object_type_srt:(TABLE OR COLUMN OR MACRO OR PROCEDURE OR REPORT OR SHEET OR DATASET OR DATASET_TABLE OR DATASET_FIELD)"

const getRanges = () => {
  let ranges = [];
  try {
    ranges = localStorage.getItem('lineageComplexityRange').split(',');
    if (ranges.length !== 5) throw new Error('Invalid range');
  } catch (error) {
    ranges = ['none', '1', '2-5', '6-10', '10+']
    localStorage.removeItem('lineageComplexityRange');
  }
  return ranges;
}


const generateCombinedRangeFacet = () => {
  let rangeFacet = {};
  let searchParamMap = {};
  const ranges = getRanges();
  const getValueByText = rangeTxt => {
    if (rangeTxt === 'none') return '0';
    if (rangeTxt.includes('+')) return `[${Number(rangeTxt.split('+')[0]) + 1} TO *]`;
    if (rangeTxt.includes('-')) return `[${rangeTxt.split('-')[0]} TO ${rangeTxt.split('-')[1]}]`;
    return Number(rangeTxt).valueOf();
  }

  const getRangeSyntax = (rangeTxt) => {
    if (rangeTxt === 'none') return 'between,0,0';
    if (rangeTxt.includes('+')) return `greater,${rangeTxt.split('+')[0]}`;
    if (rangeTxt.includes('-')) return `between,${rangeTxt.split('-')[0]},${rangeTxt.split('-')[1]}`;
    return `between,${rangeTxt},${rangeTxt}`;
  }

  ranges.forEach(upRange => {
    ranges.forEach(downRange => {
      let rangeSyntax;
      let searchParamSyntax;
      if (upRange === 'none' && downRange === 'none') {
        rangeSyntax = `upstream_count:0 AND downstream_count:0`
        searchParamSyntax = `upstream_count:between,0,0 AND downstream_count:between,0,0`
      }
      else if (upRange === 'none') {
        rangeSyntax = `upstream_count:0 AND downstream_count:${getValueByText(downRange)}`
        searchParamSyntax = `upstream_count:between,0,0 AND downstream_count:${getRangeSyntax(downRange)}`
      }
      else if (downRange === 'none') {
        rangeSyntax = `upstream_count:${getValueByText(upRange)} AND downstream_count:0`
        searchParamSyntax = `upstream_count:${getRangeSyntax(upRange)} AND downstream_count:between,0,0`
      }
      else {
        rangeSyntax = `upstream_count:${getValueByText(upRange)} AND downstream_count:${getValueByText(downRange)}`
        searchParamSyntax = `upstream_count:${getRangeSyntax(upRange)} AND downstream_count:${getRangeSyntax(downRange)}`
      }
      rangeFacet[`${upRange} - ${downRange}`] = {
        type: 'query',
        q: rangeSyntax
      }
      searchParamMap[`${upRange} - ${downRange}`] = `${searchParamSyntax}`
    })
  })
  return { rangeFacet, searchParamMap }
}

const lineageLoader = ({
  state,
  dispatch,
  source,
  usage,
  object,
}) => {
  let { rangeFacet, searchParamMap } = generateCombinedRangeFacet()
  const ranges = getRanges();

  let chartData = {};

  axiosSolr
    .get(
      '/solr/search/select', {
      params: {
        q: "*",
        fq: `source_srt:"${source}" AND usage_srt:"${usage}" AND object_type_srt:${object}`,
        rows: 0,
        'json.facet': {
          ...rangeFacet
        }
      }
    })
    .then(response => {
      // format data as below
      // yLabels = ['no upstream', '1 upstream', '2-5 upstream', '6-10 upstream', '10+ upstream']
      // xLabels = ['no downstream', '1 downstream', '2-5 downstream', '6-10 downstream', '10+ downstream']
      // data = [ { xLabel: 'no downstream', yLabel: 'no upstream', value: 100, clickFn: () => {} }, ...]

      let yLabels = ranges.map(r => r === 'none' ? 'no upstream' : `${r} upstream`)
      let xLabels = ranges.map(r => r === 'none' ? 'no downstream' : `${r} downstream`)
      let data = []
      ranges.forEach(upRange => {
        ranges.forEach(downRange => {
          let count = response.data.facets[`${upRange} - ${downRange}`].count
          data.push({
            xLabel: downRange === 'none' ? 'no downstream' : `${downRange} downstream`,
            yLabel: upRange === 'none' ? 'no upstream' : `${upRange} upstream`,
            value: count,
            clickFn: () => {
              sendMessage({
                modalQuery: "*",
                modalObject: object,
                modalFilter: `source_srt:${source} AND usage_srt:${usage} AND object_type_srt:${object} AND ${searchParamMap[`${upRange} - ${downRange}`]}`,
                modalColumn: ['upstream_count', 'downstream_count', ...(defaultColumnMap[object] || [])].join(','),
              })
            }
          })
        })
      })

      let total = Math.max(response.data.response.numFound, 100);
      let avg = Math.floor(total / 25);
      let colourRange = [
        { min: 0, max: 0, colour: '#6DBAE7', text: 'none' },
        { min: 1, max: Math.floor(avg * 0.5), colour: '#01A100', text: 'low' },
        { min: Math.floor(avg * 0.5) + 1, max: Math.floor(avg * 1.5), colour: '#FFBF2B', text: 'medium' },
        { min: Math.floor(avg * 1.5) + 1, max: Math.floor(avg * 2), colour: '#F5956A', text: 'high' },
        { min: Math.floor(avg * 2) + 1, max: Infinity, colour: '#FF3C3C', text: 'extreme' }
      ]

      chartData['lineage_complexity'] = {
        yLabels,
        xLabels,
        data,
        colourRange
      }

      dispatch({ type: 'set_graph_data', graphData: chartData })
    })
}

const generateRangeFacet = (field) => {
  let rangeFacet = {};
  let searchParamMap = {};
  const ranges = getRanges();
  ranges.forEach(range => {
    let rangeSyntax;
    let searchParamSyntax;
    if (range.length === 1) {
      rangeSyntax = `${range}`
      searchParamSyntax = `between,${range},${range}`
    }
    else if (range.includes('-')) {
      rangeSyntax = `[${range.split('-')[0]} TO ${range.split('-')[1]}]`
      searchParamSyntax = `between,${range.split('-')[0]},${range.split('-')[1]}`
    }
    else if (range === 'none') {
      rangeSyntax = `0`
      searchParamSyntax = `between,0,0`
    }
    else if (range.includes('+')) {
      let number = Number(range.split('+')[0]) + 1
      rangeSyntax = `[${number} TO *]`
      searchParamSyntax = `greater,${range.split('+')[0]}`
    }
    rangeFacet[range] = {
      type: 'query',
      q: `${field}:${rangeSyntax}`
    }
    searchParamMap[range] = `${field}:${searchParamSyntax}`
  })
  return { rangeFacet, searchParamMap }
}

export const upstreamLineageLoader = ({
  state,
  dispatch,
  source,
  usage,
  object,
}) => {

  let { rangeFacet, searchParamMap } = generateRangeFacet('upstream_count')
  const ranges = getRanges();
  let chartData = { recommendations: [] };

  let byLineageLoad = axiosSolr
    .get(
      '/solr/search/select', {
      params: {
        q: "*",
        fq: `source_srt:"${source}" AND usage_srt:"${usage}" AND object_type_srt:${object}`,
        rows: 0,
        'json.facet': {
          ...rangeFacet
        }
      }
    })
    .then(response => {
      let labels = [...ranges];
      let data = ranges.map(r => response.data.facets[r].count);
      let clickFns = ranges.map(r => () => {
        sendMessage({
          modalQuery: "*",
          modalObject: object,
          modalFilter: `source_srt:${source} AND usage_srt:${usage} AND object_type_srt:${object} AND ${searchParamMap[r]}`,
          modalColumn: ['upstream_count', ...(defaultColumnMap[object] || [])].join(','),
        })
      })

      chartData['by_lineage_count'] = {
        labels,
        series: [{
          name: 'count',
          data,
          clickFns
        }]
      }
    })

  // let unusedRecommendationLoad = axiosSolr
  //   .get(
  //     '/solr/search/select', {
  //     params: {
  //       q: "*",
  //       fq: `source_srt:"${source}" AND -usage_srt:[* TO *] AND object_type_srt:${object} AND upstream_count:0`,
  //       rows: 0
  //     }
  //   })
  //   .then(response => {
  //     if (response.data.response.numFound === 0) return;
  //     chartData.recommendations.push({
  //       title: `Review unused ${titleCaseObjectName(object)}s with no upstream lineage`,
  //       countTxt: `${formatNumber(response.data.response.numFound)} ${titleCaseObjectName(object)}s`,
  //       onClick: () => {
  //         sendMessage({
  //           modalQuery: "*",
  //           modalObject: object,
  //           modalFilter: `source_srt:${source} AND usage_srt:NO USAGE AND object_type_srt:${object} AND upstream_count:between,0,0`,
  //           modalColumn: ['upstream_count', ...(defaultColumnMap[object]||[])].join(','),
  //         })
  //       }
  //     })
  //   })

  // let dailyRecommendationLoad = axiosSolr
  //   .get(
  //     '/solr/search/select', {
  //     params: {
  //       q: "*",
  //       fq: `source_srt:"${source}" AND usage_srt:"DAILY" AND object_type_srt:${object} AND upstream_count:[11 TO *]`,
  //       rows: 0
  //     }
  //   })
  //   .then(response => {
  //     if (response.data.response.numFound === 0) return;
  //     chartData.recommendations.push({
  //       title: `Review ${titleCaseObjectName(object)}s used daily with 10+ upstream assets`,
  //       countTxt: `${formatNumber(response.data.response.numFound)} ${titleCaseObjectName(object)}s`,
  //       onClick: () => {
  //         sendMessage({
  //           modalQuery: "*",
  //           modalObject: object,
  //           modalFilter: `source_srt:${source} AND usage_srt:DAILY AND object_type_srt:${object} AND upstream_count:greater,10`,
  //           modalColumn: ['upstream_count', ...(defaultColumnMap[object]||[])].join(','),
  //         })
  //       }
  //     })
  //   })


  Promise
    .all([byLineageLoad])
    .then(() => {
      dispatch({ type: 'set_graph_data', graphData: chartData })
    })
    .catch(error => {
      console.log(error)
      dispatch({ type: 'set_graph_data', graphDataError: false })
    })
}

const downstreamLineageLoader = ({
  state,
  dispatch,
  source,
  usage,
  object,
}) => {
  let { rangeFacet, searchParamMap } = generateRangeFacet('downstream_count')
  const ranges = getRanges();
  let chartData = { recommendations: [] };

  let byLineageLoad = axiosSolr
    .get(
      '/solr/search/select', {
      params: {
        q: "*",
        fq: `source_srt:"${source}" AND usage_srt:"${usage}" AND object_type_srt:${object}`,
        rows: 0,
        'json.facet': {
          ...rangeFacet
        }
      }
    })
    .then(response => {
      let labels = [...ranges];
      let data = ranges.map(r => response.data.facets[r].count);
      let clickFns = ranges.map(r => () => {
        sendMessage({
          modalQuery: "*",
          modalObject: object,
          modalFilter: `source_srt:${source} AND usage_srt:${usage} AND object_type_srt:${object} AND ${searchParamMap[r]}`,
          modalColumn: ['downstream_count', ...(defaultColumnMap[object] || [])].join(','),
        })
      })

      chartData['by_lineage_count'] = {
        labels,
        series: [{
          name: 'count',
          data,
          clickFns
        }]
      }
    })

  // let unusedRecommendationLoad = axiosSolr
  //   .get(
  //     '/solr/search/select', {
  //     params: {
  //       q: "*",
  //       fq: `source_srt:"${source}" AND -usage_srt:[* TO *] AND object_type_srt:${object} AND downstream_count:0`,
  //       rows: 0
  //     }
  //   })
  //   .then(response => {
  //     if (response.data.response.numFound === 0) return;
  //     chartData.recommendations.push({
  //       title: `Review unused ${titleCaseObjectName(object)}s with no downstream lineage`,
  //       countTxt: `${formatNumber(response.data.response.numFound)} ${titleCaseObjectName(object)}s`,
  //       onClick: () => {
  //         sendMessage({
  //           modalQuery: "*",
  //           modalObject: object,
  //           modalFilter: `source_srt:${source} AND usage_srt:NO USAGE AND object_type_srt:${object} AND downstream_count:between,0,0`,
  //           modalColumn: ['downstream_count', ...(defaultColumnMap[object]||[])].join(','),
  //         })
  //       }
  //     })
  //   })

  // let dailyRecommendationLoad = axiosSolr
  //   .get(
  //     '/solr/search/select', {
  //     params: {
  //       q: "*",
  //       fq: `source_srt:"${source}" AND usage_srt:"DAILY" AND object_type_srt:${object} AND downstream_count:[11 TO *]`,
  //       rows: 0
  //     }
  //   })
  //   .then(response => {
  //     if (response.data.response.numFound === 0) return;
  //     chartData.recommendations.push({
  //       title: `Review ${titleCaseObjectName(object)}s used daily with 10+ downstream assets`,
  //       countTxt: `${formatNumber(response.data.response.numFound)} ${titleCaseObjectName(object)}s`,
  //       onClick: () => {
  //         sendMessage({
  //           modalQuery: "*",
  //           modalObject: object,
  //           modalFilter: `source_srt:${source} AND usage_srt:DAILY AND object_type_srt:${object} AND downstream_count:greater,10`,
  //           modalColumn: ['downstream_count', ...(defaultColumnMap[object]||[])].join(','),
  //         })
  //       }
  //     })
  //   })


  Promise
    .all([byLineageLoad])
    .then(() => {
      dispatch({ type: 'set_graph_data', graphData: chartData })
    })
    .catch(error => {
      console.log(error)
      dispatch({ type: 'set_graph_data', graphDataError: false })
    })

}

export const graphLoader = ({
  state,
  dispatch,
  type,
  source,
  usage,
  object,
}) => {
  dispatch({ type: 'set_graph_data', graphDataLoading: true })

  if (type === 'LINEAGE COMPLEXITY') {
    lineageLoader({ state, dispatch, source, usage, object })
  }
  else if (type === 'UPSTREAM LINEAGE') {
    upstreamLineageLoader({ state, dispatch, source, usage, object })
  }
  else if (type === 'DOWNSTREAM LINEAGE') {
    downstreamLineageLoader({ state, dispatch, source, usage, object })
  }
}