import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
})

function Details(props) {

  const {
    classes,
    state,
    fetchList,
  } = props;

  const description = (state.basicData.description === undefined || null) ? null : state.basicData.description;

  return (
    <div className={classes.root}>
      <UpdateInputCerebrum
        url={`/api/tags/${state.basicData.id}`}
        label="tag"
        initialValue={description||''}
        property="description"
        placeholder="Describe the tag"
        multiline
        fetchList={fetchList}
        collection="tags"
        subtitle='Describe the meaning and purpose of this tag'
        displayPage={`tagProfile`}
        data={state.basicData}
        enableMarkDown={true}
        enableWidget={true}
        state={state}
      />

    </div>
  )
}


Details.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default withStyles(styles)(Details);