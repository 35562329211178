import { toTitleCase } from "../../../utilities";

export const mapRelationshipName = (relation,) => {
  switch(relation){
    case 'SOURCE_OF':
      return 'Downstream';
    case 'SOURCE_FROM':
      return 'Upstream';
    case 'MEMBERS':
      return 'Child';
    case 'MEMBER_OF':
      return 'Parent'
    // case 'REPLACED_BY':
    //   return `Replaced on ${moment(object.replace_date).format('YYYY-MM-DD')}`
    default:
      return toTitleCase(relation.replace(/_/g,' '))
  }
}
