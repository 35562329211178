import React, { useEffect, useRef } from 'react';
import { withTheme, withStyles, Typography, } from '@material-ui/core';
import PropTypes from 'prop-types';
import { tileStyles } from './utils/styles';
import theme from '../../../../theme';
import { onGoToLink } from '../../../../utilities';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
})

function LinkManager(props) {

  const {
    classes,
    id,
    object,
    onAddTileData,
    tileData,
  } = props;

  const popperRef = useRef()

  const loadAnswerTile = async (target = object) => {
    if(target.properties && target.properties[3] && target.properties[3].trim()!==''){
      onAddTileData(
        {
          "disp_body":{value:target.properties[3]},
          "disp_title":"LINK",
          "disp_type":"text",
          "id":id
        }
      )
    }else{
      onAddTileData(
        {
          "disp_body":{value:''},
          "disp_title":"LINK",
          "disp_type":"text",
          "id":id
        }
      )
    }
  }

  useEffect(()=>{
    if(!object)return;
    loadAnswerTile()
  // eslint-disable-next-line
  },[object])


  if(!tileData)return <></>

  return (
    <div data-test-id="link-tile" className={classes.root}>
      <div style={{width:'100%'}}>
        <div ref={popperRef} style={{overflow:'hidden'}} className={classes.chipContainer} >
          <Typography 
            style={{fontSize:13.75,wordBreak:'break-word',background:'transparent',marginBottom:8,color:theme.palette.hyperLink.main,cursor:'pointer',textDecoration:'underline'}}
            onClick={()=>{
              onGoToLink(tileData.disp_body.value)
            }}
          >
            {tileData.disp_body.value || 'No link provided'}
          </Typography>
        </div>
      </div>
    </div>
  )
}

LinkManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(LinkManager));