import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Button, Tooltip } from '@material-ui/core';
import axiosCerebrum from '../../../../axios-cerebrum';
import axiosSolr from '../../../../axios-solr';
import SolrList from '../../SolrList/SolrList';
import {getIconComponent, getLabelPlural, mapObjectName, toTitleCase,} from '../../../../utilities'
import { globalListenerRef } from '../../../../GlobalListenerRef';

const styles = theme => ({
  sectionHeader:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primaryText.main,
  }
})


function ListLineage(props) {

  const {
    classes,
    theme,
    history,
    state,
    dispatch,
    direction,
    upstreamObjects,
    downstreamObjects,
    upstreamRelations,
    downstreamRelations,
  } = props;

  const scrollRef = useRef()
  const isDestroyed = useRef(false)
  const [loadingMore, setLoadingMore] = useState(false)

  const getShownObjectTypesAndRelations = (direction) => {
    const getRelationOrder = relation => {
      switch(relation){
        case 'REPLACES':
        case 'REPLACED_BY':
          return 1
        case 'SOURCE_OF':
        case 'SOURCE_FROM':
          return 2
        default:
          return 3
      }
    }

    if(direction==='upstream'){
      return {objects:upstreamObjects, relations:[...upstreamRelations,'REPLACES'].sort((a,b)=>getRelationOrder(a)-getRelationOrder(b))}
    }else{
      return {objects:downstreamObjects, relations:[...downstreamRelations,'REPLACED_BY'].sort((a,b)=>getRelationOrder(a)-getRelationOrder(b))}
    }
  }

  const loadItemByRelation = async ({page=1, per_page=5, search=state.lineageData.listSearch || '', relation}) => {
    let label = state.basicData.object.name
    let cerebrumResp;
    await axiosCerebrum
      .get(
        `/api/${getLabelPlural(label)}/${state.basicData.id}/related`,
        {params:{
          relationship:relation,
          per_page: per_page,
          object_name:getShownObjectTypesAndRelations(direction).objects.join(','),
          'search.name':search,
          active_flag:true,
          object_active_flag:true,
          page,
        }}
      )
      .then(response=>{
        cerebrumResp = response 
      })
    if(cerebrumResp.data.total===0){
      return cerebrumResp.data;
    }
    let solrResp;
    await axiosSolr
      .get(
        '/solr/search/select',
        {params:{
          q:"*",
          fq:`id:(${cerebrumResp.data.items.map(el=>el.id).join(' OR ')})`,
          rows:cerebrumResp.data.items.length
        }}
      )
      .then(response=>{
        solrResp = response;
      })
    return {
      ...cerebrumResp.data,
      items:cerebrumResp.data.items.map(i=>({
        ...i,
        ...(solrResp.data.response.docs.find(d=>d.id===i.id)||{})
      }))
    }
  }

  const initialiseList = async ({search=state.lineageData.listSearch||''}) => {
    if(isDestroyed.current)return;
    dispatch({
      type:'set_lineage_data',
      lineageData:{
        ...state.lineageData,
        listLoading:true,
        listError:false,
        listData:undefined
      }
    })
    let relations = getShownObjectTypesAndRelations(direction).relations;
    let listObj = {};
    let isError;
    setLoadingMore(true)
    for(let i=0; i<relations.length; i++){
      if(isDestroyed.current){ break}
      if(isError)break;
      try{
        listObj[relations[i]] = await loadItemByRelation({relation:relations[i],search})
        listObj[relations[i]].shown_page = 1;
      }catch(error){
        console.log(error)
        isError = true
      }
    }
    setLoadingMore(false)
    if(isDestroyed.current){
      dispatch({
        type:'set_lineage_data',
        lineageData:{
          ...state.lineageData,
          listLoading:false,
          listError:false,
          listData:undefined
        }
      })
      return;
    }
    if(isError){
      dispatch({
        type:'set_lineage_data',
        lineageData:{
          ...state.lineageData,
          listLoading:false,
          listError:true,
          listData:undefined
        }
      })
    }
    else{
      dispatch({
        type:'set_lineage_data',
        lineageData:{
          ...state.lineageData,
          listLoading:false,
          listError:false,
          listData:listObj
        }
      })
    }
   
  }

  
  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.upstreamAdded || msg.data.upstreamDeleted || msg.data.downstreamAdded || msg.data.downstreamDeleted || msg.data.refreshLineage  ){
        initialiseList({})
      }
    }
    window.removeEventListener('message',globalListenerRef.lineageMsgListener);
    globalListenerRef.lineageMsgListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.lineageMsgListener);
    return (()=>{window.removeEventListener('message',globalListenerRef.lineageMsgListener);})
   // eslint-disable-next-line
  },[JSON.stringify(upstreamObjects),JSON.stringify(downstreamObjects)])

  useEffect(()=>{
    if(!state.lineageData.listData){
      // loadItems({page:1})
      initialiseList({})
    }
  // eslint-disable-next-line
  },[])

    
  useEffect(()=>{
    return ()=>isDestroyed.current = true
  },[])


  useEffect(()=>{
    if(state.lineageData.listData){
      initialiseList({})
    }
  // eslint-disable-next-line
  },[direction, state.lineageData.listSearch , JSON.stringify(upstreamObjects), JSON.stringify(downstreamObjects)])


  const onSeeMore = async r => {
    if(state.lineageData.listData[r].shown_page<state.lineageData.listData[r].page){
      dispatch({
        type:'set_lineage_data',
        lineageData:{
          ...state.lineageData,
          listLoading:false,
          listError:false,
          listData:{
            ...state.lineageData.listData,
            [r]:{
              ...state.lineageData.listData[r],
              shown_page:state.lineageData.listData[r].shown_page+1
            }
          }
        }
      })
    }else{
      setLoadingMore(true)
      try{
        let data = await loadItemByRelation({page:state.lineageData.listData[r].shown_page+1,relation:r});
        dispatch({
          type:'set_lineage_data',
          lineageData:{
            ...state.lineageData,
            listLoading:false,
            listError:false,
            listData:{
              ...state.lineageData.listData,
              [r]:{
                ...data,
                items:[...state.lineageData.listData[r].items,...data.items],
                shown_page:state.lineageData.listData[r].shown_page+1
              }
            }
          }
        })
      }catch(error){
        console.log(error)
      }
      setLoadingMore(false)
    }
  }

  const onSeeLess = r => {
    dispatch({
      type:'set_lineage_data',
      lineageData:{
        ...state.lineageData,
        listLoading:false,
        listError:false,
        listData:{
          ...state.lineageData.listData,
          [r]:{
            ...state.lineageData.listData[r],
            shown_page:1
          }
        }
      }
    })
  }

  const generateLinkageTitle = str => {
    switch(str){
      case 'K_REFERENCES':
      case 'REFERENCES':
      case 'K_REFERENCED_BY':
      case 'REFERENCED_BY':
        return 'DIRECT LINKAGE';
      case 'REFERENCES_SHORTCUT':
      case 'REFERENCED_BY_SHORTCUT':
        return 'INDIRECT LINKAGE'
      case 'SOURCE_FROM':
        return 'Manually added upstream linkage'.toUpperCase();
      case 'SOURCE_OF':
        return 'Manually added downstream linkage'.toUpperCase();  
      default:
        return  str.replace(/_/g,' ')
    }
  }

  const generateTooltip = str => {
    let tooltip;
    let label = toTitleCase(mapObjectName(state.basicData.object.name).replace(/_/g,' '));
    if(['REFERENCES','REFERENCED_BY','K_REFERENCES','K_REFERENCED_BY'].includes(str)){
      tooltip = `Direct linkage - Items directly linked to this ${label}`
    } 
    if(['REFERENCES_SHORTCUT','REFERENCED_BY_SHORTCUT'].includes(str)){
      tooltip = `Indirect linkage - Items linked to this ${label} via code or pipeline`
    } 
    if(str==='SOURCE_FROM'){
      tooltip = `Manually added linkage - Items linked by manually by users`;
    }
    if(str==='SOURCE_OF'){
      tooltip = `Manually added linkage - Items linked by manually by users`;
    }
    if(tooltip){
      return (
        <Tooltip title={tooltip}>
          <div style={{marginLeft:8,height:16,width:16}}>
            {getIconComponent({label:"info",size:16,colour:theme.palette.primaryText.main})}
          </div>
        </Tooltip>
      )
    }
  }

  return (
    <div className={classes.root}>
      {
        state.lineageData.listData && 
        getShownObjectTypesAndRelations(direction).relations.map(r=>(
          state.lineageData.listData[r] && state.lineageData.listData[r].total>0?
          <div style={{marginBottom:24}}>
            <div style={{display:'flex',alignItems:'center',marginBottom:4}}>
              <Typography className={classes.sectionHeader}>{generateLinkageTitle(r)}</Typography>
              {generateTooltip(r)}
            </div>
            <SolrList
              history={history}
              list={state.lineageData.listData[r].items.slice(0, 5*state.lineageData.listData[r].shown_page)}
            />
            {
              state.lineageData.listData[r].total>3 && 
              <div style={{display:'flex',marginTop:4}}>
                {
                  state.lineageData.listData[r].shown_page<state.lineageData.listData[r].pages && 
                  <Button disabled={loadingMore} style={{marginRight:16}} color='primary' onClick={()=>onSeeMore(r)}>
                    SEE MORE
                  </Button>
                }
                {
                  state.lineageData.listData[r].shown_page>1 && 
                  <Button color='primary' onClick={()=>onSeeLess(r)}>
                    SEE LESS
                  </Button>
                }
              </div>
            }
          </div>
          :
          <></>
        ))
      }
      {
        state.lineageData.listData && getShownObjectTypesAndRelations(direction).relations.every(r=>!state.lineageData.listData[r] || state.lineageData.listData[r].total===0) && 
        <Typography style={{color:theme.palette.primaryText.main}}>No item found</Typography>
      }
      {
        state.lineageData.listError && 
        <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading list</Typography>
      }
      <div ref={scrollRef} style={{display:'flex',justifyContent:'center',marginTop:8}}>
        {
          state.lineageData.listLoading && 
          <CircularProgress color='secondary'/>
        }
      </div>
    </div>
  )

}

export default withTheme()(withStyles(styles)(ListLineage));