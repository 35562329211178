import React, { } from 'react';
import { withTheme, withStyles, Button, InputBase, Typography, CircularProgress} from '@material-ui/core';
import { validateUrl } from '../../../utilities';


const styles = theme => ({
  inputBase:{
    // display:'block',
    height:48,
    boxSizing:'border-box',
    width:'100%',
    ...theme.components.inputBase
  },
  error:{
    border:`1px solid ${theme.palette.error.main}`
  }
})

const AddLink = props => {

  const {
    classes,
    theme,
    onSave,
    link, 
    setLink,
    loading
  } = props;


  
  return (
    <div className={classes.root}>
      <InputBase
        value={link}
        onChange={event=>setLink(event.target.value)}
        placeholder='Enter a valid link (must begin with http:// or https://)'
        className={classes.inputBase + (link.trim()==='' || validateUrl(link)?'':' '+classes.error)}
        data-test-id='link-input'
      />
      <Typography style={{fontSize:12,marginLeft:16,marginBottom:20,color:theme.palette.error.main,whiteSpace:'pre-wrap'}}>
        {link.trim()==='' || validateUrl(link)?' ':'Must begin with http:// or https://'}
      </Typography>
      {
        loading?
        <CircularProgress color='secondary' style={{width:24,height:24}}/>
        :
        <Button onClick={()=>onSave()} data-test-id='save-button' disabled={link.trim()!=='' && !validateUrl(link)}  color='primary' variant='contained'>SAVE</Button>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(AddLink));