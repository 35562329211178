import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Modal, Paper, InputBase, Typography, Switch, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import axiosCerebrum from '../../../../axios-cerebrum';
import { generateUrl, getDefaultColumn, getSearchFilterList, processObjectType } from '../Utils/Utils';
import ModalAlert from '../../../UI/ModalAlert/ModalAlert';
import { getIconComponent, toTitleCase } from '../../../../utilities';
import KTooltip from '../../../UI/KTooltip/KTooltip';
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  paper:{
    ...theme.components.customScroll
  },
  header:{
    color:theme.palette.primary.main,
    fontSize:20,
    marginBottom:24
  },
  sectionHeader:{
    fontSize:13.75,
    marginRight:16,
    color:theme.palette.primary.main,
    width:124,
    flexGrow:0,
    flexShrink:0,
    marginTop:13,
    display:'flex'
  },
  inputBase:{
    ...theme.components.inputBase,
    width:'100%',
    height:48
  },
  placeholder:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginTop:2,
    marginLeft:16
  },

  switchIcon:{
    color:theme.palette.primary.main
  },
  switchBar:{
    backgroundColor:theme.palette.primary.main
  },
})

function SaveFilterModal(props) {

  const {
    classes,
    state,
    theme,
    modalOpen,
    setModalOpen,
    history,
    disableRedirect,
    editedFilter,
    onSaveComplete,
    isCreateFilter,
    loadSearchResults
  } = props;

  const getPresetState = (editedFilter) => {
    return {
      name:editedFilter?editedFilter.name:'',
      permission:editedFilter?(editedFilter.public?'public':'private'):'private'
    }
  }

  const [name, setName] = useState(getPresetState(editedFilter).name)
  const [permission, setPermission] = useState(getPresetState(editedFilter).permission)
  const [loading, setLoading] = useState(false)

  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const {
    sendAlert
  } = useAlert({
  })

  useEffect(()=>{
    setName(getPresetState(editedFilter).name)
    setPermission(getPresetState(editedFilter).permission)
  },[editedFilter])

  const onSave = () => {
    let promise;
    let solrQuery = {};
    if(state.filterStatus){
      let searchConfig = generateUrl({
        mainSearchQuery:state.mainSearchQuery,
        searchFilters: getSearchFilterList({filterStatus:state.filterStatus, negativeFilters:state.negativeFilters}),
        selectedObjectType:state.selectedObjectType,
        defaultColumns:getDefaultColumn({objectType:state.selectedObjectType,tabs:state.searchTabs}),
        selectedColumns:state.selectedColumns,
        searchMode:state.searchMode,
        andFilters:state.andFilters
      });
      let rawPrams = loadSearchResults({isGenerateParamOnly:true})
      solrQuery = {
        "q": searchConfig.query,
        "q_raw":rawPrams.q,
        "fq": searchConfig.presetFilter,
        "fq_raw":rawPrams.fq,
        "columns":searchConfig.presetColumn,
        "object_type":searchConfig.object,
        'and_filters':searchConfig.andFilters,
        'search_mode':searchConfig.searchMode
      }
    }else if(editedFilter){
      solrQuery = editedFilter.solr_query
    }

    if(editedFilter){
      promise = axiosCerebrum
        .put(
          `/api/filteredlists/${editedFilter.id}`,{
            "name": name,
            "public": permission==='public',
            "solr_query": solrQuery,
          }
        )
    }else{
      promise = axiosCerebrum
        .post(
          '/api/filteredlists',{
            "name": name,
            "description": "",
            "public": permission==='public',
            "solr_query": solrQuery,
            "filtered_list_type": "LIST"
          }
        )
    }
    setLoading(true)
    promise.then(response=>{
      sendAlert({type:'info', message:`Filter "${response.data.name}" successfully saved`})
      if(!disableRedirect){
        history.push('/filters')
      }
      onSaveComplete && onSaveComplete(response.data)
      onCancel()
      setLoading(false)
    })
    .catch(error=>{
      console.log(error)
      setAlertOpen(true)
      setAlertMessage('Error occurred saving filter')
      setLoading(false)
    })
  }

  const onCancel = () => {
    setModalOpen(false)
    setName(getPresetState(editedFilter).name)
    setPermission(getPresetState(editedFilter).permission)
  }

  return (
    <Modal
      open={modalOpen}
      onClose={()=>setModalOpen(false)}
      disableBackdropClick={true}
    >
      <div style={{display:'flex',height:'100vh',alignItems:'center',justifyContent:'center',overflow:'hidden'}}>
        <div>
          <Paper className={classes.paper} style={{padding:24,paddingBottom:8,width:600,boxSizing:'border-box',maxWidth:'80vw',maxHeight:'80vh',overflow:'auto',background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
            <Typography className={classes.header}>{editedFilter?'Edit Filter':isCreateFilter?'Create Filter':'Save as new Filter'}</Typography>
            <div style={{display:'flex',alignItems:'flex-start'}}>
              <Typography className={classes.sectionHeader}>Filter name</Typography>
              <div style={{flexGrow:1}}>
                <InputBase
                  value={name}
                  onChange={event=>{setName(event.target.value.slice(0,120))}}
                  className={classes.inputBase}
                  placeholder={'Enter filter name'}
                  autoFocus
                  inputProps={{
                    'data-test-id':'filter-name-input'
                  }}
                />
                <Typography className={classes.placeholder}>Required. Display name of the filter. Max 120 characters</Typography>
              </div>
            </div>
            <div style={{marginTop:40,display:'flex',alignItems:'flex-start',marginBottom:40}}>
              <Typography className={classes.sectionHeader}>
                Permissions
                <KTooltip title="Set who can view this filter. When Permission is set to Private, only the creator can view and edit the filter">
                  <div style={{width:20,height:20,flexGrow:0,flexShrink:0,marginLeft:8}}>
                    {getIconComponent({label:'info',size:20,colour:theme.palette.primary.main})}
                  </div>
                </KTooltip>
              </Typography>

              <div style={{display:'flex',alignItems:'center'}}>
                <Typography style={{fontSize:13.75,marginRight:8,color:permission==='private'?theme.palette.primary.main:theme.palette.primaryText.light}}>
                  PRIVATE
                </Typography>
                <Switch
                  checked={permission==='public'}
                  onChange={()=>{
                    let newValue;
                    if(permission==='private'){
                      newValue =  'public'
                    }else{
                      newValue = 'private'
                    }
                    setPermission(newValue)
                  }}
                  inputProps={{
                    'data-test-id':'filter-permission-switch'
                  }}
                  classes={{icon:classes.switchIcon, bar:classes.switchBar}}
                  value={permission}
                  color="primary"
                />
                <Typography style={{fontSize:13.75,marginLeft:8,color:permission==='public'?theme.palette.primary.main:theme.palette.primaryText.light}}>
                  PUBLIC
                </Typography>
              </div>
            </div>
            {
              state.selectedObjectType!=='ALL' && state.selectedObjectType &&
              <div style={{marginTop:40,display:'flex',alignItems:'flex-start',marginBottom:40}}>
                <Typography className={classes.sectionHeader}>Object Filter</Typography>
                <Typography style={{fontSize:13.75,marginTop:10}}>
                  {toTitleCase(processObjectType(state.selectedObjectType).replace(/_/g,' '))}(s)
                </Typography>
              </div>
            }
            <div style={{display:'flex',justifyContent:'flex-end'}}>
              <Button color='primary' data-test-id="save-button" disabled={!name.trim() || loading} style={{marginRight:12}} onClick={onSave}>SAVE</Button>
              <Button color='secondary' data-test-id="cancel-button" onClick={onCancel}>CANCEL</Button>
            </div>
          </Paper>
          <div style={{margin:'0 auto',width:600}}>
            <ModalAlert
              open={alertOpen}
              setOpen={setAlertOpen}
              message={alertMessage}
              type='error'
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

SaveFilterModal.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
}

export default withTheme()(withStyles(styles)(withRouter(SaveFilterModal)));
