import React from 'react';
import { withStyles} from '@material-ui/core';
import Source from '../Source/Source';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import { formatNumber } from '../../../utilities';
import Details from '../Details/Details';
import NoteAdder from '../../UI/NoteAdder/NoteAdder';
// import Details from '../Details/Details'

const styles = theme => ({
  root:{
    // display:'flex',
    // overflow:'hidden'
  }
})

function Body(props) {

  const {
    classes,
    tabOptions,
    history,
    state,
    dispatch,
    fetchList
  } = props;

  const formatName = str => {
    if(str==='DATA_QUALITY_TEST')str = 'DQ TEST'
    if(str==='COLLECTION_INSTANCE')str = 'INSTANCE'
    return str;
  }

  let bodyContent;

  switch(tabOptions[state.tabState]){
    case 'ASSETS':
      bodyContent = (
        <div className={classes.root}>
          {
            state.linkedFacets?.length ?
            <div style={{float:'left'}}>
              <VerticalTabBar
                tabOptions={state.linkedFacets.map(el=>`${formatName(el.name).replace(/_/g,' ')} (${formatNumber(el.count)})`)}
                tabState={state.sourceTabState}
                setTabState={value => dispatch({ type: 'set_source_tab_state', sourceTabState: value })}
              />
            </div>
            :
            null
          }
          <div style={{marginLeft:state.linkedFacets?.length?280:0}}>
            <Source state={state} dispatch={dispatch} history={history} formatName={formatName}/>
          </div>
        </div>
      )
      break;
    case "DETAILS":
      bodyContent = (
        <Details state={state} dispatch={dispatch} fetchList={fetchList} />
      )
      break;
    case 'KNOWLEDGE':
      bodyContent = (
        <NoteAdder
          history={history}
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.noteModalOpen}
          setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
        />
      )
      break;
    default:
  }

  return (
    bodyContent || <></>
  )
}

export default withStyles(styles)(Body);