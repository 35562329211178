import React, { useEffect, useRef } from 'react';
import { withStyles, Typography, CircularProgress, Select, MenuItem, InputBase, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosSolr from '../../../axios-solr';
import { getDispFields, getIconComponent, mapObjectName, onClickResultItem, tabGroup, toTitleCase } from '../../../utilities';
import ResultItem2 from '../../UI/SearchResults/ResultItem2';
import theme from '../../../theme';
import { globalListenerRef } from '../../../GlobalListenerRef';
import axiosCerebrum from '../../../axios-cerebrum';

const styles = theme => ({

  selectorHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:2,
    paddingLeft:16,
    marginTop:4,
    marginBottom:4
  },
  selector:{
    ...theme.components.selector,
    boxSizing:'border-box',
    width:'100%',
    '& div div':{
      padding:16
    },
    height:48
  },
  inputBase:{
    ...theme.components.inputBase,
    boxSizing:'border-box',
    width:'100%',
    height:48,
    marginBottom:24
  }
})

function RelatedList(props) {

  const {
    classes,
    state,
    dispatch,
    history,
  } = props;

  const searchTimeout = useRef()

  const loadObjects = () => {
    dispatch({
      type:'set_related_object_types',
      relatedObjectTypesLoading:true,
    })
    axiosCerebrum
      .get(
        `/api/collectioninstances/${state.basicData.id}/related/objects`,{
          params:{
            per_page:50,
            relationship:'MEMBERS,RELATES_TO,VERIFIES,CLASSIFIES',
          }
        }
      )
      .then(response=>{
        dispatch({
          type:'set_related_object_types',
          relatedObjectTypes:response.data.items.map(el=>el.name),
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_related_object_types',
          relatedObjectTypesError:true,
        })
      })
  }

  useEffect(()=>{
    if(state.tabState!==1)return;
    if(state.relatedObjectTypesLoading)return;
    if(state.relatedObjectTypes)return;
    loadObjects();
  // eslint-disable-next-line   
  },[state.tabState])

  const loadChildren = ({type=state.relatedSelectedType, page=1, search=state.relatedSearch[state.relatedSelectedType]||''}) => {
    dispatch({
      type:'set_related_list_data',
      relatedListData:{
        ...state.relatedListData,
        [type]:{
          ...(page===1?{}:(state.relatedListData?.[type]||{})),
          loading:true
      }
    }})

    axiosCerebrum
      .get(
        `/api/collectioninstances/${state.basicData.id}/related`,{
          params:{
            object_name:type,
            relationship:'MEMBERS,RELATES_TO,VERIFIES,CLASSIFIES',
            per_page:10,
            page,
            'search.name':search
          }
        }
      )
      .then(response=>{
        if(response.data.items.length===0){
          dispatch({
            type:'set_related_list_data',
            relatedListData:{
              ...state.relatedListData,
              [type]:response.data,
            }
          })
          return; 
        }

        axiosSolr
          .get(
            `/solr/search/select`,{
              params:{
                q:"*",
                fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
                rows:response.data.items.length
              }
            }
          )
          .then(solrRes=>{
            dispatch({
              type:'set_related_list_data',
              relatedListData:{
                ...state.relatedListData,
                [type]:{
                  ...response.data,
                  items:[
                    ...(page===1?[]:(state.relatedListData?.[type]?.items || [])),
                    ...response.data.items.map(el=>{
                      let solrItem = solrRes.data.response.docs.find(solrEl=>solrEl.id===el.id);
                      return {
                        ...el,
                        ...(solrItem||{})
                      }
                    })
                  ] 
                }
              }
            })
          })
          .catch(error=>{
            console.log(error)
            dispatch({
              type:'set_related_list_data',
              relatedListData:{
                ...state.relatedListData,
                [type]:{
                  error:true
                }
              }
            })
          })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_related_list_data',
          relatedListData:{
            ...state.relatedListData,
            [type]:{
              error:true
            }
          }
        })
      })
  }

  useEffect(()=>{
    if(state.tabState!==1)return;
    if(state.relatedSelectedType==='none')return;
    if(!state.relatedListData[state.relatedSelectedType] && !state.relatedListData[state.relatedSelectedType]?.loading){
      loadChildren({type:state.relatedSelectedType})
    }
  // eslint-disable-next-line 
  },[state.tabState, state.relatedSelectedType])
  
  useEffect(()=>{
    if(state.tabState!==1)return;
    if(state.relatedSelectedType==='none')return;
    if(!state.relatedListData[state.relatedSelectedType])return;
    if(state.relatedListData[state.relatedSelectedType].loading)return;
    let container = document.getElementById(`mini-profile-${state.basicData.id}`);
    if(!container)return;
    if(container.scrollHeight!==container.clientHeight)return;
    if(state.relatedListData[state.relatedSelectedType].page<state.relatedListData[state.relatedSelectedType].pages){
      loadChildren({page:state.relatedListData[state.relatedSelectedType].page+1});
    }
  // eslint-disable-next-line 
  },[state.relatedListData, state.tabState, state.relatedSelectedType])
  
  useEffect(()=>{
    let container = document.getElementById(`mini-profile-${state.basicData.id}`);
    container.removeEventListener('scroll',globalListenerRef.miniProfileScrollListener);
    if(!container)return;
    if(state.relatedSelectedType==='none')return;
    if(!state.relatedListData[state.relatedSelectedType])return;
    if(state.relatedListData[state.relatedSelectedType].loading)return;
    if(state.tabState!==1)return;
    const onScroll = (event) => {
      if(event.target.scrollTop>=event.target.scrollHeight-event.target.clientHeight-10){
        if(!state.relatedListData[state.relatedSelectedType].loading && state.relatedListData[state.relatedSelectedType].page<state.relatedListData[state.relatedSelectedType].pages){
          loadChildren({page:state.relatedListData[state.relatedSelectedType].page+1});
        }
      }
    }
    globalListenerRef.miniProfileScrollListener = onScroll;
    container.addEventListener("scroll", globalListenerRef.miniProfileScrollListener);
    return (()=>{container.removeEventListener('scroll',globalListenerRef.miniProfileScrollListener);})
  // eslint-disable-next-line
  },[state.relatedListData, state.tabState, state.relatedSelectedType])

  const onSearchChange = (value) => {
    dispatch({
      type:'set_related_search',
      relatedSearch:{
        ...state.relatedSearch,
        [state.relatedSelectedType]:value
      }
    })
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(()=>{
      loadChildren({page:1,search:value})
    },300)
  }

  return (
    <div>
      {
        state.relatedObjectTypesLoading && <CircularProgress color='secondary'/>
      }
      {
        state.relatedObjectTypesError && <Typography>Error occurred loading related objects</Typography>
      }
      {
        state.relatedObjectTypes?.length===0 && <Typography>No related objects found</Typography>
      }
      {
        state.relatedObjectTypes?.length>0 &&
        <Select
          value={state.relatedSelectedType}
          disableUnderline
          className={classes.selector}
          style={{marginBottom:24}}
          renderValue={value=>{
            if(value==='none')return 'Select a linked object type'
            return `View: ${toTitleCase(mapObjectName(value,true).replace(/_/g,' '))}`
          }}
        >
          {
            tabGroup.map(el=>(
              el.tabs.some(t=>state.relatedObjectTypes.includes(t))?
              <div style={{width:'100%',marginBottom:12}}>
                <Typography className={classes.selectorHeader} onClick={event=>{event.stopPropagation();event.preventDefault();}}>{el.name}</Typography>
                {
                  el.tabs.map(t=>{
                    if(state.relatedObjectTypes.includes(t)){
                      return (
                        <MenuItem 
                          value={t} 
                          onClick={()=>{
                            dispatch({
                              type:'set_related_selected_type',
                              relatedSelectedType:t
                            })
                          }}
                        >
                          <div style={{width:24,height:24,marginRight:16}}>{getIconComponent({label:t,size:24,colour:theme.palette.primaryText.light})}</div> 
                          {mapObjectName(t,true).replace(/_/g,' ')}
                        </MenuItem>
                      )
                    }
                    return undefined
                  })
                }
              </div>
              :
              undefined
            ))
          }
        </Select>
      }
      {
        state.relatedSelectedType!=='none' && 
        <>
          <Typography style={{fontSize:16,color:theme.palette.header.main,marginBottom:12}}>
            {state.relatedListData?.[state.relatedSelectedType]?.total?state.relatedListData?.[state.relatedSelectedType]?.total+' ':''}{mapObjectName(state.relatedSelectedType,true).replace(/_/g,' ')}(S)
          </Typography>
          <InputBase
            placeholder={`Search ${toTitleCase(mapObjectName(state.relatedSelectedType,true).replace(/_/g,' '))}(s)`}
            className={classes.inputBase}
            value={state.relatedSearch?.[state.relatedSelectedType]||''}
            onChange={event=>{onSearchChange(event.target.value)}}
            endAdornment={
              <IconButton 
                disabled={state.relatedSearch?.[state.relatedSelectedType]===''} 
                onClick={()=>onSearchChange('')}
                style={{width:32,height:32,marginRight:6}}
              >
                {getIconComponent({label:state.relatedSearch?.[state.relatedSelectedType]===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
              </IconButton>
            }
          />
          {
            state.relatedListData?.[state.relatedSelectedType]?.items?.map((item,index)=>(
              <ResultItem2
                item={item}
                key={item.id}
                dispBody={getDispFields(item,'dispBody')}
                dispSubtitle={getDispFields(item,'dispSubtitle')}
                dispTitle={getDispFields(item,'dispTitle')}
                label={item.object_type_txt}
                showIcon
                showUnderline
                showSimplifiedTrustIcon
                onClick={() => onClickResultItem({id:item.id,label:item.object_type_txt,history:history,item:item,newWindow:true})}
              />
            ))
          }
          {
            state.relatedListData?.[state.relatedSelectedType]?.items?.length===0 &&
            <Typography>No related {mapObjectName(state.relatedSelectedType,true).replace(/_/g,' ')}(s) found</Typography>
          }
          {
            state.relatedListData?.[state.relatedSelectedType]?.loading &&
            <div style={{display:'flex',justifyContent:'center',marginTop:6}}>
              <CircularProgress color='secondary'/>
            </div>
          }
          {
            state.relatedListData?.[state.relatedSelectedType]?.error &&
            <Typography>Error occurred loading items</Typography>
          }
        </>
      }
     
    </div>
  )
}

RelatedList.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sessionData: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withStyles(styles)(RelatedList);