import React, { useEffect } from 'react';
import { withStyles, Typography, CircularProgress, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosSolr from '../../../axios-solr';
import { getDispFields, onClickResultItem, sendMessage } from '../../../utilities';
import ResultItem2 from '../../UI/SearchResults/ResultItem2';
import theme from '../../../theme';
import { globalListenerRef } from '../../../GlobalListenerRef';
import axiosCerebrum from '../../../axios-cerebrum';

const styles = theme => ({

  selectorHeader: {
    fontSize: 12,
    color: theme.palette.primaryText.main,
    letterSpacing: 2,
    paddingLeft: 16,
    marginTop: 4,
    marginBottom: 4
  },
  selector: {
    ...theme.components.selector,
    boxSizing: 'border-box',
    width: '100%',
    '& div div': {
      padding: 16
    },
    height: 48
  },
  inputBase: {
    ...theme.components.inputBase,
    boxSizing: 'border-box',
    width: '100%',
    height: 48,
    marginBottom: 24
  }
})

function LineageList(props) {

  const {
    classes,
    state,
    dispatch,
    direction,
    history,
  } = props;

  // const searchTimeout = useRef()

  const getRelations = (direction) => {
    if (direction === 'downstream') {
      return ['SOURCE_OF', 'K_REFERENCED_BY', 'REFERENCED_BY', 'REFERENCED_BY_SHORTCUT']
    } else {
      return ['SOURCE_FROM', 'K_REFERENCES', 'REFERENCES', 'REFERENCES_SHORTCUT']
    }
  }


  const loadChildren = ({ page = 1, search = state.relatedSearch[direction] || '', isRef = state.relatedShowRef[direction] || 'all' }) => {
    dispatch({
      type: 'set_related_list_data',
      relatedListData: {
        ...state.relatedListData,
        [direction]: {
          ...(page === 1 ? {} : (state.relatedListData?.[direction] || {})),
          loading: true
        }
      }
    })

    axiosCerebrum
      .get(
        `/api/hosts/${state.basicData.id}/related`, {
        params: {
          object_name: 'HOST',
          relationship: getRelations(direction).join(','),
          per_page: 10,
          object_reference: isRef === 'all' ? undefined : isRef,
          page,
          'search.name': search
        }
      }
      )
      .then(response => {
        if (response.data.items.length === 0) {
          dispatch({
            type: 'set_related_list_data',
            relatedListData: {
              ...state.relatedListData,
              [direction]: response.data,
            }
          })
          return;
        }

        axiosSolr
          .get(
            `/solr/search/select`, {
            params: {
              q: "*",
              fq: `id:(${response.data.items.map(el => el.id).join(' OR ')})`,
              rows: response.data.items.length
            }
          }
          )
          .then(solrRes => {
            dispatch({
              type: 'set_related_list_data',
              relatedListData: {
                ...state.relatedListData,
                [direction]: {
                  ...response.data,
                  items: [
                    ...(page === 1 ? [] : (state.relatedListData?.[direction]?.items || [])),
                    ...response.data.items.map(el => {
                      let solrItem = solrRes.data.response.docs.find(solrEl => solrEl.id === el.id);
                      return {
                        ...el,
                        ...(solrItem || {})
                      }
                    })
                  ]
                }
              }
            })
          })
          .catch(error => {
            console.log(error)
            dispatch({
              type: 'set_related_list_data',
              relatedListData: {
                ...state.relatedListData,
                [direction]: {
                  error: true
                }
              }
            })
          })
      })
      .catch(error => {
        console.log(error)
        dispatch({
          type: 'set_related_list_data',
          relatedListData: {
            ...state.relatedListData,
            [direction]: {
              error: true
            }
          }
        })
      })
  }

  useEffect(() => {
    if (!state.relatedListData[direction] && !state.relatedListData[direction]?.loading) {
      loadChildren({})
    }
    sendMessage({ map_view_mode: direction })
    return () => {
      sendMessage({ map_view_mode: 'all' })
    }
    // eslint-disable-next-line 
  }, [])

  useEffect(() => {
    if (!state.relatedListData[direction]) return;
    if (state.relatedListData[direction].loading) return;
    let container = document.getElementById(`mini-profile-${state.basicData.id}`);
    if (!container) return;
    if (container.scrollHeight !== container.clientHeight) return;
    if (state.relatedListData[direction].page < state.relatedListData[direction].pages) {
      loadChildren({ page: state.relatedListData[direction].page + 1 });
    }
    // eslint-disable-next-line 
  }, [state.relatedListData])

  useEffect(() => {
    let container = document.getElementById(`mini-profile-${state.basicData.id}`);
    container.removeEventListener('scroll', globalListenerRef.miniProfileScrollListener);
    if (!container) return;
    if (!state.relatedListData[direction]) return;
    if (state.relatedListData[direction].loading) return;
    if (state.tabState !== 1) return;
    const onScroll = (event) => {
      if (event.target.scrollTop >= event.target.scrollHeight - event.target.clientHeight - 10) {
        if (!state.relatedListData[direction].loading && state.relatedListData[direction].page < state.relatedListData[direction].pages) {
          loadChildren({ page: state.relatedListData[direction].page + 1 });
        }
      }
    }
    globalListenerRef.miniProfileScrollListener = onScroll;
    container.addEventListener("scroll", globalListenerRef.miniProfileScrollListener);
    return (() => { container.removeEventListener('scroll', globalListenerRef.miniProfileScrollListener); })
    // eslint-disable-next-line
  }, [state.relatedListData])

  // const onSearchChange = (value) => {
  //   dispatch({
  //     type:'set_related_search',
  //     relatedSearch:{
  //       ...state.relatedSearch,
  //       [direction]:value
  //     }
  //   })
  //   clearTimeout(searchTimeout.current);
  //   searchTimeout.current = setTimeout(()=>{
  //     loadChildren({page:1,search:value})
  //   },300)
  // }

  return (
    <div>
      <Typography style={{ fontSize: 16, color: theme.palette.header.main, marginBottom: 12 }}>
        {state.relatedListData?.[direction]?.total ? state.relatedListData?.[direction]?.total + ' ' : ''}{direction.toUpperCase()} SOURCE(S)
      </Typography>
      {/* <InputBase
        placeholder={`Search source(s)`}
        className={classes.inputBase}
        value={state.relatedSearch?.[direction]||''}
        onChange={event=>{onSearchChange(event.target.value)}}
        endAdornment={
          <IconButton 
            disabled={state.relatedSearch?.[direction]===''} 
            onClick={()=>onSearchChange('')}
            style={{width:32,height:32,marginRight:6}}
          >
            {getIconComponent({label:state.relatedSearch?.[direction]===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
          </IconButton>
        }
      /> */}

      <Select
        value={typeof(state.relatedShowRef[direction])==='boolean'? state.relatedShowRef[direction] : 'all'}
        disableUnderline
        className={classes.selector}
        style={{ marginBottom: 24, width: 160 }}
        onChange={event => {
          dispatch({
            type: 'set_related_show_ref',
            relatedShowRef: {
              ...state.relatedShowRef,
              [direction]: event.target.value
            }
          })
          loadChildren({ isRef: event.target.value })
        }}
        renderValue={value => {
          return `Reference: ${value === 'all' ? 'All' : value ? 'Yes' : 'No'}`
        }}
      >
        <MenuItem value={'all'} onClick={() => loadChildren({ page: 1, search: state.relatedSearch[direction] || '', isRef: 'all' })}>
          All
        </MenuItem>
        <MenuItem value={false} onClick={() => loadChildren({ page: 1, search: state.relatedSearch[direction] || '', isRef: false })}>
          No
        </MenuItem>
        <MenuItem value={true} onClick={() => loadChildren({ page: 1, search: state.relatedSearch[direction] || '', isRef: true })}>
          Yes
        </MenuItem>
      </Select>
      {
        state.relatedListData?.[direction]?.items?.map((item, index) => (
          <ResultItem2
            item={item}
            key={item.id}
            dispBody={getDispFields(item, 'dispBody')}
            dispSubtitle={getDispFields(item, 'dispSubtitle')}
            dispTitle={getDispFields(item, 'dispTitle')}
            label={item.object_type_txt}
            showIcon
            showUnderline
            showSimplifiedTrustIcon
            onClick={() => onClickResultItem({ id: item.id, label: item.object_type_txt, history: history, item: item, newWindow: true })}
          />
        ))
      }
      {
        state.relatedListData?.[direction]?.items?.length === 0 &&
        <Typography>No {direction} source(s) found</Typography>
      }
      {
        state.relatedListData?.[direction]?.loading &&
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 6 }}>
          <CircularProgress color='secondary' />
        </div>
      }
      {
        state.relatedListData?.[direction]?.error &&
        <Typography>Error occurred loading {direction} sources</Typography>
      }
    </div>
  )
}

LineageList.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sessionData: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withStyles(styles)(LineageList);