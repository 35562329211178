import React, { useEffect, useRef } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, InputBase, Select, MenuItem, IconButton, Button } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import { getIconComponent, isInViewport, onClickResultItem } from '../../../utilities';
import AddRuleModal from '../AddRuleModal/AddRuleModal'
import DeleteRuleModal from '../DeleteRuleModal/DeleteRuleModal';

const styles = theme => ({
  infoBox:theme.components.infoBox,
  infoHeader:theme.components.infoBoxHeader,
  infoDescription:theme.components.infoBoxDescription,
  focusTxt:theme.components.infoBoxFocusTxt,
  button:theme.components.infoBoxButtonContained,
  title:{
    fontSize:20,
    color:theme.palette.header.main,
    marginBottom:8
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light
  },
  ruleItemContainer:{
    display:'flex',
    minHeight:55,
    padding:'12px 18px',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    overflow:'hidden'
  },
  ruleItemInstanceName:{
    width:'max-content',
    maxWidth:"100%",
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpacing:'nowrap',
    color:theme.palette.primary.main,
    fontSize:16,
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline'
    }
  },
  ruleItemCollectionName:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpacing:'nowrap',
    color:theme.palette.primaryText.light,
    fontSize:12
  },
  columnText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    wordBreak:'break-word'
  },
  columnHeaderText:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primary.main
  },
  inputBase:{
    ...theme.components.inputBase,
    padding:'8px 12px',
    width:220,
  },
  selector:{
    ...theme.components.selector,
    width:200,
    marginLeft:24,
    '& div div':{
      paddingTop:14,
      paddingLeft:16,
      paddingBottom:15,
      fontSize:16
    }
  },
  iconButton:{
    width:36,
    height:36,
    marginTop:8,
  }
})

function LinkingRules(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    history
  } = props;

  const scrollRef = useRef()
  const searchTimeoutRef = useRef()

  const loadAvailableCollections = () => {
    axiosCerebrum
      .get(
        `/api/collectioninstances/rules/attributes`,
        {params:{
          attribute:'collection',
        }}
      )
      .then(response=>{
        dispatch({type:'set_linking_rules_available_collections',linkingRulesAvailableCollections:response.data.items})
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_linking_rules_available_collections',linkingRulesAvailableCollections:[]})
      })
  }

  const loadRules = async ({page=1, searchStr=state.linkingRuleSearchValue, collectionId=state.linkingRuleCollection }) => {
    dispatch({
      type:'set_linking_rules',
      linkingRulesLoading:true,
      linkingRules:page===1?undefined:state.linkingRules
    })

    let instanceIds, error;
    if(searchStr.trim()!=='' || collectionId!=='all'){
      await axiosCerebrum
        .get(
          `/api/collectioninstances/rules/attributes`,
          {params:{
            attribute:'collection_instance',
            attribute_search:searchStr,
            collection_id:collectionId==='all'?undefined:collectionId
          }}
        )
        .then(response=>{
          instanceIds = response.data.items.map(el=>el.id)
        })
        .catch(error=>{
          console.log(error)
          error = true;
          dispatch({
            type:'set_linking_rules',
            linkingRulesError:true,
          })
        })
    }    
    if(error)return;
    if(instanceIds && instanceIds.length===0){
      dispatch({
        type:'set_linking_rules',
        linkingRules:{page:1,pages:0,total:0,items:[]}
      })
      return;
    }

    axiosCerebrum 
      .get(
        `/api/collectioninstances/rules`,
        {params:{
          per_page:10,
          page:page,
          collection_instance_ids:instanceIds && instanceIds.length>0 ? instanceIds.join(','):undefined
        }}
      )
      .then(response=>{
        dispatch({
          type:'set_linking_rules',
          linkingRules:page===1?response.data:{...response.data,items:[...state.linkingRules.items,...response.data.items]}
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_linking_rules',
          linkingRulesError:true,
        })
      })
  }

  useEffect(()=>{
    if(!state.linkingRules)loadRules({})
    if(!state.linkingRulesAvailableCollections)loadAvailableCollections({})
  // eslint-disable-next-line
  },[])

  const shouldLoadMore = () => {
    return state.linkingRules && state.linkingRules.page<state.linkingRules.pages && !state.linkingRulesLoading && isInViewport(scrollRef)
  }

  window.onscroll = () => {
    if(shouldLoadMore())loadRules({page:state.linkingRules.page+1})
  }

  useEffect(()=>{
    if(shouldLoadMore())loadRules({page:state.linkingRules.page+1})
  // eslint-disable-next-line
  },[state.linkingRules,state.linkingRulesLoading])

  const onChangeLinkRuleSearchValue = event => {
    clearTimeout(searchTimeoutRef.current)
    let value = event.target.value
    dispatch({type:'set_linking_rule_search_value',linkingRuleSearchValue:value})
    searchTimeoutRef.current = setTimeout(()=>{
      loadRules({page:1,searchStr:value})
    },250)
  }

  const onCollectionChange = event => {
    dispatch({type:'set_linking_rule_collection',linkingRuleCollection:event.target.value})
    loadRules({collectionId:event.target.value})
  }

  const onClickEdit = rule => {
    dispatch({type:'set_add_rule_selected_object_type',addRuleSelectedObjectType:rule.object_type})
    dispatch({type:'set_add_rule_regex',addRuleRegex:rule.regex_rule})
    dispatch({type:'set_add_rule_modal_open',addRuleModalOpen:{isEdit:true,selectedRule:rule}})
  }

  const onClickDelete = rule => {
    dispatch({type:'set_delete_rule_modal_open',deleteRuleModalOpen:{selectedRule:rule}})
  }

  return (
    <div className={classes.root}>
      <div className={classes.infoBox}>
        <Typography className={classes.infoHeader}>Linking Data & Content to Collections</Typography>
        <div style={{display:'flex',alignItems:'flex-start'}}>
          <Typography className={classes.infoDescription}>
            Linking rules are a great way to automatically link data and content items to a collection using business rule written in a Regex format (aka Regex Rule). Rules link data and content item by their names. Linking rules create consistency and help save manual time and effort. Data and content items that no longer meet the Regex Rule will automatically be de-linked from the collection.
            {'\n\n'}
            Tip: There are a number of excellent public resources for helping build Regex to meet your needs such as <span onClick={()=>window.open('https://regex101.com/','_blank')} className={classes.focusTxt}>https://regex101.com/</span>
          </Typography>
          <Button className={classes.button} onClick={()=>dispatch({type:'set_add_rule_modal_open',addRuleModalOpen:true})}>
            NEW LINKING RULE
          </Button>
        </div>
      </div>
      <div style={{display:'flex',alignItems:'flex-start',justifyContent:'space-between',marginBottom:32}}>
        <div style={{flexGrow:1}}>
          <Typography className={classes.title}>{state.linkingRules?state.linkingRules.total+' ':''}LINKING RULES</Typography>
          <Typography className={classes.subTitle}>Regex will match to item(s) name. Click NEW LINKING RULE to add a new rule. </Typography>
        </div>
        <InputBase
          value={state.linkRuleSearchValue}
          onChange={onChangeLinkRuleSearchValue}
          className={classes.inputBase}
          placeholder="Search instances"
          endAdornment={
            <div style={{width:24,height:24}}>
              {getIconComponent({label:'search',size:24,colour:theme.palette.primaryText.light})}
            </div>
          }
        />
        <Select
          className={classes.selector}
          value={state.linkingRuleCollection}
          onChange={onCollectionChange}
        >
          <MenuItem value='all'>All collections</MenuItem>
          {
            state.linkingRulesAvailableCollections && state.linkingRulesAvailableCollections.map(el=>(
              <MenuItem value={el.id}>{el.value}</MenuItem>
            ))
          }
        </Select>
      </div>
      {
        state.linkingRules && state.linkingRules.total>0 && 
        <div style={{display:'flex',padding:'0px 18px'}}>
          <div style={{flex:'0 0 24px',marginRight:18}}></div>
          <div style={{overflow:'hidden',flex:'0 0 25%',marginRight:12}} className={classes.columnHeaderText}>INSTANCE</div>
          <div style={{overflow:'hidden',flex:'0 0 25%',marginRight:12}} className={classes.columnHeaderText}>LINKED TO</div>
          <div style={{overflow:'hidden',flex:'1 0',marginRight:24}} className={classes.columnHeaderText}>RULE</div>
          <div style={{overflow:'hidden',flex:'0 0 80px'}} className={classes.columnHeaderText}>ACTION</div>
        </div>
      }
      {
        state.linkingRules && state.linkingRules.items.map(el=>(
          <div className={classes.ruleItemContainer}>
            <div style={{flex:'0 0 24px',marginRight:18,marginTop:2}}>
              {getIconComponent({label:'collection',size:24,colour:theme.palette.primary.main})}
            </div>
            <div style={{overflow:'hidden',flex:'0 0 25%',marginRight:12}}>
              <Typography className={classes.ruleItemInstanceName} onClick={()=>onClickResultItem({item:el.collection_instance,id:el.collection_instance.id,label:'collection_instance',history})}>{el.collection_instance.name}</Typography>
              <Typography className={classes.ruleItemCollectionName}>{el.collection_instance.parent.name}</Typography>
            </div>
            <div style={{overflow:'hidden',flex:'0 0 25%',marginRight:12}}>
              <Typography className={classes.columnText}>{el.object_type}</Typography>
            </div>
            <div style={{overflow:'hidden',flex:'1 0',marginRight:24}}>
              <Typography className={classes.columnText}>{el.regex_rule}</Typography>
            </div>
            <IconButton className={classes.iconButton} onClick={()=>onClickEdit(el)}>
              {getIconComponent({label:'edit',size:24,colour:theme.palette.primaryText.light})}
            </IconButton>
            <IconButton className={classes.iconButton} style={{marginLeft:8}} onClick={()=>onClickDelete(el)}>
              {getIconComponent({label:'delete',size:24,colour:theme.palette.primaryText.light})}
            </IconButton>
          </div>
        ))
      }
      {
        state.linkingRulesError && <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading linking rules</Typography>
      }
      <div ref={scrollRef} style={{marginTop:8,marginBottom:48,textAlign:"center"}}>
        {
          state.linkingRulesLoading && <CircularProgress color='secondary' />
        }
      </div>
      <DeleteRuleModal state={state} dispatch={dispatch} loadRules={loadRules} loadAvailableCollections={loadAvailableCollections} selectedRule={state.deleteRuleModalOpen.selectedRule}/>
      <AddRuleModal state={state} dispatch={dispatch} loadRules={loadRules} loadAvailableCollections={loadAvailableCollections} isEdit={state.addRuleModalOpen.isEdit} selectedRule={state.addRuleModalOpen.selectedRule}/>
    </div>
  )
}


export default withTheme()(withStyles(styles)(LinkingRules));