import moment from "moment"
import timezoneDetect from 'jstimezonedetect';
import axiosSolr from "../../../axios-solr";
import { capitalizeFirstWord } from "../../../utilities";

export const modalExtracts = ['0005','0050','0070','0070_all','0080','0100','0110','0140','0150','0170','0200','0200_TABLE','0200_CONTENT','0210','0220','0230','0250','0280','0300','0340_OWNER','0340_STEWARD','0340_OBJECT','0350_CONTENT','0360','0400','0390']
export const dbOnlyExtracts = ['0005','0050','0070','0070_all','0080','0100','0110','0140','0150','0170','0200_TABLE','0250','0280','0300','0340_OBJECT']
export const toolOnlyExtracts = ['0200_CONTENT','0350_CONTENT','0390']
export const contentTypeOnlyExtracts = ['0390']

export const generatePayload = ({
  code,
  dataSource,
  startDate,
  endDate,
  surveyTemplate,
  objectType,
  zone,
  contentType,
  period,
  trend,
  userNum,
  user,
  userList,
  team,
  object,
  objectList,
  surveyCategory,
  instance,
  sendAlert
}) => {
  let filters = {}
  let payload = {}
  if(modalExtracts.includes(code)){
    if (['0230', '0210',].includes(code)) {
      filters = {
        sourceId: dataSource + ''
      }
    }
    if (code === '0220') {
      filters = {
        source_id: dataSource + '',
        start_at: moment(startDate).format('YYYY-MM-DD'),
        end_at: moment(endDate).format('YYYY-MM-DD'),
        survey_template_id: surveyTemplate,
        object_type_id: objectType,
        tz: timezoneDetect.determine().name()
      }
    }
    if (['0250', '0300'].includes(code)) {
      filters = {
        sourceId: dataSource + '',
        zone: zone === 'All' ? '*' : zone
      }
    }
    if (code === '0200') {
      filters = {
        source_id: dataSource + '',
        start_at: moment(startDate).format('YYYY-MM-DD'),
        end_at: moment(endDate).format('YYYY-MM-DD'),
        object_type: objectType,
        tz: timezoneDetect.determine().name()
      }
    }
    if (code === '0200_TABLE') {
      filters = {
        source_id: dataSource + '',
        start_at: moment(startDate).format('YYYY-MM-DD'),
        end_at: moment(endDate).format('YYYY-MM-DD'),
        object_type: 'TABLE',
        tz: timezoneDetect.determine().name()
      }
    }
    if (code === '0200_CONTENT') {
      filters = {
        source_id: dataSource + '',
        start_at: moment(startDate).format('YYYY-MM-DD'),
        end_at: moment(endDate).format('YYYY-MM-DD'),
        object_type: contentType,
        tz: timezoneDetect.determine().name()
      }
    }
    if (['0280'].includes(code)) {
      filters = {
        sourceId: dataSource + '',
        period: period,
        tz: timezoneDetect.determine().name()
      }
    }
    if (code === '0150') {
      filters = {
        sourceId: dataSource + '',
        startDate: moment().format('YYYY-MM-DD'),
        period: period,
        tz: timezoneDetect.determine().name()
      }
    }
    if (code === '0140') {
      filters = {
        sourceId: dataSource + '',
        groupId: team.id,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        tz: timezoneDetect.determine().name()
      }
    }
    if (['0170', '0050'].includes(code)) {
      filters = {
        sourceId: dataSource + '',
        tz: timezoneDetect.determine().name()
      }
    }
    if (code === '0110') {
      filters = {
        sourceId: dataSource + '',
        tz: timezoneDetect.determine().name(),
        zone: zone === 'All' ? '*' : zone
      }
    }
    if (code === '0100') {
      filters = {
        sourceId: dataSource + '',
        trend: trend,
        tz: timezoneDetect.determine().name()
      }
    }
    if (code === '0080') {
      if (isNaN(userNum)) {
        sendAlert?.({ message: 'User number must be a valid number', type: 'error' })
        return;
      }
      filters = {
        sourceId: dataSource + '',
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        numUsers: userNum,
        tz: timezoneDetect.determine().name()
      }
    }
    if (code === '0070') {
      filters = {
        userId: user.id,
        sourceId: dataSource + '',
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        tz: timezoneDetect.determine().name()
      }
    }
    if (code === '0070_all') {
      filters = {
        userId: '*',
        sourceId: dataSource + '',
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        tz: timezoneDetect.determine().name()
      }
    }
    if (code === '0005') {
      filters = {
        sourceId: dataSource + '',
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        tz: timezoneDetect.determine().name()
      }
    }
    if (code === '0340_OWNER' || code === '0340_STEWARD') {
      filters = {
        user_id: user.id,
        object_ids: null,
        source_id: null,
        type: code.split('_')[1],
        period: period
      }
    }
    if (code === '0340_OBJECT') {
      filters = {
        user_id: null,
        object_ids: [object.id],
        type: 'OWNER',
        source_id: null,
        period: period
      }
    }
    if (code === '0350_CONTENT') {
      filters = {
        source_id: dataSource + '',
        object_types: [contentType],
        start_at: moment(startDate).format('YYYY-MM-DD'),
        end_at: moment(endDate).format('YYYY-MM-DD')
      }
    }
    if (code === '0390') {
      filters = {
        source_id: dataSource + '',
        object_type: contentType,
        object_ids: objectList?.length>0?objectList.map(el=>el.id):[],
        period: period,
      }
    }
    if (code === '0400') {
      filters = {
        source_id: dataSource + '',
        period: period,
        exclusion_list: userList.map(el=>el.id)
      }
    }

    payload = {
      type: 'EXTRACTS',
      domain: document.location.protocol + "//" + document.location.hostname,
      lookup_code: code.split('_')[0],
      filters
    }
  }else{
    if (!['0240', '0370'].includes(code)) filters.tz = timezoneDetect.determine().name();
    if (code === '0370') filters.collection_instance_id = instance;
    if (!modalExtracts.includes(code)) {
      payload = {
        type: 'EXTRACTS',
        domain: document.location.protocol + "//" + document.location.hostname,
        lookup_code: code.split('_')[0],
        filters
      }
    }
  }
  
  return payload
}

export const checkRequired = ({
  code,
  dataSource,
  startDate,
  endDate,
  surveyTemplate,
  objectType,
  zone,
  contentType,
  period,
  trend,
  userNum,
  user,
  userList,
  team,
  object,
  surveyCategory,
}) => {
  if (code === '0080') {
    if (dataSource === 'none') return false;
    if (userNum.trim() === '') return false;
  }
  if (code === '0100') {
    return dataSource !== 'none' && trend !== 'none'
  }
  if (code === '0070') {
    if (dataSource === 'none') return false;
    if (!user) return false;
  }
  if (code === '0070_all') {
    if (dataSource === 'none') return false;
  }
  if (code === '0140') {
    return dataSource !== 'none' && team
  }
  if (['0150', '0280'].includes(code)) {
    return dataSource !== 'none' && period !== 'none'
  }
  if (code === '0220') {
    return surveyTemplate !== 'none' && surveyCategory !== 'none' && objectType !== 'none'
  }
  if (code === '0200') {
    return dataSource !== 'none' && objectType !== 'none'
  }
  if (code === '0200_CONTENT') {
    return dataSource !== 'none' && contentType !== 'none'
  }
  if (['0005', '0050', '0110', '0170', '0230', '0200_TABLE', '0210', '0250', '0300'].includes(code)) {
    return dataSource !== 'none'
  }
  if (code === '0340_OWNER' || code === '0340_STEWARD') {
    return user && period !== 'none'
  }
  if (code === '0340_OBJECT') {
    return object && period !== 'none'
  }
  if (code === '0350_CONTENT') {
    return dataSource !== 'none' && contentType !== 'none'
  }
  if (code === '0390') {
    return dataSource !== 'none' && period !== 'none' && contentType !== 'none'
  }
  if (code === '0400') {
    return dataSource !== 'none' && period !== 'none'
  }
  return true;
}


export const getModalParamsByCode = (code) => {
  if (code === '0070') {
    return ['dataSource', 'user', 'dateRange']
  }
  if (code === '0070_all') {
    return ['dataSource', 'dateRange']
  }
  if (code === '0080') {
    return ['dataSource', 'userNum', 'dateRange']
  }
  if (code === '0050') {
    return ['dataSource']
  }
  if (code === '0005') {
    return ['dataSource', 'dateRange']
  }
  if (code === '0100') {
    return ['dataSource', 'trend']
  }
  if (code === '0110') {
    return ['dataSource']
  }
  if (code === '0140') {
    return ['dataSource', 'team', 'dateRange']
  }
  if (code === '0150') {
    return ['dataSource', 'unusedPeriod']
  }
  if (code === '0170') {
    return ['dataSource']
  }
  if (code === '0200') {
    return ['dataSource', 'dateRange']
  }
  if (code === '0200_TABLE') {
    return ['dataSource', 'dateRange']
  }
  if (code === '0200_CONTENT') {
    return ['dataSource', 'contentType', 'dateRange']
  }
  if (code === '0210') {
    return ['dataSource']
  }
  if (code === '0220') {
    return ['dataSource', 'surveyCategory', 'surveyTemplate', 'objectType', 'dateRange']
  }
  if (code === '0230') {
    return ['dataSource']
  }
  if (code === '0250') {
    return ['dataSource', 'zone']
  }
  if (code === '0280') {
    return ['dataSource', 'period']
  }
  if (code === '0300') {
    return ['dataSource']
  }
  if (code === '0340_OWNER') {
    return ['owner', 'period']
  }
  if (code === '0340_STEWARD') {
    return ['steward', 'period']
  }
  if (code === '0340_OBJECT') {
    return ['dataSource', 'schema', 'object', 'period']
  }
  if (code === '0350') {
    return ['dataSource', 'contentType', 'dateRange']
  }
  if (code === '0350_CONTENT') {
    return ['dataSource', 'contentType', 'dateRange']
  }
  if (code === '0390') {
    return ['dataSource','contentType','objectList', 'period']
  }
  if (code === '0400') {
    return ['dataSource', 'unusedPeriod','userList']
  }
  return []
}


export const mapFilterValuesToStates = async ({filters, loadSchema, allSetters, modalParams}) => {

  const loadDetails = async ({ids}) => {
    let data = []
    await axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:"*",
          fq: `id:(${ids.join(' OR ')})`,
          rows: ids.length
        }}
      )
      .then(res => {
        data = res.data.response.docs
      })
      .catch(err => {
        console.log(err)
      })

    return data;
  }

  let state = {};
  
  if(filters.start_at){
    state.startDate = moment(filters.start_at)
  } 
  if(filters.startDate){
    state.startDate = moment(filters.startDate)
  }
  if(filters.end_at){
    state.endDate = moment(filters.end_at)
  }
  if(filters.endDate){
    state.endDate = moment(filters.endDate)
  }
  if(filters.user_id){
    state.user = await loadDetails({ids:[filters.user_id]})[0] 
  }
  if(filters.exclusion_list){
    state.userList = await loadDetails({ids:filters.exclusion_list})
  } 
  if(filters.numUsers){
    state.userNum = filters.numUsers
  }
  if(filters.sourceId){
    state.dataSource = filters.sourceId
    if(modalParams.includes('schema'))loadSchema({sourceId:filters.sourceId})
  }
  if(filters.source_id){
    state.dataSource = filters.source_id
    if(modalParams.includes('schema'))loadSchema({sourceId:filters.source_id})
  }
  if(filters.groupId){
    state.team = await loadDetails({ids:[filters.groupId]})[0]
  }
  if(filters.trend){
    state.trend = filters.trend
  }
  if(filters.period){
    state.period = filters.period
  }
  if(filters.zone){
    if(filters.zone === '*'){
      state.zone = 'All'
    }else{
      state.zone = filters.zone
    }
  }
  if(filters.surveyCategory){
    state.surveyCategory = filters.surveyCategory
  }
  if(filters.survey_template_id){
    state.surveyTemplate = filters.survey_template_id
  }
  if(filters.object_type){
    state.objectType = filters.object_type
    state.contentType = filters.object_type
  }
  if(filters.object_type_id){
    state.objectType = filters.object_type_id
  }
  if(filters.object_types){
    state.contentType = filters.object_types[0] 
  }
  if(filters.collection_instance_id){
    state.instance = filters.collection_instance_id
  }
  if(filters.object_ids){
    state.objectList = await loadDetails({ids:filters.object_ids})
    state.object = await loadDetails({ids:[filters.object_ids[0]]})[0]
  }
  Object.keys(state).forEach(key => {
    let setterName = 'set' + capitalizeFirstWord(key)
    allSetters[setterName]?.(state[key])
  })
  
}