import React, { useEffect, useRef } from 'react';
import { withTheme, CircularProgress, withStyles, Typography, Select, MenuItem,} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr'
import {  isInViewport, mapObjectName } from '../../../utilities'
import {getObjectCard} from '../utils'

const styles = theme => ({
  root: {

  },
  title:{
    fontSize:20,
    flexGrow:1,
    color:theme.palette.primary.main
  },
  errorText:{
    color:theme.palette.primaryText.main,
    marginTop:24,
  },
  timelineText:{
    color:theme.palette.primary.main,
    letterSpacing:2,
    marginBottom:8,
    fontSize:12
  },
  selector:{
    ...theme.components.selector
  }
})

const FollowedList = props => {

  const {
    classes,
    history,
    objectType,
    theme,
    state,
    dispatch,
    sessionData,
    headerTop
  } = props;


  let data = state.followedListData[objectType]
  let setData = d => dispatch({type:'set_followed_list',followedListData:{...state.followedListData,[objectType]:d}})

  const scrollRef = useRef()

  const loadListData = async ({sort=state.followedListSort,page=1}) => {
    setData({
      ...(page===1?{}:data),
      loading:true
    })
    let tmpData;
    await axiosCerebrum
      .get(
        `/api/follows`,
        {params:{
          object_type:objectType,
          user_id:sessionData.id,
          per_page:10,
          page:page,
          sort:sort
        }}
      )
      .then(response=>{
        if(response.data.items.length===0){
          setData({
            ...response.data
          })
          return;
        }
        tmpData = response.data;
      })
      .catch(error=>{
        console.log(error)
        setData({error:true})
      })
    
    if(!tmpData)return;

    let solrDetails = {};
    await axiosSolr.get(
        `/solr/search/select`,{
          params:{
            q:'*',
            fq:`id:(${tmpData.items.map(el=>el.object.id).join(' OR ')})`,
            fl:'*',
            start:0,
            rows:tmpData.items.length,
          }
        }
      )
      .then(solrRes=>{
        solrRes.data.response.docs.forEach(el=>{
          solrDetails[el.id] = el;
        })
      })
      .catch(error=>{
        console.log(error)
        setData({error:true})
      })

    let stats = {}
    await axiosCerebrum
      .get(
        '/api/stats',
        {params:{
          object_ids:tmpData.items.map(el=>el.object.id).join(','),
          per_page:tmpData.items.length
        }}
      )
      .then(response=>{
        response.data.items.forEach(el=>{
          stats[el.node_id] = el
        })
      })
      .catch(error=>{
        console.log(error)
      })

    setData({
      ...tmpData,
      items:[
        ...(page===1?[]:data.items),
        ...tmpData.items.map(el=>({
          ...el.object,
          ...(solrDetails[el.object.id]||{}),
          stats:stats[el.object.id]
        }))
      ]
    })
  }

  useEffect(()=>{
    if(!data.items && !data.loading){
      loadListData({})
    }
    // eslint-disable-next-line
  },[])

  const onChangeSort = (event) => {
    loadListData({sort:event.target.value})
    dispatch({type:'set_followed_list_sort',followedListSort:event.target.value})
  }

  const shouldPaginate = () => {
    return data.items && data.page<data.pages && !data.loading && isInViewport(scrollRef)
  }

  window.onscroll = () => {
    if(shouldPaginate()){
      loadListData({page:data.page+1})
    }
  }

  useEffect(()=>{
    if(shouldPaginate()){
      loadListData({page:data.page+1})
    }
  // eslint-disable-next-line
  },[data])



  return (
    <div className={classes.root}>
      <div style={{display:'flex',alignItems:'flex-start',paddingBottom:20,background:theme.palette.background.main,position:'sticky',top:headerTop,zIndex:9}}>
        <Typography className={classes.title} data-test-classname="ecosystem-title">{!isNaN(data?.total)?data.total+' ':''}{mapObjectName(objectType).replace(/_/g,' ')}(S) FOLLOWED</Typography>
        {
          data?.total!==0 && 
          <Select
            className={classes.selector}
            style={{marginLeft:16,width:150}}
            value={state.followedListSort}
            onChange={event=>onChangeSort(event)}
            data-test-classname="ecosystem-sort-selector"
            MenuProps={{
              getContentAnchorEl:null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                horizontal: "left"
              },
            }}
          >
            {
              [
                {dispName:'Recently followed',value:'END_DESC'},
              ].map(el=>(
                <MenuItem value={el.value}>
                  {el.dispName}
                </MenuItem>
              ))
            }
          </Select>
        }
      </div>
      {
        data && data.items?.map(el=>(
          getObjectCard(el,history)
        ))
      }
      {
        data?.total===0 && 
        <Typography className={classes.errorText}>No items found</Typography>
      }
      <div ref={scrollRef} style={{textAlign:'center',marginBottom:24}}>
        {
          data?.loading && 
          <CircularProgress style={{margin:'24px auto'}} color='secondary'/>
        }
      </div>
      
      {
        data?.error && 
        <Typography className={classes.errorText}>Error occurred loading followed items</Typography>
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(FollowedList));