import React, { useEffect, useReducer, useState } from 'react';
import { withStyles, LinearProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useGetSolr from '../../hooks/useGetSolr';
import Body from '../../components/Grid/Body/Body';
// import axiosCerebrum from '../../axios-cerebrum';
import TabBar from '../../components/UI/TabBar/TabBar';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import 'url-search-params-polyfill';
import { removeUrlQueryArg, setInitialState} from '../../utilities'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
// import timezoneDetect from 'jstimezonedetect';
import { palette, genericColour } from '../../components/Grid/Components/utils'
import { addHistory } from '../../HistoryManager';
import 'url-search-params-polyfill';
import axiosSolr from '../../axios-solr';
import SearchResultModal from '../../components/UI/SearchResultModal/SearchResultModal';
import { globalListenerRef } from '../../GlobalListenerRef';

const styles = theme => ({
  button: {
    margin: '8px 0px 8px 16px',
    height: '3rem',
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
});


const initialState = {
    tabState: 0,
    usageBySourceData:{},
    sources:[],
    // new grid
    sourceColourMap:{},
    gridShowMiniMap:true,
    usageTabState:0,
    usageData:{
      data:{
        selectedTile:'DATABASE',
        lineStyle:'line',
      },
      content:{
        selectedTile:'REPORT',
        lineStyle:'line',
      },
      people:{
        selectedTile:'DATA_USERS_LAST_7D',
        lineStyle:'line',
      }
    },
    usageLogScale:{ },
    governanceTabState:0,
    governanceUsageFilter:{
      0:['DAILY','WEEKLY','MONTHLY','INFREQUENT','UNUSED','NO USAGE'],
      1:['DAILY','WEEKLY','MONTHLY','INFREQUENT','UNUSED','NO USAGE'],
      2:['DAILY','WEEKLY','MONTHLY','INFREQUENT','UNUSED','NO USAGE'],
    },
    governanceData:{
      data:{},
      content:{},
      source:{}
    },
    issueData:{},
    usageSource:undefined,
    governanceSource:undefined,
    sourceLoadSort:'LAST_LOADED_AT_DESC',
    //////////////////////
    // demo
    lifecycleTabState:0,
    lifecycleDB:{0:1000,1:1000},
    runningCode:'',
  }

const reducer = (state, action) => {
  switch (action.type) {
    case "set_tab_state":
      return {
        ...state,
        tabState: action.tabState
      }
    case 'set_running_code':
      return {
        ...state,
        // runningCode:action.runningCode
      }
    case 'set_sources':
      return {
        ...state,
        sources:action.sources
      }
    case 'set_obejct_count_by_source':
      return {
        ...state,
        objectCountBySource:action.objectCountBySource,
        objectCountBySourceLoading:false,
        objectCountBySourceError:false
      }
    case 'set_grid_nodes':
      return {
        ...state,
        gridNodes:action.gridNodes
      }
    case 'set_grid_links':
      return {
        ...state,
        gridLinks:action.gridLinks
      }
    case 'set_grid_show_inactive':
      return {
        ...state,
        gridShowInactive: action.gridShowInactive
      }
    case 'set_grid_show_minimap':
      return {
        ...state,
        gridShowMiniMap:action.gridShowMiniMap
      }
    case 'set_grid_loading':
      return {
        ...state,
        gridLoading:action.gridLoading
      }
    case 'set_source_colour_map':{
      return {
        ...state,
        sourceColourMap:action.sourceColourMap
      }
    }
    case 'set_db_list':{
      return {
        ...state,
        dbList:action.dbList,
        dbListLoading:action.dbListLoading,
        dbListError:action.dbListError
      }
    }
    case 'set_usage_tab_state':{
      return {...state,usageTabState:action.usageTabState}
    }
    case 'set_usage_data':{
      return {...state,usageData:action.usageData}
    }
    case 'set_usage_by_source_data':{
      return {...state,usageBySourceData:action.usageBySourceData}
    }
    case 'set_usage_log_scale':{
      return {...state,usageLogScale:action.usageLogScale}
    }
    case 'set_usage_by_source_tab_state':{
      return {...state,usageBySourceTabState:action.usageBySourceTabState}
    }
    case 'set_usage_source':{
      return {...state, usageSource:action.usageSource}
    }
    case 'set_governance_tab_state':{
      return {...state, governanceTabState:action.governanceTabState}
    }
    case 'set_governance_usage_filter':{
      return {...state,governanceUsageFilter:action.governanceUsageFilter}
    }
    case 'set_governance_source':{
      return {...state, governanceSource:action.governanceSource}
    }
    case 'set_usage_count_by_object':
      return {...state,usageCountByObject:action.usageCountByObject}
    case 'set_usage_count_by_object_by_source':
      return {...state,usageCountByObjectBySource:action.usageCountByObjectBySource}
    case 'set_governance_data':{
      return {...state, governanceData:action.governanceData}
    }
    case 'set_issue_data':{
      return {...state,issueData:action.issueData}
    }
    case 'set_platform_sources':{
      return {
        ...state,
        platformSources:action.platformSources,
        platfomrSourcesLoading:action.platformSourcesLoading,
        platformSourcesError:action.platformSourcesError
      }
    }
    case 'set_source_load_sort':{
      return {
        ...state,
        sourceLoadSort:action.sourceLoadSort
      }
    }
    default:
      throw new Error("Action type not supported");
  }
}

const Grid = props => {


  const {
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
    variant
  } = props;
  
  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName')
  const persetUsageSource = urlSearch.get('usageSourceId')
  const presetUsageTile = urlSearch.get('usageSelectedTile')
  
  let storedInitialState = {...setInitialState(pageCache,initialState)};
  const [state, dispatch] = useReducer(reducer, storedInitialState);

  const [modalQuery, setModalQuery] = useState(undefined);
  const [modalFilter, setModalFilter] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalSort, setModalSort] = useState(undefined);
  const [modalObjectType, setModalObjectType] = useState(undefined);
  const [modalColumn, setModalColumn] = useState(undefined);
  const [modalForceFq, setModalForceFq] = useState(undefined);

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  // eslint-disable-next-line
  },[state])

  let usageTabOptions = ['DATA','CONTENT','BY SOURCES']
  let govTabOptions = ['ALL DATA','ALL CONTENT','BY SOURCE']

  const {
    data:dbList,
    loading:dbListLoading,
    error:dbListError,
    fetchList:dbListFetch
  } = useGetSolr({
    url:'/api/sources',
    params:{
      per_page:200,
      types:'TOOL,DATABASE,INTERNAL',
      sort:'ALPHABETICAL',
      active_flag:true
    },
    preventAuto:true
  })

  useEffect(()=>{ 
    let validList = dbList?dbList.items.filter(el=>el.id!==995):state.dbList
    dispatch({
      type:'set_db_list',
      dbList:validList,
      dbListError:dbListError,
      dbListLoading:dbListLoading,
    })
    dispatch({
      type:'set_sources',
      sources:dbList?dbList.items:state.sources||[],
    })
    if(dbList){
      let colourMap = {};
      validList.forEach((el,index)=>{
        if(el.id===1000){
          colourMap[el.id]=genericColour;
        }else{
          colourMap[el.id] = palette[index%palette.length]
        }
      })
      dispatch({
        type:'set_source_colour_map',sourceColourMap:colourMap
      })
      let validSources = validList.filter(el=>['DATABASE','TOOL'].includes(el.source_template.type));
      let databases = validList.filter(el=>el.source_template.type==='DATABASE');
      if(validSources.length>0){
        dispatch({type:'set_usage_source',usageSource:validSources[0].id});
      }
      if(databases.length>0){
        dispatch({type:'set_governance_source',governanceSource:databases[0].id});
      }
      let usageBySourceData = {};
      validSources.forEach(el=>{
        usageBySourceData[el.id] = state.usageBySourceData[el.id]||{}
      });
      dispatch({type:'set_usage_by_source_data',usageBySourceData})
      loadObjectCountPerSource()
    }
     // eslint-disable-next-line
  },[dbList,dbListLoading,dbListError])

  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.modalQuery ){
        setModalQuery(msg.data.modalQuery)
      }
      if(msg.data.modalSort){
        setModalSort(msg.data.modalSort)
      }else{
        setModalSort(undefined)
      }
      if(msg.data.modalFilter){
        setModalFilter(msg.data.modalFilter)
      }
      if(msg.data.modalObject){
        setModalObjectType(msg.data.modalObject)
      }
      if(msg.data.modalForceFq){
        setModalForceFq(msg.data.modalForceFq)
      }
      if(msg.data.modalColumn){
        setModalColumn(msg.data.modalColumn)
      }else{
        setModalColumn(undefined)
      }
      if(msg.data.modalQuery || msg.data.modalFilter || msg.data.modalObject) setModalOpen(true)
    }
    window.removeEventListener('message',globalListenerRef.gridMsgListener);
    globalListenerRef.gridMsgListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.gridMsgListener);
    return (()=>{window.removeEventListener('message',globalListenerRef.gridMsgListener);})
  // eslint-disable-next-line
  },[])


  const loadObjectCountPerSource = () => {

    dispatch({type:'set_obejct_count_by_source',objectCountBySourceLoading:true})

    let objectTypes = ['SCHEMA','TABLE','REPORT','DATASET','DATA_PIPELINE','ML_MODEL']
    let usageParam = ` AND usage_srt:(DAILY OR WEEKLY OR MONTHLY OR INFREQUENT)`
    if(variant==='governance')usageParam = ''
    axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q: '*:*',
            fq:`object_type_srt:(${objectTypes.join(' OR ')}) AND active_srt:YES AND -source_id_srt:998${usageParam}`,
            rows:0,
            'json.facet':{
              "sources": {
                "type": "terms",
                "field": "source_srt",
                "mincount":1,
                "limit":100,
                "facet":{
                  "source_id":{
                    "type":"terms",
                    "field":"source_id_srt",
                    "mincount":1,
                    "limit":100,
                  },
                  "data_count":{
                    "type":"query",
                    'q':"object_type_srt:(SCHEMA OR TABLE)",
                    "facet":{
                      "usages":{
                        "type":"terms",
                        "field":"usage_srt",
                        "mincount":1,
                        "limit":10,
                        "missing":true,
                      },
                    }
                  },
                  "content_count":{
                    "type":"query",
                    'q':"object_type_srt:(REPORT OR DATASET OR DATA_PIPELINE OR ML_MODEL)",
                    "facet":{
                      "usages":{
                        "type":"terms",
                        "field":"usage_srt",
                        "mincount":1,
                        "limit":10,
                        "missing":true,
                      },
                    }
                  },
                  "object_types":{
                    "type":"terms",
                    "field":"object_type_srt",
                    "mincount":1,
                    "limit":100,
                    "facet":{
                      "usage":{
                        "type":"terms",
                        "field":"usage_srt",
                        "mincount":1,
                        "limit":10,
                        "missing":true,
                      }
                    }
                  }
                }
              }
            }
          }
        }
      )
      .then(response=>{
        let countObj = {};
        let defaultUsageID;
        let shouldReplaceDefaultUsageId = true;
        let usageCountByObjectBySource = {}
        response.data.facets?.sources?.buckets?.forEach(el=>{
          let sourceId = el.source_id?.buckets[0]?.val;
          countObj[el.val] = {total:el.count, id:sourceId};
          countObj[el.val].objects = el.object_types?.buckets || [];
          if(!defaultUsageID)defaultUsageID = sourceId;
          if(sourceId===state.usageSource)shouldReplaceDefaultUsageId = false; 

          usageCountByObjectBySource[sourceId] = {'DATABASE':{},"TOOL":{}}
          el?.data_count?.usages?.buckets?.forEach(el2=>{
            usageCountByObjectBySource[sourceId]['DATABASE'][el2.val] = {count:el2.count};
          })
          if(el?.data_count?.usages?.missing?.count){
            usageCountByObjectBySource[sourceId]['DATABASE']['NO USAGE'] = {count:el.data_count.usages.missing.count}
          }
          el?.content_count.usages?.buckets?.forEach(el2=>{
            usageCountByObjectBySource[sourceId]['TOOL'][el2.val] = {count:el2.count};
          })
          if(el?.content_count?.usages?.missing?.count){
            usageCountByObjectBySource[sourceId]['TOOL']['NO USAGE'] = {count:el.content_count.usages.missing.count}
          }
          dispatch({type:'set_usage_count_by_object_by_source',usageCountByObjectBySource})

        })
        if(shouldReplaceDefaultUsageId && defaultUsageID){
          dispatch({type:'set_usage_source',usageSource:defaultUsageID})
        }
        dispatch({type:'set_obejct_count_by_source',objectCountBySource:countObj})
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_obejct_count_by_source',objectCountBySourceError:true})
      })
  } 

  useEffect(()=>{
    if(!state.usageCountByObjectBySource)return;
    if(!state.governanceSource)return;
    
    const checkIsNoUsage = (source) => {
      let isNoUsage = ['DAILY','WEEKLY','MONTHLY','INFREQUENT','UNUSED'].every(u=>!state.usageCountByObjectBySource?.[source.id]?.[source.source_template.type]?.[u]?.count)
      return isNoUsage;
    }

    let defaultSource;
    let validSourceList = state.dbList.filter(el=>['DATABASE','TOOL'].includes(el.source_template.type));
    for(let i=0;i<validSourceList.length;i++){
      let source = validSourceList[i];
      if(!checkIsNoUsage(source)){
        defaultSource = source;
        break;
      }
    }

    dispatch({type:'set_governance_source',governanceSource:defaultSource.id})
    // eslint-disable-next-line
  },[state.usageCountByObjectBySource, state.dbList])

  const loadUsageCountByObject = () => {
    let objectTypes = ['REPORT','DATASET','DATA_PIPELINE','ML_MODEL','SCHEMA','TABLE']
    axiosSolr
      .get(
        '/solr/search/select',{
          params: {
            q: '*:*',
            fq:`object_type_srt:(${objectTypes.join(' OR ')}) AND active_srt:YES AND -source_id_srt:998`,
            rows:0,
            'json.facet':{
              "data_count":{
                "type":"query",
                'q':"object_type_srt:(TABLE)",
                "facet":{
                  "usages":{
                    "type":"terms",
                    "field":"usage_srt",
                    "mincount":1,
                    "limit":10,
                    "missing":true,
                  },
                }
              },
              "content_count":{
                "type":"query",
                'q':"object_type_srt:(REPORT OR DATASET OR DATA_PIPELINE OR ML_MODEL)",
                "facet":{
                  "usages":{
                    "type":"terms",
                    "field":"usage_srt",
                    "mincount":1,
                    "limit":10,
                    "missing":true,
                  },
                }
              },
            }
          }
        }
      )
      .then(response=>{
        let countObj = {DATA:{},CONTENT:{}};
        response.data.facets?.data_count?.usages?.buckets?.forEach(el=>{
          countObj.DATA[el.val] = {count:el.count};
        } )
        if(response.data.facets?.data_count?.usages?.missing?.count){
          countObj.DATA['NO USAGE'] = {count:response.data.facets.data_count.usages.missing.count}
        }
        response.data.facets?.content_count?.usages?.buckets?.forEach(el=>{
          countObj.CONTENT[el.val] = {count:el.count};
        })
        if(response.data.facets?.content_count?.usages?.missing?.count){
          countObj.CONTENT['NO USAGE'] = {count:response.data.facets.content_count.usages.missing.count}
        }
        dispatch({type:'set_usage_count_by_object',usageCountByObject:countObj})
        
      })
  }

  useEffect(()=>{
    if(state.usageCountByObject)return;
    loadUsageCountByObject()
    // eslint-disable-next-line
  },[])
  
  useEffect(()=>{
    if(tabName && usageTabOptions.includes(tabName)){
      dispatch({type:'set_usage_tab_state',usageTabState:usageTabOptions.indexOf(tabName)})
      window.history.replaceState(null, null,removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
    }
    // eslint-disable-next-line
  },[tabName])

  useEffect(()=>{
    if(state.dbList && persetUsageSource && state.dbList.find(el=>el.id+''===persetUsageSource)){
      dispatch({type:'set_usage_source',usageSource:parseInt(persetUsageSource)})
      if(presetUsageTile){
        dispatch({type:'set_usage_by_source_data',usageBySourceData:{...state.usageBySourceData,[parseInt(persetUsageSource)]:{...(state.usageBySourceData[parseInt(persetUsageSource)]||{}),selectedTile:presetUsageTile } }})
      }
      window.history.replaceState(null, null,removeUrlQueryArg({url:window.location.toString(),keys:['usageSourceId','usageSelectedTile']}));
    }
    // eslint-disable-next-line
  },[persetUsageSource,presetUsageTile,state.dbList])


  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])


  let tabBarEl, title, label;
  if(variant==='usage'){
    title = 'Usage Insights'
    label = 'insights'
    tabBarEl = (
      <TabBar
        tabOptions={usageTabOptions}
        tabState={state.usageTabState}
        setTabState={value => dispatch({ type: 'set_usage_tab_state', usageTabState: value })}
        minWidth={200}
        maxWidth={200}
        disableUnderline={true}
      />
    )
  }else if(variant==='governance'){
    title = 'Governance Insights'
    label = 'governance'
    tabBarEl = (
      <TabBar
        tabOptions={govTabOptions}
        tabState={state.governanceTabState}
        setTabState={value => dispatch({ type: 'set_governance_tab_state', governanceTabState: value })}
        minWidth={200}
        maxWidth={200}
        disableUnderline={true}
      />
    )
  }else if(variant==='issue'){
    title = 'Issue Dashboard'
    label = 'issue_dashboard'
  }else if(variant==='data_load'){
    title = 'Data Load Dashboard'
    label = 'data_load'
  }


  useEffect(()=>{
    if(!state.dbList)dbListFetch()
    addHistory({url:window.location.pathname, iconLabel:label, title: title, subTitle:title,type:'application'})
    // eslint-disable-next-line
  },[])

  if (state.dbListLoading || state.objectCountBySourceLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.dbListError || state.objectCountBySourceError ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.dbList || !state.objectCountBySource){
    return <div></div>
  }
  
  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              title={title}
              subtitle={' '}
              minHeight={100}
              label={label}
              buttons={[<div style={{height:48,margin:8}}></div>]}
            />
          </div>)}
        tabBar={tabBarEl}
        body={
          <Body
            history={history}
            dispatch={dispatch}
            state={state}
            sessionData={sessionData}
            variant={variant}
          />
        }
      />
      <SearchResultModal
        key={modalQuery}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        propObjectType={modalObjectType} 
        propQuery={modalQuery} 
        propSort={modalSort}
        propFilter={modalFilter} 
        resultItemVariant={"simplified"}
        propColumn={modalColumn}
        forceFq={modalForceFq}
        hiddenComponents={['columnSelector','filterEdit','filter','listTitle','filterReset','searchBar','header','tab','cartButton','downloadButton']}
      />
    </div>
  )
}


Grid.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sessionData: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['usage','governance','issue','data_load']).isRequired,
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Grid));