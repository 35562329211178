import React, { } from 'react';
import { withTheme, withStyles, Button } from '@material-ui/core';
import { getIconComponent } from '../../../utilities';
import PropTypes from 'prop-types';

const styles = theme => ({
  button: {
    height:24,
    padding:"0px 8px 0px 12px",
    minWidth:0,
    background:theme.palette.primary.dark,
    borderRadius:12,
    alignItems:'center',
    display:'flex',
  },
  label: {
    fontSize:12
  },
})

function ToggleButton(props) {

  const {
    classes,
    theme,
    id,
    testID,
    options, 
    selectedValue,
    setSelectedValue,
  } = props;

  const selectedIndex = options.indexOf(selectedValue);

  return (
    <Button
      id={id}
      data-test-id = {testID}
      className={classes.button}
      classes={{label:classes.label}}
      color='primary'
      variant='contained'
      onClick={()=>{
        setSelectedValue(options[(selectedIndex+1)%options.length]);
      }}
    >
      <span style={{flexGrow:1,lineHeight:'normal'}}>{selectedValue}</span>
      <div style={{width:16,height:16,marginLeft:4}}>
        {getIconComponent({label:selectedIndex===0?'switch_left':'switch_right',size:16,colour:theme.palette.background.main})}
      </div>
    </Button>
  )
}

ToggleButton.propTypes = {
  classes: PropTypes.object.isRequired,
  testID: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedValue: PropTypes.string.isRequired,
  setSelectedValue: PropTypes.func.isRequired,

}

export default withTheme()(withStyles(styles)(ToggleButton));