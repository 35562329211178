import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles, CircularProgress, Select, MenuItem, Button} from '@material-ui/core';
import axiosSolr from '../../../axios-solr';
import SummaryTile from '../../UI/DQInsights/UI/SummaryTile';
import {getJobResult } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import timezoneDetect from 'jstimezonedetect';
import TrendChart from '../Components/TrendChart';
import { defaultFilterMap } from '../../BasicSearch/MainSearch/Utils/Utils';
import { CerebrumLongListLoader } from '../../../LongListLoader';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import InstanceSelector from './InstanceSelector';
import BasicSearch from '../../../containers/BasicSearch/BasicSearch';

const styles = theme => ({
  root: {

  },
  titleSelector:{
    ...theme.components.titleSelector,
    marginRight:8,
    maxWidth:'80vw',
  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  caption:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:24,
    whiteSpace:'pre-wrap',
    marginTop:4
  },
  columnHeader:{
    color:theme.palette.primary.main,
    letterSpacing:2,
    fontSize:12,
    marginRight:16,
  },
  itemText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    marginRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  listItem:{
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    height:47,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    '&:hover':{
      background:theme.palette.hovered.main,
      cursor:'pointer'
    }
  }
})

const ByCollection = props => {

  const {
    classes,
    state,
    dispatch,
    history,
  } = props;

  const loadValidCollections = () => {
    CerebrumLongListLoader({
      url:'/api/collections',
      params:{
        'enable_dq_dashboarding':true,
        'sort':'ALPHABETICAL'
      },
      per_page:500,
      onStartLoad:()=>{
        dispatch({type:'set_dq_collection_list',dqCollectionListLoading:true})
      },
      onFinishLoad:({data})=>{
        dispatch({type:'set_dq_collection_list',dqCollectionList:data})
      },
      onError:()=>{
        dispatch({type:'set_dq_collection_list',dqCollectionListError:true})
      }
    })
  }

  useEffect(()=>{
    if(!state.dqCollectionList && !state.dqCollectionListLoading){
      loadValidCollections();
    }
  // eslint-disable-next-line
  },[])


  const loadObjectsAndTiles = instance => {
    dispatch({
      type:'set_by_collection_objects',
      byCollectionObjectsLoading:true
    })
    dispatch({
      type:'set_by_collection_tiles',
      byCollectionTilesLoading:true
    })
    axiosSolr
      .get(
        `/solr/data_quality_test_result/select`,{
          params:{
            q:"*",
            fq:`${instance.parent.id}_kc_msrt:"${instance.name}"`,
            rows:0,
            'json.facet':{
              'objects':{
                type:'terms',
                field:'object_type_srt',
                limit:30
              },
              'dimensions':{
                type:'terms',
                field:'data_quality_dimension_srt',
                limit:30,
                'facet':{
                  'avg':"avg(last_data_quality_score_srt)"
                }
              },
              'overall_avg':"avg(last_data_quality_score_srt)"
            }
          }
        }
      )
      .then(response=>{
        let objects = response.data.facets.objects?.buckets?.map(el=>el.val) || []
        dispatch({
          type:'set_by_collection_selected_object',
          byCollectionSelectedObject:objects[0]
        })
        dispatch({
          type:'set_by_collection_objects',
          byCollectionObjects:objects
        })

        let dimensions = [
          {
            name:'overall',
            score:response.data.facets.overall_avg,
            count:response.data.response.numFound
          }
        ];
        dimensions.push(...(response.data.facets.dimensions?.buckets?.sort((a,b)=>a.val<b.val?-1:1).map(el=>{
          return {
            name:el.val,
            score:el.avg,
            count:el.count
          }
        }) || []));
        dispatch({
          type:'set_by_collection_selected_dimension',
          byCollectionSelectedDimension:dimensions[0]?.name
        })
        dispatch({
          type:'set_by_collection_tiles',
          byCollectionTiles: dimensions
        })
      })
      .catch(error=>{
        console.log(error);
        dispatch({
          type:'set_by_collection_objects',
          byCollectionObjectsError:true
        })
      })
  }

  const onSelectInstance = instance => {
    dispatch({type:'set_by_collection_selected_instance',byCollectionSelectedInstance:instance})
    dispatch({type:'set_by_collection_selected_dimension',byCollectionSelectedDimension:'overall'})
    dispatch({type:'set_by_collection_selected_object'})
    loadTrendChart({instance_id:instance.id,dimension:'overall'})
    loadObjectsAndTiles(instance)
  }


  const loadTrendChart = ({instance_id, dimension=state.byCollectionSelectedDimension, period=state.byCollectionPeriod}) => {
    let cacheID = `${instance_id}_${dimension}_${period}`
    if(state.byCollectionTrendCache[cacheID]){
      dispatch({type:'set_by_collection_trend',byCollectionTrend:state.byCollectionTrendCache[cacheID]})
      return;
    }
    dispatch({type:'set_by_collection_trend',byCollectionTrendLoading:true})
    axiosCerebrum
      .post(
        '/api/platformbatches/0',
        {
          "adhoc_args":{
            "job": "_0060_metrics",
            'agg_method':['AVG'],
            "tz": timezoneDetect.determine().name(),
            'object_id':instance_id,
            'days':period,
            "metric_type":`DATA_QUALITY_${dimension.toUpperCase()}_SCORE`
          }
        }
      )
      .then(response=>{
        let id = response.data.id;
        getJobResult(
          id,
          data=>{
            dispatch({type:'set_by_collection_trend',byCollectionTrend:data.result.data})
            dispatch({type:'set_by_collection_trend_cache',byCollectionTrendCache:{...state.byCollectionTrendCache,[cacheID]:data.result.data}})
          },
          ()=>{
            dispatch({type:'set_by_collection_trend',byCollectionTrendError:true})
          }
        )
      })
      .catch(error=>{
        console.log(error);
        dispatch({type:'set_by_collection_trend',byCollectionTrendError:true})
      })
  }


  let propFilter = `${state.byCollectionSelectedInstance?.parent?.short_name.replace(/\s/g,'_')}_kc_msrt:${state.byCollectionSelectedInstance?.name} AND active_srt:YES AND -dq_score_overall_srt:NO DQ SCORE OVERALL`
  let propColumn = 'dq_score_overall_srt,trust,usage_srt,owner_msrt,stewards_msrt'
  let propSort = 'dq_score_overall_srt desc'
  let selectedCollectionType = state.set_by_collection_selected_instance?.parent.category
  let instanceNaming = selectedCollectionType==='GLOSSARY'?"term":'instance'

  const headerComponent = (
    <div style={{display:'flex',alignItems:'center',marginBottom:24}}>
      <InstanceSelector
        state={state}
        dispatch={dispatch}
        onSelectInstance={onSelectInstance}
        history={history}
      />
      <Typography className={classes.header}>
        DATA QUALITY DASHBOARD
      </Typography>
    </div> 
  )
  
  const getBody = tabState => {
    if(!state.byCollectionSelectedInstance){
      return (
        <>
          {headerComponent}
          <Typography >
            Select an Instance to view its insights
          </Typography>
        </>
      )
    }
    if(tabState===0){
      return (
        <>
          {
            headerComponent
          }
          <Typography className={classes.header} style={{marginRight:10}}>
            LATEST {state.byCollectionSelectedInstance.name.toUpperCase()} DQ SCORE BY DIMENSION
          </Typography>
          <Typography className={classes.caption}>
            Data quality insights for the {state.byCollectionSelectedInstance.name} {instanceNaming}
          </Typography>
          <div style={{display:'flex',marginBottom:40,flexWrap:'wrap'}}>
            {
              state.byCollectionTilesLoading && 
              <div style={{textAlign:'center',height:140}}>
                <CircularProgress color='secondary'/>
              </div>
            }
            {
              state.byCollectionTilesError &&
              <Typography>Error occurred loading summary</Typography>
            }
            {
              state.byCollectionTiles?.map((el,i)=>{
                return(
                  <SummaryTile
                    key={i}
                    id={el.name}
                    name={el.name}
                    value={el.score}
                    testCount={el.count}
                    onClick={el.score && !state.byCollectionTrendLoading?()=>{
                      dispatch({type:'set_by_collection_selected_dimension',byCollectionSelectedDimension:el.name})
                      loadTrendChart({
                        instance_id:state.byCollectionSelectedInstance.id,
                        dimension:el.name
                      })
                    }:undefined}
                    isSelected={el.name===state.byCollectionSelectedDimension}
                  />
                )
              })
            }
          </div>

          <div style={{marginBottom:40}}>
            <Typography className={classes.header}>
              {state.byCollectionSelectedInstance.name.toUpperCase()} DQ SCORE OVER
              <Select
                className={classes.titleSelector}
                style={{marginLeft:12}}
                value={state.byCollectionPeriod}
                disableUnderline
                onChange={event=>{
                  dispatch({type:'set_by_collection_period',byCollectionPeriod:event.target.value})
                  loadTrendChart({
                    instance_id:state.byCollectionSelectedInstance.id,
                    period:event.target.value
                  })
                }}
              >
                {
                  [30,90,365].map(el=>(
                    <MenuItem disabled={state.byCollectionTrendLoading} className={classes.menuItem} value={el}>
                      {el} DAYS
                    </MenuItem>
                  ))
                }
              </Select>
            </Typography>
            <Typography className={classes.caption}>
              Average DQ score for {state.byCollectionSelectedInstance.name} over the last {state.byCollectionPeriod} days
            </Typography>
            {
              state.byCollectionTrendLoading &&
              <div style={{textAlign:'center',height:140}}>
                <CircularProgress color='secondary'/>
              </div>
            }
            {
              state.byCollectionTrendError &&
              <Typography>Error occurred loading chart</Typography>
            }
            {
              state.byCollectionTrend && 
              <TrendChart
                data={state.byCollectionTrend}
              />
            }
          </div>
        </>
      )
    }
    if(tabState===1){
      return (
        <>
          {
            headerComponent
          }
          <div style={{minHeight:700}}>
            {
              state.byCollectionObjectsLoading &&
              <div style={{textAlign:'center',height:140}}>
                <CircularProgress color='secondary'/>
              </div>
            }
            {
              state.byCollectionObjectsError &&
              <Typography>Error occurred loading details</Typography>
            }
            {
              state.byCollectionObjects?.length===0 &&
              <Typography>No data quality scores available</Typography>
            }
            {
              state.byCollectionObjects?.length>0 &&
              <>
                <div style={{display:'flex',alignItems:'center',marginBottom:4}}>
                  <Typography className={classes.header} style={{marginRight:10}}>
                    BREAKDOWN DQ SCORE BY {state.byCollectionSelectedInstance.name.toUpperCase()}
                  </Typography>
                  <Select
                    className={classes.titleSelector}
                    value={state.byCollectionSelectedObject}
                    disableUnderline
                    renderValue={value=>value.toUpperCase()}
                    onChange={event=>{
                      dispatch({type:'set_by_collection_selected_object',byCollectionSelectedObject:event.target.value})
                    }}
                  >
                    {
                      state.byCollectionObjects.map((el,i)=>{
                        return (
                          <MenuItem value={el}>
                            {el}
                          </MenuItem>
                        )
                      })
                    }
                  </Select>
                  <div style={{flexGrow:1}}></div>
                  <Button 
                    color='primary' 
                    variant="outlined" 
                    onClick={()=>history.push(`/basic_search?query=*&object=${state.byCollectionSelectedObject}&presetFilter=${propFilter}&presetColumn=${propColumn}`)}
                  >
                    OPEN IN SEARCH
                  </Button>
                </div> 
                <Typography className={classes.caption}>
                  DQ score for {state.byCollectionSelectedObject} linked to this {instanceNaming}.
                </Typography>
                <BasicSearch
                  key={state.byCollectionSelectedObject+state.byCollectionSelectedInstance?.id}
                  customID={'byCollectionSearch'}
                  initialView={'main_search'}
                  history={history} 
                  alwaysOpenNewTab={true}
                  removeContainerStyle={true}
                  propObjectType={state.byCollectionSelectedObject}
                  propQuery={`*`} 
                  propFilter={propFilter} 
                  propColumn={propColumn}
                  propSort={propSort}
                  propSelectedFilters={defaultFilterMap[state.byCollectionSelectedObject]?.filter(el=>!['source_type_srt','source_srt'].includes(el)).join(',')}
                  resultItemVariant={"simplified"}
                  hiddenComponents={['columnSelector','filterEdit','listTitle','searchBar','header','tab','cartButton','downloadButton']}
                />
              </>
            } 
          </div>
        </>
      )
    }
  }
  
  return (
    <div className={classes.root}>
      {
        state.dqCollectionListLoading && 
        <CircularProgress color='secondary'/>
      }
      {
        state.dqCollectionListError && 
        <Typography>Error occurred loading summary</Typography>
      }
      {
        state.dqCollectionList?.length===0 && 
        <Typography>No data quality scores available</Typography>
      }
      {
        state.dqCollectionList?.length>0 && 
        <div>
          <div style={{float:'left',position:'sticky',top:186}}>
            <VerticalTabBar
              tabOptions={['SUMMARY','DETAILS']}
              tabState={state.byCollectionTabState}
              setTabState={value=>dispatch({type:'set_by_collection_tab_state',byCollectionTabState:value})}
            />
          </div>
          <div style={{marginLeft:280}}>
            {getBody(state.byCollectionTabState)}
          </div>
        </div>
      }
    </div>
  )
}

ByCollection.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  sessionData: PropTypes.object
}

export default withStyles(styles)(ByCollection);