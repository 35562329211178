import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import TabBar from '../../UI/TabBar/TabBar';
import InfoBox from '../InfoBox/InfoBox';
import HistoryList from './HistoryList';
import ImportFileModal from '../ImportFileModal/ImportFileModal';
import TemplatesList from './TemplatesList';
import { removeUrlQueryArg } from '../../../utilities';

const styles = theme => ({
  
})

function History(props) {

  const {
    classes,
    state,
    dispatch,
    history
  } = props;

  const urlSearch = new URLSearchParams(window.location.search);
  const isOpenModal = urlSearch.get('open_import_modal');

  useEffect(()=>{
    if(isOpenModal){
      dispatch({type:'set_import_modal_open',importModalOpen:true})
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['open_import_modal']}));
    }
  // eslint-disable-next-line
  },[])


  return (
    <div className={classes.root}>
      <InfoBox dispatch={dispatch} state={state} history={history}/>  
      <div style={{marginLeft:-56}}>
        <TabBar
          tabOptions={['HISTORY','MY TEMPLATES']}
          // tabOptions={['HISTORY']}
          tabState={state.tabState}
          setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
          minWidth={200}
          maxWidth={200}
          disableUnderline={true}
        />
      </div>
      {
        state.tabState===0 && 
        <HistoryList  state={state} dispatch={dispatch} history={history} />
      }
      {
        state.tabState===1 && 
        <TemplatesList state={state} dispatch={dispatch} history={history}/>
      }
      <ImportFileModal state={state} dispatch={dispatch} history={history}/>
    </div>
  )
}

History.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default withStyles(styles)(History);