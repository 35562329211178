///////////////////////////////////////////////////////////////////////////////////////////////
// APP
export const GET_USER_LIST_START = 'GET_USER_LIST_START';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAIL = 'GET_USER_LIST_FAIL';

export const GET_GROUP_LIST_START = 'GET_GROUP_LIST_START';
export const GET_GROUP_LIST_SUCCESS = 'GET_GROUP_LIST_SUCCESS';
export const GET_GROUP_LIST_FAIL = 'GET_GROUP_LIST_FAIL';

export const GET_EXISTING_TAGS_START = 'GET_EXISTING_TAGS_START';
export const GET_EXISTING_TAGS_SUCCESS = 'GET_EXISTING_TAGS_SUCCESS';
export const GET_EXISTING_TAGS_FAIL = 'GET_EXISTING_TAGS_FAIL';

export const CACHE_TAG_DATA = 'CACHE_TAG_DATA';
///////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////
// SEARCH
export const UPDATE_SEARCH_VALUE = 'UPDATE_SEARCH_VALUE';

export const FETCH_RESULTS_START = 'FETCH_RESULTS_START';
export const FETCH_RESULTS_SUCCESS = 'FETCH_RESULTS_SUCCESS';
export const FETCH_RESULTS_FAIL = 'FETCH_RESULTS_FAIL';

export const UDPATE_CHECK_FILTER = 'UPDATE_CHECK_FILTER';
export const UPDATE_ALL_CHECK_FILTER = 'UPDATE_ALL_CHECK_FILTER';
export const UPDATE_SELECT_FILTER = 'UPDATE_SELECT_FILTER';

export const FILTER_RESULTS_START = 'FILTER_RESULTS_START';
export const FILTER_RESULTS_SUCCESS = 'FILTER_RESULTS_SUCCESS';
export const FILTER_RESULTS_FAIL = 'FILTER_RESULTS_FAIL';

export const LOAD_ADDITIONAL_SEARCH_RESULTS_START = 'LOAD_ADDITIONAL_SEARCH_RESULTS_START';
export const LOAD_ADDITIONAL_SEARCH_RESULTS_SUCCESS = 'LOAD_ADDITIONAL_SEARCH_RESULTS_SUCCESS';
export const LOAD_ADDITIONAL_SEARCH_RESULTS_FAIL = 'LOAD_ADDITIONAL_SEARCH_RESULTS_FAIL';

export const SEARCH_STATE_RESET = 'SEARCH_STATE_RESET';
export const SEARCH_HISTORY_FETCH = 'SEARCH_HISTORY_FETCH';
export const RESET_SEARCH_STATE_ON_MOUNT = 'RESET_SEARCH_STATE_ON_MOUNT';

export const SET_SEARCHPAGE_ACTIVE_TAB = 'SET_SEARCHPAGE_ACTIVE_TAB';
///////////////////////////////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////////////////////////////
// AUTH
export const LOGIN_AUTH_SUCCESS = 'LOGIN_AUTH_SUCCESS';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const SET_PERMISSIONS = 'SET_PERMISSIONS'

///////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////
// PAGE CACHE
export const STORE_PAGE_CACHE = 'STORE_PAGE_CACHE'
export const REMOVE_PAGE_CACHE = 'REMOVE_PAGE_CACHE'

////////////////////////////////////
// BULK EDIT
export const SET_BULK_EDIT_PARAM = 'SET_BULK_EDIT_PARAM';
export const CLEAR_BULK_EDIT_PARAM = 'CLEAR_BULK_EDIT_PARAM';
export const BULK_REMOVE_PAGE_CACHE = 'BULK_REMOVE_PAGE_CACHE';