import { palette } from "../../theme"

export const dataItems = ['TABLE','COLUMN','MACRO','PROCEDURE']
export const contentItems = ['REPORT','SHEET','DATASET','DATASET_FIELD','DATASET_TABLE','CONTENT_APP','FILE','DATA_PIPELINE','ML_MODEL']
export const userItems = ['USER']

export const graphPalette = {
  TABLE:'#3965C0',
  COLUMN:'#42A5F5',
  CONTENT:'#FFCF4A',
  REPORT:'#FFCF4A',
  CONTENT_APP:'#eb994f',
  CONTENT_CHILD:'#b5a782',
  SHEET:'#b5a782',
  FILE:'#ff8294',
  STORAGE:'#775DD0',
  DATASET:'#f01042',
  DATA_PIPELINE:'#3BA55C',
  DATABASE:'#ff9412',
  TOOL:'#bdb9c7',
  MACRO:'#9c322a',
  PROCEDURE:'#57629e',
  QUERY:'#c4be74',
  COLLECTION:'#a912bd',
  COLLECTION_INSTANCE:'#E57373',
  GLOSSRY:'#86d2e3',
  DATASET_FIELD:'#dc5389',
  DATASET_TABLE:'#e968ed',
  ML_MODEL:'#4d4dff',
  UNKNOWN:palette.primaryText.light
}