import { withTheme, withStyles, IconButton, Typography } from '@material-ui/core';
import React, {} from 'react';
import { getIconComponent } from '../../../utilities';
import KTooltip from '../KTooltip/KTooltip';

const styles = theme => ({
  // root:{
  //   borderRadius:4,
  //   display:'flex',
  //   justifyContent:'center',
  //   alignItems:'center',
  //   cursor:'pointer',
  //   background:theme.palette.chip.main,
  //   border:`1px solid ${theme.palette.chipBorder.main}`
  // }
})


const KSwimLaneControl = (props) => {
  const { data, theme, classes, id } = props;

  return (
    <div style={{display:'flex',alignItems:'center'}}>
      {
        data.label &&
        <Typography style={{paddingRight:8,color:theme.palette.primaryText.light,fontSize:18}}>
          {data.label}
        </Typography>
      }
      {
        !data.hideIcon && 
        <KTooltip title={data.tooltip}>
          <IconButton id={id} className={classes.root} onClick={data.onClick}>
            {getIconComponent({label:'edit',size:22,colour:theme.palette.primaryText.light})}
          </IconButton>
        </KTooltip>
      }
    </div>
  );
};


export default withTheme()(withStyles(styles)(KSwimLaneControl));