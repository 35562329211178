import React, {useState, useEffect} from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Button, Select, MenuItem,Stepper, Step, StepLabel, StepContent, InputBase, Checkbox, IconButton} from '@material-ui/core';
import { getIconComponent, copyToClipboard } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import HelpIcon from '@material-ui/icons/HelpOutline';
import MultiStringInput from './MultiStringInput';
import KTooltip from '../../UI/KTooltip/KTooltip';
import SourceSelector from '../UIComponents/SourceSelector';
import HostSelector from './HostSelector';
import { waitForHostCreated } from './utils';
import useAlert from '../../../hooks/useAlert';
// import InfoIcon from '@material-ui/icons/InfoOutlined'

const styles = theme => ({
  root:{
    minHeight:'100vh',
    paddingBottom:'20vh'
  },
  infoBox:theme.components.infoBox,
  infoHeader:theme.components.infoBoxHeader,
  infoDescription:theme.components.infoBoxDescription,
  inTextIcon:{
    cursor:'pointer',
    marginBottom:-8,
    marginLeft:8,
    verticalAlign:'middle'
  },
  block:{
    marginBottom:16,
    width:600,
    marginTop:16
  },
  inputBlock:{
    marginBottom:16
  },
  selector: {
    ...theme.components.selector,
    // width: 600,
    '& div div': {
      paddingLeft: 16,
      paddingTop:10,
      paddingBottom:10,
      fontSize:16
    },
    width:'100%',
    height:'40px',
  },
  textField:{
    ...theme.components.inputBase,
    // width:600,
    paddingLeft:0,
    width:'100%',
    '& input':{
      padding: '18px 16px',
      color:theme.palette.primaryText.main
    },
    '& textarea':{
      padding: '8px 16px',
    },
    '& div':{
      marginTop: '0px !important'
    },
  },
  helperText:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    paddingLeft:16,
    marginTop:3
  },
  errorBorder:{
    border:`1px solid ${theme.palette.error.main}`
  },
  stepButton:{
    padding:'6px 28px'
  },
  step: {
    width:700,
    '&:hover': {
    }
  },
  stepLabel:{
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
  stepper:{
    backgroundColor:theme.palette.background.main
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  table:{
    '& th':{
      color:theme.palette.primaryText.main
    },
    '& td':{
      color:theme.palette.primaryText.main
    },
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  menu:{
    height:600
  },
  selectedButton:{
    width:200
  },
  unSelectedButton:{
    width:200,
  },
  checkbox:{
    padding:0,
  },
  tooltip:{
    fontSize:13.75
  },
  instruction:{
    width: '30vw',
    display:'flex',
    flexDirection:'column'
  },
  multiStringInputChip:{
    display:'flex',
    height:32,
    border:`1px solid ${theme.palette.border.main}`,
    background:theme.palette.chip.main,
    borderRadius:16,
    padding:'0 8px 0 16px',
    alignItems:"center",
    overflow:'hidden',
    marginBottom:8,
    marginRight:8,
    color:theme.palette.primaryText.main
  }
})

const CreateSource = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    isEdit,
    dbFetch,
    history,
    generateScheduleText
  } = props;

  let selectedSource;
  let presetAttr;


  const processRawArgInput = (args, template) => {
    let tempArgs = template.args;
    let keys = Object.keys(args);
    let newArgs = {}
    for(let i=0; i<keys.length; i++){
      let argInfo = tempArgs.find(a=>a.populates===keys[i])
      if(argInfo){
        if(argInfo.type==='JSON'){
          newArgs[keys[i]] = JSON.stringify(args[keys[i]], null, 2)
        }else{
          newArgs[keys[i]] = args[keys[i]]
        }
      }
    }
    return newArgs
  }

  if(isEdit){
    selectedSource = state.selectedSource;
    let directConnectionSettings = {};
    selectedSource.connections.forEach(el=>{
      directConnectionSettings[el.connection_template.name]={
        '_username':el._username,
        '_password':'',
        'connection_string':el.connection_string,
        id:el.id,
        saved:el._username.trim()===''?false:true
      }
    });
    presetAttr = {
      sourceType:selectedSource.source_template.name,
      loadType:selectedSource.job_mode==='EXTRACT_AND_LOAD'?1:2,
      name:selectedSource.name,
      host:selectedSource.host.replace('http://','').replace('https://',''),
      full_load:typeof(selectedSource.full_load)==='boolean'?selectedSource.full_load:true ,
      argsInput:processRawArgInput(selectedSource.args, selectedSource.source_template),
      directConnectionSettings,
    }
  }

  window.onpopstate = () => {
    dispatch({type:'set_tab_state',tabState:0})
  }

  useEffect(()=>{
    history.push(`/admin/sources`)
    return () => {
      window.onpopstate = undefined;
    }
  // eslint-disable-next-line
  },[])

  ////// only in edit mode, stores original settings
  const [preLinkedDB, setPreLinkedDB] = useState([]);
  //////////

  const [sourceType, setSourceType] = useState(isEdit?presetAttr.sourceType:'Select a Source Type');
  const [loadType, setLoadType] = useState(isEdit?presetAttr.loadType:-1);
  const [sourceName, setSourceName] = useState(isEdit?presetAttr.name:'');
  const [host, setHost] = useState(isEdit?presetAttr.host:(state.selectedHost?.name||''));
  const [hostObj, setHostObj] = useState(state.selectedHost || undefined);
  const [hostID, setHostID] = useState(state.selectedHost?.id || undefined);
  const [hostInputRef, setHostInputRef] = useState(null);
  const [isFullLoad, setIsFullLoad] = useState(isEdit?presetAttr.full_load:true)
  const [argsInput, setArgsInput] = useState(isEdit?presetAttr.argsInput:{});
  const [directConnectionSettings, setDirectConnectionSettings] = useState(isEdit?presetAttr.directConnectionSettings:{});
  const [loadDatabase, setLoadDatabase] = useState([]); // direct load type linked db
  const [availableDB, setAvailableDB] = useState({});
  const [unlinkedDB, setUnlinkedDB] = useState([]);
  const [step, setStep] = useState(isEdit?(selectedSource.job_mode==='LOAD_FROM_EXCEL'?1:2):0);


  const [connectionInitialised, setConnectionInitialised] = useState(false)
  const [connectionTested, setConnectionTested] = useState({tested:false})
  const [createdSource, setCreatedSource] = useState(isEdit?selectedSource:undefined);
  const [editingMode, setEditingMode] = useState(isEdit?true:false);

  const [uploading, setUploading] = useState(false)

  const [drawerHeight, setDrawerHeight] = useState(0)
  const [drawerTopMargin, setDrawerTopMargin] = useState(0)
  const [drawerVisible, setDrawerVisible] = useState(true)

  const {
    sendAlert
  } = useAlert({})

  let isDeactive = true;

  if(createdSource?.active_flag===true || selectedSource?.active_flag===true){
    isDeactive = false;
  }
  if(!createdSource && !selectedSource){
    isDeactive = false;
  }

  window.onscroll = () => {
    setDrawerTopMargin(isEdit?Math.max(0, window.scrollY-200):window.scrollY)
  }

  useEffect(()=>{
    let header = document.getElementById('profile-header')
    if(header){
      let topOffset = header.getBoundingClientRect().height + 60 + (isEdit?196:0)
      setDrawerHeight(window.innerHeight - topOffset)
    }
  // eslint-disable-next-line
  },[])


  const getMetaDB = async (connection) => {
    let errorMsg;
    setAvailableDB({...availableDB, loading:true})
    await axiosCerebrum.get(
      `/api/connections/${connection.id}/meta`
    ).then(response=>{
      let newArr = [...(availableDB.data || [])]
      response.data.meta.forEach(d=>{
        if(!newArr.find(a=>a.name===d.name))newArr.push(d)
      })
      setAvailableDB({...availableDB,data:newArr,loading:false})
    }).catch(error=>{
      console.log(error)
      errorMsg = error.response && error.response.data ? error.response.data.errors:'Error occurred loading meta';
      setAvailableDB({...availableDB,error:errorMsg,loading:false})
      sendAlert({message:errorMsg,type:'error',timeout:9999999})
    })
  }

  useEffect(()=>{
    if(!createdSource)return;
    if(!['IDENTITY_STORE','TOOL'].includes(createdSource.source_template.type)){
      axiosCerebrum.get(
        `/api/databases?source_id=${createdSource.source_id}&per_page=100`
      ).then(response=>{
        setUnlinkedDB(response.data.items.filter(d=>d.active_flag===false).map(el=>({...el,name:el.name.toUpperCase()})))
        setPreLinkedDB(response.data.items.filter(d=>d.active_flag===true).map(el=>({...el,name:el.name.toUpperCase()})))
        setLoadDatabase(response.data.items.filter(d=>d.active_flag===true).map(el=>el.name.toUpperCase()))
      }).catch(error=>{
        console.log(error)
      })
    }
    if((createdSource.source_template.name==='POWER_BI' && loadType===1)){
      setLoadDatabase(createdSource.args.filter_workspaces || [])
    }
   // eslint-disable-next-line
  },[createdSource])

  useEffect(()=>{
    window.scrollTo(0,0)
    // eslint-disable-next-line
  },[])


  let steps = ['Select a Source Type']
  let isTool = false;

  if((step>0 && loadType===1) || (sourceType!=='Select a Source Type' && loadType===1)){
    steps = ['Select a Source Type','Select a Connection Type','Add Source Settings',  isEdit?'Update the Connection':'Setup a Connection']
  }
  if((step>0 && loadType===2) || (sourceType!=='Select a Source Type' && loadType===2)){
    steps = ['Select a Source Type','Select a Connection Type','Add Source Settings']
  }
  if(step>0 && ['TOOL','IDENTITY_STORE'].includes(editingMode?createdSource.source_template.type:state.templates.items.filter(el=>el.name===sourceType)[0].type)){
    isTool = true;
  }
  if(loadType===1){
    steps.push('Test Connection');
  }

  const shouldLoadMeta = () => ((!isTool || (createdSource && createdSource.source_template.name==='POWER_BI' && argsInput.filter_flag===true)) && loadType===1)

  if(shouldLoadMeta()){
    steps.push('Finalise Setup');
  }
  else{
    steps.push('Finish Setup');
  }

  if(sourceType==='MANUAL'){
    steps = ['Select a Source Type','Add Source Settings']
  }

  useEffect(()=>{
    window.scrollTo({top:0,behavior:'smooth'})
    if(step>=5 && shouldLoadMeta() && !availableDB.data){
      const loadAllMetas = async () => {
        for await (let connection of createdSource.connections){
          await getMetaDB(connection)
        }
      }
      loadAllMetas()
    }
  // eslint-disable-next-line
  },[step])

  useEffect(()=>{
    if(step===3  && isEdit && !connectionInitialised && loadType===1){
      axiosCerebrum
        .get(`/api/connections?source_id=${createdSource.source_id}`)
        .then(async response=>{
          if(response.data.total===0)return;
          let connectionSetting = Object.create(null)
          Object.keys(directConnectionSettings).forEach(k=>{
            connectionSetting[k] = directConnectionSettings[k]
          })
          let allConnected = true;
          for(let i = 0; i<response.data.items.length; i++){
            let connection = response.data.items[i];
            let connectionName = connection.connection_template.name
            if(connectionSetting[connectionName]){
              connectionSetting[connectionName].saved = true
            }
            if(!connection.active_flag){
              allConnected = false;
            }
          }

          if(!allConnected){
            for(let i = 0; i<response.data.items.length; i++){
              let connectionName = response.data.items[i].connection_template.name;
              connectionSetting[connectionName]['_password'] = '';
              connectionSetting[connectionName].saved = false;
            }
          }
          setConnectionInitialised(true)
          setDirectConnectionSettings(connectionSetting)
        })
        .catch(error=>{
          console.log(error)
        })
      if(!['TOOL','IDENTITY_STORE'].includes(createdSource.source_template.type)){
        let connectionSetting = {}
        let connectionTemplates = createdSource.connections;
        connectionTemplates.forEach(el=>{
          let template = el.connection_template;
          connectionSetting[template.description] = {
            id:el.id,
            '_username':el._username,
            '_password':'',
            'connection_string':'',
            saved:false,
          }
        })
        setDirectConnectionSettings(connectionSetting)
      }
    }
    // eslint-disable-next-line
  },[step])

  useEffect(()=>{
    // if(editingMode)return;
    if(!state.templates)return;
    if(sourceType==='Select a Source Type')return;
    if(!state.templates.items.filter(el=>el.name===sourceType)[0])return;
    let args = state.templates.items.filter(el=>el.name===sourceType)[0].args;
    const argInputsValue = editingMode?{...argsInput}:{};
    args.forEach(a=>{
      if(argInputsValue[a.populates] === undefined){
        argInputsValue[a.populates] = a.type==='JSON'?JSON.stringify(a.default):a.default;
      }
    })
    setArgsInput(argInputsValue)

  // eslint-disable-next-line
  },[sourceType,state.templates,editingMode, loadType])

  if(state.templateLoading)return <CircularProgress color='secondary' />
  if(state.templateError)return <Typography className={classes.normalText}>Error occurred loading data</Typography>
  if(!state.templates)return <div></div>
  if(state.templates.items.length===0)return <Typography className={classes.normalText}>No templates found</Typography>


  // let infoText = []

  const checkIsValidJson = text => {
    let isValid = true;
    try{
      JSON.parse(text)
    }catch(error){
      isValid = false
    }
    return isValid;
  }

  const connectionBlockFilled = name => {
    if(!directConnectionSettings[name])return false;
    let allFilled = true;
    let selectedTemplate = createdSource.connections.find(el=>el.connection_template.name===name)
    if(!selectedTemplate)return false;
    selectedTemplate.connection_template.args.forEach(arg=>{
      if(arg.optional===false && (!directConnectionSettings[name][arg.populates] || (directConnectionSettings[name][arg.populates].trim()+'')==='' ) ){
        allFilled = false;
      }
      if(arg.type==='JSON' && directConnectionSettings[name][arg.populates] && directConnectionSettings[name][arg.populates].trim()!==''){
        if(!checkIsValidJson(directConnectionSettings[name][arg.populates]))allFilled = false
      }
    })
    return allFilled;
  }

  const requiredFilled = index => {
    if(index===2){
      if(sourceType==='MANUAL'){
        return sourceName.trim()!=='';
      }
      let args = editingMode?createdSource.source_template.args:state.templates.items.filter(el=>el.name===sourceType)[0].args;
      let complete = true;
      args.filter(a=>a.extractOnly===false||(a.extractOnly===true && loadType===1)).forEach(a=>{
        if(a.optional===false && a.type!=='BOOLEAN'){
          if(a.type==='STRING_MULTI' && (!argsInput[a.populates] || argsInput[a.populates].length===0 )){
            complete = false
          }
          else if(!argsInput[a.populates] || (argsInput[a.populates]+'').trim()===''){
            complete = false
          }
        }
      })
      if(sourceName.trim()==='' || !isHostValid(host))complete = false;
      return complete;
    }
    if(loadType===1 && index===3){
      let allSaved = true;
      if(Object.keys(directConnectionSettings).length===0)allSaved = false;
      Object.keys(directConnectionSettings).forEach(key=>{
        if(!directConnectionSettings[key].saved){
          allSaved = false;
        }
      })
      return allSaved;
    }
    if(loadType===1 && index===4){
      return connectionTested.tested===true
    }
    if(loadType===1 && index===5){
      return loadDatabase.length>0
    }
  }



  const sourceTypeChanged = (val) => {
    setSourceType(val);
    if(val==='MANUAL'){
      setLoadType(1)
    }else{
      setLoadType(-1);
    }
    if(val==='POWER_BI'){
      setHost('app.powerbi.com')
    }
    if(val!=='Select a Source Type'){
      setStep(1);
    }
  }

  const checkJSONArgValid = args => {
    let tempArgs = editingMode?createdSource.source_template.args:state.templates.items.find(el=>el.name===sourceType).args;
    let keys = Object.keys(args);
    let valid = true
    for(let i=0; i<keys.length; i++){
      let argInfo = tempArgs.find(a=>a.populates===keys[i])
      if(argInfo.extractOnly===false||(argInfo.extractOnly===true && loadType===1)){
        if(argInfo.type==='JSON'){
          valid = checkIsValidJson(args[keys[i]])
        }
      }
      if(!valid)break;
    }
    return valid;
  }

  const processArgs = (args, argLoadType=loadType) => {
    let tempArgs = editingMode?createdSource.source_template.args:state.templates.items.find(el=>el.name===sourceType).args;
    let keys = Object.keys(args);
    let newArgs = {}
    for(let i=0; i<keys.length; i++){
      let argInfo = tempArgs.find(a=>a.populates===keys[i])
      if(argInfo.extractOnly===false||(argInfo.extractOnly===true && argLoadType===1)){
        if(argInfo.type==='JSON'){
          newArgs[keys[i]] = JSON.parse(args[keys[i]])
        }else{
          newArgs[keys[i]] = args[keys[i]]
        }
      }
    }
    return newArgs
  }

  const onInitialiseSource = (presetPayload={}, nextStep) => {
    let payload = {}
    let selectedTemplate = editingMode?createdSource.source_template:state.templates.items.filter(el=>el.name===sourceType)[0];
    payload.source_template_id = selectedTemplate.id;
    if(!checkJSONArgValid(argsInput))return;
    payload.args = processArgs(argsInput, presetPayload.loadType);
    payload.name = sourceName.trim();
    payload.full_load = isFullLoad;

    payload.enable_access_docs = false;
    payload.access_request_instructions = '';
    payload.access_request_url = '';
    payload.access_request_show_roles = false;
    if(!editingMode){
      payload.host = host.trim();
      if(hostID){
        payload.host_id = hostID;
      }
    }
    if(presetPayload && presetPayload.loadType){
      payload.job_mode = presetPayload.loadType===1?'EXTRACT_AND_LOAD':'LOAD_FROM_FILE';
    }else{
      payload.job_mode = loadType===1?'EXTRACT_AND_LOAD':'LOAD_FROM_FILE';
    }
    if(selectedTemplate.name==='MANUAL'){
      payload.job_mode = 'LOAD_FROM_EXCEL'
    }
    if(presetPayload && presetPayload.filter_workspaces){
      payload.args.filter_workspaces = presetPayload.filter_workspaces
    }
    if(editingMode){
      payload.cron = {
        hour:createdSource.cron.hour,
        minute:createdSource.cron.minute,
        day_of_month:createdSource.cron.day_of_month,
        month_of_year:createdSource.cron.month_of_year,
        day_of_week:createdSource.cron.day_of_week
      }

      payload.enable_access_docs = createdSource.enable_access_docs;
      payload.access_request_instructions = createdSource.access_request_instructions;
      payload.access_request_url = createdSource.access_request_url;
      payload.access_request_show_roles = createdSource.access_request_show_roles;
      payload.active_flag = true;
    }
    let request;
    if(editingMode)request = axiosCerebrum.put('/api/sources/'+createdSource.source_id,payload)
    else{request = axiosCerebrum.post('/api/sources',payload)}

    return request
      .then(async response=>{
        sendAlert({message:editingMode?"Settings saved":'Source created',type:'info'})
        setCreatedSource({...response.data,source_id:response.data.id});
        setEditingMode(true)
        dispatch({type:'set_sources_updated',sourcesUpdated:true})
        if(hostID && hostObj){
          await  axiosCerebrum
            .put(
              `/api/hosts/${hostObj.id}`,{
                "alternate_name":response.data.name,
                "description": hostObj?.description||'',
              }
            )
        }
        await waitForHostCreated(response.data.host_ids[0])

        if(response.data.source_template.type==='TOOL'){
          let tool = await axiosCerebrum.get(
            `/api/tools`,
            {params:{
              "source_id": response.data.id,
              "name":response.data.name
            }}
          );
          if(tool.data.items.length===0){
            await axiosCerebrum.post(
              `/api/tools`,
              {
                "host_id": response.data.host_ids[0],
                "description": "",
                "name": response.data.name
              }
            )
          }
        }
        if(response.data.source_template.type==='IDENTITY_STORE'){
          let store = await axiosCerebrum.get(
            `/api/identitystores`,
            {params:{
              "source_id": response.data.id,
              "name":response.data.name
            }}
          );
          if(store.data.items.length===0){
            await axiosCerebrum.post(
              `/api/identitystores`,
              {
                "host_id": response.data.host_ids[0],
                "description": "",
                "name": response.data.name
              }
            )
          }
        }
        if(nextStep){
          if(nextStep===-1){
            onFinish()
            return;
          }
          setStep(nextStep)
        }
      })
      .catch((error,response)=>{
        console.log(error)
        let msg = error.response && error.response.data && typeof(error.response.data.errors)==='string'? error.response.data.errors:`Error occurred ${editingMode?'updating':'creating'} Source`;
        if(error.response && error.response.status && error.response.status===409){
          msg = 'The Source already exists'
        }
        sendAlert({message:msg,type:'error'})
      })
  }

  const loadTypeChanged = (val) => {
    setLoadType(val);
    if(editingMode){
      onInitialiseSource({loadType:val},2);
      dispatch({type:'set_sources_updated',sourcesUpdated:true})
    }else{
      setStep(2);
    }
  }

  const onArgsChange = (value, argName, type) => {
    setArgsInput({...argsInput,[argName]:type==='INTEGER'?Number(value).valueOf():value})
  }


  const directConnecitonSettingChanged = (name, field, val) => {
    setConnectionTested({})
    setDirectConnectionSettings({
      ...directConnectionSettings,
      [name]:{
        ...directConnectionSettings[name],
        [field]:val,
        saved:false
      }
    });
  }

  const onSaveConnection = async names => {
    let newObj = {...directConnectionSettings}
    for await (let name of names){
      let payload = {
        "_password": directConnectionSettings[name]['_password'] || '',
        "_username": directConnectionSettings[name]['_username'] || '',
        "connection_string": directConnectionSettings[name]['connection_string'] || ''
      }
      await axiosCerebrum.put(
        `/api/connections/${createdSource.connections.find(el=>el.connection_template.name===name).id}`,
        payload
      // eslint-disable-next-line
      ).then(async response=>{
        // let metaResp;
        // if(createdSource.source_template.type!=='TOOL'){
        //   metaResp = await getMetaDB(createdSource.connections.find(el=>el.connection_template.name===name));
        // }else{
        //   metaResp = {}
        // }
        newObj = {
          ...newObj,
          [name]:{
            ...newObj[name],
            saved:true
          }
        }
        dispatch({type:'set_sources_updated',sourcesUpdated:true})
      // eslint-disable-next-line
      }).catch(error=>{
        console.log(error);
        newObj = {
          ...newObj,
          [name]:{
            ...newObj[name],
            saved:false,
          }
        }
        sendAlert({message:`Error occurred updating connection ${name}`,type:'error'})
      })
    }
    let allSaved = true;
    Object.keys(newObj).forEach(key=>{
      if(!newObj[key].saved){
        allSaved = false;
      }
    })
    if(allSaved){
        setStep(4);
    }
    setDirectConnectionSettings(newObj)
  }

  const onDBChecked = meta => {
    if(loadDatabase.includes(meta.name.toUpperCase())){
      setLoadDatabase(loadDatabase.filter(el=>el!==meta.name.toUpperCase()));
    }else{
      setLoadDatabase([...loadDatabase,meta.name.toUpperCase()]);
    }
  }


  const onFinish = () => {
    dispatch({type:'set_tab_state',tabState:0});
    dbFetch();
  }


  const onDBLinked = () => {
    const unlinkedPromises = [];
    const linkedPromises = [];
    let isPowerBI = createdSource && createdSource.source_template.name==='POWER_BI';
    if(!state.dbData || !state.dbData.items)return;
    setUploading(true)
    if(isPowerBI){
      setArgsInput({...argsInput,filter_workspaces:loadDatabase})
    }else{
      loadDatabase.forEach(el=>{
        let sourceID = createdSource.source_id
        let hostID = createdSource.host_ids[0]
        if(preLinkedDB.filter(d=>d.name===el).length===0){
          if(state.dbData.items.filter(d=>d.name.toUpperCase()===el&&(d.source_id+''===sourceID+'')).length===0){
            linkedPromises.push(
              axiosCerebrum.post(
                '/api/databases',
                {
                  "host_id": hostID,
                  "description": "",
                  "name": el
                }
              )
            )
          }
          if(unlinkedDB.filter(d=>d.name===el).length!==0){
            linkedPromises.push(
              axiosCerebrum.put(
                `/api/databases/${unlinkedDB.filter(d=>d.name===el)[0].id}`,
                {
                  "active_flag": true,
                  "description":unlinkedDB.filter(d=>d.name===el)[0].description,
                }
              )
            )
          }
        }
      })
      preLinkedDB.forEach(d=>{
        if(!loadDatabase.includes(d.name)){
          unlinkedPromises.push(
            axiosCerebrum.put(
              `/api/databases/${d.id}`,
              {
                "active_flag": false,
                "description":""
              }
            )
          )
        }
      })
    }

    Promise.all([...linkedPromises,...unlinkedPromises,onInitialiseSource(isPowerBI?{filter_workspaces:loadDatabase}:undefined)]).then(response=>{
      dispatch({type:'set_sources_updated',sourcesUpdated:true})
      onFinish();
      setUploading(false)
    }).catch(error=>{
      console.log(error);
      if(error.response && error.response.status && error.response.status===409){
        dispatch({type:'set_sources_updated',sourcesUpdated:true});
        onFinish();
        setUploading(false);
        return;
      }
      let msg = error.response && error.response.data ? error.response.data.errors:'Error occurred linking database';
      sendAlert({message:msg,type:'error',timeout:9999999})
      setUploading(false)
    })
  }

  const onTestConnection = () => {
    setConnectionTested({loading:true})
    axiosCerebrum.get(
      `/api/sources/${createdSource.source_id}/test`,
    )
    .then(response=>{
      setConnectionTested({tested:true})
    })
    .catch(error=>{
      console.log(error)
      setConnectionTested({ error:error.response && error.response.data ? error.response.data.errors:'Failed to connect'})
    })
  }


  const getStepLabelText = index => {
    switch(index){
      case 0:
        if(step!==0){
          return `Source Type: ${sourceType}`;
        }
        return steps[index];
      case 1:
        if(sourceType==='MANUAL'){
          return 'Add Source Settings';
        }
        if(step>1){
          return `Connection Type: ${loadType===1?'Direct connection':'Load from file system'}`
        }
        return 'Select a Connection Type';
      case 2:
        return steps[index];
      case 3:
        return steps[index];
      case 4:
        return steps[index];
      case 5:
        return steps[index];
      default:
        return ''
    }
  }


  const isHostValid = (value) => {
    if(!value || value.trim()==='')return false;
    if(hostID)return true;
    if(sourceType==='SNOWFLAKE' && !isEdit && (value.length<=23 || value.slice(-23)!=='.snowflakecomputing.com'))return false;
    let splitted = value.split('.');
    if(splitted[0].includes('https://')||splitted[0].includes('http://'))return false;
    return true;
  }


  const generateArgInput = args => {

    const argInputsArr = [];
    argInputsArr.push(
      <div style={{marginBottom:16}}>
        <Typography color='primary' style={{fontSize:13.75,marginBottom:8}}>Name</Typography>
          <InputBase
            className={classes.textField + (sourceName.trim()===''?' '+classes.errorBorder:'')}
            variant="filled"
            disabled={isDeactive}
            // type={'string'}
            value={sourceName}
            placeholder={'Enter the name of the Source'}
            onChange={(event)=>{setSourceName(event.target.value.trimStart())}}
            InputProps={{
              startAdornment: <HelpIcon style={{color:theme.palette.primaryText.light,width:24,height:24}}/>
            }}
          />
        <Typography style={{fontSize:12,color:sourceName.trim()===''?theme.palette.error.main:theme.palette.primaryText.light,paddingLeft:16,marginTop:3}}>Required</Typography>
      </div>
    )


    const isValueEntered = (value, type) => {
      if(type==='INTEGER')return !isNaN(value)
      if(type==='STRING_MULTI')return value && value.length>0
      if(type==='JSON')return value && checkIsValidJson(value)
      else{
        return value && value.trim()!==''
      }
    }

    if(sourceType!=='MANUAL'){
      let hostPlaceholder = 'Search for a host or enter a new host';
      if(sourceType==='SNOWFLAKE' && !isEdit){
        hostPlaceholder = '[your-account].snowflakecomputing.com'
      }
      if(sourceType==='DBT_CLOUD'){
        hostPlaceholder = 'cloud.getdbt.com/#/accounts/[your-account] '
      }
      argInputsArr.push(
        <div style={{marginBottom:16}}>
          <Typography color='primary' style={{fontSize:13.75,marginBottom:8}}>Host</Typography>
          <InputBase
            className={classes.textField + (!isHostValid(host)?' '+classes.errorBorder:'')}
            disabled={editingMode || createdSource || state.selectedHost}
            variant="filled"
            // type={'string'}
            value={host}
            placeholder={hostPlaceholder}
            onChange={(event)=>{
              setHost(event.target.value.trimStart())
              setHostID()
              setHostObj()
              setHostInputRef(event.currentTarget);
            }}
            InputProps={{
              startAdornment: <HelpIcon style={{color:theme.palette.primaryText.light,width:24,height:24}}/>
            }}
          />
          <HostSelector
            setHost = {host=>{
              setHost(host.name)
              setHostID(host.id)
              setHostObj(host)
            }}
            searchValue={host}
            popperRef={hostInputRef}
            state={state}
            inputRef={hostInputRef}
          />
          <Typography style={{fontSize:12,color: isHostValid(host)?theme.palette.primaryText.light:theme.palette.error.main,paddingLeft:16,marginTop:3}}>
            Required
            { (host.length<=23 || host.slice(-23)!=='.snowflakecomputing.com') && !hostID && !isEdit && host && sourceType==='SNOWFLAKE'?'. Invalid host name. Must end with .snowflakecomputing.com':''}
          </Typography>
        </div>
      )

      argInputsArr.push(
        <div style={{marginBottom:16}}>
            <Typography color='primary' style={{fontSize:13.75,marginBottom:8}}>Load Type</Typography>
            <Select
              className={classes.selector}
              style={{height:50}}
              value={isFullLoad}
              disabled={isDeactive}
              onChange={(event)=> setIsFullLoad(event.target.value)}
              disableUnderline
            >
              <MenuItem value={true}>
                Full load (Preferred - All metadata for every item)
              </MenuItem>
              <MenuItem value={false}>
                Partial load (Only metadata for items run in load period)
              </MenuItem>
            </Select>
            <Typography style={{fontSize:12,color:theme.palette.primaryText.light,paddingLeft:16,marginTop:3}}>Required</Typography>
          </div>
      )

      args
        .filter(a=>a.extractOnly===false||(a.extractOnly===true && loadType===1))
        .forEach(a=>{
          if((createdSource && createdSource.source_template.name==='POWER_BI') || sourceType==='POWER_BI'){
            if(a.populates==='filter_workspaces')return;
          }
          let inputEl, displayEl;
          let type;
          if(a.secret)type='password';
          if(a.type==='STRING')type='text';
          if(a.type==='INTEGER')type='number'
          switch(a.type){
            case 'STRING':
            case 'INTEGER':
            case 'JSON':
              inputEl = (
                <InputBase
                  className={classes.textField + (!isValueEntered(argsInput[a.populates],a.type) && a.optional===false?' '+classes.errorBorder:'')}
                  variant="filled"
                  type={type}
                  disabled={isDeactive}
                  value={argsInput[a.populates]}
                  onChange={(event)=>{
                    if(a.populates==='landing' && !event.target.value.match(/^[a-z0-9_]*$/))return;
                    onArgsChange(event.target.value,a.populates, a.type)
                  }}
                  multiline={a.type==='JSON'}
                  rows={a.type==='JSON'?5:undefined}
                  InputProps={{
                    startAdornment: <HelpIcon style={{color:theme.palette.primaryText.light,width:24,height:24}}/>
                  }}
                />
              )
              break;
            case 'BOOLEAN':
              inputEl = (
                <Checkbox
                  disabled={isDeactive}
                  onChange={(event, checked)=>onArgsChange(checked,a.populates,a.type)}
                  className={classes.checkbox}
                  color='primary'
                  checked={argsInput[a.populates]}
                />
              )
              break;
            case 'STRING_MULTI':
              if(a.allowed_values){
                inputEl = (
                  <div>
                    {
                      a.allowed_values.map(value => (
                        <div style={{display:'flex',alignItems:'center',marginBottom:8}}>
                          <Checkbox
                            disabled={isDeactive}
                            onChange={(event, checked)=>{
                              if(checked){
                                onArgsChange([...(argsInput[a.populates]||[]).filter(v=>v!==value),value],a.populates,a.type)
                              }else{
                                onArgsChange((argsInput[a.populates]||[]).filter(v=>v!==value),a.populates,a.type)
                              }
                            }}
                            color='primary'
                            style={{padding:0,marginRight:12}}
                            checked={(argsInput[a.populates]||[]).includes(value)}
                          />
                          <Typography style={{color:theme.palette.primaryText.main}}>{value}</Typography>
                        </div>
                      ))
                    }
                  </div>
                )
              }else{
                inputEl = (
                  <div>
                    <MultiStringInput
                      disabled={isDeactive}
                      onAdd={value=>{
                        onArgsChange([...(argsInput[a.populates]||[]).filter(v=>v!==value),value],a.populates,a.type)
                      }}
                      placeholder={ `Add ${a.name}`}
                    />
                  </div>
                )
                displayEl = (
                  <div style={{marginTop:12}}>
                    <Typography style={{textTransform:'uppercase',letterSpacing:2,fontSize:12,color:theme.palette.primaryText.main,marginBottom:8}}>{a.name} ADDED</Typography>
                    {
                      (!argsInput[a.populates] || argsInput[a.populates].length===0) &&
                      <Typography style={{fontSize:13.75,marginTop:8,marginBottom:16,color: !isValueEntered(argsInput[a.populates],a.type) && a.optional===false?theme.palette.error.main:theme.palette.primaryText.light}}>No {a.name} added</Typography>
                    }
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                      {
                        argsInput[a.populates] && argsInput[a.populates].map(el=>(
                          <div className={classes.multiStringInputChip}>
                            <KTooltip title={el===''?'Default':el} interactive={true}>
                              <Typography style={{overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',fontSize:13.75,color:theme.palette.primaryText.main}}>
                                {el===''?'Default':el}
                              </Typography>
                            </KTooltip>
                            <IconButton style={{padding:4,marginLeft:8}} onClick={()=>{onArgsChange(argsInput[a.populates].filter(a=>a!==el),a.populates,a.type)}}>
                              {getIconComponent({label:'clear',size:16,colour:theme.palette.primaryText.main})}
                            </IconButton>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                )
              }
              break;
            default:
          }

          let helperText = a.optional===false?'Required':'Optional'
          if(a.populates==='landing')helperText += '. Must be lower case, alphanumeric and underscore only.'
          if(a.type==='JSON')helperText += '. Must be valid JSON'
          let isPowerByMeta = createdSource && ((createdSource.source_template.name==='POWER_BI' && a.populates==='filter_flag') )
          argInputsArr.push(
            <div style={{marginBottom:16}}>
              <div style={{display:'flex',alignItems:'center',marginBottom:8}}>
                <Typography color='primary' style={{fontSize:13.75}}>{a.name} {isPowerByMeta?'(Step 6)':''}</Typography>
                <KTooltip title={a.description} classes={{tooltip:classes.tooltip}}  interactive={true}>
                  <div style={{width:20,height:20,marginLeft:12,flexGrow:1}}>
                    {getIconComponent({label:'info',size:20,colour:theme.palette.primary.main})}
                  </div>
                </KTooltip>
                {
                  a.type==='JSON' &&
                  <Button
                    color='primary'
                    variant="outlined"
                    disabled={isDeactive}
                    onClick={()=>{
                      try{
                        let value = JSON.stringify(JSON.parse(argsInput[a.populates],null,2), null, 2)
                        onArgsChange(value,a.populates, a.type)
                      }catch(error){
                        console.log(error)
                        sendAlert({message:'Not a valid JSON',type:'error'})
                      }

                    }}
                  >
                    FORMAT
                  </Button>
                }
              </div>
              {inputEl}
              {
                a.type!=='BOOLEAN' &&
                <Typography style={{fontSize:12,color: !isValueEntered(argsInput[a.populates],a.type) && a.optional===false?theme.palette.error.main:theme.palette.primaryText.light,paddingLeft:16,marginTop:3}}>{helperText}</Typography>
              }
              {displayEl}
            </div>
          )
        })
    }
    return argInputsArr;
  }


  const generateConnectionInput = templates => {

    const connectionInputsArr = [];
    templates.forEach((t,index)=>{
      const template = t.connection_template;
      const name = template.name;

      const getValue = (arg) => {
        let value = directConnectionSettings[name]?directConnectionSettings[name][arg.populates]:''
        // if(arg.secret && isEdit && !value)return '********'
        return value
      }


      const isValueEntered = (value, isPassword, type) => {
        if(isPassword && directConnectionSettings[name] && directConnectionSettings[name].saved){
          return true
        }
        else if(type==='JSON' && value.trim()!==''){
          return checkIsValidJson(value)
        }
        else{
          return value && value.trim()!==''
        }
      }

      connectionInputsArr.push(
        <div style={{marginTop:index===0?0:24}}>
          <div style={{display:'flex',alignItems:'center',marginBottom:16}}>
            <Typography color='primary' style={{fontSize:13.75}}>{name}</Typography>
            <KTooltip title={template.description} classes={{tooltip:classes.tooltip}}  interactive={true}>
              <div style={{width:20,height:20,marginLeft:12}}>
                {getIconComponent({label:'info',size:20,colour:theme.palette.primary.main})}
              </div>
            </KTooltip>
          </div>
          {
            t.connection_template.args.map(arg=>(
              <div className={classes.inputBlock}>
                <div style={{display:'flex',alignItems:'center',marginBottom:8}}>
                  <Typography color='primary' style={{fontSize:13.75}}>{arg.name}</Typography>
                  <KTooltip title={arg.description} classes={{tooltip:classes.tooltip}}  interactive={true}>
                    <div style={{width:20,height:20,marginLeft:12,flexGrow:1}}>
                      {getIconComponent({label:'info',size:20,colour:theme.palette.primary.main})}
                    </div>
                  </KTooltip>
                  {
                    arg.type==='JSON' &&
                    <Button
                      color='primary'
                      variant="outlined"
                      disabled={isDeactive}
                      onClick={()=>{
                        try{
                          let value = JSON.stringify(JSON.parse(getValue(arg),null,2), null, 2)
                          directConnecitonSettingChanged(name,arg.populates,value)
                        }catch(error){
                          console.log(error)
                          sendAlert({message:'Not a valid JSON',type:'error'})
                        }

                      }}
                    >
                      FORMAT
                    </Button>
                  }
                </div>
                <InputBase
                  className={classes.textField +  (!isValueEntered(getValue(arg), arg.secret, arg.type) && arg.optional===false?' '+classes.errorBorder:'')}
                  variant="filled"
                  type={arg.secret?"password":undefined}
                  placeholder={
                    arg.secret && isEdit && getValue(arg)==='' && directConnectionSettings[name] && directConnectionSettings[name].saved?
                      '********':
                      sourceType==='SNOWFLAKE' && arg.name==='Snowflake Account'?'Enter the Account Identifier part of your [your-account].snowflakecomputing.com':arg.name
                  }
                  value={getValue(arg)}
                  disabled={isDeactive}
                  onChange={(event)=>directConnecitonSettingChanged(name,arg.populates,event.target.value)}
                  InputProps={{
                    startAdornment: <HelpIcon style={{color:'#888',width:24,height:24}}/>
                  }}
                  multiline={arg.type==='JSON'}
                  rowsMax={arg.type==='JSON'?4:undefined}
                  rows={4}
                />
                <Typography className={classes.helperText} style={!isValueEntered(getValue(arg), arg.secret, arg.type) && arg.optional===false?{color:theme.palette.error.main}:undefined}>
                  {arg.optional===false?'Required':'Optional'}{arg.type==='JSON'?'. Must be valid JSON':''}
                </Typography>
              </div>
            ))
          }
        </div>
      )
    })

    connectionInputsArr.push(
      <div style={{display:'flex',alignItems:'center'}}>
        <Button
          disabled={templates.map(el=>el.connection_template.name).some(name=>!connectionBlockFilled(name)) || (isDeactive)}
          className={classes.stepButton}
          variant='contained'
          color='primary'
          onClick={()=>onSaveConnection(templates.map(el=>el.connection_template.name))}
        >
          SAVE
        </Button>
      </div>
    )

    return connectionInputsArr;
  }

  const generateTestConnectionComponent = () => {
    return (
      <div>
        <div style={{display:'flex',alignItems:'center',marginTop:16}}>
          {
            connectionTested.loading?
            <div style={{height:36,display:'flex',alignItems:'center',marginLeft:24}}>
              <CircularProgress color='secondary' style={{ width:24,height:24}}/>
            </div>
            :
            <Button className={classes.stepButton} style={{flexShrink:0}} variant='contained' color='primary' onClick={()=>onTestConnection()}>TEST CONNECTION</Button>
          }

          {
            connectionTested.tested===true &&
            <Typography style={{display:'flex', alignItems:'center',flexShrink:1,flexGrow:1,fontSize:13.75,marginLeft:32,whiteSpace:'pre-wrap',color:"#36ba4f"}}>
              {getIconComponent({label:'checked',size:24,colour:'#36ba4f'})}  Connection successful
            </Typography>
          }
          {
            connectionTested.error &&
            <Typography style={{display:'flex', alignItems:'center',flexShrink:1,flexGrow:1,fontSize:13.75,marginLeft:32,whiteSpace:'pre-wrap', wordBreak:'break-word',color:theme.palette.error.main}}>
              {getIconComponent({label:'info',size:24,colour:theme.palette.error.main})}<div style={{width:16}}></div> {connectionTested.error.trim()}
            </Typography>
          }
        </div>
        <Button
          classes={{disabled:classes.disabledButton}}
          disabled={connectionTested.tested!==true}
          className={classes.stepButton}
          style={{marginTop:16}}
          variant='contained'
          color='primary'
          onClick={()=>{
            setStep(5)
          }}
        >
          NEXT
        </Button>
      </div>
    )
  }

  const getStepContent = index => {
    let body;
    switch(index){
      case 0:
        body = (
          <div className={classes.block}>
            <SourceSelector
              onChangeSource={sourceTypeChanged}
              sourceList={state.templates.items.filter(el=>el.type!=='INTERNAL' && el.type!=='EXTERNAL_INTEGRATION').sort((a,b)=>{if(a.name<b.name)return -1;else{return 1}})}
            />
          </div>
        )
        break;
      case 1:
        if(sourceType==='MANUAL'){
          body = (
            <div className={classes.block}>
              {
                generateArgInput(editingMode?createdSource.source_template.args:state.templates.items.filter(el=>el.name===sourceType)[0].args)
              }
              <Button
                classes={{disabled:classes.disabledButton}}
                data-testid="nextButton"
                disabled={!requiredFilled(2)||(isDeactive)}
                className={classes.stepButton}
                variant='contained'
                color='primary'
                onClick={()=>{onInitialiseSource({},-1)}}
              >
                {editingMode?'SAVE':'SAVE & ADD SOURCE'}
              </Button>
            </div>
          )
          break;
        }
        body = (
          <div className={classes.block}>
            <div style={{display:'flex',marginBottom:24}}>
              {
                sourceType!=='Select a Source Type' &&  (editingMode?createdSource.source_template.extract_and_load:state.templates.items.find(el=>el.name===sourceType).extract_and_load)===true &&
                <Button
                  color={'primary'}
                  style={{marginRight:40}}
                  className={loadType===1?classes.selectedButton:classes.unSelectedButton}
                  disabled={isDeactive}
                  onClick={()=>{if(loadType!==1)loadTypeChanged(1)}}
                  variant={loadType===1?'contained':'outlined'}
                >
                  DIRECT CONNECT
                </Button>
              }
              <Button
                color={'primary'}
                className={loadType===2?classes.selectedButton:classes.unSelectedButton}
                onClick={()=>{if(loadType!==2)loadTypeChanged(2)}}
                variant={loadType===2?'contained':'outlined'}
                disabled={isDeactive}
              >
                LOAD FROM FILE
              </Button>
            </div>
          </div>
        )
        break;
      case 2:
        if(sourceType!=='Select a Source Type'){
          body = (
            <div className={classes.block}>
              {
                generateArgInput(editingMode?createdSource.source_template.args:state.templates.items.filter(el=>el.name===sourceType)[0].args)
              }
              <Button
                classes={{disabled:classes.disabledButton}}
                data-testid="nextButton"
                disabled={!requiredFilled(2)||(isDeactive)}
                className={classes.stepButton}
                variant='contained'
                color='primary'
                onClick={()=>{onInitialiseSource({},3)}}
              >
                {editingMode?'SAVE & NEXT':'NEXT'}
              </Button>
            </div>
          )
        }
        break;
      case 3:
        if(sourceType!=='Select a Source Type' && loadType===1){
          if(createdSource && createdSource.connections.length>0){
            body = (
              <div className={classes.block}>
                {
                  generateConnectionInput(createdSource.connections)
                }
                <Button
                  classes={{disabled:classes.disabledButton}}
                  disabled={!requiredFilled(3) || (isDeactive)}
                  className={classes.stepButton}
                  style={{marginTop:16}}
                  variant='contained'
                  color='primary'
                  onClick={()=>{
                    setStep(4)
                  }}
                >
                  NEXT
                </Button>
              </div>
            )
          }else{
            body = (
              <div className={classes.block}>
                <Typography className={classes.normalText}>No connection found</Typography>
                <Button disabled={isDeactive}  classes={{disabled:classes.disabledButton}} className={classes.stepButton} style={{marginTop:16}} variant='contained' color='primary' onClick={()=>setStep(4)}>NEXT</Button>
              </div>
            )
          }
          break;
        }
      // eslint-disable-next-line
      case 4:
        if(loadType===1){
          body = generateTestConnectionComponent()
        }else{
          body = (
            <div className={classes.block}>
              <Button className={classes.stepButton} disabled={isDeactive} variant='contained' color='primary' onClick={()=>onFinish()}>FINISH SETUP</Button>
            </div>
          )
        }
        break;
      case 5:
        if(shouldLoadMeta()){
          let metaName = '';
          if(createdSource && createdSource.source_template.name==='POWER_BI'){
            metaName = ' Workspace(s)'
          }
          if(createdSource && createdSource.source_template.name==='BIG_QUERY'){
            metaName = ' Project(s)'
          }
          body = (
            <div className={classes.block}>
              <Typography color='primary' style={{fontSize:13.75,marginBottom:10}}>Select one or more{metaName} to complete the setup. The list may take a while to load if you have a lot. </Typography>
              {
                availableDB.data && availableDB.data.map((el,index)=>(
                  <div style={{display:'flex',alignItems:'center',marginBottom:8}}>
                    <Checkbox
                      disabled={isDeactive}
                      onClick={()=>onDBChecked(el)}
                      color='primary'
                      style={{padding:0,marginRight:12}}
                      checked={loadDatabase.includes(el.name.toUpperCase())}
                    />
                    <Typography style={{color:theme.palette.primaryText.main}}>{el.name}</Typography>
                  </div>
                ))
              }
              {
                availableDB.loading &&
                <CircularProgress color='secondary' style={{width:24,height:24}}/>
              }
              {
                availableDB.error &&
                <Typography style={{color:theme.palette.error.main,fontSize:13.75}}>{availableDB.error}</Typography>
              }
              <div>
                <Button style={{marginTop:8}} classes={{disabled:classes.disabledButton}} disabled={loadDatabase.length===0 || uploading || (isDeactive)} className={classes.stepButton} variant='contained' color='primary' onClick={()=>{onDBLinked()}}>
                  {
                    uploading &&
                    <div style={{marginRight:16,height:16}}>
                      <CircularProgress style={{color:theme.palette.secondary.main,width:16,height:16}}/>
                    </div>
                  }
                  {
                    uploading?
                    'SETUP IN PROGRESS':
                    'FINISH SETUP'
                  }
                </Button>
                {
                  uploading &&
                  <Typography color='secondary' style={{fontSize:13.75,marginTop:8}}>The setup process may take a few minutes to complete depending on the source.</Typography>
                }
              </div>
            </div>
          )
        }else{
          body = (
            <Button style={{marginTop:16}} disabled={isDeactive} className={classes.stepButton} variant='contained' color='primary' onClick={()=>onFinish()}>FINISH SETUP</Button>
          )
        }
        break;
      default:
    }
    return body;
  }

  const labelClickable = index => {
    if(index===0)return !createdSource;
    if(index===1)return sourceType!=='Select a Source Type'
    if(index===2)return true;
    if(loadType===1 && index===3){
      if(createdSource && requiredFilled(2))return true;
      else{return false}
    }
    if(index===4){
      return createdSource &&  requiredFilled(2) &&  requiredFilled(3)
    }
    if(index===5){
      return createdSource &&  requiredFilled(4) &&  requiredFilled(3) &&  requiredFilled(2)
    }
    return false
  }

  const onLabelClick = index => {
    labelClickable(index) && setStep(index)
  }

  if(state.templateLoading){
    return  (
      <div style={{ textAlign: 'center', marginTop: '5%' }}>
        <CircularProgress color="secondary" />
        <Typography className={classes.normalText}>Loading</Typography>
      </div>
    )
  }
  if(state.templateError || !state.templates)return <Typography  className={classes.normalText}>Error occurred loading templates</Typography>

  let iframeUrl;

  switch(sourceType){
    case 'AZURE_DATA_FACTORY':
      if(loadType===1)iframeUrl = 'https://docs.kada.ai/home/azure-data-factory'
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/azure-data-factory-via-collector-method'
      break;
    case 'ATHENA':
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/athena-via-collector-method';
      break;
    case 'BIG_QUERY':
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/bigquery-via-collector-method'
      break;
    case 'DB2':
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/db2-via-collector-method'
      break;
    case 'DBT_CLOUD':
      if(loadType===1)iframeUrl = 'https://docs.kada.ai/home/dbt-cloud';
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/dbt-cloud-via-collector-method';
      break;
    case 'DBT_CORE':
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/dbt-core-self-hosted'
      break;
    case 'FIVETRAN':
      if(loadType===1)iframeUrl = 'https://docs.kada.ai/home/fivetran'
      break;
    case 'GREAT_EXPECTATIONS':
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/great-expectations-self-hosted';
      break;
    case 'GREENPLUM':
      if(loadType===1)iframeUrl = 'https://docs.kada.ai/home/greenplum'
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/greenplum-via-collector-method';
      break;
    case 'HEVO':
      if(loadType===1)iframeUrl = 'https://docs.kada.ai/home/hevo';
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/hevo-via-collector-method';
      break;
    case 'INFORMATICA':
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/informatica-via-collector-method-1';
      break;
    case 'MYSQL':
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/mysql-via-collector-method';
      break;
    case 'ORACLE':
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/oracle-via-collector-method-1';
      break;
    case 'ORACLE_ANALYTICS':
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/oracle-analytics-via-collector-method'
      break;
    case 'POWER_BI':
      if(loadType===1)iframeUrl = 'https://docs.kada.ai/home/powerbi'
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/power-bi-via-collector-method-1';
      break;
    case 'REDSHIFT':
      if(loadType===1)iframeUrl = 'https://docs.kada.ai/home/redshift'
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/redshift-via-collector-method-1';
      break;
    case 'SNOWFLAKE':
      if(loadType===1)iframeUrl = 'https://docs.kada.ai/home/snowflake'
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/snowflake-via-collector-method-1';
      break;
    case 'SQLSERVER':
      if(loadType===1)iframeUrl = 'https://docs.kada.ai/home/sql-server'
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/sql-server-via-collector-method-1';
      break;
    case 'SSIS':
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/ssis-via-collector-method';
      break;
    case 'SSRS':
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/ssrs-via-collector-method';
      break;
    case 'POSTGRES':
      if(loadType===1)iframeUrl = 'https://docs.kada.ai/home/postgres';
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/postgres-via-collector-method';
      break;
    case 'TABLEAU':
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/tableau-via-collector-method';
      break;
    case 'THOUGHTSPOT':
      if(loadType===2)iframeUrl = 'https://docs.kada.ai/home/thoughtspot-via-collector-method'
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {
        isEdit && createdSource?.job_mode!=='LOAD_FROM_EXCEL' &&
        <div className={classes.infoBox}>
          <Typography className={classes.infoHeader}>About this Source</Typography>
          <Typography className={classes.infoDescription}>
            {`Name: ${createdSource.alternate_name || createdSource.name}`}
            {createdSource.server_folder_paths?`\nStorage location: ${createdSource.server_folder_paths.landing}`:''}
            {
              createdSource.server_folder_paths &&
              <span
                className={classes.inTextIcon}
                onClick={()=>{
                  copyToClipboard(createdSource.server_folder_paths.landing)
                  sendAlert({message:'Copied to clipboard',type:'info'})
                }}
              >
                {getIconComponent({label:'copy',size:16,colour:theme.palette.primaryText.light})}
              </span>
            }
            {`\n${generateScheduleText(createdSource)===''?'Schedule: N/A':generateScheduleText(createdSource)}`}
          </Typography>
        </div>
      }

      <div style={{display:'flex',alignItems:'flex-start',justifyContent:'space-between'}}>

        <Stepper className={classes.stepper} activeStep={step} orientation="vertical">
          {steps.map((label, index) => (
            <Step className={classes.step} key={index}>
              <StepLabel className={classes.stepLabel} style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}} >
                <div style={{display:'flex',alignItems:'center'}}>
                  <Typography color='primary' style={{color:step<index?theme.palette.primaryText.light:undefined,fontSize:16,marginLeft:6,marginRight:12}}>{getStepLabelText(index)}</Typography>
                </div>
              </StepLabel>
              <StepContent>
                {getStepContent(index)}
              </StepContent>
            </Step>
          ))}
        </Stepper>

        {
          iframeUrl && loadType>0 &&
          <div className={classes.instruction} style={{top:drawerTopMargin,position:'relative', height:drawerHeight}}>
            <Button color='primary' style={{flex:'0 0',marginBottom:16, alignSelf:'flex-end'}} onClick={()=>setDrawerVisible(!drawerVisible)}>{drawerVisible?'HIDE INTEGRATION GUIDE':'SHOW INTEGRATION GUIDE'}</Button>
            <iframe  style={{ width:'100%', flex:'1 1', border:`1px solid ${theme.palette.listItemDivider.main}`, borderRadius:4, visibility:drawerVisible?"visible":"hidden"}} src={iframeUrl} title="instructions">
            </iframe>
          </div>
        }

      </div>

    </div>
  )
}

export default withTheme()(withStyles(styles)(CreateSource));
