import React from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import Details from '../Details/Details';
import Linked from '../Linked/Linked';
import NoteAdder from '../../UI/NoteAdder/NoteAdder'
import ListBody from '../ListBody/ListBody';
import VerifiesLinked from '../VerifiesLinked/VerifiesLinked';
import Map from '../Map/Map';
import Suggested from '../Suggested/Suggested';
import Governance from '../Governance/Governance';
import ChangeManager from '../../UI/ChangeManager/ChangeManager';
import { collectionIds } from '../../../utilities';
import JobTypeList from '../JobTypeList/JobTypeList';
// import Definition from '../Definition/Definition';

const styles = theme => ({
  description:{
    color:theme.palette.primaryText.light,
    wordBreak:'break-word',
    whiteSpace:'wrap',
    marginBottom:16,
    fontSize:13.75,
    marginTop:20
  },
  tooltip:{
    fontSize:13.75
  }
})

function Body(props) {

  const {
    classes,
    history,
    state,
    dispatch,
    sessionData,
    linkedFetch,
    setModifiedUser,
    tabOptions,
    fetchList,
    editable,
  } = props;

  let bodyContent;

  if(state.basicData.parent.category==='LIST'){
    bodyContent = (
      <ListBody
        state={state}
        dispatch={dispatch}
        history={history}
        setModifiedUser={setModifiedUser}
        fetchList={fetchList}
        linkedFetch={linkedFetch}
        editable={editable}
      />
    )
  }
  else if(state.basicData.parent.id===collectionIds.verifiedUseCase){
    switch (tabOptions[state.tabState]) {
      case 'DETAILS': // details
        bodyContent = (
          <Details
            state={state}
            dispatch={dispatch}
            history={history}
            fetchList={fetchList}
            linkedFetch={linkedFetch}
            editable={editable}
          />
        )
        break;
      case 'VERIFIED FOR':
        if(!state.linkedTabs)break;
        bodyContent = (
          <VerifiesLinked 
            key={'VERIFIES'}
            state={state}
            dispatch={dispatch}
            history={history}
            relation={'VERIFIES'}
            tabs={state.linkedTabs.verifies}
            tabState={state.verifiesTabState}
            setTabState={value=>{dispatch({type:'set_verifies_tab_state',verifiesTabState:value})}}
            linkedData={state.linkedData.verifies}
            setLinkedData={data=>dispatch({type:'set_linked_data',linkedData:{...state.linkedData, verifies:data}})}
          />
        )
        break;
      case 'NOT APPROVED FOR USE':
        if(!state.linkedTabs)break;
        bodyContent = (
          <VerifiesLinked 
            key={'NOT_VERIFIED_FOR'}
            state={state}
            dispatch={dispatch}
            history={history}
            relation={'NOT_VERIFIED_FOR'}
            tabs={state.linkedTabs.notVerifies}
            tabState={state.notVerifiesTabState}
            setTabState={value=>{dispatch({type:'set_not_verifies_tab_state',notVerifiesTabState:value})}}
            linkedData={state.linkedData.notVerifies}
            setLinkedData={data=>dispatch({type:'set_linked_data',linkedData:{...state.linkedData, notVerifies:data}})}
          />
        )
        break;
      case 'GOVERNANCE':
        bodyContent = (
          <Governance state={state} dispatch={dispatch} history={history}/>
        )
        break;
      case 'CHANGES':
        bodyContent = (
          <ChangeManager state={state} dispatch={dispatch} history={history}/>
        )
        break;
      default:
    }
  }
  else if(state.basicData.parent.id===collectionIds.jobType){
    switch (tabOptions[state.tabState]) {
      case 'DETAILS': // details
        bodyContent = (
          <Details
            state={state}
            dispatch={dispatch}
            history={history}
            fetchList={fetchList}
            linkedFetch={linkedFetch}
            editable={editable}
          />
        )
        break;
      case 'DATA':
        bodyContent = (
          <JobTypeList
            key='data'
            variant="data"
            history={history}
            state={state}
            dispatch={dispatch}
          />
        )
        break
      case 'CONTENT':
        bodyContent = (
          <JobTypeList
            key='content'
            variant="content"
            history={history}
            state={state}
            dispatch={dispatch}
          />
        )
        break;
      default:
    }
  }
  else{
    switch (tabOptions[state.tabState]) {
      case 'DETAILS': // details
        bodyContent = (
          <Details
            state={state}
            dispatch={dispatch}
            history={history}
            fetchList={fetchList}
            linkedFetch={linkedFetch}
            editable={editable}
          />
        )
        break;
      case 'KNOWLEDGE':
        bodyContent = (
          <NoteAdder
            history={history}
            object={state.basicData}
            state={state}
            dispatch={dispatch}
            modalOpen={state.noteModalOpen}
            setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
          />
        )
        break;
      case 'RELATED':
        bodyContent = (
          <Linked 
            state={state}
            dispatch={dispatch}
            history={history}
            linkedFetch={linkedFetch}
            fetchList={fetchList}
          />
        )
        break;
      case 'MAP':
        bodyContent = (
          <Map 
            state={state}
            dispatch={dispatch}
            history={history}
            sessionData={sessionData}
          />
        )
        break;
      case 'SUGGESTED':
        bodyContent = (
          <Suggested
            state={state}
            dispatch={dispatch}
            history={history}
            linkedFetch={linkedFetch}
          />
        )
        break;
      case 'GOVERNANCE':
        bodyContent = (
          <Governance state={state} dispatch={dispatch} history={history}/>
        )
        break;
      case 'CHANGES':
        bodyContent = (
          <ChangeManager state={state} dispatch={dispatch} history={history}/>
        )
        break;
      default:
    }
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

export default withTheme()(withStyles(styles)(Body));