import React from 'react';
import { withTheme, withStyles, Typography} from '@material-ui/core';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment'
import theme from '../../../../theme';

const styles = theme => ({
  legendBox:{
    width:16,
    height:16,
    marginRight:8
  },
  legendText:{
    fontSize:12,
    color:theme.palette.primaryText.main
  }
})

function HeatMap(props) {

  const {
    state,
    classes
  } = props;

  const generateData = () => {
    let arr = []
    state.statsData.contribution_heatmap.day.forEach((el,index)=>{
      // arr.push([el,Math.floor(Math.random()*40)])
      arr.push([el,state.statsData.contribution_heatmap.contribution[index]])
    })
    return arr;
  }

  let colours = ['#D7D8D8','#ABF1CE','#57E39D','#00D46A']

  let option = {
    legend:{
      show:false
    },
    visualMap: {
        min: 0,
        max: 3,
        type: 'piecewise',
        orient: 'horizontal',
        left: 'center',
        textStyle: {
          color: '#000'
        },
        show:false,
        inRange:{
          color:colours
        },
        outOfRange:{
          color:colours[3]
        }
    },
    calendar: {
        left:24,
        top:24,
        bottom:0,
        cellSize: 'auto',
        range: [state.statsData.contribution_heatmap.day[0],state.statsData.contribution_heatmap.day[state.statsData.contribution_heatmap.day.length-1]],
        height:120,
        width:400,
        splitLine: {show:false},
        yearLabel: {show: false},
        itemStyle: {color:'#D8D8D8',borderColor:theme.palette.background.main,borderWidth:3},
        monthLabel:{color:theme.palette.primaryText.main,fontWeight:'lighter'},
        dayLabel:{color:theme.palette.primaryText.main,fontWeight:'lighter'},
    },
    series: {
        type: 'heatmap',
        coordinateSystem: 'calendar',
        data: generateData(),
    },    
    tooltip: {
      show:true,
      formatter:el=>{
        return `${moment(el.value[0]).format('ll')}: ${el.value[1]}`
      }
    },
  }
  
  return (
    <div>
    <ReactEcharts
      style={{height:150}}
      notMerge={true}
      option={option}
    />
    <div style={{display:'flex',alignItems:'center',marginTop:4}}>
      <Typography className={classes.legendText} style={{marginRight:8}}>Contribution: Less</Typography>
      {
        colours.map(el=>(
          <div className={classes.legendBox} key={el} style={{background:el}}>
          </div>
        ))
      }
      <Typography className={classes.legendText}>More</Typography>
    </div>
    
    </div>
  )
}

export default withTheme()(withStyles(styles)(HeatMap));