import React,{useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {withTheme, Typography, withStyles, Button, Modal, Paper, Select, MenuItem, CircularProgress } from '@material-ui/core';
import {getIconComponent, toTitleCase, mapObjectName, sendMessage} from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
import {checkFilled,EditFields} from '../CollectionInstanceEditFields/CollectionInstanceEditFields';
import 'url-search-params-polyfill';
import NoteAdderList from './NoteAdderList';
import { getIconLabel } from '../SearchResults/utils';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  modalContainer:{
    width:880,
    maxWidth:'85vw',
    margin:'auto',
    marginTop:'15vh',
    outline:'none'
  },
  formBody:{
    // minHeight:'30vh',
    paddingLeft:24,
    paddingBottom:8,
    paddingRight:0,
    paddingTop:0,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    overflow:'hidden'
  },
  formContent:{
    display:'flex',
    flexDirection:'column',
    maxHeight:'80vh',
    overflowY:'auto',
    overflowX:'hidden',
  },
  customScroll:{
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.light
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      background: theme.palette.primaryText.light,
      borderRadius:4,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{
      
    },
    '&::-webkit-scrollbar': {
      width: 5
    },
  },
  modalTitle:{
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:4,
  },
  modalSubTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  selector:{
    ...theme.components.titleSelector,
  },
  typeContainer:{
    display:'flex',
    alignItems:'center',
    padding:16,
    cursor:'pointer',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  inputTitle:{
    fontSize:16,
    color:theme.palette.primary.main,
    marginBottom:8
  },
  textFieldContainer:{
    flexGrow:1,
    flexShrink:1
  },
  inputBase:{
    ...theme.components.inputBase,
    '& textarea':{
      marginBottom:6,
      marginLeft:16,
      marginTop:6
    },
    '& input':{
      margin:6,
      marginLeft:16
    },
    width:'100%'
  },
  helperText:{
    fontSize:12,
    marginLeft:16,
    color:theme.palette.primaryText.light
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  detailHeader:{
    color:theme.palette.primary.main,
    fontSize:12,
    letterSpacing:2,
    marginBottom:8,
    marginTop:16
  },
  detailBodyText:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    marginBottom:24,
    whiteSpace:"pre-wrap"
  },
  card:{
    padding:16,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:3,
    marginBottom:24,
    paddingBottom:8
  },
  expansionPanel:{
    padding:0,
    background:theme.palette.background.main,
    boxShadow:'none',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    '&::before': {
      background: 'none'
    }
  },
  expansionPanelExpanded:{
    padding:0,
    background:theme.palette.background.main,
    '&::before': {
      background: 'none'
    }
  },
  expansionSummary:{
    padding:0,
    width:'100%',
  },
  summaryExpanded:{
    margin:`0px !important`
  },
  summaryRoot:{
    backgroundColor: theme.palette.background.main,
  },
  summaryContent:{
    margin:`0px !important`,
    overflow:'hidden'
  },
  rootExpanded:{
    border:`1px solid ${theme.palette.border.main}`,
    margin:`0px !important`,
    marginBottom:`1px !important`,
  },
  detailRoot:{
    background:theme.palette.background.main,
    flexDirection:'column',
    alignItems:'flex-start',
    paddingBottom:12,
    paddingLeft:56
  },
  timeChip:{
    width:'max-content',
    marginRight:8,
    marginBottom:2,
    flexShrink:1,
    display:'flex',
    alignItems:'center',
    padding:'1px 8px',
    height:26,
    marginTop:2,
    boxSizing:'border-box',
    background:theme.palette.chip.main,
    borderRadius:16,
    border:`1px solid ${theme.palette.chipBorder.main}`
  },
  timeChipText:{
    color:theme.palette.primaryText.main,
    fontSize:13.75
  },
  sectionHeader:{
    letterSpacing:2,
    fontSize:12,
    marginBottom:8,
    color:theme.palette.primaryText.light
  },
  emptyText:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
  },
  clickableText:{
    width:"max-content",
    flexGrow:'0 !important',
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline'
    }
  },
  listItemContainer:{
    height:47,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:'pointer',
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  bannerText:{
    fontSize:16,
    color:theme.palette.primaryText.main
  },
  bannerButton:{
    padding:'2px 4px',
    marginBottom:-2
  },
  tooltip:{
    fontSize:13.75
  }
})

function NoteAdder(props) {

  const {
    theme,
    classes,
    object,
    state,
    dispatch,
    history,
    modalOnly,
    modalOpen,
    setModalOpen,
    onSaveNote
  } = props;


  const [validTypes, setValidTypes] = useState({});
  const [type, setType] = useState('');
  const [values, setValues] = useState({});

  const [internalModalOpen, setInternalModalOpen] = useState(false);

  const [createdNote, setCreatedNote] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [userMap, setUserMap] = useState({})
  const [collectionMap, setCollectionMap] = useState({})

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  useEffect(()=>{
    setValues({});
  },[type])

  const loadTypes = () => {
    setValidTypes({loading:true});
    axiosCerebrum
      .get(`/api/collections?category=KNOWLEDGE`)
      .then(response=>{
        setValidTypes({data:response.data.items.sort((a,b)=>a.name==='Question'?-1:1)});
       
      })
      .catch(error=>{
        console.log(error);
        setValidTypes({error:true})
      })
  }

  useEffect(()=>{
    if(!validTypes.data)return;
    if(modalOpen && modalOpen.isQuestion){
      setType(validTypes.data.find(el=>el.name==='Question').id);
    }else{
      setType('')
    }
  },[validTypes,modalOpen])

  useEffect(()=>{
    if((modalOpen || internalModalOpen || !modalOnly) && !validTypes.data ){
      loadTypes();
    }
  // eslint-disable-next-line
  },[modalOpen,internalModalOpen])


  const onCancel = () => {
    setModalOpen(false);
    setInternalModalOpen(false);
    setType('');
    setValues({});
    setCreatedNote();
  }

  const onSave = async () => {
    let onCreateError;
    let createdObject = createdNote;
    let payload = {}
    payload.collection_id = type;
    payload.description = (values['description']||'').replace(/^(<br>|\s)*/,'').replace(/(<br>|\s)*$/,'');
    payload.name = values['name'];
    payload.properties = {};
    payload.source_id = 1000;
    let properties = validTypes.data.find(el=>el.id===type).properties;
    for(let i=0; i<properties.length; i++){
      let propertyId = properties[i].id;
      let required = properties[i].required;
      if(!required && (values[propertyId]===''||values[propertyId]==='placeholder'))continue;
      payload.properties[propertyId] = values[propertyId];
    }
    setCreating(true);
    let createErrorMsg = 'Error occurred creating the item, please try again.'
    if(!createdNote){
      await axiosCerebrum
        .post(
          '/api/collectioninstances',
          payload
        )
        .then(response=>{
          setCreatedNote(response.data)
          createdObject = response.data;
        })
        .catch(error=>{
          console.log(error)
          if(error.response && error.response.status && error.response.status===409){
            createErrorMsg = `The name already exists. Make the name field unique`
          }
          onCreateError = true;
        })
    }
    if(onCreateError){
      setAlertMessage(createErrorMsg)
      setAlertOpen(true);
      setCreating(false);
      return;
    }
    await axiosCerebrum
        .put(`/api/me/follows/${createdObject.id}?type=OPT_IN`)
        .catch(error=>{console.log(error)})
    await axiosCerebrum
      .put(
        `/api/collectioninstances/${createdObject.id}/related?relationship=MEMBERS&object_id=${object.id}`
      )
      .then(response=>{
        if(onSaveNote){
          onSaveNote();
        }else{
          setTimeout(()=>{
            sendMessage({[`load-note-${state.basicData.id}`]:true, note:JSON.stringify(createdObject)})
          },1000)
        }
        let name = values['name']
        if(name.length>50)name = name.slice(0,50)+'...'
        window.postMessage({reload_sidebar:'knowledge'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
        sendAlert({message:`${validTypes.data.find(el=>el.id===type).name} "${name}" successfully added`,type:'info'})
        onCancel()
      })
      .catch(error=>{
        console.log(error);
        setAlertMessage('Error occurred linking the item, please try again.')
        setAlertOpen(true);
      })
    setCreating(false);
  }

  const onClickAskQuestion = () => {
    setInternalModalOpen(true);
    if(validTypes && validTypes.data){
      let question = validTypes.data.find(el=>el.name==='Question')
      question && setType(question.id)
    }
  }

  const onClickContribute = () => {
    setInternalModalOpen(true);
    setType('')
  }


  return (
    <div className={classes.root}>
      {
        !modalOnly &&
        <NoteAdderList
          object={object}
          state={state}
          dispatch={dispatch}
          history={history}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          onClickAskQuestion={onClickAskQuestion}
          onClickContribute={onClickContribute}
        />
      }
      
      <Modal 
        open={(modalOpen && modalOnly) || internalModalOpen} 
        onClose={()=>onCancel()}
        disableBackdropClick={true}
      >
        <div className={classes.modalContainer}>
          <Paper className={classes.formBody}>
            {
              type==='' && 
              <div className={classes.formContent}>
                <div className={classes.customScroll} style={{flexGrow:1,overflow:'auto',paddingRight:24,paddingTop:24,paddingBottom:24}}>
                  <div style={{display:'flex',alignItems:'flex-start'}}>
                    <div style={{flexGrow:1,marginRight:16}}>
                      <Typography className={classes.modalTitle}>Add Knowledge</Typography>
                      <Typography className={classes.modalSubTitle}>Ask a question or add documentation</Typography>
                    </div>
                    <Button color='primary' variant='outlined' style={{width:96}} onClick={onCancel}>CLOSE</Button>
                  </div>
                  
                  {
                    validTypes.error && 
                    <Typography  style={{color:theme.palette.primaryText.main}}>Error occurred loading the form.</Typography>
                  }
                  {
                    validTypes.loading && 
                    <div style={{display:'flex',justifyContent:'center'}}>
                      <CircularProgress color='secondary'/>
                    </div>
                  }
                  {
                    validTypes.data &&
                    <div>
                      <Typography className={classes.inputTitle}>Select a type</Typography>
                      {
                        validTypes.data.filter(el=>el.name!=='Question').map(el=>(
                          <div className={classes.typeContainer} onClick={()=>setType(el.id)}>
                            {getIconComponent({label:getIconLabel({label:'collection',item:el}),size:24,colour:theme.palette.primary.main})}
                            <Typography color='primary' style={{fontSize:16,marginLeft:16}}>{el.name}</Typography>
                          </div>
                        ))
                      }
                    </div>
                  }
                </div>
              </div>
            }
            {
              type!=='' && 
              <div className={classes.formContent}>
                <div className={classes.customScroll} style={{flexGrow:1,overflow:'auto',paddingRight:24,paddingTop:24,paddingBottom:24}}>
                  <div style={{display:'flex',alignItems:'center',marginBottom:24}}>
                    <Typography className={classes.modalTitle} style={{marginBottom:0,marginRight:12}}>Add</Typography>
                    <Select
                      value={type}
                      onChange={event=>setType(event.target.value)}
                      className={classes.selector}
                      disableUnderline
                    >
                      {
                        validTypes.data.map(el=>(
                          <MenuItem value={el.id}>{el.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </div>
                  <EditFields
                    userMap={userMap}
                    setUserMap={setUserMap}
                    collectionMap={collectionMap}
                    setCollectionMap={setCollectionMap}
                    values={values}
                    properties={validTypes.data.find(el=>el.id===type).properties}
                    setValues={setValues}
                    collection={validTypes.data.find(el=>el.id===type)}
                    isCreatingNew
                    objectLabel={toTitleCase(mapObjectName(state.basicData.object.name).replace(/_/g,' '))}
                  />
                </div>
                <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',flexGrow:0,flexShrink:0}}>
                  {
                    !creating && 
                    <Button color='primary' style={{marginRight:8,width:96}} classes={{disabled:classes.disabledButton}} onClick={onSave} disabled={!validTypes.data||!checkFilled(validTypes.data.find(el=>el.id===type).properties,values)}>
                      {!createdNote?'SAVE':'RETRY'}
                    </Button>
                  }
                  {
                    creating && <div style={{width:96,height:36,display:'flex',marginRight:8,alignItems:'center',justifyContent:'center'}}><CircularProgress color='secondary' style={{width:24,height:24}}/></div>
                  }
                  <Button color='secondary' style={{width:96}} onClick={onCancel}>CANCEL</Button>
                </div>
              </div>
            }
          </Paper>
          <ModalAlert
            setOpen={setAlertOpen}
            open={alertOpen}
            message={alertMessage}
            type={'error'}
          />
        </div>
      </Modal>
    </div>
  )
}

NoteAdder.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(NoteAdder));