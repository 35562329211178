import React, { useState } from 'react';
import { withTheme, withStyles, Typography, Modal, Paper, Button, Popper, ClickAwayListener, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatTimeToUtc, getFontColourByBackground, getIconComponent } from '../../../../utilities';
import { withRouter } from 'react-router-dom'
import useAlert from '../../../../hooks/useAlert';
import moment from 'moment';
import KDateTimePicker from '../../DateTimePicker/KDateTimePicker';
import axiosCerebrum from '../../../../axios-cerebrum';
import { getPutPayload } from '../../UpdateInput/utils';
import WarningConfirmModal from '../../ConfirmModals/WarningConfirmModal';

const styles = theme => ({
  text: {
    fontSize: 13.75,
    color: theme.palette.primaryText.main
  },
  clickableText: {
    // cursor: 'pointer',
    textDecoration: 'underline'
  },
  modalRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalPaper: {
    width: 640,
    maxWidth: '90vw',
    height: 'max-content',
    maxHeight: '90vh',
    boxSizing: 'border-box',
    padding: '16px 24px 0 24px',
    background: theme.palette.background.main,
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: 3,
  },
  header: {
    fontSize: 20,
    color: theme.palette.header.main,
    marginBottom: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  modalBody: {
    flexGrow: 1,
    paddingRight: 24,
    overflow: 'auto',
    ...theme.components.customScroll
  },
  sectionHeader: {
    fontSize: 12,
    letterSpacing: 1,
    marginBottom: 16
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 0px 8px 24px',
    background: theme.palette.background.main,
    marginTop: 64
  },
})

function ExpiryChip(props) {

  const {
    theme,
    // history,
    classes,
    data
  } = props;

  const [modalOpen, setModalOpen] = useState(false)
  const [expiry, setExpiry] = useState(data.expiry)
  const [updatedExpiry, setUpdatedExpiry] = useState(data.expiry)

  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const [listActionOpen, setListActionOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const onButtonClick = event => {
    setAnchor(event.currentTarget);
    setListActionOpen(!listActionOpen);
  }

  const {
    sendAlert
  } = useAlert({})
  
  const onSaveExpiry = () => {
    // if expiry is the same as the original expiry, close the modal
    if (moment(updatedExpiry).isSame(data.expiry)) {
      setModalOpen(false)
      return
    }

    axiosCerebrum
      .put(
        `/api/collectioninstances/${data.id}`,
        {
          ...getPutPayload({
            property: 'expiry',
            value: formatTimeToUtc(updatedExpiry),
            data
          }),
          active_flag: true
        }
      )
      .then((response) => {
        sendAlert({
          message: 'Expiry updated successfully',
          type: 'info'
        })
        setExpiry(response.data.expiry)
        setUpdatedExpiry(response.data.expiry)
        setModalOpen(false)
        data.expiry = response.data.expiry;
      })
      .catch(err => {
        sendAlert({
          message: 'Failed to update expiry',
          type: 'error'
        })
        console.error(err)
      })
  }

  const onExpireNow = () => {
    axiosCerebrum
      .put(
        `/api/collectioninstances/${data.id}`,
        {
          ...getPutPayload({
            property: 'active_flag',
            value: false,
            data
          }),
          expiry: null
        }
      )
      .then((response) => {
        sendAlert({
          message: 'Expired successfully',
          type: 'info'
        })
        setExpiry(response.data.expiry)
        setUpdatedExpiry(response.data.expiry)
        setModalOpen(false)
        data.expiry = response.data.expiry;
      })
      .catch(err => {
        setAlertMessage('Failed to expire, please try again later')
        setAlertOpen(true)
        console.error(err)
      })
  }

  const isExpired = moment(expiry).isBefore(moment());
  const background = isExpired ? theme.palette.warning.main : theme.palette.success.main;

  return (
    <>
      <div style={{ display: "flex", alignItems: 'center' }}>
        <div
          data-test-classname="expiry-info-chip"
          style={{
            border: `1px solid ${background}`,
            cursor: 'pointer',
            background: background,
            marginBottom: 16,
            padding: '4px 4px 4px 12px',
            width: 'max-content',
            maxWidth: '100%',
            display: 'flex',
            boxSizing: 'border-box',
            alignItems: 'center',
            fontSize: 13.75,
            borderRadius: 6,
            letterSpacing: 1,
            height: 38,
            color: getFontColourByBackground(background),
          }}
          onClick={onButtonClick}
        >
          <span style={{ marginRight: 8 }}>
            {
              isExpired ?
                `EXPIRED` :
                'ACTIVE'
            }
          </span>
          <span style={{ position: 'relative', bottom: -2 }}>
            {getIconComponent({ label: 'triangle_down', color: getFontColourByBackground(theme.palette.warning.main), size: 24 })}
          </span>
        </div>
        {
          !isExpired && 
          <Typography style={{ fontSize: 13.75, marginBottom: 16, marginLeft: 16 }}>
            Expires {moment(expiry).fromNow()}
          </Typography>
        }
      </div>
      <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-start'>
        <ClickAwayListener onClickAway={() => setTimeout(() => setListActionOpen(false))}>
          <Paper style={{ marginTop: 2, marginRight: -2, width: 260, border: `1px solid ${theme.palette.border.main}`, background: theme.palette.background.main }}>
            <MenuItem onClick={() => { setModalOpen({ type: 'extend_expiry' }); setListActionOpen(false) }} className={classes.menuItem} >
              {getIconComponent({ label: 'edit', size: 24, colour: theme.palette.primaryText.light })}
              <Typography style={{ fontSize: 16, marginLeft: 16, color: theme.palette.primaryText.main }}>
                Extend expiry
              </Typography>
            </MenuItem>
            {
              !isExpired &&
              <MenuItem onClick={() => { setModalOpen({ type: 'expire_now' }); setListActionOpen(false) }} className={classes.menuItem} >
                {getIconComponent({ label: 'clear', size: 24, colour: theme.palette.primaryText.light })}
                <Typography style={{ fontSize: 16, marginLeft: 16, color: theme.palette.primaryText.main }}>
                  Deactivate warning
                </Typography>
              </MenuItem>
            }
          </Paper>
        </ClickAwayListener>
      </Popper>
      {
        modalOpen?.type === 'extend_expiry' &&
        <Modal classes={{ root: classes.modalRoot }} open={modalOpen}>
          <Paper className={classes.modalPaper}>
            <Typography className={classes.header}>UPDATE WARNING NOTICE EXPIRY</Typography>
            <Typography className={classes.sectionHeader}>
              EXPIRES ON
            </Typography>
            <KDateTimePicker
              initialValue={expiry}
              value={moment(updatedExpiry).add(-1, 'hour').isBefore(moment()) ? moment().add(1, 'hour') : moment(updatedExpiry)}
              onChange={setUpdatedExpiry}
              showSuggestions={true}
              suggestionText={isExpired ? undefined : 'Extend:'}
              isSuggestionExtend={isExpired ? false : true}
            />

            <div className={classes.buttonContainer}>
              <Button
                disabled={moment(updatedExpiry).add(-1, 'hour').isBefore(moment())}
                color='primary'
                style={{ marginRight: 24 }}
                onClick={() => onSaveExpiry()}
              >
                SAVE
              </Button>
              <Button color='secondary' onClick={() => { setModalOpen(false); }}>
                CANCEL
              </Button>
            </div>
          </Paper>
        </Modal>
      }
      {
        modalOpen?.type === 'expire_now' &&
        <WarningConfirmModal
          description={'Are you sure?\n\nWarning notices will be deactivated from all linked assets.'}
          title={'DEACTIVATE WARNING'}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          onConfirm={onExpireNow}
          alertOpen={alertOpen}
          setAlertOpen={setAlertOpen}
          alertMessage={alertMessage}
          confirmText={'YES'}
        />
      }
    </>
  )
}

ExpiryChip.propTypes = {
  classes: PropTypes.object.isRequired,
  infoBox: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(withRouter(ExpiryChip)));