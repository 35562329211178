import React, { useState, useEffect, useReducer } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { Typography, LinearProgress } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/WorkSpaceProfile/Body/Body';
import { handleShareClick, setInitialState, getDispFields, removeUrlQueryArg} from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import axiosCerebrum from '../../axios-cerebrum';
import axiosSolr from '../../axios-solr'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import DropdownButton from '../../components/UI/Buttons/DropdownButton'
import 'url-search-params-polyfill';
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import AddToCartButton from '../../components/UI/Buttons/AddToCartButton';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';
import { checkIsFollowed } from '../../permissionChecker';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  normalText: {
    color: theme.palette.primaryText.main,
  }
});

const initialState = {
  tabState: 0,
  tagsData: {items:[]},
  noteTabState:0,
  noteDetailMap:{},
  usagePeriod:3,
  usageType:'indirect',
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_child_count':
      return {
        ...state,
        childCount:action.childCount
      }
    case 'set_list_modal_open':{
      return {
        ...state,
        listModalOpen:action.listModalOpen
      }
    }
    case 'set_warning_modal_open': {
      return {
        ...state,
        warningModalOpen: action.warningModalOpen
      }
    }
    case 'set_terms':
      return {
        ...state,
        terms:action.terms,
        termsLoading:action.termsLoading,
        termsError:action.termsError
      }
    case 'set_notes':
      return {
        ...state,
        notesLoading:action.notesLoading,
        notesError:action.notesError,
        notes:action.notes
      }
    case 'set_survey_data':
      return {
        ...state,
        surveyData:action.surveyData,
        surveyLoading:action.surveyLoading,
        surveyError:action.surveyError
      }
    case 'set_note_tab_state':{
      return {
        ...state,
        noteTabState:action.noteTabState
      }
    }
    case 'set_note_detail_map':{
      return {
        ...state,
        noteDetailMap:action.noteDetailMap
      }
    }
    case 'set_note_modal_open':{
      return {
        ...state,
        noteModalOpen:action.noteModalOpen
      }
    }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataError:action.basicDataError,
        basicDataLoading:action.basicDataLoading
      }
    case 'set_collection_instances':{
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    }
    case 'set_creator_data':
      return {
        ...state,
        creatorData: action.creatorData
      }
    case 'set_editing_description':
      return {
        ...state,
        editingDescription: action.editingDescription
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
      }
    case 'set_access_modal_open':{
      return {
        ...state,
        accessModalOpen:action.accessModalOpen
      }
    }
    case 'set_assets_data':{
      return {
        ...state,
        assetsData:action.assetsData,
      }
    }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_additional_properties':
      return {
        ...state,
        additionalProperties:action.additionalProperties,
        additionalPropertiesLoading:action.additionalPropertiesLoading,
        additionalPropertiesError:action.additionalPropertiesError
      }
    case 'set_additional_properties_modal_open':
      return {
        ...state,
        additionalPropertiesModalOpen:action.additionalPropertiesModalOpen,
      }
    case 'set_usage_data':
      return {
        ...state,
        usageData: action.usageData,
        usageError: action.usageError,
        usageLoading: action.usageLoading
      }
    case 'set_usage_active_series':
      return {
        ...state,
        usageActiveSeries: action.usageActiveSeries
      }
    case 'set_usage_panel_data':
      return {
        ...state,
        usagePanelData: action.usagePanelData,
        usagePanelError: action.usagePanelError,
        usagePanelLoading: action.usagePanelLoading
      }
    case 'set_usage_period':
      return {
        ...state,
        usagePeriod:action.usagePeriod
      }
    case 'set_usage_type':
      return {
        ...state,
        usageType:action.usageType
      }
    case "set_selected_date":
      return {
        ...state,
        selectedDate: action.selectedDate
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const WorkSpaceProfile = props => {

  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
  } = props;


  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName');

  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));
  const [linkModalOpen, setLinkModalOpen] = useState(false);


  const getTabOptions = data => {
    return [state.childCount?'ASSETS':undefined, 'DETAILS','KNOWLEDGE'].filter(el=>el!==undefined);
  }
  
  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])

  const fetchList = () => {
    dispatch({type:'set_basic_data',basicDataLoading:state.basicData?false:true,basicData:state.basicData});
    axiosCerebrum
      .get(
        `/api/workspaces/${match.params.id.toLowerCase()}`,
      )
      .then(async cerebrumResponse=>{
        let fullResponse = cerebrumResponse.data;
        if(!fullResponse){
          dispatch({ type: 'set_basic_data', basicDataError: true})
          return;
        }
        if(fullResponse.active_flag === false ){
          !state.basicData && dispatch({type:'set_show_deleted_tables',showDeletedTables:true})
        }
        if(fullResponse.reference===true){
          dispatch({type:'set_show_reference_tables',showReferenceTables:true})
        }
        let sourceData;
        await axiosCerebrum.get(`/api/sources/${fullResponse.source_id}`).then(response=>sourceData=response.data).catch(error=>{console.log(error)})
        addHistory({url:window.location.pathname, title: getDispFields(fullResponse,'dispTitle'), subTitle:getDispFields(fullResponse,'dispSubtitle'),object:fullResponse,type:'profile'})
        axiosSolr
          .get(`/solr/search/select`,{params:{q:"*",fq:`id:${fullResponse.id}`,fl:'*'}})
          .then(response=>{
            dispatch({ type: 'set_basic_data', basicData: {...(state.basicData||{}),...fullResponse,...(response.data.response.docs[0]||{}), source_data:sourceData }})
            dispatch({type:'set_child_count',childCount:response.data.response.docs[0]?response.data.response.docs[0].child_object_count_srt:0})
          })
          .catch(error=>{
            dispatch({ type: 'set_basic_data', basicData: fullResponse})
          })
      })
      .catch(error=>{
        dispatch({ type: 'set_basic_data', basicDataError: true})
      })
  }

  const followDataFetch = () => {
    checkIsFollowed({objectId:match.params.id.toLowerCase()})
      .then(followed=>{
        dispatch({type:'set_following',following:followed})
      })
  }


  useEffect(()=>{
    if(state.followData===undefined){
      followDataFetch()
    }
    if(!state.basicData){
      fetchList();
    }
    // eslint-disable-next-line
  },[])


  useEffect(()=>{
    if(tabName && state.basicData && !isNaN(state.childCount)){
      let tabOptions = getTabOptions(state.basicData);
      if(!tabOptions.includes(tabName.toUpperCase()))return;
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
      let presetTabState = tabOptions.indexOf(tabName.toUpperCase());
      dispatch({type:'set_tab_state',tabState:presetTabState})
    }
    // eslint-disable-next-line
  },[state.basicData, state.childCount])


  if (state.basicDataLoading ) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData){
    return <div></div>
  }

  const isShell = state.basicData.reference!==false

  let buttons = [];


  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  // let optionTitle = ['NOTICES']
  // let optionList = [
    
  // ]

  // buttons.push(
  //   <DropdownButton
  //     iconLabel='dot_menu'
  //     tooltip="More actions"
  //     iconOnly={true}
  //     iconColour={theme.palette.primaryText.light}
  //     title={optionTitle}
  //     optionList={optionList}
  //     hideSectionTitle
  //   />
  // )

  buttons.push(
    <AddToCartButton
      object={state.basicData}
    />
  )

  buttons.push(
    <DropdownButton
      iconLabel='add'
      text='ADD'
      optionList={[
        {onClick: () => dispatch({ type: 'set_warning_modal_open', warningModalOpen: true }), iconLabel: 'warning_notice', text: 'Add warning notice', group: 'NOTICES'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:true}),iconLabel:'note',text:'Add Knowledge'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}}),iconLabel:'question',text:'Ask Question'}
      ]}
    />
  )

  buttons.push(
    <FollowButton
      following={state.following}
      object={state.basicData}
      dispatch={dispatch}
    />
  )


  let bannerdisplayText,bannerCause,bannerVisibility;
  let data = state.basicData
  

  if (data && data.active_flag === false) {
    bannerdisplayText = 'This Workspace has been deleted';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }

  if (data && data.source_data && data.source_data.active_flag===false) {
    bannerdisplayText = 'The source of this Workspace has been deactivated in K. Information on this Workspace is dated and may no longer be accurate';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }

  if (data && isShell){
    let sourceName = state.basicData.source_data?state.basicData.source_data.name:'Unkown Source'
    if(data.reference_sources){
      if(data.reference_sources.length===0){
        bannerdisplayText=`This Workspace is referenced in unknown source. Limited metadata available.`
      }
      else if(data.reference_sources.length===1){
        bannerdisplayText=`This Workspace is referenced in ${data.reference_sources[0].reference_source.name}. ${sourceName} is not connected to K. Limited metadata available.`
      }else{
        // bannerdisplayText=(
        //   <Typography>
        //     This Workspace is referenced in <span onClick={()=>dispatch({type:'set_tab_state',tabState:getTabOptions(state.basicData).indexOf('RELATED')})} style={{textDecoration:'underline',cursor:'pointer'}}>multiple Tools</span>. {sourceName} is not connected to K. Limited metadata available.
        //   </Typography>
        // )
        bannerdisplayText=(
          <Typography>
            This Workspace is referenced in multiple Tools. {sourceName} is not connected to K. Limited metadata available.
          </Typography>
        )
      }
    }
    else{
      bannerdisplayText= 'This Workspace is referenced in content but not integrated as a source. Limited metadata available.';
    }
    bannerCause = 'shell';
    bannerVisibility = 'visible'
  }

  const tabOptions = getTabOptions(data)

  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['list','link','warning','note','additional_property']}
        history={history}
      />
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              tabOptions={tabOptions}
              type='workspace'
              label={'workspace'}
              title={state.basicData.name ? state.basicData.name : 'Untitled'}
              shouldLoadBreadCrumb
              buttons={buttons}
              data={state.basicData}
              creatorData={state.creatorData}
              history={history}
              isShell={isShell}
              state={state}
              dispatch={dispatch}
              bannerdisplayText={bannerdisplayText}
              bannerVisibility={bannerVisibility}
              bannerCause={bannerCause}
              shouldLoadLinkedInstance
              onClickAddTag={()=>{
                setLinkModalOpen({
                  linkableObjects:['COLLECTION_INSTANCE'],
                  relations:['MEMBER_OF'],
                  onLinkUpdated:()=>window.postMessage({reload_header_instance:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
                })
              }}
            />
          </div>)}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            tabOptions={tabOptions}
            history={history}
            tabState={state.tabState}
            data={state.basicData}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
            fetchList={fetchList}
            isShell={isShell}
            // tablelistFetch={tablelistFetch}
            totalTableData={state.totalTablelistData}
          />
        }
        hideSideBar={['MAP'].includes(tabOptions[state.tabState])}
        sideBar={
          <ProfileSideBar
            tabOptions={tabOptions}
            history={history}
            state={state}
            dispatch={dispatch}
            basicData={state.basicData}
            mapping={
              ['tags','trust','sourceType','category','lastUpdated','domain','verified','channels','verifiedNot','classification','knowledge', 'topTeams','owner', 'stewardedBy']
            }
            cerebrumLabel={'workspace'}
            fetchList={fetchList}
            sessionData={sessionData}
            data={state.basicData}
          />
        }
      />
    </div>)
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(WorkSpaceProfile)));
