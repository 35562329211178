import React, { } from 'react';
import { withTheme, withStyles, Modal, Paper, Button, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import BasicSearch from '../../../containers/BasicSearch/BasicSearch';
import { withRouter } from 'react-router-dom'
import { sendMessage } from '../../../utilities';

const styles = theme => ({
  paper:{
    ...theme.components.customScroll
  }
})

function SearchResultModal(props) {

  const {
    classes,
    history,
    theme,
    modalOpen,
    setModalOpen
  } = props;

  return (
    <Modal 
      open={modalOpen} 
      onClose={()=>setModalOpen(false)}
      disableBackdropClick={true}
    > 
      <div style={{display:'flex',height:'100vh',alignItems:'center',justifyContent:'center',overflow:'hidden'}}>
        <Paper className={classes.paper} style={{padding:24,paddingTop:0,width:'80vw',maxHeight:'80vh',overflow:'auto',background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
          <div style={{display:'flex',paddingTop:16,zIndex:7,position:'sticky',top:0,paddingBottom:28,background:theme.palette.background.main}}>
            <Typography style={{fontSize:20,color:theme.palette.header.main}}>DETAILS</Typography>
            <div style={{flexGrow:1}}></div>
            <Button 
              color='primary' 
              variant='outlined' 
              style={{marginRight:16}} 
              onClick={()=>{
                sendMessage({'modal_search_open_search':true})
              }}
            >
              OPEN IN SEARCH
            </Button>
            <Button color='primary' variant='outlined' onClick={()=>{setModalOpen(false)}}>CLOSE</Button>
          </div>
          {/* <div style={{position:'relative',top:-24}}> */}
          {
            modalOpen && 
            <BasicSearch
              customID={'modal_search'}
              initialView={'main_search'}
              history={history} 
              alwaysOpenNewTab={true}
              removeContainerStyle={true}
              {...props}
            />
          }
          {/* </div> */}
        </Paper>
      </div>
    </Modal>
  )
}

SearchResultModal.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  ...BasicSearch.propTypes
}

export default withTheme()(withStyles(styles)(withRouter(SearchResultModal)));