import React from 'react';
import { withTheme,  Typography, FormControl,Select,MenuItem, FormHelperText,} from '@material-ui/core';


const SourceSelector = props => {

  const {
    classes,
    theme,
    state,
    onSelectSource
  } = props;
  
  return (
    <div className={classes.block}>
      <FormControl>
        <Select
          className={classes.selector}
          value={state.sourceIDs}
          onChange={onSelectSource}
          disableUnderline
          displayEmpty
          renderValue={el=>{
            return (
              state.sourceIDs.length===0
                ?
                <Typography style={{color:theme.palette.primaryText.light,fontSize:16}}>
                  Select a Source
                </Typography>
                :
                <div>
                  <Typography style={{color:theme.palette.primaryText.light,fontSize:12}}>
                    Select the Source
                  </Typography>
                  <Typography style={{fontSize:16,color:theme.palette.primaryText.main}}>
                    {state.sourceList?.find(s=>s.id+""===el+"").name}
                  </Typography>
                </div>
            )
          }}
        > 
          <MenuItem value={'none'}>
            Select the Source
          </MenuItem>
          {
            state.sourceList?.map(el=>(
              <MenuItem value={el.id}>
                {el.name}
              </MenuItem>
            ))
          }

        </Select>
        <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>Required</FormHelperText>
      </FormControl>
    </div>
  )
}

export default withTheme()(SourceSelector);