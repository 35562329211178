import React, { } from 'react';
import { withTheme, Typography, Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';


const ObjectTypeSelector = props => {

  const {
    classes,
    theme,
    objectType,
    setObjectType,
    validObjectTypes,
  } = props;


  return (
    <div className={classes.modalBlock}>
      <Typography className={classes.modalBlockTitle}>Object Type</Typography>
      <div style={{ flexGrow: 1 }}>
        <FormControl style={{ width: '100%' }}>
          <Select
            className={classes.selector}
            disableUnderline
            value={objectType}
            onChange={event => setObjectType(event.target.value)}
            MenuProps={{
              classes: {
                paper: classes.selectPaper
              }
            }}
            renderValue={el => {
              let msg = el === 'none' ? 'Select an object type' : validObjectTypes.find(v => v.id === el).value;
              return (
                <Typography style={{ fontSize: 16, marginLeft: 18, color: el === 'none' ? theme.palette.primaryText.light : theme.palette.primaryText.main }}>{msg}</Typography>
              )
            }}
          >
            <MenuItem className={classes.menuItem} value={'none'}>
              {
                validObjectTypes && validObjectTypes.length === 0 ?
                  'No objects found' :
                  'Select an object type'
              }
            </MenuItem>
            {
              validObjectTypes && validObjectTypes.map(el => (
                <MenuItem className={classes.menuItem} value={el.id}>
                  {el.value}
                </MenuItem>
              ))
            }
          </Select>
          <FormHelperText className={classes.helperText}>
            {'Required'}
          </FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}

ObjectTypeSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  objectType: PropTypes.string.isRequired,
  setObjectType: PropTypes.func.isRequired,
  validObjectTypes: PropTypes.array.isRequired,
}

export default withTheme()(ObjectTypeSelector);