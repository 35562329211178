import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Stepper, Step, StepLabel } from '@material-ui/core';
import StepBody from '../StepBody/StepBody';

const styles = theme => ({
  root: {
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  stepMessage: {
    color: theme.palette.primary.main,
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    textAlign: 'center',
    overflow: 'visible',
  },
  stepper:{
    background:theme.palette.background.main
  }
})


function UserOnboardBody(props) {

  const {
    classes,
    theme,
    history,
    dispatch,
    activeStep,
    state,
    sessionData
  } = props;

  const stepMessages = [
    "About you",
    "Your K settings",
    "Link Accounts",
    "Confirm profile"
  ]
  

  const stepperClick = (currentStep) => {
    dispatch({ type: 'set_active_step', activeStep: currentStep });
  }

  let stepContent = [];
  for (let i = 0; i < stepMessages.length; i++) {
    stepContent.push(
      <Step completed={false}>
        <StepLabel className={classes.step} onClick={() => stepperClick(i)} >
          <div style={{ height: '3rem', width: '100%',display:'flex',justifyContent:'center' }}>
            <Typography className={classes.stepMessage} style={activeStep === i ? { color: theme.palette.primary.main } : { color:theme.palette.primaryText.light }}>{stepMessages[i]}</Typography>
          </div>
        </StepLabel>
      </Step>
    )
  }

  return (
    <div style={{ width: '80%' }}>
      <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel nonLinear>
        {stepContent}
      </Stepper>
      <StepBody
        activeStep={activeStep}
        dispatch={dispatch}
        history={history}
        state={state}
        sessionData={sessionData}
      />
    </div>
  )
}

UserOnboardBody.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  impactData: PropTypes.object,
  impactLoading: PropTypes.bool,
  impactError: PropTypes.bool,
  dispatch: PropTypes.func,
  activeStep: PropTypes.number,
  selectedIndex: PropTypes.array
}

export default withTheme()(withStyles(styles)(UserOnboardBody));