import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme,  Button, Typography, withStyles, IconButton, CircularProgress } from '@material-ui/core';
import { formatNumber, getDispFields, getIconComponent, isInViewport, mapObjectName, onClickResultItem, sendMessage, tabGroup } from '../../../utilities';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import axiosCerebrum from '../../../axios-cerebrum';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root:{
    display:'flex',
  },
  header:{
    fontSize:16,
    color:theme.palette.header.main
  }
})

function List(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    history
  } = props;

  const scrollRef = useRef()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const loadCartData = ({type = state.selectedType, page = 1}) => {
    dispatch({type:'set_cart_data',cartData:{...state.cartData,[type]:{...(state.cartData[type]||{}),loading:true}}})
    axiosCerebrum
      .get(
        `/api/me/cart`,{
          params:{
            object_type:type,
            page,
            per_page:20
          }
        }
      )
      .then(response=>{
        if(page===1){
          dispatch({type:'set_cart_data',cartData:{...state.cartData,[type]:response.data}})
        }else{
          dispatch({type:'set_cart_data',cartData:{...state.cartData,[type]:{...response.data,items:[...state.cartData[type].items,...response.data.items]}}})
        }
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_cart_data',cartData:{...state.cartData,[type]:{error:true}}})
      })
  }


  const onRemoveFromCart = (item) => {
    axiosCerebrum 
      .delete(
        `/api/me/cart?object_id=${item.id}`
      )
      .then(response=>{
        sendMessage({reloadCart:true})
        dispatch({type:'set_cart_data',cartData:{...state.cartData,[state.selectedType]:{...state.cartData[state.selectedType],items:state.cartData[state.selectedType].items.filter(el=>el.id!==item.id)}}})
        dispatch({type:'set_tabs',tabs:state.tabs.map(el=>el.objectType===state.selectedType?{...el,count:el.count-1}:el)})
      })
      .catch(error=>{
        console.log(error)
        sendAlert({message:'Error occurred removing item from Cart',type:'error'})
      })
  }

  const onRemoveType = (type=state.selectedType) => {
    axiosCerebrum 
      .delete(
        `/api/me/cart?object_type=${type}`
      )
      .then(response=>{
        sendMessage({reloadCart:true})
        dispatch({type:'set_cart_data',cartData:{...state.cartData,[state.selectedType]:{items:[],page:0,pages:0}}})
        dispatch({type:'set_tabs',tabs:state.tabs.map(el=>el.objectType===state.selectedType?{...el,count:0}:el)})
      })
      .catch(error=>{
        console.log(error)
        sendAlert({message:'Error occurred removing items from Cart',type:'error'})
      })
  }

  const onRemoveAll = () => {
    axiosCerebrum 
    .delete(
      `/api/me/cart`
    )
    .then(response=>{
      sendMessage({reloadCart:true})
      dispatch({type:'set_cart_data',cartData:{}})
      dispatch({type:'set_tabs',tabs:[]})
    })
    .catch(error=>{
      console.log(error)
      sendAlert({message:'Error occurred removing items from Cart',type:'error'})
    })
  }

  const shouldLoadMore = () => {
    const {selectedType,cartData} = state;
    if(!cartData[selectedType])return false;
    const {page,pages,loading} = cartData[selectedType]
    return page<pages && isInViewport(scrollRef) && !loading
  }

  useEffect(()=>{
    if(!state.cartData[state.selectedType])loadCartData({type:state.selectedType})
  // eslint-disable-next-line
  },[state.selectedType])

  window.onscroll = () => {
    if(shouldLoadMore()){
      loadCartData({page:state.cartData[state.selectedType].page+1})
    }
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadCartData({page:state.cartData[state.selectedType].page+1})
    }
  // eslint-disable-next-line
  },[state.cartData])

  const onChangeTab = (value) => {
    dispatch({type:'set_selected_type',selectedType:value})
    window.scrollTo({behavior:'smooth',top:0})
  }
  
  const count = state.tabs.find(el=>el.objectType===state.selectedType)?.count
  const totalCount = state.tabs.reduce((acc,el)=>acc+el.count,0)
  return (
    <div>
      <div style={{display:'flex',paddingBottom:24,position:'sticky',top:150,background:theme.palette.background.main,zIndex:1}}>
        <Typography data-test-id="cart-list-title" style={{fontSize:20,marginRight:16}}>{totalCount} item(s) in cart</Typography>
        <Button disabled={totalCount===0} data-test-id="clear-cart-button" variant='outlined' color='primary' onClick={()=>onRemoveAll()} style={{marginLeft:16}}>CLEAR CART</Button>
      </div>
      {
        totalCount===0 && 
        <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light}}><span style={{cursor:'pointer',textDecoration:'underline'}} onClick={()=>history.push('/basic_search')}>Go to search</span> to add items to your cart</Typography>
      }
      {
        totalCount>0 && 
        <>
          <div style={{width:220,position:"sticky",top:200,float:'left',zIndex:1}}>
            <VerticalTabBar
              tabOptions={state.tabs.map(el=>el.objectType)}
              renderOptions={value=>`${mapObjectName(value)} (${formatNumber(state.tabs.find(el=>el.objectType===value).count)})`}
              tabState={state.tabs.map(el=>el.objectType).indexOf(state.selectedType)}
              tabIcons={state.tabs.map(el=>el.objectType)}
              setTabState={value => {
                onChangeTab(state.tabs[value].objectType)
              }}
              grouping={tabGroup}
              alwaysShowFullTab
              highlightOnSelected
              width={220}
            />
          </div>

          <div style={{display:'flex',alignItems:'center',position:'sticky',top:200,marginLeft:260,paddingBottom:16,background:theme.palette.background.main,zIndex:1}}>
            <Typography data-test-id="list-selection-title" className={classes.header}>{count} {mapObjectName(state.selectedType).replace(/_/g,' ')}(S) SELECTED</Typography>
            <Button data-test-id="clear-type-button" disabled={count===0} color='secondary' onClick={()=>onRemoveType(state.selectedType)} style={{marginLeft:16}}>CLEAR {mapObjectName(state.selectedType).replace(/_/g,' ')}(S)</Button>
          </div>

          <div style={{marginLeft:260,overflow:'hidden'}}>
            {
              state.cartData[state.selectedType] && state.cartData[state.selectedType].items && state.cartData[state.selectedType].items.map(el=>(
                <SimpleResultItem
                  title={getDispFields(el,'dispTitle')}
                  label={el.object.name}
                  item={el}
                  subTitle={getDispFields(el,'dispSubtitle')}
                  rightText={el.score}
                  hideRight
                  showUnderline
                  tailObject={
                    <IconButton onClick={(event)=>{event.stopPropagation();onRemoveFromCart(el)}}> 
                      {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  }
                  onClick={() => onClickResultItem({id:el.id,item:el, label: el.object.name, history: history })}
                />  
              ))
            }
            {
              state.cartData[state.selectedType] && state.cartData[state.selectedType].items && state.cartData[state.selectedType].items.length===0 && 
              <Typography>No item found</Typography>
            }
            {
              state.cartData[state.selectedType] && state.cartData[state.selectedType].error &&
              <Typography>Error occurred loading items</Typography>
            }
            <div ref={scrollRef} style={{marginTop:8,marginBottom:24,display:'flex',justifyContent:'center'}}>
              {
                state.cartData[state.selectedType] && state.cartData[state.selectedType].loading &&
                <CircularProgress color='secondary'/>
              }
            </div>
          </div> 
        </>
      }
    </div>
  )
}

List.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(List));