import React, {useEffect} from 'react';
import { withStyles } from '@material-ui/core';
import DataManagement from '../DataManagement/DataManagement';
import Cost from '../Cost/Cost';
import DataGovernance from '../DataGovernance/DataGovernance'
import DataRisk from '../DataRisk/DataRisk'
import Surveys from '../Surveys/Surveys'
import TabBar from '../../UI/VerticalTabBar/VerticalTabBar'
import Platform from '../Platform/Platform'
import Scanner from '../Scanner/Scanner';

import 'url-search-params-polyfill';

const styles = theme => ({
  root:{
    marginBottom:30,
    display:'flex'
  }
})

function Body(props) {

  const {
    classes,
    history,
    state,
    dispatch
  } = props;
  
  const tabOptions = ['USAGE','GOVERNANCE', 'RISK', 'SURVEYS', 'COSTS','PLATFORM','SCANNER'];

  useEffect(()=>{
    const urlSearch = new URLSearchParams(window.location.search);
    const tabName = urlSearch.get('subTabName')
    if(tabOptions.indexOf(tabName)>=0)dispatch({type:'set_question_tab_state',questionTabState:tabOptions.indexOf(tabName)})
    // eslint-disable-next-line
  },[])

  let bodyContent;
  switch (state.questionTabState) {
    case 0: 
      bodyContent = (
        <DataManagement
          history={history}
          state={state}
          dispatch={dispatch}
        />
      );
      break;
    case 1:
      bodyContent = (
        <DataGovernance
          history={history}
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    case 2:
      bodyContent = (
        <DataRisk
          history={history}
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    case 3:
      bodyContent = (
        <Surveys
          history={history}
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    case 4:
      bodyContent = (
        <Cost
          history={history}
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    case 5:
      bodyContent = (
        <Platform
          history={history}
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    case 6:
      bodyContent = (
        <Scanner
          history={history}
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    default:
  }

  return (
    <div className={classes.root}>
      <TabBar
        tabOptions={tabOptions}
        tabState={state.questionTabState}
        setTabState={value => dispatch({ type: 'set_question_tab_state', questionTabState: value })}
      />
      <div style={{flexGrow:1,marginLeft:80}}>
        {bodyContent}
      </div>
    </div>
  )
}

export default withStyles(styles)(Body);