import React, {  useEffect, useRef, useState } from 'react';
import 
{ withTheme, withStyles, Modal, Paper, Typography, Button, CircularProgress, IconButton} 
from '@material-ui/core';
import ModalAlert from '../../UI/ModalAlert/ModalAlert'
import axiosCerebrum from '../../../axios-cerebrum';
import FolderSelector from './FolderSelector';
import TabBar from '../../UI/TabBar/TabBar';
import InputSelector from './InputSelector';
import { getIconComponent, sendMessage, toTitleCase } from '../../../utilities';
import useAlert from '../../../hooks/useAlert';
import { getPutPayload } from '../../UI/UpdateInput/utils';

const styles = theme => ({
  root:{
    width:'100vw',
    height:'100vh',
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'center',
  },
  title: {
    fontSize: 20,
    color:theme.palette.header.main,
  },
  subTitle: {
    fontSize:12,
    // marginBottom: 8,
    whiteSpace:'pre-wrap',
    color:theme.palette.primaryText.light
  },
  buttons: {
    flexGrow:0,
    backgroundColor: theme.palette.background.main,
    paddingTop: 12,
    marginTop:5,
    width:'100%',
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  sectionHeader:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primaryText.main,
    marginBottom:8
  },
  chip:{
    height:24,
    display:'flex',
    alignItems:'center',
    borderRadius:12,
    fontSize:13.75,
    paddingLeft:12,
    paddingRight:4,
    maxWidth:400,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    width:'max-content'
  },
  selectedChip:{
    border:`1px solid ${theme.palette.primary.dark}`,
    background:theme.palette.primary.dark,
    color:theme.palette.background.main
  },
  iconButton:{
    padding:0,
    marginLeft:6,
    '&:hover':{
      background:`${theme.palette.hovered.main}30`
    }
  }
})

function EditModal(props) {

  const {
    classes,
    theme,
    modalOpen,
    setModalOpen,
    state,
    dispatch,
    initialParent
  } = props;

  const [disableButton, setDisableButton] = useState(false); 
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [tabState, setTabState] = useState(0);
  const [instances, setInstances] = useState({});
  const [selectedInstance, setSelectedInstance] = useState()
  const [loading, setLoading] = useState()
  
  const [pageMap, setPageMap] = useState({})

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`ci-parent-editor`,
    isCancelledRef,
    disableStateCache:true
  })

  useEffect(()=>{ 
    return () => {
      isCancelledRef.current = true
    }
  },[])


  useEffect(()=>{
    setSelectedInstance()
  },[tabState])

  useEffect(()=>{
    if(!modalOpen)setSelectedInstance()
  },[modalOpen])

  const loadInstances = ({parentID, isExpand=false, page}) => {

    let newData = [...(instances.data||[])];

    if(parentID!==state.basicData.parent.id){
      newData.find(d=>d.id===parentID).expanded=isExpand;
    }
    
    setInstances({data:newData,loading:parentID===state.basicData.parent.id?true:undefined})
    if(parentID!==state.basicData.parent.id)setLoading(parentID)
    
    axiosCerebrum
      .get(
        `/api/collectioninstances`,{
          params:{
            ...(
              parentID===state.basicData.parent.id?
              {hierarchy_parent_id:'null',collection_id:state.basicData.parent.id}:
              {hierarchy_parent_id:parentID}
            ),
            page,
            per_page:10
          }
        }
      )
      .then(response=>{

        setPageMap({
          ...pageMap,
          [parentID]:{
            page:response.data.page,
            pages:response.data.pages
          }
        })

        let data = response.data.items.map(el=>({
          ...el,
          has_children:el.hierarchy_children?.length>0,
          upperLevelID:parentID,
          expanded:false
        }))
        setInstances({data:[...(instances.data||[]),...data]})
        setLoading()
      })
      .catch(error=>{
        console.log(error)
        setLoading()
        setInstances({error:true})
      })
  }

  const onItemExpand = item => {
    if(pageMap[item.id]){
      let newData = [...(instances.data||[])];
      newData.find(d=>d.id===item.id).expanded =  !newData.find(d=>d.id===item.id).expanded;
      setInstances({data:newData})
    }else{
      loadInstances({parentID:item.id, isExpand:true})
    }
    
  }

  useEffect(()=>{
    if(!instances.data && !instances.loading){
      loadInstances({parentID:state.basicData.parent.id})
    }
  // eslint-disable-next-line
  },[])

  const onCancel = () => {
    setModalOpen(false)
  }

  const onRemoveParent = () => {
    if(selectedInstance){
      setSelectedInstance()
    }else{
      setSelectedInstance('root')
    }
  }

  const onMove = () => {
    let selectedId = selectedInstance;
    if(typeof(selectedInstance)==='object')selectedId = selectedInstance.id;
    if( initialParent && selectedId===initialParent.id){
      setModalOpen(false);
      return;
    }
    setDisableButton(true)
    let id = selectedId;
    if(selectedId==='root')id = null
    axiosCerebrum
      .put(
        `/api/collectioninstances/${state.basicData.id}`,
        getPutPayload({property:"hierarchy_parent_id", value:id, data:state.basicData}),
      )
      .then(response=>{
        dispatch({
          type:'set_basic_data',
          basicData:{
            ...state.basicData,
            ...response.data
          }
        })
        setTimeout(()=>{
          sendMessage({reload_breadcrumb:true})
        },150)
        if(instances.data){
          let newData = instances.data.map(i=>{
            if(i.id!==state.basicData.id)return i;
            return {
              ...i,
              upperLevelID:selectedId
            }
          })
          setInstances({data:newData})
        }
        setDisableButton(false)
        setModalOpen(false)
        sendMessage({load_instance_parent_tile:response.data})
        sendAlert({type:'info',message:'Item successfully moved'})
      })
      .catch(error=>{
        console.log(error)
        setAlertMessage("Error occurred moving the item, please try again")
        setAlertOpen(true)
        setDisableButton(false)
      })
  }

  const onLoadMore = id => {
    if(instances.loading)return;
    loadInstances({parentID:id, page:pageMap[id].page+1, isExpand:true })
  }
  
  let selectedParent;
  if(initialParent){
    selectedParent = initialParent
  }
  if(selectedInstance && instances.data){
    if(typeof(selectedInstance)==='string'){
      selectedParent = instances.data.find(i=>i.id===selectedInstance)
    }
    if(typeof(selectedInstance)==='object'){
      selectedParent = selectedInstance
    }
  }
  if(selectedInstance==='root'){
    selectedParent = state.basicData.parent
  }

  let categoryName = state.basicData.parent.category==='GLOSSARY'?'TERM':'INSTANCE'

  return (
    <div >
      <Modal 
        className={classes.root}
        open={modalOpen} 
        onClose={()=>onCancel()}
        disableBackdropClick={true}
      > 
        <div style={{width:640,marginTop:'20vh',outline:'none'}}>
          <Paper style={{width:590,padding:24,paddingBottom:8,background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
            <Typography className={classes.title}>MOVE {categoryName}</Typography>
            <Typography className={classes.subTitle}>Select a new parent for this {toTitleCase(categoryName)}. Clear the parent if you want to move it back to the top most level.</Typography>
            <TabBar
              minWidth={150}
              maxWidth={150}
              tabOptions={['BROWSE','SEARCH']}
              tabState={tabState}
              setTabState={setTabState}
              disableScroll={true}
              disableUnderline={true}
            />

            {
              selectedParent && 
              <div style={{marginTop:16}}>
                <Typography className={classes.sectionHeader}>PARENT</Typography>
                <div className={classes.chip + ' ' + classes.selectedChip} style={{paddingRight:selectedParent.id !== state.basicData.parent.id?undefined:12}}>
                  {selectedParent.name}
                  {
                    selectedParent.id !== state.basicData.parent.id &&
                    <IconButton data-test-id="remove-parent-button" onClick={()=>onRemoveParent()} className={classes.iconButton}>
                      {getIconComponent({label:'clear_circle',size:20,colour:theme.palette.background.main})}
                    </IconButton>
                  }
                </div>
              </div>
            }
            {
              tabState===0 && 
              <div>
                <div style={{marginTop:16}}>
                  <Typography className={classes.sectionHeader}>SELECT A NEW PARENT</Typography>
                  {
                    instances.error && 
                    <Typography style={{fontSize:13.75}}>Error occurred loading data</Typography>
                  }
                  {
                    instances.data && 
                    <FolderSelector
                      rootCollection={state.basicData.parent}
                      list={instances.data}
                      setSelectedInstance={setSelectedInstance}
                      onItemExpand={onItemExpand}
                      loading={loading}
                      onLoadMore={onLoadMore}
                      pageMap={pageMap}
                      currentInstance={state.basicData}
                    />
                  }
                  {
                    instances.loading && 
                    <div style={{display:'flex',justifyContent:'center'}}>
                      <CircularProgress color='secondary'/>
                    </div>
                  }
                </div>
              </div>
            }
            {
              tabState===1 && 
              <InputSelector
                state={state}
                rootId={state.basicData.parent.id}
                selectedInstance={selectedInstance}
                setSelectedInstance={setSelectedInstance}
                instanceId={state.basicData.id}
              />
            }
            <div className={classes.buttons} align='right'>
              <Button data-test-id="parent-modal-move-button" color="primary" style={{width:80}} onClick={onMove} disabled={disableButton || !selectedInstance}>
                MOVE
              </Button>
              <Button data-test-id="parent-modal-cancel-button" color="secondary" style={{width:80, marginLeft: 8}}  onClick={onCancel} disabled={disableButton}>
                CANCEL
              </Button>
            </div>
          </Paper>

          <ModalAlert
            open={alertOpen}
            setOpen={setAlertOpen}
            message={alertMessage}
            type='error'
          />
        </div>
      </Modal>
    </div>
  )
}
export default withTheme()(withStyles(styles)(EditModal));