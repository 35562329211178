import React, {useEffect, useState} from 'react';
import { withStyles, Select, MenuItem, Typography, withTheme } from '@material-ui/core';
import {loadTileData, loadGraphData} from './dataLoader'
import { generateChartGroup, generateRecommendationList } from './componentGenerator'
import VerticalTabBar from '../../../UI/VerticalTabBar/VerticalTabBar';
import { useRef } from 'react';
import { getIconComponent } from '../../../../utilities';
import 'url-search-params-polyfill';

const styles = theme => ({
  chartContainer:{
    width:'100%',
    flexGrow:1,
    boxSizing:'border-box',
    padding:16,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:4,
    marginBottom:24
  },
  header:{
    fontSize:20,
    color:theme.palette.primaryText.main,
  },
  subHeader:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:8
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  selector:{
    ...theme.components.titleSelector,
    paddingRight:8,
    marginBottom:4
  },
  captionText:{
    fontSize:12,
    color:theme.palette.primaryText.light
  },
  chartTitle:{
    fontSize:16,
    color:theme.palette.header.main,
  },
  chartSubtitle:{
    fontSize:12,
    color:theme.palette.primaryText.light
  },
  subTitle:{
    color:theme.palette.primaryText.light,
    fontSize:13.75
  },
  tooltip:{
    fontSize:13.75,
    whiteSpace:'pre-wrap'
  },
  timeSelector:{
    ...theme.components.inputBase,
    '& div':{
      backgroundColor:'transparent',
    },
    '& label':{
      color:theme.palette.primaryText.light
    },
    '& input':{
      color:theme.palette.primaryText.main,
      padding:'15px 16px 15px'
    }
  },
  selectorHeader:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.overline.main,
    marginBottom:8,
    width:'max-content'
  },
  suggestionChip:{
    ...theme.components.suggestionChip
  },
  suggestionHeader:{
    fontSize:12,
    letterSpacing:2,
    marginTop:16,
    marginBottom:8,
    color:theme.palette.primaryText.light
  },
  recommendationContainer:{
    height:48,
    display:'flex',
    alignItems:'center',
    padding:'0px 16px',
    overflow:'hidden',
    maxWidth:800,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:"pointer",
    '&:hover':{
      backgroundColor:theme.palette.hovered.main
    }
  },
  recommendationTitle:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    flexGrow:1,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginRight:16
  },
  recommendationCount:{
    height:32,
    borderRadius:16,
    fontSize:13,
    backgroundColor:theme.palette.primary.dark,
    color:theme.palette.background.main,
    display:'flex',
    alignItems:'center',
    padding:'0px 8px',
  },
  releaseStateChip:{
    padding:'4px 8px',
    borderRadius:12,
    height:24,
    boxSizing:'border-box',
    display:'flex',
    alignItems:'center',
    fontSize:13.75,
    marginLeft:8
  },
  sectionHeader:{
    fontSize:12,
    letterSpacing:1,
    marginLeft:16,
    marginTop:12,
    marginBottom:12,
    pointerEvents:'none',
  }
})

const BySource = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    history
  } = props;


  let data = state.usageBySourceData[state.usageSource];
  let setData = d => dispatch({type:'set_usage_by_source_data',usageBySourceData:{...state.usageBySourceData,[state.usageSource]:d}})

  const tabState = state.usageBySourceTabState || 0;
  const setTabState = value => dispatch({type:'set_usage_by_source_tab_state',usageBySourceTabState:value})

  let source = state.dbList.find(el=>el.id===state.usageSource)
  const graphApiSeq = useRef(1)

  const [filterValues, setFilterValues] = useState({})

  useEffect(()=>{
    if(Object.keys(state.usageData).length===0 || !data)return;
    loadTileData({data, setData, source});
    return ()=>{
      window.onpopstate = undefined
    }
    // eslint-disable-next-line
  },[state.usageSource])

  useEffect(()=>{
    if(data && data.selectedTile){
      setFilterValues({...filterValues,sourceId: state.usageSource, objectType:data.selectedTile})
    }
  // eslint-disable-next-line
  },[data,state.usageSource])

  useEffect(()=>{
    if(Object.keys(state.usageData).length===0)return;
    if(!data || !data.tileData || data[`${data.selectedTile}_graphData`] || data[`${data.selectedTile}_graphDataLoading`] || data[`${data.selectedTile}_graphDataError`])return;
    if(!data.selectedTile)return;
    graphApiSeq.current += 1;
    loadGraphData({data,setData, history,source, state, apiSeq:graphApiSeq.current, apiSeqRef:graphApiSeq})
    // eslint-disable-next-line
  },[data])

  const getSourceListItem = el =>  {
    return (
      <MenuItem value={el.id} style={{display:'flex',alignItems:'center'}}>
        <div style={{width:24,height:24,marginRight:16}}>
          {getIconComponent({label:el.source_template.name, size:24, colour:theme.palette.primaryText.light,defaultLabel:'source'})}
        </div>
        <Typography style={{fontSize:16,overflow:'hidden',flexGrow:1,whiteSpace:'nowrap',textOverflow:'ellipsis',marginRight:16}}>
          {el.name}
        </Typography>
      </MenuItem>
    )
  }

  if(Object.keys(state.usageData).length===0){
    return <Typography className={classes.normalText}>No sources available</Typography>
  }
  
  let tabOptions = [
    'SUMMARY',
    'ACTIVE USER RISK MAP'
  ].filter(el=>el)

  return (
    <div style={{display:'flex',overflow:'hidden',minHeight:500}}>
      <div style={{flex:'0 0 200px'}}>
        <VerticalTabBar
          tabOptions={[tabOptions[0]]}
          tabState={tabState}
          setTabState={value=>setTabState(0)}
        />
        <Typography className={classes.sectionHeader}>INSIGHTS</Typography>
        <VerticalTabBar
          tabOptions={tabOptions.slice(1)}
          tabState={tabState-1}
          setTabState={value=>setTabState(value+1)}
          enableTooltip
        />
      </div>
      
      <div style={{marginLeft:80,flexGrow:1,minHeight:'120vh'}}>

        
        <div style={{display:"flex",justifyContent:'space-between',alignItems:'flex-start'}}>
          <Select
            className={classes.selector}
            value={state.usageSource}
            disableUnderline
            inputProps={{
              'data-test-id':'usage-source-selector'
            }}
            onChange={event=>dispatch({type:'set_usage_source',usageSource:event.target.value})}
            renderValue={value => {
              let source = state.dbList.find(db=>db.id===value);
              if(!source)return value;
              return (
                <div style={{display:'flex',alignItems:'center',overflow:'hidden',paddingTop:0,paddingBottom:0,paddingRight:12,paddingLeft:8}}>
                  {
                    getIconComponent({label:source.source_template.name, size:24, colour:theme.palette.primaryText.light,defaultLabel:'source'})
                  }
                  <Typography style={{flexGrow:1,marginLeft:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap',textTransform:'uppercase',color:theme.palette.header.main,fontSize:20}}>{source.name}</Typography>
                </div>
              )
            }}
          >
            {
              state.dbList.find(el=>el.source_template.type==='DATABASE') &&
              <Typography className={classes.sectionHeader}>DATA</Typography>
            }
            {
              state.dbList.filter(el=>['DATABASE'].includes(el.source_template.type)).map(getSourceListItem)
            }
            {
              state.dbList.find(el=>el.source_template.type==='DATABASE') &&
              <Typography className={classes.sectionHeader}>CONTENT</Typography>
            }
            {
              state.dbList.filter(el=>['TOOL'].includes(el.source_template.type)).map(getSourceListItem)
            }
          </Select>
          
          {/* {generateExtractDownloader({ history, state, dispatch, data, filterValues, dbList:state.dbList})} */}
        </div>

        <Typography className={classes.captionText} style={{marginBottom:16}}>Select a source or tool to see details</Typography>
        {/* {generateDataTiles({classes, data, setData})} */}

        {
          <div key={state.usageSource + data.selectedTile}>
            <Typography className={classes.header}>INSIGHTS</Typography>
            <Typography className={classes.subHeader}>Select a metric to drill through to the details in Search</Typography>

            {generateChartGroup({classes,data,setData,tabState,tabOptions})}

            {
              tabState!==0 && 
              <>
                <Typography className={classes.header} id="recommendation-section">RECOMMENDATIONS</Typography>
                <Typography className={classes.subHeader}>Select a recommendation to see more details</Typography>
                {generateRecommendationList({classes,data,tabState,tabOptions}) }
              </>
            }
          </div>
        }
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(BySource));