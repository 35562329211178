import React from 'react';
import PropTypes from 'prop-types';
import { withStyles} from '@material-ui/core';
import Dashboard from '../../UI/Dashboard/DirectIndirectDashboard/DirectIndirectDashboard';
import Details from '../Details/Details';
import NoteAdder from '../../UI/NoteAdder/NoteAdder'
import NewLineageContainer from '../../UI/Lineage/NewLineageContainer'

const styles = theme => ({
  root: {

  }
})

const Body = props => {

  const {
    classes,
    tabState,
    history,
    state,
    dispatch,
    fetchList,
    tabOptions,
    isLineageLinkable
  } = props;

  let bodyContent;
  switch (tabOptions[tabState]) {
    case 'DETAILS': // DETAILS
      bodyContent = (
        <Details
          detailsData={state.detailsData}
          state={state}
          fetchList={fetchList}
          history={history}
          dispatch={dispatch}
        >
        </Details>
      );
      break;
    case 'KNOWLEDGE':
      bodyContent = (
        <NoteAdder
          history={history}
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.noteModalOpen}
          setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
        />
      )
      break;
    case 'USAGE': // USAGE
      bodyContent = (
        <Dashboard
          history={history}
          state={state}
          label='macro'
          dispatch={dispatch}
          drillThrough={true}
        />
      )
      break;
    case 'LINEAGE': // LINEAGE
      bodyContent = (
        <NewLineageContainer
          history={history}
          state={state}
          dispatch={dispatch}
          isLinkable={isLineageLinkable}
        />
      )
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
  tabUrlPrefix: PropTypes.string,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.object,
  sessionData: PropTypes.string
}

export default withStyles(styles)(Body);