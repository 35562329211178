import React,{useState,useEffect,useRef} from 'react';
import { withTheme, withStyles, Typography, Select,MenuItem, CircularProgress,Button,  Paper, Popper, MenuList, ClickAwayListener, Checkbox, InputBase,IconButton } from '@material-ui/core';
import ResultItem2 from '../../UI/SearchResults/ResultItem2';
import {getIconComponent,  onClickResultItem, getDispFields,isInViewport,toTitleCase,getSearchMode, mapObjectName, formatNumber} from '../../../utilities'
import axiosSolr from '../../../axios-solr'
import axiosCerebrum from '../../../axios-cerebrum'
import ChipWrapper from '../../UI/ChipWrapper/ChipWrapper'
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';

const styles = theme => ({
  root:{
    display:'flex'
  },
  selector: {
    ...theme.components.selector,
		width: 160,
  },
  divButton:{
    height:36,
    width:36,
    display:'flex',
    alignItems:'center',
    justifyContent:'space-around',
    borderRadius:18,
    cursor:'pointer',
    '&:hover':{
      backgroundColor:theme.palette.hovered.main
    }
  },
  title: {
    display: 'inline-block',
    // fontSize: '1.1rem',
    position: 'relative',
    top: '0.1rem',
    color: theme.palette.header.main,
    fontWeight: '400',
    fontSize: '1.25rem'
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },

  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  checkbox:{
    paddingLeft:0,
  },

	searchFilter:{
    ...theme.components.inputBase,
    marginRight:24,
    width:160,
		height:40,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
  chip:{
    ...theme.components.suggestionChip,
    width:'max-content',
    height:28,
    borderRadius:14,
    padding:'0 12px'
  },
})

function Linked(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    history,
    tabs,
    tabState,
    setTabState,
    linkedData, 
    setLinkedData,
    relation
  } = props;

  let scrollRef = useRef();
  const [editing, setEditing] = useState(false);
	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);
	const searchTimeoutRef = useRef()

  let label, data, loading;
  let sort = 'TRUST_SCORE_DESC'
  let search = ''
  if(tabs && tabs.length>0 && linkedData){
    label = tabs[tabState].label;
    data = linkedData[label];
    loading = data?data.loading:false;
    sort  = data?(data.sort||'TRUST_SCORE_DESC'):'TRUST_SCORE_DESC'
    search = data?(data.search||''):''
  }
  
  const loadData = ({page=1,sort,search}) => {
    setLinkedData({
      ...linkedData,
      [label]:{
        ...(data||{}),
        items:page===1?[]:data.items,
        sort,
        search,
        loading:true,
        error:false
      }
    })
    let url, params;
    url = `/api/collectioninstances/${state.basicData.id}/related`;
    params = {
      object_name:label,
      page:page,
      relationship: relation,
      'search.name':search && search.trim()!==''?search:undefined,
      sort,
      per_page:10
    }
    axiosCerebrum
      .get(
        url,
        {params}
      )
      .then(response=>{
        if(response.data.total===0){
          setLinkedData({
            ...linkedData,
            [label]:{...response.data,sort,search}
          })
          return;
        }
        axiosSolr.get(
          '/solr/search/select',
          {params:{
            q:"*",
            fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
            fl:'*',
            rows:10
          }}
        ).then(solrRes=>{
          let arr = [];
          response.data.items.forEach(el=>{
            arr.push({...el,...solrRes.data.response.docs.find(s=>s.id===el.id)||{}})
          }) 
          setLinkedData({
            ...linkedData,
            [label]:{
              ...response.data,
              items:page===1?arr:[...data.items,...arr],
              sort,
              search,
              loading:false,
              error:false
            }
          })
        }).catch(error=>{
          console.log(error)
          setLinkedData({
            ...linkedData,
            [label]:{
              error:true
            }
          })
        })
      })
      .catch(error=>{
        console.log(error)
        setLinkedData({
          ...linkedData,
          [label]:{
            error:true
          }
        })
      })
  }


  const shoudLoadMore = () => {
    return data && !loading && data.page<data.pages && isInViewport(scrollRef)
  }

  useEffect(()=>{
    if(!tabs||tabs.length===0)return;
    if(!data && !loading){
      loadData({page:1,sort,search});
    }
    if(shoudLoadMore())loadData({page:data.page+1,sort:sort,search})
  // eslint-disable-next-line
  },[tabs,tabState,linkedData])

  window.onscroll=()=>{
    if(!shoudLoadMore())return;
    loadData({page:data.page+1,sort,search})
  }


  const onFilterChange = value => {
    setLinkedData({
      ...linkedData,
      [label]:{
        ...data,
        sort:value
      }
    })
    loadData({
      page:1, sort:value, search
    })
  }

  const onDelete = item => {
    axiosCerebrum.delete(
      `/api/collectioninstances/${state.basicData.id}/related?relationship=${item.relationship}&object_id=${item.id}`,
    ).then(()=>{
      let object = {...data,total:data.total-1,items:data.items.filter(el=>el.id!==item.id || el.relationship!==item.relationship)}
      setLinkedData({...state.linkedData,[label]:object})
    }).catch(error=>{
      console.log(error);
    })
  }

  const filterOutDeleted = arr => {
    let finalArr = [];
    arr.forEach(el=>{
      if(state.showDeletedItems || el.active_txt!=='NO')finalArr.push(el);
    })
    return finalArr
  }

  const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

	const onClickViewInSearch = () => {
    if(!state.basicData.parent.short_name)return;
    let presetFilter = `${state.basicData.parent.short_name.toLowerCase().replace(/\s+/g,'_')}_kc_msrt:${state.basicData.name}`;
    let objectName = mapObjectName(label);
    if(label==='COLLECTION_INSTANCE'){
      objectName = 'ALL'
    }
    presetFilter += ` AND object_type_srt:COLLECTION_INSTANCE`
    history.push(`/basic_search?query=*&object=${objectName}&presetFilter=${presetFilter}`)
	}

  const onClickShowDeleted = value => {
		dispatch({type:'set_show_deleted_items',showDeletedItems:value})
		// onLoading({showDeleted:value})
	}

  const onSearchChange = value => {
    setLinkedData({
      ...linkedData,
      [label]:{
        ...data,
        search:value
      }
    })
    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(()=>{
      loadData({page:1,sort,search:value})
    },250)
  }

  if(state.linkedTabsLoading){
    return (
      <div style={{width:'100%',marginTop:80,display:'flex',justifyContent:'space-around'}}>
        <CircularProgress color='secondary' />
      </div>
    )
  }
  if(state.linkedTabsError){
    return <Typography className={classes.normalText}>Error occurred loading items</Typography>
  }
  if(!tabs){
    return <div></div>
  }

  if(tabs.length===0){
    return <Typography className={classes.normalText}>No item found</Typography>
  }

  return (
    <div className={classes.root}>
      <VerticalTabBar
        tabOptions={tabs.map(el=>el.dispName)}
        tabState={tabState}
        setTabState={setTabState}
      />
      <div style={{marginLeft:80,flexGrow:1,overflow:'hidden'}}>
        <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap'}}>
          <div style={{marginBottom:24}}>
            <div style={{display:'flex',alignItems:'center',minHeight:42}}>
              <Typography className={classes.title}>
                {`${data && data.total?formatNumber(data.total):'0'} ${mapObjectName(label,true).replace(/_/g,' ').toUpperCase()}(S)`}
              </Typography>
              {!editing &&  <Button color='primary' style={{marginLeft:16,marginTop:6}} onClick={()=>setEditing(true)}>EDIT</Button>}
              {editing &&  <Button color='secondary' style={{marginLeft:16,marginTop:6}} onClick={()=>setEditing(false)}>CLOSE</Button>}
            </div>
            <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>{toTitleCase(mapObjectName(label,true).replace(/_/g,' '))}(s) linked to this instance. Click EDIT to remove links</Typography>
          </div>
          <div style={{display:'flex',marginBottom:24}}>
            {
              !editing &&
              <div style={{display:'flex',flexGrow:0,alignItems:'center'}}>
                {
                  tabs[tabState].dispName!=='QUERY' && 
                  <InputBase
                    value={search}
                    onChange={event => onSearchChange(event.target.value)}
                    variant={'filled'}
                    placeholder={'Search'}
                    className={classes.searchFilter}
                    endAdornment={
                      <IconButton 
                        disabled={search===''} 
                        onClick={()=>onSearchChange('')}
                        style={{width:32,height:32,marginRight:6}}
                      >
                        {getIconComponent({label:search===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                      </IconButton>
                    }
                  />
                }
                <Select
                  className={classes.selector}
                  value={sort}
                  onChange={event => onFilterChange(event.target.value)}
                  MenuProps={{
                    classes:{
                      paper:classes.selectPaper
                    }
                  }}
                >
                  {
                    [{disp:'Trust',value:'TRUST_SCORE_DESC'},{disp:'A-Z',value:'ALPHABETICAL'},{disp:'Z-A',value:'REVERSE_ALPHABETICAL'}].map(el=>(
                      <MenuItem  className={classes.menuItem} value={el.value}>
                        <span>{el.disp}</span>
                      </MenuItem>
                    ))
                  }
                </Select>
                {
                  <div style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} onClick={onListActionClick}>
                    {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
                  </div>
                }
                
                <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
                  <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
                    <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                      {
                        !['CHANGE','KNOWLEDGE'].includes(state.basicData.parent.category) && !(getSearchMode()==='basic_search' && !state.basicData.parent.short_name) && data && data.items && filterOutDeleted(data.items).length!==0 &&
                        <div>
                          <Typography className={classes.listActionSectionTitle}>ACTIONS</Typography>
                          <MenuList className={classes.listContainer}>
                            <MenuItem onClick={()=>{onClickViewInSearch()}} className={classes.menuItem} >
                              <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>View in search</Typography>
                            </MenuItem>
                          </MenuList>
                        </div>
                      }
                      <Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
                      <MenuItem onClick={()=>{onClickShowDeleted(!state.showDeletedItems)}} className={classes.menuItem} >
                        <Checkbox key={state.showDeletedItems} className={classes.checkbox} color='primary' checked={!state.showDeletedItems}/>
                        <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
                      </MenuItem>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </div>
            }
          </div>
        </div>
        <div>
          {
            data && data.error &&
            <Typography className={classes.normalText} style={{marginTop:16}}>Error occurred loading items</Typography>
          }
          {
            data && data.items && filterOutDeleted(data.items).length===0 && !loading &&
            <Typography className={classes.normalText} style={{marginTop:16}}>No item found</Typography>
          }
          {
            data && data.items && filterOutDeleted(data.items).map(item=>(
              <ChipWrapper
                showUnderline={true}
                wrappedComponent={
                  <ResultItem2
                    key={item.id+state.editing}
                    item={item}
                    dispBody={getDispFields(item,'dispBody')}
                    dispSubtitle={getDispFields(item,'dispSubtitle')}
                    dispTitle={getDispFields(item,'dispTitle')}
                    showIcon
                    showDetailedTestScore
                    label={item.object_type_txt||item.type}
                    tagsWidthOffset={state.editing?48:0}
                    onClick={() => onClickResultItem({id:item.id, label:item.object_type_txt||item.type,history:history,item:item})}
                  />
                }
                icon={editing?getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light}):undefined}
                onIconClick={()=>onDelete(item)}
              />
            ))
          }
        </div>
        <div ref={scrollRef} style={{width:'100%',display:'flex',justifyContent:'center',marginBottom:10}}>
          {loading && <CircularProgress style={{marginTop:80}} color='secondary'/>}
        </div>
      </div>
    </div>
  )
}



export default (withTheme()(withStyles(styles)(Linked)));