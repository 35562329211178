import React,{useEffect,useRef} from 'react';
import { withTheme, withStyles, Typography, CircularProgress } from '@material-ui/core';
import {isInViewport} from '../../../utilities'
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import axiosSolr from '../../../axios-solr';
import axiosCerebrum from '../../../axios-cerebrum';
import SolrList from '../../UI/SolrList/SolrList';

const styles = theme => ({
  root:{
    display:'flex'
  }
})

function Linked(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    history
  } = props;

  let scrollRef = useRef();
  const tabOptions = ['tool']

  const loadData = ({page=1, tabState}) => {
    let tabName = tabOptions[tabState];
    dispatch({
      type:'set_linked_data',
      linkedData:{
        ...state.linkedData,
        [tabName+'_loading']:true
      }
    });
    let url,param;
    if(tabName==='tool'){
      url = `/api/queries/${state.basicData.id}/related`;
      param = {
        object_name:'TOOL',
        per_page:10,
        page
      }
    }
    axiosCerebrum.get(
      url,
      {params:param}
    ).then(response=>{
      if(response.data.total===0){
        dispatch({
          type:'set_linked_data',
          linkedData:{
            ...state.linkedData,
            [tabName+'_loading']:false,
            [tabName]:response.data
          }
        })
        return;
      }
      axiosSolr
        .get(
          `/solr/search/select`,
          {params:{
            q:"*",
            fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
            fl:'*',
            rows:10,
          }}
        )
        .then(solrRes=>{
          let items = response.data.items.map(el=>solrRes.data.response.docs.find(s=>s.id===el.id));
          dispatch({
            type:'set_linked_data',
            linkedData:{
              ...state.linkedData,
              [tabName+'_loading']:false,
              [tabName]:page===1?{...response.data,items}:{...response.data,items:[...state.linkedData[tabName].items,...items]}
            }
          })
        })
    }).catch(error=>{
      console.log(error);
      dispatch({
        type:'set_linked_data',
        linkedData:{
          ...state.linkedData,
          [tabName+'_loading']:false,
          [tabName+'_error']:true
        }
      });
    })
    
  }

  const shoudLoadMore = () => {
    let tabName = tabOptions[state.linkedTabState];
    return isInViewport(scrollRef) && state.linkedData[tabName] && state.linkedData[tabName].page<state.linkedData[tabName].pages && !state.linkedData[tabName+'_loading']
  }

  window.onscroll=()=>{
    let tabName = tabOptions[state.linkedTabState];
    if(shoudLoadMore())loadData({page:state.linkedData[tabName].page+1,tabState:state.linkedTabState})
  }

  useEffect(()=>{
    let tabName = tabOptions[state.linkedTabState];
    if(!state.linkedData[tabName] && !state.linkedData[tabName+'_loading']){
      loadData({page:1,tabState:state.linkedTabState})
    }
  // eslint-disable-next-line
  },[state.tabState,state.linkedTabState])

  useEffect(()=>{
    if(shoudLoadMore())loadData({page:state.linkedData[tabName].page+1,tabState:state.linkedTabState})
    // eslint-disable-next-line
  },[state.linkedData])

  let tabName = tabOptions[state.linkedTabState];
  let subTitle = '';
  switch(tabName){
    case 'tool':
      subTitle='Tools used to run this query';
      break;
    default:
  }

  if(state.linkedData[tabName+'_loading'] && !state.linkedData[tabName]?.items){
    return <CircularProgress color='secondary' />
  }

  if(state.linkedData[tabName]?.items?.length===0){
    return <Typography style={{color:theme.palette.primaryText.main}}>{`No item linked to this query`}</Typography>
  }

  if(!state.linkedData[tabName]){
    return <div></div>
  }

  return (
    <div className={classes.root}>
      <VerticalTabBar
        tabOptions={tabOptions}
        tabState={state.linkedTabState}
        setTabState={value => dispatch({ type: 'set_linked_tab_state', linkedTabState: value })}
        minWidth={200}
        maxWidth={200}
        disableUnderline={true}
      />
      <div style={{flexGrow:1,marginLeft:80,overflow:'hidden'}}>
        {
          state.linkedData[tabName] && 
          <div>
            <Typography style={{fontSize:20,color:theme.palette.header.main}}>
              {`${state.linkedData[tabName].total} ${tabName.toUpperCase()}(S)`}
            </Typography>
            <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:24}}>{subTitle}</Typography>
            {
              state.linkedData[tabName] && state.linkedData[tabName].total===0 && 
              <Typography style={{color:theme.palette.primaryText.main}}>{`No ${tabName} linked to this query`}</Typography>
            }
            <SolrList
              list={state.linkedData[tabName].items}
              history={history}
              hideTopMessage={true}
            />
          </div>  
        }

        {
          state.linkedData[tabName+'_loading'] && state.linkedData[tabName+'_error'] && 
          <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading data</Typography>
        }

        <div ref={scrollRef} style={{marginBottom:20,marginTop:10,display:'flex',justifyContent:'center'}}>
          {
            state.linkedData[tabName+'_loading'] && 
            <CircularProgress color='secondary'/>
          }
        </div>
      </div>

    </div>
  )
}


export default withTheme()(withStyles(styles)(Linked));