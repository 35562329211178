import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Dashboard from '../../UI/Dashboard/DirectIndirectDashboard/DirectIndirectDashboard';
import Details from '../Details/Details';
// import RelatedContent from '../RelatedContent/RelatedContent';
import LinkedTo from '../LinkedTo/LinkedTo'
import Joins from '../../UI/Joins/Joins';
import NoteAdder from '../../UI/NoteAdder/NoteAdder'
import Issues from '../../UI/ProfileIssueSection/Issues';
import NewLineageContainer from '../../UI/Lineage/NewLineageContainer';
import DQInsights from '../../UI/DQInsights/DQInsights';

const styles = theme => ({
  root: {

  },
  ItemListWrapper: {
  }
})

function Body(props) {

  const {
    classes,
    tabOptions,
    tabState,
    history,
    basicData,
    // new props
    state,
    dispatch,
    fetchList,
    sessionData,
    loading,
    error,
    isLineageLinkable,
    cerebrumLabel
  } = props;
 
  let bodyContent;
  switch (tabOptions[tabState]) {
    case 'DETAILS': // details
      bodyContent = (
        <Details
          basicData={basicData}
          cerebrumLabel={cerebrumLabel}
          fetchList={fetchList}
          userName={sessionData.user_name}
          state={state}
          dispatch={dispatch}
          history={history}
          loading={loading}
          error={error}
        />
      )
      break;
    case 'KNOWLEDGE':
      bodyContent = (
        <NoteAdder
          history={history}
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.noteModalOpen}
          setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
        />
      )
      break;
    case 'USAGE': // summary
      bodyContent = (
        <Dashboard
          history={history}
          state={state}
          label='query'
          dispatch={dispatch}
          drillThrough={true}
        />
      )
      break; 
    case 'QUALITY':
      bodyContent = (
        <DQInsights
          state={state}
          history={history}
          dispatch={dispatch}
          object={state.basicData}
        />
      )
      break;
    case 'JOINS':
      bodyContent = (
        <Joins
          state={state}
          history={history}
          targetObject={state.basicData}
          joinsData={state.joinsData}
          setJoinsData={data=>dispatch({type:'set_joins_data',joinsData:data})}
          joinsView={state.joinsView}
          setJoinsView={view=>dispatch({type:'set_joins_view',joinsView:view})}
        />
      )
      break;
    case 'LINEAGE':
      bodyContent=(
        <NewLineageContainer
          history={history}
          state={state}
          dispatch={dispatch}
          isLinkable={isLineageLinkable}
        />
      )
      break;
    case 'ISSUES':
        bodyContent = (
          <Issues
            history={history}
            state={state}
            dispatch={dispatch}
          />
        )
      break;
    case 'RELATED': 
      bodyContent = (
        <LinkedTo
          basicData={basicData}
          cerebrumLabel={cerebrumLabel}
          fetchList={fetchList}
          userName={sessionData.user_name}
          state={state}
          dispatch={dispatch}
          history={history}
          loading={loading}
          error={error}
        />
      )
    break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.object,
  sessionData: PropTypes.object
}

export default withStyles(styles)(Body);