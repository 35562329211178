import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Button, CircularProgress,Typography,Modal,Paper,LinearProgress } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import axiosCerebrum from '../../../axios-cerebrum';
import AddDetails from '../AddDetails/AddDetails';
import AddProperties from '../AddProperties/AddProperties';
import CreateCollection from '../CreateCollection/CreateCollection';
import { toTitleCase } from '../../../utilities';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {
    width: '100%',
  },
  labelText: {
    color:theme.palette.primaryText.light
  },
  selectedLabelText:{
    color:`${theme.palette.primary.main} !important`,
    fontWeight:'400 !important'
  },
  stepIcon:{
    cursor:'pointer'
  },
  button:{
    minWidth:96,
    height:36,
    marginLeft:8
  },
  buttons:{
    position:'fixed',
    backgroundColor:theme.palette.background.main,
    height:80,
    bottom:0,
    right:0,
    left:40,
    //width:'100%',
    display:'flex',
    alignItems:'center',
    borderTop:`1px solid ${theme.palette.listItemDivider.main}`
  },
  modalButtons:{
    width:'100%',
    display:'flex',
    justifyContent:'flex-end',
    marginTop:26,
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  stepper:{
    backgroundColor:theme.palette.background.main
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  boldText:{
    fontWeight:700,
    fontSize:16
  }
})

function Body(props) {

  const {
    theme,
    classes,
    state,
    dispatch,
    history,
    onSave,
    onCancel,
    onActiveStepChange
  } = props;

  const [creating, setCreating] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);

  const {
    sendAlert
  } = useAlert({
    id:`create-collection-body`,
  })

  let instanceName = 'Instance'
  if(state.createdCollection){
    if(state.createdCollection.category==='GLOSSARY')instanceName = 'Term'
  }else{
    if(state.collectionType==='GLOSSARY')instanceName = 'Term'
  }
  let steps;
  if(!state.editMode)steps=['Add Details',`Add ${instanceName} Properties`];
  if(state.editMode)steps=['Edit Details',`Edit ${instanceName} Properties`]

  let bodyContent;

  const isFieldComplete = (field, value) => {
    if(field==='name'){
      return value && value.trim()!==''
    }
    if(field==='shortName'){
      return value && /^[A-Za-z]([A-Za-z0-9_]|\s)*$/.test(state.collectionShortName)===true
    }
    if(field==='description'){
      return value && value.trim()!==''
    }
  }

  switch (state.activeStep) {
    case 0: 
      bodyContent = <AddDetails history={history} isFieldComplete={isFieldComplete} dispatch={dispatch} state={state} />
      break;
    case 1:
      bodyContent = <AddProperties history={history} dispatch={dispatch} state={state} instanceName={instanceName}/>
      break;
    case 2: 
      bodyContent = <CreateCollection history={history} dispatch={dispatch} state={state} />
      break;
    default:
  }

  
  const create = () => {
    if(state.editMode){
      dispatch({type:'set_active_step',activeStep:1})
      return;
    }
    setCreating(true);
    let properties = state.properties.sort((a,b)=>a.position-b.position)
                                      .map(el=>({id:el.id,data_type:el.data_type,description:el.description,name:el.name,required:el.required,allowed_values:el.allowed_values,filter:el.filter}))

    let roles = {};
    let instance_roles = {};
    state.rolesData.forEach(el=>{
      let name = el.name;
      if(state.editableBy.includes(name)){
        roles[name] = ['READ_ONLY','EDIT'];
        instance_roles[name] = ['READ_ONLY','EDIT'];
      }else{
        roles[name] = ['READ_ONLY'];
        instance_roles[name] = ['READ_ONLY'];
      }
      // if(state.linkBy.includes(name)){
      //   instance_roles[name].push('LINK')
      // } 
    })
    if(state.createdCollection){
      if(!state.shouldUpdate){
        setSaveModalOpen(false);
        setCreating(false);
        return;
      }
      
      let payload={
        "description":state.description,
        "roles":roles,
        "instance_roles":instance_roles,
        "properties": properties,
        'short_name':state.collectionShortName
      }
      axiosCerebrum.put(
        `/api/collections/${state.collectionId || state.createdCollection.id}`,payload
      ).then((response)=>{
        setCreating(false);
        sendAlert({type:'info',message:`${toTitleCase(state.collectionName)} collection successfully updated`})
        setSaveModalOpen(false);
        dispatch({type:'set_edit_mode',editMode:true})
        dispatch({type:'set_active_step',activeStep:1})
        dispatch({type:'set_created_collection',createdCollection:response.data})
        dispatch({type:'set_should_update',shouldUpdate:false})
      }).catch(error=>{
        setCreating(false);
        setSaveModalOpen(false);
        console.log(error)
        
        let msg = `Error occurred updating ${toTitleCase(state.collectionName)} collection`;
        if(error.response && error.response.status && error.response.status===409){
          msg = 'The collection name or short name already exists.'
        }
        sendAlert({type:'error',message:msg})
      })
      return;
    }

    let payload={
      "name":state.collectionName,
      "description":state.description,
      "category": state.collectionType,
      "roles":roles,
      "instance_roles":instance_roles,
      "properties": properties,
      'short_name':state.collectionShortName,
      'enable_dq_dashboarding':state.enableDQ
      // "isMultiValue":state.isMultiValue
    }
    axiosCerebrum.post(
      '/api/collections',payload
    ).then(response=>{
      setCreating(false);
      setSaveModalOpen(false);
      sendAlert({type:'info',message:`${toTitleCase(state.collectionName)} collection successfully created`})
      dispatch({type:'set_edit_mode',editMode:true})
      dispatch({type:'set_created_collection',createdCollection:response.data})
      dispatch({type:'set_active_step',activeStep:1})
    }).catch(error=>{
      let msg = `Error occurred creating ${toTitleCase(state.collectionName)} collection`
      if(error.response && error.response.status && error.response.status===409){
        msg = 'The collection name or short name already exists.'
      }
      sendAlert({type:'error',message:msg})
      console.log(error);
      setSaveModalOpen(false);
      setCreating(false);
    })
  }

  const createProperties = () => {
    if(state.editMode && !state.shouldUpdate){
      setSaveModalOpen(false);
      if(onSave)onSave();
      else{history.push(`/admin/collection?tabName=${state.collectionType}`)}
    }
    let roles = {};
    let instance_roles = {};
    state.rolesData.forEach(el=>{
      let name = el.name;
      if(state.editableBy.includes(name)){
        roles[name] = ['READ_ONLY','EDIT'];
        instance_roles[name] = ['READ_ONLY','EDIT'];
      }else{
        roles[name] = ['READ_ONLY'];
        instance_roles[name] = ['READ_ONLY'];
      }
    })
    setCreating(true)
    let properties = state.properties.sort((a,b)=>a.position-b.position)
                                      .map(el=>({
                                        id:el.id,
                                        data_type:el.data_type,
                                        description:el.description,
                                        name:el.name,
                                        required:el.required,
                                        allowed_values:el.allowed_values,
                                        filter:el.filter
                                      }))
    let payload={
      "description":state.description,
      "roles": roles,
      "instance_roles":instance_roles,
      "properties": properties,
      'short_name':state.collectionShortName,
      'enable_dq_dashboarding':state.enableDQ
    }

    axiosCerebrum.put(
      `/api/collections/${state.collectionId || state.createdCollection.id}`,payload
    ).then(()=>{
      setCreating(false);
      sendAlert({type:'info',message:`${toTitleCase(state.collectionName)} collection successfully updated`})
      setSaveModalOpen(false);
      if(onSave)onSave();
      else{ history.push(`/admin/collection?tabName=${state.collectionType}`)}
    }).catch(error=>{
      setCreating(false);
      setSaveModalOpen(false);
      console.log(error)
      sendAlert({type:'error',message:`Error occurred updating ${toTitleCase(state.collectionName)} collection`})
    })
    return;
  }


  const requiredComplete = async () => {
    if(state.activeStep===0){
      if(!state.collectionName || state.collectionName.trim()==='')return false;
      if(!state.description || state.description.trim()==='')return false;
      if(!state.collectionShortName || state.collectionShortName.trim()==='')return false;
      if(/^[A-Za-z]([A-Za-z0-9_]|\s)*$/.test(state.collectionShortName)===false){
        sendAlert({type:'error',message:'Short name must be alphanumeric and underscore characters only and can not start with number, underscore or space.'})
        return false;
      }
      if(!state.editMode && !state.createdCollection){
        const response =  await axiosCerebrum.get(`/api/collections`,{params:{name:state.collectionName, category:state.collectionType}});
        if(response.data.total!==0){
          sendAlert({type:'error',message:'A Collection has already been created with this name. The Collection Name must be unique.'})
          return false;
        }
      }
    }

    return true;
  }

  const onNextClick = () => {
    requiredComplete().then(value=>{
      if(value===true){
        if(state.activeStep===0){
          if(!state.editMode){
            setSaveModalOpen(true);
          }else{
            if(state.shouldUpdate){
              create();
            }else{
              dispatch({type:'set_active_step',activeStep:1})
            }
          }
        }
        if(state.activeStep===1){
          createProperties();
        }
        // if(state.activeStep<steps.length-1)dispatch({type:'set_active_step',activeStep:state.activeStep+1})
      }
    })
  }

  const onBackClick = () => {
    if(state.activeStep>0)dispatch({type:'set_active_step',activeStep:state.activeStep-1})
  }

  const onCancelClick = () => {
    if(onCancel)onCancel();
    else{
      history.goBack()
    }
  }

  useEffect(()=>{
    onActiveStepChange && onActiveStepChange()
    window.scrollTo(0,0)
    // eslint-disable-next-line
  },[state.activeStep])

  const isCreateDisbaled = () => {
    return !isFieldComplete('name',state.collectionName) || !isFieldComplete('shortName',state.collectionShortName) || !isFieldComplete('description',state.description)
  }

  return (
    <div className={classes.root}>
      {
        (state.categoryLoading || state.propertyLoading || state.rolesLoading) &&
        <div style={{ textAlign: 'center', marginTop: '5%' }}>
          <CircularProgress color="secondary" />
          <Typography className={classes.normalText}>Loading</Typography>
        </div>
      }
      {
        (!(state.categoryLoading || state.propertyLoading || state.rolesLoading)) && (state.categoryError || !state.categoryData || state.propertyError || !state.propertyData || state.rolesError || !state.rolesData) && 
        <Typography className={classes.normalText} style={{marginTop:40}}>Error occurred loading template</Typography>
      }
      {
        (state.categoryData && state.propertyData && state.rolesData) && 
        <Stepper className={classes.stepper} activeStep={state.activeStep} alternativeLabel>
          {steps.map((label,index) => (
            <Step  className={classes.step}  key={label} completed={false}>
              <StepLabel 
                onClick={()=>{
                  requiredComplete().then(value=>{
                    if(value===true){
                      if(index===1 && (!state.editMode))setSaveModalOpen(true);
                      else{ dispatch({type:'set_active_step',activeStep:index})}
                    }
                  })
                }}
                data-test-id={`create-collection-step-${index+1}`}
                classes={{
                  active:classes.selectedLabelText,
                  iconContainer:classes.stepIcon,
                  label:classes.labelText
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      }
      {
        (state.categoryData && state.propertyData && state.rolesData) && 
        <div style={{marginBottom:100,marginTop:32}}>{bodyContent}</div>
      }
      
      <div className={classes.buttons}>
          <Button style={{marginLeft:'60%',width:120,height:48, color:theme.palette.primaryText.light,visibility:state.activeStep>0?'visible':'hidden'}} onClick={onBackClick} disabled={creating}>
            BACK
          </Button>
          {
            (state.categoryData && state.propertyData && state.rolesData) && 
            <Button variant='contained' color='primary' style={{marginLeft:30,width:120,height:48}} data-testid = 'CreateCollection-Next/Create' onClick={onNextClick} disabled={creating || isCreateDisbaled()}>
              {state.activeStep===0?
                (state.editMode || state.createdCollection)?'NEXT':'CREATE'
                :
                'SAVE'
              } 
            </Button>
          }
          <Button color='primary' style={{marginLeft:30,width:120,height:48}} onClick={onCancelClick} disabled={creating}>
            Cancel
          </Button>
      </div>

      <Modal
        open={saveModalOpen}
        onClose={() =>setSaveModalOpen(false)}
        disableBackdropClick={true}
      >
        <div style={{widh:`100%`,height:`100vh`,display:'flex',alignItems:'center',outline:'none'}}>
          <Paper style={{width:470,padding:24,paddingBottom:8,margin:'-15vh auto 0', background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}} >
            {
              creating &&
                <div>
                  <Typography style={{fontSize:20,marginBottom:8, color:theme.palette.primaryText.main}}>{`Saving changes to Collection`}</Typography>
                  <div style={{width:'100%'}} align='center'>
                    <Typography style={{fontSize:13.75,marginTop:60, color:theme.palette.primaryText.main}}>Update in progress. This may take some time.</Typography>
                    <LinearProgress color='secondary' style={{width:400,marginTop:24,marginBottom:50}}/>
                  </div>
                  
                </div>
            }
            {
              !state.editMode && !creating && 
              <div>
                <Typography style={{fontSize:20,marginBottom:8, color:theme.palette.primaryText.main}}>{`Confirm the Collection name`}</Typography>
                <Typography style={{ color:theme.palette.primaryText.light,fontSize:16, whiteSpace:'pre-wrap'}}>
                  {`The collection is called `}<span className={classes.boldText}>"{state.collectionName}"</span>{` and the short name is `}<span className={classes.boldText}>"{state.collectionShortName}"</span>{`\n\nMake sure the name Collection name and Short name is correct as you cannot update a name after it has been created`}
                </Typography>
                <div className={classes.modalButtons}>
                  <Button className={classes.button} data-test-id='create-collection-create-button' color='primary' onClick={()=>create()}>CREATE</Button>
                  <Button className={classes.button} data-test-id='create-collection-cancel-button' color='secondary' onClick={()=>setSaveModalOpen(false)}>CANCEL</Button>
                </div>
              </div>
            }
          </Paper>
        </div>
      </Modal> 
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(Body));