import React from 'react';
import { withStyles, withTheme, Typography } from '@material-ui/core';
import { getInitials } from '../../../utilities';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../../UI/ContextMenu/ContextMenu'


const styles = theme => ({
  root:{
    width:'100%',
    paddingTop:10,
    paddingBottom:10,
    overflow:'hidden',
    display:'flex',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  avatar:{
    width:24,
    height:24,
    borderRadius:'50%',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    backgroundColor: theme.palette.avatar.main,
    color:'#FFF',
    marginLeft:16,
    marginRight:32,
    flexShrink:0,
    flexGrow:0,
    fontSize:12
  },
  name:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:"nowrap",
    marginRight:32,
    flexGrow:1,
    color:theme.palette.primaryText.main
  }
})


function ListItem(props) {

  const {
    classes,
    item,
    onClick
  } = props;

  let title = item.name || item.name_txt

  return (
    <div style={{width:"100%"}}>
      <ContextMenuTrigger id={item.id}>
        <div data-test-id={`team-list-item-${title.replace(/\s/g,'-').toLowerCase()}`} onClick={onClick} className={classes.root}>
          <div className={classes.avatar}>
            {getInitials(title)}
          </div>
          <Typography className={classes.name}>{title}</Typography>
        </div>
      </ContextMenuTrigger>

      {
        <ContextMenu id={item.id}>
          <CustomMenu
            item={item}
            actions={[
              'open_new_tab',
            ]}
          />
        </ContextMenu>
      }
    </div>
  );

}


export default withTheme()(withStyles(styles)(ListItem));