import React, { useEffect, useRef } from 'react';
import { withTheme, CircularProgress, withStyles, Typography} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import moment from 'moment'
import axiosSolr from '../../../axios-solr'
import { isInViewport } from '../../../utilities'
import {assignedObjects, getObjectCard} from '../utils'
 
const styles = theme => ({
  root: {

  },
  errorText:{
    color:theme.palette.primaryText.main,
    marginTop:24,
  },
  timelineText:{
    color:theme.palette.primary.main,
    letterSpacing:2,
    marginBottom:8,
    fontSize:12
  }
})

const AssignedTimeLine = props => {

  const {
    classes,
    history,
    state,
    dispatch,
    sessionData,
    forceObject
  } = props;


  const scrollRef = useRef()

  const loadTimeLineData = async ({page=1}) => {
    dispatch({type:'set_assigned_timeline',assignedTimeLineData:page===1?undefined:state.assignedTimeLineData,assignedTimeLineLoading:true})
    let data;
    await axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related`,
        {params:{
          relationship:'ASSIGNEE_OF',
          object_name:forceObject || assignedObjects.join(','),
          per_page:10,
          page:page,
          sort:'LINKAGE_END_DESC'
        }}
      )
      .then(response=>{
        if(response.data.items.length===0){
          dispatch({
            type:'set_assigned_timeline',
            assignedTimeLineData:response.data
          })
          return;
        }
        data = response.data;
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_assigned_timeline',assignedTimeLineError:true})
      })

    if(!data)return;

    let solrDetails = {}
    await axiosSolr.get(
      `/solr/search/select`,{
        params:{
          q:'*',
          fq:`id:(${data.items.map(el=>el.id).join(' OR ')})`,
          fl:'*',
          start:0,
          rows:data.items.length,
        }
      }
    )
    .then(solrRes=>{
      solrRes.data.response.docs.forEach(el=>{
        solrDetails[el.id] = el;
      })
    })
    .catch(error=>{
      console.log(error)
      dispatch({type:'set_assigned_timeline',assignedTimeLineError:true})
    })

    let stats = {}
    await axiosCerebrum
      .get(
        '/api/stats',
        {params:{
          object_ids:data.items.map(el=>el.id).join(','),
          per_page:data.items.map(el=>el.id).length
        }}
      )
      .then(response=>{
        response.data.items.forEach(el=>{
          stats[el.node_id] = el
        })
      })
      .catch(error=>{
        console.log(error)
      })

    dispatch({
      type:'set_assigned_timeline',
      assignedTimeLineData:{
        ...data,
        items:[
          ...(page===1?[]:state.assignedTimeLineData.items),
          ...data.items.map(el=>({
            ...el,
            ...(solrDetails[el.id]||{}),
            stats:stats[el.id]
          }))
        ]
      }
    })
  }

  useEffect(()=>{
    if(!state.assignedTimeLineData && !state.assignedTimeLineLoading){
      loadTimeLineData({})
    }
    // eslint-disable-next-line
  },[])


  const shouldPaginate = () => {
    return state.assignedTimeLineData && state.assignedTimeLineData.page<state.assignedTimeLineData.pages && !state.assignedTimeLineLoading && isInViewport(scrollRef)
  }

  window.onscroll = () => {
    if(shouldPaginate()){
      loadTimeLineData({page:state.assignedTimeLineData.page+1})
    }
  }

  useEffect(()=>{
    if(shouldPaginate()){
      loadTimeLineData({page:state.assignedTimeLineData.page+1})
    }
  // eslint-disable-next-line
  },[state.assignedTimeLineData])

  let todayList = []
  let yesterdayList = [];
  let last7DList = [];
  let lastMonthList = [];
  let moreThanAMonth = []

  if(state.assignedTimeLineData){
    let today = moment(moment().format('YYYY-MM-DD'));
    const getUpdatedTime = el => moment(el.linkage_updated_at).format('YYYY-MM-DD')
    todayList = state.assignedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')===0)
    yesterdayList = state.assignedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')===1)
    last7DList = state.assignedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')>1 && today.diff(getUpdatedTime(el),'day')<=7)
    lastMonthList = state.assignedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')>7 && today.diff(getUpdatedTime(el),'day')<=30)
    moreThanAMonth = state.assignedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')>30)
  }

  return (
    <div className={classes.root}>
      {
        todayList.length > 0 && 
        <Typography className={classes.timelineText}>TODAY</Typography>
      }
      {
        todayList.length > 0 && 
        <div style={{marginBottom:24}}>
          {todayList.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        yesterdayList.length > 0 && 
        <Typography className={classes.timelineText}>YESTERDAY</Typography>
      }
      {
        yesterdayList.length > 0 && 
        <div style={{marginBottom:24}}>
          {yesterdayList.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        last7DList.length > 0 && 
        <Typography className={classes.timelineText}>IN THE LAST 7 DAYS</Typography>
      }
      {
        last7DList.length > 0 && 
        <div style={{marginBottom:24}}>
          {last7DList.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        lastMonthList.length > 0 && 
        <Typography className={classes.timelineText}>IN THE LAST MONTH</Typography>
      }
      {
        lastMonthList.length > 0 && 
        <div style={{marginBottom:24}}>
          {lastMonthList.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        moreThanAMonth.length > 0 && 
        <Typography className={classes.timelineText}>MORE THAN A MONTH</Typography>
      }
      {
        moreThanAMonth.length > 0 && 
        <div style={{marginBottom:24}}>
          {moreThanAMonth.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        state.assignedTimeLineData && (state.assignedTimeLineData.total===0 || state.assignedTimeLineData.items.every(el=>!el.linkage_updated_at)) && 
        <Typography className={classes.errorText}>
          {
            state.assignedTimeLineData.total===0?
            'No items found':
            'No timeline data available'
          }
        </Typography>
      }
      <div ref={scrollRef} style={{textAlign:'center',marginBottom:24}}>
        {
          state.assignedTimeLineLoading && 
          <CircularProgress style={{margin:'24px auto'}} color='secondary'/>
        }
      </div>
      {
        state.assignedTimeLineError && 
        <Typography className={classes.errorText}>Error occurred loading assigned items</Typography>
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(AssignedTimeLine));