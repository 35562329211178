import React, { useReducer, useEffect } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/MyTask/Body/Body';
import { removeUrlQueryArg, setInitialState } from '../../utilities';
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import TabBar from '../../components/UI/TabBar/TabBar';
import { addHistory } from '../../HistoryManager';
import axiosCerebrum from '../../axios-cerebrum';
import 'url-search-params-polyfill';
import useAlert from '../../hooks/useAlert';
import { checkEmailConfigured } from '../../permissionChecker';
import { globalListenerRef } from '../../GlobalListenerRef';

const styles = theme => ({
});


const initialState = {
  tabState: 0,
  jobTabState: 0,
  extractTabState: 0,
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_sources':
      return {
        ...state,
        sources: action.sources,
      }
    case 'set_job_tab_state':
      return {
        ...state,
        jobTabState: action.jobTabState,
      }
    case 'set_extract_tab_state':
      return {
        ...state,
        extractTabState: action.extractTabState,
      }
    /// issue tab
    case 'set_issue_data':
      return {
        ...state,
        issueData:action.issueData,
        issueLoading:action.issueLoading,
        issueError:action.issueError
      }
    case 'set_question_data':
      return {
        ...state,
        questionData:action.questionData,
        questionLoading:action.questionLoading,
        questionError:action.questionError
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const MyTask = props => {

  const {
    history,
    pageCache,
    storePageCache,
    sessionData
  } = props;

  const tabOptions = ['EXTRACTS','JOBS','QUESTIONS','ISSUES']
  
  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));

  const {
    sendAlert
  } = useAlert({})

  const loadSources = () => {
    axiosCerebrum
      .get(
        `/api/sources`,{
          params:{
            per_page:500,
            sort:'ALPHABETICAL'
          }
        }
      )
      .then(response => {
        dispatch({ type: 'set_sources', sources: response.data.items });
      })
      .catch(error => {
        console.log(error);
      });
  }

  useEffect(()=>{
    addHistory({url:window.location.pathname, iconLabel:'data_solution', title: 'My Application Results', subTitle:'My Application Results',type:'application'})
    if(!state.sources)loadSources()
    // eslint-disable-next-line
  },[])

  const onNotifyJobRun = () => {
    let message = 'Your job has been submitted.'
    if(checkEmailConfigured()){
      message += '\n\nYou will receive an email when the results are ready to review.'
    }else{
      message += '\n\nEmail delivery is currently not configured. Please check back regularly.'
    }

    sendAlert({message, type:'info'})
  }

  useEffect(()=>{
    const urlSearch = new URLSearchParams(window.location.search);
    const tabName = urlSearch.get('tabName')
    const submitted = urlSearch.get('isSubmitted')
    if(tabName && tabOptions.includes(tabName)){
      dispatch({type:'set_tab_state',tabState:tabOptions.indexOf(tabName)})
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
    }
    if(submitted){
      onNotifyJobRun()
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['isSubmitted']}));
    }
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.job_submitted ){
        onNotifyJobRun()
      }
    }
    window.removeEventListener('message',globalListenerRef.myTaskMsgListener);
    globalListenerRef.myTaskMsgListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.myTaskMsgListener);
    return (()=>{window.removeEventListener('message',globalListenerRef.myTaskMsgListener);})
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])


  return (
    <div>
      <ProfileLayout
        header={(
          <ProfileHeader
            title={'My Application Results'}
            subtitle={' '}
            label={'my_tasks'}
            iconLabel={'data_solution'}
            state={state}
            history={history}
          />
        )}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
            tabOptions={tabOptions}
          />
        }
        state={state}
        dispatch={dispatch}
      />
    </div> 
  ) 
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
    permissions: state.auth.permissions
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(MyTask)));
