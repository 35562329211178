import React from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import { EditFields } from '../../UI/CollectionInstanceEditFields/CollectionInstanceEditFields';

const styles = theme => ({

})

function PropertyEditor(props) {

  const {
    property,
    values,
    setValues,
    state,
    dispatch,
  } = props;  

  return (
    <div>
      <EditFields
        properties={[property]}
        values={values}
        setValues={setValues}
        userMap={state.userMap}
        setUserMap={userMap=>dispatch({type:'set_user_map',userMap:userMap})}
        collectionMap={state.collectionMap}
        setCollectionMap={newMap=>dispatch({type:'set_collection_map',collectionMap:newMap})}
        collection={state.collectionData}
        object={state.basicData}
        hideDefaultProperty={true}
        singlePropertyEdit
      />
    </div>
  )
}


export default withTheme()(withStyles(styles)(PropertyEditor));