import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, CircularProgress, Typography, Popper, ClickAwayListener, Paper, MenuItem, Checkbox } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import { getDispFields, getIconComponent, getLabelPlural, isInViewport, mapObjectName, onClickResultItem } from '../../../utilities';
import VerticalTabBar from '../VerticalTabBar/VerticalTabBar';
import ResultItem2 from '../SearchResults/ResultItem2';
import axiosSolr from '../../../axios-solr';


const styles = theme => ({
  header:{
    color:theme.palette.header.main,
    fontSize:20,
  },
  root:{
    display:'flex',
    overflow:"hidden"
  },
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },
  checkbox:{
    paddingLeft:0,
  },
})

function RelatedSearch(props) {
  const {
    classes,
    theme,
    target,
    history,
    relatedContentData,
    setRelatedContentData,
    additionalTab
  } = props;

  const scrollRef = useRef()

  const [tabState, setTabState] = useState(0);
  const [anchor, setAnchor] = useState();
  
  const getRelatedTabs = () => {
    setRelatedContentData({loading:true})
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(target.object.name)}/${target.id}/related/objects`,{
          params:{
            relationship:'REFERENCES,K_REFERENCES,REFERENCES_SHORTCUT',
            per_page:50,
            page:1,
          }
        }
      )
      .then(response=>{
        let obj = {};
        response.data.items.forEach(e=>{
          obj[e.name] = {}
        })
        let isShowActive = target.active_flag===true
        setRelatedContentData({data:obj, showActiveOnly:isShowActive})
      })
      .catch(error=>{
        console.log(error)
        setRelatedContentData({error:true})
      })
  }

  useEffect(()=>{
    if(!relatedContentData){
      getRelatedTabs()
    }
  // eslint-disable-next-line
  },[])

  const getListData = ({type, page=1, showActiveOnly=relatedContentData?.showActiveOnly, isAllReload}) => {
    let newPayload = {...relatedContentData}
    if(isAllReload){
      Object.keys(relatedContentData.data).forEach(e=>{
        newPayload.data[e] = {}
      })
      newPayload.data[type] = {loading:true}
    }else{
      newPayload.data[type] = {loading:true, ...(page===1?{}:relatedContentData.data[type])}
    }
    newPayload.showActiveOnly = showActiveOnly

    setRelatedContentData(newPayload)
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(target.object.name)}/${target.id}/related`,{
          params:{
            relationship:'REFERENCES,K_REFERENCES,REFERENCES_SHORTCUT',
            per_page:10,
            page:page,
            object_name:type,
            object_active_flag:showActiveOnly?true:undefined
          }
        }
      )
      .then(async response=>{
        if(response.data.items.length>0){
          await axiosSolr
            .get(
              `/solr/search/select`,{
                params:{
                  q:"*",
                  fq:`id:(${response.data.items.map(e=>e.id).join(' OR ')})`,
                  rows:response.data.items.length
                }
              }
            )
            .then(solrRes=>{
              response.data.items = response.data.items.map(e=>{
                let solrItem = solrRes.data.response.docs.find(d=>d.id===e.id)
                return {
                  ...e,
                  ...(solrItem||{})
                }
              })
            })
            .catch(error=>{
              console.log(error)
            })

        }

        setRelatedContentData({
          ...newPayload,
          data:{
            ...newPayload.data,
            [type]:{
              ...response.data,
              items:page===1?response.data.items:newPayload.data[type].items.concat(response.data.items)
            }
          }
        })
      })
      .catch(error=>{
        console.log(error)
        setRelatedContentData({
          ...newPayload,
          data:{
            ...newPayload.data,
            [type]:{
              error:true
            }
          }
        })
      })
  }

  const shouldloadMore = () => {
    let type = Object.keys(relatedContentData.data)[tabState]
    let data = relatedContentData.data[type]
    return isInViewport(scrollRef) && data && data.page<data.pages && !data.loading && !data.error
  }

  useEffect(()=>{
    if(relatedContentData && relatedContentData.data){
      let type = Object.keys(relatedContentData.data)[tabState]
      if(!type)return;
      if(!relatedContentData.data[type]?.items && !relatedContentData.data[type]?.loading && !relatedContentData.data[type]?.error){

        getListData({type})
      }
      else if(shouldloadMore()){
        getListData({type,page:relatedContentData.data[type].page+1})
      }
    }
  // eslint-disable-next-line
  },[relatedContentData, tabState])

  window.onscroll = () => {
    if(relatedContentData && relatedContentData.data){
      let type = Object.keys(relatedContentData.data)[tabState];
      if(!type)return;
      if(shouldloadMore()){
        getListData({type,page:relatedContentData.data[type].page+1})
      }
    }
  }

  const onListActionClick = event => {
		setAnchor(event.currentTarget);
	}

  const onToggleShowActiveOnly = value => {
    getListData({type:Object.keys(relatedContentData.data)[tabState],page:1,showActiveOnly:value,isAllReload:true})
  }

  if(!relatedContentData)return <></>

  if (relatedContentData?.error)return <Typography className={classes.normalText}>Failed to load related content</Typography>
  if (relatedContentData?.loading)return <div style={{display:'flex',alignItems:'center'}}><CircularProgress color='secondary'/></div>

  if (relatedContentData?.data && Object.keys(relatedContentData.data).length === 0 && !additionalTab) {
    return (
      <Typography>No related items found</Typography>
    )
  }

  const tabs = [
    ...Object.keys(relatedContentData.data).map(e=>({
      value:e,
      name:mapObjectName(e,true).replace(/_/g,' ')
    })),
    ...(additionalTab||[])
  ]
  
  const currentData = relatedContentData.data[tabs[tabState].value]
  
  return (
    <div className={classes.root}>
      <VerticalTabBar
        tabState={tabState}
        tabOptions={tabs.map(e=>e.name)}
        setTabState={t=>{
          setTabState(t)
        }}
      />
      <div style={{flexGrow:1,marginLeft:80,overflow:'hidden'}}>
        {
          tabs[tabState].component?
          tabs[tabState].component:
          <div>
            <div style={{display:'flex',justifyContent:'space-between',marginBottom:24}}>
              <Typography className={classes.header}>
                {currentData?.total} {tabs[tabState].name}(S)
              </Typography>

              <div style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} onClick={onListActionClick}>
                {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
              </div>
              
              <Popper open={Boolean(anchor)} anchorEl={anchor} placement='bottom-end'>
                <ClickAwayListener onClickAway={()=>setTimeout(()=>setAnchor())}>
                  <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                    <Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
                    <MenuItem onClick={()=>{onToggleShowActiveOnly(!relatedContentData?.showActiveOnly)}} className={classes.menuItem} >
                      <Checkbox key={relatedContentData?.showActiveOnly} className={classes.checkbox} color='primary' checked={relatedContentData?.showActiveOnly}/>
                      <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
                    </MenuItem>
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </div>
            <div
              key={tabState}
            >
              {
                currentData?.items?.map(item=>(
                  <ResultItem2
                    item={item}
                    key={item.id}
                    dispBody={getDispFields(item,'dispBody')}
                    dispSubtitle={getDispFields(item,'dispSubtitle')}
                    dispTitle={getDispFields(item,'dispTitle')}
                    label={item.type}
                    showIcon
                    showUnderline
                    onClick={() => onClickResultItem({id:item.id,label: item.type, history, item: item })}
                  />
                ))
              }
            </div>
            {
              currentData?.error && 
              <Typography>Error occurred loading items</Typography>
            }
            <div ref={scrollRef} style={{display:"flex",justifyContent:'center',marginTop:16,marginBottom:24}}>
              {
                currentData?.loading && 
                <CircularProgress color='secondary'/>
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}

RelatedSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  solrField: PropTypes.string.isRequired,
  relatedContentData: PropTypes.object,
  setRelatedContentData: PropTypes.func
}

export default withTheme()(withStyles(styles)(RelatedSearch));