import React, {useState} from 'react';
import { withStyles, withTheme, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import KTooltip from '../../KTooltip/KTooltip';
import { getIconComponent } from '../../../../utilities';

const styles = theme => ({
  container:{
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  },
  itemContainer:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    overflow:'hidden',
    height:40,
    width:'100%',
  },
  itemName:{
    fontSize:16,
    color:theme.palette.primary.main,
    flexGrow:1,
    flexShrink:1,
    width:'30%',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  clickableText:{
    textDecoration:'underline',
    cursor:'pointer'
  },
  itemTime:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    flexGrow:1,
    flexShrink:1,
    width:'30%',
    whiteSpace:'pre-wrap'
  },
  itemIconContainer:{
    width:40,
    height:40,
    borderRadius:'50%',
    marginLeft:16,
    marginRight:16,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexShrink:0,
    flexGrow:0,
    background:'#BDBDBD'
  },
  selectedIconContainer:{
    background:theme.palette.primary.main
  },
  divider:{
    width:0,
    height:24,
    marginTop:12,
    marginBottom:12,
    borderRight:`1px solid #BDBDBD`
  },
})

function TimelineVertical(props) {

  const {
    classes,
    theme,
    items,
    // top/bottom actions must be promise
    bottomAction, 
    topAction, 
    bottomText
  } = props;

  const [isTopLoading, setIsTopLoading] = useState(false)
  const [isBottomLoading, setIsBottomLoading] = useState(false)

  const getItem = (el, index) => {
    const isReversed = index%2===1;
    return (
      <div className={classes.itemContainer} data-test-classname="timeline-item" style={{flexDirection:isReversed?'row-reverse':'row'}}>
        {/* <KTooltip title={el.name}> */}
          <Typography className={classes.itemName + (el.onClick?' '+classes.clickableText:'')} onClick={el.onClick} style={{textAlign:isReversed?'left':'right'}}>
            {el.name}
          </Typography>
        {/* </KTooltip> */}
        <div onClick={el.onClick} style={{cursor:el.onClick?'pointer':undefined}} className={classes.itemIconContainer + (el.selected?' '+classes.selectedIconContainer:'')}>
          {getIconComponent({label:el.iconLabel,size:20,colour:theme.palette.background.main})}
        </div>
        <KTooltip title={el.time}>
          <Typography className={classes.itemTime} style={{textAlign:isReversed?'right':'left'}}>
            {el.time}
          </Typography>
        </KTooltip>
      </div>
    )
  }


  return (
    <div className={classes.container}>
      {
        topAction && 
        <>
          <div 
            className={classes.itemIconContainer} 
            style={{cursor:'pointer'}} 
            data-test-id='top-action-button'
            onClick={()=>{
              setIsTopLoading(true)
              topAction.onClick().then(()=>{
                setIsTopLoading(false)
              })
            }}
          >
            {
              isTopLoading?
              <CircularProgress style={{color:theme.palette.background.main,width:20,height:20}} />
              :
              getIconComponent({label:topAction.iconLabel,size:20,colour:theme.palette.background.main})
            }
          </div>
          <div className={classes.divider}></div>
        </>
      }
      {
        items.map((el,index)=>(
          <>
            {getItem(el, index)}
            {
              index < items.length-1 && 
              <div className={classes.divider}></div>
            }
          </>
        ))
      }
      {
        bottomAction && 
        <>
          <div className={classes.divider}></div>
          <div 
            className={classes.itemIconContainer} 
            style={{cursor:'pointer'}} 
            data-test-id='bottom-action-button'
            onClick={()=>{
              setIsBottomLoading(true)
              bottomAction.onClick().then(()=>{
                setIsBottomLoading(false)
              })
            }}
          >
            {
              isBottomLoading?
              <CircularProgress style={{color:theme.palette.background.main,width:20,height:20}} />
              :
              getIconComponent({label:bottomAction.iconLabel,size:20,colour:theme.palette.background.main})
            }
          </div>
        </>
      }
      {
        bottomText && 
        <Typography className={classes.itemTime} style={{textAlign:'center',marginTop:8}}>
          {bottomText}
        </Typography>
      }
    </div>
  )
}

TimelineVertical.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    time: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    iconLabel: PropTypes.string.isRequired,
    selected: PropTypes.bool
  })),
  bottomAction:PropTypes.shape({
    onClick: PropTypes.string.isRequired,
    iconLabel: PropTypes.string.isRequired,
  }),
  topAction:PropTypes.shape({
    onClick: PropTypes.string.isRequired,
    iconLabel: PropTypes.string.isRequired,
  })
}


export default withTheme()(withStyles(styles)(TimelineVertical));