const toastUIStyle = `
  .toastui-editor-ww-container, .ProseMirror {
    background: transparent !important;
  }

  .toastui-editor-ww-container .toastui-editor-contents * {
    width: max-content;
    max-width: 100%;
    cursor: text;
    min-width: 1px;
    user-select: text;
  }

  .toastui-editor-defaultUI {
    box-sizing: border-box;
  }
  
  .toastui-editor-contents a {
    color: ${localStorage.hasOwnProperty('dark')?'rgb(234,234,234)':'#53A8E2'} !important;
  }

  .toastui-editor-defaultUI .ProseMirror {
    padding:16px;
  }

  .toastui-editor-defaultUI-toolbar {
    padding:0 16px;
  }

  .toastui-editor-contents ul {
    box-sizing: border-box;
  }

  .toastui-editor-tooltip {
    transform: translateX(-40px);
    min-width: 52px;
    margin-top: 12px;
    text-align: center;
  }

  .toastui-editor-contents a {
    cursor: pointer !important;
  }

  .toastui-editor-tooltip .arrow {
    margin-left: 40px;
  }

  .toastui-editor-dark .toastui-editor-popup-add-table .toastui-editor-table-cell {
    background: #282A36;
    border-color: #b8b9bd;
  }

  .toastui-editor-dark.toastui-editor-defaultUI .toastui-editor-close-button {
    background: #282A36;
  }

  .toastui-editor-dark .toastui-editor-popup-body input[type='text'] {
    border-color: #b8b9bd;
  }

  .toastui-editor-dark .toastui-editor-popup-add-table .toastui-editor-table-cell.header {
    background: #282A36;
    border-color: #b8b9bd;
  }

  .toastui-editor-dark .toastui-editor-popup, .toastui-editor-dark .toastui-editor-context-menu {
    background: #282A36;
  }

  .toastui-editor-dark .ProseMirror {
    background: #282A36;
  }

  .toastui-editor-contents {
    z-index: 0;
  }

  .toastui-editor-ww-container {
    z-index: 0;
  }

  .toastui-editor-dark .toastui-editor-contents blockquote p{
    color: #b8b9bd;
  }

  .toastui-editor-dark .toastui-editor-contents blockquote {
    color: #b8b9bd;
    border-color: #b8b9bd;
  }

  .toastui-editor-dark .toastui-editor-contents table th, .toastui-editor-dark .toastui-editor-contents table td {
    border-color: #b8b9bd;
  }

  .toastui-editor-dark.toastui-editor-defaultUI {
    border-color: #b8b9bd;
  }
`;

export default toastUIStyle;