import axiosCerebrum from "../../axios-cerebrum"
import Alert from 'react-s-alert'

export const loadRefSources = ({state, dispatch, setLoading, currentID, apiIdRef, search, setError, isPaginate, showDeleted}) => {
  setLoading(true)
  let perPage = 20;
  axiosCerebrum
    .get(
      `/api/hosts`,{
        params:{
          reference:true,
          per_page:perPage,
          sort:'ALPHABETICAL',
          active_flag:showDeleted?undefined:true,
          'search.alternate_name':search,
          page:isPaginate?Math.floor((state.list?.docs?.length||0)/perPage)+1:1
        }
      }
    )
    .then(response=>{
      if(currentID!==apiIdRef.current)return;
      let items = response.data.items;
      dispatch({type:'set_list',list:{docs:isPaginate?[...state.list?.docs,...items]:items, numFound:response.data.total}})
      setLoading(false)
    })
    .catch(error=>{
      setLoading(false)
      setError(true)
      console.log(error)
      Alert.error('Error loading the items', {
        position: 'top',
        effect: 'stackslide',
      });
    })
}
