import React, { useState , useEffect, useRef} from 'react';
import { withTheme,withStyles, Typography, CircularProgress, Button, IconButton, Modal, Paper, Select, MenuItem} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import { getIconComponent, isInViewport} from '../../../utilities'
import KTooltip from '../../UI/KTooltip/KTooltip';
import moment from 'moment';
import { globalListenerRef } from '../../../GlobalListenerRef';
import CancelModal from '../../PlatformManagement/Monitor/CancelModal';

const styles = theme => ({
  root: {

  },
  tooltip:{
    fontSize:13.75,
    whiteSpace:'pre-wrap'
  },
  columnRow:{
    height:48,
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    display:'flex',
    alignItems:'center',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    // cursor:'pointer',
    // '&:hover':{
    //   background:theme.palette.hovered.main
    // }
  },
  titleRow:{
    display:'flex',
    // marginBottom:18
  },
  tableTitle:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primary.main,
    overflow:"hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  statusChip:{
    minWidth:100,
    width:'max-content',
    textAlign:'center',
    padding:'8px 12px',
    borderRadius:3,
    fontSize:13.75
  },
  detailSectionHeader:{
    fontSize:12,
    letterSpacing:1.5,
    marginBottom:12
  },
  detailSectionBody:{
    fontSize:16,
    marginBottom:48,
    wordBreak:'break-word'
  },
  hiddenScroll:{
    ...theme.components.hideScroll
  },

  iconButton:{
    padding:2
  },
  selector:{
    ...theme.components.selector,
    width:160,
  }
})

const ImportHistory = props => {

  const {
    classes,
    // history,
    state,
    dispatch,
    theme,
    sessionData,
  } = props;

  const [historyData, setHistoryData] = useState({loading:false,error:false});
  const scrollRef = useRef();
  const [detailModalOpen, setDetailModalOpen] = useState(false)

  const [cancelModalOpen, setCancelModalOpen] = useState(false)

  const autoRefreshTimeoutRef = useRef();
  const [autoRefreshCount, setAutoRefreshCount] = useState();

  const loadHistory = ({page=1,autoRefresh=false,filter=state.importHistoryFilter}) => {
    if(!autoRefresh){
      setHistoryData({loading:true,data:page===1?undefined:historyData.data})
    }
    if(page===1){
      clearInterval(autoRefreshTimeoutRef.current)
      setAutoRefreshCount()
    }
    axiosCerebrum
      .get(
        `/api/jobexecutions`, 
        { params: {
          page,
          per_page:20,
          platform_job:'BULK_EDIT_SOURCE_EXCEL',
          submitted_by_id:filter==='all'?undefined:sessionData.id,
          sort:'START_DESC'
        }}
      )
      .then(async response=>{
        let newData;
        if(page===1){
          newData = response.data
        }else{
          newData = {...response.data,items:[...historyData.data.items,...response.data.items]}
        }
        setHistoryData({loading:false,data:newData});
        if(page===1){
          clearInterval(autoRefreshTimeoutRef.current)
          setAutoRefreshCount(30)
          let count = 30;
          autoRefreshTimeoutRef.current = setInterval(()=>{
            count -= 1;
            setAutoRefreshCount(count)
            if(count===0){
              setAutoRefreshCount()
              loadHistory({autoRefresh:true,filter})
              clearInterval(autoRefreshTimeoutRef.current)
            }
          },1000)
        }
      })
      .catch(error=>{
        console.log(error);
        setHistoryData({loading:false,error:true})
      })
  }

  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.reload_bulk_history ){
        setTimeout(()=>{
          loadHistory({page:1})
        },3000)
      }
    }
    window.removeEventListener('message',globalListenerRef.bulkHistoryMsg);
    globalListenerRef.bulkHistoryMsg = onMsgReceived;
    window.addEventListener("message", globalListenerRef.bulkHistoryMsg);
    return (()=>{window.removeEventListener('message',globalListenerRef.bulkHistoryMsg);})
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    loadHistory({})
    return ()=>{
      clearInterval(autoRefreshTimeoutRef.current)
    }
     // eslint-disable-next-line
  },[])

  window.onscroll = () => {
    if(isInViewport(scrollRef) && historyData.data && historyData.data.page<historyData.data.pages && !historyData.loading){
      loadHistory({page:historyData.data.page+1})
      clearInterval( autoRefreshTimeoutRef.current)
      setAutoRefreshCount()
    } 
  }

  useEffect(()=>{
    if(isInViewport(scrollRef) && historyData.data && historyData.data.page<historyData.data.pages && !historyData.loading){
      loadHistory({page:historyData.data.page+1})
    } 
  // eslint-disable-next-line
  },[historyData.data,historyData.loading])


  const getIcon = extract => {
    if(extract.job_status==='RUNNING'){
      return <div className={classes.statusChip} style={{background:'#FC642D',color:'white'}}>Running</div>
    }
    if(extract.job_status==='COMPLETE'){
      return <div className={classes.statusChip} style={{background:'#48D46A',color:'black'}}>Complete</div>
    }
    if(extract.job_status==='SUBMITTED'){
      return <div className={classes.statusChip} style={{background:'#FFAB00',color:'black'}}>Submitted</div>
    }
    if(extract.job_status==='FAILED'){
      return <div className={classes.statusChip} style={{background:'#B00020',color:'white'}}>Failed</div>
    }
    if(extract.job_status==='KILLED'){
      return <div className={classes.statusChip} style={{background:'#B00020',color:'white'}}>Cancelled</div>
    }
  }

  const getName = job => {
    let totalMetrics = job.metrics?.find(m=>m.job_metric==='BULK_EDIT_SOURCE_EXCEL');
    if(!totalMetrics || !totalMetrics.args.filename)return '';
    return totalMetrics.args.filename
  }

  const getSourceName = job => {
    return job.job_args?.[1]?.name || ''
  }
  
  const getImportType = job => {
    if(job.job_args?.[1]?.action){
      if(job.job_args[1].action==='UPDATE'){
        return 'Update Only'
      }else{
        return 'DELETE & RELOAD'
      }
    }
    return ''
  }

  const getImportedBy = job => {
    let submitter = job.submitted_by;
    if(!submitter)return '';
    if(submitter.first_name && submitter.last_name){
      return submitter.first_name + ' ' + submitter.last_name;
    }
    return submitter.name
  }

  const getImportedAt = job => {
    return moment(job.updated_at).format('llll')
  }

  // const getItemsUpdated = job => {
  //   let processedMetric = job.metrics?.find(m=>m.job_metric==='BULK_EDIT_PROCESSED_RECORDS');
  //   return processedMetric?.value || 0;
  // }

  const onSeeDetails = job => {
    setDetailModalOpen({job})
  }
  
  return (
    <div className={classes.root}>
      <div style={{zIndex:9,background:theme.palette.background.main,paddingBottom:16,position:'sticky',top:128}}>
        <div style={{display:'flex',alignItems:'flex-start',marginBottom:24,marginTop:16}}>
          <div style={{flexGrow:1}}>
            <Typography style={{color:theme.palette.header.main,fontSize:20}}>IMPORT HISTORY</Typography>
            <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginTop:2}}>
              Previous source imports loaded to K. Error details are available for imports that have failed to import.
            </Typography>
          </div>
          <Select
            className={classes.selector}
            value={state.importHistoryFilter}
            onChange={event=>{
              clearInterval(autoRefreshTimeoutRef.current)
              dispatch({type:'set_import_history_filter',importHistoryFilter:event.target.value})
              loadHistory({filter:event.target.value})
            }}
            disableUnderline
          >
            <MenuItem value="all">All Imports</MenuItem>
            <MenuItem value="my_imports">My Imports</MenuItem>
          </Select>
          <Button 
            color='primary' 
            style={{height:40,marginLeft:24,flexShrink:0,background:autoRefreshCount?theme.palette.success.main:undefined,color:autoRefreshCount?'#000':undefined}} 
            variant='contained' 
            onClick={()=>loadHistory({})}
          >
            REFRESH {autoRefreshCount && `(${autoRefreshCount})`}
          </Button>
        </div>
        <div className={classes.titleRow}>
          <div  className={classes.tableTitle} style={{width:160,flexShrink:0}}>
            STATUS
          </div>
          <div className={classes.tableTitle} style={{flexGrow:1,minWidth:100,marginRight:16,}}>
            IMPORT NAME
          </div>
          <div className={classes.tableTitle} style={{width:200,flexShrink:0,marginRight:16}}>
            SOURCE NAME
          </div>
          <div className={classes.tableTitle} style={{width:110,flexShrink:0,marginRight:16}}>
            IMPORT TYPE
          </div>
          <div className={classes.tableTitle} style={{width:125,flexShrink:0,marginRight:16}}>
            IMPORTED BY
          </div>
          <div className={classes.tableTitle}  style={{width:240,flexShrink:0,marginRight:16}}>
            IMPORTED ON
          </div>
          <div className={classes.tableTitle}  style={{width:180,wordBreak:'break-word',whiteSpace:"pre-wrap",flexShrink:0,marginRight:16}}>
            ITEMS UPDATED
          </div>
          <div className={classes.tableTitle} style={{width:128,flexShrink:0}}>
            ACTIONS
          </div>
        </div>
      </div>
      {
        historyData.error && 
        <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading history</Typography>
      }
      {
        historyData.data && historyData.data.total===0 && 
        <Typography style={{color:theme.palette.primaryText.main}}>No history found</Typography>
      }
      {
        historyData.data && historyData.data.items.map(el=>(
          <div>
            <div className={classes.columnRow}>
              <KTooltip classes={{tooltip:classes.tooltip}} title={el.job_status}>
                <div style={{width:150,flexShrink:0,marginRight:10}}>
                  {getIcon(el)}
                </div>
              </KTooltip>
              <KTooltip classes={{tooltip:classes.tooltip}} title={getName(el)}>
                <div style={{flexGrow:1,minWidth:100,marginRight:16,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap',}}>
                  {getName(el)}
                </div>
              </KTooltip>
              <KTooltip classes={{tooltip:classes.tooltip}} title={getSourceName(el)}>
                <div style={{width:200,marginRight:16,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                  {getSourceName(el)}
                </div>
              </KTooltip>
              <div style={{width:110,marginRight:16,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                {getImportType(el)}
              </div>
              <KTooltip classes={{tooltip:classes.tooltip}} title={getImportedBy(el)}>
                <div style={{width:125,marginRight:16,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                  {getImportedBy(el)}
                </div>
              </KTooltip>
              <div style={{width:240,marginRight:16,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                {getImportedAt(el)}
              </div>
              <div style={{width:180,marginRight:16,flexShrink:0,overflow:'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                {el.metrics?.[0]?.args?.object_stats?.total}
              </div>
              <div style={{width:128,flexShrink:0,display:'flex'}}>
                {/* <IconButton onClick={()=>history.push(`/profile/source/${el.job_args?.[1]?.id}`)} className={classes.iconButton} style={{marginRight:8}}>
                  {getIconComponent({label:'open',size:20,colour:theme.palette.primaryText.light})}
                </IconButton> */}
                {
                  ['RUNNING','SUBMITTED'].includes(el.job_status)?
                  <KTooltip title="Cancel job">
                    <IconButton 
                      className={classes.iconButton}
                      style={{marginRight:8}}
                      onClick={(event) => {
                        event.stopPropagation();
                        setCancelModalOpen({item:el,type:'single'});
                      }}
                    >
                      {getIconComponent({label:'clear_circle',size:20,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  </KTooltip>
                  :
                  <div style={{width:24,marginRight:8}}/>
                }
                {
                  ['COMPLETE','FAILED'].includes(el.job_status) ?
                  <IconButton onClick={()=>onSeeDetails(el)} className={classes.iconButton} style={{marginRight:8}}>
                    {getIconComponent({label:'info',size:20,colour:theme.palette.primaryText.light})}
                  </IconButton>
                  :
                  <div style={{width:24,marginRight:8}}/>
                }
                {
                  el.job_args?.[1]?.source_id && 
                  <KTooltip title="Open in new tab">
                    <IconButton 
                      className={classes.iconButton}
                      onClick={(event) => {
                        event.stopPropagation();
                        window.open(`/profile/source/${el.job_args?.[1]?.source_id}`,'_blank')
                      }}
                    >
                      {getIconComponent({label:'open',size:20,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  </KTooltip>
                }
              </div>
            </div>
          </div>
        ))
      }
      <div ref={scrollRef} style={{marginBottom:40,display:'flex',justifyContent:'center'}}>
        {
          historyData.loading && 
          <CircularProgress color='secondary'/>
        }
      </div>
      <CancelModal
        modalOpen={cancelModalOpen}
        setModalOpen={setCancelModalOpen}
        loadMoreHistory={loadHistory}
      />
      {
        detailModalOpen && 
        <Modal open={detailModalOpen}>
          <div style={{width:'100vw',height:'100vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Paper style={{width:880,display:'flex',flexDirection:'column',maxWidth:'90vw',minHeight:300,maxHeight:'90vh',background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`,padding:24,boxSizing:'border-box'}}>
              <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:24,flexGrow:0}}>
                <Typography style={{color:theme.palette.primary.main,fontSize:20}}>
                  {
                    detailModalOpen.job.job_status==='FAILED' ?
                    'IMPORT ISSUES':
                    'IMPORT DETAILS'
                  }
                </Typography>
                <Button color='primary' variant='outlined' onClick={()=>setDetailModalOpen(false)}>CLOSE</Button>
              </div>
              <div style={{flexGrow:1,flexShrink:1,overflow:'auto'}} className={classes.hiddenScroll}>
                <Typography className={classes.detailSectionHeader}>
                  FILE NAME
                </Typography>
                <Typography  className={classes.detailSectionBody}>
                  {getName(detailModalOpen.job)}
                </Typography>
                <Typography className={classes.detailSectionHeader}>
                  {
                    detailModalOpen.job.job_status==='FAILED' ?
                    'ISSUES':
                    'DETAILS'
                  }
                </Typography>
                <Typography  className={classes.detailSectionBody} style={{whiteSpace:detailModalOpen.job.job_status==='COMPLETE'?'pre-wrap':'break-spaces'}}>
                  {
                    detailModalOpen.job.job_status==='FAILED' ?
                    detailModalOpen.job.error:
                    JSON.stringify(detailModalOpen.job.job_args?.[1],null,4)
                  }
                </Typography>
              </div>
            </Paper>
          </div>
        </Modal>
      }
    </div>  
  )
}

export default withTheme()(withStyles(styles)(ImportHistory));