import React, { useEffect, useRef } from 'react';
import { withTheme, CircularProgress, withStyles, Typography} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import moment from 'moment'
import axiosSolr from '../../../axios-solr'
import {  isInViewport } from '../../../utilities'
import {contributedObjects, getObjectCard} from '../utils'
 
const styles = theme => ({
  root: {

  },
  errorText:{
    color:theme.palette.primaryText.main,
    marginTop:24,
  },
  timelineText:{
    color:theme.palette.primary.main,
    letterSpacing:2,
    marginBottom:8,
    fontSize:12
  }
})

const ContributedTimeLine = props => {

  const {
    classes,
    history,
    state,
    dispatch,
    sessionData
  } = props;

  const scrollRef = useRef()

  const loadTimeLineData = ({page=1}) => {
    dispatch({type:'set_contributed_timeline',contributedTimeLineData:page===1?undefined:state.contributedTimeLineData,contributedTimeLineLoading:true})
    axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related`,
        {params:{
          relationship:'CREATOR_OF',
          object_name:contributedObjects.join(','),
          per_page:10,
          page:page,
          sort:'LINKAGE_END_DESC'
        }}
      )
      .then(response=>{
        if(response.data.items.length===0){
          dispatch({
            type:'set_contributed_timeline',
            contributedTimeLineData:response.data
          })
          return;
        }
        axiosSolr.get(
          `/solr/search/select`,{
            params:{
              q:'*',
              fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')}) AND -collection_srt:LIST`,
              fl:'*',
              start:0,
              rows:response.data.items.length,
            }
          }
        )
        .then(solrRes=>{
          dispatch({
            type:'set_contributed_timeline',
            contributedTimeLineData:{
              ...response.data,
              items:[
                ...(page===1?[]:state.contributedTimeLineData.items),
                ...response.data.items.filter(el=>solrRes.data.response.docs.find(s=>s.id===el.id)).map(el=>({
                  ...el,
                  ...solrRes.data.response.docs.find(s=>s.id===el.id)
                }))
              ]
            }
          })
        })
        .catch(error=>{
          console.log(error)
          dispatch({type:'set_contributed_timeline',contributedTimeLineError:true})
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_contributed_timeline',contributedTimeLineError:true})
      })
  }

  useEffect(()=>{
    if(!state.contributedTimeLineData && !state.contributedTimeLineLoading){
      loadTimeLineData({})
    }
    // eslint-disable-next-line
  },[])


  const shouldPaginate = () => {
    return state.contributedTimeLineData && state.contributedTimeLineData.page<state.contributedTimeLineData.pages && !state.contributedTimeLineLoading && isInViewport(scrollRef)
  }

  window.onscroll = () => {
    if(shouldPaginate()){
      loadTimeLineData({page:state.contributedTimeLineData.page+1})
    }
  }

  useEffect(()=>{
    if(shouldPaginate()){
      loadTimeLineData({page:state.contributedTimeLineData.page+1})
    }
  // eslint-disable-next-line
  },[state.contributedTimeLineData])


  let todayList = [];
  let yesterdayList = [];
  let last7DList = [];
  let lastMonthList = [];
  let moreThanAMonth = []

  if(state.contributedTimeLineData){
    let today = moment(moment().format('YYYY-MM-DD'));
    const getUpdatedTime = el => moment(el.linkage_updated_at).format('YYYY-MM-DD')
    todayList = state.contributedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')===0)
    yesterdayList = state.contributedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')===1)
    last7DList = state.contributedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')>1 && today.diff(getUpdatedTime(el),'day')<=7)
    lastMonthList = state.contributedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')>7 && today.diff(getUpdatedTime(el),'day')<=30)
    moreThanAMonth = state.contributedTimeLineData.items.filter(el=>today.diff(getUpdatedTime(el),'day')>30)
  }

  return (
    <div className={classes.root}>
      {
        todayList.length > 0 && 
        <Typography className={classes.timelineText}>TODAY</Typography>
      }
      {
        todayList.length > 0 && 
        <div style={{marginBottom:24}}>
          {todayList.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        yesterdayList.length > 0 && 
        <Typography className={classes.timelineText}>YESTERDAY</Typography>
      }
      {
        yesterdayList.length > 0 && 
        <div style={{marginBottom:24}}>
          {yesterdayList.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        last7DList.length > 0 && 
        <Typography className={classes.timelineText}>IN THE LAST 7 DAYS</Typography>
      }
      {
        last7DList.length > 0 && 
        <div style={{marginBottom:24}}>
          {last7DList.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        lastMonthList.length > 0 && 
        <Typography className={classes.timelineText}>IN THE LAST MONTH</Typography>
      }
      {
        lastMonthList.length > 0 && 
        <div style={{marginBottom:24}}>
          {lastMonthList.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        moreThanAMonth.length > 0 && 
        <Typography className={classes.timelineText}>MORE THAN A MONTH</Typography>
      }
      {
        moreThanAMonth.length > 0 && 
        <div style={{marginBottom:24}}>
          {moreThanAMonth.map(el=>(
            getObjectCard(el,history)
          ))}
        </div>
      }
      {
        state.contributedTimeLineData && (state.contributedTimeLineData.total===0 || state.contributedTimeLineData.items.length===0) && 
        <Typography className={classes.errorText}>No items found</Typography>
      }
      <div ref={scrollRef} style={{textAlign:'center',marginBottom:24}}>
        {
          state.contributedTimeLineLoading && 
          <CircularProgress style={{margin:'24px auto'}} color='secondary'/>
        }
      </div>
      {
        state.contributedTimeLineError && 
        <Typography className={classes.errorText}>Error occurred loading contributed items</Typography>
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(ContributedTimeLine));