import React, { } from 'react';
import { withStyles } from '@material-ui/core';
import Search from '../Search/Search';
import History from '../History/History';

const styles = theme => ({
});



const Body = props => {

  const {
    history,
    tabOptions,
    classes,
    state,
    dispatch
  } = props;

  let body
  switch(tabOptions[state.tabState]){
    case 'SEARCH':
      body = (
        <Search state={state} dispatch={dispatch} history={history}/>
      )
      break;
    case 'EXTRACTS':
      body = (
        <History state={state} dispatch={dispatch} history={history}/>
      )
      break
    default:
  }

  return (
    <div className={classes.root}>
      {body}
    </div>
  )
}

export default withStyles(styles)(Body);
