import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Button } from '@material-ui/core';

const styles = theme => ({
  infoBox:theme.components.infoBox,
  infoHeader:theme.components.infoBoxHeader,
  infoDescription:theme.components.infoBoxDescription,
  focusTxt:theme.components.infoBoxFocusTxt,
  button:theme.components.infoBoxButtonContained,
})

function InfoBox(props) {

  const {
    classes,
    dispatch
  } = props;

  const rolesUrl = 'https://kada-knowledge-library.scrollhelp.site/home/roles-in-k'
  const permissionUrl = 'https://kada-knowledge-library.scrollhelp.site/home/role-permissions'

  return (
    <div className={classes.infoBox}>
      <Typography className={classes.infoHeader}>About Roles</Typography>
      <div style={{display:'flex',alignItems:'flex-start'}}>
        <Typography className={classes.infoDescription}>
          There are 5 types of roles in K. Each role has a different level of permissions designed to make it easier for them to navigate and use K. This page enables you to edit the default permissions for different parts of K.
          {'\n\n'}
          Before enabling a role to have additional access, we recommend you check who is currently assigned to the roles to make sure the permissions need to be changed. As a principle, we do not recommend business users to edit lineage as this can be quite technical to get correct. When enabling more users to link items to collections, you may also want to consider creating a process to review the collection linkages to ensure consistency and accuracy of linkages.
          {'\n\n'}
          Visit the K Knowledge Library to find more information about the <span className={classes.focusTxt} onClick={()=>window.open(rolesUrl,'_blank')}>different roles</span> and the <span onClick={()=>window.open(permissionUrl,'_blank')} className={classes.focusTxt}>role permission</span> settings. 
        </Typography>
        <Button className={classes.button} onClick={()=>{dispatch({type:'set_editing',editing:true})}}>
          EDIT ROLES
        </Button>
      </div>
    </div>
  )
}

InfoBox.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default withStyles(styles)(InfoBox);