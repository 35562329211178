import React, { useEffect, useRef } from 'react';
import { withTheme, CircularProgress, withStyles, Typography} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr'
import { isInViewport, getDispFields, onClickResultItem } from '../../../utilities'
import ResultItem2 from '../../UI/SearchResults/ResultItem2'
 
const styles = theme => ({
  root: {

  },
  title:{
    fontSize:20,
    flexGrow:1,
    color:theme.palette.primary.main
  },
  errorText:{
    color:theme.palette.primaryText.main,
    marginTop:24,
  },
  timelineText:{
    color:theme.palette.primary.main,
    letterSpacing:2,
    marginBottom:8,
    fontSize:12
  },
  checkbox:{
    paddingLeft:0
  },
  selector:{
    ...theme.components.selector
  },
  searchFilter:{
    ...theme.components.inputBase,
    marginRight:24,
    width:180,
		height:40,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
})

const Users = props => {

  const {
    classes,
    // theme,
    history,
    state,
    dispatch,
  } = props;

  const scrollRef = useRef()
  // const searchTimeoutRef = useRef()
  
  const loadData = ({page=1, search=state.linkedUsersSearch}) => {
    dispatch({type:'set_linked_users',linkedUsersData:page===1?undefined:state.linkedUsersData,linkedUsersLoading:true})
    axiosCerebrum
      .get(
        `/api/merges`,
        {params:{
          type:'TARGET',
          object_type:'USER',
          object_id:state.userData.id,
          per_page:10,
          page,
          object_name:search
        }}
      )
      .then(response=>{
        if(response.data.total===0){
          dispatch({
            type:'set_linked_users',
            linkedUsersData:response.data
          })
          return;
        }
        let items = response.data.items.map(el=>el.candidate)
        axiosSolr.get(
          `/solr/search/select`,{
            params:{
              q:'*',
              fq:`id:(${items.map(el=>el.id).join(' OR ')})`,
              fl:'*',
              start:0,
              rows:items.length,
            }
          }
        )
        .then(solrRes=>{
          dispatch({
            type:'set_linked_users',
            linkedUsersData:{
              ...response.data,
              items:[
                ...(page===1?[]:state.linkedUsersData.items),
                ...items.map(el=>({
                  ...el,
                  ...(solrRes.data.response.docs.find(s=>s.id===el.id)||{})
                }))
              ]
            }
          })
        })
        .catch(error=>{
          console.log(error)
          dispatch({type:'set_linked_users',linkedUsersError:true})
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_linked_users',linkedUsersError:true})
      })
  }

  useEffect(()=>{
    if(!state.linkedUsersData && !state.linkedUsersLoading){
      loadData({})
    }
    // eslint-disable-next-line
  },[])


  const shouldPaginate = () => {
    return state.linkedUsersData && state.linkedUsersData.page<state.linkedUsersData.pages && !state.linkedUsersLoading && isInViewport(scrollRef)
  }

  window.onscroll = () => {
    if(shouldPaginate()){
      loadData({page:state.linkedUsersData.page+1})
    }
  }

  useEffect(()=>{
    if(shouldPaginate()){
      loadData({page:state.linkedUsersData.page+1})
    }
  // eslint-disable-next-line
  },[state.linkedUsersData])

  // const onSearchChange = value => {
  //   dispatch({type:'set_linked_users_search',accessRolesSearch:value})
  //   clearTimeout(searchTimeoutRef.current);
  //   searchTimeoutRef.current = setTimeout(()=>{
  //     loadData({page:1,search:value})
  //   },250)
  // }


  return (
    <div className={classes.root}>
      <div style={{display:'flex',alignItems:'flex-start',marginBottom:20}}>
        <Typography className={classes.title}>{state.linkedUsersData?state.linkedUsersData.total+' ':""}USER(S)</Typography>
        {/* <InputBase
          value={state.linkedUsersSearch}
          onChange={event => onSearchChange(event.target.value)}
          variant={'filled'}
          placeholder={'Search'}
          className={classes.searchFilter}
          endAdornment={
            <IconButton 
              disabled={state.linkedUsersSearch===''} 
              onClick={()=>onSearchChange('')}
              style={{width:32,height:32,marginRight:6}}
            >
              {getIconComponent({label:state.linkedUsersSearch===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
            </IconButton>
          }
        /> */}
      </div>
      {
         state.linkedUsersData && state.linkedUsersData.items.map(item=>(
          <ResultItem2
            key={item.id}
            item={item}
            dispBody={getDispFields(item,'dispBody')}
            dispSubtitle={getDispFields(item,'dispSubtitle')}
            dispTitle={getDispFields(item,'dispTitle')}
            showIcon
            label={item.object_type_txt}
            showUnderline
            tagsWidthOffset={state.editing?48:0}
            onClick={() => onClickResultItem({id:item.id, label:item.object_type_txt,history:history,item:item})}
          />
        ))
      }
      {
        state.linkedUsersData && state.linkedUsersData.total===0 && 
        <Typography className={classes.errorText}>No items found</Typography>
      }
      <div ref={scrollRef} style={{textAlign:'center',marginBottom:24}}>
        {
          state.linkedUsersLoading && 
          <CircularProgress style={{margin:'24px auto'}} color='secondary'/>
        }
      </div>
      
      {
        state.linkedUsersError && 
        <Typography className={classes.errorText}>Error occurred loading roles</Typography>
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(Users));