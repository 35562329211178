import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Modal, Paper, CircularProgress, Typography, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import PlatformIssueInput from './PlatformIssueInput';
import axiosCerebrum from '../../../axios-cerebrum'
import IntegrationIssueInput from './IntegrationIssueInput';

const styles = theme => ({
  title: {
    fontSize: '1.25rem',
    fontWeight: '500',
    color:theme.palette.header.main,
  },
})

function DQModal(props) {

  const {
    history,
    theme,
    classes,
    dqModalOpen,
    setDqModalOpen,
    label,
    id,
    state,
    dispatch,
    presetData,
    onSuccess
  } = props;

  const [integrations, setIntegrations] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  
  const loadIntegration = ({page=1, previousData=[]}) => {
    setLoading(true)
    axiosCerebrum 
      .get(
        `/api/sources`,
        {params:{
          capabilities:'ISSUE_MANAGEMENT',
          types:'EXTERNAL_INTEGRATION',
          sort:'ALPHABETICAL',
          internal_flag:false,
          per_page:10,
          page:page
        }}
      )
      .then(response=>{
        if(response.data.page<response.data.pages){
          loadIntegration({page:response.data.page+1,previousData:[...previousData,...response.data.items]})
        }else{
          setIntegrations([...previousData,...response.data.items])
          setLoading(false)
        }
      })
      .catch(error=>{
        console.log(error)
        setError(true)
        setLoading(false)
      })
  }

  useEffect(()=>{
    if(!integrations && dqModalOpen)loadIntegration({})
   // eslint-disable-next-line
  },[dqModalOpen])

  if(error || loading)return (
    <Modal 
      open={dqModalOpen} 
      onClose={()=>setDqModalOpen(false)}
      disableBackdropClick={true}
    > 
      <Paper style={{margin:'7vh auto 0 auto',width:500,padding:24,background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
        <Typography className={classes.title}>Add Issue</Typography>
        {
          loading && <div style={{textAlign:'center',marginTop:24}}><CircularProgress color='secondary'/></div>
        }
        {
          error && <Typography style={{marginTop:24,color:theme.palette.primaryText.main}}>Error occurred loading the form</Typography>
        }
        <div style={{display:'flex',justifyContent:'flex-end',marginRight:-12,marginBottom:-12}}>
          <Button color='primary' data-test-id="add-issue-cancel-button" onClick={()=>setDqModalOpen(false)}>CANCEL</Button>
        </div>
      </Paper>
    </Modal>
  )

  if(!integrations)return <div></div>

  if(integrations.length>0)return (
    <IntegrationIssueInput
      history={history} 
      dqModalOpen={dqModalOpen} 
      setDqModalOpen={setDqModalOpen} 
      id={id} 
      state={state} 
      dispatch={dispatch}
      integrations={integrations}
      presetData={presetData}
      onSuccess={onSuccess}
    />
  )

  return (
    <PlatformIssueInput 
      history={history} 
      dqModalOpen={dqModalOpen} 
      setDqModalOpen={setDqModalOpen} 
      label={label} 
      id={id} 
      state={state} 
      dispatch={dispatch}
      presetData={presetData}
      onSuccess={onSuccess}
    />
  )
}

DQModal.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  dqModalOpen: PropTypes.bool.isRequired,
  setDqModalOpen: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  issueFetch: PropTypes.func.isRequired,
  presetData: PropTypes.object,
  onSuccess: PropTypes.func,
}

export default withTheme()(withStyles(styles)(DQModal));