import React from 'react';
import { withTheme, withStyles} from '@material-ui/core';
import Knowledge from '../Knowledge/Knowledge'
import Responses from '../Responses/Responses'

const styles = theme => ({
  root:{
    marginBottom:80
  }
})

function Body(props) {

  const {
    classes,
    history,
    state,
    dispatch,
  } = props;

  let body;
  switch(state.tabState){
    case 0:
      body = <Knowledge state={state} history={history} dispatch={dispatch}/>
      break;
    case 1:
      body = <Responses state={state} history={history} dispatch={dispatch}/>
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {body}
    </div>
  )
}

export default withTheme()(withStyles(styles)(Body));