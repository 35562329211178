import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import TableDashboard from '../../UI/Dashboard/DirectIndirectDashboard/DirectIndirectDashboard';
import Description from '../Details/Description';
import ColumnList from '../ColumnList/ColumnList';
import Joins from '../../UI/Joins/Joins';
import RelatedSearch from '../../UI/RelatedSearch/RelatedSearch';
import TableCluster from '../TableCluster/TableCluster';
import NoteAdder from '../../UI/NoteAdder/NoteAdder'
import Issues from '../../UI/ProfileIssueSection/Issues';
import NewLineageContainer from '../../UI/Lineage/NewLineageContainer';
import ChangeManager from '../../UI/ChangeManager/ChangeManager';
import DQInsights from '../../UI/DQInsights/DQInsights';
import Reference from '../Reference/Reference';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background:theme.palette.background.main
  },
})

function Body(props) {

  const {
    classes,
    tabState,
    history,
    data,
    fetchList,
    state,
    dispatch,
    sessionData,
    setLinkModalOpen,
    tabOptions,
    isShell,
    isLineageLinkable
  } = props;

  let bodyContent;
  switch (tabOptions[tabState]) {
    case 'COLUMNS':
      bodyContent = (
        <ColumnList
          history={history}
          data={data}
          state={state}
          dispatch={dispatch}
          isShell={isShell}
        />
      );
      break;
    case 'DETAILS': // details
      bodyContent = (
        <Description
          nodeKey={state.basicData.nodeKey}
          setLinkModalOpen={setLinkModalOpen}
          label='table'
          history={history}
          fetchList={fetchList}
          userName={sessionData.user_name}
          profileState={state}
          sessionData={sessionData}
          profileDispatch={dispatch}
        />
      )
      break;
    case 'KNOWLEDGE':
      bodyContent = (
        <NoteAdder
          history={history}
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.noteModalOpen}
          setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
        />
      )
      break;
    case 'USAGE': // summary
      bodyContent = (
        <TableDashboard
          history={history}
          state={state}
          label='table'
          dispatch={dispatch}
          drillThrough={true}
        />
      )
      break;
    case 'JOINS': // joins to
      bodyContent = (
        <Joins
          state={state}
          history={history}
          targetObject={state.basicData}
          joinsData={state.joinsData}
          setJoinsData={data=>dispatch({type:'set_joins_data',joinsData:data})}
          joinsView={state.joinsView}
          setJoinsView={view=>dispatch({type:'set_joins_view',joinsView:view})}
        />
      )
      break;
    case 'LINEAGE': // Lineage
      bodyContent = (
        <NewLineageContainer
          history={history}
          state={state}
          dispatch={dispatch}
          isLinkable={isLineageLinkable}
        />
      )
      break;
    case 'ISSUES':
      bodyContent = (
        <Issues
          history={history}
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    case 'QUALITY':
      bodyContent = (
        // <QualityMetrics
        //   state={state}
        //   history={history}
        //   dispatch={dispatch}
        // />
        <DQInsights
          state={state}
          history={history}
          dispatch={dispatch}
          object={state.basicData}
        />
      )
      break;
    case 'RELATED': // realated content
      bodyContent = (
        <RelatedSearch
          history={history}
          nodeKey={state.basicData.signature}
          targetLabel="table"
          solrField="tables_str"
          newSolrField="tables_full_name_msrt"
          target={state.basicData}
          relatedContentData={state.relatedContentData}
          setRelatedContentData={data => dispatch({ type: 'set_related_data', relatedContentData: data })}
          additionalTab={
            isShell ? 
            (state.basicData.reference_sources.length>0 && [
              {
                name:`Sources (${state.basicData.reference_sources.length})`, 
                component:<Reference
                  history={history}
                  state={state}
                  dispatch={dispatch}
                />
              }
            ])
            :
            (state.tableClusterTotal && [
              {
                name:'Table Cluster' + (state.tableClusterTotal?` (${state.tableClusterTotal})`:''), 
                component:<TableCluster
                  history={history}
                  state={state}
                  dispatch={dispatch}
                />
              }
            ])

          }
        />
      )
      break;
    case 'CHANGES':
      bodyContent = (
        <ChangeManager state={state} dispatch={dispatch} history={history}/>
      )
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
  tabUrlPrefix: PropTypes.string,
  nodeKey: PropTypes.string,
  data: PropTypes.object,
  history: PropTypes.object.isRequired,
  fetchList: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.object.isRequired,
  sessionData: PropTypes.object,
}

export default withStyles(styles)(Body);