import React, { useState} from 'react';
import { withTheme, Typography, withStyles, Button, ListItem} from '@material-ui/core';
import SearchSelector from '../../UI/SearchSelector/SearchSelector'
import { getIconComponent, toTitleCase } from '../../../utilities';
import UserSuggestionItem from '../../UI/SearchResults/UserSuggestionItem';

const styles = theme => ({
  root: {
    
  },
  chip:{
    padding:'8px 16px',
    display:'flex',
    overflow:'hidden',
    alignItems:'center',
    marginBottom:12,
    background:theme.palette.chip.main,
    border:`1px solid ${theme.palette.border.main}`,
    width:'max-content',
    maxWidth:"100%",
    borderRadius:24
  },
  chipText:{
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    fontSize:13.75,
    marginLeft:16,
    marginRight:20
  },
  clearButton:{
    width:24,
    padding:0,
    minWidth:24,
    height:24,
    borderRadius:12,
    "&:hover":{
      background:theme.palette.primary.main
    }
  }
})

const SelectTarget = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
  } = props;

  const [searchValue, setSearchValue] = useState('')

  const onItemSelected = item => {
    dispatch({type:'set_merge_target',mergeTarget:item})
    dispatch({type:'set_merge_tab_state',mergeTabState:2})
  }

  return (
    <div className={classes.root}>
      {
        state.mergeTarget ?
        <div className={classes.chip} style={{background:theme.palette.primary.dark}}>
          {getIconComponent({label:state.mergeTargetType,size:24,colour:theme.palette.background.main})}
          <Typography className={classes.chipText} style={{color:theme.palette.background.main}}>{state.mergeTarget.name} ({[state.mergeTarget.source.name,...state.mergeTarget.reference_sources.map(el=>el.reference_source.name)].join(', ')})</Typography>
          <Button className={classes.clearButton} onClick={()=> dispatch({type:'set_merge_target'})}>{getIconComponent({label:'clear',size:16,colour:theme.palette.background.main})}</Button>
        </div>
        :
        <div className={classes.chip}>
          {getIconComponent({label:state.mergeTargetType,size:24,colour:theme.palette.primaryText.main})}
          <Typography className={classes.chipText}>No {toTitleCase(state.mergeTargetType.replace(/_/g,' '))} selected</Typography>
        </div>
      }
      <SearchSelector 
        url={`/api/merges/search`}
        isCerebrum={true}
        clearable={true}
        params={{'object_name':searchValue,type:'TARGET',per_page:20,source_id:state.mergeSourceFilter==='all'?undefined:state.mergeSourceFilter,object_type:state.mergeTargetType}}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        placeholder={`Search for a Target ${toTitleCase(state.mergeTargetType.replace(/_/g,' '))} (Primary User Profile)`}
        onResultClick={el=>onItemSelected(el)}
        renderResults={(items)=>{
          if(items.length===0){
            return [
              <div>
                <ListItem>
                  <div style={{display:'flex',alignItems:'center'}}>
                    <Typography style={{marginLeft:12,color:theme.palette.primaryText.main}}>No suggestions found</Typography>
                  </div>
                </ListItem>
              </div>
            ]
          }
          return items.map((item,index)=>(
            <UserSuggestionItem item={item} onClick={()=>onItemSelected(item)}  itemSource="merge"/>
        ))}}
      />
      <Typography style={{color:theme.palette.primaryText.light,fontSize:12,paddingLeft:12,marginTop:4}}>Required</Typography>
    </div>
  )
}

export default withTheme()(withStyles(styles)(SelectTarget));