import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, ClickAwayListener, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
// import axiosCerebrum from '../../../../axios-cerebrum'
import axiosCerebrum from '../../../../axios-cerebrum';
import { collectionIds, getCollectionColour, getIconComponent,  getLabelPlural} from '../../../../utilities';
import { tileStyles } from './utils/styles';
import { checkIsClickOnSuggestion, measureText } from './utils/utils';
import useAlert from '../../../../hooks/useAlert';
import HierarchySearchSelector from '../../SearchSelector/HierarchySearchSelector';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  editableHeaderContainer:{
    ...tileStyles.headerContainer,
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    border:`1px solid ${theme.palette.listItemDivider.main}`,
  },
  tag:{
    ...tileStyles.tag
  },
  tagText:{
    ...tileStyles.tagText,
  },
  clickableText:{
    ...tileStyles.clickableText
  },
  iconButton:{
    ...tileStyles.deleteButton
  },
})

function VerifiedManager(props) {

  const {
    classes,
    theme,
    history,
    object,
    onAddTileData,
    tileData,
    oppositeTileData,
    isNotVerified,
    isEditable,
    alwaysOpenNewTab
  } = props;
  
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [deleting, setDeleting] = useState(false)
  const [adding, setAdding] = useState(false)
  const popperRef = useRef()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  
  const loadUseCase = () => {
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related`,
        {params:{
          relationship:isNotVerified?'NOT_VERIFIED_BY':"VERIFIED_BY",
          per_page:200
        }}
      )
      .then(response=>{
        let useCases = response.data.items;
        onAddTileData(
          {
            "disp_body":useCases.map(el=>({
              labels:'collection_instance',
              name:el.name,
              id:el.id,
              onClick:()=>{
                alwaysOpenNewTab?
                window.open(`/profile/collection_instance/${el.id}`,'_blank'):
                history.push(`/profile/collection_instance/${el.id}`)
              }
            })),
            "disp_title":  isNotVerified?'Do not use for':"Verified for",
            "id": isNotVerified?'verifiedNot':'verified',
          }
        )
      })
      .catch(error=>{
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(()=>{
    if(!tileData){
      loadUseCase()
    }
  // eslint-disable-next-line
  },[])

    
  const onAdd = async item => {
    if(adding)return;
    setSearchValue('')
    setAdding(true)
    axiosCerebrum
      .put(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=${isNotVerified?'NOT_VERIFIED_BY':'VERIFIED_BY'}&object_id=${item.id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":[...tileData.disp_body,{
              name:item.name_txt || item.name,
              labels:'collection_instance',
              id:item.id,
              onClick:()=>{
                alwaysOpenNewTab?
                window.open(`/profile/collection_instance/${item.id}`,'_blank'):
                history.push(`/profile/collection_instance/${item.id}`)
              }
            }],
            "disp_title":tileData.disp_title,
            "id":tileData.id,
          }
        )
        setAdding(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred adding use case, please try again', type: 'error' })
        setAdding(false)
      })
  }

  const onDelete = (item) => {
    setDeleting(true)
    let showLoadingTimeout = setTimeout(()=>{
      setLoading(true)
    },800)
    axiosCerebrum
      .delete(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related?relationship=${isNotVerified?'NOT_VERIFIED_BY':'VERIFIED_BY'}&object_id=${item.id}`
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":tileData.disp_body.filter(b=>b.id!==item.id),
            "disp_title":tileData.disp_title,
            "id":tileData.id,
          }
        )
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({ message: 'Error occurred deleting use case, please try again', type: 'error' })
        clearTimeout(showLoadingTimeout)
        setLoading(false)
        setDeleting(false)
      })
  }

  if(!tileData)return <></>

  let chipBackground = isNotVerified?getCollectionColour('verified not').colour:getCollectionColour('verified').colour;
  let chipText = isNotVerified?getCollectionColour('verified not').fontColour:getCollectionColour('verified').fontColour

  
  return (
    <div data-test-id="verified-data-tile" className={classes.root}>
      <div className={classes.editableHeaderContainer}  >
        <Typography className={classes.title}>{tileData.disp_title.toUpperCase()}</Typography>
      </div>
      <ClickAwayListener 
        onClickAway={(event)=>{
          if(checkIsClickOnSuggestion(event.target))return;
          setSearchValue('')
          setEditing(false)
        }}
      >
        <div ref={popperRef} className={classes.chipContainer + ' ' + (editing?classes.editingBox:isEditable?classes.clickableBox:'')} onClick={()=>isEditable && setEditing(true)}>
          {
            loading && <CircularProgress color='secondary' size={16}/>
          }
          {
            !loading && tileData && tileData.disp_body.length===0 && 
            <div data-test-classname="verified-data-tile-chip" className={classes.tag} style={{background:theme.palette.chip.main,color:theme.palette.primaryText.main,border:`1px solid ${theme.palette.border.main}`,cursor:'default'}}>
              <span className={classes.tagText} style={{color:theme.palette.primaryText.main}}>None</span>
            </div>
          }
          {
            !loading && tileData && tileData.disp_body.sort((a,b)=>a.name<b.name?-1:1).map(t=>(
              <div key={t.name} data-test-classname="verified-data-tile-chip" className={classes.tag} style={{background:chipBackground,border:'none',width:editing||tileData.disp_body.length===1?undefined:measureText(t.name)+24}} onClick={t.onClick}>
                <span className={classes.tagText} style={{color:chipText}}>{t.name}</span>
                {
                  isEditable && 
                  <IconButton className={classes.iconButton + ' chip-delete-button'}  style={{display:editing?'block':undefined}} onClick={event=>{event.stopPropagation();!deleting && onDelete(t)}}>
                    {getIconComponent({label:'clear',size:13.75,colour:chipText})}
                  </IconButton>
                }
              </div>
            ))
          }
          {
            editing && 
            <div style={{marginTop:8,marginBottom:6,width:'100%'}}>
              <HierarchySearchSelector
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                rootId={collectionIds.verifiedUseCase}
                fq={
                  `object_type_srt:COLLECTION_INSTANCE AND collection_srt:"VERIFIED USE CASE"` +
                  (tileData.disp_body.length>0?` AND -id:(${tileData.disp_body.map(b=>b.id).join(' OR ')})`:'') +
                  (oppositeTileData && oppositeTileData.disp_body?.length>0?` AND -id:(${oppositeTileData.disp_body.map(b=>b.id).join(' OR ')})`:'')
                }
                rootLabel={'collection'}
                height={32}
                onResultClick={item=>{
                  if(tileData.disp_body.map(b=>b.id).includes(item.id)){
                    if(deleting)return;
                    onDelete(item)
                  }else{
                    onAdd(item)
                  }
                }}
                clearable
                autoFocus
                testID="use-case-manager-input"
                placeholder={`Search for a use case`}
                isItemSelected={item=>{
                  return tileData.disp_body.map(b=>b.id).includes(item.id) 
                }}
                isItemDisabled={item=>{
                  return oppositeTileData && oppositeTileData.disp_body.map(b=>b.id).includes(item.id)
                }}
                checkboxColour={!isNotVerified?getCollectionColour('verified').colour:getCollectionColour('verified not').colour}
                replaceItemCheckbox={item=>{
                  if(oppositeTileData && oppositeTileData.disp_body.map(b=>b.id).includes(item.id)){
                    return (
                      <div style={{width:18,height:18,marginRight:8,marginLeft:4,borderRadius:2,background:isNotVerified?getCollectionColour('verified').colour:getCollectionColour('verified not').colour}}>
                      </div>
                    )
                  }else{
                    return undefined;
                  }
                }}
              />
            </div>
          }
        </div>
      </ClickAwayListener>
    </div>
  )
}

VerifiedManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(VerifiedManager));