import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, IconButton, Avatar } from '@material-ui/core';
import { getIconComponent, getInitials, onClickResultItem } from '../../../utilities';
import KTooltip from '../../UI/KTooltip/KTooltip';
import theme from '../../../theme';
import TeamEditModal from './TeamEditModal';
import UserEditModal from './UserEditModal';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';
import CustomMenu from '../../UI/ContextMenu/ContextMenu'

const styles = theme => ({

  columnText:{
    fontSize:12,
    paddingRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    color:theme.palette.primary.main,
    minWidth:50,
    letterSpacing:1.5
  },
  hoverableText:{
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline',
    }
  },
  tableText:{
    fontSize:12,
    paddingRight:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    minWidth:50,
  },
  listItem:{
    display:'flex',
    alignItems:'center',
    height:48,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    // cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  avatar: {
    fontSize: 12,
    height: 24,
    width: 24,
    // backgroundColor: theme.palette.avatar.main,
  },
})

function UserList(props) {

  const {
    classes,
    state,
    dispatch
  } = props;

  const [teamModalOpen, setTeamModalOpen] = React.useState(false)
  const [userModelOpen, setUserModelOpen] = React.useState(false)
  const [buttonHovered, setButtonHovered] = React.useState(false)

  const flexConfig = [
    '1 1 200px',
    '0 1 180px',
    '0 1 180px',
    '0 1 220px',
    '0 1 180px',
    '0 1 130px',
    '0 1 220px',
    '0 1 220px',
    '0 0 120px',
  ]

  const getSource = u => {
    return (
      u.reference?
      u.reference_sources?.map(el=>el.reference_source?.name).join(', ')||'':
      u.source_txt
    )
  }

  return (
    <div className={classes.root}>
      <div style={{display:"flex",marginBottom:8}}>
        <div style={{width:24,height:24,flexGrow:0,flexShrink:0,marginLeft:16,marginRight:16}}>
          
        </div>
        <Typography className={classes.columnText} style={{flex:flexConfig[0]}}>
          USER NAME
        </Typography>
        <Typography className={classes.columnText} style={{flex:flexConfig[1]}}>
          FIRST NAME
        </Typography>
        <Typography className={classes.columnText} style={{flex:flexConfig[2]}}>
          LAST NAME
        </Typography>
        <Typography className={classes.columnText} style={{flex:flexConfig[3]}}>
          EMAIL
        </Typography>
        <Typography className={classes.columnText} style={{flex:flexConfig[4]}}>
          USER TYPE
        </Typography>
        <Typography className={classes.columnText} style={{flex:flexConfig[5]}}>
          USAGE TYPE
        </Typography>
        <Typography className={classes.columnText} style={{flex:flexConfig[6]}}>
          TEAM(S)
        </Typography>
        <Typography className={classes.columnText} style={{flex:flexConfig[7]}}>
          SOURCE(S)
        </Typography>
        <Typography className={classes.columnText} style={{flex:flexConfig[8]}}>
          ACTIONS
        </Typography>
      </div>
      {
        state.userData?.items.map(u=>(
          <>
            <ContextMenuTrigger id={u.id}>
              <div 
                className={classes.listItem} 
                key={u.id}
                style={{background:buttonHovered?theme.palette.background.main:undefined}}
              >
                <div style={{width:24,height:24,flexGrow:0,flexShrink:0,marginLeft:16,marginRight:16}}>
                  <Avatar align='center' className={classes.avatar} style={{backgroundColor:theme.palette.primary.main,color:theme.palette.background.main}}>
                    {getInitials(u.name)}
                  </Avatar>
                </div>
                <KTooltip title={u.username}>
                  <Typography 
                    className={classes.tableText + ' ' +classes.hoverableText} 
                    style={{flex:flexConfig[0]}}
                    onClick={()=>{
                      onClickResultItem({item:u,id:u.id,label:'user',newWindow:true})
                    }}
                  >
                    {u.username}
                  </Typography>
                </KTooltip>
                <KTooltip title={u.first_name}>
                  <Typography className={classes.tableText} style={{flex:flexConfig[1]}}>
                    {u.first_name}
                  </Typography>
                </KTooltip>
                <KTooltip title={u.last_name}>
                  <Typography className={classes.tableText} style={{flex:flexConfig[2]}}>
                    {u.last_name}
                  </Typography> 
                </KTooltip>
                <KTooltip title={u.email}>
                  <Typography className={classes.tableText} style={{flex:flexConfig[3]}}>
                    {u.email}
                  </Typography>
                </KTooltip>
                <Typography className={classes.tableText} style={{flex:flexConfig[4]}}>
                  {u.reference?'Reference':'Onboarded'}
                </Typography>
                <Typography className={classes.tableText} style={{flex:flexConfig[5]}}>
                  {u.system_user_flag?'System':'User'}
                </Typography>
                <KTooltip title={u.teams_txts?.join(', ')}>
                  <Typography className={classes.tableText} style={{flex:flexConfig[6]}}>
                    {u.teams_txts?.join(', ')}
                  </Typography>
                </KTooltip>
                <KTooltip title={getSource(u)}>
                  <Typography className={classes.tableText} style={{flex:flexConfig[7]}}>
                    {getSource(u)}
                  </Typography>
                </KTooltip>
                <div className={classes.tableText} style={{flex:flexConfig[8], display:'flex'}}>
                  {
                    u.reference===false?
                    <div style={{width:40}}></div>:
                    <IconButton
                      onClick={(event)=>{
                        setUserModelOpen({user:u})
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                      onMouseEnter={()=>setButtonHovered(true)}
                      onMouseLeave={()=>setButtonHovered(false)}
                    >
                      {getIconComponent({label:'edit',size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  }
                  <IconButton
                    onClick={(event)=>{
                      setTeamModalOpen({user:u})
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                    onMouseEnter={()=>setButtonHovered(true)}
                    onMouseLeave={()=>setButtonHovered(false)}
                  >
                    {getIconComponent({label:'group_add',size:24,colour:theme.palette.primaryText.light})}
                  </IconButton>
                  <IconButton
                    onClick={(event)=>{
                      onClickResultItem({item:u,id:u.id,label:'user'})
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                    onMouseEnter={()=>setButtonHovered(true)}
                    onMouseLeave={()=>setButtonHovered(false)}
                  >
                    {getIconComponent({label:'open',size:24,colour:theme.palette.primaryText.light})}
                  </IconButton>
                </div>
              </div>
            </ContextMenuTrigger>
            <ContextMenu id={u.id}>
              <CustomMenu
                item={{...u,labels:'user',id:u.id}}
                actions={[
                  'open_new_tab'
                ]}
              />
            </ContextMenu>
          </>
        ))
      }
      <TeamEditModal
        modalOpen={teamModalOpen}
        setModalOpen={setTeamModalOpen}
        state={state}
        dispatch={dispatch}
      />
      <UserEditModal
        modalOpen={userModelOpen}
        setModalOpen={setUserModelOpen}
        state={state}
        dispatch={dispatch}
      />
    </div>
  )
}

UserList.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(UserList));