import React, {} from 'react';
import { withStyles, Typography} from '@material-ui/core';
import classnames from 'classnames'
import PropTypes from 'prop-types';
import theme from '../../../theme';
import KTooltip from '../KTooltip/KTooltip';

const styles = theme => ({
  root: {
    '& *':{
      userSelect: 'none'
    }
  },
  tab:{
    cursor:'pointer',
    color:theme.palette.primaryText.light,
    overflow:'hidden',
    opacity:0.6,
    '&:hover':{
      background:theme.palette.hovered.main
    },
    minHeight:48,
    padding:'6px',
    boxSizing:'border-box',
    display:'flex',
    // flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
  },
  selectedTab:{
    opacity:1
  },
  tabText:{
    fontSize:13.75,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    maxWidth:'100%',
    // marginTop:6,
    opacity:0.6,
  },
  selectedTabText:{
    opacity:1
  }
})

const MiniVerticalTabBar = props => {

  const {
    classes,
    tabOptions,
    width,
    tabState,
    setTabState,
    position
  } = props;

  
  return (
    <div data-test-id="minivertical-tab-bar" className={classes.root} style={{flexShrink:0,flexGrow:0,width}}>
      {
        tabOptions?.map((t,index)=>{
          let selected = tabState===index;
          return (
            <KTooltip title={t.name}>
              <div
                key={t.name}
                data-test-id="minivertical-tab-bar-tab"
                className={classnames(
                  classes.tab,
                  tabState===index?classes.selectedTab:'',
                )}
                style={{
                  [position==='right'?'borderLeft':'borderRight']:selected?`2px solid ${theme.palette.primary.main}`:`2px solid transparent`,
                }}
                onClick={()=>setTabState(index)}
              >
                {/* {getIconComponent({label:t.iconLabel, colour: selected?theme.palette.primary.main:theme.palette.primaryText.light,size:16 })} */}
                <Typography 
                  data-test-id="minivertical-tab-bar-tab-text" 
                  className={classnames(
                    classes.tabText,
                    tabState===index?classes.selectedTabText:'',
                  )} 
                  style={{color: selected?theme.palette.primary.main:theme.palette.primaryText.light}}
                >
                  {t.name}
                </Typography>
              </div>
            </KTooltip>
          )
        })
      }
    </div>
  )
}

MiniVerticalTabBar.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  tabState: PropTypes.number.isRequired,
  setTabState: PropTypes.func.isRequired,
  tabOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    iconLabel: PropTypes.string
  })).isRequired,
  position: PropTypes.oneOf(['left','right']).isRequired,
}

export default withStyles(styles)(MiniVerticalTabBar);