import React, {useEffect} from 'react';
import { CircularProgress, withStyles, Typography} from '@material-ui/core';
import theme from '../../../theme';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
// import { getIconComponent } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum'
import { setHelpWdigetVisibility } from '../../../utilities';

const styles = theme => ({
  title:{
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:8
  },
  subtitle:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    marginBottom:16
  },
})

const Capabilities = props => {

  const {
    classes,
    state,
    dispatch,
    capabilityLabelMap
  } = props;

  useEffect(()=>{
    setHelpWdigetVisibility(true)
    return ()=>{setHelpWdigetVisibility(false)}
  // eslint-disable-next-line
  },[])

  const loadCapabilities = ({page=1,previousData=[]}) => {
    if(page===1){
      dispatch({
        type:'set_capabilities',
        capabilitiesLoading:true
      })
    }
    axiosCerebrum
      .get(
        `/api/capabilities`,
        {params:{
          page,
          per_page:10,
          sort:'ALPHABETICAL'
        }}
      )
      .then(response=>{
        if(response.data.page<response.data.pages){
          loadCapabilities({page:response.data.page+1,previousData:[...previousData,...response.data.items]})
        }else{
          dispatch({
            type:'set_capabilities',
            capabilities:[...previousData,...response.data.items].sort((a,b)=>a.name==='ISSUE_MANAGEMENT'?-1:1).filter(el=>!['ACCESS_REQUESTS'].includes(el.name))
          })
        }
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_capabilities',
          capabilitiesError:true
        })
      })
  }

  useEffect(()=>{
    if(!state.capabilities)loadCapabilities({})
    // eslint-disable-next-line
  },[])

  const onClickCapability = c => {
    dispatch({type:'set_viewed_capability',viewedCapability:c.name})
    dispatch({type:'set_tab_state',tabState:1})
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>{state.capabilities?3:2} CAPABILITIES</Typography>
      <Typography className={classes.subtitle}>Select a capability to integrate to external source</Typography>
      {
        state.capabilities && state.capabilities.filter(el=>el.name==='ISSUE_MANAGEMENT').map(el=>(
          <SimpleResultItem
            item={el}
            label={capabilityLabelMap(el.name)}
            titleColour={el.name!=='ISSUE_MANAGEMENT'?theme.palette.primaryText.light:theme.palette.primaryText.main}
            title={el.name + (el.name!=='ISSUE_MANAGEMENT'?' (Coming soon)':'')}
            subTitle={el.description || 'No description'}
            hideRight={true}
            iconColour={el.name!=='ISSUE_MANAGEMENT'?theme.palette.primaryText.light:undefined}
            showUnderline
            onClick={el.name==='ISSUE_MANAGEMENT'?()=>onClickCapability(el):undefined}
          />
        ))
      }
      <SimpleResultItem
        item={{id:'EMAIL',name:'EMAIL'}}
        label={'email'}
        titleColour={theme.palette.primaryText.main}
        title={'EMAIL'}
        subTitle={"Manage the SMTP service for email notifications"}
        hideRight={true}
        showUnderline
        onClick={()=>onClickCapability({name:'EMAIL'})}
      />
      <SimpleResultItem
        item={{id:'GENERATIVE_AI',name:'GENERATIVE AI'}}
        label={'gen_ai'}
        titleColour={theme.palette.primaryText.main}
        title={'GENERATIVE AI'}
        subTitle={"Setup a generative AI service to generate metadata"}
        hideRight={true}
        showUnderline
        onClick={()=>onClickCapability({name:'GENERATIVE AI',id:'GENERATIVE_AI'})}
      />
      <div style={{marginBottom:12,marginTop:8,textAlign:'center'}}>
        {state.capabilitiesLoading && <CircularProgress color='secondary'/>}
      </div>
      {state.capabilitiesError && <Typography style={{marginTop:16,color:theme.palette.primaryText.main}}>Error occurred loading capabilities</Typography>}

    </div>
  )
}


export default withStyles(styles)(Capabilities);