import React,{useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {withTheme, Typography, withStyles, Button, CircularProgress, Select, MenuItem} from '@material-ui/core';
import {getLabelPlural, isInViewport, removeUrlQueryArg} from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import axiosSolr from '../../../axios-solr'
import 'url-search-params-polyfill';
import NoteChip from './NoteChip';
import { globalListenerRef } from '../../../GlobalListenerRef';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  bannerText:{
    fontSize:16,
    color:theme.palette.primaryText.main
  },
  bannerButton:{
    padding:'2px 4px',
    marginBottom:-2
  },
  selector:{
    ...theme.components.selector
  },
  titleSelector:{
    ...theme.components.titleSelector,
  },
})

function NoteAdderList(props) {

  const {
    theme,
    classes,
    object,
    state,
    dispatch,
    history,
    onClickAskQuestion,
    onClickContribute,
  } = props;


  // const [view, setView] = useState(state.notes?.view || 'note')
  const [view] = useState('note')
  const [availableTypes, setAvailableTypes] = useState({})
  const [selectedType, setSelectedType] = useState(state.notes?.type||'all')
  const [selectedItem, setSelectedItem] = useState()

  const [newlyCreatedNote, setNewlyCreatedNote] = useState() // to handle api sync issue

  const [isEdit, setIsEdit] = useState(false)

  const scrollRef = useRef();
  const urlSearch = new URLSearchParams(window.location.search);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  // const onChangeView = v => {
  //   setView(v)
  // }

  const loadAvailableTypes = async () => {
    setAvailableTypes({loading:true})
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related/collections`,
        {params:{
          category:'KNOWLEDGE',
          page:1,
          parent_flag:true,
          per_page:10,
        }}
      )
      .then(response=>{
        let sort = ['Question','Decision','Business Logic','Note','Roadmap','How To','Link']
        response.data.items.sort((a,b)=>sort.indexOf(a.name)-sort.indexOf(b.name));
        setAvailableTypes({types:response.data.items})
      })
      .catch(error=>{
        console.log(error)
        setAvailableTypes({error:true})
      })
  }
  
  useEffect(()=>{
    loadAvailableTypes()
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.answer_updated){
        dispatch({
          type:'set_note_detail_map',
          noteDetailMap:{
            ...state.noteDetailMap,
            [msg.data.object_id]:{
              ...state.noteDetailMap?.[msg.data.object_id],
              objDetail:{
                ...state.noteDetailMap?.[msg.data.object_id]?.objDetail,
                properties:{
                  ...state.noteDetailMap?.[msg.data.object_id]?.objDetail?.properties,
                  3:msg.data.answer_updated
                }
              }
            }
          }
        })
      }
    }
    window.removeEventListener('message',globalListenerRef.noteListUpdate);
    globalListenerRef.noteListUpdate = onMsgReceived;
    window.addEventListener("message", globalListenerRef.noteListUpdate);
    return (()=>{window.removeEventListener('message',globalListenerRef.noteListUpdate);})
  // eslint-disable-next-line
  },[state.noteDetailMap])
  
  useEffect(()=>{
    if(!availableTypes?.types)return;
    if(availableTypes?.types?.length===0){
      setSelectedType('all')
      dispatch({
        type:'set_notes',
        notes:{
          type:'all',
          view:'note',
          items:[],
          page:1,
          pages:1,
          total:0,
        }
      })
      return;
    }
    const tabName = urlSearch.get('subTabName')
    if(tabName){
      let type = availableTypes?.types?.find(el=>el.name.toUpperCase()===tabName.toUpperCase())
      if(type){
        setSelectedType(type.id)
        loadNotes({page:1,type:type.id})
      }else{
        if(availableTypes?.types?.find(el=>el.id===selectedType)){
          loadNotes({page:1,type:selectedType})
        }else{
          setSelectedType('all')
          loadNotes({page:1,type:'all'})
        }
      }
      window.history.replaceState(null, null,removeUrlQueryArg({url:window.location.toString(),keys:['subTabName']}));
    }else{
      if(state.notes?.items || state.notesLoading)return;
      if(availableTypes?.types?.find(el=>el.id===selectedType)){
        loadNotes({page:1,type:selectedType})
      }else{
        setSelectedType('all')
        loadNotes({page:1,type:'all'})
      }
    }
   // eslint-disable-next-line
  },[availableTypes])


  const loadDetails = async (item) => {

    let updatedBy;
    let createdBy;
    let objDetail;

    let loadUser = axiosCerebrum
      .get(
        `/api/collectioninstances/${item.id}/related`,{
          params:{relationship:'UPDATED_BY',per_page:1,sort:'END_DESC'}
        }
      )
      .then(async response=>{
        updatedBy = response.data.items[0];
        if(!updatedBy){
          await axiosCerebrum
            .get(
              `/api/collectioninstances/${item.id}/related`,{
                params:{relationship:'CREATED_BY',per_page:1,sort:'END_DESC'}
              }
            )
            .then(response=>{
              createdBy = response.data.items[0];
            })
        }
      })
      .catch(error=>{
        console.log(error)
      })
    let loadObj = axiosCerebrum
      .get(
        `/api/collectioninstances/${item.id}`
      )
      .then(response=>{
        objDetail = response.data;
      })
      .catch(error=>{
        console.log(error)
      })
    await Promise.all([loadUser,loadObj])

    return Promise.resolve({updatedBy, createdBy, objDetail});
  }

  const loadNotes = ({page=1,type=selectedType}) => {
    dispatch({
      type:'set_notes',
      notesLoading:true,
      notes:{
        ...(page===1?{}:state.notes),
        type,
        view:'note'
      }
    })
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(object.object.name)}/${object.id}/related/collections`,
        {params:{
          category:'KNOWLEDGE',
          parent_id:type!=='all'?type:undefined,
          page:page,
          sort:'END_DESC',
          per_page:10,
        }}
      )
      .then(async response=>{
        let data = response.data;
        if(data.total===0){
          dispatch({
            type:'set_notes',
            notes:{
              ...data,
              type,
              view:'note'
            },
          })
          return;
        }

        if(page>1){
          data.items = [...state.notes.items,...data.items]
        }

        await axiosSolr
          .get(
            `/solr/search/select`,{
              params:{
                q:"*",
                fq:`id:(${data.items.map(el=>el.id).join(' OR ')})`,
              }
            }
          )
          .then(response=>{
            let docs = response.data.response.docs;
            data.items = data.items.map(el=>{
              let doc = docs.find(doc=>doc.id===el.id) || {}
              return {
                ...el,
                ...doc,
                ...(el.id===newlyCreatedNote?.id?newlyCreatedNote:{}) 
              }
            })
          })
          .catch(error=>{
            console.log(error)
          })

        dispatch({
          type:'set_notes',
          notes:{
            ...data,
            type,
            view:'note'
          },
          notesLoading:false,
        })

        let details = {};
        let promises = [];
        data.items.forEach(el=>{
          promises.push(loadDetails(el).then(response=>{
            details[el.id] = response;
          }))
        })
        await Promise.all(promises)
          .then(response=>{
            dispatch({
              type:'set_note_detail_map',
              noteDetailMap:{...(state.noteDetailMap||{}),...details}
            })
          })
          .catch(error=>{
            console.log(error)
          })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_notes',
          notesError:true,
          notes:{
            type,
            view:'note'
          }
        })
      })
  }

  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data[`load-note-${state.basicData.id}`] ){
        if(msg.data.note){
          try{  
            setNewlyCreatedNote(JSON.parse(msg.data.note))
          }catch(error){}
        }
        dispatch({
          type:'set_notes',
          notes:{
            type:state.notes?.type,
            view:state.notes?.view
          }
        })
        loadAvailableTypes()
      } 
    }
    window.removeEventListener('message',globalListenerRef.profileNoteListener);
    globalListenerRef.profileNoteListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.profileNoteListener);
    return (()=>{window.removeEventListener('message',globalListenerRef.profileNoteListener);})
  // eslint-disable-next-line
  },[state.notes])

  const onChangeType = type => {
    setSelectedType(type)
    loadNotes({page:1,type:type})
  }

  const loadSuveys = ({page=1}) => {
    dispatch({
      type:'set_survey_data',
      surveyLoading:true,
      surveyData:page===1?undefined:state.surveyData
    })
    axiosCerebrum
      .get(
        `/api/surveytemplates/3/responses`,// hardcoded feedback category id
        {params:{
          object_id:object.id,
          page,
          per_page:10
        }}
      )
      .then(res=>{
        dispatch({
          type:'set_survey_data',
          surveyData:{
            ...res.data,
            items:page===1?res.data.items:[...state.surveyData.items,...res.data.items]
          },
          surveyLoading:false
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_survey_data',
          surveyError:false
        })
      })
  }

  useEffect(()=>{
    if(view==='feedback' && !state.surveyData && !state.surveyLoading){
      loadSuveys({page:1})
    }
  // eslint-disable-next-line
  },[view])

  const shouldLoadMore = () => {
    if(view==='feedback'){
      return isInViewport(scrollRef) && !state.surveyLoading && state.surveyData?.items && state.surveyData?.page<state.surveyData?.pages
    }
    return isInViewport(scrollRef) && !state.notesLoading && state.notes?.items && state.notes?.page<state.notes?.pages
  }

  window.onscroll = () => {
    if(shouldLoadMore()){
      if(view==='feedback'){
        loadSuveys({page:state.surveyData.page+1})
        return;
      }
      loadNotes({page:state.notes.page+1})
    }
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadNotes({page:state.notes.page+1})
    }
  // eslint-disable-next-line
  },[state.notes, state.notesLoading])

  useEffect(()=>{
    if(shouldLoadMore()){
      loadSuveys({page:state.surveyData.page+1})
    }
  // eslint-disable-next-line
  },[state.surveyData, state.surveyLoading])

  const onRemoveNote = (el) => {
    axiosCerebrum
      .delete(
        `/api/collectioninstances/${el.id}/related?relationship=MEMBERS&object_id=${object.id}`
      )
      .then(response=>{
        dispatch({
          type:"set_notes",
          notes:{
            ...state.notes,
            items:state.notes.items.filter(item=>item.id!==el.id),
            total:state.notes.total-1
          }
        })
        window.postMessage({reload_sidebar:'knowledge'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
        sendAlert({ message: `Link to ${el.name} removed`, type: 'info' })
      })
      .catch(error=>{
        console.log(error);
        sendAlert({ message: `Error occurred unlinking ${el.name}`, type: 'error' })
      })
  }

  if(availableTypes.loading){
    return (
      <div style={{display:'flex',justifyContent:'center',marginTop:16}}>
        <CircularProgress color='secondary'/>
      </div>
    )
  }
  if(availableTypes.error){
    return (
      <Typography style={{marginTop:16}}>Error occurred loading notes</Typography>
    )
  }
  if(!availableTypes.types)return <div></div>

  return (
    <div className={classes.root}>
      <div id='profile-note-section'>
        {
          (state.notes?.total!==0 || selectedType!=='all') && 
          <div style={{display:'flex',height:48,justifyContent:'center',alignItems:'center',marginBottom:16,borderRadius:3,border:`1px solid ${theme.palette.listItemDivider.main}`}}>
            <Button onClick={onClickAskQuestion} data-test-id="knowledge-manager-create-question-button" variant="outlined" className={classes.bannerButton} color='primary'>ASK A QUESTION</Button>
            <span className={classes.bannerText} style={{marginLeft:8,marginRight:8}}>or</span>
            <Button onClick={onClickContribute} data-test-id="knowledge-manager-create-note-button" variant="outlined" className={classes.bannerButton} color='primary'>CONTRIBUTE YOUR KNOWLEDGE</Button>
            <span className={classes.bannerText} style={{marginLeft:8}}>to help your colleagues</span>
          </div> 
        }
        {
          (state.notes?.total!==0 || selectedType!=='all') &&
          <>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-start',marginBottom:24}}>
              <div style={{marginRight:16,flexGrow:1}}>
                <div style={{display:'flex',alignItems:'center'}}>
                  {/* <Select
                    value={view}
                    onChange={e=>onChangeView(e.target.value)}
                    className={classes.titleSelector}
                    disableUnderline
                  >
                    <MenuItem value='note'>KNOWLEDGE BOARD</MenuItem>
                    <MenuItem value='feedback'>FEEDBACK</MenuItem>
                  </Select> */}
                  <Typography style={{fontSize:20,color:theme.palette.header.main}}>KNOWLEDGE BOARD</Typography>
                  {
                    view==='note' &&
                    <Button color='primary' style={{marginLeft:16}} onClick={()=>{setIsEdit(!isEdit)}}>
                      {isEdit?'CLOSE':'EDIT'}
                    </Button>
                  }
                </div>
                <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginTop:6}}>
                  {
                    view==='note'?
                    'Question and knowledge contributions about this table':
                    'Feedback captured from users'
                  }
                </Typography>
              </div>
              {
                view==='note' &&
                <Select
                  value={selectedType}
                  onChange={e=>onChangeType(e.target.value)}
                  style={{width:180}}
                  className={classes.selector}
                  disableUnderline
                >
                  <MenuItem value='all'>All</MenuItem>
                  {
                    availableTypes?.types?.map(el=><MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)
                  }
                </Select>
              }
            </div>
          </>
        }
        {
          view==='note' && 
          <>
            {
              state.notesError && <Typography style={{fontSize:12,color:theme.palette.primaryText.main,marginBottom:16}}>Error occurred loading notes</Typography>
            } 
            {
              state.notes?.items?.map(el=>(
                <NoteChip
                  key={el.id}
                  note={el}
                  onRemoveNote={onRemoveNote}
                  isEdit={isEdit}
                  additionalDetail={state.noteDetailMap?.[el.id]}
                  history={history}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              ))
            }
            {
              state.notes?.total===0 && 
                (
                selectedType==='all'?
                  <div style={{textAlign:'center',marginTop:80}}>
                    <Typography className={classes.emptyText} style={{marginBottom:24}}>No questions have been asked or knowledge contributed. </Typography>
                    <Typography className={classes.emptyText}>Be the first to contribute</Typography>
                    <div style={{display:'flex',justifyContent:'center',marginTop:40}}>
                      <Button color='primary' style={{marginRight:24}} variant="contained" onClick={()=>onClickAskQuestion(true)}>ASK A QUESTION</Button>
                      <Button color='primary' variant="contained" onClick={()=>onClickContribute(true)}>ADD KNOWLEDGE</Button>
                    </div>
                  </div>
                  :
                  <Typography>No items found</Typography>
                )
            }
            <div ref={scrollRef} style={{textAlign:'center',marginBottom:24}}>
              {state.notesLoading && <CircularProgress style={{marginTop:16}} color='secondary'/>}
            </div>
          </>
        }
        {
          view==='feedback' && 
          <>
            {
              state.surveyError && <Typography style={{fontSize:12,color:theme.palette.primaryText.main,marginBottom:16}}>Error occurred loading notes</Typography>
            } 
            {
              state.surveyData?.items?.map(el=>(
                <NoteChip
                  key={el.id}
                  note={el}
                  history={history}
                  isFeedback={true}
                  object={object}
                />
              ))
            }
            {
              state.surveyData?.total===0 && 
              <Typography>No items found</Typography>
            }
            <div ref={scrollRef} style={{textAlign:'center',marginBottom:24}}>
              {state.surveyLoading && <CircularProgress style={{marginTop:16}} color='secondary'/>}
            </div>
          </>
        }
      </div>
    </div>
  )
}

NoteAdderList.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onClickAskQuestion: PropTypes.func.isRequired,
  onClickContribute: PropTypes.func.isRequired,
}

export default withTheme()(withStyles(styles)(NoteAdderList));