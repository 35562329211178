import React, { useEffect, useRef } from 'react';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import { generateChartGroup, generateSwitchableLineGraph, generateDataTiles, generateExtractDownloader } from './componentGenerator'
import { loadGraphData , loadTileData} from './dataLoader'

const styles = theme => ({
  chartContainer:{
    width:'50%',
    flexGrow:1,
    boxSizing:'border-box',
    padding:16,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:4,
    marginBottom:24
  },
  header:{
    fontSize:20,
    color:theme.palette.primaryText.main,
  },
  subHeader:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:8
  },
  chartTitle:{
    fontSize:16,
    color:theme.palette.header.main,
  },
  chartSubtitle:{
    fontSize:12,
    color:theme.palette.primaryText.light
  },
  selector:{
    ...theme.components.selector,
    width:160,
    height:48
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  subTitle:{
    color:theme.palette.primaryText.light,
    fontSize:13.75
  },
  menuItem:{
    fontSize:13.75,
    paddingLeft:0,
    color:theme.palette.primaryText.main,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  basicMenuItem:{
    color:theme.palette.primaryText.main,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  dropdownCaption:{
    color:theme.palette.primary.main,
    fontSize:12,
    letterSpacing:2,
    marginLeft:16,
    marginBottom:8
  },
  tooltip:{
    fontSize:13.75,
    whiteSpace:'pre-wrap'
  },

  selectorHeader:{
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.overline.main,
    marginBottom:8
  },
  timeSelector:{
    ...theme.components.inputBase,
    '& div':{
      backgroundColor:'transparent',
    },
    '& label':{
      color:theme.palette.primaryText.light
    },
    '& input':{
      color:theme.palette.primaryText.main,
      padding:'15px 16px 15px'
    }
  },
  suggestionChip:{
    ...theme.components.suggestionChip
  },
  suggestionHeader:{
    fontSize:12,
    letterSpacing:2,
    marginTop:16,
    marginBottom:8,
    color:theme.palette.primaryText.light
  }
})

const Content = props => {

  const {
    classes,
    // theme,
    history,
    state,
    dispatch
  } = props;

  const data = state.usageData['content'];
  const setData =  d => dispatch({type:'set_usage_data',usageData:{...state.usageData,content:d}})
  const apiSeq = useRef(1)

  useEffect(()=>{
    loadTileData({data, setData});
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(!data.tileData || data[`${data.selectedTile}_graphData`] || data[`${data.selectedTile}_graphDataError`] || data[`${data.selectedTile}_graphDataLoading`])return;
    apiSeq.current += 1;
    loadGraphData({data,setData,dbList:state.dbList, history, dispatch, state, apiSeq:apiSeq.current, apiSeqRef:apiSeq })
    // eslint-disable-next-line
  },[data.selectedTile,data.tileData, data])
  
  return (
    <div className={classes.root}>
      {generateExtractDownloader({state, dispatch, data})}
      {generateDataTiles({classes, data, setData})}

      {
        data.selectedTile && 
        <>
          <Typography className={classes.header}>INSIGHTS</Typography>
          <Typography className={classes.subHeader}>Select a metric to drill through to the details in Search</Typography>
          {
            generateChartGroup({classes,data,sourceColourMap:state.sourceColourMap})
          }
          {
            generateSwitchableLineGraph({classes, data, setData, dbList:state.dbList,sourceColourMap:state.sourceColourMap})
          }
        </>
      }
      {
        data.selectedTile && data[`${data.selectedTile}_graphData`] && (Object.keys(data[`${data.selectedTile}_graphData`]).length===0 || Object.keys(data[`${data.selectedTile}_graphData`]).every(k=>!data[`${data.selectedTile}_graphData`][k]) ) && 
        <Typography>No insights available</Typography>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(Content));