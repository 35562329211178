import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme,  withStyles, Paper, Typography, Popper, CircularProgress, Button } from '@material-ui/core';
import { onClickResultItem } from '../../../utilities';
import theme from '../../../theme';
import axiosCerebrum from '../../../axios-cerebrum';
import { removeMarkDown } from './Templates';

const styles = () => ({
  unselectable:{
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none'
  }
})

function DetailPopperEL(props) {

  const {
    classes,
    objectId, 
    name,
    buttonText,
    buttonAction,
    isSuggestion,
    isMultiple,
    colour
  } = props;

  const [anchor, setAnchor] = useState()
  const [data, setData] = useState()
  const closeTimeoutRef = useRef()
  const enterTimeoutRef = useRef()

  const loadData = () =>{
    setData({loading:true})
    axiosCerebrum
      .get(
        `/api/collectioninstances/${objectId}`
      )
      .then(response=>{
        setData({data:response.data})
      })
      .catch(error=>{
        console.log(error)
        setData({error:true})
      })
  }

  useEffect(()=>{
    if(anchor && !data){
      loadData()
    }
  // eslint-disable-next-line
  },[anchor])
  
  let caption = isSuggestion?
        isMultiple?
          'MULTIPLE SUGGESTED TERMS':
          'SUGGESTED TERM'
        :
        'LINKED TERM'
  
  if(data?.data?.parent.category!=='GLOSSARY')caption = 'LINKED COLLECTION INSTANCE'

  return (
    <span className={classes.unselectable}>
      <span 
        style={{cursor:'default'}} 
        onMouseOver={(event)=>{
          clearTimeout(closeTimeoutRef.current)
          let target = event.target
          enterTimeoutRef.current = setTimeout(()=>{
            setAnchor(target)
          },200)
        }}
        onMouseLeave={()=>{ 
          closeTimeoutRef.current = setTimeout(()=>{
            setAnchor()
          },300);
          clearTimeout(enterTimeoutRef.current)
        }}
      >
        {name}
      </span>
      <Popper
        open={Boolean(anchor)}
        style={{marginLeft:80}}
        anchorEl={anchor}
        onClose={()=>setAnchor()}
        onMouseEnter={()=>{
          clearTimeout(closeTimeoutRef.current)
        }}
        onMouseOver={()=>{
          clearTimeout(closeTimeoutRef.current)
        }}
        onMouseLeave={()=>{
          closeTimeoutRef.current = setTimeout(()=>{
            setAnchor()
          },300)
        }}
      > 
        <Paper className='inline-detail-popper' style={{marginTop:8,width:300,padding:'16px 16px 8px',border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
          <Typography  style={{fontSize:12,color:theme.palette.primaryText.main, letterSpacing:1, marginBottom:8}}>
            {caption}
          </Typography>
          {
            data && data.error && 
            <Typography style={{color:theme.palette.primaryText.main,fontSize:12}}>Error occurred loading details</Typography>
          }
          {
            data && data.loading &&
            <CircularProgress style={{color:theme.palette.secondary.main}}/> 
          }
          {
            data && data.data && 
            <div >
              <div  style={{display:'flex',overflow:'hidden',alignItems:'center',marginBottom:12}}>
                <Typography  style={{fontSize:13.75,color:theme.palette.primaryText.main,flex:'1 1 50%',marginRight:8,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                  {data.data.name}
                </Typography>
                <div  style={{border:`1px solid ${colour}`,color:colour,borderRadius:12,fontSize:13.75,padding:'4px 8px',width:'max-content',maxWidth:'40%',overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                  {data.data.parent.name}
                </div>
              </div>
              <Typography style={{color:theme.palette.primaryText.light,fontSize:12,wordBreak:'break-word', marginBottom:32}}>
                {data.data.description?removeMarkDown(data.data.description).length>100?removeMarkDown(data.data.description).slice(0,97)+'...':removeMarkDown(data.data.description):'No description'}
              </Typography>
              <div  style={{display:'flex',justifyContent:'flex-end'}}>
                <Button style={{color:theme.palette.primary.main}} onClick={()=>{buttonAction?buttonAction():onClickResultItem({id:data.data.id,label:data.data.object.name,item:data.data, newWindow:true})}}>
                  {buttonText||'OPEN'}
                </Button>
              </div>
            </div>
          }
        </Paper>
      </Popper>
    </span>
    
  )
}

DetailPopperEL.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  deleted: PropTypes.bool
}

export default withTheme()(withStyles(styles)(DetailPopperEL));