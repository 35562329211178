import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Select, MenuItem } from '@material-ui/core';
import { getIconComponent, tabGroup, titleCaseObjectName } from '../../../utilities';

const styles = theme => ({
  selector: {
    ...theme.components.titleSelector
  },
  selectorHeader: {
    fontSize: 12,
    color: theme.palette.primaryText.main,
    letterSpacing: 2,
    paddingLeft: 16,
    marginTop: 4,
    marginBottom: 4
  }
})

function ObjectSelector(props) {

  const {
    classes,
    theme,
    id,
    validObjectTypes,
    objectType,
    onSetObjectType,
    selectorClassname
  } = props;

  return (
    <Select
      className={selectorClassname || classes.selector}
      value={objectType}
      style={{ marginLeft: 8 }}
      data-test-id={id}
      disableUnderline
      renderValue={() => objectType ? titleCaseObjectName(objectType) + 's' : 'Select a type'}
      displayEmpty
    >
      {
        tabGroup.map(el => (
          el.tabs.some(t => validObjectTypes.includes(t)) ?
            <div style={{ width: 188, marginBottom: 12 }}>
              <Typography className={classes.selectorHeader} onClick={event => { event.stopPropagation(); event.preventDefault(); }}>{el.name}</Typography>
              {
                el.tabs.map(t => {
                  if (validObjectTypes.includes(t)) {
                    return (
                      <MenuItem value={t} onClick={() => {
                        onSetObjectType(t)
                      }}>
                        <div style={{ width: 24, height: 24, marginRight: 16 }}>{getIconComponent({ label: t, size: 24, colour: theme.palette.primaryText.light })}</div>
                        {titleCaseObjectName(t)}s
                      </MenuItem>
                    )
                  }
                  return undefined
                })
              }
            </div>
            :
            undefined
        ))
      }
    </Select>
  )
}

ObjectSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  validObjectTypes: PropTypes.array.isRequired,
  objectType: PropTypes.string.isRequired,
  onSetObjectType: PropTypes.func.isRequired,
  selectorClassname: PropTypes.string
}

export default withTheme()(withStyles(styles)(ObjectSelector));