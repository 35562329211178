import React, {useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, CircularProgress, InputBase, IconButton, Button } from '@material-ui/core';
import { getIconComponent, isInViewport, onClickResultItem, sendMessage} from '../../../utilities';
import axiosSolr from '../../../axios-solr';
import { globalListenerRef } from '../../../GlobalListenerRef';
import axiosCerebrum from '../../../axios-cerebrum';
import UserSuggestionItem from '../../UI/SearchResults/UserSuggestionItem';

const styles = theme => ({
  root: {
    marginBottom: 30
  },
  mainText: {
    fontSize: '1.25rem',
    color:theme.palette.header.main
  },
	searchFilter:{
    ...theme.components.inputBase,
    width:180,
		height:40,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
})


const Member = props => {

  const {
    classes,
    history,
    theme,
    state,
    dispatch
  } = props;

  const [loadingMore, setLoadingMore] = useState(false);
  const memberLoader = useRef(null);
	const searchTimeoutRef = useRef()

  let per_page = 20;

  const loadMoreMember = ({page=1,search=state.memberSearch}) => {
    setLoadingMore(true);
    if(page===1){
      dispatch({type:'set_member_data',memberData:null,memberError:false})
    }
    axiosCerebrum.get(
      `/api/collectioninstances/${state.basicData.id}/related`,
      {
        params:{
          relationship:'MEMBERS',
          object_name:'USER',
          page,
          per_page,
          sort:'ALPHABETICAL',
          'search.name':search
        }
      }
    ).then(response=>{
      if(response.data.items.length===0){
        dispatch({type:'set_member_data',memberData:[],memberNum:0})
        setLoadingMore(false)
        return; 
      }else{
        axiosSolr
          .get(
            `/solr/search/select`,{
              params:{
                q:"*",
                fq:`id:(${response.data.items.map(m=>m.id).join(' OR ')})`,
                rows:response.data.items.length,
              }
            }
          )
          .then(solrRes=>{
            let solrData = solrRes.data.response.docs;
            let memberData = response.data.items.map(m=>{
              let solrItem = solrData.find(s=>s.id===m.id);
              return {
                ...m,
                ...(solrItem||{})
              }
            })
            dispatch({type:'set_member_data',memberData:[...(page===1?[]:state.memberData),...memberData],memberNum:response.data.total})
            setLoadingMore(false)
          })
          .catch(error=>{
            setLoadingMore(false)
            dispatch({type:'set_member_data',memberError:true})
          })
      }
    }).catch(error=>{
      setLoadingMore(false)
      dispatch({type:'set_member_data',memberError:true})
    })
  }


  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.jobTypeMemberListener)
    globalListenerRef.jobTypeMemberListener = (msg) => {
      if(msg.data.reload_member){
        loadMoreMember({})
      }
    }
    window.addEventListener('message',globalListenerRef.jobTypeMemberListener)
    return ()=>{
      window.removeEventListener('message',globalListenerRef.jobTypeMemberListener)
    }
  // eslint-disable-next-line
  },[])


  useEffect(()=>{
    if(!state.memberData)loadMoreMember({})
    // eslint-disable-next-line
  },[])

  const shouldLoadMore = () => {
    return state.memberData && !loadingMore && state.memberData.length<state.memberNum && isInViewport(memberLoader)
  }

  window.onscroll=(()=>{
    if(shouldLoadMore())loadMoreMember({page:Math.floor(state.memberData.length/per_page)+1});
  })

  useEffect(()=>{
    if(shouldLoadMore())loadMoreMember({page:Math.floor(state.memberData.length/per_page)+1});
    // eslint-disable-next-line
  },[loadingMore,state.memberData])

	const onSearchChange = value => {
    dispatch({type:'set_member_search',memberSearch:value})
    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(()=>{
      loadMoreMember({search:value})
    },250)
  }
  

  return (
    <div 
      className={classes.root} 
    >
      <div style={{display:'flex',alignItems:"flex-end",marginBottom:16}}>
        <div style={{flexGrow:1, display:'flex'}}>
          <Typography className={classes.mainText}>{state.memberNum} MEMBER(S)</Typography>
          <Button color='primary' onClick={()=>sendMessage({open_instance_link_modal:true})} style={{marginLeft:8,marginTop:-2}}>EDIT</Button>
        </div>
        <InputBase
          value={state.memberSearch}
          onChange={event => onSearchChange(event.target.value)}
          variant={'filled'}
          placeholder={'Search'}
          className={classes.searchFilter}
          endAdornment={
            <IconButton 
              disabled={state.memberSearch===''} 
              onClick={()=>onSearchChange('')}
              style={{width:32,height:32,marginRight:6}}
            >
              {getIconComponent({label:state.memberSearch===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
            </IconButton>
          }
        />
      </div>
      <div className={classes.list} >
        {
          state.memberData && state.memberData.length!==0 && state.memberData.map(m=>(
            <UserSuggestionItem
              onClick={()=>onClickResultItem({label:'user', id:m.id, item:m,history})}
              item={m}
            />
          ))
        
        }
        {state.membernum===0 && <Typography>No people added to this Team</Typography>}
      </div>
      {
        state.memberData && state.memberData.length===0 && 
        <Typography>No member found</Typography>
      }
      <div ref={memberLoader}>
      {
        loadingMore && 
        <div style={{display:'flex',width:'100%',justifyContent:'center',marginBottom:10,marginTop:10}}><CircularProgress color='secondary'/></div>
      } 
      {
        state.memberError && 
        <Typography>Error occurred loading members</Typography>
      }
    </div>

    </div>
  )
}

Member.propTypes = {
  classes: PropTypes.object.isRequired,
  nodeKey: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func
}

export default withTheme()(withStyles(styles)(Member));