import React, { } from 'react';
import { withTheme, Typography, Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';


const UnusedPeriodSelector = props => {

  const {
    classes,
    theme,
    period,
    setPeriod,
  } = props;


  return (
    <div className={classes.modalBlock}>
      <Typography className={classes.modalBlockTitle}>{'Unused Period'}</Typography>
      <div style={{ flexGrow: 1 }}>
        <FormControl style={{ width: '100%' }}>
          <Select
            className={classes.selector}
            disableUnderline
            value={period}
            onChange={event => setPeriod(event.target.value)}
            MenuProps={{
              classes: {
                paper: classes.selectPaper
              }
            }}
            renderValue={el => {
              return (
                el === 'none'
                  ?
                  <Typography style={{ color: theme.palette.primaryText.light, fontSize: 16 }}>
                    Set a period
                  </Typography>
                  :
                  <>
                    <Typography style={{ fontSize: 16, color: theme.palette.primaryText.main }}>
                      {`Unused in ${el}${el === 365 ? '+' : ''} days`}
                    </Typography>
                  </>
              )
            }}
          >
            <MenuItem className={classes.menuItem} value={'none'}>
              Set a period
            </MenuItem>
            {
              [90, 180].map(el => (
                <MenuItem className={classes.menuItem} value={el}>
                  {`Unused in ${el} days`}
                </MenuItem>
              ))
            }
            <MenuItem className={classes.menuItem} value={365}>
              {`Unused in 365+ days`}
            </MenuItem>
          </Select>
          <FormHelperText className={classes.helperText} >
            {"Required. Defines how long a period before a user's access is considered unnecessary"}
          </FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}

UnusedPeriodSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  period: PropTypes.number.isRequired,
  setPeriod: PropTypes.func.isRequired,
}

export default withTheme()(UnusedPeriodSelector);