import React, { memo } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Button } from '@material-ui/core';
import { getIconComponent, } from '../../../utilities';
import PropTypes from 'prop-types';
import theme from '../../../theme';
import { getIconLabel } from '../../UI/SearchResults/utils';

const styles = theme => ({
  listItem:{
    height:40,
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    cursor:'pointer',
    boxSizing:'border-box',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    '& .select-button':{
      display:'none'
    },
    '&:hover':{
      '& .select-button':{
        display:'inline-block'
      }
    }
  },
  selectedItem:{
    background:theme.palette.hovered.main
  },
  root:{
    overflow:'hidden'
  },
  itemName:{
    fontSize:13.75,
    overflow:"hidden",
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginRight:8,
    flexGrow:1
  },
  customScroll:{
    ...theme.components.customScroll,
    maxHeight:300,
    overflow:'auto'
  },
  seeMoreButton:{
    '& span':{
      fontSize:12
    }
  }
})

const ListItem = withStyles(styles)(memo((props) => {

  const {
    list,
    onItemExpand,
    item,
    classes,
    setSelectedInstance,
    rootCollection,
    loading,
    pageMap, 
    onLoadMore,
    currentInstance
  } = props;

  let children = list.filter(l=>l.upperLevelID===item.id)
  let hasChildren = item.has_children || children.length>0

  const generateLevel = () => {
    let level = 0;
    let upperID = item.upperLevelID;
    while(upperID!==rootCollection.id){
      level += 1;
      // eslint-disable-next-line
      upperID = list.find(l=>l.id===upperID).upperLevelID
    }
    return level
  }

  let level = generateLevel();

  return (
    <div className={classes.root} style={{maxHeight:item.expanded?undefined:40,paddingLeft:level===0?0:24}}>
      <div 
        // className={classes.listItem + (selectedInstance===item.id?' '+classes.selectedItem:'')} 
        className={classes.listItem} 
        style={{cursor:hasChildren?'pointer':undefined}} 
        onClick={()=> {
          // setSelectedInstance(item.id)
          if(hasChildren)onItemExpand(item)
        }}
      >
        <div style={{width:20,height:20,marginLeft:8,marginRight:4,display:'flex',alignItems:'center',justifyContent:'center',transition: "transform .3s ease",transform:hasChildren?`rotate(${item.expanded?90:0}deg)`:undefined}}>
          {
            getIconComponent({label:hasChildren?"expand":'dot',size:16,colour:theme.palette.primaryText.light})
          }
        </div>
        <div style={{width:20,height:20,marginLeft:0,marginRight:8,display:'flex',alignItems:'center',justifyContent:'center'}}>
          {
            getIconComponent({label:getIconLabel({label:'collection',item:rootCollection}),size:20,colour:theme.palette.primaryText.light})
          }
        </div>
        <Typography className={classes.itemName}>{item.name}</Typography>
        <Button data-test-classname="parent-select-button" data-test-id={`parent-select-button-${item.name.toLowerCase().replace(/\s/g,'-')}`}  className='select-button' color='secondary' variant='outlined' style={{padding:'0 6px',marginRight:0, minWidth:0}} onClick={(event)=>{event.stopPropagation();setSelectedInstance(item.id)}}>
          SELECT
        </Button>
      </div>
      {
        children.length>0 && children.filter(c=>c.id!==currentInstance.id).map(c=>(
          <ListItem
            onItemExpand={onItemExpand}
            item={c}
            list={list}
            setSelectedInstance={setSelectedInstance}
            rootCollection={rootCollection}
            loading={loading}
            pageMap={pageMap}
            onLoadMore={onLoadMore}
            currentInstance={currentInstance}
          />
        ))
      }
      {
        loading===item.id && 
        <div style={{marginTop:6,marginLeft:24}}>
          <CircularProgress style={{width:16,height:16}} color='secondary'/>
        </div>
      }
      {
        pageMap[item.id] && pageMap[item.id].page<pageMap[item.id].pages &&
        <div style={{marginTop:6, marginLeft:24}}>
          <Button 
            color='secondary' 
            disabled={loading===item.id} 
            style={{marginTop:4,marginBottom:4,padding:'0 4px'}}
            onClick={()=>onLoadMore(item.id)}
            className={classes.seeMoreButton}
          >
            SEE MORE
          </Button>
        </div>
      }
    </div>
  );
}));

ListItem.propTypes = {
  item:PropTypes.object.isRequired,
  onItemExpand:PropTypes.func.isRequired,
  list: PropTypes.array,
  setSelectedInstance: PropTypes.func.isRequired
}

function FolderSelector(props) {

  const {
    classes,
    onItemExpand,
    rootCollection,
    list,
    setSelectedInstance,
    loading,
    pageMap,
    onLoadMore,
    currentInstance
  } = props;

  if(!list)return <div></div>

  let rootEls = list.filter(l=>l.upperLevelID===rootCollection.id).filter(c=>c.id!==currentInstance.id)

  if(rootEls.length===0)return <Typography style={{fontSize:12}}>No items found</Typography>

  return (
    <div 
      className={classes.customScroll}
      onScroll={event=>{
        if(event.target.scrollTop>=event.target.scrollHeight-event.target.clientHeight-10){
          if(!loading && pageMap[rootCollection.id] && pageMap[rootCollection.id].page<pageMap[rootCollection.id].pages){
            onLoadMore(rootCollection.id)
          }
        }
      }}
    >
      {
        rootEls.map(el=>(
          <ListItem
            item={el}
            onItemExpand={onItemExpand}
            list={list}
            setSelectedInstance={setSelectedInstance}
            rootCollection={rootCollection}
            loading={loading}
            pageMap={pageMap}
            onLoadMore={onLoadMore}
            currentInstance={currentInstance}
          />
        ))
      }
    </div>
  )
}

FolderSelector.propTypes = {
  onItemExpand:PropTypes.func.isRequired,
  rootCollection: PropTypes.object.isRequired,
  list: PropTypes.array,
  setSelectedInstance: PropTypes.func.isRequired,
  pageMap: PropTypes.object.isRequired,
  onLoadMore: PropTypes.func.isRequired
}

export default withTheme()(withStyles(styles)(FolderSelector));