import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, InputBase, InputAdornment,Typography, Select, MenuItem, CircularProgress } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchOutlined'
import {  toTitleCase, mapObjectName, onClickResultItem, getIconComponent, isInViewport } from '../../../utilities'
import axiosCerebrum from '../../../axios-cerebrum'
import axiosSolr from '../../../axios-solr'
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../../UI/ContextMenu/ContextMenu'
import { getIconLabel } from '../../UI/SearchResults/utils';

const styles = theme => ({
  inputField:{
    ...theme.components.inputBase,
    width:'100%',
    maxWidth:'100%',
    marginRight:24,
    '& input':{
      padding:'14px 6px 14px 12px'
    }
  },
  selector: {
    ...theme.components.selector,
    paddingTop:4,
    paddingBottom:4,
    width:180,
    '& p':{
      overflow:'hidden'
    }
  },
  selectorText:{
    paddingLeft:16,
    color:theme.palette.primaryText.light,
    fontSize:16
  },
  chip:{
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    padding:'8px 16px',
    cursor:'pointer',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  button:{
    width:96
  },
  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
})

const CollectionList = props => {

  const {
    classes,
    history,
    theme,
    state,
    dispatch,
    sessionData,
    objectName,
    variant
  } = props;

  const searchRef = useRef();
  const scrollRef = useRef();

  let listData = state.listData[objectName];
  let sort = state.sort[objectName] || 'ALPHABETICAL';
  let search = state.search[objectName] || '';
  let loading = state.listData[objectName+'_loading'];
  let error = state.listData[objectName+'_error'];

  const loadData = ({page=1, sort=state.sort[objectName]||'ALPHABETICAL', query=state.search[objectName] || ''}) => {
    dispatch({
      type:'set_list_data',
      listData:{...state.listData,[objectName+'_loading']:true,[objectName]:page===1?undefined:state.listData[objectName]}
    })
    axiosCerebrum
      .get(
        `/api/users/${state.viewedUser?state.viewedUser.id:sessionData.id}/related/collections`,
        {params:{
          parent_name:objectName,
          page,
          per_page:10,
          sort,
          relationship:variant==='owner'?'OWNER_OF':'STEWARD_OF',
          'search.name':query.trim()!==''?query:undefined
        }}
      )
      .then(async response=>{
        if(response.data.total===0){
          dispatch({
            type:'set_list_data',
            listData:{...state.listData,[objectName]:response.data,[objectName+'_loading']:false,[objectName+'_error']:false}
          })
          return;
        }
        let detailsData = [];
        await axiosSolr
          .get(
            `/solr/search/select`,
            {params:{
              q:'*',
              fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
              fl:'*',
              rows:response.data.items.length
            }}
          )
          .then(solrRes=>{
            detailsData = solrRes.data.response.docs
          })
        let array = [];
        response.data.items.forEach((el)=>{
          array.push({
            ...el,
            labels:objectName.toLowerCase(),
            ...(detailsData.find(d=>d.id.toLowerCase()===el.id.toLowerCase())||{}),
            id:el.id
          })
        });
        let newData = {...response.data, items:[...(page===1?[]:state.listData[objectName].items),...array]}
        dispatch({
          type:'set_list_data',
          listData:{...state.listData,[objectName]:newData,[objectName+'_loading']:false,[objectName+'_error']:false}
        })
      })
      .catch(error=>{
        console.log(error);
        dispatch({
          type:'set_list_data',
          listData:{...state.listData,[objectName+'_loading']:false,[objectName+'_error']:true}
        })
      })
  }


  useEffect(()=>{
    if(!listData)loadData({page:1})
  // eslint-disable-next-line
  },[])

  const onSearchChange = value => {
    dispatch({type:'set_search',search:{...state.search,[objectName]:value}});
    dispatch({type:'set_page',page:{...state.page,[objectName]:1}})
    clearTimeout(searchRef.current);
    searchRef.current = setTimeout(() => {
      loadData({
        page:1,
        search:value,
        sort:sort
      })
    }, 300);
  }

  const onSortChange = value => {
    dispatch({type:'set_sort',sort:{...state.sort,[objectName]:value}});
    dispatch({type:'set_page',page:{...state.page,[objectName]:1}})
    loadData({
      page:1,
      search:search,
      sort:value
    })
  }

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && !loading && listData && listData.page < listData.pages && !error
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadData({page:listData.page+1,search,sort})
    }
    // eslint-disable-next-line
  },[listData, loading, error])

  window.onscroll = () => {
    if(shouldLoadMore()){
      loadData({page:listData.page+1,search,sort})
    }
  }

  let shownObjName = mapObjectName(objectName);
  if(objectName==='COLLECTION_INSTANCE')shownObjName = 'INSTANCE'
  

  return (
    <div className={classes.root}>
      <div style={{display:'flex',alignItems:'flex-start',marginBottom:20}}>
        <div style={{flexGrow:1}}>
          <Typography style={{fontSize:20,color:theme.palette.header.main}}>{listData?`${listData.total} `:''}{shownObjName.replace(/_/g,' ')}(S)</Typography>
          {listData && <Typography style={{color:theme.palette.primaryText.light,fontSize:12}}>Select a {toTitleCase(shownObjName.replace(/_/g,' '))} to review and update its details</Typography>}
        </div>
        <div style={{width:160,marginRight:24}}>
          <InputBase
            variant="filled"
            value={search}
            onChange={event=>onSearchChange(event.target.value)}
            className={classes.inputField}
            placeholder="Search"
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon style={{color:theme.palette.primaryText.light,marginRight:6}}/>
              </InputAdornment>
            }
          />
        </div>
        <Select
          className={classes.selector}
          value={sort}
          onChange={event => onSortChange(event.target.value)}
          inputProps={{className:classes.selectorText}}
          MenuProps={{
            classes:{
              paper:classes.selectPaper
            }
          }}
        > 
        {
          [
            {name:'A-Z',value:'ALPHABETICAL'},
            {name:'Z-A',value:'REVERSE_ALPHABETICAL'},
            {name:'Recently used',value:'LAST_USED_DESC'},
            {name:'Frequently used',value:'USAGE_DESC'},
          ].map(el=>(
            <MenuItem  className={classes.menuItem} value={el.value}>
              {el.name}
            </MenuItem>
          ))
        }
        </Select>
      </div>
      {
        error && <Typography style={{fontSize:theme.palette.primaryText.main}}>Error occurred loading data</Typography>
      }
      {
        listData && listData.total===0 && <Typography style={{color:theme.palette.primaryText.main}}>No data found</Typography>
      }
      <div>
        {
          listData?.items?.map((el,index)=>(
            <div>
              <ContextMenuTrigger id={el.id}>
                <div className={classes.chip}  onClick={()=>onClickResultItem({label:el.object_type_txt,id:el.id,item:el,history})}>
                  <div style={{marginTop:-16}}>
                    {getIconComponent({label:getIconLabel({label:el.object_type_txt,item:el}),size:24,colour:theme.palette.primary.main})}
                  </div>
                  <div style={{overflow:'hidden',flexGrow:1}}>
                    <Typography color='primary' style={{fontSize:16,marginLeft:16,width:240,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                      {el.name_txt}
                    </Typography>
                    <Typography style={{fontSize:13.75,marginLeft:16,width:400,color:theme.palette.primaryText.light,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>
                      {el.location_txt}
                    </Typography>
                  </div>
                </div>
              </ContextMenuTrigger>
              <ContextMenu id={el.id}>
                <CustomMenu
                  item={el}
                  actions={[
                    'open_new_tab',
                    'bookmark'
                  ]}
                />
              </ContextMenu>
            </div>
          ))
        }
      </div>
      <div ref={scrollRef} style={{display:'flex',justifyContent:'center',marginTop:16}}>
        {
          loading && <CircularProgress color='secondary'/>
        }
      </div>
    </div>
  )
}

CollectionList.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func
}

export default withTheme()(withStyles(styles)(CollectionList));