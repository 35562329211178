import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Modal, Paper, Select, MenuItem, Typography, Button, InputBase, CircularProgress } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import { getIconComponent, collectionIds, mapObjectName, toTitleCase } from '../../../utilities';
import ModalAlert from '../../UI/ModalAlert/ModalAlert'
import { getIconLabel } from '../../UI/SearchResults/utils';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  paper:{
    border:`1px solid ${theme.palette.border.main}`,
    padding:24,
    paddingRight:0,
    width:776,
    margin:'10vh auto 0px',
  },
  container:{
    width:802,
    margin:'auto',
    outline:'none'
  },
  mainSection:{
    maxHeight:'60vh',
    paddingRight:24,
    overflow:'auto',
    '&::-webkit-scrollbar':{
      width: '5px'
    },
    /* Track */
    '&::-webkit-scrollbar-track':{
      background: '#eee',
      borderRadius:2,
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      background: '#888',
      borderRadius:2,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{
      // background: #555;
    }
  },
  buttons:{
    display:'flex',
    justifyContent:"flex-end",
    marginRight:12,
    marginBottom:-12
  },
  title:{
    fontSize:20,
    paddingRight:24,
    color:theme.palette.header.main,
    marginBottom:32
  },
  inputSection:{
    display:'flex',
    marginBottom:24,
  },
  inputTitle:{
    fontSize:16,
    marginTop:14,
    color:theme.palette.primaryText.main,
    width:144,
    marginRight:24,
    flexGrow:0,
    flexShrink:0
  },
  inputContent:{
    flexGrow:1,
    overflow:'hidden'
  },
  selector:{
    ...theme.components.selector,
    color:theme.palette.primaryText.light,
    '& div div': {
      paddingLeft: 16,
      paddingTop: 16,
      paddingBottom: 16,
      paddingRight:44,
      fontSize:16
    }
  },
  helperText:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginTop:2,
    paddingLeft:16,
    wordBreak:'break-word'
  },
  errorBorder:{
    border:`1px solid ${theme.palette.error.main} !important`
  },
  errorText:{
    color:theme.palette.error.main
  },
  successBorder:{
    border:`1px solid ${theme.palette.success.main} !important`
  },
  successText:{
    color:theme.palette.success.main
  },
  inputBase:{
    ...theme.components.inputBase,
    '& textarea':{
      padding:'4px 16px'
    }
  },
  subHeader:{
    color:theme.palette.header.main,
    fontSize:12,
    letterSpacing:2,
    marginBottom:8
  },
  selectorHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:2,
    paddingLeft:16,
    marginTop:4,
    marginBottom:4
  }
})

function AddRuleModal(props) {

  const {
    classes,
    state,
    theme,
    dispatch,
    loadRules,
    loadAvailableCollections,
    isEdit,
    selectedRule
  } = props;

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [validating, setValidating] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    id:`linking-rule-add`,
    isCancelledRef,
    disableStateCache:true
  })

  useEffect(()=>{ 
    return () => {
      isCancelledRef.current = true
    }
  },[])

  const loadCollections = ({page=1,previousData=[]}) => {
    axiosCerebrum
      .get(`/api/collections`,{params:{
        category:'PLATFORM,DATA_GOVERNANCE,DATA_MANAGEMENT,GLOSSARY',
        per_page:20,
        page
      }})
      .then(response=>{
        if(response.data.pages<=page){
          dispatch({type:"set_add_rule_collection_list",addRuleCollectionList:[...previousData,...response.data.items].filter(el=>[collectionIds.domain,collectionIds.piiMetaLinked].includes(el.id) || el.category!=='PLATFORM')})
        }else{
          loadCollections({page:response.data.page+1,previousData:[...previousData,...response.data.items]})
        }
      })
      .catch(error=>{
        console.log(error)
      })
  }

  const loadInstances = ({collectionId, page=1, previousData=[]}) => {
    axiosCerebrum
      .get(`/api/collectioninstances`,{params:{
        collection_id:collectionId,
        per_page:20,
        page
      }})
      .then(response=>{
        if(response.data.pages<=page){
          dispatch({type:"set_add_rule_instance_list",addRuleInstanceList:[...previousData,...response.data.items]})
        }else{
          loadInstances({page:response.data.page+1,previousData:[...previousData,...response.data.items],collectionId})
        }
      })
      .catch(error=>{
        console.log(error)
      })
  }


  useEffect(()=>{
    if(!state.addRuleCollectionList)loadCollections({});
    // eslint-disable-next-line
  },[])
 
  const onChangeCollection = id => {
    dispatch({type:'set_add_rule_selected_object_type',addRuleSelectedObjectType:'none'})
    dispatch({type:'set_add_rule_selected_instance',addRuleSelectedInstance:'none'})
    dispatch({type:'set_add_rule_regex_validation',addRuleRegexValidation:{validated:false}})
    dispatch({type:'set_add_rule_selected_collection',addRuleSelectedCollection:id})
    dispatch({type:'set_add_rule_instance_list'})
    if(id!=='none')loadInstances({collectionId:id})
  }

  const onChangeInstance = event => {
    dispatch({type:'set_add_rule_selected_object_type',addRuleSelectedObjectType:'none'})
    dispatch({type:'set_add_rule_regex_validation',addRuleRegexValidation:{validated:false}})
    dispatch({type:'set_add_rule_selected_instance',addRuleSelectedInstance:event.target.value})
  }

  const onChangeObjectType = event => {
    dispatch({type:'set_add_rule_regex_validation',addRuleRegexValidation:{validated:false}})
    dispatch({type:'set_add_rule_selected_object_type',addRuleSelectedObjectType:event.target.value})
  }

  const onChangeRegex = event => {
    dispatch({type:'set_add_rule_regex',addRuleRegex:event.target.value})
    dispatch({type:'set_add_rule_regex_validation',addRuleRegexValidation:{validated:false}})
  }

  const isValidatedButtonDisbaled = () => {
    if(isEdit)return state.addRuleRegex.trim()===''
    return state.addRuleSelectedCollection==='none' || state.addRuleSelectedInstance==='none' || state.addRuleSelectedObjectType==='none' || state.addRuleRegex.trim()===''
  }

  const onValidateRegex = () => {
    if(['*','.*'].includes(state.addRuleRegex.trim())){
      dispatch({type:'set_add_rule_regex_validation',addRuleRegexValidation:{validated:false,error:'ERROR: * cannot be used in a rule'}})
      return;
    }
    setValidating(true)
    axiosCerebrum 
      .post(
        `/api/collectioninstances/rules/test`,
        {
          "object_type": state.addRuleSelectedObjectType,
          "reg_exp": state.addRuleRegex
        }
      )
      .then(response=>{
        setValidating(false)
        if(response.data.total===0){
          dispatch({type:'set_add_rule_regex_validation',addRuleRegexValidation:{validated:false,warning:'WARNING: Rule sampling returned 0 matches. Please check your rule before saving.'}})
        }else{
          dispatch({type:'set_add_rule_regex_validation',addRuleRegexValidation:{validated:true,total:response.data.total,items:response.data.items.slice(0,5)}})
        }
      })
      .catch(error=>{
        setValidating(false)
        let errorMsg = 'Error: Rule fails regex validation';
        if(error.response && error.response.status===408){
          dispatch({type:'set_add_rule_regex_validation',addRuleRegexValidation:{validated:false,warning:'WARNING: Rule sampling timed out. Please check your rule before saving.'}})
          return;
        }
        dispatch({type:'set_add_rule_regex_validation',addRuleRegexValidation:{validated:false,error:errorMsg}})
      })
  }

  const onCancel = () => {
    dispatch({type:'set_add_rule_selected_object_type',addRuleSelectedObjectType:'none'})
    dispatch({type:'set_add_rule_selected_instance',addRuleSelectedInstance:'none'})
    dispatch({type:'set_add_rule_regex',addRuleRegex:''})
    dispatch({type:'set_add_rule_regex_validation',addRuleRegexValidation:{validated:false}})
    dispatch({type:'set_add_rule_selected_collection',addRuleSelectedCollection:'none'})
    dispatch({type:'set_add_rule_instance_list'})
    dispatch({type:'set_add_rule_modal_open',addRuleModalOpen:false})
  }

  const onAddRule = () => {

    let promise;
    if(isEdit){
      promise = axiosCerebrum
        .put(
          `/api/collectioninstances/rules/${selectedRule.id}`,
          {
            "object_type": state.addRuleSelectedObjectType,
            "reg_exp": state.addRuleRegex
          }
        )
    }else{
      promise = axiosCerebrum
        .post(
          '/api/collectioninstances/rules',
          {
            "collection_instance_id": state.addRuleSelectedInstance,
            "object_type": state.addRuleSelectedObjectType,
            "reg_exp": state.addRuleRegex
          }
        )
    }

    promise.then(response=>{
      onCancel()
      loadRules({page:1})
      loadAvailableCollections()
      sendAlert({type:'info',message:isEdit?"Rule successfully updated":"Rule successfully created"})
    })
    .catch(error=>{
      console.log(error)
      setAlertOpen(true)
      setAlertMessage(`Error occurred ${isEdit?'updating':'creating'} the rule`)
    })
  }
  
  return (
    <Modal open={state.addRuleModalOpen} disableBackdropClick>
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <Typography className={classes.title}>
            {
              isEdit?
              `Edit ${selectedRule.collection_instance.name} linking rule`:
              'Add a new Instance linking rule'
            }
          </Typography>
            
          <div className={classes.mainSection}>
            {
              !isEdit &&
              <div className={classes.inputSection}>
                <Typography className={classes.inputTitle}>Collection</Typography>
                <div className={classes.inputContent}>
                  <div style={{width:'100%',display:'flex'}}>
                    <Select
                      value={state.addRuleSelectedCollection}
                      className={classes.selector + (state.addRuleSelectedCollection==='none'?' '+classes.errorBorder:'')}
                      // onChange={onChangeCollection}
                      style={{flexGrow:1}}
                      disableUnderline
                      renderValue={value=>value==='none'?'Select a Collection':state.addRuleCollectionList.find(c=>c.id===value).name}
                    >
                      {
                        ['DATA_GOVERNANCE','DATA_MANAGEMENT','PLATFORM'].map(el=>(
                          state.addRuleCollectionList && state.addRuleCollectionList.some(c=>c.category===el)?
                          <div style={{marginBottom:12}}>
                            <Typography className={classes.selectorHeader} onClick={event=>{event.stopPropagation();event.preventDefault();}}>{el.replace(/_/g,' ')}</Typography>
                            {
                              state.addRuleCollectionList.filter(c=>c.category===el).map(t=>{
                                return (
                                  <MenuItem value={t} onClick={()=>onChangeCollection(t.id)}>
                                    <div style={{width:24,height:24,marginRight:16}}>{getIconComponent({label:getIconLabel({label:'collection',item:t}),size:24,colour:theme.palette.primaryText.light})}</div> 
                                    {t.name}
                                  </MenuItem>
                                )
                              })
                            }
                          </div>
                          :
                          undefined
                        ))
                      }
                    </Select>
                  </div>
                  <Typography className={classes.helperText + (state.addRuleSelectedCollection==='none'?' '+classes.errorText:'')}>Required</Typography>
                </div>
              </div>
            }
            
            {
              !isEdit &&
              <div className={classes.inputSection}>
                <Typography className={classes.inputTitle}>Instance</Typography>
                <div className={classes.inputContent}>
                  <div style={{width:'100%',display:'flex'}}>
                    <Select
                      value={state.addRuleSelectedInstance}
                      className={classes.selector + (state.addRuleSelectedInstance==='none'?' '+classes.errorBorder:'')}
                      onChange={onChangeInstance}
                      style={{flexGrow:1}}
                      disableUnderline
                    >
                      <MenuItem value='none'>
                        Select an instance
                      </MenuItem>
                      {
                        state.addRuleInstanceList && state.addRuleInstanceList.map(el=>(
                          <MenuItem value={el.id}>
                            {el.name}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </div>
                  <Typography className={classes.helperText + (state.addRuleSelectedInstance==='none'?' '+classes.errorText:'')}>Required</Typography>
                </div>
              </div>
            }

            {
              !isEdit &&
              <div className={classes.inputSection}>
                <Typography className={classes.inputTitle}>Item to link</Typography>
                <div className={classes.inputContent}>
                  <div style={{width:'100%',display:'flex'}}>
                    <Select
                      value={state.addRuleSelectedObjectType}
                      className={classes.selector + (state.addRuleSelectedObjectType==='none'?' '+classes.errorBorder:'')}
                      onChange={onChangeObjectType}
                      style={{flexGrow:1}}
                      disableUnderline
                    >
                      <MenuItem value='none'>
                        Select a data & content item that can be linked to this instance
                      </MenuItem>
                      {
                        state.dataTypes.map(el=>(
                          <MenuItem value={el}>
                            {toTitleCase(mapObjectName(el,true).replace(/_/g,' '))}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </div>
                  <Typography className={classes.helperText + (state.addRuleSelectedObjectType==='none'?' '+classes.errorText:'')}>
                    Required
                  </Typography>
                </div>
              </div>
            }   

            <div className={classes.inputSection}>
              <Typography className={classes.inputTitle}>Regex rule</Typography>
              <div className={classes.inputContent} style={{display:'flex',flexDirection:'column'}}>
                <div style={{width:'100%',display:'flex'}}>
                  <InputBase
                    className={classes.inputBase + (state.addRuleRegex.trim()==='' || state.addRuleRegexValidation.error ?' '+classes.errorBorder:'') + (state.addRuleRegexValidation.validated?' '+classes.successBorder:'')}
                    value={state.addRuleRegex}
                    style={{flexGrow:1}}
                    onChange={onChangeRegex}
                    placeholder={"Enter a regex rule"}
                    multiline={true}
                    rows={5}
                  />
                </div>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                  <Typography className={classes.helperText + (state.addRuleRegex.trim()==='' || state.addRuleRegexValidation.error || state.addRuleRegexValidation.warning ?' '+classes.errorText:'') + (state.addRuleRegexValidation.validated?' '+classes.successText:'')}>
                    {state.addRuleRegexValidation.error || state.addRuleRegexValidation.warning || (state.addRuleRegexValidation.validated?'Regex validated':'Required. Click validate to test the rule before saving')}
                  </Typography>
                  <Button color='secondary' style={{fontSize:13.75,marginLeft:16,padding:'2px 6px'}} disabled={isValidatedButtonDisbaled()} onClick={onValidateRegex}>
                    {
                      validating && 
                      <CircularProgress color='secondary' style={{width:16,height:16,marginRight:8}}/>
                    }
                    {
                      validating?
                      'VALIDATING':
                      'VALIDATE'
                    }
                    
                  </Button>
                </div>
                <div style={{marginTop:16,flexGrow:1,overflow:'auto'}}>
                  <Typography className={classes.subHeader}>
                    {
                      state.addRuleRegexValidation.validated ?
                      `${state.addRuleRegexValidation.items.length} SAMPLE RESULTS (OF ${state.addRuleRegexValidation.total} MATCHES)`:
                      'SAMPLE RESULTS'
                    }
                  </Typography>
                  {
                    !state.addRuleRegexValidation.validated && 
                    <Typography style={{marginTop:4,fontSize:13.75,color:theme.palette.primaryText.light}}>Add a rule and click Validate to see a sample of items that will be linked. </Typography>
                  }
                  {
                    state.addRuleRegexValidation.items && state.addRuleRegexValidation.items.map(el=>(
                      <div style={{display:'flex',borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,alignItems:'center',padding:16}}>
                        {getIconComponent({label:state.addRuleSelectedObjectType,size:24,colour:theme.palette.primary.main})}
                        <Typography color='primary' style={{marginLeft:16,fontSize:16,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{el.name}</Typography>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>

          </div>
          <div className={classes.buttons}>
            <Button color='primary' style={{marginRight:24}} onClick={onAddRule} disabled={!(state.addRuleRegexValidation.validated||state.addRuleRegexValidation.warning)}>{isEdit?'SAVE':'ADD'}</Button>
            <Button color='secondary' onClick={onCancel}>CANCEL</Button>
          </div>
        </Paper>
        <ModalAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          type='error'
        />
      </div>
      
    </Modal>
  )
}


export default withTheme()(withStyles(styles)(AddRuleModal));