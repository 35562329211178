import React from 'react';
import { withStyles} from '@material-ui/core';
import Merge from '../Merge/Merge'
import CreateMerge from '../CreateMerge/CreateMerge'

const styles = theme => ({
  root: {

  }
})

const Body = props => {

  const {
    classes,
    state,
    dispatch,
    history
  } = props;

  switch (state.tabState) {
    case -1:
      return <CreateMerge state={state} dispatch={dispatch}/>
    case 0:
      return <Merge history={history} state={state} dispatch={dispatch}/>
    default:
  }

  return (
    <div className={classes.root}>
    </div>
  )
}

export default withStyles(styles)(Body);