import axiosCerebrum from "../../../axios-cerebrum";

export const waitForHostCreated = async (hostID, iteration = 1) => {
  if(iteration>10){
    return true;
  }
  let host;
  await axiosCerebrum
          .get(`/api/hosts/${hostID}`)
          .then((response) => {
            host = response.data;
          });
  if(!host){
    // wait for 2 second
    await new Promise(resolve => setTimeout(resolve, 2000));
    await waitForHostCreated(hostID, iteration+1);
  }
  return true;
}