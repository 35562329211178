import React, { } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import { getIconComponent, mapObjectName } from '../../../utilities';

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.primaryText.main,
    marginBottom:16
  },
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:1.5,
    marginTop:36,
    marginBottom:16
  },
  objectChip:{
    height:48,
    display:'flex',
    alignItems:'center',
    paddingLeft:16
  },
  objectText:{
    fontSize:16,
    marginLeft:16
  }
})

function ReviewTemplate(props) {

  const {
    theme,
    classes,
    properties,
    state,
    selectedTypes
    // onClickCreate
  } = props;

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Review & create template</Typography>
      <Typography className={classes.sectionHeader}>ITEMS TO EDIT</Typography>
      {
        state.objectCount && state.objectCount.filter(c=>selectedTypes.includes(c.objectType)).map(el=>(
          <div className={classes.objectChip}>
            {getIconComponent({label:el.objectType,size:24,colour:theme.palette.primaryText.light})}
            <Typography className={classes.objectText}>{el.count} {mapObjectName(el.objectType).replace(/_/g,' ').toLowerCase()}(s)</Typography>
          </div>
        ))
      }
      <Typography className={classes.sectionHeader} style={{marginTop:40}}>PROPERTIES TO EDIT</Typography>
      {
        properties.map(el=>(
          <div className={classes.objectChip}>
            {getIconComponent({label:'info',size:24,colour:theme.palette.primaryText.light})}
            <Typography className={classes.objectText}>{el.name_txt}</Typography>
          </div>
        ))
      }
      {/* <Button onClick={onClickCreate} color='primary' variant='contained' style={{marginTop:40}}>
        CREATE TEMPLATE
      </Button> */}
    </div>
  )
}

ReviewTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ReviewTemplate));