import React, { useEffect, useReducer } from 'react';
import { withStyles, LinearProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body from '../../components/CustomerEcosystem/Body/Body';
// import axiosCerebrum from '../../axios-cerebrum';
import TabBar from '../../components/UI/TabBar/TabBar';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import 'url-search-params-polyfill';
import { setInitialState, removeUrlQueryArg } from '../../utilities'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
// import timezoneDetect from 'jstimezonedetect';
import { addHistory } from '../../HistoryManager';
import { CerebrumLongListLoader } from '../../LongListLoader';

const styles = theme => ({
  button: {
    margin: '8px 0px 8px 16px',
    height: '3rem',
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
});


const initialState = {
    tabState: 0,
    discoverTabState:0,
    sourceTabState:0,
    sourceView:'cards',
    lineageData:{},
    usageData:{},
    reportsView:'new',
    glossaryView:'new',
    collectionView:'platform',
    listView:'new',
    issueView:'new',
    tagView:'new',
    sourceSearchValue:{},
    sourcesSort:{}
  }

const reducer = (state, action) => {
  switch (action.type) {
    case "set_tab_state":
      return {
        ...state,
        tabState: action.tabState
      }
    //////// source tab
    case 'set_source_tab_state':
      return {
        ...state,
        sourceTabState:action.sourceTabState
      }
    case 'set_source_search_value':
      return {
        ...state,
        sourceSearchValue:action.sourceSearchValue
      }
    case 'set_sources_sort':
      return {
        ...state,
        sourcesSort:action.sourcesSort
      }
    case 'set_source_view':
      return {
        ...state,
        sourceView:action.sourceView
      }
    case 'set_source_list':
      return {
        ...state,
        sourceList:action.sourceList,
        sourceListLoading:action.sourceListLoading,
        sourceListError:action.sourceListError
      }
    case 'set_hosts':{
      return {
        ...state,
        hosts:action.hosts,
        hostLoading:action.hostLoading,
        hostError:action.hostError
      }
    }
    case 'set_reference_sources':{
      return {
        ...state,
        referenceSources:action.referenceSources,
        referenceSourcesLoading:action.referenceSourcesLoading,
        referenceSourcesError:action.referenceSourcesError
      }
    }
    //////// map tab
    case 'set_db_list':
      return {
        ...state,
        dbList:action.dbList,
        dbListLoading:action.dbListLoading,
        dbListError:action.dbListError
      }
    case 'set_map_root_object':
      return {
        ...state,
        mapRootObject:action.mapRootObject,
      }
    case 'set_map_data':
      return {
        ...state,
        mapData:action.mapData
      }
    case 'set_map_show_minimap':
      return {
        ...state,
        mapShowMiniMap:action.mapShowMiniMap
      }
    case 'set_domain_map_data':{
      return {
        ...state,
        domainMapData:action.domainMapData,
        domainMapLoading:action.domainMapLoading,
        domainMapError:action.domainMapError
      }
    }
    // case 'set_grid_nodes':
    //   return {
    //     ...state,
    //     gridNodes:action.gridNodes
    //   }
    // case 'set_grid_links':
    //   return {
    //     ...state,
    //     gridLinks:action.gridLinks
    //   }
    // case 'set_grid_show_inactive':
    //   return {
    //     ...state,
    //     gridShowInactive: action.gridShowInactive
    //   }
    // case 'set_grid_loading':
    //   return {
    //     ...state,
    //     gridLoading:action.gridLoading
    //   }
    // case 'set_grid_error':
    //   return {
    //     ...state,
    //     gridError:action.gridError
    //   }
    //// discover tab
    case "set_discover_tab_state":
      return {
        ...state,
        discoverTabState: action.discoverTabState
      }
    case 'set_reports_data':
      return {
        ...state,
        reportsData:action.reportsData,
        reportsError:action.reportsError,
        reportsLoading:action.reportsLoading
      }
    case 'set_reports_view':
      return {
        ...state,
        reportsView:action.reportsView
      }
    case 'set_glossary_data':
      return {
        ...state,
        glossaryData:action.glossaryData,
        glossaryError:action.glossaryError,
        glossaryLoading:action.glossaryLoading
      }
    case 'set_glossary_view':
      return {
        ...state,
        glossaryView:action.glossaryView
      }
    case 'set_collection_data':
      return {
        ...state,
        collectionData:action.collectionData,
        collectionError:action.collectionError,
        collectionLoading:action.collectionLoading
      }
    case 'set_collection_view':
      return {
        ...state,
        collectionView:action.collectionView
      }
    case 'set_list_data':
      return {
        ...state,
        listData:action.listData,
        listError:action.listError,
        listLoading:action.listLoading
      }
    case 'set_list_view':
      return {
        ...state,
        listView:action.listView
      }
    case 'set_issue_data':
      return {
        ...state,
        issueData:action.issueData,
        issueError:action.issueError,
        issueLoading:action.issueLoading
      }
    case 'set_issue_view':
      return {
        ...state,
        issueView:action.issueView
      }
    case 'set_tag_data':
      return {
        ...state,
        tagData:action.tagData,
        tagError:action.tagError,
        tagLoading:action.tagLoading
      }
    case 'set_tag_view':
      return {
        ...state,
        tagView:action.tagView
      }
    default:
      throw new Error("Action type not supported");
  }
}

const CustomerEcosystem = props => {


  const {
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache
  } = props;

  // const isDemo = localStorage.hasOwnProperty('demo');

  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName')
  const discoverTabName = urlSearch.get('discoverTabName')
  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  // eslint-disable-next-line
  },[state])

  let tabOptions = ['SOURCES','MAP','DOMAIN MAP','DISCOVER'];

  const loadSources = () => {

    CerebrumLongListLoader({
      url: '/api/sources',
      params: {
        per_page:200,
        types:'TOOL,DATABASE,INTERNAL',
        sort:'ALPHABETICAL',
        active_flag:true
      },
      onStartLoad: () => {
        dispatch({
          type:'set_db_list',
          dbListLoading:true
        })
      },
      onFinishLoad: ({data}) => {
        dispatch({
          type:'set_db_list',
          dbList:data
        })
      },
      onError: (error) => {
        console.log(error)
        dispatch({
          type:'set_db_list',
          dbListError:true
        })
      }
    })
    
  }

  const hostFetchList = () => {
    CerebrumLongListLoader({
      url: '/api/hosts',
      params: {
        reference:false,
        sort:'ALPHABETICAL',
      },
      per_page:200,
      onStartLoad: () => {
        dispatch({
          type:'set_hosts',
          hostLoading:true,
        })
      },
      onFinishLoad: ({data}) => {
        dispatch({
          type:'set_hosts',
          hosts:data
        })
      },
      onError: (error) => {
        console.log(error)
        dispatch({
          type:'set_hosts',
          hostError:true
        })
      }
    })
  }

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])

  useEffect(()=>{
    if(tabName){
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
      dispatch({type:'set_tab_state',tabState:Math.max(0,tabOptions.indexOf(tabName))})
    }
    if(discoverTabName){
      let discoverTabOptions = ['REPORTS','TERMS','COLLECTIONS','LISTS','ISSUES','TAGS']
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['discoverTabName']}));
      dispatch({type:'set_discover_tab_state',discoverTabState:Math.max(0,discoverTabOptions.indexOf(discoverTabName))})
    }
  // eslint-disable-next-line
  },[tabName,discoverTabName])
  


  useEffect(()=>{
    if(!state.dbList)loadSources()
    if(!state.hosts)hostFetchList()
    addHistory({url:window.location.pathname, iconLabel:'insights', title: 'My Ecosystem', subTitle:'My Ecosystem',type:'application'})
    // eslint-disable-next-line
  },[])


  if (state.dbListLoading || state.hostLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.dbListError || state.hostError ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.dbList || !state.hosts){
    return <div></div>
  }

  let buttons = []

  return (
    <ProfileLayout
      // headerMinHeight={110}
      // maxWidth={1160}
      hideHeader={tabOptions[state.tabState]==='MAP'}
      disableMinContentHeight={tabOptions[state.tabState]==='MAP'}
      header={(
        <div>
          <ProfileHeader
            title={'Ecosystem'}
            subtitle={' '}
            label='my_ecosystem'
            buttons={buttons}
            minHeight={100}
          />
        </div>)}
      tabBar={
        <TabBar
          tabOptions={tabOptions}
          tabState={state.tabState}
          setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
          disableUnderline={true}
          maxWidth={200}
        />
      }
      body={
        <Body
          history={history}
          dispatch={dispatch}
          state={state}
          sessionData={sessionData}
          tabOptions={tabOptions}
        />
      }
    />
  )
}


CustomerEcosystem.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  sessionData: PropTypes.object.isRequired
}


const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerEcosystem));