import React, { useEffect } from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import TextDataTile from './TilesComponents/TextDataTile';
import { removeMarkDown } from '../../InteractiveInput/Templates';

const styles = theme => ({
  dataBox: {
    marginBottom: '2rem'
  },
  avatar: {
    backgroundColor: theme.palette.avatar.main,
    width:24,
    height:24,
    fontSize:12
  },
})

function DescriptionTile(props) {

  const {
    object,
    onAddTileData,
    tileData,
    id
  } = props;

  const loadDescriptionTile = async () => {
    if(object.description && object.description.trim()!==''){
      onAddTileData(
        {
          "disp_body":{value:removeMarkDown(object.description)},
          "disp_title":"DESCRIPTION",
          "disp_type":"text",
          "id":id
        }
      )
    }
  }


  useEffect(()=>{
    if(!object)return;
    loadDescriptionTile()
  // eslint-disable-next-line
  },[object])
  
  if(!tileData)return <></>
  
  return (
    <div style={{marginBottom:16}}>
      <TextDataTile 
        title={tileData.disp_title}
        text={tileData.disp_body.value}
        keepSpace
      />
    </div>
    
  )

}

DescriptionTile.propTypes = {
  classes: PropTypes.object.isRequired,
  boxData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(DescriptionTile));