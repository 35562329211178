import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import CodeMirror from '../../Generic/Editor/CodeMirror';
import sqlFormatter from "sql-formatter";

const styles = theme => ({
  root: {
    '& .CodeMirror-cursor': {
      visibility: 'hidden'
    }
  }
})

function CodeDisplay(props) {

  const {
    classes,
    code,
    noStyle
  } = props;

  const [value, setValue] = React.useState(code);

  useEffect(()=>{
    if(noStyle)return;
    setTimeout(()=>{
      setValue(sqlFormatter.format(code,{language:'pl/sql'}))
    })
  // eslint-disable-next-line
  },[code])

  return (
     code !== undefined ? 
      <div className={classes.root}>
        <CodeMirror
          value={value}
          options={{
            readOnly: 'true',
          }}
          noStyle={noStyle}
        />
      </div> : 
      <div> </div> 
      
  )
}

CodeDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  code: PropTypes.string,
  noStyle: PropTypes.bool
}

export default withStyles(styles)(CodeDisplay);