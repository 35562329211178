import axiosCerebrum from '../../../../axios-cerebrum'
import { loadBySourceChart, loadByTrustChart, loadChangeStatusChart, loadTilesByObjectType, loadbyFrequencyChart, processTileData, sortByTotalUsage } from '../loadUtils';

export const loadTileData = async ({data, setData}) => {
  if(!data.tileData && !data.tileDataLoading){
    setData({...data,tileDataLoading:true,tileDataError:false});

    try{
      let objectTypes = ['REPORT','DATASET','DATA_PIPELINE','ML_MODEL'];
      let tileData = [];
      
      await loadTilesByObjectType({objectTypes}).then(facet=>{
        tileData = processTileData({facet, objectTypes})
      });

      setData({
        ...data,
        tileDataLoading:false,
        tileDataError:false,
        tileData,
        selectedTile:tileData[0]?.id
      })
    }catch(error){
      console.log(error);
      setData({...data,tileDataError:true,titleDataLoading:false,tileData:undefined});
    }

  }
}

export const loadGraphData = async ({data,setData,state, dispatch,dbList,history,apiSeq,apiSeqRef}) => {
  setData({
    ...data,
    [`${data.selectedTile}_graphDataLoading`]:true,
    [`${data.selectedTile}_graphDataError`]:false,
  });

  try{
    if(['REPORT','DATASET','DATA_PIPELINE','ML_MODEL'].includes(data.selectedTile)){

      let objectType = data.selectedTile;
      let chartsData = {}

      let promises = [
        loadChangeStatusChart({objectType, history, state}).then(d=>chartsData[`${objectType}_BY_CHANGE`] = d),
        loadBySourceChart({objectType,state, dbList, dispatch ,history}).then(d=>chartsData[`${objectType}_BY_SOURCE`] = d),
        loadbyFrequencyChart({objectType, history, state}).then(d=>chartsData[`${objectType}_BY_FREQUENCY`] = d),
        loadByTrustChart({objectType, history, state}).then(d=>chartsData[`${objectType}_BY_TRUST`] = d),
      ]

      await Promise.all(promises)

      let sourceIds = [
        // 1000,
        ...dbList
            .filter(el=>el.source_template.type==='TOOL' || el.source_template.name==='REFERENCE')
            // .sort((a,b)=>{
            //   let valueA = tablesUsedBySourceMeasures.find(el=>el.source_id===a.id)?tablesUsedBySourceMeasures.find(el=>el.source_id===a.id).value:0;
            //   let valueB = tablesUsedBySourceMeasures.find(el=>el.source_id===b.id)?tablesUsedBySourceMeasures.find(el=>el.source_id===b.id).value:0;
            //   return valueB-valueA
            // })
            .map(el=>el.id)
      ];
      
      let perDayMeasure;
      if(['REPORT','DATASET','DATA_PIPELINE'].includes(objectType)){
        let cerebrumLabel;
        if(objectType==='REPORT')cerebrumLabel='CONTENT';
        if(objectType==='DATASET')cerebrumLabel='DATASETS';
        if(objectType==='DATA_PIPELINE')cerebrumLabel='DATA_PIPELINES';
        if(sourceIds.length>0){
          await axiosCerebrum
            .get(
              `/api/metrics`,
              {params:{
                measures:`${cerebrumLabel}_USED_1D`,
                timeseries_flag:true,
                source_ids:sourceIds.join(','),
                period:30,
                per_page:sourceIds.length,
              }}
            )
            .then(response=>{
              perDayMeasure = response.data.items
            })
          if(perDayMeasure.length>0 ){
            chartsData[`${objectType}_USED_PER_DAY`] = {
              series:perDayMeasure.map(el=>({
                name:el.source_id+''==='1000'?'All Sources':dbList.find(d=>d.id+''===el.source_id+'').name,
                source_id:el.source_id,
                data:el.dataset.y,
                labels:el.dataset.x
              })).sort((a,b)=>a.name<b.name?-1:1)
            }
          }
        }
      }
      if(apiSeq!==apiSeqRef.current)return;
      let newDataObj = {}
      Object.keys(data).filter(el=>!el.includes('_graphDataLoading') &&  !el.includes('_graphDataError')).forEach(k=>{
        newDataObj[k] = data[k]
      })
      setData({
        ...newDataObj,
        [`${data.selectedTile}_graphDataLoading`]:false,
        [`${data.selectedTile}_graphDataError`]:false,
        [`${data.selectedTile}_graphData`]:chartsData,
        [`${data.selectedTile}_line_compared_sources`]:perDayMeasure?sourceIds.sort((a,b)=>sortByTotalUsage(perDayMeasure.find(p=>p.source_id===a), perDayMeasure.find(p=>p.source_id===b) )).filter(el=>el!==1000).slice(0,5):[],
        [`${data.selectedTile}_stacked_compared_sources`]:perDayMeasure?sourceIds.sort((a,b)=>sortByTotalUsage(perDayMeasure.find(p=>p.source_id===a), perDayMeasure.find(p=>p.source_id===b) )).filter(el=>el!==1000).slice(0,5):[],
      })
    }
  }catch(error){
    console.log(error);
    if(apiSeq!==apiSeqRef.current)return;
    setData({
      ...data,
      [`${data.selectedTile}_graphDataLoading`]:false,
      [`${data.selectedTile}_graphDataError`]:true,
      [`${data.selectedTile}_graphData`]:undefined,
    })
  }
}
