import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
// import CodeMirror from 'codemirror';
import { Controlled as ReactCodeMirror } from 'react-codemirror2';
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/sql-hint';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/closebrackets';


const styles = theme => ({
  plainText:{
    position: 'relative',
    '& .CodeMirror *':{
      fontSize: theme.typography.body1.fontSize,
    },
    '&  .CodeMirror-selected': {
      background:theme.palette.textSelectedBackground.main
    },
    '& .cm-m-sql':{
      color:theme.palette.primaryText.main
    },
    '& .cm-variable-2':{
      color:theme.palette.primaryText.main
    },
    '& .cm-keyword':{
      color:theme.palette.primaryText.main
    },
    '&  .CodeMirror': {
      height: 'auto',
      backgroundColor: 'transparent',
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit
    },
    '&  .CodeMirror .CodeMirror-gutters': {
      backgroundColor: 'transparent',
    },
    '& .CodeMirror-scroll': {
      overflow: 'auto !important',
    },
    '&  .CodeMirror-gutters': {
      border: 0,
    },
    '&  .CodeMirror-linenumber': {
      display:"none",
      color: '#1888E0',
    },
    '& .CodeMirror-line':{
      paddingLeft:`0 !important`
    },
    '& .CodeMirror span.CodeMirror-matchingbracket':{
      color:theme.palette.primaryText.main
    }
  },
  codeMirrorWrapper: {
    position: 'relative',
    '&  .CodeMirror *': {
      fontSize: theme.typography.body1.fontSize,
      fontFamily: 'Roboto Mono',
    },
    '& .cm-m-sql':{
      color:theme.palette.primaryText.main
    },
    '& .cm-variable-2':{
      color:theme.palette.primary.main
    },
    '& .cm-keyword':{
      color:theme.palette.secondary.main
    },
    '&  .CodeMirror': {
      height: 'auto',
      backgroundColor: 'transparent',
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit
    },
    '&  .CodeMirror-selected': {
      background:theme.palette.textSelectedBackground.main
    },
    '&  .CodeMirror .CodeMirror-gutters': {
      backgroundColor: 'transparent',
    },
    '&  .CodeMirror-linenumbers': {
      // backgroundColor: '#FFF',
    },
    '& .CodeMirror-scroll': {
      overflow: 'auto !important',
    },
    '&  .CodeMirror-gutters': {
      border: 0,
    },
    '&  .CodeMirror-linenumber': {
      color: '#1888E0',
    },
  },
});

class Top extends Component {

  render() {

    const { classes, className, options, noStyle } = this.props;

    const defaultOptions = {
      lineNumbers: true,
      mode: 'text/x-pgsql',
      tabSize: 2,
      readOnly: false,
      extraKeys: {
        'Ctrl-Space': 'autocomplete',
      },
      highlightSelectionMatches: {
        trim: true
      },
      lineWrapping: true,
      matchBrackets: true,
      addModeClass: true,
      viewportMargin: 100,
      scrollbarStyle: 'null'
      // hintOptions: {
      //   hint: CodeMirror.hint.sql,
      //   tables: this.this.props.autocompleteTables
      // }
    };

    return (
      <ReactCodeMirror
        {...this.props}
        className={classnames(noStyle?classes.plainText:classes.codeMirrorWrapper, className)}
        editorDidMount={editor => { this.instance = editor; this.instance.refresh(); setTimeout(() => this.instance.refresh(), 2000) }}
        options={{...defaultOptions, ...options}} />
    )
  }
}

export default withStyles(styles)(Top);