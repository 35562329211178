import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Button } from '@material-ui/core';
import UpdateInputCerebrum from '../../UI/UpdateInput/UpdateInputCerebrum';
import timezoneDetect from 'jstimezonedetect';
import AdditionalPropertyAdder from '../../UI/AdditionalPropertyAdder/AdditionalPropertyAdder'
import AccessAdder from '../../UI/AccessAdder/AccessAdder'
import GlossaryAdder from '../../UI/GlossaryAdder/GlossaryAdder';
import SourceDescription from '../../UI/SourceDescription/SourceDescription';
import theme from '../../../theme';
import CodeDisplay from '../../UI/CodeDisplay/CodeDisplay';
import sqlFormatter from "sql-formatter";
import useAlert from '../../../hooks/useAlert';
import { copyToClipboard } from '../../../utilities';

const styles = theme => ({

})

const Details = props => {

  const {
    history,
    fetchList,
    profileState,
    profileDispatch,
    isShell
  } = props;


  const {
    sendAlert
  } = useAlert({})

  const handleCodeClick = () => {
    copyToClipboard(sqlFormatter.format(profileState.basicData.codeData.code,{language:'pl/sql'}))
    sendAlert({message:"Copied code to clipboard",type:'info'})
  }
  
  return (
    <div>
      <UpdateInputCerebrum
        url={`/api/tables/${profileState.basicData.id}?tz=${timezoneDetect.determine().name()}`}
        label="table"
        initialValue={profileState.basicData.description}
        property="description"
        placeholder="Add a description"
        multiline
        data={profileState.basicData}
        fetchList={fetchList}
        collection="data"
        enableMarkDown={true}
        enableWidget={true}
        state={profileState}
      />

      <SourceDescription
        state={profileState}
        dispatch={profileDispatch}
      />
      
      <div style={{ marginTop: 60 }} >
        <GlossaryAdder
          state={profileState}
          dispatch={profileDispatch}
          history={history}
          disableModal
        />
      </div>

      {
        !isShell && 
        <div style={{ marginTop: 60 }} >
          <AdditionalPropertyAdder
            object={profileState.basicData}
            state={profileState}
            dispatch={profileDispatch}
            modalOpen={profileState.additionalPropertiesModalOpen}
            setModalOpen={value=>profileDispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:value})}
          />
        </div>
      }
      
      {
        !isShell && 
        <div style={{ marginTop: 60 }} >
          <AccessAdder
            object={profileState.basicData}
            state={profileState}
            dispatch={profileDispatch}
            modalOpen={profileState.accessModalOpen}
            setModalOpen={value=>profileDispatch({type:'set_access_modal_open',accessModalOpen:value})}
          />
        </div>
      }


      {
        profileState.basicData.codeData?.code && 
        <div style={{marginTop:60}}>  
          <div style={{display:'flex',alignItems:'flex-start',marginBottom: 24}}>
            <Typography style={{ fontSize: 20,color:theme.palette.header.main}}>CODE</Typography>
            <Button style={{marginLeft:16,marginTop:-2}} color='primary' onClick={handleCodeClick}>COPY</Button>
          </div>
          <CodeDisplay
            code={profileState.basicData.codeData.code}
          />
        </div> 
      }

    </div>

  )
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  userName: PropTypes.string,
  tableData: PropTypes.object,
  profileState: PropTypes.object.isRequired,
  profileDispatch: PropTypes.func.isRequired
}

export default withStyles(styles)(Details);