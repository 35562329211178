import axios from 'axios';
import keycloakInstance from './keycloak-instance';
import timezoneDetect from 'jstimezonedetect';
// export the basic axios cerebrum instance, with no tokens

	//console.log(store);
	// export const redashURL = document.location.protocol + "//" +  document.location.hostname;
	let cerebrumURL = document.location.protocol + "//" + document.location.hostname;

	const axiosCerebrum = axios.create({
			baseURL: cerebrumURL,
	});
	
	let CancelToken = axios.CancelToken;
	axiosCerebrum.interceptors.request.use(
    (
        config => {
					config.params = config.params || {};
					config.params['tz'] = timezoneDetect.determine().name();
					if(new Date(keycloakInstance.refreshTokenParsed.exp * 1000).getTime()<new Date().getTime()){
            window.open(keycloakInstance.createLogoutUrl(), "_self")
						return {
							...config,
							cancelToken: new CancelToken((cancel) => cancel())
						};
					}
				//if(process.env.NODE_ENV === "development")return config;
					return keycloakInstance.updateToken(5) //set it to 1800 to test refresh token process
						.then(refreshed=>{
							config.headers['Authorization'] = 'Bearer '+keycloakInstance.token;
							return config
					})
			}
    )
);


export default axiosCerebrum;