import React, { } from 'react';
import { withStyles } from '@material-ui/core';
import LandingBody from '../Landing/LandingBody/LandingBody'
import MainSearchBody from '../MainSearch/MainSearchBody/MainSearchBody'

const styles = theme => ({
  // root:{
    // width:'100%',
    // paddingLeft:80,
    // paddingRight:80,
    // boxSizing:'border-box',
  // },
  // '@media (max-width: 1350px)': {
  //   root:{
  //     paddingLeft:40,
  //     paddingRight:40,
  //   },
  // },
});



const Body = props => {

  const {
    history,
    classes,
    state,
    dispatch,
    sessionData,
    isNotSearchPage,
    onChangeTab, 
    loadSearchResults,
    onSearch,
    onChangePage,
    onUpdateFilter,
    onResetAllFilters,
    getDefaultParams,
    onUpdateSort,
    tabGrouping,
    hiddenComponents,
    addtioonalComponents,
    removeContainerStyle,
    customHeaderFormatter,
    resultItemVariant,
    alwaysOpenNewTab,
    isCreateFilter,
    indexName,
    customEmptyMsg,
    forceGlobalFilterStr,
    forceGlobalQueryStr,
    forceOnItemClick
  } = props;

  let body
  switch(state.view){
    case 'landing':
      body = <LandingBody state={state} dispatch={dispatch} history={history} onSearch={onSearch} indexName={indexName}/>
      break;
    case 'main_search':
      body = (
        <MainSearchBody 
          state={state} 
          dispatch={dispatch} 
          sessionData={sessionData}
          isNotSearchPage={isNotSearchPage}
          history={history} 
          onSearch={onSearch} 
          onChangeTab={onChangeTab} 
          onChangePage={onChangePage} 
          onUpdateFilter={onUpdateFilter}
          onUpdateSort={onUpdateSort}
          onResetAllFilters={onResetAllFilters}
          loadSearchResults={loadSearchResults}
          tabGrouping={tabGrouping}
          getDefaultParams={getDefaultParams}
          hiddenComponents={hiddenComponents}
          addtioonalComponents={addtioonalComponents}
          removeContainerStyle={removeContainerStyle}
          customHeaderFormatter={customHeaderFormatter}
          resultItemVariant={resultItemVariant}
          alwaysOpenNewTab={alwaysOpenNewTab}
          isCreateFilter={isCreateFilter}
          indexName={indexName}
          customEmptyMsg={customEmptyMsg}
          forceGlobalFilterStr={forceGlobalFilterStr}
          forceGlobalQueryStr={forceGlobalQueryStr}
          forceOnItemClick={forceOnItemClick}
        />
      )
      break
    default:
  }

  return (
    <div className={classes.root}>
      {body}
    </div>
  )
}

export default withStyles(styles)(Body);
