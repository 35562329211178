import React, { } from 'react';
import { withTheme, withStyles, Button} from '@material-ui/core';
import InteractiveInputBody from '../../UI/InteractiveInput/InteractiveInputBody';


const styles = theme => ({
})

const AddInstruction = props => {

  const {
    classes,
    setActiveStep,
    instructions, 
    setInstructions
  } = props;


  
  return (
    <div className={classes.root}>
      <InteractiveInputBody
        placeholder="Add instructions"
        initialValue={instructions}
        onChange={(value)=>setInstructions(value)}
        disableWidget={true}
        
      />
      <Button onClick={()=>setActiveStep(1)} data-test-id='instruction-next-button' style={{marginTop:24}} color='primary' variant='contained' disabled={instructions.trim()===''}>NEXT</Button>
    </div>
  )
}

export default withTheme()(withStyles(styles)(AddInstruction));