import React, {  } from 'react';
import {withTheme, withStyles, InputBase, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getIconComponent } from '../../../utilities';


const styles = theme => ({

  inputBase:{
    ...theme.components.inputBase,
    border:'none',
    borderBottom:`1px solid ${theme.palette.border.main}`,
    width:'100%',
    marginBottom:4,
    marginTop:-8,
    height:40,
    minWidth:240
  },
})


const FilterSearchInput = props => {

  const {
    theme,
    classes,
    searchValue, 
    setSearchValue,
    placeholder
  } = props;

  return (
    <InputBase 
      onClick={event=>{event.stopPropagation();event.preventDefault()}}
      inputProps={{
        onClick:event=>{
          event.stopPropagation();event.preventDefault()
        },
        'data-test-id':"link-modal-filter-search"
      }}
      endAdornment={
        <IconButton data-test-id="link-modal-filter-search-clear" disabled={!searchValue || searchValue.trim()===''} onClick={(event)=>{setTimeout(()=>setSearchValue(''));event.preventDefault();event.stopPropagation();}} style={{padding:4,marginRight:8}}>
          {getIconComponent({label:!searchValue || searchValue.trim()===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
        </IconButton>
      }
      value={searchValue}
      onChange={event=>{
        setSearchValue(event.target.value)
      }}
      placeholder={placeholder}
      className={classes.inputBase}
    />
  )

}

FilterSearchInput.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  history:PropTypes.object.isRequired,
  selectedObjectType: PropTypes.string.isRequired,
  selectedRelation: PropTypes.string.isRequired,
}


export default withTheme()(withStyles(styles)(FilterSearchInput));