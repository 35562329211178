import React from 'react';
import { withStyles} from '@material-ui/core';
import Details from '../Details/Details'
import Dashboard from '../../UI/Dashboard/DirectIndirectDashboard/DirectIndirectDashboard';
// import ChangeAdder from '../../UI/ChangeAdder/ChangeAdder'
import NoteAdder from '../../UI/NoteAdder/NoteAdder'
import Issues from '../../UI/ProfileIssueSection/Issues';
import NewLineageContainer from '../../UI/Lineage/NewLineageContainer'
import ChildList from '../ChildList/ChildList';
import ChangeManager from '../../UI/ChangeManager/ChangeManager';
import DQInsights from '../../UI/DQInsights/DQInsights';

const styles = theme => ({
})

function Body(props) {

  const {
    history,
    tabOptions,
    state,
    dispatch,
    sessionData,
    fetchList,
    setLinkModalOpen,
    isLineageLinkable
  } = props;

  let bodyContent;

  switch(tabOptions[state.tabState]){
    case 'TABLES':
      bodyContent=(
        <ChildList
          history={history}
          state={state}
          dispatch={dispatch}
          sessionData={sessionData}
        />
      )
      break;
    case 'DETAILS':
      bodyContent=(
        <Details
          history={history}
          state={state}
          dispatch={dispatch}
          sessionData={sessionData}
          fetchList={fetchList}
          setLinkModalOpen={setLinkModalOpen}
        />
      )
      break;
    case 'KNOWLEDGE':
      bodyContent = (
        <NoteAdder
          history={history}
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.noteModalOpen}
          setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
        />
      )
      break;
    case 'USAGE': // summary
      bodyContent = (
        <Dashboard
          history={history}
          state={state}
          label='dataset'
          dispatch={dispatch}
          drillThrough={true}
        />
      )
      break;  
    case 'LINEAGE':
      bodyContent=(
        <NewLineageContainer
          history={history}
          state={state}
          dispatch={dispatch}
          isLinkable={isLineageLinkable}
        />
      )
      break;
    case 'QUALITY':
      bodyContent = (
        <DQInsights
          state={state}
          history={history}
          dispatch={dispatch}
          object={state.basicData}
        />
      )
      break;
    case 'ISSUES':
      bodyContent=(
        <Issues
          history={history}
          state={state}
          dispatch={dispatch}
        />
      )
      break;
    case 'CHANGES':
      bodyContent = (
        <ChangeManager state={state} dispatch={dispatch} history={history}/>
      )
      break;
    default:

  }

  return <div>{bodyContent}</div>;
}

export default withStyles(styles)(Body);