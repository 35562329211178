import React, {useEffect, useState, useRef} from 'react';
import { withStyles, Typography } from '@material-ui/core';
import {loadTileData, loadGraphData} from './dataLoader'
import { generateDataTiles, generateChartGroup, generateRecommendationList } from './componentGenerator'
import { scrollIntoElemenet } from '../../../utilities';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';

const styles = theme => ({
  chartContainer:{
    width:'50%',
    flexGrow:1,
    boxSizing:'border-box',
    padding:16,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    borderRadius:4,
    marginBottom:24
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  captionText:{
    fontSize:12,
    color:theme.palette.primaryText.light
  },
  chartTitle:{
    fontSize:16,
    color:theme.palette.header.main,
  },
  chartSubtitle:{
    fontSize:12,
    color:theme.palette.primaryText.light
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  subTitle:{
    color:theme.palette.primaryText.light,
    fontSize:13.75
  },
  tooltip:{
    fontSize:13.75,
    whiteSpace:'pre-wrap'
  },
  hidden:{
    display:'none'
  },
  header:{
    fontSize:20,
    color:theme.palette.primaryText.main,
  },
  subHeader:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:8
  },
  recommendationContainer:{
    height:48,
    display:'flex',
    alignItems:'center',
    padding:'0px 16px',
    overflow:'hidden',
    maxWidth:800,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    cursor:"pointer",
    '&:hover':{
      backgroundColor:theme.palette.hovered.main
    }
  },
  recommendationTitle:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    flexGrow:1,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginRight:16
  },
  recommendationCount:{
    height:32,
    borderRadius:16,
    fontSize:13,
    backgroundColor:theme.palette.primary.dark,
    color:theme.palette.background.main,
    display:'flex',
    alignItems:'center',
    padding:'0px 8px',
  },
})

const Issue = props => {

  const {
    classes,
    state,
    dispatch,
  } = props;

  let data = state.issueData;
  let setData = d => dispatch({type:'set_issue_data',issueData:d})

  const [viewTabState, setViewTabState] = useState(0)

  const [transformTop, setTransformTop] = useState(0)
  const transformTimeout = useRef()

  window.onscroll = event => {
    if(window.scrollY>1100){
      setViewTabState(1)
    }
    else{
      setViewTabState(0)
    }
    clearTimeout(transformTimeout.current)
    setTransformTop(Math.max(0,window.scrollY-16))
  }

  useEffect(()=>{
    loadTileData({data, setData});
    // eslint-disable-next-line
  },[state.usageSource])

  useEffect(()=>{
    if(!data || !data.tileData || data[`${data.selectedTile}_graphDataError`])return;
    loadGraphData({data,setData,dbList:state.dbList})
    // eslint-disable-next-line
  },[data])

  return (
    <div style={{display:'flex'}}>
      <div style={{flex:'0 0 200px',height:200,transform:`translate(0px,${transformTop}px)` }}>
        <VerticalTabBar
          tabOptions={['INSIGHTS',data?.selectedTile==='CLOSED'?undefined:'RECOMMENDATIONS'].filter(el=>el)}
          tabState={viewTabState}
          setTabState={value=>{
            if(value===0)window.scrollTo({top:0,behavior:'smooth'})
            if(value===1)scrollIntoElemenet('recommendation-section',0)
          }}
        />
      </div>
      <div className={classes.root} style={{marginLeft:80,flexGrow:1,minHeight:'120vh'}}>
        <div style={{display:'flex',alignItems:'flex-start',justifyContent:'space-between'}}>
          <div style={{flexGrow:1,marginRight:8}}>
            <Typography className={classes.title}>ISSUE DASHBOARD</Typography>
            <Typography className={classes.captionText} style={{marginBottom:16}}>Issues recorded in K</Typography>
          </div>
          {/* <Button variant='outlined' color='primary' style={{marginRight:16}} onClick={()=>history.push('/basic_search?query=*&object=ISSUE')}>SEE ISSUES IN SEARCH</Button>
          <QuestionBoardChip
            buttonText={'ISSUE REPORT'}
            buttonOnly
            state={state}
            dispatch={dispatch}
            code={`0290`}
            removeButtonMargin
          /> */}
        </div>

        {generateDataTiles({classes, data, setData})}
        {
          data.selectedTile && 
          <>
            <Typography className={classes.header}>ISSUE INSIGHTS</Typography>
            <Typography className={classes.subHeader}>Select a metric to drill through to the details in Search</Typography>
            {generateChartGroup({classes,data,setData})}
            {
              data.selectedTile!=='CLOSED' && 
              <div id="recommendation-section">
                <Typography className={classes.header}>RECOMMENDATIONS</Typography>
                <Typography className={classes.subHeader}>Select a recommendation to see more details</Typography>
                {generateRecommendationList({classes,data,setData})}
              </div>
            }
          </>
        }
      </div>
    </div>
  )
}

export default withStyles(styles)(Issue);