import React, { useEffect, useRef } from 'react';
import { withTheme, CircularProgress, withStyles, Typography, Select, MenuItem } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr'
import { isInViewport } from '../../../utilities'
import {getObjectCard, dedupeList} from '../utils'
import theme from '../../../theme';
 
const styles = theme => ({
  root: {

  },
  title:{
    fontSize:20,
    flexGrow:1,
    color:theme.palette.primary.main
  },
  errorText:{
    color:theme.palette.primaryText.main,
    marginTop:24,
  },
  timelineText:{
    color:theme.palette.primary.main,
    letterSpacing:2,
    marginBottom:8,
    fontSize:12
  },
  checkbox:{
    paddingLeft:0
  },
  selector:{
    ...theme.components.selector
  }
})

const WarningList = props => {

  const {
    classes,
    history,
    state,
    dispatch,
    sessionData,
    headerTop
  } = props;

  const scrollRef = useRef()

  const loadListData = ({sort=state.warningListSort,page=1}) => {
    dispatch({type:'set_warning_list',warningListData:page===1?undefined:state.warningListData,warningListLoading:true})
    axiosCerebrum
      .get(
        `/api/users/${sessionData.id}/related/collections`,
        {params:{
          relationship:'CREATOR_OF',
          parent_name:"WARNING NOTICE",
          per_page:10,
          page:page,
          sort:sort
        }}
      )
      .then(response=>{
        if(response.data.items.length===0){
          dispatch({
            type:'set_warning_list',
            warningListData:response.data
          })
          return;
        }
        axiosSolr.get(
          `/solr/search/select`,{
            params:{
              q:'*',
              fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
              fl:'*',
              start:0,
              rows:response.data.items.length,
            }
          }
        )
        .then(solrRes=>{
          dispatch({
            type:'set_warning_list',
            warningListData:{
              ...response.data,
              items:[
                ...(page===1?[]:state.warningListData.items),
                ...response.data.items.map(el=>({
                  ...el,
                  ...(solrRes.data.response.docs.find(s=>s.id===el.id)||{})
                }))
              ]
            }
          })
        })
        .catch(error=>{
          console.log(error)
          dispatch({type:'set_warning_list',warningListError:true})
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_warning_list',warningListError:true})
      })
  }

  useEffect(()=>{
    if(!state.warningListData && !state.warningListLoading){
      loadListData({})
    }
    // eslint-disable-next-line
  },[])


  const onChangeSort = (event) => {
    loadListData({sort:event.target.value})
    dispatch({type:'set_warning_list_sort',warningListSort:event.target.value})
  }

  const shouldPaginate = () => {
    return state.warningListData && state.warningListData.page<state.warningListData.pages && !state.warningListLoading && isInViewport(scrollRef)
  }

  window.onscroll = () => {
    if(shouldPaginate()){
      loadListData({page:state.warningListData.page+1})
    }
  }

  useEffect(()=>{
    if(shouldPaginate()){
      loadListData({page:state.warningListData.page+1})
    }
  // eslint-disable-next-line
  },[state.warningListData])



  return (
    <div className={classes.root}>
      <div style={{display:'flex',alignItems:'flex-start',paddingBottom:20,background:theme.palette.background.main,position:'sticky',top:headerTop,zIndex:9}}>
        <Typography className={classes.title} data-test-classname="ecosystem-title">{state.warningListData?state.warningListData.total-(state.warningListData.items.length-dedupeList(state.warningListData.items).length)+ ' ':''}LIST(S)</Typography>
        {
          state.warningListData?.total!==0 && 
          <Select
            className={classes.selector}
            style={{marginLeft:16,width:150}}
            value={state.warningListSort}
            onChange={event=>onChangeSort(event)}
            data-test-classname="ecosystem-sort-selector"
            MenuProps={{
              getContentAnchorEl:null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                horizontal: "left"
              },
            }}
          >
            {
              [
                {dispName:'Recently created',value:'LINKAGE_END_DESC'},
                {dispName:'A-Z',value:'ALPHABETICAL'},
                {dispName:'Z-A',value:'REVERSE_ALPHABETICAL'}
              ].map(el=>(
                <MenuItem value={el.value}>
                  {el.dispName}
                </MenuItem>
              ))
            }
          </Select>
        }
      </div>
      {
         state.warningListData && dedupeList(state.warningListData.items).map(el=>(
            getObjectCard(el,history)
         ))
      }
      {
        state.warningListData && state.warningListData.total===0 && 
        <Typography className={classes.errorText}>No items found</Typography>
      }
      <div ref={scrollRef} style={{textAlign:'center',marginBottom:24}}>
        {
          state.warningListLoading && 
          <CircularProgress style={{margin:'24px auto'}} color='secondary'/>
        }
      </div>
      
      {
        state.warningListError && 
        <Typography className={classes.errorText}>Error occurred loading warning notices</Typography>
      }
    </div>
  )
}


export default withTheme()(withStyles(styles)(WarningList));