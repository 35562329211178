import React, {  useState } from 'react';
import 
{ withTheme, withStyles, Modal, Paper, Typography, FormControl, Button,  IconButton, Avatar, CircularProgress} 
from '@material-ui/core';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputBase from '@material-ui/core/InputBase';
// import SearchSelector from '../SearchSelector/SearchSelector';
import { getIconComponent, toTitleCase, getInitials, getDispFields, getSeverityColour, issueSeverities, issueTypes, issueStatus } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import ModalAlert from '../ModalAlert/ModalAlert'
import SearchSelector from '../SearchSelector/SearchSelector'
import SnackBar from '../SnackBar/SnackBar'

const styles = theme => ({
  root:{
    width:'100vw',
    height:'100vh',
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'center',
  },
  modalBody:{
    flexGrow:1,
    overflowY:'auto',
    overflowX:'hidden',
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.main
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      background: theme.palette.primaryText.light,
      borderRadius:4,
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{
      
    },
    '&::-webkit-scrollbar': {
      width: 5
    },
    background:theme.palette.background.main,
    padding: '24px 40px',
    height: 570,
    width:830,
    maxHeight:'80vh'
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: '500',
    color:theme.palette.header.main,
  },
  subTitle: {
    width:140,
    color:theme.palette.primaryText.main,
    fontSize:16,
    marginTop:16,
    flexGrow:0,
    flexShrink:0
  },
  inputBlock:{
    marginTop:32,
    display:'flex',
    alignItems:'flex-start'
  },
  subText: {
    color: theme.palette.primaryText.light,
    fontSize: '0.75rem'
  },
  inputField: {
    width: '100%',
  },
  helperText: {
    color:theme.palette.primaryText.light,
    backgroundColor: theme.palette.background.main,
    margin: 0,
    paddingTop: '0.5rem',
    paddingLeft: '1rem'
  },
  textField:{
    ...theme.components.inputBase,
    '& input': {
      paddingLeft: '1rem',
      height: '2rem'
    },
    '& textarea': {
      paddingTop:8,
      paddingBottom:8,
      paddingLeft: '1rem',
    },
    paddingTop: '0.25rem'
  },
  selector: {
    ...theme.components.selector,
    '& div': {
      paddingLeft: '0.5rem',
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem'
    }
  },
  menuItem: {
    fontWeight: '400',
    '& span':{
      paddingLeft: '0.5rem'
    }
  },
  button: {
    width: 100,
    marginRight: '2rem',
    borderColor:theme.palette.primaryText.light
  },
  buttons: {
    flexGrow:0,
    backgroundColor: theme.palette.background.main,
    paddingTop: 12,
    marginTop:5,
    height: 50,
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end',
    width:'100%',
  },
  disabledButton:{
    ...theme.components.disabledButton
  },
  avatar: {
    fontSize: 12,
    height: 25,
    width: 25,
    backgroundColor: theme.palette.avatar.main,
  },
  chip: {
    marginRight: 5, 
    marginTop: 5,
    cursor: 'pointer',
    '&:hover $avatar': {
      backgroundColor: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: theme.palette.hovered.main
    }
  },
  subSectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:2,
    marginTop:12,
    marginBottom:8
  },
  focusBorder:{
    border:`1px solid ${theme.palette.error.main} !important`,
  },
  focusText:{
    color:theme.palette.error.main
  },
  snackbar:{
    background:theme.palette.snackbarBackground.main
  },

  iconButton:{
    '&:hover':{
      background:`${theme.palette.hovered.main}30 !important`
    }
  }
})

function PlatformIssueInput(props) {

  const {
    classes,
    theme, 
    history,
    dqModalOpen,
    setDqModalOpen,
    label,
    id,
    state,
    dispatch,
    presetData,
    onSuccess
  } = props;

  const [type, setType] = useState('none');
  const [severity, setSeverity] = useState(issueSeverities[2]);
  const [title, setTitle] = useState((presetData && presetData.title) || '');
  const [description, setDescription] = useState('');
  const [assignee, setAssignee] = useState();
  const [assigneeSearch, setAssigneeSearch] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [successful, setSuccessful] = useState(false); // to control snack bar button actions
  const [disableButton, setDisableButton] = useState(false); // to disable save/cancel buttons on submitting
  const [issue, setIssue] = useState(); // the node key of the issue created
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [issueCreated, setIssueCreated] = useState(false);
  const [issueAssigned, setIssueAssigned] = useState(false);
  const [issueLinked, setIssueLinked] = useState(false);

  const [creating, setCreaing] = useState(false);

  const objectName = getDispFields(state.basicData,'dispTitle')

  const currentUser = localStorage.hasOwnProperty('userInfo')?JSON.parse(localStorage.getItem('userInfo')):undefined

  const restoreStates = () =>{
    setType('none');
    setSeverity(issueSeverities[0]);
    setTitle('');
    setIssueCreated(false)
    setDescription('');
    setAssignee();
  }

  const onCancel = () =>{
    setSuccessful(false);
    setDqModalOpen(false)
    restoreStates();
  }

  const requriedFilled = () => {
    return !(type==='none' || title.trim()==='' || description.trim()==='')
  }

  const onCreated = (issue) => {
    onSuccess && onSuccess({issue})
    if(state.issueData && dispatch){
      dispatch({
        type:'set_issue_data',
        issueData:{
          ...state.issueData,
          total:state.issueData.total+1,
          items:[
            {
              issue_type_txt:issue.type,
              issue_status_txt:issue.status,
              top_users_txts:assignee?[assignee.name_txt]:undefined,
              name_txt:'ISSUE-' + issue.display_id + ': ' + issue.name,
              description:issue.description,
              id:issue.id,
              issue_severity:issue.severity,
              object_type_txt:'ISSUE'
            },
            ...state.issueData.items,
          ]
        }
      })
    }
    setSnackbarMessage(`Issue-${issue.display_id} created and linked successfully`);
    setSnackbarOpen(true);
    setDisableButton(false);
    restoreStates();
    setDqModalOpen(false);
    setAlertOpen(false);
    setIssueLinked(false);
  }

  const onLinkFail = (issue) => {
    setDisableButton(false);
    setAlertMessage(
      <span>
        <span style={{cursor:'pointer',display:'inline-block',textDecoration:'underline'}} onClick={()=>history.push('/profile/issue/'+issue.id)}>
          { `ISSUE-${issue.display_id}`}
            {/* <div style={{verticalAlign:'middle',display:'inline-block'}}>{getIconComponent({label:'link',size:20,colour:'#fff'})}</div> */}
        </span>

        {
          `  has been created however there is a problem linking to ${objectName}.\n\n`+
          `Click RETRY to try again. If the problem persists please try linking from the `
        }
        <span style={{cursor:'pointer',display:'inline-block',textDecoration:'underline'}} onClick={()=>history.push('/profile/issue/'+issue.id)}>
          {`ISSUE-${issue.display_id}`}
            {/* <div style={{verticalAlign:'middle',display:'inline-block'}}>{getIconComponent({label:'link',size:20,colour:'#fff'})}</div> */}
        </span>
        {
          ` profile page or contact the Admin`
        }
      </span>
      
    );
    setAlertOpen(true);
  }

  const onAssignFail = (issue) => {
    setDisableButton(false);
    setAlertMessage(
      <span>
        <span style={{cursor:'pointer',display:'inline-block',textDecoration:'underline'}} onClick={()=>history.push('/profile/issue/'+issue.id)}>
          { `ISSUE-${issue.display_id}`}
            {/* <div style={{verticalAlign:'middle',display:'inline-block'}}>{getIconComponent({label:'link',size:20,colour:'#fff'})}</div> */}
        </span>

        {
          `  has been created however there is a problem assigning to ${toTitleCase(assignee.name_txt)}.\n\n`+
          `Click RETRY to try again. If the problem persists please try assigning from the `
        }
        <span style={{cursor:'pointer',display:'inline-block',textDecoration:'underline'}} onClick={()=>history.push('/profile/issue/'+issue.id)}>
          {`ISSUE-${issue.display_id}`}
            {/* <div style={{verticalAlign:'middle',display:'inline-block'}}>{getIconComponent({label:'link',size:20,colour:'#fff'})}</div> */}
        </span>
        {
          ` profile page or contact the Admin`
        }
      </span>
      
    );
    setAlertOpen(true);
  }

  const onSave = async () => {
    let issueObj = issue;
    setCreaing(true);
    setDisableButton(true);
    let requestBody = {
      "name": title,
      "description": description,
      "type": type,
      "severity": severity,
      "status": issueStatus[0],
      "source_id":1000
    }
    let isError;
    if(!issueCreated){
      await axiosCerebrum
        .post(
          '/api/issues',
          requestBody
        )
        .then(response=>{
          setSuccessful(true); // issue successfully created
          setIssue(response.data);
          setIssueCreated(true);
          issueObj = response.data;
        })
        .catch(error=>{
          setAlertMessage(`Error creating the issue. Please click SAVE to try again. If the problem persist please contact the Admin`);
          setAlertOpen(true);
          setDisableButton(false);
          isError = true;
        })
    }
    if(isError){
      setCreaing(false)
      return;
    }
    if(!issueLinked){
      await axiosCerebrum
        .put(`/api/issues/${issueObj.id}/related?object_id=${id}&relationship=IMPACTS`)
        .then(()=>{ 
          setIssueLinked(true)
        })
        .catch(error=>{
          onLinkFail(issueObj);
          console.log(error);
          isError=true;
        })
    }
    if(isError){
      setCreaing(false)
      return;
    }
    if(assignee && !issueAssigned){
      await axiosCerebrum
        .put(`/${localStorage.hasOwnProperty('issue_assign_fail_test')?'error':''}api/issues/${issueObj.id}/related?object_id=${assignee.id}&relationship=ASSIGNED_TO`)
        .then(()=>{ 
          setIssueAssigned(true);
        })
        .catch(error=>{
          onAssignFail(issueObj);
          console.log(error);
          isError=true;
        })
    }
    if(isError){
      setCreaing(false)
      return;
    }
    setCreaing(false)
    onCreated(issueObj)
  }


  return (
    <div >
      <SnackBar
        snackBarOpen={snackbarOpen}
        setSnackBarOpen={setSnackbarOpen}
        message={snackbarMessage}
        buttons={[
          successful?<Button color='secondary' variant='outlined' onClick={()=>history.push(`/profile/issue/${issue.id}`)} >OPEN</Button>:null,
          <IconButton onClick={()=>setSnackbarOpen(false)} className={classes.iconButton} style={{marginLeft:12,padding:6}}>{getIconComponent({label:'clear', size: 16, colour: theme.palette.snackbarContent.main})}</IconButton>
        ]}
      />

      <Modal 
        className={classes.root}
        open={dqModalOpen} 
        onClose={()=>onCancel()}
        //disableAutoFocus={true}
        disableBackdropClick={true}
      > 
        <div style={{width:912,marginTop:'7vh',outline:'none'}}>
        <Paper style={{width:'max-content',display:'flex', height:880,maxHeight:'70vh', flexDirection:'column', background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
          <div className={classes.modalBody} >
            <Typography className={classes.title}>Add Issue</Typography>
            <Typography className={classes.subText}>{`Raise an issue about ${label.replace(/_/g,' ').toUpperCase()}`}</Typography>

            {/* select issue type */}
            <div className={classes.inputBlock}>
              <Typography className={classes.subTitle}>Issue Type</Typography>
              <div style={{flexGrow:1}}>
                <FormControl className={`${classes.inputField}`}>
                  <Select
                    className={`${classes.selector} ${type===0?classes.focusBorder:''}`}
                    style={type===0?{color:'#999'}:null}
                    value={type}
                    onChange={event=>setType(event.target.value)}
                    data-test-id="issue-modal-type"
                    disableUnderline
                  > 
                    <MenuItem className={classes.menuItem} value={'none'}>
                      <span>Select an issue type</span>
                    </MenuItem>
                    {
                      issueTypes.map(el=>{
                        return (
                          <MenuItem className={classes.menuItem} value={el}>
                            <span>{toTitleCase(el.replace(/_/g,' '))}</span>
                          </MenuItem>
                        )
                      })
                    }
                  </Select>
                  <FormHelperText className={`${classes.helperText} ${type===0?classes.focusText:''}`}>Required</FormHelperText>
                </FormControl>
              </div>
            </div>


            {/* select severity */}
            <div className={classes.inputBlock}>
              <Typography className={classes.subTitle} style={{marginTop:6}}>Severity</Typography>
              <div style={{flexGrow:1}}>
                {
                  issueSeverities.map(el=>{
                    let colour = getSeverityColour(el)
                    return (
                      <Button 
                        variant="outlined"  
                        className={classes.button}
                        style={el===severity?{color:colour}:{color:theme.palette.primaryText.light}} // if the button is selected
                        onClick={()=>setSeverity(el)}
                        data-test-id={`issue-modal-severity-${el.toLowerCase()}`}
                      >
                        {el}
                      </Button>
                    )
                  })
                }
              </div>
            </div>

            {/* enter title */}
            <div className={classes.inputBlock}>
              <Typography className={classes.subTitle}>Issue name</Typography>
              <div style={{flexGrow:1}}>
                <FormControl className={classes.inputField}>
                  <InputBase 
                    className={`${classes.textField} ${title.trim()===''?classes.focusBorder:''}`}
                    value={title}
                    inputProps={{
                      'data-test-id':'issue-modal-name'
                    }}
                    onChange={event=>{setTitle(event.target.value.slice(0,255))}}
                    placeholder={'Give this issue a title'}
                    multiline
                    rows={4}
                  />
                  <FormHelperText className={`${classes.helperText} ${title.trim()===''?classes.focusText:''}`}>Required. Max 255 characters</FormHelperText>
                </FormControl>
              </div>
            </div>

            {/* description */}
            <div className={classes.inputBlock}>
              <Typography className={classes.subTitle} style={{marginTop:12}}>Description</Typography>
              <div style={{flexGrow:1}}>
                <FormControl className={classes.inputField}>
                  <InputBase 
                    className={`${classes.textField} ${description.trim()===''?classes.focusBorder:''}`}
                    value={description}
                    inputProps={{
                      'data-test-id':'issue-modal-description'
                    }}
                    onChange={event=>setDescription(event.target.value)}
                    placeholder={'Describe the issue'}
                    multiline
                    rows="5"
                  />
                  <FormHelperText className={`${classes.helperText} ${description.trim()===''?classes.focusText:''}`}>Required</FormHelperText>
                </FormControl>
              </div>
            </div>

            <div className={classes.inputBlock}>
              <Typography className={classes.subTitle} style={{marginTop:12}}>Assignee</Typography>
              <div style={{flexGrow:1}}>
                <SearchSelector 
                  url='/solr/search/select'
                  params={{
                    q: `${assigneeSearch}*`,
                    fq:'object_type_srt:user AND account_type_srt:"USER_ACCOUNT" AND -merge_type_srt:CANDIDATE',
                    rows:10
                  }}
                  testID="issue-modal-assignee-search"
                  // removeFLModify={false}
                  searchValue={assigneeSearch}
                  setSearchValue={setAssigneeSearch}
                  placeholder='Search for a user' 
                  onResultClick={el=>{setAssignee(el)}}
                />
                {
                  assignee && 
                  <div>
                    <Typography className={classes.subSectionHeader}>ADDED USER</Typography>
                    <div 
                      style={{width:'max-content',maxWidth:300,overflow:'hidden',marginRight:6,marginBottom:6,padding:'0px 4px',border:`1px solid ${theme.palette.border.main}`,height:32, display:'flex',borderRadius:16,background:theme.palette.chip.main,alignItems:'center'}}
                    >
                      <Avatar align='center' className={classes.avatar}>{getInitials(assignee.name_txt)}</Avatar>
                      <Typography className={classes.ellipsisText} style={{marginLeft:10,overflow:'hidden',textOverflow:'ellipsis',color:theme.palette.primaryText.main}}>{assignee.name_txt}</Typography>
                      <IconButton style={{marginLeft:20,padding:3,marginRight:3}} onClick={()=>setAssignee()}>{getIconComponent({label:'clear', size:20,colour:theme.palette.primaryText.main})}</IconButton>
                    </div>
                  </div>
                }
                <Typography  style={{fontSize: 12, color: theme.palette.primaryText.light, marginTop: 16,marginBottom:8}}>Suggestions</Typography>
                {
                  currentUser && 
                  <div 
                    className={classes.chip}
                    style={{width:'max-content',maxWidth:300,overflow:'hidden',marginRight:6,marginBottom:6,padding:'0px 12px 0px 4px',border:`1px solid ${theme.palette.border.main}`,height:32, display:'flex',borderRadius:16,background:theme.palette.chip.main,alignItems:'center'}}
                    onClick={()=>{setAssignee({...currentUser,name_txt:currentUser.name})}}
                  >
                    <Avatar align='center' className={classes.avatar}>{getInitials(currentUser.name)}</Avatar>
                    <Typography className={classes.ellipsisText} style={{marginLeft:10,overflow:'hidden',textOverflow:'ellipsis',color:theme.palette.primaryText.main}}>{currentUser.name}</Typography>
                  </div>
                }
              </div>
            </div>
          </div>

          <div className={classes.buttons}>
            {
              !creating &&
              <Button data-test-id="add-issue-save-button" color="primary" classes={{disabled:classes.disabledButton}} style={{width:96}} onClick={onSave} disabled={disableButton || !requriedFilled()}>
                {issueCreated?'RETRY':'SAVE'}
              </Button>
            }
            {
              creating && <div style={{width:96,height:36,display:'flex',alignItems:'center',justifyContent:'center'}}><CircularProgress color='secondary' style={{width:24,height:24}}/></div>
            }
            <Button data-test-id="add-issue-close-button" color="secondary" classes={{disabled:classes.disabledButton}} style={{width:96, marginLeft: 16,marginRight:8}}  onClick={onCancel} disabled={disableButton}>
              CLOSE
            </Button>
          </div>
        </Paper>

        <ModalAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          type='error'
        />
        </div>
        
      </Modal>
    </div>
  )
}

export default withTheme()(withStyles(styles)(PlatformIssueInput));