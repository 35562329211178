import React, { useEffect, useReducer, } from 'react';
import { withStyles, } from '@material-ui/core';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/FilterList/Body/Body';
import 'url-search-params-polyfill';
import { addHistory } from '../../HistoryManager';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'

const styles = theme => ({

});

const initialState = {
  filterListSearch:'',
  filterListSort:'END_DESC',
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_filter_list':
      return {
        ...state,
        filterList:action.filterList,
        filterListLoading:action.filterListLoading,
        filterListError:action.filterListError
      }
    case 'set_filter_list_search':
      return {
        ...state,
        filterListSearch:action.filterListSearch
      }
    case 'set_filter_list_sort':
      return {
        ...state,
        filterListSort:action.filterListSort
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const FilterList = props => {

  const {
    history,
    sessionData
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState)
  
  useEffect(()=>{
    addHistory({url:window.location.pathname, iconLabel:'filter_list', title: 'Filters', subTitle:'Filters',type:'application'})
  },[])

  let buttons = [];

  buttons.push(
    <ProfileButton
      onClick={() => history.push('/create_filter')}
      tooltip={'Create Filter'}
      text='Create Filter'
      testID='create-filter-button'
    />
  )

  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              label='filter_list'
              title='Filters'
              buttons={buttons}
              subtitle=' '
            />
          </div>)}
        body={
          <Body
            state={state}
            history={history}
            dispatch={dispatch}
            sessionData={sessionData}
          />
        }
      />
    </div>
  )
}

export default withStyles(styles)(FilterList);
