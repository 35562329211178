import React, { useEffect }  from 'react';
import { withTheme, withStyles, Typography, CircularProgress} from '@material-ui/core';
import axiosSolr from '../../../../axios-solr';
import HorizontalBar from '../Charts/HorizontalBar';
import { contentItems, dataItems } from '../../utils';

const styles = theme => ({

})

const DataChart = props => {

  const {
    classes,
    // theme,
    state,
    data,
    setData,
    collectionName,
    collectionShortName,
    collectionID,
    variant
  } = props;
  
  const chartData = data[`${variant}Data`];
  const loading = data[`${variant}Loading`];
  const error = data[`${variant}Error`];
  const setError = () => setData({...data, [`${variant}Error`]:true, [`${variant}Loading`]:false})
  const setLoading = () => setData({...data, [`${variant}Loading`]:true, [`${variant}Error`]:false})
  const setChartData = d => setData({...data, [`${variant}Data`]:d,  [`${variant}Loading`]:false, [`${variant}Error`]:false})

  let fieldName = `${variant==='data'?'data':'content'}_impact_count_srt`

  const loadChartData = async (showReference=state.showReference) => {
    if(!chartData)setLoading()
    let instanceCount = []
    let error;
    await axiosSolr
      .get(
        `/solr/impact/select`,
        {params:{
          q:'*:*',
          rows:5,
          fq:`object_type_srt:(COLLECTION_INSTANCE) AND parent_id_srt:${collectionID} AND extract_id:${state.extractData.id}` + 
            (state.historyObject?` AND starting_object_id:${state.historyObject.id}`:' AND -starting_object_id:*'),
          sort:`${fieldName} desc`
        }}
      ).then(response=>{
        response.data.response.docs.forEach(d=>{
          if(d[fieldName]){
            instanceCount.push({
              val:d.name_srt,
              count:d[fieldName]
            })
          }
        })
      }).catch(error=>{
        setError()
        error = true;
        console.log(error)
      })
    if(error)return;
    await axiosSolr
      .get(
        `/solr/impact/select`,
        {params:{
          q:'*:*',
          rows:0,
          fq:`object_type_srt:(${(variant==='data'?dataItems:contentItems).join(' OR ')}) AND extract_id:${state.extractData.id}` + 
              (instanceCount.length>0?` AND -${collectionShortName.replace(/\s+/g,'_')}_kc_msrt:(${instanceCount.map(n=>`"${n.val}"`).join(' OR ')})`:'') + 
              ` AND ${collectionShortName.replace(/\s+/g,'_')}_kc_msrt:* ` + 
              ` AND -reference_srt:YES` + 
              ' AND -starting_object_id:[* TO *]',
        }}
      ).then(response=>{
        if(response.data.response.numFound>0){
          instanceCount.push({
            val:`other ${collectionName}`,
            count:response.data.response.numFound
          })
        }
      }).catch(error=>{
        setError()
        error = true;
        console.log(error)
      })
    if(error)return;
    await axiosSolr
      .get(
        `/solr/impact/select`,
        {params:{
          q:'*:*',
          rows:0,
          fq:`object_type_srt:(${(variant==='data'?dataItems:contentItems).join(' OR ')}) AND extract_id:${state.extractData.id} AND -${collectionShortName.replace(/\s+/g,'_')}_kc_msrt:[* TO *]` + 
              ` AND -reference_srt:YES` + 
              ' AND -starting_object_id:[* TO *]',
        }}
      ).then(response=>{
        if(response.data.response.numFound>0){
          instanceCount.push({
            val:`no ${collectionName}`,
            count:response.data.response.numFound
          })
        }
      }).catch(error=>{
        setError()
        error = true;
        console.log(error)
      })
    if(error)return;
    setChartData(instanceCount)
  }

  useEffect(()=>{
    if(!chartData && !loading)loadChartData()
     // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(chartData){
      loadChartData({showReference:state.showReference})
    }
    // eslint-disable-next-line
  },[state.showReference])
  
  return (
    <div className={classes.root}>
      {loading && <CircularProgress color='secondary'/>}
      {error && <Typography>Error occurred loading chart</Typography>}
      {
        chartData && chartData.length>0 && chartData.some(el=>el!==0) && 
        <HorizontalBar
          labels={chartData.map(c=>c.val)}
          data={chartData.map(c=>c.count)}
          id="collection-data-chart"
        />
      }
      {
        chartData && (chartData.length===0 || chartData.every(el=>el===0) ) && 
        <Typography>No {variant} item(s) found</Typography>
      }
    </div>  
  )
}

export default withTheme()(withStyles(styles)(DataChart));