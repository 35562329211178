import * as actionTypes from './actionTypes';

export const loginSuccess = (data) => {
  return {
    type: actionTypes.LOGIN_AUTH_SUCCESS,
    data: data
  };
};

export const logout = () => {
  return {
    type: actionTypes.LOGOUT_START
  };
};


export const logoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS
  };
};
