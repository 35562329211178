export const mapRoleName = role => {
  switch(role){
    case 'kada_user':
      return 'Data User'
    case 'kada_data_gov_user':
      return 'Data Governance'
    case 'kada_data_manager':
      return 'Data Manager'
    case 'kada_business_user':
      return 'Business User'
    case 'kada_admin':
      return 'Kada Admin'
    default:
      return role
  }
}
