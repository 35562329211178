import React, { useEffect } from 'react';
import { withTheme, withStyles, Typography, Button, Stepper, Step, StepLabel, StepContent} from '@material-ui/core';
import PeriodSelector from '../AssessmentDetails/PeriodSelector';
import AdditionalSelector from '../AssessmentDetails/AdditionalSelector';
import ClearCartToggler from './ClearCartToggler';
import useAlert from '../../../hooks/useAlert';
import axiosSolr from '../../../axios-solr';

const styles = theme => ({
  root: {
    marginTop:16
  },
  block:{
    maxWidth:640,
    marginLeft:6,
    marginTop:8
  },
  title:{
    fontSize:16,
    color:theme.palette.header.main,
  },
  chip: {
    padding: '0.5rem',
    display: 'inline-block',
    backgroundColor: theme.palette.chip.main,
    border:`1px solid ${theme.palette.border.main}`,
    marginBottom: '1rem',
    borderRadius: 5
  },
  selector: {
    ...theme.components.selector,
    width: 618,
    height:56,
    marginTop: '0.5rem',
  },
  collectionListItem:{
    height:48,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    display:'flex',
    alignItems:'center',
    overflow:'hidden'
  },
  collectionItemText:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    fontSize:16,
    flexGrow:1,
    marginRight:8,
  },
  collectionChildChip:{
    height:24,
    flexGrow:0,
    flexShrink:0,
    borderRadius:12,
    border:`1px solid ${theme.palette.secondary.main}`,
    color:theme.palette.secondary.main,
    display:'flex',
    alignItems:'center',
    padding:'0 8px',
    marginRight:8,
    fontSize:12
  },
  stepper:{
    marginBottom:120,
    backgroundColor:theme.palette.background.main
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },

  suggestionChip:{
    ...theme.components.suggestionChip
  }
})

const AssessmentDetails = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    onRun,
  } = props;

  const {
    sendAlert
  } = useAlert({
    id:`multi-ia-details`,
  })

  let steps = [
    'Select a usage period',
    'Add additional assessment criteria (optional)',
    'Clear cart after submitting assessment (optional)',
    'Assess target'
  ]

  const getStepLabelText = index => {
    switch(index){
      case 0:
        if(state.settingStep!==0 && state.usageWindow!==-1){
          return `Assessment timeframe: Used in last ${state.usageWindow} days`
        }
        return steps[index];
      case 1: 
        if(state.settingStep!==1 && state.additionalCriteria.length>0){
          return `Additional criteria: ${state.additionalCriteria.map(c=>c.name_txt).join(', ')}`
        }
        return steps[index];
      case 2:
      case 3:
        return steps[index];
      default:
        return ''
    }
  }

  const onSelectUsageWindow = event => {
    dispatch({type:'set_usage_window',usageWindow:event.target.value})
    if(event.target.value===-1)return;
    dispatch({type:'set_setting_step',settingStep:1})
  }

  const onPeriodNextClick = () => {
    dispatch({type:'set_setting_step',settingStep:1})
  }

  const onSelectAdditionalCriteria = object => {
    if(state.additionalCriteria.length>=3){
      sendAlert({type:'info',message:"You can only select max 3 additional criterias"})
      return;
    }
    axiosSolr
      .get(
        `/solr/search/select`,{params:{
          q:"*",
          fq:`object_type_srt:COLLECTION_INSTANCE AND parent_id_srt:${object.id}`,
          rows:0,
        }}
      )
      .then(res=>{
        dispatch({type:'set_additional_criteria',additionalCriteria:[...state.additionalCriteria,{...object,child_object_count_srt:res.data.response.numFound}]})
      })
      .catch(err=>{
        console.log(err)
        dispatch({type:'set_additional_criteria',additionalCriteria:[...state.additionalCriteria,object]})
      })
    dispatch({type:'set_additional_criteria',additionalCriteria:[...state.additionalCriteria,object]})
  }
  
  const onRemoveAdditionalCriteria = object => {
    dispatch({type:'set_additional_criteria',additionalCriteria:state.additionalCriteria.filter(c=>c.id!==object.id)})
  }

  const onCriteriaNextClick = () => {
    dispatch({type:'set_setting_step',settingStep:2})
  }

  const labelClickable = index => {
    let clickable = false;
    if(index===0)return true;
    if(index===1 && state.usageWindow!==-1)clickable = true;
    if(index===2)clickable = true;
    return clickable && labelClickable(index-1)
  }

  const onLabelClick = index => {
    if(!labelClickable(index))return;
    dispatch({type:'set_setting_step',settingStep:index})
  }

  const checkRequired = () => {
    if(!state.multiIAObjects.length===0 || state.usageWindow===-1 )return false;
    return true
  }

  const getStepContent = index => {
    switch(index){
      case 0:
        return <PeriodSelector classes={classes} state={state} onSelectUsageWindow={onSelectUsageWindow} onPeriodNextClick={onPeriodNextClick}/>
      case 1:
        return <AdditionalSelector classes={classes} state={state} onSelectAdditionalCriteria={onSelectAdditionalCriteria} onRemoveAdditionalCriteria={onRemoveAdditionalCriteria} onCriteriaNextClick={onCriteriaNextClick}/>
      case 2:
        return <ClearCartToggler classes={classes} state={state} dispatch={dispatch}/>
      case 3:
        return (
          <div className={classes.block}>
            <Button disabled={!checkRequired()} variant='contained' color='primary' style={{width:96,height:36,marginTop:24}} onClick={()=>onRun()}>
              RUN
            </Button>
          </div>
        )
      default:
        return <div></div>
    }
  }

  useEffect(()=>{
    return ()=>{
      dispatch({type:'reset_setting'})
    }
  // eslint-disable-next-line
  },[])

  return (
    <div className={classes.root}>
      <div style={{marginBottom:24}}>
        <Typography style={{fontSize:20,color:theme.palette.primaryText.main}}>
          ADD ASSESSMENT CRITERIA
        </Typography>
        <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>
          Confirm criteria and run the assessment
        </Typography>
      </div>
      
      <Stepper activeStep={state.settingStep} orientation="vertical" className={classes.stepper} >
        {steps.map((label, index) => (
          <Step key={index} className={classes.step}>
            <StepLabel style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}}>
              <Typography color='primary' style={{color:state.settingStep<index?theme.palette.primaryText.light:theme.palette.primaryText.main,fontSize:16,marginLeft:6}}>{getStepLabelText(index)}</Typography> 
            </StepLabel>
            <StepContent>
             {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
     
      
    </div>
  )
}

export default withTheme()(withStyles(styles)(AssessmentDetails));