import React, { } from 'react';
import { withTheme, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toTitleCase } from '../../../../utilities';
import SearchSelector from '../../../UI/SearchSelector/SearchSelector';
import { getPlatformSettings } from '../../../../permissionChecker';
import UserChip from '../../../UI/Chips/UserChip';


const UserSelector = props => {

  const {
    classes,
    theme,
    userSearchValue,
    setUserSearchValue,
    user,
    setUser,
    userList,
    setUserList,
    isMulti,
    code,
    modalParams
  } = props;

  let userFilter;
  if(localStorage.hasOwnProperty('platformSettings')){
    try{
      userFilter = getPlatformSettings(4008)?.value;
    }catch(error){
      console.log(error);
      userFilter = 'any_user'
    }
  }

  return (
    <div className={classes.modalBlock}>
      <Typography color='primary' className={classes.modalBlockTitle}>
        {
          ['owner','steward'].find(n=>modalParams.includes(n))?
          toTitleCase(['owner','steward'].find(n=>modalParams.includes(n))):
          code==='0400'?
            'Excluded Users'
            :
            'User'
        }
      </Typography>
      <div style={{flexGrow:1}}>
        <SearchSelector
          url='/solr/search/select'
          params={{
            q:'*',
            fq: `(name_srt:(${userSearchValue.split(' ').join(' AND ')}*) ` + 
                ` OR name_srt:(${userSearchValue.split(' ').join(' AND ')})) ` + 
                ( userFilter==='data_roles'? 
                    modalParams.includes('owner')?
                      ' AND data_role_kc_msrt:"DATA OWNER"'
                      :
                      modalParams.includes('steward')?
                      ' AND data_role_kc_msrt:"DATA STEWARD"'
                      :
                      ''
                    :
                    ''
                )+
                ' AND object_type_srt:USER AND -merge_type_srt:CANDIDATE' + 
                (
                  isMulti && userList.length>0?
                  ` AND -id:(${userList.map(u=>u.id).join(' OR ')})`:''
                )
                ,
            fl:'*',
            rows: 10
          }}
          searchValue={userSearchValue}
          setSearchValue={setUserSearchValue}
          placeholder={`Search for a ${['owner','steward'].find(n=>modalParams.includes(n))?['owner','steward'].find(n=>modalParams.includes(n)):'user'}`}
          onResultClick={el=>{
            if(isMulti){
              if(!userList.find(u=>u.id===el.id)){
                setUserList([...userList,el])
              }
            }else{
              setUser(el)
            }
          }}
        />
        <Typography style={{fontSize:12,marginLeft:18,marginBottom:16,color:theme.palette.primaryText.light,marginTop:6}}>Required</Typography>
        <div style={{display:'flex',flexWrap:'wrap', overflow:'hidden'}}>
          {
            (isMulti?userList:[user]).filter(el=>el).map((el,i)=>(
              <UserChip
                user={el}
                onRemove={()=>{
                  if(isMulti){
                    setUserList(userList.filter(u=>u.id!==el.id))
                  }else{
                    setUser()
                  }
                }}
              />
            ))
          }
        </div>
        {
          (isMulti?userList:[user]).filter(el=>el).length===0 && 
          <UserChip
            user={{name_txt:'No user selected'}}
            iconLabel={'user'}
          />
        }
      </div>
    </div>
  )
}

UserSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  userSearchValue: PropTypes.string.isRequired,
  setUserSearchValue: PropTypes.func.isRequired,
  user: PropTypes.object,
  setUser: PropTypes.func.isRequired,
  modalParams: PropTypes.array.isRequired,
}

export default withTheme()(UserSelector);