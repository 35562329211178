import React, { } from 'react';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import { formatNumber, getFontColourByBackground } from '../../../utilities'
import PropTypes from 'prop-types'
import KTooltip from '../../UI/KTooltip/KTooltip';

const styles = theme => ({
  legendItemText: {
    marginLeft: 8,
    color: theme.palette.primaryText.light,
    fontSize: 12,
  },
})

const HeatMap = props => {

  const {
    theme,
    classes,
    id,
    height,
    width,
    data,
    xLabels,
    yLabels,
    colourRange,
  } = props;

  const getValueColour = value => {
    const colour = colourRange.find(c => value >= c.min && value <= c.max);
    return colour ? colour.colour : undefined;
  }

  const getBlock = ({ text, value, clickFn, tooltip }) => {
    return (
      <KTooltip title={tooltip || value}>
        <div
          style={{
            color: isNaN(value) ? theme.palette.primaryText.main : getFontColourByBackground(getValueColour(value)),
            width: '16.6%',
            height: '100%',
            borderBottom: `1px solid ${theme.palette.background.main}`,
            borderRight: `1px solid ${theme.palette.background.main}`,
            boxSizing: 'border-box',
            flexGrow: 0,
            flexShrink: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 10,
            background: !isNaN(value) ? getValueColour(value) : undefined,
            cursor: clickFn && value ? 'pointer' : 'default',
          }}
          onClick={value ? clickFn : undefined}
        >
          {text}
        </div>
      </KTooltip>
    )
  }

  let reversedYLabels = [...yLabels].reverse();

  return (
    <div style={{ padding: 32 }}>
      <div style={{ marginBottom: 12, display: 'flex', justifyContent: 'flex-end' }}>
        {
          colourRange.map(el => (
            <div key={el.text} style={{ display: 'flex', alignItems: 'center', marginLeft: 12 }}>
              <div style={{ width: 16, height: 16, borderRadius: 2, background: el.colour }}></div>
              <Typography className={classes.legendItemText}>{el.text}</Typography>
            </div>
          ))
        }
      </div>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 32, boxSizing: 'border-box' }}>
        <div style={{ width, height }} id={id}>
          {
            reversedYLabels.map((yLabel, i) => {
              let arr = [];
              arr.push(getBlock({ text: yLabel }));

              xLabels.forEach((xLabel, j) => {
                const dataPoint = data.find(d => d.xLabel === xLabel && d.yLabel === yLabel);
                arr.push(getBlock({ text: formatNumber(dataPoint.value), value: dataPoint.value, clickFn: dataPoint.clickFn, tooltip: `${dataPoint.yLabel} and ${dataPoint.xLabel}: ${formatNumber(dataPoint.value)}` }));
              })

              return (
                <div style={{ display: 'flex', height: '16.6%' }}>
                  {arr}
                </div>
              )
            })
          }
          <div style={{ display: 'flex', height: '10%' }}>
            <div style={{ width: '16.6%', flexShrink: 0, height: '100%' }}></div>
            {
              xLabels.map(xLabel => (
                getBlock({ text: xLabel })
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

HeatMap.prototype = {
  id: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    xLabel: PropTypes.string.isRequired,
    yLabel: PropTypes.string.isRequired,
    clickFn: PropTypes.func,
    value: PropTypes.number.isRequired,
  })).isRequired,
  xLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  yLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  colourRange: PropTypes.arrayOf(PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    colour: PropTypes.string.isRequired,
  })).isRequired,
}



export default withTheme()(withStyles(styles)(HeatMap));