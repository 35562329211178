import axios from 'axios';
import keycloakInstance from './keycloak-instance';

// export const redashURL = document.location.protocol + "//" +  document.location.hostname;
// export const solrURL = document.location.protocol + "//" +  document.location.hostname + ':8983';

let solrURL= document.location.protocol + "//" + document.location.hostname;

export { solrURL };

const axiosSolr = axios.create({
    baseURL: solrURL
});

const escapeAPIParam = str => {
	return str.replace(/"(.*?)"/g, s=>`"${s.split('"')[1].replace(/([+-:&|!(){}[\]^~*?\\/])/g,'\\$1')}"`)
}

let CancelToken = axios.CancelToken;
axiosSolr.interceptors.request.use(
    (
        config => {
					if(config.params && config.params.fq){
						config.params.fq = escapeAPIParam(config.params.fq)
					}
					if(config.params?.['json.facet']){
						config.params['json.facet'] = JSON.stringify(config.params['json.facet'])
					}
					if(config.data){
						try{
							if(config.data.params.fq)config.data.params.fq = escapeAPIParam(config.data.params.fq)
						}catch(error){}
						try{
							if(config.data.filter)config.data.filter = escapeAPIParam(config.data.filter)
						}catch(error){}
					}
					if(new Date(keycloakInstance.refreshTokenParsed.exp * 1000).getTime()<new Date().getTime()){
            window.open(keycloakInstance.createLogoutUrl(), "_self")
						return {
							...config,
							cancelToken: new CancelToken((cancel) => cancel())
						};
					}
					return keycloakInstance.updateToken(5) //set it to 1800 to test refresh token process
									.then(refreshed=>{
										config.headers['Authorization'] = 'Bearer '+keycloakInstance.token;
										return config;
									})
        }
    )
);

export default axiosSolr;
