import React, { } from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import TimeIcon from '@material-ui/icons/AvTimer';
import theme from '../../../theme';
import moment from 'moment';


const styles = theme => ({
  textField:{
    ...theme.components.inputBase,
    width:'100%',
    '& input':{
      padding: '18.5px 8px',
      color:theme.palette.primaryText.main
    },
    '& div':{
      marginTop: '0px !important',
      background:"none",
      '&:hover':{
        background:'none'
      }
    },
  },
})

const SelectLoadTime = props => {

  const {
    classes,
    activeStep,
    setActiveStep,
    loadTime,
    setLoadTime,
  } = props;

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <TimePicker
          className={classes.textField}
          value={loadTime}
          ampm={false}
          onChange={(time)=>{
            setLoadTime(moment(time))
            setActiveStep?.(activeStep+1)
          }}
          variant="filled"
          InputProps={{
            endAdornment: <TimeIcon style={{width:24,height:24,color:theme.palette.primaryText.light}}/>
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default withTheme()(withStyles(styles)(SelectLoadTime));