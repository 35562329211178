import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, InputBase, Paper, Typography, IconButton } from '@material-ui/core';
import { getDispFields, getIconComponent,} from '../../../../utilities';
import SimpleResultItem from '../../../UI/SearchResults/SimpleResultItem';
import { withRouter } from 'react-router-dom'; 
import { nodeTypes } from '../../../UI/Lineage/LineageV3/layoutUtils';

const styles = theme => ({
  inputBase:{
    ...theme.components.inputBase,
    height:48,
    width:'100%'
  },
  listContainer:{
    maxHeight:'80vh',
    overflow:'auto',
    ...theme.components.customScroll
  },
  tailChip:{
    flexShrink:0,
    background:theme.palette.chip.main,
    color:theme.palette.primaryText.main,
    border:`1px solid ${theme.palette.chipBorder.main}`,
    padding:'0px 8px',
    borderRadius:12,
    height:24,
    fontSize:12,
    display:'flex',
    alignItems:'center'
  },
  expandableItem:{
    height:48,
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    },
    overflow:'hidden',
    padding:'0px 16px'
  },
  itemText:{
    fontSize:16,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    flexGrow:1,
    marginRight:16
  },
  expandIcon:{
    width:24,
    height:24,
    marginRight:8,
    transition: "transform .3s ease",
  }
})

function NodeSearch(props) {
  const {
    classes,
    theme,
    nodes
  } = props;

  const [search, setSearch] = useState('')
  const [list, setList] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [expandedId, setExpandedId] = useState(null)
  const searchRef = useRef()

  const getListBySearch = (value) => {
    let items = [];
    let total = 0;
    nodes.forEach(el=>{
      if(el.type!==nodeTypes.groupNodeType)return;
      let dispTitle = getDispFields(el.data.obj,'dispTitle')
      let dispSubtitle = getDispFields(el.data.obj,'dispSubtitle')
      if(dispTitle.toLowerCase().includes(value.toLowerCase()) || dispSubtitle.toLowerCase().includes(value.toLowerCase())){
        if(!items.find(i=>i.id===el.data.obj.id)){
          items.push({id:el.data.obj.id, list:[el]})
        }else{
          items.find(i=>i.id===el.data.obj.id).list.push(el)
        }
        total += 1;
      }
    })

    items.sort((a,b)=>{
      if(a.list.length!==b.list.length){
        return b.list.length-a.list.length
      }else{
        return getDispFields(a.list[0].data.obj,'dispTitle').localeCompare(getDispFields(b.list[0].data.obj,'dispTitle'))
      }
    })

    setList(items)
    setTotalCount(total)
  }



  const getSingleItem = el => {
    let swimlaneName = nodes.find(n=>n.id===el.swimlaneId).data.obj.name;
    return (
      <SimpleResultItem
        key={el.id}
        title={getDispFields(el.data.obj,'dispTitle')}
        label={el.data.obj.object_type || el.data.obj.object_type_txt}
        item={el.data.obj}
        subTitle={getDispFields(el.data.obj,'dispSubtitle')}
        hideRight
        onClick={() => document.getElementById(el.id)?.click()}
        height={48}
        tailObject={
          swimlaneName?
          <div className={classes.tailChip}>
            {swimlaneName}
          </div>:
          undefined
        }
      />
    )
  }

  const getExpandableItem = el => {
    return (
      <div className={classes.expandableContainer}>
        <div className={classes.expandableItem} onClick={()=>expandedId===el.id?setExpandedId():setExpandedId(el.id)}>
          <div className={classes.expandIcon} style={{transform:`rotate(${el.id===expandedId?0:-90}deg)`}}>
            {getIconComponent({label:'triangle_down',size:24,colour:theme.palette.primaryText.light})}
          </div>
          <Typography className={classes.itemText}>
            {el.list[0].data.label}
          </Typography>
          <div className={classes.tailChip}>
            {el.list.length} matches
          </div>
        </div>
        <div style={{paddingLeft:32}}>
          {
            el.id===expandedId &&
            el.list.map(getSingleItem)
          }
        </div>
      </div>
    )
  }

  const generateListItem = l => {
    return l.map(el=>{
      if(el.list.length>1){
        return getExpandableItem(el)
      }else{
        return getSingleItem(el.list[0])
      }
    })
  }

  const onChangeSearch = value => {
    setSearch(value.trim())
  }
  

  useEffect(()=>{
    clearTimeout(searchRef.current)
    if(search.trim()===''){
      setList([])
      return;
    }
    searchRef.current = setTimeout(()=>{
      getListBySearch(search)
    },100)
  // eslint-disable-next-line
  },[search])

  return (
    <>
      <Paper
        style={{marginLeft:16,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main,width:520}}
      >
        <InputBase
          value={search}
          onChange={e=>onChangeSearch(e.target.value)}
          className={classes.inputBase}
          placeholder='Search map'
          endAdornment={
            <>
              {
                search.trim()!=='' &&
                <IconButton 
                  onClick={e=>{
                    setSearch('')
                  }}
                  style={{width:32,height:32,marginRight:6}}
                >
                  {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
                </IconButton>
              }
            </>
          }
        />
        <div className={classes.listContainer} style={search.trim()===''?{}:{marginTop:16}}>
          {
            search.trim()!=='' && list.length===0 && 
            <Typography style={{padding:'0px 16px 16px'}}>No results found</Typography>
          }
          {
            list.length>0 && 
            <Typography style={{fontSize:12,marginBottom:8,marginLeft:16,letterSpacing:1.5}}>{totalCount} RESULT(S)</Typography>
          }
          {
            generateListItem(list)
          }
        </div>
      </Paper>
    </>
  )
}

NodeSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  disableFocus: PropTypes.bool,
  dispatch: PropTypes.func,
  childrenMap: PropTypes.object,
}

export default withTheme()(withStyles(styles)(withRouter(NodeSearch)));