import React, {useState} from 'react';
import { withTheme, withStyles, Modal, Paper, Typography, Button, LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getIconComponent } from '../../../utilities'
import ProfileButton from '../../UI/Buttons/ProfileButton'
import ProfileHeader from '../../UI/ProfileHeader/ProfileHeader3';
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
import axiosCerebrum from '../../../axios-cerebrum'
import { generateDefaultPayload, generateScheduleText } from '../utils';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {
    background:theme.palette.background.main,
    marginBottom: '1.5rem',
    display:'flex'
  },
  description:{
    fontSize:16,
    color:theme.palette.primaryText.light,
    width:830
  },
  title:{
    fontSize:33,
    marginBottom:16,
    color:theme.palette.header.main,
  },
  subtitle:{
    color:theme.palette.primaryText.main,
    // fontSize:13.75,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  modalRoot:{
    padding:24,
    // paddingBottom:8,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    margin:'20vh auto 0',
    maxHeight:'70vh',
    overflow:'auto'
  },
  modalTitle:{
    wordBreak:'break-word',
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:12
  },
  modalBodyText:{
    fontSize:16,
    color:theme.palette.primaryText.light,
    whiteSpace:'pre-wrap'
  },
  buttons:{
    marginTop:24,
    marginRight:-12,
    marginBottom:-16,
    display:'flex',
    justifyContent:'flex-end'
  },
});

function Header(props) {

  const {
    classes,
    state,
    theme,
    dispatch,
    tabState,
    setTabState,
    history,
  } = props;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [disableAccessModalOpen, setDisableAccessModalOpen] = useState(false)
  const [disableScheduleModalOpen, setDisableScheduleModalOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const {
    sendAlert
  } = useAlert({})

  const onDeactivate = source => {
    setDeleting(true)
    axiosCerebrum
      .post(
        '/api/platformbatches/6',
        {
          "adhoc_args":{
            "job": "_0070_deactivate_source",
            "source_id":source.source_id
          }
        }
      )
      .then(response=>{
        setDeleting(false);
        setTabState(0);
        setDeleteModalOpen(false);
        dispatch({type:'set_selected_source'});
        dispatch({type:'set_sources_updated',sourcesUpdated:true})
      })
      .catch(error=>{
        setDeleting(false)
        console.log(error);
        setAlertOpen(true)
        setAlertMessage("Error occurred deactivating source, please try again")
      })
  }

  const onDisableAccess = (source) => {
    let payload = generateDefaultPayload(source)

    payload.enable_access_docs = false;

    setDeleting(true);
    axiosCerebrum
      .put('/api/sources/'+source.source_id, payload)
      .then(response=>{
        setDeleting(false);
        setDisableAccessModalOpen(false)
        dispatch({type:'set_selected_source',selectedSource:{...response.data, source_id:response.data.id, id:response.data.host_ids[0]}})
        sendAlert({type:'info',message:`Successfully disabled access request for ${source.name}`})
      }).catch((error)=>{
        setDeleting(false)
        console.log(error);
        setAlertOpen(true)
        setAlertMessage(`Error occurred disabling access request for ${source.name}`)
      })
  }

  const setDisableSchedule = () => {
    let payload = generateDefaultPayload(state.selectedSource)
    payload.cron = {};
    payload.args.load_frequency = ''
    setDeleting(true)
    axiosCerebrum
      .put('/api/sources/'+state.selectedSource.source_id,payload)
      .then(response=>{
        dispatch({type:'set_tab_state',tabState:0});
        dispatch({type:'set_sources_updated',sourcesUpdated:true})
        setDeleting(false)
        setDisableScheduleModalOpen(false)
        sendAlert({type:'info',message:`Schedule successfully disabled`})
      }).catch((error)=>{
        console.log(error.response);
        setDeleting(false)
        setAlertOpen(true)
        setAlertMessage(`Error occurred disabling schedule, please try again`)
      })
  }


  let title;
  let subtitle;
  let description;

  if(tabState===0){
    title='Sources'
    description='Use this page to connect a new source and update an existing source connector. Contact KADA Support if a source connector is not available.'
  }

  if(tabState===1){
    title='Add a new source'
    subtitle=<span className={classes.subtitle} onClick={()=>{setTabState(0)}}>SOURCES</span>
    description='Use this form to add a new source to K'
  }

  if(tabState===2){
    title='Edit ' + state.selectedSource.name + ' Connection details'
    subtitle=<span className={classes.subtitle} onClick={()=>{setTabState(0)}}>SOURCES</span>
    description=`Use this form to update the ${state.selectedSource.name} connection details` 
  } 

  if(tabState===3){
    title=`Schedule ${state.selectedSource.name} load`
    subtitle=<span className={classes.subtitle} onClick={()=>{setTabState(0)}}>SOURCES</span>
    description=`Use this form to schedule how frequently a source is loaded` 
  } 

  if(tabState===4){
    title = `Map unlinked sources for ${state.selectedSource.name}`
    subtitle=<span><span className={classes.subtitle} onClick={()=>{setTabState(0);history.goBack()}}>SOURCES</span> / {state.selectedSource.name.toUpperCase()}</span>
    description=`Use this form to map ${state.selectedSource.name} sources that could not be linked by K automatically` 
  }

  if(tabState===5){
    title = `Edit Access Request for ${state.selectedSource.name}`
    subtitle=<span className={classes.subtitle} onClick={()=>{setTabState(0);history.goBack()}}>SOURCES</span>
    description=`Use this form to configure the Access Request information for users browsing ${state.selectedSource.name} assets in K` 
  }

  if(tabState===6){
    title = `Download collectors`
    subtitle=<span className={classes.subtitle} onClick={()=>{setTabState(0);history.goBack()}}>SOURCES</span>
    description=`Use this page to download a collector to extract metadata from your data source` 
  }

  if(tabState===7){
    title = `Add Reference Source`
    subtitle=<span className={classes.subtitle} onClick={()=>{setTabState(0);history.goBack()}}>SOURCES</span>
    description=`Use this form to add a new reference source in K` 
  }

  if(tabState===8){
    title = `Edit Reference Source`
    subtitle=<span className={classes.subtitle} onClick={()=>{setTabState(0);history.goBack()}}>SOURCES</span>
    description=`Use this form to edit reference source in K` 
  }


  const buttons = []

  if(tabState===2){
    // let unscheduled = state.selectedSource.active_flag===false || !state.selectedSource.cron || Object.keys(state.selectedSource.cron).length===0;
    let unscheduled = state.selectedSource.scheduled_flag===false
    if(state.selectedSource.active_flag===true ){
      buttons.push(
        <ProfileButton
          text="DEACTIVATE"
          onClick={()=>{setDeleteModalOpen(true)}}
          disabled={!unscheduled && state.selectedSource.job_mode!=="LOAD_FROM_FILE"}
          testID='deactive-button'
          tooltip={unscheduled?'':'Source is currently scheduled.\nStop the schedule before deactivating'}
        />
      )
    }
  }

  if(tabState===3 && state.selectedSource.scheduled_flag===true){
    buttons.push(
      <ProfileButton
        text="REMOVE SCHEDULE"
        onClick={()=>{setDisableScheduleModalOpen(true)}}
        testID='deactivate-schedule-button'
      />
    )
  }
  
  if(tabState===5 && state.selectedSource.enable_access_docs){
    buttons.push(
      <ProfileButton
        text="DISABLE"
        testID='disable-button'
        onClick={()=>setDisableAccessModalOpen(true)}
      />
    )
  }

  if([1,2,3,4,5,6,7,8].includes(tabState)){
    buttons.push(
      <ProfileButton
        text="CLOSE"
        onClick={()=>setTabState(0) && history.goBack()}
      />
    )
  }

  const onReactivate = () => {
    axiosCerebrum
      .put(
        `/api/sources/${state.selectedSource.source_id}`,{
          "source_template_id": state.selectedSource.source_template.id,
          "args": state.selectedSource.args,
          "name": state.selectedSource.name,
          "job_mode": state.selectedSource.job_mode,
          "full_load": state.selectedSource.full_load,
          "cron":state.selectedSource.cron,
          "active_flag":true,
          enable_access_docs: state.selectedSource.enable_access_docs,
          access_request_instructions: state.selectedSource.access_request_instructions,
          access_request_url: state.selectedSource.access_request_url,
          access_request_show_roles: state.selectedSource.access_request_show_roles
        }
      )
      .then(response=>{
        dispatch({type:'set_sources_updated',sourcesUpdated:true})
        dispatch({type:'set_selected_source',selectedSource:{...response.data, source_id:response.data.id, id:response.data.host_ids[0]}})
      })
      .catch(error=>{
        console.log(error)
        sendAlert({type:'error',message:'Error occurred reactivating the Source, please try again'})
      })
  }


  let bannerdisplayText, bannerCause, bannerAction;

  if ([3].includes(tabState)  && state.selectedSource && state.selectedSource.scheduled_flag===true && generateScheduleText(state.selectedSource)) {
    bannerdisplayText = `Current Schedule: ${generateScheduleText(state.selectedSource)}`;
    bannerCause = 'source_scheduled';
  }
  
  if ([2].includes(tabState)  && state.selectedSource && state.selectedSource.active_flag===false) {
    bannerdisplayText = `This source has been deactivated`;
    bannerCause = 'source_deactivation';
    bannerAction = onReactivate;
  }
  
  
  return (
    <div>
      <ProfileHeader
        title={title}
        label={'source_admin'}
        iconLabel={[4,2].includes(tabState)?getIconComponent({label:state.selectedSource.source_template.name})?state.selectedSource.source_template.name:'source':'source'}
        subtitle={tabState===0?' ':subtitle}
        description={description}
        buttons={buttons}
        minHeight={tabState===4?160:140}
        bannerCause={bannerCause}
        bannerdisplayText={bannerdisplayText}
        bannerAction={bannerAction}
      />
      {
        [2,3,5].includes(tabState) && state.selectedSource && 
        <Modal open={deleteModalOpen || disableAccessModalOpen || disableScheduleModalOpen} disableBackdropClick>
          <div style={{width:'max-content',margin:'auto',outline:'none'}}>
            {
              deleteModalOpen && 
              <Paper className={classes.modalRoot} style={{width:600}}>
                <Typography className={classes.modalTitle}>Are you sure?</Typography>
                {
                  deleting?
                  <div style={{textAlign:'center'}}>
                    <LinearProgress color='secondary' style={{height:6,width:'80%',margin:'auto',marginTop:32}}/>
                    <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Deactivation in progress</Typography>
                  </div>
                  :
                  <Typography className={classes.modalBodyText}>
                    {`Deactivating a source will flag all items in the source as deleted.\n\n`}
                    {`The deactivation process may take some time to complete. The source will be set to deactivated after the process is complete.\n\n`}
                    {`Items from this source will be not appear in Search (by default all deleted items are filtered in search results)`}
                  </Typography>
                }
                <div className={classes.buttons}>
                  <Button color='primary' disabled={deleting} onClick={()=>onDeactivate(state.selectedSource)} style={{marginRight:16}}>
                    DEACTIVATE
                  </Button>
                  <Button color='secondary' onClick={()=>setDeleteModalOpen(false)}>CANCEL</Button>
                </div>
              </Paper>
            }
            {
              disableAccessModalOpen && 
              <Paper className={classes.modalRoot} style={{width:400}}>
                <Typography className={classes.modalTitle}>Are you sure?</Typography>
                {
                  deleting?
                  <div style={{textAlign:'center'}}>
                    <LinearProgress color='secondary' style={{height:6,width:'80%',margin:'auto',marginTop:32}}/>
                    <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Disabling access request</Typography>
                  </div>
                  :
                  <Typography className={classes.modalBodyText}>
                    {`You are about to disable Access Request details for ${state.selectedSource.name}`}
                  </Typography>
                }
                <div className={classes.buttons}>
                  <Button color='primary' disabled={deleting} onClick={()=>onDisableAccess(state.selectedSource)} style={{marginRight:16}}>YES</Button>
                  <Button color='secondary' onClick={()=>setDisableAccessModalOpen(false)}>CANCEL</Button>
                </div>
              </Paper>
            }
            {
              disableScheduleModalOpen && 
              <Paper className={classes.modalRoot} style={{width:400}}>
                <Typography className={classes.modalTitle}>Are you sure?</Typography>
                {
                  deleting?
                  <div style={{textAlign:'center'}}>
                    <LinearProgress color='secondary' style={{height:6,width:'80%',margin:'auto',marginTop:32}}/>
                    <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Disabling schedule</Typography>
                  </div>
                  :
                  <Typography className={classes.modalBodyText}>
                    {`You are about to remove the scheduled load for ${state.selectedSource.name}`}
                  </Typography>
                }
                <div className={classes.buttons}>
                  <Button color='primary' disabled={deleting} onClick={()=>setDisableSchedule()} style={{marginRight:16}}>YES</Button>
                  <Button color='secondary' onClick={()=>setDisableScheduleModalOpen(false)}>CANCEL</Button>
                </div>
              </Paper>
            }
            <div style={{margin:'0 auto',width:disableAccessModalOpen?450:650}}>
              <ModalAlert
                open={alertOpen}
                setOpen={setAlertOpen}
                message={alertMessage}
                type='error'
              />
            </div>
          </div>
        </Modal>
      }
    </div>
    
  )

}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
  setTabState: PropTypes.func,
  sourcesFetchList: PropTypes.func.isRequired,
  dispatch:PropTypes.func.isRequired
}

export default withTheme()(withStyles(styles)(Header));