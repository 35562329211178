import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Checkbox } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import theme from '../../../../theme';
import KTooltip from '../../KTooltip/KTooltip';

const styles = theme => ({
  root: {
    '& .apexcharts-toolbar': {
      zIndex: 0
    },
    '& .apexcharts-legend-text': {
      paddingRight: '20px !important'
    },
  },
  legendText:{
    fontSize:12,
    color:theme.palette.primaryText.main
  },
  legendIcon:{
    
  },
  chartHeader:{
    fontSize:16,
    color:theme.palette.primary.main
  }
})


function SummaryChart(props) {
  const {
    classes,
    labels,
    // directUsage,
    // indirectUsage,
    data,
    dispatch,
    state,
    // databeforeReplacedByDate,
    // dataafterReplacedbyDate,
    replacedByDate,
    replacedByDateIndex,
    activeSeries,
    objectLabel,
    setActiveSeries,
  } = props;
  
  const [date, setDate] = useState();

  useEffect(() => {
    if (!date) return;
    dispatch({ type: 'set_selected_date', selectedDate: date });
  }, [date, dispatch])

  const getSeriesColour = (type, series) => {
    if(type==='direct' && series==='Reads')return '#00D46A';
    if(type==='indirect' && series==='Reads')return '#FC642D';
    if(type==='direct' && series==='Writes')return '#3C8FF2';
    if(type==='indirect' && series==='Writes')return '#FEB019';
    if(type==='direct' && series==='Runs')return '#7b8eab';
    if(type==='indirect' && series==='Runs')return '#c78763';
    if(type==='direct' && series==='Modifies')return '#F76A1C';
    if(type==='indirect' && series==='Modifies')return '#FC642D';
    if(type==='direct' && series==='Downloads')return '#3C8FF2';
    if(type==='indirect' && series==='Downloads')return '#FEB019';
  }


  const generateOption = variant => {
    return {
      colors:[getSeriesColour(state.usageType,variant)],
      tooltip:{
        x:{
          formatter:str=>{
            switch(state.usagePeriod){
              case -1:
                return moment(str).format('DD MMM')
              case 6:
                return moment(str).format('DD MMM')+ ' - ' + moment(str).add(1,'week').add(-1,'day').format('DD MMM')
              case 12:
                return moment(str).format('DD MMM')+ ' - ' + moment(str).add(1,'month').add(-1,'day').format('DD MMM')
              default:
                return moment(str).format('DD MMM')
            }
          }
        }
      },
      grid:{
        xaxis:{
          lines:{
            show:false
          }
        },
        borderColor:'#90A4AE30'
      },
      chart: {
        id:variant,
        toolbar:{
          show:false
        },
        zoom: {
          enabled: false
        },
        events: {
          click: function (e, chartContext, opts) {
            if (opts.seriesIndex >= 0) {
              if(opts.config.series[opts.seriesIndex].data[opts.dataPointIndex]===0)return;
              if (replacedByDate && opts.seriesIndex === 1) {
                if (opts.dataPointIndex === -1) {
                  setDate({date:chartContext.w.config.labels[replacedByDateIndex], variant})
                }
                else {
                  setDate({date:chartContext.w.config.labels[replacedByDateIndex + opts.dataPointIndex], variant})
                }
              }
              else {
                setDate({date:chartContext.w.config.labels[opts.dataPointIndex], variant})
              }
            }
          }

        }
      },
      annotations:
        replacedByDate?
          {
            position:'back',
            xaxis:[
              {
                x: Math.max(new Date(replacedByDate).getTime(),new Date(labels[0]).getTime()),
                x2: new Date().getTime(),
                fillColor: theme.palette.hovered.main,
                opacity: 0.5,
                label: {
                  textAnchor:new Date(replacedByDate).getTime()<(new Date(labels[0]).getTime()+new Date(labels[labels.length-1]).getTime())/2?'start':'end',
                  orientation:'horizontal',
                  position:'top',
                  borderColor: '#CED4DC',
                  style: {
                    fontSize: '12px',
                    color: '#000',
                    background: '#CED4DC',
                  },
                  offsetY: -5,
                  text: `Replaced on ${moment(replacedByDate).format('ll')}`,
                }
              }
            ]
          }:{},
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: 'true',
        curve: 'smooth',
        width: 2
      },
      labels,
      xaxis: {
        type: 'datetime',
        labels:{
          style:{
            colors:theme.palette.primaryText.main
          }
        }
      },
      yaxis: {
        labels: {
          formatter: function(val) {
            return val.toFixed(0)
          },
          style:{
            colors:theme.palette.primaryText.main
          }
        },
        seriesName:state.usageType==='direct'?'Usage':'Content',
        axisTicks:{
          show:true,
        },
        axisBorder: {
          show: true,
        },
      },
      legend: {
        show:false,
      },
      markers: {
        size: 2,
        style: 'full',
      },
    }
  }
  
  let readSeries = [{
    name: 'Reads',
    data: data.find(d=>d.labels==='Read').data,
  }];

  let writeSeries = [{
    name: 'Writes',
    data: data.find(d=>d.labels==='Write').data,
  }];

  let runSeries = [{
    name: 'Runs',
    data: data.find(d=>d.labels==='Run').data,
  }];

  let modifiesSeries = [{
    name: 'Modifies',
    data: data.find(d=>d.labels==='Modified').data,
  }];

  let downloadSeries = [{
    name: 'Downloads',
    data: data.find(d=>d.labels==='Downloads')?.data,
  }];
  
  const onToggleLegend = name => {
    if(activeSeries.includes(name)){
      setActiveSeries(activeSeries.filter(el=>el!==name));
    }
    else{setActiveSeries([...activeSeries,name])}
  }

  let usagePeriodText;
  if(state.usagePeriod===3)usagePeriodText = '90 days'
  if(state.usagePeriod===6)usagePeriodText =  '6 months'
  if(state.usagePeriod===12)usagePeriodText =  '1 year'

  return (
    <div className={classes.root}>
      <div style={{display:'flex',alignItems:'flex-start',flexWrap:'wrap',marginBottom:16,paddingRight:40,marginTop:-36}}>
        <div style={{width:368,height:40,flexShrink:0,flexGrow:1}}></div>
        <div style={{display:'flex'}}>
          {
            [
              {name:'Reads',colour:getSeriesColour(state.usageType,'Reads'), tooltip:'Any event that reads or views data such as running a query against a table or viewing a report'},
              {name:'Writes',colour:getSeriesColour(state.usageType,'Writes'), tooltip:'Any event that writes data such as inserting rows to a table or refreshing a reporting dataset'},
              {name:'Modifies',colour:getSeriesColour(state.usageType,'Modifies'), tooltip:'Any event that modifies the item such as updating the data structure of a table'},
              ['column','table','schema','database'].includes(objectLabel)?undefined:{name:'Runs',colour:getSeriesColour(state.usageType,'Runs'), tooltip:'Any event that runs a process such as running a data pipeline'},
              {name:'Downloads',colour:getSeriesColour(state.usageType,'Downloads'), tooltip:'Any event that extracts data such as a download from a report'},
            ].filter(el=>el!==undefined).map(el=>(
              <KTooltip title={el.tooltip}>
                <div style={{display:'flex',marginRight:24,alignItems:'center',cursor:'pointer'}} onClick={()=>onToggleLegend(el.name)}>
                  <Checkbox style={{paddingLeft:0}} checked={activeSeries.includes(el.name)} color='primary' />
                  <Typography className={classes.legendText}>{el.name.toUpperCase()}</Typography>
                </div>
              </KTooltip>
            ))
          }
        </div>
      </div>
      {
        activeSeries.length===0 && 
        <Typography style={{textAlign:'center',marginTop:48}}>No view selected</Typography>
      }
      {
        activeSeries.includes('Reads')?
          <div style={{marginBottom:16}}>
            <Typography className={classes.chartHeader}>Read usage over last {usagePeriodText}</Typography>
            <ReactApexChart options={generateOption('Reads')} series={readSeries} height='240' type="area" />
          </div>
          :null
      }
      {
        activeSeries.includes('Writes')?
          <div style={{marginBottom:16}}>
            <Typography className={classes.chartHeader}>Write usage over last {usagePeriodText}</Typography>
            <ReactApexChart options={generateOption('Writes')} series={writeSeries} height='240' type="area" />
          </div>
          :null
      }
      {
        activeSeries.includes('Modifies')?
          <div style={{marginBottom:16}}>
            <Typography className={classes.chartHeader}>Modified usage over last {usagePeriodText}</Typography>
            <ReactApexChart options={generateOption('Modifies')} series={modifiesSeries} height='240' type="area" />
          </div>
          :null
      }
      {
        activeSeries.includes('Runs')?
          <div style={{marginBottom:16}}>
            <Typography className={classes.chartHeader}>Run usage over last {usagePeriodText}</Typography>
            <ReactApexChart options={generateOption('Runs')} series={runSeries} height='240' type="area" />
          </div>
          :null
      }
      {
        activeSeries.includes('Downloads')?
          <div style={{marginBottom:16}}>
            <Typography className={classes.chartHeader}>Download usage over last {usagePeriodText}</Typography>
            <ReactApexChart options={generateOption('Downloads')} series={downloadSeries} height='240' type="area" />
          </div>
          :null
      }
    </div>
  );
}

SummaryChart.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  seriesName: PropTypes.string,
  range: PropTypes.number,
}

SummaryChart.defaultProps = {
  classes: {},
  seriesName: "",
  data: [],
  labels: [],
  dispatch: () => null,
}

export default withStyles(styles)(SummaryChart);