import { withStyles, Typography, CircularProgress, IconButton, Button } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { getIconComponent, onClickResultItem } from '../../../utilities';
import theme from '../../../theme'; 
import { getIconLabel } from '../../UI/SearchResults/utils';
import {withRouter} from 'react-router'
import InstanceEditModal from './InstanceEditModal';

const styles = theme => ({
  root:{
    overflow:'hidden',
    boxSizing: 'border-box',
  },
  listItem:{
    height:48,
    display:'flex',
    alignItems:'center',
    boxSizing: 'border-box',
    overflow:'hidden',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`
  },
  hoverItem:{
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  draggedEl:{
    opacity:0.3,
  },
  selected:{
    background:theme.palette.hovered.main
  },
  itemName:{
    fontSize:13.75,
    overflow:"hidden",
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    marginRight:8,
    width:'max-content',
    flexShrink:1,
    flexGrow:1,
  },
  iconButton:{
    // padding:8
  }
})


const DraggableEl = (props) => {

  const {
    classes,
    setDragging,
    history,
    dragging,
    item,
    dragPosition,
    onDrag,
    selectedItem,
    setSelectedItem,
    onDragEnd,
    onItemExpand,
    list,
    loading,
    rootCollection,
    editing,
    onDelete,
    onAddChild,
    pageMap,
    onLoadMore,
    dispatch,
    state
  } = props;
  
  const itemRef = useRef()
  const containerRef = useRef()
  const [iconHovered, setIconHovered] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)

  let children = list.filter(l=>l.upperLevelID===item.id);
  let hasChildren = (!item.childLoaded && item.has_children) || children.length>0 || (pageMap[item.id] && pageMap[item.id].page<pageMap[item.id].pages)
  
  const generateLevel = () => {
    let level = 0;
    let upperID = item.upperLevelID;
    while(upperID!==rootCollection.id){
      level += 1;
      // eslint-disable-next-line
      upperID = list.find(l=>l.id===upperID).upperLevelID
    }
    return level
  }

  let level = generateLevel();

  useEffect(()=>{
    if(!dragPosition){
      setSelectedItem()
      return;
    };
    let itemY = itemRef.current.getBoundingClientRect().y;
    let itemX = containerRef.current.getBoundingClientRect().x
    let itemHeight = itemRef.current.getBoundingClientRect().height;
    if(itemY>=window.innerHeight)return;
    if(itemY+itemHeight<=0)return;

    if(dragPosition.y > itemY && dragPosition.y < itemY + itemHeight){
      if(dragging===item.id){
        if(dragPosition.x<itemX+100){
          setSelectedItem(rootCollection.id)
        }else{
          setSelectedItem()
        }
      }else{
        setSelectedItem(item.id)
      }
    }
  // eslint-disable-next-line
  },[dragPosition, dragging])

  let isMovingOut = selectedItem===rootCollection.id && dragging===item.id
  
  return (
    <div 
      className={classes.root}  
      ref={containerRef}
      style={{
        maxHeight:item.expanded?undefined:48,
      }}
    >
      <div 
        id={`expandable-item-${item.id}`}
        data-test-id={`draggable-item-${item.id}`}
        data-test-classname={`instance-draggable-item`}
        ref={itemRef} 
        style={{
          marginLeft:isMovingOut?0:24*level
        }}
        draggable={editing}
        className={classes.listItem + (iconHovered ?'':' '+classes.hoverItem) + (dragging===item.id && !isMovingOut?' '+classes.draggedEl:'') + (selectedItem===item.id || isMovingOut ?' '+classes.selected:'')} 
        onDragStart={()=>{
          setDragging(item.id)
        }}
        onDragEnd={onDragEnd}
        onDrag={onDrag}
        onClick={(event)=>{
          event.stopPropagation()
          if(hasChildren)onItemExpand(item.id)
          else{onClickResultItem({item, id:item.id, label:'collection_instance', history})}
        }}
      >
        {
          editing && 
          <div
            data-test-id={`expandable-item-drag-${item.name.replace(/\s/g,'-')}`}
            data-test-classname={`expandable-item-drag`}
            style={{width:20,height:20,marginLeft:8,cursor:"grab"}}
          >
            {
              getIconComponent({label:'drag',size:20,colour:theme.palette.primaryText.light})
            }
        </div>
        }
        <IconButton 
          disabled
          data-test-id={`expandable-item-dropdown-${item.name.replace(/\s/g,'-')}`}
          data-test-classname={`expandable-item-dropdown`}
          style={{width:24,height:24,padding:6,marginLeft:editing?2:8,transition: "transform .3s ease",transform:hasChildren?`rotate(${item.expanded?90:0}deg)`:undefined}}
        >
          {
            getIconComponent({label:hasChildren?"expand":"dot",size:16,colour:theme.palette.primaryText.light})
          }
        </IconButton>

        <div style={{width:20,height:20,marginLeft:4,marginRight:8}}>
          {
            getIconComponent({
              label:getIconLabel({label:'collection_instance',item:rootCollection.object.name==='COLLECTION_INSTANCE'?rootCollection:{parent:rootCollection}}),
              size:20,
              colour:theme.palette.primaryText.light
            })
          }
        </div>
        <Typography 
          className={classes.itemName}
          data-test-id={`expandable-item-${item.name.replace(/\s/g,'-')}-name`}
          data-test-classname={`expandable-item-name`}
        >
          {item.name}
        </Typography>
        {
          editing && 
          <div style={{display:'flex',justifyContent:'flex-end',flexGrow:1}}>
            <IconButton 
              data-test-id={`expandable-item-edit-button-${item.name.replace(/\s/g,'-')}`}
              data-test-classname={`expandable-item-edit-button`}
              onClick={(event)=>{event.stopPropagation();setEditModalOpen(true)}} 
              className={classes.iconButton}
              onMouseEnter={()=>setIconHovered(true)}
              onMouseOut={()=>setIconHovered(false)}
              onMouseOver={()=>setIconHovered(true)}
            >
              {getIconComponent({label:'edit',size:24,colour:theme.palette.primaryText.light})}
            </IconButton>
            <IconButton 
              data-test-id={`expandable-item-add-button-${item.name.replace(/\s/g,'-')}`}
              data-test-classname={`expandable-item-add-button`}
              onClick={(event)=>{event.stopPropagation();onAddChild(item)}} 
              className={classes.iconButton}
              onMouseEnter={()=>setIconHovered(true)}
              onMouseOut={()=>setIconHovered(false)}
              onMouseOver={()=>setIconHovered(true)}
            >
              {getIconComponent({label:'add_circle',size:24,colour:theme.palette.primaryText.light})}
            </IconButton>
            <IconButton 
              data-test-id={`expandable-item-clear-button-${item.name.replace(/\s/g,'-')}`}
              data-test-classname={`expandable-item-clear-button`}
              onClick={(event)=>{event.stopPropagation();onDelete(item)}} 
              className={classes.iconButton}
              onMouseEnter={()=>setIconHovered(true)}
              onMouseOut={()=>setIconHovered(false)}
              onMouseOver={()=>setIconHovered(true)}
            >
              {getIconComponent({label:'clear_circle',size:24,colour:theme.palette.primaryText.light})}
            </IconButton>
          </div>
        }
        {
          !editing && hasChildren && 
          <div
            style={{marginRight:8}}
            onMouseEnter={()=>setIconHovered(true)}
            onMouseOut={()=>setIconHovered(false)}
            onMouseOver={()=>setIconHovered(true)}
          >
            <Button 
              data-test-id={`expandable-item-open-button-${item.name.replace(/\s/g,'-')}`}
              data-test-classname={`expandable-item-open-button`}
              id={`open-icon-${item.id}`}
              className={classes.iconButton}
              style={{padding:'1px 2px'}}
              color='primary'
              variant='outlined'
              onClick={event=>{
                event.stopPropagation()
                onClickResultItem({item, id:item.id, label:'collection_instance', history})
              }}
            >
              {
                // getIconComponent({label:'open',size:20,colour:theme.palette.primaryText.light})
                'OPEN'
              }
            </Button>
          </div>
        }
      </div>
      {
        children.length>0 && children.map(c=>(
          <DraggableEl
            classes={classes}
            dragging={dragging}
            setDragging={setDragging}
            item={c}
            dragPosition={dragPosition}
            onDrag={onDrag}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            onDragEnd={onDragEnd}
            onItemExpand={onItemExpand}
            list={list}
            loading={loading}
            rootCollection={rootCollection}
            editing={editing}
            onDelete={onDelete}
            onAddChild={onAddChild}
            pageMap={pageMap}
            onLoadMore={onLoadMore}
            state={state}
            dispatch={dispatch}
          />
        ))
      }
      {
        loading===item.id && 
        <div style={{marginTop:6, marginLeft:24+(isMovingOut?0:24*level)}}>
          <CircularProgress style={{width:16,height:16}} color='secondary'/>
        </div>
      }
      {
        pageMap[item.id] && pageMap[item.id].page<pageMap[item.id].pages &&
        <div style={{marginTop:6, marginLeft:24+(isMovingOut?0:24*level)}}>
          <Button 
            color='secondary' 
            disabled={loading===item.id} 
            style={{marginTop:4}}
            onClick={()=>onLoadMore(item.id)}
          >
            SEE MORE
          </Button>
        </div>
      }
      {
        editModalOpen && 
        <InstanceEditModal modalOpen={editModalOpen} setModalOpen={setEditModalOpen} data={item} dispatch={dispatch} state={state}/>
      }
    </div>
  );
};

export default withRouter(withStyles(styles)(DraggableEl));