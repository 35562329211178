import React, { useEffect } from 'react';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import VerticalTabBar from '../../../UI/VerticalTabBar/VerticalTabBar'
import DataChart from './DataChart';

const styles = theme => ({
  title:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    textTransform:'uppercase'
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  }
})

const CollectionSummaryBody = props => {

  const {
    // theme,
    state,
    classes,
    dispatch,
    collectionID,
    direction
  } = props;

  const data = state.summaryChartData[collectionID]
  const setData = d => dispatch({type:'set_summary_chart_data',summaryChartData:{...state.summaryChartData,[collectionID]:d}})

  const collection = state.extractCollectionDetails && state.extractCollectionDetails.find(c=>c.id===collectionID);
  const collectionName = collection && collection.name;
  const collectionShortName = collection && collection.short_name;
  
  useEffect(()=>{
    if(!data){
      setData({tabState:0})
    }
  // eslint-disable-next-line
  },[])


  if(!data)return <div></div>
  
  let title, subTitle, chartComponent;
  let impactText = direction==='downstream'?'IMPACTED':'DEPENDENT';
  switch(data.tabState){
    case 0:
      title = `${direction.toUpperCase()} ${impactText} DATA ASSETS BY ${collectionName}`;
      subTitle = `Count of ${direction} ${impactText.toLowerCase()} data assets by ${collectionName}`
      chartComponent = <DataChart key='data' state={state} data={data} setData={setData} collectionID={collectionID} collectionShortName={collectionShortName} collectionName={collectionName} variant='data'/>
      break;
    case 1:
      title = `${direction.toUpperCase()} ${impactText} CONTENT BY ${collectionName}`;
      subTitle = `Count of ${direction} ${impactText.toLowerCase()} content by ${collectionName}`
      chartComponent = <DataChart key="content" state={state} data={data} setData={setData} collectionID={collectionID} collectionShortName={collectionShortName} collectionName={collectionName} variant='content'/>
      break;
    default:
  }


  return (
    <div style={{display:'flex',alignItems:'flex-start'}}>
      <VerticalTabBar
        tabOptions={['BY DATA ASSET','BY CONTENT']}
        tabState={data.tabState || 0}
        setTabState={value=>setData({...data, tabState:value})}
      />
      <div style={{flexGrow:1,marginLeft:80,overflow:'hidden'}}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.subTitle}>{subTitle}</Typography>
        {
          collectionName && 
          chartComponent
        }
        {
          !collectionName && 
          <Typography>No instances found</Typography>
        }
      </div>
    </div>
    
  )
}

export default withTheme()(withStyles(styles)(CollectionSummaryBody));