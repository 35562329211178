import React, { useEffect, useRef, useState} from 'react';
import { withStyles, Typography, Button, Stepper, Step, StepLabel, StepContent, Select, FormControl, MenuItem, FormHelperText, InputBase, CircularProgress} from '@material-ui/core';
import theme from '../../../theme';
import axiosCerebrum from '../../../axios-cerebrum'
import { getIconComponent, validateEmail } from '../../../utilities'
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import KTooltip from '../../UI/KTooltip/KTooltip';
import { setPlatformSettings } from '../../../permissionChecker';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  title:{
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:8
  },
  block:{
    maxWidth:640,
    marginLeft:6,
    marginTop:8
  },
  buttons:{
    position:'fixed',
    backgroundColor:theme.palette.background.main,
    height:80,
    bottom:0,
    right:0,
    left:40,
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end',
    boxShadow:'0px -1px 5px 0px #DDD'
  },
  stepper:{
    marginBottom:120,
    backgroundColor:theme.palette.background.main,
    // paddingLeft:0
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
  selector: {
    ...theme.components.selector,
    '& div div':{
      paddingLeft:16,
      fontSize:16
    },
    width: 618,
    height:56,
    marginTop: 8,
  },
  inputBase:{
    ...theme.components.inputBase,
    width: 618,
    height:56,
    marginTop: 8,
  },
  inputField:{
    ...theme.components.inputBase,
    width:618,
    marginTop:8,
    '& textarea':{
      padding:'8px 16px'
    }
  },
  detailInputBase:{
    ...theme.components.inputBase,
    width:618,
    '& input':{
      padding:'16px 16px'
    }
  },
  checkbox:{
    color:theme.palette.primary.main
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  inputContainer:{
    marginBottom:16
  },
  tooltip:{
    fontSize:13.75,
  },
  inputTitle:{
    color:theme.palette.primary.main,
    fontSize:13.75,
    marginRight:8
  },
  focusBorder:{
    border: `1px solid ${theme.palette.error.main}`
  }
})


const CreateIntegration = props => {

  const {
    classes,
    state,
    dispatch,
    hasEmail,
    emailSettings
    // sessionData,
    // history
  } = props;

  const [details, setDetails] = useState(emailSettings);

  const firstLevelIDs = [5001,5002,5007,5006,5005];
  const authMethodID = 5007;
  const authDetails = {
    BASIC_AUTH:[5003,5004],
    MICROSOFT_OAUTH2:[4998,5000,4999,5003]
  }


  let platformAlertEmail;
  try{
    platformAlertEmail = JSON.parse(localStorage.getItem('platformSettings')).items.find(el=>el.id+''==='5011').value
  }catch{
    platformAlertEmail = ''
  }
  const [alertEmail, setAlertEmail] = useState(platformAlertEmail);
  
  const [creating, setCreating] = useState(false)
  const [integrationCreated, setIntegrationCreated] = useState(false)
  const [connectionStaus, setConnectionStatus] = useState()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  },[state.createIntegrationStep])

  const steps = [
    'Add SMTP details',
    'Add SMTP authentication details',
    'Add support email',
    (hasEmail?'Save':'Create') + ' integration'
  ]

  const detailsFilled = (type) => {
    let checkedDetails = [];
    if(type==='detail'){
      checkedDetails = firstLevelIDs.map(id=>details.find(el=>el.id===id))
    }
    if(type==='auth_detail'){
      let method = details.find(el=>el.id===authMethodID).value;
      if(!authDetails[method])method = 'BASIC_AUTH'
      checkedDetails = authDetails[method].map(id=>details.find(el=>el.id===id))
    }
    return checkedDetails.every(el=>el.value && el.value.trim()!=='')
  }

  const labelClickable = index => {
    if(index===1)return detailsFilled('detail')
    if(index===2)return detailsFilled('detail') && detailsFilled('auth_detail')
    if(index===3)return detailsFilled('detail') && detailsFilled('auth_detail') && validateEmail(alertEmail)
    return true
  }

  const getStepLabelText = index => {
    switch(index){
      case 0:
        return steps[0];
      case 1:
        return steps[1];
      case 2:
        return steps[2];
      case 3:
        return steps[3];
      default:
    }
  }

  const onReset = () => {
    setDetails(emailSettings)
    dispatch({type:'set_create_integration_step',createIntegrationStep:0})
    setAlertEmail(platformAlertEmail)
  } 

  // to do 
  const onLabelClick = index => {
    if(labelClickable(index))dispatch({type:'set_create_integration_step',createIntegrationStep:index})
  }

  const checkConnection = () => {
    setConnectionStatus({status:'loading'})
    axiosCerebrum
      .get('/api/notifications/email/test')
      .then(response=>{
        setConnectionStatus({status:'success',msg:'Great, you have successfully added an integration for Email. We have sent a test email to the support email you added.'})
      })
      .catch(error=>{
        let msg = 'Error occurred checking connection';
        if(error.response && error.response.data &&  error.response.data.errors){
          msg = 'error: '+ error.response.data.errors
        }
        setConnectionStatus({status:'error',msg})
      })
  }

  const onCreate = async () => {
    setCreating(true)
    try{
      if(emailSettings.some(el=>el.value!==details.find(d=>d.id===el.id).value)){
        for(let i=0; i<details.length; i++){
          if(details[i].id===5004 && Array.from(details[i].value).every(el=>el==='*'))continue
          await axiosCerebrum.put(
            `/api/settings/${details[i].id}`, 
            { value: details[i].value }
          )
        }
      }
      let alertEmailBody;
      await axiosCerebrum.get(
        `/api/settings/5011`
      ).then(alertEmailResp=>{
        alertEmailBody = alertEmailResp.data;
      })
      if(alertEmailBody.value!==alertEmail){
        await axiosCerebrum.put(
          `/api/settings/5011`, 
          { value: alertEmail }
        )
      }
      await axiosCerebrum.get(
        `/api/settings`,{params:{per_page:100}}
      ).then(response=>{
        localStorage.setItem('platformSettings',JSON.stringify(response.data))
        setPlatformSettings(response.data.items)
      })
      setCreating(false)
      setIntegrationCreated(true)
    }catch(error){
      console.log(error)
      setCreating(false)
      let msg = `Error occurred ${hasEmail?'updating':'creating'} the integration, please try again`;
      if(error?.response?.data?.errors){
        msg = JSON.stringify(error.response.data.errors)
      }
      sendAlert({message:msg,type:'error',timeout: 99999999})
    }
    checkConnection()
  }

  const onInputChange = (id,value) => {
    setIntegrationCreated(false);
    setConnectionStatus();
    setDetails(details.map(el=>{
      if(id!==el.id)return el;
      return {...el,value}
    }))
  }


  const generateDetailsInput = (type) => {
    let inputs = [];
    let settings = [];
    if(type==='detail'){
      settings = firstLevelIDs.map(id=>emailSettings.find(el=>el.id===id))
    }
    if(type==='auth_detail'){
      let method = details.find(el=>el.id===authMethodID).value;
      if(!authDetails[method])method = 'BASIC_AUTH'
      settings = authDetails[method].map(id=>emailSettings.find(el=>el.id===id))
    }
    settings.filter(el=>el).forEach(el=>{
      inputs.push(
        <div className={classes.inputContainer}>
          <div style={{display:'flex',alignItems:'center',marginBottom:8}}>
            <Typography className={classes.inputTitle}>
              {el.key}
            </Typography>
            <KTooltip title={el.description} classes={{tooltip:classes.tooltip}} placement="right">
              {getIconComponent({label:'info',size:20,colour:theme.palette.primary.main})}
            </KTooltip>
          </div>
          {
            el.type==='BOOLEAN' && 
            <FormControl>
              <Select
                className={classes.selector}
                value={details.find(d=>d.id===el.id).value===''?'Select a value':details.find(d=>d.id===el.id).value}
                onChange={event=>{onInputChange(el.id,event.target.value)}}
              > 
                <MenuItem className={classes.menuItem} value={'Select a value'} disabled>
                  Select a value
                </MenuItem>
                {
                  ['true','false'].map(el=>(
                    <MenuItem className={classes.menuItem} value={el}>
                      {el}
                    </MenuItem>
                  ))
                }
              </Select>
              <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>{'Required'}</FormHelperText>
            </FormControl>
          }
          {
            el.dropdown_values?.length>0 && 
            <FormControl>
              <Select
                className={classes.selector}
                value={details.find(d=>d.id===el.id).value===''?'Select a value':details.find(d=>d.id===el.id).value}
                onChange={event=>{onInputChange(el.id,event.target.value)}}
              > 
                <MenuItem className={classes.menuItem} value={'Select a value'} disabled>
                  Select a value
                </MenuItem>
                {
                  el.dropdown_values.map(el=>(
                    <MenuItem className={classes.menuItem} value={el}>
                      {el}
                    </MenuItem>
                  ))
                }
              </Select>
              <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>{'Required'}</FormHelperText>
            </FormControl>
          }
          {
            el.type!== 'BOOLEAN' && (!el.dropdown_values || el.dropdown_values.length===0) &&
            <FormControl>
              <InputBase
                value={details.find(d=>d.id===el.id).value}
                className={classes.detailInputBase + (details.find(d=>d.id===el.id).value===''?' '+classes.focusBorder:'')}
                hidden={true}
                type={el.type==='PASSWORD'?'password':undefined}
                onChange={event=>{onInputChange(el.id,event.target.value)}}
                placeholder={el.description}
              />
              <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>{'Required'}</FormHelperText>
            </FormControl>
          }
        </div>
      )
    })
    return inputs;
  }

  const detailsInput = (
    <div className={classes.block}>
      {generateDetailsInput('detail')}

      <Button style={{marginTop:16}} onClick={()=>dispatch({type:'set_create_integration_step',createIntegrationStep:1})} disabled={!detailsFilled('detail')} color="primary" variant="contained" >NEXT</Button>
    </div>
  )

  const authDetailsInput = (
    <div className={classes.block}>
      {generateDetailsInput('auth_detail')}

      <Button style={{marginTop:16}} onClick={()=>dispatch({type:'set_create_integration_step',createIntegrationStep:2})} disabled={!detailsFilled('auth_detail')} color="primary" variant="contained" >NEXT</Button>
    </div>
  )

  const supportEmailInput = (
    <div className={classes.block}>
      <div className={classes.inputContainer}>
        <div style={{display:'flex',alignItems:'center',marginBottom:8}}>
          <Typography className={classes.inputTitle}>
            Support email
          </Typography>
        </div>
        <FormControl>
          <InputBase
            value={alertEmail}
            className={classes.detailInputBase + (alertEmail===''?' '+classes.focusBorder:'')}
            onChange={event=>{setAlertEmail(event.target.value)}}
            placeholder={'Add a support email'}
          />
          <FormHelperText style={{marginLeft:18, color:!validateEmail(alertEmail)?theme.palette.error.main:theme.palette.primaryText.light}}>
            {!validateEmail(alertEmail)?'Email address invalid':'Required'}
          </FormHelperText>
        </FormControl>
      </div>
      <Button style={{marginTop:16}} onClick={()=>dispatch({type:'set_create_integration_step',createIntegrationStep:3})} disabled={!validateEmail(alertEmail)} color="primary" variant="contained" >NEXT</Button>
    </div>
  )
  
  const createButton = (
    <div className={classes.block}>
      {
        creating && <div style={{marginTop:16}}><CircularProgress style={{width:16,height:16,color:theme.palette.secondary.main}} color='secondary' /></div>
      }
      {
        !creating && (!integrationCreated || (connectionStaus && connectionStaus.status==='error') )&& <Button style={{marginTop:16}} disabled={connectionStaus && connectionStaus.status==='loading'} onClick={onCreate} color="primary" variant="contained" >{hasEmail?'SAVE':'CREATE'}</Button>
      }
      {
        integrationCreated && !creating &&
        <div style={{marginTop:8}}>
          { 
            connectionStaus && connectionStaus.status==='loading' && 
            <Typography style={{fontSize:13.75,color:theme.palette.secondary.main}}>Validating connection <span style={{marginLeft:8}}><CircularProgress color='secondary' style={{width:16,height:16,color:theme.palette.secondary.main}}/></span></Typography>
          }
          {
            connectionStaus && connectionStaus.status==='error' && 
            <Typography style={{fontSize:13.75,color:theme.palette.error.main}}>{connectionStaus.msg}</Typography>
          }
          {
            connectionStaus && connectionStaus.status==='success' && 
            <div>
              <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginBottom:8}}>{connectionStaus.msg}</Typography>
              <SimpleResultItem
                item={{id:'email_integration',name:details.find(el=>el.key==='platform_email_smtp_sender_email').value}}
                label={'email'}
                titleColour={theme.palette.primaryText.main}
                title={alertEmail}
                subTitle={details.find(el=>el.key==='platform_email_smtp_server').value}
                hideRight={true}
                showUnderline
                onClick={()=>dispatch({type:'set_tab_state',tabState:1})}
              />
            </div>
          }
        </div>
      }
    </div>
  )

  const getStepContent = index => {
    switch(index){
      case 0:
        return detailsInput
      case 1:
        return authDetailsInput
      case 2:
        return supportEmailInput
      case 3:
        return createButton
      default:
    }
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>INTEGRATION DETAILS</Typography>

      <Stepper activeStep={state.createIntegrationStep} orientation="vertical" className={classes.stepper} >
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel  className={classes.step} style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}}>
              <Typography color='primary' style={{color:state.settingStep<index?theme.palette.primaryText.light:theme.palette.primaryText.main,fontSize:16,marginLeft:6}}>
                {getStepLabelText(index)}
              </Typography> 
            </StepLabel>
            <StepContent>
             {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      
      <div className={classes.buttons}>
        <Button color='primary' onClick={onReset} style={{height:48,width:120}} >
          RESET
        </Button>
        <Button variant='contained' color='primary' style={{marginLeft:30,height:48,marginRight:180,width:120}} onClick={()=>dispatch({type:'set_tab_state',tabState:1})}>
          {integrationCreated?"CLOSE":"CANCEL"}
        </Button>
    </div>
    </div>
  )
}


export default withStyles(styles)(CreateIntegration);