import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Select, MenuItem } from '@material-ui/core';
import { getIconComponent } from '../../../utilities'
import { lighten } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  block: {
    marginBottom: '5rem',
    // width: '40rem'
  },
  subTitle: {
    color: theme.palette.primaryText.main,
    fontSize: 20,
    marginBottom:2
  },
  subLine: {
    width:640,
    color: theme.palette.primaryText.light,
    whiteSpace:'pre-wrap',
    fontSize: 12,
    marginBottom:16
  },
  welcomeTitle:{
    fontSize:20,
    color:theme.palette.primaryText.main,
    marginBottom:6
  },
  welcomeText:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    whiteSpace:'pre-wrap',
    marginBottom:40,
    lineHeight:2.25
    // width:640,
  },
  selector:{
    ...theme.components.selector,
    width:'100%',
    height:50,
    '& div div':{
      padding:'6px 16px',
      fontSize:16
    }
  },
  avatar: {
    fontSize: 12,
    height: 24,
    width: 24,
    backgroundColor: theme.palette.avatar.main,
    marginRight:12
  },
  itemChip:{
    width:'max-content',
    maxWidth:'100%',
    overflow:'hidden',
    display:'flex',
    alignItems:'center',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    borderRadius:16,
    padding:'4px 6px',
    marginRight:8,
    marginBottom:8,
    '& span':{
      padding:'0 8px',
      fontSize:13.75,
      // marginLeft:4
    }
  },
  targetItemChip:{
    background:theme.palette.primary.dark,
    color:theme.palette.background.main
  },
  clearButton:{
    marginLeft:16,
    width:16,
    height:16,
    '&:hover':{
      background:`${lighten(theme.palette.primary.dark,0.4)} !important`
    }
  }
})

const KSettings = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
  } = props;

  const onChangeReceiveEmail = value => {
    dispatch({type:'set_receive_email',receiveEmail:value})
  }

  const onChangeDarkMode = value => {
    dispatch({type:'set_is_dark_mode',isDarkMode:value})
  }

  return (
    <div>
      <div className={classes.block}>
        <Typography className={classes.welcomeTitle}>Your K Settings</Typography>
        <Typography className={classes.welcomeText}>
          Personalise your K experience. 
          {'\n'}
          You can change these settings anytime through the Profile Settings page.
        </Typography>

        <Typography className={classes.subTitle} style={{marginTop:46}}>Display Preferences</Typography>
        <Typography className={classes.subLine}>
          You can choose between light and dark mode
        </Typography>
        <div style={{width:450}}>
          <Select
            className={classes.selector}
            value={state.isDarkMode}
            disableUnderline
            onChange={event=>onChangeDarkMode(event.target.value)}
          >
            <MenuItem value={false}>
              Light mode
            </MenuItem>
            <MenuItem value={true}>
              Dark mode
            </MenuItem>
          </Select>
        </div>

        <Typography className={classes.subTitle} style={{marginTop:46}}>Daily Briefing</Typography>
        <Typography className={classes.subLine}>
          Receive a daily email with the latest news from your data ecosystem
        </Typography>
        <div style={{width:450}}>
          <Select
            className={classes.selector}
            value={state.receiveEmail}
            disableUnderline
            onChange={event=>onChangeReceiveEmail(event.target.value)}
            renderValue={value=>{
              return (
                <div style={{display:'flex',alignItems:'center',padding:0}}>
                  <div style={{width:24,height:24,marginRight:16,padding:0}}>{getIconComponent({label:'email',size:24,colour:theme.palette.primary.main})}</div>
                  <Typography style={{fontSize:16,color:theme.palette.primaryText.main}}>
                    {value==='true'?'Yes I want to receive the daily briefing':'No I do not want to receive the daily briefing'}
                  </Typography>
                </div>
              )
            }}
          >
            <MenuItem value={'true'}>
              Yes I want to receive the daily briefing
            </MenuItem>
            <MenuItem value={'false'}>
              No I do not want to receive the daily briefing
            </MenuItem>
          </Select>
        </div>
      </div>
    </div>
  )
}

KSettings.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
}

export default withTheme()(withStyles(styles)(KSettings));