import React, { useState } from 'react';
import { withTheme,withStyles, Typography, InputBase, IconButton, Button} from '@material-ui/core';
import { getIconComponent } from '../../../utilities';
import SearchSelector from '../SearchSelector/SearchSelector'

const styles = theme => ({
  root: {

  },
  inputBase:{
    ...theme.components.inputBase,
    height:42,
    width:'100%'
  },
  listContainer:{
    '&::-webkit-scrollbar':{
      width: '3px'
    },
    /* Track */
    '&::-webkit-scrollbar-track':{
      background: '#eee',
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb':{
      background: '#bbb',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover':{
      // background: #555;
    },
    flexGrow:1,
    overflow:'auto',
    marginBottom:16,
  },
  listItem:{
    width:'100%',
    height:48,
    display:'flex',
    overflow:'hidden',
    alignItems:'center',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
  }
})

const Results = props => {

  const {
    classes,
    theme,
    users,
    type,
    setType,
    onAddUser,
    onAddEmail,
    onRemoveUser,
    customRightPadding
  } = props;

  const [modalInput, setModalInput] = useState('');

  return (
    <div>
      <Typography style={{marginTop:24,marginBottom:8,fontSize:16}}>Add contact by</Typography>
      <div style={{paddingRight:customRightPadding,marginBottom:24,flexGrow:0,flexShrink:0,display:'flex'}}>
        <Button
          color='primary'
          variant={type==='email'?'contained':'outlined'}
          style={{marginRight:16}}
          onClick={()=>{setModalInput('');setType('email')}}
        >
          EMAIL
        </Button>
        <Button
          color='primary'
          variant={type==='user'?'contained':'outlined'}
          onClick={()=>{setModalInput('');setType('user')}}
        >
          USERNAME
        </Button>
      </div>
      <div style={{paddingRight:customRightPadding,flexGrow:0,flexShrink:0}}>
        {
          type==='user' && 
          <SearchSelector 
            url='/solr/search/select'
            params={{
              q: `${modalInput}*`,
              fq:'object_type_srt:USER AND account_type_srt:"USER_ACCOUNT" AND -merge_type_srt:CANDIDATE',
              fl:'*',
              rows:10
            }}
            autoFocus
            searchValue={modalInput}
            setSearchValue={setModalInput}
            placeholder='Search by username' 
            onResultClick={el=> {
              let newUser = {...el};
              for(let key in newUser){
                if(key.endsWith('_txt')){
                  newUser[key.replace('_txt','_srt')] = newUser[key];
                }
                if(key.endsWith('_txts')){
                  newUser[key.replace('_txts','_msrt')] = newUser[key];
                }
              }
              onAddUser(newUser)
              setModalInput('')
            }}
          />
        }
        {
          type==='email' && 
          <div style={{display:'flex',alignItems:'center'}}>
            <div style={{flexGrow:1}}>
              <InputBase
                value={modalInput}
                onChange={(event)=>setModalInput(event.target.value)}
                className={classes.inputBase}
                autoFocus
                style={{height:48}}
                placeholder={'Add contact email'}
                onKeyDown={event=>{
                  if(event.key==='Enter' && modalInput.trim()!=='' && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(modalInput)){
                    onAddEmail(modalInput)
                    setModalInput('')
                  }
                }}
                endAdornment={
                  <Button 
                    color='primary' 
                    style={{marginLeft:8,marginRight:8}} 
                    disabled={modalInput.trim()==='' || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(modalInput)} 
                    onClick={()=>{
                      onAddEmail(modalInput)
                      setModalInput('')
                    }}
                  >
                    ADD
                  </Button>
                }
              />
            </div>
            
          </div>
        }
      </div>

      <Typography color='primary' style={{fontSize:12,letterSpacing:2,marginTop:24,marginBottom:8,flexGrow:0,flexShrink:0}}>USER(S) ADDED</Typography>
      <div className={classes.listContainer} style={{paddingRight:customRightPadding}}>
        {
          users?.map(el=>(
            <div className={classes.listItem}>
              <div style={{marginLeft:16,marginRight:16,flexShrink:0,flexGrow:0}}>
                {getIconComponent({label:el.object_type_srt,size:24,colour:theme.palette.primaryText.main})}
              </div>
              <div style={{flexGrow:1,overflow:'hidden'}}>
                <Typography style={{color:theme.palette.primaryText.main,fontSize:16,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}>{el.name_srt}</Typography>
                {
                  el.teams_msrt && 
                  <Typography style={{color:theme.palette.primaryText.light,fontSize:13.75,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}>{el.teams_msrt}</Typography>
                }
              </div>
              <IconButton style={{width:48,height:48,marginRight:16,marginLeft:16}} onClick={()=>onRemoveUser(el)}>
                {getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light})}
              </IconButton>
            </div>
          ))
        }
        {
          users?.length===0 && 
          <Typography style={{color:theme.palette.primaryText.light,fontSize:13.75,marginTop:16}}>No user(s) added</Typography>
        }
      </div>
    </div>
  )
}

export default withTheme()(withStyles(styles)(Results));