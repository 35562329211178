import React, { useEffect, useReducer } from 'react';
import { withStyles } from '@material-ui/core';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
// import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/InstanceLinking/Body/Body'
import { setHelpWdigetVisibility } from '../../utilities';
import { addHistory } from '../../HistoryManager';
import { adminPageInfo } from '../NavBar/utils';
// import ProfileButton from '../../components/UI/Buttons/ProfileButton'

const styles = theme => ({
});

const initialState = {
  tabState:0,
  extractFilters:{},
  linkingRuleSearchValue:'',
  linkingRuleCollection:'all',
  addRuleModalOpen:false,
  deleteRuleModalOpen:false,
  addRuleSelectedCollection:"none",
  addRuleSelectedInstance:'none',
  addRuleSelectedObjectType:'none',
  addRuleRegex:'',
  addRuleRegexValidation:{validated:false,error:undefined},
  dataTypes:[]
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState:action.tabState
      }
    case 'set_data_types':
      return {
        ...state,
        dataTypes:action.dataTypes
      }
    case 'set_linking_rules':
      return {
        ...state,
        linkingRules:action.linkingRules,
        linkingRulesLoading:action.linkingRulesLoading,
        linkingRulesError:action.linkingRulesError
      }
    case 'set_linking_rule_search_value':
      return {
        ...state,
        linkingRuleSearchValue:action.linkingRuleSearchValue
      }
    case 'set_linking_rules_available_collections':
      return {
        ...state,
        linkingRulesAvailableCollections:action.linkingRulesAvailableCollections
      }
    case 'set_linking_rule_collection':
      return {
        ...state,
        linkingRuleCollection:action.linkingRuleCollection
      }
    /// add rule modal
    case 'set_add_rule_collection_list':
      return {
        ...state,
        addRuleCollectionList:action.addRuleCollectionList
      }
    case 'set_add_rule_instance_list':
      return {
        ...state,
        addRuleInstanceList:action.addRuleInstanceList
      }
    case 'set_add_rule_modal_open':
      return {
        ...state,
        addRuleModalOpen:action.addRuleModalOpen
      }
    case 'set_delete_rule_modal_open':
      return {
        ...state,
        deleteRuleModalOpen:action.deleteRuleModalOpen
      }
    case 'set_add_rule_selected_collection':
      return {
        ...state,
        addRuleSelectedCollection:action.addRuleSelectedCollection
      }
    case 'set_add_rule_selected_instance':
      return {
        ...state,
        addRuleSelectedInstance:action.addRuleSelectedInstance
      }
    case 'set_add_rule_selected_object_type':
      return {
        ...state,
        addRuleSelectedObjectType:action.addRuleSelectedObjectType
      }
    case 'set_add_rule_regex':
      return {
        ...state,
        addRuleRegex:action.addRuleRegex
      }
    case 'set_add_rule_regex_validation':
      return {
        ...state,
        addRuleRegexValidation:action.addRuleRegexValidation
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

const InstanceLinking = props => {

  const {
    history,
  } = props;

  const [state, dispatch] = useReducer(reducer,initialState)

  useEffect(()=>{
    setHelpWdigetVisibility(true)
    return ()=>{setHelpWdigetVisibility(false)}
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    addHistory({
      url:window.location.pathname, 
      iconLabel:adminPageInfo.linkingRules.icon, 
      title: adminPageInfo.linkingRules.name, 
      subTitle:adminPageInfo.linkingRules.description,
      type:'application',
    })
  },[])

  useEffect(()=>{
    const dataTypes = [
      'TABLE',
      'COLUMN',
      'SCHEMA',
      'DATABASE',
      'CONTENT',
      'CONTENT_APP',
      'CONTENT_CHILD',
      'DATASET',
      'DATA_PIPELINE',
      'FILE',
      'STORAGE',
      'TOOL',
      'MACRO',
      'PROCEDURE'
    ];
    dispatch({type:'set_data_types',dataTypes:dataTypes})
  // eslint-disable-next-line
  },[])

  // let buttons = [];

  // buttons.push(
  //   <ProfileButton
  //     onClick={() => dispatch({type:'set_add_rule_modal_open',addRuleModalOpen:true})}
  //     iconLabel='add'
  //     text='NEW LINKING RULE'
  //   />
  // )

  return (
    <div>
      <ProfileLayout
        header={(
          <ProfileHeader
            title='Linking Rules'
            label="instance_linking_admin"
            iconLabel="instance_linking"
            subtitle=' '
            // buttons={buttons}
            description=' '
          />
        )}
        // tabBar={
        //   <TabBar
        //     tabOptions={['LINKING RULES']}
        //     tabState={state.tabState}
        //     setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
        //     minWidth={200}
        //     maxWidth={200}
        //     disableUnderline={true}
        //   />
        // }
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
          />
        }
      />
    </div>)
}

export default withStyles(styles)(InstanceLinking);
