import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, MenuItem, Checkbox, InputBase, Typography, Popper, Paper } from '@material-ui/core';
import { autoCollectionShortNames, getIconComponent, platformCollectionShortNames, toTitleCase } from '../../../../../../utilities';
import ToggleButton from '../../../../ToggleButton/ToggleButton';
import UserChip from '../../../../Chips/UserChip';

const styles = theme => ({
  root:{
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    flexWrap:'wrap',
    maxWidth:'100%',
    // height:34,
    flexGrow:1,
    flexShrink:0,
    // width:'max-content',
    marginRight:24
  },
  selector: {
    ...theme.components.titleSelector,
    height:24,
    borderRadius:12,
    maxWidth:180,
    '& div div':{
      fontSize:13,
      width:'max-content',
      maxWidth:146,
      padding:'1px 24px 0px 8px'
    }
  },
  button:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    display:'block',
    textTransform:'none',
    padding:0,
    marginTop:2,
    marginLeft:2,
    minWidth:0,
    paddingLeft:6,
    paddingRight:6,
    '& span':{
      fontSize:12
    }
  },
  inputBase:{
    ...theme.components.inputBase,
    width:'100%',
    height:42,
    borderBottom:`1px solid ${theme.palette.primaryText.main}`,
    marginBottom:8
  },
  selectPaper:{
    padding:0,
    minWidth:240
  },
  buttonLabel:{
    fontSize:12,
    lineHeight:'normal',
    textTransform:'none',
  },
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    marginLeft:8,
    marginTop:20,
    letterSpacing:1,
    marginBottom:4
  },
  popperPaper:{
    maxHeight:'60vh',
    overflow:'auto',
    ...theme.components.customScroll
  }
})

function NodeFilter(props) {
  const {
    classes,
    theme,
    focusView,
    onChangeFocus,
    subFocusView,
    onChangeSubFocus,
    onToggleType,
    onToggleInclude,
    type, //and/or
    isExclude,
    childrenMap
  } = props;

  const nodes = [];
  Object.keys(childrenMap).forEach(k=>{
    nodes.push(...childrenMap[k])
  })

  const [subFocusSearch, setSubFocusSearch] = useState('')

  const [subAnchor, setSubAnchor] = useState()
  const [hoveredFocus, setHoveredFocus] = useState()
  const popperExitTimeout = useRef()

  const getDefaultSubFocus = value => {
    if(value==='none'){
      return [];
    }
    return generateSubOptions(value).map(el=>el.value)
  }

  const generateSubOptions = (forceFocusView) => {
    let view = forceFocusView ;
    if(view==='none')return;
    let objectNodes = nodes
    let options = [];
    if(view==='trust'){
      let highestCount = objectNodes.filter(n=>n.data?.obj?.trust==='HIGHEST').length
      if(highestCount)options.push({ value: 'HIGHEST', label: 'HIGHEST', count: highestCount })
      let highCount = objectNodes.filter(n=>n.data?.obj?.trust==='HIGH').length
      if(highCount)options.push({ value: 'HIGH', label: 'HIGH', count: highCount })
      let mediumCount = objectNodes.filter(n=>n.data?.obj?.trust==='MEDIUM').length
      if(mediumCount)options.push({ value: 'MEDIUM', label: 'MEDIUM', count: mediumCount })
      let lowCount = objectNodes.filter(n=>n.data?.obj?.trust==='LOW').length
      if(lowCount)options.push({ value: 'LOW', label: 'LOW', count: lowCount })
      let lowestCount = objectNodes.filter(n=>n.data?.obj?.trust==='LOWEST').length
      if(lowestCount)options.push({ value: 'LOWEST', label: 'LOWEST', count: lowestCount})
      let noCount = objectNodes.filter(n=>!n.data?.obj?.trust).length
      if(noCount)options.push({ value: 'NONE', label: 'NO TRUST CALCULATED',count: noCount })
    }
    if(view==='classification'){
      let classifications = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.classification_kc_txts){
          n.data?.obj?.classification_kc_txts.forEach(c=>{
            if(!classifications.find(cl=>cl.val===c)){
              classifications.push({val:c,count:1})
            }
            else{
              let index = classifications.findIndex(cl=>cl.val===c)
              classifications[index].count++
            }
          })
        }
      })
      options = classifications.map(c=>({value:c.val,label:c.val,count:c.count}))
      let noCount = objectNodes.filter(n=>!n.data?.obj?.classification_kc_txts).length
      if(noCount)options.push({value:'NONE',label:'NO CLASSIFICATION', count:noCount})
    }
    if(view==='domain'){
      let domains = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.domain_kc_txts){
          n.data?.obj?.domain_kc_txts.forEach(d=>{
            if(!domains.find(dm=>dm.val===d)){
              domains.push({val:d,count:1})
            }else{
              let index = domains.findIndex(dm=>dm.val===d)
              domains[index].count++
            }
          })
        }
      })
      options = domains.map(d=>({value:d.val,label:d.val,count:d.count}))
      let noCount = objectNodes.filter(n=>!n.data?.obj?.domain_kc_txts).length
      if(noCount)options.push({value:'NONE',label:'NO DOMAIN', count:noCount})
    }
    if(view==='category'){
      let categories = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.category_kc_txts){
          n.data?.obj?.category_kc_txts.forEach(c=>{
            if(!categories.find(ct=>ct.val===c)){
              categories.push({val:c,count:1})
            }else{
              let index = categories.findIndex(ct=>ct.val===c)
              categories[index].count++
            }
          })
        }
      })
      options = categories.map(c=>({value:c.val,label:c.val,count:c.count}))
      let noCount = objectNodes.filter(n=>!n.data?.obj?.category_kc_txts).length
      if(noCount)options.push({value:'NONE',label:'NO CATEGORY', count:noCount})
    }
    if(view==='verified'){
      let verified = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.verified_kc_txts){
          n.data?.obj?.verified_kc_txts.forEach(v=>{
            if(!verified.find(vr=>vr.val===v)){
              verified.push({val:v,count:1})
            }else{
              let index = verified.findIndex(vr=>vr.val===v)
              verified[index].count++
            }
          })
        }
      })
      options = verified.map(v=>({value:v.val,label:v.val,count:v.count}))
      let noCount = objectNodes.filter(n=>!n.data?.obj?.verified_kc_txts).length
      if(noCount)options.push({value:'NONE',label:'NO USE CASE', count:noCount})
    }
    if(view==='not_verified'){
      let notVerified = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.not_verified_kc_txts){
          n.data?.obj?.not_verified_kc_txts.forEach(v=>{
            if(!notVerified.find(vr=>vr.val===v)){
              notVerified.push({val:v,count:1})
            }else{
              let index = notVerified.findIndex(vr=>vr.val===v)
              notVerified[index].count++
            }
          })
        }
      })
      options = notVerified.map(v=>({value:v.val,label:v.val,count:v.count}))
      let noCount = objectNodes.filter(n=>!n.data?.obj?.not_verified_kc_txts).length
      if(noCount)options.push({value:'NONE',label:'NO USE CASE', count:noCount})
    }
    if(view==='dq_score'){
      for(let i=0; i<9; i++){
        let low = i*10;
        let high = i*10+(i===9?10:9);
        let count = objectNodes.filter(n=>n.data?.obj?.dq_score_overall_srt>=low && n.data?.obj?.dq_score_overall_srt<=high).length
        if(count){
          options.push({value:`${low} TO ${high}`,label:`${low} TO ${high}`,count})
        }
      }
      let noCount = objectNodes.filter(n=>isNaN(n.data?.obj?.dq_score_overall_srt)).length
      if(noCount)options.push({value:'NONE',label:'UNKNOWN',count:noCount})
    }
    if(view==='dq_failed'){
      options.push({value:'last_7d',label:'FAILED (LAST 7D)'})
    }
    if(view==='owned_txt'){
      let ownedCount = objectNodes.filter(n=>n.data?.obj?.owned_txt==='IS_OWNED').length
      if(ownedCount)options.push({value:'IS_OWNED',label:'OWNED',count:ownedCount})
      let notOwnedCount = objectNodes.filter(n=>n.data?.obj?.owned_txt==='NOT_OWNED').length
      if(notOwnedCount)options.push({value:'NOT_OWNED',label:'NOT OWNED',count:notOwnedCount})
    }
    if(view==='steward_count'){
      let noneCount = objectNodes.filter(n=>n.data?.obj?.steward_count===0).length
      if(noneCount)options.push({value:'NONE',label:'NONE',count:noneCount})
      let oneCount = objectNodes.filter(n=>n.data?.obj?.steward_count===1).length
      if(oneCount)options.push({value:'ONE',label:'ONE',count:oneCount})
      let manyCount = objectNodes.filter(n=>n.data?.obj?.steward_count>1).length
      if(manyCount)options.push({value:'MANY',label:'MANY',count:manyCount})
    }
    if(view==='documented'){
      let documented = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.documented_txt){
          if(!documented.find(d=>d.val===n.data?.obj?.documented_txt)){
            documented.push({val:n.data?.obj?.documented_txt,count:1} )
          }else{
            let index = documented.findIndex(d=>d.val===n.data?.obj?.documented_txt)
            documented[index].count++
          }
        }
      })
      options = documented.map(t=>({value:t.val,label:t.val.replace(/_/g,' '),count:t.count}))
    }
    if(view==='issue_count'){
      let noneCount = objectNodes.filter(n=>n.data?.obj?.number_of_issues_srt===0).length
      if(noneCount)options.push({value:'NONE',label:'NONE',count:noneCount})
      let oneCount = objectNodes.filter(n=>n.data?.obj?.number_of_issues_srt===1).length
      if(oneCount)options.push({value:'ONE',label:'ONE',count:oneCount})
      let manyCount = objectNodes.filter(n=>n.data?.obj?.number_of_issues_srt>1).length
      if(manyCount)options.push({value:'MANY',label:'MANY',count:manyCount})
    }
    if(view==='usage_txt'){
      let usages = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.usage_txt){
          if(!usages.find(u=>u.val===n.data?.obj?.usage_txt)){
            usages.push({val:n.data?.obj?.usage_txt,count:1})
          }else{
            let index = usages.findIndex(u=>u.val===n.data?.obj?.usage_txt)
            usages[index].count++
          }
        }
      })
      let usageSeq = ['DAILY','WEEKLY','MONTHLY','INFREQUENT','UNUSED']
      options = usages.map(d=>({value:d.val,label:d.val,count:d.count})).sort((a,b)=>usageSeq.indexOf(a.value)-usageSeq.indexOf(b.value))
      let noneCount = objectNodes.filter(n=>!n.data?.obj?.usage_txt).length
      if(noneCount)options.push({value:'NONE',label:'NO USAGE',count:noneCount})
    }
    if(view==='load_frequency_txt'){
      let usages = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.load_frequency_txt){
          if(!usages.find(u=>u.val===n.data?.obj?.load_frequency_txt)){
            usages.push({val:n.data?.obj?.load_frequency_txt,count:1})
          }else{
            let index = usages.findIndex(u=>u.val===n.data?.obj?.load_frequency_txt)
            usages[index].count++
          }
        }
      })
      let usageSeq = ['DAILY','WEEKLY','MONTHLY','INFREQUENT','UNUSED']
      options = usages.map(d=>({value:d.val,label:d.val,count:d.count})).sort((a,b)=>usageSeq.indexOf(a.value)-usageSeq.indexOf(b.value))
      let noneCount = objectNodes.filter(n=>!n.data?.obj?.load_frequency_txt).length
      if(noneCount)options.push({value:'NONE',label:'NO LOAD FREQUENCY',count:noneCount})
    }
    // instance options
    if(view.includes('_kc_txts')){
      let instances = [];
      objectNodes.forEach(n=>{
        if(n.data?.obj?.[view]){
          n.data?.obj?.[view].forEach(v=>{
            if(!instances.find(c=>c.val===v)){
              instances.push({val:v,count:1})
            }else{
              let index = instances.findIndex(c=>c.val===v)
              instances[index].count++
            }
          })
        }
      })
      options = instances.map(v=>({value:v.val,label:v.val,count:v.count}))
      let noneCount = objectNodes.filter(n=>!n.data?.obj?.[view]).length
      if(noneCount)options.push({value:'NONE',label:'NO VALUE',count:noneCount})
    }
    return options;
  }

  const generateOptions = () => {
    let options = [];
    let objectNodes = nodes;
    if(!objectNodes || objectNodes.length===0)return options;
    if(objectNodes.find(n=>n.data?.obj?.trust))options.push({ value: 'trust', label: 'Trust' })
    if(objectNodes.find(n=>n.data?.obj?.classification_kc_txts))options.push({ value: 'classification', label: 'Classification', isCollection:true })
    if(objectNodes.find(n=>n.data?.obj?.domain_kc_txts))options.push({ value: 'domain', label: 'Domain', isCollection:true })
    if(objectNodes.find(n=>n.data?.obj?.category_kc_txts))options.push({ value: 'category', label: 'Category', isCollection:true })
    // if(objectNodes.find(n=>n.data?.obj?.verified_kc_txts))options.push({ value: 'verified', colour:theme.palette.success.main, label: 'Verified for' })
    // if(objectNodes.find(n=>n.data?.obj?.not_verified_kc_txts))options.push({ value: 'not_verified',colour:theme.palette.error.main,  label: 'Not verified for' })
    if(objectNodes.find(n=>n.data?.obj?.verified_kc_txts))options.push({ value: 'verified', label: 'Verified for', isCollection:true })
    if(objectNodes.find(n=>n.data?.obj?.not_verified_kc_txts))options.push({ value: 'not_verified', label: 'Not verified for', isCollection:true })
    if(objectNodes.find(n=>!isNaN(n.data?.obj?.dq_score_overall_srt)))options.push({ value: 'dq_score', label: 'DQ Overall Score' })
    if(objectNodes.find(n=>n.data?.obj?.data_quality_failed_txt==='YES'))options.push({ value: 'dq_failed', label: 'DQ Issues last 7d' })
    if(objectNodes.find(n=>n.data?.obj?.owned_txt==='IS_OWNED'))options.push({ value: 'owned_txt', label: 'Owned' })
    if(objectNodes.find(n=>n.data?.obj?.steward_count!==undefined))options.push({ value: 'steward_count', label: 'Steward' })
    if(objectNodes.find(n=>n.data?.obj?.documented_txt))options.push({ value: 'documented', label: 'Documented' })
    if(objectNodes.find(n=>n.data?.obj?.number_of_issues_srt!==undefined))options.push({ value: 'issue_count', label: 'Issues' })
    if(objectNodes.find(n=>n.data?.obj?.usage_txt!==undefined))options.push({ value: 'usage_txt', label: 'Usage' })
    if(objectNodes.find(n=>n.data?.obj?.load_frequency_txt!==undefined))options.push({ value: 'load_frequency_txt', label: 'Load Frequency' })    
    return options.sort((a,b)=>a.label<b.label?-1:1);
  }

  const generateDataGovMgmOptions = () => {
    let options = [];
    let objectNodes = nodes;
    objectNodes.forEach(el=>{
      let collectionKeys = Object.keys(el.data?.obj||{}).filter(el=>el.includes('_kc_txts'));
      collectionKeys = collectionKeys.filter(el=>![...autoCollectionShortNames,...platformCollectionShortNames].includes(el.replace(/_kc_txts/g,'').replace(/_/g,' ')))
      collectionKeys.forEach(key=>{
        if(!options.find(o=>o.value===key))options.push({value:key,label:key.replace(/_kc_txts/g,'').replace(/_/g,' ')})
      })
    })
    options.sort((a,b)=>a.label<b.label?-1:1)
    return options;
  }

  const onUnselectAll = (event, focus) => {
    event.stopPropagation();
    event.preventDefault();
    if(subFocusView[focus].length===0){
      onChangeSubFocus({...subFocusView,[focus]:getDefaultSubFocus(focus)})
      return;
    }
    onChangeSubFocus({...subFocusView,[focus]:[]})
  }

  const getOptionMenuItem = o => {

    let totalCount = 0;
    generateSubOptions(o.value)?.forEach(el=>{
      if(subFocusView[o.value]?.includes(el.value)){
        totalCount+=el.count
      }
    })

    return (
      <MenuItem 
        key={o.value}
        onMouseEnter={(event)=>{
          setSubAnchor(event.currentTarget)
          setHoveredFocus(o.value)
        }}
        onClick={()=>{
          if(focusView.includes(o.value)){
            onChangeSubFocus({...subFocusView,[o.value]:[]})
            onChangeFocus(focusView.filter(f=>f!==o.value))
          }else{
            onChangeSubFocus({...subFocusView,[o.value]:getDefaultSubFocus(o.value)})
            onChangeFocus([...focusView,o.value])
          }
        }}
        selected={hoveredFocus===o.value}
      >
        <Checkbox key={focusView} color='primary' style={{paddingLeft:0}} checked={focusView.includes(o.value)}/> 
        <span style={{flexGrow:1}}>
          {o.label} {totalCount?` (${totalCount})`:''}
        </span>
        {
          o.colour && 
          <div style={{width:20,height:20,marginRight:12,background:o.colour}}></div>
        }
        {getIconComponent({label:'triangle_right',size:24,colour:theme.palette.primaryText.light})}
      </MenuItem>
    )
  }

  let options = generateOptions()
  let instanceOptions = generateDataGovMgmOptions()
  let allOptions = options.concat(instanceOptions)


  const generateSelectionChips = () => {
    let chips = [];
    focusView.forEach(f=>{
      if(subFocusView[f]?.length>0){
        let chip = {value:f, label:options.find(o=>o.value===f).label+': '+ subFocusView[f].map(v=>generateSubOptions(f).find(s=>s.value===v).label).join(', ')}
        chips.push(chip)
      }
    })
    return chips;
  }


  if(!nodes || nodes.length===0)return <div style={{marginLeft:200}}></div>
  return (
    <div className={classes.root}>
      <Paper 
        style={{marginTop:0,marginLeft:16,width:350,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}
        className={classes.popperPaper}
        onMouseLeave={event=>{
          clearTimeout(popperExitTimeout.current)
          popperExitTimeout.current = setTimeout(()=>{
            setHoveredFocus()
            setSubAnchor()
          },200)
        }}
        onMouseEnter={event=>{
          clearTimeout(popperExitTimeout.current)
          setSubFocusSearch('')
        }}
      >
        <div style={{display:'flex',padding:'0 8px',alignItems:'center',marginBottom:8,marginTop:8}}>
          <Typography style={{color:theme.palette.primary.main,fontSize:12,letterSpacing:1,marginRight:8}}>FILTER:</Typography>
          <ToggleButton
            options={['INCLUDE','EXCLUDE']}
            selectedValue={isExclude?'EXCLUDE':'INCLUDE'}
            setSelectedValue={()=>{onToggleInclude(!isExclude)}}
            testID={`lineage-filter-include-toggle`}
          />
          <div style={{width:8}}></div>
          <ToggleButton
            options={['AND','OR']}
            selectedValue={type==='or'?'OR':'AND'}
            setSelectedValue={()=>{onToggleType(type==='or'?'and':'or')}}
            testID={`lineage-filter-or-and-toggle`}
          />
          <div style={{flexGrow:1}}></div>
          {
            focusView.length>0 &&
            <Typography 
              onClick={event=>{
                onChangeFocus([])
                onChangeSubFocus({})
              }}
              style={{color:theme.palette.secondary.main,fontSize:12,letterSpacing:1,cursor:'pointer'}}
            >
              CLEAR ALL
            </Typography>
          }
        </div>
        <div style={{display:'flex',flexWrap:'wrap',padding:'0px 8px',maxWidth:"100%"}}>
          {
            generateSelectionChips().map(chip=>(
              <UserChip
                key={chip.value}
                user={{name:chip.label}}
                removeIcon
                onRemove={event=>{
                  onChangeFocus(focusView.filter(f=>f!==chip.value))
                  onChangeSubFocus({...subFocusView,[chip.value]:[]})
                }}
              />
            ))
          }
        </div>
        {
          options.filter(o=>o.isCollection).length>0 &&
          <>
            <Typography className={classes.sectionHeader}>K COLLECTIONS</Typography>
            {
              options.filter(o=>o.isCollection).map(getOptionMenuItem)
            }
          </>
        }
        <Typography className={classes.sectionHeader}>K PROPERTIES</Typography>
        {
          options.filter(o=>!o.isCollection).map(getOptionMenuItem)
        }
        {
          instanceOptions.length>0 && 
          <>
            <Typography className={classes.sectionHeader}>DATA GOVERNANCE / MANAGEMENT</Typography>
            {
              instanceOptions.map(getOptionMenuItem)
            }
            <div style={{height:16}}></div>
          </>
        }
      </Paper>
      <Popper open={subAnchor} anchorEl={subAnchor} placement='right-start'>
        <Paper 
          style={{marginLeft:6,minWidth:260,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}
          className={classes.popperPaper}
          onMouseLeave={event=>{
            clearTimeout(popperExitTimeout.current)
            popperExitTimeout.current = setTimeout(()=>{
              setHoveredFocus()
              setSubAnchor()
            },200)
          }}
          onMouseEnter={event=>{
            clearTimeout(popperExitTimeout.current)
          }}
        >
          {
            hoveredFocus &&
            <>
              <InputBase
                value={subFocusSearch}
                onChange={event=>setSubFocusSearch(event.target.value)}
                className={classes.inputBase}
                placeholder={`Search ${allOptions.find(option=>option.value===hoveredFocus).label}`}
              />
               <div style={{display:'flex',justifyContent:'space-between',padding:'0 8px',marginBottom:8}}>
                <Typography style={{color:theme.palette.primary.main,fontSize:12,letterSpacing:1}}>{subFocusView[hoveredFocus]?.length||0} SELECTED</Typography>
                <Typography onClick={event=>onUnselectAll(event,hoveredFocus)} style={{color:theme.palette.secondary.main,fontSize:12,letterSpacing:1,cursor:'pointer'}}>
                  {subFocusView[hoveredFocus]?.length>0?'UNSELECT':'SELECT'} ALL
                </Typography>
              </div>
              {
                generateSubOptions(hoveredFocus).filter(el=>subFocusSearch.trim()===''||el.value.toLowerCase().includes(subFocusSearch.toLowerCase())).map(option=>{
                  return (
                    <MenuItem 
                      onClick={(event)=>{
                        if(subFocusView[hoveredFocus]?.includes(option.value)){
                          onChangeSubFocus({...subFocusView,[hoveredFocus]:subFocusView[hoveredFocus].filter(el=>el!==option.value)})
                        }else{
                          onChangeSubFocus({...subFocusView,[hoveredFocus]:[...(subFocusView[hoveredFocus]||[]),option.value]})
                          if(!focusView.includes(hoveredFocus))onChangeFocus([...focusView,hoveredFocus])
                        }
                      }} 
                      key={option.value} 
                      value={option.value}
                    >
                      <Checkbox key={subFocusView[hoveredFocus]} color='primary' style={{paddingLeft:0}} checked={subFocusView[hoveredFocus]?.includes(option.value)}/> 
                      {toTitleCase(option.label)} ({option.count})
                    </MenuItem>
                  )
                })
              }
            </>
          }
        </Paper>
      </Popper>
    </div>
  )
}

NodeFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  focusView: PropTypes.string.isRequired,
  onChangeFocus: PropTypes.func.isRequired,
  subFocusView: PropTypes.string.isRequired,
  onChangeSubFocus: PropTypes.func.isRequired,
  nodes: PropTypes.array.isRequired,
}

export default withTheme()(withStyles(styles)(NodeFilter));