import React, {useRef, useState, useEffect} from 'react';
import {withTheme, Typography, withStyles, Stepper, Step, StepLabel, StepContent} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import SelectTargetType from './SelectTargetType';
import SelectTarget from './SelectTarget'
import SelectMergedItems from './SelectMergedItems'
import ConfirmMerge from './ConfirmMerge'
import { getIconComponent, toTitleCase } from '../../../utilities';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {
    
  },
  title:{
    fontSize:20,
    color:theme.palette.primary.main,
    marginBottom:24
  },
  stepper:{
    marginBottom:120,
    backgroundColor:theme.palette.background.main
  },
  step: {

  },
  label:{
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
  chartBox:{
    padding:8,
    display:'flex',
    alignItems:'flex-start',
    border:`1px solid ${theme.palette.primary.dark}`,
    borderRadius:3,
    width:208,
    overflow:'hidden',
    height:42
  },
  overflowText:{
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  }
})

const CreateMerge = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
  } = props;

  const [merging, setMerging] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  let targetTypeName = state.mergeTargetType && state.mergeTargetType!=='none'?toTitleCase(state.mergeTargetType.replace(/_/g,' ')):'Target'
  const steps = [
    'Select a Target Type',
    `Select a Target ${targetTypeName} (Primary User Profile)`,
    `Select ${targetTypeName}(s) to merge to ${state.mergeTarget?state.mergeTarget.name:'Target'}`,
    'Confirm Merge details'
  ]

  const labelClickable = index => {
    switch(index){
      case 0:
        return false;
      case 1:
        return state.mergeTargetType && state.mergeTargetType!=='none';
      case 2: 
        return state.mergeTarget && labelClickable(1)
      case 3:
        return state.mergedItems && state.mergedItems.length>0 && labelClickable(2)
      default:
        return false
    }
  }

  const onLabelClick = index => {
    if(labelClickable(index)){
      dispatch({type:'set_merge_tab_state',mergeTabState:index})
    }
  }

  const onMerge = () => {
    setMerging(true)
    axiosCerebrum
      .post(
        `/api/merges`,{
          "target": state.mergeTarget.id,
          "object_type": state.mergeTargetType,
          "candidates": state.mergedItems.map(el=>el.id),
          "action":'ADD_TO_EXISTING'
        }
      )
      .then(response=>{
        dispatch({type:'set_merge_records',mergeRecords:{}})
        setMerging(false)
        dispatch({type:'set_merge_target_type',mergeTargetType:'USER'})
        dispatch({type:'set_tab_state',tabState:0})
        dispatch({type:'set_merge_tab_state',mergeTabState:1})
        dispatch({type:'set_merge_target'})
        dispatch({type:'set_merged_items'})
      })
      .catch(error=>{
        console.log(error)
        let msg = 'Error occurred merging the items, please try again';
        if(error && error.response &&  error.response.status && error.response.status===409){
          msg = "The items have already been merged"
        }
        sendAlert({message:msg,type:'error'})
        setMerging(false)
      })
  }

  const getStepContent = index => {
    switch(index){
      case 0:
        return <SelectTargetType state={state} dispatch={dispatch}/>
      case 1:
        return <SelectTarget state={state} dispatch={dispatch}/>
      case 2:
        return <SelectMergedItems state={state} dispatch={dispatch}/>
      case 3:
        return <ConfirmMerge state={state} dispatch={dispatch} onMerge={onMerge} merging={merging}/>
      default:
        return <div></div>
    }
  }

  const getStepLabelText = index => {
    if(index>=state.mergeTabState)return steps[index]
    switch(index){
      case 0:
        if(state.mergeTargetType && state.mergeTargetType!=='none')return `Target Type: ${toTitleCase(state.mergeTargetType.replace(/_/g,' '))}`
        return steps[index]
      case 1:
        if(state.mergeTarget)return `Target: ${state.mergeTarget.name} (${[state.mergeTarget.source.name,...state.mergeTarget.reference_sources.map(el=>el.reference_source.name)].join(', ')})`
        return steps[index]
      default:
        return steps[index]
    }
  }

  const getSourceText = () => {
    if(!state.mergedItems || state.mergedItems.length===0)return ''
    let sources = [];
    state.mergedItems.forEach(m=>{
      if(m.source && !sources.includes(m.source.name))sources.push(m.source.name)
    })
    return sources.length<=1?sources[0]:'Multiple Sources'
  }
  

  return (
    <div className={classes.root}>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginBottom:24}}>
        <div className={classes.chartBox}>
          {getIconComponent({label:state.mergeTargetType || 'info',size:24,colour:state.mergeTargetType?theme.palette.primary.dark:theme.palette.primaryText.light})}
          <div style={{marginLeft:12,marginTop:2,overflow:'hidden'}}>
            <Typography className={classes.overflowText} style={{fontSize:13.75,color:theme.palette.primaryText.main}}>
              {
                !state.mergedItems || state.mergedItems.length===0?
                `Select items to merge`:
                `${state.mergedItems.length} ${toTitleCase(state.mergeTargetType.replace(/_/g,' '))}(s) selected`
              }
            </Typography>
            {
               !state.mergedItems || state.mergedItems.length===0?null:
               <Typography className={classes.overflowText} style={{fontSize:12,color:theme.palette.primaryText.light}}>
                 {getSourceText()}
              </Typography>
            }
          </div>
        </div>
        <span style={{color:theme.palette.primary.main,letterSpacing:4,fontSize:24,margin:'0 24px'}}>------------{`>`}</span>
        <div className={classes.chartBox} style={{background:theme.palette.primary.dark}}>
          {getIconComponent({label:state.mergeTargetType || 'info',size:24,colour:theme.palette.background.main})}
          <div style={{marginLeft:12,marginTop:2,overflow:'hidden'}}>
            <Typography className={classes.overflowText} style={{fontSize:13.75,color:theme.palette.background.main}}>
              {
                !state.mergeTarget?
                `Target item`:
                state.mergeTarget.name
              }
            </Typography>
            {
               !state.mergeTarget?null:
               <Typography className={classes.overflowText} style={{fontSize:12,color:theme.palette.background.main}}>
                 {[state.mergeTarget.source.name,...state.mergeTarget.reference_sources.map(el=>el.reference_source.name)].join(', ')}
              </Typography>
            }
          </div>
        </div>
      </div>
      <Typography className={classes.title}>MERGE DETAILS</Typography>
      <Stepper activeStep={state.mergeTabState} orientation="vertical" className={classes.stepper} >
        {steps.map((label, index) => (
          <Step key={index} className={classes.step}>
            <StepLabel className={classes.label} style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}}>
              <Typography color='primary' style={{color:state.mergeTabState<index?theme.palette.primaryText.light:theme.palette.primaryText.main,fontSize:16,marginLeft:6}}>{getStepLabelText(index)}</Typography> 
            </StepLabel>
            <StepContent>
              <div style={{width:960,marginTop:16}}>
                {getStepContent(index)}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

export default withTheme()(withStyles(styles)(CreateMerge));