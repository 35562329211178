import React, { useEffect } from 'react';
import { Typography, withStyles, Button} from '@material-ui/core';
import MergedList from './MergedList';
import { getIconComponent, setHelpWdigetVisibility } from '../../../utilities';
import theme from '../../../theme';

const styles = theme => ({
  root: {
    
  },
  infoBox:theme.components.infoBox,
  infoHeader:theme.components.infoBoxHeader,
  infoDescription:theme.components.infoBoxDescription,
  clickableText:theme.components.infoBoxFocusTxt,
  button:theme.components.infoBoxButtonContained,
})

const Merge = props => {

  const {
    classes,
    state,
    dispatch,
    history,
  } = props;


  useEffect(()=>{
    setHelpWdigetVisibility(true)
    return ()=>{setHelpWdigetVisibility(false)}
  // eslint-disable-next-line
  },[])

  return (
    <div className={classes.root}>
      <div className={classes.infoBox}>
        <div style={{display:'flex',alignItems:'flex-start'}}>
          <div>
            <Typography className={classes.infoHeader}>Merge Users</Typography>
            <Typography className={classes.infoDescription}>
              The same user for example, may exist in databases and reporting tools as different accounts. <span style={{fontWeight:700}}>Jane Doe</span> may be <span style={{fontWeight:700}}>jdoe123</span> in Snowflake and <span style={{fontWeight:700}}>jane.doe@company.com</span> in Tableau. 
              Using the Merge feature, you can merge <span style={{fontWeight:700}}>jdoe123</span> and <span style={{fontWeight:700}}>jane.doe@company.com</span> into the <span style={{fontWeight:700}}>Jane Doe</span> profile on K.{`\n\n`}
            </Typography>
            <Typography className={classes.infoHeader}>Merge Sources</Typography>
            <Typography className={classes.infoDescription}>
              Where a user has re-named the underlying source of a database, K is unable to automatically link the database source. These unlinked database sources are treated as a reference source and K will show limited information and lineage. If the underlying source has already been linked to K, you can merge the underlying source to the correct source via the <span className={classes.clickableText} onClick={()=>history.push(`/admin/sources`)}>Sources</span> page and clicking on the <span style={{position:'relative',bottom:-5}}>{getIconComponent({label:'merge',size:20,colour:theme.palette.primaryText.light})}</span> icon
            </Typography>
            <Typography className={classes.infoDescription}>
              Note: Merge/Unmerge is a batch process that typically runs overnight. Changes may take up to 24 hours to complete
            </Typography>
          </div>
          <Button className={classes.button} style={{marginTop:24}} onClick={()=>dispatch({type:'set_tab_state',tabState:-1})}>
            MERGE
          </Button>
        </div>
      </div>
      <MergedList
        state={state}
        dispatch={dispatch}
        history={history}
      />
    </div>
  )
}

export default withStyles(styles)(Merge);