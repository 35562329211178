import React, { useEffect, useReducer } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/QuestionBoard/Body/Body';
import useGetCerebrum from '../../hooks/useGetCerebrum';
import { getUserRoles, removeUrlQueryArg } from '../../utilities';
import { addHistory } from '../../HistoryManager';
import 'url-search-params-polyfill';
import axiosCerebrum from '../../axios-cerebrum';
import { LinearProgress, Typography } from '@material-ui/core';
import DeadEnd from '../../components/Generic/Page/DeadEnd';

const styles = theme => ({
  underlineOnHover:{
    color: theme.palette.primaryText.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  }
});

const initialState = {
  tabState: 0,
  sources:[],
  runningCode: '',
  questionTabState: 0
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_question_tab_state':
      return {
        ...state,
        questionTabState:action.questionTabState
      }
    case 'set_templates':{
      return {
        ...state,
        templates:action.templates,
        templatesLoading:action.templatesLoading,
        templatesError:action.templatesError
      }
    }
    case 'set_sources':{
      return {
        ...state,
        sources:action.sources,
        sourcesLoading:action.sourcesLoading
      }
    }
    case 'set_survey_templates':{
      return {
        ...state,
        surveyTemplates:action.surveyTemplates
      }
    }
    case 'set_running_code':{
      return {
        ...state,
        runningCode:action.runningCode
      }
    }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const QuestionBoard = props => {

  const {
    history,
    classes,
    sessionData
  } = props;

  const showQuestions = getUserRoles(sessionData.user_role).find(el=>['10','00','40'].includes(el))
  const tabOptions = showQuestions?['QUESTIONS','EXTRACTS']:['EXTRACTS']

  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName')


  const [state, dispatch] = useReducer(
    reducer,
    initialState
  )

  useEffect(()=>{
    if(tabOptions.indexOf(tabName)>=0){
      dispatch({type:'set_tab_state',tabState:tabOptions.indexOf(tabName)})
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
    }
    addHistory({
      url:window.location.pathname, 
      iconLabel:'questionboard', 
      title: 'Ask K', 
      subTitle:`Ask K`,
      type:'application',
    })
    // eslint-disable-next-line
  },[])

  // database data
  const {
    data:dbData,
    loading:dbLoading,
  } = useGetCerebrum({
    url:'/api/sources',
    params:{
      per_page:200,
      types:'DATABASE,TOOL',
      sort:'ALPHABETICAL',
      active_flag:true
    }
  })

  const {
    data:surveyTemplates,
  } = useGetCerebrum({
    url:'/api/surveytemplates',
    params:{per_page:20}
  })

  useEffect(()=>{
    if(surveyTemplates){
      dispatch({
        type:'set_survey_templates',
        surveyTemplates:surveyTemplates.items
      })
    }
  },[surveyTemplates])

  const loadExtractTemplates = () => {
    dispatch({
      type:'set_templates',
      templatesLoading:true,
    })
    axiosCerebrum
      .get(
        `/api/extracttemplates`,{params:{
          per_page:100,
          type:'EXTRACTS',
        }}
      )
      .then(response=>{
        dispatch({type:'set_templates',templates:response.data.items})
      })
      .catch(error=>{
        dispatch({type:'set_templates',templatesError:error})
      })
  }

  useEffect(()=>{
    if(!state.templates){
      loadExtractTemplates()
    }
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])

  useEffect(()=>{
    if(!dbData)return;
    dispatch({type:'set_sources',sources:dbData.items,sourcesLoading:dbLoading})
    //if(dbData.response.docs[0])dispatch({type:'set_selected_source',selectedSource:dbData.response.docs[0]})
  },[dbData,dbLoading])

  if (state.templatesLoading ) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.templatesError ) {
    return (
      <DeadEnd />
    )
  }

  if(!state.templates){
    return <div></div>
  }

  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              label='questionboard'
              description="Ask K is a self-service portal for extracts that answers common questions about your data ecosystem"
              title='Ask K'
              buttons={[<div style={{height:50,margin:8}}></div>]}
            />
          </div>)}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            showQuestions={showQuestions}
            history={history}
            tabState={state.tabState}
            state={state}
            dispatch={dispatch}
          />
        }
      />
    </div>)
}

export default withStyles(styles)(QuestionBoard);
