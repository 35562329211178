import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Typography, ClickAwayListener, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
// import axiosCerebrum from '../../../../axios-cerebrum'
import axiosCerebrum from '../../../../axios-cerebrum';
import { tileStyles } from './utils/styles';
import { Editor as MDEditor } from '@toast-ui/react-editor';
import { getAllRules, removeMarkDown } from '../../InteractiveInput/Templates';
import { sendMessage } from '../../../../utilities';
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  root: {
    ...tileStyles.chipRoot,
  },
  title: {
    ...tileStyles.title
  },
  chipContainer:{
    ...tileStyles.chipContainer
  },
  clickableBox:{
    ...tileStyles.clickableBox
  },
  editingBox:{
    // border:`1px solid ${theme.palette.listItemDivider.main}`,
    padding:0
  },
})

function AnswerManager(props) {

  const {
    classes,
    id,
    object,
    onAddTileData,
    tileData,
    isEditable
  } = props;

  const MDRef = useRef()

  const [refreshFlag, setRefreshFlag] = useState(1)

  const [editing, setEditing] = useState(false)
  const [text, setText] = useState('')
  const popperRef = useRef()

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  const loadAnswerTile = async (target = object) => {
    if(target.properties && target.properties[3] && target.properties[3].trim()!==''){
      setText(target.description)
      onAddTileData(
        {
          "disp_body":{value:removeMarkDown(target.properties[3])},
          "disp_title":"ANSWER",
          "disp_type":"text",
          "id":id
        }
      )
    }else{
      onAddTileData(
        {
          "disp_body":{value:'Click to answer'},
          "disp_title":"ANSWER",
          "disp_type":"text",
          "id":id
        }
      )
    }
  }

  useEffect(()=>{
    if(!object)return;
    loadAnswerTile()
  // eslint-disable-next-line
  },[object])


  const onSave = () => {
    let payload = {};
    payload.name = object.name;
    payload.description = object.description;

    payload.properties = object.properties;
    payload.properties[3] = text
    payload.source_id = object.source_id;

    axiosCerebrum 
      .put(
        `/api/collectioninstances/${object.id}`,
        payload
      )
      .then(response=>{
        loadAnswerTile(response.data)
        setRefreshFlag(refreshFlag*-1)
        setEditing(false)
        sendMessage({answer_updated:text,object_id:object.id})
      })
      .catch(error=>{
        console.log(error)
        sendAlert({
          message: 'Error occurred updating answer, please try again',
          type: 'error'
        })
      })
  }

  if(!tileData)return <></>

  return (
    <div data-test-id="answer-tile" className={classes.root}>
      <ClickAwayListener 
        onClickAway={(event)=>{
          setEditing(false)
        }}
      >
        <div style={{width:'100%'}}>
          {
            editing?
            (
              <div style={{width:'100%',overflow:'hidden'}}>
                <MDEditor
                  ref={MDRef}
                  key={refreshFlag}
                  height={Math.min(500,Math.max(200,(text||'').length/2.5))+'px'}
                  hideModeSwitch
                  onChange={()=>setText(MDRef.current.getInstance().getMarkdown())}
                  initialValue={text}
                  onFocus={()=>{
                    MDRef.current.getInstance().moveCursorToEnd()
                  }}
                  initialEditType="wysiwyg"
                  useCommandShortcut={true}
                  previewStyle="vertical"
                  widgetRules={getAllRules()}
                  theme={localStorage.hasOwnProperty('dark')?"dark":"white"}
                  usageStatistics={false}
                  toolbarItems={
                    [
                      ['heading', 'bold', 'italic', 'strike'],
                      ['hr', 'quote'],
                      ['ul', 'ol', 'indent', 'outdent'],
                      ['table','link'],
                      ['code', 'codeblock']
                    ]
                  }
                />
                <div style={{display:'flex',paddingLeft:24,marginTop:16}}>
                  <Button color='primary' variant='contained' onClick={onSave}>SAVE</Button>
                  <Button color='secondary' style={{marginLeft:16}} onClick={()=>{setEditing(false);setText(object.description)}}>CANCEL</Button>
                </div>
              </div>
            )
            :
            <div ref={popperRef} style={{overflow:'hidden'}} className={classes.chipContainer + ' ' + (editing?classes.editingBox:isEditable?classes.clickableBox:'')} onClick={()=>isEditable && setEditing(true)}>
              <Typography style={{fontSize:13.75,wordBreak:'break-word',background:'transparent',marginBottom:8}}>
                {tileData.disp_body.value}
              </Typography>
            </div>
          }
        </div>
      </ClickAwayListener>
    </div>
  )
}

AnswerManager.propTypes = {
  classes: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  tileData: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(AnswerManager));