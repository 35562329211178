import React, { useEffect, useReducer } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/BulkEditTemplated/Body/Body';
import 'url-search-params-polyfill';
import { addHistory } from '../../HistoryManager';
import 'url-search-params-polyfill';
import { removeUrlQueryArg } from '../../utilities';
import axiosCerebrum from '../../axios-cerebrum';

const styles = theme => ({
});

const initialState = {
  tabState:0,
  view:'history'
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState:action.tabState
      }
    case 'set_view':
      return {
        ...state,
        view:action.view
      }
    case 'set_import_modal_open':
      return {
        ...state,
        importModalOpen:action.importModalOpen
      }
    case 'set_object_count':
      return {
        ...state,
        objectCount:action.objectCount
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const BulkEditTemplated = props => {

  const {
    history,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState)

  const urlSearch = new URLSearchParams(window.location.search);
  const view = urlSearch.get('view');
  const tabName = urlSearch.get('tabName');

  const loadCount = () => {
    axiosCerebrum
      .get(
        `/api/me/cart/stats`
      )
      .then(response=>{
        let counts = response.data.counts.map(el=>({
          objectType:Object.keys(el)[0],
          count:el[Object.keys(el)[0]]
        }))
        dispatch({type:'set_object_count',objectCount:counts})
      })
      .catch(error=>{
        console.log(error)
      })
  }

  useEffect(()=>{
    loadCount()
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(view && ['history','create_template'].includes(view)){
      dispatch({type:'set_view',view:view})
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['view']}));
    }
    if(tabName?.toUpperCase()==='MY TEMPLATES'){
      dispatch({type:'set_tab_state',tabState:1})
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
    }
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    let title = 'Import Bulk Edit File';
    if(state.view==='create_template'){
      title = 'Create Bulk Edit Template';
    }
    addHistory({url:window.location.pathname+`?view=${state.view}`, iconLabel:'bulk_edit', title:title, subTitle:title,type:'application'})
  },[state.view])

  let label = 'bulk_edit'
  let header = 'Import Bulk Edit File'
  if(state.view==='create_template'){
    header = 'Create Bulk Edit Template'
  }


  return (
    <div>
      <ProfileLayout
        header={(
          <div>
            <ProfileHeader
              label={label}
              title={header}
              subtitle=' '
            />
          </div>)}
        body={
          <Body
            state={state}
            history={history}
            dispatch={dispatch}
          />
        }
      />
    </div>
  )
}

export default withStyles(styles)(BulkEditTemplated);
