import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Monitor from '../Monitor/Monitor';
import Settings from '../Settings/Settings';
import BatchManager from '../BatchManager/BatchManager'
import BatchScheduler from '../BatchManager/BatchScheduler';

const styles = theme => ({
  root: {
   // width: '70%'
  }
})

const Body = props => {

  const {
    classes,
    tabState,
    history,
    nodeKey,
    state,
    sessionData,
    dispatch,
    historyFecthlist
  } = props;

  let bodyContent;

  if(state.view==='home'){
    switch (tabState) {
      case 0: // 
        bodyContent = (
          <Monitor 
            nodeKey={nodeKey}
            userName={sessionData.user_name}
            state={state}
            dispatch={dispatch}
            historyFecthlist={historyFecthlist}
          />
        );
        break;
      case 1: // 
        bodyContent = (
          <BatchManager 
            state={state}
            dispatch={dispatch}
            history={history}
          />
        );
        break;
      case 2: // settings
        bodyContent = (
          <Settings
            history={history}
            state={state}
            nodeKey={nodeKey}
            dispatch={dispatch}
            historyFecthlist={historyFecthlist}
          />
        )
        break;
      default:
    }
  }

  if(state.view==='scheduler'){
    bodyContent = (
      <BatchScheduler
        state={state}
        dispatch={dispatch}
        history={history}
      />
    )
  }

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
  tabUrlPrefix: PropTypes.string,
  nodeKey: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.object,
  sessionData: PropTypes.object,
}

export default withStyles(styles)(Body);