import React, {} from 'react';
import { withTheme, Typography,Select, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toTitleCase } from '../../../../utilities';


const TrendSelector = props => {

  const {
    classes,
    theme,
    trend,
    setTrend,
  } = props;


  return (
    <div className={classes.modalBlock}>
      <Typography  className={classes.modalBlockTitle}>Trend</Typography>
      <div style={{flexGrow:1}}>
        <FormControl style={{width:'100%'}}>
          <Select
            className={classes.selector}
            disableUnderline
            value={trend}
            onChange={event=>setTrend(event.target.value)}
            MenuProps={{
              classes:{
                paper:classes.selectPaper
              }
            }}
            renderValue={el=>{
              return (
                el==='none'
                  ?
                  <Typography style={{color:theme.palette.primaryText.light,fontSize:16}}>
                    Select a trend
                  </Typography>
                  :
                  <>
                    <Typography style={{fontSize:16,color:theme.palette.primaryText.main}}>
                      {toTitleCase(el)}
                    </Typography>
                  </>
              )
            }}
          > 
            <MenuItem  className={classes.menuItem} value={'none'}>
              Select a trend
            </MenuItem>
            {
              ['Increasing','Decreasing','Stable'].map(el=>(
                <MenuItem   className={classes.menuItem} value={el}>
                  {el}
                </MenuItem>
              ))
            }

          </Select>
          <FormHelperText className={classes.helperText}>Required</FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}

TrendSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  trend: PropTypes.string.isRequired,
  setTrend: PropTypes.func.isRequired,
}

export default withTheme()(TrendSelector);