import { Typography, withTheme, withStyles, IconButton } from '@material-ui/core';
import React from 'react';

import theme, { checkIsDarkMode } from '../../../theme';
import { getIconComponent, mapSearchObjectName } from '../../../utilities';

import { Handle } from 'react-flow-renderer';
import KTooltip from '../KTooltip/KTooltip';
import { graphPalette, getBackgroundColour } from '../Lineage/utils';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { groupBodyActionHeight, groupHeaderHeight } from '../Lineage/LineageV3/layoutUtils';

const styles = theme => ({
  undraggableNode:{
    cursor:'default !important',
  },
  clickable:{
    cursor:'pointer',
    '&:hover':{
      textDecoration:'underline',
      textUnderlineOffset:'2px',
      textDecorationColor:theme.palette.primary.main,
    }
  },
  // nodeHoverable:{
  //   cursor:"pointer",
  //   '&:hover':{
  //     filter: `saturate(400%)`
  //   },
  // },
  // nodeSelected:{
  //   filter: `saturate(400%)`
  // },
  actionButton:{
    border:`1px solid ${theme.palette.background.main}`,
    background:theme.palette.primary.dark,
    padding:2,
    width:24,
    marginBottom:8,
    height:24,
    position:'absolute',
    zIndex:999,
    '&:hover':{
      width:28,
      height:28,
      background:theme.palette.primary.dark,
      // background:theme.palette.primaryText.light,
    }
  },
  leftActionButton:{
    left:-35,
    alignSelf:'center',
    '&:hover':{
      left:-37,
    }
  },
  rightActionButton:{
    right:-35,
    alignSelf:'center',
    '&:hover':{
      right:-37,
    }
  },
  loadingIcon:{
    border:`1px solid ${theme.palette.background.main}`,
    width:24,
    height:24,
    marginBottom:8,
    alignItems:'center',
    justifyContent:"center",
    display:'inline-flex',
    position:'absolute',
    borderRadius:'50%',
    background:theme.palette.primaryText.light,
    animationName: 'loading-spin',
    animationDuration: '800ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    cursor:'default'
  },
  [`@keyframes loading-spin`]: {
    from: {
      transform:'rotate(0deg)',
    },
    to: {
      transform:'rotate(360deg)',
    },
  },
  leftLoadingIcon:{
    left:-35,
    alignSelf:'center',
  },
  rightLoadingIcon:{
    right:-35,
    alignSelf:'center',
  },
  bodyAction:{
    position:'absolute',
    fontSize:13.75,
    letterSpacing:1.5,
    height:32,
    left:2,
    borderRadius:4,
    background:theme.palette.background.main,
    borderTop:`1px solid ${theme.palette.border.main}`,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    '&:hover':{
      background:theme.palette.hovered.main,
    
    }
  }
})


const LineageGroupNodeV3 = ({ data, draggable,selected,viewOnly, type, classes, id }) => {
  
  const [hovered, setHovered] = React.useState(false)

  const objectType = data.obj?.object_type_txt|| data.obj?.object_type || data.obj?.type || data.obj?.object?.name
  let typeColour = graphPalette[mapSearchObjectName(objectType,data.obj?.code_type_txt)]
  let background = getBackgroundColour(mapSearchObjectName(objectType,data.obj?.code_type_txt))  
  if(selected){
    background = checkIsDarkMode()?lighten(background,0.2):lighten(typeColour,0.4)
  }
  
  let defaultBorderWidth = 2;
  let borderWidth = selected?4:(data.borderWidth || defaultBorderWidth);
  let borderWidthOffset = borderWidth-defaultBorderWidth;
  let width = data.width
  let height = data.height + 2 + (data.bodyAction && !data.hideBodyAction?groupBodyActionHeight:0)

  let titleColour = data.titleColour || theme.palette.primaryText.main
  let borderColour = data.borderColour || theme.palette.primaryText.main

  
  return (
    <div
      id={id}
      style={{
        border:`${borderWidth}px solid ${borderColour}`,
        borderRadius:5,
        marginLeft:-borderWidthOffset,
        width:width+borderWidthOffset*2,
        minWidth:100,
        marginTop:-borderWidthOffset,
        height:height+borderWidthOffset*2,
        opacity:data.faded?0.5:1,
        boxSizing:'border-box',
        cursor:data.onClick && !draggable?'pointer':undefined
      }}
      onClick={event=>{
        event.preventDefault();
        // event.stopPropagation();
      }}
      className={data.draggable===false?classes.undraggableNode:''}
    >
      <div style={{display:'flex',alignItems:'center'}}>
        {
          !viewOnly && 
          <Handle
            type="target"
            position="left"
            style={{ 
              background: '#555',
              visibility:'hidden', 
              position:'absolute',
              top:groupHeaderHeight/2,
              width:0,
              left:data.leftAction?-42:-8
            }}
          />
        }
        {
          data.leftAction && !viewOnly &&
          (
            data.leftActionIcon==='loading'?
            <div className={classes.loadingIcon + ' ' + classes.leftLoadingIcon}>
              {getIconComponent({label:'refresh', size:20, colour:theme.palette.background.main})}
            </div>
            :
            <KTooltip title={data.leftActionTooltip || ''}>
              <IconButton 
                className={classes.actionButton + ' ' + classes.leftActionButton + ' node-action'}
                onClick={event=>{
                  // event.stopPropagation();
                  data.leftAction()
                }}
              >
                {getIconComponent({label:data.leftActionIcon || 'add', size:16, colour:theme.palette.background.main})}
              </IconButton>
            </KTooltip>
          )
        }
        <div 
          style={{
            display:'flex',
            width:'100%',
            alignItems:'center',
            overflow:"hidden",
            minHeight:44,
            padding:'8px 16px',
            background:selected || hovered?background:theme.palette.background.main,
          }}
          onMouseEnter={()=>setHovered(true)}
          onMouseLeave={()=>setHovered(false)}
          onContextMenu={(event)=>{
            event.preventDefault()
            if(viewOnly)return;
            // setContextMenuOpen(true)
            data.onContextMenu?.()
          }}
          onClick={data.onClick}
        >
          {
            data.icon &&
            <div 
              style={{width:24,height:24,marginRight:8,marginTop:-1}}
            >
              {
                getIconComponent({
                  label:data.icon,
                  size:24,
                  colour:selected || checkIsDarkMode() ?titleColour:(data.iconColour || typeColour ||  theme.palette.primary.main)
                })
              }
            </div>
          }
          <div onClick={data.onTitleClick} className={data.onTitleClick?classes.clickable:''}  style={{flexGrow:1,overflow:'hidden'}}>
            <KTooltip title={data.label}>
              <Typography 
                style={{
                  fontSize:13.75,
                  color: titleColour,
                  fontWeight:700,
                  ...(data.wrapTitle?{whiteSpace:'normal',wordBreak:'break-word'}:{})
                }}
                className={'overflow-text group-title'}
              >
                {data.label}
              </Typography>
            </KTooltip>
            {
              data.subTitle && 
              <KTooltip title={data.subTitleStyled || data.subTitle}>
                <Typography 
                  style={{
                    fontSize:12,
                    color:selected?titleColour:theme.palette.primaryText.light,
                    marginTop:4,
                    ...(data.wrapTitle?{whiteSpace:'normal',wordBreak:'break-word'}:{})
                  }}
                  className={'overflow-text group-subtitle'}
                >
                  {data.subTitleStyled || data.subTitle}
                </Typography>
              </KTooltip>
            }
          </div>
          {
            data.tags?.length>0 && 
            <div style={{marginLeft:8,width:data.tags.length*16 + 4*(data.tags.length-1),flexGrow:0,flexShrink:0,display:"flex",justifyContent:'space-between',alignItems:'center',height:32}}>
              {
                data.tags.map(t=>(
                  <KTooltip title={t.tooltip}>
                    <div style={{height:32,width:16,display:'flex',alignItems:'center'}}>{t.icon}</div>
                  </KTooltip>
                ))
              }
            </div>
          }
          {
            data.rightIcon &&
            <div style={{width:24,height:24,marginLeft:8}}>
              {
                getIconComponent({
                  label: data.rightIcon,
                  size:24,
                  colour:data.rightIconColour || theme.palette.primaryText.light
                })
              }
            </div>
          }
        </div>

        {
          data.rightAction && !viewOnly &&
          (
            data.rightActionIcon==='loading'?
            <div className={classes.loadingIcon + ' ' + classes.rightLoadingIcon}>
              {getIconComponent({label:'refresh', size:20, colour:theme.palette.background.main})}
            </div>
            :
            <KTooltip title={data.rightActionTooltip || ''}>
              <IconButton 
                className={classes.actionButton + ' ' + classes.rightActionButton + ' node-action'}
                onClick={event=>{
                  // event.stopPropagation();
                  data.rightAction()
                }}
              >
                {getIconComponent({label:data.rightActionIcon || 'add', size:16, colour:theme.palette.background.main})}
              </IconButton>
            </KTooltip>
          )
        }
        {
          !viewOnly && 
          <Handle
            type="source"
            position="right"
            style={{ 
              background: '#555',
              visibility:'hidden', 
              position:'absolute',
              top:groupHeaderHeight/2,
              width:0,
              right:data.rightAction?-42:-8
            }}
            // isConnectable={isConnectable}
          />
        }

      </div>
      {
        data.bodyAction && !data.hideBodyAction && 
        <div 
          className={classes.bodyAction} 
          onClick={data.bodyAction} 
          style={{
            color:titleColour,
            borderTop:`1px solid ${borderColour}`,
            boxSizing:'border-box',
            width:data.width-4,
            bottom:borderWidthOffset+1
          }}
        >
          {data.bodyActionText}
        </div>
      }
      {
        // data.childLoading && 
        // <div>
        //   <CircularProgress color='secondary'/>
        // </div>
      }
    </div>
  );
}


export default withTheme()(withStyles(styles)(LineageGroupNodeV3));