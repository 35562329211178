import React, { useState, useEffect } from 'react';
import { withTheme, withStyles, Modal, Paper, Typography, Button, CircularProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../axios-cerebrum'
import {  getDispFields } from '../../../utilities';
import ModalAlert from '../ModalAlert/ModalAlert';
import sqlFormatter from "sql-formatter";
import CodeDisplay from '../CodeDisplay/CodeDisplay';

const styles = theme => ({
  header: {
    fontSize: 20,
    color:theme.palette.header.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whitSpace:'nowrap',
    flexGrow:1
  },
  button:{
    height:28,
    marginLeft:24
  },
  customScroll:{
    ...theme.components.customScroll
  }
})

function CodeDisplayModal(props) {

  const {
    theme,
    classes,
    modalOpen,
    setModalOpen,
    onOpen,
    item,
  } = props;

  const [data, setData] = useState({});
  const [alertOpen, setAlertOpen] = useState();
  const [alertMessage, setAlertMessage] = useState('');


  useEffect(()=>{
    setData({loading:true})
    axiosCerebrum
      .get(
        `/api/queries/${item.id}`
      )
      .then(response=>{
        setData({data:response.data})
      })
      .catch(error=>{
        console.log(error)
        setData({error:true})
      })
    // eslint-disable-next-line
  },[])

  const onCopy = async () => {
    try{
      if(navigator.clipboard){
        await navigator.clipboard.writeText(sqlFormatter.format(data.data.code,{language:'pl/sql'}));
      }
    }catch{
      return;
    }
    setAlertMessage("Copied code to clipboard")
    setAlertOpen(true)
  }

  return (
    <Modal 
      open={modalOpen} 
      onClose={()=>setModalOpen(false)}
      disableBackdropClick={true}
    > 
      <div style={{margin:'15vh auto 0 auto',width:800}}>
        <Paper className={classes.customScroll} style={{width:752,padding:24,paddingTop:0,maxHeight:'75vh',overflow:'auto',paddingBottom:32,background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
          <div style={{display:'flex',overflow:'hidden',position:'sticky',top:0,zIndex:10,paddingBottom:16,paddingTop:24,background:theme.palette.background.main}}>
            <Typography className={classes.header}>
              {getDispFields(item, 'dispTitle')}
            </Typography>
            <Button variant='outlined' color='primary' onClick={onCopy} className={classes.button}>COPY</Button>
            <Button variant='outlined' color='primary' onClick={onOpen} className={classes.button}>OPEN PROFILE</Button>
            <Button variant='outlined' color='primary' onClick={()=>setModalOpen(false)} className={classes.button}>CLOSE</Button>
          </div>
          {
            data.loading && 
            <CircularProgress color='secondary'/>
          }
          {
            data.error && 
            <Typography style={{fontSize:13.75}}>Error occurred loading code</Typography>
          }
          {
            data.data && data.data.code && data.data.code.trim()!=='' && 
            <div>
              <CodeDisplay code={data.data.code} />
            </div>
          }
          {
             data.data && (!data.data.code || data.data.code.trim()==='') &&
             <Typography style={{fontSize:13.75}}>No code found</Typography>
          }
        </Paper>
        <ModalAlert
          open={alertOpen}
          setOpen={setAlertOpen}
          message={alertMessage}
          type='info'
        />
      </div>
    </Modal>
  )

}

CodeDisplayModal.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  onOpen: PropTypes.func.isRequired,
}

export default withTheme()(withStyles(styles)(CodeDisplayModal));