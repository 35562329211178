import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, CircularProgress, Select, MenuItem,Paper, MenuList, Popper, ClickAwayListener, InputBase, IconButton, Checkbox } from '@material-ui/core';
import axiosSolr from '../../../axios-solr';
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import ResultItem from '../../UI/SearchResults/ResultItem2'
import {onClickResultItem,getDispFields,getIconComponent, isInViewport, getNameSearchQuery } from '../../../utilities';


const styles = theme => ({
  root: {
    marginBottom: '5rem'
  },
  body:{
    flexGrow:1,
    marginLeft:80,
    overflow:'hidden'
  },
  mainText: {
    fontSize: '1.25rem',
    marginTop: '1.5rem',
    color:theme.palette.header.main
  },
  subText: {
    marginBottom: '2.5rem',
    fontsize: '1.1rem',
    color: theme.palette.primaryText.light
  },
  left: {
    float: 'left',
    width: '35%',
    marginRight: '5%'
  },
  right: {
    float: 'left',
    width: '50%'
  },
  smallTitle: {
    marginBottom: '1rem',
    fontSize: '1rem'
  },
  title: {
    display: 'inline-block',
    color: theme.palette.header.main,
    fontSize: '1.25rem'
  },
  subtitle: {
    height: '16px',
    color: theme.palette.primaryText.light,
    fontSize: '11.78px',
  },
  titleSelector:{
    ...theme.components.titleSelector,
    maxWidth:300,
  },
  selector: {
    ...theme.components.selector,
    width: 160,
  },
  icon: {
    display: 'inline-block',
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
	searchFilter:{
    ...theme.components.inputBase,
    marginRight:24,
    width:160,
		height:40,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },
  checkbox:{
    paddingLeft:0,
  },
  collapseHeader:{
    display:'flex',
    alignItems:"center",
    marginBottom:8
  },
  sectionText:{
    fontSize:12,
    color:theme.palette.primary.main,
    marginRight:8
    // letterSpacing:1,
  }
})

const UsedList = props => {

  const {
    classes,
    theme,
    history,
    variant,
    state,
    dispatch,
  } = props;

	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);
  const scrollRef = useRef()
  const searchTimeoutRef = useRef()
  

  let tabOptions, tabOptionsLoading, tabOptionsError, tabState, setTabState, data, setData, selectedType;

  if(variant==='data'){
    tabOptions = state.dataTabOption;
    tabOptionsLoading = state.dataTabOptionLoading;
    tabOptionsError = state.dataTabOptionError;
    tabState = state.dataTabState;
    setTabState = value => dispatch({type:'set_data_tab_state',dataTabState:value})
    selectedType = state.dataTabOption?.[tabState]
    data = selectedType?state.listData[selectedType]:undefined;
    setData = value => dispatch({type:'set_list_data',listData:{...state.listData,[selectedType]:value}})
  }
  if(variant==='content'){
    tabOptions = state.contentTabOption;
    tabOptionsLoading = state.contentTabOptionLoading;
    tabOptionsError = state.contentTabOptionError;
    tabState = state.contentTabState;
    setTabState = value => dispatch({type:'set_content_tab_state',contentTabState:value})
    selectedType = state.contentTabOption?.[tabState]
    data = selectedType?state.listData[selectedType]:undefined;
    setData = value => dispatch({type:'set_list_data',listData:{...state.listData,[selectedType]:value}})
  }

  const sortByItems = [
    { dispName: 'Frequently used', value: 'total_usage_srt desc' },
    { dispName: 'Recently used', value: 'last_used_srt desc' },
    { dispName: 'Trust score', value: 'trust_srt desc' },
  ]

  const loadTabOptions = () => {
    dispatch({
      type:`set_${variant}_tab_option`,
      [`${variant}TabOptionLoading`]:true
    })
    let options = [];
    let objectTypes;
    if(variant==='content'){
      objectTypes = ['REPORT','DATASET','DATASET_TABLE','FILE','DATA_PIPELINE','WORKSPACE']
    }else{
      objectTypes = ['SCHEMA','TABLE','COLUMN']
    }
    axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:"*",
            fq:`object_type_srt:(${objectTypes.join(' OR ')}) AND job_type_kc_msrt:"${state.basicData.name}"`,
            rows:0,
            'json.facet':{
              object_type:{
                "type": "terms",
                "field":"object_type_srt",
                'min.count':1
              }
            }
          }
        }
      )
      .then(response=>{
        if(response.data.facets.object_type && response.data.facets.object_type.buckets){
          response.data.facets.object_type.buckets.forEach(b=>{
            options.push(b.val)
          })
        }
        dispatch({
          type:`set_${variant}_tab_option`,
          [`${variant}TabOption`]:options
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:`set_${variant}_tab_option`,
          [`${variant}TabOptionError`]:true
        })
      })
  }

  const loadData = ({sort=state.listSort, search=state.listSearch[selectedType]||'', type=selectedType, showDeleted=state.listShowDeleted, start=0}) => {

    setData({
      ...(start===0?{}:state.listData[type]),
      loading:true
    })
    let perPage = 20;
  
    let fq = `object_type_srt:"${type}" AND job_type_kc_msrt:"${state.basicData.name}"`;
    if(!showDeleted)fq += ` AND active_srt:YES`
    axiosSolr.get(
      '/solr/search/select',
      {
        params: {
          q:getNameSearchQuery(search),
          fq,
          sort,
          start,
          rows: perPage,
        }
      }
    )
    .then((response) => {
      dispatch({ 
        type: 'set_list_data', 
        listData: {
          ...state.listData,
          [type]:{
            ...response.data.response,
            docs:start===0?response.data.response.docs:[...state.listData[type].docs,...response.data.response.docs]
          }
        }
      });
    })
    .catch(error => {
      console.log('Error loading the data', error)
      dispatch({ 
        type: 'set_list_data', 
        listData: {
          ...state.listData,
          [type]:{error:true}
        }
      });
    })
  }

  useEffect(()=>{
    if(!tabOptions)loadTabOptions()
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(!tabOptions || tabOptions.length===0)return;
    let newSelectedType = tabOptions[tabState]
    if(!state.listData[newSelectedType] && !state.listData[newSelectedType]?.error && !state.listData[newSelectedType]?.loading){
      loadData({type:newSelectedType})
    }
  // eslint-disable-next-line
  },[tabState, tabOptions])


  const handleSeeMore = () => {
    let presetFilter = `job_type_kc_msrt:${state.basicData.name}`;
    history.push(`/basic_search?query=*&object=${selectedType}&presetFilter=${presetFilter}`)
  }

  const onChangeFilter = value => {
    loadData({sort:value})
    dispatch({ type: 'set_list_sort', listSort: value });
  }

  const onActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

  const onClickShowDeleted = showDeleted => {
    loadData({showDeleted:showDeleted})
    dispatch({ type: 'set_list_show_deleted', listShowDeleted: showDeleted });
  }

  const onChangeSearch = value => {
    dispatch({
      type:'set_list_search',
      listSearch:{
        ...state.listSearch,
        [selectedType]:value
      }
    })
    clearTimeout(searchTimeoutRef.current)
    searchTimeoutRef.current = setTimeout(()=>{
      loadData({search:value,type:selectedType})
    },300)
  }

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && data && !data.loading && data.docs.length<data.numFound
  }

  window.onscroll = () => {
    if(shouldLoadMore()){
      loadData({start:data.docs.length})
    }
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadData({start:data.docs.length})
    }
    // eslint-disable-next-line
  },[data])

  if(tabOptionsLoading)return <div style={{display:'flex',justifyContent:'center',marginTop:24}}><CircularProgress color='secondary'/></div>
  if(tabOptionsError)return <Typography style={{fontSize:13.75}}>Error occurred loading items</Typography>
  if(!tabOptions)return <div></div>
  if(tabOptions.length===0)return <Typography style={{fontSize:13.75}}>No data found</Typography>

  return (
    <div className={classes.root}>
      <div style={{display:'flex'}}>
        <VerticalTabBar
          minWidth={200}
          maxWidth={200}
          tabOptions={tabOptions}
          tabState={tabState}
          setTabState={value=>setTabState(value)}
          disableUnderline={true}
        />
        <div className={classes.body}>
          <div>
            <div style={{ display: 'flex', justifyContent:'space-between', alignItems: "flex-start",marginBottom:16}}>
              <div style={{ display: "flex", flexGrow:1, alignItems:'center' }}>
                <Typography className={classes.title}>
                  {data?(data.numFound||0):0} {selectedType.replace(/_/g,' ')}(S)
                </Typography>
              </div>
              <div style={{flexGrow:0,flexShrink:0, display:'flex', alignItems:'center'}}>
                <InputBase
                  value={state.listSearch[selectedType]||''}
                  onChange={event => onChangeSearch(event.target.value)}
                  variant={'filled'}
                  placeholder={'Search'}
                  className={classes.searchFilter}
                  endAdornment={
                    <IconButton 
                      disabled={(state.listSearch[selectedType]||'')===''} 
                      onClick={()=>onChangeSearch('')}
                      style={{width:32,height:32,marginRight:6}}
                    >
                      {getIconComponent({label:(state.listSearch[selectedType]||'')===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  }
                />
                <Select
                  className={classes.selector}
                  value={state.listSort}
                  disableUnderline
                  onChange={event => onChangeFilter(event.target.value)}
                >
                  {
                    sortByItems.map(el => (
                      <MenuItem value={el.value}>
                        <span>{el.dispName}</span>
                      </MenuItem>
                    ))
                  }
                </Select>
                <div style={{marginLeft:24,width:24,height:24,cursor:'pointer'}} onClick={onActionClick}>
                  {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
                </div>
                <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
                  <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
                    <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                      <Typography className={classes.listActionSectionTitle}>ACTIONS</Typography>
                      <MenuList className={classes.listContainer}>
                        <MenuItem onClick={()=>{handleSeeMore()}} className={classes.menuItem} >
                          <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>View in search</Typography>
                        </MenuItem>
                      </MenuList>
                      <Typography className={classes.listActionSectionTitle}>FILTERS</Typography>
                      <MenuItem data-test-id="child-list-show-deleted" onClick={()=>{onClickShowDeleted(!state.listShowDeleted)}} className={classes.menuItem} >
                        <Checkbox key={state.listShowDeleted} className={classes.checkbox} color='primary' checked={!state.listShowDeleted}/>
                        <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>Show active only</Typography>
                      </MenuItem>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </div>
            </div>

            {
              data && data.docs?.map(item=>(
                <ResultItem
                  key={item.id}
                  item={item}
                  dispBody={getDispFields(item,'dispBody')}
                  dispSubtitle={getDispFields(item,'dispSubtitle')}
                  dispTitle={getDispFields(item,'dispTitle')}
                  label={item.object_type_txt}
                  showIcon
                  showUnderline
                  onClick={ () => onClickResultItem({ id:item.id,label: item.object_type_txt, history, item: item })}
                />
              ))
            }
            {
              data && data.numFound===0 &&
              <Typography className={classes.normalText} style={{marginTop:16}}>No item to be displayed.</Typography> 
            }
            {
              data && data.error &&
              <Typography className={classes.normalText} style={{marginTop:16}}>Error occurred loading Tables</Typography> 
            }
            <div style={{textAlign:'center',marginTop:16,marginBottom:24}} ref={scrollRef}>
              {
                data && data.loading && 
                <CircularProgress color='secondary'/>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


UsedList.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(UsedList));