import React, { useReducer, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Header from '../../components/OnBoardSource/Header/Header';
import Body from '../../components/OnBoardSource/Body/Body';
import axiosCerebrum from '../../axios-cerebrum';
import { addHistory } from '../../HistoryManager';
import { adminPageInfo } from '../NavBar/utils';
import { CerebrumLongListLoader } from '../../LongListLoader';

const styles = theme => ({
  buttons:{
    position:'fixed',
    bottom:0,
    height:70,
    right:0,
    left:40,
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end',
    background:theme.palette.background.main,
    borderTop:`1px solid ${theme.palette.border.main}`
  },
  title:{
    color:theme.palette.header.main,
  }
})

const initialState = {
  tabState:0,
  sourceTabState:0,
  sourceActiveTabState:0,
  sourceManualTabState:0,
  selectedSource: {},
  modalOpen:false,
  sourcesUpdated:false,
  referenceSearchValue:'',
  importHistoryFilter:'all'
}

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState
      }
    case 'set_source_tab_state':
      return {
        ...state,
        sourceTabState: action.sourceTabState
      }
    case 'set_source_active_tab_state':
      return {
        ...state,
        sourceActiveTabState: action.sourceActiveTabState
      }
    case 'set_source_manual_tab_state':
      return {
        ...state,
        sourceManualTabState: action.sourceManualTabState
      }
    case 'set_active_sources':
      return {
        ...state,
        activeSources:action.activeSources,
        activeSourcesLoading:action.activeSourcesLoading,
        activeSourcesError:action.activeSourcesError
      }
    case 'set_manual_sources':
      return {
        ...state,
        manualSources:action.manualSources,
        manualSourcesLoading:action.manualSourcesLoading,
        manualSourcesError:action.manualSourcesError
      }
    case 'set_inactive_sources':
      return {
        ...state,
        inactiveSources:action.inactiveSources,
        inactiveSourcesLoading:action.inactiveSourcesLoading,
        inactiveSourcesError:action.inactiveSourcesError
      }
    case 'set_reference_sources':
      return {
        ...state,
        referenceSources:action.referenceSources,
        referenceSourcesLoading:action.referenceSourcesLoading,
        referenceSourcesError:action.referenceSourcesError
      }
    case 'set_reference_search_value':
      return {
        ...state,
        referenceSearchValue:action.referenceSearchValue
      }
    case 'set_import_source_modal_open':
      return {
        ...state,
        importSourceModalOpen:action.importSourceModalOpen
      }
    case 'set_import_source':
      return {
        ...state,
        importSource:action.importSource
      }
    case 'set_import_history_filter':
      return {
        ...state,
        importHistoryFilter:action.importHistoryFilter
      }
    case 'set_sources_updated':
      return {
        ...state, sourcesUpdated:action.sourcesUpdated
      }
    case 'set_source_created':
      return {
        ...state,
        sourceCreated:action.sourceCreated
      }
    case 'set_selected_source':
      return {
        ...state,
        selectedSource:action.selectedSource
      }
    case 'set_hosts':
      return {
        ...state,
        hosts:action.hosts,
        hostLoading:action.hostLoading,
        hostError:action.hostError
      }
    case 'set_hosts_updated':
      return {
        ...state, hostsUpdated:action.hostsUpdated
      }
    case 'set_selected_host':
      return {
        ...state,
        selectedHost:action.selectedHost
      }
    case 'set_templates':
      return {
        ...state,
        templates: action.templates,
        templateLoading:action.templateLoading,
        templateError:action.templateError
      }
    case 'set_db_data':
      return {
        ...state,
        dbData: action.dbData,
        dbError:action.dbError,
        dbLoading:action.dbLoading
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

function Migration(props) {
  const {
    classes,
    history,
    sessionData
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);


  useEffect(()=>{
    addHistory({
      url:window.location.pathname, 
      iconLabel:adminPageInfo.sources.icon, 
      title: adminPageInfo.sources.name, 
      subTitle:adminPageInfo.sources.description,
      type:'application',
    })
  },[])



  const templateFetch = () => {
    dispatch({
      type:'set_templates',
      templateLoading:true
    })
    const load = ({prevData=[],page=1}) => {
      axiosCerebrum
        .get(
          '/api/sourcetemplates',
          {params:{
            per_page:100,
            types:'TOOL,DATABASE,IDENTITY_STORE',
            page
          }}
        )
        .then(response=>{
          let data = [...prevData, ...response.data.items]
          if(response.data.page<response.data.pages){
            load({prevData:data, page:response.data.page+1 })
          }else{
            dispatch({
              type:'set_templates',
              templates:{...response.data,items:data}
            })
          }
        })
        .catch(error=>{
          console.log(error)
          dispatch({
            type:'set_templates',
            templates:{items:prevData}
          })
        })
    }
    load({})
  }

  const dbFetch = () => {
    CerebrumLongListLoader({
      url:'/api/databases',
      params:{},
      per_page:500,
      onStartLoad:()=>{
        dispatch({
          type:'set_db_data',
          dbLoading:true
        })
      },
      onFinishLoad:data=>{
        dispatch({
          type:'set_db_data',
          dbData:{
            items:data.data,
            total:data.data.length
          },
        })
      },
      onError:()=>{
        dispatch({
          type:'set_db_data',
          dbError:true
        })
      }
    })
  }

  useEffect(()=>{
    if(state.tabState===1 || state.tabState===2 || state.tabState===6){
      if(!state.templates)templateFetch();
      if(!state.dbData)dbFetch();
    }
  // eslint-disable-next-line
  },[state.tabState])

  return (
    <div className={classes.root}>
      <ProfileLayout
        noPadding
        header={(
          <Header
            dispatch={dispatch}
            state={state}
            history={history}
            tabState={state.tabState}
            setTabState={tabState => dispatch({ type: 'set_tab_state', tabState })}
          />
        )}
        body={
          <div>
            <Body
              tabState={state.tabState}
              history={history}
              state={state}
              dispatch={dispatch}
              sessionData={sessionData}
              dbFetch={dbFetch}
            />  
          </div>
          
        }
      > 
        {/* <div style={{height:100}}></div> */}
        
      </ProfileLayout>
    </div>
  )

}

export default withStyles(styles)(Migration);