import React, { } from 'react';
import { withTheme, Typography,  FormControl, FormHelperText, Input, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getIconComponent } from '../../../../utilities';


const UserNumSelector = props => {

  const {
    classes,
    theme,
    userNum,
    setUserNum,
  } = props;


  return (
    <div className={classes.modalBlock}>
      <Typography  className={classes.modalBlockTitle}>Maximum number of Users per Table</Typography>
      <div style={{flexGrow:1}}>
        <FormControl style={{width:'100%'}}>
          <Input
            value={userNum}
            style={{height:56}}
            className={classes.inputBase}
            onChange={event=>setUserNum(event.target.value)}
            placeholder='Set the number of users'
            startAdornment={
              <InputAdornment position="start" style={{marginLeft:20,marginRight:16}}>
                {getIconComponent({label:'user', size:24, colour:theme.palette.primaryText.light})}
              </InputAdornment>
            }
          />
          <FormHelperText className={classes.helperText}>Set to 0 to find Tables with no users</FormHelperText>
        </FormControl>
      </div>
    </div>
  )
}

UserNumSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  userNum: PropTypes.number.isRequired,
  setUserNum: PropTypes.func.isRequired,
}

export default withTheme()(UserNumSelector);