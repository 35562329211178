import React,{useState,useEffect, useRef } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Button, Modal, Paper, Select, MenuItem } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import DraggableEl from './DraggableEl';
import { globalListenerRef } from '../../../GlobalListenerRef';
import { getIconComponent, isInViewport, onClickResultItem } from '../../../utilities';
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
import {useDispatch} from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';
import useAlert from '../../../hooks/useAlert';
import { getPutPayload } from '../../UI/UpdateInput/utils';

const styles = theme => ({
  title:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: '500',
    color:theme.palette.header.main,
    marginBottom: 16,
  },
  modalSubTitle: {
    fontSize:13.75,
    marginBottom: 8,
    whiteSpace:'pre-wrap',
    color:theme.palette.primaryText.main
  },
  buttons: {
    flexGrow:0,
    backgroundColor: theme.palette.background.main,
    paddingTop: 12,
    marginRight:-16,
    marginTop:5,
    width:'100%',
    display:'flex',
    justifyContent:'flex-end'
  },
  selector: {
    ...theme.components.selector,
		width: 150,
  },
})

function Details(props) {

  const {
    classes,
    state,
    dispatch,
    instanceName,
    theme,
    title,
    viewOnly,
    subTitle,
    editable,
    headerFontSize
  } = props;

  const [dragging, setDragging] = useState(false)
  const [dragPosition, setDragPosition] = useState()
  const [selectedItem, setSelectedItem] = useState()
  const [loading, setLoading] = useState()
  const [deleteModalOpen, setDeleteModalOpen] = useState()
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [deleting, setDeleting] = useState(false)
  const expandSelectedRef = useRef()

  const cursorStayTimeoutRef = useRef(true)

  const editing = state.instanceEditing;
  const setEditing = value => dispatch({type:'set_instance_editing',instanceEditing:value})

  const scrollRef = useRef()
  const reduxDispatch = useDispatch()

  const {
    sendAlert
  } = useAlert({
    id:`collection-profile-delete`,
  })

  
  const loadInstances = ({rootId, isExpand, page=1, isReload}) => {
    setLoading(rootId)
    if(isExpand){
      let newData = [...state.instanceData] 
      newData.find(d=>d.id===rootId).expanded=true
      dispatch({
        type: 'set_instance_data',
        instanceData:newData,
      })
    }
    if(isReload){
      dispatch({
        type: 'set_instance_data',
        instanceData:undefined,
        instanceLoading:true
      })
    }

    let per_page = 10;
    if(page!==1 && state.instanceData.filter(d=>d.upperLevelID===rootId).length<per_page){
      page = 1;
    }

    axiosCerebrum
      .get(
        `/api/collectioninstances`,{
          params:{
            per_page,
            page:page,
            ...(
              rootId===state.basicData.id && state.basicData.object.name==='COLLECTION'?
              {hierarchy_parent_id:'null', collection_id:state.basicData.id}:
              {hierarchy_parent_id:rootId}
            )
          }
        }
      )
      .then(response=>{
        dispatch({
          type:'set_instance_data_page_map',
          instanceDataPageMap:{
            ...state.instanceDataPageMap,
            [rootId]:{
              page:response.data.page,
              pages:response.data.pages
            }
          }
        })

        let data = response.data.items.map(i=>({
          ...i,
          has_children:i.hierarchy_children?.length>0,
          upperLevelID:rootId,
          expanded:false,
          childLoaded:false
        })).filter(i=>i.id!==state.basicData.id)

        let finalData = isReload?data:[...(state.instanceData||[]).filter(d=>!data.find(n=>n.id===d.id)),...data]
        if(rootId!==state.basicData.id)finalData.find(d=>d.id===rootId).childLoaded=true
        dispatch({
          type: 'set_instance_data',
          instanceData:finalData
        });
        setLoading(false)
      })
      .catch(error=>{
        console.log(error)
        if(rootId===state.basicData.id){
          dispatch({
            type: 'set_instance_data',
            instanceError:true
          })
        }
        setLoading(true)
      })
  }

  const checkIfParent = (childId, parentId) => {
    let isParent = false;
    let upperID = state.instanceData.find(i=>i.id===childId).upperLevelID;
    while(upperID!==state.basicData.id){
      if(parentId===upperID){
        isParent=true;
        break;
      }
      // eslint-disable-next-line
      upperID = state.instanceData.find(l=>l.id===upperID).upperLevelID
    }
    return isParent
  }
  
  const onDragEnd = () => {
    clearTimeout(expandSelectedRef.current)

    let parentId = selectedItem;
    let childId = dragging;
    setDragging(false);
    setDragPosition();
    setSelectedItem();
    if(parentId===childId)return;
    if(!parentId || !childId)return;
    if(state.instanceData.find(i=>i.id===childId).upperLevelID===parentId)return;

    if(parentId!==state.basicData.id && checkIfParent(parentId, childId)){
      sendAlert({type:'error',message:'You cannot move a parent into its child'})
      return;
    }

    let originalParentId = state.instanceData.find(i=>i.id===childId).upperLevelID;
    dispatch({
      type:'set_instance_data',
      instanceData:state.instanceData.map(el=>{
        if(el.id!==childId)return el;
        return {
          ...el,
          upperLevelID:parentId,
        }
      })
    })
    axiosCerebrum
      .put(
        `/api/collectioninstances/${childId}`,
        getPutPayload({
          property:'hierarchy_parent_id', 
          value:parentId===state.basicData.id && state.basicData.object.name==='COLLECTION'?null:parentId, 
          data:state.instanceData.find(i=>i.id===childId)
        })
      )
      .then(response=>{
        if(parentId===state.basicData.id)return;
        if(state.instanceData.find(i=>i.id===parentId).expanded)return;
        setTimeout(()=>{
          document.getElementById(`expandable-item-${parentId}`).click()
        },500)
      })
      .catch(error=>{
        console.log(error)
        sendAlert({type:'error',message:'Error occurred moving the item, please try again'})
        dispatch({
          type:'set_instance_data',
          instanceData:state.instanceData.map(el=>{
            if(el.id!==childId)return el;
            return {
              ...el,
              upperLevelID:originalParentId,
            }
          })
        })
      })
  }

  const onItemExpand = id => {
    let item = state.instanceData.find(i=>i.id===id)
    if(!item.childLoaded && item.has_children){
      loadInstances({rootId:id, isExpand:true})
    }else{
      dispatch({
        type:'set_instance_data',
        instanceData:state.instanceData.map(el=>{
          if(el.id!==id)return el;
          return {
            ...el,
            expanded:!el.expanded
          }
        })
      })
    }
  }

  const onDelete = (item) => {
    if(item.has_children && !item.childLoaded)loadInstances({rootId:item.id})
    setDeleteModalOpen({item})
  }

  const onRemoveItem = item => {
    setDeleting(true)
    axiosCerebrum
      .delete(`/api/collectioninstances/${item.id}`)
      .then(response=>{
        let parentId = item.upperLevelID;
        let newData = state.instanceData.filter(i=>i.id!==item.id).map(i=>{
          if(i.upperLevelID!==item.id)return i;
          return {
            ...i,
            upperLevelID:parentId
          }
        })
        dispatch({type:'set_instance_data',instanceData:newData})
        reduxDispatch({
          type:actions.REMOVE_PAGE_CACHE,
          data:`${document.location.protocol + "//" + document.location.host}`+onClickResultItem({urlOnly:true, item, id:item.id, label:'collection_instance'})
        })
        sendAlert({type:'info',message:`${item.name} successfully deleted`})
        setDeleteModalOpen(false)
        setDeleting(false)
      })
      .catch(error=>{
        console.log(error);
        setDeleting(false)
        let msg = `Error occurred deleting ${item.name}, please try again`;
        if(error.response && error.response.status && error.response.status===403){
          msg = `You are not allowed to delete this instance`
        }
        setAlertMessage(msg)
        setAlertOpen(true)
      })
  }

  const onDrag = event =>{
    if(cursorStayTimeoutRef.current===true){
      setDragPosition({x:event.clientX, y:event.clientY})
      cursorStayTimeoutRef.current=false
      setTimeout(()=>{
        cursorStayTimeoutRef.current=true
      },30)
    }
  }

  const onAddInstance = item => {
    dispatch({
      type:'set_add_instance_modal_open',
      addInstanceModalOpen:{
        presetData:{
          parent:{
            ...item,
            object:{
              name:'COLLECTION_INSTANCE'
            },
            parent:state.basicData
          }
        },
      }
    })
  }

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && state.instanceDataPageMap[state.basicData.id] && state.instanceDataPageMap[state.basicData.id].page<state.instanceDataPageMap[state.basicData.id].pages && !state.instanceLoading && !loading
  }

  window.onscroll = () => {
    if(shouldLoadMore()){
      loadInstances({rootId:state.basicData.id, page:state.instanceDataPageMap[state.basicData.id].page+1 })
    }
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadInstances({rootId:state.basicData.id, page:state.instanceDataPageMap[state.basicData.id].page+1 })
    }
  // eslint-disable-next-line
  },[state.instanceData, state.instanceLoading, loading])

  const onLoadMore = id => {
    loadInstances({rootId:id, page:state.instanceDataPageMap[id].page+1 })
  }

  useEffect(()=>{
    if(selectedItem){
      clearTimeout(expandSelectedRef.current)
      if(selectedItem===dragging)return;
      if(selectedItem===state.basicData.id)return;
      if(loading)return;
      let children = state.instanceData.filter(l=>l.upperLevelID===selectedItem.id);
      let hasChildren = state.instanceData.find(i=>i.id===selectedItem).has_children || children.length>0;
      let expanded = state.instanceData.find(i=>i.id===selectedItem).expanded;
      if(!hasChildren || expanded)return;
      expandSelectedRef.current = setTimeout(()=>{
        onItemExpand(selectedItem)
      },1000)
    }
  // eslint-disable-next-line
  },[selectedItem, dragging, loading])
  
  useEffect(()=>{
    if(!state.instanceData && !state.instanceLoading)loadInstances({rootId:state.basicData.id})
  // eslint-disable-next-line
  },[])
  
  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.add_instance ){
        let obj = JSON.parse(msg.data.add_instance.instance)
        dispatch({type:'set_instance_data',instanceData:[...state.instanceData,obj]})

        let parent = state.instanceData.find(i=>i.id===obj.parent_id)
        if(parent && !parent.expanded){
          setTimeout(()=>{
            document.getElementById(`expandable-item-${parent.id}`).click()
          },500)
        }
        
      }
    }
    window.removeEventListener('message',globalListenerRef.addInstanceListener);
    globalListenerRef.addInstanceListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.addInstanceListener);
    return (()=>{window.removeEventListener('message',globalListenerRef.addInstanceListener);})
  // eslint-disable-next-line
  },[state.instanceData])


  return (
    <div className={classes.root}>
      <div style={{marginTop:8,marginBottom:16,display:'flex'}}>
        <div style={{display:'flex',flexGrow:1,alignItems:'flex-start'}}>
          <div>
            <Typography className={classes.title} style={{fontSize:headerFontSize}}>{title || `${instanceName}(S)`}</Typography>
            {
              subTitle && 
              <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>{subTitle}</Typography>
            }
          </div>
          {
            !viewOnly && editable && state.instanceData && state.instanceData.length>0 &&
            <Button color='primary' data-test-id="child-edit-button" onClick={()=>setEditing(!editing)} style={{marginLeft:12}}>
              {
                editing?'CLOSE':'EDIT'
              }
            </Button>
          }
        </div>
        {
          !viewOnly && state.instanceData && state.instanceData.length>0 &&
          <Select
            className={classes.selector}
            style={{width:100,marginLeft:16}}
            value={state.instanceView}
            data-test-id="child-view-selector"
            onChange={event => dispatch({type:'set_instance_view',instanceView:'list'})}
          >
            <MenuItem  className={classes.menuItem} value={'tree'}>
              Tree
            </MenuItem>
            <MenuItem  className={classes.menuItem} value={'list'}>
              List
            </MenuItem>
          </Select>
        }
      </div>
      {
        state.instanceError && 
        <Typography style={{fontSize:13.75}}>Error occurred loading items</Typography>
      }
      {
        state.instanceData && state.instanceData.length===0 && 
        <Typography data-test-id="draggable-instance-no-found"  style={{fontSize:13.75}}>No {instanceName.toLowerCase()}s found</Typography>
      }
      {
        state.instanceData && state.instanceData.filter(el=>el.upperLevelID===state.basicData.id).map(el=>(
          <DraggableEl
            dragging={dragging}
            setDragging={setDragging}
            item={el}
            dragPosition={dragPosition}
            onDrag={onDrag}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            onDragEnd={onDragEnd}
            onItemExpand={onItemExpand}
            list={state.instanceData}
            loading={loading}
            rootCollection={state.basicData}
            editing={editing}
            onDelete={onDelete}
            onAddChild={onAddInstance}
            pageMap={state.instanceDataPageMap}
            onLoadMore={onLoadMore}
            dispatch={dispatch}
            state={state}
          />
        ))
      }
      <div style={{display:'flex',justifyContent:'center',marginTop:6}} ref={scrollRef}>
        {
          (state.instanceLoading || loading===state.basicData.id) &&
          <CircularProgress color='secondary'/>
        }
      </div>


      <Modal 
        open={deleteModalOpen} 
        onClose={()=>setDeleteModalOpen(false)}
        disableBackdropClick={true}
      > 
        <div style={{outline:'none',width:'max-content',margin:'25vh auto 0 auto'}}>
          {
            deleteModalOpen && 
            <Paper style={{width:720,padding:24,paddingBottom:8, background:theme.palette.background.main,border:`1px solid ${theme.palette.border.main}`}}>
              <Typography className={classes.modalTitle}>DELETE {deleteModalOpen.item.name.toUpperCase()}</Typography>
              <Typography className={classes.modalSubTitle}>
                Are you sure? This action cannot be reversed
              </Typography>
              {
                (deleteModalOpen.item.has_children || state.instanceData.find(i=>i.upperLevelID===deleteModalOpen.item.id)) && 
                <div className={classes.modalSubTitle} >
                  <div style={{display:'inline-block',position:'relative',bottom:-8,marginRight:12,lineHeight:1.5}}>
                    {getIconComponent({label:'warning',size:24,colour:theme.palette.primaryText.light})}
                  </div>
                  {state.instanceData.filter(i=>i.upperLevelID===deleteModalOpen.item.id).length} child {instanceName.toLowerCase()}(s) of {deleteModalOpen.item.name} will become child of {deleteModalOpen.item.upperLevelID===state.basicData.id?state.basicData.name:state.instanceData.find(i=>i.id===deleteModalOpen.item.upperLevelID).name}
                </div>
              }
              <div className={classes.buttons}>
                {
                  deleting?
                  <div style={{height:36,width:36,display:'flex',alignItems:'center'}}>
                    <CircularProgress style={{width:24,height:24}} color='secondary'/>
                  </div>
                  :
                  <Button color="primary" style={{width:80}} onClick={()=>onRemoveItem(deleteModalOpen.item)} disabled={deleting}>
                    {'DELETE'}
                  </Button>
                }
                <Button color="secondary" style={{width:80, marginLeft: 8}}  onClick={()=>setDeleteModalOpen()}>
                  CLOSE
                </Button>
              </div>
            </Paper>
          }
          <ModalAlert
            open={alertOpen}
            setOpen={setAlertOpen}
            message={alertMessage}
            type='error'
          />
        </div>
      </Modal>

    </div>
  )
}

export default withTheme()(withStyles(styles)(Details));