import React from 'react';
import { withTheme, withStyles, Typography, CircularProgress} from '@material-ui/core';
import HeatMap from './HeatMap'

const styles = theme => ({
  title:{
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:8
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  tile:{
    flex:'0 0 80px',
    marginTop:14,
    marginLeft:32
  },
  tileValue:{
    fontSize: 24,
    color:theme.palette.primaryText.light,
    marginBottom:4
  },
  tileName:{
    fontSize:12,
    color:theme.palette.primaryText.main
  }
})

function Contributions(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
  } = props;



  return (
    <div style={{marginBottom:32}}>
      <Typography className={classes.title}>KNOWLEDGE CONTRIBUTIONS</Typography>
      {
        state.statsLoading && <CircularProgress color='secondary'/>
      }
      {
        state.statsError && <Typography className={classes.normalText}>Error occurred loading stats</Typography>
      }
      {
        state.statsData && 
        <div style={{display:'flex'}} id="knowledge_contribution">
          <div style={{flex:'0 0 450px'}}>
            <HeatMap
              state={state}
              dispatch={dispatch}
            />
          </div>
          <div className={classes.tile}>
            <Typography className={classes.tileValue} style={{color:theme.palette.primary.main}}>{state.statsData.actions_pending}</Typography>
            <Typography className={classes.tileName}>Pending Actions</Typography>
          </div>
          <div className={classes.tile}>
            <Typography className={classes.tileValue}>{state.statsData.contribution_total}</Typography>
            <Typography className={classes.tileName}>Total Contributions</Typography>
          </div>
          <div className={classes.tile} style={{flex:'0 0 110px'}}>
            <Typography className={classes.tileValue}>{state.statsData.contribution_weekly_average}</Typography>
            <Typography className={classes.tileName}>Avg Contributions per week</Typography>
          </div>
        </div>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(Contributions));