import React from 'react';
import { withStyles, Typography} from '@material-ui/core';
import ListItem from '../Components/ListItem';
import { collectionIds, instanceIds, getUserRoles } from '../../../utilities'
import { useStore } from 'react-redux'
 

const styles = theme => ({
  root:{
    marginBottom:40
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
    marginBottom:16
  }
})

const DataGovernanceSetup = props => {

  const {
    classes,
    history,
    state,
    dispatch,
  } = props;


  const store = useStore();
  let sessionData = store.getState().auth.session_user;
  let roles = getUserRoles(sessionData.user_role);
  
  let dataRolesValue = state.settingsData.find(s=>s.id===4008)?state.settingsData.find(s=>s.id===4008).value:undefined;


  return (
    <div className={classes.root}>
      <Typography className={classes.title}>DATA GOVERNANCE SETUP</Typography>
      <ListItem 
        state={state}
        dispatch={dispatch}
        type="link" 
        title={'Configure Data Classifications'}
        onClick={()=>{history.push(`/profile/collection/${collectionIds.classification}`)}}
      />
      <ListItem 
        state={state}
        dispatch={dispatch}
        type="link" 
        title={'Configure Data Domains'}
        onClick={()=>{history.push(`/profile/collection/${collectionIds.domain}`)}}
      />
      <ListItem 
        state={state}
        dispatch={dispatch}
        type="link" 
        title={'Configure Data & Analytical Content Categories'}
        onClick={()=>{history.push(`/profile/collection/${collectionIds.category}`)}}
      />
      <ListItem 
        state={state}
        dispatch={dispatch}
        type="link" 
        title={'Configure Verified Use Cases for Data and Analytical Content'}
        onClick={()=>{history.push(`/profile/collection/${collectionIds.verifiedUseCase}`)}}
      />
      <ListItem 
        state={state}
        dispatch={dispatch}
        type="link" 
        title={'Create a Business Glossary'}
        onClick={()=>{history.push(`/admin/collection?tabName=GLOSSARY`)}}
      />
      {
        roles.find(r=>['00','40'].includes(r)) && 
        <ListItem 
          state={state}
          dispatch={dispatch}
          type="link" 
          title={'Create a Data Governance Collection'}
          onClick={()=>{history.push(`/admin/collection?tabName=DATA_GOVERNANCE`)}}
        />
      }
      {
        state.settingsData.find(s=>s.id===4008) && 
        <ListItem 
          state={state}
          dispatch={dispatch}
          type="platform_setting" 
          platformSettingID={4008} 
          title={`Configure Data Owner / Steward Lookup`}
          platformSettingName={'user lookup'}
          initSetting={state.settingsData.find(s=>s.id===4008)}
        />
      }
      {
        dataRolesValue==='data_roles' && 
        <ListItem 
          state={state}
          dispatch={dispatch}
          type="link" 
          title={'Assign Data Owners'}
          onClick={()=>{history.push(`/profile/collection_instance/${instanceIds.dataOwner}`)}}
        />
      }
      {
        dataRolesValue==='data_roles' && 
        <ListItem 
          state={state}
          dispatch={dispatch}
          type="link" 
          title={'Assign Data Stewards'}
          onClick={()=>{history.push(`/profile/collection_instance/${instanceIds.dataSteward}`)}}
        />
      }
    </div>
  )
}

export default withStyles(styles)(DataGovernanceSetup);