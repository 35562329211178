import React from 'react';
import { withTheme, withStyles, Typography, InputBase, Checkbox, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toTitleCase } from '../../../utilities';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root: {
  },
  block:{
    marginBottom:32
  },
  title:{
    marginBottom:16,
    fontSize:16,
    color:theme.palette.header.main,
  },
  textField:{
    ...theme.components.inputBase,
    width:800,
    margin:0,
    '& input':{
      height:56,
      padding:0,
      paddingLeft:16,
    },
    '& div':{
      padding:0,
    },
    '& textarea':{
      padding:16,
      wordBreak:'break-all',
    },
    '& p':{
      margin:0,
      background:theme.palette.background.main,
      padding:'8px 12px 0',
      color:theme.palette.primaryText.light
    }
  },
  subtitle:{
    fontSize:12,
    marginBottom:16,
    color:theme.palette.primaryText.main
  },
  checkbox:{
    padding:'0px 8px 0px 0px',
    color:theme.palette.primaryText.light
  },
  normalText:{
    color:theme.palette.primaryText.light
  },
  selector:{
    ...theme.components.selector,
    width: 800,
    paddingTop:7,
    paddingBottom:7,
    '& div div':{
      paddingLeft:16,
      color:theme.palette.primaryText.light,
      fontSize:16
    }
  },
  IconButton:{
    width:24,
    height:24,
    padding:0
  },
  helperText:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginLeft:16,
    marginTop:2
  },

  focusBorder:{
    border:`1px solid ${theme.palette.error.main} !important`,
  },
  errorText:{
    color:theme.palette.error.main
  },
  chip:{
    padding:'12px 28px',
    borderRadius:4,
    border:`1px solid ${theme.palette.primary.main}`,
    color:theme.palette.primary.main,
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    marginRight:40,
    fontSize:13.75
  },
  selectedChip:{
    background:theme.palette.primary.main,
    color:theme.palette.background.main
  }
})

function AddDetails(props) {

  const {
    classes,
    theme,
    state,
    dispatch,
    isFieldComplete
  } = props;

  const {
    sendAlert
  } = useAlert({
    id:`create-collection-add-details`,
  })


  const onChangeType = (type) => {
    if(type!==state.collectionType){
      dispatch({type:'set_should_update',shouldUpdate:true})
    }
    dispatch({type:'set_collection_type',collectionType:type});
  }

  const onChangeName = name => {
    dispatch({type:'set_should_update',shouldUpdate:true})
    if(name.length<=30)dispatch({type:'set_collection_name',collectionName:name})
  }

  const onChangeShortName = shortName => {
    dispatch({type:'set_should_update',shouldUpdate:true})
    if(shortName.length<=10)dispatch({type:'set_collection_short_name',collectionShortName:shortName})
  }

  const onChangeDescription = description => {
    dispatch({type:'set_should_update',shouldUpdate:true})
    dispatch({type:'set_description',description:description})
  }

  const onChangeEditableBy = (el, checked) => {
    if(!checked && state.editableBy.filter(el=>el!=='KADA_ADMIN').length<=1){
      sendAlert({type:'info',message:"At least 1 role must be selected"})
      return;
    }
    dispatch({type:'set_should_update',shouldUpdate:true})
    dispatch({type:'set_editable_by',item:el.name,checked})
  }

  // const onChangeLinkBy = (el, checked) => {
  //   dispatch({type:'set_should_update',shouldUpdate:true})
  //   dispatch({type:'set_link_by',item:el.name,checked})
  // }



  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <Typography  style={{color:theme.palette.header.main,fontSize:20}}>{state.editMode?`EDIT DETAILS`:`ADD DETAILS`}</Typography>
        <Typography  className={classes.normalText} style={{fontSize:12}}>Fill in details about this collection including what type of collection it is, what it can be linked to and who can link it.</Typography>
      </div>
      
      {
        !state.editMode && !state.createdCollection && 
        <div className={classes.block}>
          <Typography  className={classes.title}>
            COLLECTION TYPE
          </Typography>
          <div style={{display:'flex'}}>
            {
              ['GLOSSARY','DATA_MANAGEMENT','DATA_GOVERNANCE'].filter(ct=>state.categoryData.map(el=>el.name).includes(ct)).map(el=>(
                <div  
                  data-test-id={`create-collection-type-${el.replace(/_/g,'-').toLowerCase()}`}
                  className={classes.chip + (state.collectionType===el?' '+classes.selectedChip:'')}
                  onClick={()=>onChangeType(el)}
                >
                  {el.replace('_',' ')}
                </div>
              ))
            }
          </div>
        </div>
      }

      {
        !state.editMode && !state.createdCollection && 
        <div className={classes.block}>
          <Typography  className={classes.title}>
            COLLECTION NAME
          </Typography>
          <InputBase
            id="collection_name"
            variant="filled"
            className={classes.textField+(isFieldComplete('name',state.collectionName)?'':" "+classes.focusBorder)}
            value={state.collectionName}
            inputProps={{ "data-testid": 'collectionName-input-textField'}} 
            onChange={event=>onChangeName(event.target.value)}
            placeholder='Give this collection a name e.g.Source System'
            margin="normal"
            autoFocus
          />
          <Typography className={classes.helperText + (isFieldComplete('name',state.collectionName)?'':" "+classes.errorText)}>Required. Max 30 characters</Typography>
        </div>
      }

      <div className={classes.block}>
        <Typography className={classes.title}>
          COLLECTION SHORT NAME
        </Typography>
        <InputBase
          id="collection_short_name"
          variant="filled"
          className={classes.textField+(isFieldComplete('shortName',state.collectionShortName)?'':" "+classes.focusBorder)}
          value={state.collectionShortName}
          onChange={event=>onChangeShortName(event.target.value)}
          placeholder='Give this collection a short name'
          margin="normal"
          autoFocus={state.editMode || state.createdCollection}
        />
        <Typography className={classes.helperText + (isFieldComplete('shortName',state.collectionShortName)?'':" "+classes.errorText)}>
          {
            state.collectionShortName && state.collectionShortName!=='' && !isFieldComplete('shortName',state.collectionShortName)?
            `Short name must be alphanumeric and underscore characters only and can not start with number, underscore or space.`:
            `Required. Used to display in Tags and Search. Max 10 characters`
          } 
        </Typography>
      </div>
      
      <div className={classes.block}>
        <Typography  className={classes.title}>
          DESCRIPTION
        </Typography>
        <InputBase
          id="collection_description"
          variant="filled"
          className={classes.textField+(isFieldComplete('description',state.description)?'':" "+classes.focusBorder)}
          multiline
          rows={10}
          value={state.description}
          inputProps={{ "data-testid": 'collectionDescription-input-textField'}} 
          onChange={event=>onChangeDescription(event.target.value)}
          placeholder='Describe the purpose of collection'
          margin="normal"
        />
        <Typography className={classes.helperText+(isFieldComplete('description',state.description)?'':" "+classes.errorText)}>Required</Typography>
      </div>

      <div className={classes.block}>
        <Typography  className={classes.title} style={{marginBottom:8}}>
          EDITABLE BY
        </Typography>
        <Typography className={classes.subtitle}>
          Select the roles that can add, edit and delete item(s) in this collection. More than 1 role can be selected.
        </Typography>
        {
          state.rolesData.filter(el=>!['KADA_ADMIN'].includes(el.name)).map(el=>(
            <div style={{display:'flex',alignItems:'center',marginBottom:8}}>
              <Checkbox color='primary' className={classes.checkbox} inputProps={{ "data-test-id": `create-collection-editable-${el.name}`}} checked={state.editableBy.includes(el.name)}  onChange={(event,checked)=>onChangeEditableBy(el, checked)} />
              <Typography style={{fontSize:12,color:theme.palette.primaryText.main}}>{toTitleCase(el.name.split('_').join(' '))}</Typography>
            </div>
          ))
        }
      </div>

      <div className={classes.block}>
        <Typography  className={classes.title} style={{marginBottom:8}}>
          ENABLE DATA QUALITY METRICS
        </Typography>
        <Typography className={classes.subtitle}>
          Roll up data quality scores from data and content assets linked to instances in this collection. Collection DQ results are updated daily. 
        </Typography>
        <div style={{display:'flex'}}>
          <Button 
            style={{width:66,marginRight:16}} 
            color='primary' 
            variant={state.enableDQ?'outlined':'contained'}
            onClick={()=>{
              dispatch({type:'set_should_update',shouldUpdate:true})
              dispatch({type:'set_enable_dq',enableDQ:false})
            }}
          >
            NO
          </Button>
          <Button 
            style={{width:66}} 
            color='primary' 
            variant={state.enableDQ?'contained':'outlined'}
            onClick={()=>{
              dispatch({type:'set_should_update',shouldUpdate:true})
              dispatch({type:'set_enable_dq',enableDQ:true})
            }}
          >
            YES
          </Button>
        </div>
      </div>

      {/* <div className={classes.block}>
        <Typography color='primary' className={classes.title} style={{marginBottom:8}}>
          LINKED BY
        </Typography>
        <Typography className={classes.subtitle}>
          Select the roles that can link assets to this collection. More than one role can be selected.
        </Typography>
        {
          state.rolesData.filter(el=>!['KADA_ADMIN','KADA_DATA_GOV_USER'].includes(el.name)).map(el=>(
            <div style={{display:'flex',alignItems:'center',marginBottom:8}}>
              <Checkbox color='primary' className={classes.checkbox} inputProps={{ "data-testid": `linkedBy-${el.name}`}} checked={state.linkBy.includes(el.name)}  onChange={(event,checked)=>onChangeLinkBy(el, checked)} />
              <Typography style={{fontSize:12,color:theme.palette.primaryText.main}}>{toTitleCase(el.name.split('_').join(' '))}</Typography>
            </div>
          ))
        }
      </div> */}
    </div>
  )
}

AddDetails.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(AddDetails));

