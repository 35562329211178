import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Dashboard from '../../UI/Dashboard/DirectIndirectDashboard/DirectIndirectDashboard';
import Description from '../Details/Description';
import Map from '../Map/Map';
// import Reference from '../Reference/Reference';
import NoteAdder from '../../UI/NoteAdder/NoteAdder'
import Assets from '../TableList/Assets';


const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    ...theme.containers.card,
    flexGrow: 1,
    height: '100%',
    flexBasis: 0,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 8
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0 16px'
  },
})

function Body(props) {

  const {
    classes,
    tabState,
    history,
    data,
    fetchList,
    state,
    dispatch,
    sessionData,
    tabOptions,
    isShell
  } = props;

  let bodyContent;
  switch (tabOptions[tabState]) {
    case 'ASSETS':
      bodyContent = (
        <Assets
          history={history}
          data={data}
          nodeKey={state.basicData.nodeKey}
          state={state}
          dispatch={dispatch}
          isShell={isShell}
        />
      );
      break;
    case 'DETAILS': // details
      bodyContent = (
        <Description
          nodeKey={state.basicData.nodeKey}
          label='schema'
          history={history}
          fetchList={fetchList}
          userName={sessionData.user_name}
          profileState={state}
          profileDispatch={dispatch}
        />
      )
      break;
    case 'KNOWLEDGE':
      bodyContent = (
        <NoteAdder
          history={history}
          object={state.basicData}
          state={state}
          dispatch={dispatch}
          modalOpen={state.noteModalOpen}
          setModalOpen={value=>dispatch({type:'set_note_modal_open',noteModalOpen:value})}
        />
      )
      break;
    case 'MAP': // map
      bodyContent = (
        <Map
          history={history}
          nodeKey={state.basicData.nodeKey}
          state={state}
          label='schema'
          dispatch={dispatch}
        />
      )
      break;
    case 'USAGE': // summary
      bodyContent = (
        <Dashboard
          history={history}
          state={state}
          label='schema'
          dispatch={dispatch}
          drillThrough={true}
        />
      )
      break;
    // case 'RELATED':
    //   bodyContent = (
    //     <Reference
    //       history={history}
    //       data={data}
    //       state={state}
    //       dispatch={dispatch}
    //       isShell={true}
    //     />
    //   )
    //   break;
    default:
  }
 

  return (
    <div className={classes.root}>
      {bodyContent}
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number,
  tabUrlPrefix: PropTypes.string,
  nodeKey: PropTypes.string,
  data: PropTypes.object,
  history: PropTypes.object.isRequired,
  fetchList: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.object.isRequired,
  sessionData: PropTypes.object,
}

export default withStyles(styles)(Body);