import React, {useEffect, useState} from 'react';
import { withStyles, Typography, Modal, Paper, Button, InputBase, Select, MenuItem} from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum'
import ModalAlert from '../../UI/ModalAlert/ModalAlert'
import useAlert from '../../../hooks/useAlert';
import { validateEmail } from '../../../utilities';
import { getPutPayload } from '../../UI/UpdateInput/utils';

const styles = theme => ({
  modalRoot:{
    padding:24,
    // paddingBottom:8,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    margin:'20vh auto 0',
    maxHeight:'70vh',
    overflow:'auto'
  },
  title:{
    wordBreak:'break-word',
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:4
  },
  subtitle:{
    color:theme.palette.primaryText.light,
    fontSize:12,
    marginBottom:24
  },
  sectionHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:1.5,
    marginBottom:16
  },
  section:{
    marginBottom:24
  },
  inputBase:{
    ...theme.components.inputBase,
    height:48,
    width:'100%'
  },
  selector:{
    ...theme.components.selector,
    height:48,
    width:'100%',
    '& div div':{
      paddingLeft:16
    }
  },
  errorBorder:{
    border:`1px solid ${theme.palette.error.main}`
  },
  buttons:{
    marginTop:36,
    marginRight:-12,
    marginBottom:-16,
    display:'flex',
    justifyContent:'flex-end'
  },
})

const TeamEdit = props => {

  const {
    classes,
    state,
    dispatch,
    setModalOpen,
    modalOpen
  } = props;

  let user = modalOpen.user || {}
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [usageType, setUsageType] = useState('none')

  const [updating, setUpdating] = useState(false)

  const {
    sendAlert
  } = useAlert({})

  useEffect(()=>{
    if(modalOpen){
      let user = modalOpen.user || {}
      setFirstName(user.first_name||'')
      setLastName(user.last_name||'')
      setEmail(user.email||'')
      setUsageType(user.system_user_flag?'system':'user')
    }else{
      setFirstName('')
      setLastName('')
      setEmail('')
      setUsageType('none')
    }
  // eslint-disable-next-line
  },[modalOpen])

  const onSave = () => {

    let payload = getPutPayload({data:{...user,object:{name:'user'}}});
    payload.first_name = firstName
    payload.last_name = lastName
    payload.email = email
    payload.system_user_flag = usageType==='system'

    setUpdating(true)

    axiosCerebrum
      .put(
        `/api/users/${user.id}`,payload
      )
      .then(response=>{
        setUpdating(false)
        dispatch({
          type:'set_user_data',
          userData:{
            ...state.userData,
            items:state.userData.items.map(u=>{
              if(u.id===user.id){
                return {
                  ...u,
                  first_name:response.data.first_name,
                  last_name:response.data.last_name,
                  email:response.data.email,
                  system_user_flag:response.data.system_user_flag
                }
              }
              return u
            })
          }
        })
        setModalOpen(false)
        sendAlert({message:'User updated successfully',type:'info'})
      })
      .catch(error=>{
        setUpdating(false)
        setAlertMessage('Error occurred updating user')
        setAlertOpen(true)
        console.log(error)
      })
  }

  const checkIsEmailValid = email => {
    return validateEmail(email) || email===user.email
  }

  const checkAllFilled = () => {
    return (email.trim()==='' || checkIsEmailValid(email)) && usageType!=='none'
  }

  return (
    <Modal open={modalOpen} disableBackdropClick>
      <div style={{width:'max-content',margin:'auto',outline:'none'}}>
        <Paper className={classes.modalRoot} style={{width:450}}>
          <Typography className={classes.title}>Update {user.name}</Typography>
          <Typography className={classes.subtitle}>
            Onboarded user name and email are only editable via Admin Portal or are synchronised from your identity provider
          </Typography>
          <div className={classes.section}>
            <Typography className={classes.sectionHeader}>FIRST NAME</Typography>
            <InputBase
              className={classes.inputBase}
              value={firstName}
              onChange={e=>setFirstName(e.target.value)}
              placeholder='First Name'
            />
          </div>
          <div className={classes.section}>
            <Typography className={classes.sectionHeader}>LAST NAME</Typography>
            <InputBase
              className={classes.inputBase}
              value={lastName}
              onChange={e=>setLastName(e.target.value)}
              placeholder='Last Name'
            />
          </div>
          <div className={classes.section}>
            <Typography className={classes.sectionHeader}>EMAIL</Typography>
            <InputBase
              className={classes.inputBase + (email.trim() && !checkIsEmailValid(email)?' '+classes.errorBorder:'')}
              value={email}
              onChange={e=>setEmail(e.target.value)}
              placeholder='Email'
            />
          </div>
          <div className={classes.section}>
            <Typography className={classes.sectionHeader}>USAGE TYPE</Typography>
            <Select
              value={usageType}
              onChange={e=>setUsageType(e.target.value)}
              className={classes.selector + (usageType==='none'?' '+classes.errorBorder:'')}
              disableUnderline
            >
              <MenuItem value={'none'}>Select Usage Type</MenuItem>
              <MenuItem value={'user'}>User</MenuItem>
              <MenuItem value={'system'}>System</MenuItem>
            </Select>
          </div>
          <div className={classes.buttons}>
            <Button color='primary' disabled={!checkAllFilled()||updating} onClick={()=>onSave()} style={{marginRight:16}}>SAVE</Button>
            <Button color='secondary' onClick={()=>setModalOpen(false)}>CANCEL</Button>
          </div>
        </Paper>
        <div style={{margin:'0 auto',width:450}}>
          <ModalAlert
            open={alertOpen}
            setOpen={setAlertOpen}
            message={alertMessage}
            type='error'
          />
        </div>
      </div>
    </Modal>
  )
}


export default withStyles(styles)(TeamEdit);