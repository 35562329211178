
import cronstrue from 'cronstrue';
import { dayOfWeekAsString}  from '../UI/Scheduler/utils'

export const generateDefaultPayload = source => {
  let payload = {}
  payload.source_template_id = source.source_template.id;
  payload.args = source.args;
  payload.name = source.name;
  payload.job_mode = source.job_mode;
  payload.full_load = source.full_load;
  payload.active_flag = source.active_flag;
  payload.cron = source.cron;
  payload.enable_access_docs = source.enable_access_docs;
  payload.access_request_instructions = source.access_request_instructions;
  payload.access_request_url = source.access_request_url;
  payload.access_request_show_roles = source.access_request_show_roles;
  return payload;
}

export const generateScheduleText = source => {
  let cronArr = [];
  let cron = source.cron;
  let frequency = source.args.load_frequency;
  let cronText;
  if(!cron || Object.keys(cron).length===0) return 'N/A';
  if(frequency==='custom' || !['daily','weekly','monthly'].includes(frequency)){
    cronArr.push(cron.minute?cron.minute:'*');
    cronArr.push(cron.hour?cron.hour:'*');
    cronArr.push(cron.day_of_month?cron.day_of_month:'*');
    cronArr.push(cron.month_of_year?cron.month_of_year:'*');
    cronArr.push(cron.day_of_week?cron.day_of_week:'*');
    try{
      cronText = cronstrue.toString(cronArr.join(' '), { use24HourTimeFormat: true })+' (UTC)'
      let splitted = cronText.split('At ')
      if(splitted.length>1){
        cronText = splitted.slice(1).join('At ')
      }
    }catch(error){
      cronText = JSON.stringify(cron)
      // console.log(error)
    }
  }else{
    try{
      if(frequency==='daily')cronText = `Every day at ${cron.hour}:${cron.minute}`;
      if(frequency==='weekly')cronText = `Every ${dayOfWeekAsString(parseInt(cron.day_of_week))} at ${cron.hour}:${cron.minute}`;
      if(frequency==='monthly')cronText = `${cron.day_of_month==='1-7'?'First':'Last'} ${dayOfWeekAsString(parseInt(cron.day_of_week))} of the month at ${cron.hour}:${cron.minute}`;
      cronText+=' (UTC)'
    }catch(error){
      cronText = JSON.stringify(cron)
      // console.log(error)
    }
  }
  
  return cronText
}