import React, { useEffect } from 'react';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import VerticalTabBar from '../../../UI/VerticalTabBar/VerticalTabBar';
import UsageChart from './UsageChart';
import BySourceChart from './BySourceChart';

const styles = theme => ({
  title:{
    fontSize:16,
    color:theme.palette.primaryText.main
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  }
})

const DataSummaryBody = props => {

  const {
    // theme,
    state,
    classes,
    dispatch,
    variant,
    direction
  } = props;

  const data = state.summaryChartData[variant]
  const setData = d => dispatch({type:'set_summary_chart_data',summaryChartData:{...state.summaryChartData,[variant]:d}})

  useEffect(()=>{
    if(!data){
      setData({tabState:0})
    }
  // eslint-disable-next-line
  },[])

  if(!['data','content'].includes(variant))return <div></div>
  
  if(!data)return <div></div>

  let tabOptions = ['BY USAGE']

  if(state.multiHistoryObjects && !state.historyObject){
    tabOptions = ['BY '+(variant==='data'?'DATABASE':'TOOL'),'BY USAGE']
  }

  let title, subTitle, chartComponent;
  let varaintText = variant==='data'?'DATA ASSETS':'CONTENT ASSETS';
  let impactText = direction==='downstream'?'IMPACTED':'DEPENDENT';

  switch(tabOptions[data.tabState]){
    case 'BY DATABASE':
    case 'BY TOOL':
      title = `${direction.toUpperCase()} ${impactText} ${varaintText} BY ${variant==='data'?'DATABASE':'TOOL'}`;
      subTitle = `Count of ${direction} ${impactText.toLowerCase()} ${variant} assets by ${variant==='data'?'database':'tool'}`
      chartComponent = <BySourceChart state={state} data={data} setData={setData} variant={variant}/>
      break;
    // case 'BY TYPE':
    //   title = `${direction.toUpperCase()} ${impactText} ${varaintText} BY TYPE`;
    //   subTitle = `Count of ${direction} ${impactText.toLowerCase()} ${variant} assets by type from the target`
    //   chartComponent = <TypeChart state={state} data={data} setData={setData} variant={variant}/>
    //   break;
    case 'BY USAGE':
      title = `${direction.toUpperCase()} ${impactText} ${varaintText} BY USAGE`;
      subTitle = `Count of ${direction} ${impactText.toLowerCase()} ${variant} assets by usage`
      chartComponent = <UsageChart state={state} data={data} setData={setData} variant={variant}/>
      break;
    default:
  }


  return (
    <div style={{display:'flex',alignItems:'flex-start'}}>
      <VerticalTabBar
        tabOptions={tabOptions}
        tabState={data.tabState || 0}
        setTabState={value=>setData({...data, tabState:value})}
      />
      <div style={{flexGrow:1,marginLeft:80,overflow:'hidden'}}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.subTitle}>{subTitle}</Typography>
        {
          chartComponent
        }
      </div>
    </div>
    
  )
}

export default withTheme()(withStyles(styles)(DataSummaryBody));