import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Typography } from '@material-ui/core';
import ReactApexChart from 'react-apexcharts';
import { formatNumber } from '../../../utilities';

const styles = theme => ({
  root: {
    marginTop:12,
  },
  legendText:{
    fontSize:12,
    color:theme.palette.primaryText.main
  },
  legendBox:{
    width:12,
    height:12,
    marginRight:'8px',
    borderRadius:2
  },
  chartTitle:{
    fontSize:16,
    color:theme.palette.primary.main,
    marginBottom:8
  }
})


function Chart(props) {
  const {
    classes,
    theme,
    data,
  } = props;

  let chartRef = useRef()

  let labels = data[0].x
  
  let avg = data.find(el=>el.name==='AVG').y
  let count = data.find(el=>el.name==='COUNT').y


  let countSeries = [{
    name: 'Total tests run',
    data: count,
    type:'column',
    colour:'#A1C4FD',
  },]

  let avgSeries = [{
    name: 'Average score',
    data: avg,
    type: 'line',
    colour:'#00D46A',
  }]

  const countOption = {
    colors:countSeries.map(el=>el.colour),
    // fill:{
    //   type:['gradient','solid']
    // },
    chart: {
      toolbar:{
        show:false
      },
      zoom: {
        enabled: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: 'true',
      curve: 'smooth',
      width: 3
    },
    labels,
    xaxis: {
      type: 'datetime',
      labels:{
        style:{
          colors:theme.palette.primaryText.main
        }
      }
    },
    yaxis: [
      {
        labels: {
          formatter: function(val) {
            return formatNumber(val.toFixed(0),true)
          },
          style:{
            colors:theme.palette.primaryText.main
          }
        },
        // max:100,
        min:0,
        title:{
          show:false,
        },
        axisTicks:{
          show:true,
        },
        axisBorder: {
          show: true,
        },
      }
    ],
    legend: {
      show:false,
    },
    markers: {
      size: 3,
      style: 'full',
      hover:{
        size:3
      }
    },
  };


  const avgOption = {
    colors:avgSeries.map(el=>el.colour),
    // fill:{
    //   type:['gradient','solid']
    // },
    chart: {
      toolbar:{
        show:false
      },
      zoom: {
        enabled: false
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: 'true',
      curve: 'smooth',
      width: 3
    },
    labels,
    xaxis: {
      type: 'datetime',
      labels:{
        style:{
          colors:theme.palette.primaryText.main
        }
      }
    },
    yaxis: [
      {
        labels: {
          formatter: function(val) {
            return formatNumber(val.toFixed(0),true)
          },
          style:{
            colors:theme.palette.primaryText.main,
          }
        },
        max:100,
        title:{
          show:false,
        },
        axisTicks:{
          show:true,
        },
        axisBorder: {
          show: true,
        },
      }
    ],
    legend: {
      show:false,
    },
    markers: {
      size: 3,
      style: 'full',
      hover:{
        size:3
      }
    },
  };
  
  // const onToggleLegend = name => {
  //   if(!shownSeries.includes(name)){
  //     setShownSeries([...shownSeries,name])
  //   }else{
  //     setShownSeries(shownSeries.filter(el=>el!==name))
  //   }
  // }

  return (
    <div className={classes.root}>
      {/* <div style={{display:'flex'}}>
        <span className={classes.legendText} style={{marginRight:8}}>Legend: </span>
        <div style={{display:'flex',alignItems:'center',marginRight:8,cursor:'pointer',height:14}} onClick={()=>{onToggleLegend('Average score')}}>
          <div className={classes.legendBox} style={{background:shownSeries.includes('Average score')?'#00D46A':undefined,border:`1px solid #00D46A`}}></div>
          <span className={classes.legendText}>Average score</span>
        </div>
        <div style={{display:'flex',alignItems:'center',marginRight:8,cursor:'pointer',height:14}} onClick={()=>{onToggleLegend('Total tests run')}}>
          <div className={classes.legendBox} style={{background:shownSeries.includes('Total tests run')?'#A1C4FD':undefined,border:`1px solid #A1C4FD`}}></div>
          <span className={classes.legendText}>Count of tests run</span>
        </div>
      </div> */}
      <Typography className={classes.chartTitle}>Average overall DQ Score per day</Typography>
      <ReactApexChart ref={chartRef} options={avgOption} series={avgSeries} height='250' type="line" />
      <Typography className={classes.chartTitle} style={{marginTop:24}}>Count of DQ Tests per day</Typography>
      <ReactApexChart ref={chartRef} options={countOption} series={countSeries} height='250' type="bar" />
    </div>
  );
}

Chart.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(Chart));