import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, CircularProgress, Typography, withStyles, Checkbox, Button } from '@material-ui/core';
import { getIconComponent } from '../../../utilities';
import KTooltip from '../../UI/KTooltip/KTooltip';
import axiosCerebrum from '../../../axios-cerebrum';
import { updatePlatformSettings } from '../../../permissionChecker';
import { mapRoleName } from '../../KPlatformSetup/Utils/Utils';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  header:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  columnText:{
    display:'flex',
    marginLeft:8,
    fontSize:12,
    letterSpacing:2,
    color:theme.palette.primaryText.main
  },
  tooltip:{
    width:13.75,
    height:13.75,
    marginLeft:6
  },
  rolesRow:{
    height:48,
    display:'flex',
    alignItems:'center'
  },
  rolesText:{
    marginLeft:8,
    fontSize:13.75,
    color:theme.palette.primaryText.main
  },
  checkbox:{
    padding:0,
    marginLeft:32,
    color:theme.palette.primaryText.light
  }
})

function RolesEditor(props) {

  const {
    classes,
    theme,
    state,
    dispatch
  } = props;

  const [loading, setLoading] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  const validRoles = ['kada_business_user','kada_user','kada_data_gov_user','kada_data_manager','kada_admin']

  const checkSelected = (id, role) => {
    let setting = state.settingsData.find(s=>s.id===id);
    if(!setting)return false;
    return setting.value.split(',').includes(role.toLowerCase())
  }

  const onChangeValue = (id, role) => {
    let setting = state.settingsData.find(s=>s.id===id);
    if(!setting)return;
    let valuesArr = setting.value.split(',');
    if(valuesArr.includes(role))valuesArr = valuesArr.filter(el=>el!==role)
    else{valuesArr.push(role)}
    if(valuesArr.length===0){
      sendAlert({
        alertType:'error',
        message:'At least one role must be selected'
      })
      return;
    }
    dispatch({
      type:'set_settings_data',
      settingsData:state.settingsData.map(s=>{
        if(s.id!==id)return s;
        return {
          ...s,
          value:valuesArr.join(',')
        }
      })
    })
  }
  
  const onSave = () => {
    let changedItems = [];
    state.originalSettingsData.forEach(el=>{
      let newData = state.settingsData.find(s=>s.id===el.id);
      let oldArr = el.value.split(',')
      let newArr = newData.value.split(',')
      if(oldArr.length===newArr.length && oldArr.every(v=>newArr.includes(v)))return;
      changedItems.push(newData)
    })
    
    if(changedItems.length===0){
      dispatch({type:'set_editing',editing:false})
    }else{
      setLoading(true)
      let promises = changedItems.map(i=>
        axiosCerebrum
          .put(
            `/api/settings/${i.id}`, 
            {value:i.value.split(',').filter(r=>validRoles.includes(r)).join(',') }
          )
          .then(response=>{
            updatePlatformSettings(response.data)
            dispatch({
              type:'set_original_settings_data',
              originalSettingsData:state.originalSettingsData.map(s=>{
                if(s.id===response.data.id)return response.data;
                return s
              })
            })
          })
      )
      Promise 
        .all(promises)
        .then(response=>{
          setLoading(false)
          dispatch({type:'set_editing',editing:false})
          sendAlert({message:'Roles successfully updated',type:'info'})
        })
        .catch(error=>{
          setLoading(false)
          sendAlert({message:'Error occurred saving roles, please try again',type:'error'})
        })
    }
  }

  const onReset = () => {
    dispatch({
      type:'set_settings_data',
      settingsData:state.settingsData.map(s=>{
        return {
          ...s,
          value:s.default_value
        }
      })
    })
  }

  const onCancel = () => {
    dispatch({
      type:'set_settings_data',
      settingsData:state.originalSettingsData.map(el=>({...el}))
    })
    dispatch({type:'set_editing',editing:false})
  }
  
  return (
    <div className={classes.root}>  
      <div style={{display:'flex',alignItems:'center'}}>
        <Typography className={classes.header}>ROLE PERMISSIONS</Typography>
        <Button disabled={loading} color='primary' style={{marginLeft:16}} onClick={()=>{state.editing?onSave():dispatch({type:'set_editing',editing:!state.editing})}}>
          {state.editing?'SAVE':'EDIT'}
        </Button>
        {
          state.editing && 
          <>
            <Button disabled={loading} color='secondary' onClick={()=>{onReset()}}>
              RESET
            </Button>
            <Button color='secondary' style={{marginLeft:6}} onClick={()=>onCancel()}>
              CANCEL
            </Button>
          </>
        }
      </div>
      <Typography className={classes.subTitle}>Set the feature permissions for Data & Content profile pages for each role </Typography>
      {
        state.settingsLoading &&
        <div style={{display:'flex',justifyContent:'center'}}>
          <CircularProgress color='secondary'/>
        </div>
      }
      {
        state.settingsError && 
        <Typography style={{fontSize:13.75}}>Error occurred loading roles</Typography>
      }
      {
        state.settingsData &&
        <div style={{paddingLeft:24,maxWidth:950,boxSizing:'border-box'}}>
          <div style={{display:'flex',alignItems:'center',marginBottom:8}}>
            <div className={classes.columnText} style={{marginLeft:0,flex:'1 1'}}>
              ROLE
            </div>
            <div className={classes.columnText} style={{flex:'0 0 160px'}}>
              EDIT PROFILE
              <KTooltip title="Allows the user to edit the description, assign domains, classification, owners and stewards to data & content items. Terms / Collection instances edit permissions are managed per Glossary / Collection">
                <div className={classes.tooltip}>
                  {getIconComponent({label:'info',size:13.75,colour:theme.palette.primaryText.light})}
                </div>
              </KTooltip>
            </div>
            <div className={classes.columnText} style={{flex:'0 0 160px'}}>
              EDIT LINEAGE
              <KTooltip title="Allows the user to add and remove manual linkages in the item’s lineage map.">
                <div className={classes.tooltip}>
                  {getIconComponent({label:'info',size:13.75,colour:theme.palette.primaryText.light})}
                </div>
              </KTooltip>
            </div>
            <div className={classes.columnText} style={{flex:'0 0 160px'}}>
              LINK TERMS
              <KTooltip title="Allows the user to add and remove links to Glossary Terms and suggested Glossary Terms.">
                <div className={classes.tooltip}>
                  {getIconComponent({label:'info',size:13.75,colour:theme.palette.primaryText.light})}
                </div>
              </KTooltip>
            </div> 
            <div className={classes.columnText} style={{flex:'0 0 160px'}}>
              LINK COLLECTIONS
              <KTooltip title="Allows the user to add and remove links to Data Governance and Data Management collection.">
                <div className={classes.tooltip}>
                  {getIconComponent({label:'info',size:13.75,colour:theme.palette.primaryText.light})}
                </div>
              </KTooltip>
            </div>
          </div>

          {
            validRoles.map(r=>({
              value:r, name:mapRoleName(r)
            }))
            .map(el=>(
              <div className={classes.rolesRow}>
                <div className={classes.rolesText} style={{marginLeft:0,flex:'1 1'}}>
                  {el.name}
                </div>
                <div className={classes.rolesText} style={{flex:'0 0 160px'}}>
                  <Checkbox disabled={!state.editing} checked={checkSelected(6000, el.value)} onChange={()=>{onChangeValue(6000, el.value)}} className={classes.checkbox} color='primary'/>
                </div>
                <div className={classes.rolesText} style={{flex:'0 0 160px'}}>
                  <Checkbox disabled={!state.editing} checked={checkSelected(6030, el.value)} onChange={()=>{onChangeValue(6030, el.value)}} className={classes.checkbox} color='primary' />
                </div>
                <div className={classes.rolesText} style={{flex:'0 0 160px'}}>
                  <Checkbox disabled={!state.editing} checked={checkSelected(6010, el.value)} onChange={()=>{onChangeValue(6010, el.value)}} className={classes.checkbox} color='primary' />
                </div>
                <div className={classes.rolesText} style={{flex:'0 0 160px'}}>
                  <Checkbox disabled={!state.editing} checked={checkSelected(6020, el.value)} onChange={()=>{onChangeValue(6020, el.value)}} className={classes.checkbox} color='primary' />
                </div>
              </div>
            ))
          }

        </div>
      }
    </div>
  )
}

RolesEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(RolesEditor));