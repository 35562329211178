import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Button} from '@material-ui/core';
import ResultItem from '../SearchResults/ResultItem2';
import { onClickResultItem,getDispFields} from '../../../utilities'
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  normalText:{
    color:theme.palette.primaryText.main
  },
  topMessage:{
    marginBottom: 20,
    color:theme.palette.primaryText.main
  },
})

function SolrList(props) {

  const {
    classes,
    list,
    history,
    topMessage,
    hideTopMessage,
    action,
    hideTrust,
    enableEdit,
    onDescriptionUpdated
  } = props;
  
  return (
    <div className={classes.root}>
      {
        (!hideTopMessage && (list && list.length!==0)) && topMessage && 
        <Typography className={classes.topMessage}>{topMessage}</Typography>
      }
      {
        list && list.length===0 && !hideTopMessage &&
        <Typography className={classes.normalText}>No item to be displayed.</Typography>
      }
      {list && list.map(item => {
        if(!item)return <div></div>;
        return (
          <ResultItem
            item={item.item || item}
            key={item.id}
            dispBody={getDispFields(item,'dispBody')}
            dispSubtitle={getDispFields(item,'dispSubtitle')}
            dispTitle={getDispFields(item,'dispTitle')}
            label={item.labels || item.object_type_txt}
            showIcon
            enableEdit={enableEdit}
            onDescriptionUpdated={onDescriptionUpdated}
            hideTrust={hideTrust}
            showUnderline
            updated={item.lastUsed || item.last_used_srt}
            onClick={() => onClickResultItem({
              item,
              id:item.id,
              label: item.labels || item.object_type_txt,
              history: history
            })}
            tailObject={
              action?
              <div style={{display:'flex'}}>
                {
                  action.map(a=>(
                    <Button color='secondary' variant='outlined' style={{marginLeft:8,minWidth:120,padding:'0 8px'}} onClick={(event)=>{event.stopPropagation(); a.action(item)}}>
                      {a.name}
                    </Button>
                  ))
                }
              </div>
              :
              undefined
            }
          />
        )
      })}
    </div>
  )
}

SolrList.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  list: PropTypes.arrayOf(PropTypes.object),
  topMessage: PropTypes.string,
  isDataEmpty: PropTypes.bool,
  hideTopMessage:PropTypes.bool,
  enableEdit:PropTypes.bool,
  onDescriptionUpdated:PropTypes.func
}

export default withRouter(withStyles(styles)(SolrList));