import React, { useEffect, useReducer } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/Settings/Body/Body';
import useGetCerebrum from '../../hooks/useGetCerebrum';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import { collectionIds } from '../../utilities'


const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: 8,
    height: '3rem',
    right: '-12rem'
  },
  underlineOnHover: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },

  version:{
    position:'sticky',
    bottom:10,
    left:50
  },
  title: {
      height: '53.03px',
      width: '477px',
      color: theme.palette.primary.main,
      
      fontSize: '33.38px',
      letterSpacing: '0.25px',
      lineHeight: '44px'
  },
  titleBelowLine: {
      height: '24px',
      width: '640px',
      color: theme.palette.primaryText.light,
      
      fontSize: '13.75px',
      fontWeight: 500,
      letterSpacing: '0.1px',
      lineHeight: '24px',
  },
});

const initialState = {
  tabState: 0,
  //selectedDate: null,
  team: null,
  originalAlias: [],
  selectedSource:'all'
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    // case 'set_sources':
    //   return {
    //     ...state,
    //     sourcesData:action.sourcesData
    //   }
    case 'set_original_alias':
      return {
        ...state,
        originalAlias:action.originalAlias
      }
    // case 'set_selected_source':
    //   return {
    //     ...state,
    //     selectedSource:action.selectedSource
    //   }
    case 'set_roles_data':
      return {
        ...state,
        rolesData:action.rolesData
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

const tabOptions = ['ABOUT ME', 'MY ACCOUNTS'];


const Settings = props => {

  const {
    history,
    classes,
    sessionData
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    data:rolesData,
  } = useGetCerebrum({
    url:`/api/users/${sessionData.id}/related/collections`,
    params:{
      category:'PLATFORM',
      parent_id:collectionIds.dataRole,
      page:1,
      sort:'END_DESC',
      per_page:20,
    }
  })

  useEffect(()=>{
    if(rolesData)dispatch({type:'set_roles_data',rolesData:rolesData.items.map(el=>el.name)})
  },[rolesData])



  // const {
  //   data:sourceData,
  // } = useGetCerebrum({
  //   url:`/api/sources`,
  //   params:{
  //     per_page:100,
  //   }
  // })

  // useEffect(()=>{
  //   if(sourceData){
  //     dispatch({type:'set_sources',sourcesData:sourceData.items})
  //   }
  // },[sourceData])
  
  const {
    data:aliasData,
    loading:aliasLoading,
    error:aliasError,
    fetchList:aliasFetch
  } = useGetCerebrum({
    url:`/api/merges`,
    params:{
      type:'TARGET',
      object_type:'USER',
      object_name:sessionData.name,
      source_id:sessionData.source_id,
      per_page:100
    }
  })

  useEffect(()=>{
    dispatch({type:'set_original_alias',originalAlias:aliasData?aliasData.items.map(el=>el.candidate):[],aliasLoading,aliasError})
  },[aliasData,aliasLoading,aliasError])

  // trigger api call when tab state is changed, if data is already fetched, do nothing
  useEffect(() => {
    switch (state.tabState) {
      default:
        break;
    }
  }, [state.tabState])


  return (
    <div>

      <ProfileLayout
        header={(
          <ProfileHeader
            label='setting'
            title='Profile Settings'
            subtitle=' '
            // buttons={buttons}
            description="Manage your settings and link your identifiers across the data ecosystem"
          />
        )}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            disableUnderline={true}
            minWidth={200}
            maxWidth={200}
          />
        }
        body={
          <div className={classes.bodyProfile}>
            <Body
              history={history}
              tabState={state.tabState}
              state={state}
              dispatch={dispatch}
              sessionData={sessionData}
              aliasFetch={aliasFetch}
            />
          </div>
        }
      />
    </div>)
}

export default withStyles(styles)(Settings);
