import React, { useEffect, useState, useRef } from 'react';
import { withTheme, withStyles, Typography, CircularProgress, IconButton, InputBase, Select, MenuItem, Modal, Paper, Button, LinearProgress} from '@material-ui/core';
import { getIconComponent,  isInViewport } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum'
import AddMergModal from './AddMergeModal';
import ModalAlert from '../../UI/ModalAlert/ModalAlert';
import axiosSolr from '../../../axios-solr'
import KTooltip from '../../UI/KTooltip/KTooltip';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  header:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.light
  },
  inputBase:{
    ...theme.components.inputBase,
    width:160,
    height:39
  },
  selector:{
    ...theme.components.selector,
    width:180,
    flexShrink:0,
    marginLeft:16,
  },
  columnHeader:{
    marginLeft:16,
    fontSize:12,
    letetrSpacing:2
  },
  bodyText:{
    marginLeft:16,
    overflow:'hidden'
  },
  itemTitleText:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  itemTitleSubtext:{
    fontSize:12,
    marginTop:2,
    color:theme.palette.primaryText.light,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap'
  },
  modalRoot:{
    padding:24,
    // paddingBottom:8,
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    margin:'20vh auto 0',
    maxHeight:'70vh',
    overflow:'auto'
  },  
  modalTitle:{
    wordBreak:'break-word',
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:12
  },
  modalBodyText:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    whiteSpace:'pre-wrap'
  },
  buttons:{
    marginTop:24,
    marginRight:-12,
    marginBottom:-16,
    display:'flex',
    justifyContent:'flex-end'
  },
})

const Merge = props => {

  const {
    classes,
    theme,
    state,
    dispatch,
    history
  } = props;
  

  const [data, setData] = useState()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [targetDetail, setTargetDetail] = useState({})

  const [mappedCount, setMappedCount] = useState()

  const scrollRef = useRef()

  const searchTimeout = useRef()
  const [searchValue, setSearchValue] = useState('')

  const [mappedFilter, setMappedFilter] = useState('all')
  const [mergeModalOpen, setMergeModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  const loadMappedCount = ({ searchStr }) => {
    axiosCerebrum
      .get(
        `/api/merges/databases`,{
          params:{
            source_id:state.selectedSource.source_id,
            mapped_flag: true,
            per_page:0,
            object_name:searchStr
          }
        }
      )
      .then(response=>{
        setMappedCount(response.data.total)
      })
      .catch(error=>{
        console.log(error)
        setMappedCount()
      })
  }

  const loadSources = ({page = 1, mappedFlag = mappedFilter, searchStr = searchValue }) => {
    setLoading(true)
    setError(false)
    if(page===1)setData();
    axiosCerebrum
      .get(
        `/api/merges/databases`,{
          params:{
            source_id:state.selectedSource.source_id,
            mapped_flag: mappedFlag==='all'?undefined:mappedFlag,
            page, 
            per_page:20,
            object_name:searchStr
          }
        }
      )
      .then(response=>{
        if(page===1 || isNaN(mappedCount))loadMappedCount({searchStr});
        setLoading(false)
        setData({
          ...response.data,
          items:page!==1?[...data.items,...response.data.items]:response.data.items
        })

        let targetIDs = response.data.items.filter(el=>el.target).map(el=>el.target.id)
        if(targetIDs.length===0)return;
        axiosSolr
          .get(
            `/solr/search/select`,{
              params:{
                q:"*",
                fq:`id:(${targetIDs.join(' OR ')})`,
                rows:targetIDs.length,
                fl:'*'
              }
            }
          )
          .then(detailRes=>{
            let obj = {}
            detailRes.data.response.docs.forEach(el=>{
              obj[el.id] = el;
            })
            setTargetDetail({...targetDetail,...obj})
          })
      })
      .catch(error=>{
        setLoading(false)
        setError(true)
        console.log(error)
      })
  }

  window.onpopstate = () => {
    dispatch({type:'set_tab_state',tabState:0})
  }

  
  useEffect(()=>{
    if(!data)loadSources({})

    history.push(`/admin/sources`)
    return () => {
      window.onscroll = undefined;
      window.onpopstate = undefined;
    }
  // eslint-disable-next-line
  },[])

  const onSearchChange = str => {
    setSearchValue(str)
    clearTimeout(searchTimeout.current)
    searchTimeout.current = setTimeout(()=>{
      loadSources({searchStr:str})
    },250)
  }

  const onMappedFilterChange = value => {
    setMappedFilter(value)
    loadSources({mappedFlag:value})
  }

  const shouldLoadMore = () => {
    return data && !loading && data.page<data.pages && isInViewport(scrollRef)
  }

  window.onscroll = () => {
    if(shouldLoadMore())loadSources({page:data.page+1})
  }

  useEffect(()=>{
    if(shouldLoadMore())loadSources({page:data.page+1})
  // eslint-disable-next-line
  },[data])

  const onEdit = el => {
    setMergeModalOpen({candidate:el.candidate})
  }

  const onDelete = merge => {
    setDeleting(true)
    axiosCerebrum
      .post(
        `/api/merges`,{
          "target": merge.target.id,
          "object_type": merge.target.object.name,
          "candidates": [merge.candidate.id],
          "action":'CLEAR'
        }
      )
      .then(response=>{
        setDeleting(false)
        loadSources({page:1})
        setDeleteModalOpen(false)
        sendAlert({message:'Item successfully deleted',type:'info'})
      })
      .catch(error=>{
        setDeleting(false)
        console.log(error)
        let msg = 'Error occurred deleting the item';
        setAlertMessage(msg)
        setAlertOpen(true)
      })
  }

  return (
    <div className={classes.root}>
      <div style={{display:'flex',marginBottom:24,alignItems:'flex-start'}}>
        <div style={{flexGrow:1,marginRight:16}}>
          <Typography className={classes.header}>
            {data?data.total:0} SOURCE(S) NOT LINKED BY K{(isNaN(mappedCount) || !data)?'':`. ${data.total-mappedCount} SOURCES PENDING MAPPING`}
          </Typography>
          <Typography className={classes.subTitle}>
            Select an unlinked source and map it to a source in K. An unlinked sources may not map if the source is not loaded into K. 
          </Typography>
        </div>
        <InputBase
          value={searchValue}
          onChange={event => onSearchChange(event.target.value)}
          variant={'filled'}
          placeholder={'Search'}
          className={classes.inputBase}
          endAdornment={
            <IconButton 
              disabled={searchValue===''} 
              onClick={()=>onSearchChange('')}
              style={{width:32,height:32,marginRight:6}}
            >
              {getIconComponent({label:searchValue===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
            </IconButton>
          }
        />

        <Select
          className={classes.selector}
          value={mappedFilter}
          onChange={event=>onMappedFilterChange(event.target.value)}
          disableUnderline
        >
          <MenuItem value='all'>All</MenuItem>
          <MenuItem value={true}>Mapped</MenuItem>
          <MenuItem value={false}>Unmapped</MenuItem>
        </Select>
      </div>
      {
        data && data.total!==0 && 
        <div style={{paddingLeft:16}}>
          <div style={{display:'flex',marginBottom:8}}>
            <Typography style={{flex:'1 1 300px',marginLeft:0}} className={classes.columnHeader}>
              SOURCES IN {state.selectedSource.name.toUpperCase()}
            </Typography>
            <Typography style={{flex:'1 1 300px'}} className={classes.columnHeader}>
              MAPPED TO SOURCE IN K
            </Typography>
            <Typography style={{flex:'0 0 80px'}} className={classes.columnHeader}>
              ACTIONS
            </Typography>
          </div>
          {
            data.items.map(el=>(
              <div style={{display:'flex',alignItems:'center',height:64,borderBottom:`1px solid ${theme.palette.listItemDivider.main}`}}>
                <div style={{flex:'1 1 300px',marginLeft:0}} className={classes.bodyText}>
                  <KTooltip title={el.candidate.name}>
                    <Typography className={classes.itemTitleText}>{el.candidate.name}</Typography>
                  </KTooltip>
                  <KTooltip title={el.candidate.location}>
                    <Typography className={classes.itemTitleSubtext}>{el.candidate.location}</Typography>
                  </KTooltip>
                </div>
                <div style={{flex:'1 1 300px'}} className={classes.bodyText}>
                  {
                    el.target && 
                    <KTooltip title={el.target.name}>
                      <Typography className={classes.itemTitleText}>{el.target.name}</Typography>
                    </KTooltip>
                  }
                  {
                    el.target && 
                    <KTooltip title={el.target.location}>
                      <Typography className={classes.itemTitleSubtext}>{el.target.location}</Typography>
                    </KTooltip>
                  }
                </div>
                <div style={{flex:'0 0 80px'}} className={classes.bodyText}>
                  <KTooltip title={el.target?'Remove this mapping':'Map this Source'}>
                    <IconButton style={{padding:8}} onClick={()=>el.target?setDeleteModalOpen(el):onEdit(el)}>
                      {getIconComponent({label:el.target?'clear':'merge',size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  </KTooltip>
                </div>
              </div>
            ))
          }
        </div>
      }
      {
        data && data.total===0 && 
        <Typography >No source found</Typography>
      }
      {
        error && 
        <Typography >Error occrured loading sources</Typography>
      }
      <div ref={scrollRef} style={{textAlign:'center'}}>
        {
          loading && <CircularProgress color='secondary'/>
        }
      </div>
      {
        mergeModalOpen && 
        <AddMergModal history={history} state={state} dispatch={dispatch} modalOpen={mergeModalOpen} setModalOpen={setMergeModalOpen} onFinish={()=>loadSources({})}/>
      }
      <Modal open={deleteModalOpen} disableBackdropClick>
        <div style={{width:'max-content',margin:'auto',outline:'none'}}>
          {
            deleteModalOpen && 
            <Paper className={classes.modalRoot} style={{width:700}}>
              <Typography className={classes.modalTitle}>Are you sure?</Typography>
              {
                deleting?
                <div style={{textAlign:'center'}}>
                  <LinearProgress color='secondary' style={{height:6,width:'80%',margin:'auto',marginTop:32}}/>
                  <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginTop:16}}>Deletion in progress</Typography>
                </div>
                :
                <Typography className={classes.modalBodyText}>
                  {
                    `Removing the link between ${deleteModalOpen.target.name} (${deleteModalOpen.target.source.name}) and ${deleteModalOpen.candidate.name} (${deleteModalOpen.candidate.source.name}) will split the 2 items going forward.\n\n`+
                    `Previously merged metrics will ` 
                  }
                  <span style={{fontSize:13.75,fontWeight:700}}>NOT</span> {`be repaired. `}
                </Typography>
              }
              <div className={classes.buttons}>
                <Button color='primary' onClick={()=>onDelete(deleteModalOpen)} style={{marginRight:16}}>REMOVE</Button>
                <Button color='secondary' onClick={()=>setDeleteModalOpen(false)}>CLOSE</Button>
              </div>
            </Paper>
          }
          <div style={{margin:'0 auto',width:750}}>
            <ModalAlert
              open={alertOpen}
              setOpen={setAlertOpen}
              message={alertMessage}
              type='error'
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default withTheme()(withStyles(styles)(Merge));