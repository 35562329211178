import React from 'react';
import { withTheme, withStyles, Typography,ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDown';
import ViewOnlyTile from './Tiles/ViewOnlyTile';
import AliasManager from './Tiles/AliasManager';
import TagManager from './Tiles/TagManager';
import ClassificationManager from './Tiles/ClassificationManager';
import DomainManager from './Tiles/DomainManager';
import VerifiedManager from './Tiles/VerifiedManager';
import OwnerManager from './Tiles/OwnerManager';
import StewardManager from './Tiles/StewardManager';
import LineageTile from './Tiles/LineageTile';
import KnowledgeTile from './Tiles/KnowledgeTile';
import ChannelManager from './Tiles/ChannelManager';
import JoinsTile from './Tiles/JoinsTile';
import TopUserTile from './Tiles/TopUsersTile';
import ManagedByTile from './Tiles/ManagedByTile';
import DescriptionTile from './Tiles/DescriptionTile';
import DQDimensionManager from './Tiles/DQDimensionManager';
import InstanceParentTile from './Tiles/InstanceParentTile';
import DescriptionManager from './Tiles/DescriptionManager';
import PIIDetManager from './Tiles/PIIDetManager';
import DefinitionTile from './Tiles/DefinitionTile';
import { copyToClipboard, getIconComponent } from '../../../utilities';
import sqlFormatter from "sql-formatter";
import AnswerManager from './Tiles/AnswerManager';
import LinkManager from './Tiles/LinkManager';
import useAlert from '../../../hooks/useAlert';
import IssueTypeManager from './Tiles/IssueTypeManager';
import IssueSeverityManager from './Tiles/IssueSeverityManager';
import IssueStatusManager from './Tiles/IssueStatusManager';
import AssignedToManager from './Tiles/AssignedToManager';
import CategoryManager from './Tiles/CategoryManager';
import UpDownStreamSources from './Tiles/UpDownStreamSource';


const styles = theme => ({
  expansionTitle:{
    fontSize:16,
    paddingLeft:16,
    color:theme.palette.header.main
  },
  expansionPanel:{
    marginBottom:24,
    paddingBottom:0,
    borderRadius:`6px !important`,
    border:`1px solid ${theme.palette.listItemDivider.main}`,
    '&::before': {
      background: 'none'
    },
  },
  expansionSummary:{
    padding:0,
    width:'100%',
    // minHeight: 64,
  },
  expanded:{
    margin:0
  },
  summaryRoot:{
    // background:theme.palette.background.main,
    borderBottom: '0',
    // height: 64,
    padding: 0,
    borderRadius:6,
    margin: 0,
    '&$expanded': {
      // height:64,
      padding: 0,
    },
  },
  expansionPanelDetails:{
    flexDirection:'column',
    background:theme.palette.background.main,
    paddingLeft:16,
    paddingRight:16,
    paddingTop:0,
    paddingBottom:24,
    borderRadius:12
  },
})

function TileSection(props) {

  const {
    classes,
    theme,
    tiles,
    history,
    mapping,
    onAddTileData,
    object,
    isEditable,
    ignoreGrouping,
    alwaysOpenNewTab,
    defaultExpanded
  } = props;
  
  const groupMap = {
    'Link':[
      'link_wide'
    ],
    'Description':[
      'description_wide'
    ],
    'Answer':[
      'answer_wide'
    ],
    'Definition':[
      'definition'
    ],
    'Code':[
      'code'
    ],
    'Details':[
      'issueType','issueSeverity','description','contact','instanceParent','parent','team','collectionType','source','sourceType_no_location','sourceType','dataType','trustscore','category','classification','dq_dimension','domain','verified','verifiedNot','owner','stewardedBy','managedBy','assignedTo','createdBy','createdOn','lastUpdated','lastUpdatedBy',
    ],
    'Lineage details':[
      'upstreamSources','downstreamSources','lineage'
    ],
    'Labels':[
      'masked','alias','tags','piiDet'
    ],
    'Knowledge':[
      'channels','knowledge','joins',
    ],
    'Usage':[
      'lastUsed','lastRun','runs','topUsers','jobTypes','topcontent','topdata','topTeams','topTools','topSources'
    ],
    'Metrics':[
      'consumer_m','producer_m','owner_m','steward_m'
    ]
  }

  const customTiles = [
    'issueType','issueSeverity','issueStatus','description_wide','link_wide','answer_wide','definition','code','description','instanceParent', 'dq_dimension','category','domain','verified','verifiedNot','classification','owner','stewardedBy','lineage','upstreamSources','downstreamSources','managedBy','alias','tags','piiDet', 'channels','knowledge','joins','topUsers','assignedTo'
  ]

  const onExpandChange = (expanded, key) => {
    if(expanded){
      localStorage.removeItem(`side_panel_${key}_collapsed`)
    }else{
      localStorage.setItem(`side_panel_${key}_collapsed`,true)
    }
  }

  const {
    sendAlert
  } = useAlert({})


  const generateTile = id => {
    if(customTiles.includes(id)){
      let tileData = tiles.find(b=>b.id===id)
      switch(id){
        case "alias":
          return (
            <AliasManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`alias_manager_${object.id}`}
            />
          )
        case "tags":
          return (
            <TagManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`tag_manager_${object.id}`}
            />
          )
        case 'piiDet':
          return (
            <PIIDetManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              isEditable={isEditable}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`pii_det_manager_${object.id}`}
            />
          )
        case "classification":
          return(
            <ClassificationManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              isEditable={isEditable}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`classification_manager_${object.id}`}
            />
          )
        case 'category':
          return (
            <CategoryManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              isEditable={isEditable}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`category_manager_${object.id}`}
            />
          )
        case 'domain':
          return (
            <DomainManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              isEditable={isEditable}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`domain_manager_${object.id}`}
            />
          )
        case 'verified':
          return (
            <VerifiedManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              isEditable={isEditable}
              alwaysOpenNewTab={alwaysOpenNewTab}
              oppositeTileData={tiles.find(b=>b.id==='verifiedNot')}
              key={`verified_manager_${object.id}`}
            />
          )
        case 'verifiedNot':
          return (
            <VerifiedManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              isEditable={isEditable}
              alwaysOpenNewTab={alwaysOpenNewTab}
              oppositeTileData={tiles.find(b=>b.id==='verified')}
              key={`verified_not_manager_${object.id}`}
              isNotVerified={true}
            />
          )
        case 'owner':
          return (
            <OwnerManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              isEditable={isEditable}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`owner_manager_${object.id}`}
            />
          )
        case 'stewardedBy':
          return (
            <StewardManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              isEditable={isEditable}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`steward_manager_${object.id}`}
            />
          )
        case 'assignedTo':
          return (
            <AssignedToManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              isEditable={isEditable}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`assigned_to_manager_${object.id}`}
            />
          )
        case 'lineage':
          return (
            <LineageTile
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`lineage_tile_${object.id}`}
            />
          )
        case 'knowledge':
          return (
            <KnowledgeTile
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`knowledge_tile_${object.id}`}
            />
          )
        case 'channels':
          return (
            <ChannelManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              isEditable={isEditable}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`channel_manager_${object.id}`}
            />
          )
        case 'joins':
          return (
            <JoinsTile
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`joins_tile_${object.id}`}
            />
          )
        case 'topUsers':
          return (
            <TopUserTile
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`top_user_${object.id}`}
            />
          )
        case 'managedBy':
          return (
            <ManagedByTile
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`managed_by_${object.id}`}
            />
          )
        case 'description_wide':
          return (
            <DescriptionManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              isEditable={isEditable}
              object={object}
              alwaysOpenNewTab={alwaysOpenNewTab}
              id={id}
            />
          )
        case 'answer_wide':
          return (
            <AnswerManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              isEditable={isEditable}
              object={object}
              alwaysOpenNewTab={alwaysOpenNewTab}
              id={id}
            />
          )
        case 'link_wide':
          return (
            <LinkManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              id={id}
            />
          )
        case 'definition':
        case 'code':
          if(object.code && object.code.trim()!==''){
            return (
              <DefinitionTile
                onAddTileData={onAddTileData}
                tileData={tileData}
                history={history}
                object={object}
                alwaysOpenNewTab={alwaysOpenNewTab}
                id={id}
              />
            )
          }
          break;
        case 'description':
          return (
            <DescriptionTile
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              alwaysOpenNewTab={alwaysOpenNewTab}
              id={id}
              key={`${id}_${object.id}`}
            />
          )
        case 'dq_dimension':
          return (
            <DQDimensionManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              isEditable={isEditable}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`dq_dimension_${object.id}`}
            />
          )
        case 'instanceParent':
          return (
            <InstanceParentTile 
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              isEditable={isEditable}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`instance_parent_${object.id}`}
            />
          )
        case 'issueType':
          return (
            <IssueTypeManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              isEditable={isEditable}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`issue_type_${object.id}`}
            />
          )
        case 'issueSeverity':
          return (
            <IssueSeverityManager
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              isEditable={isEditable}
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`issue_severity_${object.id}`}
            />
          )
        case 'upstreamSources':
          return (
            <UpDownStreamSources
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              direction='upstream'
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`upstream_sources_${object.id}`}
            />
          )
        case 'downstreamSources':
          return (
            <UpDownStreamSources
              onAddTileData={onAddTileData}
              tileData={tileData}
              history={history}
              object={object}
              direction='downstream'
              alwaysOpenNewTab={alwaysOpenNewTab}
              key={`downstream_sources_${object.id}`}
            />
          )
        default:
          return <></>
      }
    }else{
      return <ViewOnlyTile key={id} history={history} alwaysOpenNewTab={alwaysOpenNewTab} boxInfo={tiles.find(t=>t.id===id)} />
    }
  }

  if(ignoreGrouping){
    return (
      <>
        {
          tiles.map(t=>generateTile(t.id))
        }
      </>
    )
  }
  
  return (
    <>
      {
        mapping.includes('issueStatus') && 
        <IssueStatusManager
          onAddTileData={onAddTileData}
          tileData={tiles.find(b=>b.id==='issueStatus')}
          history={history}
          object={object}
          isEditable={isEditable}
          alwaysOpenNewTab={alwaysOpenNewTab}
          key={`issue_status_${object.id}`}
        />
      }
      {Object
        .keys(groupMap)
        .filter(key=>
          (
            tiles && 
            tiles.find(t=>groupMap[key].includes(t.id))
          ) || 
          (
            groupMap[key].some(id=>(mapping.includes(id) && customTiles.includes(id)))  
          )
        )
        .filter(key=>
          !['Definition','Code'].includes(key) ||
          (object.code && object.code.trim()!=='')
        )
        .map(key=>(
          <ExpansionPanel defaultExpanded={typeof(defaultExpanded)==='boolean'?defaultExpanded:(localStorage.hasOwnProperty(`side_panel_${key}_collapsed`)?false:true)} onChange={(event,expanded)=>onExpandChange(expanded,key)} elevation={0} className={classes.expansionPanel } >
            <ExpansionPanelSummary onClick={undefined} classes={{expanded: classes.expanded,root:classes.summaryRoot}}  expandIcon={<ExpandMoreIcon style={{color:theme.palette.primaryText.main}} />}  className={classes.expansionSummary}>
              <Typography className={classes.expansionTitle}>{key}</Typography>
              {
                ['Definition','Code'].includes(key) && object.code &&
                <IconButton 
                  onClick={(event)=>{
                    event.stopPropagation()
                    copyToClipboard(sqlFormatter.format(object.code,{language:'pl/sql'}))
                    sendAlert({ message: 'Copied code to clipboard', type: 'info' })
                  }}
                  style={{marginLeft:8,width:22,height:22,padding:0}}
                >
                  {getIconComponent({label:'copy',size:18,colour:theme.palette.primaryText.light})}
                </IconButton>
              }
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              {
                groupMap[key].filter(id=>tiles.find(b=>b.id===id) || (mapping.includes(id) && customTiles.includes(id))).map(id=>generateTile(id))
              }
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))
      }
    </>
  )

}

TileSection.propTypes = {
  classes: PropTypes.object.isRequired,
  tiles: PropTypes.array.isRequired,
  mapping: PropTypes.array.isRequired,
  onAddTileData: PropTypes.func.isRequired,
  object:PropTypes.object.isRequired,
  isEditable:PropTypes.bool,
  ignoreGrouping:PropTypes.bool,
  alwaysOpenNewTab:PropTypes.bool,
  defaultExpanded:PropTypes.bool,
}

export default withTheme()(withStyles(styles)(TileSection));