import AccuracyIcon from '@material-ui/icons/Adjust'
import { getIconComponent } from '../../../utilities'
import { Fingerprint } from '@material-ui/icons'

export const getDimensionIcon = ({size, colour, dimension}) => {
  const style = { width: size, height: size, color: colour, fontSize: size }
  if(dimension==='ALL'){
    return getIconComponent({label:'data_quality_test',size,colour})
  }
  if(dimension==='ACCURACY'){
    return <AccuracyIcon style={style}/>
  }
  if(dimension==='COMPLETENESS'){
    return (
      <svg height={size} viewBox="0 -960 960 960" width={size}>
        <path fill={colour} d="M657-121 544-234l56-56 57 57 127-127 56 56-183 183Zm-537 1v-80h360v80H120Zm0-160v-80h360v80H120Zm0-160v-80h720v80H120Zm0-160v-80h720v80H120Zm0-160v-80h720v80H120Z"/>
      </svg>
    )
  }
  if(dimension==='CONSISTENCY'){
    return (
      <svg  height={size} viewBox="0 -960 960 960" width={size}><path fill={colour} d="m576-160-56-56 104-104-104-104 56-56 104 104 104-104 56 56-104 104 104 104-56 56-104-104-104 104Zm79-360L513-662l56-56 85 85 170-170 56 57-225 226ZM80-280v-80h360v80H80Zm0-320v-80h360v80H80Z"/></svg>
    )
  }
  if(dimension==='INTEGRITY'){
    return (
      <svg height={size} viewBox="0 -960 960 960" width={size}><path fill={colour} d="m360-160-56-56 70-72q-128-17-211-70T80-480q0-83 115.5-141.5T480-680q169 0 284.5 58.5T880-480q0 62-66.5 111T640-296v-82q77-20 118.5-49.5T800-480q0-32-85.5-76T480-600q-149 0-234.5 44T160-480q0 24 51 57.5T356-372l-52-52 56-56 160 160-160 160Z"/></svg>
    )
  }
  if(dimension==='TIMELINESS'){
    return getIconComponent({label:'time',size,colour})
  }
  if(dimension==='UNIQUENESS'){
    return <Fingerprint style={style}/>
  }
  if(dimension==='UNCATEGORISED'){
    return getIconComponent({size,colour})
  }
  return getIconComponent({size,colour,label:'data_quality_test'})
}