import React, { useEffect, useState } from 'react';
import { withTheme, withStyles, Typography, Modal, Paper, Button, CircularProgress, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getIconComponent, mapObjectName, toTitleCase, getLabelPlural, copyToClipboard } from '../../../../utilities';
import { hasAccessInfo, noAccessInfo } from '../utils';
import { withRouter } from 'react-router-dom'
import KTooltip from '../../KTooltip/KTooltip';
import InteractiveViewer from '../../InteractiveInput/InteractiveViewer';
import axiosCerebrum from '../../../../axios-cerebrum';
import useAlert from '../../../../hooks/useAlert';

const styles = theme => ({
  text: {
    fontSize: 13.75,
    color: theme.palette.primaryText.main
  },
  clickableText: {
    // cursor: 'pointer',
    textDecoration: 'underline'
  },
  modalRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalPaper: {
    width: 640,
    maxWidth: '90vw',
    height: 'max-content',
    minHeight:300,
    maxHeight: '90vh',
    boxSizing: 'border-box',
    padding: '16px 0 40px 32px',
    background: theme.palette.background.main,
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    fontSize: 20,
    color: theme.palette.header.main,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  modalBody: {
    flexGrow: 1,
    paddingRight: 24,
    overflow: 'auto',
    ...theme.components.customScroll
  },
  sectionHeader: {
    fontSize: 12,
    letterSpacing: 1.5,
    color: theme.palette.primary.main,
    marginBottom: 8,
    marginTop: 40
  },
  bodyText: {
    fontSize: 13.75,
    color: theme.palette.primaryText.main,
    whiteSpace: 'pre-wrap'
  },
  requestLink:{
    fontSize:13.75,
    color:theme.palette.hyperLink.main,
    textDecoration:'underline',
    cursor:'pointer',
    width:"max-content"
  },
  roleChip:{
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    height:39,
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`
  },
  roleChipText:{
    fontSize:16,
    color:theme.palette.primaryText.main,
    marginRight:16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexGrow:1
  }
})

function AccessChip(props) {

  const {
    theme,
    // history,
    classes,
    infoBox,
    data
  } = props;

  const [modalOpen, setModalOpen] = useState(false)
  const [roles, setRoles] = useState({})
  const [hovered, setHovered] = useState(false)
  const source = data.source_data;

  const {
    sendAlert
  } = useAlert({})


  const loadRoles = ({page=1}) => {
    if(page===1){
      setRoles({loading:true})
    }else{
      setRoles({...roles,loading:true,error:false})
    }
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(data.object.name)}/${data.id}/related/collections`,
        {params:{
          category:'PLATFORM',
          parent_name:'Access Role',
          // sort:'END_DESC',
          page:page,
          per_page:5,
        }}
      )
      .then(response=>{
        if(page===1){
          setRoles({data:response.data})
        }else{
          setRoles({
            data:{
              ...response.data,
              items:[...roles.data.items,...response.data.items]
            }
          })
        }
      })
      .catch(error=>{
        console.log(error)
        setRoles({error:true})
      })
  }

  useEffect(()=>{
    if(modalOpen)setHovered(false)
    if(modalOpen && !roles.data && !roles.loading){
      loadRoles({})
    }
  // eslint-disable-next-line
  },[modalOpen])

  if (!source) return <></>

  const hasAccess = infoBox?.value;
  const accesRequestEnabled = source.enable_access_docs;
  const instructions = source.access_request_instructions;
  const link = source.access_request_url;
  const displayAccess = source.access_request_show_roles;
  const objectName = toTitleCase(mapObjectName(data.object.name).replace(/_/g, ' '))

  let iconLabel, iconColour, content, chipColour, showModal, hoverable, onChipClick;

  if (hasAccessInfo.includes(data.object.name)) {
    if(!infoBox && accesRequestEnabled){
      iconLabel = 'info';
      chipColour = '#1565C0';
      iconColour = hovered?'#FFF':chipColour;
      content = (
        <Typography className={classes.text}>
          <span data-test-id="go-parent-button" className={classes.clickableText} style={{color:hovered?'#fff':undefined}}>
            See how to gain access
          </span>
        </Typography>
      )
      showModal = true;
      hoverable = true;
      onChipClick = () => setModalOpen(true);
    }
    else if (infoBox && !hasAccess && !accesRequestEnabled) {
      iconLabel = 'banned';
      iconColour = theme.palette.primaryText.light;
      chipColour = '#757575';
      content = <Typography className={classes.text}>You do not have access to this {objectName}</Typography>
    }
    else if (infoBox && !hasAccess && accesRequestEnabled) {
      iconLabel = 'info';
      chipColour = '#D3302F';
      iconColour = hovered?'#FFF':chipColour;
      content = <Typography className={classes.text} style={{color:hovered?'#fff':undefined}}>You do not have access. <span data-test-id="gain-access-button" className={classes.clickableText} >See how to gain access</span></Typography>
      showModal = true;
      hoverable = true;
      onChipClick = () => setModalOpen(true);
    }
    else if(infoBox && hasAccess){
      iconLabel = 'checked';
      chipColour = theme.palette.success.main;
      iconColour = chipColour;
      content = <Typography className={classes.text}>You have access to this {objectName}</Typography>
    }
  }

  if (noAccessInfo.includes(data.object.name) && accesRequestEnabled) {
    iconLabel = 'info';
    chipColour = '#D3302F';
    iconColour = hovered?'#FFF':chipColour;
    content = (
      <Typography className={classes.text}>
        <span data-test-id="go-parent-button" className={classes.clickableText} style={{color:hovered?'#fff':undefined}}>
          See how to gain access
        </span>
      </Typography>
    )
    showModal = true;
    hoverable = true;
    onChipClick = () => setModalOpen(true);
  }

  if (!content) return <></>


  return (
    <div
      data-test-classname="access-info-chip"
      style={{ border:`1px solid ${chipColour}`, cursor:onChipClick?'pointer':undefined, background:hovered && hoverable?chipColour:undefined, marginBottom: 16, padding: '8px 12px', width: '100%', display: 'flex', boxSizing: 'border-box', alignItems: 'center', borderRadius: 6 }}
      onMouseEnter={()=>setHovered(true)}
      onMouseLeave={()=>setHovered(false)}
      onClick={onChipClick}
    >
      <div style={{ width: 20, height: 20, marginRight: 8 }}>
        {getIconComponent({ label: iconLabel, size: 20, colour: iconColour })}
      </div>
      {content}

      {
        showModal &&
        <Modal onClick={event=>event.stopPropagation()} classes={{ root: classes.modalRoot }} open={modalOpen} disableBackdropClick>
          <Paper className={classes.modalPaper}>
            <div style={{ display: 'flex', overflow: 'hidden', alignItems: 'center', paddingRight: 24, flexGrow: 0, flexShrink: 0, marginBottom: 16 }}>
              <div style={{ flexGrow: 1, overflow: 'hidden', marginRight: 16 }}>
                <KTooltip title={`REQUEST ACCESS TO ${data.name.toUpperCase()}`}>
                  <Typography className={classes.header}>REQUEST ACCESS TO {data.name.toUpperCase()}</Typography>
                </KTooltip>
              </div>
              <Button data-test-id="request-modal-close-button" color='primary' variant='outlined' onClick={() => { setModalOpen(false) }}>CLOSE</Button>
            </div>
            <div className={classes.modalBody} id="request-access-body">
              <Typography className={classes.sectionHeader} style={{marginTop:8}}>INSTRUCTIONS</Typography>
              <InteractiveViewer scrollElID="request-access-body" initialValue={instructions||'No instruction provided'} id={"request-instruction"} />
              {
                link &&
                <>
                  <Typography className={classes.sectionHeader}>LINK</Typography>
                  <Typography className={classes.requestLink} onClick={()=>window.open(link,'_blank')}>{link}</Typography>
                </>
              }
              {
                displayAccess && 
                <>
                  <Typography className={classes.sectionHeader}>ACCESS ROLES LINKED TO THIS {objectName.toUpperCase()}</Typography>
                  {
                    roles.data && roles.data.total===0 && 
                    <Typography style={{fontSize:13.75}}>No roles found</Typography>
                  }
                  {
                    roles.data && roles.data.items.map(el=>(
                      <div className={classes.roleChip} data-test-classname="role-list-item">
                        <div style={{width:24,height:24,marginLeft:16,marginRight:32,flexGrow:0,flexShrink:0}}>
                          {getIconComponent({label:'access_role_filled',size:24,colour:theme.palette.primaryText.light})}
                        </div>
                        <KTooltip title={el.name}>
                          <Typography data-test-classname="role-name" className={classes.roleChipText}>
                            {el.name}
                          </Typography>
                        </KTooltip>
                        <IconButton 
                          data-test-classname="role-copy-button"
                          style={{marginRight:4}}
                          onClick={()=>{
                            copyToClipboard(el.name,'role-copy-placeholder')
                            sendAlert({message:"Copied name to clipboard",type:'info'})
                          }}
                        >
                          {getIconComponent({label:'copy',size:20,colour:theme.palette.primaryText.light})}
                        </IconButton>
                      </div>
                    ))
                  }
                  {
                    roles.loading && <CircularProgress color='secondary'/>
                  }
                  {
                    roles.error && <Typography style={{fontSize:13.75}}>Error occurred loading roles</Typography>
                  }
                  {
                    roles.data && roles.data.page<roles.data.pages && !roles.loading && 
                    <Button color='primary' data-tets-id="role-list-see-more-button" style={{marginTop:4}} onClick={()=>loadRoles({page:roles.data.page+1})}>SEE MORE</Button>
                  }
                  <textarea style={{width:0,height:0,overflow:'hidden',visibility:'hidden'}} value="" id="role-copy-placeholder"></textarea>
                </>
              }
            </div>
          </Paper>
        </Modal>
      }
    </div>
  )
}

AccessChip.propTypes = {
  classes: PropTypes.object.isRequired,
  infoBox: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(withRouter(AccessChip)));