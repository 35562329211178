import axiosSolr from "../../axios-solr"
import Alert from 'react-s-alert'

const loadSeverity = ({state, dispatch, setLoading, currentID, apiIdRef, search, setError}) => {
  setLoading(true)
  axiosSolr
    .get(
      `/solr/search/select`,{
        params:{
          q:"*",
          fq:`object_type_srt:ISSUE AND source_id_srt:${state.stepList[0].id}`,
          rows:0,
          'json.facet':{
            "severity":{
              "type":"terms",
              'field':'issue_severity',
              "mincount":1,
            }
          }
        }
      }
    )
    .then(response=>{
      if(currentID!==apiIdRef.current)return;
      let items = []
      if(response.data.facets.count!==0){
        items = response.data.facets.severity.buckets.map(el=>({id:el.val, name:el.val, object_type_txt:'severity'})).filter(el=>(search.trim()==='' || el.name.toLowerCase().includes(search.toLowerCase())) )
      }
      dispatch({type:'set_list',list:{docs:items, numFound:items.length}})
      setLoading(false)
    })
    .catch(error=>{
      setLoading(false)
      setError(true)
      console.log(error)
      Alert.error('Error loading the items', {
        position: 'top',
        effect: 'stackslide',
      });
    })
}

const loadSources = ({state, dispatch, setLoading, currentID, apiIdRef, search, setError, nameSort}) => {
  setLoading(true)
  axiosSolr
    .get(
      `/solr/search/select`,{
        params:{
          q:"*",
          fq:"object_type_srt:ISSUE",
          rows:0,
          'json.facet':{
            "sourceIds":{
              "type":"terms",
              'field':'source_id_srt',
              "mincount":1,
            }
          }
        }
      }
    )
    .then(response=>{
      if(currentID!==apiIdRef.current)return;
      let items = []
      if(response.data.facets.count!==0){
        items = response.data.facets.sourceIds.buckets.map(el=>state.sourceList.find(s=>s.id===el.val)).filter(el=>(search.trim()==='' || el.name.toLowerCase().includes(search.toLowerCase())) )
      }
      if(nameSort==='name_srt asc'){
        items.sort((a,b)=>a.name<b.name?-1:1)
      }else{
        items.sort((a,b)=>a.name<b.name?1:-1)
      }
      dispatch({type:'set_list',list:{docs:items, numFound:items.length}})
      setLoading(false)
    })
    .catch(error=>{
      setLoading(false)
      setError(true)
      console.log(error)
      Alert.error('Error loading the items', {
        position: 'top',
        effect: 'stackslide',
      });
    })
}

export const loadIssueSteps = ({state, dispatch, nameSort, setLoading, currentID, apiIdRef, search, setError}) => {
  if(state.stepList.length===0){
    loadSources({state, dispatch, setLoading, currentID, apiIdRef, search, setError, nameSort})
  }
  if(state.stepList.length===1){
    loadSeverity({state, dispatch, setLoading, currentID, apiIdRef, search, setError})
  }
}