import { Typography, withTheme, withStyles, Button } from '@material-ui/core';
import React, { memo } from 'react';

import theme from '../../../theme';
import { getIconComponent } from '../../../utilities';
import { Handle } from 'react-flow-renderer';

const styles = theme => ({
  undraggableNode:{
    cursor:'default !important',
  }
})


const KButtonNode = memo(({ data, draggable,selected, type, classes }) => {
  
  return (
    <div
      style={{
        background: theme.palette.kChartGroupBackground['solid'],
        border:`2px solid ${theme.palette.primary.main}`,
        borderRadius:5,
        padding:'8px 16px',
        width:data.width,
        minWidth:100,
        height:data.height+16,
        boxSizing:'border-box',
        // marginLeft:-48,
        opacity:data.faded?0.3:1,
      }}
      onClick={event=>{
        event.preventDefault();
        event.stopPropagation();
      }}
      className={data.draggable===false?classes.undraggableNode:''}
    >
      <div style={{display:'flex',alignItems:'center',overflow:"hidden",minHeight:44}}>
        {
          <div style={{width:24,height:24,marginRight:8}}>
            {
              getIconComponent({
                label: data.icon,
                size:24,
                colour:data.iconColour || theme.palette.primaryText.light
              })
            }
          </div>
        }
        <div style={{flexGrow:1,overflow:'hidden'}}>
          <Typography 
            style={{
              fontSize:13.75,
              color: data.titleColour || theme.palette.primaryText.main
            }}
            className={'overflow-text'}
          >
            {data.header}
          </Typography>
          {
            data.subHeader && 
            <Typography 
              style={{
                fontSize:12,
                color: theme.palette.primaryText.light,
                marginTop:4
              }}
              className={'overflow-text'}
            >
              {data.subHeader}
            </Typography>
          }
        </div>
      </div>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:16}}>
        <Handle
          type="target"
          position="left"
          style={{ 
            background: '#555',
            visibility:'hidden', 
            position:'relative',
            top:4,
            width:0,
            left:-8
          }}
        />
        <Button onClick={data.onClick} style={{width:data.width-62}} variant='contained'>
          {data.text}
        </Button>
        <Handle
          type="source"
          position="right"
          style={{ 
            background: '#555',
            visibility:'hidden', 
            position:'relative',
            top:4,
            width:0,
            right:0
          }}
        />
      </div>
      
    </div>
  );
});


export default withTheme()(withStyles(styles)(KButtonNode));