import React, {} from 'react';
import { withTheme,  Typography, withStyles} from '@material-ui/core';

const styles = theme => ({
  root:{
    display:'flex',
    alignItems:'center',
    height:32,
    paddingLeft:16,
    paddingRight:16,
    // marginBottom:8,
  },
  listText:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:1.5,
    overflow:"hidden"
  }
})

const ColumnHeader = props => {

  const {
    classes,
    label
  } = props;

  return (
    <div className={classes.root}>
      <div style={{flex:'0 0 24px'}}></div>
      <Typography className={classes.listText} style={{flex:'1 1 260px',minWidth:100,marginRight:16,marginLeft:16}}>
        NAME
      </Typography>
      {
        label==='term' && 
        <Typography className={classes.listText} style={{flex:'1 1 260px',marginRight:16,marginLeft:16}}>
          GLOSSARY
        </Typography>
      }
      {
        label==='instance' && 
        <Typography className={classes.listText} style={{flex:'1 1 260px',marginRight:16,marginLeft:16}}>
          COLLECTION
        </Typography>
      }
      {
        label==='report' &&
        <Typography className={classes.listText} style={{flex:'1 1 200px',marginRight:32}}>
          LOCATION
        </Typography>
      }
      <Typography className={classes.listText} style={{flex:'1 1 320px',marginRight:32}}>
        DESCRIPTION
      </Typography>
      {
        label==='issue' &&
        <Typography className={classes.listText} style={{flex:'0 0 200px',marginRight:32}}>
          ISSUE SEVERITY
        </Typography>
      }
      {
        label==='issue' &&
        <Typography className={classes.listText} style={{flex:'0 0 200px',marginRight:32}}>
          ISSUE TYPE
        </Typography>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(ColumnHeader));