import { Typography, withTheme, withStyles } from '@material-ui/core';
import React, { memo } from 'react';

import theme from '../../../theme';
import { getIconComponent } from '../../../utilities';
import { Handle } from 'react-flow-renderer';
import KTooltip from '../KTooltip/KTooltip';

const styles = theme => ({
  undraggableNode:{
    cursor:'default !important',
  },
  clickable:{
    cursor:'pointer !important',
    '&:hover':{
      background:`${theme.palette.chartNodeSelected.main} !important`
    },
  }
})


const KIconLabelNode = memo(({ data, draggable,selected, type, classes }) => {
  
  return (
    <div
      style={{
        maxWidth:data.width * 2,
        width:'max-content',
        minWidth:data.width,
        height:data.height,
        display:"flex",
        alignItems:'center',
        justifyContent:'center',
        borderRadius:4,
        padding:'0px 8px',
        boxSizing:'border-box',
        border:`2px solid ${theme.palette.primary.main}`,
        background:selected?theme.palette.chartNodeSelected.main:theme.palette.background.main,
        overflow:'hidden',
      }}
      onClick={event=>{
        data.onClick?.()
      }}
      className={(data.draggable===false?classes.undraggableNode:'') + (data.onClick?' '+classes.clickable:'') }
    >
      <div style={{flexShrink:0,width:data.iconSize||24,height:data.iconSize||24}}>
        {getIconComponent({label:data.iconLabel,size:data.iconSize || 24,colour:data.iconColor || theme.palette.primary.main})} 
      </div>
      <KTooltip title={data.text}>
        <Typography 
          style={{
            fontSize:data.fontSize || 16,
            color:data.fontColour || theme.palette.primary.main,
            padding:6,
            flexGrow:0,
            flexShrink:1,
            marginLeft:8,
            borderRadius:21,
            overflow:'hidden',
            textOverflow:'ellipsis',
            whiteSpace:'nowrap',
            boxSizing:'border-box'
          }}
        >
          {data.text}
        </Typography>
      </KTooltip>
      <Handle
        type="target"
        position="left"
        style={{ 
          background: '#555',
          visibility:'hidden', 
          position:'absolute',
          top:data.height/2,
          width:0,
          left:'50%'
        }}
      />
      <Handle
        type="source"
        position="right"
        style={{ 
          background: '#555',
          visibility:'hidden', 
          position:'absolute',
          top:data.height/2,
          width:0,
          right:'50%'
        }}
      />
    </div>
  );
});


export default withTheme()(withStyles(styles)(KIconLabelNode));