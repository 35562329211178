import React, { useReducer, useEffect, useState } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import {  LinearProgress, Typography } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/SourceProfile/Body/Body';
import {  setInitialState, handleShareClick, removeUrlQueryArg, getUserRoles, sendMessage } from '../../utilities';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import axiosSolr from '../../axios-solr'
// import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import {addHistory} from '../../HistoryManager'
import 'url-search-params-polyfill';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import axiosCerebrum from '../../axios-cerebrum';
import DropdownButton from '../../components/UI/Buttons/DropdownButton';
import { getAlterName } from '../../components/UI/ProfileHeader/utils';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  underlineOnHover: {
    color:theme.palette.primaryText.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
});


const initialState = {
  tabState: 0,
  // basic table data
  basicData: null,
  showDeleted:{},
  showReference:{},
  sourceTabState:0,
  linkedData:{},
  linkedCollectionData:{},
  linkedSort:{
    'TABLE':'trust_srt desc',
    'COLUMN':'trust_srt desc',
    'REPORT':'trust_srt desc',
    'SHEET':'trust_srt desc',
    'DATASET':'trust_srt desc',
  },
  linkedSearch:{},
  noteTabState:0,
  noteDetailMap:{},
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataLoading: action.basicDataLoading,
        basicDataError: action.basicDataError
      }
    case 'set_link_object_data':
      return {
        ...state,
        linkObjectData:action.linkObjectData
      }
    case 'set_collection_instances':{
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    }
    case 'set_show_reference':
      return {
        ...state,
        showReference:action.showReference
      }
    case 'set_show_deleted':
      return {
        ...state,
        showDeleted:action.showDeleted
      }
    case 'set_linked_facets':
      return {
        ...state,
        linkedFacets:action.linkedFacets,
        linkedFacetsLoading:action.linkedFacetsLoading,
        linkedFacetsError:action.linkedFacetsError
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
        insightsError: action.insightsError,
        insightsLoading: action.insightsLoading
      }
    case 'set_source_tab_state':
      return {
        ...state,
        sourceTabState:action.sourceTabState,
      }
    case 'set_linked_data':
      return {
        ...state,
        linkedData:action.linkedData
      }
    case 'set_linked_collection_data':
      return {
        ...state,
        linkedCollectionData:action.linkedCollectionData
      }
    case 'set_linked_search':
      return {
        ...state,
        linkedSearch:action.linkedSearch
      }
    case 'set_linked_sort':
      return {
        ...state,
        linkedSort:action.linkedSort
      }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }

    case 'set_notes':
      return {
        ...state,
        notesLoading:action.notesLoading,
        notesError:action.notesError,
        notes:action.notes
      }
    case 'set_survey_data':
      return {
        ...state,
        surveyData:action.surveyData,
        surveyLoading:action.surveyLoading,
        surveyError:action.surveyError
      }
    case 'set_note_tab_state':{
      return {
        ...state,
        noteTabState:action.noteTabState
      }
    }
    case 'set_note_detail_map':{
      return {
        ...state,
        noteDetailMap:action.noteDetailMap
      }
    }
    case 'set_note_modal_open':{
      return {
        ...state,
        noteModalOpen:action.noteModalOpen
      }
    }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}


const SourceProfile = props => {

  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache
  } = props;

  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));

  const urlSearch = new URLSearchParams(window.location.search);
  const roles = getUserRoles(sessionData.user_role)
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])


  const fetchList = async () => {
    dispatch({type:'set_basic_data',basicData:state.basicData,basicDataLoading:state.basicData?false:true})
    let sourceObj;
    if(match.params.id.length<=4){
      await axiosCerebrum
        .get(
          `/api/sources/${match.params.id.toLowerCase()}`
        )
        .then(response => {
          sourceObj = response.data;
        })
        .catch(error => {
          console.log(error);
          dispatch({type:'set_basic_data',basicDataError:true})
        });
      if(!sourceObj){
        dispatch({type:'set_basic_data',basicDataError:true})
        return;
      }
    }
    axiosCerebrum
      .get(
        `/api/hosts/${sourceObj?.host_ids[0] || match.params.id.toLowerCase()}`
      )
      .then(response => {
        let data = response.data;
        axiosSolr
          .get(`/solr/search/select`, {params:{
            q:"*",
            fq:`id:${data.id}`,
            fl:'source_type_txt,source_txt'
          }})
          .then(solrResponse=>{
            let solrData = solrResponse.data.response.docs[0];
            dispatch({type:'set_basic_data',basicData:{...data,...solrData}})
          })
          .catch(error=>{
            console.log(error)
            dispatch({type:'set_basic_data',basicDataError:true})
          })
      })
      .catch(error => {
        console.log(error);
        dispatch({type:'set_basic_data',basicDataError:true})
      });
  }


  useEffect(()=>{
    if(!state.basicData){
      fetchList()
    }
  // eslint-disable-next-line
  },[])
  

  useEffect(()=>{
    if(state.basicData && !state.linkedFacets){
      let fullResponse = state.basicData
      const isReference = fullResponse.reference;
      addHistory({url:window.location.pathname,iconLabel:fullResponse.source_type_txt, title: fullResponse.name, subTitle:'Source profile',object:fullResponse,type:'profile'})
      dispatch({type:'set_basic_data',basicData:fullResponse})
      dispatch({type:'set_linked_facets',linkedFacetsLoading:true})
      const sourceFilter = isReference?`parent_id_srt:${fullResponse.id}`:`source_id_srt:${fullResponse.source_id}`
      let isInactive = fullResponse.active_flag === false 
      let objectTypesOrder = {
        'DATABASE':10,
        'SCHEMA':20,
        'TABLE':30,
        'COLUMN':40,
        'TOOL':50,
        'CONTENT_APP':60,
        'REPORT':70,
        'SHEET':80,
        'DATASET':90,
        'DATASET_TABLE':100,
        'DATASET_FIELD':110,
        'DATA_PIPELINE':120,
        'CODE':130,
        'FILE':140,
        'ISSUE':150,
        'STORAGE':160,
      };
      axiosSolr.get(
        `/solr/search/select`,
        {params:{
          q:'*',
          fq:sourceFilter + (isInactive?'':' AND active_srt:YES ') + (isReference?'':` AND reference_srt:NO`) + ' AND -object_type_srt:(COLLECTION_INSTANCE OR SOURCE OR COLLECTION OR HOST)',
          rows:0,
          'json.facet':{
            "objects": {
              "type": "terms",
              "field": "object_type_srt",
              "mincount":1,
              limit:30
            },
            'codes':{
              "type":"query",
              'q':'object_type_srt:CODE',
              'facet':{
                "code_types": {
                  "type": "terms",
                  "field": "code_type_srt",
                  "mincount":1,
                  limit:100,
                }
              }
            },
          }
          
        }}
      ).then(response=>{
        let facetBody = response.data.facets?.objects?.buckets || [];
        let linkedFacets = [];
        let showDeleted = {};
        let showReferences = {};
        for(let i=0; i<facetBody.length; i++){
          if(facetBody[i].val==='CODE')continue;
          linkedFacets.push({
            name:facetBody[i].val,
            count:facetBody[i].count
          })
          showDeleted[facetBody[i].val] = true
          showReferences[facetBody[i].val] = true
        } 

        let codeFacetBody = response.data.facets?.codes?.code_types?.buckets || [];
        for(let i=0; i<codeFacetBody.length; i++){
          linkedFacets.push({
            name:codeFacetBody[i].val,
            count:codeFacetBody[i].count,
            isCode:true
          })
          showDeleted[codeFacetBody[i].val] = true
          showReferences[codeFacetBody[i].val] = true
        }

        if(isInactive){
          dispatch({type:'set_show_deleted',showDeleted:{...showDeleted,...state.showDeleted}})
        }
        if(isReference){
          dispatch({type:'set_show_reference',showReference:{...showReferences,...state.showReference}})
        }

        axiosSolr.get(
          `/solr/search/select`,
          {params:{
            q:'*',
            fq:sourceFilter + ' AND active_srt:YES' + (isReference?'':` AND reference_srt:NO`) + ` AND object_type_srt:COLLECTION_INSTANCE`,
            rows:0,
            'json.facet':{
              "collection":{
                "type": "terms",
                "field":"collection_srt",
                "mincount":1,
                'limit':200,
                "facet":{
                  collection_type:{
                    "type": "terms",
                    "field":"collection_type_srt",
                    "mincount":1,
                    'limit':200,
                  }
                }
              },
            },
          }}
        )
        .then(cReponse=>{
          let linkedData = {}
          let facets = cReponse.data.facets.collection;
          if(facets && facets.buckets.length>0){
            facets.buckets.forEach(el=>{
              linkedFacets.push({
                name:el.val,
                count:el.count,
                isCollection:true,
                collection_type:el.collection_type.buckets[0].val
              })
              linkedData[el.val] = {isCollection:true}
            })
          };
          let finalLinkedFacet = linkedFacets.sort((a,b)=>(objectTypesOrder[a.name]||300)-(objectTypesOrder[b.name]||300))

          let tabName = urlSearch.get('assetTabName');
          if(tabName){
            dispatch({type:'set_source_tab_state', sourceTabState:Math.max(0,finalLinkedFacet.map(el=>el.name).indexOf(tabName))})
            window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['assetTabName']}));
          }
         
          // dispatch({type:'set_linked_data',linkedData});
          // dispatch({type:'set_linked_collection_data',linkedCollectionData})
          dispatch({type:'set_linked_facets',linkedFacets:finalLinkedFacet})
        })
        .catch(error=>{
          dispatch({type:'set_linked_facets',linkedFacetsError:true})
          console.log(error)
        })
      }).catch(error=>{
        dispatch({type:'set_linked_facets',linkedFacetsError:true})
        console.log(error)
      })
    }else if(state.basicData && state.linkedFacets){
      let tabName = urlSearch.get('assetTabName');
      if(tabName){
        dispatch({type:'set_source_tab_state', sourceTabState:Math.max(0,state.linkedFacets.map(el=>el.name).indexOf(tabName))})
        window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['assetTabName']}));
      }
    }
  // eslint-disable-next-line
  },[state.basicData])

  if (state.basicDataLoading || state.linkedFacetsLoading) {
    return (
      <div style={{ textAlign:'center', alignSelf: 'center', width: '18.75rem', marginTop: '20rem' }}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError || state.linkedFacetsError) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData || !state.linkedFacets)return <div></div>

  let buttons = [];

  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )


  buttons.push(
    <DropdownButton
      iconLabel='add'
      text='ADD'
      optionList={[
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:true}),iconLabel:'note',text:'Add Knowledge'},
        {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}}),iconLabel:'question',text:'Ask Question'}
      ]}
    />
  )

  let {title, description} = getAlterName(state.basicData, roles)
  if(!title)title = 'Unknown'
  let bannerVisibility, bannerCause, bannerdisplayText;
  
  if (state.basicData && state.basicData.active_flag === false) {
    bannerdisplayText = 'This source has been deactivated in K. Information on this source is dated and may no longer be accurate';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }

  if (state.basicData && state.basicData.reference===true){
    bannerCause = 'shell';
    bannerVisibility = 'visible'
    bannerdisplayText = 'This is a reference Source. Limited meta available';
  }

  let tabOptions = ['ASSETS','DETAILS','KNOWLEDGE']

  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['link','note']}
        history={history}
      />
      <ProfileLayout
        header={(
          <ProfileHeader
            title={title}
            subtitle={' '}
            state={state}
            dispatch={dispatch}
            label={'host'}
            iconLabel={'host'}
            sourceType={state.basicData.source_type_txt==='REFERENCE'?'HOST':state.basicData.source_type_txt}
            buttons={buttons}
            data={state.basicData}
            description={description}
            history={history}
            isShell={state.basicData.reference===true}
            bannerdisplayText={bannerdisplayText}
            bannerVisibility={bannerVisibility}
            bannerCause={bannerCause}
            shouldLoadBreadCrumb
            // minHeight={130}
            shouldLoadLinkedInstance
            onClickAddTag={()=>{
              setLinkModalOpen({
                linkableObjects:['COLLECTION_INSTANCE'],
                relations:['MEMBER_OF'],
                onLinkUpdated:()=>sendMessage({reload_header_instance:true})
              })
            }}
          />
        )}
        tabBar={
          state.linkedFacets && 
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => {dispatch({type:'set_tab_state',tabState:value})}}
            minWidth={200}
            maxWidth={200}
            disableUnderline={true}
          />
        }
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
            fetchList={fetchList}
            tabOptions={tabOptions}
          />
        }
        sideBar={
          <ProfileSideBar
            history={history}
            editable={false}
            state={state}
            dispatch={dispatch}
            data={state.basicData}
            fetchList={fetchList}
            sessionData={sessionData}
            mapping={['sourceType','classification','domain','verified','verifiedNot','owner', 'stewardedBy','createdBy','lastUpdated']}
          />
        }
        state={state}
        dispatch={dispatch}
      />
    </div>
  )

}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}



export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(SourceProfile)));
