import React, { useState, useRef, useEffect} from 'react'; 
import { withTheme, withStyles, Typography, CircularProgress, Paper, Modal, Button } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import SolrList from '../../UI/SolrList/SolrList';
import sqlFormatter from "sql-formatter";
import CodeDisplay from '../CodeDisplay/CodeDisplay';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  modal: {
    width: 800,
    padding: 24,
    paddingBottom: 8,
    paddingRight:0,
    margin: '0 auto',
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  modalTitle: {
    fontSize: '1.25rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: '0.5rem',
    width:'100%',
    whiteSpace: 'nowrap',
    flexGrow: 0,

  },
  modalSubtitle: {
    marginTop: '10px',
    color: theme.palette.primaryText.light,
  },
  title:{
    fontSize:20,
    color:theme.palette.primary.main,
  },
  subTitle:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
})

function TableCluster(props) {

  const {
    classes,
    theme,
    history,
    list,
    targetObject
  } = props;

  const textAreaRef = useRef();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTable, setSelectedTable] = useState();
  const [selectedQuery, setSelectedQuery] = useState();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const {
    sendAlert
  } = useAlert({})

  const handleCopyClick = () => {
    textAreaRef.current.select();
    document.execCommand('copy');
    sendAlert({ message: "Copied to Clipboard", type: 'info' })
  }

  const getQuery = table => {
    setSelectedTable(table);
    setLoading(true);
    setError(false);
    setModalOpen(true);
    let codeComponent = list.find(el=>el.id===table.id).codeComponents[0]
    axiosCerebrum.get(
      `/api/codecomponents/${codeComponent.id}/related`,
      {
        params: {
          per_page:1,
          object_name:'QUERY',
          relationship:'REFERENCED_BY'
        }
      }
    ).then(response => {
      if(response.data.total===0){
        setLoading(false);
        return;
      }
      axiosCerebrum
        .get(`/api/queries/${response.data.items[0].id}`)
        .then(queryData=>{
          setLoading(false);
          setSelectedQuery(queryData.data);
        })
    }).catch(error => {
      setError(true);
      setLoading(false);
      console.log(error);
    })
  }

  useEffect(()=>{
    if(!modalOpen){
      setSelectedTable();
      setSelectedQuery();
    }
  },[modalOpen])

  return (
    <div className={classes.root}>
      {
        list.length===0 && <Typography className={classes.normalText}>No item to be displayed</Typography>
      }
      <SolrList
        history={history}
        list={list}
        display={true}
        hideTrust={true}
        action={[
          {
            name:'EXAMPLE JOIN',
            action:getQuery
          }
        ]}
        hideTopMessage
      />
      
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        disableBackdropClick={true}
      >
        {
          selectedTable && 
          <div style={{ display: 'flex', alignItems: 'center', height: '100vh', width: '100%',outline:'none' }}>
            <Paper className={classes.modal}>
              <div style={{ overflow: 'auto', maxHeight: '80vh',minHeight:'20vh',paddingRight:24 }}>
                <Typography color='primary' className={classes.modalTitle}>
                  {`Example join between ${targetObject.name} and ${selectedTable.name}`}
                </Typography>
                <Typography className={classes.modalSubtitle}>Click copy to add the code to your clipboard</Typography>
                {
                  loading && <CircularProgress color='secondary' style={{marginTop:32}}/>
                }
                {
                  error && <Typography style={{color:theme.palette.primaryText.main,marginTop:16}}>Error occurred loading query</Typography>
                }
                {
                  selectedQuery && 
                  <div style={{marginTop:15}}>
                    <CodeDisplay
                      code={selectedQuery.code}
                    />
                  </div>
                }
              </div>
              <div align='right' style={{ marginTop: '32px',paddingRight:24 }}>
                {
                  selectedQuery &&
                  <Button color="primary" style={{ marginRight: 16 }}
                    onClick={() => {
                      handleCopyClick();
                    }}
                  >
                    COPY
                  </Button>
                }
                {
                  selectedQuery &&
                  <Button color="primary" style={{ marginRight: 16 }}
                    onClick={() => { history.push(`/profile/query/${selectedQuery.id}`) }}
                  >
                    OPEN
                  </Button>
                }
                <Button color="secondary"  onClick={() => { setModalOpen(false) }}>
                  CLOSE
                </Button>
              </div>
              <form style={{ opacity: 0, height: 0 }}>
                <textarea
                  ref={textAreaRef}
                  value={selectedQuery ? sqlFormatter.format(selectedQuery.code,{language:'pl/sql'}) : ''}
                />
              </form>
            </Paper>
          </div>
        }
      </Modal>

    </div>
  )
}

export default withTheme()(withStyles(styles)(TableCluster));