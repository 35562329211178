import React, { useReducer,useEffect } from 'react';
import { withTheme, withStyles, Typography, LinearProgress} from '@material-ui/core';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import Body from '../../components/LineageExplorer/Body/Body';
import useGetCerebrum from '../../hooks/useGetCerebrum'
import { addHistory } from '../../HistoryManager';
import 'url-search-params-polyfill';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileButton from '../../components/UI/Buttons/ProfileButton';

const styles = theme => ({
  subTitle:{
    fontSize:13.75,
    color:theme.palette.primaryText.main
  },
  description:{
    fontSize:13.75,
    color:theme.palette.primaryText.light
  },
  underlineOnHover: {
    color: theme.palette.primaryText.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  button: {
    margin: '8px 0px 8px 16px',
    height: '3rem',
    minWidth:'5.5rem',
    paddingLeft:'8px',
    paddingRight:'8px'
  },
})

const initialState = {
  tabState:0,
  targetObject:null,
  sourceList:undefined,
  source:'none',
  stepTwoFilter:null,
  viewSetting:'none',
  datasourceId:'',
  settingStep:0,
  lineageData:{
    view:'map'
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState
      }
    case 'set_source_list':
      return {
        ...state,
        sourceList:action.sourceList
      }
    case 'set_setting_step':
      return {
        ...state,
        settingStep:action.settingStep
      }
    case 'set_source':
      return {
        ...state,
        source:action.source
      }
    case 'set_step_two_filter':
      return {
        ...state,
        stepTwoFilter:action.stepTwoFilter
      }
    case 'set_target_object':
      return {
        ...state,
        targetObject:action.targetObject
      }
    case 'set_lineage_data':
      return {
        ...state,
        lineageData:action.lineageData
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

function LineageExplorer(props) {
  const {
    classes,
    theme,
    history,
  } = props;


  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    data:sourceList,
    loading: sourcelistLoading,
    error: sourceListError
  } = useGetCerebrum({
    url:'/api/sources',
    params:{
      per_page:100,
      // internal_flag:false,
      types:'DATABASE,TOOL,INTERNAL',
      sort:'ALPHABETICAL'
    }
  })

  useEffect(()=>{
    if(!sourceList)return;
    dispatch({type:'set_source_list',sourceList:sourceList.items})
  },[sourceList])

  useEffect(()=>{
    dispatch({type:'set_step_two_filter'})
    dispatch({type:'set_target_object'})
    // eslint-disable-next-line
  },[state.source])

  useEffect(()=>{
    if(state.tabState===1){
      addHistory({url:window.location.pathname+`?targetObjectId=${state.targetObject.id}`, iconLabel:'lineage_downstream', title: 'Lineage Explorer', subTitle:state.targetObject.name,type:'application'})
    }
  // eslint-disable-next-line
  },[state.tabState])

  if(sourcelistLoading){
    return (
      <div style={{ marginTop: 100,width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center' }}>
        <Typography style={{color:theme.palette.primaryText.main}}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if(sourceListError){
    return <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading Impact Assessment page. </Typography>
  }

  if(!sourceList)return <div></div>


  let buttons = []
  let title,description,subTitle;
  subTitle=(
    <span>
      <span className={classes.underlineOnHover} onClick={()=>history.push('/data_solutions')}>DATA APPLICATIONS</span>
      <span style={{whiteSpace:'pre-wrap'}}> / </span>
      <span className={classes.underlineOnHover} onClick={()=>dispatch({type:'set_tab_state',tabState:0})}>LINEAGE EXPLORER</span>
    </span>
  );
  title = `Lineage Explorer`;
  description='Use this application to explorer lineage between items in your data ecosystem'

  if(state.tabState===1){
    if(state.targetObject){
      let objectLocation = state.targetObject.location_txt || ''
      let location = objectLocation===''?state.targetObject.name:(objectLocation.split('://')[1] || objectLocation).replace(/\//g,'.')+'.'+state.targetObject.name;
      description = `TARGET: ${location}`.toUpperCase()
    }
    if(state.lineageData.view==='map'){
      buttons.push(
        <ProfileButton
          onClick={() => {
            dispatch({type:'set_lineage_data',lineageData:{view:'basic_map'}})
          }}
          text='BASIC VIEW'
        />
      );
    }else{
      buttons.push(
        <ProfileButton
          onClick={() => {
            dispatch({type:'set_lineage_data',lineageData:{view:'map'}})
          }}
          text='INDEPTH VIEW'
        />
      );
    }
  }

  return (
    <div className={classes.root}>
      <ProfileLayout
        noPadding
        disableHeaderAutoHeight={true}
        header={(
          <ProfileHeader
            label='lineage_downstream'
            title={title}
            subtitle={subTitle}
            buttons={buttons}
            description={description}
            minHeight={state.tabState===1?100:undefined}
          />
        )}
        body={
          <Body
            history={history}
            state={state}
            dispatch={dispatch}
          />
        }
      > 
      </ProfileLayout>
    </div>
  )

}

export default withTheme()(withStyles(styles)(LineageExplorer));