import axiosSolr from '../../../axios-solr'
import axiosCerebrum from '../../../axios-cerebrum'
import Alert from 'react-s-alert'
import { templates } from '../InteractiveInput/Templates'

export const loadSuggestedTerm = ({text, onFinish, relatedTerms, forceUnlinkedTerm, excludeName}) => {
  axiosSolr 
    .post(
      '/solr/glossary/tag',text,
      {
        headers:{
          'Content-type': 'text/plain'
        }
      }
    )
    .then(async response =>{
      let terms = response.data.response.docs.sort((a,b)=>b.term.length-a.term.length);
      let unlinkedTerms = terms;
      if(excludeName){
        unlinkedTerms = unlinkedTerms.filter(u=>u.term.toLowerCase() !== excludeName.toLowerCase())
      }
      unlinkedTerms = unlinkedTerms.filter(u=>!relatedTerms.find(l=>l.name.toLowerCase() === u.term.toLowerCase()))

      let linkedManualCollections = []

      unlinkedTerms.forEach(t=>{
        if(!text.match(templates.termSuggestion.getReplacementRegex(t.term))){
          linkedManualCollections.push(t)
        }
      })
      unlinkedTerms = unlinkedTerms.filter(u=>!linkedManualCollections.find(l=>l.term.toLowerCase() === u.term.toLowerCase()))
      if(forceUnlinkedTerm)unlinkedTerms.push(...forceUnlinkedTerm)

      onFinish(unlinkedTerms)
  })
}

export const onLinkTermSuggestion = async ({term, targetID}) => {
  await axiosCerebrum
    .put(`/api/collectioninstances/${term.id}/related?relationship=RELATES_TO&object_id=${targetID}`)
    .then(response=>{
      Alert.info(`The Term ${term.term} has been linked`, {
        position: 'top',
        effect: 'stackslide',
      });
    })
    .catch(error=>{
      if(error.response && error.response.status && error.response.status===409){
        Alert.info(`The Term ${term.term} has been linked`, {
          position: 'top',
          effect: 'stackslide',
        });
        return;
      }
      console.log(error)
      Alert.error(`Error occurred linking the Term ${term.term}`, {
        position: 'top',
        effect: 'stackslide',
      });
    })
  window.postMessage({reload_description_highlight:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
  window.postMessage({reload_related_terms:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
}
