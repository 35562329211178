import React, {useEffect, useState, useRef} from 'react'; 
import { withTheme, withStyles, Typography, CircularProgress, Paper, Modal, Button } from '@material-ui/core';
import { formatNumber, isInViewport, toTitleCase } from '../../../utilities';
import axiosCerebrum from '../../../axios-cerebrum';
import axiosSolr from '../../../axios-solr';
import SolrList from '../../UI/SolrList/SolrList';
import sqlFormatter from "sql-formatter";
import CodeDisplay from '../../UI/CodeDisplay/CodeDisplay';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  root:{
    marginBottom:30
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  selector: {
    ...theme.components.selector,
		width: 180,
  },
  nameFilter:{
    ...theme.components.inputBase,
    marginRight:24,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
  listItem:{
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`
  },
  modal: {
    width: 800,
    padding: 24,
    paddingBottom: 8,
    paddingRight:0,
    margin: '0 auto',
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  modalTitle: {
    fontSize: '1.25rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: '0.5rem',
    width:'100%',
    whiteSpace: 'nowrap',
    flexGrow: 0,

  },
  modalSubtitle: {
    color: theme.palette.primaryText.light,
  },
  queryModal: {
    width: 800,
    maxHeight: '60vh',
    margin: '0 auto',
    marginTop: '-15vh',
    padding: '20px 30px 10px',
    backgroundColor:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
  },
  subTitle:{
    fontSize:13.75,
    color:theme.palette.primaryText.light,
    marginBottom:16
  },
  button:{
    height:28,
    marginLeft:24
  },
})

function TableCluster(props) {

  const {
    classes,
    state,
    theme,
    dispatch,
    history
  } = props;

  const [loadingMore, setLoadingMore] = useState(false);
  const textAreaRef = useRef();
  const [selectedCluster, setSelectedCluster] = useState();
  const [selectedQuery, setSelectedQuery] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false)
  const listRef = useRef();

  const {
    sendAlert
  } = useAlert({})

  const loadClusters = ({page}) => {
    if(page===1)dispatch({type:'set_table_cluster_data',tableClusterLoading:true})
    else{setLoadingMore(true)}
    axiosCerebrum
      .get(
        `/api/tables/${state.basicData.id}/related`,
        {params:{
          page:page,
          per_page:10,
          sort:'START_DESC',
          object_name:'COLLECTION_INSTANCE',
          relationship:'MEMBER_OF_AUTO',
          'parent_name':'table cluster'
        }}
      ).then(response=>{
        if(response.data.total===0){
          dispatch({type:'set_table_cluster_data',tableClusterData:response.data})
          return;
        }
        axiosSolr
          .get(
            `/solr/search/select`,
            {params:{
              q:'*',
              fq:`id:(${response.data.items.map(el=>el.id).join(' OR ')})`,
              fl:'*',
              rows:10,
            }}
          )
          .then(solrRes=>{
            let data;
            if(page===1){
              data = {...response.data,items: response.data.items.map(el=>({...el,...(solrRes.data.response.docs.find(s=>s.id===el.id)||{})})) }
            }else{
              data = {...response.data,items:[...state.tableClusterData.items,...response.data.items.map(el=>({...el,...(solrRes.data.response.docs.find(s=>s.id===el.id)||{})}))]}
            }
            dispatch({type:'set_table_cluster_data',tableClusterData:data})
            setLoadingMore(false)
          })
      }).catch(error=>{
        console.log(error);
        dispatch({type:'set_table_cluster_data',tableClusterData:state.tableClusterData,tableClusterError:true})
        setLoadingMore(false)
      })
  }

  useEffect(()=>{
    if(!state.tableClusterData)loadClusters({page:1})
  // eslint-disable-next-line
  },[])

  const handleCopyClick = str => {
    textAreaRef.current.select();
    document.execCommand('copy');
    sendAlert({message:'Copied to Clipboard',type:'info'})
  }

  const getQuery = cluster => {
    setSelectedCluster(cluster)
    axiosCerebrum.get(
      `/api/collectioninstances/${cluster.id}/related`,
      {
        params: {
          relationship:'MEMBERS_AUTO',
          object_name:'QUERY',
          per_page:1,
          sort:'USAGE_DESC'
        }
      }
    ).then(response => {
      if (response.data.items.length > 0) {
        axiosSolr
          .get(
            `/solr/search/select`,
            {params:{
              q:"*",
              fq:`id:${response.data.items[0].id}`,
              rows:1
            }}
          )
          .then(solrRes=>{
            setSelectedQuery(solrRes.data.response.docs[0]);
            setModalOpen(true);
          })
          .catch(error => {
            console.log(error);
            setErrorModalOpen(true);
          })
      } else {
        setErrorModalOpen(true);
      }
    }).catch(error => {
      console.log(error);
      setErrorModalOpen(true);
    })
  }


  useEffect(()=>{
    if(state.tableClusterData && !state.tableClusterError && isInViewport(listRef) && !state.tableClusterLoading &&  !loadingMore && state.tableClusterData.page<state.tableClusterData.pages){
      loadClusters({page:state.tableClusterData.page+1,nameFilter:state.searchFilter,sortBy:state.sortBy})
    }
   // eslint-disable-next-line
  },[loadingMore, state.tableClusterData])

  window.onscroll = () => {
    if(state.tableClusterData && isInViewport(listRef) && !loadingMore && state.tableClusterData.page<state.tableClusterData.pages){
      loadClusters({page:state.tableClusterData.page+1})
    }
  }

  return (
    <div className={classes.root}>
      <Typography data-test-id="related-section-title" className={classes.title}>{`${state.tableClusterData?formatNumber(state.tableClusterData.total):'0'} TABLE CLUSTER(S)`}</Typography>
      <Typography className={classes.subTitle}>Cluster of tables used that includes this Table</Typography>
      {
        state.tableClusterLoading && <div style={{marginTop:60,textAlign:'center'}}><CircularProgress color='secondary'/></div>
      }
      {
        state.tableClusterError && <Typography className={classes.normalText} style={{marginTop:30}}>Error occurred loading clusters</Typography>
      }
      {
        !state.tableClusterError && !state.tableClusterLoading && state.tableClusterData && 
        <SolrList
          history={history}
          list={state.tableClusterData.items}
          display={true}
          action={[
            {
              name:'EXAMPLE JOIN',
              action:getQuery
            }
          ]}
          hideTopMessage
        />
      }
      {
        !state.tableClusterError && !state.tableClusterLoading && state.tableClusterData && state.tableClusterData.total===0 &&
        <Typography className={classes.normalText}  style={{marginTop:30}}>No table clusters found</Typography>
      }
      <div
        style={{ marginBottom: 40, display:'flex',justifyContent:'center' }}
        ref={listRef}
      >
        {
          loadingMore && <CircularProgress color='secondary'/>
        }
      </div>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        disableBackdropClick={true}
      >
        <div style={{ display: 'flex', alignItems: 'center', height: '100vh', width: '100%' ,outline:'none'}}>
          <Paper className={classes.modal}>
            <div style={{ overflow: 'auto', maxHeight: '80vh',paddingRight:24 }}>
              <div style={{display:'flex',overflow:'hidden',position:'sticky',top:0,zIndex:10,background:theme.palette.background.main}}>
                <Typography color='primary' className={classes.modalTitle}>
                  {
                    selectedCluster &&  selectedCluster.tables_txts? 
                    `Example join between ${toTitleCase(selectedCluster.tables_txts.join(', '))}`
                    :
                    'Example join'
                  }
                </Typography>
                <Button variant='outlined' color='primary' onClick={handleCopyClick} className={classes.button}>COPY</Button>
                <Button variant='outlined' color='primary' style={{width:210}} onClick={() => { history.push(`/profile/query/${selectedQuery.id}`) }} className={classes.button}>OPEN PROFILE</Button>
                <Button variant='outlined' color='primary' onClick={() => { setModalOpen(false) }} className={classes.button}>CLOSE</Button>
              </div>
             
              <Typography className={classes.modalSubtitle}>Click copy to add the code to your clipboard</Typography>
              <div style={{marginTop:15}}>
                <CodeDisplay
                  code={selectedQuery ? selectedQuery.code : ''}
                />
              </div>
            </div>
            <form style={{ opacity: 0, height: 0 }}>
              <textarea
                ref={textAreaRef}
                value={selectedQuery ? sqlFormatter.format(selectedQuery.code,{language:'pl/sql'}) : ''}
              />
            </form>
          </Paper>
        </div>
      </Modal>

      <Modal
        open={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        disableBackdropClick={true}
      >
        <div style={{ display: 'flex', alignItems: 'center', height: '100vh', width: '100%',outline:'none' }}>
          <Paper className={classes.queryModal} style={{ width: 400 }}>
            <div style={{display:'flex',alignItems:'flex-start'}}>
              <Typography style={{ fontSize: 20, marginBottom: 10, color:theme.palette.primaryText.main,marginRight:16 }}>Example could not be found</Typography>\
              <Button color='primary' variant="outlined" onClick={() => setErrorModalOpen(false)}>CLOSE</Button>
            </div>
            
            <Typography style={{ whiteSpace: 'pre-wrap', fontSize: 16, color: theme.palette.primaryText.light, marginBottom: 46 }}>
              {
                `Sorry, we can't seem to find any example for you.\n\nTry asking one of the Top User`
              }
            </Typography>
            
          </Paper>
        </div>
      </Modal>
    </div>
  )
}

export default withTheme()(withStyles(styles)(TableCluster));