import React, { useState } from 'react';
import { withStyles, withTheme, Typography } from '@material-ui/core';
import {  getDispFields, getIconComponent, getUserRoles, handleShareClick, mapSearchObjectName } from '../../../utilities';

import Tags from './Tags';
import TitleSection from './TitleSection';
import BreadCrumb from './BreadCrumb';
import KTooltip from '../KTooltip/KTooltip';

const styles = theme => ({
  profileIcon: {
    marginLeft:-40,
    marginRight:16,
    marginTop:3
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main,
    display:'flex',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  subTitle:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
  },
  chip: {
    display: 'flex',
    alignItems: 'center',
    height: 16,
    letterSpacing:1,
    fontWeight:400,
    paddingLeft: 8,
    paddingRight: 12,
    borderRadius: 3,
    marginRight: 8,
    fontSize: 12,
    cursor: 'pointer',
    maxWidth: 300,
    marginBottom:4,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  chipText:{
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow:'ellipsis',
  },
  tagClearButton:{
    width:13.75,
    height:13.75,
    marginLeft:6,
    marginRight:-6,
    '&:hover':{
      background:'rgba(150,150,150,0.3)',
    }
  },
  tooltip:{
    fontSize:13.75,
    maxWidth:'80vw',
    width:'max-content',
  },
});


const ProfileHeader = props => {

  const {
    classes,
    theme,
    sessionData,
    data,
    state,
    dispatch,
    history,
    linkedInstances,
    linkedInstanceFetch,
    onClickAddTag,
    buttons,
  } = props;

  const [hoveredTag, setHoveredTag] = useState()

  const label = data.object_type_txt;
  const isShell = data.refenrence_txt==='YES'

  // const trimMiddleText = str => {
  //   if(str.length<=120)return str;
  //   return str.slice(0,20)+'...'+str.slice(str.length-100)
  // }

  const roles = getUserRoles(sessionData.user_role)
  
  let title = state.basicData.name;
  let description;
  if(state.basicData.alternate_name && state.basicData.alternate_name.trim()!==''){
    if(!roles.every(el=>el==='90')){
      description = `Business name: ${state.basicData.alternate_name}`
    }else{
      title = state.basicData.alternate_name
      description = `Technical name: ${state.basicData.name}`
    }
  }
  if(state.basicData.object_type_txt==='SOURCE'){
    title = getDispFields(state.basicData, 'dispTitle')
    description = getDispFields(state.basicData, 'dispSubtitle')
  }
  if(state.basicData.object_type_txt==='CODE' && state.basicData.code_type_txt==='QUERY'){
    let queryCode = 'QUERY-' + ( state.basicData.display_id || state.basicData.name);
    title = state.basicData.keyword + ' / ' + queryCode
    if(state.basicData.name && !state.basicData.name.includes(state.basicData.signature) && state.basicData.name.trim()!=='' && state.basicData.name!==state.basicData.id)title = state.basicData.name_txt
  }

  return (
    <div className={classes.root}>
        {/* <div className={classes.profileIcon}>
          {getIconComponent({ label: iconLabel || label, size: 24, colour: isShell?theme.palette.primaryText.light:theme.palette.header.main })}
        </div> */}
        {/* <div style={{flexGrow:1,marginRight:16,overflow:'hidden'}}>
          <KTooltip classes={{tooltip:classes.tooltip}} placement="bottom-start" leaveDelay={200} interactive={true}
            title={
              <div> 
                <div style={{display:'flex',alignItems:'center'}}>
                  {data.name_txt}
                  <div onClick={()=>handleShareClick(data.name_txt,'Name copied to Clipboard')} style={{cursor:'pointer',marginLeft:8}}>
                    {getIconComponent({label:'copy',size:20,colour:'#fff'})}
                  </div>
                </div>
                {
                  ['COLUMN','TABLE'].includes(label) && data && data.location && data.location.split("://")[1] && 
                  <div style={{display:'flex',alignItems:'center',marginTop:8}}>
                    {data.location.split("://")[1].split('/').map(el=>`"${el.toUpperCase()}"`).join('.')}
                    <div onClick={()=>handleShareClick(data.location.split("://")[1].split('/').map(el=>`"${el.toUpperCase()}"`).join('.'),'Location copied to Clipboard')} style={{cursor:'pointer',marginLeft:8}}>
                      {getIconComponent({label:'copy',size:20,colour:'#fff'})}
                    </div>
                  </div>
                }
              </div>
            }
          >
            <Typography className={classes.title} >
              {data.name_txt}
            </Typography>
          </KTooltip>
          
          <Typography className={classes.subTitle}>
            {trimMiddleText(data.location_txt || data.source_txt)}
          </Typography>
        </div> */}
      <div style={{display:'flex',overflow:'hidden',alignItems:'center',justifyContent:'space-between'}}>
        <BreadCrumb data={data} shouldWrap={true} fontSize={12}/>
        {
          buttons && buttons.length>0 &&
          <div style={{display:'flex',width:'max-content',marginTop:8,marginBottom:8}}>
            {buttons}
          </div>
        }
      </div>
      <div style={{marginTop:-6}}>
        <TitleSection
          data={data}
          title={title}
          description={description}
          label={mapSearchObjectName(state.basicData.object_type_txt, state.basicData.code_type_txt).toLowerCase()}
          state={state}
          dispatch={dispatch}
          isShell={isShell}
          titleFontSize={20}
        />
      </div>
      {
        data.location_txt && 
        <KTooltip  placement="bottom-start" leaveDelay={200} interactive={true}
          title={ 
            <div style={{display:'flex',alignItems:'center'}}>
              {data.location_txt}
              <div onMouseDown={event=>{event.stopPropagation();event.preventDefault()}} onClick={(event)=>{event.stopPropagation();handleShareClick(data.location_txt)}} style={{cursor:'pointer',marginLeft:8}}>
                {getIconComponent({label:'copy',size:20,colour:'#fff'})}
              </div>
            </div>
          }
        >
          <Typography style={{fontSize:12,color:theme.palette.primaryText.light,marginBottom:4}}>
            {data.location_txt}
          </Typography>
        </KTooltip>
      }
      <Tags
        isShell={isShell}
        data={data}
        label={label} 
        linkedInstances={linkedInstances}
        linkedInstanceFetch={linkedInstanceFetch}
        hoveredTag={hoveredTag}
        setHoveredTag={setHoveredTag} 
        onClickAddTag={onClickAddTag}
        addTagButtonSize={'small'}
        history={history}
      />

      {
        state.statsData && state.statsData.number_of_issues>0 ?
        <div style={{height:38,padding:'0 8px',background:'#D32F2F',display:'flex',alignItems:'center',overflow:'hidden',borderRadius:4,marginTop:18}}>
          {getIconComponent({label:'issue',size:24,colour:'#fff'})}
          <Typography style={{fontSize:13.75,color:'#fff',marginLeft:8,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:"nowrap"}}>
            {state.statsData.number_of_issues} open issue(s) linked to this {state.basicData.object_type_txt.toLowerCase().replace(/_/g,' ')}
          </Typography>
        </div>
        :
        null
      }
    </div>
  )
}



export default withTheme()(withStyles(styles)(ProfileHeader));