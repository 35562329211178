import React, { useEffect, useState } from 'react';
import { withTheme,  Typography, IconButton} from '@material-ui/core';
import SearchSelector from '../../../UI/SearchSelector/SearchSelector';
import { getIconComponent, getPartialMatchSearchString } from '../../../../utilities';
import theme from '../../../../theme';
import axiosSolr from '../../../../axios-solr';


const SourceSelector = props => {

  const {
    classes,
    filterType,
    source,
    onAddObject,
    onDeleteObject,
    objects
  } = props;
  
  const [database, setDatabase] = useState();
  const [databaseSearchFilter, setDatabaseSearchFilter] = useState('')

  const [hasObjects, setHasObjects] = useState(true)
  const [searchValue, setSearchValue] = useState('')

  useEffect(()=>{
    axiosSolr
      .get(
        `/solr/search/select`,{
          params:{
            q:'*',
            fq:`object_type_srt:${filterType.toUpperCase()} AND source_id_srt:${source}`,
            rows:0,
          }
        }
      )
      .then(response=>{
        if(response.data.response.numFound===0)setHasObjects(false)
      })
      .catch(error=>{
        console.log(error)
      })
    // eslint-disable-next-line
  },[])

  return (
    <div>
      {
        filterType==='schema' && 
        <>
          <Typography className={classes.configTitle}>Select database</Typography>
          <div style={{paddingRight:24,marginBottom:24}}>
            <SearchSelector 
              url='/solr/search/select'
              testID={'scanner-database-selector'}
              params={{
                q: getPartialMatchSearchString(databaseSearchFilter||'*'),
                fq:`object_type_srt:DATABASE AND source_id_srt:${source}`,
                fl:'*',
                rows:10
              }}
              // renderResults={hasObjects?undefined:()=>{
              //   return [
              //     <div style={{paddingLeft:16}}>
              //       <Typography>No {filterType}(s) found in this Source. Check with the Administrator to confirm the source metadata was loaded successfully</Typography>
              //     </div>
              //   ]
              // }}
              searchValue={databaseSearchFilter}
              setSearchValue={value=>{
                setDatabase()
                setDatabaseSearchFilter(value)
              }}
              placeholder={`Search for a database`}
              onResultClick={el=>{
                setDatabase(el)
                setDatabaseSearchFilter(el.name_txt)
              }}
              autoSuggestion
            />
          </div>
        </>
      }
      <Typography className={classes.configTitle}>Select tables by {filterType}</Typography>
      <div style={{paddingRight:24,marginBottom:24}}>
        <SearchSelector 
          url='/solr/search/select'
          testID={'scanner-object-selector'}
          params={{
            q: getPartialMatchSearchString(searchValue||'*'),
            fq:`object_type_srt:${filterType.toUpperCase()} AND source_id_srt:${source} ${objects.length>0?`-id:(${objects.map(el=>el.id).join(' OR ')})`:""}` +
                (filterType==='schema' && database?` AND database_srt:"${database.name_txt}"`:'')
            ,
            fl:'*',
            rows:10
          }}
          renderResults={hasObjects?undefined:()=>{
            return [
              <div style={{paddingLeft:16}}>
                <Typography>No {filterType}(s) found in this Source. Check with the Administrator to confirm the source metadata was loaded successfully</Typography>
              </div>
            ]
          }}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder={`Search for a ${filterType}`}
          onResultClick={el=>onAddObject(el)}
          autoSuggestion
        />
      </div>
      <div style={{paddingRight:24}}>
        <Typography style={{marginTop:12, fontSize:12, letterSpacing:1.5,color:theme.palette.primaryText.main,marginBottom:8}}>{filterType.toUpperCase()}(S) SELECTED</Typography>
        { 
          objects.length===0 && <Typography style={{color:theme.palette.primaryText.light,fontSize:13.75}}>No object selected</Typography>
        }
        {
          objects.length>0 && objects.map(el=>(
            <div style={{height:40,display:'flex',alignItems:'center',borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,boxSizing:'border-box'}}>
              <Typography style={{fontSize:16,marginLeft:16,color:theme.palette.primaryText.main,marginRight:8,flexGrow:1}}>
                {el.name_txt}{el.object_type_txt==='SCHEMA'?` (${el.database_txt})`:''}
              </Typography>
              <IconButton onClick={()=>onDeleteObject(el)}>
                {getIconComponent({label:'clear',size:16,colour:theme.palette.primaryText.main})}
              </IconButton>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default withTheme()(SourceSelector);