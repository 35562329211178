import { Typography, withTheme, withStyles } from '@material-ui/core';
import React, { memo } from 'react';
import {getIconComponent} from '../../../utilities'

import theme from '../../../theme';

const styles = theme => ({
  clickableContainer:{
    '&:hover':{
      // apply a lighten filter on background
      filter:localStorage.hasOwnProperty('dark')?'brightness(1.03)': 'brightness(0.95)',
      '& .group-title':{
        textDecoration:'underline',
      },
      '& .map-node-icon':{
        visibility:'visible'
      }
    }
  },
  title:{
    cursor:"pointer",
    textAlign:'center',
    lineHeight: 'normal',
  },
  openIcon:{
    visibility:'hidden',
    display:'inline-block',
    position:'relative',
    top:2
  }
})


const CollectionMapNode = memo(({ data, draggable,selected,viewOnly, type, classes, id }) => {
  
  let isCentreAlignTitle = data.isCentreAlignTitle || false;
  const fontSize = data.titleFontSize || 13.75
  const fontColour = data.titleColour || theme.palette.primaryText.main

  return (
    <div
      style={{
        borderRadius:5,
        width:data.width,
        border:`2px solid ${data.borderColour || theme.palette.primary.main}`,
        height:data.height,
        background: data.backgroundColour || theme.palette.background.main,
        cursor:data.onClick?'pointer':'default',
        ...(
          isCentreAlignTitle ? {display:'flex',alignItems:'center',justifyContent:'center',overflow:'hidden'}:{boxSizing:'border-box'}
        )
      }}
      onClick={event=>{
        event.preventDefault();
        event.stopPropagation();
        data.onClick?.();
      }}
      className={data.onClick?classes.clickableContainer:''}
    >
      <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:data.headerHeight||30,overflow:'hidden',marginRight:data.onClick?-fontSize:0}}>
        <Typography 
          style={{
            color: fontColour,
            marginLeft:16,
            marginRight:16,
            fontSize:fontSize
          }}
          className={'overflow-text group-title ' + classes.title}
        >
          {data.label}
          {
            data.onClick && 
            <div className={classes.openIcon + ' map-node-icon'} style={{width:fontSize,height:fontSize,marginLeft:2}}>
              {getIconComponent({label:'open', size:fontSize,colour:fontColour})}
            </div>
          }
        </Typography>
      </div>
    </div>
  );
});


export default withTheme()(withStyles(styles)(CollectionMapNode));