import React, {useEffect, useRef,} from 'react';
import { withTheme, CircularProgress, Typography, withStyles, MenuItem, Select} from '@material-ui/core';
import axiosSolr from '../../../axios-solr'
import ListItem from '../ListItem/ListItem';
import ColumnHeader from '../ColumnHeader/ColumnHeader';
import { isInViewport } from '../../../utilities';

const styles = theme => ({
  root: {

  },
  header:{
    fontSize:20,
    color:theme.palette.header.main,
    marginBottom:16,
    textTransform:'uppercase'
  },
  seeMoreButton:{
    marginTop:8
  },
  selector:{
    ...theme.components.titleSelector,
    marginBottom:4
  }
})

const Collection = props => {

  const {
    classes,
    theme,
    history,
    state,
    dispatch,
  } = props;
  
  const loadingRef = useRef()

  const loadData = ({view = state.collectionView, start = 0}) => {
    
    let promise;
    if(view==='platform'){
      promise = axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:COLLECTION_INSTANCE AND collection_type_srt:PLATFORM AND -collection_srt:"ACCESS ROLE"`,
              sort:'created_at_srt desc',
              rows:10,
              start,
            }
          }
        )
    }
    if(view==='dataGov'){
      promise = axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:COLLECTION_INSTANCE AND collection_type_srt:DATA_GOVERNANCE`,
              sort:'updated_at_srt desc',
              rows:10,
              start,
            }
          }
        )
    }
    if(view==='dataMgm'){
      promise = axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:COLLECTION_INSTANCE AND collection_type_srt:DATA_MANAGEMENT`,
              sort:'updated_at_srt desc',
              rows:10,
              start,
            }
          }
        )
    }
    if(!promise)return;

    dispatch({type:'set_collection_data',collectionData:state.collectionData,collectionLoading:true})
    promise
      .then(response=>{
        let data = response.data.response
        let collectionData = {
          ...(state.collectionData || {}),
          [view]:{
            ...data,
            docs:[...(state.collectionData?.[view]?.docs||[]),...data.docs],
          }
        }
        dispatch({
          type:'set_collection_data',
          collectionData,
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_collection_data',
          collectionData:state.collectionData,
          collectionError:true
        })
      })
  }

  useEffect(()=>{
    if(!state.collectionData || !state.collectionData[state.collectionView]){
      loadData({view:state.collectionView})
    }
  // eslint-disable-next-line
  },[state.collectionView])
  
  const shouldLoadMore = () => {
    if(!state.collectionData || !state.collectionData[state.collectionView])return false;
    if(state.collectionData[state.collectionView].numFound <= state.collectionData[state.collectionView].docs.length)return false;
    if(state.collectionLoading)return false;
    if(!isInViewport(loadingRef))return false;
    return true;
  }

  window.onscroll = () => {
    if (shouldLoadMore()) {
      loadData({view:state.collectionView,start:state.collectionData[state.collectionView].docs.length})
    }
  }

  useEffect(()=>{
    if (shouldLoadMore()) {
      loadData({view:state.collectionView,start:state.collectionData[state.collectionView].docs.length})
    }
  // eslint-disable-next-line
  },[state.collectionData])
  
  const getItem = item => {
    return (
      <ListItem
        item={item}
        label={'collection_instance'}
        history={history}
        key={item.id}
      />
    )
  }

  let data = state.collectionData?.[state.collectionView] // view: one of ['new','trending']

  return (
    <div className={classes.root}>
      <div style={{paddingBottom:24,background:theme.palette.background.main,position:'sticky',top:181,zIndex:9}}>
        <Select
          value={state.collectionView}
          onChange={(e)=>dispatch({type:'set_collection_view',collectionView:e.target.value})}
          className={classes.selector}
          disableUnderline
          data-test-id="collection-view-selector"
        >
          <MenuItem value="platform">NEW PLATFORM INSTANCE</MenuItem>
          <MenuItem value="dataGov">NEW DATA GOVERNANCE INSTANCE</MenuItem>
          <MenuItem value="dataMgm">NEW DATA MANAGEMENT INSTANCE</MenuItem>
        </Select>
        <Typography style={{color:theme.palette.primaryText.light,fontSize:12}}>
          New {state.collectionView==='platform'?'platform':state.collectionView==='dataGov'?'data governance':'data management'} instances added. Sorted by most recently created.
        </Typography>
      </div>
      {
        data && data.docs.length>0 && 
        <div>
          <ColumnHeader label='instance'/>
          {
            data.docs.map(getItem)
          }
        </div>
      }
      <div ref={loadingRef} style={{marginTop:8,display:'flex',justifyContent:'center'}}>
        {
          state.collectionLoading && 
          <CircularProgress color='secondary'/>
        }
      </div>
      {
        state.collectionError && 
        <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading collections</Typography>
      }
      {
        data && data.numFound===0 && 
        <Typography style={{color:theme.palette.primaryText.main}}>No collections found</Typography>
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(Collection));