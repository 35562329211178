import React, { useState, useEffect, useReducer, useRef } from 'react';
import { withStyles, Dialog,DialogTitle,DialogContent,DialogContentText,Button,DialogActions } from '@material-ui/core';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { Typography } from '@material-ui/core';
import Body from '../../components/Teams/Body/Body';
import { toTitleCase } from '../../utilities';
import axiosCerebrum from '../../axios-cerebrum'
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import { addHistory } from '../../HistoryManager';
import { adminPageInfo } from '../NavBar/utils';
import useAlert from '../../hooks/useAlert';


const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  titleAboveLine: {
    height: '24px',
    //width: '422px',
    color: theme.palette.primaryText.main,
    
    fontSize: '13.75px',
    fontWeight: 500,
    letterSpacing: '0.1px',
    lineHeight: '24px',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  alertText: {
    textAlign: 'justify',
    //overflow:'scroll',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem',
    color: theme.palette.primaryText.light,
    
    fontSize: '15.71px',
    letterSpacing: '0.15px',
    lineHeight: '24px'
  },
  modal:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
  dialogTitle:{
    '& h6':{
      color:theme.palette.primaryText.main
    }
  },
  title:{
    color:theme.palette.header.main,
  }
});

const initialState = {
  tabState: 0,
  editTabState:0,
  // 
  teamsData: null,
  teamsLoading: null,
  teamsError: null,
  alert: false,
  //memberData
  memberData: null,
  memberLoading: null,
  memberError: null,
  userData: [],
  selectedTeam: null,
  totalMemberlistData:[],
  memberlistDataStartRow: 0,
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_edit_tab_state':
      return {
        ...state,
        editTabState: action.editTabState
      }
    case 'set_teams_data':
      return {
        ...state,
        teamsData: action.teamsData,
        teamsError: action.teamsError,
        teamsLoading: action.teamsLoading
      }
    case 'set_alert':
      return {
        ...state,
        alert: action.alert
      }
    case "set_member_data":
      return {
        ...state,
        memberData: action.memberData,
        memberLoading: action.memberLoading,
        memberError: action.memberError,
        memberNum: action.memberNum
      }
    case "set_update_user_data":
      return {
        ...state,
        userData: action.userData
      }
    case "set_selected_team":
      return {
        ...state,
        selectedTeam: action.selectedTeam
      }
    case 'set_memberlist_data_start_row':
      return {
        ...state,
        memberlistDataStartRow: action.memberlistDataStartRow
      }  
    case "set_total_memberlist_data":
        return { ...state, totalMemberlistData: action.totalMemberlistData }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}

const Teams = props => {

  const {
    history,
    classes,
    sessionData
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);
  const [actAlert, setActAlert] = useState(false)
  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])

  useEffect(()=>{
    if(!state.selectedTeam){
      dispatch({type:'set_member_data'})
      return;
    }
  },[state.selectedTeam])

  useEffect(()=>{
    if(state.tabState===0){
      dispatch({type:'set_edit_tab_state',editTabState:0})
      dispatch({type:'set_selected_team'})
    }
  },[state.tabState])

  useEffect(()=>{
    addHistory({
      url:window.location.pathname, 
      iconLabel:adminPageInfo.teams.icon, 
      title: adminPageInfo.teams.name, 
      subTitle:adminPageInfo.teams.description,
      type:'application',
    })
  },[])

  const toggleActive = () => {
    axiosCerebrum.put(
      `/api/groups/${state.selectedTeam.id}`,
      {
        description:state.selectedTeam.description,
        active_flag:!state.selectedTeam.active_flag,
      }
    ).then(response=>{
      dispatch({
        type:'set_teams_data',
        teamsData:{...state.teamsData,page:0,items:[]}
      })
      dispatch({type:'set_tab_state',tabState:0});
      setActAlert(false);
      sendAlert({message:`Successfully ${state.selectedTeam.active_flag?'deactivated':'activated'} ${toTitleCase(state.selectedTeam.name)} team`,type:'info'})
    }).catch(error=>{
      console.log(error);
      sendAlert({message:`Error occurred ${state.selectedTeam.active_flag?'deactivating':'activating'} ${toTitleCase(state.selectedTeam.name)} team`,type:'error'})
    })
  }

  let title,description, subTitle;
  let buttons = [];

  description='Use this page to add, edit or deactivate a team. You can assign people to teams when you create or edit a team.'

  if(state.tabState===0){
    title='Teams';
    subTitle = ' ';
    buttons.push(
      <ProfileButton
        onClick={() => dispatch({ type: 'set_tab_state', tabState: 1 })}
        iconLabel='add'
        text='ADD TEAM'
      />
    )
  }

  if(state.tabState===1){
    title='Add a new Team'
    subTitle=<Typography className={classes.titleAboveLine} onClick={() => dispatch({ type: 'set_tab_state', tabState: 0 })}>{'TEAMS'}</Typography>
  }

  if(state.tabState===2){
    title=state.selectedTeam && state.selectedTeam.name
    subTitle=<Typography className={classes.titleAboveLine} onClick={() => dispatch({ type: 'set_tab_state', tabState: 0 })}>{'TEAMS'}</Typography>
    buttons.push(
      <ProfileButton
        onClick={() => setActAlert(true)} 
        iconLabel={state.selectedTeam.active_flag ? 'deactivate' : 'activate'}
        text={state.selectedTeam.active_flag ? 'DEACTIVATE' : 'ACTIVATE'}
      />
    )
  }

  return (
    <div>
      <ProfileLayout
        noPadding
        disableBodyScroll={true}
        header={
          <ProfileHeader
            label="team_admin"
            iconLabel='accountTeams'
            title={title}
            description={description}
            subtitle={subTitle}
            buttons={buttons}
          />
        }
        body={
          <div className={classes.bodyProfile}>
            <Body
              history={history}
              state={state}
              dispatch={dispatch}
              sessionData={sessionData}
            />
            {
              state.selectedTeam && 
              <Dialog
                open={actAlert}
                onClose={()=>setActAlert(false)}
                classes={{paper:classes.modal}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>{"Are you sure?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {
                      state.selectedTeam.active_flag ? 
                      <div>
                        <Typography color='textSecondary' className={classes.alertText}>{"Deactivating a team will prevent users from joining that team."}</Typography>
                        <Typography color='textSecondary' className={classes.alertText}>{"Deactivating the team will not affect users that currently belong to the team."}</Typography>
                      </div> 
                      : 
                      <div>
                        <Typography color='textSecondary' className={classes.alertText}>{"Activating this team will enable people from joining this team"}</Typography>
                      </div>
                    }
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={()=>setActAlert(false)} color="primary" autoFocus>
                    CANCEL
                  </Button>
                  <Button onClick={toggleActive} color="secondary">
                    {state.selectedTeam.active_flag? 'DEACTIVATE' : 'ACTIVATE'}
                  </Button>
                </DialogActions>
              </Dialog>
            }
            
          </div>
        }
      />
    </div>)
}

export default withStyles(styles)(Teams);
