import React,{useEffect,useState, useRef} from 'react';
import { withTheme, withStyles, Typography, CircularProgress, Select, MenuItem,InputBase, IconButton, Button, Paper, Popper, MenuList, ClickAwayListener } from '@material-ui/core';
import VerticalTabBar from "../../UI/VerticalTabBar/VerticalTabBar";
import axiosSolr from '../../../axios-solr';
import ResultItem from '../../UI/SearchResults/ResultItem2'
import ChipWrapper from '../../UI/ChipWrapper/ChipWrapper'
import axiosCerebrum from '../../../axios-cerebrum';
import {getIconComponent, isInViewport, onClickResultItem, getDispFields, toTitleCase, getSearchMode, mapObjectName, formatNumber} from '../../../utilities';
import useAlert from '../../../hooks/useAlert';


const styles = theme => ({
  root:{
    display:'flex'
  },
  normaltext:{
    color:theme.palette.primaryText.main
  },
  title:{
    fontSize:20,
    color:theme.palette.header.main
  },
  selector:{
    ...theme.components.selector,
    width:120,
    marginRight:8,
  },
  nameFilter:{
    ...theme.components.inputBase,
    marginRight:24,
    width:160,
    '& input':{
      paddingTop:10,
      paddingBottom:10,
      paddingLeft:8
    },
  },
  listActionSectionTitle:{
		color:theme.palette.primary.main,
		fontSize:12,
		letterSpacing:2,
		marginLeft:16,
		marginBottom:8,
		marginTop:12
	},
	listContainer:{
    padding:0,
  },

  selectPaper:{
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`
  },
})

const Linked = props => {

  const {
    classes,
    history,
    theme,
    state,
    dispatch,
  } = props;

  const loadingRef = useRef()
  const searchTimeoutRef = useRef();
	const [anchor, setAnchor] = useState()
	const [listActionOpen, setListActionOpen] = useState(false);

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    return ()=>{
      isCancelledRef.current = true
    } 
  },[])


  const loadData = ({tab,filter,search,reload}) => {
    let url = `/api/tags/${state.basicData.id}/related`;
    let params = {
      object_name:state.linkTabData[tab],
      relationship:'TAG_OF',
      'search.name':search && search.trim()!==''?search:undefined,
      sort:filter,
      per_page:10
    }

    let page = 1;
    if(state.linkData[tab] && !reload){
      page = state.linkData[tab].page+1;
    }
    params.page = page;

    dispatch({type:'set_link_error',linkError:{...state.linkError,[tab]:false}})
    dispatch({type:'set_link_loading',linkLoading:{...state.linkLoading,[tab]:true}})
    axiosCerebrum
      .get(url, {params})
      .then(response=>{
        if(response.data.total===0){
          dispatch({
            type:'set_link_data',
            linkData:{
              ...state.linkData,
              [tab]:
                page===1?
                  {...response.data}:
                  {...response.data,items:[...state.linkData[tab].items]}
            }
          })
          dispatch({type:'set_link_error',linkError:{...state.linkError,[tab]:false}})
          dispatch({type:'set_link_loading',linkLoading:{...state.linkLoading,[tab]:false}})
          return;
        }
        let ids = response.data.items.map(el=>el.id);
        let solrParam = {
          q:'*',
          fq:`id:(${ids.join(' OR ')})`,
          fl:'*'
        }
        axiosSolr
          .get('/solr/search/select',{params:solrParam})
          .then(solrRes=>{
            dispatch({
              type:'set_link_data',
              linkData:{
                ...state.linkData,
                [tab]:
                  page===1?
                    {...response.data,items:solrRes.data.response.docs.sort((a,b)=>ids.indexOf(a.id)-ids.indexOf(b.id))}:
                    {...response.data,items:[...state.linkData[tab].items,...solrRes.data.response.docs.sort((a,b)=>ids.indexOf(a.id)-ids.indexOf(b.id))]}
              }
            })
            dispatch({type:'set_link_error',linkError:{...state.linkError,[tab]:false}})
            dispatch({type:'set_link_loading',linkLoading:{...state.linkLoading,[tab]:false}})
          })
          .catch(error=>{
            dispatch({type:'set_link_error',linkError:{...state.linkError,[tab]:true}})
            dispatch({type:'set_link_loading',linkLoading:{...state.linkLoading,[tab]:false}})
          })
      })
      .catch(error=>{
        console.log(error)
        dispatch({type:'set_link_error',linkError:{...state.linkError,[tab]:true}})
        dispatch({type:'set_link_loading',linkLoading:{...state.linkLoading,[tab]:false}})
      })
  }

  useEffect(()=>{
    if(!state.linkData[state.linkTabState] && state.linkTabData){
      loadData({tab:state.linkTabState,filter:state.linkFilter[state.linkTabState],search:state.searchFilter[state.linkTabState]})
    }
  // eslint-disable-next-line
  },[state.linkTabState, state.linkTabData])

  const shouldLoadMore = () => {
    return ((!state.linkLoading[state.linkTabState]) && isInViewport(loadingRef) && state.linkData[state.linkTabState] && state.linkData[state.linkTabState].page<state.linkData[state.linkTabState].pages)
  }

  window.onscroll = () => {
    if(shouldLoadMore()){
      loadData({tab:state.linkTabState,filter:state.linkFilter[state.linkTabState],search:state.searchFilter[state.linkTabState]})
    }
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadData({tab:state.linkTabState,filter:state.linkFilter[state.linkTabState],search:state.searchFilter[state.linkTabState]})
    }
  // eslint-disable-next-line
  },[state.linkData, state.linkLoading])

  const onChangeFilter = value => {
    dispatch({
      type:'set_link_filter',
      linkFilter:{
        ...state.linkFilter,
        [state.linkTabState]:value
      }
    })
    loadData({tab:state.linkTabState,filter:value, search:state.searchFilter[state.linkTabState], reload:true})
  }

  const onNameFilterChange = value => {
    dispatch({type:'set_search_filter',searchFilter:{...state.searchFilter,[state.linkTabState]:value}})
    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(()=>{
      loadData({tab:state.linkTabState,reload:true,search:value,filter:state.linkFilter[state.linkTabState]})
    },250)
  }

  const onRemoveLink = item => {
    axiosCerebrum
    .delete(
      `/api/tags/${state.basicData.id}/related?object_id=${item.id}&relationship=TAG_OF`
    )
    .then(response=>{
      dispatch({type:'set_link_object_data'})
      let index = state.linkTabData.indexOf(item.object_type_txt.toUpperCase())
      if(!state.linkData[index])return;
      dispatch({
        type:'set_link_data',
        linkData:{
          ...state.linkData,
          [index]:{
            ...state.linkData[index],
            total:state.linkData[index].total-1,
            items:state.linkData[index].items.filter(el=>el.id!==item.id)
          }
        }
      })
    })
    .catch(error=>{
      console.log(error)
      sendAlert({message:'Error occurred unlinking the object, please try again',type:'error'})
    })
  }

  const onListActionClick = event => {
		setAnchor(event.currentTarget);
    if(!listActionOpen)setListActionOpen(true);
	}

	const onClickViewInSearch = () => {
    if(getSearchMode()==='basic_search'){
      let presetFilter = `tags_msrt:${state.basicData.name}`;
      history.push(`/basic_search?query=*&object=${mapObjectName(state.linkTabData[state.linkTabState])}&presetFilter=${presetFilter}`)
    }
	}

  if(state.linkTabError)return <Typography style={{color:theme.palette.primaryText.main}}>Error occurred loading data</Typography>
  if(state.linkTabLoading)return <div style={{display:'flex',justifyContent:'center',marginTop:20}}><CircularProgress color='secondary'/></div>
  if(!state.linkTabData)return <Typography style={{color:theme.palette.primaryText.main}}>No content to be displayed</Typography>
  if(state.linkTabData.length===0)return <Typography style={{color:theme.palette.primaryText.main}}>No item linked to the tag</Typography>

  return (
    <div className={classes.root}>
      <VerticalTabBar
        tabOptions={state.linkTabData.map(el=>el.replaceAll('_',' '))}
        tabState={state.linkTabState}
        setTabState={value => dispatch({ type: 'set_link_tab_state', linkTabState: value })}
      />
      <div style={{flexGrow:1,marginLeft:80,overflow:'hidden'}}>
        <div style={{display:'flex',marginBottom:9, alignItems:'flex-start'}}>
          <div>
            <div style={{display:'flex',flexGrow:0,flexShrink:0}}>
              <Typography className={classes.title}>{`${state.linkData[state.linkTabState]?formatNumber(state.linkData[state.linkTabState].total):'0'} ${state.linkTabData[state.linkTabState].replace(/_/g,' ')}(S)`}</Typography>
              {
                state.editingLink ?      
                <Button color='secondary' style={{marginLeft:24,width:40}} onClick={()=>dispatch({type:'set_editing_link',editingLink:false})}>
                  CLOSE
                </Button>
                :
                <Button style={{marginLeft:24,width:40}} color='primary' onClick={()=>dispatch({type:'set_editing_link',editingLink:true})}>
                  EDIT
                </Button>
              }
            </div>
            <Typography style={{fontSize:12,color:theme.palette.primaryText.light}}>{toTitleCase(state.linkTabData[state.linkTabState].replace(/_/g,' '))}(s) linked to this tag. Click EDIT to remove links</Typography>
          </div>


          <div style={{flexGrow:1}}></div>
          <InputBase
            value={state.searchFilter[state.linkTabState]}
            onChange={event => onNameFilterChange(event.target.value)}
            variant={'filled'}
            placeholder={'Search'}
            className={classes.nameFilter}
            endAdornment={
              <IconButton 
                disabled={!state.searchFilter[state.linkTabState] || state.searchFilter[state.linkTabState]===''} 
                onClick={()=>onNameFilterChange('')}
                style={{width:32,height:32,marginRight:6}}
              >
                {getIconComponent({label:!state.searchFilter[state.linkTabState] || state.searchFilter[state.linkTabState]===''?'search':'clear',size:24,colour:theme.palette.primaryText.light})}
              </IconButton>
            }
          />
          <Select
            className={classes.selector}
            value={state.linkFilter[state.linkTabState]}
            onChange={event=>onChangeFilter(event.target.value)}
            MenuProps={{
              classes:{
                paper:classes.selectPaper
              }
            }}
          >
            {
              [
                {displayName:'A-Z',value:'ALPHABETICAL'},
                {displayName:'Z-A',value:'REVERSE_ALPHABETICAL'}
              ].map(el=>(
                <MenuItem  className={classes.menuItem} value={el.value}>
                  {el.displayName}
                </MenuItem>
              ))
            }
          </Select>
          <div style={{marginTop:8,width:24,height:24,cursor:'pointer'}} onClick={onListActionClick}>
            {getIconComponent({label:'menu',size:24,colour:theme.palette.primaryText.main})}
          </div>
          <Popper open={listActionOpen} anchorEl={anchor} placement='bottom-end'>
            <ClickAwayListener onClickAway={()=>setTimeout(()=>setListActionOpen(false))}>
              <Paper style={{marginTop:20,marginRight:-2,width:200,border:`1px solid ${theme.palette.border.main}`,background:theme.palette.background.main}}>
                <Typography className={classes.listActionSectionTitle}>ACTIONS</Typography>
                <MenuList className={classes.listContainer}>
                  <MenuItem onClick={()=>{onClickViewInSearch()}} className={classes.menuItem} >
                    <Typography style={{ fontSize:13.75, color:theme.palette.primaryText.main }}>View in search</Typography>
                  </MenuItem>
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
        {
          state.linkData[state.linkTabState] && state.linkData[state.linkTabState].items.length===0 && 
          <Typography className={classes.normaltext}>No item to be displayed</Typography>
        }
        {
          state.linkData[state.linkTabState] && 
          state.linkData[state.linkTabState].items.map(item=>(
            <ChipWrapper
              wrappedComponent={
                <ResultItem
                  item={item}
                  key={item.id}
                  dispBody={getDispFields(item,'dispBody')}
                  dispSubtitle={getDispFields(item,'dispSubtitle')}
                  dispTitle={getDispFields(item,'dispTitle')}
                  label={item.object_type_txt}
                  showIcon
                  onClick={state.editingLink?undefined:() => onClickResultItem({
                    item,
                    id:item.id,
                    label: item.object_type_txt,
                    history: history
                  })}
                />
              }
              icon={state.editingLink?getIconComponent({label:'clear',size:24,colour:theme.palette.primaryText.light}):undefined}
              onIconClick={()=>onRemoveLink(item)}
              showUnderline
            />
          ))
        }
        {
          state.linkError[state.linkTabState]  && 
          <Typography className={classes.normaltext}>Error occurred loading items</Typography>
        }
        <div ref={loadingRef} style={{width:'100%',display:'flex',justifyContent:'center',marginBottom:30}}> 
          {
            state.linkLoading[state.linkTabState] && 
            <CircularProgress color='secondary'/>
          }
        </div>
      </div>
    </div>
  )
}



export default withTheme()(withStyles(styles)(Linked));