import { Typography, withStyles, withTheme } from '@material-ui/core';
import React, { memo } from 'react';

import { Handle } from 'react-flow-renderer';
import theme from '../../../theme';
import { getIconComponent } from '../../../utilities';
import { getIconLabel } from '../SearchResults/utils';


const styles = theme => ({
  nodeHoverable:{
    '&:hover':{
      background:`${theme.palette.hovered.main} !important`
    },
  },
  nodeContainer:{
    borderRadius:3,
    padding:'8px 16px',
    minWidth:100,
    pointerEvents:'auto',
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
  }
})


const Node = memo(({ data, isConnectable, selected, type, classes }) => {
  return (
    <>
      <Handle
        type="target"
        position="left"
        style={{ background: '#555',visibility:'hidden' }}
      />
      <div
        style={{
          // background: selected?theme.palette.chartNodeSelected.main:(data.backgroundColour || theme.palette.background.main),
          // border:`1px solid ${data.borderColour || theme.palette.border.main}`,
          width:data.width,
        }}
        className={classes.nodeContainer}
        onClick={event=>{
          event.stopPropagation()
          event.preventDefault()
          data.onClick?.(event)
        }}
      >
        {
          data.obj &&
          <div style={{width:24,height:24,marginRight:8}}>
            {
              getIconComponent({
                label:getIconLabel({label:data.obj.object_type_txt || data.obj.object.name, item:data.obj}),
                size:24,
                colour:data.iconColour || data.titleColour || theme.palette.primaryText.main
              })
            }
          </div>
        }
        <Typography 
          style={{
            fontSize:13.75,
            color: selected?theme.palette.primaryText.main:(data.titleColour || theme.palette.primaryText.main)
          }}
          className={'overflow-text'}
        >
          {data.label}
        </Typography>
      </div>
      <Handle
        type="source"
        position="right"
        style={{ bottom: 10, top: 'auto', background: '#555',visibility:'hidden'  }}
        // isConnectable={isConnectable}
      />
    </>
  );
});

export default withTheme()(withStyles(styles)(Node));