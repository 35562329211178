import React, {  } from 'react';
import { withTheme, withStyles, Typography, LinearProgress } from '@material-ui/core';
import useGetCerebrum from '../../hooks/useGetCerebrum';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import AutoCollectionInstanceProfile from '../AutoCollectionInstanceProfile/AutoCollectionInstanceProfile';
import ManualCollectionInstanceProfile from '../ManualCollectionInstanceProfile/ManualCollectionInstanceProfile';

const styles = theme => ({
  normalText:{
    color:theme.palette.primaryText.main
  }
});


const CollectionInstanceProfile = props => {

  const {
    match,
    classes,
    // theme,
    sessionData,
    history,
  } = props;

  const {
    data: fullResponse,
    loading,
    error,
  } = useGetCerebrum({
    url: `/api/collectioninstances/${match.params.id.toLowerCase()}`,
  })

  if (loading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (error ) {
    return (
      <DeadEnd />
    )
  }
  
  if(!fullResponse)return <></>

  if(fullResponse.parent.category==='AUTOMATED'){
    return <AutoCollectionInstanceProfile key={window.location.pathname} history={history} match={match} sessionData={sessionData} cerebrumObject={fullResponse} />
  }
  return <ManualCollectionInstanceProfile key={window.location.pathname} history={history} match={match} sessionData={sessionData} cerebrumObject={fullResponse}/>

}




export default withTheme()(withStyles(styles)(CollectionInstanceProfile));
