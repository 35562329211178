import React from 'react';
import { withStyles } from '@material-ui/core';
import AccessRoles from './AccessRoles'
import VerticalTabBar from '../../UI/VerticalTabBar/VerticalTabBar';
import Users from './Users';

const styles = theme => ({
  root: {
    display:'flex'
  }
})

const Related = props => {

  const {
    classes,
    history,
    state,
    dispatch,
  } = props;


  return (
    <div className={classes.root}>
      <VerticalTabBar
        tabOptions={['ROLES','USERS']}
        tabState={state.relatedTabState}
        setTabState={value => dispatch({ type: 'set_related_tab_state', relatedTabState: value })}
      />
      <div style={{marginLeft:80,flexGrow:1,overflow:'hidden'}}>
        {
          state.relatedTabState===0 && 
          <AccessRoles
            state={state}
            dispatch={dispatch}
            history={history}
          />
        }
        {
          state.relatedTabState===1 && 
          <Users
            state={state}
            dispatch={dispatch}
            history={history}
          />
        }
      </div>
    </div>
  )
}


export default withStyles(styles)(Related);