import React, { useEffect, useReducer, useState } from 'react';
import {withTheme, withStyles, Typography,  Modal, Select, MenuItem, Paper, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import ModalAlert from '../ModalAlert/ModalAlert'
import LinkBody from './LinkBody'
import { getIconComponent, mapObjectName, sendMessage, setInitialState, tabGroup, toTitleCase } from '../../../utilities';
import Filter from './Filter';
import LinkedList from './LinkedList';
import { globalListenerRef } from '../../../GlobalListenerRef';
import moment from 'moment';
import Diagram from './Diagram';
import {useDispatch, useStore} from 'react-redux'
import * as actions from '../../../store/actions/actionTypes';

const styles = theme => ({
  container:{
    height:'100vh',
    width:'100vw',
    display:'flex',
    justifyContent:'center',
    outline:'none'
  },
  paperContainer:{
    width:782,
    marginTop:'8vh',
  },  
  paper:{
    width:732,
    maxHeight:'65vh',
    minHeight:400,
    paddingTop:0,
    paddingLeft:24,
    paddingRight:24,
    paddingBottom:12,
    display:'flex',
    flexDirection:'column',
    background:theme.palette.background.main,
    border:`1px solid ${theme.palette.border.main}`,
    overflow:'auto',
    ...theme.components.customScroll
  },
  selector:{
    ...theme.components.titleSelector
  },
  selectorHeader:{
    fontSize:12,
    color:theme.palette.primaryText.main,
    letterSpacing:2,
    paddingLeft:16,
    marginTop:4,
    marginBottom:4
  }
})


const initialState = {
  collectionType:'all',
  collection:'all',
  collectionParent:'all',
  source:'all',
  issueSource:'all',
  userType:'all',
  team:'all',
  database:'all',
  schema:'all',
  table:'all',
  location:'all',
  reference:'NO',
  filterCollections:[],
  filterCollectionParentList:[],
  filterSources:[],
  filterIssueSources:[],
  filterUserTypes:[],
  filterTeams:[],
  filterDatabases:[],
  filterSchemas:[],
  filterTables:[],
  filterLocations:[],
  // filterSearchValue:{}
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_linked_item':
      return {
        ...state,
        linkedItemData:action.linkedItemData,
        linkedRelations: action.linkedRelations,
        linkedObjects: action.linkedObjects,
        linkedItemError:action.linkedItemError,
        linkedItemLoading:action.linkedItemLoading
      }
    case 'set_link_updated':
      return {
        ...state,
        linkUpdated:action.linkUpdated
      }
    // case 'set_filter_search_value':
    //   return {
    //     ...state,
    //     filterSearchValue:action.filterSearchValue
    //   }
    case 'set_filter_collections':{
      return {
        ...state,
        filterCollections:action.filterCollections
      }
    }
    case 'set_filter_collection_parent_list':{
      return {
        ...state,
        filterCollectionParentList:action.filterCollectionParentList
      }
    }
    case 'set_filter_sources':{
      return {
        ...state,
        filterSources:action.filterSources
      }
    }
    case 'set_filter_issue_sources':{
      return {
        ...state,
        filterIssueSources:action.filterIssueSources
      }
    }
    case 'set_filter_user_types':{
      return {
        ...state,
        filterUserTypes:action.filterUserTypes
      }
    }
    case 'set_filter_teams':{
      return {
        ...state,
        filterTeams:action.filterTeams
      }
    }
    case 'set_filter_databases':{
      return {
        ...state,
        filterDatabases:action.filterDatabases
      }
    }
    case 'set_filter_schemas':{
      return {
        ...state,
        filterSchemas:action.filterSchemas
      }
    }
    case 'set_filter_tables':{
      return {
        ...state,
        filterTables:action.filterTables
      }
    }
    case 'set_filter_locations':{
      return {
        ...state,
        filterLocations:action.filterLocations
      }
    }
    case 'set_collection':
      return {
        ...state,
        collection:action.collection
      }
    case 'set_collection_type':
      return {
        ...state,
        collectionType: action.collectionType
      }
    case 'set_collection_parent':
      return {
        ...state,
        collectionParent: action.collectionParent
      }
    case 'set_source':
      return {
        ...state,
        source: action.source
      }
    case 'set_issue_source':
      return {
        ...state,
        issueSource: action.issueSource
      }
    case 'set_user_type':
      return {
        ...state,
        userType:action.userType
      }
    case 'set_team':
      return {
        ...state,
        team: action.team
      }
    case 'set_database':
      return {
        ...state,
        database: action.database
      }
    case 'set_schema':
      return {
        ...state,
        schema:action.schema
      }
    case 'set_table':
      return {
        ...state,
        table:action.table
      }
    case 'set_location':
      return {
        ...state,
        location: action.location
      }
    case 'set_reference':
      return {
        ...state,
        reference: action.reference
      }
    case 'set_replace_date':
      return {
        ...state,
        replaceDate:action.replaceDate
      }
    default:
      throw new Error("Reducer action not supported.", action);
  }
}



const GenericLinkModal = props => {

  const {
    theme,
    history,
    classes,
    object,
    relations = ['MEMBER_OF','MEMBERS'],
    linkableObjects = ['TABLE','COLUMN','SCHEMA','DATABASE','MACRO','PROCEDURE','CONTENT','CONTENT_APP','CONTENT_CHILD','DATA_PIPELINE','QUERY','FILE','DATASET_FIELD','DATASET_TABLE','ML_MODEL'],
    modalOpen, 
    setModalOpen,
    onLinkUpdated,
    profileDispatch,
    allowAddNewIssue,
    // customised texts 
    hideTitle,
    modalTitle,
    listTitle,
    isPluginComponent,
    enableFilterHistory
  } = props;
  
  const cacheID = `link_modal_${object?.id}_${relations.join('_')}_${linkableObjects.join('_')}`;
  const reduxDispatch = useDispatch()
  const store = useStore();
  const pageCache = store.getState().pageCache.pageCache

  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState,cacheID));

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState();

  const [selectedObjectType, setSelectedObjectType] = useState();
  const [selectedRelation, setSelectedRelation] = useState()

  const clearFilter = () => {
    dispatch({type:'set_collection',collection:'all'})
    dispatch({type:'set_collection_type',collectionType:'all'})
    dispatch({type:'set_source',source:'all'})
    dispatch({type:'set_database',database:'all'})
    dispatch({type:'set_schema',schema:'all'})
  }

  useEffect(()=>{
    if(!object)return;
    if(selectedObjectType && linkableObjects.includes(selectedObjectType))return;
    if(linkableObjects.includes(object.object.name)){
      if(selectedObjectType!==object.object.name){
        clearFilter()
        setSelectedObjectType(object.object.name)
        sendMessage({selected_object_type:object.object.name})
      }
    }else{
      if(selectedObjectType!==linkableObjects[0]){
        setSelectedObjectType(linkableObjects[0])
        sendMessage({selected_object_type:linkableObjects[0]})
        clearFilter()
      }
    }
    
  // eslint-disable-next-line
  },[JSON.stringify(linkableObjects)])
  
  useEffect(()=>{
    if(!state )return;
    reduxDispatch({
      type:actions.STORE_PAGE_CACHE,
      data:{
        cacheID,
        ...state
      }
    })
  // eslint-disable-next-line
  },[state, cacheID])

  useEffect(()=>{
    if(!object)return;
    if(['REPLACES','REPLACED_BY'].includes(selectedRelation)){
      if(selectedObjectType!==object.object.name)setSelectedRelation(relations[0]);
    }
  // eslint-disable-next-line
  },[selectedObjectType, selectedRelation])

  useEffect(()=>{
    setSelectedRelation(relations[0])
  // eslint-disable-next-line
  },[relations])

  useEffect(()=>{
    if(!object)return;
    if(selectedRelation==='REPLACDE_BY' && object.repalce_date){
      dispatch({type:'set_replace_date',replaceDate:moment(object.repalce_date)})
    } 
  // eslint-disable-next-line
  },[selectedRelation])

  useEffect(()=>{
    const onMsgReceived = (msg) => {
      if(msg.data.reload_link_modal ){
        dispatch({type:'set_linked_item'})
      }
    }
    window.removeEventListener('message',globalListenerRef.reloadLinkModalListener);
    globalListenerRef.reloadLinkModalListener = onMsgReceived;
    window.addEventListener("message", globalListenerRef.reloadLinkModalListener);
    return (()=>{window.removeEventListener('message',globalListenerRef.reloadLinkModalListener);})
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if(state.linkUpdated && isPluginComponent){
      onLinkUpdated && onLinkUpdated();
      dispatch({type:'set_link_updated',linkUpdated:false})
    }
  },[state.linkUpdated, onLinkUpdated, isPluginComponent])

  const formatName = () => {
    return object.name.length>20?object.name.slice(0,20)+'...':object.name
  }

  const getObjectName = t => {
    if(t==='ML_MODEL')return 'ML Model'
    return toTitleCase(mapObjectName(t,true).replace(/_/g,' '))
  }

  if(!object || linkableObjects.length===0)return <></>

  let component = (
    <Paper className={isPluginComponent?undefined:classes.paper} elevation={isPluginComponent?0:undefined}>
      <div 
        style={isPluginComponent?{}:{display:'flex',alignItems:'center',marginBottom:4,paddingBottom:8,paddingTop:16,position:'sticky',top:0,background:theme.palette.background.main,zIndex:9999}}
      >
        {
          !hideTitle && 
          <Typography style={{fontSize:20,color:theme.palette.header.main}}>
            {
              modalTitle || 
              `Link ${formatName()} to ${linkableObjects.length===1?toTitleCase(mapObjectName(linkableObjects[0],true).replace(/_/g,' ')):''}`
            }
          </Typography>
        }
        {
          linkableObjects.length>1 &&
          <Select
            className={classes.selector}
            value={selectedObjectType}
            style={{marginLeft:8}}
            data-test-id="link-modal-object-selector"
            disableUnderline
            renderValue={()=>getObjectName(selectedObjectType)}
          > 
            {
              tabGroup.map(el=>(
                el.tabs.some(t=>linkableObjects.includes(t))?
                <div style={{width:188,marginBottom:12}}>
                  <Typography className={classes.selectorHeader} onClick={event=>{event.stopPropagation();event.preventDefault();}}>{el.name}</Typography>
                  {
                    el.tabs.map(t=>{
                      if(linkableObjects.includes(t)){
                        return (
                          <MenuItem value={t} onClick={()=>{
                            if(t===selectedObjectType)return;
                            setSelectedObjectType(t);
                            sendMessage({selected_object_type:t})
                            clearFilter();
                          }}>
                            <div style={{width:24,height:24,marginRight:16}}>{getIconComponent({label:t,size:24,colour:theme.palette.primaryText.light})}</div> 
                            {getObjectName(t)}
                          </MenuItem>
                        )
                      }
                      return undefined
                    })
                  }
                </div>
                :
                undefined
              ))
            }

            {
              // linkableObjects.map(el=>(
              //   <MenuItem  value={el}>
              //     {toTitleCase(mapObjectName(el,true).replace(/_/g,' '))}
              //   </MenuItem>
              // ))
            }
          </Select>
        }
        <div style={{flexGrow:1}}></div>
        {
          !isPluginComponent && 
          <Button 
            color='primary' 
            variant='outlined' 
            data-test-id="link-modal-close-button"
            onClick={()=>{
              if(state.linkUpdated){
                onLinkUpdated && onLinkUpdated();
                dispatch({type:'set_link_updated',linkUpdated:false})
                if(['SOURCE_FROM','SOURCE_OF','REPLACED_BY','REPLACES'].find(el=>relations.includes(el))){
                  sendMessage({upstreamAdded:true,downstreamAdded:true,objectType:selectedObjectType}) // old lineage
                  sendMessage({lineage_node_link_changed:true})  // lineage v3

                  sendMessage({reload_sidebar:'lineage'})
                }
                if(relations.includes('RELATED')){
                  sendMessage({reload_description_highlight:true})
                  sendMessage({reload_related_terms:true})
                }
              };
              setModalOpen(false);
            }}
            style={{justifySelf:"flex-end"}}
          >
            CLOSE
          </Button>
        }
      </div>  

      {
        selectedObjectType==='ISSUE' && profileDispatch && allowAddNewIssue && 
        <Button 
          color='secondary' 
          style={{width:'max-content',marginBottom:12,padding:'4px 0'}}
          onClick={()=>{
            setModalOpen(false)
            profileDispatch({ type: 'set_dqModal_open', dqModalOpen: true })
          }}
        >
          CREATE NEW ISSUE
        </Button>
      }
      
      {
        state.linkedItemData && 
        <>
          <Filter
            selectedRelation={selectedRelation}
            selectedObjectType={selectedObjectType}
            setSelectedObjectType={setSelectedObjectType}
            state={state}
            dispatch={dispatch}
            isPluginComponent={isPluginComponent}
            linkableObjects={linkableObjects}
            enableFilterHistory={enableFilterHistory}
          />


          <Diagram
            selectedObjectType={selectedObjectType}
            object={object}
            selectedRelation={selectedRelation}
          />
          
          <LinkBody
            selectedRelation={selectedRelation}
            setSelectedRelation={setSelectedRelation}
            relations={relations}
            selectedObjectType={selectedObjectType}
            object={object}
            state={state}
            dispatch={dispatch}
            setAlertOpen={setAlertOpen}
            setAlertMessage={setAlertMessage}
            setAlertType={setAlertType}
            profileDispatch={profileDispatch}
            getObjectName={getObjectName}
            isPluginComponent={isPluginComponent}
            enableFilterHistory={enableFilterHistory}
          />
        </>
      }
     
      <LinkedList
        selectedRelation={selectedRelation}
        selectedObjectType={selectedObjectType}
        history={history}
        state={state}
        relations={relations}
        linkableObjects={linkableObjects}
        object={object}
        dispatch={dispatch}
        setAlertOpen={setAlertOpen}
        setAlertMessage={setAlertMessage}
        setAlertType={setAlertType}
        profileDispatch={profileDispatch}
        listTitle={listTitle}
      />

    </Paper>
  )

  if(isPluginComponent){
    return component
  }

  return (
    <Modal
      open={modalOpen}
      onClose={() =>{
        setModalOpen(false)
      }}
      disableBackdropClick
      disableEnforceFocus
    >
      <div className={classes.container}>
        {
          modalOpen &&
          <div className={classes.paperContainer}>
            {component}
            <ModalAlert
              open={alertOpen}
              setOpen={setAlertOpen}
              message={alertMessage}
              type={alertType}
            />   
          </div>
        }
      </div>
    </Modal> 
  )

}

GenericLinkModal.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  history:PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  relations: PropTypes.array,
  linkableObjects: PropTypes.array,
  modalOpen: PropTypes.bool.isRequired, 
  setModalOpen: PropTypes.func.isRequired,
  onLinkUpdated: PropTypes.func,
  profileDispatch:PropTypes.func,
  allowAddNewIssue: PropTypes.bool,
  hideTitle: PropTypes.bool,
  modalTitle: PropTypes.string,
  listTitle: PropTypes.string,
  isPluginComponent: PropTypes.bool
}


export default withTheme()(withStyles(styles)(GenericLinkModal));