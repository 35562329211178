import React, { } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import { getIconComponent } from '../../../utilities';
import theme from '../../../theme';

const styles = theme => ({
  infoBox:theme.components.infoBox,
  infoHeader:theme.components.infoBoxHeader,
  infoDescription:theme.components.infoBoxDescription,
  focusTxt:theme.components.infoBoxFocusTxt,
  button:theme.components.infoBoxButtonOutlined,
  disabledButton:{
    border:`1px solid ${theme.palette.primaryText.light}`
  },
  sectionHeader:{
    fontSize:13.75,
    letterSpacing:1
  }
})

function InfoBox(props) {

  const {
    classes,
    variant
  } = props;
  
  return (
    <div className={classes.infoBox}>
      <Typography className={classes.infoHeader}>About K Alerts</Typography>
      <div style={{display:'flex',alignItems:'flex-start'}}>
        <Typography className={classes.infoDescription} style={{marginBottom:0}}>
          K Alerts send scheduled extracts from Ask K or Filters to your email and any specified recipient. The benefits of K alerts include:
          {'\n'}
          <ul style={{paddingInlineStart:16}}>
            <li>
              Creating audit trails of regular reports (e.g. Monthly usage access reports sent to a Team inbox to help evidence review of system access)
            </li>
            <li>
              Reports sent via K Alerts can be easily shared with non-K users (e.g. List of data assets owned by a specific Data Owner)
            </li>
            <li>
              Scheduled reports can be used to trigger BAU operational processes (Weekly scheduled extract of all data assets with open governance issues to trigger follow-up activity)
            </li>
          </ul>
          A K alert can be scheduled whenever you see this <span style={{position:'relative',top:4}}>{getIconComponent({label:'add_alert',size:20,colour:theme.palette.primaryText.light})}</span> icon. 
          Currently this is available through <span className={classes.focusTxt} onClick={()=>window.open('/question_board','_blank')}>Ask K</span> or <span className={classes.focusTxt} onClick={()=>window.open('/filters','_blank')}>Filters</span> page
          {
            variant==='admin'?
            <><span style={{fontWeight:700}}>{'\n\n'}Note:</span> On the History tab, an alert is marked as 'Complete' when K has successfully run and sent the extract to the alert recipients. If there are invalid email addresses, the alert job will still be executed so that valid recipients receive the alert. You will need to manually check the recipient list to identify invalid emails.</>
            :
            ''
          }
        </Typography>
      </div>
    </div>
  )
}

InfoBox.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default withStyles(styles)(InfoBox);