import React, {useState, useEffect} from 'react';
import { withTheme, FormHelperText,} from '@material-ui/core';
import SearchSelector from '../../UI/SearchSelector/SearchSelector';
import { toTitleCase, getPartialMatchSearchString } from '../../../utilities';
import LabeledSelector from '../../UI/LabeledSelector/LabeledSelector'
import axiosSolr from '../../../axios-solr';

const ObjectSelector = props => {

  const {
    classes,
    theme,
    state,
    onSelectTarget,
    selectedObjectType
  } = props;

  const [objectSearchValue, setObjectSearchValue] = useState('');

  const [database, setDatabase] = useState('all')
  const [schema, setSchema] = useState('all')
  const [table, setTable] = useState('all')
  const [location, setLocation] = useState('all')
  

  const [databases, setDatabases] = useState([])
  const [schemas, setSchemas] = useState([])
  const [tables, setTables] = useState([])
  const [locations, setLocations] = useState([])

  const loadDatabases = sourceID => {
    if(sourceID==='all'){
      setDatabases([])
      return;
    }
    const load = ({prevData=[]}) => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:DATABASE${sourceID && sourceID==='all'?'': ` AND source_id_srt:${sourceID}`} AND active_srt:YES AND reference_srt:NO`,
              rows:100,
              start:prevData.length,
              sort:'name_srt asc'
            }
          }
        )
        .then(response=>{
          let data = [...prevData, ...response.data.response.docs]
          if(response.data.response.numFound>data.length){
            load({prevData:data})
          }else{
            setDatabases(data)
          }
        })
    };
    load({})
  }

  const loadSchemas = dbID => {
    if(dbID==='all'){
      setSchemas([])
      return;
    }
    const load = ({prevData=[]}) => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:SCHEMA AND hierarchy_parent_id_srt:${dbID} AND active_srt:YES AND reference_srt:NO`,
              rows:100,
              start:prevData.length,
              sort:'name_srt asc'
            }
          }
        )
        .then(response=>{
          let data = [...prevData, ...response.data.response.docs]
          if(response.data.response.numFound>data.length){
            load({prevData:data})
          }else{
            setSchemas(data)
          }
        })
    };
    load({})
  }

  const loadTables = schemaID => {
    if(schemaID==='all'){
      setTables([])
      return;
    }
    const load = ({prevData=[]}) => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:TABLE AND hierarchy_parent_id_srt:${schemaID} AND active_srt:YES AND reference_srt:NO`,
              rows:100,
              start:prevData.length,
              sort:'name_srt asc'
            }
          }
        )
        .then(response=>{
          let data = [...prevData, ...response.data.response.docs]
          if(response.data.response.numFound>data.length){
            load({prevData:data})
          }else{
            setTables(data)
          }
        })
    };
    load({})
  }

  const loadLocation = (value) => { 
    if(value==='all'){
      setLocations([])
      return;
    }
    const load = ({prevData=[]}) => {
      axiosSolr
        .get(
          `/solr/search/select`,{
            params:{
              q:"*",
              fq:`object_type_srt:${selectedObjectType}${value==='all'?'': ` AND source_id_srt:${value}`} AND active_srt:YES AND reference_srt:NO`,
              rows:0,
              facet:'on',
              'facet.field':'location_srt',
              'facet.mincount':1,
              'facet.sort':'index',
              'facet.limit':100,
              'facet.offset':prevData.length
            }
          }
        )
        .then(response=>{
          let data = [];
          if(response.data.facet_counts.facet_fields && response.data.facet_counts.facet_fields.location_srt){
            let facet = response.data.facet_counts.facet_fields.location_srt;
            facet.forEach((el,index)=>{
              if(index % 2 ===0){
                data.push({
                  name: facet[index],
                  value: facet[index]
                })
              }
            })  
          }
          if(data.length===50){
            load({prevData:[...prevData,...data]})
          }else{
            setLocations([...prevData,...data])
          }
        })
    };
    load({})
  }

  useEffect(()=>{
    setDatabase('all')
    setSchema('all')
    setTable('all')
    setLocation('all')
    if(['TABLE','COLUMN','DATABASE','SCHEMA','MACRO','PROCEDURE','QUERY'].includes(selectedObjectType)){
      loadDatabases(state.source)
    }
    if(['CONTENT_APP','REPORT','SHEET','DATA_PIPELINE','DATASET','FILE','DATASET_FIELD','DATASET_TABLE'].includes(selectedObjectType)){
      loadLocation(state.source)
    }
  // eslint-disable-next-line
  },[selectedObjectType])

  const onChangeDatabase = value => {
    setDatabase(value)
    setSchema('all')
    setTable('all')
    setLocation('all')
    loadSchemas(value)
  }

  const onChangeSchema = value => {
    setSchema(value)
    setTable('all')
    setLocation('all')
    loadTables(value)
  }

  const onChangeTable = value => {
    setTable(value)
  }

  const onChangeLocation = value => {
    setLocation(value)
  }

  let databaseFilter = (
    <LabeledSelector  
      title="Database"  
      value={database}
      testID="database-selector"
      onChange={event=>onChangeDatabase(event.target.value)}
      options={
        databases.map(d=>({value:d.id,...d}))
      }
      isResultItem
      placeholder="Search for Databases"
    />
  )

  let schemaFilter = (
    <LabeledSelector  
      title="Schema"  
      value={schema}
      testID="schema-selector"
      onChange={event=>onChangeSchema(event.target.value)}
      options={
        schemas.map(d=>({value:d.id,...d}))
      }
      isResultItem
      placeholder="Search for Schemas"
    />
  )

  let tableFilter = (
    <LabeledSelector  
      title="Table"  
      value={table}
      testID="table-selector"
      onChange={event=>onChangeTable(event.target.value)}
      options={
        tables.map(d=>({value:d.id,...d}))
      }
      isResultItem
      placeholder="Search for Tables"
    />
  )

  let locationFilter = (
    <LabeledSelector  
      title="Location"  
      value={location}
      testID="location-selector"
      onChange={event=>onChangeLocation(event.target.value)}
      options={locations}
      placeholder="Search for Location"
    />
  )

  let filterArr = []

  if(['TABLE','COLUMN','DATABASE','SCHEMA'].includes(selectedObjectType)){
    if(['SCHEMA','TABLE','COLUMN'].includes(selectedObjectType))filterArr.push(databaseFilter)
    if(['TABLE','COLUMN'].includes(selectedObjectType) && database!=='all')filterArr.push(schemaFilter)
    if(selectedObjectType==='COLUMN' && schema!=='all')filterArr.push(tableFilter)
  }
  if(['REPORT','SHEET','DATA_PIPELINE','DATASET','FILE','DATASET_FIELD','DATASET_TABLE'].includes(selectedObjectType)){
    filterArr.push(locationFilter)
  }
  if(['CODE'].includes(selectedObjectType)){
    filterArr.push(databaseFilter)
  }

  let fqArr = [
    `object_type_srt:${state.objectType}`,
    `reference_srt:NO`,
    `source_id_srt:${state.source}`,
    `active_srt:YES`
  ];
  if(location!=='all'){
    fqArr.push(`location_srt:"${location}"`)
  }
  if(table!=='all'){
    fqArr.push(`table_srt:"${tables.find(t=>t.id===table).name_txt}"`)
  }
  if(schema!=='all'){
    fqArr.push(`schema_srt:"${schemas.find(t=>t.id===schema).name_txt}"`)
  }
  if(database!=='all'){
    fqArr.push(`database_srt:"${databases.find(t=>t.id===database).name_txt}"`)
  }

  const getDefaultParams = () => {
    let params = {}
    try{
      params = JSON.parse(JSON.parse(localStorage.getItem('platformSettings')).items.find(el=>el.id===8000).value);
    }catch{

    }
    return params
  }
  
  return (
    <div className={classes.block}>
      <div style={{display:'flex',flexWrap:'wrap',marginBottom:16}}>
        {
          filterArr
        }
      </div>
      <SearchSelector
        url={'/solr/search/select'}
        params={{
          ...getDefaultParams(),
          q:`name_txt:(${getPartialMatchSearchString(objectSearchValue || '*',true,true)})`,
          fq:fqArr.join(' AND '),
          fl:"*",
          rows: 10
        }}
        testID="object-search-input"
        autoSuggestion={database!=='all' || schema!=='all' || table!=='all' || location!=='all'}
        searchValue={objectSearchValue}
        setSearchValue={setObjectSearchValue}
        placeholder={`Search for a ${toTitleCase(state.objectType.replace(/_/g,' '))}`}
        onResultClick={onSelectTarget}
        scrollable  
      />
      <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>Required</FormHelperText>
    </div>
  )
}

export default withTheme()(ObjectSelector);