import React from 'react';
import PropTypes from 'prop-types';
import {  withStyles, Typography } from '@material-ui/core';
import Identifiers from '../Identifiers/Identifiers'
import Teams from '../Teams/Teams';

const styles = theme => ({
  root: {
  },

  version:{
    marginLeft:'70vw',
    fontSize:16,
    color:theme.palette.primaryText.light,
    // marginBottom:-80,
    marginTop:80,
    position:'relative',
    bottom:-80
  }
})

const Body = props => {

  const {
    classes,
    tabState,
    state,
    dispatch,
    sessionData,
    aliasFetch
  } = props;


  let bodyContent;
  switch (tabState) {
    case 0: // teams
      bodyContent = (
        <Teams
          state={state}
          dispatch={dispatch}
          sessionData={sessionData}
        />
      );
      break;
    case 1: // Identifiers
      bodyContent = (
        <Identifiers
          state={state}
          dispatch={dispatch}
          sessionData={sessionData}
          aliasFetch={aliasFetch}
        />
      )
      break;
    default:
  }

  return (
    <div className={classes.root}>
      {bodyContent}

      {
        localStorage.getItem('k_platform_version') && 
        <Typography className={classes.version}>K Platform {localStorage.getItem('k_platform_version')}</Typography>
      }
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  tabState: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default withStyles(styles)(Body);